import { FunctionComponent } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as SquareIcon } from "../assets/icon_square.svg"
import { ReactComponent as RoundedCornersIcon } from "../assets/icon_rounded_corners.svg"
import { ReactComponent as RoundIcon } from "../assets/icon_round.svg"

import { PictureFormat } from "./UserAvatarCropper"

interface ActionBtn {
  format: PictureFormat
  label: { id: string; defaultMessage: string }
  Icon: FunctionComponent<React.SVGProps<SVGSVGElement>>
}

interface UserAvatarFormatProps {
  pictureFormat: PictureFormat
  onChangeFormat: (format: PictureFormat) => void
}

const messages = defineMessages({
  format: {
    id: "UserAvatarManager.Format",
    defaultMessage: "Format",
  },
  square: {
    id: "UserAvatarManager.SquareFormat",
    defaultMessage: "Carré",
  },
  roundedCorners: {
    id: "UserAvatarManager.RoundedCornersFormat",
    defaultMessage: "Arrondi",
  },
  round: {
    id: "UserAvatarManager.RoundFormat",
    defaultMessage: "Rond",
  },
})

function pictureFormatIsSelected(
  pictureFormat: PictureFormat,
  format: PictureFormat,
) {
  return pictureFormat === format && "is-selected"
}

const actionsButtons: ActionBtn[] = [
  {
    format: "square",
    label: messages.square,
    Icon: SquareIcon,
  },
  {
    format: "rounded",
    label: messages.roundedCorners,
    Icon: RoundedCornersIcon,
  },
  {
    format: "round",
    label: messages.round,
    Icon: RoundIcon,
  },
]

const UserAvatarFormat: FunctionComponent<UserAvatarFormatProps> = ({
  pictureFormat,
  onChangeFormat,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="user-avatar-manager__format">
      <p className="user-avatar-manager__format--title">
        {formatMessage(messages.format)}
      </p>
      <div className="user-avatar-manager__format--actions">
        {actionsButtons.map((btn) => (
          <div
            key={btn.format}
            className={classNames(
              "user-avatar-manager__action-btn",
              pictureFormatIsSelected(pictureFormat, btn.format),
            )}
            onClick={() => onChangeFormat(btn.format)}
          >
            <btn.Icon
              className={classNames(
                "user-avatar-manager__action-btn--icon",
                pictureFormatIsSelected(pictureFormat, btn.format),
              )}
            />
            <p
              className={classNames(
                "user-avatar-manager__action-btn--label",
                pictureFormatIsSelected(pictureFormat, btn.format),
              )}
            >
              {formatMessage(btn.label)}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserAvatarFormat
