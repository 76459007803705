import { FunctionComponent, ReactNode } from "react"

import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

import { accountsSelectors } from "features/Accounts"

import { ACCOUNT_INFORMATIONS_URL } from "router/RouterConstants"

interface WithAccountInformationsProps {
  children: ReactNode
  needAccountFilled?: boolean
}

const WithAccountInformations: FunctionComponent<
  WithAccountInformationsProps
> = ({ children, needAccountFilled }) => {
  const isAccountInfosFilled = useSelector(
    accountsSelectors.getIsInformationsFilled,
  )

  if (needAccountFilled && !isAccountInfosFilled)
    return <Navigate to={ACCOUNT_INFORMATIONS_URL} />

  return <>{children}</>
}

export default WithAccountInformations
