import { FunctionComponent, ReactNode } from "react"
import EmptyPageAlert, {
  EmptyPageAlertType,
} from "./EmptyPageAlert/EmptyPageAlert"
import EmptyPageCard, { EmptyPageCardType } from "./EmptyPageCard/EmptyPageCard"
import EmptyPageCards, {
  EmptyPageCardsType,
} from "./EmptyPageCards/EmptyPageCards"

interface EmptyPageCTAComposition {
  Alert: EmptyPageAlertType
  Card: EmptyPageCardType
  Cards: EmptyPageCardsType
}

interface EmptyPageCTAProps {
  children: ReactNode
}

type EmptyPageCTAType = FunctionComponent<EmptyPageCTAProps> &
  EmptyPageCTAComposition

const EmptyPageCTA: EmptyPageCTAType = ({ children }) => {
  return <div className="empty-page-cta">{children}</div>
}

EmptyPageCTA.Alert = EmptyPageAlert
EmptyPageCTA.Cards = EmptyPageCards
EmptyPageCTA.Card = EmptyPageCard

export default EmptyPageCTA
