export enum TrialActionTypes {
  INIT_TRIAL_DATA = "TRIAL/INIT_TRIAL_DATA",
  INIT_TRIAL_DATA_SUCCESS = "TRIAL/INIT_TRIAL_DATA",
  INIT_TRIAL_DATA_FAILURE = "TRIAL/INIT_TRIAL_DATA",
  SET_TRIAL_SIGNATURE = "TRIAL/SET_TRIAL_SIGNATURE",
  SET_TRIAL_BANNER = "TRIAL/SET_TRIAL_BANNER",
  SET_TRIAL_SIGNATURE_BANNER = "TRIAL/SET_TRIAL_SIGNATURE_BANNER",
  SET_TRIAL_STEP = "TRIAL/SET_TRIAL_STEP",
  SET_TRIAL_STEP_SUCCESS = "TRIAL/SET_TRIAL_STEP_SUCCESS",
  SET_TRIAL_STEP_FAILURE = "TRIAL/SET_TRIAL_STEP_FAILURE",
  TRIAL_SIGNATURE_MODIFIED = "TRIAL/SIGNATURE_MODIFIED",
  FETCH_TRIAL = "TRIAL/FETCH_TRIAL",
  FETCH_TRIAL_SUCCESS = "TRIAL/FETCH_TRIAL_SUCCESS",
  FETCH_TRIAL_FAILURE = "TRIAL/FETCH_TRIAL_FAILURE",
  SEND_TRIAL_SIGNATURE_PREVIEW = "TRIAL/SEND_TRIAL_SIGNATURE_PREVIEW",
  SEND_TRIAL_SIGNATURE_PREVIEW_SUCCESS = "TRIAL/SEND_TRIAL_SIGNATURE_PREVIEW_SUCCESS",
  SEND_TRIAL_SIGNATURE_PREVIEW_FAILURE = "TRIAL/SEND_TRIAL_SIGNATURE_PREVIEW_FAILURE",
}

export interface TrialSetSignature {
  type: TrialActionTypes.SET_TRIAL_SIGNATURE
}

export interface SetTrialStepAction {
  type: TrialActionTypes.SET_TRIAL_STEP
  step: number
}

export interface TrialSignatureCreatedSuccessAction {
  type: TrialActionTypes.SET_TRIAL_STEP_SUCCESS
  trialStep: number
}

export interface TrialSignatureFetchSuccessAction {
  type: TrialActionTypes.FETCH_TRIAL_SUCCESS
  trialStep: number
}

export interface SendTrialSignaturePreviewAction {
  type: TrialActionTypes.SEND_TRIAL_SIGNATURE_PREVIEW
  withBanner: boolean
}

export type TrialActions =
  | TrialSetSignature
  | TrialSignatureCreatedSuccessAction
  | TrialSignatureFetchSuccessAction
