import React, { FunctionComponent } from "react"

import { withStyles } from "@material-ui/core/styles"

import LinearProgress from "@material-ui/core/LinearProgress"
import CachedIcon from "@material-ui/icons/Cached"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"

import "./SignatureProgress.scss"
import useJobs from "features/Jobs/hooks/useJobs"

export interface SignatureProgressProps {
  value?: number
  total?: number
  isDone: boolean
  onClose?: () => void
}

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: "#E1E5FD",
  },
  bar: {
    borderRadius: 2,
    backgroundColor: "#3D5AFE",
  },
}))(LinearProgress)

const BorderLinearProgressDone = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: "#E1E5FD",
  },
  bar: {
    borderRadius: 2,
    backgroundColor: "#2AD3C2",
  },
}))(LinearProgress)

const SignatureProgress: FunctionComponent<SignatureProgressProps> = ({
  value,
  total,
  isDone,
  onClose,
}) => {

  return (
    <div className="signature-progress">
      <div className="signature-progress__component">
        <div className="signature-progress__signature">
          <div className="signature-progress__event">
            {isDone ? (
              <DoneIcon className="signature-progress__event-icon done" />
            ) : (
              <CachedIcon className="signature-progress__event-icon rotate" />
            )}
            <p className="signature-progress__event-label">
              {isDone
                ? "Les signatures sont prêtes"
                : "Signatures en préparation"}
            </p>
          </div>
          <div className="signature-progress__close">
            {isDone && (
              <CloseIcon
                className="signature-progress__close-icon"
                onClick={onClose}
              />
            )}
          </div>
        </div>

        {!isDone && value >= 0 && (
          <div className="signature-progress__monitoring">
            <div className="signature-progress__monitoring-bar">
              {isDone ? (
                <BorderLinearProgressDone
                  variant="determinate"
                  value={(value / total) * 100}
                />
              ) : (
                <BorderLinearProgress
                  variant="determinate"
                  value={(value / total) * 100}
                />
              )}
            </div>
            <p className="signature-progress__monitoring-label">{`${value}%`}</p>
          </div>
        )}
      </div>
      <div className="signature-progress__separator" />
    </div>
  )
}

export default SignatureProgress
