import {
  FunctionComponent,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
} from "react"

import classNames from "classnames"

interface CheckboxProps {
  name: string
  children?: ReactNode
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  color?: "is-primary" | "is-white"
}

type CheckBoxType = CheckboxProps & InputHTMLAttributes<HTMLInputElement>

const Checkbox: FunctionComponent<CheckBoxType> = ({
  children,
  name,
  labelProps,
  color = "is-primary",
  ...others
}) => {
  return (
    <div className={classNames("checkbox", color)}>
      <input
        className="checkbox__input"
        type="checkbox"
        name={name}
        {...others}
      />
      {children && (
        <label className="checkbox__label" htmlFor={name} {...labelProps}>
          {children}
        </label>
      )}
    </div>
  )
}

export default Checkbox
