import { GlobalStates } from "store"
import Tour from "entities/Tour"
import { TourGuideState } from "store/tourguide.state"
import { offersSelectors } from "features/Offers"

/**
 * TourGuide SELECTORS
 */

const getTourGuide = (state: GlobalStates): TourGuideState => {
  if (state.tourguide != null) return state.tourguide
}

const getTourGuideAllTours = (state: GlobalStates) => {
  return getTourGuide(state).tours
}

const getAllToursInArray = (state: GlobalStates): Tour[] => {
  const tours = getTourGuide(state).tours
  const toursInArray = []
  for (const i in tours) {
    toursInArray.push(tours[i])
  }
  return toursInArray
}

const getTourGuideStatus = (state: GlobalStates): boolean => {
  const tourGuide = getTourGuide(state)
  return tourGuide.display
}

const isFirstVisit = (state: GlobalStates): boolean => {
  const tourGuide = getTourGuide(state)
  return tourGuide.firstVisit
}

const showTourPilot = (state: GlobalStates): boolean => {
  const isTrial = offersSelectors.isTrial(state)
  if (isTrial) return false

  const tourGuide = getTourGuide(state)
  const currentTour = getCurrentTour(state)
  const offerIsValid = offersSelectors.checkCurrentTenantOfferValidity(state)

  return tourGuide.display && offerIsValid && currentTour === null
}

const getCurrentTour = (state: GlobalStates) => {
  return getTourGuide(state).currentTour
}

const displayNavigation = (state: GlobalStates): boolean => {
  const tourGuide = getTourGuide(state)
  return tourGuide.display && tourGuide.currentTour == null
}

export const selectors = {
  getTourGuide,
  getTourGuideStatus,
  getTourGuideAllTours,
  getAllToursInArray,
  getCurrentTour,
  displayNavigation,
  showTourPilot,
  isFirstVisit,
}
