import { FunctionComponent } from "react"

import OfferCard from "./components/OfferCard"

import {
  BASIC_OFFER_NAME,
  PREMIUM_OFFER_NAME,
} from "features/Offers/OffersConstants"

import { CatalogOffer } from "features/Offers/OffersModels"

interface OffersListProps {
  offers: CatalogOffer[]
  nbUsers: number
  selectedOfferId?: number
  updateSelectedOffer: (offer: CatalogOffer) => void
}

const OffersList: FunctionComponent<OffersListProps> = ({
  offers,
  nbUsers,
  selectedOfferId,
  updateSelectedOffer,
}) => {
  const basicOffer = offers.find((offer) => offer.name === BASIC_OFFER_NAME)

  const premiumOffer = offers.find((offer) => offer.name === PREMIUM_OFFER_NAME)

  return (
    <div className="offers-list">
      <OfferCard
        offer={basicOffer}
        nbUsers={nbUsers}
        onSelect={updateSelectedOffer}
        isActive={selectedOfferId && selectedOfferId === basicOffer?.id}
      />
      <OfferCard
        offer={premiumOffer}
        nbUsers={nbUsers}
        onSelect={updateSelectedOffer}
        isActive={selectedOfferId && selectedOfferId === premiumOffer?.id}
      />
    </div>
  )
}

export default OffersList
