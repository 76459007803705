import { GlobalStates } from "store/globalState"
import Feature from "./FeaturesModels"

import { selectAllFeatures, selectFeatureByName } from "./FeaturesReducers"

export const getAllFeatures = selectAllFeatures

export const getFeatureByName =
  (featureName: string) =>
  (state: GlobalStates): Feature =>
    selectFeatureByName(state, featureName)

export const getFeatureLoadingStatus = (state: GlobalStates) =>
  state.features.allFeatures.loadingStatus

export const getBackgroundTasks = (state: GlobalStates) =>
  state.features.backgroundTasks
