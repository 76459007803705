import { createAction } from "@reduxjs/toolkit"

export const deleteScenarioAction = createAction<number>("scenario/delete")

export const associateScenarioUsersAction = createAction(
  "scenario/associateUsers",
  function prepare(scenarioId: number, userIds: number[]) {
    return {
      payload: {
        scenarioId,
        userIds,
      },
    }
  },
)

export const associateScenarioGroupsAction = createAction(
  "scenario/associateGroups",
  function prepare(scenarioId: number, groupIds: number[]) {
    return {
      payload: {
        scenarioId,
        groupIds,
      },
    }
  },
)

export const associateScenarioSignaturesAction = createAction(
  "scenario/associateSignatures",
  function prepare(scenarioId: number, signatureIds: number[]) {
    return {
      payload: {
        scenarioId,
        signatureIds,
      },
    }
  },
)

export const loadScenarioAffectationsAction = createAction(
  "scenario/loadScenarionAffectations",
  function prepare(scenarioId: number) {
    return {
      payload: scenarioId,
    }
  },
)

export const duplicateScenarioAction = createAction(
  "scenario/duplicateScenario",
  function prepare(scenarioId: number) {
    return {
      payload: scenarioId,
    }
  },
)

export const fetchScenariosCustomQueryAction = createAction(
  "scenario/fetchScenariosCustomQueryAction",
  function prepare(requestId: string, customQuery) {
    return {
      payload: { requestId, customQuery },
    }
  },
)

export const activateScenarioSuccessAction = createAction(
  "scenario/activateScenarioSuccess",
)
