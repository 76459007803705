import { FunctionComponent } from "react"
import { defineMessages, useIntl } from "react-intl"

interface ScenarioCardNameProps {
  name?: string
}

const messages = defineMessages({
  untitled: {
    id: "ScenarioCard.Untitled",
    defaultMessage: "Sans titre",
  },
})

export type ScenarioCardNameType = FunctionComponent<ScenarioCardNameProps>

const ScenarioCardName: ScenarioCardNameType = ({ name }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="scenario-card__name">
      {name || formatMessage(messages.untitled)}
    </div>
  )
}

export default ScenarioCardName
