import { FunctionComponent } from "react"

import SellsyToggle from "./components/SellsyToggle"
import SellsyParameters from "./components/SellsyParameters"

import Feature from "features/Features/FeaturesModels"
import useSellsyFeature, {
  SellsyFormState,
} from "features/Features/hooks/useSellsyFeature"

interface SellsyFeatureProps {
  featureSellsy: Feature
  launchJob: (feature: Feature) => void
  updateFeature: (feature: Feature) => void
}

function getUpdatedPropertiesFromForm(sellsyFormState: SellsyFormState) {
  return Object.values(sellsyFormState).map((formElement) => ({
    property: formElement.name,
    value: formElement.value,
  }))
}

const SellsyFeature: FunctionComponent<SellsyFeatureProps> = ({
  featureSellsy,
  launchJob,
  updateFeature,
}) => {
  const {
    sellsyFormState,
    changeClientId,
    changeClientSecret,
    displaySellsyClientIdError,
    displaySellsyClientSecretError,
    resetInputsValues,
  } = useSellsyFeature(featureSellsy)

  const handleToggle = (isActive) => {
    if (!isActive) {
      updateFeature({ ...featureSellsy, activated: false })
      resetInputsValues()
      return
    }
    const sellsyClientIdValueIsUnset =
      sellsyFormState.sellsyClientId.value.length === 0
    const sellsyClientSecretIsUnset =
      sellsyFormState.sellsyClientSecret.value.length === 0

    if (sellsyClientIdValueIsUnset || sellsyClientSecretIsUnset) {
      sellsyClientIdValueIsUnset && displaySellsyClientIdError(true)
      sellsyClientSecretIsUnset && displaySellsyClientSecretError(true)
      return
    }

    displaySellsyClientIdError(false)
    displaySellsyClientSecretError(false)

    updateFeature({
      ...featureSellsy,
      activated: isActive,
      properties: getUpdatedPropertiesFromForm(sellsyFormState),
    })
  }

  return (
    <div className="sellsy-feature">
      <SellsyToggle
        featureSellsy={featureSellsy}
        launchJob={launchJob}
        handleToggle={handleToggle}
      />
      <SellsyParameters
        featureSellsy={featureSellsy}
        sellsyFormState={sellsyFormState}
        onChangeClientId={changeClientId}
        onChangeClientSecret={changeClientSecret}
      />
    </div>
  )
}

export default SellsyFeature
