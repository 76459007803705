import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import Tile from "features/Accounts/components/HomePageSingleTile"

import UsersTileDatasSide from "./UsersTileDatasSide"

import { LoadingStatus } from "core/CoreModels"

import { USERS_URL } from "router/RouterConstants"

interface UsersTileDatasProps {
  loadingStatus: LoadingStatus
  latestCount: number
  usersCount: number
  groupsCount: number
}

const messages = defineMessages({
  emptyTitle: {
    id: "HomePage.UsersTileEmptyStateTitle",
    defaultMessage: "Ajouter vos premiers utilisateurs",
  },
  usersLabel: {
    id: "HomePage.UsersTileUsersLabel",
    defaultMessage: "Utilisateurs",
  },
  usersInfos: {
    id: "HomePage.UsersTileUsersInfos",
    defaultMessage: "actifs avec licence",
  },
  groupsLabel: {
    id: "HomePage.UsersTileGroupsLabel",
    defaultMessage: "Groupes",
  },
  groupsInfos: {
    id: "HomePage.UsersTileGroupsInfos",
    defaultMessage: "actifs",
  },
})

const UsersTileDatas: FunctionComponent<UsersTileDatasProps> = ({
  loadingStatus,
  latestCount,
  usersCount,
  groupsCount,
}) => {
  const { locale, formatMessage } = useIntl()

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <div className="users-tile__datas--container">
        <UsersTileDatasSide
          icon="users"
          label={formatMessage(messages.usersLabel)}
          infos={formatMessage(messages.usersInfos)}
        >
          <div className="users-tile__skeleton-loading--container" />
        </UsersTileDatasSide>
        <UsersTileDatasSide
          icon="groups"
          label={formatMessage(messages.groupsLabel)}
          infos={formatMessage(messages.groupsInfos)}
        >
          <div className="users-tile__skeleton-loading--container" />
        </UsersTileDatasSide>
      </div>
    )

  if (loadingStatus === LoadingStatus.LOADED && latestCount === 0)
    return (
      <Tile.AddButton to={`${USERS_URL}/-1`} isColumn>
        {formatMessage(messages.emptyTitle)}
      </Tile.AddButton>
    )

  return (
    <div className="users-tile__datas--container">
      <UsersTileDatasSide
        icon="users"
        label={formatMessage(messages.usersLabel)}
        infos={formatMessage(messages.usersInfos)}
      >
        <p className="users-tile__datas--count">
          {new Intl.NumberFormat(locale).format(usersCount)}
        </p>
      </UsersTileDatasSide>
      <UsersTileDatasSide
        icon="groups"
        label={formatMessage(messages.groupsLabel)}
        infos={formatMessage(messages.groupsInfos)}
      >
        <p className="users-tile__datas--count">
          {new Intl.NumberFormat(locale).format(groupsCount)}
        </p>
      </UsersTileDatasSide>
    </div>
  )
}

export default UsersTileDatas
