import { buffers, eventChannel, EventChannel } from "redux-saga"
import {
  apply,
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects"

import { PayloadAction } from "@reduxjs/toolkit"

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr"

import { selectors as accountsSelectors } from "features/Accounts/AccountsSelectors"
import { selectors as jobsSelectors } from "./JobSelectors"

import config from "config/config"

import { addJob, updateJob, removeJob } from "./JobsReducer"
import { AvailableJobs, JobsActionsTypes } from "./JobsConstants"
import { JobData, JobState } from "./JobsModels"
import { jobFinishedAction } from "./JobsActions"

import { fetchAllUsers } from "features/Users/UsersReducers"

import { withSagaErrorHandler } from "features/Errors"
import { ErrorTarget } from "features/Errors/ErrorsModel"
import { fetchAllGroups } from "features/Groups/GroupsReducer"

function* updateJobData(newJobData: JobData, jobData: JobData) {
  if (jobData.state === JobState.Succeeded) {
    newJobData.open = false
  }
  if (newJobData.state !== jobData.state) {
    if (newJobData.state === JobState.Succeeded) {
      yield put(updateJob(newJobData))
      yield delay(3000)
      yield put(removeJob(newJobData))
      yield put(jobFinishedAction(newJobData))
    } else {
      yield put(updateJob(newJobData))
    }
  }
  // CompilationJob - update percent
  if (newJobData.percent != null && newJobData.percent != jobData.percent)
    yield put(updateJob(newJobData))
}

function* onJobChange(job) {
  job.open = true
  const jobsQueue = yield select(jobsSelectors.getJobsQueue)
  const existing = jobsQueue.find((existing) => existing.jobId === job.jobId)

  if (existing) {
    yield updateJobData(job, existing)
  } else {
    yield put(addJob(job))
  }
}

function createSocket(rootUrl): HubConnection {
  const connection = new HubConnectionBuilder()
    .withUrl(`${rootUrl}/api/jobprogress`, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .configureLogging(LogLevel.Debug)
    .build()
  return connection
}

function subscribeToUpdates(connection: HubConnection): EventChannel<JobData> {
  return eventChannel((emit) => {
    function overwriteJobStateHandler(job: JobData) {
      emit(job)
    }

    function unsubscribe() {
      connection.off("progress", overwriteJobStateHandler)
    }

    connection.on("progress", overwriteJobStateHandler)

    return unsubscribe
  }, buffers.expanding())
}

export function* connectToHub(): IterableIterator<unknown> {
  const tenantId = yield select(accountsSelectors.getTenantId)
  const socket = yield call(createSocket, config.webJobsApiUrl)
  const socketChannel = yield call(subscribeToUpdates, socket)

  try {
    yield apply(socket, HubConnection.prototype.start, [])
    yield apply(socket, HubConnection.prototype.invoke, ["Progress", tenantId])
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  try {
    while (true) {
      const update = yield take(socketChannel)
      yield onJobChange(update)
    }
  } finally {
    yield apply(socket, HubConnection.prototype.stop, [])
  }
}

export function* reloadUsers() {
  yield put(fetchAllUsers())
  yield put(fetchAllGroups())
}

export function* relatedJobFinishedActionOrchestrator(
  action: PayloadAction<JobData>,
) {
  const { jobName } = action.payload

  switch (jobName) {
    case AvailableJobs.SYNC_USERS:
      yield reloadUsers()
      break
    default:
      break
  }
}

export const jobsWatchers = [
  takeEvery(
    JobsActionsTypes.CONNECT_TO_HUB,
    withSagaErrorHandler(connectToHub, null, null, ErrorTarget.Backend),
  ),
  takeEvery(
    jobFinishedAction.type,
    withSagaErrorHandler(relatedJobFinishedActionOrchestrator),
  ),
]
