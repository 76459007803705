import { FunctionComponent } from "react"

import { WeightingIndicator } from "components/Weighting"

interface ScenarioCardWeightProps {
  weighting: number
}

export type ScenarioCardWeightType = FunctionComponent<ScenarioCardWeightProps>

const ScenarioCardWeight: ScenarioCardWeightType = ({ weighting }) => {
  if (!weighting) return null
  return <WeightingIndicator weighting={weighting} />
}

export default ScenarioCardWeight
