import { useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"

import {
  CAMPAIGNS_URL,
  SIGNATURES_URL,
  TRACKING_URL,
  USERS_URL,
} from "router/RouterConstants"

import { AdminAccount, AdminAccountDto } from "features/Accounts/AccountsModels"
import { RolesNeeded } from "router/routes"

export const useCurrentAccountAccess = () => {
  const currentAccount = useSelector(accountsSelectors.getCurrentAccount)

  const accountProps: AdminAccountDto = {
    company: currentAccount?.Company,
    id: currentAccount?.Id,
    roles: currentAccount?.Roles,
    userName: currentAccount?.UserName,
  }
  const account = new AdminAccount(accountProps)

  const hasAccessTo = (checkingRoles) => account.hasAccessTo(checkingRoles)

  const hasAccessSignatures = hasAccessTo(RolesNeeded.SIGNATURES_PAGE).isActive
  const hasAccessCampaigns = hasAccessTo(RolesNeeded.CAMPAIGNS_PAGE).isActive
  const hasAccessTracking = hasAccessTo(RolesNeeded.TRACKING_PAGE).isActive
  const hasAccessUsers = hasAccessTo(RolesNeeded.USERS_PAGE).isActive

  const homePageAccess = [
    { access: hasAccessSignatures, redirectUrl: SIGNATURES_URL },
    { access: hasAccessCampaigns, redirectUrl: CAMPAIGNS_URL },
    { access: hasAccessTracking, redirectUrl: TRACKING_URL },
    { access: hasAccessUsers, redirectUrl: USERS_URL },
  ]

  const hasAccessToHomePage =
    homePageAccess.filter((activeRole) => activeRole.access).length > 1

  const hasNoGlobalAccess =
    homePageAccess.filter((activeRole) => activeRole.access).length === 0

  const homePageRedirectUrl = homePageAccess.find(
    (activeRole) => activeRole.access,
  )?.redirectUrl

  return {
    hasAccessTo,
    hasAccessToHomePage,
    homePageRedirectUrl,
    hasNoGlobalAccess,
  }
}
