import React from "react"

interface Props {
  children: React.ReactNode
  type: "checkbox" | "radio"
  checked: boolean
  onClick()
  disabled?: boolean
  actionSide?: "right" | "left"
}

const CheckboxCard: React.FC<Props> = (props) => {
  const { children, type, checked, disabled, actionSide, onClick } = props

  return (
    <div
      onClick={() => onClick()}
      className={`checkbox-card ${checked ? "checkbox-card--checked" : ""} ${
        actionSide === "left" ? "checkbox-card--left" : ""
      } ${disabled ? "checkbox-card--disabled" : ""}`}
      data-cy="checkbox-card"
    >
      <div className="checkbox-card__label">{children}</div>
      <div className="checkbox-card__action">
        {type === "checkbox" && (
          <i className="material-icons">
            {checked ? "check_box" : "check_box_outline_blank"}
          </i>
        )}
        {type === "radio" && (
          <i className="material-icons">
            {checked ? "check_circle" : "radio_button_unchecked"}
          </i>
        )}
      </div>
    </div>
  )
}

export default CheckboxCard
