import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import Tenant from "entities/Tenant"
import {
  Account,
  CampaignsTileCount,
  CampaignsTileDatas,
  OnBoardingItems,
  SignaturesTileCounts,
  SignaturesTileDatas,
  TrackingTileCount,
  TrackingTileDatas,
  UsersTileCount,
  UsersTileDatas,
} from "features/Accounts/AccountsModels"
import TenantProperties from "entities/TenantProperties"
import Version from "entities/Version"
import TenantProperty from "entities/TenantProperty"
import { RootState } from "store/configureStore"

import { DEFAULT_ON_BOARDING_ITEMS } from "./AccountsConstants"

import { AccountInformations, AdminAccount, OnBoarding } from "./AccountsModels"
import { LoadingStatus, SavingStatus, ViewManagement } from "core/CoreModels"

interface AdminAccountsEntities {
  [id: string]: AdminAccount
}

export interface AccountsState {
  allAdminAccounts: {
    entities: AdminAccountsEntities
    ids: EntityId[]
    loadingStatus: LoadingStatus
    savingStatus: SavingStatus
    updatingStatus: SavingStatus
    newAdminAccountErrorMessage: string | null
  }
  currentAccount: Account
  currentTenant: Tenant
  createAccountErrors: [{ code: string; description: string }]
  resetPassword: {
    errors: [{ code: string; description: string }] | null
    redirectUrl: string | null
  }
  accountInformations: {
    savingStatus: SavingStatus
  }
  loginErrors: string | null
  isAuthenticated: boolean
  isJustCreated: boolean
  isNotReadyYet: boolean
  captcha: string | null
  loading: boolean
  windowsVersions: Version[]
  macLink: string
  powershellLink: string
  propertiesLoadingStatus: LoadingStatus
  properties: TenantProperties
  domains: string[]
  onBoarding: OnBoarding
  homePage: ViewManagement & { signatures: SignaturesTileDatas } & {
    campaigns: CampaignsTileDatas
  } & { tracking: TrackingTileDatas } & { users: UsersTileDatas }
}

const adminAccountsAdapter = createEntityAdapter<AdminAccount>({
  selectId: (adminAccount) => adminAccount.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id),
})

export const {
  selectAll: selectAllAdminAccounts,
  selectById: selectAdminAccountById,
} = adminAccountsAdapter.getSelectors<RootState>(
  (state) => state.accounts.allAdminAccounts,
)

const accountsInitialState: AccountsState = {
  allAdminAccounts: adminAccountsAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
    savingStatus: SavingStatus.IDLE,
    updatingStatus: SavingStatus.IDLE,
    newAdminAccountErrorMessage: null,
  }),
  currentAccount: null,
  currentTenant: null,
  isAuthenticated: false,
  isJustCreated: false,
  isNotReadyYet: null,
  createAccountErrors: null,
  resetPassword: {
    errors: null,
    redirectUrl: null,
  },
  accountInformations: {
    savingStatus: SavingStatus.IDLE,
  },
  loginErrors: null,
  captcha: null,
  loading: false,
  windowsVersions: [],
  macLink: null,
  powershellLink: null,
  propertiesLoadingStatus: LoadingStatus.NOT_STARTED,
  properties: {
    googleanalytics: null,
    business: null,
    companyname: null,
    employeesnumber: null,
    customMsiLink: null,
    messaging: null,
    campaignUnlockedProperty: null,
    trackingJobCreated: null,
    onBoarding: null,
    syncgsuitedomain: null,
    syncgsuiteadminmail: null,
    gsuitejson: null,
    owaaccounts: null,
    owaendpointurl: null,
  },
  domains: [],
  onBoarding: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    items: DEFAULT_ON_BOARDING_ITEMS,
  },
  homePage: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    signatures: {
      loadingStatus: LoadingStatus.NOT_STARTED,
      count: {
        all: 0,
        active: 0,
        newMail: 0,
        inResponse: 0,
      },
    },
    campaigns: {
      loadingStatus: LoadingStatus.NOT_STARTED,
      count: { all: 0, campaigns: 0, scenarios: 0 },
    },
    tracking: {
      loadingStatus: LoadingStatus.NOT_STARTED,
      count: { delivered: 0, signaturesClicked: 0, bannersClicked: 0 },
    },
    users: {
      loadingStatus: LoadingStatus.NOT_STARTED,
      count: {
        users: 0,
        groups: 0,
        licences: 0,
      },
      latest: [],
    },
  },
}

const accountsReducer = createSlice({
  name: "accounts",
  initialState: accountsInitialState,
  reducers: {
    fetchAdminAccounts(state) {
      state.allAdminAccounts.loadingStatus = LoadingStatus.PENDING
    },
    fetchAdminAccountsSuccess(state, action: PayloadAction<AdminAccount[]>) {
      adminAccountsAdapter.setAll(state.allAdminAccounts, action.payload)
      state.allAdminAccounts.loadingStatus = LoadingStatus.LOADED
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createAdminAccount(state, _action: PayloadAction<AdminAccount>) {
      state.allAdminAccounts.savingStatus = SavingStatus.SAVING
    },
    createSubsidiaryAdminAccount(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        adminAccount: AdminAccount
        subsidiariesIds: number[]
      }>,
    ) {
      state.allAdminAccounts.savingStatus = SavingStatus.SAVING
    },
    createAdminAccountSuccess(state, action: PayloadAction<AdminAccount>) {
      state.allAdminAccounts.savingStatus = SavingStatus.SUCCESS
      state.allAdminAccounts.newAdminAccountErrorMessage = null
      adminAccountsAdapter.addOne(state.allAdminAccounts, action.payload)
    },
    createAdminAccountFailure(state, action: PayloadAction<string>) {
      state.allAdminAccounts.savingStatus = SavingStatus.ERROR
      state.allAdminAccounts.newAdminAccountErrorMessage = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateAdminAccountRoles(state, _action: PayloadAction<AdminAccount>) {
      state.allAdminAccounts.updatingStatus = SavingStatus.SAVING
    },
    updateAdminAccountRolesSuccess(state, action: PayloadAction<AdminAccount>) {
      adminAccountsAdapter.upsertOne(state.allAdminAccounts, action.payload)
      state.allAdminAccounts.updatingStatus = SavingStatus.SUCCESS
    },
    deleteAdminAccountSuccess(state, action: PayloadAction<string>) {
      adminAccountsAdapter.removeOne(state.allAdminAccounts, action.payload)
    },
    fillCaptcha(state, action: PayloadAction<string>) {
      state.captcha = action.payload
    },
    resetCaptcha(state) {
      state.captcha = null
    },
    resetPasswordSuccess(state, action: PayloadAction<string>) {
      state.resetPassword.redirectUrl = action.payload
    },
    resetPasswordFailure(
      state,
      action: PayloadAction<[{ code: string; description: string }]>,
    ) {
      state.resetPassword.errors = action.payload
    },
    fetchAccountLinksSuccess(
      state,
      action: PayloadAction<{
        macLink: string
        powershellLink: string
      }>,
    ) {
      state.macLink = action.payload.macLink
      state.powershellLink = action.payload.powershellLink
    },
    fettchAccountLinksFailure(state) {
      state.powershellLink = null
    },
    fetchWindowsVersionsSuccess(state, action: PayloadAction<Version[]>) {
      state.windowsVersions = action.payload
    },
    fetchGWorkspaceDomainsSuccess(state, action: PayloadAction<string[]>) {
      state.domains = action.payload
    },
    fetchTenantProperties(state) {
      state.propertiesLoadingStatus = LoadingStatus.PENDING
    },
    fetchTenantPropertiesSuccess(
      state,
      action: PayloadAction<TenantProperties>,
    ) {
      state.propertiesLoadingStatus = LoadingStatus.LOADED
      state.properties = action.payload
    },
    updateTenantPropertiesSuccess(
      state,
      action: PayloadAction<TenantProperty>,
    ) {
      state.properties[action.payload.Property] = action.payload
    },
    fetchTenantInfosSuccess(state, action: PayloadAction<Tenant>) {
      state.currentTenant = action.payload
    },
    updateTenantFeatureSuccess(
      state,
      action: PayloadAction<{ featureName: string; activated: boolean }>,
    ) {
      state.currentTenant[action.payload.featureName] = action.payload.activated
    },
    fetchCurrentAccountSuccess(state, action: PayloadAction<Account>) {
      state.currentAccount = action.payload
      state.isAuthenticated = true
      state.loginErrors = null
      state.loading = false
    },
    fetchCurrentAccountPending(state) {
      state.loading = false
      state.isAuthenticated = true
    },
    fetchCurrentAccountReady(state) {
      state.loading = false
      state.isAuthenticated = true
    },

    createCurrentAccount(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ email: string; password: string }>,
    ) {
      state.loading = true
    },
    createCurrentAccountSuccess(state) {
      state.isJustCreated = true
      state.isAuthenticated = false
      state.loading = false
    },
    createCurrentAccountFailure(state) {
      state.loading = false
    },
    updateAccountInformations(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<AccountInformations>,
    ) {
      state.accountInformations.savingStatus = SavingStatus.SAVING
    },
    updateAccountInformationsSuccess(state, action: PayloadAction<Account>) {
      state.accountInformations.savingStatus = SavingStatus.IDLE
      state.currentAccount = action.payload
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.loginErrors = action.payload
      state.loading = false
      state.isAuthenticated = false
    },
    logoutSuccess(state) {
      state.isAuthenticated = false
      state.currentAccount = null
      state.loginErrors =
        "Vous avez été correctement déconnecté, nous vous conseillons de fermer votre navigateur"
      state.loading = false
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setOnBoarding(state) {
      state.onBoarding.loadingStatus = LoadingStatus.PENDING
    },
    setOnBoardingData(state, action: PayloadAction<OnBoardingItems>) {
      state.onBoarding.items = action.payload
    },
    finishOnBoarding(state) {
      state.onBoarding.loadingStatus = LoadingStatus.LOADED
    },
    homePageStartup(state) {
      state.homePage.loadingStatus = LoadingStatus.PENDING
    },
    homePageStartupSuccess(state) {
      state.homePage.loadingStatus = LoadingStatus.LOADED
    },
    fetchSignaturesTileStart(state) {
      state.homePage.signatures.loadingStatus = LoadingStatus.PENDING
    },
    fetchSignaturesTileSuccess(
      state,
      action: PayloadAction<SignaturesTileCounts>,
    ) {
      state.homePage.signatures.loadingStatus = LoadingStatus.LOADED
      state.homePage.signatures.count = action.payload
    },
    fetchCampaignsTileStart(state) {
      state.homePage.campaigns.loadingStatus = LoadingStatus.PENDING
    },
    fetchCampaignsTileSuccess(
      state,
      action: PayloadAction<CampaignsTileCount>,
    ) {
      state.homePage.campaigns.loadingStatus = LoadingStatus.LOADED
      state.homePage.campaigns.count = action.payload
    },
    fetchTrackingTileStart(state) {
      state.homePage.tracking.loadingStatus = LoadingStatus.PENDING
    },
    fetchTrackingTileSuccess(state, action: PayloadAction<TrackingTileCount>) {
      state.homePage.tracking.loadingStatus = LoadingStatus.LOADED
      state.homePage.tracking.count = action.payload
    },
    fetchUsersTileStart(state) {
      state.homePage.users.loadingStatus = LoadingStatus.PENDING
    },
    fetchUsersTileSuccess(
      state,
      action: PayloadAction<{ count: UsersTileCount; latest: string[] }>,
    ) {
      state.homePage.users.loadingStatus = LoadingStatus.LOADED
      state.homePage.users.count = action.payload.count
      state.homePage.users.latest = action.payload.latest
    },
  },
})

export const {
  fetchAdminAccounts,
  fetchAdminAccountsSuccess,
  createAdminAccount,
  createAdminAccountSuccess,
  createAdminAccountFailure,
  updateAdminAccountRoles,
  updateAdminAccountRolesSuccess,
  deleteAdminAccountSuccess,
  fillCaptcha,
  resetCaptcha,
  resetPasswordSuccess,
  resetPasswordFailure,
  fetchAccountLinksSuccess,
  fettchAccountLinksFailure,
  fetchWindowsVersionsSuccess,
  fetchGWorkspaceDomainsSuccess,
  fetchTenantProperties,
  fetchTenantPropertiesSuccess,
  updateTenantPropertiesSuccess,
  fetchTenantInfosSuccess,
  updateTenantFeatureSuccess,
  fetchCurrentAccountSuccess,
  fetchCurrentAccountPending,
  fetchCurrentAccountReady,
  createCurrentAccount,
  createCurrentAccountSuccess,
  createCurrentAccountFailure,
  loginFailure,
  logoutSuccess,
  loading,
  updateAccountInformations,
  updateAccountInformationsSuccess,
  setOnBoarding,
  setOnBoardingData,
  finishOnBoarding,
  homePageStartup,
  homePageStartupSuccess,
  fetchSignaturesTileStart,
  fetchSignaturesTileSuccess,
  fetchCampaignsTileStart,
  fetchCampaignsTileSuccess,
  fetchTrackingTileStart,
  fetchTrackingTileSuccess,
  fetchUsersTileStart,
  fetchUsersTileSuccess,
  createSubsidiaryAdminAccount,
} = accountsReducer.actions

export default accountsReducer.reducer
