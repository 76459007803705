/**
 * Class singleton pour n'initialiser l'api canva qu'une seule fois
 * Dépendance : appelée directement dans le Config.ts lorsqu'on a récupéré l'API Key Canva
 */
interface CanvaApi {
  createDesign(any)
  editDesign(any)
}
class Canva {
  Api: CanvaApi // api initialized
  IsInitialized = false

  SetCanvaApiKey(apiKey: string) {
    if (this.IsInitialized === true) return
    ;(async function () {
      const api = await (window as any).Canva.DesignButton.initialize({
        apiKey,
      })
      return api
    })().then((res) => {
      this.Api = res
    })
  }
}

// Export de l'instance par défaut pour ne travailler qu'avec une seule instance
// Singleton Pattern en ES6
export default new Canva()
