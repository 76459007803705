import { FunctionComponent, useEffect, useState } from "react"

import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { useStripe, useElements } from "@stripe/react-stripe-js"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"

import CheckoutCB from "./components/CheckoutCB"
import CheckoutSepa from "./components/CheckoutSepa"
import CheckoutLogos from "./components/CheckoutLogos"

interface Props {
  onChange?: (key: string, value: string | boolean) => void
}

const CheckoutForm: FunctionComponent<Props> = ({ onChange }) => {
  const stripe = useStripe()
  const elements = useElements()
  const {
    intentSecret,
    saveStripePayment,
    paymentAlreadyValidated,
    enrollmentDatas,
    setEnrollmentDatas,
  } = useEnrollmentData()
  const [paymentMethod] = useState("card")
  const [holder, setHolder] = useState("")
  const [email, setEmail] = useState("")

  const onHolderChange = (key: string, value: string | boolean) => {
    if (typeof value === "string") {
      if (key == "holder" || key == "holder_sepa") setHolder(value)
      if (key == "email") setEmail(value)
    }
    onChange && onChange(key, value)
  }

  useEffect(() => {
    if (intentSecret != null && !paymentAlreadyValidated && intentSecret !== "")
      savePayment(intentSecret)
  }, [intentSecret, paymentAlreadyValidated])

  const savePayment = (setupIntentSecret: string) => {
    let confirmSetup
    // Confirmer le formulaire (élément(s) Stripe, infos titulaire)
    switch (enrollmentDatas.paymentMethod) {
      case "card": {
        const cardNum = elements.getElement("cardNumber")
        confirmSetup = stripe.confirmCardPayment(setupIntentSecret, {
          payment_method: {
            card: cardNum,
            billing_details: {
              name: holder,
            },
          },
        })
        break
      }
      case "sepa_debit": {
        const iban = elements.getElement("iban")
        confirmSetup = stripe.confirmSepaDebitPayment(setupIntentSecret, {
          payment_method: {
            sepa_debit: iban,
            billing_details: {
              name: holder,
              email: email,
            },
          },
        })
        break
      }
      default:
        break
    }
    Promise.all([confirmSetup]).then(function (result) {
      // Enregistrement du moyen de paiement et envoi du paiement s'il y en a un
      saveStripePayment(
        null,
        paymentMethod,
        holder,
        result[0],
        result[0].error,
        email,
      )
    })
  }

  return (
    <form className="bank-details">
      <div className="enrollment-tabs__header--container">
        <div
          onClick={() =>
            setEnrollmentDatas({ paymentMethod: "card", checkout: {} })
          }
          className={classNames(
            "enrollment-tabs__header--item",
            enrollmentDatas.paymentMethod === "card" && "is-selected",
          )}
        >
          <p className="enrollment-tabs__header--label">
            <FormattedMessage
              id="BillingForm.TabCard"
              defaultMessage="Carte bancaire"
            />
          </p>
        </div>
        <div
          onClick={() =>
            setEnrollmentDatas({ paymentMethod: "sepa_debit", checkout: {} })
          }
          className={classNames(
            "enrollment-tabs__header--item",
            enrollmentDatas.paymentMethod === "sepa_debit" && "is-selected",
          )}
        >
          <p className="enrollment-tabs__header--label">
            <FormattedMessage
              id="BillingForm.TabSepa"
              defaultMessage="Prélèvement bancaire"
            />
          </p>
        </div>
      </div>
      <div className="enrollment-tabs__content--container">
        {enrollmentDatas.paymentMethod === "card" && (
          <div style={{ flex: 1 }}>
            <CheckoutLogos />
            <CheckoutCB onChange={onHolderChange} />
          </div>
        )}
        {enrollmentDatas.paymentMethod === "sepa_debit" && (
          <CheckoutSepa onChange={onHolderChange} />
        )}
      </div>
    </form>
  )
}

export default CheckoutForm
