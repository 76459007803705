import React, { ReactNode } from "react"
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles"

import "./MenuButton.scss"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"

const BmmTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#3D5AFE",
    border: "solid 1px rgba(61,90,254,.15)",
    borderRadius: "6px",
    fontSize: 11,
    fontWeight: 500,
    marginTop: "-20px",
    marginLeft: "40px",
  },
}))(Tooltip)

interface Props {
  label?: string
  choices: {
    label: string
    value?: string
    icon?: ReactNode
    onClick?(): void
    disabled?: boolean
    tooltip?: string
  }[]
  icon?: ReactNode
  fab?: boolean
  value?: string
  disableRipple?: boolean
  onChange?: (string) => void
}

const MenuButton: React.FC<Props> = (props) => {
  const [menu, setMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenu(event.currentTarget)
  const closeMenu = () => setMenu(null)

  const handleChoice = (value) => {
    closeMenu()
    props.onChange && props.onChange(value)
  }

  const value = props.value ?? props.choices[0]?.value

  return (
    <div className="MenuButton">
      {props.label && <label>{props.label}</label>}
      {!props.fab ? (
        <Button
          disableRipple={props.disableRipple}
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="MenuButton__square"
          onClick={openMenu}
        >
          {props.choices
            .filter((choice) => choice.value == value)
            .map((choice) => choice.label)}
          {menu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      ) : (
        <Fab
          onClick={openMenu}
          className={menu && "MenuButton--open"}
          size="small"
          color="primary"
          disableRipple={props.disableRipple}
        >
          {props.icon}
        </Fab>
      )}
      <Menu
        id="simple-menu"
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={closeMenu}
        className={"MenuButton__menu"}
      >
        {props.choices.map((choice, i) => (
          <BmmTooltip
            key={i}
            title={choice.tooltip ? choice.tooltip : ""}
            placement="bottom-start"
          >
            <span>
              <MenuItem
                key={i}
                disableRipple={props.disableRipple}
                onClick={() => {
                  handleChoice(choice.value)
                  choice.onClick && choice.onClick()
                }}
                disabled={choice.disabled}
              >
                {choice.icon && (
                  <div className="MenuButton__item__icon">{choice.icon}</div>
                )}
                {choice.label}
              </MenuItem>
            </span>
          </BmmTooltip>
        ))}
      </Menu>
    </div>
  )
}

export default MenuButton
