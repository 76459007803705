/**
 * Signatures Sagas - Call Signatures API for retrieve current user signatures
 */
import { Signature } from "entities/Signature"
import { put, call, select, all, take, delay } from "redux-saga/effects"

import * as $ from "jquery"

import mime from "mime"

import { User } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import Template from "entities/Template"
import TemplatesFactory from "entities/TemplatesFactory"
import { getFileExtension, getSvgAsFile } from "utils/ImageUtils"
import { uploadImage } from "sagas/templates.sagas"

import { appSelectors } from "features/App"
import {
  TypeKeys,
  signaturesSelectors,
  DissociateUsersAction,
  DissociateGroupAction,
} from "features/Signatures"
import { GlobalStates } from "store"
import { cloneDeep } from "lodash"
import { usersSelectors } from "features/Users"

import config from "config/config"
import { tourGuideSelectors } from "features/TourGuide"
import { navigateTo } from "features/App/AppSagas"

import { LoadingStatus, TypedResult } from "core/CoreModels"
import { fetchAllUsersSuccess } from "features/Users/UsersReducers"
import { getAllUsersAsync } from "features/Users/UsersSagas"

import { SignatureDto } from "features/Signatures/SignaturesModels"
import { accountsSelectors } from "features/Accounts"

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"

import {
  activateSignature,
  createSignature,
  deleteSignature,
  dissociateGroupsToSignature,
  dissociateUsersToSignature,
  getUserSignature,
  sendSignaturePreview,
  updateSignature,
} from "features/Signatures/SignaturesApi"

import {
  activateSignatureSuccess,
  changeTemplateColor,
  createSignatureSuccess,
  deleteSignatureSuccess,
  fetchTemplatesGallerySuccess,
  fillTemplateUserPropertiesSuccess,
  initSignatureLogo,
  selectTemplateSuccess,
  sendPreviewMailFailure,
  sendPreviewMailSuccess,
  setActiveSignatureSuccess,
  setEditingSignatureWeighting,
  updateSignatureName,
  updateSignatureSuccess,
} from "features/Signatures/SignaturesReducer"
import {
  createSignatureAction,
  createSignatureFailureAction,
  deleteSignatureAction,
} from "features/Signatures/SignaturesActions"
import { PayloadAction } from "@reduxjs/toolkit"
import { uploadImageToStorageAsUrl } from "features/Storage/StorageApi"
import { setOfferLimitations } from "features/Offers/OffersReducer"
import { hideAutosave, showAutosave } from "features/App/AppReducers"
import { ADDITIONAL_INFOS_URL } from "router/RouterConstants"
import {
  initSendingMode,
  getOrLoadSignaturesSlice,
} from "features/Signatures/SignaturesSagas"
import { DEFAULT_SIGNATURE_WEIGHTING_VALUE } from "features/Signatures/SignaturesRules"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fillUserProperties() {
  // on attend à la fois que les signatures et les users aient été chargés, pour pouvoir effectuer le remplacement et définir notre utilisateur de test
  const usersLoadingStatus = yield select(usersSelectors.getLoadingStatus)
  const signaturesLoadingStatus = yield select(
    signaturesSelectors.getSignaturesSliceLoadingStatus("all"),
  )

  if (usersLoadingStatus !== LoadingStatus.LOADED) {
    yield call(getAllUsersAsync)
    yield take(fetchAllUsersSuccess.type)
  }

  const allSignatures = yield call(getOrLoadSignaturesSlice, "all")

  const sampleUser: User = yield select(usersSelectors.getSampleUser)

  if (sampleUser === null || signaturesLoadingStatus !== LoadingStatus.LOADED)
    return

  const signaturesWithfilledUserProperties = allSignatures.map((signature) => {
    signature.Template.CompileUserTags(sampleUser.Properties)
    return signature
  })

  yield put(
    fillTemplateUserPropertiesSuccess(signaturesWithfilledUserProperties),
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* initOrSetActiveSignature(action) {
  // Vérification de l'existence d'un 1er utilisateur correspondant à l'utilisateur connecté
  yield call(CheckAdminUserExists)
  const activeSignatureId =
    action.signatureId != null
      ? action.signatureId
      : yield select(signaturesSelectors.getActiveSignatureId)

  if (activeSignatureId == null) {
    yield call(initNewSignature)
  } else {
    const activeSignature = yield select((state) =>
      signaturesSelectors.getSignatureById(state, activeSignatureId),
    )
    yield call(initEditedSignature, activeSignature)
  }
}

function* CheckAdminUserExists() {
  const usersLoaded = yield select(usersSelectors.getLoadingStatus)
  if (
    usersLoaded === LoadingStatus.NOT_STARTED ||
    usersLoaded === LoadingStatus.PENDING
  )
    yield take(fetchAllUsersSuccess)

  const allUsers = yield select(usersSelectors.getAllUsers)
  const atLeastOneUserExists: boolean = allUsers.length > 0

  if (!atLeastOneUserExists) yield navigateTo(ADDITIONAL_INFOS_URL)
}

function* initNewSignature() {
  let defaultTemplate = yield select(signaturesSelectors.getDefaultTemplate)
  // temporisation - si null, on attend que la gallerie soit chargée
  if (defaultTemplate == null) {
    yield take(fetchTemplatesGallerySuccess.type)
    yield (defaultTemplate = yield select(
      signaturesSelectors.getDefaultTemplate,
    ))
  }
  // temporisation - si null, on attend que les userprops soient chargées
  let sampleUser: User = yield select(usersSelectors.getSampleUser)
  if (sampleUser === null) {
    yield take(fillTemplateUserPropertiesSuccess.type)
    yield (sampleUser = yield select(usersSelectors.getSampleUser))
  }

  const template = TemplatesFactory.DeepCopy(defaultTemplate)

  const signature = new Signature({})

  signature.Template = template
  // traitement unique : définition des propriétés utilisateurs disponibles dans le template
  const allProps = yield select(usersSelectors.getUserProperties)
  signature.Template.userProperties = allProps.map((m) => m.InternalName) //  signature.Template.GetUserProperties();
  signature.Template.sampleUserProps = sampleUser && sampleUser.Properties

  yield call(setActiveSignature, signature, template)
}

function* initEditedSignature(signature) {
  // si la signature existe, on attache le template utilisé, pour avoir l'emplacement des tags qui auraient été supprimés
  const signatureTemplate = yield select((state) =>
    signaturesSelectors.getTemplateById(state, signature.Template.templateId),
  )
  // 1- Génération d'un nouveau template pour la signature, afin de redéfinir les tags disponibles et ne pas modifier le template dans la galerie
  const template = TemplatesFactory.DeepCopy(signatureTemplate)

  const fullSignature = yield call(setActiveSignature, signature, template)

  yield put(
    initSignatureLogo({
      logoUrl: fullSignature.Template.GetLogoUrl(),
      size: fullSignature.Template.dataTags.logo.size,
      maxSize: fullSignature.Template.dataTags.logo.maxSize,
    }),
  )

  yield put(setEditingSignatureWeighting(fullSignature.Weighting))
}

function* setActiveSignature(signature: Signature, template: Template) {
  signature.Template.showBanner = false

  signature.Template.UpdateTemplate(template)

  // TODO - retirer la rustine et externaliser la gestion du state du TourGuide
  const currentTour = yield select((state: GlobalStates) =>
    tourGuideSelectors.getCurrentTour(state),
  )
  if (currentTour == null) {
    yield put(updateSignatureName(signature.Name))
  } else {
    // si on revient du tunnel banière,
    const activeSignatureId = yield select((state: GlobalStates) =>
      signaturesSelectors.getActiveSignatureId(state),
    )
    if (activeSignatureId == null) {
      //Nettoyage des éléments avant de reprendre le tour
      yield removeDemoSignature()
    }
  }

  yield all([
    put(selectTemplateSuccess(signature.Template.templateId)),
    put(setActiveSignatureSuccess(signature)),
    put(changeTemplateColor(signature.Template.currentColor)),
    call(initSendingMode),
    put(setEditingSignatureWeighting(DEFAULT_SIGNATURE_WEIGHTING_VALUE)),
  ])

  return signature
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* removeDemoSignature() {
  const demoSignatureName = yield select(
    appSelectors.getTranslationText("TourJS.DemoSignatureName"),
  )

  const allSignatures = yield select((state: GlobalStates) =>
    signaturesSelectors.getAllSignatures(state),
  )
  const demoSignature = allSignatures.find(
    (signature: Signature) => signature.Name === demoSignatureName,
  )
  if (demoSignature != null) {
    yield put(deleteSignatureAction(demoSignature))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createSignatureAsync(action: PayloadAction<Signature>) {
  try {
    const result: TypedResult<SignatureDto> = yield call(
      createSignature,
      Signature.toJson(action.payload),
    )

    if (!result.success) {
      yield put(createSignatureFailureAction())
      return
    }
    action.payload.Id = result.result.id

    yield put(createSignatureSuccess(action.payload))

    yield put(hideAutosave())
  } catch (error) {
    yield put(createSignatureFailureAction())
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateSignatureAsync(action: PayloadAction<Signature>) {
  try {
    yield put(showAutosave())
    // on lance l'upload du logo sur le CDN du client si ce dernier est une data url, la nouvelle url viendra remplacer l'url du logo de la signature active.
    if (
      action.payload.Template.dataTags.logo != null &&
      action.payload.Template.GetLogoUrl() &&
      action.payload.Template.GetLogoUrl().startsWith("data:image/")
    ) {
      const extension = getFileExtension(action.payload.Template.GetLogoUrl())
      const fileName = `${
        action.payload.Name
      }-${new Date().getTime()}.${extension}`
      const fileType = mime.getType(extension) || ""
      // Gestion du redimensionnement via balise HTML width/height pour ne pas perdre de qualité de visuels et ne pas surcharger le serveur
      // REFACTO - Extraire dans une action à part l'upload d'images et l'utiliser pour banner et signature
      const uploadResultData = yield call(
        uploadImageToStorageAsUrl,
        action.payload.Template.GetLogoUrl(),
        fileName,
        fileType,
      )

      const logoUrl = uploadResultData.result.url

      action.payload.Template.SetLogoUrl(logoUrl)
    }

    // On génère les images à partir du SVG pour support sur Outlook
    const uploadFilePromises = []
    const filledDatatags = []
    for (const dataTag in action.payload.Template.dataTags) {
      const currDataTag = action.payload.Template.dataTags[dataTag]

      // cas d'une balise img (et non d'un SVG) -- pas d'upload
      const $img = $(currDataTag.children)
      if ($img && $img.attr("src") != null) {
        const $imgSrc = $img.attr("src")
        // svg non-parsé à cause des CORS, on le remplace par un equivalent en PNG
        if ($imgSrc.indexOf(".svg") > 0) {
          // remplacement du host, avec le https par l'url cdn
          let newUrl = $imgSrc.replace(
            /(https?:\/\/.*?)(\/.*)/g,
            config.cdnUrl + "$2",
          )
          newUrl = newUrl.replace(".svg", ".png")

          $img.attr("src", newUrl)
          currDataTag.markup = currDataTag.markup.replace(
            currDataTag.children,
            $img[0].outerHTML,
          )
          currDataTag.children = $img[0].outerHTML
        }
      }

      if (
        !currDataTag.children ||
        currDataTag.url === "" ||
        currDataTag.children.indexOf("svg") === -1
      )
        continue

      const svgUploadPromise = new Promise((resolve) => {
        let fileName
        // si la couleur est 'undefined', on utilise une des images de base, pas besoin de la regénérée
        // on doit quand même remplacer le SVG par l'url correspondante
        if (
          action.payload.Template.currentColor == "undefined" ||
          action.payload.Template.currentColor == null ||
          action.payload.Template.currentColor === ""
        ) {
          fileName = `${action.payload.Template.GetSvgFileName(
            currDataTag,
          )}.png`
          const fileUrl = `${config.cdnUrl}/images/common/${fileName}`
          resolve(fileUrl)
        } else {
          fileName =
            action.payload.Template.GetSvgFileName(currDataTag) +
            "_" +
            action.payload.Template.currentColor +
            ".png"
          // Si la couleur est définie, on génère la nouvelle image à partir du SVG
          getSvgAsFile(currDataTag.children, fileName, (file) => {
            resolve(file)
          })
        }
      })
      uploadFilePromises.push(svgUploadPromise)
      filledDatatags.push(currDataTag)
    }

    // upload de tous les fichiers sur le CDN , dans le répertoire commun (common)
    const imagesUrls = yield new Promise((resolve) => {
      Promise.all(uploadFilePromises).then((files) => {
        resolve(files)
      })
    })

    const urls = yield all(
      imagesUrls.map((blob) => {
        // ! getBase64ImgAsFile - erreur lors de la conversion en File d'un svg
        if (blob == null) return
        if (typeof blob === "object") {
          return call(uploadImage, blob, true)
        } else {
          // dans le cas d'une url, on redirige directement vers le fichier
          return blob
        }
      }),
    )

    for (const i in filledDatatags) {
      const updatedDataTag = filledDatatags[i]
      action.payload.Template.SetDataTagSrc(updatedDataTag.name, urls[i])
    }

    // Avant sauvegarde, on retire du rawContent les propriétés qui ont été masqués via l'interface
    const selectedTemplate = yield select((state: GlobalStates) =>
      signaturesSelectors.getSelectedTemplate(state),
    )
    const invisibleProperties = selectedTemplate
      .GetUserProperties()
      .filter(
        (prop) => action.payload.Template.userProperties.indexOf(prop) === -1,
      )

    action.payload.Template.RemoveInvisibleProperties(invisibleProperties)

    action.payload.Template.Id = selectedTemplate.Id
    action.payload.Template.templateId = selectedTemplate.Id

    // Dans le cas d'une creation de signature
    if (action.payload.Id == null) {
      yield createSignatureAsync(createSignatureAction(action.payload))
      yield put(hideAutosave())
      return
    }

    const result: TypedResult<SignatureDto> = yield call(
      updateSignature,
      Signature.toJson(action.payload) as Signature,
    )
    if (!result.success) {
      yield put(hideAutosave())
      return
    }

    // Merge de la nouvelle signature avec celle passée en params pour récupérer les assignedUsers (évite des requetes supplémentaires)
    const activeSignature = Object.assign(
      new Signature(result.result),
      action.payload,
    )

    yield put(updateSignatureSuccess(activeSignature))
    yield delay(1000)
    yield put(hideAutosave())
  } catch (error) {
    yield delay(1000)
    yield put(hideAutosave())
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* duplicateSignatureAsync(signatureAction) {
  try {
    const signatureData = cloneDeep(signatureAction.newSignature)
    delete signatureData.Id
    signatureData.Name = signatureData.Name + " (copie)"
    const signatureCopy = new Signature(signatureData)
    const result = yield call(createSignature, Signature.toJson(signatureCopy))

    if (!result.success) {
      yield put(createSignatureFailureAction())
      return
    }

    const newSignature = new Signature(result.result)
    newSignature.Template = signatureData.Template

    yield put(createSignatureSuccess(newSignature))
    yield put(
      addNotification(
        new Notification("Signature dupliquée avec succès.", "SUCCESS"),
      ),
    )
  } catch (error) {
    yield put(createSignatureFailureAction())
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* toggleSignatureAsync(action: PayloadAction<Signature>) {
  yield call(
    updateSignatureActivation,
    action.payload,
    !action.payload.Activated,
  )
}

/**
 * Met à jour l'activation d'une signature
 * @param signature
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* activateSignatureAsync(signatureAction) {
  if (signatureAction.signature.Activated === true) return

  yield call(updateSignatureActivation, signatureAction.signature, true)
}

function* updateSignatureActivation(signature: Signature, activated: boolean) {
  const result: TypedResult<SignatureDto> = yield call(
    activateSignature,
    signature.Id,
    activated,
  )

  if (!result.success) throw new Error(result.errorMessage)

  if (result.limitationsReach) {
    yield put(setOfferLimitations("SignatureLimitationsCantActivate"))
    return
  }

  yield put(
    activateSignatureSuccess({
      id: signature.Id,
      activated: result.result.activated,
      activatedDate: result.result.activatedDate,
      modified: result.result.modified,
    }),
  )
}

/**
 * Mise à jour du template de la signature active, sans sauvegarde au niveau de la base pour ne pas surcharger d'appels (appelé à chaque modif du template)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateTemplate(action) {
  try {
    const activeSignature: Signature = yield select(
      signaturesSelectors.getActiveSignature,
    )

    activeSignature.Template.rawContent = action.template

    yield put(updateSignatureSuccess(activeSignature))
  } catch (error) {
    yield put({
      type: TypeKeys.UPDATE_ACTIVE_SIGNATURE_TEMPLATE,
      error: error.response.data,
    })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* deleteSignatureAsync(action: PayloadAction<Signature>) {
  yield call(deleteSignature, action.payload)
  yield put(deleteSignatureSuccess(action.payload))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* dissociateUsersToActiveSignature(
  action: DissociateUsersAction,
) {
  const activeSignature = yield select((state: GlobalStates) =>
    signaturesSelectors.getActiveSignature(state),
  )

  if (activeSignature.Id != null) {
    yield call(dissociateUsersToSignature, activeSignature, [
      action.selectedUser.Id,
    ])
  }

  activeSignature.AffectedUsers = activeSignature.AffectedUsers.filter(
    (user: User) => {
      // On retourne les utilisateurs qui ne font pas parti de la liste d'utilisateurs retirés
      return user.Id !== action.selectedUser.Id
    },
  )
  yield put(updateSignatureSuccess(activeSignature))
}

/**
 * Dissocie tous les utilisateurs assignés à la signature active
 * @param action
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* unassignAllUsersToActiveSignature() {
  const activeSignature = yield select((state: GlobalStates) =>
    signaturesSelectors.getActiveSignature(state),
  )

  const affectedUsersIds =
    activeSignature.AffectedUsers &&
    activeSignature.AffectedUsers.map((user) => user.Id)
  // Mise à jour en base si la signature existe
  if (activeSignature.Id != null) {
    yield call(dissociateUsersToSignature, activeSignature, affectedUsersIds)
  }

  // reset des utilisateurs affectés
  activeSignature.AffectedUsers = []

  yield put(updateSignatureSuccess(activeSignature))
}

/**
 * Dissocie le groupe selectionné de la signature active
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* dissociateGroupToActiveSignature(
  action: DissociateGroupAction,
) {
  const activeSignature = yield select(signaturesSelectors.getActiveSignature)

  if (activeSignature.Id != null) {
    yield call(dissociateGroupsToSignature, activeSignature.Id, [
      action.selectedGroup.Id,
    ])
  }

  activeSignature.AffectedGroups = activeSignature.AffectedGroups.filter(
    (group: Group) => {
      // On retourne les groupes qui ne font pas parti de la liste de groupes retirés
      return group.Id !== action.selectedGroup.Id
    },
  )

  yield put(updateSignatureSuccess(activeSignature))
}

/**
 * Dissocie tous les groupes assignés à la signature active
 * @param action
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* unassignAllGroupsToActiveSignature() {
  const activeSignature = yield select(signaturesSelectors.getActiveSignature)

  const affectedGroupsIds = activeSignature.AffectedGroups?.map(
    (group) => group.Id,
  )
  // Mise à jour en base si la signature existe
  if (activeSignature.Id != null) {
    yield call(
      dissociateGroupsToSignature,
      activeSignature.Id,
      affectedGroupsIds,
    )
  }
  // reset des utilisateurs affectés
  activeSignature.AffectedGroups = []

  yield put(updateSignatureSuccess(activeSignature))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* sendPreviewByMail(action: PayloadAction<string>) {
  try {
    // Récupèration de la signature de l'utilisateur
    const signature: TypedResult<SignatureDto> = yield call(
      getUserSignature,
      action.payload,
    )

    if (signature.success) {
      // Récuperation de l'adresse mail de l'administrateur courant
      const adminMail = yield select(accountsSelectors.getCurrentAccountEmail)
      // Envoie la signature par email
      yield call(sendSignaturePreview, adminMail, signature.result.template)
      yield put(sendPreviewMailSuccess())
    }
  } catch (error) {
    yield put(sendPreviewMailFailure())
  }
}

/**
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* sendActiveSignaturePreviewByMail() {
  try {
    // Récupèration de la signature de l'utilisateur
    const activeSignatureTemplate = yield select(
      signaturesSelectors.getActiveCompiledTemplate,
    )
    // Récuperation de l'adresse mail de l'administrateur courant
    const adminMail = yield select(accountsSelectors.getCurrentAccountEmail)
    // Envoie la signature par email
    yield call(sendSignaturePreview, adminMail, activeSignatureTemplate)
    yield put(sendPreviewMailSuccess())
  } catch (error) {
    yield put(sendPreviewMailFailure())
  }
}
