import { useEffect } from "react"

import { useDispatch } from "react-redux"

import { useSearchParams } from "react-router-dom"

import { UTM_PARAMETERS } from "features/Marketing/MarketingConstants"
import { addUTM } from "../MarketingReducers"

const useUTMManagement = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    UTM_PARAMETERS.forEach((utmField) => {
      const utmParamValue = searchParams.get(utmField)

      if (utmParamValue)
        dispatch(addUTM({ utm: utmField, value: utmParamValue }))
    })
  }, [searchParams])
}

export default useUTMManagement
