import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { LoadingStatus } from "core/CoreModels"
import { RootState } from "store/configureStore"
import { Subsidiary } from "./SubsidiaryModels"

export interface SubsidiariesState {
  allSubsidiaries: {
    entities: Subsidiary[]
    ids: Array<number>
    loadingStatus: LoadingStatus
    isUpdated: boolean
  }
  currentSubsidiary: Subsidiary
}

export interface UpdateCurrentSubsidiary {
  userId: string
  subsidiaryId: number
}

export interface PayloadCurrentSubsidiariesFetch {
  subsidiaries: Subsidiary[]
  currentSubsidiary: Subsidiary
}

export interface PayloadSubsidiariesFetch {
  subsidiaries: Subsidiary[]
}

const subsidiaryAdapter = createEntityAdapter<Subsidiary>({
  selectId: (subsidiary) => subsidiary.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const {
  selectAll: selectAllSubsidiaries,
  selectById: selectSubsidiaryById,
  selectIds: selectSubsidiaryIds,
} = subsidiaryAdapter.getSelectors<RootState>(
  (state) => state.subsidiaries.allSubsidiaries,
)

const initialState = {
  allSubsidiaries: subsidiaryAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
    isUpdated: false,
  }),
  currentSubsidiary: null,
  allCurrentSubsidiaries: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    isUpdated: false,
    subsidiaries: null,
  },
}

const subsidiaryReducer = createSlice({
  name: "subsidiary",
  initialState,
  reducers: {
    fetchSubsidiaries(state) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.PENDING
    },
    fetchSubsidiariesSuccess(
      state,
      action: PayloadAction<PayloadSubsidiariesFetch>,
    ) {
      subsidiaryAdapter.upsertMany(
        state.allSubsidiaries,
        action.payload.subsidiaries,
      )
      state.allSubsidiaries.loadingStatus = LoadingStatus.LOADED
    },
    fetchSubsidiariesFailure(state) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.ERROR
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createSubsidiary(state, _action: PayloadAction<Subsidiary>) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.PENDING
    },
    createSubsidiarySuccess(state, action) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.LOADED
      subsidiaryAdapter.addOne(state.allSubsidiaries, action.payload)
    },
    createSubsidiaryFailure(state) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.LOADED
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSubsidiary(state, _action: PayloadAction<Subsidiary>) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.PENDING
    },
    updateSubsidiarySuccess(state, action: PayloadAction<Subsidiary>) {
      subsidiaryAdapter.upsertOne(state.allSubsidiaries, action.payload)
      state.allSubsidiaries.loadingStatus = LoadingStatus.LOADED
    },
    updateSubsidiaryFailure(state) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.LOADED
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSubsidiary(state, _action: PayloadAction<number>) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.PENDING
    },
    deleteSubsidiarySuccess(state, action: PayloadAction<number>) {
      subsidiaryAdapter.removeOne(state.allSubsidiaries, action.payload)
      state.allSubsidiaries.loadingStatus = LoadingStatus.LOADED
    },
    deleteSubsidiaryFailure(state) {
      state.allSubsidiaries.loadingStatus = LoadingStatus.ERROR
    },
    setSubsidiaryUpdatedStatus(state, action: PayloadAction<boolean>) {
      state.allSubsidiaries.isUpdated = action.payload
    },
    fetchCurrentSubsidiaries(state) {
      state.allCurrentSubsidiaries.loadingStatus = LoadingStatus.PENDING
    },
    fetchCurrentSubsidiariesSuccees(
      state,
      action: PayloadAction<PayloadCurrentSubsidiariesFetch>,
    ) {
      state.allCurrentSubsidiaries.subsidiaries = action.payload.subsidiaries
      state.currentSubsidiary = action.payload.currentSubsidiary
      state.allCurrentSubsidiaries.loadingStatus = LoadingStatus.LOADED
    },
    fetchCurrentSubsidiariesFailure(state) {
      state.allCurrentSubsidiaries.loadingStatus = LoadingStatus.ERROR
    },
  },
})

export const {
  fetchSubsidiaries,
  fetchSubsidiariesSuccess,
  fetchSubsidiariesFailure,
  createSubsidiary,
  createSubsidiarySuccess,
  createSubsidiaryFailure,
  updateSubsidiary,
  updateSubsidiarySuccess,
  updateSubsidiaryFailure,
  deleteSubsidiarySuccess,
  deleteSubsidiary,
  deleteSubsidiaryFailure,
  setSubsidiaryUpdatedStatus,
  fetchCurrentSubsidiaries,
  fetchCurrentSubsidiariesSuccees,
  fetchCurrentSubsidiariesFailure,
} = subsidiaryReducer.actions

export default subsidiaryReducer.reducer
