import Country from "entities/Country"
import React from "react"
import PhoneInput from "react-phone-number-input"
import Input from "react-phone-number-input/input"
import CustomTextInput from "./CustomTextInput"

interface Props {
  value?: string
  onChange?(value: string)
  international?: boolean
  withCountry?: boolean
  disabled?: boolean
  error?: boolean
  errorText?: string
}

const Phone: React.FC<Props> = (props) => {
  const {
    value,
    onChange,
    international,
    withCountry,
    disabled,
    error,
    errorText,
  } = props
  const showCountrySelect = withCountry === undefined ? true : withCountry

  type InputPropTypes = {
    defaultCountry: Country
  }

  const inputProps: any & InputPropTypes = {
    value: value,
    onChange: onChange,
    inputComponent: CustomTextInput,
    defaultCountry: "FR",
    limitMaxLength: true,
    international: international || false,
    disabled: disabled || false,
    error: error,
    errorText: errorText,
  }

  return (
    <div className={`phone ${error && !!errorText ? "phone--error" : ""}`}>
      {showCountrySelect && <PhoneInput {...inputProps} />}
      {!showCountrySelect && <Input {...inputProps} />}
    </div>
  )
}

export default Phone
