import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import SubHeaderTabs from "components/SubHeaderTabs"

const messages = defineMessages({
  subsidiaries: {
    id: "SubsidiariesPage.SubsidiariesTab",
    defaultMessage: "Filiales",
  },
  admin: {
    id: "SubsidiariesPage.AdminsTab",
    defaultMessage: "Administrateurs",
  },
})

const tabs = [
  { id: "subsidiaries", label: messages.subsidiaries },
  { id: "admin", label: messages.admin },
]

const SubsidiariesPageHeader: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="subsidiaries-page-header">
      <div />
      <div className="subsidiaries-page-header__tabs">
        <SubHeaderTabs
          availableTabs={tabs.map((tab) => tab.id)}
          defaultTab={"subsidiaries"}
        >
          {tabs.map((tab) => (
            <SubHeaderTabs.Tab key={tab.id} tabName={tab.id}>
              {formatMessage(tab.label)}
            </SubHeaderTabs.Tab>
          ))}
        </SubHeaderTabs>
      </div>
      <div />
    </div>
  )
}

export default SubsidiariesPageHeader
