import { FunctionComponent, ReactNode } from "react"

interface AccountInformationsFormSubmitButtonProps {
  children: ReactNode
  onClick: () => void
  disabled?: boolean
}

const AccountInformationsFormSubmitButton: FunctionComponent<
  AccountInformationsFormSubmitButtonProps
> = ({ children, disabled, onClick }) => {
  return (
    <button
      type="button"
      className="account-informations-form__step--button"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default AccountInformationsFormSubmitButton
