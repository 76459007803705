import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import Group, { GroupPayloadDto } from "features/Groups/GroupsModels"

import { groupsSelectors } from "features/Groups"
import { fetchGroup, resetEditingGroup } from "features/Groups/GroupsReducer"
import {
  createGroupAction,
  editGroupAction,
  upsertDynamicGroupAction,
} from "features/Groups/GroupsActions"

import { LoadingStatus, SavingStatus } from "core/CoreModels"

interface UseSingleGroupData {
  currentGroup: Group | null
  isLoading: LoadingStatus
  isSaving: SavingStatus
  onAddGroup: (newGroup: GroupPayloadDto) => void
  onEditGroup: (group: GroupPayloadDto) => void
  onUpsertDynamicGroup: (newGroup: GroupPayloadDto) => void
  onResetEditingGroup: () => void
}

const useSingleGroupData = (currentGroupId: number): UseSingleGroupData => {
  const dispatch = useDispatch()
  const currentGroup: Group = useSelector(groupsSelectors.getEditingGroup)
  const { loadingStatus: isLoading, savingStatus: isSaving } = useSelector(
    groupsSelectors.getEditingGroupStatuses,
  )

  useEffect(() => {
    if (currentGroupId !== -1) {
      dispatch(fetchGroup(currentGroupId))
    }
  }, [])

  const onAddGroup = (newGroup) => {
    dispatch(createGroupAction(newGroup))
  }

  const onEditGroup = (group) => {
    dispatch(editGroupAction(group))
  }

  const onUpsertDynamicGroup = (group) => {
    dispatch(upsertDynamicGroupAction(group))
  }

  const onResetEditingGroup = () => {
    dispatch(resetEditingGroup())
  }

  return {
    currentGroup,
    isLoading,
    isSaving,
    onAddGroup,
    onEditGroup,
    onUpsertDynamicGroup,
    onResetEditingGroup,
  }
}

export default useSingleGroupData
