import { FunctionComponent } from "react"

import classNames from "classnames"

import { ReactComponent as IconUser } from "assets/icon-user.svg"

interface SignatureCardAffectedCountProps {
  affectedCount: number
  activated: boolean
  isLoading: boolean
}

export type SignatureCardAffectedCountType =
  FunctionComponent<SignatureCardAffectedCountProps>

const SignatureCardAffectedCount: SignatureCardAffectedCountType = ({
  affectedCount,
  activated,
  isLoading,
}) => {
  return (
    <div
      data-cy="signature-card__nb-users"
      className={classNames(
        "signature-card__nb-users",
        activated && affectedCount > 0 ? "is-assigned" : null,
      )}
    >
      <IconUser className="signature-card__nb-users--icon" />
      {isLoading ? (
        <div className="signature-card__nb-users--skeleton" />
      ) : (
        <p className="signature-card__nb-users--count">
          {activated ? affectedCount : 0}
        </p>
      )}
    </div>
  )
}

export default SignatureCardAffectedCount
