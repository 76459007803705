import { useDispatch, useSelector } from "react-redux"
import { scenarioSelectors } from "features/Scenarios"
import {
  DiffusionMode,
  updateDiffusionMode,
} from "features/Scenarios/ScenarioReducers"

interface UseDiffusionMode {
  diffusionMode: DiffusionMode
  setDiffusionMode: (diffusionMode: DiffusionMode) => void
}

const useDiffusionMode = (): UseDiffusionMode => {
  const dispatch = useDispatch()

  const diffusionMode = useSelector(scenarioSelectors.getDiffusionMode)

  const setDiffusionMode = (diffusionMode: DiffusionMode) => {
    dispatch(updateDiffusionMode(diffusionMode))
  }

  return { diffusionMode, setDiffusionMode }
}

export default useDiffusionMode
