/* Selectors **/

import { signaturesSelectors } from "features/Signatures"
import { GlobalStates } from "store"
import { SignatureMetricVM } from "features/Signatures/SignaturesModels"
import Signature from "entities/Signature"
import ScenarioMetric from "entities/ScenarioMetric"
import { orderBy } from "lodash"
import BannerClickByDate from "entities/BannerClickByDate"
import moment from "moment"
import SignatureAssignation from "entities/SignatureAssignation"
import { usersSelectors } from "features/Users"
import { uniq } from "lodash"
import { scenarioSelectors } from "features/Scenarios"
import { Scenario } from "features/Scenarios/ScenarioModels"
import { groupsSelectors } from "features/Groups"
import { LoadingStatus } from "core/CoreModels"
import {
  selectAllSignaturesPowerUsers,
  selectAllSignaturesDeployment,
  selectSignaturesPowerUserById,
  selectSignatureDeploymentById,
  selectAllSignaturesTracking,
  selectSignatureTrackingById,
  selectAllScenariosPowerUsers,
  selectScenariosPowerUserById,
  selectAllScenariosTracking,
  selectScenarioTrackingById,
  selectAllSignaturesDelivered,
  selectSignaturesDeliveredById,
} from "./TrackingReducers"

import {
  BannersHistoryDto,
  QueriesDateRange,
  SignaturesHistoryDto,
} from "./TrackingModels"
import ScenarioTrackingMapper from "./mappers/ScenarioTrackingMapper"

const getSignaturesMetrics = (
  state: GlobalStates,
): Array<SignatureMetricVM> => {
  const allSignatures = signaturesSelectors.getAllSignatures(state)
  const signaturesDeployments = selectAllSignaturesDelivered(state)

  const signaturesMetrics = allSignatures.map((signature: Signature) => {
    const affectedUsersList = signature.AffectedUsersCount

    const assignedUsers = affectedUsersList.length

    const signatureUsers = affectedUsersList.map((userId) => {
      const stateUser = usersSelectors.getUserById(state, userId)

      if (stateUser == null) return // data pas encore chargées

      const currentUser = signaturesDeployments.find(
        (sd) => sd.userEmail === stateUser.Username,
      )

      const usage = currentUser?.signatureIds?.includes(signature.Id) || false

      return new SignatureAssignation(
        userId,
        stateUser.Properties.firstname,
        stateUser.Properties.lastname,
        usage,
      )
    })

    const nbUsersNoSignature: number = signatureUsers.filter(
      (su) => su.usage === false,
    ).length

    return {
      signatureId: signature.Id,
      signatureName: signature.Name,
      nbAssignedUsers: assignedUsers,
      nbUsersNoSignature: nbUsersNoSignature,
      signatureUsers: signatureUsers,
    } as SignatureMetricVM
  })
  return signaturesMetrics
}

function getAssignedBannerClicks(bannerIds, allClicks) {
  if (!Array.isArray(allClicks)) return 0
  return allClicks
    .filter((c) => bannerIds.indexOf(c.bannerId) !== -1)
    .reduce((acc, click) => acc + click.nbClicks, 0)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getBannersMetrics = (state: GlobalStates) => {
  const allScenarios = scenarioSelectors.getAllScenarios(state)

  const bannersMetrics = allScenarios
    .sort((prev: Scenario, next: Scenario) =>
      prev.props.created < next.props.created ? 1 : -1,
    )
    .map((scenario: Scenario) => {
      const clickCount = getAssignedBannerClicks(
        scenario.props.banners.map((banner) => banner.id),
        state.tracking.scenarios.clickByDate,
      )

      let nbAssignedUser = 0
      if (state.groups.allGroups.loadingStatus === LoadingStatus.LOADED) {
        const assignedUsersByGroups = groupsSelectors.getAllMembersForGroups(
          state,
          scenario.props.groupIds,
        )
        const assignedUsers = uniq([
          ...scenario.props.userIds,
          ...assignedUsersByGroups.map((usr) => usr.Id),
        ]) // TODO  .map(g => g.Members)].length
        nbAssignedUser = assignedUsers.length
      }

      return {
        id: scenario.id,
        name: scenario.props.name,
        default:
          scenario.props.startDate == null && scenario.props.endDate == null,
        banners: scenario.props.banners.map((banner) => banner.props.imageUrl),
        nbAssignedUsers: nbAssignedUser,
        clickCount,
      } as ScenarioMetric
    })
  return bannersMetrics
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getBannerMetricsByDate = (state: GlobalStates) => {
  const bannerMetricsByDate = {}

  for (const bannerTrack in state.tracking.scenarios.clickByDate) {
    const currentBannerTrack = state.tracking.scenarios.clickByDate[bannerTrack]
    if (bannerMetricsByDate[currentBannerTrack.clickedDate] == null)
      bannerMetricsByDate[currentBannerTrack.clickedDate] = 0
    bannerMetricsByDate[currentBannerTrack.clickedDate] +=
      currentBannerTrack.nbClicks
  }

  // transformation en tableau
  const bannerMetricsByDateArray: BannerClickByDate[] = []
  for (const index in bannerMetricsByDate) {
    const bannerClics = new BannerClickByDate()
    bannerClics.date = moment(index)
    bannerClics.dateLibelle = bannerClics.date.format("D MMM")
    bannerClics.nbClicks = bannerMetricsByDate[index]
    bannerMetricsByDateArray.push(bannerClics)
  }
  return orderBy(bannerMetricsByDateArray, (bannerMetric) => bannerMetric.date)
}

const getNbUsersWithLicence = (state: GlobalStates): number => {
  const allUsers = usersSelectors.getAllUsersWithLicences(state)
  return allUsers.length
}

const getNbSignatures = (state: GlobalStates): number =>
  state.signatures.allSignatures.ids.length

const getNbBanners = (state: GlobalStates): number =>
  scenarioSelectors.getAllScenarios(state)?.length

const getSignaturesDeploymentLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.signaturesDeployment.loadingStatus

const getSignatureDeploymentUsersInfos =
  (id: number | null) => (state: GlobalStates) => {
    if (id === null) return null

    return selectSignatureDeploymentById(state, id).users
  }

const getSignaturesPowerUsersLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.signaturesPowerUsers.loadingStatus

const getSignaturesPowerUsersSortByTotalClicks = (state: GlobalStates) =>
  selectAllSignaturesPowerUsers(state).sort((a, b) =>
    a.nbClicks > b.nbClicks ? -1 : 1,
  )

const getSignaturesTrackingLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.signaturesTracking.loadingStatus

const getScenariosPowerUsersLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.scenariosPowerUsers.loadingStatus

const getScenariosPowerUsersSortByTotalClicks = (state: GlobalStates) =>
  selectAllScenariosPowerUsers(state).sort((a, b) =>
    a.totalClicks > b.totalClicks ? -1 : 1,
  )

const getScenariosTrackingLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.scenariosTracking.loadingStatus

const getScenariosTrackingDataSortByTotalClicks = (state: GlobalStates) => {
  const { startDate, endDate } = getScenariosDateRange(state)

  return selectAllScenariosTracking(state)
    .map((sTrack) => ({
      ...sTrack,
      totalClicks:
        ScenarioTrackingMapper.getTotalClicksFromScenarioTrackingHistory(
          sTrack.history,
          startDate,
          endDate,
        ),
    }))
    .sort((a, b) => (a.totalClicks > b.totalClicks ? -1 : 1))
}

const getBannersClickedLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.tracking.bannersClickedDatas.loadingStatus

const getBannersClickedRawData = (state: GlobalStates) =>
  state.tracking.bannersClickedDatas.rawData

const getBannersHistoryLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.tracking.bannersHistoryDatas.loadingStatus

const getBannersHistoryRawData = (state: GlobalStates): BannersHistoryDto[] =>
  state.tracking.bannersHistoryDatas.rawData

const getSignaturesClickedLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.signaturesClickedDatas.loadingStatus

const getSignaturesHistoryLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.signaturesHistoryDatas.loadingStatus

const getSignaturesHistoryRawData = (
  state: GlobalStates,
): SignaturesHistoryDto[] => state.tracking.signaturesHistoryDatas.rawData

const getSignaturesClickedRawData = (state: GlobalStates) =>
  state.tracking.signaturesClickedDatas.rawData

const getSignaturesDeliveredLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.tracking.signaturesDelivered.loadingStatus

const getScenariosDateRange = (state: GlobalStates): QueriesDateRange => {
  const { startDate, endDate } = state.tracking.scenarios.queries

  if (startDate === null || endDate === null)
    return { startDate: null, endDate: null }

  return { startDate, endDate }
}

const getSingleScenarioDateRange = (state: GlobalStates): QueriesDateRange => {
  const { startDate, endDate } = state.tracking.scenarios.singleItemQuery

  if (startDate === null || endDate === null)
    return { startDate: null, endDate: null }

  return { startDate, endDate }
}

const getSignaturesDateRange = (
  state: GlobalStates,
): QueriesDateRange | null => {
  const { startDate, endDate } = state.tracking.signatures.queries

  if (startDate === null || endDate === null) return null

  return { startDate, endDate }
}

const getAllScenariosTrackingTotalClicksFromDateRange =
  (from: string, to: string) => (state: GlobalStates) =>
    selectAllScenariosTracking(state)
      .map((sTrack) =>
        ScenarioTrackingMapper.getTotalClicksFromScenarioTrackingHistory(
          sTrack.history,
          from,
          to,
        ),
      )
      .reduce((acc, curr) => acc + curr, 0)

const getAllScenariosTrackingMaxAffectedUsers = (state: GlobalStates) =>
  Math.max(
    ...selectAllScenariosTracking(state).map((sTrack) => {
      if (sTrack.affectedUsersHistory.length === 0) return 0

      return Math.max(
        ...sTrack.affectedUsersHistory.map((h) => h.affectedUsers),
      )
    }),
  )

const getSingleScenarioTrackingCurrentHistory =
  (id: number) => (state: GlobalStates) =>
    selectScenarioTrackingById(state, id)?.history || []

const getSingleScenarioTrackingCurrentAffectedUsersHistory =
  (id: number) => (state: GlobalStates) =>
    selectScenarioTrackingById(state, id)?.affectedUsersHistory || []

const getSingleScenarioTrackingTotalClicksFromDateRange =
  (id: number, from: string, to: string) => (state: GlobalStates) => {
    const selected = selectScenarioTrackingById(state, id)

    return ScenarioTrackingMapper.getTotalClicksFromScenarioTrackingHistory(
      selected.history,
      from,
      to,
    )
  }

const getSingleScenarioTrackingMaxClicksFromDateRange =
  (id: number, from: string, to: string) => (state: GlobalStates) => {
    const selected = selectScenarioTrackingById(state, id)
    return ScenarioTrackingMapper.getMaxClicksFromScenarioTrackingHistory(
      selected.history,
      from,
      to,
    )
  }

const getSingleScenarioTrackingMaxAffectedUsers =
  (id: number) => (state: GlobalStates) => {
    const selected = selectScenarioTrackingById(state, id)

    return (selected?.affectedUsersHistory || []).reduce(
      (prev, curr) => (prev.affectedUsers > curr.affectedUsers ? prev : curr),
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        affectedUsers: 0,
      },
    )
  }

const getSingleScenarioTrackingLoadingStatus =
  (id: number) => (state: GlobalStates) =>
    selectScenarioTrackingById(state, id)?.loadingStatus

export const selectors = {
  getNbUsersWithLicence,
  getSignaturesMetrics,
  getNbSignatures,
  getNbBanners,
  getBannersMetrics,
  getBannerMetricsByDate,
  getAllSignaturesDeployment: selectAllSignaturesDeployment,
  getSignatureDeploymentById: selectSignatureDeploymentById,
  getSignaturesDeploymentLoadingStatus,
  getAllSignaturesPowerUsers: selectAllSignaturesPowerUsers,
  getSignaturesPowerUserById: selectSignaturesPowerUserById,
  getSignaturesPowerUsersLoadingStatus,
  getSignaturesPowerUsersSortByTotalClicks,
  getAllSignaturesTracking: selectAllSignaturesTracking,
  getSignatureTrackingById: selectSignatureTrackingById,
  getSignaturesTrackingLoadingStatus,
  getAllScenariosPowerUsers: selectAllScenariosPowerUsers,
  getScenariosPowerUserById: selectScenariosPowerUserById,
  getScenariosPowerUsersSortByTotalClicks,
  getScenariosPowerUsersLoadingStatus,
  getAllScenariosTracking: selectAllScenariosTracking,
  getScenarioTrackingById: (id: number) => (state) =>
    selectScenarioTrackingById(state, id),
  getScenariosTrackingLoadingStatus,
  getScenariosTrackingDataSortByTotalClicks,
  getSingleScenarioTrackingCurrentHistory,
  getSingleScenarioTrackingCurrentAffectedUsersHistory,
  getAllScenariosTrackingTotalClicksFromDateRange,
  getAllScenariosTrackingMaxAffectedUsers,
  getSingleScenarioTrackingTotalClicksFromDateRange,
  getSingleScenarioTrackingMaxClicksFromDateRange,
  getSingleScenarioTrackingLoadingStatus,
  getSingleScenarioTrackingMaxAffectedUsers,
  getAllSignaturesDelivered: selectAllSignaturesDelivered,
  getAllSignaturesDeliveredById: selectSignaturesDeliveredById,
  getSignaturesDeliveredLoadingStatus,
  getBannersClickedLoadingStatus,
  getBannersClickedRawData,
  getBannersHistoryLoadingStatus,
  getBannersHistoryRawData,
  getSignaturesClickedLoadingStatus,
  getSignaturesClickedRawData,
  getSignaturesHistoryLoadingStatus,
  getSignaturesHistoryRawData,
  getScenariosDateRange,
  getSingleScenarioDateRange,
  getSignaturesDateRange,
  getSignatureDeploymentUsersInfos,
}
