export const LOGIN_URL = "/"

export const REGISTRATION_URL = "/register"

export const CONFIRM_USER_URL = "/account/definePassword"

export const RESET_PASSWORD_URL = "/account/resetpassword"

export const FORGOT_PASSWORD_URL = "/account/forgotpassword"

export const ACCOUNT_INFORMATIONS_URL = "/portal/account"

export const HOME_PORTAL_URL = "/portal"

export const ON_BOARDING_URL = "/portal/on-boarding"

export const ADDITIONAL_INFOS_URL = "/portal/additional-infos"

export const TRIAL_TUNNEL_URL = "/portal/trial"

export const SIGNATURES_URL = "/portal/signature"

export const SIGNATURES_BUILDER_URL = "/portal/builder"

export const SUBSIDIARIES_URL = "/portal/subsidiaries"

export const USERS_URL = "/portal/users"

export const GROUPS_URL = "/portal/groups"

export const ENROLLMENT_URL = "/portal/enrollment"

export const SUBSCRIPTION_URL = "/portal/subscription"

export const EXPIRED_URL = "/portal/expired"

export const PARAMETERS_URL = "/portal/parameters"

export const CAMPAIGNS_URL = "/portal/campaigns"

export const TRACKING_URL = "/portal/statistiques"
