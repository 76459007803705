import { FunctionComponent, ReactNode } from "react"

import { useSelector } from "react-redux"

import classNames from "classnames"

import { accountsSelectors } from "features/Accounts"

interface RegisterFormSubmitButtonProps {
  children: ReactNode
}

const RegisterFormSubmitButton: FunctionComponent<
  RegisterFormSubmitButtonProps
> = ({ children }) => {
  const isCreatingAccount = useSelector(accountsSelectors.getLoadingStatus)

  return (
    <button
      className={classNames(
        "register-form__submit-button",
        isCreatingAccount && "is-saving",
      )}
      type="submit"
    >
      {children}
    </button>
  )
}

export default RegisterFormSubmitButton
