import { TourGuideActionTypes, TourGuideActions } from "./"
import Tour from "entities/Tour"
import { combineReducers, Reducer } from "redux"

/** Reducer type  */
interface TourGuideReducer {
  display: boolean
  tours: Tour[]
  currentTour: Tour
  firstVisit: boolean
}

const tourGuideInitial = {
  display: false,
  firstVisit: false,
  currentTour: null,
  tours: [],
}

const toursReducer: Reducer<Tour[]> = (
  tours = tourGuideInitial.tours,
  action: TourGuideActions,
) => {
  switch (action.type) {
    case TourGuideActionTypes.INIT_TOURGUIDE_SUCCESS:
    case TourGuideActionTypes.EXIT_TOUR_GUIDE_SUCCESS:
      return action.tourguide.tours
    default:
      return tours
  }
}

const currentTourReducer: Reducer<Tour> = (
  state = tourGuideInitial.currentTour,
  action: TourGuideActions,
) => {
  switch (action.type) {
    case TourGuideActionTypes.START_TOUR_SUCCESS:
    case TourGuideActionTypes.NEXT_TOUR_STEP:
    case TourGuideActionTypes.PREV_TOUR_STEP:
      return action.tour
    case TourGuideActionTypes.EXIT_TOUR_GUIDE:
    case TourGuideActionTypes.NOT_DISPLAY_ANYMORE:
      return null
    default:
      return state
  }
}

const displayReducer: Reducer<boolean> = (
  display = tourGuideInitial.display,
  action: TourGuideActions,
) => {
  switch (action.type) {
    case TourGuideActionTypes.INIT_TOURGUIDE_SUCCESS:
      return action.tourguide.display
    case TourGuideActionTypes.SHOW_TOUR_GUIDE:
      return true
    case TourGuideActionTypes.NOT_DISPLAY_ANYMORE:
      return false
    default:
      return display
  }
}

const firstVisitReducer: Reducer<boolean> = (
  firstVisit = tourGuideInitial.firstVisit,
  action: TourGuideActions,
) => {
  switch (action.type) {
    case TourGuideActionTypes.INIT_TOURGUIDE_SUCCESS:
      return action.tourguide.firstVisit
    case TourGuideActionTypes.START_TOUR_SUCCESS:
    case TourGuideActionTypes.PASSTHROUGHT_TOURGUIDE:
      return false
    default:
      return firstVisit
  }
}

export default combineReducers<TourGuideReducer>({
  tours: toursReducer,
  currentTour: currentTourReducer,
  display: displayReducer,
  firstVisit: firstVisitReducer,
})
