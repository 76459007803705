import { AxiosRequestConfig, AxiosResponse } from "axios"

import { SortOrders } from "components/PaginatedDataGrid/PaginatedDataGrid"

export enum DiffusionModeEnum {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export enum SendingModeEnum {
  NEWMAIL = "newMail",
  INRESPONSE = "inResponse",
}

export enum LoadingStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export enum SavingStatus {
  IDLE = "IDLE",
  SAVING = "SAVING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface TableStateSortBy {
  id: string
  desc: boolean
}

export interface ITableState {
  pageIndex: number
  pageSize: number
  sortBy: Array<TableStateSortBy>
}

export class TypedResult<T> {
  result: T
  errorMessage: string
  timeGenerated: Date
  success: boolean
  httpStatus: number
  limitationsReach = false

  constructor(json: AxiosResponse) {
    if (json === null)
      throw new Error("Result: la reponse de l'appel API est vide.")

    this.httpStatus = json.status
    this.success = json.status.toString().startsWith("20")

    if (json.data && json.data.limitationsReach)
      this.limitationsReach = json.data.limitationsReach

    if (!this.success) this.errorMessage = JSON.stringify(json.data)

    this.result = json.data && json.data.result
    // si le Code HTTP n'est pas un succès, alors l'API retourne le message d'erreur est dans les datas
    this.timeGenerated = json.data && json.data.timeGenerated
  }
}

export class PaginatedResult<T> extends TypedResult<T> {
  currentPage: number
  lastPage: number
  totalCount: number
  pageSize: number

  constructor(json: AxiosResponse) {
    super(json)
    const paginationHeader = json.headers["x-pagination"]
    const parsedPagination = paginationHeader && JSON.parse(paginationHeader)

    this.currentPage = parsedPagination.CurrentPage
    this.lastPage = parsedPagination.TotalPages
    this.totalCount = parsedPagination.TotalCount
    this.pageSize = parsedPagination.PageSize
  }
}

interface PaginatedRequestParams {
  pageSize: number
  pageNumber: number
}

export interface PaginatedRequestConfig extends AxiosRequestConfig {
  params: PaginatedRequestParams
}

export interface PaginationData {
  currentPage: number
  lastPage: number
  pageSize: number
  orderBy: SortOrders
  search: string
  loadingStatus: LoadingStatus
  totalCount: number
}

export interface ViewManagement {
  loadingStatus: LoadingStatus
}
