import cuid from "cuid"

const isEntity = <T>(v: Entity<T>): v is Entity<T> => {
  return v instanceof Entity
}

export abstract class Entity<T> {
  public readonly id: number
  public props: T

  constructor(props: T, id?: number) {
    this.props = props
    this.id = id
  }

  public equals(object?: Entity<T>): boolean {
    if (object == null || object == undefined) {
      return false
    }

    if (this === object) {
      return true
    }

    if (!isEntity(object)) {
      return false
    }

    return this.id == object.id
  }
}
