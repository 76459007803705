import { createAction } from "@reduxjs/toolkit"

import { BuilderSignature } from "./BuilderSignaturesModels"

export const deleteBuilderSignatureAction = createAction(
  "builderSignatures/delete",
  function prepare(builderSignatureId: number) {
    return {
      payload: builderSignatureId,
    }
  },
)

export const toggleBuilderSignatureAction = createAction(
  "builderSignatures/toggleSignature",
  function prepare(builderSignatureId: BuilderSignature) {
    return {
      payload: builderSignatureId,
    }
  },
)

export const loadBuilderSignatureUsersAction = createAction(
  "builderSignatures/loadBuilderSignatureUsers",
  function prepare(signatureId: number) {
    return {
      payload: signatureId,
    }
  },
)

export const loadBuilderSignatureGroupsAction = createAction(
  "builderSignatures/loadBuilderSignatureGroups",
  function prepare(signatureId: number) {
    return {
      payload: signatureId,
    }
  },
)

export const sendBuilderSignaturePreviewAction = createAction(
  "builderSignatures/sendBuilderSignaturePreview",
  function prepare(signatureId: number, recepientsEmails: string[]) {
    return { payload: { signatureId, recepientsEmails } }
  },
)

export const initActiveBuilderSignatureAction = createAction(
  "builderSignatures/initActiveBuilderSignature",
  function prepare(signatureId: number | null) {
    return {
      payload: signatureId,
    }
  },
)

export const duplicateBuilderSignatureAction = createAction(
  "builderSignatures/duplicateBuilderSignature",
  function prepare(builderSignature: BuilderSignature) {
    return {
      payload: builderSignature,
    }
  },
)
