import { FunctionComponent } from "react"

import { SignatureRenderer } from "bmm-builder"

import SignaturePreview from "features/Signatures/components/SignaturePreview"

interface SignatureDeploymentPreviewProps {
  template: string
  isBuilder: boolean
  top: number
  left: number
}

const relativeOffset = { top: 15, left: 10 }

const SignatureDeploymentPreview: FunctionComponent<
  SignatureDeploymentPreviewProps
> = ({ template, isBuilder, top, left }) => {
  return (
    <div
      className="sign-depl-preview"
      style={{
        top: `${top + relativeOffset.top}px`,
        left: `${left - relativeOffset.left}px`,
      }}
    >
      {isBuilder ? (
        <SignatureRenderer templateJSON={template} scale={0.8} />
      ) : (
        <SignaturePreview template={template} cardHeight={160} />
      )}
    </div>
  )
}

export default SignatureDeploymentPreview
