/**
 * Service de log des erreurs innatendues
 */
import { PayloadAction } from "@reduxjs/toolkit"

import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from "redux-saga/effects"

import { DomainErrorDto, ErrorService } from "./ErrorsService"

import { sagaError } from "./ErrorsActions"
import { ErrorLog, ErrorMessage, ErrorTarget } from "./ErrorsModel"
import { catchFrontError } from "./ErrorsReducer"

import config from "config/config"

// Routing des erreurs gérés via les ActionTypes? Yes
// On passe ensuite dans une saga router pour gérer le routing soit vers un sink d'erreur
// Soit vers le reducer, sur lequel sera branché la Notification
export function* logErrorAsync(action: PayloadAction<ErrorLog>): Generator<
  | CallEffect<Generator<void, void, unknown>>
  | PutEffect<{
      payload: ErrorMessage
      type: string
    }>,
  void,
  unknown
> {
  const mustNotify = (target: ErrorTarget) => action.payload?.target === target

  const mustNotifyBackend =
    mustNotify(ErrorTarget.Backend) || mustNotify(ErrorTarget.Both)
  const mustNotifyFrontend =
    mustNotify(ErrorTarget.Frontend) || mustNotify(ErrorTarget.Both)

  if (mustNotifyBackend || action.payload.message != null)
    yield call(logBackEndErrorAsync, action)

  if (mustNotifyFrontend) {
    yield put(
      catchFrontError({
        message: action.payload.message,
        feature: action.payload.feature,
      }),
    )
  }
}

export function* logBackEndErrorAsync(
  action: PayloadAction<ErrorLog>,
): Generator<void, void, unknown> {
  const errorService: ErrorService = new ErrorService(config.logUrl)
  const error: DomainErrorDto = {
    message: action.payload.message,
    stack: action.payload.stack,
    action: action.type,
    feature: action.payload.feature,
  }
  yield errorService.catchError(error)
}

export const withSagaErrorHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  saga: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  failure?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  cleanup?: any,
  logLevel: ErrorTarget = ErrorTarget.Both,
) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  function* (action: any) {
    try {
      yield call(saga, action)
    } catch (err) {
      const error = new Error(err.message)
      yield put(sagaError(error.message, error.stack, logLevel, action.type))
      if (failure) {
        yield put(failure)
      }
    } finally {
      if (cleanup) {
        yield put(cleanup)
      }
    }
  }

export function* logFrontendErrorAsync(
  action: PayloadAction<ErrorLog>,
): Generator<void, void, unknown> {
  const errorService: ErrorService = new ErrorService(config.logUrl)
  const error: DomainErrorDto = {
    message: action.payload.message,
    stack: action.payload.stack,
    action: action.type,
    feature: action.payload.feature,
  }
  yield errorService.catchError(error)
}

export const errorWatcher = [
  takeLatest((action) => action.type.startsWith("error/"), logErrorAsync),
]
