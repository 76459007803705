import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import Weighting from "components/Weighting"

import { builderSignaturesSelectors } from "features/BuilderSignatures"

import { setEditingBuilderSignatureWeighting } from "features/BuilderSignatures/BuilderSignaturesReducers"

const messages = defineMessages({
  priority: {
    id: "BuilderParametersPanel.Priority",
    defaultMessage: "Priorité",
  },
  informations: {
    id: "BuilderParametersPanel.PriorityInformations",
    defaultMessage:
      "Modifier le curseur de priorité pour définir la signature qui s'affichera en priorité par rapport à d'autres",
  },
})

const WeightingBlock: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const currentWeighting = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureWeightingValue,
  )
  return (
    <>
      <p className="weighting-block__label">
        {formatMessage(messages.priority)}
      </p>
      <p className="weighting-block__infos">
        {formatMessage(messages.informations)}
      </p>
      <div className="weighting-block__weighting">
        <Weighting
          weighting={currentWeighting}
          onChange={(weighting) => {
            dispatch(setEditingBuilderSignatureWeighting(weighting))
          }}
        />
      </div>
    </>
  )
}

export default WeightingBlock
