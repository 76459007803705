import CalendarRange from "../CalendarView/entities/CalendarRange"
import CalendarCard from "."
import Banner from "entities/Banner"

interface OwnProps {
  range: CalendarRange
  isExtended?: boolean
  onClose?: () => void
  onChange?: (banner: Banner) => void
  onSelect?: (id: number) => void
  editable?: boolean
  setEditable?: (id: number) => void
  signaturesLoaded?: boolean
  signatureUsersLoaded?: boolean
}

const ConnectedCalendarCard = (props: OwnProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (_range: CalendarRange) => null

  return <CalendarCard {...props} onChange={onChange}></CalendarCard>
}

export default ConnectedCalendarCard
