import React, { FunctionComponent, ReactNode } from "react"

import HoverTooltip from "components/HoverTooltip"

interface HeaderWithTipProps {
  children: ReactNode
  message: ReactNode
}

const HeaderWithTip: FunctionComponent<HeaderWithTipProps> = ({
  children,
  message,
}) => {
  return (
    <div className="sync-properties-grid__header-with-tip">
      <div className="sync-properties-grid__header-with-tip--label">
        {children}
      </div>
      <HoverTooltip tooltip={message} level="info">
        <i className="material-icons sync-properties-grid__header-with-tip--icon">
          info
        </i>
      </HoverTooltip>
    </div>
  )
}

export default HeaderWithTip
