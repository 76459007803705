export default function CypressStoreSubscriber() {
  const subscribers = {}

  function publish(eventName, data) {
    if (!Array.isArray(subscribers[eventName])) {
      return
    }
    subscribers[eventName].forEach((callback) => {
      callback(data)
    })
  }

  function unsubscribeEvent(eventName){
    const index = subscribers[eventName].length - 1
    subscribers[eventName].splice(index, 1)
  }

  function subscribe(eventName, callback) {
    if (!Array.isArray(subscribers[eventName])) {
      subscribers[eventName] = []
    }
    subscribers[eventName].push(callback)
    const index = subscribers[eventName].length - 1

    return {
      unsubscribe() {
        subscribers[eventName].splice(index, 1)
      },
    }
  }

  return {
    publish,
    subscribe,
    unsubscribeEvent
  }
}
