import { FunctionComponent, ReactNode } from "react"

import LeftSideColumn, { LeftSideColumnType } from "./components/LeftSideColumn"
import RightSideColumn, {
  RightSideColumnType,
} from "./components/RightSideColumn"

interface TwoColumnsLayoutComposition {
  Left: LeftSideColumnType
  Right: RightSideColumnType
}

interface TwoColumnsLayoutProps {
  children: ReactNode
}

type TwoColumnsLayoutType = FunctionComponent<TwoColumnsLayoutProps>

const TwoColumnsLayout: TwoColumnsLayoutType & TwoColumnsLayoutComposition = ({
  children,
}) => {
  return <div className="two-columns-layout">{children}</div>
}

TwoColumnsLayout.Left = LeftSideColumn
TwoColumnsLayout.Right = RightSideColumn

export default TwoColumnsLayout
