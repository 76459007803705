import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

interface SignatureCardDateProps {
  type: "activated" | "lastUpdate"
  date: string
}

const messages = defineMessages({
  activated: {
    id: "SignatureCard.ActivatedSince",
    defaultMessage: "Activé depuis le",
  },
  updated: {
    id: "SignatureCard.LastUpdate",
    defaultMessage: "Dernière modification le",
  },
})

export type SignatureCardDateType = FunctionComponent<SignatureCardDateProps>

const SignatureCardDate: SignatureCardDateType = ({ type, date }) => {
  const { formatMessage } = useIntl()

  let label: string

  switch (type) {
    case "activated":
      label = formatMessage(messages.activated)
      break
    case "lastUpdate":
      label = formatMessage(messages.updated)
      break
    default:
      break
  }

  return <div className="signature-card__infos--date">{`${label} ${date}`}</div>
}

export default SignatureCardDate
