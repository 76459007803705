/**
 * Composant Wrapper - Encapsule le Material Ui Field pour pouvoir préciser le style générique qu'une seule fois.
 */
import React from "react"
import TextBox from "./TextBox"
import { injectIntl, IntlShape, defineMessages } from "react-intl"

const styles = {
  inputName: {
    underlineStyle: {
      borderColor: "#4776E6",
    },
    floatingLabelStyle: {
      color: "#747475",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
    },
    floatingLabelFocusStyle: {
      color: "#4776E6",
      fontSize: "0.9rem",
    },
  },
}

interface State {
  value: string
  errorText: string
  isFocus: boolean
}

interface IntlProps {
  intl: IntlShape
}

const messages = defineMessages({
  mandatory: {
    id: "TextBox.MandatoryField",
    defaultMessage: "Champ obligatoire",
  },
})

class UnderlineTextBox extends React.Component<any & IntlProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      errorText: null,
      isFocus: false,
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      value: newProps.value,
    })
  }

  validate = (value: string) => {
    if (!this.props.validators || this.props.validators.length === 0)
      return true

    this.props.validators.map((validator) => {
      if (validator.checker(value) === false) {
        this.setState({ errorText: validator.errorText })
        return false
      }
      return true
    })
    return true
  }

  handleInputChange = (evt) => {
    const value = evt.target.value
    if (this.state.isFocus) {
      if (this.props.mandatory && value === "") {
        this.setState({
          errorText: this.props.intl.formatMessage(messages.mandatory),
        })
      } else {
        this.setState({ errorText: "" })
      }
      this.validate(value)
    }
    this.setState({ value })
    this.props.onChange(evt)
  }

  handleInputFocus = (evt) => {
    this.setState({ isFocus: true })
  }

  handleInputBlur = (evt) => {
    if (this.state.isFocus) {
      if (this.props.mandatory && evt.target.value === "") {
        this.setState({
          errorText: this.props.intl.formatMessage(messages.mandatory),
        })
      } else {
        this.setState({ errorText: "" })
      }
      this.validate(evt.target.value)
    }
    this.setState({ isFocus: false })
    if (this.props.onBlur) this.props.onBlur(evt)
  }

  render() {
    const { errorText } = this.state
    const hintText =
      this.props.mandatory && this.props.hintText != null
        ? `${this.props.hintText} *`
        : this.props.hintText
    const textFieldProps = { ...this.props }

    // delete textFieldProps.mandatory;
    // delete textFieldProps.validators;
    delete textFieldProps.intl

    return (
      <TextBox
        data-cy={this.props["data-cy"]}
        underlineFocusStyle={styles.inputName.underlineStyle}
        floatingLabelFocusStyle={styles.inputName.floatingLabelFocusStyle}
        floatingLabelStyle={styles.inputName.floatingLabelStyle}
        {...textFieldProps}
        className={"bmm-textbox " + this.props.className}
        value={this.state.value}
        errorText={errorText || this.props.errorText}
        hintText={hintText}
        onChange={this.handleInputChange}
        onBlur={this.handleInputBlur}
      />
    )
  }
}

export default injectIntl(UnderlineTextBox)
