import { TypedResult } from "core/CoreModels"
import ServiceApi from "core/services/serviceApi"

import { StorageImageDto, StorageMetadatas, StorageTags } from "./StorageModels"

export function fetchAllImages(
  tags: string[] = [],
): Promise<TypedResult<StorageImageDto[]>> {
  const requestedTags = tags.length === 0 ? undefined : tags.join(",")
  return ServiceApi.request("GET", "/storage/images", {
    params: { tags: requestedTags },
  })
}

export function uploadImageToStorage(
  file: File,
  tags: StorageTags = {},
  metadatas: StorageMetadatas = {},
): Promise<TypedResult<StorageImageDto>> {
  const formData = new FormData()
  formData.append("formFile", file)
  formData.append("fileName", file.name)
  formData.append("fileType", file.type)
  formData.append("metadatas", JSON.stringify(metadatas))
  formData.append("tags", JSON.stringify(tags))

  return ServiceApi.uploadFileRequest("POST", "/storage/images", {
    data: formData,
  })
}

export function uploadImageToCommonStorage(
  file: File,
  tags: StorageTags = {},
  metadatas: StorageMetadatas = {},
): Promise<TypedResult<StorageImageDto>> {
  const formData = new FormData()
  formData.append("formFile", file)
  formData.append("fileName", file.name)
  formData.append("fileType", file.type)
  formData.append("metadatas", JSON.stringify(metadatas))
  formData.append("tags", JSON.stringify(tags))

  return ServiceApi.uploadFileRequest("POST", "/storage/images/common", {
    data: formData,
  })
}

export function uploadImageToStorageAsUrl(
  url: string,
  fileName: string,
  fileType: string,
  tags: StorageTags = {},
  metadatas: StorageMetadatas = {},
): Promise<TypedResult<StorageImageDto>> {
  return ServiceApi.request("POST", "/storage/images/url", {
    data: {
      url,
      fileName,
      fileType,
      tags,
      metadatas,
    },
  })
}

export function deleteImageFromStorage(
  id: string,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("DELETE", `/storage/images/${id}`)
}
