import { FunctionComponent, useState } from "react"

import { useDispatch } from "react-redux"

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input"

import { addNotification } from "features/Notifications/NotificationReducer"
import { Notification } from "features/Notifications/NotificationModels"

import { updateAccountInformations } from "features/Accounts/AccountsReducer"

import AccountInformationsFormSubmitButton from "./AccountInformationsFormSubmitButton"
import { AccountInformations } from "features/Accounts/AccountsModels"

interface AccountInformationsFormPhoneNumberStepProps {
  nextRegisterStep: number
  label: string
  buttonLabel: string
  errorLabel: string
  submitButtonLocker: (value: string) => boolean
  payload: (value: string, stepRegister: number) => AccountInformations
  initialValue?: string
  placeholder?: string
}

const AccountInformationsFormPhoneNumberStep: FunctionComponent<
  AccountInformationsFormPhoneNumberStepProps
> = ({
  nextRegisterStep,
  label,
  placeholder,
  buttonLabel,
  errorLabel,
  initialValue = "",
  submitButtonLocker,
  payload,
}) => {
  const dispatch = useDispatch()
  const [phoneNumber, setPhoneNumber] = useState(initialValue)

  const handleSubmit = () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      dispatch(addNotification(new Notification(errorLabel, "ERROR")))
      return
    }
    dispatch(updateAccountInformations(payload(phoneNumber, nextRegisterStep)))
  }
  return (
    <>
      <p className="account-informations-form__step--label">{label}</p>
      <PhoneInput
        defaultCountry="FR"
        className="account-informations-form__phone-number"
        placeholder={placeholder}
        value={phoneNumber}
        onChange={(value) => setPhoneNumber(value)}
        autoFocus
        autoComplete="off"
      />
      <AccountInformationsFormSubmitButton
        disabled={submitButtonLocker(phoneNumber || "")}
        onClick={handleSubmit}
      >
        {buttonLabel}
      </AccountInformationsFormSubmitButton>
    </>
  )
}

export default AccountInformationsFormPhoneNumberStep
