import { Params } from "core/services/requestBuilderService"
import Signature from "entities/Signature"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"
import Group from "features/Groups/GroupsModels"
import { User } from "features/Users/UserModels"

export enum SignaturesActivatedStateFilter {
  ALL = "ALL",
  SHOW_ACTIVATED = "SHOW_ACTIVATED",
  SHOW_DESACTIVATED = "SHOW_DESACTIVATED",
}

export const SENDING_MODES = ["newMail", "inResponse"] as const

export const SIGNATURES_SLICES = [...SENDING_MODES] as const

// 1 - export ActionTypes Constants
export enum TypeKeys {
  // Signature
  ACTIVATE_SIGNATURE = "SIGNATURE/ACTIVATE_SIGNATURE",
  DUPLICATE_SIGNATURE = "SIGNATURE/DUPLICATE_SIGNATURE",
  // Utilisateurs
  ASSIGN_USERS = "SIGNATURE/ASSIGN_USERS",
  DISSOCIATE_USERS = "SIGNATURE/DISSOCIATE_USERS",
  DISSOCIATE_USERS_AND_GROUPS = "SIGNATURE/DISSOCIATE_USERS_AND_GROUPS",
  UPDATE_SIGNATURE_ASSIGNATIONS = "SIGNATURE/UPDATE_SIGNATURE_ASSIGNATIONS",
  LOAD_AFFECTED_USERS = "SIGNATURE/LOAD_AFFECTED_USERS",
  LOAD_AFFECTED_USERS_FAILURE = "SIGNATURE/LOAD_AFFECTED_USERS_FAILURE",
  LOAD_SIGNATURE_AFFECTED_USERS = "SIGNATURE/LOAD_SIGNATURE_AFFECTED_USERS",
  LOAD_SIGNATURE_AFFECTED_USERS_FAILURE = "SIGNATURE/LOAD_SIGNATURE_AFFECTED_USERS_FAILURE",
  LOAD_SIGNATURE_AFFECTED_GROUPS = "SIGNATURE/LOAD_SIGNATURE_AFFECTED_GROUPS",
  LOAD_ALL_AFFECTED_USERS = "SIGNATURE/LOAD_ALL_AFFECTED_USERS",
  SET_PREVIEWED_SIGNATURE = "SIGNATURE/SET_PREVIEWED_SIGNATURE",
  // Groups
  LOAD_AFFECTED_GROUPS = "SIGNATURE/LOAD_AFFECTED_GROUPS",
  LOAD_AFFECTED_GROUPS_SUCCESS = "SIGNATURE/LOAD_AFFECTED_GROUPS_SUCCESS",
  DISSOCIATE_GROUP = "SIGNATURE/DISSOCIATE_GROUP",
  DISSOCIATE_ALL_GROUPS = "SIGNATURE/DISSOCIATE_ALL_GROUPS",
  // Templates
  SELECT_TEMPLATE = "SIGNATURE/SELECT_TEMPLATE",
  SELECT_PREV_TEMPLATE = "SIGNATURE/SELECT_PREV_TEMPLATE",
  SELECT_NEXT_TEMPLATE = "SIGNATURE/SELECT_NEXT_TEMPLATE",
  UPDATE_ACTIVE_SIGNATURE_TEMPLATE = "SIGNATURE/UPDATE_ACTIVE_SIGNATURE_TEMPLATE",
  UPDATE_ACTIVE_SIGNATURE_TEMPLATE_FAILURE = "SIGNATURE/UPDATE_ACTIVE_SIGNATURE_TEMPLATE_FAILURE",
  SEARCH_SIGNATURES = "SIGNATURE/SEARCH_SIGNATURES",
  INSERT_BANNER_PROPERTY = "SIGNATURE/INSERT_BANNER_PROPERTY",
  OTHER = "SIGNATURE/NONHANDLE",
}

export interface DuplicateSignatureAction {
  type: TypeKeys.DUPLICATE_SIGNATURE
  newSignature: Signature
}

export interface ActivateSignatureAction {
  type: TypeKeys.ACTIVATE_SIGNATURE
  signature: Signature
}

export interface AssignUsersAction {
  type: TypeKeys.ASSIGN_USERS
  payload: number[]
}

export interface UpdateSignatureUsersAssignation {
  type: TypeKeys.UPDATE_SIGNATURE_ASSIGNATIONS
  selectedUsers: User[]
}

export interface UpdateSignatureGroupsAssignation {
  type: TypeKeys.UPDATE_SIGNATURE_ASSIGNATIONS
  selectedGroups: Group[]
}

export interface DissociateUsersAction {
  type: TypeKeys.DISSOCIATE_USERS
  selectedUser: User
}

export interface DissociateGroupAction {
  type: TypeKeys.DISSOCIATE_GROUP
  selectedGroup: Group
}

export interface DissociateUsersAndGroupsAction {
  type: TypeKeys.DISSOCIATE_USERS_AND_GROUPS
}

export interface InsertBannerPropsAction {
  type: TypeKeys.INSERT_BANNER_PROPERTY
}

export interface LoadAffectedUsersAction {
  type: TypeKeys.LOAD_AFFECTED_USERS
}

export interface LoadAffectedGroupsAction {
  type: TypeKeys.LOAD_AFFECTED_GROUPS
}

export interface LoadAllAffectedUsersAction {
  type: TypeKeys.LOAD_ALL_AFFECTED_USERS
}

export interface LoadSignatureAffectedUsersAction {
  type: TypeKeys.LOAD_SIGNATURE_AFFECTED_USERS
  signature: Signature
}

export interface SelectPrevTemplateAction {
  type: TypeKeys.SELECT_PREV_TEMPLATE
}

export interface SelectNextTemplateAction {
  type: TypeKeys.SELECT_NEXT_TEMPLATE
}

export interface SelectTemplateAction {
  type: TypeKeys.SELECT_TEMPLATE
  payload: {
    id: number
  }
}

export interface OtherAction {
  type: TypeKeys.OTHER
}

export type SignatureActionTypes =
  | AssignUsersAction
  | DissociateUsersAction
  | LoadAffectedUsersAction
  | LoadSignatureAffectedUsersAction
  | OtherAction

export const AT_LEAST_ONE_SIGNATURE_QUERY: Params<Signature> = {
  top: 1,
  fields: ["Id"],
  queries: {
    isNewBuilder: {
      operand: "isNewBuilder",
      operator: "eq",
      value: "false",
    },
  },
}

export const AT_LEAST_ONE_BUILDER_SIGNATURE_QUERY: Params<BuilderSignature> = {
  top: 1,
  fields: ["Id"],
  queries: {
    isNewBuilder: {
      operand: "isNewBuilder",
      operator: "eq",
      value: "true",
    },
  },
}
