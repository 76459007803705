import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"

import AccountInformationsFormFullNameStep from "../components/AccountInformationsFormFullNameStep"
import AccountInformationsFormSingleFieldStep from "../components/AccountInformationsFormSingleFieldStep"
import AccountInformationsFormCgsStep from "../components/AccountInformationsFormCgsStep"
import AccountInformationsFormSuccessStep from "../components/AccountInformationsFormSuccessStep"
import AccountInformationsFormPhoneNumberStep from "../components/AccountInformationsFormPhoneNumberStep"
import AccountInformationsFormTenantNameStep from "../components/AccountInformationsFormTenantNameStep"

import {
  ACCOUNT_INFOS_FIELD_MAX_LENGTH,
  ACCOUNT_INFOS_FIELD_MIN_LENGTH,
} from "features/Accounts/AccountsConstants"

const messages = defineMessages({
  companyLabel: {
    id: "AccountInformationsForm.CompanyLabel",
    defaultMessage: "Votre entreprise",
  },
  companyPlaceholder: {
    id: "AccountInformationsForm.CompanyPlaceholder",
    defaultMessage: "Nom de votre entreprise",
  },
  nextButton: {
    id: "AccountInformationsForm.NextButton",
    defaultMessage: "Suivant",
  },
  validateButton: {
    id: "AccountInformationsForm.ValidateButton",
    defaultMessage: "Valider l'inscription",
  },
  launchButton: {
    id: "AccountInformationsForm.ValidateButton",
    defaultMessage: "Lancer Boost My Mail",
  },
  employeesNumberLabel: {
    id: "AccountInformationsForm.EmployeesNumberLabel",
    defaultMessage: "Vos collaborateurs",
  },
  employeesNumberPlaceholder: {
    id: "AccountInformationsForm.EmployeesNumberPlaceholder",
    defaultMessage: "Nombre de vos collaborateurs",
  },
  fullNameLabel: {
    id: "AccountInformationsForm.FullNameLabel",
    defaultMessage: "Quels sont vos prénom et nom ?",
  },
  firstnamePlaceholder: {
    id: "AccountInformationsForm.FirstnamePlaceholder",
    defaultMessage: "Prénom",
  },
  lastnamePlaceholder: {
    id: "AccountInformationsForm.LastnamePlaceholder",
    defaultMessage: "nom",
  },
  phoneNumberLabel: {
    id: "AccountInformationsForm.PhoneNumberLabel",
    defaultMessage: "Quel est votre numéro de téléphone ?",
  },
  phoneNumberPlaceholder: {
    id: "AccountInformationsForm.PhoneNumberPlaceholder",
    defaultMessage: "Téléphone",
  },
  phoneNumberInvalidError: {
    id: "AccountInformationsForm.PhoneNumberInvalidError",
    defaultMessage: "Merci de saisir un numéro de téléphone valide",
  },
  functionNumberLabel: {
    id: "AccountInformationsForm.FunctionLabel",
    defaultMessage: "Quel est votre fonction ?",
  },
  functionPlaceholder: {
    id: "AccountInformationsForm.FunctionPlaceholder",
    defaultMessage: "Fonction",
  },
  cgsLabel: {
    id: "AccountInformationsForm.CgsLabel",
    defaultMessage: "Plus qu’un clic !",
  },
  cgsToggleLabel: {
    id: "AccountInformationsForm.CgsToggleLabel",
    defaultMessage: "Je comprend et j’accepte les termes des",
  },
  cgsLink: {
    id: "AccountInformationsForm.CgsLink",
    defaultMessage: "conditions générales de services",
  },
  cgsInformations: {
    id: "AccountInformationsForm.CgsInformations",
    defaultMessage:
      "En soumettant ce formulaire, vous acceptez que les informations saisies soient exploitées dans le cadre du traitement de votre demande et de la relation commerciale qui peut en découler. Vous bénéficiez d’un droit d’accès, de rectification et d’opposition.",
  },
  endOfSubscription: {
    id: "AccountInformationsForm.EndOfSubscription",
    defaultMessage: "Votre inscription est terminée",
  },
})

function validateLengthOfField(field: string) {
  return (
    field.length < ACCOUNT_INFOS_FIELD_MIN_LENGTH ||
    field.length > ACCOUNT_INFOS_FIELD_MAX_LENGTH
  )
}

const useAccountsInformationForm = () => {
  const { formatMessage } = useIntl()
  let component: FunctionComponent
  let componentProps: unknown
  const currentRegisterStep = useSelector(accountsSelectors.getRegisterStep)

  switch (currentRegisterStep) {
    case 1:
      component = AccountInformationsFormSingleFieldStep
      componentProps = {
        nextRegisterStep: 2,
        label: formatMessage(messages.employeesNumberLabel),
        buttonLabel: formatMessage(messages.nextButton),
        initialValue: "10",
        submitButtonLocker: (value: string) => parseInt(value, 10) === 0,
        payload: (value: string, stepRegister: number) => ({
          employeesNumber: parseInt(value, 10),
          stepRegister,
        }),
        validator: (value: string) => {
          if (value.length === 0) return "0"

          const isValidNumber = value.match(/^([0-9]*)$/gm)

          if (isValidNumber) return value
          return null
        },
      }
      break
    case 2:
      component = AccountInformationsFormFullNameStep
      componentProps = {
        nextRegisterStep: 3,
        label: formatMessage(messages.fullNameLabel),
        firstnamePlaceholder: formatMessage(messages.firstnamePlaceholder),
        lastnamePlaceholder: formatMessage(messages.lastnamePlaceholder),
        buttonLabel: formatMessage(messages.nextButton),
        submitButtonLocker: (firstname: string, lastname: string) =>
          validateLengthOfField(firstname) && validateLengthOfField(lastname),
        payload: (
          firstname: string,
          lastname: string,
          stepRegister: number,
        ) => ({
          firstname,
          lastname,
          stepRegister,
        }),
      }
      break
    case 3:
      component = AccountInformationsFormPhoneNumberStep
      componentProps = {
        nextRegisterStep: 4,
        label: formatMessage(messages.phoneNumberLabel),
        placeholder: formatMessage(messages.phoneNumberPlaceholder),
        buttonLabel: formatMessage(messages.nextButton),
        errorLabel: formatMessage(messages.phoneNumberInvalidError),
        submitButtonLocker: validateLengthOfField,
        payload: (value: string, stepRegister: number) => ({
          phoneNumber: value,
          stepRegister,
        }),
      }
      break
    case 4:
      component = AccountInformationsFormSingleFieldStep
      componentProps = {
        nextRegisterStep: 5,
        label: formatMessage(messages.functionNumberLabel),
        placeholder: formatMessage(messages.functionPlaceholder),
        buttonLabel: formatMessage(messages.nextButton),
        submitButtonLocker: validateLengthOfField,
        payload: (value: string, stepRegister: number) => ({
          function: value,
          stepRegister,
        }),
      }
      break
    case 5:
      component = AccountInformationsFormCgsStep
      componentProps = {
        nextRegisterStep: 6,
        label: formatMessage(messages.cgsLabel),
        toggleLabel: formatMessage(messages.cgsToggleLabel),
        cgsLinkMessage: formatMessage(messages.cgsLink),
        cgsInformations: formatMessage(messages.cgsInformations),
        buttonLabel: formatMessage(messages.validateButton),
        submitButtonLocker: (check: boolean) => !check,
        payload: (value: boolean, stepRegister: number) => ({
          cgs: value,
          stepRegister,
        }),
      }
      break
    case 6:
      component = AccountInformationsFormSuccessStep
      componentProps = {
        nextRegisterStep: 7,
        endOfSubscription: formatMessage(messages.endOfSubscription),
        buttonLabel: formatMessage(messages.launchButton),
      }
      break
    case 0:
    default:
      component = AccountInformationsFormTenantNameStep
      componentProps = {
        nextRegisterStep: 1,
        label: formatMessage(messages.companyLabel),
        placeholder: formatMessage(messages.companyPlaceholder),
        buttonLabel: formatMessage(messages.nextButton),
        submitButtonLocker: validateLengthOfField,
        payload: (value: string, stepRegister: number) => ({
          company: value,
          stepRegister,
        }),
      }
      break
  }

  return { component, componentProps }
}
export default useAccountsInformationForm
