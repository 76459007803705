import { useDispatch, useSelector } from "react-redux"

import { JobData } from "features/Jobs/JobsModels"

import { GlobalStates } from "store"
import { updateJob as updateJobAction } from "features/Jobs/JobsReducer"

interface UseJobs {
  jobsInBackgroundProcessChannel: Array<JobData>
  jobsInSignatureCompilation: Array<JobData>
  updateJob: (job: JobData) => void
}

const backgroundProcessJobNames = [
  /* Job Names Translations */
  "IDeployOwa",
  "IUpsell",
  "ISubscribe",
  "IDeployGmail",
  "ISyncGSuite",
  "ISyncO365",
  "ISyncLucca",
  "IDeploySalesforce",
  "IDeploySellsy",
  "SyncUsersOrchestratorJob",
]

const compilationJobs = [
  /* Job Names Translations */
  "CompileSignatureJob",
]

const useJobsQueue = (): UseJobs => {
  const dispatch = useDispatch()

  const jobByChannel = (channel: string[]) => (state: GlobalStates) =>
    state.jobs.queue.filter((j) => channel.indexOf(j.jobName) != -1)

  const jobInBackgroundProcessChannel = jobByChannel(backgroundProcessJobNames)
  const jobInCompilationChannel = jobByChannel(compilationJobs)

  const jobsInBackgroundProcessChannel = useSelector(
    jobInBackgroundProcessChannel,
  )
  const jobsInSignatureCompilation = useSelector(jobInCompilationChannel)

  const updateJob = (job: JobData) => {
    dispatch(updateJobAction(job))
  }

  return {
    jobsInBackgroundProcessChannel,
    jobsInSignatureCompilation,
    updateJob,
  }
}

export default useJobsQueue
