import { FunctionComponent, HTMLAttributes } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { useOnBoardingDisplay } from "features/Accounts/components/OnBoardingTile/hooks/useOnBoardingDisplay"

import { ReactComponent as IconDuotoneTracking } from "assets/duotone/icon-tracking-duotone.svg"

import Tile from "features/Accounts/components/HomePageSingleTile"

import TrackingTileSignaturesBlock from "./components/TrackingTileSignaturesBlock"
import TrackingTileCampaignsBlock from "./components/TrackingTileCampaignsBlock"
// import TrackingTileDelivered from "./components/TrackingTileDelivered"

import { TRACKING_URL } from "router/RouterConstants"

interface TrackingTileProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  isTrial: boolean
}

const messages = defineMessages({
  title: {
    id: "HomePage.TrackingTileTitle",
    defaultMessage: "Statistiques",
  },
})

// * [#5413] Le composant TrackingTileDelivered est retiré en attente de la correction de la valeur affichée
const TrackingTile: FunctionComponent<TrackingTileProps> = ({
  isTrial,
  className,
  ...others
}) => {
  const { formatMessage } = useIntl()

  const onBoardingDisplay = useOnBoardingDisplay()

  return (
    <Tile
      {...others}
      className={classNames(
        className,
        "tracking-tile",
        !onBoardingDisplay && "is-taller",
      )}
    >
      <Tile.Title
        icon={<IconDuotoneTracking />}
        label={formatMessage(messages.title)}
        to={!isTrial && TRACKING_URL}
      />
      {isTrial ? (
        <Tile.Datas>
          <Tile.NeedSubscription />
        </Tile.Datas>
      ) : (
        <>
          {/* {!onBoardingDisplay && <TrackingTileDelivered />} */}
          <TrackingTileSignaturesBlock />
          <TrackingTileCampaignsBlock />
        </>
      )}
    </Tile>
  )
}

export default TrackingTile
