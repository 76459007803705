import { FunctionComponent } from "react"

import { ReactComponent as IconUser } from "assets/icon-user.svg"

interface ScenarioCardAssignationsCountProps {
  affectedCount: number
  isLoading: boolean
}

export type ScenarioCardAssignationsCountType =
  FunctionComponent<ScenarioCardAssignationsCountProps>

const ScenarioCardAssignationsCount: ScenarioCardAssignationsCountType = ({
  affectedCount,
  isLoading,
}) => {
  return (
    <div className="scenario-card__assignations-count">
      <IconUser className="scenario-card__assignations-count--icon" />
      {isLoading ? (
        <div className="scenario-card__assignations-count--skeleton" />
      ) : (
        <p className="scenario-card__assignations-count--count">
          {affectedCount}
        </p>
      )}
    </div>
  )
}

export default ScenarioCardAssignationsCount
