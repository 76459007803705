import React from "react"
import config from "config/config"
import { getBase64, getResizedBase64 } from "../../utils/ImageUtils"

export const DEFAULT_IMAGE_URL = "/images/bmm/icon_import__hover.svg"
export const DEFAULT_IMAGE_HOVER_URL = "/images/bmm/icon_import.svg"

export interface Props {
  onImageChange?: (image: string) => void | null
  onFileChange: (image: File, refresh: boolean) => void | null
  imageUrl: string
  showPreview: boolean
  showHover?: boolean
  defaultImageUrl?: string
  onDelete?: () => void
  maxWidth?: number
  maxHeight?: number
  sizeLimit?: number
  handleSizeLimitReach?: (file: File) => void
}

interface State {
  imageUrl?: string
  imageSelected: boolean
  isHover: boolean
  openEditor: boolean
  defaultImage: string
  showEditHover: boolean
  showDeleteHover: boolean
}

class ImagePicker extends React.Component<Props, State> {
  private nativeInputFile: HTMLInputElement

  constructor(props) {
    super(props)
    const defaultImage =
      props.defaultImageUrl || config.cdnUrl + DEFAULT_IMAGE_URL

    // Si le template utilise l'image par défaut, on n'affiche pas cette image
    let imageUrl =
      !props.imageUrl || props.imageUrl === config.defaultImageUrl
        ? defaultImage
        : props.imageUrl

    if (!this.props.showPreview) imageUrl = defaultImage

    this.state = {
      imageSelected:
        props.imageUrl &&
        props.imageUrl !== config.defaultImageUrl &&
        props.imageUrl !== defaultImage,
      imageUrl,
      isHover: false,
      openEditor: false,
      defaultImage,
      showEditHover: false,
      showDeleteHover: false,
    }
  }

  componentWillReceiveProps(newProps: Props) {
    if (
      this.props.showPreview &&
      newProps.imageUrl &&
      newProps.imageUrl.indexOf(config.defaultImageUrl) === -1 &&
      newProps.imageUrl !== this.state.defaultImage
    )
      this.setState({ imageUrl: newProps.imageUrl, imageSelected: true })
    else
      this.setState({ imageUrl: this.state.defaultImage, imageSelected: false })

    if (!newProps.imageUrl)
      this.setState({ imageUrl: this.state.defaultImage, imageSelected: false })
  }

  handleFileChange = (files: FileList) => {
    const file = files[0]

    if (
      file != null &&
      this.props.sizeLimit != null &&
      file.size > this.props.sizeLimit
    ) {
      this.props.handleSizeLimitReach(files[0])
      return
    }

    const handleFileChangeCallback = (imageAsBase64Url) => {
      const updatedState: { imageSelected: boolean; imageUrl?: string } = {
        imageSelected: true,
      }
      if (this.props.showPreview) {
        updatedState.imageUrl = imageAsBase64Url.toString()
      }
      this.setState(updatedState)

      if (this.props.onImageChange)
        this.props.onImageChange(imageAsBase64Url.toString())

      if (this.props.onFileChange) this.props.onFileChange(file, true) // on force le rechargement de l'image
    }

    // Si une taille maximale est spécifiée, on redimensionne l'image
    if (this.props.maxWidth && this.props.maxHeight) {
      getResizedBase64(
        file,
        this.props.maxWidth,
        this.props.maxHeight,
        handleFileChangeCallback,
      )
    } else {
      // Sinon on utilise la taille naturelle
      getBase64(file, handleFileChangeCallback)
    }
  }

  onMouseOver = (evt) => {
    if (!this.state.imageSelected || !this.props.showPreview) {
      this.setState({
        imageUrl:
          this.props.defaultImageUrl || config.cdnUrl + DEFAULT_IMAGE_HOVER_URL,
      })
    }

    if (this.props.showHover && this.state.imageSelected) {
      this.setState({ showDeleteHover: true })
    }

    if (this.props.showHover && !this.state.imageSelected) {
      this.setState({ showEditHover: true })
    }

    this.setState({ isHover: true })
  }

  onMouseLeave = (evt) => {
    if (!this.state.imageSelected || !this.props.showPreview) {
      this.setState({ imageUrl: this.state.defaultImage })
    }

    this.resetHover()
  }

  getClass = () => {
    let classes = "image-picker image-picker__container "
    if (this.state.isHover) classes += "image-picker--hover "
    if (this.state.imageSelected) classes += "image-picker--image-selected"
    return classes
  }

  resetHover = () => {
    this.setState({
      isHover: false,
      showDeleteHover: false,
      showEditHover: false,
    })
  }

  handleClick = (evt) => {
    this.resetHover()
    this.nativeInputFile.value = null

    if (this.props.onDelete && this.state.imageSelected) {
      this.setState({
        imageSelected: false,
        imageUrl: this.state.defaultImage,
      })
      this.props.onDelete()
    } else {
      this.nativeInputFile.click()
    }
  }

  render() {
    return (
      <div
        className={this.getClass()}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        onClick={this.handleClick}
      >
        {this.state.isHover && this.props.showHover && (
          <div className="image-picker__hover">
            <div className="animated fadeIn image-picker__icon">
              {this.state.showEditHover && (
                <img
                  src={`${config.cdnUrl}/images/bmm/users/ic_photo.svg`}
                  alt="edit"
                />
              )}
              {this.state.showDeleteHover && (
                <img
                  src={`${config.cdnUrl}/images/bmm/icon_delete_hover.svg`}
                  alt="delete"
                />
              )}
            </div>
          </div>
        )}
        <img src={this.state.imageUrl} alt="" className="image-picker__logo" />
        <input
          accept="image/*"
          ref={(inputFile) => {
            this.nativeInputFile = inputFile
          }}
          type="file"
          style={{ display: "none" }}
          onChange={(evt) => this.handleFileChange(evt.target.files)}
        />
      </div>
    )
  }
}

export default ImagePicker
