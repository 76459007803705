import { FC, useEffect, useState } from "react";
import {Alert} from "../../AlertsModels"
import { ReactComponent as ArrowIcon} from "../../assets/icon-arrow.svg"

interface Props{
  alert: Alert
  removeAlertSelected: () => void
}

const AlertInfo: FC<Props> = ({alert, removeAlertSelected}) => {
  const [open, setOpen] = useState(true)

  const onClickHandler = (alert: Alert) => {
    window.open(alert.link, "_blank");
  }

  const handleClick = () => {
    setOpen(false)
    removeAlertSelected()
  }

  return <div
    className={`alert__infos__container
      ${open ?"alert__infos__container--open" : "alert__infos__container--hide"}`
    }
  >
    <div className="alert__infos">
      {
        alert?.image
        &&
        <img className="alert__infos__img" src={alert?.image} alt="" />
      }
      <div className="alert__infos__content">
        <div className="alert__infos__content__header">
          <ArrowIcon className="alert__infos__content__header__icon" onClick={() => handleClick()}/>
          <p className="alert__infos__content__title">{alert?.title}</p>
        </div>
        <p className="alert__infos__content__texte">{alert?.content}</p>
      </div>
      {
        alert?.link
        &&
        <div className="alert__infos__button">
          <button className="bmmbutton" onClick={() => onClickHandler(alert)}> Voir la page</button>
        </div>
      }
    </div>
  </div>
};

export default AlertInfo