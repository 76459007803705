import { FunctionComponent } from "react"

import Layout from "components/TwoColumnsLayout"
import LangPillSwitch from "features/App/components/LangPillSwitch"

import AccountsInformationsReInsurancePanel from "features/Accounts/components/AccountsInformationsReInsurancePanel"
import ProgressiveAccountInfosLogo from "features/Accounts/components/ProgressiveAccountInfosLogo"
import AccountInformationsForm from "features/Accounts/components/AccountInformationsForm"

const AccountInformationsPage: FunctionComponent = () => {
  return (
    <Layout>
      <Layout.Left>
        <AccountsInformationsReInsurancePanel />
      </Layout.Left>
      <Layout.Right>
        <LangPillSwitch />
        <ProgressiveAccountInfosLogo />
        <AccountInformationsForm />
      </Layout.Right>
    </Layout>
  )
}

export default AccountInformationsPage
