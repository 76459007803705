import { Dispatch, useEffect, useReducer } from "react"

import { ScenarioMode } from "features/Scenarios/ScenarioModels"
import { ScenarioTracking } from "../TrackingModels"

export type DiffusionModeFilterType = "all" | "internal" | "external"

export type ScenarioModeFilterType = "all" | ScenarioMode

interface ScenariosTrackingSliderFiltersStateType {
  scenarios: ScenarioTracking[]
  filteredScenarios: ScenarioTracking[]
  diffusionMode: DiffusionModeFilterType
  scenarioMode: ScenarioModeFilterType
}

export enum Types {
  Init = "INIT_DATA",
  FilterByDiffusionMode = "FILTER_BY_DIFFUSION_MODE",
  UnsetDiffusionModeFilter = "UNSET_DIFFUSION_MODE_FILTER",
  FilterByScenarioMode = "FILTER_BY_SCENARIO_MODE",
  UnsetScenarioModeFilter = "UNSET_SCENARIO_MODE_FILTER",
}

interface InitData {
  type: Types.Init
  payload: ScenarioTracking[]
}

interface FilterByDiffusionMode {
  type: Types.FilterByDiffusionMode
  payload: Omit<DiffusionModeFilterType, "all">
}

interface UnsetDiffusionModeFilter {
  type: Types.UnsetDiffusionModeFilter
}

interface FilterByScenarioMode {
  type: Types.FilterByScenarioMode
  payload: Omit<ScenarioModeFilterType, "all">
}

interface UnsetScenarioModeFilter {
  type: Types.UnsetScenarioModeFilter
}

export type ActionType =
  | InitData
  | FilterByDiffusionMode
  | UnsetDiffusionModeFilter
  | FilterByScenarioMode
  | UnsetScenarioModeFilter

interface UseScenariosTrackingSliderFilters {
  filteredScenarios: ScenarioTracking[]
  diffusionMode: DiffusionModeFilterType
  scenarioMode: ScenarioModeFilterType
  dispatch: Dispatch<ActionType>
}

const initialState: ScenariosTrackingSliderFiltersStateType = {
  scenarios: [],
  filteredScenarios: [],
  diffusionMode: "all",
  scenarioMode: "all",
}

function init(
  state: ScenariosTrackingSliderFiltersStateType,
  action: InitData,
) {
  return {
    ...state,
    filteredScenarios: action.payload,
    scenarios: action.payload.sort((a, b) => (a.created > b.created ? -1 : 1)),
  }
}

function filterByDiffusionMode(
  scenarios: ScenarioTracking[],
  diffusionMode: DiffusionModeFilterType,
) {
  if (diffusionMode === "all") return scenarios

  return scenarios.filter((scenario) => scenario[diffusionMode] === true)
}

function filterByScenarioMode(
  scenarios: ScenarioTracking[],
  scenarioMode: ScenarioModeFilterType,
) {
  if (scenarioMode === "all") return scenarios

  return scenarios.filter((scenario) => scenario.scenarioMode === scenarioMode)
}

function processScenariosFilter(scenarios, diffusionMode, scenarioMode) {
  return filterByScenarioMode(
    filterByDiffusionMode(scenarios, diffusionMode),
    scenarioMode,
  )
}

function reducer(
  state: ScenariosTrackingSliderFiltersStateType = initialState,
  action: ActionType,
): ScenariosTrackingSliderFiltersStateType {
  let newState: ScenariosTrackingSliderFiltersStateType
  const { scenarios, diffusionMode, scenarioMode } = state
  switch (action.type) {
    case Types.Init: {
      newState = init(state, action)
      break
    }
    case Types.FilterByDiffusionMode: {
      newState = {
        ...state,
        filteredScenarios: processScenariosFilter(
          scenarios,
          action.payload,
          scenarioMode,
        ),
        diffusionMode: action.payload as DiffusionModeFilterType,
      }
      break
    }
    case Types.UnsetDiffusionModeFilter: {
      newState = {
        ...state,
        filteredScenarios: processScenariosFilter(
          scenarios,
          "all",
          scenarioMode,
        ),
        diffusionMode: "all",
      }
      break
    }
    case Types.FilterByScenarioMode: {
      newState = {
        ...state,
        filteredScenarios: processScenariosFilter(
          scenarios,
          diffusionMode,
          action.payload,
        ),
        scenarioMode: action.payload as ScenarioModeFilterType,
      }
      break
    }
    case Types.UnsetScenarioModeFilter: {
      newState = {
        ...state,
        filteredScenarios: processScenariosFilter(
          scenarios,
          diffusionMode,
          "all",
        ),
        scenarioMode: "all",
      }
      break
    }
    default:
      throw new Error("Unknown action")
  }
  return newState
}

const useScenariosTrackingSliderFilters = (
  scenarios: ScenarioTracking[],
): UseScenariosTrackingSliderFilters => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { filteredScenarios, diffusionMode, scenarioMode } = state

  useEffect(() => {
    dispatch({ type: Types.Init, payload: scenarios })
  }, [])

  return { filteredScenarios, diffusionMode, scenarioMode, dispatch }
}

export default useScenariosTrackingSliderFilters
