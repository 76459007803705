import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import moment from "moment"

import useSignaturesQueriesDateRange from "features/Tracking/hooks/useSignaturesQueriesDateRange"

import TrackingDatePicker from "features/Tracking/components/TrackingDatePicker"

interface SingleSignatureTrackingHeaderProps {
  name: string
  created: string
  handleUnselect: () => void
}

const messages = defineMessages({
  created: {
    id: "Tracking.SingleSignatureTrackingHeaderCreated",
    defaultMessage: "Créé le",
  },
  ctaUnselect: {
    id: "Tracking.SingleSignatureTrackingHeaderUnselect",
    defaultMessage: "Annuler la sélection",
  },
})

const SingleSignatureTrackingHeader: FunctionComponent<
  SingleSignatureTrackingHeaderProps
> = ({ name, created, handleUnselect }) => {
  const { formatMessage } = useIntl()

  const { startDate, endDate, handleDateChange } =
    useSignaturesQueriesDateRange()

  return (
    <div className="single-sign-track__header">
      <div className="single-sign-track__title">
        <p className="single-sign-track__title--label">{name}</p>
        <p className="single-sign-track__title--created">{`${formatMessage(
          messages.created,
        )} ${moment(created).format("DD/MM/YYYY")}`}</p>
      </div>
      <TrackingDatePicker
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleDateChange}
      />
      <div className="single-sign-track__title--unselect">
        <button
          className="single-sign-track__title--btn"
          type="button"
          onClick={() => handleUnselect()}
        >
          {formatMessage(messages.ctaUnselect)}
        </button>
      </div>
    </div>
  )
}

export default SingleSignatureTrackingHeader
