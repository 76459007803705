import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"

import { formatAmount } from "utils/NumberHelper"

import iconOutlook from "features/Enrollment/assets/logo-outlook.svg"
import iconGsuite from "features/Enrollment/assets/logo-gsuite.svg"
import iconEmail from "features/Enrollment/assets/logo-email.svg"

import { DEFAULT_OFFER_CURRENCY } from "features/Offers/OffersConstants"

const messages = defineMessages({
  offer: {
    id: "Enrollment.SummaryOffer",
    defaultMessage: "Offre",
  },
  perMonth: {
    id: "Enrollment.SummaryHTPerMonth",
    defaultMessage: "HT / mois",
  },
  updateOffer: {
    id: "Enrollment.SummaryUpdateOffer",
    defaultMessage: "Changer d'offre",
  },
})

const clientIcons = {
  outlook: iconOutlook,
  gsuite: iconGsuite,
  email: iconEmail,
}

interface EnrollmentSummaryProps {
  onClick?: () => void
}

const EnrollmentSummary: FunctionComponent<EnrollmentSummaryProps> = ({
  onClick,
}) => {
  const { formatMessage, locale } = useIntl()
  const { enrollmentDatas } = useEnrollmentData()

  const optionsPrice = enrollmentDatas.options.reduce((sum, option) => {
    const price = option.getPricingFromLicencesNumber(enrollmentDatas.nbUsers)
    return isNaN(price) ? sum : sum + price
  }, 0)

  if (enrollmentDatas.selectedOffer === null) return null

  return (
    <div className="enroll-summary">
      <div className="enroll-summary__main">
        <p className="enroll-summary__main--name">
          {`${formatMessage(messages.offer)} ${
            enrollmentDatas?.selectedOffer.name
          }`}
        </p>
        <div className="enroll-summary__main--price-container">
          <p className="enroll-summary__main--price">
            {formatAmount(enrollmentDatas.priceMonthly, locale)}
            <span className="enroll-summary__main--currency">
              {DEFAULT_OFFER_CURRENCY.symbol}
            </span>
          </p>
          <p className="enroll-summary__main--per-month">
            {formatMessage(messages.perMonth)}
          </p>
        </div>
        <div className="enroll-summary__options--container">
          {enrollmentDatas.options.length ? (
            <div className="enroll-summary__options">
              <p className="enroll-summary__options--quantity">
                {`+ ${enrollmentDatas.options.length} ${
                  enrollmentDatas.options.length > 1 ? "options" : "option"
                } `}
              </p>
              <p className="enroll-summary__options--amount">
                {`(${formatAmount(optionsPrice, locale)}${
                  DEFAULT_OFFER_CURRENCY.symbol
                })`}
              </p>
            </div>
          ) : null}
          {enrollmentDatas.clients.map((client, i) => (
            <div key={i} className="enroll-summary__options--icon">
              <img src={clientIcons[client]} />
            </div>
          ))}
        </div>
      </div>
      <div className="enroll-summary__aside">
        <button
          type="button"
          className="enroll-summary__aside--button"
          onClick={() => {
            onClick && onClick()
          }}
        >
          {formatMessage(messages.updateOffer)}
        </button>
      </div>
    </div>
  )
}

export default EnrollmentSummary
