import React from "react"

interface Props {
  label: string
  href: string
  disabled?: boolean
}

class LinkButton extends React.Component<Props> {
  render() {
    const { label, href, disabled } = this.props

    return (
      <div className="link-button">
        <a
          className={`bmm-button bmm-button--mini-width ${
            disabled ? " link-button--disabled" : ""
          }`}
          href={href}
        >
          {label}
        </a>
      </div>
    )
  }
}

export default LinkButton
