import { FunctionComponent, ReactNode } from "react"

interface SignaturesTileActiveCountProps {
  children: ReactNode
  activeCount: number
}

const SignaturesTileActiveCount: FunctionComponent<
  SignaturesTileActiveCountProps
> = ({ children, activeCount }) => {
  return (
    <div className="signature-tile__active-count--container">
      <p className="signature-tile__active-count--all-count">{activeCount}</p>
      <p className="signature-tile__active-count--label">{children}</p>
    </div>
  )
}

export default SignaturesTileActiveCount
