import { ReactNode, FunctionComponent } from "react"

import NavigationButtons from "features/Scenarios/components/NavButtons"

import Stepper from "components/Stepper"

import type { NavigationData } from "features/Scenarios/components/NavButtons"

interface ScenarioFormProps {
  id?: number
  view?: ReactNode
  sidebar?: ReactNode
  activeStep?: number
  steps?: { to: string; label: ReactNode }[]
  mainHistory?: unknown
  stepper?: ReactNode
  nextData?: NavigationData
  prevData?: NavigationData
  next?: () => void
  prev?: () => void
}

const ScenarioForm: FunctionComponent<ScenarioFormProps> = ({
  view,
  sidebar,
  steps,
  activeStep,
  nextData,
  prevData,
  next,
  prev,
}) => {
  const stepLabels = steps.map((item) => item.label)

  return (
    <div className="CampaignForm">
      <div className="CampaignForm__stepper">
        <Stepper
          activeStep={activeStep}
          onClick={() => null}
          steps={stepLabels}
        />
      </div>
      <div className="CampaignForm__layout">
        <div className="CampaignForm__panel">{sidebar && sidebar}</div>
        <div className="CampaignForm__main">
          <div className="CampaignForm__content">{view}</div>
          <div className="CampaignForm__footer">
            <NavigationButtons
              nextData={nextData}
              prevData={prevData}
              onNext={next}
              onPrev={prev}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScenarioForm
