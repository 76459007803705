import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as DuplicateIcon } from "assets/icon-duplicate.svg"

import MouseTooltip from "components/MouseTooltip"

interface SignatureActionDuplicateActionProps {
  onDuplicate: () => void
}

export type SignatureActionDuplicateActionType =
  FunctionComponent<SignatureActionDuplicateActionProps>

const messages = defineMessages({
  duplicate: {
    id: "SignatureCard.DuplicateTooltip",
    defaultMessage: "Dupliquer la signature",
  },
})

const SignatureActionDuplicateAction: SignatureActionDuplicateActionType = ({
  onDuplicate,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div
        className="signature-card__btn"
        data-cy="signature-card__duplicate"
        onClick={() => {
          onDuplicate()
        }}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <DuplicateIcon className="signature-card__btn--icon" />
      </div>
      <MouseTooltip visible={visible}>
        {formatMessage(messages.duplicate)}
      </MouseTooltip>
    </>
  )
}

export default SignatureActionDuplicateAction
