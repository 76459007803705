import { useEffect, useState } from "react"

const MAX_SCROLL_SPEED = 25
const SCROLL_INCREMENT_INTERVAL = 50

let count = 0

const useHorizontalScrollingButtons = (
  node: HTMLDivElement | null,

  scrollLeft = undefined,
) => {
  const [overflow, setOverFlow] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [buttonHovering, setButtonHovering] = useState<null | number>(null)

  const onContainerEnter = () => setIsHovering(true)

  const onContainerLeave = () => setIsHovering(false)

  const onScrollNode = (scrollValue: number) => {
    if (node !== null) node.scrollLeft += scrollValue
  }

  useEffect(() => {
    if (node !== null) {
      setOverFlow(node.clientWidth < node.scrollWidth)
      node.scrollLeft = scrollLeft || 0
    }
  }, [node])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (buttonHovering !== null) {
      interval = setInterval(() => {
        node.scrollLeft += count * buttonHovering
        if (count < MAX_SCROLL_SPEED) count++
      }, SCROLL_INCREMENT_INTERVAL)
    } else {
      clearInterval(interval)
      count = 0
    }

    return () => clearInterval(interval)
  }, [buttonHovering])

  return {
    overflow,
    isHovering,
    onContainerEnter,
    onContainerLeave,
    onScrollNode,
    setButtonHovering,
  }
}

export default useHorizontalScrollingButtons
