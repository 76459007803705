import { FunctionComponent, useEffect } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import MultiStepForm, {
  FORM_LAYOUT,
} from "../../../components/MultiStepForm/MultiStepForm"

/** Main / SideBar Components */
import {
  SignaturePropertiesSideBar,
  SignaturePropertiesView,
} from "./1.SignatureProperties"
import { SignatureLicencesView } from "./3.SignatureLicences"

import useGroupsData from "features/Groups/hooks/useGroupsData"
import useUsersData from "features/Users/hooks/useUsersData"

import { signaturesSelectors } from ".."

import {
  initSignatureFormAction,
  loadActiveSignatureAffectationsAction,
} from "../SignaturesActions"
import SignaturesFormUsersSidebar from "../components/SignaturesFormUsersSidebar"

const messages = defineMessages({
  MultiStepFormSignatures: {
    id: "SignatureForm.Signatures",
    defaultMessage: "Signatures",
  },
  MultiStepFormSignaturesProperties: {
    id: "SignatureForm.SignaturesProperties",
    defaultMessage: "Propriétés",
  },
  MultiStepFormUsers: {
    id: "SignatureForm.Users",
    defaultMessage: "Utilisateurs",
  },
})

interface SignatureFormProps {
  title: string
}

const SignatureForm: FunctionComponent<SignatureFormProps> = ({ title }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const signature = useSelector(signaturesSelectors.getActiveSignature)
  const signatureName = useSelector(signaturesSelectors.getSignatureName)
  const currentStep = useSelector(signaturesSelectors.getCurrentStep)

  const onLoadSignatureForm = () => {
    dispatch(initSignatureFormAction())
  }

  useUsersData()
  useGroupsData()
  useEffect(() => {
    onLoadSignatureForm()
  }, [])

  useEffect(() => {
    if (signature.Id) {
      dispatch(loadActiveSignatureAffectationsAction(signature.Id))
    }
  }, [signature.Id])

  return (
    <div className="col-12 body-content">
      <MultiStepForm
        scrollToTop={signatureName === ""}
        title={title}
        datas={signature}
        breadCrumbNodes={[
          {
            number: 1,
            name: formatMessage(messages.MultiStepFormSignatures),
            description: formatMessage(
              messages.MultiStepFormSignaturesProperties,
            ),
          },
          {
            number: 2,
            name: formatMessage(messages.MultiStepFormUsers),
            description: formatMessage(messages.MultiStepFormUsers),
          },
        ]}
        showSubNav={false}
        step={currentStep}
        pages={[
          {
            main: <SignaturePropertiesView />,
            sidebar: <SignaturePropertiesSideBar />,
            layout: FORM_LAYOUT.TWO_COL,
          },
          {
            main: <SignatureLicencesView />,
            sidebar: <SignaturesFormUsersSidebar />,
            layout: FORM_LAYOUT.TWO_COL,
          },
        ]}
      />
    </div>
  )
}

export default SignatureForm
