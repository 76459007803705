import React from "react"

import "./Alert.scss"

interface Props {
  severity?: "info" | "error" | "success"
  title?: React.ReactNode
}

const Alert: React.FC<Props> = ({ severity = "info", title, children }) => {
  const icons = {
    info: "info",
    error: "warning",
    success: "check",
  }

  return (
    <div className={`Alert alert--${severity}`}>
      <div className="alert__title">
        <i className="alert__icon material-icons">{icons[severity]}</i>
        <div>{title}</div>
      </div>
      {children && <div className="alert__text">{children}</div>}
    </div>
  )
}

export default Alert
