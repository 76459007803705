import { useCallback, useState } from "react"

import SharedTrackingMapper from "features/Tracking/mappers/SharedTrackingMapper"

import { TrackingGraphData } from "features/Tracking/TrackingModels"

const useGraphAxisConfig = (data: TrackingGraphData[]) => {
  const [width, setWidth] = useState(0)

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width)
    }
  }, [])

  const maxQuantity = SharedTrackingMapper.getMaxFromHistory(data)

  const { max, values } = SharedTrackingMapper.getYScaleFromMax(maxQuantity)

  const axisBottomTickValues = SharedTrackingMapper.getTickValuesFromGraphData(
    data,
    width,
  )

  return {
    yScaleMax: max,
    axisLeftTickValues: values,
    axisBottomTickValues,
    measuredRef,
  }
}

export default useGraphAxisConfig
