import { FunctionComponent } from "react"

import Weighting from "components/Weighting"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { signaturesSelectors } from "features/Signatures"
import { setEditingSignatureWeighting } from "features/Signatures/SignaturesReducer"

const messages = defineMessages({
  infos: {
    id: "SignatureWeighting.Informations",
    defaultMessage:
      "Modifier le curseur de priorité pour définir la signature qui s'affichera en priorité par rapport à d'autres",
  },
})

const SignatureWeighting: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const weighting = useSelector(
    signaturesSelectors.getEditingSignatureWeightingValue,
  )

  return (
    <div className="sig-form__weighting">
      <p className="sig-form__weighting--infos">
        {formatMessage(messages.infos)}
      </p>
      <Weighting
        weighting={weighting}
        onChange={(value) => dispatch(setEditingSignatureWeighting(value))}
      />
    </div>
  )
}

export default SignatureWeighting
