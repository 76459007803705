import { takeEvery, all, takeLatest } from "redux-saga/effects"
import { TypeKeys as signatureActionsTypes } from "../features/Signatures"

import {
  setTrialStep,
  fetchTrialStep,
  sendTrialSignaturePreviewByMail,
  setTrialSignature,
  initTrialData,
} from "./trial.sagas"

import {
  createSignatureAsync,
  updateSignatureAsync,
  toggleSignatureAsync,
  activateSignatureAsync,
  dissociateUsersToActiveSignature,
  deleteSignatureAsync,
  duplicateSignatureAsync,
  initOrSetActiveSignature,
  sendPreviewByMail,
  unassignAllGroupsToActiveSignature,
  dissociateGroupToActiveSignature,
  sendActiveSignaturePreviewByMail,
} from "./signatures.sagas"

import {
  updateTemplateLogo,
  updateActiveTemplate,
  fetchTemplatesGallery,
  selectNextTemplate,
  selectPrevTemplate,
  selectTemplateColor,
  cropTemplateLogo,
  resizeTemplateLogo,
  selectTemplate,
} from "./templates.sagas"

import { loadInitialDatas } from "./startup.sagas"

import { TrialActionTypes } from "features/Trial"

import {
  createJobInstruction,
  backgroundJobCallbacks,
  deleteJobInstruction,
} from "./jobs.sagas"
import { appWatchers } from "features/App/AppSagas"
import { featuresWatchers } from "features/Features/FeaturesSagas"

// New Enrollment
import { enrollmentWatchers } from "features/Enrollment/EnrollmentSagas"
import { scenarioWatchers } from "features/Scenarios/ScenarioSagas"

import {
  errorWatcher,
  withSagaErrorHandler,
} from "../features/Errors/ErrorsSagas"
import { featuresTogglrWatchers } from "../features/FeatureTogglr/FeatureTogglrSagas"
import { usersWatchers } from "features/Users/UsersSagas"
import { billingsWatchers } from "features/Billings/BillingsSagas"
import { signaturesWatchers } from "features/Signatures/SignaturesSagas"
import { builderSignaturesWatchers } from "features/BuilderSignatures/BuilderSignaturesSagas"
import { jobsWatchers } from "features/Jobs/JobsSagas"
import { accountsWatchers } from "features/Accounts/AccountsSagas"
import { loadInitialDatasAction } from "features/Accounts/AccountsActions"
import { groupsWatchers } from "features/Groups/GroupsSagas"
import { subsidiaryWatchers } from "features/Subsidiaries/SubsidiariesSaga"
import { trackingWatchers } from "features/Tracking/TrackingSagas"
import { storageWatchers } from "features/Storage/StorageSagas"
import { offersWatchers } from "features/Offers/OffersSagas"
import { alertsWatchers } from "features/Alerts/AlertsSagas"

import {
  changeTemplateColor,
  sendPreviewMail,
} from "features/Signatures/SignaturesReducer"
import {
  createSignatureAction,
  cropLogoAction,
  deleteSignatureAction,
  fetchTemplatesGalleryAction,
  initSignatureFormAction,
  sendSignaturePreviewAction,
  toggleSignatureAction,
  updateLogoSizeAction,
  updateSignatureAction,
  updateSignatureLogoAction,
} from "features/Signatures/SignaturesActions"
import {
  createJobInstructionAction,
  deleteJobInstructionAction,
  jobFinishedAction,
} from "features/Jobs/JobsActions"

// single entry point to start all Sagas at once
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(loadInitialDatasAction.type, loadInitialDatas),
    ...errorWatcher,
    ...featuresWatchers,
    ...scenarioWatchers,
    ...enrollmentWatchers,
    ...appWatchers,
    ...featuresTogglrWatchers,
    ...usersWatchers,
    ...billingsWatchers,
    ...signaturesWatchers,
    ...builderSignaturesWatchers,
    ...jobsWatchers,
    ...accountsWatchers,
    ...groupsWatchers,
    ...subsidiaryWatchers,
    ...trackingWatchers,
    ...storageWatchers,
    ...offersWatchers,
    ...alertsWatchers,
    // Users
    // Group / Signatures
    takeEvery(
      signatureActionsTypes.DISSOCIATE_USERS_AND_GROUPS,
      withSagaErrorHandler(unassignAllGroupsToActiveSignature),
    ),
    // Signature
    takeEvery(
      initSignatureFormAction.type,
      withSagaErrorHandler(initOrSetActiveSignature),
    ),
    takeEvery(createSignatureAction.type, createSignatureAsync),
    takeEvery(
      signatureActionsTypes.DUPLICATE_SIGNATURE,
      duplicateSignatureAsync,
    ),
    takeEvery(
      toggleSignatureAction.type,
      withSagaErrorHandler(toggleSignatureAsync),
    ),
    takeEvery(
      signatureActionsTypes.ACTIVATE_SIGNATURE,
      withSagaErrorHandler(activateSignatureAsync),
    ),
    takeEvery(updateSignatureAction.type, updateSignatureAsync),
    takeEvery(
      updateSignatureLogoAction.type,
      withSagaErrorHandler(updateTemplateLogo),
    ),
    takeEvery(cropLogoAction.type, cropTemplateLogo),
    takeEvery(updateLogoSizeAction.type, resizeTemplateLogo),
    takeEvery(
      signatureActionsTypes.UPDATE_ACTIVE_SIGNATURE_TEMPLATE,
      updateActiveTemplate,
    ),
    takeEvery(deleteSignatureAction.type, deleteSignatureAsync),
    takeEvery(
      signatureActionsTypes.DISSOCIATE_USERS,
      withSagaErrorHandler(dissociateUsersToActiveSignature),
    ),
    takeEvery(
      signatureActionsTypes.DISSOCIATE_GROUP,
      withSagaErrorHandler(dissociateGroupToActiveSignature),
    ),
    takeEvery(sendPreviewMail.type, sendPreviewByMail),
    takeEvery(
      sendSignaturePreviewAction.type,
      sendActiveSignaturePreviewByMail,
    ),
    // Templates
    takeEvery(
      fetchTemplatesGalleryAction.type,
      withSagaErrorHandler(fetchTemplatesGallery),
    ),
    takeEvery(changeTemplateColor.type, selectTemplateColor),
    takeEvery(signatureActionsTypes.SELECT_TEMPLATE, selectTemplate),
    takeEvery(signatureActionsTypes.SELECT_NEXT_TEMPLATE, selectNextTemplate),
    takeEvery(signatureActionsTypes.SELECT_PREV_TEMPLATE, selectPrevTemplate),
    // Properties

    // Trial
    takeLatest(TrialActionTypes.INIT_TRIAL_DATA, initTrialData),
    takeEvery(TrialActionTypes.FETCH_TRIAL, fetchTrialStep),
    takeEvery(TrialActionTypes.SET_TRIAL_STEP, setTrialStep),
    takeEvery(
      TrialActionTypes.SEND_TRIAL_SIGNATURE_PREVIEW,
      sendTrialSignaturePreviewByMail,
    ),
    takeEvery(TrialActionTypes.SET_TRIAL_SIGNATURE, setTrialSignature),
    // Background Jobs
    takeEvery(createJobInstructionAction.type, createJobInstruction),
    takeEvery(deleteJobInstructionAction.type, deleteJobInstruction),
    takeEvery(jobFinishedAction.type, backgroundJobCallbacks),
  ])
}
