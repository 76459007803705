import { FunctionComponent, InputHTMLAttributes, useState } from "react"

import { ReactComponent as HideIcon } from "assets/icon-hide.svg"
import { ReactComponent as PreviewIcon } from "assets/icon-preview.svg"

import UnderlinedInputField from "components/UnderlinedInputField"

interface PasswordUnderlinedInputFieldProps {
  placeholder?: string
}

type PasswordUnderlinedInputFieldType = PasswordUnderlinedInputFieldProps &
  InputHTMLAttributes<HTMLInputElement>

const PasswordUnderlinedInputField: FunctionComponent<
  PasswordUnderlinedInputFieldType
> = ({ placeholder, ...others }) => {
  const [type, setType] = useState<"password" | "text">("password")

  const hideIcon = <HideIcon onClick={() => setType("text")} />
  const showIcon = <PreviewIcon onClick={() => setType("password")} />

  return (
    <UnderlinedInputField
      name="password"
      type={type}
      placeholder={placeholder}
      required
      rightIcon={type === "password" ? hideIcon : showIcon}
      {...others}
    />
  )
}

export default PasswordUnderlinedInputField
