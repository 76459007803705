import ServiceApi from "core/services/serviceApi"
import PortalApi from "core/services/portalApi"

import { TypedResult } from "core/CoreModels"

import {
  BuilderSignature,
  BuilderSignatureDto,
} from "features/BuilderSignatures/BuilderSignaturesModels"
import { UserDto } from "features/Users/UserModels"
import { GroupDto } from "features/Groups/GroupsModels"
import { SignatureAssignationsDto, SignatureDto } from "./SignaturesModels"
import Signature from "entities/Signature"

import { LoadingSlicesTypes } from "core/services/loadingSlicesService"
import { SignaturesSlices } from "./SignaturesReducer"

import { RequestParams } from "core/services/requestBuilderService"

export function fetchSignatures(
  params: RequestParams,
): Promise<TypedResult<SignatureDto[]>> {
  return ServiceApi.request("GET", "/signatures", { params })
}

export function fetchSignaturesApi(
  loadingSlice: LoadingSlicesTypes<SignaturesSlices>,
): Promise<TypedResult<SignatureDto[]>> {
  const newBuilderQuery = "isNewBuilder eq false"
  const loadingSliceQuery =
    loadingSlice === "all" ? "" : `and ${loadingSlice} eq true`

  const query = [newBuilderQuery, loadingSliceQuery].join(" ")

  return ServiceApi.request("GET", "/signatures", {
    params: { q: query },
  })
}

export function createSignature(
  signature: unknown,
): Promise<TypedResult<SignatureDto>> {
  return ServiceApi.request("POST", "/signatures", { data: signature })
}

export function updateSignature(
  signature: Signature,
): Promise<TypedResult<SignatureDto>> {
  return ServiceApi.request("PUT", `/signatures/${signature.Id}`, {
    data: signature,
  })
}

export function activateSignature(
  signatureId: number,
  activated: boolean,
): Promise<TypedResult<SignatureDto>> {
  return ServiceApi.request("PUT", `/signatures/${signatureId}/activated`, {
    data: activated,
  })
}

export function deleteSignature(
  signature: Signature,
): Promise<TypedResult<void>> {
  return ServiceApi.request("DELETE", `/signatures/${signature.Id}`)
}

export function fetchUsersForSignature(
  signatureId: number,
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("GET", `/signatures/${signatureId}/users`)
}

export function fetchGroupsForSignature(
  signatureId: number,
): Promise<TypedResult<GroupDto[]>> {
  return ServiceApi.request("GET", `/signatures/${signatureId}/groups`)
}

export function getUserSignature(
  email: string,
): Promise<TypedResult<SignatureDto>> {
  return ServiceApi.request("GET", `/signatures/delivery/users/${email}`)
}

export function assignUsersToSignature(
  signatureId: number,
  userIds: number[],
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("PUT", `/signatures/${signatureId}/users`, {
    data: userIds,
  })
}

export function dissociateUsersToSignature(
  signature: Signature,
  userIds: number[],
): Promise<TypedResult<void>> {
  return ServiceApi.request("DELETE", `/signatures/${signature.Id}/users`, {
    data: userIds,
  })
}

export function assignGroupsToSignature(
  signatureId: number,
  groupIds: number[],
): Promise<TypedResult<GroupDto[]>> {
  return ServiceApi.request("PUT", `/signatures/${signatureId}/groups`, {
    data: groupIds,
  })
}

export function dissociateGroupsToSignature(
  signatureId: number,
  groupIds: number[],
): Promise<TypedResult<void>> {
  return ServiceApi.request("DELETE", `/signatures/${signatureId}/groups`, {
    data: groupIds,
  })
}

export function fetchBuilderSignatures(
  loadingSlice: LoadingSlicesTypes<SignaturesSlices>,
): Promise<TypedResult<BuilderSignatureDto[]>> {
  const newBuilderQuery = "isNewBuilder eq true"
  const loadingSliceQuery =
    loadingSlice === "all" ? "" : `and ${loadingSlice} eq true`

  const query = [newBuilderQuery, loadingSliceQuery].join(" ")

  return ServiceApi.request("GET", "/signatures", {
    params: { q: query },
  })
}

export function createBuilderSignature(
  signature: Omit<BuilderSignature, "id" | "template">,
): Promise<TypedResult<BuilderSignatureDto>> {
  return ServiceApi.request("POST", "/signatures", {
    data: signature,
    headers: { "X-Version": 2 },
  })
}

export function updateBuilderSignature(
  signature: Omit<BuilderSignature, "template">,
): Promise<TypedResult<BuilderSignatureDto>> {
  return ServiceApi.request("PUT", `/signatures/${signature.id}`, {
    data: signature,
    headers: { "X-Version": 2 },
  })
}

export function deleteBuilderSignature(
  builderSignatureId: number,
): Promise<TypedResult<BuilderSignatureDto>> {
  return ServiceApi.request("DELETE", `/signatures/${builderSignatureId}`)
}

export function fetchBuilderSignaturesUsers(
  signatureId: number,
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("GET", `/signatures/${signatureId}/users`)
}

export function fetchBuilderSignaturesGroups(
  signatureId: number,
): Promise<TypedResult<GroupDto[]>> {
  return ServiceApi.request("GET", `/signatures/${signatureId}/groups`)
}

export function updateBuilderSignatureUsers(
  signatureId: number,
  users: number[],
): Promise<TypedResult<BuilderSignatureDto>> {
  return ServiceApi.request("PUT", `/signatures/${signatureId}/users`, {
    data: users,
  })
}

export function updateBuilderSignatureGroups(
  signatureId: number,
  groups: number[],
): Promise<TypedResult<BuilderSignatureDto>> {
  return ServiceApi.request("PUT", `/signatures/${signatureId}/groups`, {
    data: groups,
  })
}

export function sendTrialSignaturePreview(
  email: string,
  signature: string,
  withBanner: boolean,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/signatures/sendtrialpreview", {
    data: { Email: email, Signature: signature, WithBanner: withBanner },
  })
}

export function sendSignaturePreview(
  email: string,
  signature: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/signatures/sendpreview", {
    data: { Email: email, Signature: signature },
  })
}

export function fetchSignaturesTemplates(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/signaturetemplates")
}

export function fetchSignatureAssignations(
  signatureId: number,
): Promise<TypedResult<SignatureAssignationsDto>> {
  return ServiceApi.request("GET", `/signatures/${signatureId}/assignations`)
}
