import React from "react"
import BreadCrumbItem from "./BreadCrumbItem"

interface Props {
  nodes: any[]
  step: number
}

class BreadCrumb extends React.Component<Props> {
  render() {
    const nodesItem = this.props.nodes.map((node, idx) => (
      <BreadCrumbItem
        key={idx}
        title={node.name}
        active={node.number <= this.props.step}
      />
    ))

    return (
      <nav className="container-breadcrumb">
        <div className="bmm-breadcrumb">
          <div className="breadcrumb__line" />
          {nodesItem}
        </div>
      </nav>
    )
  }
}

export default BreadCrumb
