import { FunctionComponent, useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"

import { Navigate, useSearchParams } from "react-router-dom"

import { resetPasswordAction } from "features/Accounts/AccountsActions"
import { accountsSelectors } from "features/Accounts"
import { defineMessages, useIntl } from "react-intl"
import Loader from "components/Loader"
import Password from "components/Password"
import StringHelper from "utils/StringHelper"
import Button from "components/Button"

interface ResetPasswordFormProps {
  title: string
  isConfirmUser: boolean
}

const messages = defineMessages({
  password: {
    id: "ResetPasswordForm.Password",
    defaultMessage: "Mot de passe",
  },
  mandatory: {
    id: "ResetPasswordForm.Mandatory",
    defaultMessage: "Champ obligatoire",
  },
  notTheSame: {
    id: "ResetPasswordForm.NotTheSame",
    defaultMessage: "Les deux champs sont différents",
  },
  confirmPass: {
    id: "ResetPasswordForm.ConfirmPassword",
    defaultMessage: "Confirmez votre mot de passe",
  },
})

const USER_ID_QUERY_PARAMETER = "userId"

const CODE_QUERY_PARAMETER = "code"

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = ({
  title,
  isConfirmUser,
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState({ password: "", confirmPassword: "" })
  const [errorsState, setErrorsState] = useState({
    password: null,
    confirmPassword: null,
  })

  const resetPasswordRedirectUrl = useSelector(
    accountsSelectors.getResetPasswordRedirectUrl,
  )
  const errors = useSelector(accountsSelectors.getResetPasswordErrors)

  const userId = searchParams.get(USER_ID_QUERY_PARAMETER)
  // * Le code de sécurité contient des signes +, interprétés comme des espaces dans les search params
  // * Les espaces sont retransformés en symbole +
  const code = searchParams.get(CODE_QUERY_PARAMETER).replaceAll(" ", "+")

  useEffect(() => {
    if (errors && errors.length > 0) setIsLoading(false)
  }, [errors])

  const handleInputChange = (event) => {
    const value = event.target.value
    const property = event.target.name

    setValues((prevState) => ({ ...prevState, [property]: value }))
  }
  const checkInputs = () => {
    const checkedErrors = { ...errorsState }
    for (const key in checkedErrors) {
      checkedErrors[key] = values[key] === ""
      if (key === "confirmPassword")
        checkedErrors[key] =
          values["confirmPassword"] === "" ||
          values["confirmPassword"] !== values["password"]
    }

    setErrorsState(checkedErrors)

    for (const key in checkedErrors)
      if (checkedErrors[key] === true) return true

    return false
  }

  const handleResetPasswordRequest = () => {
    if (checkInputs()) return
    const params = { userId, code, isConfirmUser }

    setIsLoading(true)
    dispatch(resetPasswordAction(params, values))
  }

  const renderPasswordErrors = () => {
    if (errors) {
      const errorsBlock = errors.map((error, index) => (
        <li key={index}>{error.description}</li>
      ))

      return <ul className="reset-password__errors">{errorsBlock}</ul>
    }
  }

  if (resetPasswordRedirectUrl)
    return <Navigate to={resetPasswordRedirectUrl} />

  return (
    <div className="reset-password">
      <Loader fullScreen isLoading={isLoading} />
      {renderPasswordErrors()}
      <div className="reset-password__container">
        <h4 className="reset-password__title">{title}</h4>
        <div className="reset-password__liseret" />
        <div className="reset-password__input-container">
          <div>
            <Password
              name="password"
              hintText={formatMessage(messages.password)}
              mandatory
              fullWidth={true}
              onChange={handleInputChange}
              value={values.password}
              validators={[
                {
                  checker: StringHelper.validateLength,
                  errorText: formatMessage({ id: "Password.TooShort" }),
                },
                {
                  checker: StringHelper.validateLowerChar,
                  errorText: formatMessage({ id: "Password.NoLower" }),
                },
                {
                  checker: StringHelper.validateUpperChar,
                  errorText: formatMessage({ id: "Password.NoUpper" }),
                },
                {
                  checker: StringHelper.validateNbNumbers,
                  errorText: formatMessage({ id: "Password.NoNumber" }),
                },
              ]}
              errorText={
                errorsState.password && formatMessage(messages.mandatory)
              }
            />
          </div>
          <div>
            <Password
              name="confirmPassword"
              hintText={formatMessage(messages.confirmPass)}
              mandatory
              fullWidth={true}
              onChange={handleInputChange}
              value={values.confirmPassword}
              errorText={
                errorsState.confirmPassword &&
                formatMessage(messages.notTheSame)
              }
            />
          </div>
        </div>
        <Button label="Modifier" onClick={handleResetPasswordRequest} />
      </div>
    </div>
  )
}

export default ResetPasswordForm
