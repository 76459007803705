import { FunctionComponent } from "react"

import classNames from "classnames"

import { AvailableLanguages } from "features/App/AppModels"

interface LangSwitchItemProps {
  lang: AvailableLanguages
  selected: boolean
  hasSeparation: boolean
  onSwitchLanguage: (lang: AvailableLanguages) => void
}

const LangSwitchItem: FunctionComponent<LangSwitchItemProps> = ({
  lang,
  selected,
  hasSeparation,
  onSwitchLanguage,
}) => {
  return (
    <div>
      <div
        className={classNames(
          "langswitch__item",
          selected && "langswitch__item--selected",
        )}
        onClick={() => onSwitchLanguage(lang)}
      >
        {lang}
      </div>
      {hasSeparation && <div className="langswitch__separator"></div>}
    </div>
  )
}

export default LangSwitchItem
