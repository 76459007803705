import { LoadingStatus } from "core/CoreModels"
import { useDispatch, useSelector } from "react-redux"
import { GlobalStates } from "store"
import { usersSelectors } from ".."
import { fetchDynamicUsers as fetchUsers, resetDynamicUsers as resetUsers } from "../UsersReducers"  

const useDynamicUsers = () => {
    
    const dispatch = useDispatch()
    const loadingStatus = useSelector<GlobalStates, LoadingStatus>((state) =>
      usersSelectors.getDynamicUsersStatus(state),
    )
    const allDynamicUserIds: number[] = useSelector(usersSelectors.getDynamicUsers)
    const fetchDynamicUsers = (data) => dispatch(fetchUsers(data))
    const resetDynamicUsers = () => dispatch(resetUsers())

    return { allDynamicUserIds, loadingStatus, fetchDynamicUsers, resetDynamicUsers }
  }


export default useDynamicUsers