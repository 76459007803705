import { FunctionComponent } from "react"

import { ReactComponent as IconPointer } from "assets/icon-click-pointer.svg"
import { useIntl } from "react-intl"

interface TrackingTileClicksProps {
  value: number
  unit: string
}

const TrackingTileClicks: FunctionComponent<TrackingTileClicksProps> = ({
  value,
  unit,
}) => {
  const { locale } = useIntl()
  return (
    <div className="tracking-tile__clicks--container">
      <div className="tracking-tile__clicks--value-container">
        <IconPointer className="tracking-tile__clicks--icon" />
        <p className="tracking-tile__clicks--value">
          {new Intl.NumberFormat(locale).format(value)}
        </p>
      </div>
      <p className="tracking-tile__clicks--unit">{unit}</p>
    </div>
  )
}

export default TrackingTileClicks
