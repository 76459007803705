import React from "react"
import { Checkbox, FormControlLabel } from "@material-ui/core"

const styles = {
  global: {
    width: "40px",
  },
  inputStyle: {
    fill: "#CBCBCB",
  },
  iconStyle: {
    fill: "#3D5AFE",
  },
}

export interface Props {
  id?: string
  name: string
  className?: string
  checked: boolean
  inputStyle?: {}
  label?: string
  disabled?: boolean
  onCheck(event, checked)
}

export class BmmCheckBox extends React.Component<Props> {
  render() {
    const { checked, className } = this.props
    return (
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            disableRipple={true}
            name={this.props.name}
            disabled={this.props.disabled}
            checked={checked}
            onChange={this.props.onCheck}
            color="primary"
          />
        }
        label={this.props.label && this.props.label}
      />
    )
  }
}

export default BmmCheckBox
