import { FunctionComponent } from "react"

import AccountInformationsFormSingleFieldStep, {
  AccountInformationsFormSingleFieldStepProps,
} from "./AccountInformationsFormSingleFieldStep"
import useUTMManagement from "features/Marketing/hooks/useUTMManagement"

const AccountInformationsFormTenantNameStep: FunctionComponent<
  AccountInformationsFormSingleFieldStepProps
> = (props) => {
  useUTMManagement()
  return <AccountInformationsFormSingleFieldStep {...props} />
}

export default AccountInformationsFormTenantNameStep
