/** Selectors **/
import { differenceBy } from "lodash"
import { createSelector } from "reselect"

import { signaturesSelectors } from "features/Signatures"

import {
  User,
  UserProperties,
  UserProperty,
  ImportReport,
  UserPropertiesById,
  PreviewTemplate,
} from "features/Users/UserModels"
import { GlobalStates } from "store"
import { accountsSelectors } from "features/Accounts"

import { LoadingStatus, SavingStatus } from "core/CoreModels"

import {
  selectAllPartialUsers,
  selectAllUsers,
  selectPartialUserById,
  selectUserById,
} from "features/Users/UsersReducers"
import { SortOrders } from "components/PaginatedDataGrid/PaginatedDataGrid"
import Signature from "entities/Signature"
import { RootState } from "store/configureStore"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

const getAllUsersWithLicences = (state: GlobalStates): Array<User> => {
  const allUsersActive = selectAllUsers(state).filter(
    (user: User) => user.ActiveLicence,
  )
  return allUsersActive
}

const getNbLicencedUsers = (state: GlobalStates): number => {
  return getAllUsersWithLicences(state).length
}

const getNbUsers = (state: GlobalStates): number => {
  return selectAllUsers(state).length
}

const getPartialUsersSearchString = (state: GlobalStates): string =>
  state.users.partialUsers.search.toLowerCase()

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getCurrentTemplateUserProperties = (state: GlobalStates) => {
  const userProperties = getUserProperties(state)
  const selectedTemplate = signaturesSelectors.getSelectedTemplate(state)
  if (selectedTemplate == null || userProperties == null) return
  const templatePropNames = selectedTemplate.GetUserProperties()
  const templateProperties = templatePropNames
    .map((tempProperty) => {
      const up = userProperties.find(
        (prop) => prop.InternalName === tempProperty,
      )
      if (up != null) return new UserProperty(up)
    })
    .filter((val) => val != null)

  // Cas spécial des bannières - gérer sur l'écran d'après
  const templatePropertiesWithoutBanner = templateProperties.filter(
    (templateProperty) => templateProperty.InternalName !== "banner",
  )

  const activeSignature = signaturesSelectors.getActiveSignature(state)
  const tagsInSignature = activeSignature.Template.userProperties
  templatePropertiesWithoutBanner
    .filter((property) => tagsInSignature.indexOf(property.InternalName) !== -1)
    .forEach((property) => {
      property.Visible = true
      return property
    })

  // Cas spécial des propriétés userMessages & disclaimer en trial qui ne doivent pas être utilisable
  templatePropertiesWithoutBanner
    .filter(
      (property) =>
        property.InternalName === "disclaimer" ||
        property.InternalName === "usermessage",
    )
    .forEach((property) => {
      property.Disabled = true
      return property
    })

  return templatePropertiesWithoutBanner
}

const getSampleUser = (state: GlobalStates): User => state.users.sampleUser

function ExcludeAffectedUsers(users: User[], usersToExclude: User[]) {
  return differenceBy(users, usersToExclude, (user) => user.Id)
}

/** Affectation des signatures */
const getSignatureUsersWithoutAffectedUsersSelector = createSelector(
  [
    getAllUsersWithLicences,
    signaturesSelectors.getActiveSignatureAffectedUsers,
  ],
  (allUsers, affectedUsers) => ExcludeAffectedUsers(allUsers, affectedUsers),
)

/** Gestion des propriétés utilisateur */
const getUserProperties = (state: GlobalStates): Array<UserProperty> =>
  state.users.userProperties.ids.map(
    (propId) => state.users.userProperties.entities[propId],
  )

const getUserPhoto = (state: GlobalStates, userId: number): string =>
  state.users.allUsers.entities[userId].Properties.picture

const getUserPropertiesByUserId = (
  state: GlobalStates,
  userId: number,
): UserProperties => {
  const currentUser = state.users.allUsers.entities[userId]
  if (currentUser != null) return currentUser.Properties
  return null
}

const getExcelModelLink = (state: GlobalStates): string =>
  state.users.excelModelLink

const getLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.users.allUsers.loadingStatus

const getCurrentUser = (state: GlobalStates): User | null => {
  const allUsers = selectAllUsers(state)
  const connectedUser = accountsSelectors.getCurrentAccount(state)

  if (connectedUser === null) return null

  const currentUser = allUsers.find(
    (us) => us.Username === connectedUser.UserName,
  )

  if (currentUser) return currentUser

  return null
}

const getPreviewById = (state: GlobalStates, id?: number): string | null => {
  if (!id) return null

  const user = selectPartialUserById(state, id)

  if (!user?.preview) return null

  return user.preview
}

const getReport = (state: GlobalStates): ImportReport =>
  state.users.importReport

const getShouldRedirect = (state: GlobalStates): boolean =>
  state.users.shouldRedirect

const getUsersPropertiesById = (state: GlobalStates): UserPropertiesById =>
  state.users.userProperties.entities

const getUsersPropertiesAreChecking = (state: GlobalStates): boolean =>
  state.users.userProperties.isChecking

const getUsersPropertiesAreLoading = (state: GlobalStates): boolean =>
  state.users.userProperties.isLoading

const getPartialUsersCurrentPage = (state: GlobalStates): number =>
  state.users.partialUsers.currentPage

const getPartialUsersLastPage = (state: GlobalStates): number =>
  state.users.partialUsers.lastPage

const getPartialUsersPageSize = (state: GlobalStates): number =>
  state.users.partialUsers.pageSize

const getPartialUsersLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.users.partialUsers.loadingStatus

const getPartialUsersTotalCount = (state: GlobalStates): number =>
  state.users.partialUsers.totalCount

const getPartialUsersOrderBy = (state: GlobalStates): SortOrders =>
  state.users.partialUsers.orderBy

const getActiveLicencesCount = (state: GlobalStates): number =>
  state.users.partialUsers.activeLicencesCount

const getEditingUser = (state: GlobalStates): User | null =>
  state.users.editingUser.entity.user

const getEditingUserAssignedSignatures = (
  state: GlobalStates,
): Array<Signature | BuilderSignature> =>
  state.users.editingUser.entity.assignedSignatures

const getEditingUserDefaultSignature = (
  state: GlobalStates,
): Signature | BuilderSignature | null =>
  state.users.editingUser.entity.defaultSignature

const getEditingUserStatuses = (
  state: RootState,
): { loadingStatus: LoadingStatus; savingStatus: SavingStatus } => {
  const { loadingStatus, savingStatus } = state.users.editingUser
  return { loadingStatus, savingStatus }
}

const getDynamicUsersStatus = (state: GlobalStates): LoadingStatus =>
  state.users.dynamicUsers.loadingStatus

const getDynamicUsers = (state: GlobalStates): number[] =>
  state.users.dynamicUsers.ids

const getUserTemplatesPreviewLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.users.partialUsers.previewMail.loadingStatus

const getUserTemplatesPreview = (state: GlobalStates): PreviewTemplate[] =>
  state.users.partialUsers.previewMail.templates

const getUserSignaturesTemplatesPreview = (
  state: GlobalStates,
): PreviewTemplate[] =>
  state.users.partialUsers.previewMail.templates.filter(
    (template) => template.signatureId !== null,
  )

export const selectors = {
  getAllUsers: selectAllUsers,
  getUserById: selectUserById,
  getSampleUser,
  getLoadingStatus,
  getSignatureUsersWithoutAffectedUsersSelector,
  getAllUsersWithLicences,
  getUserProperties,
  getUserPropertiesByUserId,
  getCurrentTemplateUserProperties,
  getExcelModelLink,
  getUserPhoto,
  getCurrentUser,
  getPreviewById,
  getReport,
  getPartialUsersSearchString,
  getNbLicencedUsers,
  getNbUsers,
  getShouldRedirect,
  getUsersPropertiesById,
  getUsersPropertiesAreChecking,
  getUsersPropertiesAreLoading,
  getPartialUserById: selectPartialUserById,
  getAllPartialUsers: selectAllPartialUsers,
  getPartialUsersCurrentPage,
  getPartialUsersLastPage,
  getPartialUsersPageSize,
  getPartialUsersLoadingStatus,
  getPartialUsersTotalCount,
  getPartialUsersOrderBy,
  getActiveLicencesCount,
  getEditingUser,
  getEditingUserAssignedSignatures,
  getEditingUserDefaultSignature,
  getEditingUserStatuses,
  getDynamicUsersStatus,
  getDynamicUsers,
  getUserTemplatesPreviewLoadingStatus,
  getUserTemplatesPreview,
  getUserSignaturesTemplatesPreview,
}

export default selectors
