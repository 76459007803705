import React, { ReactNode, useState, MouseEvent } from "react"

import classNames from "classnames"

import { FormattedMessage } from "react-intl"

import { ReactComponent as IconUser } from "assets/icon-user.svg"
import { ReactComponent as IconGroup } from "assets/icon-group.svg"

import CollapseListContent from "../CollapseListContent/CollapseListContent"

import { PeoplePickerCollapseListItem } from "../PeoplePickerCollapseLists"

interface CollapseListProps {
  title: ReactNode
  data: PeoplePickerCollapseListItem[]
  selectedData: PeoplePickerCollapseListItem[]
  grouped?: boolean
  search?: string
  type?: "users" | "groups"
  handleClick: (item: PeoplePickerCollapseListItem) => void
  selectAll: (items: PeoplePickerCollapseListItem[]) => void
  removeAll: () => void
}
const SHOW_SELECT_ALL_OVER = 1

const CollapseList: React.FC<CollapseListProps> = ({
  title,
  data,
  selectedData,
  grouped,
  search,
  type = "users",
  handleClick,
  selectAll,
  removeAll,
}) => {
  const [show, setShow] = useState(true)

  const icon =
    type === "groups" ? (
      <IconGroup className="people-picker-collapse-list__icon--groups" />
    ) : (
      <IconUser className="people-picker-collapse-list__icon--users" />
    )

  if (search) {
    data = data.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase()),
    )
    selectedData = selectedData.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase()),
    )
  }

  const handleCollapse = () => {
    setShow((prevState) => !prevState)
  }

  const handleSelectAll = (e: MouseEvent<HTMLButtonElement | MouseEvent>) => {
    e.stopPropagation()
    selectAll(data)
  }

  const handleRemoveAll = (e: MouseEvent<HTMLButtonElement | MouseEvent>) => {
    e.stopPropagation()
    removeAll()
  }

  const selectAllButton = (
    <button
      onClick={handleSelectAll}
      tabIndex={0}
      role="button"
      className={classNames("people-picker-collapse-list__select-all", type)}
    >
      <FormattedMessage
        id="CollapseList.SelectAll"
        defaultMessage="Tout sélectionner"
      />
    </button>
  )

  const removeAllButton = (
    <button
      onClick={handleRemoveAll}
      tabIndex={0}
      role="button"
      className="people-picker-collapse-list__remove-all"
    >
      <FormattedMessage
        id="CollapseList.RemoveAll"
        defaultMessage="Tout supprimer"
      />
    </button>
  )

  const handleAllButton =
    data.length !== selectedData.length ? selectAllButton : removeAllButton

  const content =
    data.length > 0 ? (
      <CollapseListContent
        data={data}
        selectedData={selectedData}
        onClick={(item) => {
          handleClick(item)
        }}
        grouped={grouped}
      />
    ) : (
      <div className="people-picker-collapse-list__empty">
        <FormattedMessage
          id="CollapseList.NoResult"
          defaultMessage="Aucun résultat"
        />
      </div>
    )

  return (
    <div className={classNames("people-picker-collapse-list")}>
      <div
        className="people-picker-collapse-list__title"
        onClick={handleCollapse}
      >
        <div className="people-picker-collapse-list__title--left-side">
          {icon}
          <span className="people-picker-collapse-list__title--label">
            {title}
          </span>
        </div>
        <div className="people-picker-collapse-list__title--right-side">
          {data.length > SHOW_SELECT_ALL_OVER && handleAllButton}
          {show ? (
            <i className="material-icons">expand_more</i>
          ) : (
            <i className="material-icons">expand_less</i>
          )}
        </div>
      </div>
      <div
        className={classNames(
          "people-picker-collapse-list__collapser",
          show ? "opened" : "closed",
        )}
      >
        {content}
      </div>
    </div>
  )
}

export default CollapseList
