import { useMemo } from "react"

import { marketingSelectors } from "features/Marketing"

import { useSelector } from "react-redux"

const useOauthProviderRedirectUrl = (oAuthRedirectUrl) => {
  const utms = useSelector(marketingSelectors.getAllUTMs)

  const redirectUrl = useMemo(() => {
    const baseUrl = new URL(document.baseURI)
    const url = new URL(`${baseUrl.origin}${oAuthRedirectUrl}`)

    Object.entries(utms).map(([key, value]) =>
      url.searchParams.append(key, value),
    )

    return url.href
  }, [utms])

  return redirectUrl
}

export default useOauthProviderRedirectUrl
