import Tour from "entities/Tour"
import TourGuide from "entities/TourGuide"

/**
 * TourGuide CONSTANTS
 */

// 1 - export ActionTypes Constants
export enum TourGuideActionTypes {
  INIT_TOURGUIDE = "TOURGUIDE/INIT_TOURGUIDE",
  INIT_TOURGUIDE_SUCCESS = "TOURGUIDE/INIT_TOURGUIDE_SUCCESS",
  INIT_TOURGUIDE_FAILURE = "TOURGUIDE/INIT_TOURGUIDE_FAILURE",
  SHOW_TOUR_GUIDE = "TOURGUIDE/SHOW_TOURGUIDE",
  HIDE_ON_BOARDING = "TOURGUIDE/HIDE_ON_BOARDING",
  UPDATE_TOUR_GUIDE_DISPLAY = "TOURGUIDE/UPDATE_TOUR_GUIDE_DISPLAY",
  PASSTHROUGHT_TOURGUIDE = "TOURGUIDE/PASSTHROUGHT_TOURGUIDE",
  START_TOUR = "TOURGUIDE/START_TOUR",
  START_TOUR_SUCCESS = "TOURGUIDE/START_TOUR_SUCCESS",
  NEXT_TOUR_STEP = "TOURGUIDE/NEXT_TOUR_STEP",
  PREV_TOUR_STEP = "TOURGUIDE/PREV_TOUR_STEP",
  EXIT_TOUR_GUIDE = "TOURGUIDE/EXIT_TOUR_GUIDE",
  EXIT_TOUR_GUIDE_SUCCESS = "TOURGUIDE/EXIT_TOUR_GUIDE_SUCCESS",
  EXIT_TOUR_GUIDE_FAILURE = "TOURGUIDE/EXIT_TOUR_GUIDE_FAILURE",
  UPDATE_TOUR_GUIDE = "TOURGUIDE/UPDATE_TOUR_GUIDE",
  UPDATE_TOUR_GUIDE_SUCCESS = "TOURGUIDE/UPDATE_TOUR_GUIDE_SUCCESS",
  UPDATE_TOUR_GUIDE_FAILURE = "TOURGUIDE/UPDATE_TOUR_GUIDE_FAILURE",
  NOT_DISPLAY_ANYMORE = "TOURGUIDE/NOT_DISPLAY_ANYMORE",
  NOT_DISPLAY_ANYMORE_SUCCESS = "TOURGUIDE/NOT_DISPLAY_ANYMORE_SUCCESS",
  NOT_DISPLAY_ANYMORE_FAILURE = "TOURGUIDE/NOT_DISPLAY_ANYMORE_FAILURE",
  RESET_TOUR_GUIDE = "TOURGUIDE/RESET_TOUR_GUIDE",
}

interface InitTourGuideSuccessAction {
  type: TourGuideActionTypes.INIT_TOURGUIDE_SUCCESS
  tourguide: TourGuide
}

export interface PassThroughtTourGuideAction {
  type: TourGuideActionTypes.PASSTHROUGHT_TOURGUIDE
}

export interface StartTourGuideAction {
  type: TourGuideActionTypes.START_TOUR
  tour: Tour
}

interface StartTourSuccessGuideAction {
  type: TourGuideActionTypes.START_TOUR_SUCCESS
  tour: Tour
}

interface ShowTourGuideAction {
  type: TourGuideActionTypes.SHOW_TOUR_GUIDE
}

export interface UpdateTourGuideDisplayAction {
  type: TourGuideActionTypes.UPDATE_TOUR_GUIDE_DISPLAY
  display: boolean
}

interface NextTourStepAction {
  type: TourGuideActionTypes.NEXT_TOUR_STEP
  tour: Tour
}

interface PrevTourStepAction {
  type: TourGuideActionTypes.PREV_TOUR_STEP
  tour: Tour
}

export interface ExitTourGuideAction {
  type: TourGuideActionTypes.EXIT_TOUR_GUIDE
  tour: Tour
}

interface ExitTourGuideSuccessAction {
  type: TourGuideActionTypes.EXIT_TOUR_GUIDE_SUCCESS
  tourguide: TourGuide
}

export interface UpdateTourGuideAction {
  type: TourGuideActionTypes.UPDATE_TOUR_GUIDE
  tour: Tour
}

interface NotDisplayAnymoreAction {
  type: TourGuideActionTypes.NOT_DISPLAY_ANYMORE
}

export interface ResetTourGuideAction {
  type: TourGuideActionTypes.RESET_TOUR_GUIDE
}

export type TourGuideActions =
  | InitTourGuideSuccessAction
  | PassThroughtTourGuideAction
  | ShowTourGuideAction
  | StartTourSuccessGuideAction
  | NextTourStepAction
  | PrevTourStepAction
  | ExitTourGuideAction
  | ExitTourGuideSuccessAction
  | NotDisplayAnymoreAction
