import { Link } from "react-router-dom"

interface FloatingButtonProps {
  url?: string
  iconName: string
  onClick?: (evt) => void
}

const FloatingButton = ({ url, iconName, onClick }: FloatingButtonProps) => {
  return (
    <div className="menu floating-action" role="navigation">
      <Link
        data-cy="signature__add"
        onClick={onClick}
        to={url}
        data-title={iconName}
        className="btn btn-boost"
      >
        <i className="material-icons">{iconName}</i>
      </Link>
    </div>
  )
}

export default FloatingButton
