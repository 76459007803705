import { useEffect, useMemo, useState } from "react"
import FileUploadCard from "components/FileUploadCard"
import Loader from "components/Loader"
import PeoplePicker, { Tag } from "components/PeoplePicker"
import PeoplePickerCollapseLists from "components/PeoplePickerCollapseLists"
import lodash from "lodash"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import useUsersData from "features/Users/hooks/useUsersData"
import useGroupsData from "features/Groups/hooks/useGroupsData"
import { LoadingStatus } from "core/CoreModels"
import { Link, useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  createSubsidiary,
  updateSubsidiary,
  fetchSubsidiaries,
  selectSubsidiaryById,
  setSubsidiaryUpdatedStatus,
} from "features/Subsidiaries/SubsidiariesReducer"

import UserSelectors from "features/Users/UsersSelectors"
import GroupsSelectors from "features/Groups/GroupsSelectors"

import { CenteredLayout } from "pages/shared/CenteredLayout"
import Button from "components/Button"
import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"
import MultiSelect from "components/MultiSelect"
import SubsidiaryAdminPicker from "../SubsidiaryAdminPicker"
import { useForm } from "react-hook-form"
import { TextField } from "@material-ui/core"
import { RootState } from "store/configureStore"
import useAdminsAccountData from "features/Accounts/hooks/useAdminsAccountsData"
import { getBase64, getSizeOfImageFile } from "utils/ImageUtils"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"

import type { PeoplePickerCollapseListItem } from "components/PeoplePickerCollapseLists"

import { SUBSIDIARIES_URL } from "router/RouterConstants"

interface UserItem {
  type: "user" | "group"
  id: number
  label: string
}

export interface AdminItem {
  id: string
  label: string
}

interface FormData {
  name: string
  streetAddress: string
  postalCode: string
  city: string
}
const messages = defineMessages({
  nameIsRequired: {
    id: "SubsidiaryForm.NameIsRequired",
    defaultMessage: "le nom est obligatoire",
  },
})

const SubsidiaryForm: React.FC = () => {
  const dispatch = useDispatch()
  const { subsidiaryId } = useParams()
  const { formatMessage } = useIntl()
  const [imageSubsidiary, setImageSubsidiary] = useState(null)

  // Dependencies
  const { loadingStatus: usersLoadingStatus } = useUsersData()
  const { loadingStatus: groupsLoadingStatus } = useGroupsData()
  const { loadingStatus: adminsLoadingStatus, adminAccounts } =
    useAdminsAccountData()
  const { isUpdated, loadingStatus: subsidiariesLoadingStatus } =
    useSubsidiariesData()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const current = useSelector((state: RootState) =>
    selectSubsidiaryById(state, parseInt(subsidiaryId)),
  )
  const allUser = useSelector(UserSelectors.getAllUsersWithLicences)
  const allGroup = useSelector(GroupsSelectors.getAllGroups)
  const navigate = useNavigate()

  const adminAccountsNotSuperAdmin = adminAccounts?.filter(
    (admin) => !admin.isSuperAdmin(),
  )
  const subsidiaryAdmins = adminAccounts?.filter((admin) =>
    current?.aspNetUserIds.includes(admin.id),
  )

  const allAspnetUser = lodash
    .uniq([...adminAccountsNotSuperAdmin, ...subsidiaryAdmins])
    .map((user) => ({ id: user.id, label: user.userName }))

  const handleUpload = async (file) => {
    const size = await getSizeOfImageFile(file)
    const handleFileChangeCallback = (imageAsBase64Url) => {
      setImageSubsidiary({
        imageUrl: imageAsBase64Url,
        width: size.width,
        height: size.height,
      })
    }
    getBase64(file, handleFileChangeCallback)
  }

  const getUsersSubsidiary = (): UserItem[] =>
    current != undefined && usersLoadingStatus == LoadingStatus.LOADED
      ? allUser
          .filter((user) => current.userIds.includes(user.Id))
          .map((user) => ({ type: "user", id: user.Id, label: user.Username }))
      : []

  const getAdminsSubsidiary = (): AdminItem[] =>
    current != undefined &&
    subsidiariesLoadingStatus == LoadingStatus.LOADED &&
    adminsLoadingStatus == LoadingStatus.LOADED
      ? allAspnetUser
          .filter((user) => current.aspNetUserIds.includes(user.id))
          .map((user) => ({ id: user.id, label: user.label }))
      : []

  const getGroupsSubsidiary = (): UserItem[] =>
    current != undefined && groupsLoadingStatus == LoadingStatus.LOADED
      ? allGroup
          .filter((group) => current.groupIds.includes(group.Id))
          .map((group) => ({ type: "group", id: group.Id, label: group.Name }))
      : []

  const [users, setUsers] = useState<UserItem[]>(getUsersSubsidiary())
  const [groups, setGroups] = useState<UserItem[]>(getGroupsSubsidiary())

  const selectedGroups = allGroup.filter((group) =>
    groups.map((group) => group.id).includes(group.Id),
  )

  const activeMembers = useMemo(
    () => [
      ...new Set(
        selectedGroups.reduce((acc, group) => [...acc, ...group.MembersId], []),
      ),
    ],
    [selectedGroups],
  )

  const usersIds = selectedGroups
    .map((group) => group.Members.map((user) => user.Id))
    .flat(1)

  const [aspNetUsers, setAspNetUsers] = useState<AdminItem[]>(
    getAdminsSubsidiary(),
  )

  useEffect(() => {
    setGroups(getGroupsSubsidiary())
  }, [groupsLoadingStatus])

  useEffect(() => {
    setUsers(getUsersSubsidiary())
  }, [usersLoadingStatus])

  useEffect(() => {
    setAspNetUsers(getAdminsSubsidiary())
  }, [subsidiariesLoadingStatus, adminsLoadingStatus])

  useEffect(() => {
    if (current) {
      setValue("name", current.name)
      setValue("streetAddress", current.streetAddress)
      setValue("postalCode", current.postalCode)
      setValue("city", current.city)
    }
  }, [current])

  useEffect(() => {
    // in edit mode, we need to load the subsidiaries
    if (
      subsidiaryId != null &&
      subsidiariesLoadingStatus !== LoadingStatus.LOADED
    )
      dispatch(fetchSubsidiaries())
  }, [])

  useEffect(() => {
    if (isUpdated) {
      dispatch(setSubsidiaryUpdatedStatus(false))
      navigate(SUBSIDIARIES_URL)
    }
  }, [isUpdated])

  useEffect(() => {
    if (current?.iconUrl) setImageSubsidiary({ imageUrl: current.iconUrl })
  }, [current])

  const handleSave = (dataform: FormData) => {
    const createOrupdateAction =
      current?.id != null ? updateSubsidiary : createSubsidiary
    dispatch(
      createOrupdateAction({
        name: dataform.name,
        userIds: users.map((user) => user.id),
        groupIds: groups.map((group) => group.id),
        aspNetUserIds: aspNetUsers.map((admin) => admin.id.toString()),
        id: current?.id,
        streetAddress: dataform.streetAddress,
        postalCode: dataform.postalCode,
        city: dataform.city,
        iconUrl: imageSubsidiary?.imageUrl || null,
      }),
    )
  }

  const handleSelectItem =
    (
      collection: UserItem[],
      setter: React.Dispatch<React.SetStateAction<UserItem[]>>,
      type: "user" | "group",
    ) =>
    (item: PeoplePickerCollapseListItem) => {
      const mustRemove = collection.some((u) => u.id === item.id)
      if (mustRemove) {
        if (type == "group") {
          const selectUsersGroup = allGroup
            .find((group) => item.id == group.Id)
            .Members.map((user) => user.Id)
          const userItems = users.filter(
            (user) => !selectUsersGroup.includes(user.id),
          )
          setUsers(userItems)
          setter([...collection.filter((u) => u.id !== item.id)])
        } else {
          if (
            !usersIds.includes(
              typeof item.id == "string" ? parseInt(item.id) : item.id,
            )
          ) {
            setter([...collection.filter((u) => u.id !== item.id)])
          }
        }
      } else {
        if (type == "group") {
          const groupMembersIds =
            allGroup.find((group) => group.Id === item.id)?.MembersId || []

          const remainingUsers = users.filter(
            (affectedUser) => !groupMembersIds.includes(affectedUser.id),
          )
          setUsers(remainingUsers)
        }
        setter([
          ...collection,
          { id: item.id as number, label: item.label, type },
        ])
      }
    }

  const selectAll =
    (setter, type: "user" | "group") =>
    (all: PeoplePickerCollapseListItem[]) => {
      setter(
        all.map((item) => ({ id: item.id as number, label: item.label, type })),
      )
    }

  const removeAll = (setter) => () => setter([])

  const removeOneAdmin = (id: string) => {
    setAspNetUsers(aspNetUsers.filter((admin) => id != admin.id))
  }

  const removePeoplePicker = (tag: Tag) => {
    if (tag.type == "user") {
      setUsers(users.filter((user) => user.id != tag.id))
    } else {
      setGroups(groups.filter((group) => group.id != tag.id))
    }
  }

  const handleSelectUser = handleSelectItem(users, setUsers, "user")
  const handleSelectGroup = handleSelectItem(groups, setGroups, "group")

  return (
    <section
      className="col-12 subsidiaries-home"
      data-tour="subsidiaries__home"
    >
      <section className="l-sub-header">
        <h4>
          <FormattedMessage
            id="SubsidiaryForm.Title"
            defaultMessage="Nouvelle filiale"
          />
        </h4>
      </section>

      <Loader
        fullScreen
        isLoading={
          usersLoadingStatus === LoadingStatus.PENDING ||
          groupsLoadingStatus === LoadingStatus.PENDING
        }
      />

      <CenteredLayout full withSubTitle>
        <div className="l-card l-full l-col margin-2 margin-r-4 subsidiary-card">
          <div className="l-full l-row subsidiary-container">
            <div className="l-col w-1/2">
              <section className="section section-image">
                {imageSubsidiary !== null && (
                  <div className="section-image_container">
                    {(!subsidiaryId ||
                      (subsidiaryId && current.iconUrl === null)) && (
                      <CancelOutlinedIcon
                        className={"section-image_cancel-button"}
                        onClick={() => setImageSubsidiary(null)}
                      />
                    )}
                    <img src={imageSubsidiary.imageUrl} alt="filiale_Logo" />
                  </div>
                )}
                <FileUploadCard
                  label={
                    <FormattedMessage
                      id="SubsidiaryIcon.UploadTxt"
                      defaultMessage="Cliquez ou faites glisser un logo ici"
                    />
                  }
                  hideProgress={true}
                  fileTypes="image/png, image/jpeg, image/gif"
                  onFileChange={handleUpload}
                />
              </section>
              <section className="section">
                <div className="title">
                  <FormattedMessage
                    id="SubsidiaryForm.Name"
                    defaultMessage="Nom *"
                  />
                  <TextField
                    {...register("name", {
                      required: formatMessage(messages.nameIsRequired),
                    })}
                    error={errors["name"]}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                </div>
              </section>

              <section className="section">
                <div className="title">
                  <FormattedMessage
                    id="SubsidiaryForm.AssignationsAdminsTitle"
                    defaultMessage="Administrateur(s)"
                  />
                  <MultiSelect
                    options={allAspnetUser}
                    value={aspNetUsers}
                    onChange={setAspNetUsers}
                    error={false}
                    idProperty="id"
                    displayProperty="label"
                    emptyPlaceholder="0 administrateur"
                  />
                </div>
              </section>
              <section className="section">
                <div className="title">
                  <FormattedMessage
                    id="SubsidiaryForm.AssignationsTitle"
                    defaultMessage="Assigner des utilisateurs"
                  />

                  <PeoplePickerCollapseLists
                    groupCollapseList={{
                      title: (
                        <FormattedMessage
                          id="Campaigns.Groups"
                          defaultMessage="Groupes"
                        />
                      ),
                      selectedData: groups,
                      handleClick: handleSelectGroup,
                      selectAll: selectAll(setGroups, "group"),
                      removeAll: removeAll(setGroups),
                    }}
                    userCollapseList={{
                      title: (
                        <FormattedMessage
                          id="Campaigns.Users"
                          defaultMessage="Utilisateurs"
                        />
                      ),
                      selectedData: users,
                      handleClick: handleSelectUser,
                      selectAll: selectAll(setUsers, "user"),
                      removeAll: removeAll(setUsers),
                    }}
                    search={""}
                    activeMembers={activeMembers}
                  />
                </div>
              </section>
            </div>
            <div className="l-col w-1/2">
              <section className="section">
                <div className="title">
                  <FormattedMessage
                    id="SubsidiaryForm.SelectedAdminSumary"
                    defaultMessage="Administrateurs sélectionnés"
                  />
                  <SubsidiaryAdminPicker
                    subsidiariesAdmin={aspNetUsers}
                    removeOne={removeOneAdmin}
                  />
                </div>
              </section>
              <section className="section">
                <div className="title">
                  <FormattedMessage
                    id="SubsidiaryForm.SelectedUsersSumary"
                    defaultMessage="Utilisateurs / Groupes assignés"
                  />

                  <PeoplePicker
                    tags={[
                      ...groups,
                      ...users.filter((user) => !usersIds.includes(user.id)),
                    ]}
                    onTagClick={removePeoplePicker}
                    overflowDirection="no-overflow"
                  />
                </div>
              </section>
            </div>
          </div>
          <div className="subsidiary-bottom w-full">
            <Link onClick={() => null} to={SUBSIDIARIES_URL}>
              <div className="bmm-button bmm-button--reverse">
                <span>
                  <FormattedMessage
                    id="SubsidiaryForm.Cancel"
                    defaultMessage="Annuler"
                  />
                </span>
              </div>
            </Link>
            <Button
              label="Enregistrer"
              onClick={handleSubmit(handleSave)}
            ></Button>
          </div>
        </div>
      </CenteredLayout>
    </section>
  )
}

export default SubsidiaryForm
