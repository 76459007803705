import { FunctionComponent } from "react"

import moment from "moment"

import { Point } from "@nivo/line"

interface TrackingGraphTooltipProps {
  point: Point
  unit?: string
}

const TrackingGraphTooltip: FunctionComponent<TrackingGraphTooltipProps> = ({
  point,
  unit = " clics",
}) => {
  const { data } = point
  const { xFormatted, yFormatted } = data
  return (
    <div className="tracking-graph-tooltip">
      <p className="tracking-graph-tooltip__date">{`Le ${moment(
        xFormatted,
      ).format("D MMMM YYYY")}`}</p>
      <p className="tracking-graph-tooltip__clics">{`${yFormatted}${unit}`}</p>
    </div>
  )
}

export default TrackingGraphTooltip
