import { FunctionComponent, ReactNode } from "react"

import { useDispatch, useSelector } from "react-redux"

import { useLocation, Navigate } from "react-router-dom"

import { storePath } from "features/App/AppReducers"

import { accountsSelectors } from "features/Accounts"

import { LOGIN_URL } from "router/RouterConstants"

interface WithAuthenticationProps {
  children: ReactNode
  needAuthentification?: boolean
}

const WithAuthentication: FunctionComponent<WithAuthenticationProps> = ({
  children,
  needAuthentification,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isAuthenticated = useSelector(accountsSelectors.getIsAuthentified)

  dispatch(storePath(location.pathname))

  if (needAuthentification && !isAuthenticated)
    return <Navigate to={LOGIN_URL} />

  return <>{children}</>
}

export default WithAuthentication
