import React from "react"
import Slide from "./Slide"

interface Props {
  selectedIndex: number
}

class GalleryContainer extends React.Component<Props> {
  render() {
    const { children } = this.props

    return (
      <div className="gallery__container">
        {React.Children.map(children, (child, idx) => {
          return (
            <Slide
              key={idx}
              classname={this.props.selectedIndex === idx ? "active" : ""}
              index={idx}
            >
              {child}
            </Slide>
          )
        })}
      </div>
    )
  }
}

export default GalleryContainer
