import { connect } from "react-redux"
import { trackingSelectors } from "features/Tracking"
import BannerTrackingList from "./BannerTrackingList"

const mapStateToProps = (state, ownProps) => ({
  scenarioMetrics: trackingSelectors.getBannersMetrics(state),
})

const ConnectedBannerSignatureList = connect(
  mapStateToProps,
  null,
)(BannerTrackingList)

export default ConnectedBannerSignatureList
