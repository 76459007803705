import { FunctionComponent, useEffect, useState } from "react"

import classNames from "classnames"

import ReactCrop, { Crop } from "react-image-crop"
import { defineMessages, useIntl } from "react-intl"

import { ModalActions, ModalService } from "components/Modal"

import UserAvatarFormat from "./UserAvatarFormat"

import { blobToDataURL, getCroppedImgAsDataUrl } from "utils/ImageUtils"

import { USER_AVATAR_MIN_SIZE } from "features/Users/UsersConstants"

export type PictureFormat = "square" | "rounded" | "round"

interface UserAvatarCropperProps {
  uploadedFile: Blob
  onValidate: (picture: string) => void
}

const messages = defineMessages({
  cancel: {
    id: "UserAvatarManager.Cancel",
    defaultMessage: "Annuler",
  },
  validate: {
    id: "UserAvatarManager.Validate",
    defaultMessage: "Valider",
  },
  userPicture: {
    id: "UserAvatarManager.UserPicture",
    defaultMessage: "photo utilisateur",
  },
})

const cropInitialState = {
  x: 0,
  y: 0,
  width: USER_AVATAR_MIN_SIZE,
  height: USER_AVATAR_MIN_SIZE,
  unit: "px" as Crop["unit"],
}

const UserAvatarCropper: FunctionComponent<UserAvatarCropperProps> = ({
  uploadedFile,
  onValidate,
}) => {
  const { formatMessage } = useIntl()
  const [crop, setCrop] = useState<Crop>(cropInitialState)
  const [pictureFormat, setPictureFormat] = useState<PictureFormat>("square")
  const [image, setImage] = useState(null)

  const handlePictureFormat = (nxtFormat: PictureFormat) => {
    setPictureFormat(nxtFormat)
  }

  useEffect(() => {
    blobToDataURL(uploadedFile).then((result) => setImage(result))
  }, [])

  const handleCroppedImage = () => {
    getCroppedImgAsDataUrl(
      image,
      crop,
      (croppedImageDataUrl) => {
        onValidate(croppedImageDataUrl)
      },
      pictureFormat === "square" ? undefined : pictureFormat,
    )
  }

  return (
    <div className="user-avatar-manager__cropper">
      <div className="user-avatar-manager__crop-field">
        <ReactCrop
          onChange={(updatedCrop) => setCrop(updatedCrop)}
          crop={crop}
          aspect={1}
          minWidth={USER_AVATAR_MIN_SIZE}
          minHeight={USER_AVATAR_MIN_SIZE}
          className={classNames(
            "user-avatar-manager__crop-field--image",
            pictureFormat === "rounded" && "rounded-corners",
          )}
          circularCrop={pictureFormat === "round"}
        >
          <img src={image} alt={formatMessage(messages.userPicture)} />
        </ReactCrop>
        <UserAvatarFormat
          pictureFormat={pictureFormat}
          onChangeFormat={handlePictureFormat}
        />
      </div>
      <div className="user-avatar-manager__crop-actions">
        <ModalActions>
          <ModalActions.Secondary onClick={() => ModalService.close()}>
            {formatMessage(messages.cancel)}
          </ModalActions.Secondary>
          <ModalActions.Main onClick={handleCroppedImage}>
            {formatMessage(messages.validate)}
          </ModalActions.Main>
        </ModalActions>
      </div>
    </div>
  )
}

export default UserAvatarCropper
