import { usersSelectors } from "../.."
import { connect } from "react-redux"
import ImportExcelPopup from "./ImportExcelPopup"
import { uploadExcelFileAction } from "features/Users/UsersActions"

interface OwnProps {
  open: boolean
  close: () => void
}

interface StateToProps {
  excelModelLink: string
}

const mapStateToProps = (state) => ({
  excelModelLink: usersSelectors.getExcelModelLink(state),
})

interface DispatchToProps {
  uploadFile: (file: File) => void
}

const mapDispatchToProps = {
  uploadFile: uploadExcelFileAction,
}

const ConnectedImportExcelPopup = connect<
  StateToProps,
  DispatchToProps,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(ImportExcelPopup)

export default ConnectedImportExcelPopup
