import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import PillButtonsGroup from "components/PillButtonsGroup"

const messages = defineMessages({
  internal: {
    id: "SignaturesList.Internal",
    defaultMessage: "Interne",
  },
  external: {
    id: "SignaturesList.External",
    defaultMessage: "Externe",
  },
})

const InternalExternalPills: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [current, setCurrent] = useState("internal")

  const handleCurrent = () => {
    if (current === "internal") setCurrent("external")
    if (current === "external") setCurrent("internal")
  }

  const options = [
    {
      id: "internal",
      children: <>{formatMessage(messages.internal)}</>,
    },
    { id: "external", children: <>{formatMessage(messages.external)}</> },
  ]

  return (
    <PillButtonsGroup
      current={current}
      onChange={handleCurrent}
      pillButtons={options}
      size="large"
    />
  )
}

export default InternalExternalPills
