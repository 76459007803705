import ServiceApi from "core/services/serviceApi"
import PortalApi from "core/services/portalApi"

import { TypedResult } from "core/CoreModels"

import { CreateTenantOfferDto } from "features/Offers/OffersModels"

export function addTenantOffer(
  tenantOffer: CreateTenantOfferDto,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("POST", `/tenantoffers`, { data: tenantOffer })
}

export function fetchAllOffersApi(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/offers")
}

export function fetchOffersOptionsApi(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/offers/options")
}

export function fetchCurrentTenantOfferApi(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/tenantoffers/current")
}

export function fetchAllCatalogOffersApi(): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", "/catalog/offers")
}
