import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as IconUser } from "assets/icon-user.svg"

interface UsersTileLatestProps {
  latestUsers: string[]
  isLoading: boolean
}

const LATEST_USER_LOADING = 10

const messages = defineMessages({
  latest: {
    id: "HomePage.UsersTileLatestAdded",
    defaultMessage: "Derniers ajouts",
  },
})

const UsersTileLatest: FunctionComponent<UsersTileLatestProps> = ({
  latestUsers,
  isLoading,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="users-tile__latest--container">
      <p className="users-tile__latest--label">
        {formatMessage(messages.latest)}
      </p>
      <div className="users-tile__latest--users">
        {isLoading
          ? [...Array(LATEST_USER_LOADING).keys()].map((el) => (
              <div className="users-tile__latest--user-container" key={el}>
                <div className="users-tile__skeleton-loading--icon" />
                <div className="users-tile__skeleton-loading--user" />
              </div>
            ))
          : latestUsers.map((user) => (
              <div key={user} className="users-tile__latest--user-container">
                <div className="users-tile__latest--icon-container">
                  <IconUser className="users-tile__latest--icon" />
                </div>

                <p className="users-tile__latest--username">{user}</p>
              </div>
            ))}
      </div>
    </div>
  )
}

export default UsersTileLatest
