import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import SubsidiariesPageContent from "features/Subsidiaries/components/SubsidiariesPageContent"
import SubsidiariesPageHeader from "features/Subsidiaries/components/SubsidiariesPageHeader"

import Page from "components/Page"

const SubsidiariesPage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="SubsidiariesHome.Subsidiaries"
          defaultMessage="Filiales"
        />
      }
    >
      <SubsidiariesPageHeader />
      <SubsidiariesPageContent />
    </Page>
  )
}

export default SubsidiariesPage
