import { FunctionComponent, ReactNode } from "react"

import { useSelector } from "react-redux"

import { Navigate } from "react-router-dom"

import { tourGuideSelectors } from "features/TourGuide"

import { ON_BOARDING_URL } from "router/RouterConstants"

interface WithTourGuideProps {
  children: ReactNode
  tourguide?: boolean
}

const WithTourGuide: FunctionComponent<WithTourGuideProps> = ({
  children,
  tourguide,
}) => {
  const isFirstVisit = useSelector(tourGuideSelectors.isFirstVisit)
  const currentTour = useSelector(tourGuideSelectors.getCurrentTour)

  const isRedirectToOnBoarding = isFirstVisit && currentTour === null

  if (tourguide && isRedirectToOnBoarding)
    return <Navigate to={ON_BOARDING_URL} />

  return <>{children}</>
}

export default WithTourGuide
