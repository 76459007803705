import { createAction } from "@reduxjs/toolkit"

import Feature from "./FeaturesModels"

export const updateTenantFeatureAction = createAction(
  "features/updateTenantFeature",
  function prepare(feature: Feature) {
    return { payload: feature }
  },
)
