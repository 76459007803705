import { put, call, takeLatest, select, take } from "redux-saga/effects"
import { fetchFeatures as fetchFeaturesApi } from "./FeatureTogglrApi"
import { withSagaErrorHandler } from "features/Errors"
import {
  fetchFeatures as fetchFeaturesAction,
  setFeatures,
} from "./FeatureTogglrReducer"
import { Feature, FeaturesResponse } from "./FeatureTogglrModels"
import { getFeatures, getFeaturesLoadingStatus } from "./FeatureTogglrSelectors"

import { LoadingStatus, TypedResult } from "core/CoreModels"

export function* getOrLoadFeaturesAsync() {
  const loadingStatus = yield select(getFeaturesLoadingStatus)

  if (loadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchFeaturesAction())
    yield take(setFeatures.type)
  }

  return yield select(getFeatures)
}

function* fetchFeaturesAsync() {
  const result: TypedResult<FeaturesResponse> = yield call(fetchFeaturesApi)

  if (!result.success) throw new Error(result.errorMessage)

  const features: Feature[] = result?.result?.features
    ? Object.entries(result.result.features).map(([key, value]) => ({
        name: key,
        isActive: value.active,
      }))
    : []

  yield put(setFeatures(features))
}

export const featuresTogglrWatchers = [
  takeLatest(
    fetchFeaturesAction.type,
    withSagaErrorHandler(fetchFeaturesAsync),
  ),
]
