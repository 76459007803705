import { FunctionComponent } from "react"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"

import OfferDetails from "./components/OfferDetails"

interface OfferDetailsPopupProps {
  open: boolean
  onClose: () => void
}

const OfferDetailsPopup: FunctionComponent<OfferDetailsPopupProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <OfferDetails />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OfferDetailsPopup
