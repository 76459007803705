import { FunctionComponent, ReactNode } from "react"

interface RightSideColumnProps {
  children: ReactNode
}

export type RightSideColumnType = FunctionComponent<RightSideColumnProps>

const RightSideColumn: RightSideColumnType = ({ children }) => {
  return <div className="two-columns-layout__right-side">{children}</div>
}

export default RightSideColumn
