import { FunctionComponent, useEffect, useMemo } from "react"

import { useSelector } from "react-redux"
import { defineMessages, useIntl } from "react-intl"

import accountsInformationsContentOne from "features/Accounts/assets/account-informations-customers-one.webp"
import accountsInformationsContentTwo from "features/Accounts/assets/account-informations-customers-two.webp"

import { ReactComponent as IconDuotoneEye } from "assets/duotone/icon-eye-duotone.svg"
import { ReactComponent as IconDuotonePointer } from "assets/duotone/icon-pointer-duotone.svg"
import { ReactComponent as IconDuotoneClock } from "assets/duotone/icon-clock-duotone.svg"

import { accountsSelectors } from "features/Accounts"

const messages = defineMessages({
  clicksPerDayValue: {
    id: "AccountsInformations.ReInsurancePanelClickPerDayValue",
    defaultMessage: "20",
  },
  clicksPerDay: {
    id: "AccountsInformations.ReInsurancePanelClickPerDayLabel",
    defaultMessage: "Vues par jour",
  },
  clicksPerMonthValue: {
    id: "AccountsInformations.ReInsurancePanelClickPerMonthValue",
    defaultMessage: "4",
  },
  clicksPerMonth: {
    id: "AccountsInformations.ReInsurancePanelClickPerMonthLabel",
    defaultMessage: "Clics potentiels par mois",
  },
  timeWinValue: {
    id: "AccountsInformations.ReInsurancePanelTimeWinValue",
    defaultMessage: "1h",
  },
  timeWin: {
    id: "AccountsInformations.ReInsurancePanelTimeWinLabel",
    defaultMessage: "De gagné !",
  },
})

const slidesToSteps = [
  { steps: [0, 1], slide: 0 },
  { steps: [2, 3], slide: 1 },
  { steps: [4, 5, 6], slide: 2 },
]

const AccountsInformationsReInsurancePanelContent: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const currentRegisterStep = useSelector(accountsSelectors.getRegisterStep)

  const slideStep = useMemo(
    () => slidesToSteps.map((el) => el.steps.map(() => el.slide)).flat(),
    [],
  )

  useEffect(() => {
    document
      .getElementById(`slide-${slideStep[currentRegisterStep]}`)
      ?.scrollIntoView()
  }, [currentRegisterStep])

  return (
    <div className="reinsurance-panel__content">
      <div className="reinsurance-panel__content--item" id="slide-0">
        <img
          className="reinsurance-panel__content--img"
          src={accountsInformationsContentOne}
          alt="logos clients"
        />
      </div>
      <div className="reinsurance-panel__content--item" id="slide-1">
        <img
          className="reinsurance-panel__content--img"
          src={accountsInformationsContentTwo}
          alt="logos clients"
        />
      </div>
      <div className="reinsurance-panel__content--item" id="slide-2">
        <div className="reinsurance-panel__card">
          <div className="reinsurance-panel__card--item">
            <IconDuotoneEye className="reinsurance-panel__card--icon" />
            <div className="reinsurance-panel__card--container">
              <p className="reinsurance-panel__card--value">
                {formatMessage(messages.clicksPerDayValue)}
              </p>
              <p className="reinsurance-panel__card--label">
                {formatMessage(messages.clicksPerDay)}
              </p>
            </div>
          </div>
          <div className="reinsurance-panel__card--item">
            <IconDuotonePointer className="reinsurance-panel__card--icon" />
            <div className="reinsurance-panel__card--container">
              <p className="reinsurance-panel__card--value">
                {formatMessage(messages.clicksPerMonthValue)}
              </p>
              <p className="reinsurance-panel__card--label">
                {formatMessage(messages.clicksPerMonth)}
              </p>
            </div>
          </div>
          <div className="reinsurance-panel__card--item">
            <IconDuotoneClock className="reinsurance-panel__card--icon" />
            <div className="reinsurance-panel__card--container">
              <p className="reinsurance-panel__card--value">
                {formatMessage(messages.timeWinValue)}
              </p>
              <p className="reinsurance-panel__card--label">
                {formatMessage(messages.timeWin)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountsInformationsReInsurancePanelContent
