import { FunctionComponent, ReactNode } from "react"

interface SignatureCardContentProps {
  children: ReactNode
}

export type SignatureCardContentType =
  FunctionComponent<SignatureCardContentProps>

const SignatureCardContent: SignatureCardContentType = ({ children }) => {
  return <div className="signature-card__content">{children}</div>
}

export default SignatureCardContent
