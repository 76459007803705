import React from "react"
import moment from "moment"
import CalendarRange from "./entities/CalendarRange"

interface Props {
  ranges: CalendarRange[]
  date: string
  posX?: number
  posY?: number
  isVisible?: boolean
  onSelectRange: any
  onPopdown: any
}

const CalendarViewDay: React.FC<Props> = (props) => {
  /* State */

  const dateFormat = moment.locale() === "fr" ? "dddd D MMMM" : "MMMM D, dddd"
  const date = moment(props.date).format(dateFormat)
  const year = moment(props.date).format("YYYY")
  const ranges = props.ranges

  ranges.sort((r1, r2) => (r1.order < r2.order ? -1 : 1))

  const jsxRanges = ranges.map((range: CalendarRange, index) => {
    const style = { background: range.color }
    return (
      <div
        key={index}
        className="cv-btn cvd-range"
        style={style}
        onClick={() => {
          props.onSelectRange(range.id)
        }}
      >
        {range.name}
      </div>
    )
  })

  const pos = { marginLeft: props.posX, marginTop: props.posY }

  /* Rendering */

  if (props.isVisible === false) return null

  return (
    <div className="calendar-view-day" style={pos}>
      <div className="cvd-date">
        <div className="cvd-day">{date}</div>
        <div className="cvd-year">{year}</div>
      </div>
      <div className="cvd-ranges">{jsxRanges}</div>
    </div>
  )
}

export default CalendarViewDay
