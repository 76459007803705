import * as $ from "jquery"
import { first } from "lodash"
import { call, put, select, take, fork, delay } from "redux-saga/effects"

import {
  TrialActionTypes,
  SendTrialSignaturePreviewAction,
  SetTrialStepAction,
} from "features/Trial"
import {
  signaturesSelectors,
  TypeKeys as signatureType,
} from "features/Signatures"

import { Signature } from "entities/Signature"
import { User } from "features/Users/UserModels"
import { usersSelectors } from "features/Users"

import {
  fetchAccountPropertySettings,
  updateAccountPropertySettings,
} from "features/Accounts/AccountsApi"
import {
  assignUsersToSignature,
  getUserSignature,
  sendTrialSignaturePreview,
} from "features/Signatures/SignaturesApi"
import {
  createSignatureSuccess,
  setActiveSignatureSuccess,
  updateSignatureName,
  updateSignatureSuccess,
} from "features/Signatures/SignaturesReducer"
import { initSignatureFormAction } from "features/Signatures/SignaturesActions"
import { getOrLoadSignaturesSlice } from "features/Signatures/SignaturesSagas"
import { accountsSelectors } from "features/Accounts"
import TenantProperty from "entities/TenantProperty"
import { createTenantPropertyAction } from "features/Accounts/AccountsActions"

export function* initTrialData() {
  // Données nécessaires au bon fonctionnement du formulaire :
  // signatures chargées
  yield call(getOrLoadSignaturesSlice, "all")

  // définir la signature si elle existe
  const signatureId = yield call(setTrialSignature)

  // Initialisation le formulaire
  yield put(initSignatureFormAction())

  // si la signature Trial n'existe pas encore,
  // - on prévoit de l'assigner / activer par défaut après sa creation
  // - on prévoit de surcharger également le titre de la signature
  if (signatureId) {
    yield fork(waitSignatureUpdated)
  } else {
    yield fork(waitSignatureCreated)
    // on attend que la signature soit initialisée, puis on défini le nom de la signature de test
    yield take(setActiveSignatureSuccess.type)
    yield put(updateSignatureName("Modèle signature test"))
  }
}

export function* waitSignatureCreated() {
  // cette action doit s'executer après la creation de la signature
  yield take(createSignatureSuccess.type)
  yield call(activeAndAssigneNewTrialSignature)
}

export function* waitSignatureUpdated() {
  // cette action doit s'executer après la modification de la signature
  yield take(updateSignatureSuccess.type)
  // Activation de la signature
  yield call(activeUpdatedTrialSignature)
}

/**
 * Surcharge de la méthode de creation de signature pour qu'elle soit opérationnelle directement :
 * Dès sa creation effective, on effectue en plus les actions suivantes:
 *  - assignation l'utilisateur de l'administrateur à cette signature
 *  - activation cette signature
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* activeAndAssigneNewTrialSignature() {
  const adminUser: User = yield select(usersSelectors.getCurrentUser)

  const activeSignature = yield select(signaturesSelectors.getActiveSignature)

  const assignedUsers = []

  if (adminUser?.Id) assignedUsers.push(adminUser.Id)

  yield call(assignUsersToSignature, activeSignature.Id, assignedUsers)

  // Activation de la signature
  yield put({
    type: signatureType.ACTIVATE_SIGNATURE,
    signature: activeSignature,
  })

  yield call(createBannerUnlockedProperty)
}

export function* activeUpdatedTrialSignature() {
  // Récuperation du BmmUser créé pour l'administrateur courant
  const activeSignature = yield select((state) =>
    signaturesSelectors.getActiveSignature(state),
  )
  // Si la signature est déjà activée, on dispatch directement l'action de succes
  if (activeSignature.Activated === true) {
    yield put(updateSignatureSuccess(activeSignature))
    return
  }
  // Activation de la signature
  yield put({
    type: signatureType.ACTIVATE_SIGNATURE,
    signature: activeSignature,
  })
}

/**
 * Mise à jour du parcours utilisateur Trial en base
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* setTrialStep(action: SetTrialStepAction) {
  try {
    const STEP_NUMBERS = 7
    if (action.step == null || action.step < 0 || action.step > STEP_NUMBERS) {
      yield put({
        type: TrialActionTypes.SET_TRIAL_STEP_FAILURE,
        error: "Invalid Step: " + action.step,
      })
      return
    }

    yield call(updateAccountPropertySettings, "Trial", action.step)
    yield put({
      type: TrialActionTypes.SET_TRIAL_STEP_SUCCESS,
      trialStep: action.step,
    })
  } catch (error) {
    yield put({ type: TrialActionTypes.SET_TRIAL_STEP_FAILURE, error })
  }
}

/**
 * Récupération du parcours utilisateur Trial en base
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchTrialStep() {
  try {
    const trialPropertyResponse = yield call(
      fetchAccountPropertySettings,
      "Trial",
    )
    const trialStep = parseInt(trialPropertyResponse.result)
    yield put({ type: TrialActionTypes.FETCH_TRIAL_SUCCESS, trialStep })
  } catch (error) {
    yield put({ type: TrialActionTypes.FETCH_TRIAL_FAILURE, error })
  }
}

/**
 * Initialisation du tunnel Trial avec la signature si elle existe
 */
export function* setTrialSignature() {
  const allSignatures: Signature[] = yield select(
    signaturesSelectors.getAllSignatures,
  )
  const lastSignature = first(allSignatures)

  if (lastSignature?.Id) return lastSignature.Id

  return null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* sendTrialSignaturePreviewByMail(
  sendTrialAction: SendTrialSignaturePreviewAction,
) {
  try {
    // Récuperation du BmmUser créé pour l'administrateur courant
    const adminUser: User = yield select(usersSelectors.getCurrentUser)
    // Recupération de la signature active
    const activeSignature: Signature = yield select(
      signaturesSelectors.getActiveSignature,
    )

    if (adminUser == null || activeSignature == null) return

    // Si on est dans le tunnel Signature, on attend que cette dernière soit créée, assignée et activée avant d'envoyer le mail
    if (!sendTrialAction.withBanner) {
      if (!activeSignature.Id || !activeSignature.Activated)
        // Si elle n'est pas encore activée, on attend l'activation
        yield take(updateSignatureSuccess.type)
    }

    // On ajoute un délai de 2s pour être sûr que la modification en base s'est propagée avant l'appel du service
    yield delay(2000)
    // Récupèration de la signature de l'utilisateur comme s'il utilisait le vrai service
    const signature = yield call(getUserSignature, adminUser.Username)

    if (signature.success) {
      let signatureTemplate = signature.result.template

      // hack - si on ne souhaite pas recevoir la bannière, on la retire du markup avant envoi
      const $signatureHtml = $(signatureTemplate)
      const $bannerTable = $signatureHtml.find("table:last")
      if ($bannerTable != null && $bannerTable.length > 0) {
        if (!sendTrialAction.withBanner) {
          $bannerTable.empty()
        } else {
          // hack - redimensionnement à la volée pour adapter la bannière au modele de mail - Template 2/5/10
          const $bannerimage = $bannerTable.find("img")
          // FIX - redimensionnement de bannière pour qu'elle ne paraisse pas énorme
          if ($bannerimage.length > 0) {
            // garde-fou pour ne pas redimensionner les réseaux sociaux
            if ($bannerimage.attr("src").indexOf("images/common") === -1) {
              $bannerimage.attr("style", "max-width:450px")
            }
          }
        }
      }
      if ($signatureHtml.length > 0)
        signatureTemplate = $signatureHtml[0].outerHTML

      // Envoie la signature par email
      yield call(
        sendTrialSignaturePreview,
        adminUser.Username,
        signatureTemplate,
        sendTrialAction.withBanner,
      )
      yield put({ type: TrialActionTypes.SEND_TRIAL_SIGNATURE_PREVIEW_SUCCESS })
    }
  } catch (error) {
    const errorMsg = error.message ? error.message : error
    yield put({
      type: TrialActionTypes.SEND_TRIAL_SIGNATURE_PREVIEW_FAILURE,
      error: errorMsg,
    })
  }
}

function* createBannerUnlockedProperty() {
  const campaignUnlockedProperty = yield select(
    accountsSelectors.getTrialCampaignUnlockedProperty,
  )

  if (campaignUnlockedProperty?.Value === "created") return

  const relatedProperty = new TenantProperty(
    "campaignUnlockedProperty",
    "created",
  )

  yield put(createTenantPropertyAction(relatedProperty))
}
