import { FunctionComponent } from "react"

const TrackingTileLoadingState: FunctionComponent = () => {
  return (
    <div className="tracking-tile__skeleton-loading--container">
      <div className="tracking-tile__skeleton-loading--label" />
      <div className="tracking-tile__skeleton-loading--infos" />
    </div>
  )
}

export default TrackingTileLoadingState
