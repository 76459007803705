import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Loader from "components/Loader"

import ScenarioForm from "features/Scenarios/components/ScenarioForm"
import CampaignScenarioPreview from "features/Scenarios/components/CampaignScenarioPreview"

import { useScenarioFromRoutes } from "features/Scenarios/hooks/useScenarioFromRoutes"
import { useScenarioData } from "features/Scenarios/hooks/useScenarioData"
import useAllSignaturesData from "features/Signatures/hooks/useAllSignaturesData"
import useAllBuilderSignaturesData from "features/BuilderSignatures/hooks/useAllBuilderSignaturesData"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { isBuilderSignature } from "features/BuilderSignatures/BuilderSignaturesHelpers"

import {
  ScenarioContextProvider,
  useScenarioFormContext,
} from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

// steps
import {
  CampaignFormImages,
  CampaignFormScenario,
  CampaignFormImagesSidebar,
} from "features/Scenarios/components/CampaignFormImages"
import {
  CampaignFormSettings,
  CampaignFormSettingsSidebar,
} from "features/Scenarios/components/CampaignFormSettings"
import {
  CampaignFormUsers,
  CampaignFormUsersSidebar,
  CampaignFormSignaturesSidebar,
} from "features/Scenarios/components/CampaignFormUsers"
import {
  CampaignFormSummary,
  CampaignFormSummarySidebar,
} from "features/Scenarios/components/CampaignFormSummary"

import { featureNames } from "config/config.features"
import { CAMPAIGNS_URL } from "router/RouterConstants"

import {
  ScenarioFormVm,
  SignaturesScenBySignsFeat,
} from "features/Scenarios/ScenarioModels"
import { LoadingStatus } from "core/CoreModels"
import type { NavigationData } from "features/Scenarios/components/NavButtons"

const ScenarioFormPage: FunctionComponent = () => {
  // Init scenario form from url
  const { mode, scenarioId, step, baseUrl } = useScenarioFromRoutes()

  // Check datas in state
  const {
    loadingStatus,
    saveScenario,
    allUsers,
    allGroups,
    visuals,
    existingScenario,
  } = useScenarioData(scenarioId)

  const { signatures } = useAllSignaturesData()
  const { builderSignatures } = useAllBuilderSignaturesData()

  const allSignatures: SignaturesScenBySignsFeat[] = [
    ...signatures,
    ...builderSignatures,
  ]
    .map((s) => {
      if (isBuilderSignature(s))
        return {
          id: s.id,
          name: s.name,
          preview: s.json,
          isNewBuilder: true,
        }
      return {
        id: s.Id,
        name: s.Name,
        preview: s.Template.compiledContent,
        isNewBuilder: false,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  // Guard clause for not rendering until it's loaded
  if (loadingStatus === LoadingStatus.PENDING)
    return <Loader isLoading={true} />

  const steps = [
    {
      to: `${baseUrl}1`,
      label: (
        <FormattedMessage id="Campaigns.StepImages" defaultMessage="Visuels" />
      ),
      guard: (scenarioData: ScenarioFormVm) => scenarioData.banners.length > 0,
    },
    {
      to: `${baseUrl}2`,
      label: (
        <FormattedMessage
          id="Campaigns.StepSettings"
          defaultMessage="Parametrage"
        />
      ),
      guard: (scenarioData: ScenarioFormVm) =>
        scenarioData.name !== "" &&
        (scenarioData.default === true ||
          (scenarioData.startDate != null && scenarioData.endDate != null)) &&
        (scenarioData.internal || scenarioData.external),
    },
    {
      to: `${baseUrl}3`,
      label: (
        <FormattedMessage
          id="Campaigns.StepUsers"
          defaultMessage="Utilisateurs"
        />
      ),
    },
    {
      to: `${baseUrl}4`,
      label: (
        <FormattedMessage id="Campaigns.StepSummary" defaultMessage="Résumé" />
      ),
    },
    { to: CAMPAIGNS_URL, label: "" },
  ]

  return (
    <div className="scenario-form-page">
      <ScenarioContextProvider
        mode={mode}
        existingScenario={existingScenario}
        saveScenario={saveScenario}
        allUsers={allUsers}
        allGroups={allGroups}
        allSignatures={allSignatures}
        initialStep={step}
        visuals={visuals}
      >
        {mode === "simple" && <UniqScenarioFormRoutes steps={steps} />}
        {mode === "multiple" && <MultipleScenarioFormRoutes steps={steps} />}
      </ScenarioContextProvider>
    </div>
  )
}

const UniqScenarioFormRoutes = ({ steps }) => {
  const scenarioBySignatures = useFeatureTogglr(
    featureNames.SCENARIO_BY_SIGNATURES,
  )

  const assignationStepSidebar = scenarioBySignatures?.isActive ? (
    <CampaignFormSignaturesSidebar />
  ) : (
    <CampaignFormUsersSidebar />
  )

  const { step, setStep, editingCampaign, saveCurrentCampaign } =
    useScenarioFormContext()
  // Check du passage d'une step à l'autre
  const prevStep = steps[step - 2]
  const currentStep = steps[step - 1]
  const nextStep = steps[step]
  const isLastStep = steps[step + 1] == null

  const nextData: NavigationData = {
    active: currentStep.guard == null || currentStep.guard(editingCampaign),
    visible: true,
    to: nextStep != null && nextStep.to,
    nextLabel: isLastStep ? "Terminé" : null,
  }

  const prevData: NavigationData = {
    active: prevStep != null,
    visible: prevStep != null,
    to: prevStep != null && prevStep.to,
  }

  const handleLastStepChange = () => {
    saveCurrentCampaign()
  }

  if (step === 2)
    return (
      <ScenarioForm
        steps={steps}
        activeStep={2}
        view={<CampaignFormSettings />}
        sidebar={<CampaignFormSettingsSidebar />}
        nextData={nextData}
        prevData={prevData}
        next={() => setStep(step + 1)}
        prev={() => setStep(step - 1)}
      />
    )
  if (step === 3)
    return (
      <ScenarioForm
        steps={steps}
        activeStep={3}
        view={<CampaignFormUsers />}
        sidebar={assignationStepSidebar}
        nextData={nextData}
        prevData={prevData}
        next={() => setStep(step + 1)}
        prev={() => setStep(step - 1)}
      />
    )

  if (step === 4)
    return (
      <ScenarioForm
        steps={steps}
        activeStep={4}
        view={<CampaignFormSummary />}
        sidebar={<CampaignFormSummarySidebar />}
        nextData={nextData}
        prevData={prevData}
        next={handleLastStepChange}
        prev={() => setStep(step - 1)}
      />
    )
  return (
    <ScenarioForm
      steps={steps}
      activeStep={1}
      view={<CampaignFormImages />}
      sidebar={<CampaignFormImagesSidebar />}
      nextData={nextData}
      prevData={prevData}
      next={() => setStep(step + 1)}
      prev={() => setStep(step - 1)}
    />
  )
}

// Qu'on soit sur du multi ou du simple, la logique de multi-step est la même : on parcourt des composants "pages" et on fournit
const MultipleScenarioFormRoutes = ({ steps }) => {
  const { step, setStep, editingCampaign, saveCurrentCampaign } =
    useScenarioFormContext()
  // Check du passage d'une step à l'autre
  const prevStep = steps[step - 2]
  const currentStep = steps[step - 1]
  const nextStep = steps[step]
  const isLastStep = steps[step + 1] == null

  const nextData: NavigationData = {
    active: currentStep.guard == null || currentStep.guard(editingCampaign),
    visible: true,
    to: nextStep != null && `${nextStep.to}?mode=multiple`,
    nextLabel: isLastStep ? "Terminé" : null,
  }

  const prevData: NavigationData = {
    active: prevStep != null,
    visible: prevStep != null,
    to: prevStep != null && `${prevStep.to}?mode=multiple`,
  }

  const handleLastStepChange = () => {
    saveCurrentCampaign()
  }

  const scenarioBySignatures = useFeatureTogglr(
    featureNames.SCENARIO_BY_SIGNATURES,
  )
  const assignationStepSidebar = scenarioBySignatures?.isActive ? (
    <CampaignFormSignaturesSidebar />
  ) : (
    <CampaignFormUsersSidebar />
  )

  if (step === 2)
    return (
      <ScenarioForm
        steps={steps}
        activeStep={2}
        view={<CampaignScenarioPreview />}
        sidebar={<CampaignFormSettingsSidebar showUrl={false} />}
        nextData={nextData}
        prevData={prevData}
        next={() => setStep(step + 1)}
        prev={() => setStep(step - 1)}
      />
    )
  if (step === 3)
    return (
      <ScenarioForm
        steps={steps}
        activeStep={3}
        view={<CampaignScenarioPreview />}
        sidebar={assignationStepSidebar}
        nextData={nextData}
        prevData={prevData}
        next={() => setStep(step + 1)}
        prev={() => setStep(step - 1)}
      />
    )
  if (step === 4)
    return (
      <ScenarioForm
        steps={steps}
        activeStep={4}
        view={<CampaignFormScenario editable={false} />}
        sidebar={<CampaignFormSummarySidebar />}
        nextData={nextData}
        prevData={prevData}
        next={handleLastStepChange}
        prev={() => setStep(step - 1)}
      />
    )

  return (
    <ScenarioForm
      steps={steps}
      activeStep={1}
      view={<CampaignFormScenario />}
      sidebar={<CampaignFormImagesSidebar />}
      nextData={nextData}
      prevData={prevData}
      next={() => setStep(step + 1)}
      prev={() => setStep(step - 1)}
    />
  )
}
export default ScenarioFormPage
