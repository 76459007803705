import { FunctionComponent, useEffect, useMemo } from "react"
import moment from "moment"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import useTrackingGraphData from "features/Tracking/hooks/useTrackingGraphData"
import useScenariosQueriesDateRange from "features/Tracking/hooks/useScenariosQueriesDateRange"

import ScenarioTrackingMapper from "features/Tracking/mappers/ScenarioTrackingMapper"

import SingleScenarioTrackingHeader from "./components/SingleScenarioTrackingHeader"
import SingleScenarioTrackingGraph from "./components/SingleScenarioTrackingGraph"
import SingleScenarioTrackingLegend from "./components/SingleScenarioTrackingLegend"
import SingleScenarioTrackingInsufficientsDatas from "./components/SingleScenarioTrackingInsufficientsDatas"
import SingleScenarioTrackingLoading from "./components/SingleScenarioTrackingLoading"

import TrackingKPI from "features/Tracking/components/TrackingKPI"

import { ScenarioTracking } from "features/Tracking/TrackingModels"

import { trackingSelectors } from "features/Tracking"

import { MINIMUM_GRAPH_DATA_LENGTH } from "features/Tracking/TrackingConstants"

import { LoadingStatus } from "core/CoreModels"
import { resetSingleScenarioQueryDateRange } from "features/Tracking/TrackingReducers"
import useSingleScenarioQueryDateRange from "features/Tracking/hooks/useSingleScenarioQueryDateRange"

interface SingleScenarioTrackingProps {
  scenario: ScenarioTracking
  handleUnselect: () => void
}

const messages = defineMessages({
  title: {
    id: "Tracking.SingleScenarioTrackingGraphTitle",
    defaultMessage: "Taux de la campagne",
  },
  maxAffectedUsersTooltip: {
    id: "Tracking.SingleScenarioTrackingKPIMaxAffectedUsersTooltip",
    defaultMessage: "Message d'information",
  },
  maxClicksTooltip: {
    id: "Tracking.SingleScenarioTrackingKPIMaxClicksTooltip",
    defaultMessage: "Message d'information",
  },
})

const SingleScenarioTracking: FunctionComponent<
  SingleScenarioTrackingProps
> = ({ scenario, handleUnselect }) => {
  const { formatMessage } = useIntl()
  const dispatchAction = useDispatch()

  const related = useSelector(
    trackingSelectors.getScenarioTrackingById(scenario.id),
  )

  const customHook = related.isDefault
    ? useScenariosQueriesDateRange
    : useSingleScenarioQueryDateRange

  const { startDate, endDate, handleDateChange } = customHook()

  const formattedStartDate = startDate.format("YYYY-MM-DD")
  const formattedEndDate = endDate.format("YYYY-MM-DD")

  const { data, dispatch } = useTrackingGraphData<ScenarioTracking>(
    related,
    ScenarioTrackingMapper.getGraphDataFromHistory,
  )

  const loadingStatus = useSelector(
    trackingSelectors.getSingleScenarioTrackingLoadingStatus(related.id),
  )
  const { date: maxAffectedUsersDate, affectedUsers: maxAffectedUsers } =
    useSelector(
      trackingSelectors.getSingleScenarioTrackingMaxAffectedUsers(related.id),
    )

  const { date: maxClicksDate, quantity: maxClicks } = useSelector(
    trackingSelectors.getSingleScenarioTrackingMaxClicksFromDateRange(
      related.id,
      formattedStartDate,
      formattedEndDate,
    ),
  )

  const totalClicks = useSelector(
    trackingSelectors.getSingleScenarioTrackingTotalClicksFromDateRange(
      related.id,
      formattedStartDate,
      formattedEndDate,
    ),
  )

  const sufficientDatas = useMemo(
    () => related.history.length >= MINIMUM_GRAPH_DATA_LENGTH,
    [related],
  )

  useEffect(() => {
    return () => {
      dispatchAction(resetSingleScenarioQueryDateRange())
    }
  }, [])

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <SingleScenarioTrackingLoading
        isMultiple={related.scenarioMode === "multiple"}
        title={formatMessage(messages.title)}
      />
    )

  const filteredData = data.map((serie) => ({
    ...serie,
    data: serie.data.filter(
      (item) =>
        moment(item.x).isSameOrBefore(endDate) &&
        moment(item.x).isSameOrAfter(startDate),
    ),
  }))

  return (
    <div className="single-scen-track">
      <SingleScenarioTrackingHeader
        name={related.name}
        scenarioMode={related.scenarioMode}
        handleUnselect={handleUnselect}
        startDate={startDate}
        endDate={endDate}
        handleDateChange={handleDateChange}
        minDate={
          related.startDate !== null ? moment(related.startDate) : undefined
        }
      />
      <TrackingKPI
        maxAffectedUsers={maxAffectedUsers}
        maxAffectedUsersDate={maxAffectedUsersDate}
        maxClicks={maxClicks}
        maxClicksDate={maxClicksDate}
        totalClicks={totalClicks}
        sufficientGraphDatas={related.history.length > 0}
        maxAffectedUsersTooltip={formatMessage(
          messages.maxAffectedUsersTooltip,
        )}
        maxClicksTooltip={formatMessage(messages.maxClicksTooltip)}
      />
      <div className="single-scen-track__content">
        {related.scenarioMode === "multiple" && sufficientDatas ? (
          <SingleScenarioTrackingLegend
            series={filteredData}
            dispatch={dispatch}
          />
        ) : null}
        {sufficientDatas ? (
          <SingleScenarioTrackingGraph
            title={formatMessage(messages.title)}
            data={filteredData
              .filter((history) => history.display === true)
              .sort((a, b) => Number(a.hover) - Number(b.hover))}
          />
        ) : (
          <SingleScenarioTrackingInsufficientsDatas
            title={formatMessage(messages.title)}
          />
        )}
      </div>
    </div>
  )
}

export default SingleScenarioTracking
