import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { LoadingStatus } from "core/CoreModels"

import { fetchSignaturesHistory } from "../TrackingReducers"

import { trackingSelectors } from ".."

const useSignaturesHistoryData = (): LoadingStatus => {
  const dispatch = useDispatch()

  const loadingStatus = useSelector(
    trackingSelectors.getSignaturesHistoryLoadingStatus,
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchSignaturesHistory())
    }
  }, [loadingStatus, dispatch])

  return loadingStatus
}

export default useSignaturesHistoryData
