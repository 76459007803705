import { FunctionComponent } from "react"

import { useDispatch, useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import PillButtonsGroup from "components/PillButtonsGroup"

import { signaturesSelectors } from "features/Signatures"

import { toggleSendingModeAction } from "features/Signatures/SignaturesActions"

const messages = defineMessages({
  newMail: {
    id: "SignaturesList.NewMail",
    defaultMessage: "Nouveau Mail",
  },
  reply: {
    id: "SignaturesList.Reply",
    defaultMessage: "En réponse",
  },
})

const NewMailReplyPills: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const current = useSelector(signaturesSelectors.getSendingMode)

  const handleCurrent = () => dispatch(toggleSendingModeAction())

  const options = [
    {
      id: "newMail",
      children: <>{formatMessage(messages.newMail)}</>,
    },
    { id: "inResponse", children: <>{formatMessage(messages.reply)}</> },
  ]

  return (
    <PillButtonsGroup
      current={current}
      onChange={handleCurrent}
      pillButtons={options}
      size="large"
    />
  )
}

export default NewMailReplyPills
