import React from "react"
import { FormattedMessage } from "react-intl"

interface Props {
  width: number
  height: number
}

class CropSize extends React.Component<Props> {
  render() {
    return (
      <div className="crop-size">
        <div className="crop-size__width">
          <FormattedMessage id="CropSize.WidthLetter" defaultMessage="L:" />{" "}
          {Math.floor(this.props.width)}{" "}
        </div>
        <div className="crop-size__height">
          {" "}
          <FormattedMessage
            id="CropSize.HeightLetter"
            defaultMessage="H:"
          />{" "}
          {Math.floor(this.props.height)}{" "}
        </div>
      </div>
    )
  }
}

export default CropSize
