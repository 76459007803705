import { FunctionComponent, HTMLAttributes, ReactNode } from "react"

import classNames from "classnames"

import HomePageTileTitle, {
  HomePageTileTitleType,
} from "./components/HomePageTileTitle"

import HomePageTileInfos, {
  HomePageTileInfosType,
} from "./components/HomePageTileInfos"

import HomePageTileDatasContainer, {
  HomePageTileDatasContainerType,
} from "./components/HomePageTileDatasContainer"

import HomePageTileDatasContainerWithIcon, {
  HomePageTileDatasContainerWithIconType,
} from "./components/HomePageTileDatasContainerWithIcon"

import HomePageTileNeedSubcription from "./components/HomePageTileNeedSubcription"

import HomePageTileAddButton, {
  HomePageTileAddButtonType,
} from "./components/HomePageTileAddButton"

interface HomePageSingleTileComposition {
  Title: HomePageTileTitleType
  Infos: HomePageTileInfosType
  Datas: HomePageTileDatasContainerType
  DatasWithIcon: HomePageTileDatasContainerWithIconType
  NeedSubscription: FunctionComponent
  AddButton: HomePageTileAddButtonType
}

interface HomePageSingleTileProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  className?: string
}

type HomePageSingleTileType = FunctionComponent<HomePageSingleTileProps> &
  HomePageSingleTileComposition

const HomePageSingleTile: HomePageSingleTileType = ({
  children,
  className,
  ...others
}) => {
  return (
    <div className={classNames("home-tile", className)} {...others}>
      {children}
    </div>
  )
}

HomePageSingleTile.Title = HomePageTileTitle
HomePageSingleTile.Infos = HomePageTileInfos
HomePageSingleTile.Datas = HomePageTileDatasContainer
HomePageSingleTile.DatasWithIcon = HomePageTileDatasContainerWithIcon
HomePageSingleTile.NeedSubscription = HomePageTileNeedSubcription
HomePageSingleTile.AddButton = HomePageTileAddButton

export default HomePageSingleTile
