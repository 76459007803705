import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import Toggle from "components/Toggle"
import { ModalActions } from "components/Modal"

import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"

interface AssignSubisidiaryModalProps {
  adminAccount: DataGridAdminAccount
  onAssign: (subsidiariesIds: number[]) => void
  onCancel: () => void
  onClose: () => void
}

const messages = defineMessages({
  cancel: {
    id: "AssignSubisidiaryModal.Cancel",
    defaultMessage: "Annuler",
  },
  assign: {
    id: "AssignSubisidiaryModal.Assign",
    defaultMessage: "Affecter",
  },
  label: {
    id: "AssignSubisidiaryModal.Label",
    defaultMessage:
      "Sur quelle(s) filiale(s) souhaitez-vous associer votre nouvel administrateur ?",
  },
})

const SubsidiaryItem = ({ subsidiary, subsidiariesIds, handleChange }) => (
  <div className="assign-subsidiary-modal__list--element">
    <img
      className="assign-subsidiary-modal__list--img"
      src={subsidiary.iconUrl}
    />
    <p className="assign-subsidiary-modal__list--label">{subsidiary.name}</p>
    <div className="assign-subsidiary-modal__list--ctn-toggle">
      <Toggle
        className="assign-subsidiary-modal__list--toggle"
        name={`${subsidiary.name}-${subsidiary.id}`}
        onChange={handleChange(subsidiary.id)}
        defaultChecked={subsidiariesIds.includes(subsidiary.id)}
      />
    </div>
  </div>
)

const AssignSubisidiaryModal: FunctionComponent<
  AssignSubisidiaryModalProps
> = ({ adminAccount, onAssign, onCancel, onClose }) => {
  const [subsidiariesIds, setSubsidiariesIds] = useState<number[]>([])

  const { formatMessage } = useIntl()
  const { subsidiaries } = useSubsidiariesData()

  useEffect(() => {
    setSubsidiariesIds(adminAccount.subsidiariesIds)
  }, [])

  const handleChange = (id: number) => (toggle: boolean) => {
    setSubsidiariesIds((prevState) =>
      toggle ? [...prevState, id] : prevState.filter((v) => v !== id),
    )
  }

  const handleAssign = () => {
    adminAccount.setSubsidiariesIds(subsidiariesIds)
    onAssign(subsidiariesIds)
    onClose()
  }

  return (
    <div className="assign-subsidiary-modal">
      <div className="assign-subsidiary-modal__content">
        <p className="assign-subsidiary-modal__content--label">
          {formatMessage(messages.label)}
        </p>
        <div className="assign-subsidiary-modal__list">
          {subsidiaries.map((s) => (
            <SubsidiaryItem
              key={s.id}
              subsidiary={s}
              subsidiariesIds={subsidiariesIds}
              handleChange={handleChange}
            />
          ))}
        </div>
      </div>
      <ModalActions>
        <ModalActions.Secondary onClick={onCancel}>
          {formatMessage(messages.cancel)}
        </ModalActions.Secondary>
        <ModalActions.Main onClick={() => handleAssign()}>
          {formatMessage(messages.assign)}
        </ModalActions.Main>
      </ModalActions>
    </div>
  )
}

export default AssignSubisidiaryModal
