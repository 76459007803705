import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "../features/reducers"
import rootSaga from "../sagas"

// Logger
import { limitationsMiddleware } from "./middlewares/LimitationsMiddleware"
import { cypressMiddleware } from "./middlewares/CypressMiddleware"

// Sagas
import createSagaMiddleware from "redux-saga"

const sagaMiddleware = createSagaMiddleware()

const middleWares = [sagaMiddleware, limitationsMiddleware]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).Cypress) middleWares.push(cypressMiddleware)

const initStore = () => {
  const config = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(middleWares),
    devTools: true,
  })
  sagaMiddleware.run(rootSaga)
  return config
}

const store = initStore()

export type RootState = ReturnType<typeof store.getState>

export default store
