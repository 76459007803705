import { FunctionComponent } from "react"

import Layout from "components/TwoColumnsLayout"

import LangPillSwitch from "features/App/components/LangPillSwitch"
import LoginSlider from "features/Accounts/components/LoginSlider"
import LoginForm from "features/Accounts/components/LoginForm"

import useUTMManagement from "features/Marketing/hooks/useUTMManagement"

const LoginPage: FunctionComponent = () => {
  useUTMManagement()
  return (
    <Layout>
      <Layout.Left>
        <LoginSlider />
      </Layout.Left>
      <Layout.Right>
        <LangPillSwitch />
        <LoginForm />
      </Layout.Right>
    </Layout>
  )
}

export default LoginPage
