import { ChangeEvent, FunctionComponent, LegacyRef, useState } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import { BmmIcon, HoverTooltip } from "bmm-builder"
import StringHelper from "utils/StringHelper"

interface SendPreviewFieldProps {
  display?: boolean
  sendPreviewRef: LegacyRef<HTMLDivElement>
  onSendPreview: (email: string) => void
}

const messages = defineMessages({
  send: {
    id: "BuilderSendPreview.Send",
    defaultMessage: "Envoyer",
  },
  validEmail: {
    id: "BuilderSendPreview.ValidEmail",
    defaultMessage: "Votre email est valide",
  },
  invalidEmail: {
    id: "BuilderSendPreview.InvalidEmail",
    defaultMessage: "Votre email n'est pas valide",
  },
})

const SendPreviewField: FunctionComponent<SendPreviewFieldProps> = ({
  sendPreviewRef,
  onSendPreview,
  display = false,
}) => {
  const { formatMessage } = useIntl()

  const [email, setEmail] = useState("")
  const [displayHelper, setDisplayHelper] = useState<
    "success" | "warning" | null
  >(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const isEmailValid = StringHelper.validateEmail(value)

    if (value.length === 0) setDisplayHelper(null)
    else if (isEmailValid) setDisplayHelper("success")
    else setDisplayHelper("warning")

    setEmail(value)
  }

  const handleClick = () => {
    onSendPreview(email)
    setEmail("")
    setDisplayHelper(null)
  }

  return (
    <div
      className={classNames("send-preview-field", display && "display")}
      ref={sendPreviewRef}
    >
      <input
        placeholder="Adresse Mail"
        className="send-preview-field__input"
        value={email}
        onChange={handleChange}
        autoFocus={display}
      />
      {displayHelper && (
        <div
          className={classNames("send-preview-field__tooltip", displayHelper)}
        >
          <HoverTooltip
            tooltip={formatMessage(
              displayHelper === "success"
                ? messages.validEmail
                : messages.invalidEmail,
            )}
            level={displayHelper}
            align="right"
          >
            <BmmIcon
              icon={
                displayHelper === "success" ? "CheckBubble" : "WarningBubble"
              }
            />
          </HoverTooltip>
        </div>
      )}
      <button
        type="button"
        className="send-preview-field__register-button"
        disabled={email.length === 0 || displayHelper === "warning"}
        onClick={handleClick}
      >
        {formatMessage(messages.send)}
      </button>
    </div>
  )
}

export default SendPreviewField
