import { FunctionComponent, useEffect, useState } from "react"

import { FileUploader } from "bmm-builder"

import {
  BANNER_ACCEPTED_FILETYPES,
  BANNER_ERROR_SIZE,
  BANNER_SUCCESS_SIZE,
  BANNER_WARNING_SIZE,
} from "features/Scenarios/ScenarioConstants"
import { defineMessages, useIntl } from "react-intl"
import { isSpan } from "utils/I18nHelpers"
import { humanFileSize } from "utils/NumberHelper"
import UploaderDisclaimer from "./components/UploaderDisclaimer"

interface BannerUploadCardProps {
  onFileChange: (blobFile: Blob) => void
}

const messages = defineMessages({
  label1: {
    id: "BannerUploadCard.LabelLine1",
    defaultMessage: "<sp>Cliquez</sp> ou faites glisser",
  },
  label2: {
    id: "BannerUploadCard.LabelLine2",
    defaultMessage: "votre fichier ici ({files})",
  },
  forbiddenLabel1: {
    id: "BannerUploadCard.ForbiddenLabelLine1",
    defaultMessage: "Format non reconnu",
  },
  forbiddenLabel2: {
    id: "BannerUploadCard.ForbiddenLabelLine2",
    defaultMessage: "(format {files} attendus)",
  },
  successLabel: {
    id: "BannerUploadCard.SuccessLabel",
    defaultMessage: "Visuel ajouté à votre campagne",
  },
  warningLabel: {
    id: "BannerUploadCard.WarningLabel",
    defaultMessage:
      "Poids du visuel proche de la limite (Taille : {currentSize}, Limite : {maxSize})",
  },
  errorLabel: {
    id: "BannerUploadCard.ErrorLabel",
    defaultMessage:
      "Poids du visuel supérieur à la limite (Taille : {currentSize}, Limite : {maxSize})",
  },
})

const BannerUploadCard: FunctionComponent<BannerUploadCardProps> = ({
  onFileChange,
}) => {
  const { formatMessage } = useIntl()
  const [displayAltLabel, setDisplayAltLabel] = useState<
    "error" | "warning" | "success" | undefined
  >(undefined)

  const [disclaimerUploadedFile, setDisclaimerUploadedFile] = useState(null)
  const [currentFileSize, setCurrentFileSize] = useState(0)

  const handleuploadDisclaimer = () => {
    setDisplayAltLabel(undefined)
    onFileChange(disclaimerUploadedFile)
    setDisclaimerUploadedFile(null)
  }

  const handleUpload = async (blobFile: Blob) => {
    setCurrentFileSize(blobFile.size)

    if (blobFile.size < BANNER_SUCCESS_SIZE) setDisplayAltLabel("success")

    if (
      blobFile.size >= BANNER_SUCCESS_SIZE &&
      blobFile.size < BANNER_WARNING_SIZE
    )
      setDisplayAltLabel("warning")

    if (
      blobFile.size >= BANNER_WARNING_SIZE &&
      blobFile.size < BANNER_ERROR_SIZE
    ) {
      setDisplayAltLabel(undefined)
      setDisclaimerUploadedFile(blobFile)
      return
    }

    if (blobFile.size >= BANNER_ERROR_SIZE) {
      setDisplayAltLabel("error")
      return
    }

    onFileChange(blobFile)
  }

  const label = (
    <p className="banner-upload-card__upload-field--label">
      {formatMessage(messages.label1, { sp: isSpan })}
      <br />
      {formatMessage(messages.label2, {
        files: BANNER_ACCEPTED_FILETYPES.map((format) => format.name).join(
          ", ",
        ),
      })}
    </p>
  )

  const errorLabel = (
    <p className="banner-upload-card__upload-field--label">
      {formatMessage(messages.errorLabel, {
        currentSize: humanFileSize(currentFileSize, true, 0),
        maxSize: humanFileSize(BANNER_ERROR_SIZE, true, 0),
      })}
    </p>
  )

  const uploadDisclaimerLabel = (
    <UploaderDisclaimer
      currentSize={currentFileSize}
      maxSize={BANNER_WARNING_SIZE}
      onAccept={(e) => {
        e.preventDefault()
        handleuploadDisclaimer()
      }}
      onRefuse={(e) => {
        e.preventDefault()
        setDisclaimerUploadedFile(null)
      }}
    />
  )

  useEffect(() => {
    if (displayAltLabel) {
      const timer1 = setTimeout(() => {
        setDisplayAltLabel(undefined)
      }, 3000)

      return () => {
        clearTimeout(timer1)
      }
    }
  }, [displayAltLabel])

  const forbiddenLabel = (
    <p className="banner-upload-card__upload-field--label">
      {formatMessage(messages.forbiddenLabel1)}
      <br />
      {formatMessage(messages.forbiddenLabel2, {
        files: BANNER_ACCEPTED_FILETYPES.map((format) => format.name).join(
          ", ",
        ),
      })}
    </p>
  )

  const successLabel = (
    <p className="banner-upload-card__upload-field--label">
      {formatMessage(messages.successLabel)}
    </p>
  )

  const warningLabel = (
    <p className="banner-upload-card__upload-field--label">
      {formatMessage(messages.warningLabel, {
        currentSize: humanFileSize(currentFileSize, true, 0),
        maxSize: humanFileSize(BANNER_WARNING_SIZE, true, 0),
      })}
    </p>
  )

  return (
    <div className="banner-upload-card">
      <FileUploader
        onUploaded={handleUpload}
        accept={BANNER_ACCEPTED_FILETYPES}
        label={disclaimerUploadedFile !== null ? uploadDisclaimerLabel : label}
        displayAltLabel={displayAltLabel}
        forbiddenLabel={forbiddenLabel}
        errorLabel={errorLabel}
        successLabel={successLabel}
        warningLabel={warningLabel}
      />
    </div>
  )
}

export default BannerUploadCard
