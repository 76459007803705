import { MutableRefObject, useEffect, useRef } from "react"

import { useDispatch, useSelector } from "react-redux"

import { useIntersectionObserver } from "hooks/useIntersectionObserver"

import { loadSignatureAffectedUsersCount } from "../SignaturesReducer"

import { signaturesSelectors } from ".."

import { LoadingStatus } from "core/CoreModels"

interface UseSignatureAffectedDatas {
  signatureRef: MutableRefObject<Element | null>
  loadingStatus: LoadingStatus
}

const useSignatureAffectedDatas = (
  signatureId: number,
): UseSignatureAffectedDatas => {
  const dispatch = useDispatch()
  const signatureRef = useRef<Element | null>(null)
  const isIntersecting = useIntersectionObserver(signatureRef)
  const loadingStatus = useSelector(
    signaturesSelectors.getSignatureAffectUsersCountLoadingStatus(signatureId),
  )

  useEffect(() => {
    if (
      isIntersecting &&
      loadingStatus !== LoadingStatus.LOADED &&
      loadingStatus !== LoadingStatus.PENDING
    )
      dispatch(loadSignatureAffectedUsersCount(signatureId))
  }, [isIntersecting])

  return { signatureRef, loadingStatus }
}

export default useSignatureAffectedDatas
