import { connect } from "react-redux"
import AutoSave from "components/AutoSave/AutoSave"
import { appSelectors } from "features/App"

const mapStateToProps = (state) => ({
  isHidden: appSelectors.getAutoSaveStatus(state),
})

const ConnectedAutoSave = connect(mapStateToProps, null)(AutoSave)

export default ConnectedAutoSave
