import React from "react"

interface Props {
  title: string
  active: boolean
  step: number
}

class VerticalBreadCrumbItem extends React.Component<Props> {
  getClass = () => {
    return this.props.active
      ? "breadcrumb__vertical-item breadcrumb__vertical-item--active"
      : "breadcrumb__vertical-item"
  }

  render() {
    return (
      <div className={this.getClass()}>
        <div className="breadcrumb__vertical-title">{this.props.title}</div>
        <div className="breadcrumb__vertical-circle">{this.props.step}</div>
      </div>
    )
  }
}

export default VerticalBreadCrumbItem
