import useDebounce from "hooks/useDebounce"
import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

interface PaginatedDataGridPaginationProps {
  currentPage: number
  lastPage: number
  pageSize: number
  changePage: (page: number) => void
  setPageSize: (pageSize: number) => void
  pageSizeOptions: Array<number>
  empty: boolean
}

const messages = defineMessages({
  rowsText: { id: "DataGrid.RowsText", defaultMessage: "par page" },
})

const PaginatedDataGridPagination: FunctionComponent<
  PaginatedDataGridPaginationProps
> = ({
  currentPage,
  lastPage,
  pageSize,
  pageSizeOptions,
  changePage,
  setPageSize,
  empty,
}) => {
  const intl = useIntl()
  const [value, setValue] = useState<number>(currentPage)
  const debouncedValue = useDebounce<number>(value, 500)

  const canPreviousPage = currentPage > 1
  const canNextPage = currentPage < lastPage

  useEffect(() => {
    setValue(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (value !== currentPage) {
      changePage(debouncedValue)
    }
  }, [debouncedValue])

  if (empty) return <div className="data-grid__pagination" />

  return (
    <div className="data-grid__pagination">
      <div className="data-grid__pagination--page-size">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {pageSizeOptions.map((pageSizeOption) => (
            <option key={pageSizeOption} value={pageSizeOption}>
              {pageSizeOption} {intl.formatMessage(messages.rowsText)}
            </option>
          ))}
        </select>
      </div>

      <div className="data-grid__pagination--page-number">
        <FormattedMessage id="DataGrid.PageText" defaultMessage="Page" />
        <input
          type="number"
          min={1}
          max={lastPage}
          value={value}
          onChange={(e) => {
            let page = e.target.value ? Number(e.target.value) : 1
            if (page > lastPage) page = lastPage
            setValue(page)
          }}
        />
        <FormattedMessage id="DataGrid.OfText" defaultMessage="sur" />
        {` ${lastPage}`}
      </div>

      <div className="data-grid__pagination--arrows">
        <button
          className="data-grid__pagination--btn data-grid__pagination--btn-large"
          onClick={() => changePage(1)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="data-grid__pagination--btn data-grid__pagination--btn-small"
          onClick={() => changePage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="data-grid__pagination--btn data-grid__pagination--btn-small"
          onClick={() => changePage(currentPage + 1)}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="data-grid__pagination--btn data-grid__pagination--btn-large"
          onClick={() => changePage(lastPage)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
      </div>
    </div>
  )
}

export default PaginatedDataGridPagination
