import { FunctionComponent } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { NavLink } from "react-router-dom"

import { VerticalBreadCrumb } from "components/BreadCrumb"

import config from "config/config"

import { trialBreadCrumbMessages } from "../TrialTunnelContext"
import { ENROLLMENT_URL } from "router/RouterConstants"

const Advertising: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const breadCrumbNodes = [
    {
      number: 1,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep1),
    },
    {
      number: 2,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep2),
    },
    {
      number: 3,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep3),
    },
    {
      number: 4,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep4),
    },
  ]

  return (
    <div className="trial__layout l-additionnal-infos">
      <div className="advertising">
        <div className="advertising__promo">
          <div className="advertising__promo-container">
            <img
              className="advertising__promo-img"
              src={`${config.cdnUrl}/images/bmm/onboarding/ic_present.svg`}
              alt=""
            />
            <div className="advertising__text" data-cy="advertising__text">
              <FormattedMessage
                id="Advertising.Promo"
                defaultMessage="Bénéficiez d'une remise de 20% pendant 72h"
              />
            </div>
          </div>
        </div>
        <div className="advertising__slogan">
          <FormattedMessage
            id="Advertising.Slogan"
            defaultMessage="Abonnez-vous et utilisez sans limite le potentiel de vos emails."
          />
        </div>
        <div className="advertising__cards">
          <div className="advertising__card">
            <img
              className="advertising__card-image"
              src={`${config.cdnUrl}/images/bmm/onboarding/ic_avantage_01.svg`}
              alt=""
            />
            <FormattedMessage
              id="Advertising.Card1"
              defaultMessage="Découvrez tous les modèles de signatures mails et diffusez des bannières mails illimités."
            />
          </div>
          <div className="advertising__card advertising__card--green">
            <img
              className="advertising__card-image"
              src={`${config.cdnUrl}/images/bmm/onboarding/ic_avantage_02.svg`}
              alt=""
            />
            <FormattedMessage
              id="Advertising.Card2"
              defaultMessage="Délivrez simultanément sur les messageries de vos collaborateurs leur nouvelle signature
          avec leurs coordonnées professionnelles."
            />
          </div>
          <div className="advertising__card advertising__card--red">
            <img
              className="advertising__card-image"
              src={`${config.cdnUrl}/images/bmm/onboarding/ic_avantage_03.svg`}
              alt=""
            />
            <FormattedMessage
              id="Advertising.Card3"
              defaultMessage="Mesurez la performance de vos campagnes avec les statistiques."
            />
          </div>
        </div>

        <div className="advertising__btn-zone">
          <NavLink
            to={ENROLLMENT_URL}
            className="bmm-btn advertising__btn"
            data-cy="advertising-btn"
          >
            <FormattedMessage
              id="Advertising.Button"
              defaultMessage="Découvrir les abonnements"
            ></FormattedMessage>
          </NavLink>
        </div>
      </div>
      <div className="breadcrumb-container">
        <VerticalBreadCrumb nodes={breadCrumbNodes} step={4} />
      </div>
    </div>
  )
}

export default Advertising
