import React, { FunctionComponent } from "react"

import { useSelector } from "react-redux"

import { trackingSelectors } from "features/Tracking"

import { defineMessages, useIntl } from "react-intl"

import TrackingCounter from "../TrackingCounter"

const messages = defineMessages({
  title: { id: "ConnectedBannerCounter.Title", defaultMessage: "Bannières" },
})

const BannersTrackingCounter: FunctionComponent = () => {
  const intl = useIntl()

  const dataNumber = useSelector(trackingSelectors.getNbBanners)
  const title = intl.formatMessage({ id: "ConnectedBannerCounter.Title" })

  const renderTitle = () => {
    return `${title} (${dataNumber})`
  }

  return <TrackingCounter title={renderTitle()} />
}

export default BannersTrackingCounter
