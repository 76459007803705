import { FunctionComponent } from "react"

import Layout from "features/Scenarios/components/ScenarioCardLayout"

const ScenariosListLayoutLoadingSkeleton: FunctionComponent = () => {
  return (
    <>
      {[1, 2, 3].map((id) => (
        <Layout key={id}>
          <div className="scenarios-list-layout__skeleton--carousel" />
          <Layout.Details>
            <Layout.Infos>
              <Layout.Assignations affectedCount={0} isLoading />
              <div className="scenarios-list-layout__skeleton--type" />
              <div className="scenarios-list-layout__skeleton--name" />
              <div className="scenarios-list-layout__skeleton--date" />
            </Layout.Infos>
            <Layout.Controls>
              <div className="scenarios-list-layout__skeleton--toggle-container">
                <div className="scenarios-list-layout__skeleton--toggle" />
              </div>
              <Layout.Weight weighting={0} />
            </Layout.Controls>
          </Layout.Details>
          <Layout.Actions>
            <Layout.Edit scenarioId={0} isMultiple={false} disabled />
            <Layout.Assign
              scenarioId={0}
              isMultiple={false}
              roundedCorner="top"
              disabled
            />
            <Layout.Duplicate isMultiple={false} onDuplicate={() => null} />
            <Layout.Delete
              isMultiple={false}
              onDelete={() => null}
              roundedCorner="bottom"
              disabled
            />
          </Layout.Actions>
        </Layout>
      ))}
    </>
  )
}

export default ScenariosListLayoutLoadingSkeleton
