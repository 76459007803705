import { FunctionComponent, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import ToggleField from "components/ToggleField"

import { accountsSelectors } from "features/Accounts"
import { updateAccountInformations } from "features/Accounts/AccountsReducer"

import AccountInformationsFormSubmitButton from "./AccountInformationsFormSubmitButton"

import { AccountInformations } from "features/Accounts/AccountsModels"

interface AccountInformationsFormCgsStepProps {
  nextRegisterStep: number
  label: string
  toggleLabel: string
  cgsLinkMessage: string
  cgsInformations: string
  buttonLabel: string
  submitButtonLocker: (toggle: boolean) => boolean
  payload: (toggle: boolean, stepRegister: number) => AccountInformations
}

const AccountInformationsFormCgsStep: FunctionComponent<
  AccountInformationsFormCgsStepProps
> = ({
  nextRegisterStep,
  label,
  toggleLabel,
  cgsLinkMessage,
  cgsInformations,
  buttonLabel,
  submitButtonLocker,
  payload,
}) => {
  const [toggle, setToggle] = useState(false)
  const dispatch = useDispatch()
  const cgsLink = useSelector(accountsSelectors.getCGSLink)

  const handleChange = (toggleValue) => {
    setToggle(toggleValue)
  }

  const handleSubmit = () => {
    dispatch(updateAccountInformations(payload(toggle, nextRegisterStep)))
  }

  return (
    <>
      <p className="account-informations-form__step--label">{label}</p>
      <ToggleField
        className="account-informations-form__cgs--toggle"
        name="cgs"
        onChange={handleChange}
      >
        {`${toggleLabel} `}
        <a
          className="account-informations-form__cgs--link"
          href={cgsLink}
          target="_blank"
        >
          {cgsLinkMessage}
        </a>
      </ToggleField>
      <AccountInformationsFormSubmitButton
        disabled={submitButtonLocker(toggle)}
        onClick={handleSubmit}
      >
        {buttonLabel}
      </AccountInformationsFormSubmitButton>
      <p className="account-informations-form__cgs--informations">
        {cgsInformations}
      </p>
    </>
  )
}

export default AccountInformationsFormCgsStep
