import { FunctionComponent } from "react"
import AdditionalInformations from "features/Users/components/AdditionalInformations"
import { FormattedMessage } from "react-intl"
import { Scrollbars } from "react-custom-scrollbars-2"

const AdditionalInformationsPage: FunctionComponent = () => {
  return (
    <div className="col-12 body-content">
      <section className="col-12 h-100 home">
        <Scrollbars>
          <section className="l-sub-header">
            <h4>
              <FormattedMessage
                id="SignaturesPage.NewSignatureTitle"
                defaultMessage="Ma nouvelle signature mail"
              />
            </h4>
            <div className="l-sub-header__liseret"></div>
          </section>
          <section className="add-infos__page">
            <AdditionalInformations />
          </section>
        </Scrollbars>
      </section>
    </div>
  )
}

export default AdditionalInformationsPage
