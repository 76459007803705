import { FunctionComponent, useEffect, useMemo, useState } from "react"

import AdminsGrid from "features/Accounts/components/AdminsGrid"
import useAdminsAccountsData from "features/Accounts/hooks/useAdminsAccountsData"

import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { DataGridAdminAccount, Roles } from "features/Accounts/AccountsModels"
import { useDispatch, useSelector } from "react-redux"
import { accountsSelectors } from "features/Accounts"
import ConfirmModal from "components/ConfirmModal"
import { deleteAdminAccountAction } from "features/Accounts/AccountsActions"
import {
  createAdminAccount,
  updateAdminAccountRoles,
} from "features/Accounts/AccountsReducer"

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"

import { defineMessages, useIntl } from "react-intl"
import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

export const NEW_ADMIN_ID = "-1"

const messages = defineMessages({
  atLeastOneSuperAdmin: {
    id: "AdminsGrid.AtLeastOneSuperAdmin",
    defaultMessage: "Un super administrateur doit être présent",
  },
})

const AdminsPanel: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { Id: currentAccountId } = useSelector(
    accountsSelectors.getCurrentAccount,
  )
  const newAdminAccountSavingStatus = useSelector(
    accountsSelectors.getNewAdminAccountSavingStatus,
  )
  const newAdminAccountErrorMessage = useSelector(
    accountsSelectors.getNewAdminAccountErrorMessage,
  )
  const adminAccountUpdatingStatus = useSelector(
    accountsSelectors.getAdminAccountUpdatingStatus,
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(50)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchString, setSearchString] = useState("")
  const [disableSave, setDisableSave] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [atLeastTwoSuperAdmin, setAtLeastTwoSuperAdmin] = useState(true)
  const [newAdminAccount, setNewAdminAccount] =
    useState<DataGridAdminAccount | null>(null)
  const [data, setData] = useState([])

  const { tenantAdminAccounts, loadingStatus } = useAdminsAccountsData()
  const { loadingStatus: loadingStatusSubsidiaries } = useSubsidiariesData()

  useEffect(() => {
    let counter = 0
    data.forEach(
      (adminAccount) =>
        adminAccount.isSuperAdmin() && !adminAccount.isNew && counter++,
    )
    if (counter < 2) {
      setAtLeastTwoSuperAdmin(false)
    } else {
      setAtLeastTwoSuperAdmin(true)
    }
  }, [data])

  useEffect(() => {
    if (newAdminAccountSavingStatus === SavingStatus.SUCCESS) {
      setNewAdminAccount(null)
      setIsEditing(false)
    }
    if (newAdminAccountSavingStatus === SavingStatus.ERROR) {
      const newData = data.map((adminAccount) => {
        if (adminAccount.isNew) adminAccount.setError(true)

        return adminAccount
      })
      setData(newData)
    }
  }, [newAdminAccountSavingStatus])

  useEffect(() => {
    const newData = tenantAdminAccounts.map(
      (adminAccount) =>
        new DataGridAdminAccount({ ...adminAccount, isNew: false }),
    )

    setData([newAdminAccount, ...newData].filter(Boolean))
  }, [tenantAdminAccounts, newAdminAccount])

  const filteredData = useMemo(
    () =>
      data.filter((adminAccount) => {
        if (searchString.length >= 3) {
          const { userName } = adminAccount
          return userName?.includes(searchString) || adminAccount.isNew
        }
        return adminAccount
      }),
    [data, searchString],
  )

  const lastPage = useMemo(
    () => Math.ceil(filteredData.length / currentPageSize),
    [filteredData, currentPageSize],
  )

  const deleteAdminAccount = () => {
    const isNew = data.find((adminAccount) => adminAccount.isNew)
    const relatedAdminAccount = data.find(
      (adminAccount) => adminAccount.id === selectedId,
    )
    if (isNew && relatedAdminAccount) {
      setNewAdminAccount(null)
      setIsEditing(false)
    } else if (relatedAdminAccount) {
      dispatch(
        deleteAdminAccountAction(
          relatedAdminAccount.userName,
          relatedAdminAccount.id,
        ),
      )
    }

    setSelectedId(null)
    setOpenConfirm(false)
  }

  const handleDeleteAdminAccount = (id) => {
    setSelectedId(id)
    setOpenConfirm(true)
  }

  const handleCloseModal = () => {
    setSelectedId(null)
    setOpenConfirm(false)
  }

  const handleToggleRole = (id: string, role: Roles) => {
    const adminAccount = data.find((adminAccount) => adminAccount.id === id)
    if (adminAccount.isNew) {
      adminAccount.toggleRole(role)
      setNewAdminAccount(new DataGridAdminAccount(adminAccount))
      return
    }
    if (
      !adminAccount.hasRole(role) &&
      adminAccountUpdatingStatus !== SavingStatus.SAVING
    ) {
      adminAccount.toggleRole(role)
      dispatch(updateAdminAccountRoles(adminAccount))
      return
    }
    if (
      adminAccount.hasRole(role) &&
      adminAccount.isSuperAdmin() &&
      !atLeastTwoSuperAdmin
    ) {
      dispatch(
        addNotification(
          new Notification(
            formatMessage(messages.atLeastOneSuperAdmin),
            "INFO",
          ),
        ),
      )
      return
    }
    if (adminAccountUpdatingStatus !== SavingStatus.SAVING) {
      adminAccount.toggleRole(role)
      dispatch(updateAdminAccountRoles(adminAccount))
    }
  }

  const handleSuperAdminToggle = (id: string) => {
    const adminAccount = data.find((adminAccount) => adminAccount.id === id)
    if (adminAccount.isNew) {
      adminAccount.toggleSuperAdmin()
      setNewAdminAccount(new DataGridAdminAccount(adminAccount))
      return
    }
    if (
      !adminAccount.isSuperAdmin() &&
      adminAccountUpdatingStatus !== SavingStatus.SAVING
    ) {
      adminAccount.toggleSuperAdmin()
      dispatch(updateAdminAccountRoles(adminAccount))
      return
    }
    if (
      atLeastTwoSuperAdmin &&
      adminAccountUpdatingStatus !== SavingStatus.SAVING
    ) {
      adminAccount.toggleSuperAdmin()
      dispatch(updateAdminAccountRoles(adminAccount))
      return
    }

    dispatch(
      addNotification(
        new Notification(formatMessage(messages.atLeastOneSuperAdmin), "INFO"),
      ),
    )
  }

  const handleEditEmail = (email: string, id: string) => {
    const newData = data.map((item) => {
      setIsEditing(true)
      if (item.id === id) {
        item.setUserName(email)
      }
      return item
    })
    setData(newData)
  }

  const handleSave = () => {
    dispatch(createAdminAccount(newAdminAccount))
  }

  const onAddLine = () => {
    const newAdminAccount = new DataGridAdminAccount({
      id: NEW_ADMIN_ID,
      company: "",
      roles: [],
      userName: "",
      isNew: true,
    })
    setNewAdminAccount(newAdminAccount)
    setIsEditing(true)
  }

  return (
    <div>
      <ConfirmModal
        action={deleteAdminAccount}
        handleClose={handleCloseModal}
        open={openConfirm}
      />
      <AdminsGrid
        data={filteredData.slice(
          currentPageSize * (currentPage - 1),
          currentPageSize * currentPage,
        )}
        disableSave={disableSave}
        onDisableSave={(value) => setDisableSave(value)}
        isEditing={isEditing}
        isSaving={newAdminAccountSavingStatus === SavingStatus.SAVING}
        savingErrorMessage={newAdminAccountErrorMessage}
        currentAccountId={currentAccountId}
        onEditing={() => setIsEditing(true)}
        onEditEmail={handleEditEmail}
        onDeleteAdminAccount={handleDeleteAdminAccount}
        onToggleRole={handleToggleRole}
        onToggleSuperAdmin={handleSuperAdminToggle}
        onSearch={(search) => setSearchString(search)}
        onAddLine={onAddLine}
        onSave={handleSave}
        isLoading={
          loadingStatus === LoadingStatus.PENDING ||
          loadingStatusSubsidiaries === LoadingStatus.PENDING
        }
        currentPage={currentPage}
        pageSize={currentPageSize}
        lastPage={lastPage}
        changePage={(page) => setCurrentPage(page)}
        setPageSize={(pageSize) => setCurrentPageSize(pageSize)}
      />
    </div>
  )
}

export default AdminsPanel
