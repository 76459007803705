import { FunctionComponent, ReactNode } from "react"

interface UserSignaturePreviewInformationsProps {
  children: ReactNode
}

export type UserSignaturePreviewInformationsType =
  FunctionComponent<UserSignaturePreviewInformationsProps>

const UserSignaturePreviewInformations: UserSignaturePreviewInformationsType =
  ({ children }) => {
    return (
      <div className="user-signature-preview__informations">{children}</div>
    )
  }

export default UserSignaturePreviewInformations
