import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { selectors as UsersSelectors } from "features/Users/UsersSelectors"
import { selectors as AccountsSelectors } from "features/Accounts/AccountsSelectors"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"

import { useDispatch, useSelector } from "react-redux"

import SyncPropertiesGrid from "../SyncPropertiesGrid"

import ConfirmModal from "components/ConfirmModal"

import { Origin } from "features/Users/UserModels"
import { GlobalStates } from "store"

import { featureNames } from "config/config.features"

import {
  deletePropertyAction,
  updatePropertiesAction,
} from "features/Users/UsersActions"
import { checkProperties, fetchProperties } from "features/Users/UsersReducers"

const buildAvaibleSources = (availableSources) => {
  return [
    ...availableSources.map((source) => {
      switch (source) {
        case Origin.ORIGIN_API:
          return {
            label: "Api",
            value: "Api",
          }
        case Origin.ORIGIN_GSUITE:
          return {
            label: "Google Workspace",
            value: "GSuite",
          }
        case Origin.ORIGIN_O365:
          return {
            label: "Office 365",
            value: "O365",
          }
        case Origin.ORIGIN_LUCCA:
          return {
            label: "Lucca",
            value: "Lucca",
          }
      }
    }),
    {
      label: "Portail",
      value: "Portal",
    },
  ]
}

const SyncProperties: FunctionComponent = () => {
  const userPropertiesById = useSelector(UsersSelectors.getUsersPropertiesById)
  const availableSources = useSelector(
    AccountsSelectors.getEnabledSynchronisationMethods,
  )
  const isChecking: boolean = useSelector(
    UsersSelectors.getUsersPropertiesAreChecking,
  )
  const isLoading: boolean = useSelector(
    UsersSelectors.getUsersPropertiesAreLoading,
  )
  const isFeatureSelfServicePropertiesActive = useSelector<
    GlobalStates,
    boolean
  >(isFeatureActive(featureNames.SELF_SERVICE_PROPERTIES))

  const dispatch = useDispatch()

  const sources = useMemo(
    () => buildAvaibleSources(availableSources),
    [availableSources],
  )

  const [data, setData] = useState([])

  const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(
    null,
  )
  const [searchString, setSearchString] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(50)
  const [isEditing, setIsEditing] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  useEffect(() => {
    if (Object.entries(userPropertiesById).length > 0) {
      const data = Object.keys(userPropertiesById)
        // * Masquage du champ banner aux utilisateurs
        .filter((key) => userPropertiesById[key].InternalName !== "banner")
        .map((key) => ({
          id: userPropertiesById[key].Id,
          hashtag: userPropertiesById[key].InternalName,
          technicalName: userPropertiesById[key].RequestSource || "",
          isUnknown: userPropertiesById[key].isUnknown,
          syncSource: userPropertiesById[key].Origin,
          isSyncSourceEditing: false,
          activated: userPropertiesById[key].Activated,
          isEditable: userPropertiesById[key].IsEditable,
          isReadOnly: userPropertiesById[key].IsReadOnly,
          isNew: false,
        }))
      setData(data)
      setIsEditing(false)
    } else {
      dispatch(fetchProperties())
    }
  }, [userPropertiesById])

  useEffect(() => {
    const notFilledFields = data.filter((item) => item.hashtag === "")
    if (notFilledFields.length > 0 || isDuplicate) {
      setDisableSave(true)
    } else {
      setDisableSave(false)
    }
  }, [data, isDuplicate])

  const filteredData = useMemo(
    () =>
      data.filter((syncProperty) => {
        if (searchString.length >= 3) {
          const { hashtag, technicalName } = syncProperty
          return (
            hashtag?.includes(searchString) ||
            technicalName?.includes(searchString)
          )
        }
        return syncProperty
      }),
    [data, searchString],
  )

  const lastPage = useMemo(
    () => Math.ceil(filteredData.length / currentPageSize),
    [filteredData, currentPageSize],
  )

  const checkDuplicateHashtags = (hashtag: string, id: string) => {
    let alreadyExists = false
    const duplicate = data.filter(
      (item) => item.id !== id && item.hashtag === hashtag,
    )
    if (duplicate.length > 0) {
      alreadyExists = true
      setDisableSave(true)
      setIsDuplicate(true)
    } else {
      setDisableSave(false)
      setIsDuplicate(false)
    }
    return alreadyExists
  }

  const handleEditHashtag = (hashtag: string, id: string) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        setIsEditing(true)
        return {
          ...item,
          hashtag,
          technicalName:
            item.technicalName === "" ? hashtag : item.technicalName,
        }
      }
      return item
    })
    setData(newData)
  }

  const handleEditTechnicalName = (technicalName: string, id: string) => {
    const newData = data.map((item) => {
      setIsEditing(true)
      if (item.id === id) {
        return {
          ...item,
          technicalName,
        }
      }
      return item
    })
    setData(newData)
  }

  const handleDeactivate = (hashtag) => {
    const newData = data.map((item) => {
      if (item.hashtag === hashtag) {
        return { ...item, isEditable: !item.isEditable }
      }
      return item
    })
    setData(newData)
    setIsEditing(true)
  }

  const onAddLine = () => {
    const nextId = data.filter((el) => el.isNew).length
    const newData = [
      {
        id: -1 - nextId,
        hashtag: "",
        technicalName: "",
        isUnknown: false,
        syncSource: "Portal",
        isSyncSourceEditing: true,
        activated: true,
        isEditable: false,
        isReadOnly: false,
        isNew: true,
      },
      ...data,
    ]
    setData(newData)
    setIsEditing(true)
  }

  const handleCloseModal = () => {
    setSelectedPropertyId(null)
    setOpenConfirm(false)
  }

  const deleteProperty = () => {
    const relevantProperty = data.find((item) => item.id === selectedPropertyId)

    const deletedProperty = {
      id: relevantProperty.id,
      internalName: relevantProperty.hashtag,
      activated: relevantProperty.activated,
      origin: relevantProperty.origin,
      requestSource: relevantProperty.technicalName,
      isEditable: relevantProperty.isEditable,
      isReadOnly: relevantProperty.isReadOnly,
    }

    setSelectedPropertyId(null)
    setOpenConfirm(false)
    dispatch(deletePropertyAction(deletedProperty))
  }

  const handleDeleteProperty = (syncProperty) => {
    if (syncProperty.isNew) {
      const newData = data.filter((el) => el.id !== syncProperty.id)
      const isEditing = newData.filter((el) => el.isNew).length
      setData(newData)
      setIsEditing(Boolean(isEditing > 0))
    } else {
      setSelectedPropertyId(syncProperty.id)
      setOpenConfirm(true)
    }
  }

  const handleEditSyncSource = (id) => {
    const newData = data.map((item) => {
      if (item.id === id && !item.isReadOnly) {
        setIsEditing(true)
        return {
          ...item,
          isSyncSourceEditing: !item.isSyncSourceEditing,
        }
      }
      return item
    })

    setData(newData)
  }

  const handleChangeSyncSource = useCallback(
    (source, id) => {
      const newData = data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            syncSource: source,
          }
        }
        return item
      })

      setData(newData)
    },

    [data],
  )

  const handleUpdate = useCallback(() => {
    const newData = data.map((item) => {
      return {
        id: item.id,
        internalName: item.hashtag,
        requestSource: item.technicalName,
        origin: item.syncSource,
        isEditable: item.isEditable,
        isReadOnly: item.isReadOnly,
      }
    })
    dispatch(updatePropertiesAction(newData))
  }, [data, dispatch])

  const handleCheckProperties = useCallback(() => {
    const newData = data.map((item) => {
      return {
        name: item.hashtag,
        requestSource: item.technicalName,
        origin: item.syncSource,
      }
    })
    dispatch(checkProperties(newData))
  }, [data, dispatch])

  return (
    <div>
      <ConfirmModal
        action={deleteProperty}
        handleClose={handleCloseModal}
        open={openConfirm}
      />
      <SyncPropertiesGrid
        sources={sources}
        data={filteredData.slice(
          currentPageSize * (currentPage - 1),
          currentPageSize * currentPage,
        )}
        isChecking={isChecking}
        isEditing={isEditing}
        isFeatureSelfServicePropertiesActive={
          isFeatureSelfServicePropertiesActive
        }
        disableSave={disableSave}
        onDisableSave={() => setDisableSave(true)}
        checkDuplicateHashtags={checkDuplicateHashtags}
        onEditing={() => setIsEditing(true)}
        onSearch={(search) => setSearchString(search)}
        onDeactivate={handleDeactivate}
        onDeleteProperty={handleDeleteProperty}
        onCheckData={handleCheckProperties}
        onEditHashtag={handleEditHashtag}
        onEditTechnicalName={handleEditTechnicalName}
        onEditSyncSource={handleEditSyncSource}
        onChangeSyncSource={handleChangeSyncSource}
        onSave={handleUpdate}
        addLine={onAddLine}
        isLoading={isLoading}
        currentPage={currentPage}
        pageSize={currentPageSize}
        lastPage={lastPage}
        changePage={(page) => setCurrentPage(page)}
        setPageSize={(pageSize) => setCurrentPageSize(pageSize)}
      />
    </div>
  )
}

export default SyncProperties
