import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import { LoadingStatus } from "core/CoreModels"

import { GlobalStates } from "store"

import {
  SignaturesPowerUser,
  SignatureDeployment,
  SignatureTracking,
  ScenariosPowerUser,
  ScenarioTracking,
  SignaturesDeploymentTrackingDatasVOneDto,
  BannersClickedDto,
  SignaturesHistoryDto,
  SignaturesClickedDto,
  BannersHistoryDto,
  QueriesDateRange,
} from "./TrackingModels"

interface TrackingRawDatas<T> {
  rawData: null | T[]
  loadingStatus: LoadingStatus
}

interface TrackingRawDatasWithRange<T> {
  rawData: Array<{ range: QueriesDateRange; data: T[] }>
  loadingStatus: LoadingStatus
}

interface TrackingState {
  scenarios: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickByDate: any
    queries: { startDate: string | null; endDate: string | null }
    singleItemQuery: { startDate: string | null; endDate: string | null }
  }
  signatures: {
    queries: { startDate: string | null; endDate: string | null }
  }
  signaturesDelivered: {
    entities: { [id: string]: SignaturesDeploymentTrackingDatasVOneDto }
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  signaturesDeployment: {
    entities: { [id: string]: SignatureDeployment }
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  signaturesPowerUsers: {
    entities: { [id: string]: SignaturesPowerUser }
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  signaturesTracking: {
    entities: { [id: string]: SignatureTracking }
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  scenariosPowerUsers: {
    entities: { [id: string]: ScenariosPowerUser }
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  scenariosTracking: {
    entities: { [id: string]: ScenarioTracking }
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  bannersClickedDatas: TrackingRawDatasWithRange<BannersClickedDto>
  bannersHistoryDatas: TrackingRawDatas<BannersHistoryDto>
  signaturesClickedDatas: TrackingRawDatasWithRange<SignaturesClickedDto>
  signaturesHistoryDatas: TrackingRawDatas<SignaturesHistoryDto>
}

export const signaturesDeliveredAdapter =
  createEntityAdapter<SignaturesDeploymentTrackingDatasVOneDto>({
    selectId: (sd) => sd.userEmail,
    sortComparer: (a, b) => a.userEmail.localeCompare(b.userEmail),
  })

export const {
  selectById: selectSignaturesDeliveredById,
  selectAll: selectAllSignaturesDelivered,
} = signaturesDeliveredAdapter.getSelectors<GlobalStates>(
  (state) => state.tracking.signaturesDelivered,
)

const signaturesDeploymentAdapter = createEntityAdapter<SignatureDeployment>({
  selectId: (sd) => sd.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const {
  selectById: selectSignatureDeploymentById,
  selectAll: selectAllSignaturesDeployment,
} = signaturesDeploymentAdapter.getSelectors<GlobalStates>(
  (state) => state.tracking.signaturesDeployment,
)

const signaturesPowerUsersAdapter = createEntityAdapter<SignaturesPowerUser>({
  selectId: (sd) => sd.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const {
  selectById: selectSignaturesPowerUserById,
  selectAll: selectAllSignaturesPowerUsers,
} = signaturesPowerUsersAdapter.getSelectors<GlobalStates>(
  (state) => state.tracking.signaturesPowerUsers,
)

const signaturesTrackingAdapter = createEntityAdapter<SignatureTracking>({
  selectId: (sd) => sd.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const {
  selectById: selectSignatureTrackingById,
  selectAll: selectAllSignaturesTracking,
} = signaturesTrackingAdapter.getSelectors<GlobalStates>(
  (state) => state.tracking.signaturesTracking,
)

const scenariosPowerUsersAdapter = createEntityAdapter<ScenariosPowerUser>({
  selectId: (sd) => sd.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const {
  selectById: selectScenariosPowerUserById,
  selectAll: selectAllScenariosPowerUsers,
} = scenariosPowerUsersAdapter.getSelectors<GlobalStates>(
  (state) => state.tracking.scenariosPowerUsers,
)

const scenariosTrackingAdapter = createEntityAdapter<ScenarioTracking>({
  selectId: (sd) => sd.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const {
  selectById: selectScenarioTrackingById,
  selectAll: selectAllScenariosTracking,
} = scenariosTrackingAdapter.getSelectors<GlobalStates>(
  (state) => state.tracking.scenariosTracking,
)

const initialState: TrackingState = {
  scenarios: {
    clickByDate: {},
    queries: { startDate: null, endDate: null },
    singleItemQuery: { startDate: null, endDate: null },
  },
  signatures: {
    queries: { startDate: null, endDate: null },
  },
  signaturesDelivered: signaturesDeliveredAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  signaturesDeployment: signaturesDeploymentAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  signaturesPowerUsers: signaturesPowerUsersAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  signaturesTracking: signaturesTrackingAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  scenariosPowerUsers: scenariosPowerUsersAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  scenariosTracking: scenariosTrackingAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  bannersClickedDatas: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    rawData: [],
  },
  bannersHistoryDatas: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    rawData: null,
  },
  signaturesClickedDatas: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    rawData: [],
  },
  signaturesHistoryDatas: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    rawData: null,
  },
}

const trackingReducer = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    fetchBannersClickSuccess(state, action: PayloadAction<unknown>) {
      state.scenarios.clickByDate = action.payload
    },
    fetchSignaturesDelivered(state) {
      state.signaturesDelivered.loadingStatus = LoadingStatus.PENDING
    },
    fetchSignaturesDeliveredSuccess(
      state,
      action: PayloadAction<SignaturesDeploymentTrackingDatasVOneDto[]>,
    ) {
      state.signaturesDelivered.loadingStatus = LoadingStatus.LOADED
      signaturesDeliveredAdapter.setAll(
        state.signaturesDelivered,
        action.payload,
      )
    },
    fetchSignaturesDeliveredFailure(state) {
      state.signaturesDelivered.loadingStatus = LoadingStatus.ERROR
    },
    computeSignaturesDeploymentData(state) {
      state.signaturesDeployment.loadingStatus = LoadingStatus.PENDING
    },
    computeSignaturesDeploymentDataSuccess(
      state,
      action: PayloadAction<SignatureDeployment[]>,
    ) {
      signaturesDeploymentAdapter.setAll(
        state.signaturesDeployment,
        action.payload,
      )
      state.signaturesDeployment.loadingStatus = LoadingStatus.LOADED
    },
    computeSignaturesDeploymentDataFailure(state) {
      state.signaturesDeployment.loadingStatus = LoadingStatus.ERROR
    },
    computeSignaturesPowerUsersData(state) {
      state.signaturesPowerUsers.loadingStatus = LoadingStatus.PENDING
    },
    computeSignaturesPowerUsersDataSuccess(
      state,
      action: PayloadAction<SignaturesPowerUser[]>,
    ) {
      signaturesPowerUsersAdapter.setAll(
        state.signaturesPowerUsers,
        action.payload,
      )
      state.signaturesPowerUsers.loadingStatus = LoadingStatus.LOADED
    },
    computeSignaturesTrackingData(state) {
      state.signaturesTracking.loadingStatus = LoadingStatus.PENDING
    },
    computeSignaturesTrackingDataSuccess(
      state,
      action: PayloadAction<SignatureTracking[]>,
    ) {
      signaturesTrackingAdapter.setAll(state.signaturesTracking, action.payload)
      state.signaturesTracking.loadingStatus = LoadingStatus.LOADED
    },
    computeScenariosPowerUsersData(state) {
      state.scenariosPowerUsers.loadingStatus = LoadingStatus.PENDING
    },
    computeScenariosPowerUsersDataSuccess(
      state,
      action: PayloadAction<ScenariosPowerUser[]>,
    ) {
      scenariosPowerUsersAdapter.setAll(
        state.scenariosPowerUsers,
        action.payload,
      )
      state.scenariosPowerUsers.loadingStatus = LoadingStatus.LOADED
    },
    computeScenariosTrackingData(state) {
      state.scenariosTracking.loadingStatus = LoadingStatus.PENDING
    },
    computeScenariosTrackingDataSuccess(
      state,
      action: PayloadAction<ScenarioTracking[]>,
    ) {
      scenariosTrackingAdapter.setAll(state.scenariosTracking, action.payload)
      state.scenariosTracking.loadingStatus = LoadingStatus.LOADED
    },

    fetchBannersClicked(state) {
      state.bannersClickedDatas.loadingStatus = LoadingStatus.PENDING
    },
    fetchBannersClickedSuccess(
      state,
      action: PayloadAction<{
        range: QueriesDateRange
        data: BannersClickedDto[]
      }>,
    ) {
      state.bannersClickedDatas.loadingStatus = LoadingStatus.LOADED
      state.bannersClickedDatas.rawData.push(action.payload)
    },
    fetchBannersClickedFailure(state) {
      state.bannersClickedDatas.loadingStatus = LoadingStatus.ERROR
    },
    fetchSingleScenarioTrackingDatas(state, action: PayloadAction<number>) {
      scenariosTrackingAdapter.updateOne(state.scenariosTracking, {
        id: action.payload,
        changes: {
          loadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    fetchSingleScenarioTrackingDatasSuccess(
      state,
      action: PayloadAction<ScenarioTracking>,
    ) {
      scenariosTrackingAdapter.upsertOne(state.scenariosTracking, {
        ...action.payload,
        loadingStatus: LoadingStatus.LOADED,
      })
    },
    fetchSingleScenarioTrackingDatasFailure(
      state,
      action: PayloadAction<number>,
    ) {
      scenariosTrackingAdapter.updateOne(state.scenariosTracking, {
        id: action.payload,
        changes: {
          loadingStatus: LoadingStatus.ERROR,
        },
      })
    },
    fetchSignaturesClicked(state) {
      state.signaturesClickedDatas.loadingStatus = LoadingStatus.PENDING
    },
    fetchSignaturesClickedSuccess(
      state,
      action: PayloadAction<{
        range: QueriesDateRange
        data: SignaturesClickedDto[]
      }>,
    ) {
      state.signaturesClickedDatas.loadingStatus = LoadingStatus.LOADED
      state.signaturesClickedDatas.rawData.push(action.payload)
    },
    fetchSignaturesClickedFailure(state) {
      state.signaturesClickedDatas.loadingStatus = LoadingStatus.ERROR
    },
    fetchSignaturesHistory(state) {
      state.signaturesHistoryDatas.loadingStatus = LoadingStatus.PENDING
      state.signaturesHistoryDatas.rawData = null
    },
    fetchSignaturesHistorySuccess(
      state,
      action: PayloadAction<SignaturesHistoryDto[]>,
    ) {
      state.signaturesHistoryDatas.loadingStatus = LoadingStatus.LOADED
      state.signaturesHistoryDatas.rawData = action.payload
    },
    fetchSignaturesHistoryFailure(state) {
      state.signaturesHistoryDatas.loadingStatus = LoadingStatus.ERROR
    },
    fetchBannersHistory(state) {
      state.bannersHistoryDatas.loadingStatus = LoadingStatus.PENDING
      state.bannersHistoryDatas.rawData = null
    },
    fetchBannersHistorySuccess(
      state,
      action: PayloadAction<BannersHistoryDto[]>,
    ) {
      state.bannersHistoryDatas.loadingStatus = LoadingStatus.LOADED
      state.bannersHistoryDatas.rawData = action.payload
    },
    fetchBannersHistoryFailure(state) {
      state.bannersHistoryDatas.loadingStatus = LoadingStatus.ERROR
    },
    setQueriesDateRange(state, action: PayloadAction<QueriesDateRange>) {
      state.scenarios.queries = action.payload
      state.signatures.queries = action.payload
    },
    setScenariosQueriesDateRange(
      state,
      action: PayloadAction<QueriesDateRange>,
    ) {
      state.scenarios.queries = action.payload
    },
    setSingleScenarioQueryDateRange(
      state,
      action: PayloadAction<QueriesDateRange>,
    ) {
      state.scenarios.singleItemQuery = action.payload
    },
    resetSingleScenarioQueryDateRange(state) {
      state.scenarios.singleItemQuery.startDate = null
      state.scenarios.singleItemQuery.endDate = null
    },
    setSignaturesQueriesDateRange(
      state,
      action: PayloadAction<QueriesDateRange>,
    ) {
      state.signatures.queries = action.payload
    },
  },
})

export const {
  fetchBannersClickSuccess,
  fetchSignaturesDelivered,
  fetchSignaturesDeliveredSuccess,
  fetchSignaturesDeliveredFailure,
  computeSignaturesDeploymentData,
  computeSignaturesDeploymentDataSuccess,
  computeSignaturesDeploymentDataFailure,
  computeSignaturesPowerUsersData,
  computeSignaturesPowerUsersDataSuccess,
  computeSignaturesTrackingData,
  computeSignaturesTrackingDataSuccess,
  computeScenariosPowerUsersData,
  computeScenariosPowerUsersDataSuccess,
  computeScenariosTrackingData,
  computeScenariosTrackingDataSuccess,
  fetchBannersClicked,
  fetchBannersClickedSuccess,
  fetchBannersClickedFailure,
  fetchBannersHistory,
  fetchBannersHistorySuccess,
  fetchBannersHistoryFailure,
  fetchSignaturesClicked,
  fetchSignaturesClickedSuccess,
  fetchSignaturesClickedFailure,
  fetchSignaturesHistory,
  fetchSignaturesHistorySuccess,
  fetchSignaturesHistoryFailure,
  setQueriesDateRange,
  setScenariosQueriesDateRange,
  setSingleScenarioQueryDateRange,
  resetSingleScenarioQueryDateRange,
  setSignaturesQueriesDateRange,
  fetchSingleScenarioTrackingDatas,
  fetchSingleScenarioTrackingDatasSuccess,
  fetchSingleScenarioTrackingDatasFailure,
} = trackingReducer.actions

export default trackingReducer.reducer
