import { FunctionComponent, useState } from "react"

import { useDispatch } from "react-redux"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { useNavigate } from "react-router-dom"

import config from "config/config"

import { ReactComponent as UsersEmptyIcon } from "features/Users/assets/icon-users-empty-results.svg"

import { useAutoScrollTo } from "hooks/useScroll"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import Toggle from "components/MUIToggle"
import DataGridButton from "components/DataGridButton"
import ConfirmModal from "components/ConfirmModal"
import PaginatedDataGrid, {
  SortOrder,
  SortOrders,
  OverflowedCell,
} from "components/PaginatedDataGrid"

import UserSignaturesPreview from "../UserSignaturesPreview"

import { featureNames } from "config/config.features"
import { USERS_URL } from "router/RouterConstants"

import {
  copySignatureByUserAction,
  deleteUserAction,
  upsertLicenceAction,
} from "features/Users/UsersActions"

import {
  resetEditingUser,
  setPartialUsersOrderBy,
} from "features/Users/UsersReducers"

import { PartialUser } from "features/Users/UserModels"
import { Origin } from "features/Users/UserModels"

import {
  ColumnConfig,
  DataGridConfig,
} from "components/PaginatedDataGrid/PaginatedDataGrid"

interface UsersGridProps {
  partialUsers: Array<PartialUser>
  currentPage: number
  lastPage: number
  pageSize: number
  sortOrders: SortOrders
  changePage: (pageNumber: number) => void
  changePageSize: (pageSize: number) => void
  hasSubsidiary: boolean
  isLoading?: boolean
}

const messages = defineMessages({
  editLegend: { id: "UsersGrid.EditLegend", defaultMessage: "Editer" },
  deleteLegend: { id: "UsersGrid.DeleteLegend", defaultMessage: "Supprimer" },
  previewLegend: {
    id: "UsersGrid.PreviewLegend",
    defaultMessage: "Prévisualiser",
  },
  copyLegend: {
    id: "UsersGrid.CopyLegend",
    defaultMessage: "Copier le HTML de la signature",
  },
  noResults: {
    id: "UsersTab.NoResults",
    defaultMessage: "Aucun résultats de recherche",
  },
})

const UsersGrid: FunctionComponent<UsersGridProps> = ({
  partialUsers,
  currentPage,
  lastPage,
  pageSize,
  sortOrders,
  changePage,
  changePageSize,
  hasSubsidiary,
  isLoading,
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const emailUsernameFeature = useFeatureTogglr(featureNames.EMAIL_USERNAME)

  const [selectedUserId, setSelectedUserId] = useState<number>(-1)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openPreview, setOpenPreview] = useState<boolean>(false)
  const [userToPreview, setUserToPreview] = useState<PartialUser>(null)

  const { setScrollId } = useAutoScrollTo("users")

  const handleUserSubscription = (partialUser) => {
    dispatch(upsertLicenceAction(partialUser.id, !partialUser.activeLicence))
  }

  const handleEditUser = async (userId) => {
    dispatch(resetEditingUser())
    setSelectedUserId(userId)
    setScrollId(`data-grid_${userId}`)
    navigate(`${USERS_URL}/${userId}`)
  }

  const handleDeleteUser = (userId) => {
    setSelectedUserId(userId)
    setOpenConfirm(true)
  }

  const handleCloseModal = () => {
    setSelectedUserId(-1)
    setOpenConfirm(false)
  }

  const deleteUser = () => {
    dispatch(deleteUserAction(selectedUserId))
  }

  const handlePreviewSignature = (row) => {
    setOpenPreview(true)
    setUserToPreview(row)
  }

  const handleSort = (columnKey: string, sortOrder: SortOrder) => {
    dispatch(setPartialUsersOrderBy({ columnKey, sortOrder }))
  }

  const userGridColumns: ColumnConfig<PartialUser>[] = [
    {
      label: (
        <FormattedMessage id="UsersGrid.Licence" defaultMessage="Licence" />
      ),
      value: (_value, partialUser) => {
        return (
          <Toggle
            checked={partialUser.activeLicence}
            onChange={() => handleUserSubscription(partialUser)}
          />
        )
      },
      cellsClassName: "users-grid__licence--cell",
    },
    {
      label: (
        <FormattedMessage id="UsersGrid.FirstName" defaultMessage="Prénom" />
      ),
      value: (_value, partialUser) => partialUser.properties.firstname,
      onSort: handleSort,
      sortKey: "firstname",
      cellsClassName: "users-grid__ellipsis--cell",
    },
    {
      label: <FormattedMessage id="UsersGrid.Name" defaultMessage="Nom" />,
      value: (_value, partialUser) => partialUser.properties.lastname,
      onSort: handleSort,
      sortKey: "lastname",
      cellsClassName: "users-grid__ellipsis--cell",
    },
    {
      label: <FormattedMessage id="UsersGrid.Email" defaultMessage="Email" />,
      value: (_value, partialUser) => (
        <OverflowedCell text={partialUser.properties.mail} />
      ),
      onSort: handleSort,
      sortKey: "mail",
    },
    {
      label: (
        <FormattedMessage id="UsersGrid.Origin" defaultMessage="Mode d'ajout" />
      ),
      value: "origin",
      cellsClassName: "users-grid__origin--cell",
    },
    {
      label: "",
      renderLoading: false,
      value: (_value, partialUser: PartialUser) => {
        return (
          <div className="users-grid__actions__cell">
            <div className="users-grid__actions__container">
              <div className="users-grid__actions__icons">
                <DataGridButton
                  legend={formatMessage(messages.editLegend)}
                  icon={
                    <img
                      alt="edit"
                      className="users-grid__actions__icon-image"
                      src={`${config.cdnUrl}/images/bmm/icon_edit_hover-01.svg`}
                    />
                  }
                  onClick={() => handleEditUser(partialUser.id)}
                />
                {partialUser.origin === Origin.ORIGIN_PORTAL &&
                  !hasSubsidiary && (
                    <DataGridButton
                      legend={formatMessage(messages.deleteLegend)}
                      icon={
                        <img
                          alt="delete"
                          className="users-grid__actions__icon-image"
                          src={`${config.cdnUrl}/images/bmm/icon_delete_hover-01.svg`}
                        />
                      }
                      onClick={() => handleDeleteUser(partialUser.id)}
                    />
                  )}
                <DataGridButton
                  legend={formatMessage(messages.previewLegend)}
                  icon={
                    <img
                      alt="preview"
                      className="users-grid__actions__icon-image"
                      src={`${config.cdnUrl}/images/bmm/users/ic_signature_hover.svg`}
                    />
                  }
                  onClick={() => handlePreviewSignature(partialUser)}
                />
                <DataGridButton
                  legend={formatMessage(messages.copyLegend)}
                  icon={
                    <img
                      alt="copy"
                      className="users-grid__actions__icon-image"
                      src={`${config.cdnUrl}/images/bmm/users/carbon_code.svg`}
                    />
                  }
                  onClick={() =>
                    dispatch(copySignatureByUserAction(partialUser.id))
                  }
                />
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  const usernameColumn = {
    label: (
      <FormattedMessage id="UsersGrid.Username" defaultMessage="Username" />
    ),
    value: (_value, partialUser) => (
      <OverflowedCell text={partialUser.username} />
    ),
  }

  if (emailUsernameFeature?.isActive)
    userGridColumns.splice(4, 0, usernameColumn)

  const userGridConfig: DataGridConfig<PartialUser> = {
    columns: userGridColumns,
    isLoading,
    skeletonRowQuantity: 12,
    background: (
      <div className="users-grid__empty-results">
        <UsersEmptyIcon />
        <p className="users-grid__empty-results--text">
          {formatMessage(messages.noResults)}
        </p>
      </div>
    ),
  }

  return (
    <>
      <div className="users-grid">
        <ConfirmModal
          action={deleteUser}
          handleClose={handleCloseModal}
          open={openConfirm}
        />
        <PaginatedDataGrid
          config={userGridConfig}
          data={partialUsers}
          currentPage={currentPage}
          lastPage={lastPage}
          pageSize={pageSize}
          changePage={changePage}
          setPageSize={changePageSize}
          sortOrders={sortOrders}
        />
      </div>
      {openPreview ? (
        <UserSignaturesPreview
          user={userToPreview}
          close={() => setOpenPreview(false)}
        />
      ) : null}
    </>
  )
}

export default UsersGrid
