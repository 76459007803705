import { GlobalStates } from "store"

import DateHelper from "utils/DateHelper"
import { MAX_ADMINS, TRIAL_OFFER_NAME } from "./OffersConstants"
import {
  selectAllCatalogOffers,
  selectAllOfferOptions,
  selectAllOffers,
  selectOfferById,
} from "./OffersReducer"

const getCurrentOffer = (state: GlobalStates) => {
  if (state.offers.currentOffer.entity)
    return selectOfferById(state, state.offers.currentOffer.entity.OfferId)
}

const getSelectedOptions = (state: GlobalStates) => {
  const allOptions = selectAllOfferOptions(state)
  return allOptions.filter((option) => option.Selected === true)
}

const getOffer = (state: GlobalStates, offerId: number) => {
  return selectOfferById(state, offerId)
}

const checkCurrentTenantOfferValidity = (state: GlobalStates): boolean => {
  if (state.offers.currentOffer.entity) {
    return DateHelper.IsNotExpired(
      state.offers.currentOffer.entity.StartDate,
      state.offers.currentOffer.entity.EndDate,
    )
  }
  return true
}

const getCurrentTenantOffer = (state: GlobalStates) => {
  return state.offers.currentOffer.entity
}

const getCurrentTenantOfferValidity = (state: GlobalStates) => {
  const tenantOffer = state.offers.currentOffer.entity

  return DateHelper.IsNotExpired(tenantOffer.StartDate, tenantOffer.EndDate)
}

const getCurrentTenantOfferLoadingStatus = (state: GlobalStates) =>
  state.offers.currentOffer.loadingStatus

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getMaxAdminsLimitations = (_state: GlobalStates) => {
  return MAX_ADMINS
}

const getOfferLimitations = (state: GlobalStates) => state.offers.limitations

const isTrial = (state: GlobalStates): boolean =>
  state.offers.currentOffer.entity?.Name === TRIAL_OFFER_NAME || false

const isTrialSince = (state: GlobalStates) => {
  if (!isTrial) return -1

  const trialOffer = getCurrentTenantOffer(state)

  if (trialOffer == null) return -1

  const trialOfferStart = trialOffer.StartDate
  const now = new Date().toISOString()

  return DateHelper.GetNumberOfDaysSince(trialOfferStart, now)
}

const getTrialLimitations = (state: GlobalStates) =>
  state.offers.trialLimitations

const getMaxLicences = (state: GlobalStates) => {
  return state.offers.currentOffer.entity?.MaxLicences
}

const getCatalogOffersLoadingStatus = (state: GlobalStates) =>
  state.offers.allCatalogOffers.loadingStatus

export const selectors = {
  getAllOffers: selectAllOffers,
  getAllOptions: selectAllOfferOptions,
  getSelectedOptions,
  getOffer,
  getCurrentOffer,
  checkCurrentTenantOfferValidity,
  getCurrentTenantOffer,
  isTrial,
  isTrialSince,
  getOfferLimitations,
  getMaxAdminsLimitations,
  getTrialLimitations,
  getMaxLicences,
  getCurrentTenantOfferValidity,
  getCurrentTenantOfferLoadingStatus,
  getAllCatalogOffers: selectAllCatalogOffers,
  getCatalogOffersLoadingStatus,
}
