import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"

import {
  fetchAllCatalogOffersApi,
  fetchAllOffersApi,
  fetchCurrentTenantOfferApi,
  fetchOffersOptionsApi,
} from "./OffersApi"

import {
  fetchAllCatalogOffers,
  fetchAllCatalogOffersFailure,
  fetchAllCatalogOffersSuccess,
  fetchAllOffers,
  fetchAllOffersFailure,
  fetchAllOffersSuccess,
  fetchAllOptions,
  fetchAllOptionsFailure,
  fetchAllOptionsSuccess,
  fetchCurrentTenantOffer,
  fetchCurrentTenantOfferFailure,
  fetchCurrentTenantOfferSuccess,
} from "./OffersReducer"

import { CatalogOffer, Offer, OfferOption } from "./OffersModels"
import { withSagaErrorHandler } from "features/Errors"
import { offersSelectors } from "."
import { LoadingStatus } from "core/CoreModels"

function* getAllOffersAsync() {
  const response = yield call(fetchAllOffersApi)
  if (!response.success) throw new Error(response.errorMessage)

  const offers = response.result.map((offerDto) => {
    return new Offer(offerDto)
  })

  yield put(fetchAllOffersSuccess(offers))
}

export function* getorLoadAllCatalogOffersAsync() {
  const loadingStatus = yield select(
    offersSelectors.getCatalogOffersLoadingStatus,
  )

  if (loadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchAllCatalogOffers())
    yield take(fetchAllCatalogOffersSuccess.type)
  }
}

function* fetchAllCatalogOffersAsync() {
  const response = yield call(fetchAllCatalogOffersApi)

  if (!response.success) throw new Error(response.errorMessage)

  const allCatalogOffers = response.result.map(
    (offer) => new CatalogOffer(offer),
  )

  yield put(fetchAllCatalogOffersSuccess(allCatalogOffers))
}

export function* getAllOptionsAsync() {
  const response = yield call(fetchOffersOptionsApi)

  if (!response.success) throw new Error(response.errorMessage)

  const options = response.result.map((offerOptionDto) => {
    return new OfferOption(offerOptionDto)
  })

  yield put(fetchAllOptionsSuccess(options))
}

export function* getCurrentTenantOffer() {
  const response = yield call(fetchCurrentTenantOfferApi)

  if (!response.success) throw new Error(response.errorMessage)

  yield put(fetchCurrentTenantOfferSuccess(response.result))
}

export function* getOrLoadCurrentOffer() {
  const currentOfferLoadingStatus = yield select(
    offersSelectors.getCurrentTenantOfferLoadingStatus,
  )

  if (currentOfferLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchCurrentTenantOffer())
    yield take(fetchCurrentTenantOfferSuccess.type)
  }

  const currentOffer = yield select(offersSelectors.getCurrentTenantOffer)

  return currentOffer
}

export const offersWatchers = [
  takeEvery(
    fetchAllOffers,
    withSagaErrorHandler(getAllOffersAsync, fetchAllOffersFailure()),
  ),
  takeEvery(
    fetchAllOptions,
    withSagaErrorHandler(getAllOptionsAsync, fetchAllOptionsFailure()),
  ),
  takeEvery(
    fetchCurrentTenantOffer.type,
    withSagaErrorHandler(
      getCurrentTenantOffer,
      fetchCurrentTenantOfferFailure(),
    ),
  ),
  takeLatest(
    fetchAllCatalogOffers.type,
    withSagaErrorHandler(
      fetchAllCatalogOffersAsync,
      fetchAllCatalogOffersFailure(),
    ),
  ),
]
