import { createAction } from "@reduxjs/toolkit"

export const createGroupAction = createAction(
  "group/createGroup",
  function prepare(group) {
    return { payload: group }
  },
)

export const upsertDynamicGroupAction = createAction(
  "group/upsertDynamicGroup",
  function prepare(group) {
    return { payload: group }
  },
)

export const editGroupAction = createAction(
  "group/editGroup",
  function prepare(group) {
    return { payload: group }
  },
)

export const deleteGroupAction = createAction(
  "group/deleteGroup",
  function prepare(groupId) {
    return { payload: groupId }
  },
)

export const upsertLicenceGroupAction = createAction(
  "group/upsertLicenceGroup",
  function prepare(groupId: number, activated: boolean) {
    return { payload: { groupId, activated } }
  },
)

export const searchGroupAction = createAction(
  "group/searchGroup",
  function prepare(search: string) {
    return { payload: search }
  },
)
