import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react"

import PeoplePicker, { Tag } from "components/PeoplePicker"

import { User } from "features/Users/UserModels"

import ArrayHelper from "utils/ArrayHelper"
import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"
import { useDispatch, useSelector } from "react-redux"

import { updateActiveBuilderSignatureAssignment } from "features/BuilderSignatures/BuilderSignaturesReducers"
import { builderSignaturesSelectors } from "features/BuilderSignatures"
import Group from "features/Groups/GroupsModels"

interface BuilderPeoplePickerProps {
  tags: Tag[]
  assignedUsersIds: number[]
  assignedGroupsIds: number[]
  onSetTags: Dispatch<SetStateAction<Tag[]>>
}

function getExistingUsersAssignment(assignedUsers, allUsers) {
  return assignedUsers.map((userId) => {
    const user = allUsers.find((user) => user.Id === userId)
    const fullName = (user && User.getFullName(user)) || ""
    if (!user) return null
    return {
      id: userId,
      label: fullName,
      type: "user",
    }
  })
}

function getExistingGroupsAssignment(assignedGroups, allGroups) {
  return assignedGroups.map((groupId) => {
    const group = allGroups.find((group) => group.Id === groupId)
    const name = (group && group.Name) || ""
    if (!group) return null
    return {
      id: groupId,
      label: name,
      type: "group",
    }
  })
}

const BuilderPeoplePicker: FunctionComponent<BuilderPeoplePickerProps> = ({
  tags,
  assignedUsersIds,
  assignedGroupsIds,
  onSetTags,
}) => {
  const dispatch = useDispatch()
  const allUsers = useSelector(usersSelectors.getAllUsers)
  const allGroups: Group[] = useSelector(groupsSelectors.getAllGroups)
  const assignedUsers = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureUsers,
  )
  const assignedGroups = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureGroups,
  )

  useEffect(() => {
    const newTags = []
    if (assignedGroups.length > 0) {
      newTags.push(...getExistingGroupsAssignment(assignedGroups, allGroups))
    }
    if (assignedUsers.length > 0) {
      newTags.push(...getExistingUsersAssignment(assignedUsers, allUsers))
    }
    onSetTags(newTags.filter(Boolean))
  }, [
    assignedGroups.length,
    assignedUsers.length,
    allGroups.length,
    allUsers.length,
    onSetTags,
  ])

  const handleRemoveUser = (userId: number) => {
    dispatch(
      updateActiveBuilderSignatureAssignment({
        users: assignedUsersIds.filter((u) => u !== userId),
      }),
    )
  }

  const handleRemoveGroup = (groupId: number) => {
    dispatch(
      updateActiveBuilderSignatureAssignment({
        groups: assignedGroupsIds.filter((g) => g !== groupId),
      }),
    )
  }

  const handleTagClick = (item) => {
    if (item.type === "group") {
      const group = allGroups.find((group) => group.Id === item.id)
      handleRemoveGroup(group.Id)
      const remainingTags = ArrayHelper.reject(
        tags,
        (tag) => tag.type === "group" && tag.id === item.id,
      )
      onSetTags(remainingTags)
    }
    if (item.type === "user") {
      const user = allUsers.find((user) => user.Id === item.id)
      handleRemoveUser(user.Id)
      const remainingTags = ArrayHelper.reject(
        tags,
        (tag) => tag.type === "user" && tag.id === item.id,
      )
      onSetTags(remainingTags)
    }
  }

  return <PeoplePicker tags={tags} onTagClick={handleTagClick} />
}

export default BuilderPeoplePicker
