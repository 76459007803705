import { useDispatch } from "react-redux"
import { Notification, NotificationType } from "./NotificationModels"
import { addNotification, deleteNotification } from "./NotificationReducer"

/**
 * Hook permettant de gérer les notifications basé sur Redux
 */
export const useNotifier = () => {
  const dispatch = useDispatch()

  return {
    addNotification: (message: string, type: NotificationType) =>
      dispatch(addNotification(new Notification(message, type))),
    deleteNotification: (id: string) => {
      dispatch(deleteNotification(id))
    },
  }
}
