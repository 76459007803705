import Toggle from "components/MUIToggle"
import { accountsSelectors } from "features/Accounts"
import { Roles } from "features/Accounts/AccountsModels"
import { builderSignaturesSelectors } from "features/BuilderSignatures"
import { toggleDebugMode } from "features/BuilderSignatures/BuilderSignaturesReducers"

import { FunctionComponent } from "react"
import { defineMessages, useIntl } from "react-intl"

import { useDispatch, useSelector } from "react-redux"

const messages = defineMessages({
  label: {
    id: "BuilderParametersPanel.DebugModeLabel",
    defaultMessage: "Mode debug",
  },
})

const DebugModeBlock: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const debugMode = useSelector(builderSignaturesSelectors.getBuilderDebugMode)

  const currentAccount = useSelector(accountsSelectors.getCurrentAccount)

  if (currentAccount?.Roles.includes(Roles.BmmAdmin))
    return (
      <div className="debug-mode-block">
        <p className="debug-mode-block__label">
          {formatMessage(messages.label)}
        </p>
        <Toggle
          checked={debugMode}
          value={debugMode}
          onChange={() => dispatch(toggleDebugMode())}
        />
      </div>
    )

  return null
}

export default DebugModeBlock
