import React, { FunctionComponent, ReactNode, useState } from "react"

import classNames from "classnames"

interface HoverTooltipProps {
  children: ReactNode
  tooltip: ReactNode
  level?: "info" | "warning"
  align?: "left" | "right"
  className?: string
}

type HoverTooltipType = HoverTooltipProps & React.HTMLAttributes<HTMLDivElement>

const HoverTooltip: FunctionComponent<HoverTooltipType> = ({
  children,
  tooltip,
  className,
  level = "info",
  align = "left",
  ...others
}) => {
  const [hideTip, setHideTip] = useState(true)

  const handleMouseEnter = () => {
    setHideTip(false)
  }

  const handleMouseLeave = () => {
    setHideTip(true)
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames("hover-tooltip__container", className)}
      {...others}
    >
      {children}
      <div
        className={classNames("hover-tooltip__tip", level, align)}
        hidden={hideTip}
      >
        {tooltip}
      </div>
    </div>
  )
}

export default HoverTooltip
