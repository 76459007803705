import { put, call, takeLatest, takeEvery } from "redux-saga/effects"
import { withSagaErrorHandler } from "features/Errors"
import { PayloadAction } from "@reduxjs/toolkit"

import {
  createSubsidiary,
  createSubsidiaryFailure,
  createSubsidiarySuccess,
  deleteSubsidiary,
  deleteSubsidiaryFailure,
  deleteSubsidiarySuccess,
  fetchSubsidiaries,
  fetchSubsidiariesFailure,
  fetchSubsidiariesSuccess,
  setSubsidiaryUpdatedStatus,
  updateSubsidiary,
  updateSubsidiaryFailure,
  updateSubsidiarySuccess,
  fetchCurrentSubsidiaries,
  fetchCurrentSubsidiariesFailure,
  fetchCurrentSubsidiariesSuccees,
} from "./SubsidiariesReducer"

import { Subsidiary } from "./SubsidiaryModels"

import SubsidiaryApi, {
  CreateSubsidiaryRequest,
  UpdateSubsidiaryRequest,
} from "./SubsidiariesApi"
import { addNotification, Notification } from "features/Notifications"
import { uploadImageAsync } from "features/Scenarios/services/bannerService"
import { updateCurrentSubsidiaryAction } from "./SubsidiariesActions"

function* fetchSubsidiariesAsync() {
  const response = yield call(SubsidiaryApi.fetchSubsidiaries)
  if (!response.success) throw Error(response.errorMessage)

  yield put(fetchSubsidiariesSuccess({ subsidiaries: response.result }))
}

function* createSubsidiaryAsync(action: PayloadAction<Subsidiary>) {
  let urlStorage = null
  if (action.payload.iconUrl != null) {
    urlStorage = yield call(uploadImageAsync, action.payload.iconUrl)
  }

  const mapRequestFromSubsidiary = (subsidiary): CreateSubsidiaryRequest => ({
    name: subsidiary.name,
    aspNetUserIds: subsidiary.aspNetUserIds,
    groupIds: subsidiary.groupIds,
    userIds: subsidiary.userIds,
    iconUrl: urlStorage,
  })

  const response = yield call(
    SubsidiaryApi.createSubsidiary,
    mapRequestFromSubsidiary(action.payload),
  )

  const notification = response.success
    ? new Notification(
        `Filiale ${response.result.name} créée avec succès`,
        "SUCCESS",
      )
    : new Notification(
        `Une erreur est survenue lors de la création de la filiale: ${response.errorMessage}`,
        "ERROR",
      )

  yield put(addNotification(notification))

  if (!response.success) throw Error(response.errorMessage)
  yield put(setSubsidiaryUpdatedStatus(true))
  yield put(createSubsidiarySuccess(response.result))
}

function* updateSubsidiaryAsync(action: PayloadAction<Subsidiary>) {
  let urlStorage = null
  if (action.payload.iconUrl !== null) {
    urlStorage = yield call(uploadImageAsync, action.payload.iconUrl)
  }

  const mapRequestFromSubsidiary = (subsidiary): UpdateSubsidiaryRequest => ({
    id: subsidiary.id,
    name: subsidiary.name,
    aspNetUserIds: subsidiary.aspNetUserIds,
    groupIds: subsidiary.groupIds,
    userIds: subsidiary.userIds,
    iconUrl: urlStorage,
  })

  const response = yield call(
    SubsidiaryApi.updateSubsidiary,
    mapRequestFromSubsidiary(action.payload),
  )

  const notification = response.success
    ? new Notification(
        `Filiale ${response.result.name} modifiée avec succès`,
        "SUCCESS",
      )
    : new Notification(
        `Une erreur est survenue lors de la modification de la filiale: ${response.errorMessage}`,
        "ERROR",
      )

  yield put(addNotification(notification))

  if (!response.success) throw Error(response.errorMessage)
  yield put(setSubsidiaryUpdatedStatus(true))
  yield put(updateSubsidiarySuccess(response.result))
}

function* deleteSubsidiaryAsync(action: PayloadAction<number>) {
  const response = yield call(SubsidiaryApi.deleteSubsidiary, action.payload)

  const notification = response.success
    ? new Notification(`Filiale supprimée avec succès`, "SUCCESS")
    : new Notification(
        `Une erreur est survenue lors de la suppression de la filiale: ${response.errorMessage}`,
        "ERROR",
      )

  // Messaging - notification
  yield put(addNotification(notification))
  if (!response.success) throw Error(response.errorMessage)

  yield put(deleteSubsidiarySuccess(action.payload))
}

function* changeCurrentSubsidiaryAsync(action: PayloadAction<number>) {
  const response = yield call(
    SubsidiaryApi.updateCurrentSubsidiary,
    action.payload,
  )

  if (!response.success) throw Error(response.errorMessage)
  location.reload()
}

function* fetchCurrentSubsidiariesAsync() {
  const responseAllSubsidiaries = yield call(
    SubsidiaryApi.getCurrentSubsidiaries,
  )
  if (!responseAllSubsidiaries.success)
    throw Error(responseAllSubsidiaries.errorMessage)

  const responseCurrentSubsidiary = yield call(
    SubsidiaryApi.getCurrentSubsidiary,
  )
  if (!responseCurrentSubsidiary.success)
    throw Error(responseCurrentSubsidiary.errorMessage)

  yield put(
    fetchCurrentSubsidiariesSuccees({
      currentSubsidiary: responseCurrentSubsidiary.result,
      subsidiaries: responseAllSubsidiaries.result,
    }),
  )
}

export const subsidiaryWatchers = [
  takeLatest(
    fetchSubsidiaries.type,
    withSagaErrorHandler(fetchSubsidiariesAsync, fetchSubsidiariesFailure()),
  ),
  takeLatest(
    createSubsidiary.type,
    withSagaErrorHandler(createSubsidiaryAsync, createSubsidiaryFailure()),
  ),
  takeEvery(
    updateSubsidiary.type,
    withSagaErrorHandler(updateSubsidiaryAsync, updateSubsidiaryFailure()),
  ),
  takeLatest(
    deleteSubsidiary.type,
    withSagaErrorHandler(deleteSubsidiaryAsync, deleteSubsidiaryFailure()),
  ),
  takeLatest(
    updateCurrentSubsidiaryAction.type,
    withSagaErrorHandler(changeCurrentSubsidiaryAsync),
  ),
  takeLatest(
    fetchCurrentSubsidiaries.type,
    withSagaErrorHandler(
      fetchCurrentSubsidiariesAsync,
      fetchCurrentSubsidiariesFailure(),
    ),
  ),
]
