import { Dispatch, FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import GraphDataLegendItem from "features/Tracking/components/GraphDataLegendItem"

import {
  ScenarioTracking,
  TrackingGraphData,
} from "features/Tracking/TrackingModels"

import { ActionType } from "features/Tracking/hooks/useTrackingGraphData"

interface SingleScenarioTrackingLegendProps {
  series: TrackingGraphData[]
  dispatch: Dispatch<ActionType<ScenarioTracking>>
}

const messages = defineMessages({
  title: {
    id: "Tracking.SingleScenarioTrackingLegendTitle",
    defaultMessage: "Légende",
  },
})

const SingleScenarioTrackingLegend: FunctionComponent<
  SingleScenarioTrackingLegendProps
> = ({ series, dispatch }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="single-scen-track__legend">
      <div className="single-scen-track__legend--title-container">
        <p className="single-scen-track__legend--title">
          {formatMessage(messages.title)}
        </p>
      </div>

      {series.map((serie) => (
        <GraphDataLegendItem key={serie.id} serie={serie} dispatch={dispatch} />
      ))}
    </div>
  )
}

export default SingleScenarioTrackingLegend
