import { defineMessages, MessageDescriptor } from "react-intl"
import { JobData } from "./JobsModels"

/** Gestion de la traduction **/
export const messages = defineMessages({
  /* State Translations */
  EnqueuedMsg: {
    id: `Jobs.EnqueuedMsg`,
    defaultMessage: " mis en file.",
  },
  ScheduledMsg: {
    id: `Jobs.ScheduledMsg`,
    defaultMessage: " planifié.",
  },
  ProcessingMsg: {
    id: `Jobs.ProcessingMsg`,
    defaultMessage: " en cours d'exécution.",
  },
  SuccessMsg: {
    id: `Jobs.SuccessMsg`,
    defaultMessage: " s'est terminée avec succès.",
  },
  FailedMsg: {
    id: `Jobs.FailedMsg`,
    defaultMessage: " s'est terminée en erreur.",
  },
  /* Job Names Translations */
  IDeployOwa: {
    id: `Jobs.IDeployOwa`,
    defaultMessage: "Le déploiement OWA",
  },
  IUpsell: {
    id: `Jobs.IUpsell`,
    defaultMessage: "La mise à jour de votre offre",
  },
  ISubscribe: {
    id: `Jobs.ISubscribe`,
    defaultMessage: "La création de votre environnement",
  },
  IDeployGmail: {
    id: `Jobs.IDeployGmail`,
    defaultMessage: "Le déploiement de vos signatures GMail",
  },
  ISyncGSuite: {
    id: `Jobs.ISyncGSuite`,
    defaultMessage: "La synchronisation de votre annuaire GSuite",
  },
  ISyncLucca: {
    id: `Jobs.ISyncLucca`,
    defaultMessage: "La synchronisation de votre annuaire Lucca",
  },
  IDeploySalesforce: {
    id: `Jobs.IDeploySalesforce`,
    defaultMessage: "Le déploiement de vos signatures Salesforce",
  },
  ISyncO365: {
    id: `Jobs.ISyncO365`,
    defaultMessage: "La synchronisation de votre annuaire O365",
  },
  IDeploySellsy: {
    id: `Jobs.IDeploySellsy`,
    defaultMessage: "Le déploiement de vos signatures Sellsy",
  },
  SyncUsersOrchestratorJob: {
    id: "Jobs.SyncUsersOrchestratorJob",
    defaultMessage: "La synchronisation de votre annuaire",
  },
})

/**
 * Retourne le message en fonction du nom du job
 * @param job JobData
 */
export const getJobMessage = (job: JobData): MessageDescriptor => {
  return messages[`${job.jobName}`]
}
