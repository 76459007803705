import { PayloadAction } from "@reduxjs/toolkit"

import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  select,
  take,
  all,
} from "redux-saga/effects"

import { withSagaErrorHandler } from "features/Errors"

import { addNotification } from "features/Notifications/NotificationReducer"
import { Notification } from "features/Notifications/NotificationModels"

import { UserProperties } from "features/Users/UserModels"
import {
  updateUserLicenceSuccess,
  upsertUserAction,
} from "features/Users/UsersActions"
import {
  deleteUserSuccess,
  setSampleUser,
  shouldRedirect,
  upsertUserSuccess,
} from "features/Users/UsersReducers"
import {
  upsertBmmUser,
  updateCrmContactAsync,
  updateMailingListContactAsync,
} from "features/Users/UsersSagas"

import {
  addAdminUserAction,
  deleteAdminAccountAction,
  fetchAccountsLinksAction,
  resetPasswordAction,
  requestResetPasswordSuccessAction,
  requestResetPasswordFailureAction,
  requestResetPasswordAction,
  fetchGWorkspaceDomainsAction,
  fetchGWorkspaceDomainsFailureAction,
  updateTenantPropertyAction,
  fetchTenantFeaturesAction,
  createTenantFeaturePropertiesAction,
  updateTenantFeaturePropertiesSuccessAction,
  updateTenantFeaturePropertiesFromBmmSuccessAction,
  updateTenantPropertiesFromBmmAction,
  loginSuccessAction,
  fetchCurrentAccountAction,
  loginAction,
  logoutAction,
  loadInitialDatasAction,
  tenantCreatedAction,
  finishAccountRegistrationAction,
  createTenantPropertyAction,
  addAdminAccountToSubsidiariesAction,
  removeAdminAccountToSubsidiariesAction,
} from "./AccountsActions"
import {
  createAdminAccountApi,
  deleteAdminAccountApi,
  fetchAdminsAccountsApi,
  fetchCurrentTenant,
  fetchTenantProperties as fetchTenantPropertiesApi,
  createTenantPropertyApi,
  fetchMe,
  fillAccountInformations,
  updateAdminAccountRolesApi,
  getWindowsVersions,
  createCurrentTenant,
  updateGoogleTenantProperty,
  fetchGoogleWorkspaceDomains as fetchGoogleWorkspaceDomainsApi,
  updateTenantPropertyApi,
} from "./AccountsApi"
import {
  createAdminAccount,
  createAdminAccountSuccess,
  createAdminAccountFailure as createAdminAccountFailureAction,
  deleteAdminAccountSuccess,
  fetchAdminAccounts,
  fetchAdminAccountsSuccess,
  updateAdminAccountRoles,
  updateAdminAccountRolesSuccess,
  resetCaptcha,
  resetPasswordSuccess,
  resetPasswordFailure,
  fetchAccountLinksSuccess,
  fettchAccountLinksFailure,
  fetchWindowsVersionsSuccess,
  fetchGWorkspaceDomainsSuccess,
  fetchTenantProperties as fetchTenantPropertiesAction,
  fetchTenantPropertiesSuccess,
  updateTenantPropertiesSuccess,
  fetchTenantInfosSuccess,
  fetchCurrentAccountSuccess,
  fetchCurrentAccountPending,
  fetchCurrentAccountReady,
  createCurrentAccountSuccess,
  createCurrentAccountFailure,
  createCurrentAccount,
  loginFailure,
  logoutSuccess,
  loading,
  updateAccountInformationsSuccess,
  updateAccountInformations,
  setOnBoardingData,
  finishOnBoarding,
  setOnBoarding,
  homePageStartup,
  homePageStartupSuccess,
  fetchSignaturesTileStart,
  fetchSignaturesTileSuccess,
  fetchCampaignsTileStart,
  fetchCampaignsTileSuccess,
  fetchTrackingTileStart,
  fetchTrackingTileSuccess,
  fetchUsersTileStart,
  fetchUsersTileSuccess,
  createSubsidiaryAdminAccount,
} from "./AccountsReducer"
import {
  AccountInformations,
  AdminAccount,
  AdminAccountDto,
  DataGridAdminAccount,
  OnBoardingItem,
  OnBoardingItems,
  OnBoardingKey,
} from "./AccountsModels"

import { LoadingStatus, TypedResult } from "core/CoreModels"

import DateHelper from "utils/DateHelper"
import { Account } from "features/Accounts/AccountsModels"

import { accountsSelectors } from "features/Accounts"

import StringHelper from "utils/StringHelper"

import config from "config/config"

import { fillUserProperties } from "sagas/signatures.sagas"

import Version from "entities/Version"
import { Tenant } from "entities/Tenant"
import { TenantOffer } from "features/Offers/OffersModels"

import TenantProperty from "entities/TenantProperty"
import TenantProperties, {
  TENANT_PROPERTY_NAMES,
} from "entities/TenantProperties"

import { fetchFeatures } from "features/FeatureTogglr/FeatureTogglrReducer"
import { JobsActionsTypes } from "features/Jobs"

import Feature from "features/Features/FeaturesModels"

import {
  login as loginApi,
  logout as logoutApi,
  registerAccountApi,
  resetPassword as resetPasswordApi,
  requestResetPassword as requestResetPasswordApi,
} from "features/Accounts/AccountsApi"

import { initSignatureFormAction } from "features/Signatures/SignaturesActions"
import {
  fetchAllOffers,
  fetchCurrentTenantOfferSuccess,
} from "features/Offers/OffersReducer"
import { fetchCurrentTenantOfferApi } from "features/Offers/OffersApi"
import {
  hideGlobalLoader,
  initLanguagesSuccess,
  showGlobalLoader,
} from "features/App/AppReducers"
import { marketingSelectors } from "features/Marketing"
import { resetUTM } from "features/Marketing/MarketingReducers"
import {
  ALL_ACTIVES_CURRENT_CAMPAIGNS_QUERY,
  ALL_ACTIVE_GROUPS_COUNT_QUERY,
  ALL_ACTIVE_USERS_COUNT_QUERY,
  ALL_SIGNATURES_TILE_DATAS_QUERY,
  AT_LEAST_ONE_ACTIVE_BANNER_QUERY,
  AT_LEAST_ONE_ACTIVE_SIGNATURE_QUERY,
  AT_LEAST_ONE_ACTIVE_USER_QUERY,
  AT_LEAST_ONE_USER_ASSIGNED_TO_SIGNATURE_QUERY,
  DEFAULT_ON_BOARDING_ITEMS,
  LATEST_TEN_USERS_QUERY,
  TRACKING_TILE_DELIVERED_PERIOD,
  TRACKING_TILE_PERIOD_RANGE,
} from "./AccountsConstants"
import { fetchUsers } from "features/Users/UsersApi"
import { fetchSignatures } from "features/Signatures/SignaturesApi"
import { fetchScenarios } from "features/Scenarios/ScenarioApi"
import {
  RequestParams,
  requestParamsBuilder,
} from "core/services/requestBuilderService"
import {
  fetchBannersClickedApi,
  fetchSignaturesClickedApi,
  fetchSignaturesDeliveredApi,
  fetchSignaturesHistoryApi,
} from "features/Tracking/TrackingApi"
import {
  commonOnBoardingRule,
  onBoardingInProgress,
  onBoardingRulesValidation,
  syncedSignatureOnBoardingRule,
} from "./AccountsRules"
import {
  createScenarioSuccess,
  deleteScenarioSuccess,
  duplicateScenarioSuccess,
  updateScenarioSuccess,
} from "features/Scenarios/ScenarioReducers"
import {
  activateSignatureSuccess,
  assignGroupsSuccess,
  assignUsersSuccess,
  createSignatureSuccess,
  deleteSignatureSuccess,
  removeSendingModeFromSignatureSuccess,
  updateSignatureSuccess,
} from "features/Signatures/SignaturesReducer"
import {
  activateBuilderSignatureSuccess,
  createBuilderSignatureSuccess,
  deleteBuilderSignatureSuccess,
  updateBuilderSignatureSuccess,
} from "features/BuilderSignatures/BuilderSignaturesReducers"
import { offersSelectors } from "features/Offers"
import { getOrLoadFeaturesAsync } from "features/FeatureTogglr/FeatureTogglrSagas"
import { SignatureDto } from "features/Signatures/SignaturesModels"
import { ScenarioCustomQueryDto } from "features/Scenarios/ScenarioModels"
import moment from "moment"
import { activateScenarioSuccessAction } from "features/Scenarios/ScenarioActions"
import {
  BannersClickedDto,
  SignaturesClickedDto,
  SignaturesHistoryDto,
} from "features/Tracking/TrackingModels"
import { fetchGroupsApi } from "features/Groups/GroupsApi"
import { getOrLoadCurrentOffer } from "features/Offers/OffersSagas"
import { updateTenantFeatureSuccess } from "features/Features/FeaturesReducers"
import {
  selectAllSubsidiaries,
  updateSubsidiary,
  updateSubsidiarySuccess,
} from "features/Subsidiaries/SubsidiariesReducer"
import { Subsidiary } from "features/Subsidiaries/SubsidiaryModels"
import {
  UpdateSubsidiaryRequest,
  updateSubsidiaryApi,
} from "features/Subsidiaries/SubsidiariesApi"

function* addAdminUser(
  action: PayloadAction<{
    userId: number
    properties: UserProperties
    redirect: string
  }>,
) {
  yield put(showGlobalLoader())
  const createAction = {
    ...action,
    type: upsertUserAction.type,
  }

  const adminUser = yield call(upsertBmmUser, createAction)

  // définition de l'utilisateur comme utilisateur exemple dans la signature
  yield put(setSampleUser(adminUser))

  // remplacement des tags dans les signatures avec les infos de l'admin
  yield fork(fillUserProperties)

  // mise à jour du contact dans la CRM en parralèle
  yield fork(updateCrmContactAsync, {
    payload: {
      properties: { ...action.payload.properties, email: adminUser.Username },
    },
  })
  // mise à jour du contact dans la Mailing list en parralèle
  yield fork(updateMailingListContactAsync, {
    payload: {
      properties: { ...action.payload.properties, email: adminUser.Username },
    },
  })

  yield put(initSignatureFormAction())

  yield put(hideGlobalLoader())
  // yield call(navigateTo, action.redirect)
  yield put(shouldRedirect(true))
}

function* fetchAdminAccountsAsync() {
  const result: TypedResult<AdminAccountDto[]> = yield call(
    fetchAdminsAccountsApi,
  )
  if (!result.success) throw new Error(result.errorMessage)

  const adminAccounts = result.result
    .map((adminAccount) => new AdminAccount(adminAccount))
    .filter((adminAccount) => !adminAccount.isBmmAdmin().isActive)

  yield put(fetchAdminAccountsSuccess(adminAccounts))
}

function* createAdminAccountFailure(errorMessage: string) {
  const parsedErrorMessage = JSON.parse(errorMessage)

  const duplicateEmail = parsedErrorMessage?.errors?.find(
    (error) => error.code === "DuplicateEmail",
  )

  const invalidEmail = parsedErrorMessage?.errors?.find(
    (error) => error.code === "InvalidEmail",
  )

  if (duplicateEmail) {
    yield put(createAdminAccountFailureAction(duplicateEmail.description))
    yield put(
      addNotification(new Notification(duplicateEmail.description, "WARNING")),
    )
    return
  }

  if (invalidEmail) {
    yield put(createAdminAccountFailureAction(invalidEmail.description))
    yield put(
      addNotification(new Notification(invalidEmail.description, "WARNING")),
    )
    return
  }

  if (parsedErrorMessage?.errors?.length > 0) {
    yield put(
      createAdminAccountFailureAction(parsedErrorMessage.errors[0].description),
    )
    yield put(
      addNotification(
        new Notification(parsedErrorMessage.errors[0].description, "WARNING"),
      ),
    )
    return
  }

  if (parsedErrorMessage?.message)
    yield put(createAdminAccountFailureAction(parsedErrorMessage.message))
}

function* createAdminAccountAsync(action: PayloadAction<AdminAccount>) {
  const result = yield call(createAdminAccountApi, action.payload)

  if (!result.success) {
    yield call(createAdminAccountFailure, result.errorMessage)
  } else {
    const newAdminAccount = new AdminAccount(result.result)

    yield put(createAdminAccountSuccess(newAdminAccount))
    yield put(
      addNotification(
        new Notification(
          `Administrateur ${newAdminAccount.userName} ajouté avec succès`,
          "INFO",
        ),
      ),
    )
  }
}

function* createSubsidiaryAdminAccountAsync(
  action: PayloadAction<{
    adminAccount: DataGridAdminAccount
    subsidiariesIds: number[]
  }>,
) {
  const { adminAccount, subsidiariesIds } = action.payload

  const result = yield call(createAdminAccountApi, adminAccount)

  if (!result.success) {
    yield call(createAdminAccountFailure, result.errorMessage)
  } else {
    const newAdminAccount = new AdminAccount(result.result)

    yield put(createAdminAccountSuccess(newAdminAccount))

    const allSubsidiaries = yield select(selectAllSubsidiaries)

    const relatedSubsidiaries = allSubsidiaries.filter((s) =>
      subsidiariesIds.includes(s.id),
    )

    yield all(
      relatedSubsidiaries.map((s) =>
        put(
          updateSubsidiary({
            ...s,
            aspNetUserIds: [...s.aspNetUserIds, newAdminAccount.id],
          }),
        ),
      ),
    )

    yield put(
      addNotification(
        new Notification(
          `Administrateur ${newAdminAccount.userName} ajouté avec succès`,
          "INFO",
        ),
      ),
    )
  }
}

function* updateAdminAccountRolesAsync(action: PayloadAction<AdminAccount>) {
  const result = yield call(updateAdminAccountRolesApi, action.payload)

  if (!result.success) throw new Error(result.errorMessage)

  yield put(updateAdminAccountRolesSuccess(action.payload))
}

function* deleteAdminAccountAsync(
  action: PayloadAction<{ userName: string; id: string }>,
) {
  const result = yield call(deleteAdminAccountApi, action.payload.userName)

  if (!result.success) throw new Error(result.errorMessage)

  yield put(deleteAdminAccountSuccess(action.payload.id))
  yield put(
    addNotification(
      new Notification(
        `Administrateur ${action.payload.userName} supprimé avec succès`,
        "INFO",
      ),
    ),
  )
}

function* authentificationFlow() {
  if (config.maintenanceMode === "true") return

  // 1- attente du chargement des langues
  yield take(initLanguagesSuccess.type)
  // 2- Verification de l'existence d'une session
  let currentSession = yield call(getCurrentSession)

  // 3- Si la session n'existe pas, on lance la routine de Login et on attend le résultat
  if (currentSession == null) {
    yield fork(loginWatcher)
    // retrait du loader
    yield put(hideGlobalLoader())
    const loginSuccess = yield take(loginSuccessAction.type)
    currentSession = loginSuccess.payload
    // affichage du loader
    yield put(showGlobalLoader())
  }

  // 4. Mise en state de la session courante
  yield put(fetchCurrentAccountSuccess(currentSession))

  // 5- Connexion au hub pour être notifié lorsque le tenant est créé
  yield put({ type: JobsActionsTypes.CONNECT_TO_HUB })

  // 6- tenant & offre
  const tenantStatus = yield call(manageTenantState)

  if (tenantStatus) {
    const offerStatus = yield call(fetchCurrentTenantOffer)

    // Charger des données si l'offre est valide
    if (offerStatus) {
      // 7- Chargement des features pour le tenant ou l'utilisateur courant
      yield put(fetchFeatures())
      yield put(loadInitialDatasAction())
    }
  }
  // retrait du loader dans tous les cas
  yield put(hideGlobalLoader())
}

function* loginWatcher() {
  yield put(loading(false))
  yield takeEvery(loginAction.type, login)
}

function* login(
  action: PayloadAction<{
    email: string
    password: string
    rememberMe: boolean
  }>,
) {
  yield put(loading(true))
  const loginResult = yield call(
    loginApi,
    action.payload.email,
    action.payload.password,
    action.payload.rememberMe,
  )

  if (loginResult.httpStatus === 403) {
    const message = "Identifiant ou mot de passe incorrect"

    yield put(addNotification(new Notification(message, "ERROR")))
    yield put(loginFailure(message))
  } else {
    if (!loginResult.success)
      throw new Error(`Erreur lors du login : ${loginResult.errorMessage}`)

    const account = new Account(loginResult.result)
    yield put(loginSuccessAction(account))
  }
}

function* logout() {
  yield call(logoutApi)
  yield put(logoutSuccess())
  location.reload()
}

/**
 * Récupère l'offre courante du tenant
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchCurrentTenantOffer() {
  const result = yield call(fetchCurrentTenantOfferApi)

  if (result.success) {
    const tenantOffer = new TenantOffer(result.result)

    const isValidOffer = DateHelper.IsNotExpired(
      tenantOffer.StartDate,
      tenantOffer.EndDate,
    )

    // chargement des données nécessaires au tunnel d'abonnement
    yield put(fetchCurrentTenantOfferSuccess(tenantOffer))
    yield put(fetchAllOffers())

    return isValidOffer
  }
}

/**
 * Retourne la session courante
 */
function* getCurrentSession() {
  const fetchSessionResult = yield call(fetchMe)
  if (fetchSessionResult.result == null || fetchSessionResult.result === "")
    return null
  return new Account(fetchSessionResult.result)
}

/**
 * Retourne l'état du tenant (créé ou non)
 */
function* manageTenantState() {
  const tenantInformationsResult = yield call(fetchCurrentTenant)

  if (!tenantInformationsResult.success)
    throw new Error(
      `Erreur lors de la récupération du tenant : ${tenantInformationsResult.errorMessage}`,
    )

  let currentTenant: Tenant = new Tenant(tenantInformationsResult.result)

  //  Si le tenant n'est pas existant, on lance sa création
  if (!currentTenant.Processed) {
    // Lancement de la creation de l'environnement
    yield put(fetchCurrentAccountPending())
    yield put(hideGlobalLoader())
    // Création du tenant au travers du job
    // attente de la création du tenant pour mettre en state l'Id via le job
    yield take(tenantCreatedAction.type)
    currentTenant = new Tenant(
      yield select(accountsSelectors.getTenantInformations),
    )
  }

  yield put(fetchCurrentAccountReady())
  yield put(fetchTenantInfosSuccess(currentTenant))
  return true
}

// TODO : Methode a supprimer quand on pourra envoyer des payload partiels
function* getCurrentAccountInformations() {
  const currentAccount: Account | null = yield select(
    accountsSelectors.getCurrentAccount,
  )

  const currentAccountInformations = {
    company: currentAccount.Company,
    employeesNumber: currentAccount.EmployeesNumber,
    firstname: currentAccount.Firstname,
    lastname: currentAccount.Lastname,
    phoneNumber: currentAccount.PhoneNumber,
    function: currentAccount.Function,
    cgs: false,
  }

  return currentAccountInformations
}

function* updateAccountInformationsAsync(
  action: PayloadAction<AccountInformations>,
) {
  const { stepRegister } = action.payload

  const currentAccountInformations = yield call(getCurrentAccountInformations)

  const utm = yield select(marketingSelectors.getAllUTMs)

  const registeredUtm = stepRegister === 1 ? utm : undefined

  const result = yield call(fillAccountInformations, {
    ...currentAccountInformations,
    ...action.payload,
    ...registeredUtm,
  })

  if (!result.success) throw new Error(result.errorMessage)

  const account = new Account(result.result)

  yield put(updateAccountInformationsSuccess(account))
}

function* finishAccountRegistrationAsync(
  action: PayloadAction<AccountInformations>,
) {
  const currentAccountInformations = yield call(getCurrentAccountInformations)

  const result = yield call(fillAccountInformations, {
    ...currentAccountInformations,
    ...action.payload,
  })

  if (!result.success) throw new Error(result.errorMessage)

  const account = new Account(result.result)

  yield put(updateAccountInformationsSuccess(account))

  const currentTenant = yield select(accountsSelectors.getTenantInformations)

  if (!currentTenant?.Processed) {
    yield put(showGlobalLoader())
    // Création du tenant au travers du job
    const result = yield call(createCurrentTenant)

    if (!result.success) throw new Error(result.errorMessage)

    const tenantInformationsResult = yield call(fetchCurrentTenant)

    if (!tenantInformationsResult.success)
      throw new Error(
        `Erreur lors de la récupération du tenant : ${tenantInformationsResult.errorMessage}`,
      )

    const createdTenant: Tenant = new Tenant(tenantInformationsResult.result)

    yield put(fetchTenantInfosSuccess(createdTenant))
  }
}

function* createAccount(
  action: PayloadAction<{ email: string; password: string }>,
) {
  const { email, password } = action.payload

  const captcha = yield select(accountsSelectors.getCaptcha)

  const utms = yield select(marketingSelectors.getAllUTMs)

  const registerResponse = yield call(
    registerAccountApi,
    email,
    password,
    captcha,
    utms,
  )

  if (!registerResponse.success) {
    const parsedErrorMessage = JSON.parse(registerResponse.errorMessage)

    yield put(resetCaptcha())
    yield all([
      put(createCurrentAccountFailure()),
      put(
        addNotification(
          new Notification(parsedErrorMessage.errorMessage, "ERROR"),
        ),
      ),
    ])
  } else {
    yield all([put(createCurrentAccountSuccess()), put(resetUTM())])
  }
}

/**
 * Récupère le lien des clients lourds
 * Ajout MAC en dur pour le moment
 */
function* fetchAccountLinks() {
  try {
    const result = yield call(fetchTenantPropertiesApi)

    // QuickFix
    const findProperty = (propertyName) => {
      const property = result.result.find((e) => e.property == propertyName)
      if (property != null) return property.value
      return null
    }

    const links = {
      msiLink: findProperty("addinmsilink"),
      macLink: config.macOsDownloadLink, // Pour l'instant, on ne gère qu'un client unique MacOS
      exportUserLink: findProperty("addinexportuserlink"),
    }

    const versionsResult = yield call(getWindowsVersions)

    if (result.result == null) {
      yield put(fettchAccountLinksFailure())
      return
    }

    if (versionsResult.success) {
      const versions = versionsResult.result.map(
        (versionJson) => new Version(versionJson),
      )
      yield put(fetchWindowsVersionsSuccess(versions))
    }

    yield put(
      fetchAccountLinksSuccess({
        macLink: links.macLink,
        powershellLink: links.exportUserLink,
      }),
    )
  } catch (error) {
    yield put(fettchAccountLinksFailure())
  }
}

/**
 * Récupère les propriétés du tenant
 */
function* fetchTenantProperties() {
  const result = yield call(fetchTenantPropertiesApi)

  if (!result.success) throw new Error(result.ErrorMessage)

  if (result.result?.IsExpired) throw new Error("Offre expirée")

  const propertiesJson = result.result
  const properties = propertiesJson.map((propertyJson) => {
    return TenantProperty.fromJson(propertyJson)
  })

  const tenantProperties: TenantProperties = {
    googleanalytics: properties.find(
      (property) => property.Property === "googleanalytics",
    ),
    companyname: properties.find(
      (property) => property.Property === "companyname",
    ),
    business: properties.find((property) => property.Property === "business"),
    employeesnumber: properties.find(
      (property) => property.Property === "employeesnumber",
    ),
    messaging: properties.find((property) => property.Property === "messaging"),
    customMsiLink: properties.find(
      (property) =>
        property.Property === TENANT_PROPERTY_NAMES.CUSTOM_MSI_LINK_NAME,
    ),
    campaignUnlockedProperty: properties.find(
      (property) => property.Property === "campaignUnlockedProperty",
    ),
    trackingJobCreated: properties.find(
      (property) =>
        property.Property === TENANT_PROPERTY_NAMES.TRACKING_JOB_CREATED,
    ),
    onBoarding: properties.find(
      (property) => property.Property === TENANT_PROPERTY_NAMES.ON_BOARDING,
    ),
    syncgsuitedomain: properties.find(
      (property) =>
        property.Property === TENANT_PROPERTY_NAMES.SYNC_GSUITE_DOMAIN,
    ),
    syncgsuiteadminmail: properties.find(
      (property) =>
        property.Property === TENANT_PROPERTY_NAMES.SYNC_GSUITE_ADMIN_MAIL,
    ),
    gsuitejson: properties.find(
      (property) =>
        property.Property === TENANT_PROPERTY_NAMES.SYNC_GSUITE_JSON,
    ),
    owaaccounts: properties.find(
      (property) => property.Property === TENANT_PROPERTY_NAMES.OWA_ACCOUNTS,
    ),
    owaendpointurl: properties.find(
      (property) =>
        property.Property === TENANT_PROPERTY_NAMES.OWA_ENDPOINT_URL,
    ),
  }

  yield put(fetchTenantPropertiesSuccess(tenantProperties))
  return
}

function* createTenantPropertyAsync(action: PayloadAction<TenantProperty>) {
  const result = yield call(createTenantPropertyApi, action.payload)

  if (!result.success) throw new Error(result.errorMessage)

  const property = TenantProperty.fromJson(result.result)

  yield put(updateTenantPropertiesSuccess(property))
}

function* updateTenantPropertyAsync(action: PayloadAction<TenantProperty>) {
  const result = yield call(updateTenantPropertyApi, action.payload)

  if (!result.success) throw new Error(result.errorMessage)

  const property = TenantProperty.fromJson(result.result)

  yield put(updateTenantPropertiesSuccess(property))
}

/**
 * Réinitialise le mot de passe d'un utilisateur ASPNET
 */
function* resetPassword(
  action: PayloadAction<{
    params: { userId: string; code: string; isConfirmUser: boolean }
    values: { password: string; confirmPassword: string }
  }>,
) {
  const resetPasswordData = Object.assign(
    {},
    {
      ...action.payload.params,
      password: action.payload.values.password,
    },
  )
  const resetPasswordJson = {}
  for (const prop in resetPasswordData) {
    resetPasswordJson[StringHelper.Capitalize(prop)] = resetPasswordData[prop]
  }

  const result = yield call(resetPasswordApi, resetPasswordJson)

  if (!result.success) {
    const error = JSON.parse(result.errorMessage)
    yield put(
      resetPasswordFailure([
        {
          code: "500",
          description: error.errorMessage,
        },
      ]),
    )
  }

  if (result.result !== null) {
    yield put(resetPasswordSuccess(result.result))
    const message = resetPasswordData.isConfirmUser
      ? "Votre compte a été confirmé."
      : "Votre mot de passe a été réinitialisé."
    yield put(addNotification(new Notification(message, "SUCCESS")))
  }
}

/**
 * Génère une demande de réinitialisation de mot de passe d'un utilisateur ASPNET
 */
function* requestResetPassword(action: PayloadAction<string>) {
  try {
    const result = yield call(requestResetPasswordApi, action.payload)
    if (result.result !== null) {
      yield put(requestResetPasswordSuccessAction())
      yield put(
        addNotification(
          new Notification(
            "Un email a été envoyé afin de réinitialiser le mot de passe.",
            "SUCCESS",
          ),
        ),
      )
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(
        addNotification(
          new Notification(
            error?.response?.data || "Ressource non trouvée",
            "ERROR",
          ),
        ),
      )
    } else {
      yield put(requestResetPasswordFailureAction())
    }
  }
}

export function* createOrUpdateProperty(property: TenantProperty) {
  const relatedProperty = yield select(
    accountsSelectors.getTenantProperty(property.Property),
  )

  if (relatedProperty) return yield call(updateTenantPropertyApi, property)

  return yield call(createTenantPropertyApi, property)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createTenantFeatureProperties(action: PayloadAction<Feature>) {
  if (action.payload.properties == null) return

  const results = yield all(
    action.payload.properties.map((property) =>
      call(
        createOrUpdateProperty,
        new TenantProperty(property.property, property.value),
      ),
    ),
  )

  let errors = ""
  results.forEach((result) => {
    if (!result.success) errors += result.errorMessage + " "
  })
  if (errors) throw new Error(errors)

  yield put(updateTenantFeaturePropertiesSuccessAction())
  yield put(fetchTenantFeaturesAction())
  yield put(
    addNotification(
      new Notification(
        "Les propriétés de la fonctionnalité ont été mises à jour.",
        "SUCCESS",
      ),
    ),
  )
}

/**
 * Utilisation du compte de service BMM pour gérer les utilisateurs du tenant client
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateTenantFeaturePropertiesFromBmm() {
  const result = yield call(updateGoogleTenantProperty)

  if (!result.success) throw new Error(result.errorMessage)

  yield put(updateTenantFeaturePropertiesFromBmmSuccessAction())
}

function* fetchGoogleWorkspaceDomains(action: PayloadAction<string>) {
  try {
    const result = yield call(fetchGoogleWorkspaceDomainsApi, action.payload)

    if (!result.success) throw new Error(result.errorMessage)

    if (result.result.serviceFaulted === true || result.result.logs.length > 0)
      throw new Error(`Retour GSuite: ${result.result.logs.join("\n")} `)

    const domainList = result.result.domains.map((d) => d.domainName)

    yield put(fetchGWorkspaceDomainsSuccess(domainList))
  } catch (error) {
    yield put(fetchGWorkspaceDomainsFailureAction())
  }
}

function* waitForTenantProperties() {
  const tenantPropertiesLoaded = yield select(
    accountsSelectors.getTenantPropertiesLoadingStatus,
  )

  if (tenantPropertiesLoaded !== LoadingStatus.LOADED) {
    yield put(fetchTenantPropertiesAction)
    yield take(fetchTenantPropertiesSuccess.type)
  }
}

function* initOnboarding() {
  const onBoardingPropertyExists = yield select(
    accountsSelectors.getTenantProperty(TENANT_PROPERTY_NAMES.ON_BOARDING),
  )

  if (onBoardingPropertyExists !== null) {
    yield put(setOnBoardingData(JSON.parse(onBoardingPropertyExists)))
    return
  }

  const onBoardingProperty = new TenantProperty(
    TENANT_PROPERTY_NAMES.ON_BOARDING,
    JSON.stringify(DEFAULT_ON_BOARDING_ITEMS),
  )
  yield put(createTenantPropertyAction(onBoardingProperty))
}

export function* runApiCall<T>(
  onBoardingKey: OnBoardingKey,
  requestFn: (args: unknown) => Promise<TypedResult<T>>,
  onBoardingRule: (
    result: unknown[],
    currentState: OnBoardingItems,
    onBoardingKey: OnBoardingKey,
  ) => OnBoardingItem,
  params?: RequestParams,
) {
  const response = yield call(requestFn, params)

  if (!response.success) return { onBoardingKey, onBoardingRule, result: [] }

  return { onBoardingKey, onBoardingRule, result: response.result }
}

export function* checkOnBoardingSteps() {
  const requests = [
    {
      onBoardingKey: "addUsers",
      request: fetchUsers,
      params: requestParamsBuilder(AT_LEAST_ONE_ACTIVE_USER_QUERY),
      onBoardingRule: commonOnBoardingRule,
    },
    {
      onBoardingKey: "firstSignature",
      request: fetchSignatures,
      params: requestParamsBuilder(AT_LEAST_ONE_ACTIVE_SIGNATURE_QUERY),
      onBoardingRule: commonOnBoardingRule,
    },
    {
      onBoardingKey: "firstCampaign",
      request: fetchScenarios,
      params: requestParamsBuilder(AT_LEAST_ONE_ACTIVE_BANNER_QUERY),
      onBoardingRule: commonOnBoardingRule,
    },
    {
      onBoardingKey: "assignedSignature",
      request: fetchSignatures,
      params: requestParamsBuilder(
        AT_LEAST_ONE_USER_ASSIGNED_TO_SIGNATURE_QUERY,
      ),
      onBoardingRule: commonOnBoardingRule,
    },
    {
      onBoardingKey: "syncedSignature",
      request: fetchSignaturesDeliveredApi,
      onBoardingRule: syncedSignatureOnBoardingRule,
    },
  ]

  const onBoardingStepsResults = yield all(
    requests.map((r) =>
      runApiCall(
        r.onBoardingKey as OnBoardingKey,
        r.request,
        r.onBoardingRule,
        r?.params,
      ),
    ),
  )

  const currentOnBoardingItems = yield select(
    accountsSelectors.getOnBoardingItems,
  )

  const updatedOboardingItems = onBoardingRulesValidation(
    onBoardingStepsResults,
    currentOnBoardingItems,
  )

  yield put(setOnBoardingData(updatedOboardingItems))

  const onBoardingProperty = new TenantProperty(
    TENANT_PROPERTY_NAMES.ON_BOARDING,
    JSON.stringify(updatedOboardingItems),
  )
  yield put(updateTenantPropertyAction(onBoardingProperty))
}

export function* checkOnBoardingState() {
  const isTrial = yield select(offersSelectors.isTrial)
  const onBoardingCompletedDate = yield select(
    accountsSelectors.getLastOnBoardingEventDate,
  )

  return onBoardingInProgress(isTrial, onBoardingCompletedDate)
}

export function* initOnBoardingOrchestrator() {
  yield put(setOnBoarding())
  yield call(waitForTenantProperties)

  yield call(initOnboarding)

  const inProgress = yield call(checkOnBoardingState)

  if (inProgress) yield call(checkOnBoardingSteps)

  yield put(finishOnBoarding())
  return
}

export function* updateOnBoardingOrchestrator() {
  const inProgress = yield call(checkOnBoardingState)

  if (inProgress) yield call(checkOnBoardingSteps)
}

export function* fetchSignaturesTileDatasAsync() {
  yield put(fetchSignaturesTileStart())

  const response: TypedResult<SignatureDto[]> = yield call(
    fetchSignatures,
    requestParamsBuilder(ALL_SIGNATURES_TILE_DATAS_QUERY),
  )

  if (!response.success) throw new Error(response.errorMessage)

  const { result } = response

  const activatedOnly = result.filter((r) => r.activated)

  const count = {
    all: result.length,
    active: activatedOnly.length,
    newMail: activatedOnly.filter((r) => r.newMail).length,
    inResponse: activatedOnly.filter((r) => r.inResponse).length,
  }

  yield put(fetchSignaturesTileSuccess(count))
}

export function* fetchCampaignsTileDatasAsync() {
  yield put(fetchCampaignsTileStart())

  const response: TypedResult<ScenarioCustomQueryDto[]> = yield call(
    fetchScenarios,
    requestParamsBuilder(ALL_ACTIVES_CURRENT_CAMPAIGNS_QUERY),
  )

  if (!response.success) throw new Error(response.errorMessage)

  const { result } = response

  const today = new Date()

  // TODO : en attente de la possibilité de requêter sur les dates null
  const activeRangeScenarios = result.filter(
    (s) =>
      s.activeRange.isInfinite ||
      moment(today).isBetween(s.activeRange.startDate, s.activeRange.endDate),
  )

  const count = {
    all: activeRangeScenarios.length,
    campaigns: activeRangeScenarios.filter(
      (s) => s.assignedBannersScenario.length < 2,
    ).length,
    scenarios: activeRangeScenarios.filter(
      (s) => s.assignedBannersScenario.length > 1,
    ).length,
  }

  yield put(fetchCampaignsTileSuccess(count))
}

export function* fetchTrackingTileDatasAsync() {
  yield put(fetchTrackingTileStart())

  const from = moment()
    .subtract(
      TRACKING_TILE_PERIOD_RANGE.amount as moment.DurationInputArg1,
      TRACKING_TILE_PERIOD_RANGE.unit as moment.unitOfTime.DurationConstructor,
    )
    .format("YYYY-MM-DD")
  const to = moment().format("YYYY-MM-DD")

  const yesterday = moment()
    .subtract(
      TRACKING_TILE_DELIVERED_PERIOD.amount as moment.DurationInputArg1,
      TRACKING_TILE_DELIVERED_PERIOD.unit as moment.unitOfTime.DurationConstructor,
    )
    .format("YYYY-MM-DD")

  const requestParams = { from, to }

  const [signaturesResponse, bannersResponse, deliveredResponse] = yield all([
    call(fetchSignaturesClickedApi, requestParams),
    call(fetchBannersClickedApi, requestParams),
    call(fetchSignaturesHistoryApi, { from: yesterday, to: yesterday }),
  ])

  if (!signaturesResponse.success)
    throw new Error(signaturesResponse.errorMessage)
  if (!bannersResponse.success) throw new Error(bannersResponse.errorMessage)
  if (!deliveredResponse.success) throw new Error(bannersResponse.errorMessage)

  const signaturesHistoryEvents: SignaturesHistoryDto[] =
    deliveredResponse.result.map((event) => ({
      ...event,
      created: moment(event.created).format("YYYY-MM-DD"),
    }))

  const assignedUsersSum = signaturesHistoryEvents.reduce(
    (acc, curr) => acc + curr.users.length,
    0,
  )
  const deliveredUsersSum = signaturesHistoryEvents.reduce(
    (acc, curr) => acc + curr.users.filter((u) => u.delivered === true).length,
    0,
  )

  const deliveredPercent =
    assignedUsersSum === 0 ? 0 : (deliveredUsersSum * 100) / assignedUsersSum

  const count = {
    delivered: Math.round(deliveredPercent),
    signaturesClicked: signaturesResponse.result.reduce(
      (acc: number, curr: SignaturesClickedDto) => acc + curr.nbClicks,
      0,
    ),
    bannersClicked: bannersResponse.result.reduce(
      (acc: number, curr: BannersClickedDto) => acc + curr.nbClicks,
      0,
    ),
  }

  yield put(fetchTrackingTileSuccess(count))
}

export function* fetchUsersTileDatasAsync() {
  yield put(fetchUsersTileStart())

  const [usersCountResponse, groupsCountResponse, latestUsersResponse] =
    yield all([
      call(fetchUsers, requestParamsBuilder(ALL_ACTIVE_USERS_COUNT_QUERY)),
      call(fetchGroupsApi, requestParamsBuilder(ALL_ACTIVE_GROUPS_COUNT_QUERY)),
      call(fetchUsers, requestParamsBuilder(LATEST_TEN_USERS_QUERY)),
    ])

  if (!usersCountResponse.success)
    throw new Error(usersCountResponse.errorMessage)
  if (!groupsCountResponse.success)
    throw new Error(groupsCountResponse.errorMessage)
  if (!latestUsersResponse.success)
    throw new Error(latestUsersResponse.errorMessage)

  const currentOffer = yield call(getOrLoadCurrentOffer)

  const count = {
    users: usersCountResponse.result.length,
    groups: groupsCountResponse.result.length,
    licences: currentOffer?.MaxLicences || 0,
  }

  // TODO : A voir, le payload obtenu ne respecte pas la UserDTO
  const latest = latestUsersResponse.result.map((user) => {
    const firstName = user?.properties.find(
      (p) => p.property.internalName === "firstname",
    )?.value

    const lastName = user?.properties.find(
      (p) => p.property.internalName === "lastname",
    )?.value

    if (firstName && lastName) return `${firstName} ${lastName}`

    return user.username
  })

  yield put(fetchUsersTileSuccess({ count, latest }))
}

export function* homePageOrchestrator() {
  yield all([
    call(fetchSignaturesTileDatasAsync),
    call(fetchCampaignsTileDatasAsync),
    call(fetchTrackingTileDatasAsync),
    call(fetchUsersTileDatasAsync),
  ])
}

export function* homePageStartupAsync() {
  yield all([call(waitForTenantProperties), call(getOrLoadFeaturesAsync)])

  yield put(homePageStartupSuccess())
}

export function* addAdminAccountToSubsidiaryAsync(
  action: PayloadAction<{ adminId: string; subsidiariesIds: number[] }>,
) {
  const { adminId, subsidiariesIds } = action.payload

  const allSubsidiaries: Subsidiary[] = yield select(selectAllSubsidiaries)

  const updatedSubsidiaries: Subsidiary[] = allSubsidiaries
    .filter((s) => subsidiariesIds.includes(s.id))
    .map((s) => ({ ...s, aspNetUserIds: [...s.aspNetUserIds, adminId] }))

  const mapRequestFromSubsidiary = (subsidiary): UpdateSubsidiaryRequest => ({
    id: subsidiary.id,
    name: subsidiary.name,
    aspNetUserIds: subsidiary.aspNetUserIds,
    groupIds: subsidiary.groupIds,
    userIds: subsidiary.userIds,
    iconUrl: subsidiary.iconUrl,
  })

  const responses = yield all(
    updatedSubsidiaries.map((s) =>
      call(updateSubsidiaryApi, mapRequestFromSubsidiary(s)),
    ),
  )

  const responsesSuccess: Subsidiary[] = []
  const responsesFailed = []

  responses.map((r) => {
    if (r.success) {
      responsesSuccess.push(r.result)
      return
    }
    responsesFailed.push(r.result)
  })

  yield all(responsesSuccess.map((s) => put(updateSubsidiarySuccess(s))))

  const adminAccount = yield select(
    accountsSelectors.getAdminAccountById(adminId),
  )

  if (responsesSuccess.length > 0) {
    const messageSuccess =
      responsesSuccess.length > 1
        ? `L'administrateur ${adminAccount.userName} est basculé sur ${responsesSuccess.length} filiales`
        : `L'administrateur ${adminAccount.userName} est basculé sur la filiale ${responsesSuccess[0].name}`
    yield put(addNotification(new Notification(messageSuccess, "SUCCESS")))
  }

  if (responsesFailed.length > 0) {
    const messagesFailed =
      responsesFailed.length > 1
        ? `Le basculement de l'administrateur ${adminAccount.userName} sur ${responsesFailed.length} filiales a échoué`
        : `Le basculement de l'administrateur ${adminAccount.userName} sur la filiale ${responsesFailed[0].name} a échoué`

    yield put(addNotification(new Notification(messagesFailed, "ERROR")))
  }
}

export function* removeAdminAccountToSubsidiaryAsync(
  action: PayloadAction<string>,
) {
  const allSubsidiaries: Subsidiary[] = yield select(selectAllSubsidiaries)

  const updatedSubsidiaries: Subsidiary[] = allSubsidiaries
    .filter((s) => s.aspNetUserIds.includes(action.payload))
    .map((s) => ({
      ...s,
      aspNetUserIds: s.aspNetUserIds.filter((a) => a !== action.payload),
    }))

  const mapRequestFromSubsidiary = (subsidiary): UpdateSubsidiaryRequest => ({
    id: subsidiary.id,
    name: subsidiary.name,
    aspNetUserIds: subsidiary.aspNetUserIds,
    groupIds: subsidiary.groupIds,
    userIds: subsidiary.userIds,
    iconUrl: subsidiary.iconUrl,
  })

  const responses = yield all(
    updatedSubsidiaries.map((s) =>
      call(updateSubsidiaryApi, mapRequestFromSubsidiary(s)),
    ),
  )

  const responsesSuccess: Subsidiary[] = []
  const responsesFailed = []

  responses.map((r) => {
    if (r.success) {
      responsesSuccess.push(r.result)
      return
    }
    responsesFailed.push(r.result)
  })

  yield all(responsesSuccess.map((s) => put(updateSubsidiarySuccess(s))))

  const adminAccount = yield select(
    accountsSelectors.getAdminAccountById(action.payload),
  )

  if (responsesSuccess.length > 0) {
    const messageSuccess =
      responsesSuccess.length > 1
        ? `L'administrateur ${adminAccount.userName} est retiré de ${responsesSuccess.length} filiales`
        : `L'administrateur ${adminAccount.userName} est retiré de la filiale ${responsesSuccess[0].name}`
    yield put(addNotification(new Notification(messageSuccess, "SUCCESS")))
  }

  if (responsesFailed.length > 0) {
    const messagesFailed =
      responsesFailed.length > 1
        ? `Le retrait de l'administrateur ${adminAccount.userName} de ${responsesFailed.length} filiales a échoué`
        : `Le retrait de l'administrateur ${adminAccount.userName} de la filiale ${responsesFailed[0].name} a échoué`

    yield put(addNotification(new Notification(messagesFailed, "ERROR")))
  }
}

export const accountsWatchers = [
  takeEvery(addAdminUserAction.type, addAdminUser),
  takeLatest(fetchAdminAccounts.type, fetchAdminAccountsAsync),
  takeEvery(createAdminAccount.type, createAdminAccountAsync),
  takeEvery(updateAdminAccountRoles.type, updateAdminAccountRolesAsync),
  takeEvery(deleteAdminAccountAction.type, deleteAdminAccountAsync),
  takeLatest(fetchCurrentAccountAction.type, authentificationFlow),
  takeEvery(logoutAction.type, logout),
  takeEvery(createCurrentAccount.type, createAccount),
  takeEvery(fetchAccountsLinksAction.type, fetchAccountLinks),
  takeEvery(
    [
      fetchTenantPropertiesAction.type,
      updateTenantFeaturePropertiesSuccessAction.type,
      updateTenantFeatureSuccess.type,
    ],
    withSagaErrorHandler(fetchTenantProperties),
  ),
  takeEvery(
    createTenantPropertyAction.type,
    withSagaErrorHandler(createTenantPropertyAsync),
  ),
  takeEvery(
    updateTenantPropertyAction.type,
    withSagaErrorHandler(updateTenantPropertyAsync),
  ),
  takeEvery(resetPasswordAction.type, resetPassword),
  takeEvery(requestResetPasswordAction.type, requestResetPassword),
  takeEvery(
    createTenantFeaturePropertiesAction.type,
    createTenantFeatureProperties,
  ),
  takeEvery(
    updateTenantPropertiesFromBmmAction.type,
    withSagaErrorHandler(updateTenantFeaturePropertiesFromBmm),
  ),
  takeEvery(fetchGWorkspaceDomainsAction.type, fetchGoogleWorkspaceDomains),
  takeLatest(updateAccountInformations.type, updateAccountInformationsAsync),
  takeLatest(
    finishAccountRegistrationAction.type,
    finishAccountRegistrationAsync,
  ),
  takeLatest(
    [
      createSignatureSuccess.type,
      createBuilderSignatureSuccess.type,
      updateBuilderSignatureSuccess.type,
      assignUsersSuccess.type,
      assignGroupsSuccess.type,
      deleteBuilderSignatureSuccess.type,
      deleteSignatureSuccess.type,
      createScenarioSuccess.type,
      deleteScenarioSuccess.type,
    ],
    withSagaErrorHandler(updateOnBoardingOrchestrator),
  ),
  takeLatest(homePageStartup.type, withSagaErrorHandler(homePageStartupAsync)),
  takeLatest(homePageStartup.type, withSagaErrorHandler(homePageOrchestrator)),
  takeEvery(
    [
      activateSignatureSuccess.type,
      removeSendingModeFromSignatureSuccess.type,
      createSignatureSuccess.type,
      updateSignatureSuccess.type,
      deleteSignatureSuccess.type,
      createBuilderSignatureSuccess.type,
      updateBuilderSignatureSuccess.type,
      deleteBuilderSignatureSuccess.type,
      activateBuilderSignatureSuccess.type,
    ],
    withSagaErrorHandler(fetchSignaturesTileDatasAsync),
  ),
  takeEvery(
    [
      createScenarioSuccess.type,
      updateScenarioSuccess.type,
      deleteScenarioSuccess.type,
      duplicateScenarioSuccess.type,
      activateScenarioSuccessAction.type,
    ],
    withSagaErrorHandler(fetchCampaignsTileDatasAsync),
  ),
  takeEvery(
    [
      upsertUserSuccess.type,
      deleteUserSuccess.type,
      updateUserLicenceSuccess.type,
    ],
    withSagaErrorHandler(fetchUsersTileDatasAsync),
  ),
  takeLatest(
    addAdminAccountToSubsidiariesAction.type,
    addAdminAccountToSubsidiaryAsync,
  ),
  takeLatest(
    createSubsidiaryAdminAccount.type,
    createSubsidiaryAdminAccountAsync,
  ),
  takeLatest(
    removeAdminAccountToSubsidiariesAction.type,
    removeAdminAccountToSubsidiaryAsync,
  ),
]
