import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { Link } from "react-router-dom"

import { ReactComponent as EditIcon } from "assets/icon-edit.svg"

import MouseTooltip from "components/MouseTooltip"

interface SignatureCardEditActionProps {
  onEdit: () => void
  editLink: string
  roundedCorner?: "top" | "bottom"
  disabled?: boolean
}

export type SignatureCardEditActionType =
  FunctionComponent<SignatureCardEditActionProps>

const messages = defineMessages({
  edit: {
    id: "SignatureCard.EditTooltip",
    defaultMessage: "Modifier la signature",
  },
})

const SignatureCardEditAction: FunctionComponent<
  SignatureCardEditActionProps
> = ({ onEdit, editLink, roundedCorner, disabled = false }) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Link
        className={classNames(
          "signature-card__btn",
          roundedCorner && `signature-card__rounder-corner--${roundedCorner}`,
          disabled && "is-disabled",
        )}
        data-cy="signature-card__editBtn"
        onClick={onEdit}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        to={disabled ? "#" : editLink}
      >
        <EditIcon className="signature-card__btn--icon" />
      </Link>
      <MouseTooltip visible={visible}>
        {formatMessage(messages.edit)}
      </MouseTooltip>
    </>
  )
}

export default SignatureCardEditAction
