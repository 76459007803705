import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import StringHelper from "utils/StringHelper"

import {
  FormItem,
  InputSize,
  InputType,
} from "components/GenericForm/GenericForm"

import { SignatureMapper } from "features/Signatures/mappers"

import { usersSelectors } from "features/Users"

import { Origin, User } from "features/Users/UserModels"

import Signature from "entities/Signature"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

import { featureNames } from "config/config.features"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

interface UseEditUserInitFormProps {
  currentUser: User | null
  currentUserId: number
  assignedSignatures: Array<Signature | BuilderSignature>
  defaultSignature: Signature | BuilderSignature
}

interface UseEditUserInitForm {
  initForm: FormItem[]
}

const messages = defineMessages({
  address: { id: "EditUserForm.Address", defaultMessage: "Adresse" },
  mail: { id: "EditUserForm.Email", defaultMessage: "E-mail *" },
  errorFormatMail: {
    id: "AccountCreation.ErrorFormatMail",
    defaultMessage: "Format d'e-mail invalide",
  },
  errorFormatUsername: {
    id: "AccountCreation.ErrorFormatUsername",
    defaultMessage: "Format dde nom d'utilisateur invalide",
  },
  errorUniqueMail: {
    id: "AccountCreation.ErrorUniqueMail",
    defaultMessage: "E-mail déjà utilisé",
  },
  errorUniqueUsername: {
    id: "AccountCreation.ErrorUniqueUsername",
    defaultMessage: "Ce nom d'utilisateur existe déjà",
  },
  mandatory: {
    id: "AdditionalInfos.Mandatory",
    defaultMessage: "Champ obligatoire",
  },
  lastName: { id: "EditUserForm.LastName", defaultMessage: "Nom" },
  firstName: { id: "EditUserForm.FirstName", defaultMessage: "Prénom" },
  username: { id: "EditUserForm.Username", defaultMessage: "Username" },
  jobTitle: { id: "EditUserForm.JobTitle", defaultMessage: "Fonction" },
  city: { id: "EditUserForm.City", defaultMessage: "Ville" },
  cp: { id: "EditUserForm.PostalCode", defaultMessage: "Code Postal" },
  phone: { id: "EditUserForm.Phone", defaultMessage: "Téléphone" },
  mobile: { id: "EditUserForm.Mobile", defaultMessage: "Mobile" },
  website: { id: "EditUserForm.WebSite", defaultMessage: "Site web" },
  selectDefaultSignature: {
    id: "EditUserForm.SelectDefaultSignature",
    defaultMessage: "Signature par défaut",
  },
  noDefaultSignature: {
    id: "EditUserForm.NoDefaultSignature",
    defaultMessage: "Aucune signature par défaut",
  },
  disclaimer: { id: "EditUserForm.Disclaimer", defaultMessage: "Disclaimer" },
  userMessage: {
    id: "EditUserForm.UserMessage",
    defaultMessage: "Message utilisateur",
  },
  photoCropTitle: {
    id: "EditUserForm.PhotoCropTitle",
    defaultMessage: "Ajustez votre photo",
  },
})

const useEditUserInitForm = ({
  currentUser,
  currentUserId,
  assignedSignatures,
  defaultSignature,
}: UseEditUserInitFormProps): UseEditUserInitForm => {
  const { formatMessage } = useIntl()

  const emailUsernameFeature = useFeatureTogglr(featureNames.EMAIL_USERNAME)

  const globalProperties = useSelector(usersSelectors.getUserProperties)
  const allUsers = useSelector(usersSelectors.getAllUsers)

  const isNew = currentUserId === -1

  const userOriginIsPortal =
    currentUser?.Origin === Origin.ORIGIN_PORTAL || isNew

  const properties = currentUser?.Properties

  const userAlreadyExists = (value) => {
    if (allUsers && value)
      return allUsers.some(
        (user) =>
          (isNew || user.Id !== currentUserId) &&
          (user.Properties.mail === value || user.Username === value),
      )
  }

  const propertyIsReadOnly = (name: string) => {
    const prop = globalProperties.find((p) => p.InternalName === name)
    if (prop) {
      return !userOriginIsPortal && prop.Origin !== Origin.ORIGIN_PORTAL
    }
    return false
  }

  const signatureOptions = assignedSignatures.map((signature) => {
    return SignatureMapper.getSignatureOption(signature)
  })
  signatureOptions.unshift({
    value: -1,
    label: formatMessage(messages.noDefaultSignature),
  })

  const legacyProperties = [
    {
      name: "picture",
      label: formatMessage(messages.photoCropTitle),
      type: InputType.avatar,
      size: InputSize.S12,
      value: properties && properties.picture,
      disabled: propertyIsReadOnly("picture"),
    },
    {
      name: "firstname",
      label: formatMessage(messages.firstName),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.firstname,
      disabled: propertyIsReadOnly("firstname"),
    },
    {
      name: "lastname",
      label: formatMessage(messages.lastName),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.lastname,
      disabled: propertyIsReadOnly("lastname"),
    },
    {
      name: "mail",
      label: formatMessage(messages.mail),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.mail,
      rules: {
        required: formatMessage(messages.mandatory),
        validate: {
          format: (value) =>
            StringHelper.validateEmail(value) ||
            formatMessage(messages.errorFormatMail),
          unique: (value) =>
            !userAlreadyExists(value) ||
            formatMessage(messages.errorUniqueMail),
        },
      },
      disabled: propertyIsReadOnly("mail"),
    },
    {
      name: "jobtitle",
      label: formatMessage(messages.jobTitle),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.jobtitle,
      disabled: propertyIsReadOnly("jobtitle"),
    },
    emailUsernameFeature?.isActive
      ? {
          name: "username",
          label: formatMessage(messages.username),
          type: InputType.text,
          size: InputSize.S12,
          value: currentUser?.Username,
          rules: {
            required: formatMessage(messages.mandatory),
            validate: {
              format: (value) =>
                StringHelper.validateEmail(value) ||
                formatMessage(messages.errorFormatUsername),
              unique: (value) =>
                !userAlreadyExists(value) ||
                formatMessage(messages.errorUniqueUsername),
            },
          },
        }
      : undefined,
    {
      name: "streetaddress",
      label: formatMessage(messages.address),
      type: InputType.text,
      size: InputSize.S12,
      value: properties && properties.streetaddress,
      disabled: propertyIsReadOnly("streetaddress"),
    },
    {
      name: "city",
      label: formatMessage(messages.city),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.city,
      disabled: propertyIsReadOnly("city"),
    },
    {
      name: "postalcode",
      label: formatMessage(messages.cp),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.postalcode,
      disabled: propertyIsReadOnly("postalcode"),
    },
    {
      name: "phone",
      label: formatMessage(messages.phone),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.phone,
      disabled: propertyIsReadOnly("phone"),
    },
    {
      name: "mobile",
      label: formatMessage(messages.mobile),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.mobile,
      disabled: propertyIsReadOnly("mobile"),
    },
    {
      name: "website",
      label: formatMessage(messages.website),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.website,
      disabled: propertyIsReadOnly("website"),
    },
    {
      name: "disclaimer",
      label: formatMessage(messages.disclaimer),
      type: InputType.text,
      size: InputSize.S6,
      value: properties && properties.disclaimer,
      disabled: propertyIsReadOnly("disclaimer"),
    },
    {
      name: "usermessage",
      label: formatMessage(messages.userMessage),
      type: InputType.text,
      size: InputSize.S12,
      value: properties && properties.usermessage,
      disabled: propertyIsReadOnly("usermessage"),
    },
  ]
    .filter((legacyProp) => legacyProp !== undefined)
    .map((legacyProp, index) => ({ ...legacyProp, order: index + 1 }))

  const customProperties = globalProperties
    .filter(
      (prop) =>
        !legacyProperties.some(
          (p) => p.name === prop.InternalName || prop.InternalName === "banner",
        ),
    )
    .map((property, index) => ({
      name: property.InternalName,
      label: StringHelper.Capitalize(
        property.DisplayName || property.InternalName,
      ),
      type: InputType.text,
      size: InputSize.S12,
      order: legacyProperties.length + index + 1,
      value: properties && properties[property.InternalName],
      disabled: propertyIsReadOnly(property.InternalName),
      custom: true,
    }))

  const defaultSignatureInitForm = {
    name: "default-signature",
    label: formatMessage(messages.selectDefaultSignature),
    type: InputType.select,
    size: InputSize.S12,
    order: legacyProperties.length + customProperties.length + 1,
    value: defaultSignature
      ? SignatureMapper.getSignatureId(defaultSignature)
      : "-1",
    selectOptions: signatureOptions,
    disabled: signatureOptions.length === 1,
  }
  const initForm = [...legacyProperties, ...customProperties]

  const initFormWithDefault = [...initForm, defaultSignatureInitForm]

  if (isNew) return { initForm }

  return { initForm: initFormWithDefault }
}

export default useEditUserInitForm
