import "./polyfills"
import ReactDOM from "react-dom"

import history from "./utils/history"
import cyStoreSubscriber from "./utils/CystoreSubscriber"
import { Provider } from "react-redux"
import GlobalRoutes from "./router/routes"

import withAppInsights, { configureAppInsight } from "AppInsightService"

import store from "./store/configureStore"

// Vendors Lib
import "bootstrap"
import "animate.css/animate.compat.css"
import "bootstrap/dist/css/bootstrap.css"
import "react-phone-number-input/style.css"

// Global styles
import "./css/theme.scss"
import "./css/mixins.scss"
import "./css/base.scss"
import "./css/form.scss"

/** Import Only Client JS**/
// Note that Froala Editor has to be required separately
// import 'froala-editor/js/froala_editor.pkgd.min.js';
// import 'froala-editor/js/plugins/font_family.min.js';

import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"

import ConnectedIntlProvider from "./features/App/components/IntlProviderWrapper/IntlProviderWrapper"

import config from "./config/config"

import { themeOptions } from "./theme"
import { LocationStateContext } from "hooks/useLocationState"

import PortalApi from "core/services/portalApi"
import ServiceApi from "core/services/serviceApi"

import { fetchCurrentAccountAction } from "features/Accounts/AccountsActions"
import { fetchConfig } from "features/App/AppApi"

import { checkApiAction, initLanguagesAction } from "features/App/AppActions"

const theme = createTheme(themeOptions)

function renderApp() {
  PortalApi.initialize(`${config.proxyUrl}/api`)
  // loading configuration from server-side
  fetchConfig()
    .then((configResponse: { result: string }) => {
      const parsedConfig = JSON.parse(configResponse.result)

      config.setFromState(parsedConfig)

      ServiceApi.initialize(`${config.webApiUrl}/api`)

      // pas de chargement superflus en Cypress
      store.dispatch(checkApiAction())
      store.dispatch(initLanguagesAction())
      // Chargement de l'utilisateur courant
      store.dispatch(fetchCurrentAccountAction())

      // Exposition du store pour les manipulations exterieures (retour d'appel Canva)
      ;(window as any).store = store

      // Expose le store Redux et l'objet `history` si Cypress est en cours d'execution
      if ((window as any).Cypress) {
        ;(window as any).bmmHistory = history

        // creation d'un subscriber pour permettre d'écouter les actions Redux dans Cypress
        ;(window as any).storeSubscriber = cyStoreSubscriber()

        // activation du DevTools Redux sur Cypress
        window["__REDUX_DEVTOOLS_EXTENSION__"] =
          window.parent["__REDUX_DEVTOOLS_EXTENSION__"]
        window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] =
          window.parent["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
      }

      const hasAppInsights: boolean =
        config.appInsightPublicConnectionString != null

      if (hasAppInsights) configureAppInsight()

      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <LocationStateContext.Provider value={{ locations: {} }}>
            <Provider store={store}>
              <ConnectedIntlProvider>
                <GlobalRoutes />
              </ConnectedIntlProvider>
            </Provider>
          </LocationStateContext.Provider>
        </ThemeProvider>,
        document.getElementById("root"),
      )
    })
    .catch((error: any) => {
      console.error(error)
      ReactDOM.render(
        <div>
          <h1>💥Environnement de developpement indisponible</h1>
          <h5>
            Utilisez un navigateur de développement sans la politique de CORS
          </h5>
        </div>,
        document.getElementById("root"),
      )
    })
}

renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
