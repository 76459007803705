import { FunctionComponent } from "react"

import SendingModeBlock from "./components/SendingModeBlock"
import WeightingBlock from "./components/WeightingBlock"
import DebugModeBlock from "./components/DebugModeBlock"

const BuilderParametersPanel: FunctionComponent = () => {
  return (
    <div className="builder-parameters-panel">
      <SendingModeBlock />
      <WeightingBlock />
      <DebugModeBlock />
    </div>
  )
}

export default BuilderParametersPanel
