import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { isSpan } from "utils/I18nHelpers"

import { ModalActions } from "components/Modal"

import { AdminAccount } from "features/Accounts/AccountsModels"

interface ToggleSubsidiaryModalProps {
  adminAccount: AdminAccount
  onCancel: () => void
  onDelete: () => void
}

const messages = defineMessages({
  modalLabel: {
    id: "SubsidiariesAdminsGrid.ToggleSubsidiaryModalLabel",
    defaultMessage:
      "Vous allez sortir l'administrateur <sp>{name}</sp> du mode filiale, êtes-vous sûr de vouloir continuer ?",
  },
  cancel: {
    id: "SubsidiariesAdminsGrid.ToggleSubsidiaryModalCancel",
    defaultMessage: "Annuler",
  },
  delete: {
    id: "SubsidiariesAdminsGrid.ToggleSubsidiaryModalDelete",
    defaultMessage: "Sortir l'admin",
  },
})

const ToggleSubsidiaryModal: FunctionComponent<ToggleSubsidiaryModalProps> = ({
  adminAccount,
  onCancel,
  onDelete,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="toggle-from-subsidiary-modal">
      <div className="toggle-from-subsidiary-modal__content">
        <p className="toggle-from-subsidiary-modal__content--label">
          {formatMessage(messages.modalLabel, {
            name: adminAccount.userName,
            sp: isSpan,
          })}
        </p>
      </div>
      <ModalActions>
        <ModalActions.Secondary onClick={onCancel}>
          {formatMessage(messages.cancel)}
        </ModalActions.Secondary>
        <ModalActions.Main onClick={() => onDelete()}>
          {formatMessage(messages.delete)}
        </ModalActions.Main>
      </ModalActions>
    </div>
  )
}

export default ToggleSubsidiaryModal
