import { FunctionComponent, useState } from "react"

import classNames from "classnames"

import { FormattedMessage } from "react-intl"

import { ReactComponent as GenericEmptyIcon } from "assets/duotone/icon-generic-empty-results.svg"

import AddIcon from "@material-ui/icons/Add"
import SyncIcon from "@material-ui/icons/Sync"

import SearchBar from "components/SearchBar"
import Toggle from "components/MUIToggle"

import HeaderWithTip from "./HeaderWithTip/HeaderWithTip"
import SyncSource from "./SyncSource/SyncSource"
import DeleteButtonWithTip from "./DeleteButtonWithTip/DeleteButtonWithTip"
import EditableHashtag from "./EditableHashtag/EditableHastag"
import EditableTechnicalName from "./EditableTechnicalName/EditableTechnicalName"

import { SyncProperty } from "features/Users/UserModels"
import PaginatedDataGrid from "components/PaginatedDataGrid/PaginatedDataGrid"

interface SyncPropertiesGridProps {
  data: Array<SyncProperty>
  sources: Array<{ label: string; value: string }>
  isEditing: boolean
  isChecking: boolean
  disableSave: boolean
  isFeatureSelfServicePropertiesActive: boolean
  onDisableSave: () => void
  onEditing: () => void
  onSearch: (search: string) => void
  onDeactivate: (id: number) => void
  onDeleteProperty: (syncProperty) => void
  onCheckData: () => void
  onEditHashtag: (hashtag: string, id: string) => void
  checkDuplicateHashtags: (hashtag: string, id: string) => boolean
  onEditTechnicalName: (technicalName: string, id: string) => void
  onEditSyncSource: (id: string) => void
  onChangeSyncSource: (syncSource: string, id: string) => void
  onSave: () => void
  addLine: () => void
  currentPage: number
  lastPage: number
  pageSize: number
  changePage: (page: number) => void
  setPageSize: (pageSize: number) => void
  isLoading?: boolean
}

const syncPropertiesGridConfig = (dataProvider) => ({
  columns: [
    {
      label: (
        <FormattedMessage
          id="SyncPropertiesGrid.Hashtag"
          defaultMessage="# hashtag"
        />
      ),
      value: (_value, syncProperty) => (
        <EditableHashtag
          initialValue={syncProperty.hashtag}
          id={syncProperty.id}
          disabled={syncProperty.isReadOnly}
          onEditHashtag={dataProvider.onEditHashtag}
          checkDuplicateHashtags={dataProvider.checkDuplicateHashtags}
          onDisableSave={dataProvider.onDisableSave}
          onEditing={dataProvider.onEditing}
        />
      ),
    },
    {
      label: (
        <HeaderWithTip
          message={
            <FormattedMessage
              id="SyncPropertiesGrid.TechnicalNameInfo"
              defaultMessage="Message d'information"
            />
          }
        >
          <FormattedMessage
            id="SyncPropertiesGrid.TechnicalName"
            defaultMessage="Dénomination technique"
          />
        </HeaderWithTip>
      ),
      value: (_value, syncProperty) => (
        <EditableTechnicalName
          initialValue={syncProperty.technicalName}
          id={syncProperty.id}
          disabled={
            dataProvider.sources.length === 0 || syncProperty.isReadOnly
          }
          onEditTechnicalName={dataProvider.onEditTechnicalName}
          onEditing={dataProvider.onEditing}
        />
      ),
    },
    {
      label: (
        <div className="sync-properties-grid__header-item">
          <FormattedMessage
            id="SyncPropertiesGrid.Origin"
            defaultMessage="source"
          />
        </div>
      ),
      value: (_value, syncProperty) => (
        <div className="sync-properties-grid__cell--container">
          <SyncSource
            sources={dataProvider.sources}
            source={syncProperty.syncSource}
            isUnknown={syncProperty.isUnknown}
            isSyncSourceEditing={syncProperty.isSyncSourceEditing}
            onEditSyncSource={() =>
              dataProvider.onEditSyncSource(syncProperty.id)
            }
            onChangeSyncSource={(e) =>
              dataProvider.onChangeSyncSource(e.target.value, syncProperty.id)
            }
          />
        </div>
      ),
      cellsClassName: "sync-properties-grid__cell--origin",
    },
    {
      label: dataProvider.isFeatureSelfServicePropertiesActive && (
        <div className="sync-properties-grid__header-item">
          <HeaderWithTip
            message={
              <FormattedMessage
                id="SyncPropertiesGrid.SelfServiceInfo"
                defaultMessage="Message d'information"
              />
            }
          >
            <FormattedMessage
              id="SyncPropertiesGrid.SelfService"
              defaultMessage="self-service"
            />
          </HeaderWithTip>
        </div>
      ),
      value: (_value, syncProperty) =>
        !syncProperty.isReadOnly &&
        dataProvider.isFeatureSelfServicePropertiesActive && (
          <div className="sync-properties-grid__cell--container">
            <Toggle
              checked={syncProperty.isEditable}
              onChange={() =>
                dataProvider.handleSelfServiceChange(syncProperty)
              }
            />
          </div>
        ),
      cellsClassName: "sync-properties-grid__cell--editable",
    },
    {
      value: (_value, syncProperty) =>
        !syncProperty.isReadOnly && (
          <DeleteButtonWithTip
            message="Supprimer la propriété"
            onClick={() => dataProvider.onDeleteProperty(syncProperty)}
          />
        ),
      cellsClassName: "sync-properties-grid__cell--delete",
    },
  ],
  isLoading: dataProvider.isLoading,
  skeletonRowQuantity: 6,
  background: (
    <div className="sync-properties-grid__empty-results">
      <GenericEmptyIcon />
      <p className="sync-properties-grid__empty-results--text">
        <FormattedMessage
          id="SyncPropertiesGrid.NoResults"
          defaultMessage="Aucun résultats de recherche"
        />
      </p>
    </div>
  ),
})

const SyncPropertiesGrid: FunctionComponent<SyncPropertiesGridProps> = ({
  data,
  sources,
  isChecking,
  isEditing,
  isFeatureSelfServicePropertiesActive,
  disableSave,
  onDisableSave,
  onEditing,
  onSearch,
  onDeactivate,
  onDeleteProperty,
  onCheckData,
  onEditHashtag,
  checkDuplicateHashtags,
  onEditTechnicalName,
  onEditSyncSource,
  onChangeSyncSource,
  onSave,
  addLine,
  isLoading,
  currentPage,
  lastPage,
  pageSize,
  changePage,
  setPageSize,
}) => {
  const [scrollToTop, setScrolltoTop] = useState(false)

  const handleSelfServiceChange = (syncProperty) => {
    onDeactivate(syncProperty.hashtag)
  }

  const handleRowStyle = (syncProperty) => {
    let rowProps
    if (syncProperty.isUnknown)
      rowProps = { ...rowProps, style: { backgroundColor: "#FFF6DA" } }

    return rowProps
  }

  const handleAddline = () => {
    addLine()
    setScrolltoTop((prevState) => !prevState)
  }

  const dataProvider = {
    sources,
    isFeatureSelfServicePropertiesActive,
    onEditTechnicalName,
    onEditing,
    onEditHashtag,
    onDisableSave,
    onEditSyncSource,
    onChangeSyncSource,
    handleSelfServiceChange,
    onDeleteProperty,
    checkDuplicateHashtags,
    isLoading,
  }

  return (
    <div className="sync-properties-grid__container">
      <div className="sync-properties-grid__header">
        <div className="sync-properties-grid__title">
          <FormattedMessage
            id="SyncPropertiesGrid.Title"
            defaultMessage="Propriétés de synchronisation"
          />
        </div>
        <div className="sync-properties-grid__search">
          <SearchBar onChange={onSearch} placeholder="Rechercher" />
        </div>
        <div className="sync-properties-grid__actions">
          {!isEditing && (
            <button
              type="button"
              className={classNames(
                "sync-properties-grid__btn",
                isChecking && "rotate",
              )}
              onClick={onCheckData}
            >
              <SyncIcon />
            </button>
          )}
          <button
            type="button"
            className="sync-properties-grid__btn"
            onClick={handleAddline}
          >
            <AddIcon />
          </button>
          {isEditing && (
            <button
              type="button"
              className="sync-properties-grid__btn-save"
              disabled={disableSave}
              onClick={onSave}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
      <PaginatedDataGrid
        config={syncPropertiesGridConfig(dataProvider)}
        data={data}
        currentPage={currentPage}
        lastPage={lastPage}
        pageSize={pageSize}
        sortOrders={[]}
        changePage={changePage}
        setPageSize={setPageSize}
        getRowProps={handleRowStyle}
        scrollToTop={scrollToTop}
      />
    </div>
  )
}

export default SyncPropertiesGrid
