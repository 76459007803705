import { defineMessages } from "react-intl"

import TrackingPage from "pages/private/TrackingPage"
import Users from "pages/private/UsersPage"
import Parameters from "pages/private/ParametersPage"
import Scenario, {
  ScenarioCalendarPage,
  ScenarioFormPage,
} from "pages/private/Scenario"
import EditUser from "pages/private/EditUserPage"
import EditGroupPage from "pages/private/EditGroupPage"

import ScenariosList from "features/Scenarios/components/ScenariosList"

import {
  CAMPAIGNS_URL,
  GROUPS_URL,
  PARAMETERS_URL,
  TRACKING_URL,
  USERS_URL,
} from "router/RouterConstants"

import { LimitedByOfferRoute } from "router/RouterModels"

const limitationsMessages = defineMessages({
  statsTitle: {
    id: "LimitationPopup.StatistiquesTitle",
    defaultMessage:
      "Attribuer à chacun de vos collaborateurs une signature mail personnalisée.",
  },
  statsContent: {
    id: "LimitationPopup.StatistiquesContent",
    defaultMessage:
      "Délivrez simultanément sur les messageries de vos collaborateurs leur nouvelle signature avec leurs coordonnées professionnelles.",
  },
  bannersTitle: {
    id: "LimitationPopup.BannersTitle",
    defaultMessage:
      "Attribuer à chacun de vos collaborateurs une signature mail personnalisée.",
  },
  bannersContent: {
    id: "LimitationPopup.BannersContent",
    defaultMessage:
      "Délivrez simultanément sur les messageries de vos collaborateurs leur nouvelle signature avec leurs coordonnées professionnelles.",
  },
  usersTitle: {
    id: "LimitationPopup.UsersTitle",
    defaultMessage:
      "Attribuer à chacun de vos collaborateurs une signature mail personnalisée.",
  },
  usersContent: {
    id: "LimitationPopup.UsersContent",
    defaultMessage:
      "Délivrez simultanément sur les messageries de vos collaborateurs leur nouvelle signature avec leurs coordonnées professionnelles.",
  },
  parametersTitle: {
    id: "LimitationPopup.ParametersTitle",
    defaultMessage: "Gérer les paramètres de vos collaborateurs",
  },
  parametersContent: {
    id: "LimitationPopup.ParametersContent",
    defaultMessage: "Téléchargez le client lourd",
  },
})

const limitedByOfferRoutes: LimitedByOfferRoute[] = [
  {
    key: "tracking",
    path: TRACKING_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      requirePaidOffer: true,
      tourguide: true,
    },
    accessRoles: "TRACKING_PAGE",
    limitationTitle: limitationsMessages.statsTitle,
    limitationContent: limitationsMessages.statsContent,
    element: <TrackingPage />,
  },
  {
    key: "users",
    path: USERS_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      requirePaidOffer: true,
    },
    accessRoles: "USERS_PAGE",
    limitationTitle: limitationsMessages.usersTitle,
    limitationContent: limitationsMessages.usersContent,
    element: <Users />,
  },
  {
    key: "userEdit",
    path: `${USERS_URL}/:user`,
    limitations: {
      accountFilled: true,
      offerValid: true,
      requirePaidOffer: true,
    },
    accessRoles: "USERS_PAGE",
    element: <EditUser />,
    limitationTitle: limitationsMessages.usersTitle,
    limitationContent: limitationsMessages.usersContent,
  },
  {
    key: "groupEdit",
    path: `${GROUPS_URL}/:group`,
    limitations: {
      accountFilled: true,
      offerValid: true,
      requirePaidOffer: true,
    },
    accessRoles: "GROUP_PAGE",
    element: <EditGroupPage />,
    limitationTitle: limitationsMessages.usersTitle,
    limitationContent: limitationsMessages.usersContent,
  },
  {
    key: "parameters",
    path: PARAMETERS_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      requirePaidOffer: true,
    },
    accessRoles: "PARAMETERS_PAGE",
    limitationTitle: limitationsMessages.parametersTitle,
    limitationContent: limitationsMessages.parametersContent,
    element: <Parameters />,
  },
  {
    key: "campaigns",
    path: CAMPAIGNS_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      bannerUnlocked: true,
      tourguide: true,
    },
    accessRoles: "CAMPAIGNS_PAGE",
    limitationTitle: limitationsMessages.bannersTitle,
    limitationContent: limitationsMessages.bannersContent,
    element: <Scenario />,
    children: [
      {
        key: "scenariohome",
        index: true,
        path: "",
        element: <ScenariosList />,
      },
      {
        key: "scenarioCalendar",
        path: "calendar",
        element: <ScenarioCalendarPage />,
      },
      {
        key: "scenarioEdit",
        path: "edit/:scenarioId?/step/:step",
        element: <ScenarioFormPage />,
      },
    ],
  },
]

export default limitedByOfferRoutes
