import * as React from "react"
import { FormattedMessage } from "react-intl"
import ImageEmpty from "./calendar-card-list-empty.svg"

const CalendarCardListEmpty: React.FC = () => {
  return (
    <div className="bcl-empty">
      <img src={ImageEmpty} width="60" />
      <br />
      <FormattedMessage
        id="CalendarViewBanner.noCampaignMsg"
        defaultMessage="Aucune campagne ce mois-ci"
      />
      <br />
    </div>
  )
}

export default CalendarCardListEmpty
