import { FunctionComponent, ReactNode, Fragment } from "react"

import classNames from "classnames"

import PillButtonElement from "./PillButtonElement/PillButtonElement"

export type PillSize = "normal" | "large"

interface PillButton {
  id: string
  children: ReactNode
}

interface PillButtonsGroupProps {
  pillButtons: PillButton[]
  current: string
  onChange: (value: string) => void
  hasSeparator?: boolean
  size?: PillSize
}

const PillButtonsGroup: FunctionComponent<PillButtonsGroupProps> = ({
  pillButtons,
  current,
  onChange,
  hasSeparator,
  size = "normal",
}) => {
  return (
    <div className={classNames("pill-buttons-group", size)}>
      {pillButtons.map((pillButton, i) => (
        <Fragment key={pillButton.id}>
          <PillButtonElement
            isActive={pillButton.id === current}
            onClick={() => onChange(pillButton.id)}
            size={size}
          >
            {pillButton.children}
          </PillButtonElement>
          {i < pillButtons.length - 1 && hasSeparator && (
            <div className="pill-buttons-group__separator" />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default PillButtonsGroup
