import { FormEvent, FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import MouseTooltip from "components/MouseTooltip"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

import { TENANT_PROPERTY_NAMES } from "entities/TenantProperties"

import Feature from "features/Features/FeaturesModels"

interface OWAParamsFormvalues extends EventTarget {
  login: { value: string }
  password: { value: string }
  domain: { value: string }
  serviceUrl: { value: string }
}
interface OWAParamsFormProps {
  featureOWA: Feature
  onActivate: (
    login: string,
    password: string,
    domain: string,
    serviceUrl: string,
  ) => void
}

const messages = defineMessages({
  title: {
    id: "OWAParamsForm.Title",
    defaultMessage: "Paramétrage de votre compte de service",
  },
  loginLabel: {
    id: "OWAParamsForm.LoginLabel",
    defaultMessage: "Identifiant",
  },
  loginPlaceholder: {
    id: "OWAParamsForm.LoginPlaceholder",
    defaultMessage: "Votre identifiant",
  },
  passwordLabel: {
    id: "OWAParamsForm.PasswordLabel",
    defaultMessage: "Mot de passe",
  },
  passwordPlaceholder: {
    id: "OWAParamsForm.PasswordPlaceholder",
    defaultMessage: "Votre mot de passe",
  },
  domainLabel: {
    id: "OWAParamsForm.DomainLabel",
    defaultMessage: "Domaine d'entreprise",
  },
  domainPlaceholder: {
    id: "OWAParamsForm.DomainPlaceholder",
    defaultMessage: "Votre domaine",
  },
  urlLabel: {
    id: "OWAParamsForm.UrlLabel",
    defaultMessage: "URL du service Exchange (asmx)",
  },
  urlPlaceholder: {
    id: "OWAParamsForm.UrlPlaceholder",
    defaultMessage: "https://mail.boostmymail.com/EWS/Exchange.asmx",
  },
  save: {
    id: "OWAParamsForm.SaveButton",
    defaultMessage: "Enregistrer",
  },
  tooltip: {
    id: "OWAParamsForm.Tooltip",
    defaultMessage:
      "Le nom d'utilisateur sera sous la forme domaine\\identifiant",
  },
})

function getDefaultAccountValues(feature: Feature) {
  const defaultaccount = feature.properties.find(
    (p) => p.property === TENANT_PROPERTY_NAMES.OWA_ACCOUNTS,
  )

  if (!defaultaccount) return null
  const values = JSON.parse(defaultaccount.value)

  if (!values || (!Array.isArray(values) && values.length === 0)) return null

  return values[0]
}

function getEndpointUrl(feature: Feature) {
  const defaultEndpoint = feature.properties.find(
    (p) => p.property === TENANT_PROPERTY_NAMES.OWA_ENDPOINT_URL,
  )

  if (!defaultEndpoint) return null

  return defaultEndpoint.value
}

const OWAParamsForm: FunctionComponent<OWAParamsFormProps> = ({
  featureOWA,
  onActivate,
}) => {
  const { formatMessage } = useIntl()

  const [visible, setVisible] = useState(false)

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    const { login, password, domain, serviceUrl } =
      evt.target as OWAParamsFormvalues

    onActivate(login.value, password.value, domain.value, serviceUrl.value)
  }

  const defaultAccount = getDefaultAccountValues(featureOWA)

  const defaultEndpoint = getEndpointUrl(featureOWA)

  return (
    <>
      <form className="owa-params-form" onSubmit={handleSubmit}>
        <div className="owa-params-form__title">
          <p className="owa-params-form__title--label">
            {formatMessage(messages.title)}
          </p>
          <InfoIcon
            className="owa-params-form__title--icon"
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
          />
        </div>

        <div className="owa-params-form__content">
          <div className="owa-params-form__content--field">
            <label className="owa-params-form__content--label" htmlFor="domain">
              {formatMessage(messages.domainLabel)}
            </label>
            <input
              required
              className="owa-params-form__content--input"
              name="domain"
              type="text"
              defaultValue={defaultAccount?.domain}
              placeholder={formatMessage(messages.domainPlaceholder)}
            />
          </div>
          <div className="owa-params-form__content--field">
            <label htmlFor="login" className="owa-params-form__content--label">
              {formatMessage(messages.loginLabel)}
            </label>
            <input
              required
              className="owa-params-form__content--input"
              type="text"
              name="login"
              defaultValue={defaultAccount?.login}
              placeholder={formatMessage(messages.loginPlaceholder)}
            />
          </div>
          <div className="owa-params-form__content--field">
            <label
              htmlFor="password"
              className="owa-params-form__content--label"
            >
              {formatMessage(messages.passwordLabel)}
            </label>
            <input
              required
              className="owa-params-form__content--input"
              name="password"
              type="password"
              defaultValue={defaultAccount?.password}
              placeholder={formatMessage(messages.passwordPlaceholder)}
            />
          </div>
          <div className="owa-params-form__content--field">
            <label
              className="owa-params-form__content--label"
              htmlFor="serviceUrl"
            >
              {formatMessage(messages.urlLabel)}
            </label>
            <input
              required
              className="owa-params-form__content--input"
              name="serviceUrl"
              type="text"
              defaultValue={defaultEndpoint}
              placeholder={formatMessage(messages.urlPlaceholder)}
            />
          </div>
        </div>
        <div className="owa-params-form__actions">
          <button type="submit" className="owa-params-form__actions--btn">
            {formatMessage(messages.save)}
          </button>
        </div>
      </form>
      {visible && (
        <MouseTooltip visible={visible}>
          {formatMessage(messages.tooltip)}
        </MouseTooltip>
      )}
    </>
  )
}

export default OWAParamsForm
