import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { builderSignaturesSelectors } from "features/BuilderSignatures"

import { fetchBuilderSignatures } from "features/BuilderSignatures/BuilderSignaturesReducers"

import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"
import { LoadingStatus } from "core/CoreModels"
import { featureNames } from "config/config.features"
import { getFeaturesLoadingStatus } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"
import { signaturesSelectors } from "features/Signatures"
import usePrevious from "hooks/usePrevious"

interface UseBuilderSignaturesData {
  builderSignatures: Array<BuilderSignature>
  loadingStatus: LoadingStatus
}

const useBuilderSignaturesData = (): UseBuilderSignaturesData => {
  const dispatch = useDispatch()
  const builderSignatures = useSelector(
    builderSignaturesSelectors.getAllBuilderSignatures,
  )
  const sendingMode = useSelector(signaturesSelectors.getSendingMode)
  const featuresTogglrLoadingStatus = useSelector(getFeaturesLoadingStatus)

  const previousSendingMode = usePrevious(sendingMode)

  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const signaturesLoadingSlices = newMailInResponseFeature?.isActive
    ? sendingMode
    : "all"

  const loadingStatus = useSelector(
    builderSignaturesSelectors.getBuilderSignaturesSliceLoadingStatus(
      signaturesLoadingSlices,
    ),
  )

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.NOT_STARTED &&
      featuresTogglrLoadingStatus === LoadingStatus.LOADED &&
      sendingMode !== previousSendingMode
    ) {
      dispatch(fetchBuilderSignatures(signaturesLoadingSlices))
    }
  }, [loadingStatus, dispatch])

  return { builderSignatures, loadingStatus }
}

export default useBuilderSignaturesData
