import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface SignaturesDeploymentInsufficientsDatasProps {
  deploymentValue: number
}

const messages = defineMessages({
  unit: { id: "Tracking.SignaturesDeploymentUnit", defaultMessage: "%" },
  deployed: {
    id: "Tracking.SignaturesDeploymentDeployment",
    defaultMessage: "de déploiement",
  },
  infos: {
    id: "Tracking.SignaturesDeploymentInformations",
    defaultMessage: "En attente de données suffisantes",
  },
})

const SignaturesDeploymentInsufficientsDatas: FunctionComponent<
  SignaturesDeploymentInsufficientsDatasProps
> = ({ deploymentValue }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="sign-depl-insuff">
      <div className="sign-depl-insuff__value">
        <p className="sign-depl-insuff__value--data">
          {deploymentValue}
          <span className="sign-depl-insuff__value--unit">
            {formatMessage(messages.unit)}
          </span>
        </p>
        <p className="sign-depl-insuff__value--label">
          {formatMessage(messages.deployed)}
        </p>
      </div>
      <div className="sign-depl-insuff__infos">
        <InfoIcon className="sign-depl-insuff__infos--icon" />
        <p className="sign-depl-insuff__infos--label">
          {formatMessage(messages.infos)}
        </p>
      </div>
    </div>
  )
}

export default SignaturesDeploymentInsufficientsDatas
