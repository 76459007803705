import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios"
import { TypedResult } from "core/CoreModels"

import store from "store/configureStore"

import { ErrorService } from "features/Errors/ErrorsService"
import { apiError } from "features/Errors"

import { createBaseAxiosInstance } from "./api"

let portalApi: AxiosInstance = null

export default {
  initialize: (baseUrl: string): void => {
    portalApi = createBaseAxiosInstance(baseUrl)
  },
  request<T>(
    method: Method,
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<TypedResult<T>> {
    const requestConfig = {
      ...config,
      method,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
        Expires: "-1",
      },
      responseType: "json",
      data: config?.data && JSON.stringify(config.data),
    } as AxiosRequestConfig

    if (portalApi === null) throw Error("PortalApi not initialized yet.")

    return portalApi({
      url,
      ...requestConfig,
    })
      .then((response: AxiosResponse<T>) => {
        return new TypedResult(response) as TypedResult<T>
      })
      .catch((error: AxiosError) => {
        store.dispatch(
          apiError(error.message, ErrorService.apiErrorStack(error)),
        )
        return new TypedResult(error.response) as TypedResult<T>
      })
  },
}
