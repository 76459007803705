import { TourGuideActionTypes, PassThroughtTourGuideAction } from "."
import {
  StartTourGuideAction,
  ExitTourGuideAction,
  UpdateTourGuideDisplayAction,
  UpdateTourGuideAction,
  ResetTourGuideAction,
} from "./TourGuideConstants"

export const actionCreators = {
  passThroughtTourGuide: (): PassThroughtTourGuideAction => ({
    type: TourGuideActionTypes.PASSTHROUGHT_TOURGUIDE,
  }),
  startTourGuide: (selectedTour): StartTourGuideAction => ({
    type: TourGuideActionTypes.START_TOUR,
    tour: selectedTour,
  }),
  updateTourGuideDisplay: (display: boolean): UpdateTourGuideDisplayAction => ({
    type: TourGuideActionTypes.UPDATE_TOUR_GUIDE_DISPLAY,
    display,
  }),
  hideTourGuide: (selectedTour): UpdateTourGuideDisplayAction => ({
    type: TourGuideActionTypes.UPDATE_TOUR_GUIDE_DISPLAY,
    display: false,
  }),
  exitTour: (selectedTour): ExitTourGuideAction => ({
    type: TourGuideActionTypes.EXIT_TOUR_GUIDE,
    tour: selectedTour,
  }),
  updateTour: (selectedTour): UpdateTourGuideAction => ({
    type: TourGuideActionTypes.UPDATE_TOUR_GUIDE,
    tour: selectedTour,
  }),
  resetTour: (): ResetTourGuideAction => ({
    type: TourGuideActionTypes.RESET_TOUR_GUIDE,
  }),
}
