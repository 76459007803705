import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { isEqual } from "lodash"
import { useIntl, defineMessages } from "react-intl"
import { useBlocker } from "react-router-dom"

import { useBuilderData } from "bmm-builder"

import { ModalService } from "components/Modal"

import MustSaveModal from "features/BuilderSignatures/components/MustSaveModal"
import usePrevious from "hooks/usePrevious"
import { builderSignaturesSelectors } from ".."
import { setNeedSave } from "../BuilderSignaturesReducers"

const messages = defineMessages({
  title: {
    id: "BuilderSignatures.SaveModalTitle",
    defaultMessage: "Êtes vous sûr de quitter la page ?",
  },
})

function useMustSave(
  onSave: (signature) => void,
  signatureName: string,
  activeBuilderSignature,
) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const currentAssignments = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureAssignment,
  )
  const needSave = useSelector(
    builderSignaturesSelectors.getNeedSaveEditingBuilderSignature,
  )

  const { getData } = useBuilderData()

  const previousAssignments = usePrevious(currentAssignments)
  const previousSignatureName = usePrevious(signatureName)

  const modalOpen = useRef(false)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      needSave && currentLocation.pathname !== nextLocation.pathname,
  )
  const signatureNameEquality = isEqual(signatureName, previousSignatureName)
  const assignmentsEquality = isEqual(currentAssignments, previousAssignments)

  const handleCancel = () => {
    modalOpen.current = false
    blocker.reset()
    ModalService.close()
  }

  const handleQuitWithoutSave = () => {
    modalOpen.current = false
    dispatch(setNeedSave(false))
    blocker.proceed()
    ModalService.close()
  }

  const handleSave = () => {
    modalOpen.current = false
    blocker.reset()
    ModalService.close()
    handleProcessTemplate()
  }

  const handleProcessTemplate = async () => {
    const jsonTemplate =
      activeBuilderSignature && JSON.parse(activeBuilderSignature.json)
    const templateName = signatureName
    const templateCategory = jsonTemplate?.document?.category || "Custom"
    const newJsonTemplate = await getData({
      name: templateName,
      category: templateCategory,
    })

    if (newJsonTemplate)
      onSave({
        id: activeBuilderSignature?.id,
        name: activeBuilderSignature?.name || "",
        json: newJsonTemplate,
        html: activeBuilderSignature?.html,
      })
  }

  useEffect(() => {
    if (blocker.state === "blocked" && !modalOpen.current) {
      modalOpen.current = true
      ModalService.open({
        title: formatMessage(messages.title),
        content: (
          <MustSaveModal
            onCancel={handleCancel}
            onQuitWithoutSave={handleQuitWithoutSave}
            onSave={handleSave}
            disableSave={signatureName.length === 0}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCancel: async (_e) => handleCancel(),
      })
    }
  }, [blocker.state])

  useEffect(() => {
    if (!assignmentsEquality && previousAssignments !== undefined)
      dispatch(setNeedSave(true))
  }, [assignmentsEquality])

  useEffect(() => {
    if (!signatureNameEquality && previousSignatureName !== undefined)
      dispatch(setNeedSave(true))
  }, [signatureNameEquality])
}

export default useMustSave
