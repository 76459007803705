import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { usersSelectors } from "features/Users"

import { GlobalStates } from "store"
import { LoadingStatus } from "core/CoreModels"
import { fetchAllUsers } from "../UsersReducers"

import { User } from "../UserModels"

interface UseUsersData {
  allUsers: Array<User>
  loadingStatus: LoadingStatus
}

const useUsersData = (): UseUsersData => {
  const dispatch = useDispatch()
  const loadingStatus = useSelector<GlobalStates, LoadingStatus>((state) =>
    usersSelectors.getLoadingStatus(state),
  )
  const allUsers = useSelector(usersSelectors.getAllUsers)

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchAllUsers())
    }
  }, [loadingStatus, dispatch])

  return { allUsers, loadingStatus }
}

export default useUsersData
