import React from "react"
import Switch from "@material-ui/core/Switch"
import { makeStyles } from "@material-ui/styles"

export interface Props {
  checked?: boolean
  disabled?: boolean
  trackColor?: string
  trackColorChecked?: string
  thumbColor?: string
  thumbColorChecked?: string
  // controlled interface
  value?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  name?: string
}

/**
 * Wrapper pour centraliser le style du composant Toggle
 */
const Toggle: React.FC<Props> = (props) => {
  const useStyles = makeStyles({
    root: {
      width: 36,
      height: 18,
      padding: 0,
      cursor: props.disabled ? "not-allowed" : "default",
    },
    switchBase: {
      padding: 4,
      color: props.thumbColor || "#ffffff",
      "&$checked": {
        color: props.thumbColorChecked || "#ffffff",
      },
      "&$checked + $track": {
        backgroundColor: props.trackColorChecked || "#3D5AFE",
        opacity: props.disabled ? 0.5 : 1,
        cursor: props.disabled ? "not-allowed" : "default",
      },
    },
    thumb: {
      width: 10,
      height: 10,
    },
    track: {
      backgroundColor: props.trackColor || "#C9CED6",
      opacity: 1,
      borderRadius: 10,
    },
    checked: {},
  })

  const classes = useStyles({})

  const { ...toggleProps } = props

  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...toggleProps}
    />
  )
}

export default Toggle
