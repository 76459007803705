import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"

import UsersPageHeader from "features/Users/components/UsersPageHeader"
import UsersPageContent from "features/Users/components/UsersPageContent"

const UsersPage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="UsersPage.Title"
          defaultMessage="Gestion des utilisateurs"
        />
      }
    >
      <UsersPageHeader />
      <UsersPageContent />
    </Page>
  )
}

export default UsersPage
