import React from "react"
import BmmColorPicker from "./BmmColorPicker"

interface Props {
  currentColor: string
  onColorSelected(color: string)
}

class ColorPickerContainer extends React.Component<Props> {
  colorPickerDiv: React.RefObject<HTMLDivElement>
  constructor(props) {
    super(props)
    this.colorPickerDiv = React.createRef()
  }

  handleChangeComplete = (color) => {
    if (color === "") {
      this.props.onColorSelected("")
    } else this.props.onColorSelected(color.hex.split("#")[1])
  }

  render() {
    const selectedColor = this.props.currentColor || ""

    return (
      <div className="color-picker">
        <BmmColorPicker
          color={selectedColor}
          onReset={() => this.handleChangeComplete("")}
          onChange={this.handleChangeComplete}
        />
      </div>
    )
  }
}

export default ColorPickerContainer
