import mime from "mime"

export type StorageTags = {
  [key: string]: string | boolean
}

export type StorageMetadatas = {
  [key: string]: string | boolean
}

export interface StorageImageDto {
  id: string
  path: string
  fullPath: string
  name: string
  url: string
  metadatas: StorageMetadatas
  tags: StorageTags
}

export class StorageImage {
  id: string
  fileName: string
  mimeType: string
  url: string
  tags: StorageTags

  constructor(img: StorageImageDto) {
    this.id = img.id
    this.fileName = img.name
    this.mimeType = mime.getType(img.name) || ""
    this.url = img.url
    this.tags = img.tags
  }
}
