import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import Signature from "entities/Signature"

import { signaturesSelectors } from "../"
import { fetchSignatures } from "../SignaturesReducer"

import { LoadingStatus } from "core/CoreModels"

interface UseSignaturesData {
  signatures: Array<Signature>
  loadingStatus: LoadingStatus
}

const useAllSignaturesData = (): UseSignaturesData => {
  const dispatch = useDispatch()
  const signatures = useSelector(signaturesSelectors.getAllSignatures)

  const loadingStatus = useSelector(
    signaturesSelectors.getSignaturesSliceLoadingStatus("all"),
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchSignatures("all"))
    }
  }, [loadingStatus, dispatch])

  return {
    signatures,
    loadingStatus,
  }
}

export default useAllSignaturesData
