import { useEffect, useState } from "react"

import { useSelector } from "react-redux"

import { useSlicedScenariosDatas } from "features/Scenarios/hooks/useSlicedScenariosDatas"

import { scenarioSelectors } from "features/Scenarios"

import { ScenarioVm } from "features/Scenarios/ScenarioModels"
import { LoadingStatus } from "core/CoreModels"
import { ScenarioMapper } from "features/Scenarios/mappers/ScenarioMapper"

interface UseScenariosAssignedToGroup {
  affectedScenarios: ScenarioVm[]
  loadingStatus: LoadingStatus
  displayedIndex: number | null
  previousScenario: () => void
  nextScenario: () => void
}

const useScenariosAssignedToGroup = (
  groupId: number,
): UseScenariosAssignedToGroup => {
  const [displayedIndex, setDisplayedIndex] = useState<number | null>(null)

  const previousScenario = () => setDisplayedIndex((prevState) => prevState - 1)

  const nextScenario = () => setDisplayedIndex((prevState) => prevState + 1)

  const { scenarios, loadingStatus } = useSlicedScenariosDatas({
    forceAll: true,
  })

  const affectedScenarios = useSelector(
    scenarioSelectors.getActivatedScenariosAssignedToGroup(groupId),
  )

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.LOADED &&
      scenarios.length > 0 &&
      displayedIndex === null
    )
      setDisplayedIndex(0)
  }, [loadingStatus, scenarios])

  return {
    affectedScenarios: affectedScenarios.map(ScenarioMapper.toViewModel),
    loadingStatus,
    displayedIndex,
    previousScenario,
    nextScenario,
  }
}

export default useScenariosAssignedToGroup
