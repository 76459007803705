import { FunctionComponent, ReactNode, useState } from "react"

import Checkbox from "components/Checkbox"

interface RememberMeCheckboxProps {
  children: ReactNode
}

const RememberMeCheckbox: FunctionComponent<RememberMeCheckboxProps> = ({
  children,
}) => {
  const [checked, setChecked] = useState(false)

  const handleCheck = () => setChecked((prevState) => !prevState)

  return (
    <Checkbox
      name="rememberMe"
      checked={checked}
      onChange={handleCheck}
      color="is-white"
    >
      <span className="login-form__options--remember-me" onClick={handleCheck}>
        {children}
      </span>
    </Checkbox>
  )
}

export default RememberMeCheckbox
