import { FunctionComponent, ReactNode } from "react"

interface UserSignaturePreviewPreviewProps {
  children: ReactNode
}

export type UserSignaturePreviewPreviewType =
  FunctionComponent<UserSignaturePreviewPreviewProps>

const UserSignaturePreviewPreview: UserSignaturePreviewPreviewType = ({
  children,
}) => {
  return <div className="user-signature-preview__preview">{children}</div>
}

export default UserSignaturePreviewPreview
