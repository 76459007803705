import React from "react"
import { TextField } from "@material-ui/core"

interface Props {
  error: boolean
  errorText?: string
}

const CustomTextInput: React.FC<Props> = React.forwardRef((props, ref) => {
  const { error, errorText, ...inputProps } = props

  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="dense"
      inputRef={ref}
      {...inputProps}
      FormHelperTextProps={{
        error: true,
      }}
      error={error}
      helperText={error && errorText}
    />
  )
})

export default CustomTextInput
