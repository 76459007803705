import { FunctionComponent } from "react"

import { ReactComponent as IconImage } from "assets/icon-picture.svg"
import { ReactComponent as LargeGraphIcon } from "features/Tracking/assets/large_loading_graph.svg"
import { defineMessages, useIntl } from "react-intl"

interface ScenariosTrackingWrapperLoadingProps {
  title: string
}

const SCENARIOS_MODEL_LOADING = 4
const AXIS_BUBBLE_LOADING = 6

const messages = defineMessages({
  clickRate: {
    id: "TrackingTotals.ClicksRate",
    defaultMessage: "Taux de clics",
  },
})

const ScenariosTrackingWrapperLoading: FunctionComponent<
  ScenariosTrackingWrapperLoadingProps
> = ({ title }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <div className="scen-tracking-wrapper__skeleton--header">
        <p className="sign-track-slider__title">{title}</p>
      </div>
      <div className="scen-tracking-wrapper__skeleton--templates-container">
        {[...Array(SCENARIOS_MODEL_LOADING).keys()].map((el) => (
          <div
            key={el}
            className="scen-tracking-wrapper__skeleton--template-container"
          >
            <IconImage className="scen-tracking-wrapper__skeleton--template" />
          </div>
        ))}
      </div>
      <div className="scen-tracking-wrapper__skeleton--totals-container">
        <div className="scen-tracking-wrapper__skeleton--clic-rate-container">
          <p className="scen-tracking-wrapper__skeleton--clic-rate-title">
            {formatMessage(messages.clickRate)}
          </p>
          <div className="scen-tracking-wrapper__skeleton--graph-container">
            <div className="scen-tracking-wrapper__skeleton--y-axis-container">
              {[...Array(AXIS_BUBBLE_LOADING).keys()].map((el) => (
                <div
                  key={el}
                  className="scen-tracking-wrapper__skeleton--graph-bubble"
                />
              ))}
            </div>
            <div className="scen-tracking-wrapper__skeleton--graph-block">
              <LargeGraphIcon className="scen-tracking-wrapper__skeleton--graph" />
              <div className="scen-tracking-wrapper__skeleton--x-axis-container">
                {[...Array(AXIS_BUBBLE_LOADING).keys()].map((el) => (
                  <div
                    key={el}
                    className="scen-tracking-wrapper__skeleton--graph-bubble"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="scen-tracking-wrapper__skeleton--kpi-container">
          <div className="scen-tracking-wrapper__skeleton--kpi-block">
            <div className="scen-tracking-wrapper__skeleton--kpi-value" />
            <div className="scen-tracking-wrapper__skeleton--kpi-label" />
          </div>
          <div className="scen-tracking-wrapper__skeleton--kpi-block">
            <div className="scen-tracking-wrapper__skeleton--kpi-value" />
            <div className="scen-tracking-wrapper__skeleton--kpi-label" />
          </div>
        </div>
      </div>
    </>
  )
}

export default ScenariosTrackingWrapperLoading
