import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"

import mime from "mime"
import beautify from "js-beautify"

import * as _ from "lodash"

import { PayloadAction } from "@reduxjs/toolkit"

import { selectors as UsersSelectors } from "./UsersSelectors"

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"

import {
  updateUsersProperties as updateUsersPropertiesApi,
  deleteUsersProperty as deleteUsersPropertyApi,
  checkUsersProperties as checkUsersPropertiesApi,
  createBmmUser,
  updateBmmUser,
  fetchUsers,
  deleteUser as deleteUserApi,
  updateDefaultUserSignature,
  removeDefaultUserSignature,
  updateLicence,
  fetchPartialUsers,
  fetchActiveLicencesCount,
  fetchSingleUser as fetchSingleUserApi,
  fetchUserSignatures as fetchUserSignaturesApi,
  fetchUserDefaultSignature as fetchUserDefaultSignatureApi,
  fetchUsersProperties,
  fetchUserIdsByQuery,
  importUsersToTenantAsExcelFile,
} from "./UsersApi"

import {
  changeDefaultSignatureAction,
  copyPreviewMailNotFoundAction,
  copyPreviewMailSuccessAction,
  copySignatureByUserAction,
  deletePropertyAction,
  deleteUserAction,
  fetchExcelModelAction,
  togglePropertyAction,
  updatePropertiesAction,
  updateUserLicenceSuccess,
  updateUserPhotoFailureAction,
  upsertLicenceAction,
  uploadExcelFileAction,
  upsertUserAction,
  fetchActiveLicencesCountAction,
} from "./UsersActions"

import {
  checkProperties as checkPropertiesAction,
  checkPropertiesFailure,
  checkPropertiesSuccess,
  deleteUserSuccess,
  fetchAllUsers,
  fetchAllUsersSuccess,
  fetchExcelModelSuccess,
  fetchPropertiesSuccess,
  previewMail,
  previewMailFailure,
  previewMailSuccess,
  setSampleUser as setSampleUserAction,
  setUser,
  uploadExcelFileSuccess,
  upsertUserSuccess,
  fetchProperties as fetchPropertiesAction,
  fetchAllUsersFailure,
  fetchPartialUsers as fetchPartialUsersAction,
  reloadPartialUsers as reloadPartialUsersAction,
  fetchPartialUsersSuccess,
  FetchPartialUsersActionPayload,
  setPartialUsersPageNumber,
  fetchActiveLicencesCountSuccess,
  addPartialUser,
  updatePartialUser,
  fetchSingleUser,
  fetchSingleUserSuccess,
  resetEditingUser,
  savingEditingUser,
  fetchDynamicUsers,
  DynamicGroupFields,
  fetchDynamicUsersSuccess,
  savingEditingUserFailure,
} from "./UsersReducers"

import {
  CheckPropertiesDto,
  UpdateUserPropertiesDto,
  UserProperties,
  UserProperty,
  ImportReport,
  User,
  UserDto,
  PartialUser,
  PreviewTemplate,
  PreviewTemplateDto,
} from "./UserModels"

import { usersSelectors } from "features/Users"

import { accountsSelectors } from "features/Accounts"
import { fillUserProperties } from "sagas/signatures.sagas"

import config from "config/config"
import { USER_PICTURE_UPLOAD_SUB_FOLDER } from "features/App/AppConstants"
import { TypeKeys, signaturesSelectors } from "features/Signatures"
import CrmContact from "entities/CrmContact"
import { updateCrmContact } from "features/Billings/BillingsApi"
import MailingContact from "entities/MailingContact"

import { getFileExtension } from "utils/ImageUtils"
import { navigateTo } from "features/App/AppSagas"
import HttpStatusCode from "utils/HttpStatusCode"
import { GlobalStates } from "store"

import StringHelper from "utils/StringHelper"

import Signature from "entities/Signature"
import TemplatesFactory from "entities/TemplatesFactory"
import { withSagaErrorHandler } from "features/Errors"

import { LoadingStatus, PaginatedResult, TypedResult } from "core/CoreModels"

import { syncFinishedAction } from "features/Accounts/AccountsActions"
import {
  deleteGroupSuccess,
  fetchAllGroups,
} from "features/Groups/GroupsReducer"
import { SignatureDto } from "features/Signatures/SignaturesModels"
import { updateMailingContact } from "features/Marketing/MarketingApi"

import { getUserSignature } from "features/Signatures/SignaturesApi"
import DynamicGroupsHelper from "utils/DynamicGroupsHelper"
import {
  updateSignatureSuccess,
  updateTemporarySignatureSuccess,
} from "features/Signatures/SignaturesReducer"

import { BuilderSignatureDto } from "features/BuilderSignatures/BuilderSignaturesModels"

import { SignatureMapper } from "features/Signatures/mappers"

import { uploadImageToStorageAsUrl } from "features/Storage/StorageApi"
import cuid from "cuid"

import { setOfferLimitations } from "features/Offers/OffersReducer"
import { USERS_URL } from "router/RouterConstants"
import { fetchSignatures } from "features/Signatures/SignaturesReducer"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { featureNames } from "config/config.features"
import { getOrLoadScenariosSlice } from "features/Scenarios/ScenarioSagas"
import { getOrLoadSignaturesSlice } from "features/Signatures/SignaturesSagas"
import { getOrLoadBuilderSignaturesSlice } from "features/BuilderSignatures/BuilderSignaturesSagas"
import { DEFAULT_SIGNATURE_WEIGHTING_VALUE } from "features/Signatures/SignaturesRules"

export function* getOrLoadAllUsersAsync() {
  const allUsersLoadingStatus = yield select(usersSelectors.getLoadingStatus)

  if (allUsersLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchAllUsers())
    yield take(fetchAllUsersSuccess.type)
  }

  return yield select(usersSelectors.getAllUsers)
}

function updateProperties(currentProperties, data) {
  const properties = []

  Object.keys(currentProperties).map((key) => {
    const property = currentProperties[key]
    const checkedProperty = data.find((p) => p.name === property.InternalName)
    if (checkedProperty && !checkedProperty.isSuccess) {
      property.isUnknown = true
      properties.push(property)
    }
    properties.push(property)
  })
  return properties
}

/**
 * Défini l'utilisateur par défaut
 * Utilise la configuration Config si ce dernier n'existe pas.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* setSampleUser(sampleUser) {
  if (sampleUser == null) sampleUser = config.defaultUser

  // gestion du cas spécial de la photo - même si l'utilisateur n'a pas de propriété photo, on utilise celle par défaut
  if (sampleUser.Properties.picture == null)
    sampleUser.Properties.picture = config.defaultPhotoUrl

  const updatedSampleUser = new User({ ...sampleUser })
  yield put(setSampleUserAction(updatedSampleUser))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateCrmContactAsync(action) {
  try {
    const userProperties = action.payload.properties
    // mise à jour du contact de la CRM
    const crmContact = new CrmContact()
    crmContact.Email = userProperties.email
    crmContact.Lastname = userProperties.lastname
    crmContact.Firstname = userProperties.firstname
    crmContact.Function = userProperties.jobtitle
    crmContact.Phone = userProperties.phone
    crmContact.Mobile = userProperties.mobile

    yield call(updateCrmContact, crmContact)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Update of MailingContact failed ${err}`)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateMailingListContactAsync(action) {
  try {
    const userProperties = action.payload.properties
    const contact = new MailingContact()
    contact.Email = userProperties.email
    contact.Attributes = {
      NOM: userProperties.lastname,
      PRENOM: userProperties.firstname,
      JOB_TITLE: userProperties.jobtitle,
    }
    yield call(updateMailingContact, contact)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Update of MailingContact failed ${err}`)
  }
}

function* uploadPhoto(dataUrl: string, fileName: string) {
  try {
    const fullFileName = `${USER_PICTURE_UPLOAD_SUB_FOLDER}${fileName}`

    const response = yield call(
      uploadImageToStorageAsUrl,
      dataUrl,
      fullFileName,
      mime.getType(getFileExtension(dataUrl)),
    )

    return response.result.url
  } catch (error) {
    yield put(updateUserPhotoFailureAction())
  }
}

function* uploadUserPicture(pictureDataUrl: string, photoFileName: string) {
  if (pictureDataUrl == null || pictureDataUrl === "") return null
  // la photo est déjà uploadée à une url, on retourne directement cette dernière
  if (pictureDataUrl.startsWith("http")) return pictureDataUrl

  try {
    const photoUrl = yield call(uploadPhoto, pictureDataUrl, photoFileName)
    return photoUrl
  } catch (error) {
    yield put(
      addNotification(
        new Notification(
          `Problème lors de l'upload de la photo ${error}.`,
          "ERROR",
        ),
      ),
    )
  }
}

function* getPreviewByUser(userId) {
  const partialUser = yield select((state: GlobalStates) =>
    usersSelectors.getPartialUserById(state, userId),
  )

  let username = partialUser?.username

  if (!partialUser) {
    const response = yield call(fetchSingleUserApi, userId)

    if (!response.success) throw new Error(response.errorMessage)

    const user = new User(response.result)

    username = user.Username
  }

  return yield call(getUserSignature, username)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* updateUsersProperties(
  action: PayloadAction<Array<UpdateUserPropertiesDto>>,
) {
  try {
    const response = yield call(updateUsersPropertiesApi, action.payload)
    if (!response.success) throw new Error(response.errorMessage)

    const properties = response.result.map(
      (userPropertyJson) => new UserProperty(userPropertyJson),
    )

    yield put(fetchPropertiesSuccess(properties))
  } catch (error) {
    yield put(addNotification(new Notification(error.message, "ERROR")))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* deleteUsersProperty(action: PayloadAction<UpdateUserPropertiesDto>) {
  try {
    const result = yield call(deleteUsersPropertyApi, action.payload)
    if (!result.success) throw new Error(result.errorMessage)

    yield put(fetchPropertiesAction())
  } catch (error) {
    yield put(addNotification(new Notification(error.message, "ERROR")))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* checkUsersProperties(action: PayloadAction<CheckPropertiesDto[]>) {
  try {
    const response = yield call(checkUsersPropertiesApi, action.payload)

    if (!response.success)
      throw new Error(JSON.parse(response.errorMessage).errorMessage)

    const currentProperties = yield select(
      UsersSelectors.getUsersPropertiesById,
    )

    const propertiesbyId = yield call(
      updateProperties,
      currentProperties,
      response.result,
    )

    yield put(checkPropertiesSuccess(propertiesbyId))
  } catch (error) {
    yield put(addNotification(new Notification(error.message, "ERROR")))
    yield put(checkPropertiesFailure())
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getAllUsersAsync() {
  try {
    const response: TypedResult<UserDto[]> = yield call(fetchUsers)

    if (!response.success) throw new Error(response.errorMessage)

    const bmmUsersJson = response.result
    const bmmUsers = bmmUsersJson.map((userJson) => new User(userJson))
    const currentAccount = yield select(accountsSelectors.getCurrentAccount)

    const currentUser = bmmUsers.find(
      (user) => user.Username === currentAccount.UserName,
    )

    // définition de l'utilisateur à utiliser pour les prévisualisations de signatures
    yield call(setSampleUser, currentUser)

    yield put(fetchAllUsersSuccess(bmmUsers))
    // on remplace les tags dans les signatures si ce n'est pas déjà fait
    yield call(fillUserProperties)
  } catch (error) {
    yield put(fetchAllUsersFailure())
  }
}

/**
 * Upload la photo à partir de la propriété `picture` si elle est renseignée
 * Créer/Modifier un utilisateur BMM
 * Créer/Modifier les propriétés affectées à cet utilisateur
 * retourne l'utilisateur créé/modifié
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* upsertBmmUser(
  action: PayloadAction<{
    userId: number
    properties: UserProperties
    redirect: string
  }>,
) {
  const emailUsernameIsActive = yield select(
    isFeatureActive(featureNames.EMAIL_USERNAME),
  )
  const isNew =
    action.payload.userId === undefined || action.payload.userId === -1

  if (!isNew) yield put(savingEditingUser())

  // Upload de la photo de l'utilisateur et mise à jour de la nouvelle adresse si l'upload est correct
  if (action.payload.properties.picture) {
    const photoDataUrl = action.payload.properties.picture
    const extension = getFileExtension(photoDataUrl)
    const photoFileName = cuid().slice(8) + "." + extension
    action.payload.properties.picture = yield call(
      uploadUserPicture,
      photoDataUrl,
      photoFileName,
    )
  }
  const filledProperties = Object.keys(action.payload.properties)
    .filter((k) => action.payload.properties[k] != null)
    .filter((k) => k !== "username")

  const userProperties = filledProperties.map((p) => ({
    internalName: p,
    value: action.payload.properties[p],
  }))

  const userJson = {
    Username: emailUsernameIsActive
      ? action.payload.properties.username
      : action.payload.properties.mail,
    Properties: userProperties,
  }

  let response: TypedResult<UserDto>

  if (isNew) {
    // Cas d'une creation d'utilisateur
    response = yield call(createBmmUser, userJson)
  } else {
    // Cas d'une modification d'un utilisateur existant
    response = yield call(updateBmmUser, action.payload.userId, userJson)
  }

  if (!response.success) {
    yield put(
      addNotification(
        new Notification(
          `Erreur lors de la ${
            isNew ? "création" : "modification"
          } de l'utilisateur`,
          "ERROR",
        ),
      ),
    )
    yield put(savingEditingUserFailure())
    return
  }

  const user = new User(response.result)
  const userPartial = new PartialUser(response.result)

  if (isNew) {
    yield put(addPartialUser(userPartial))
  } else {
    yield put(updatePartialUser(userPartial))
  }
  yield put(upsertUserSuccess(user))

  if (isNew) {
    // Active par défaut la licence d'un nouvel utilisateur
    yield put(upsertLicenceAction(user.Id, true))
  }

  yield put(
    addNotification(
      new Notification(
        `Utilisateur ${isNew ? "ajouté" : "modifié"} avec succès.`,
        "SUCCESS",
      ),
    ),
  )

  yield put(resetEditingUser())

  return user
}

/**
 * Récupère le lien du modèle Excel
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchExcelModel() {
  const excelModelLink = `${config.cdnUrl}/documents/Bmm-Import-Users.xlsx`
  yield put(fetchExcelModelSuccess(excelModelLink))
  return excelModelLink
}

/**
 * Upload un fichier Excel pour importer les utilisateurs
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* uploadExcelFile(action: PayloadAction<File>) {
  try {
    yield put(
      addNotification(
        new Notification(`Votre import est en cours de traitement`, "INFO"),
      ),
    )
    const result = yield call(importUsersToTenantAsExcelFile, action.payload)

    if (!result.success) throw new Error(result.errorMessage)

    const report = new ImportReport(result.result)

    yield put(fetchAllUsers())

    yield put(setPartialUsersPageNumber(1))
    yield put(fetchAllGroups())
    yield navigateTo(USERS_URL)
    yield put(uploadExcelFileSuccess(report))
    yield put(
      addNotification(
        new Notification(`Votre import s'est terminée avec succès.`, "INFO"),
      ),
    )
  } catch (error) {
    yield put(addNotification(new Notification(error.message, "ERROR")))
  }
}

/**
 * Supprime un utilisateur
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* deleteUser(action: PayloadAction<number>) {
  // Supprime l'utilisateur -- quand on supprime un User, sa licence est aussi désactivée/supprimée
  const response = yield deleteUserApi(action.payload)

  if (!response.success)
    throw new Error(`Erreur lors de la suppression de l'utilisateur`)

  const currentPage = yield select(usersSelectors.getPartialUsersCurrentPage)
  const lastPage = yield select(usersSelectors.getPartialUsersLastPage)
  const partialUsers = yield select(usersSelectors.getAllPartialUsers)

  if (currentPage === lastPage && partialUsers.length <= 1)
    yield put(setPartialUsersPageNumber(lastPage === 1 ? 1 : currentPage - 1))

  yield put(deleteUserSuccess(action.payload))

  yield put(reloadPartialUsersAction())
}

/**
 * Mise à jour de la signature par défaut de l'utilisateur
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* updateDefaultUserSignatures(
  action: PayloadAction<{ userId: number; signatureId: number }>,
) {
  let response: TypedResult<void>

  if (action.payload.signatureId !== -1) {
    response = yield call(
      updateDefaultUserSignature,
      action.payload.userId,
      action.payload.signatureId,
    )
  } else {
    response = yield call(removeDefaultUserSignature, action.payload.userId)
  }

  if (response && !response.success) throw new Error(response.errorMessage)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* previewByUser(action: PayloadAction<number>) {
  const [allSignatures, allBuilderSignatures] = yield all([
    call(getOrLoadSignaturesSlice, "all"),
    call(getOrLoadBuilderSignaturesSlice, "all"),
    call(getOrLoadScenariosSlice, "all"),
  ])

  const legacySignaturesWeighting = allSignatures.map((s) => ({
    id: s.Id,
    weighting: s.Weighting,
  }))

  const builderSignaturesWeighting = allBuilderSignatures.map((s) => ({
    id: s.id,
    weighting: s.weighting,
  }))

  const signaturesWeighting = [
    ...legacySignaturesWeighting,
    ...builderSignaturesWeighting,
  ]

  const response: TypedResult<PreviewTemplateDto[]> = yield call(
    getPreviewByUser,
    action.payload,
  )

  if (!response.success) throw new Error(response.errorMessage)

  if (response.success) {
    const templates = (response.result || []).map((template, i) => {
      const relatedSignature = signaturesWeighting.find(
        (s) => s.id === template.signatureId,
      )

      return new PreviewTemplate(
        template,
        relatedSignature?.weighting || DEFAULT_SIGNATURE_WEIGHTING_VALUE,
        i,
      )
    })

    yield put(previewMailSuccess(templates))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* upsertLicenceAsync(
  action: PayloadAction<{ id: number; isActivate: boolean }>,
) {
  const response = yield call(
    updateLicence,
    action.payload.id,
    action.payload.isActivate,
  )

  if (!response.success)
    throw new Error(`Erreur lors de la requete: ${response.errorMessage}`)
  if (response.limitationsReach) {
    yield put(setOfferLimitations("LicencesLimitations"))
    return
  }
  if (response.result.IsExpired) throw new Error("Offre expirée")

  if (response.result !== null) {
    const updatedUser = new User(response.result)
    const updatedPartialUser = new PartialUser(response.result)
    // On vérifie que le retour est cohérent avec l'envoi
    if (updatedUser.Id !== action.payload.id)
      yield put(
        addNotification(
          new Notification(
            "La licence mise à jour ne correspond pas à l'utilisateur coché ou à l'offre",
            "ERROR",
          ),
        ),
      )

    yield all([
      put(fetchActiveLicencesCountAction()),
      put(updateUserLicenceSuccess()),
      put(fetchSignatures("all")),
      put(setUser(updatedUser)),
      put(updatePartialUser(updatedPartialUser)),
      put(resetEditingUser()),
    ])
  }
}

function* previewMailNotFound() {
  yield put(copyPreviewMailNotFoundAction())
  yield put(
    addNotification(new Notification("Pas de signature à copier", "SUCCESS")),
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* copySignatureByUser(action: PayloadAction<number>) {
  let template = ""

  const signatureResult: TypedResult<PreviewTemplateDto[]> = yield call(
    getPreviewByUser,
    action.payload,
  )

  if (!signatureResult.success) throw new Error(signatureResult.errorMessage)

  if (
    signatureResult.httpStatus === HttpStatusCode.NOT_FOUND ||
    signatureResult.result === null
  ) {
    yield call(previewMailNotFound)
  }

  const signatureTemplates = signatureResult.result.filter(
    (template) => template.signatureId !== null,
  )

  if (signatureResult.success && signatureTemplates.length > 0) {
    template = signatureResult.result[0].content

    const options = { indent_size: 2, unformatted: [] }

    const beautified = beautify.html(template, options)

    yield call(StringHelper.writeToClipboard, beautified)

    yield put(copyPreviewMailSuccessAction())
    yield put(
      addNotification(
        new Notification(
          "La signature a été copiée dans le presse-papier",
          "SUCCESS",
        ),
      ),
    )
  } else {
    yield call(previewMailNotFound)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* fetchProperties() {
  const response = yield call(fetchUsersProperties)

  // on compare les propriétés disponibles avec le template courant s'il est défini
  yield select(signaturesSelectors.getSelectedTemplate)

  const properties = response.result.map(
    (userPropertyJson) => new UserProperty(userPropertyJson),
  )

  yield put(fetchPropertiesSuccess(properties))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* toggleProperties(action: PayloadAction<UserProperty>) {
  const activeTemplate = yield select(signaturesSelectors.getSelectedTemplate)

  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )

  const activeSignatureClone = _.cloneDeep(activeSignature)

  const property = new UserProperty(action.payload)

  property.Visible = !action.payload.Visible

  if (property.Visible) {
    activeSignatureClone.Template.userProperties.push(property.InternalName)
  } else {
    const index = activeSignatureClone.Template.userProperties.indexOf(
      property.InternalName,
    )
    activeSignatureClone.Template.userProperties.splice(index, 1)
  }

  const template = TemplatesFactory.DeepCopy(activeTemplate)

  activeSignatureClone.Template.UpdateTemplate(template)

  if (!activeSignatureClone.Id)
    yield put(updateTemporarySignatureSuccess(activeSignatureClone))
  else yield put(updateSignatureSuccess(activeSignatureClone))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* insertBannerProperties() {
  yield put({
    type: TypeKeys.INSERT_BANNER_PROPERTY,
    adProp: new UserProperty({
      internalName: "banner",
      displayName: "Bannière",
    }),
  })
}

function* fetchPartialUsersAsync(
  action: PayloadAction<FetchPartialUsersActionPayload>,
) {
  const { pageSize, pageNumber, searchString, orderBy } = action.payload
  const response: PaginatedResult<UserDto[]> = yield call(fetchPartialUsers, {
    pageSize,
    pageNumber,
    searchString,
    orderBy,
  })

  if (!response.success) throw new Error(response.errorMessage)

  const partialUsers = response.result.map(
    (partialUser) => new PartialUser(partialUser),
  )

  yield put(
    fetchPartialUsersSuccess({
      partialUsers,
      currentPage: response.currentPage,
      lastPage: response.lastPage,
      totalCount: response.totalCount,
      pageSize: response.pageSize,
    }),
  )
}

function* reloadPartialUsersAsync() {
  const pageSize = yield select(usersSelectors.getPartialUsersPageSize)
  const pageNumber = yield select(usersSelectors.getPartialUsersCurrentPage)
  const searchString = yield select(usersSelectors.getPartialUsersSearchString)
  const orderBy = yield select(usersSelectors.getPartialUsersOrderBy)

  const actionPayload = {
    pageSize,
    pageNumber,
    searchString,
    orderBy,
  }

  const fetchPartialUsersAction = {
    payload: actionPayload,
    type: fetchAllUsers.type,
  } as PayloadAction<FetchPartialUsersActionPayload>

  yield call(fetchPartialUsersAsync, fetchPartialUsersAction)
}

function* fetchActiveLicencesCountAsync() {
  const response: TypedResult<number> = yield call(fetchActiveLicencesCount)

  if (!response.success) throw new Error(response.errorMessage)

  yield put(fetchActiveLicencesCountSuccess(response.result))
}

function* fetchSingleUserAsync(action: PayloadAction<number>) {
  const response: TypedResult<UserDto> = yield call(
    fetchSingleUserApi,
    action.payload,
  )

  if (!response.success) throw new Error(response.errorMessage)

  return new User(response.result)
}

function* fetchAssignedSignaturesAsync(action: PayloadAction<number>) {
  const response: TypedResult<Array<SignatureDto | BuilderSignatureDto>> =
    yield call(fetchUserSignaturesApi, action.payload)

  if (!response.success) throw new Error(response.errorMessage)

  return response.result.map((signature) => SignatureMapper.create(signature))
}

function* fetchDefaultSignatureAsync(action: PayloadAction<number>) {
  const response: TypedResult<SignatureDto | BuilderSignatureDto> = yield call(
    fetchUserDefaultSignatureApi,
    action.payload,
  )
  if (!response.success) throw new Error(response.errorMessage)
  if (!response.result) return null
  return SignatureMapper.create(response.result)
}

function* fetchSingleUserDataAsync(action: PayloadAction<number>) {
  const [user, assignedSignatures, defaultSignature] = yield all([
    call(fetchSingleUserAsync, action),
    call(fetchAssignedSignaturesAsync, action),
    call(fetchDefaultSignatureAsync, action),
  ])

  yield put(
    fetchSingleUserSuccess({ user, assignedSignatures, defaultSignature }),
  )
}

function* fetchDynamicUsersAsync(action: PayloadAction<DynamicGroupFields>) {
  const query = DynamicGroupsHelper.dataToQuery(action.payload)
  const response: TypedResult<number[]> = yield call(fetchUserIdsByQuery, query)
  if (!response.success) throw new Error(response.errorMessage)
  yield put(fetchDynamicUsersSuccess(response.result))
}

export const usersWatchers = [
  takeLatest(updatePropertiesAction.type, updateUsersProperties),
  takeLatest(deletePropertyAction.type, deleteUsersProperty),
  takeLatest(checkPropertiesAction.type, checkUsersProperties),
  takeEvery(fetchAllUsers.type, getAllUsersAsync),
  takeEvery(deleteGroupSuccess.type, getAllUsersAsync),
  takeEvery(syncFinishedAction.type, getAllUsersAsync),
  takeEvery(upsertUserAction.type, withSagaErrorHandler(upsertBmmUser)),
  takeEvery(fetchExcelModelAction.type, fetchExcelModel),
  takeEvery(uploadExcelFileAction.type, uploadExcelFile),
  takeEvery(deleteUserAction.type, deleteUser),
  takeEvery(
    changeDefaultSignatureAction.type,
    withSagaErrorHandler(updateDefaultUserSignatures),
  ),
  takeEvery(
    previewMail.type,
    withSagaErrorHandler(previewByUser, previewMailFailure()),
  ),
  takeEvery(upsertLicenceAction.type, withSagaErrorHandler(upsertLicenceAsync)),
  takeEvery(
    copySignatureByUserAction.type,
    withSagaErrorHandler(copySignatureByUser),
  ),
  takeEvery(fetchPropertiesAction.type, fetchProperties),
  takeEvery(togglePropertyAction.type, withSagaErrorHandler(toggleProperties)),
  takeEvery(TypeKeys.INSERT_BANNER_PROPERTY, insertBannerProperties),
  takeLatest(fetchPartialUsersAction.type, fetchPartialUsersAsync),
  takeLatest(reloadPartialUsersAction.type, reloadPartialUsersAsync),
  takeLatest(
    [fetchActiveLicencesCountAction.type, deleteUserSuccess.type],
    withSagaErrorHandler(fetchActiveLicencesCountAsync),
  ),
  takeLatest(
    fetchSingleUser.type,
    withSagaErrorHandler(fetchSingleUserDataAsync),
  ),
  takeLatest(
    fetchDynamicUsers.type,
    withSagaErrorHandler(fetchDynamicUsersAsync),
  ),
]
