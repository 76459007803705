import { FunctionComponent, useMemo } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import { ScenarioTracking } from "features/Tracking/TrackingModels"

interface ScenariosTrackingSliderItemProps {
  scenario: ScenarioTracking
  handleSelect: (id: number) => void
}

const messages = defineMessages({
  unique: {
    id: "Tracking.ScenariosTrackingSliderUnique",
    defaultMessage: "Unique",
  },
  scenario: {
    id: "Tracking.ScenariosTrackingSliderScenario",
    defaultMessage: "Scénario",
  },
})

const ScenariosTrackingSliderItem: FunctionComponent<
  ScenariosTrackingSliderItemProps
> = ({ scenario, handleSelect }) => {
  const { formatMessage } = useIntl()

  const firstBannerUrl = useMemo(() => {
    const firstBanner = scenario.banners.find((banner) => banner.order === 0)
    return firstBanner?.imageUrl
  }, [scenario])

  const scenarioModeLabel =
    scenario.scenarioMode === "simple" ? messages.unique : messages.scenario

  return (
    <div
      className="scen-track-slider__scenarios--container"
      key={scenario.id}
      onClick={() => handleSelect(scenario.id)}
    >
      <div
        className={classNames(
          "scen-track-slider__scenarios--tooltip",
          scenario.scenarioMode === "simple" ? "is-unique" : "is-scenario",
        )}
      >
        {formatMessage(scenarioModeLabel)}
      </div>
      <img
        className="scen-track-slider__scenarios--banner"
        src={firstBannerUrl}
      />
    </div>
  )
}

export default ScenariosTrackingSliderItem
