import { FunctionComponent, ReactNode } from "react"

import { useSelector } from "react-redux"

import { Navigate } from "react-router-dom"

import { offersSelectors } from "features/Offers"

import { EXPIRED_URL } from "router/RouterConstants"

interface WithValidOfferProps {
  children: ReactNode
  needOfferValid?: boolean
}

const WithValidOffer: FunctionComponent<WithValidOfferProps> = ({
  children,
  needOfferValid,
}) => {
  const isCurrentValidOffer = useSelector(
    offersSelectors.checkCurrentTenantOfferValidity,
  )

  if (needOfferValid && !isCurrentValidOffer)
    return <Navigate to={EXPIRED_URL} />

  return <>{children}</>
}

export default WithValidOffer
