import React from "react"

interface Props {
  children: React.ReactNode
  onClick()
  disabled?: boolean
  header?: Element | React.ReactElement
  imageSrc?: string
}

const ButtonCard: React.FC<Props> = (props) => {
  const { children, disabled, imageSrc, header, onClick } = props

  return (
    <div
      onClick={onClick}
      className={`button-card ${disabled ? "button-card--disabled" : ""}`}
    >
      {header && <div className="button-card__header">{header}</div>}
      {imageSrc && (
        <div className="button-card__image__container">
          <img className="button-card__image" src={imageSrc} />
        </div>
      )}
      <div className="button-card__label">{children}</div>
    </div>
  )
}

export default ButtonCard
