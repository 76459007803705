import { connect } from "react-redux"
import LogoPicker from "./LogoPicker"
import { signaturesSelectors } from "features/Signatures"

import {
  cropLogoAction,
  updateLogoSizeAction,
  updateSignatureLogoAction,
} from "features/Signatures/SignaturesActions"
import { addNotification, Notification } from "features/Notifications"

interface StateToProps {
  imageUrl: string
  cropWidth: number
  cropHeight: number
  max: { width: number; height: number }
  size: { width: number; height: number }
}

interface DispatchToProps {
  onImageChange: (logo: string) => void
  onImageResize(width: number, height: number)
  onImageCrop(baseUrl: string, width: number, height: number)
  onSizeLimitReach(message: Notification)
}

const mapStateToProps = (state) => ({
  imageUrl: signaturesSelectors.getActiveSignatureLogoUrl(state),
  cropWidth: signaturesSelectors.getLogoIdealSize(state).width,
  cropHeight: signaturesSelectors.getLogoIdealSize(state).height,
  max: signaturesSelectors.getLogoMaxSize(state),
  size: signaturesSelectors.getLogoSize(state),
})

const dispatchToProps = {
  onImageChange: updateSignatureLogoAction,
  onImageCrop: cropLogoAction,
  onImageResize: updateLogoSizeAction,
  onSizeLimitReach: addNotification,
}

interface OwnProps {
  showPreview?: boolean
}

const ConnectedImagePicker = connect<StateToProps, DispatchToProps, OwnProps>(
  mapStateToProps,
  dispatchToProps,
)(LogoPicker)

export default ConnectedImagePicker
