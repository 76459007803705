import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import SearchField from "components/SearchField"
import PillButtonsGroup from "components/PillButtonsGroup"
import SelectField from "components/SelectField"

import { FilterValuesType } from "../signaturesListLayoutUtilities"
import { SelectedOption } from "components/SelectField/SelectField"

interface SignaturesListLayoutSubHeaderProps {
  filter: FilterValuesType
  handleSearch: (searchValue: string) => void
  handleChangeFilter: (filterValue: string) => void
  handleChangeSort: (option: SelectedOption) => void
}

const messages = defineMessages({
  searchPlaceholder: {
    id: "SignaturesListLayout.SearchPlaceholder",
    defaultMessage: "Rechercher",
  },
  all: {
    id: "SignaturesListLayout.AllSignatures",
    defaultMessage: "Toutes",
  },
  activated: {
    id: "SignaturesListLayout.ActivatedSignatures",
    defaultMessage: "Activées",
  },
  deactivated: {
    id: "SignaturesListLayout.DeactivatedSignatures",
    defaultMessage: "Désactivées",
  },
  alphabeticalOrder: {
    id: "SignaturesListLayout.AlphabeticalOrder",
    defaultMessage: "Ordre alphabétique",
  },
  creationDate: {
    id: "SignaturesListLayout.CreationDate",
    defaultMessage: "Date de création",
  },
  lastUpdate: {
    id: "SignaturesListLayout.LastUpdate",
    defaultMessage: "Dernière modification",
  },
  weighting: {
    id: "SignaturesListLayout.Priority",
    defaultMessage: "Priorité",
  },
})

const SignaturesListLayoutSubHeader: FunctionComponent<
  SignaturesListLayoutSubHeaderProps
> = ({ filter, handleSearch, handleChangeFilter, handleChangeSort }) => {
  const { formatMessage } = useIntl()

  const defaultValue = {
    value: "alphabeticalOrder",
    label: formatMessage(messages.alphabeticalOrder),
  }

  const filterOptions = [
    { id: "all", children: <>{formatMessage(messages.all)}</> },
    { id: "activated", children: <>{formatMessage(messages.activated)}</> },
    { id: "deactivated", children: <>{formatMessage(messages.deactivated)}</> },
  ]

  const sortOptions = [
    { ...defaultValue },
    { value: "creationDate", label: formatMessage(messages.creationDate) },
    { value: "lastUpdate", label: formatMessage(messages.lastUpdate) },
    { value: "weighting", label: formatMessage(messages.weighting) },
  ]

  return (
    <div className="signatures-list-layout__sub-header">
      <div className="signatures-list-layout__sub-header--container">
        <SearchField
          onSearch={handleSearch}
          placeholder={formatMessage(messages.searchPlaceholder)}
        />
        <PillButtonsGroup
          current={filter}
          onChange={handleChangeFilter}
          pillButtons={filterOptions}
        />
        <SelectField
          options={sortOptions}
          defaultValue={defaultValue}
          onChange={handleChangeSort}
          isSearchable={false}
        />
      </div>
    </div>
  )
}

export default SignaturesListLayoutSubHeader
