import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Notification } from "./NotificationModels"

const initialState = []

const notificationReducer = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      return [...state, action.payload]
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
      return state.filter((notif) => notif.id !== action.payload)
    },
  },
})

export const { addNotification, deleteNotification } =
  notificationReducer.actions
export default notificationReducer.reducer
