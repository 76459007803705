import { connect } from "react-redux"

// Redux
import { accountsSelectors } from "features/Accounts"

// Custom Components
import ProfileHeader from "./ProfileHeader"
import { tourGuideActionCreators } from "features/TourGuide"
import { offersSelectors } from "features/Offers"

const mapStateToProps = (state) => ({
  identity: accountsSelectors.getCurrentAccount(state),
  offer: offersSelectors.getCurrentOffer(state),
})

const mapDispatchToProps = {
  resetTour: tourGuideActionCreators.resetTour,
}

interface StateProps {
  identity: any
  offer: any
}

interface DispatchProps {
  resetTour()
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileHeader)
