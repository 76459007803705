import { Fragment, FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import { Link } from "react-router-dom"

import { HoverCardContent } from "features/Accounts/AccountsModels"

interface PreviewCardProps {
  title: string
  visual: string
  url: string
  content: HoverCardContent["content"]
}

const messages = defineMessages({
  cta: {
    id: "OnBoarding.CalltoActionButton",
    defaultMessage: "Je me lance",
  },
})

const PreviewCard: FunctionComponent<PreviewCardProps> = ({
  title,
  visual,
  url,
  content,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="onboarding-tile-preview-card">
      <div className="onboarding-tile-preview-card__informations">
        <p className="onboarding-tile-preview-card__informations--title">
          {title}
        </p>
        <div className="onboarding-tile-preview-card__content-grid">
          {content.map((contentItem, i) => (
            <Fragment key={i}>
              <div className="onboarding-tile-preview-card__content-grid--index-container">
                <p className="onboarding-tile-preview-card__content-grid--index">
                  {`${i + 1}.`}
                </p>
              </div>
              <div className="onboarding-tile-preview-card__content-grid--container">
                <p className="onboarding-tile-preview-card__content-grid--title">
                  {contentItem.title}
                </p>
                <div className="onboarding-tile-preview-card__content-grid--items">
                  {contentItem.elements.map((el, i) => (
                    <p
                      key={i}
                      className="onboarding-tile-preview-card__content-grid--item"
                    >
                      {contentItem.elements.length > 1 ? `${i + 1}. ${el}` : el}
                    </p>
                  ))}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        <Link
          className="onboarding-tile-preview-card__informations--cta"
          to={url}
        >
          {formatMessage(messages.cta)}
        </Link>
      </div>
      <div className="onboarding-tile-preview-card__visual--container">
        <img
          src={visual}
          alt=""
          className="onboarding-tile-preview-card__visual"
        />
      </div>
    </div>
  )
}

export default PreviewCard
