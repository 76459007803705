import { FunctionComponent, useState, useCallback } from "react"

interface SignaturePreviewProps {
  template: string
  cardHeight?: number
}

const SignaturePreview: FunctionComponent<SignaturePreviewProps> = ({
  template,
  cardHeight = 140,
}) => {
  const [zoom, setZoom] = useState<string | number>(1)
  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        const height = node.getBoundingClientRect().height
        if (!height) setZoom(0.65)
        // calcul du dezoom nécessaire si la taille est > à la taille de la card
        if (height > cardHeight) {
          const result = (100 * cardHeight) / height
          return setZoom(result + "%")
        }
        return setZoom(1)
      }
    },
    [cardHeight],
  )

  return (
    <div
      ref={measuredRef}
      dangerouslySetInnerHTML={{
        __html: template,
      }}
      style={{ zoom }}
    />
  )
}

export default SignaturePreview
