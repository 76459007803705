import { FunctionComponent } from "react"

import CheckBox from "components/Checkbox"

interface ScenarioBySignaturesCardHeaderProps {
  name: string
  checked: boolean
  onCheck: () => void
}

const ScenarioBySignaturesCardHeader: FunctionComponent<
  ScenarioBySignaturesCardHeaderProps
> = ({ name, checked, onCheck }) => {
  return (
    <div className="scen-by-sign-card__header">
      <p className="scen-by-sign-card__header--name">{name}</p>
      <CheckBox name="select-signature" checked={checked} onChange={onCheck} />
    </div>
  )
}

export default ScenarioBySignaturesCardHeader
