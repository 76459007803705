import { FunctionComponent, useEffect } from "react"

import clsx from "clsx"

import { defineMessages, useIntl } from "react-intl"

import { useSearchParams } from "react-router-dom"

import outlookLogo from "features/Parameters/assets/install_panel_outlook.webp"
import windowsLogo from "features/Parameters/assets/install_panel_windows.webp"

import InstallerOutlook from "./components/InstallerOutlook"
import InstallerWindows from "./components/InstallerWindows"

const messages = defineMessages({
  title: {
    id: "InstallerPanel.Title",
    defaultMessage: "Installation",
  },
  addin: {
    id: "InstallerPanel.AddInOutlook",
    defaultMessage: "Add-In Outlook",
  },
  windows: {
    id: "InstallerPanel.AppWindows",
    defaultMessage: "Application Windows",
  },
})

const DEFAULT_TAB = "addin"

const INSTALLER_PANEL_TABS = [
  { id: "addin", label: messages.addin },
  { id: "windows", label: messages.windows },
]

export const PARAM_NAME = "app"

const InstallerPanel: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()

  const currentTab = searchParams.get(PARAM_NAME)

  const handleUpdateTab = (newTab: string) => {
    searchParams.set(PARAM_NAME, newTab)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (
      !currentTab ||
      !INSTALLER_PANEL_TABS.map((tab) => tab.id).includes(currentTab)
    ) {
      searchParams.set(PARAM_NAME, DEFAULT_TAB)
      setSearchParams(searchParams)
    }
  }, [searchParams])

  const subTabs = {
    addin: <InstallerOutlook />,
    windows: <InstallerWindows />,
  }

  return (
    <div className="installer-panel">
      <p className="installer-panel__title">{formatMessage(messages.title)}</p>
      <div className="installer-panel__container">
        <div className="installer-panel__sub-tabs">
          <div className="installer-panel__sub-tabs--container">
            <button
              className={clsx(
                "installer-panel__sub-tabs--pill",
                currentTab === "addin" && "is-selected",
              )}
              type="button"
              onClick={() => {
                handleUpdateTab("addin")
              }}
            >
              <img
                alt="outlook logo"
                className="installer-panel__sub-tabs--logo"
                src={outlookLogo}
              />
              <span className="installer-panel__sub-tabs--label">
                {formatMessage(messages.addin)}
              </span>
            </button>
            <button
              className={clsx(
                "installer-panel__sub-tabs--pill",
                currentTab === "windows" && "is-selected",
              )}
              type="button"
              onClick={() => {
                handleUpdateTab("windows")
              }}
            >
              <img
                alt="windows logo"
                className="installer-panel__sub-tabs--logo"
                src={windowsLogo}
              />
              <span className="installer-panel__sub-tabs--label">
                {formatMessage(messages.windows)}
              </span>
            </button>
          </div>
        </div>
        {currentTab && (
          <div className="installer-panel__content">{subTabs[currentTab]}</div>
        )}
      </div>
    </div>
  )
}

export default InstallerPanel
