/**
 * Représente un utilisateur assigné à une signature
 * @class SignatureAssignation
 */
class SignatureAssignation {
  id: number
  label: string
  firstName: string
  lastName: string
  usage: boolean

  constructor(id: number, firstName: string, lastName: string, usage: boolean) {
    this.id = id
    this.label =
      (firstName ? firstName.toLowerCase() : "") +
      (firstName && lastName ? " " : "") +
      (lastName ? lastName.toLowerCase() : "")
    this.firstName = firstName ? firstName.toLowerCase() : ""
    this.lastName = lastName ? lastName.toLowerCase() : ""
    this.usage = usage
  }
}

export default SignatureAssignation
