import { FunctionComponent } from "react"

import { useSelector } from "react-redux"

import useSignaturesTileDatas from "../hooks/useSignaturesTileDatas"

import Tile from "features/Accounts/components/HomePageSingleTile"

import SignaturesTileActiveCountLoading from "./SignaturesTileActiveCountLoading"
import SignaturesTileActiveCount from "./SignaturesTileActiveCount"
import SignaturesTileNewMailCount from "./SignaturesTileNewMailCount"

import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"

import { featureNames } from "config/config.features"

import { LoadingStatus } from "core/CoreModels"

interface SignaturesTileAsColumnDatasProps {
  to: string
  title: string
  addLabel: string
  label: string
  addSignature: () => void
}

const SignaturesTileAsColumnDatas: FunctionComponent<
  SignaturesTileAsColumnDatasProps
> = ({ to, title, addLabel, label, addSignature }) => {
  const { loadingStatus, count } = useSignaturesTileDatas()
  const isNewMailInResponseFeatureActive = useSelector(
    isFeatureActive(featureNames.NEWMAIL_INRESPONSE),
  )

  if (count.all === 0 && loadingStatus === LoadingStatus.LOADED)
    return (
      <Tile.AddButton to={to} isColumn onClick={addSignature}>
        {title}
      </Tile.AddButton>
    )

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <>
        <SignaturesTileActiveCountLoading>
          {label}
        </SignaturesTileActiveCountLoading>
        <div className="signature-tile__datas--add-btn-container">
          <Tile.AddButton isFullsize to={to} onClick={addSignature}>
            {addLabel}
          </Tile.AddButton>
        </div>
      </>
    )

  return (
    <>
      <SignaturesTileActiveCount activeCount={count.active}>
        {label}
      </SignaturesTileActiveCount>
      {isNewMailInResponseFeatureActive && (
        <SignaturesTileNewMailCount
          newMailcount={count.newMail}
          inResponseCount={count.inResponse}
        />
      )}
      <div className="signature-tile__datas--add-btn-container">
        <Tile.AddButton isFullsize onClick={addSignature} to={to}>
          {addLabel}
        </Tile.AddButton>
      </div>
    </>
  )
}

export default SignaturesTileAsColumnDatas
