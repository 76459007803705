import { GlobalStates } from "store"
import { Language } from "./AppModels"

const getRedirectTo = (state: GlobalStates): string => state.app.redirectTo

const getAutoSaveStatus = (state: GlobalStates): boolean => state.app.isHidden

const getLocale = (state: GlobalStates) => state.app.locales

const getSelectedLang = (state: GlobalStates) => state.app.selectedLocale

const getTranslations = (state: GlobalStates) => state.app.locales

const getLocaleAsArray = (state: GlobalStates): Language[] => {
  if (state.app.locales === null) return []

  return Object.values(state.app.locales)
}

const getGlobalLoadingStatus = (state: GlobalStates) => state.app.globalLoading

const getTranslationText = (id: string) => (state: GlobalStates) => {
  const locale = getSelectedLang(state)
  if (
    state.app.locales[locale] == null ||
    state.app.locales[locale].translations[id] == null
  )
    return id
  return state.app.locales[locale].translations[id]
}

export const selectors = {
  getRedirectTo,
  getAutoSaveStatus,

  getLocale,
  getSelectedLang,
  getTranslations,
  getLocaleAsArray,
  getGlobalLoadingStatus,
  getTranslationText,
}
