import React from "react"

import { Slide } from "@material-ui/core"

import LuccaToggle from "./components/LuccaToggle"

import LuccaParamsForm from "./LuccaParamsForm"

import Feature from "features/Features/FeaturesModels"

interface Props {
  featureLucca: Feature
  updateFeature(feature: Feature)
  launchJob(feature: Feature)
}

const LuccaFeature: React.FC<Props> = ({
  featureLucca,
  updateFeature,
  launchJob,
}) => {
  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [slide, setSlide] = React.useState<boolean>(false)

  const handleToggle = (isActive: boolean) => {
    if (isActive) {
      setShowForm(true)
      setSlide(true)
    } else {
      featureLucca.activated = false
      updateFeature(featureLucca)
    }
  }

  const onActiveForm = (active: boolean) => {
    setShowForm(active)
    setSlide(active)
  }

  const onSave = (dataform: { [x: string]: unknown }) => {
    const featureCopy = JSON.parse(JSON.stringify(featureLucca))
    Object.keys(dataform).map((key) => {
      const index = featureCopy.properties.findIndex(
        (property) => property.property === key,
      )
      featureCopy.properties[index].value = dataform[key]
    })
    featureCopy.activated = true
    updateFeature(featureCopy)
    onActiveForm(false)
  }

  return (
    <div className="lucca-feature" data-cy="lucca-feature">
      {showForm ? (
        <Slide
          direction="right"
          in={slide}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <div>
            <LuccaParamsForm
              featureLucca={featureLucca}
              onActiveForm={onActiveForm}
              onSave={onSave}
            />
          </div>
        </Slide>
      ) : (
        <LuccaToggle
          featureLucca={featureLucca}
          launchJob={launchJob}
          handleToggle={handleToggle}
          onActiveForm={onActiveForm}
        />
      )}
    </div>
  )
}

export default LuccaFeature
