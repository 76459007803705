import { FunctionComponent, HTMLAttributes } from "react"

import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"

import SignaturesTileAsRowDatas from "./components/SignaturesTileAsRowDatas"
import SignaturesTileAsColumnDatas from "./components/SignaturesTileAsColumnDatas"

import { useOnBoardingDisplay } from "features/Accounts/components/OnBoardingTile/hooks/useOnBoardingDisplay"

import Tile from "features/Accounts/components/HomePageSingleTile"

import { ReactComponent as IconDuotoneSignature } from "assets/duotone/icon-create-signature-duotone.svg"

import { trialTunnelSelectors } from "features/Trial"
import { setActiveSignature } from "features/Signatures/SignaturesReducer"

import { SIGNATURES_URL, TRIAL_TUNNEL_URL } from "router/RouterConstants"

interface SignaturesTileProps extends HTMLAttributes<HTMLDivElement> {
  isTrial: boolean
  className?: string
}

const messages = defineMessages({
  title: {
    id: "HomePage.SignaturesTileTitle",
    defaultMessage: "Signatures",
  },
  infos: {
    id: "HomePage.SignaturesTileInfos",
    defaultMessage:
      "Choisissez et personnalisez de A à Z un modèle de signature mail.",
  },
  emptyTitle: {
    id: "HomePage.SignaturesTileEmptyStateTitle",
    defaultMessage: "Ajouter votre première signature",
  },
  add: {
    id: "HomePage.SignaturesTileAdd",
    defaultMessage: "Ajouter",
  },
  label: {
    id: "HomePage.SignaturesTileActiveCountLabel",
    defaultMessage: "Signature(s) active(s)",
  },
})

type SignaturesTileType = FunctionComponent<SignaturesTileProps>

const SignaturesTile: SignaturesTileType = ({
  isTrial,
  className,
  ...others
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)
  const onBoardingDisplay = useOnBoardingDisplay()

  const handleAddSignature = () => {
    if (isTrialFinished) dispatch(setActiveSignature())
  }

  const signatureFormLink = !isTrialFinished
    ? `${TRIAL_TUNNEL_URL}/1`
    : `${SIGNATURES_URL}/create`

  return (
    <Tile
      {...others}
      className={classNames(
        className,
        "signature-tile",
        !onBoardingDisplay && "is-taller",
      )}
    >
      <Tile.Title
        icon={<IconDuotoneSignature />}
        label={formatMessage(messages.title)}
        to={!isTrial && SIGNATURES_URL}
      />
      <Tile.Infos message={formatMessage(messages.infos)} />
      <Tile.Datas
        className="signature-tile__datas--margin"
        isColumn={!onBoardingDisplay}
      >
        {onBoardingDisplay ? (
          <SignaturesTileAsRowDatas
            title={formatMessage(messages.emptyTitle)}
            addLabel={formatMessage(messages.add)}
            label={formatMessage(messages.label)}
            to={signatureFormLink}
            addSignature={handleAddSignature}
          />
        ) : (
          <SignaturesTileAsColumnDatas
            title={formatMessage(messages.emptyTitle)}
            addLabel={formatMessage(messages.add)}
            label={formatMessage(messages.label)}
            to={signatureFormLink}
            addSignature={handleAddSignature}
          />
        )}
      </Tile.Datas>
    </Tile>
  )
}

export default SignaturesTile
