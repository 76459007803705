import { GlobalStates } from "store"
import { offersSelectors } from "../Offers"
import { selectOfferById } from "features/Offers/OffersReducer"

const getNbUsers = (state: GlobalStates) => state.enrollments.nbUsers

const getSelectedOffer = (state: GlobalStates) =>
  state.enrollments.plan.offerId &&
  selectOfferById(state, state.enrollments.plan.offerId)

const getStripeCard = (state: GlobalStates) => state.enrollments.credit.card

const getStripeSepa = (state: GlobalStates) => state.enrollments.credit.sepa

const getValidatedStatus = (state: GlobalStates) =>
  state.enrollments.credit.isValidated

const getEnrollmentSuccess = (state: GlobalStates) =>
  state.enrollments.ui.enrollmentSuccess

//promotion
const getDiscount = (state: GlobalStates, forceTrial = false) => {
  if (!offersSelectors.isTrial(state) && !forceTrial) return 0
  const nbLicences = getNbUsers(state)
  const currentOffer = getSelectedOffer(state)
  const percent = currentOffer.getDiscountFromLicencesNumber(nbLicences)
  return percent
}

const getCodePromo = (state: GlobalStates) => state.enrollments.ui.codepromo

const getNewOfferInformations = (state: GlobalStates) =>
  state.enrollments.ui.newOfferInformations

/**
 * Enrollment Form SELECTORS
 */

const checkoutIsComplete = (state: GlobalStates): boolean =>
  state.enrollments.enrollmentForm.checkoutComplete

const isValidating = (state: GlobalStates): boolean =>
  state.enrollments.enrollmentForm.isValidating

const getIntentSecret = (state: GlobalStates): string =>
  state.enrollments.enrollmentForm.intentSecret

const isUpdate = (state: GlobalStates): boolean =>
  state.enrollments.enrollmentForm.isUpdate

const getEnrollmentFormLoadingStatus = (state: GlobalStates) =>
  state.enrollments.enrollmentForm.loadingStatus

const getEnrollmentFormCurrentStep = (state: GlobalStates) =>
  state.enrollments.enrollmentForm.currentStep

export const selectors = {
  getNbUsers,
  getSelectedOffer,
  getValidatedStatus,
  getDiscount,
  getCodePromo,
  getStripeCard,
  getStripeSepa,
  getEnrollmentSuccess,
  getNewOfferInformations,
  // EnrollmentForm selectors
  getIntentSecret,
  checkoutIsComplete,
  isValidating,
  isUpdate,
  getEnrollmentFormLoadingStatus,
  getEnrollmentFormCurrentStep,
}
