import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"
import { homePageStartup } from "features/Accounts/AccountsReducer"

import { LoadingStatus } from "core/CoreModels"

function useHomePage() {
  const dispatch = useDispatch()
  const loadingStatus = useSelector(accountsSelectors.getHomePageLoadingStatus)

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) dispatch(homePageStartup())
  }, [loadingStatus])

  return loadingStatus
}

export default useHomePage
