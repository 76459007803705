import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { isSpan } from "utils/I18nHelpers"

import classNames from "classnames"

import InstallerWindowsExe from "./InstallerWindowsExe"
import InstallerWindowsMsi from "./InstallerWindowsMsi"

import { accountsSelectors } from "features/Accounts"

const messages = defineMessages({
  apiKey: {
    id: "InstallerPanel.Windows.ApiKey",
    defaultMessage: "Clé de sécurité",
  },
  apiKeyButton: {
    id: "InstallerPanel.Windows.ApiKeyButton",
    defaultMessage: "Copier la clé",
  },
  copyToClipboard: {
    id: "InstallerPanel.Windows.ApiKeyCopyToClipboard",
    defaultMessage: "La clé est copiée dans votre presse papiers",
  },
  informations: {
    id: "InstallerPanel.Windows.ApiKeyInformations",
    defaultMessage:
      "La clé de sécurité est <sp>obligatoire</sp> pour utiliser le client Windows BoostMyMail",
  },
})

const InstallerWindows: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const [hideNotif, setHideNotif] = useState(true)

  const windowsVersions = useSelector(accountsSelectors.getWindowsVersions)
  const clientApiKey = useSelector(accountsSelectors.getClientApiKey)

  const exeLink = windowsVersions?.[0]?.ExeLink || ""

  const msiLink = windowsVersions?.[0]?.MsiLink || ""

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clientApiKey)
    setHideNotif(false)
  }

  useEffect(() => {
    let timer
    if (!hideNotif) {
      timer = setTimeout(() => setHideNotif(true), 6000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [hideNotif])

  return (
    <div className="installer-windows">
      <div className="installer-windows__grid">
        <InstallerWindowsExe appLink={exeLink} apiKey={clientApiKey} />
        <InstallerWindowsMsi appLink={msiLink} apiKey={clientApiKey} />
        <div className="installer-windows-grid__requirements">
          <h5 className="installer-windows__requirements--title">Prérequis</h5>
          <p className="installer-windows__requirements--informations">
            {formatMessage(messages.informations, { sp: isSpan })}
          </p>
          <div className="installer-windows__requirements--api-key-container">
            <p className="installer-windows__api-key--title">
              {formatMessage(messages.apiKey)}
            </p>
            <div className="installer-windows__api-key--container">
              <p className="installer-windows__api-key--key">{clientApiKey}</p>
              <p
                className={classNames(
                  "installer-windows__api-key--notify",
                  !hideNotif && "text-blur-out",
                )}
                hidden={hideNotif}
              >
                {formatMessage(messages.copyToClipboard)}
              </p>
              <button
                className="installer-windows__api-key--button"
                type="button"
                onClick={copyToClipboard}
              >
                {formatMessage(messages.apiKeyButton)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstallerWindows
