import { put, call, select, all } from "redux-saga/effects"
import {} from "../utils/ImageUtils"

import { Template } from "entities/Template"
import { Signature } from "entities/Signature"
import {
  signaturesSelectors,
  SelectTemplateAction,
} from "../features/Signatures"

import { GlobalStates } from "../store/"
import TemplatesFactory from "entities/TemplatesFactory"
import templatesApi from "../api/templatesApi"

import { fetchProperties } from "features/Users/UsersReducers"

import { fetchSignaturesTemplates } from "features/Signatures/SignaturesApi"
import {
  fetchTemplatesGallerySuccess,
  resizeSignatureLogoSuccess,
  selectTemplateSuccess,
  updateSignatureLogoSuccess,
  updateSignatureSuccess,
  updateTemporarySignatureSuccess,
} from "features/Signatures/SignaturesReducer"
import { PayloadAction } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"
import {
  uploadImageToCommonStorage,
  uploadImageToStorage,
} from "features/Storage/StorageApi"

/**
 * Recupère les modèles depuis la gallerie de modèle en base
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchTemplatesGallery() {
  const response = yield call(fetchSignaturesTemplates)

  if (response.success && response.result.IsExpired)
    throw new Error("Offre expirée")

  const templates = response.result.map((templateJson) => {
    const temp = new Template({})
    temp.rawContent = templateJson.template
    temp.rawLightContent = templateJson.templateLight
    temp.rawRawContent = templateJson.templateRaw
    temp.Id = templateJson.id
    temp.Name = templateJson.name
    temp.Custom = templateJson.custom
    return temp
  })

  // remplacement des SVGs par leur contenu pour pouvoir changer de couleur
  yield all(
    templates.map((temp) => {
      try {
        return call(templatesApi.replaceSvgLinkBySvg, temp)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Template ID - ${temp.Id} contient des liens vers un cdn dont les CORS sont non-configurés`,
        )
      }
    }),
  )

  yield put(fetchTemplatesGallerySuccess(templates))
}

/**
 * Mise à jour du template de la signature active, en remplacant le logo, sans sauvegarde au niveau de la base pour ne pas surcharger d'appels (appelé à chaque modif du template)
 * On effectuera également l'upload au moment de passer à l'étape suivante (donc conserver une reference de cette image dans la classe Template)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateTemplateLogo(action: PayloadAction<string>) {
  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )

  if (action.payload !== null) {
    activeSignature.Template.SetLogoUrl(action.payload)

    // MàJ du DataTag Logo
    const updateAction = { dataTag: activeSignature.Template.dataTags.logo }

    yield call(updateActiveTemplate, updateAction)

    yield put(updateSignatureLogoSuccess())
  }
}

/**
 * Mise à jour du logo de la signature active "croppé", sans sauvegarde au niveau de la base pour ne pas surcharger d'appels (appelé à chaque modif du template)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* cropTemplateLogo(
  action: PayloadAction<{ logoUrl: string; width: number; height: number }>,
) {
  const { logoUrl, width, height } = action.payload
  let updatedWidth = width
  let updatedHeight = height

  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )

  if (logoUrl != null) {
    activeSignature.Template.SetLogoUrl(logoUrl)

    // si la taille maximum est déclenchée, on redimensionne l'image au max
    const maxWidth = activeSignature.Template.dataTags.logo.maxSize.width
    const maxHeight = activeSignature.Template.dataTags.logo.maxSize.height

    if (width > maxWidth || height > maxHeight) {
      updatedHeight = (height * maxWidth) / width
      updatedWidth = maxWidth
    }

    const updatedLogo = cloneDeep(activeSignature.Template.dataTags.logo)

    updatedLogo.size.width = updatedWidth
    updatedLogo.size.height = updatedHeight

    // MàJ du DataTag Logo
    const updateAction = { dataTag: updatedLogo }
    yield call(updateActiveTemplate, updateAction)

    yield put(
      resizeSignatureLogoSuccess({
        width: updatedWidth,
        height: updatedHeight,
      }),
    )
  }
}

/**
 * Mise à jour du logo de la signature active redimensionné,
 * sans sauvegarde au niveau de la base pour ne pas surcharger d'appels (appelé à chaque modif du template)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* resizeTemplateLogo(
  action: PayloadAction<{ width: number; height: number }>,
) {
  const { width, height } = action.payload
  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )

  const updatedLogo = cloneDeep(activeSignature.Template.dataTags.logo)

  updatedLogo.size.width = width
  updatedLogo.size.height = height

  // MàJ du DataTag Logo
  const updateAction = { dataTag: updatedLogo }
  yield call(updateActiveTemplate, updateAction)
  yield put(
    resizeSignatureLogoSuccess({
      width,
      height,
    }),
  )
}

/**
 * Mise à jour du template de la signature active, en remplacant le dataTag modifié,
 * sans sauvegarde au niveau de la base pour ne pas surcharger d'appels (appelé à chaque modif du template)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateActiveTemplate(action) {
  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )
  const currentTemplate = yield select(signaturesSelectors.getSelectedTemplate)

  // 1- Génération d'un nouveau template pour la signature, afin de redéfinir les tags disponibles et ne pas modifier le template dans la galerie
  const template = TemplatesFactory.DeepCopy(currentTemplate)

  const updatedSignature = cloneDeep(activeSignature)

  // definition des paramètres modifiés - DataTag
  updatedSignature.Template.SetDataTagUrl(action.dataTag)

  updatedSignature.Template.UpdateTemplate(template)

  if (updatedSignature.Id != null)
    yield put(updateSignatureSuccess(updatedSignature))
  else yield put(updateTemporarySignatureSuccess(updatedSignature))
}

/**
 * Télécharge l'image sur le CDN et renvoi l'url générée,
 * puis retourne l'url de la ressource
 * @param imageFile
 * @param inCommonFolder - si vrai, upload le fichier dans le répertoire commun BoostMyMail
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* uploadImage(imageFile: File, inCommonFolder = false) {
  let fileUrlResult
  if (inCommonFolder)
    fileUrlResult = yield uploadImageToCommonStorage(imageFile)
  else fileUrlResult = yield uploadImageToStorage(imageFile)
  return fileUrlResult.result.url
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* selectTemplateColor(action: PayloadAction<string>) {
  const currentTemplate = yield select((state: GlobalStates) =>
    signaturesSelectors.getSelectedTemplate(state),
  )

  const activeSignature: Signature = yield select((state: GlobalStates) =>
    signaturesSelectors.getActiveSignature(state),
  )

  // 1- Génération d'un nouveau template pour la signature, afin de redéfinir les tags disponibles et ne pas modifier le template dans la galerie
  const template = TemplatesFactory.DeepCopy(currentTemplate)

  // definition des paramètres modifiés - Couleur
  activeSignature.Template.currentColor = action.payload

  // Regénération du template
  activeSignature.Template.UpdateTemplate(template)

  // on recharge les propriétés dispos dans le volet de droite en fonction du template
  yield put(fetchProperties())
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* selectPrevTemplate() {
  const currentTemplate = yield select((state: GlobalStates) =>
    signaturesSelectors.getSelectedTemplate(state),
  )
  const allTemplates = yield select((state: GlobalStates) =>
    signaturesSelectors.getAllTemplates(state),
  )

  // On part du principe que le template suivant est défini par son index dans allIds et non par son ID.
  const allIds: number[] = yield select((state: GlobalStates) =>
    signaturesSelectors.getTemplateAllIds(state),
  )
  const currentIndex = allIds.indexOf(currentTemplate.Id)
  if (currentIndex === 0) return

  const activeSignature: Signature = yield select((state: GlobalStates) =>
    signaturesSelectors.getActiveSignature(state),
  )

  // definition des paramètres modifiés - Template
  const selectedTemplateId = allIds[currentIndex - 1]
  const newTemplate = allTemplates.find(
    (templ) => templ.Id === selectedTemplateId,
  )

  // Start
  // 1- Génération d'un nouveau template pour la signature, afin de redéfinir les tags disponibles et ne pas modifier le template dans la galerie
  const template = TemplatesFactory.DeepCopy(newTemplate)

  activeSignature.Template.UpdateTemplate(template)

  yield put(selectTemplateSuccess(selectedTemplateId))
  // on recharge les propriétés dispos dans le volet de droite en fonction du template
  yield put(fetchProperties())
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* selectTemplate(action: SelectTemplateAction) {
  const selectedTemplate: Template = yield select((state: GlobalStates) =>
    signaturesSelectors.getTemplateById(state, action.payload.id),
  )

  const activeSignature: Signature = yield select((state: GlobalStates) =>
    signaturesSelectors.getActiveSignature(state),
  )

  // 1- Génération d'un nouveau template pour la signature, afin de redéfinir les tags disponibles et ne pas modifier le template dans la galerie
  const template = TemplatesFactory.DeepCopy(selectedTemplate)

  activeSignature.Template.UpdateTemplate(template)

  yield put(selectTemplateSuccess(selectedTemplate.Id))
  // on recharge les propriétés dispos dans le volet de droite en fonction du template
  yield put(fetchProperties())
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* selectNextTemplate() {
  const currentTemplate = yield select((state: GlobalStates) =>
    signaturesSelectors.getSelectedTemplate(state),
  )
  const allTemplates = yield select((state: GlobalStates) =>
    signaturesSelectors.getAllTemplates(state),
  )

  // On part du principe que le template suivant est défini par son index dans allIds et non par son ID.
  const allIds: number[] = yield select((state: GlobalStates) =>
    signaturesSelectors.getTemplateAllIds(state),
  )
  const currentIndex = allIds.indexOf(currentTemplate.Id)
  if (currentIndex === allIds.length - 1) return

  const activeSignature: Signature = yield select((state: GlobalStates) =>
    signaturesSelectors.getActiveSignature(state),
  )

  const selectedTemplateId = allIds[currentIndex + 1]
  const newTemplate = allTemplates.find(
    (templ) => templ.Id === selectedTemplateId,
  )

  // 1- Génération d'un nouveau template pour la signature, afin de redéfinir les tags disponibles et ne pas modifier le template dans la galerie
  const template = TemplatesFactory.DeepCopy(newTemplate)

  activeSignature.Template.UpdateTemplate(template)

  yield put(selectTemplateSuccess(selectedTemplateId))
  // on recharge les propriétés dispos dans le volet de droite en fonction du template
  yield put(fetchProperties())
}

function* setAssociatedBannerVisibility(visibility: boolean) {
  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )

  activeSignature.Template.showBanner = visibility
  activeSignature.Template.UpdateTemplate(activeSignature.Template)

  yield put(updateTemporarySignatureSuccess(activeSignature))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* hideAssignedBanner() {
  yield setAssociatedBannerVisibility(false)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* showAssignedBanner() {
  yield setAssociatedBannerVisibility(true)
}
