export interface CreateTenantOfferDto {
  OfferId: number
  NbLicences: number
}

export interface TenantOfferDto {
  id: number
  name: string
  offerId: number
  startDate: string
  endDate: string
  availableLicences: number | null
  maxActivatedBanners: number | null
  maxActivatedSignatures: number | null
  maxAdmins: number | null
  maxLicences: number | null
}

interface PricingDto {
  minUsers: number
  maxUsers: number
  price: number
}

export interface OfferPricingDto extends PricingDto {
  percentDiscount: number
  isActive: boolean
}

interface AssociatedPricingDto extends PricingDto {
  optionId: number
}

export interface OfferOptionDto {
  id: number
  description: string
  name: string
  paymentName: string
  associatedPricing: AssociatedPricingDto[]
}

export interface OfferDto {
  id: number
  name: string
  description: string
  maxActivatedBanners: number | null
  maxActivatedSignatures: number | null
  maxAdmins: number | null
  maxLicences: number | null
  pricings: OfferPricingDto[]
}

class AssociatedPricing {
  OptionId: number
  MinUsers: number
  MaxUsers: number
  Price: number

  constructor(associatedPricingDto: AssociatedPricingDto) {
    this.OptionId = associatedPricingDto.optionId
    this.MinUsers = associatedPricingDto.minUsers
    this.MaxUsers = associatedPricingDto.maxUsers
    this.Price = associatedPricingDto.price
  }
}

export class OfferPricing {
  MaxUsers: number
  MinUsers: number
  Price: number
  PercentDiscount: number
  IsActive: boolean

  constructor(offerPricing: OfferPricingDto) {
    this.MaxUsers = offerPricing.maxUsers
    this.MinUsers = offerPricing.minUsers
    this.Price = offerPricing.price
    this.PercentDiscount = offerPricing.percentDiscount
    this.IsActive = offerPricing.isActive
  }
}

export class OfferOption {
  Id: number
  Name: string
  PaymentName: string
  Description: string
  AssociatedPricing: AssociatedPricing[]
  Selected: boolean

  constructor(offerOptionDto: OfferOptionDto) {
    this.Id = offerOptionDto.id
    this.Name = offerOptionDto.name
    this.PaymentName = offerOptionDto.paymentName
    this.Description = offerOptionDto.description

    this.AssociatedPricing = offerOptionDto.associatedPricing.map(
      (pricing) => new AssociatedPricing(pricing),
    )

    this.Selected = false
  }

  getPricingFromLicencesNumber(nbLicence: number) {
    if (!this.AssociatedPricing) return null

    const offerPricing = this.AssociatedPricing.find(
      (offerPricing) =>
        offerPricing.MaxUsers >= nbLicence &&
        offerPricing.MinUsers <= nbLicence,
    )
    if (offerPricing) return offerPricing.Price
  }
}

export class Offer {
  Id: number
  Name: string
  Description: string
  MaxActivatedBanners: number | null
  MaxActivatedSignatures: number | null
  MaxAdmins: number | null
  MaxLicences: number | null
  Pricings: OfferPricing[]

  constructor(offerDto: OfferDto) {
    this.Id = offerDto.id
    this.Name = offerDto.name
    this.Description = offerDto.description
    this.MaxActivatedBanners = offerDto.maxActivatedBanners
    this.MaxActivatedSignatures = offerDto.maxActivatedSignatures
    this.MaxAdmins = offerDto.maxAdmins
    this.MaxLicences = offerDto.maxLicences

    this.Pricings = offerDto.pricings.map(
      (pricing) => new OfferPricing(pricing),
    )
  }

  getOfferPricingFromLicencesNumber(nbLicence: number) {
    if (!this.Pricings) return null

    const offerPricing = this.Pricings.find(
      (offerPricing) =>
        offerPricing.MaxUsers >= nbLicence &&
        offerPricing.MinUsers <= nbLicence,
    )
    return offerPricing
  }

  getPricingFromLicencesNumber(nbLicence: number) {
    const offerPricing = this.getOfferPricingFromLicencesNumber(nbLicence)
    if (offerPricing) return offerPricing.Price
  }

  getMaxUsersFromLicencesNumber(nbLicence: number) {
    const offerPricing = this.getOfferPricingFromLicencesNumber(nbLicence)
    if (offerPricing) return offerPricing.MaxUsers
  }

  getDiscountFromLicencesNumber(nbLicence: number) {
    const offerPricing = this.getOfferPricingFromLicencesNumber(nbLicence)
    const percent = offerPricing.PercentDiscount

    if (offerPricing.IsActive) return percent
    else return 0
  }

  // Retourne les dates limites de la nouvelle offre, en début de journée (00:00:00)
  getSubscriptionStartDate(startDate): { startDate: Date; endDate: Date } {
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date(startDate)
    endDate.setFullYear(endDate.getFullYear() + 1)
    return {
      startDate,
      endDate,
    }
  }
}

export class TenantOffer {
  Id: number
  OfferId: number
  Name: string
  StartDate: Date
  EndDate: Date
  MaxActivatedSignatures?: number
  MaxActivatedBanners?: number
  MaxLicences?: number
  AvailableLicences?: number

  constructor(tenantOfferDto: TenantOfferDto) {
    this.Id = tenantOfferDto.id
    this.OfferId = tenantOfferDto.offerId
    this.Name = tenantOfferDto.name
    this.StartDate = new Date(tenantOfferDto.startDate)
    this.EndDate = new Date(tenantOfferDto.endDate)
    this.MaxActivatedSignatures = tenantOfferDto.maxActivatedSignatures
    this.MaxActivatedBanners = tenantOfferDto.maxActivatedBanners
    this.MaxLicences = tenantOfferDto.maxLicences
    this.AvailableLicences = tenantOfferDto.availableLicences
  }
}

interface CatalogOfferDto {
  id: number
  name: string
  unitPrice: number
  minimalPrice: number
  maxUsers: number
}

export class CatalogOffer {
  id: number
  name: string
  unitPrice: number
  minimalPrice: number
  maxUsers: number

  constructor(catalogOfferDto: CatalogOfferDto) {
    this.id = catalogOfferDto.id
    this.name = catalogOfferDto.name
    this.unitPrice = catalogOfferDto.unitPrice
    this.minimalPrice = catalogOfferDto.minimalPrice
    this.maxUsers = catalogOfferDto.maxUsers
  }
}
