import React, { useEffect, useState } from "react"

import { useForm, Controller } from "react-hook-form"

import { Grid } from "@material-ui/core"

import InputText from "components/TextBox/InputText"
import { InputSelect } from "components/Select"
import Phone from "components/Phone"
import TooltipInfo from "components/TooltipInfo"

import UserAvatar from "features/Users/components/UserAvatar"

interface Props {
  formId: string
  initForm: FormItem[]
  formValidate(form)
  validateOnBlur?: boolean
}

export enum InputType {
  text = "text",
  phone = "phone",
  select = "select",
  avatar = "avatar",
  title = "title",
}

export enum InputSize {
  S1 = 1,
  S2 = 2,
  S3 = 3,
  S4 = 4,
  S5 = 5,
  S6 = 6,
  S7 = 7,
  S8 = 8,
  S9 = 9,
  S10 = 10,
  S11 = 11,
  S12 = 12,
}

export class FormItem {
  name: string
  label?: string
  tooltipMsg?: string
  type: InputType
  size: InputSize
  order: number
  value: any
  rules?: {
    required?: boolean | string
    validate?: any
  }
  visible?: boolean
  disabled?: boolean
  textAttr?: {
    placeholder?: string
    multiline?: boolean
    rows?: number
  }
  selectOptions?: { value: any; label: string }[]
}

const GenericForm: React.FC<Props> = (props) => {
  const { formId, initForm, formValidate, validateOnBlur } = props
  const [form, setForm] = useState([])

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: validateOnBlur ? "onBlur" : "onSubmit",
  })

  useEffect(() => {
    initForm.forEach((element) => {
      setValue(element.name, element.value)
    })
    setForm([...initForm])
  }, [initForm])

  const onSubmit = (formData) => {
    form.map((elem) => {
      // Vérifier que la propriété est présente dans formData pour ne pas écraser toutes les propriétés de form (ex: avatar)
      if (formData[elem.name] !== undefined) {
        elem.value = formData[elem.name]
      }
    })
    formValidate(form.filter((e) => e.type !== InputType.title))
  }

  const handlePictureChange = (picture, name) => {
    setValue(name, picture, { shouldValidate: false })
  }

  return (
    <div className="generic-form">
      <form noValidate onSubmit={handleSubmit(onSubmit)} id={formId}>
        <Grid container spacing={2} className="generic-form__body">
          {form
            .filter((element) => (element.visible ? element.visible : true))
            .sort((a, b) => a.order - b.order)
            .map((element) => {
              switch (element.type) {
                default:
                case "text":
                  return (
                    <Grid item xs={element.size} key={element.name}>
                      {element.label && (
                        <label
                          className={`generic-form__label ${
                            element.disabled
                              ? "generic-form__label--disabled"
                              : ""
                          }`}
                        >
                          {element.label}{" "}
                          {element.rules && element.rules.required && "*"}
                        </label>
                      )}
                      <Controller
                        render={({ field }) => (
                          <InputText
                            {...field}
                            error={errors[element.name]}
                            errorText={
                              errors[element.name] &&
                              errors[element.name].message
                            }
                            disabled={element.disabled}
                          ></InputText>
                        )}
                        control={control}
                        rules={element.rules}
                        name={element.name}
                        defaultValue={element.value}
                      />
                    </Grid>
                  )
                case "phone":
                  return (
                    <Grid item xs={element.size} key={element.name}>
                      {element.label && (
                        <label
                          className={`generic-form__label ${
                            element.disabled
                              ? "generic-form__label--disabled"
                              : ""
                          }`}
                        >
                          {element.label}{" "}
                          {element.rules && element.rules.required && "*"}
                        </label>
                      )}
                      <Controller
                        render={({ field }) => <Phone {...field} />}
                        control={control}
                        rules={element.rules}
                        name={element.name}
                        defaultValue={element.value}
                      />
                    </Grid>
                  )
                case "select":
                  return (
                    <Grid item xs={element.size} key={element.name}>
                      {element.label && element.tooltipMsg && (
                        <TooltipInfo intlId={element.tooltipMsg}>
                          <label
                            className={`generic-form__label ${
                              element.disabled
                                ? "generic-form__label--disabled"
                                : ""
                            }`}
                          >
                            {element.label}{" "}
                            {element.rules && element.rules.required && "*"}
                          </label>
                        </TooltipInfo>
                      )}
                      {element.label && element.tooltipMsg == null && (
                        <label
                          className={`generic-form__label ${
                            element.disabled
                              ? "generic-form__label--disabled"
                              : ""
                          }`}
                        >
                          {element.label}{" "}
                          {element.rules && element.rules.required && "*"}
                        </label>
                      )}
                      <Controller
                        render={({ field }) => (
                          <InputSelect
                            selectOptions={element.selectOptions}
                            {...field}
                          />
                        )}
                        control={control}
                        rules={element.rules}
                        name={element.name}
                        defaultValue={element.value}
                      />
                    </Grid>
                  )
                case "avatar":
                  return (
                    <Grid item xs={element.size} key={element.name}>
                      <div
                        className={`generic-form__avatar ${
                          element.disabled
                            ? "generic-form__avatar--disabled"
                            : ""
                        }`}
                      >
                        <UserAvatar
                          pictureUrl={element.value}
                          handlePicture={(picture) => {
                            handlePictureChange(picture, element.name)
                          }}
                        />
                      </div>
                    </Grid>
                  )
                case "title":
                  return (
                    <Grid
                      item
                      xs={element.size}
                      key={element.name}
                      style={{ marginTop: element.order !== 1 && 20 }}
                    >
                      <span id={element.name} className="generic-form__title">
                        {element.value}
                      </span>
                    </Grid>
                  )
              }
            })}
        </Grid>
      </form>
    </div>
  )
}

export default GenericForm
