import { FunctionComponent, ReactNode } from "react"

import AssignationsPanelCarousel, {
  AssignationsPanelCarouselType,
} from "./components/AssignationsPanelCarousel"

import AssignationsPanelItem, {
  AssignationsPanelItemType,
} from "./components/AssignationsPanelItem"
import AssignationsPanelNavigation, {
  AssignationsPanelNavigationType,
} from "./components/AssignationsPanelNavigation"
import AssignationsPanelScenarios, {
  AssignationsPanelScenariosType,
} from "./components/AssignationsPanelScenarios"
import AssignationsPanelSignatures, {
  AssignationsPanelSignaturesType,
} from "./components/AssignationsPanelSignatures"
import AssignationsPanelData, {
  AssignationsPanelDataType,
} from "./components/AssignationsPanelData"

import { DiffusionModeEnum, SendingModeEnum } from "core/CoreModels"

export interface AssignationsPanelSingleSignature {
  name: string
  diffusionMode?: DiffusionModeEnum
  sendingMode?: SendingModeEnum
  template: string
  weighting: number
}

export interface SingleScenarioBanner {
  id: number
  url: string
  linkUrl: string
}

export interface AssignationsPanelSingleScenario {
  name: string
  diffusionMode?: DiffusionModeEnum
  template?: string
  banners?: SingleScenarioBanner[]
}

interface AssignationsPanelProps {
  children: ReactNode
}

interface AssignationsPanelComposition {
  Item: AssignationsPanelItemType
  Navigation: AssignationsPanelNavigationType
  Signatures: AssignationsPanelSignaturesType
  Scenarios: AssignationsPanelScenariosType
  Carousel: AssignationsPanelCarouselType
  Data: AssignationsPanelDataType
}

type AssignationsPanelType = AssignationsPanelComposition &
  FunctionComponent<AssignationsPanelProps>

const AssignationsPanel: AssignationsPanelType = ({ children }) => {
  return <div className="assignations-panel">{children}</div>
}

AssignationsPanel.Item = AssignationsPanelItem
AssignationsPanel.Navigation = AssignationsPanelNavigation
AssignationsPanel.Signatures = AssignationsPanelSignatures
AssignationsPanel.Scenarios = AssignationsPanelScenarios
AssignationsPanel.Carousel = AssignationsPanelCarousel
AssignationsPanel.Data = AssignationsPanelData

export default AssignationsPanel
