import { FunctionComponent, ReactNode } from "react"

import { ReactComponent as PreviousIcon } from "assets/icon-previous.svg"
import { ReactComponent as NextIcon } from "assets/icon-next.svg"

interface UserSignaturePreviewNavigationProps {
  children: ReactNode
  displayedPreviewIndex: number | null
  templatesLength: number
  previousPreview: () => void
  nextPreview: () => void
}

export type UserSignaturePreviewNavigationType =
  FunctionComponent<UserSignaturePreviewNavigationProps>

const UserSignaturePreviewNavigation: UserSignaturePreviewNavigationType = ({
  children,
  displayedPreviewIndex,
  templatesLength,
  previousPreview,
  nextPreview,
}) => {
  return (
    <div className="user-signature-preview__navigation">
      {children}
      <button
        className="user-signature-preview__navigation--prev"
        onClick={previousPreview}
        disabled={displayedPreviewIndex === null || displayedPreviewIndex === 0}
      >
        <PreviousIcon />
      </button>
      <button
        className="user-signature-preview__navigation--next"
        onClick={nextPreview}
        disabled={
          displayedPreviewIndex === null ||
          displayedPreviewIndex >= templatesLength - 1
        }
      >
        <NextIcon />
      </button>
    </div>
  )
}

export default UserSignaturePreviewNavigation
