import { FunctionComponent, ReactNode } from "react"

interface ScenarioCardActionsProps {
  children: ReactNode
}

export type ScenarioCardActionsType =
  FunctionComponent<ScenarioCardActionsProps>

const ScenarioCardActions: ScenarioCardActionsType = ({ children }) => {
  return <div className="scenario-card__actions">{children}</div>
}

export default ScenarioCardActions
