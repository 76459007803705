import { FormEventHandler, FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import "./CouponCode.scss"

interface CouponCodeProps {
  error?: boolean
  onSubmit?: (value: string) => void
}

const CouponCode: FunctionComponent<CouponCodeProps> = ({
  error,
  onSubmit,
}) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    const target = event.currentTarget

    onSubmit && onSubmit(target.code.value)
  }

  return (
    <div className="coupon-code">
      <form
        onSubmit={handleSubmit}
        className={error ? "coupon-code--error" : ""}
      >
        <input name="code" />
        <input type="submit" placeholder="Code Promo" value="Valider" />
      </form>
      {error && (
        <div className="coupon-code__error">
          <FormattedMessage
            id="Enroll.ErrorCoupon"
            defaultMessage="Code invalide !"
          />
        </div>
      )}
    </div>
  )
}

export default CouponCode
