import FloatingButton from "components/FloatingButton"
import SearchBar from "components/SearchBar"
import { Subsidiary } from "features/Subsidiaries/SubsidiaryModels"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import SubsidiaryCard from "../SubsidiaryCard"
import useUsersData from "features/Users/hooks/useUsersData"

interface Props {
  subsidiaries: Subsidiary[]
}

const messages = defineMessages({
  subsidiariesSearchBar: {
    id: "SubsidiariesHome.placeHolderSearchBar",
    defaultMessage: "Chercher une filiale",
  },
})

const SubsidiariesList: React.FC<Props> = ({ subsidiaries }) => {
  const [filterValue, setFilterValue] = useState("")
  const intl = useIntl()

  const { loadingStatus } = useUsersData()

  const handleSearchbar = (value) => {
    setFilterValue(value)
  }

  return (
    <div className="body-main-content-subsidiaries_list">
      <div className="main-content_header-subsidiaries_list">
        <SearchBar
          onChange={(value) => handleSearchbar(value)}
          shrink
          placeholder={intl.formatMessage(messages.subsidiariesSearchBar)}
          defaultValue={filterValue}
        />
        <FloatingButton url={"/portal/subsidiaries/edit"} iconName={"add"} />
      </div>
      <div className="main-content_subsidiray-list">
        {subsidiaries
          .filter((subsidiary) =>
            subsidiary.name.toLowerCase().includes(filterValue.toLowerCase()),
          )
          .map((subsidiary) => (
            <SubsidiaryCard
              key={subsidiary.id}
              subsidiary={subsidiary}
              usersLoadingStatus={loadingStatus}
            />
          ))}
      </div>
    </div>
  )
}

export default SubsidiariesList
