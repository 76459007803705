import { useState } from "react"
import { FormattedMessage } from "react-intl"
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { ClickAwayListener } from "@material-ui/core"

export interface Item{
    id: number
    name: string
    img?: string
}

interface Props{
    listItem: Item[]
    selectedItem: Item
    handleChange: (item) => void
}

export const DropDown: React.FC<Props> = ({listItem, selectedItem, handleChange}) => {
    const [open, setOpen] = useState<boolean>(false)
    const listWithoutSelected: Item[] = listItem.filter((item) => item.id != selectedItem?.id )

    const handleClick = (item) => {
        setOpen(false)
        handleChange(item)
    }

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
            <nav className="menuDropdown">
                <div className="menuDropdown_container"> 
                    <div className={`menu-header menu-header${open ? "--open" : "--closed"}`}>
                        {
                            open
                            &&
                            <div className="dropdown_item_vertical-bar">
                                <div className="dropdown_item_dotIcon_container">
                                    <div className="dropdown_item_dotIcon_centered"/>
                                </div>
                                <div className="dropdown_item__link-part">
                                    <div className={`dropdown_item__link-part--bottom--left`}/>
                                    <div className={`dropdown_item__link-part--bottom--right`}/>
                                </div>                               
                            </div>
                        }
                        <div className="menu-header_selected" onClick={() => setOpen(!open)}>
                            <div className="menu-header_title">
                                {
                                    !open
                                    &&
                                    <FormattedMessage
                                        id="Subsidiaries.DropDownContexte"
                                        defaultMessage="Contexte :"
                                    />
                                }
                                <p>{selectedItem.name}</p>
                            </div>       
                            <ArrowDownIcon className={`dropdown_button${open ? "--open" : ""}`} />
                        </div>
                        <ol className="sub-menu">
                            {
                                listWithoutSelected.map((item, index) => (
                                    <li className="menu-item" onClick={() => handleClick(item) }>
                                        <div className="menu-item_container">
                                            <div className="dropdown_item_left-part_container">
                                                <div className="dropdown_item_vertical-bar">
                                                    <div className="dropdown_item__link-part">
                                                        <div className={` dropdown_item__link-part--top--left${(index == listWithoutSelected.length-1) ? "--last" : ""}`}/>
                                                        <div className={` dropdown_item__link-part--top--right${(index == listWithoutSelected.length-1) ? "--last" : ""}`}/>
                                                    </div>
                                                    <div className="dropdown_item__link-part">
                                                        <div className={`dropdown_item__link-part${(index < listWithoutSelected.length-1) && "--bottom--left"}`}/>
                                                        <div className={`dropdown_item__link-part${(index < listWithoutSelected.length-1) && "--bottom--right"}`}/>
                                                    </div>                               
                                                </div>
                                                <div className="dropdown_item_dotIcon"></div>
                                            </div>    
                                            <div className="dropdown_item-bar"></div>
                                            {
                                                item.img
                                                &&
                                                <img src={item.img} alt="img" />
                                            }
                                            <p>{item.name}</p> 
                                        </div>
                                    </li>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </nav>
        </ClickAwayListener>
    
}

export default DropDown