/**
 * Gestion des erreurs
 */
import { AxiosError } from "axios"
import PortalApi from "core/services/portalApi"

export interface DomainErrorDto {
  message: string
  stack: string
  feature?: string
  action?: string
  type?: "Applicative" | "Network" | "UI"
}

export interface IErrorService {
  catchError(error: DomainErrorDto)
}

const mandatoryObfuscatedKeys = ["Password", "password"]

export class ErrorService implements IErrorService {
  _sinkUrl: string
  constructor(sinkUrl: string) {
    this._sinkUrl = sinkUrl
  }

  public catchError(error: DomainErrorDto): void {
    PortalApi.request("POST", this._sinkUrl, {
      data: error,
    })
  }

  static obfuscateValues(keysToObfuscate: string[]) {
    return (k: string, v: unknown): unknown => {
      return keysToObfuscate.includes(k) ? "*******" : v
    }
  }

  static apiErrorStack(
    error: AxiosError,
    obfuscatedKeys: string[] = [],
  ): string {
    const { config, response } = error

    const parsedData = config.data && JSON.parse(config.data)

    const errorStack = {
      request: {
        url: `${config.baseURL}${config.url}`,
        method: config.method,
        data: parsedData,
      },
      response: {
        status: `${response.status} - ${response.statusText}`,
      },
    }

    const stringifiedJson = JSON.stringify(
      errorStack,
      ErrorService.obfuscateValues([
        ...mandatoryObfuscatedKeys,
        ...obfuscatedKeys,
      ]),
      2,
    )

    return stringifiedJson
  }
}
