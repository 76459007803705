import { FunctionComponent } from "react"

import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"

import Signature from "entities/Signature"
import ActivateSignatureButton from "./ActivateSignatureButton"

import ConnectedSignatureReadOnlyView from "../../SignatureReadOnlyView"

import Card, { CardHeader } from "components/Card"

import {
  signaturesActionCreators,
  signaturesSelectors,
} from "features/Signatures"

import { updateActiveSignaturesAssignationsAction } from "features/Signatures/SignaturesActions"
import useWaitForUsersLoading from "features/Users/hooks/useWaitForUsersLoading"
import { SIGNATURES_URL } from "router/RouterConstants"

interface SignatureLicencesViewProps {
  prev?: (evt, datas?) => void
}

const SignatureLicencesView: FunctionComponent<SignatureLicencesViewProps> = ({
  prev,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoaded, notifyUser } = useWaitForUsersLoading()
  const assignedUsersIds = useSelector(
    signaturesSelectors.getActiveSignatureAffectedUsersIds,
  )
  const assignedGroupsIds = useSelector(
    signaturesSelectors.getActiveSignatureAffectedGroupsIds,
  )
  const signature = useSelector(signaturesSelectors.getActiveSignature)

  const handleActivate = (signature: Signature) => {
    if (!isLoaded) return notifyUser()

    dispatch(
      updateActiveSignaturesAssignationsAction(
        assignedUsersIds,
        assignedGroupsIds,
      ),
    )

    dispatch(signaturesActionCreators.activateSignature(signature))

    navigate(SIGNATURES_URL)
  }

  return (
    <div className="signature-form__assignations">
      <div className="signature-preview">
        <Card>
          <CardHeader>
            <div>Nouveau message</div>
            <div className="client-mail__icon">
              <i className={"material-icons"}>send</i>
            </div>
          </CardHeader>
          <div className="client-mail">
            <div className="client-mail__line">à</div>
            <div className="client-mail__line">Objet</div>
          </div>
          <div className="signature-template">
            <div className="signature-template__viewer">
              <ConnectedSignatureReadOnlyView />
            </div>
          </div>
        </Card>
      </div>
      <div className="bmm-button__container">
        <div className="bmm-button" onClick={prev}>
          <div>
            <span className="bmm-button__intitule">
              <FormattedMessage
                id="SignatureLicencesView.BtnPrevious"
                defaultMessage="PRÉCÉDENT"
              />
            </span>
          </div>
        </div>
        <ActivateSignatureButton
          onActivate={handleActivate}
          signature={signature}
        />
      </div>
    </div>
  )
}

export default SignatureLicencesView
