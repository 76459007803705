import { FunctionComponent, ReactElement, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import {
  BuilderToolbar,
  GroupedTemplateCards,
  DraggablePropertiesList,
  useBuilderData,
  SocialsPanel,
  ImagesPanel,
  PropertiesPanel,
  BmmIcon,
  AvailableSocialMedia,
  StaticTextPanel,
} from "bmm-builder"

import BuilderAssignmentPanel from "features/BuilderSignatures/components/BuilderAssignmentPanel"
import BuilderParametersPanel from "features/BuilderSignatures/components/BuilderParametersPanel"

import { usersSelectors } from "features/Users"

import StringHelper from "utils/StringHelper"
import {
  extractAndReplacePhones,
  reorderUserProperties,
} from "features/BuilderSignatures/BuilderSignaturesHelpers"
import { fromBlobToFile } from "features/Storage/services/fileService"

import useStorageImagesDatas from "features/Storage/hooks/useStorageImagesDatas"
import useMustSave from "features/BuilderSignatures/hooks/useMustSave"

import { storageSelectors } from "features/Storage"

import {
  deleteImageFromStorageAction,
  uploadImageToStorageAction,
} from "features/Storage/StorageActions"

import {
  BUILDER_TEMPLATES,
  CDN_DOMAIN,
  IMAGE_LIBRARY_ACCEPTED_FORMATS,
  PROD_STORAGE_DOMAIN,
} from "features/BuilderSignatures/BuilderSignaturesConstants"

import { userPropertiesTranslationsForBuilder } from "features/BuilderSignatures/BuilderSignaturesTranslations"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

interface PortalBuilderToolbarProps {
  onSave: (signature: BuilderSignature) => void
  signatureName: string
  activeBuilderSignature: BuilderSignature | null
}

const socials: {
  id: string
  media: AvailableSocialMedia
  icon: ReactElement
  isAdded: boolean
}[] = [
  {
    id: "linkedin",
    media: "linkedin",
    icon: <BmmIcon icon="Linkedin" />,
    isAdded: false,
  },
  {
    id: "twitter",
    media: "twitter",
    icon: <BmmIcon icon="Twitter" />,
    isAdded: false,
  },
  {
    id: "facebook",
    media: "facebook",
    icon: <BmmIcon icon="Facebook" />,
    isAdded: false,
  },
  {
    id: "youtube",
    media: "youtube",
    icon: <BmmIcon icon="Youtube" />,
    isAdded: false,
  },
  {
    id: "instagram",
    media: "instagram",
    icon: <BmmIcon icon="Instagram" />,
    isAdded: false,
  },
  {
    id: "tiktok",
    media: "tiktok",
    icon: <BmmIcon icon="Tiktok" />,
    isAdded: false,
  },
  {
    id: "siteweb",
    media: "siteweb",
    icon: <BmmIcon icon="Siteweb" />,
    isAdded: false,
  },
]

const messages = defineMessages({
  design: {
    id: "SignaturesBuilder.TabDesign",
    defaultMessage: "Design",
  },
  properties: {
    id: "SignaturesBuilder.TabProperties",
    defaultMessage: "Propriétés",
  },
  parameters: {
    id: "SignaturesBuilder.TabSettings",
    defaultMessage: "Paramètres",
  },
  diffusion: {
    id: "SignaturesBuilder.TabDiffusion",
    defaultMessage: "Diffusion",
  },
})

function renderDefaultTabId(searchParams: URLSearchParams) {
  const tabParam = searchParams.get("panel")

  if (!tabParam) return 0

  switch (tabParam) {
    case "properties":
      return 1
    case "parameters":
      return 2
    case "diffusion":
      return 3
    case "design":
    default:
      return 0
  }
}

const PortalBuilderToolbar: FunctionComponent<PortalBuilderToolbarProps> = ({
  onSave,
  signatureName,
  activeBuilderSignature,
}) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const { setData } = useBuilderData()

  useStorageImagesDatas()

  useMustSave(onSave, signatureName, activeBuilderSignature)

  const userProperties = useSelector(usersSelectors.getUserProperties)

  const storageImages = useSelector(
    storageSelectors.getBuilderImagesFromImagesStorages,
  )

  const visuals = useMemo(
    () =>
      storageImages.map((img) => ({
        id: img.id,
        src: img.url.replace(CDN_DOMAIN, PROD_STORAGE_DOMAIN),
        alt: img.fileName,
        mimeType: img.mimeType,
      })),
    [storageImages],
  )

  const propertiesItems = useMemo(
    () =>
      extractAndReplacePhones(
        formatMessage(userPropertiesTranslationsForBuilder.phones),
        reorderUserProperties(
          userProperties
            .filter((u) => u.InternalName !== "banner")
            .map((userProperty) => {
              let propertyLabel = StringHelper.Capitalize(
                userProperty.InternalName,
              )
              if (
                userProperty.InternalName in
                userPropertiesTranslationsForBuilder
              )
                propertyLabel = formatMessage(
                  userPropertiesTranslationsForBuilder[
                    userProperty.InternalName
                  ],
                )

              return {
                id: userProperty.Id,
                label: `#${propertyLabel}`,
                value: `?#${userProperty.InternalName}#?`,
                property: userProperty.InternalName,
              }
            }),
        ),
      ),
    [userProperties],
  )

  const handleUploaded = async (blobFile: Blob) => {
    const file = fromBlobToFile(blobFile)
    dispatch(
      uploadImageToStorageAction(file, {
        "new-builder": true,
      }),
    )
  }

  const handleDeleted = (id: string) => {
    dispatch(deleteImageFromStorageAction(id))
  }

  return (
    <BuilderToolbar
      principal={[
        {
          id: 0,
          header: {
            label: formatMessage(messages.design),
          },
          defaultSubTabId: 0,
          subTabs: [
            {
              id: 0,
              header: {
                label: "Modèles",
                icon: <BmmIcon icon="Templates" />,
              },
              content: (
                <GroupedTemplateCards
                  templates={BUILDER_TEMPLATES}
                  onSelect={(template) => {
                    setData(template.json)
                  }}
                />
              ),
            },
            {
              id: 1,
              header: {
                label: "Images",
                icon: <BmmIcon icon="Images" />,
              },
              content: (
                <ImagesPanel
                  accept={IMAGE_LIBRARY_ACCEPTED_FORMATS}
                  visuals={visuals}
                  onUploaded={handleUploaded}
                  onDelete={handleDeleted}
                />
              ),
            },
            {
              id: 2,
              header: {
                label: "Texte",
                icon: <BmmIcon icon="Text" />,
              },
              content: <StaticTextPanel />,
            },
            {
              id: 3,
              header: {
                label: "Réseaux Sociaux",
                icon: <BmmIcon icon="SocialMedia" />,
              },
              content: <SocialsPanel items={socials} />,
            },
          ],
        },
        {
          id: 1,
          header: {
            label: formatMessage(messages.properties),
          },
          content: (
            <PropertiesPanel
              userProperties={
                <DraggablePropertiesList
                  items={propertiesItems}
                  draggable={true}
                />
              }
            />
          ),
        },
        {
          id: 2,
          header: {
            label: formatMessage(messages.parameters),
          },
          content: <BuilderParametersPanel />,
        },
        {
          id: 3,
          header: {
            label: formatMessage(messages.diffusion),
          },
          content: <BuilderAssignmentPanel />,
        },
      ]}
      defaultTabId={renderDefaultTabId(searchParams)}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
    />
  )
}

export default PortalBuilderToolbar
