import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react"

import config from "config/config"

interface ReCaptchaProps {
  id: string
  captcha: string
  onChange: (captcha: string) => void
}

declare global {
  interface Window {
    grecaptcha
  }
}

const GOOGLE_RECAPTCHA_URL =
  "https://www.google.com/recaptcha/api.js?render=explicit"

const ReCaptcha: FunctionComponent<ReCaptchaProps> = ({
  id,
  captcha,
  onChange,
}) => {
  const [scriptState, setScriptState] = useState(null)
  let recatchaElt

  const loadRecaptcha = () => {
    recatchaElt = window.grecaptcha.render(id, {
      sitekey: config.reCaptchaClientKey,
      theme: "light", // light | dark
      callback: handleChange,
    })
  }

  const handleScript = (e) => {
    setScriptState(e.type)
  }

  useLayoutEffect(() => {
    const script = document.createElement("script")

    script.src = GOOGLE_RECAPTCHA_URL
    script.async = true

    document.body.appendChild(script)

    script.addEventListener("load", handleScript)
    script.addEventListener("error", handleScript)
    return () => {
      script.removeEventListener("load", handleScript)
      script.removeEventListener("error", handleScript)
    }
  }, [])

  useEffect(() => {
    if (scriptState === "load") window.grecaptcha?.ready(() => loadRecaptcha())
  }, [scriptState])

  useEffect(() => {
    if ((captcha === null || captcha === "") && scriptState === "load")
      window.grecaptcha?.ready(() => window.grecaptcha.reset())
  }, [captcha])

  const getValue = () => {
    return window.grecaptcha.getResponse(recatchaElt)
  }

  const handleChange = () => {
    onChange(getValue())
  }

  return <div id={id} />
}

export default ReCaptcha
