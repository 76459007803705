import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { FormattedMessage } from "react-intl"

export interface AutoSaveProps {
  isHidden: boolean
}

class AutoSave extends React.Component<AutoSaveProps> {
  render() {
    return (
      <div>
        {!this.props.isHidden && (
          <div>
            <div className="autosave__container">
              <CircularProgress color="primary" size={30} thickness={2} />
              <span className="autosave-text">
                <FormattedMessage
                  id="AutoSave.saving"
                  defaultMessage="Enregistrement"
                />
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default AutoSave
