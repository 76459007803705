import { FunctionComponent, MouseEvent } from "react"

import clsx from "clsx"

import { ReactComponent as WarningIcon } from "assets/icon-warning.svg"
import { ReactComponent as ErrorIcon } from "assets/icon-error.svg"
import { ReactComponent as SuccessIcon } from "assets/icon-success.svg"
import { ReactComponent as PreviewIcon } from "assets/icon-preview.svg"
import { ReactComponent as UserIcon } from "assets/icon-user-regular.svg"

interface SignaturesDeploymentOverviewProps {
  status: { status: "error" | "warning" | "success"; value: number; id: number }
  id: number
  title: string
  deployed: number
  total: number
  isSelected: boolean
  handleClick: () => void
  handleDisplayPreview: (
    id: number | null,
    e?: MouseEvent<HTMLDivElement>,
  ) => void
  handleDisplayUsers: (
    id: number | null,
    e?: MouseEvent<HTMLDivElement>,
  ) => void
}

const icon = {
  error: <ErrorIcon style={{ width: "12px", height: "12px" }} />,
  warning: <WarningIcon style={{ width: "12px", height: "12px" }} />,
  success: <SuccessIcon style={{ width: "12px", height: "12px" }} />,
}

const SignaturesDeploymentOverview: FunctionComponent<
  SignaturesDeploymentOverviewProps
> = ({
  id,
  status,
  title,
  deployed,
  total,
  isSelected,
  handleClick,
  handleDisplayPreview,
  handleDisplayUsers,
}) => {
  return (
    <div
      className={clsx("sign-depl-overview", isSelected && "is-selected")}
      onClick={handleClick}
    >
      <div className={clsx("sign-depl-overview__status", status.status)}>
        {icon[status.status]}
      </div>
      <div className="sign-depl-overview__center">
        <div className="sign-depl-overview__infos">
          <p className="sign-depl-overview__name">{title}</p>
          <p className="sign-depl-overview__count">{`${deployed}/${total}`}</p>
        </div>
        {(status.status === "error" || status.status === "warning") && (
          <div className="sign-depl-overview__progress-meter">
            <div
              className={clsx(
                "sign-depl-overview__progress-meter--value",
                status.status,
              )}
              style={{ width: `${status.value}%` }}
            />
          </div>
        )}
      </div>
      <div
        className="sign-depl-overview__preview"
        onClick={(e) => handleDisplayPreview(id, e)}
      >
        <PreviewIcon />
      </div>
      <div
        className="sign-depl-overview__user"
        onClick={(e) => handleDisplayUsers(id, e)}
      >
        <UserIcon />
      </div>
    </div>
  )
}

export default SignaturesDeploymentOverview
