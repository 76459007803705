import { togglePropertyAction } from "features/Users/UsersActions"
import { connect } from "react-redux"
import { usersSelectors } from "../../"

import UserPropertiesPanel from "./UserPropertiesPanel"

const mapStateToProps = (state) => {
  return {
    properties: usersSelectors.getCurrentTemplateUserProperties(state),
  }
}

const mapDispatchToProps = {
  toggleProperty: togglePropertyAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPropertiesPanel)
