import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import Toggle from "components/MUIToggle"

import Feature from "features/Features/FeaturesModels"

interface GmailToggleProps {
  featureGmail: Feature
  onToggle: (activate: boolean) => void
  onTriggerJob: () => void
  onOpenParams: () => void
}

const messages = defineMessages({
  deployGmailTitle: {
    id: "GSuiteForm.DeployGmailTitle",
    defaultMessage: "Déploiement des signatures Gmail",
  },
  deployGmailDescription1: {
    id: "GSuiteForm.DeployGmailDescription1",
    defaultMessage: "Activez le déploiement automatique de vos signatures ",
  },
  deployGmailDescription2: {
    id: "GSuiteForm.DeployGmailDescription2",
    defaultMessage: "toutes les 12 heures",
  },
  deployGmailDisable: {
    id: "GSuiteForm.DeployGmailDisable",
    defaultMessage: "Désactivé",
  },
  deployGmailEnable: {
    id: "GSuiteForm.DeployGmailEnable",
    defaultMessage: "Activé",
  },
  manualDeploy: {
    id: "GSuiteForm.ManualDeploy",
    defaultMessage: "Déployer manuellement",
  },
  settings: {
    id: "GSuiteForm.Settings",
    defaultMessage: "Paramètres",
  },
})

const GmailToggle: FunctionComponent<GmailToggleProps> = ({
  featureGmail,
  onToggle,
  onTriggerJob,
  onOpenParams,
}) => {
  const { formatMessage } = useIntl()

  const [launchDeploy, setLaunchDeploy] = useState(false)

  const isDeploying = useJobIsRunning("DeployGmail")

  useEffect(() => {
    if (!isDeploying) setLaunchDeploy(false)
  }, [isDeploying])

  const handleDeploy = () => {
    setLaunchDeploy(true)
    onTriggerJob()
  }

  const handleToggle = (isActive) => {
    if (isActive === null) isActive = !featureGmail.activated

    onToggle(isActive)
  }

  return (
    <div className="gsuite-toggle">
      <p className="gsuite-toggle__title">
        {formatMessage(messages.deployGmailTitle)}
      </p>
      <p className="gsuite-toggle__description">
        {formatMessage(messages.deployGmailDescription1)}
        <span className="gsuite-toggle__description--bold">
          {formatMessage(messages.deployGmailDescription2)}
        </span>
      </p>
      <div className="gsuite-toggle__action">
        <div
          className={`gsuite-toggle__action--${
            featureGmail.activated ? "inactive" : "active"
          }`}
          onClick={() => handleToggle(false)}
        >
          {formatMessage(messages.deployGmailDisable)}
        </div>
        <div className="gsuite-toggle__toggle">
          <Toggle
            checked={featureGmail.activated}
            onChange={() => handleToggle(null)}
          />
        </div>
        <div
          className={`gsuite-toggle__action--${
            featureGmail.activated ? "active" : "inactive"
          }`}
          onClick={() => handleToggle(true)}
        >
          {formatMessage(messages.deployGmailEnable)}
        </div>
      </div>
      {featureGmail.activated && (
        <>
          <button
            type="button"
            className="gsuite-toggle__sync"
            onClick={handleDeploy}
            disabled={launchDeploy || isDeploying}
          >
            <p className="gsuite-toggle__sync--label">
              {formatMessage(messages.manualDeploy)}
            </p>
            <SyncIcon
              className={classNames(
                "gsuite-toggle__sync--icon",
                (launchDeploy || isDeploying) && "is-loading",
              )}
            />
          </button>
          <div className="gsuite-toggle__params">
            <div className="gsuite-toggle__params__btn" onClick={onOpenParams}>
              <i className="material-icons gsuite-toggle__params__btn--icon">
                settings
              </i>
              {formatMessage(messages.settings)}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default GmailToggle
