import { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import Slider from "@material-ui/core/Slider"
import { FormattedMessage } from "react-intl"

export const SLIDER_MIN_WIDTH = 30
export const SLIDER_MIN_HEIGHT = 30

interface Size {
  width: number
  height: number
}

interface ImageResizerProps {
  max: Size
  size: Size
  originalSize?: Size
  resizeImage(width: number, height: number)
}

const ImageResizer: FunctionComponent<ImageResizerProps> = ({
  max,
  size,
  resizeImage,
  originalSize,
}) => {
  const [width, setWidth] = useState(SLIDER_MIN_WIDTH)
  const [maxWidth, setMaxWidth] = useState(null)
  const [originalSizeState, setOriginalSizeState] = useState(null)

  useEffect(() => {
    if (originalSize && originalSize !== originalSizeState) {
      setOriginalSizeState(originalSize)
      setMaxWidth(max.width)
      setWidth(max.width > originalSize.width ? originalSize.width : max.width)
    }
  }, [originalSize, originalSizeState, max, maxWidth, size])

  useEffect(() => {
    setWidth(size.width)
  }, [size])

  useEffect(() => {
    setMaxWidth(max.width)
  }, [max])

  const handleWidthSliderChange = (
    _event: ChangeEvent<unknown>,
    value: number,
  ) => {
    setWidth(value)
    const height = value === 0 ? 0 : (size.height * value) / size.width
    resizeImage(value, height)
  }

  return (
    <div className="image-resizer">
      <div className="image-resizer__size">
        <div>
          <FormattedMessage
            id="ImageResizer.Size"
            defaultMessage="Dimensions"
          />
        </div>
        <div>
          <label>
            <FormattedMessage id="ImageResizer.WidthAbbr" defaultMessage="L" />
          </label>
          <span>{`${size.width}px`}</span>
          <label>
            <FormattedMessage id="ImageResizer.HeightAbbr" defaultMessage="H" />
          </label>
          <span>{`${Number(size.height).toFixed(0)}px`}</span>
        </div>
      </div>
      <Slider
        className="slider-control"
        min={SLIDER_MIN_WIDTH}
        max={maxWidth}
        disabled={maxWidth === null}
        value={width}
        onChange={handleWidthSliderChange}
        marks={[]}
      />
    </div>
  )
}

export default ImageResizer
