import { FunctionComponent, ReactNode } from "react"

interface SignaturesTileActiveCountLoadingProps {
  children: ReactNode
}

const SignaturesTileActiveCountLoading: FunctionComponent<
  SignaturesTileActiveCountLoadingProps
> = ({ children }) => {
  return (
    <div className="signature-tile__skeleton-loading--container">
      <div className="signature-tile__skeleton-loading--all-count" />
      <p className="signature-tile__skeleton-loading--label">{children}</p>
      <div className="signature-tile__skeleton-loading--active-count" />
    </div>
  )
}

export default SignaturesTileActiveCountLoading
