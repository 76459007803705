import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"

import SignaturesList from "features/Signatures/components/SignaturesList"

const SignatureHomePage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="SignaturesHome.Signatures"
          defaultMessage="Signatures"
        />
      }
    >
      <SignaturesList />
    </Page>
  )
}

export default SignatureHomePage
