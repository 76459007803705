import { FunctionComponent } from "react"

import Loader from "components/Loader"

import Calendar from "features/Scenarios/components/CampaignCalendar/Calendar/"
import CalendarRange from "features/Scenarios/components/CampaignCalendar/CalendarView/entities/CalendarRange"
import Layout from "features/Scenarios/components/ScenariosListLayout"

import { useSlicedScenariosDatas } from "features/Scenarios/hooks/useSlicedScenariosDatas"

import useDiffusionMode from "features/Scenarios/hooks/useDiffusionMode"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { LoadingStatus } from "core/CoreModels"

import { featureNames } from "config/config.features"

const ScenarioCalendarPage: FunctionComponent = () => {
  const { scenarios, loadingStatus } = useSlicedScenariosDatas()
  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)
  const { diffusionMode } = useDiffusionMode()

  if (loadingStatus === LoadingStatus.PENDING)
    return <Loader isLoading={true} />

  /* Mapping */
  const ranges: CalendarRange[] = scenarios
    .map((scenario) => ({
      id: scenario.id,
      name: scenario.name,
      startDate: scenario.startDate,
      endDate: scenario.endDate,
      imageUrl: scenario.banners[0].imageUrl,
      linkUrl: scenario.banners[0].linkUrl,
      users: scenario.userIds?.length,
      internal: scenario.internal,
      external: scenario.external,
      // signatures: scenario.AffectedSignatures,
    }))
    .filter((scenario) => {
      if (internalExternalFeature?.isActive) {
        if (diffusionMode.internal) return scenario.internal === true
        if (diffusionMode.external) return scenario.external === true
      }
      return scenario
    })

  return (
    <Layout>
      <Layout.Content>
        <Calendar ranges={ranges} />
      </Layout.Content>
    </Layout>
  )
}

export default ScenarioCalendarPage
