/**
 * Helpers for array manipulations
 *
 * @class ArrayHelper
 */

class ArrayHelper {
  /**
   * Replace the item wich
   * @param array
   * @param item
   * @param condition
   */
  public static Replace(
    array: any[],
    item,
    condition: (item1: any, item2: any) => boolean,
  ): any[] {
    return array.map((arrItem, index) => {
      // If the item in the array match the condition, we replace this by our item
      if (condition(arrItem, item)) {
        return {
          ...arrItem,
          ...item,
        }
      }
      // else, we keep the item at the same place
      return arrItem
    })
  }

  public static reject<T>(
    array: T[],
    iteratorFunction: (item: T) => boolean,
  ): T[] {
    return array.filter(function (item) {
      return !iteratorFunction(item)
    })
  }
}

export function containsAll(
  arr1: Array<string | number>,
  arr2: Array<string | number>,
): boolean {
  return arr2.every((arr2Item) => arr1.includes(arr2Item))
}

export function containsSameMembers(
  arr1: Array<string | number>,
  arr2: Array<string | number>,
): boolean {
  return containsAll(arr1, arr2) && containsAll(arr2, arr1)
}

export default ArrayHelper
