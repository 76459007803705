import { TrialActionTypes } from "features/Trial"

// Fonction déclenchée lorsque l'utilisateur est correctement identifié
import { call, put, select } from "redux-saga/effects"

import { offersSelectors } from "features/Offers"

import { fetchAllUsers, fetchProperties } from "features/Users/UsersReducers"
import { fetchExcelModelAction } from "features/Users/UsersActions"

import {
  fetchAccountsLinksAction,
  loadInitialDatasSuccessAction,
} from "features/Accounts/AccountsActions"

import { fetchTenantProperties } from "features/Accounts/AccountsReducer"

import { fetchTemplatesGalleryAction } from "features/Signatures/SignaturesActions"

import { fetchAllOffers, fetchAllOptions } from "features/Offers/OffersReducer"
import { hideGlobalLoader, showGlobalLoader } from "features/App/AppReducers"
import { initOnBoardingOrchestrator } from "features/Accounts/AccountsSagas"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* loadInitialDatas() {
  yield put(showGlobalLoader())
  // Chargement des users properties
  yield put(fetchProperties())
  // Chargement de la gallery de signatures
  yield put(fetchTemplatesGalleryAction())
  yield put(fetchAllOffers())

  // on recharge uniquement si les options ne sont pas déjà chargées,
  // afin de garder l'historique des options selectionnées à la fin de l'inscription
  const optionsAlreadyLoaded = yield select(offersSelectors.getAllOptions)
  if (optionsAlreadyLoaded.length === 0) yield put(fetchAllOptions())

  // On précharge les bannières car elles sont utilisées par la suite dans l'encart de signature pour remplacer les tags ?#banner#? par la bannière correspondante
  // Récupère les liens liés au tenant : installer, excel, powershell, o365 ...
  yield put(fetchAccountsLinksAction())
  yield put(fetchExcelModelAction())
  // Récupère les tenant properties
  yield put(fetchTenantProperties())

  yield call(initOnBoardingOrchestrator)

  // Chargement des utilisateurs
  yield put(fetchAllUsers())

  // Récupère le Tour JS / Trial
  yield put({ type: TrialActionTypes.FETCH_TRIAL })

  yield put(loadInitialDatasSuccessAction())

  yield put(hideGlobalLoader())
}
