import {
  call,
  takeEvery,
  select,
  take,
  takeLatest,
  all,
  put,
} from "redux-saga/effects"

import moment from "moment"

import { withSagaErrorHandler } from "features/Errors"

import {
  fetchBannerClicksByDate,
  fetchSignaturesDeliveredApi,
  fetchBannersClickedApi,
  fetchSignaturesClickedApi,
  fetchSignaturesHistoryApi,
  fetchBannersHistoryApi,
  createTrackingJobApi,
  fetchSingleBannerHistoryApi,
  fetchSingleBannerClickedApi,
} from "features/Tracking/TrackingApi"

import {
  checkTrackingJob,
  getBannersClickAction,
  initQueriesDateRangeAction,
  requestSingleScenarioTrackingDataAction,
} from "./TrackingActions"

import {
  fetchBannersClickSuccess,
  computeSignaturesPowerUsersData,
  computeSignaturesPowerUsersDataSuccess,
  computeSignaturesDeploymentData,
  computeSignaturesTrackingDataSuccess,
  computeSignaturesTrackingData,
  computeScenariosPowerUsersDataSuccess,
  computeScenariosTrackingDataSuccess,
  computeScenariosTrackingData,
  fetchSignaturesDelivered,
  fetchSignaturesDeliveredFailure,
  fetchSignaturesDeliveredSuccess,
  fetchBannersClickedSuccess,
  fetchBannersClickedFailure,
  fetchBannersClicked,
  computeScenariosPowerUsersData,
  fetchSignaturesClickedSuccess,
  fetchSignaturesClicked,
  fetchSignaturesClickedFailure,
  fetchSignaturesHistory,
  fetchSignaturesHistoryFailure,
  fetchSignaturesHistorySuccess,
  computeSignaturesDeploymentDataSuccess,
  fetchBannersHistory,
  fetchBannersHistorySuccess,
  fetchBannersHistoryFailure,
  setScenariosQueriesDateRange,
  setSignaturesQueriesDateRange,
  setQueriesDateRange,
  fetchSingleScenarioTrackingDatas,
  fetchSingleScenarioTrackingDatasFailure,
  fetchSingleScenarioTrackingDatasSuccess,
  setSingleScenarioQueryDateRange,
} from "./TrackingReducers"

import {
  SignaturesPowerUser,
  SignatureDeployment,
  SignatureTracking,
  ScenariosPowerUser,
  ScenarioTracking,
  BannersClickedDto,
  SignaturesClickedDto,
  SignaturesHistoryDto,
  QueriesDateRange,
  EventClickedDto,
  BannersHistoryDto,
} from "./TrackingModels"

import { getOrLoadSignaturesSlice } from "features/Signatures/SignaturesSagas"

import { LoadingStatus } from "core/CoreModels"

import Signature from "entities/Signature"

import { User } from "features/Users/UserModels"

import { scenarioSelectors } from "features/Scenarios"
import {
  createScenarioSuccess,
  deleteScenarioSuccess,
  duplicateScenarioSuccess,
  fetchScenarios,
  fetchScenariosSuccess,
  updateScenarioSuccess,
} from "features/Scenarios/ScenarioReducers"
import { ScenarioMapper } from "features/Scenarios/mappers/ScenarioMapper"

import SignatureTrackingMapper from "./mappers/SignatureTrackingMapper"
import SharedTrackingMapper from "./mappers/SharedTrackingMapper"
import ScenarioTrackingMapper from "./mappers/ScenarioTrackingMapper"

import {
  createSignatureSuccess,
  deleteSignatureSuccess,
  updateSignatureSuccess,
} from "features/Signatures/SignaturesReducer"
import { trackingSelectors } from "."
import { getOrLoadAllUsersAsync } from "features/Users/UsersSagas"
import { accountsSelectors } from "features/Accounts"

import {
  getFeaturesLoadingStatus,
  isFeatureActive,
} from "features/FeatureTogglr/FeatureTogglrSelectors"

import { featureNames } from "config/config.features"
import {
  fetchFeatures,
  setFeatures,
} from "features/FeatureTogglr/FeatureTogglrReducer"
import {
  fetchTenantProperties,
  fetchTenantPropertiesSuccess,
} from "features/Accounts/AccountsReducer"

import { TENANT_PROPERTY_NAMES } from "entities/TenantProperties"

import TenantProperty from "entities/TenantProperty"
import { SIGNATURES_DEPLOYMENT_FETCH_DATA_RANGE } from "./TrackingRules"

import { Scenario, ScenarioVm } from "features/Scenarios/ScenarioModels"
import { PayloadAction } from "@reduxjs/toolkit"
import { getOrLoadBuilderSignaturesSlice } from "features/BuilderSignatures/BuilderSignaturesSagas"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"
import { isBuilderSignature } from "features/BuilderSignatures/BuilderSignaturesHelpers"
import { createTenantPropertyAction } from "features/Accounts/AccountsActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getBannersClick() {
  const result = yield call(fetchBannerClicksByDate)
  if (!result.success) throw new Error(result.errorMessage)

  yield put(fetchBannersClickSuccess(result.result))
}

export function* getSignaturesDeliveredAsync() {
  const result = yield call(fetchSignaturesDeliveredApi, {})

  if (!result.success) throw new Error(result.errorMessage)

  yield put(fetchSignaturesDeliveredSuccess(result.result))
}

// * Statistiques V2

function getDataFromDateRange<T>(
  from: string,
  to: string,
  rawData: Array<{ range: QueriesDateRange; data: T[] }>,
) {
  let dataFromRange = null

  const mStartDate = moment(from)
  const mEndDate = moment(to)

  rawData.forEach((rawDataItem) => {
    const { startDate, endDate } = rawDataItem.range
    if (
      mStartDate.isBetween(startDate, endDate, "day", "[]") &&
      mEndDate.isBetween(startDate, endDate, "day", "[]")
    )
      dataFromRange = rawDataItem.data
  })

  return dataFromRange
}

function getEventHistoryFromCache(
  from: string,
  to: string,
  eventHistoryData: EventClickedDto[],
) {
  return eventHistoryData.filter(
    (eventHistory: BannersClickedDto | SignaturesClickedDto) =>
      moment(eventHistory.clickedDate).isBetween(from, to, "day", "[]"),
  )
}

function* buildScenarioTrackingEntity(
  scenarioViewModel: ScenarioVm,
  bannersHistoryEvents: BannersHistoryDto[],
  bannersClickedData: BannersClickedDto[],
) {
  const currentLoadingStatus = yield select(
    trackingSelectors.getSingleScenarioTrackingLoadingStatus,
  )

  const currentHistory = yield select(
    trackingSelectors.getSingleScenarioTrackingCurrentHistory(
      scenarioViewModel.id,
    ),
  )

  const currentAffectedUsersHistory = yield select(
    trackingSelectors.getSingleScenarioTrackingCurrentAffectedUsersHistory(
      scenarioViewModel.id,
    ),
  )

  const affectedUsersHistory =
    SharedTrackingMapper.getEventsAffectedUsersHistory(bannersHistoryEvents)

  const history = ScenarioTrackingMapper.getScenariosTrackingDataHistory(
    bannersClickedData,
    scenarioViewModel.banners.map((b) => b.id),
  )

  const mergedHistory =
    SharedTrackingMapper.removeDuplicatesFromTrackingHistoryByDate([
      ...history,
      ...currentHistory,
    ])

  return new ScenarioTracking(
    scenarioViewModel,
    [...affectedUsersHistory, ...currentAffectedUsersHistory],
    mergedHistory,
    currentLoadingStatus,
  )
}

function* computeSingleScenarioTrackingDataAsync(
  action: PayloadAction<number>,
) {
  const scenarioTracking = yield select(
    trackingSelectors.getScenarioTrackingById(action.payload),
  )

  if (scenarioTracking.loadingStatus === LoadingStatus.LOADED) {
    const to = moment
      .max(scenarioTracking.history.map((hEvent) => moment(hEvent.date)))
      .toISOString()

    yield put(
      setSingleScenarioQueryDateRange({
        startDate: moment(scenarioTracking.startDate).toISOString(),
        endDate: to,
      }),
    )

    return
  }

  yield put(fetchSingleScenarioTrackingDatas(action.payload))

  const historyRequest = yield call(
    fetchSingleBannerHistoryApi,
    scenarioTracking.id,
    scenarioTracking.startDate,
  )
  if (!historyRequest.success) throw new Error(historyRequest.errorMessage)

  const bannersIds = scenarioTracking.banners.map((b) => b.id)

  const clickedRequests = yield all(
    bannersIds.map((b) =>
      call(fetchSingleBannerClickedApi, b, scenarioTracking.startDate),
    ),
  )

  const bannersHistory = historyRequest.result.map((event) => ({
    ...event,
    created: moment(event.created).format("YYYY-MM-DD"),
  }))

  const clickedEvents = clickedRequests
    .map((c) =>
      c.result.map((clickedEvent) => ({
        ...clickedEvent,
        clickedDate: moment(clickedEvent.clickedDate).format("YYYY-MM-DD"),
      })),
    )
    .flat()

  const to = moment
    .max(clickedEvents.map((cEvent) => moment(cEvent.clickedDate)))
    .toISOString()

  yield put(
    setSingleScenarioQueryDateRange({
      startDate: moment(scenarioTracking.startDate).toISOString(),
      endDate: to,
    }),
  )

  const scenario = yield select((state) =>
    scenarioSelectors.getScenarioById(state, action.payload),
  )
  const scenarioViewModel = ScenarioMapper.toViewModel(scenario)

  const singleScenarioTrackingDatas = yield call(
    buildScenarioTrackingEntity,
    scenarioViewModel,
    bannersHistory,
    clickedEvents,
  )

  yield put(
    fetchSingleScenarioTrackingDatasSuccess(singleScenarioTrackingDatas),
  )
}

function* computeScenariosTrackingDataAsync(
  bannersClickedData: BannersClickedDto[],
) {
  yield put(computeScenariosTrackingData())

  const allScenariosLoadingStatus = yield select(
    scenarioSelectors.getScenarioSliceLoadedStatus("all"),
  )

  if (allScenariosLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchScenarios("all"))
    yield take(fetchScenariosSuccess.type)
  }

  const allScenarios = yield select(scenarioSelectors.getAllScenarios)

  const bannersHistoryLoadingStatus = yield select(
    trackingSelectors.getBannersHistoryLoadingStatus,
  )

  if (bannersHistoryLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchBannersHistory())
    yield take(fetchBannersHistorySuccess.type)
  }

  const bannersHistoryEvents = yield select(
    trackingSelectors.getBannersHistoryRawData,
  )

  const scenariosTrackingData = yield all(
    allScenarios.map((scenario: Scenario) => {
      const scenarioViewModel = ScenarioMapper.toViewModel(scenario)
      const currentScenarioHistoryEvents = bannersHistoryEvents.filter(
        (event) => event.scenarioId === scenarioViewModel.id,
      )
      return call(
        buildScenarioTrackingEntity,
        scenarioViewModel,
        currentScenarioHistoryEvents,
        bannersClickedData,
      )
    }),
  )

  yield put(computeScenariosTrackingDataSuccess(scenariosTrackingData))
}

function* computeScenariosPowerUsersAsync(
  bannersClickedData: BannersClickedDto[],
) {
  yield put(computeScenariosPowerUsersData())

  const allUsers: User[] = yield call(getOrLoadAllUsersAsync)

  const scenariosPowerUsers = allUsers
    .map((user) => {
      const history = ScenarioTrackingMapper.getScenariosPowerUserHistory(
        bannersClickedData,
        user.Id,
      )

      if (history === null) return null

      return new ScenariosPowerUser(user, history)
    })
    .filter((pu) => pu !== null)

  yield put(computeScenariosPowerUsersDataSuccess(scenariosPowerUsers))
}

function* computeSignaturesPowerUsersAsync(
  signaturesClickedEvents: SignaturesClickedDto[],
) {
  yield put(computeSignaturesPowerUsersData())

  const allUsers: User[] = yield call(getOrLoadAllUsersAsync)

  const allSignatures: Signature[] = yield call(getOrLoadSignaturesSlice, "all")

  const signaturesPowerUsers = allUsers
    .map((user) => {
      const maxClicksHistory =
        SignatureTrackingMapper.getSignaturesPowerUserHistory(
          signaturesClickedEvents,
          user.Id,
        )
      if (maxClicksHistory === null) return null
      const relatedSignature = allSignatures.find(
        (s) => s.Id === maxClicksHistory.signatureId,
      )

      if (!relatedSignature) return null
      return new SignaturesPowerUser(user, relatedSignature, maxClicksHistory)
    })
    .filter((data) => data !== null)

  yield put(computeSignaturesPowerUsersDataSuccess(signaturesPowerUsers))
}

function* computeSignaturesTrackingDataAsync(
  signaturesClickedEvents: SignaturesClickedDto[],
) {
  yield put(computeSignaturesTrackingData())

  const allSignatures: Signature[] = yield call(getOrLoadSignaturesSlice, "all")

  const allBuilderSignatures: BuilderSignature[] = yield call(
    getOrLoadBuilderSignaturesSlice,
    "all",
  )

  const signaturesHistoryLoadingStatus = yield select(
    trackingSelectors.getSignaturesHistoryLoadingStatus,
  )

  if (signaturesHistoryLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchSignaturesHistory())
    yield take(fetchSignaturesHistorySuccess.type)
  }

  const signaturesHistoryEvents = yield select(
    trackingSelectors.getSignaturesHistoryRawData,
  )

  const signaturesTrackingData = [
    ...allSignatures,
    ...allBuilderSignatures,
  ].map((signature) => {
    const signId = isBuilderSignature(signature) ? signature.id : signature.Id

    const currentSignatureClickEvents = signaturesClickedEvents.filter(
      (s) => s.signatureId === signId,
    )
    const currentSignatureAssignedEvents = signaturesHistoryEvents.filter(
      (event) => event.signatureId === signId,
    )

    const totalClicks = SignatureTrackingMapper.getSignatureTotalClicks(
      currentSignatureClickEvents,
    )
    const maxClicks = SignatureTrackingMapper.getSignatureMaxClicks(
      currentSignatureClickEvents,
    )

    const history = SignatureTrackingMapper.getSignaturesTrackingDataHistory(
      currentSignatureClickEvents,
    )

    const maxAffectedUsers = SharedTrackingMapper.getEventsMaxAffectedUsers(
      currentSignatureAssignedEvents,
    )

    return new SignatureTracking(
      signature,
      maxAffectedUsers,
      maxClicks,
      totalClicks,
      history,
    )
  })

  yield put(computeSignaturesTrackingDataSuccess(signaturesTrackingData))
}

function* computeSignaturesDeploymentsAsync(
  signaturesHistoryEvents: SignaturesHistoryDto[],
) {
  yield put(computeSignaturesDeploymentData())

  const allSignatures: Signature[] = yield call(getOrLoadSignaturesSlice, "all")

  const allBuilderSignatures: BuilderSignature[] = yield call(
    getOrLoadBuilderSignaturesSlice,
    "all",
  )

  const signaturesDeploymentData = [
    ...allSignatures,
    ...allBuilderSignatures,
  ].map((signature) => {
    const signId = isBuilderSignature(signature) ? signature.id : signature.Id

    const currentSignatureHistoryEvents = signaturesHistoryEvents.filter(
      (event) => event.signatureId === signId,
    )

    const history = SignatureTrackingMapper.getSignaturesDeploymentsDataHistory(
      currentSignatureHistoryEvents,
    )
    const users = SignatureTrackingMapper.getSignatureRelatedUsersState(
      currentSignatureHistoryEvents,
    )

    return new SignatureDeployment(signature, users, history)
  })

  yield put(computeSignaturesDeploymentDataSuccess(signaturesDeploymentData))
}

function* getRelatedQueriesDateRange(selector) {
  const initCheck = yield call(initQueriesDateRangeAsync)

  if (initCheck !== null) {
    yield take(setQueriesDateRange.type)
  }

  const { startDate, endDate } = yield select(selector)

  const from = moment(startDate).format("YYYY-MM-DD")
  const to = moment(endDate).format("YYYY-MM-DD")

  return { from, to }
}

function* fetchBannersClickedAsync(from: string, to: string) {
  const result = yield call(fetchBannersClickedApi, { from, to })

  if (!result.success) throw new Error(result.errorMessage)

  const bannersClickedEvents = result.result.map((clickedEvent) => ({
    ...clickedEvent,
    clickedDate: moment(clickedEvent.clickedDate).format("YYYY-MM-DD"),
  }))

  yield put(
    fetchBannersClickedSuccess({
      range: { startDate: from, endDate: to },
      data: bannersClickedEvents,
    }),
  )

  return bannersClickedEvents
}

function* getBannersClickedAsync() {
  const { from, to } = yield call(
    getRelatedQueriesDateRange,
    trackingSelectors.getScenariosDateRange,
  )

  const bannersClickedRawData = yield select(
    trackingSelectors.getBannersClickedRawData,
  )

  const cachedData = getDataFromDateRange(from, to, bannersClickedRawData)

  const bannersClickedEvents =
    cachedData === null
      ? yield call(fetchBannersClickedAsync, from, to)
      : (getEventHistoryFromCache(from, to, cachedData) as BannersClickedDto[])

  yield all([
    call(computeScenariosPowerUsersAsync, bannersClickedEvents),
    call(computeScenariosTrackingDataAsync, bannersClickedEvents),
  ])
}

function* getBannersHistoryAsync() {
  const result = yield call(fetchBannersHistoryApi)

  if (!result.success) throw new Error(result.errorMessage)

  const bannersHistoryEvents = result.result.map((event) => ({
    ...event,
    created: moment(event.created).format("YYYY-MM-DD"),
  }))

  yield put(fetchBannersHistorySuccess(bannersHistoryEvents))
}

function* fetchSignaturesClickedAsync(from: string, to: string) {
  const result = yield call(fetchSignaturesClickedApi, { from, to })

  if (!result.success) throw new Error(result.errorMessage)

  const signaturesClickedEvents = result.result.map((clickedEvent) => ({
    ...clickedEvent,
    clickedDate: moment(clickedEvent.clickedDate).format("YYYY-MM-DD"),
  }))

  yield put(
    fetchSignaturesClickedSuccess({
      range: { startDate: from, endDate: to },
      data: signaturesClickedEvents,
    }),
  )

  return signaturesClickedEvents
}

function* getSignaturesClickedAsync() {
  const { from, to } = yield call(
    getRelatedQueriesDateRange,
    trackingSelectors.getSignaturesDateRange,
  )

  const signaturesClickedRawData = yield select(
    trackingSelectors.getSignaturesClickedRawData,
  )

  const cachedData = getDataFromDateRange(from, to, signaturesClickedRawData)

  const signaturesClickedEvents =
    cachedData === null
      ? yield call(fetchSignaturesClickedAsync, from, to)
      : (getEventHistoryFromCache(
          from,
          to,
          cachedData,
        ) as SignaturesClickedDto[])

  yield all([
    call(computeSignaturesPowerUsersAsync, signaturesClickedEvents),
    call(computeSignaturesTrackingDataAsync, signaturesClickedEvents),
  ])
}

function* getSignaturesHistoryAsync() {
  const { from, to } = SIGNATURES_DEPLOYMENT_FETCH_DATA_RANGE

  const result = yield call(fetchSignaturesHistoryApi, { from, to })

  if (!result.success) throw new Error(result.errorMessage)

  const signaturesHistoryEvents = result.result.map((event) => ({
    ...event,
    created: moment(event.created).format("YYYY-MM-DD"),
  }))
  yield put(fetchSignaturesHistorySuccess(signaturesHistoryEvents))

  yield call(computeSignaturesDeploymentsAsync, signaturesHistoryEvents)
}

function* createTrackingJobAsync() {
  const response = yield call(createTrackingJobApi)

  if (!response.success) return null

  return true
}

function* initTrackingJob() {
  const featuresAreLoaded = yield select(getFeaturesLoadingStatus)
  const tenantPropertiesAreLoaded = yield select(
    accountsSelectors.getTenantPropertiesLoadingStatus,
  )

  if (featuresAreLoaded !== LoadingStatus.LOADED) {
    yield put(fetchFeatures())
    yield take(setFeatures.type)
  }

  if (tenantPropertiesAreLoaded !== LoadingStatus.LOADED) {
    yield put(fetchTenantProperties())
    yield take(fetchTenantPropertiesSuccess.type)
  }

  const trackingJobIsCreated = yield select(
    accountsSelectors.getTrackingJobCreatedProperty,
  )

  const newVersionStatistiques = yield select(
    isFeatureActive(featureNames.STATISTICS_V2),
  )

  if (!newVersionStatistiques) return

  if (trackingJobIsCreated?.Value === "created") return

  const createJob = yield call(createTrackingJobAsync)

  if (createJob) {
    const trackingJobProperty = new TenantProperty(
      TENANT_PROPERTY_NAMES.TRACKING_JOB_CREATED,
      "created",
    )

    yield put(createTenantPropertyAction(trackingJobProperty))
  }
}

function* initQueriesDateRangeAsync() {
  const scenariosDateRange = yield select(
    trackingSelectors.getScenariosDateRange,
  )

  const signaturesDateRange = yield select(
    trackingSelectors.getSignaturesDateRange,
  )

  if (scenariosDateRange !== null && signaturesDateRange !== null) return null

  const currentDay = moment().toISOString()

  const oneMonthAgo = moment().subtract(1, "months").toISOString()

  yield put(
    setQueriesDateRange({ startDate: oneMonthAgo, endDate: currentDay }),
  )
}

export const trackingWatchers = [
  takeEvery(getBannersClickAction.type, withSagaErrorHandler(getBannersClick)),
  takeLatest(
    fetchSignaturesDelivered.type,
    withSagaErrorHandler(getSignaturesDeliveredAsync),
    fetchSignaturesDeliveredFailure(),
  ),
  takeLatest(
    [
      fetchBannersClicked.type,
      setScenariosQueriesDateRange.type,
      createScenarioSuccess.type,
      updateScenarioSuccess.type,
      deleteScenarioSuccess.type,
      duplicateScenarioSuccess.type,
    ],
    withSagaErrorHandler(getBannersClickedAsync),
    fetchBannersClickedFailure(),
  ),
  takeLatest(
    [
      fetchBannersHistory.type,
      createScenarioSuccess.type,
      updateScenarioSuccess.type,
      deleteScenarioSuccess.type,
      duplicateScenarioSuccess.type,
    ],
    withSagaErrorHandler(getBannersHistoryAsync),
    fetchBannersHistoryFailure(),
  ),
  takeLatest(
    [
      fetchSignaturesClicked.type,
      setSignaturesQueriesDateRange.type,
      createSignatureSuccess.type,
      updateSignatureSuccess.type,
      deleteSignatureSuccess.type,
    ],
    withSagaErrorHandler(getSignaturesClickedAsync),
    fetchSignaturesClickedFailure(),
  ),
  takeLatest(
    [
      fetchSignaturesHistory.type,
      createSignatureSuccess.type,
      updateSignatureSuccess.type,
      deleteSignatureSuccess.type,
    ],
    withSagaErrorHandler(getSignaturesHistoryAsync),
    fetchSignaturesHistoryFailure(),
  ),
  takeEvery(checkTrackingJob.type, withSagaErrorHandler(initTrackingJob)),
  takeLatest(
    initQueriesDateRangeAction.type,
    withSagaErrorHandler(initQueriesDateRangeAsync),
  ),
  takeLatest(
    requestSingleScenarioTrackingDataAction.type,
    withSagaErrorHandler(
      computeSingleScenarioTrackingDataAsync,
      fetchSingleScenarioTrackingDatasFailure(),
    ),
  ),
]
