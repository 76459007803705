import React, { useLayoutEffect, useRef, useState } from "react"
import moment from "moment"

import CalendarView from "../CalendarView"
import CalendarCardList from "../CalendarCardList"
import CalendarRange from "../CalendarView/entities/CalendarRange"

import Banner from "entities/Banner"

// TODO - généraliser du point de vue du calendrier l'affichage
export interface CalendarProps {
  ranges: CalendarRange[]
  onRangeChange?: (banner: Banner) => void
}

const Calendar: React.FC<CalendarProps> = (props) => {
  /* State */
  const { ranges } = props
  const [selectedId, setSelectedId] = useState(0)
  const [hoveredId, setHoveredId] = useState(0)
  const [date, setDate] = useState(moment().format())
  const calendarMainRef = useRef(null)
  const calendarSideRef = useRef(null)

  useLayoutEffect(() => {
    const height = calendarMainRef.current.children[0].clientHeight
    calendarSideRef.current.children[0].style.height = height + "px"
  }, [date])

  const handleChange = (id, start, end) => {
    const { onRangeChange } = props
    if (start && end) {
      const banner = props.ranges.find((banner) => banner.id === id)
      const updatedBanner = new Banner({
        ...banner,
        StartDate: start,
        EndDate: end,
      })
      onRangeChange && onRangeChange(updatedBanner)
    }
  }

  const filterRangesOnDate = (ranges: CalendarRange[] = [], date: string) =>
    ranges.filter((range) =>
      moment(date).isBetween(
        moment(range.startDate),
        moment(range.endDate),
        "month",
        "[]",
      ),
    )

  /* Rendering */
  return (
    <div className="banners-calendar">
      <div ref={calendarMainRef} className="banners-calendar__main">
        <CalendarView
          ranges={ranges}
          selectedId={selectedId}
          hoveredId={hoveredId}
          onSelect={(id: number) => setSelectedId(id)}
          onHover={(id: number) => setHoveredId(id)}
          onMonthChange={(date: string) => setDate(date)}
        />
      </div>
      <div ref={calendarSideRef} className="banners-calendar__aside">
        <CalendarCardList
          ranges={filterRangesOnDate(ranges, date)}
          date={date}
          selectedId={selectedId}
          hoveredId={hoveredId}
          onCloseSelected={() => setSelectedId(0)}
          onChange={handleChange}
          onSelect={(id: number) => setSelectedId(id)}
          signaturesLoaded={null}
          signatureUsersLoaded={null}
        />
      </div>
    </div>
  )
}

export default Calendar
