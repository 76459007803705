import React, { useState } from "react"

import classNames from "classnames"

import { ReactComponent as IconCheck } from "assets/icon-check.svg"

import MouseTooltip from "components/MouseTooltip"

import { PeoplePickerCollapseListItem } from "../PeoplePickerCollapseLists"

interface CollapseListItemProps {
  item: PeoplePickerCollapseListItem
  checked?: boolean
  onClick: (item: PeoplePickerCollapseListItem) => void
}

const CollapseListItem: React.FC<CollapseListItemProps> = ({
  item,
  onClick,
  checked,
}) => {
  const [visible, setVisible] = useState(false)

  const handleMouseEnter = () => {
    setVisible(true)
  }

  const handleMouseLeave = () => {
    setVisible(false)
  }

  const handleClick = () => {
    if (item.isDisabled && !checked) return

    onClick(item)
  }

  return (
    <>
      <div
        key={item.id}
        className="people-picker-collapse-list__item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <p
          className={classNames(
            "people-picker-collapse-list__item--label",
            checked && "checked",
            item.isDisabled && !checked && "is-disabled",
          )}
        >
          {item.label}
        </p>
        <div
          className={classNames(
            "people-picker-collapse-list__item--icon",
            item.isDisabled && !checked && "is-disabled",
          )}
        >
          {checked && <IconCheck />}
          {item.isDisabled && !checked && <IconCheck />}
        </div>
      </div>
      {item.message && (
        <MouseTooltip visible={visible}>{item.message}</MouseTooltip>
      )}
    </>
  )
}

export default CollapseListItem
