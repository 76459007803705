import { LoadingStatus } from "core/CoreModels"
import { GlobalStates } from "store"
import { Subsidiary } from "./SubsidiaryModels"

export const getLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.subsidiaries.allSubsidiaries.loadingStatus

export const getUpdatingStatus = (state: GlobalStates): boolean =>
  state.subsidiaries.allSubsidiaries.isUpdated

export const getCurrentSubsidiary = (state: GlobalStates): Subsidiary =>
  state.subsidiaries.currentSubsidiary

export const getCurrentSubsidiaries = (state: GlobalStates): Subsidiary[] =>
  state.subsidiaries.allCurrentSubsidiaries?.subsidiaries

export const getCurrentSubsidiariesLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.subsidiaries.allCurrentSubsidiaries.loadingStatus
