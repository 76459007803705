/**
 * Composant Wrapper - Encapsule le Material Ui Field pour pouvoir préciser le style générique qu'une seule fois.
 */
import React from "react"
import TextBox from "components/TextBox"
import { injectIntl } from "react-intl"

const styles = {
  inputName: {
    underlineStyle: {
      borderColor: "#4776E6",
    },
    floatingLabelStyle: {
      color: "#747475",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
    },
    floatingLabelFocusStyle: {
      color: "#4776E6",
      fontSize: "0.9rem",
    },
  },
}

interface State {
  textType: string
  icon: string
}

interface PasswordProps {
  error: string
  className: string
  value: string
  validators?: [{ checker: any; errorText: string }]
}

class Password extends React.Component<PasswordProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      textType: "password",
      icon: "password__icon__hide",
    }
  }

  changeType = () => {
    let textType = "password"
    let icon = "password__icon__hide"
    if (this.state.textType === textType) {
      textType = "text"
      icon = "password__icon"
    }

    this.setState({ textType, icon })
  }

  renderDynamicError = () => {
    const errors = []
    if (this.props.validators) {
      this.props.validators.forEach(({ checker, errorText }, idx) => {
        if (!checker(this.props.value)) {
          errors.push(
            <li key={idx} className="password__error-item password__error--ko">
              {errorText}
            </li>,
          )
        } else {
          errors.push(
            <li key={idx} className="password__error-item password__error--ok">
              <div>{errorText}</div>
              <i className="material-icons">done</i>
            </li>,
          )
        }
      })
    }
    return errors
  }

  render() {
    const textBoxProps = { ...this.props }

    return (
      <React.Fragment>
        <div className="password__container">
          <TextBox
            error={this.props.error}
            className={"bmm-textbox " + this.props.className}
            underlineFocusStyle={styles.inputName.underlineStyle}
            floatingLabelFocusStyle={styles.inputName.floatingLabelFocusStyle}
            floatingLabelStyle={styles.inputName.floatingLabelStyle}
            type={this.state.textType}
            {...textBoxProps}
          />
          <div className="password__icon__container">
            <i
              className={this.state.icon}
              aria-hidden="true"
              onClick={this.changeType}
            ></i>
          </div>
        </div>
        <div className="password__error">
          <ul>{this.renderDynamicError()}</ul>
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(Password)
