import {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"

import classNames from "classnames"

interface MouseTooltipProps {
  children: ReactNode
  visible: boolean
  level?: "info" | "warning"
  offsetX?: number
  offsetY?: number
  className?: string
  style?: CSSProperties
}

const MouseTooltip: FunctionComponent<MouseTooltipProps> = ({
  children,
  visible,
  level,
  offsetX = 0,
  offsetY = 30,
  className,
  style,
}) => {
  const divRef = useRef(null)
  const [xPosition, setXposition] = useState(0)
  const [yPosition, setYPosition] = useState(0)
  const [xOffset, setXOffset] = useState(0)

  const [mouseMoved, setMouseMoved] = useState(false)

  const getTooltipPosition = ({ clientX, clientY }) => {
    setXposition(clientX)
    setYPosition(clientY)
    setMouseMoved(true)
  }

  const addListener = () => {
    window.addEventListener("mousemove", getTooltipPosition)
  }

  const removeListener = () => {
    window.removeEventListener("mousemove", getTooltipPosition)
  }

  useEffect(() => {
    if (visible) addListener()

    if (!visible) removeListener()

    return () => {
      removeListener()
    }
  }, [visible])

  useEffect(() => {
    const boundingClientRect = divRef.current

    if (boundingClientRect === null && !visible) return
    const { left, right } = boundingClientRect.getBoundingClientRect()
    const { innerWidth } = window

    const rightOffset = right > innerWidth ? -(right - left) / 2 : 0
    setXOffset(rightOffset)
  }, [visible])

  return (
    <div
      ref={divRef}
      className={classNames("mouse-tooltip", level, className)}
      style={{
        display: visible && mouseMoved ? "block" : "none",
        position: "fixed",
        top: yPosition + offsetY,
        left: xPosition + offsetX + xOffset,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default MouseTooltip
