import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"

import { LoadingStatus } from "core/CoreModels"
import { RootState } from "store/configureStore"

import { StorageImage } from "./StorageModels"

interface StorageImageEntities {
  [id: string]: StorageImage
}

export interface StorageState {
  allImages: {
    entities: StorageImageEntities
    ids: Array<string>
    loadingStatus: LoadingStatus
  }
}

const storageImagesAdapter = createEntityAdapter<StorageImage>({
  selectId: (storageImage) => storageImage.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id),
})

export const { selectAll: selectAllStorageImages } =
  storageImagesAdapter.getSelectors<RootState>(
    (state) => state.storage.allImages,
  )

const initialState = {
  allImages: storageImagesAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
}

const storageReducer = createSlice({
  name: "storage",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchAllImages(state, _action: PayloadAction<Array<string>>) {
      state.allImages.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllImagesSuccess(state, action: PayloadAction<Array<StorageImage>>) {
      storageImagesAdapter.setAll(state.allImages, action.payload)
      state.allImages.loadingStatus = LoadingStatus.LOADED
    },
    fetchAllImagesFailure(state) {
      state.allImages.loadingStatus = LoadingStatus.ERROR
    },
    uploadImageSuccess(state, action: PayloadAction<StorageImage>) {
      storageImagesAdapter.addOne(state.allImages, action.payload)
    },
    deleteImageSucess(state, action: PayloadAction<string>) {
      storageImagesAdapter.removeOne(state.allImages, action.payload)
    },
  },
})

export const {
  fetchAllImages,
  fetchAllImagesSuccess,
  fetchAllImagesFailure,
  uploadImageSuccess,
  deleteImageSucess: deleteImageSuccess,
} = storageReducer.actions

export default storageReducer.reducer
