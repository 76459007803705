import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface TrackingTotalsInsufficientDatasProps {
  title: string
}

const message = defineMessages({
  datas: {
    id: "Tracking.TrackingTotalsInsufficientDatas",
    defaultMessage:
      "En attente de données suffisantes pour afficher les taux de clics",
  },
})

const TrackingTotalsInsufficientDatas: FunctionComponent<
  TrackingTotalsInsufficientDatasProps
> = ({ title }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="tracking-totals__insuff">
      <p className="tracking-totals__insuff--title">{title}</p>
      <InfoIcon className="tracking-totals__insuff--icon" />
      <p className="tracking-totals__insuff--label">
        {formatMessage(message.datas)}
      </p>
    </div>
  )
}

export default TrackingTotalsInsufficientDatas
