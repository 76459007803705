import { ReactNode } from "react"

import { ColumnConfig, SortOrders } from "../PaginatedDataGrid"

import {
  UnfoldMore,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@material-ui/icons"

interface SortableButtonProps<T> {
  children: ReactNode
  column: ColumnConfig<T>
  sortOrders: SortOrders
  onHeaderCellsClick?: (column: ColumnConfig<T>, index: number) => void
}

const SortableButton = <T,>({
  children,
  column,
  sortOrders,
}: SortableButtonProps<T>): JSX.Element => {
  const isSorted = sortOrders.find(
    ({ columnKey }) => columnKey === column.sortKey,
  )

  const toggleSortOrder = isSorted?.sortOrder === "asc" ? "desc" : "asc"

  return (
    <>
      <div>{children}</div>
      <div
        className="data-grid__th__icon"
        onClick={() => column.onSort(column.sortKey, toggleSortOrder)}
      >
        {column.sortKey && isSorted ? (
          isSorted?.sortOrder === "desc" ? (
            <KeyboardArrowUp style={{ fontSize: 14 }} />
          ) : (
            <KeyboardArrowDown style={{ fontSize: 14 }} />
          )
        ) : (
          <UnfoldMore style={{ fontSize: 14 }} />
        )}
      </div>
    </>
  )
}

export default SortableButton
