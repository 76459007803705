import React from "react"

import CampaignPreview from "features/Scenarios/components/CampaignPreview"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

const CampaignFormSummary: React.FC = () => {
  const { editingCampaign } = useScenarioFormContext()

  return (
    <div className="campaignform__summary">
      <CampaignPreview
        url={editingCampaign.banners[0]?.imageUrl}
        width={editingCampaign.banners[0]?.width}
      />
    </div>
  )
}

export default CampaignFormSummary
