import StringHelper from "../utils/StringHelper"
import DataTag from "entities/DataTag"
import { map, merge, uniq, clone } from "lodash"

import * as $ from "jquery"
import LogoTag from "entities/LogoTag"
import { replace } from "lodash"

export const DATA_TAG = "data-bmm-tag"

export const BANNER_TAG = "?#banner#?"
export const LOGO_TAG = "logo"
export const HIDE_IF_DATATAG_EMPTY = "hide-if-empty"

export const LINKEDIN_TAG = "linkedin"
export const FACEBOOK_TAG = "facebook"
export const TWITTER_TAG = "twitter"
export const INSTAGRAM_TAG = "instagram"
export const PINTEREST_TAG = "pinterest"

export const TAG_SEPARATOR = "?#"

export const TEMPLATE_ID_TAG = "?#templateId#?"
export const DATA_COLOR_TAG = "?#theme#?"

export const COLOR_TAG = "?#color#?"
export const Colors = [
  "4A4A4A",
  "9C27B0",
  "2196F3",
  "4CAF50",
  "FFC107",
  "FF7C10",
  "D50000",
]

export const MAX_LOGO_SIZE = 500

export enum TemplateMode {
  Full = "full",
  Light = "light",
  Raw = "raw",
}

/* Liste actuelle des ressources stockées dans le template de signature:
 *  - Logo - avec taille
 *  - Tags sociaux
 *  - TemplateId
 *  - Color
 **/

/**
 * Représente un modèle de signature - permet d'obtenir son contenu sous différentes formes
 * - Compile : prend les informations depuis le rawContent -> compiledContent
 * - Decompile : prend les informations depuis le compiledContent -> rawContent
 */
export class Template {
  Id: number
  Name: string
  Custom: boolean
  rawContent: string
  rawLightContent: string
  rawRawContent: string
  compiledContent: string
  sampleUserProps: {} // si le template a été compilé une fois avec des données utilisateurs, on les stock pour ne pas rafraîchir à chaque fois les données
  templateId: number
  currentColor: string
  maxWidth: number

  dataTags: {
    logo: LogoTag
    linkedin: DataTag
    facebook: DataTag
    twitter: DataTag
    google: DataTag
    youtube: DataTag
    instagram: DataTag
    pinterest: DataTag
  }

  systemTags: string[] = [TEMPLATE_ID_TAG, COLOR_TAG, DATA_COLOR_TAG] // liste des tags systèmes
  userProperties: string[] = []
  currentMode: TemplateMode = TemplateMode.Full
  bannerMarkup = ""
  showBanner = false

  constructor(templateJson) {
    if (templateJson.id) this.Id = templateJson.id
    this.InitDataTags()
    this.sampleUserProps = null
  }

  /**
   * Si le nouveau template est différent, réinitialise les dataTags
   */
  public InitDataTags() {
    this.dataTags = {
      logo: {
        name: "logo",
      },
      linkedin: {
        name: "linkedin",
      },
      facebook: {
        name: "facebook",
      },
      twitter: {
        name: "twitter",
      },
      google: {
        name: "google",
      },
      youtube: {
        name: "youtube",
      },
      instagram: {
        name: "instagram",
      },
      pinterest: {
        name: "pinterest",
      },
    }
    return this
  }

  public GetRawContent() {
    switch (this.currentMode) {
      case TemplateMode.Light:
        return this.rawLightContent
      case TemplateMode.Raw:
        return this.rawRawContent
      default:
        return this.rawContent
    }
  }

  public SetRawContent(newContent) {
    switch (this.currentMode) {
      case TemplateMode.Light:
        return (this.rawLightContent = newContent)
      case TemplateMode.Raw:
        return (this.rawRawContent = newContent)
      default:
        return (this.rawContent = newContent)
    }
  }

  // Main méthode - compile entièrement le modèle à partir d'un nouveau modèle de la gallerie
  public UpdateTemplate(sourceTemplate: Template = this) {
    // !important - stocker un "clone" des anciens dataTags avant de les remplacer par les nouveaux
    const currentDataTags = clone(this.dataTags)
    // 1 - remplacement du compiled content par le Raw Content pour avoir les dataTags et les user Tags
    this.SetRawContent(sourceTemplate.GetRawContent())
    this.compiledContent = sourceTemplate.GetRawContent()

    //FIX - On écrase toujours le contenu Light de la signature par celui du template
    this.rawLightContent = sourceTemplate.rawLightContent

    this.ResetDataTagsVisible()

    this.DecompileDataTags()

    this.UpdateDataTags(currentDataTags)

    this.CompileDataTags()

    this.CompileBannerTag()

    this.CompileColorTags(this.currentColor)

    this.CompileInvisibleProperties()

    this.CompileUserTags(this.sampleUserProps)

    this.CompileSeparators()

    this.CompileHideIfEmpty()
  }

  getTagName(tagName: string) {
    return `${TAG_SEPARATOR}${tagName}${TAG_SEPARATOR.split("")
      .reverse()
      .join("")}`
  }

  /**
   * Process le raw content d'un des 3 templates avant de l'envoyer au serveur pour ajouter les infos à enregistrer dans le template (logo, réseaux sociaux, template id)
   * et retirer les tags qui sont masqués
   */
  public GetRecordableTemplates() {
    // Pour l'instant on ne process que le template Full
    const rawContent = this.GetRecordableTemplate(this.rawContent)

    return {
      template: rawContent,
      templateLight: this.rawLightContent,
      templateRaw: this.rawRawContent,
    }
  }

  public GetRecordableTemplate(rawTemplate) {
    if (rawTemplate == null) return
    if (this.templateId == null)
      throw new Error(
        "Error Template - la propriété templateID est obligatoire",
      )

    rawTemplate = rawTemplate.replace(
      TEMPLATE_ID_TAG,
      `data-bmm-template-id="${this.Id || this.templateId}"`,
    )
    rawTemplate = rawTemplate.replace(
      DATA_COLOR_TAG,
      `data-bmm-color="${this.currentColor}"`,
    )

    for (const key in this.dataTags) {
      if (this.dataTags[key] && this.dataTags[key].markup != null) {
        if (key === "logo" || this.dataTags[key].url !== "") {
          // si le datatag existe et est utilisé
          const bmmTagName = this.getTagName(key)
          rawTemplate = rawTemplate.replace(
            bmmTagName,
            this.dataTags[key].markup,
          ) // on le remplace par son contenu pour sauvegarde en base
          // gestion de la taille sur le TD parent
          const logoTag = this.dataTags[key] as LogoTag
          if (logoTag && logoTag.size) {
            const $rawTemplate = $(rawTemplate)
            const $parentTd = $rawTemplate
              .find("a[data-bmm-tag='?#logo#?']")
              .parents("td")
              .first()
            $parentTd.attr("width", logoTag.size.width)
            rawTemplate = $rawTemplate[0].outerHTML
          }
        } else {
          const bmmTagName = this.getTagName(key)

          // cas spécial - si un parent comporte l'attr hide-if-empty, on le retire du markup
          const $template = $(rawTemplate)
          const dataTagParent = $template.find(
            ":contains('" + bmmTagName + "'):last",
          )
          if (
            dataTagParent.length > 0 &&
            dataTagParent.attr(HIDE_IF_DATATAG_EMPTY) != null
          ) {
            $template.find(":contains('" + bmmTagName + "'):last").remove()
            rawTemplate = $template[0].outerHTML
          } else {
            // sinon, on retirer uniquement le tag enfant
            rawTemplate = rawTemplate.replace(bmmTagName, "")
          }
        }
      }
    }

    return rawTemplate
  }

  public DecompileTemplateId() {
    const regex = /.*(data-bmm-template-id=["'](.*?)["']).*>/
    const match = this.GetRawContent().match(regex)
    if (match != null && match.length > 0) {
      this.SetRawContent(
        this.GetRawContent().replace(match[1], TEMPLATE_ID_TAG),
      )
      this.templateId = parseInt(match[2], 10)
    }
  }

  public DecompileColor() {
    const regex = /.*(data-bmm-color=["'](.*?)["']).*>/
    const match = this.GetRawContent().match(regex)
    if (match != null && match.length > 0) {
      this.SetRawContent(this.GetRawContent().replace(match[1], DATA_COLOR_TAG))
      this.currentColor = match[2]
    }
  }

  /**
   * Remplace les ressources stockées dans le template par leur tag Bmm, dans le raw content
   * -- Lors de l'enregistrement d'une signature, on sauvegarde les informations directement dans le template, avec un attribut custom `data-bmm-tag` afin de savoir à quoi corresponde chacune de ces ressource. Lors du chargement du template, il faut donc remplacer ces ressources par le tag BMM et stocker leur valeur dans le Template, afin de permettre à l'utilisateur de modifier ces dernières
   */
  public DecompileDataTags() {
    for (const key in this.dataTags) {
      this.DecompileMarkup(key)
    }
    // this.ReplaceDataTagMarkupByTagName();
    this.compiledContent = this.GetRawContent()
  }

  // stock le contenu d'un data-tag-bmm dans une variable d'instance
  // et remplace la balise portant cet attr data-tag-bmm par un marqueur ?##?
  public DecompileMarkup(dataTagName: string) {
    try {
      const bmmTagName = this.getTagName(dataTagName)
      // 1. Remplacement du tag de color
      const rawContentWithColors = this.GetRawContent().replace(
        /\?#color#\?/g,
        this.currentColor,
      )

      // 2. Parcours des datatags et extraction de leurs infos
      const $dataTags = $(rawContentWithColors).find(
        "a[data-bmm-tag='" + bmmTagName + "']",
      )
      if ($dataTags.length > 0) {
        const $dataTag = $dataTags.first()
        const visible = true
        const url = $dataTag.attr("href")
        const markup = $dataTag[0].outerHTML
        const children = $dataTag.html()

        // on stock le lien et le contenu de la balise avant de la remplacer par le tag
        const datatag = {
          name: dataTagName,
          markup,
          url,
          children,
          visible,
        }

        // Récupération des informations du logo
        if (dataTagName === LOGO_TAG)
          this.dataTags[dataTagName] = this.extractLogoInfos(
            markup,
            datatag as LogoTag,
          )

        this.dataTags[dataTagName] = datatag

        this.dataTags[dataTagName] = datatag
        const $rawContent = $(rawContentWithColors)
        $rawContent
          .find("a[data-bmm-tag='" + bmmTagName + "']")
          .replaceWith(bmmTagName)

        this.SetRawContent(
          $rawContent[0].outerHTML
            .replace('?#templateid#?=""', "?#templateId#?")
            .replace('?#theme#?=""', "?#theme#?"),
        )
        return this
      }
    } catch (error) {}
  }

  GetSvgUrls() {
    const urls = []
    const $urls = $(this.rawContent).find("img[src*='.svg']")
    for (const svgUrl of $urls) {
      urls.push($(svgUrl).attr("src"))
    }
    return urls
  }

  ReplaceSvgLinksByMarkup(url, svgMarkup) {
    const $rawContent = $(this.rawContent)
    const $svgImg = $rawContent.find(`img[src='${url}']`)
    if ($svgImg.length === 0) return
    const $svgMarkup = $(svgMarkup)
    // ajout du nom du fichier initial pour récupération lors de sa génération sous forme de PNG
    const splittedUrl = url.split("/")
    $svgMarkup.attr(
      "data-file-name",
      splittedUrl[splittedUrl.length - 1].split(".")[0],
    )

    // stockage des attributs de style qu'on appliquera à l'image générée
    if ($svgImg[0].style.cssText !== "")
      $svgMarkup.attr("style", $svgImg[0].style.cssText)
    $svgMarkup.attr("height", $svgImg.attr("width"))
    $svgMarkup.attr("width", $svgImg.attr("height"))

    $svgImg.replaceWith($svgMarkup[0].outerHTML)
    this.SetRawContent($rawContent[0].outerHTML)
  }

  /**
   * Retourne la valeur de l'attr data-file-name s'il existe, sinon on utilise le nom du dataTag
   */
  GetSvgFileName(dataTag: DataTag) {
    if (dataTag.markup && $(dataTag.markup).find("svg").length > 0)
      return $(dataTag.markup).find("svg").first().attr("data-file-name")
    return dataTag.name
  }

  /**
   * Remplace les markup portant l'attribut custom [data-bmm-tag] par leur équivalent ?#<tagname>#?
   */
  ReplaceDataTagMarkupByTagName() {
    const rawContentWithColors = this.GetRawContent().replace(
      /\?#color#\?/g,
      this.currentColor,
    )
    const $rawContent = $(rawContentWithColors)

    for (const key in this.dataTags) {
      const bmmTagName = this.getTagName(key)
      $rawContent
        .find("a[data-bmm-tag='" + bmmTagName + "']")
        .replaceWith(bmmTagName)
    }

    this.SetRawContent(
      $rawContent[0].outerHTML
        .replace('?#templateid#?=""', "?#templateId#?")
        .replace('?#theme#?=""', "?#theme#?"),
    )
  }

  /**
   * Extrait les informations contenu dans le logo sous forme de DataTags
   */
  extractLogoInfos(markup: string, logoDataTag: LogoTag) {
    logoDataTag.idealSize = { width: 0, height: 0 }
    const $markup = $(markup)
    if ($markup.find("[data-ideal-width]").length > 0)
      logoDataTag.idealSize.width = parseInt(
        $markup.find("[data-ideal-width]").attr("data-ideal-width"),
      )

    if ($markup.find("[data-ideal-height]").length > 0)
      logoDataTag.idealSize.height = parseInt(
        $markup.find("[data-ideal-height]").attr("data-ideal-height"),
      )

    logoDataTag.size = { width: 0, height: 0 }
    if ($markup.find("[width]").length > 0)
      logoDataTag.size.width = parseInt($markup.find("img[width]")[0].width)

    if ($markup.find("[height]").length > 0)
      logoDataTag.size.height = parseInt($markup.find("img[height]")[0].height)

    logoDataTag.maxSize = { width: MAX_LOGO_SIZE, height: MAX_LOGO_SIZE }
    if ($markup.find("[data-max-width]").length > 0)
      logoDataTag.maxSize.width = parseInt(
        $markup.find("[data-max-width]").attr("data-max-width"),
      )
    if ($markup.find("[data-max-height]").length > 0)
      logoDataTag.maxSize.height = parseInt(
        $markup.find("[data-max-height]").attr("data-max-height"),
      )

    return logoDataTag
  }

  /**
   * Retire les tags des propriétés qui ont été désactivées via l'interface
   * @param invisibleProps propriétés utilisateurs invisibles
   */
  public RemoveInvisibleProperties(invisiblePropsInternalName: string[]) {
    let newRawContent = this.GetRawContent()
    invisiblePropsInternalName.forEach((prop) => {
      newRawContent = replace(
        newRawContent,
        new RegExp("\\?#" + prop + "#\\?", "g"),
        "",
      )
    })
    this.SetRawContent(newRawContent)
  }

  UpdateDataTags(currentDataTags) {
    map(this.dataTags, (dataTag) => {
      if (currentDataTags[dataTag.name].url != null) {
        dataTag.url = currentDataTags[dataTag.name].url

        // ajout du https sur le lien s'il n'a pas été explicitement spécifié
        if (
          dataTag.url !== "" &&
          !dataTag.url.startsWith("http://") &&
          !dataTag.url.startsWith("https://")
        ) {
          dataTag.url = "https://" + dataTag.url
        }

        this.SetDataTagUrl(dataTag)

        // Si le datatag contient un SVG, on le colorise
        this.UpdateSvgColor(dataTag)

        // cas spécial du logo, qui est choisi par l'utilisateur, qu'on conserve d'un template à l'autre
        if (dataTag.name === LOGO_TAG) {
          const logoTag: LogoTag = dataTag

          if (logoTag.visible === false) return

          // Récupération de l'ancienne Url du logo pour le conserver d'un template à l'autre
          const oldChildren = dataTag.children
          const currentImgUrl = StringHelper.GetImageSrc(
            currentDataTags[logoTag.name].children,
          )
          const newImgUrl = StringHelper.GetImageSrc(logoTag.children)

          logoTag.children = logoTag.children.replace(newImgUrl, currentImgUrl)
          logoTag.markup = logoTag.markup.replace(oldChildren, logoTag.children)
          // récupération de la taille du logo
          // par défaut, si on a pas de taille définie, on prend la taille recommandée, sinon on initialise à 0
          logoTag.size = currentDataTags.logo.size

          if (logoTag.size != null) {
            this.SetLogoAttr("width", logoTag.size.width.toString())
            this.SetLogoAttr("max-width", logoTag.size.width.toString())
            this.SetLogoAttr(
              "height",
              Number(logoTag.size.height).toFixed(0).toString(),
            )
          }

          dataTag = logoTag
        }
      } else {
        dataTag.url = ""
      }
    })
  }

  public ResetDataTagsVisible() {
    map(this.dataTags, (dataTag) => {
      dataTag.visible = false
    })
  }

  /**
   * Défini dans la propriété `compiledContent` le contenu brut avec les tags remplacés
   * @param signature signature de laquelle on extrait le template
   * @param userProps objet 'properties' contenu dans les Users
   */
  public CompileUserTags(userProps: {}) {
    const regex = /\?#([\w\d]*)#\?/ // Extrait les groupes de mots compris entre un marqeur '?#' et un marqueur '#?'
    // const regex = /\?#[\w\d]*#\?/g;
    if (userProps == null) return this

    const template = StringHelper.ReplaceTags(
      this.compiledContent,
      regex,
      (occurence: string) => {
        const prop = occurence.match(new RegExp(regex))

        if (prop.length > 0 && userProps.hasOwnProperty(prop[1])) {
          if (userProps[prop[1]] != null) return userProps[prop[1]]
          else return prop[1] // si la propriété de l'AD est null, donc non-renseignée, on retourne le nom de la propriété
        }
        return occurence
      },
    )
    this.compiledContent = template
    this.sampleUserProps = userProps
    return this
  }

  /**
   * Retourne la liste des valeurs entre séparateurs
   */
  public GetUserProperties() {
    const regex = /\?#([\w\d]*)#\?/g
    const allProperties = StringHelper.FindTags(this.GetRawContent(), regex)
    // On ne ramène pas les propriétés système ni la bannière, ni les dataTags
    const dataTagNames = map(this.dataTags, (dataTag) => dataTag.name)

    return uniq(
      allProperties.filter(
        (prop) =>
          this.systemTags.indexOf("?#" + prop + "#?") === -1 &&
          dataTagNames.indexOf(prop) === -1,
      ),
    )
  }

  /**
   * Retire les propriétés de l'utilisateur `properties` qui sont marquées comme invisibles
   */
  public CompileInvisibleProperties() {
    const regex = /\?#([\w\d]*)#\?/

    const template = StringHelper.ReplaceTags(
      this.compiledContent,
      regex,
      (occurence: string) => {
        const prop = occurence.match(new RegExp(regex))
        if (prop.length > 0) {
          if (!this.userProperties.find((property) => property === prop[1]))
            return ""
          else return prop[0] // on retourne le nom du tag
        }
        return occurence
      },
    )
    this.compiledContent = template
    return this
  }

  /**
   * Remplace les marqueurs des `?#<dataTags.name>#?` par les valeurs des objets DataTags
   * Si pas d'url de définie, on n'affiche pas le dataTag, sauf pour le logo
   */
  public CompileDataTags() {
    map(this.dataTags, (dataTag) => {
      const tagName = this.getTagName(dataTag.name)

      // cas spécial, pour le logo, on l'affiche même s'il n'y a pas de lien dessus
      const isLogoTag = dataTag.name === this.dataTags.logo.name

      if (!isLogoTag && (dataTag.url == null || dataTag.url === "")) {
        // cas spécial - si un parent comporte l'attr hide-if-empty, on le retire du markup
        //  const $template = $(this.compiledContent);
        //  const dataTagParent = $template.find(":contains('" + tagName + "'):last");
        //  if (dataTagParent.length > 0 && dataTagParent.attr(HIDE_IF_DATATAG_EMPTY) != null) {
        //    $template.find(":contains('" + tagName + "'):last").remove();
        //    this.compiledContent = $template[0].outerHTML;
        //  } else {
        //    // sinon, on retirer uniquement le tag enfant
        //  }

        this.compiledContent = this.compiledContent.replace(tagName, "")
        return this
      }
      this.compiledContent = this.compiledContent.replace(
        tagName,
        dataTag.markup,
      )
    })
    return this
  }

  /**
   * Remplace le tag bannière par la campagne courante
   */
  CompileBannerTag() {
    const regex = /\?#(banner)#\?/

    const template = StringHelper.ReplaceTags(
      this.compiledContent,
      regex,
      (occurence: string) => {
        const prop = occurence.match(new RegExp(regex))
        if (prop.length > 0) {
          if (this.showBanner) {
            return this.bannerMarkup
          } else {
            return ""
          }
        }
        return occurence
      },
    )
    this.compiledContent = template
    return this
  }

  /**
   * Application de la couleur
   */
  CompileColorTags(color: string) {
    const regex = /\?#(color)#\?/ // Extrait les tags de bannière présents par l'url fournie en paramètre

    const template = StringHelper.ReplaceTags(
      this.compiledContent,
      regex,
      (occurence: string) => {
        const prop = occurence.match(new RegExp(regex))
        if (prop.length > 0) {
          return color
        }
        return occurence
      },
    )
    this.compiledContent = template
    return this
  }

  async UpdateSvgColor(dataTag: DataTag) {
    const $dataTag = $(dataTag.markup)
    // remplacement de la couleur
    if ($dataTag.find("svg").length === 0) return
    $dataTag.find("path:first").css("fill", "#" + this.currentColor)

    // cas spécial, le svg google contient un "circle" et pas un "path"
    if (dataTag.name === "google")
      $dataTag.find("circle:first").css("fill", "#" + this.currentColor)
    dataTag.children = $dataTag.html()
    dataTag.markup = $dataTag[0].outerHTML
    // Màj du datatag
    this.dataTags[dataTag.name] = dataTag
  }

  /**
   * Retire les éléments marqués comme dépendants d'un autre bloc, si ce dernier est invisible
   */
  CompileSeparators() {
    const $compiledContent = $(this.compiledContent)
    const $separators = $compiledContent.find("[data-depend-on]")
    // jQuery ne fournit pas de méthode 'map' sur ses $elements, on est obligé de passé par .map
    // eslint-disable-next-line array-callback-return
    $separators.map((idx, separator) => {
      // 1. récupération de l'Id du parent
      const parentIds = $(separator).attr("data-depend-on").split(",")
      parentIds.forEach((parentId) => {
        if (parentId != null && parentId !== "") {
          const $parent = $compiledContent.find(`#${parentId}`)
          if ($parent.length > 0) {
            // 2. Check du contenu
            if ($parent.html().trim() === "") $(separator).hide()
          }
        }
      })
    })

    this.compiledContent = $compiledContent[0].outerHTML
  }

  /**
   * Retire les éléments si leur contenu est vide
   */
  CompileHideIfEmpty() {
    const checkedEmptyTags = ["img", "a"]
    const $compiledContent = $(this.compiledContent)
    const $hideIfEmptys = $compiledContent.find(`[${HIDE_IF_DATATAG_EMPTY}]`)

    $hideIfEmptys.each((idx, parent) => {
      let $parent = $(parent)
      // 1. cas d'un attribut de balise
      if (
        checkedEmptyTags.indexOf($parent.prop("tagName").toLowerCase()) !== -1
      ) {
        // traitement sur le tag directement
        $parent = Template.HideIfTagAttrsAreEmpties($parent)
      } else {
        // 2. cas d'un parent de balise
        $parent = Template.HideIfChildrenIsEmpty($parent)
      }
    })
    this.compiledContent = $compiledContent[0].outerHTML
  }

  public GetLogoUrl() {
    if (this.dataTags.logo.markup == null) return null
    return StringHelper.GetImageSrc(this.dataTags.logo.markup)
  }

  // Remplace l'url de l'image contenue dans le markup du dataTag `Logo` ainsi que  par la nouvelle url
  // Remplace l'url de l'image par la nouvelle url
  public SetLogoUrl(logoUrl: string) {
    if (this.dataTags.logo.markup == null) return

    /**
     * REGEXP - les parenthèses permettent d'isoler des groupes, qui sont accessible via les token $<index du groupe> dans le second args de string.replace. On découpe donc la regex en 3 groups : avant - le src - après, et on remplace juste la src par la nouvelle valeur.
     */
    const regex = /(<img .*src=["'])(.*?)(["'].*>)/

    const markup = this.dataTags.logo.markup.replace(
      regex,
      "$1" + logoUrl + "$3",
    )

    this.dataTags.logo.markup = markup

    const match = markup.match(regex)
    if (match != null && match.length > 0) {
      this.dataTags.logo.children = match[0]
    }
  }

  // Remplace l'url de l'image contenue dans le markup du dataTag `Logo` par la nouvelle url
  public SetDataTagSrc(dataTagName: string, imgSrc: string) {
    const dataTag = this.dataTags[dataTagName]
    if (dataTag.markup == null) return

    const $markup = $(this.dataTags[dataTagName].markup)
    // récupération des attributs stockés lors du remplacement de l'image par le SVG
    const dataImgStyle = $markup.find("svg").attr("data-style")
    const dataImgHeight = $markup.find("svg").attr("height")
    const dataImgWidth = $markup.find("svg").attr("width")

    const $children = $(
      `<img alt=${dataTagName} style='${dataImgStyle}' src='${imgSrc}'>`,
    )

    if (dataImgHeight != null) $children.attr("height", dataImgHeight)
    if (dataImgWidth != null) $children.attr("width", dataImgWidth)

    $markup.find("svg").replaceWith($children)
    this.dataTags[dataTagName].children = $children[0].outerHTML
    this.dataTags[dataTagName].markup = $markup[0].outerHTML
  }

  // Remplace l'attribut spécifié de l'image contenue dans le markup du dataTag `Logo`
  // par la nouvelle valeur
  // créer l'attribut s'il n'existe pas
  public SetLogoAttr(attrName: string, attrValue: string) {
    const $dataTag = $(this.dataTags["logo"].markup)
    const $logo = $dataTag.find("img")
    $logo.attr(attrName, attrValue)
    this.dataTags["logo"].markup = $dataTag[0].outerHTML

    // cas spécial: il faut toujours spécifier le width dans le TD parent pour la preview Outlook
    const $compiledContent = $(this.compiledContent)
    const $parentTd = $compiledContent.find("td:contains('?#logo#?')")
    $parentTd.attr(attrName, attrValue)

    // jQuery respect les standards HTML, et remplace donc les attributs avec majuscule par des attributs lowercase, puis ajoute un '=""' derrière.
    // Dans le cadre de notre moteur de template, on doit donc remplacer ces corrections automatique par nos attr custom (?#templateId#? et ?#theme#?)
    // pour qu'ils soient correctement parsés par la suite
    this.compiledContent = $compiledContent[0].outerHTML
  }

  public SetDataTagUrl(updatedTag: DataTag) {
    if (updatedTag.markup == null) return

    if (updatedTag?.url && updatedTag?.url.length > 0)
      updatedTag.url = updatedTag.url.trim()

    // 1- Update the markup with the url
    const regex = /(<a .*href=["'])(.*?)(["'].*>)/
    updatedTag.markup = updatedTag.markup.replace(
      regex,
      "$1" + updatedTag.url + "$3",
    )

    this.dataTags = merge(this.dataTags, { [updatedTag.name]: updatedTag })
  }

  // Custom Getter
  GetTemplateWithoutBanner() {
    return this.compiledContent.replace(BANNER_TAG, "")
  }

  // Vérifie qu'un attribut existe sur la balise, et s'il est vide, masque cette élément du DOM
  static HideIfTagAttrsAreEmpties($parent) {
    const checkedEmptyAttrs = ["src", "href"]
    checkedEmptyAttrs.forEach((attr) => {
      if ($parent.attr(attr) != null && $parent.attr(attr) === "") {
        $parent.hide()
        return $parent
      }
    })
    return $parent
  }

  // Vérifie qu'un élément enfant existe sur la balise, et s'il est vide, masque le parent du DOM
  static HideIfChildrenIsEmpty($parent) {
    // 1. récupération de l'enfant
    const children = $parent.children()
    // cas d'un tag vide
    if (children.length === 0) {
      $parent.hide()
      return $parent
    }
  }
}

export default Template
