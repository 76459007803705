import { ButtonHTMLAttributes, FunctionComponent, useState } from "react"

import MouseTooltip from "components/MouseTooltip"

import { defineMessages, useIntl } from "react-intl"

import useToggleSubsidiary from "../hooks/useToggleSubsidiary"

import { ReactComponent as SubsidiaryDuotoneIcon } from "assets/duotone/icon-subsidiary-duotone-type2.svg"
import { ReactComponent as SubsidiaryIcon } from "assets/icon-subsidiary.svg"
import { ReactComponent as ArrowRightIcon } from "assets/icon-arrow-right.svg"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"

interface ToggleSubsidiaryButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  adminAccount: DataGridAdminAccount
}

const messages = defineMessages({
  tooltip: {
    id: "AdminsGrid.ToggleSubsidiaryTooltip",
    defaultMessage: "Basculer cet admin à une filiale",
  },
})

const ToggleSubsidiaryButton: FunctionComponent<
  ToggleSubsidiaryButtonProps
> = ({ adminAccount }) => {
  const { formatMessage } = useIntl()
  const [isHovering, setIsHovering] = useState(false)
  const { handleDisplayModal } = useToggleSubsidiary(adminAccount)

  return (
    <>
      <button
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className="toggle-subsidiary-btn"
        onClick={() => handleDisplayModal()}
      >
        {isHovering ? (
          <SubsidiaryIcon className="toggle-subsidiary-btn__icon" />
        ) : (
          <SubsidiaryDuotoneIcon className="toggle-subsidiary-btn__icon" />
        )}
        <ArrowRightIcon className="toggle-subsidiary-btn__icon" />
      </button>
      <MouseTooltip visible={isHovering}>
        {formatMessage(messages.tooltip)}
      </MouseTooltip>
    </>
  )
}

export default ToggleSubsidiaryButton
