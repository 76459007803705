import {
  FunctionComponent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react"

import classNames from "classnames"

interface ToggleProps {
  name: string
  onChange?: (toggle: boolean) => void
  defaultChecked?: boolean
  disabled?: boolean
  className?: string
}

type ToggleType = ToggleProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">

const Toggle: FunctionComponent<ToggleType> = ({
  name,
  onChange,
  disabled,
  className,
  defaultChecked = false,
  ...others
}) => {
  const [toggle, setToggle] = useState<boolean>(false)

  useEffect(() => {
    if (defaultChecked) {
      setToggle(defaultChecked)
    }
  }, [defaultChecked])

  const triggerToggle = useCallback(() => {
    if (disabled) return

    setToggle(!toggle)

    if (onChange) onChange(!toggle)
  }, [disabled, onChange, toggle])
  return (
    <div
      onClick={triggerToggle}
      className={classNames(
        "toggle-button",
        className,
        disabled && "is-disabled",
      )}
    >
      <div
        className={classNames(
          "toggle-button__background",
          toggle && "is-checked",
        )}
      />
      <div
        className={classNames("toggle-button__circle", toggle && "is-checked")}
      />
      <input
        type="checkbox"
        name={name}
        className="toggle-button__input"
        {...others}
      />
    </div>
  )
}

export default Toggle
