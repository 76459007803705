import { put, call, takeLatest, select } from "redux-saga/effects"

import { TypedResult } from "core/CoreModels"

import { addNotification } from "features/Notifications/NotificationReducer"
import { Notification } from "features/Notifications/NotificationModels"

import {
  checkDeployPropertiesApi,
  fetchTenantFeatures as fetchTenantFeaturesApi,
  updateTenantFeature as updateTenantFeatureApi,
} from "./FeaturesApi"
import {
  fetchTenantFeaturesSuccess,
  fetchTenantFeaturesFailure,
  fetchTenantFeatures,
  updateTenantFeatureSuccess,
  addBackgroundTask,
  removeBackgroundTask,
  updateTenantFeatureProperties as updateTenantFeatureSettings,
  updateTenantFeatureFailure,
} from "./FeaturesReducers"
import { getFeatureByName } from "./FeaturesSelectors"
import { updateTenantFeatureAction } from "./FeaturesActions"

import { createJobInstruction, deleteJobInstruction } from "sagas/jobs.sagas"
import { createTenantFeatureProperties } from "features/Accounts/AccountsSagas"
import Feature, { FeatureResponse, FeatureType } from "./FeaturesModels"
import { createFeatures } from "./FeatureFactory"
import { cloneDeep } from "lodash"
import cuid from "cuid"
import { fetchProperties } from "features/Users/UsersReducers"

import { updateTenantFeatureSuccess as updateTenantFeatureSuccessForAccounts } from "features/Accounts/AccountsReducer"
import { createTenantFeaturePropertiesAction } from "features/Accounts/AccountsActions"
import {
  createJobInstructionAction,
  deleteJobInstructionAction,
} from "features/Jobs/JobsActions"
import { TENANT_PROPERTY_NAMES } from "entities/TenantProperties"

/**
 * Récupère les propriétés du tenant
 */
function* fetchTenantFeaturesAsync() {
  try {
    // 1. REST Call Request
    const result: TypedResult<FeatureResponse[]> = yield call(
      fetchTenantFeaturesApi,
    )

    if (!result.success) throw new Error(result.errorMessage)

    // 2. Mapping de la data vers une collection de DTO
    const featuresJson = result.result

    // const features = FeatureFactory.toDomain(featuresJson)
    const features = createFeatures(featuresJson)

    // 3. Appel du reducer pour mise en State
    yield put(fetchTenantFeaturesSuccess(features))
  } catch (error) {
    // Gestion de l'erreur
    yield put(fetchTenantFeaturesFailure())
  }
}

function* resetTenantFeatureProperties(feature: Feature) {
  const resetProperties = feature.properties.map((prop) => ({
    ...prop,
    value: null,
  }))

  yield put(
    updateTenantFeatureSettings({
      name: feature.name,
      properties: resetProperties,
    }),
  )
}
/**
 * Modification d'une Feature et création/suppression des jobs en relation
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateTenantFeatureAsync(action) {
  const id = cuid()

  const feature: Feature = action.payload

  if (!feature.onCheck) yield put(addBackgroundTask({ id }))

  if (feature.onCheck && feature.activated) {
    const result = yield call(feature.onCheck, feature)

    if (result === null) {
      feature.activated = false

      yield put(updateTenantFeatureFailure(feature))
      yield put(removeBackgroundTask({ id }))
      return
    }
  }

  // optimistic UI
  yield put(updateTenantFeatureSuccess(feature))

  yield put(
    updateTenantFeatureSuccessForAccounts({
      featureName: feature.name,
      activated: feature.activated,
    }),
  )

  // suppression des jobs associés à cette fonctionnalité
  if (feature.activated === false) {
    if (feature.jobName != null)
      yield call(
        deleteJobInstruction,
        deleteJobInstructionAction(feature.jobName, true),
      )
    yield call(resetTenantFeatureProperties, feature)
    //TODO - supprimer une fois que la tâche de désactivation est migrée en back
    yield call(updateFeature, feature)
  } else {
    yield call(disableExclusiveFeatures, feature.exclusiveFeatures)

    // 1. Creation du recurring job si besoin
    if (feature.createRecurringJob && feature.jobName != null) {
      yield call(
        createJobInstruction,
        createJobInstructionAction(feature.jobName, feature.createRecurringJob),
      )
    }

    // création des tenantProperties associées
    if (feature.updatePropertiesOrigin)
      yield call(
        createTenantFeatureProperties,
        createTenantFeaturePropertiesAction(feature),
      )

    // gestion des actions spécifiques définies lors du fetch des features
    // Pour les redirections - les calls suivant ce dernier ne sont pas appelés
    if (feature.onActivate) yield call(feature.onActivate)

    // lancement du job
    if (feature.launchAtCreation === true)
      yield call(
        createJobInstruction,
        createJobInstructionAction(feature.jobName, false),
      )

    if (feature.updateFeature) yield call(updateFeature, feature)
  }

  yield put(fetchProperties())
  yield put(removeBackgroundTask({ id }))
}

function* updateFeature(feature: Feature) {
  const result = yield call(updateTenantFeatureApi, {
    name: feature.name,
    activated: feature.activated,
  })
  if (!result.success) throw new Error(result.errorMessage)
  return result
}

function* disableExclusiveFeatures(exclusiveFeatures: FeatureType[]) {
  for (const exclusiveFeature of exclusiveFeatures) {
    const exclusiveFeat = yield select(getFeatureByName(exclusiveFeature))

    if (exclusiveFeat == null || exclusiveFeat.activated === false) continue

    // si le job n'existe pas, le back renvoit un 500, on le catch pour terminer l'activation
    try {
      yield call(
        deleteJobInstruction,
        deleteJobInstructionAction(exclusiveFeat.jobName, true),
      )
    } catch {
      // eslint-disable-next-line no-console
      console.error(
        `Erreur lors de la désactivation du job: ${exclusiveFeat.jobName}`,
      )
    }

    const disabledFeature: Feature = cloneDeep(exclusiveFeat)
    disabledFeature.activated = false
    const result = yield call(updateFeature, disabledFeature)
    if (result.success) yield put(updateTenantFeatureSuccess(disabledFeature))
  }
}

function* notifyOWAParametersError() {
  yield put(
    addNotification(
      new Notification(
        "Connection impossible ! Les paramètres sont incorrects",
        "ERROR",
      ),
    ),
  )
}

export function* checkOWAParameters(feature?: Feature) {
  if (!feature) {
    yield call(notifyOWAParametersError)
    return null
  }

  const owaAccounts = feature.properties.find(
    (p) => p.property === TENANT_PROPERTY_NAMES.OWA_ACCOUNTS,
  )

  const exchangeUrlProperty = feature.properties.find(
    (p) => p.property === TENANT_PROPERTY_NAMES.OWA_ENDPOINT_URL,
  )

  if (
    !owaAccounts ||
    owaAccounts.value === null ||
    !exchangeUrlProperty ||
    exchangeUrlProperty.value === null
  ) {
    yield call(notifyOWAParametersError)
    return null
  }

  const parsedOwaAccounts = JSON.parse(owaAccounts.value)

  if (parsedOwaAccounts.length === 0) {
    yield call(notifyOWAParametersError)
    return null
  }

  const data = {
    exchangeUrl: exchangeUrlProperty.value,
    login: parsedOwaAccounts[0].login,
    password: parsedOwaAccounts[0].password,
    domain: parsedOwaAccounts[0].domain,
  }

  const result = yield call(checkDeployPropertiesApi, data)

  if (!result.success) {
    yield call(notifyOWAParametersError)
    return null
  }
}

export const featuresWatchers = [
  takeLatest(fetchTenantFeatures.type, fetchTenantFeaturesAsync),
  takeLatest(updateTenantFeatureAction.type, updateTenantFeatureAsync),
]
