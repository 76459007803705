import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import HoverTooltip from "components/HoverTooltip"

interface DeleteButtonWithTipProps {
  message: ReactNode
  onClick: () => void
  className?: string
}

const DeleteButtonWithTip: FunctionComponent<DeleteButtonWithTipProps> = ({
  message,
  onClick,
  className,
}) => {
  return (
    <HoverTooltip level="warning" tooltip={message} align="right">
      <button
        type="button"
        className={classNames("datagrid__delete-button--with-tip", className)}
        onClick={onClick}
      >
        <DeleteOutlinedIcon />
      </button>
    </HoverTooltip>
  )
}

export default DeleteButtonWithTip
