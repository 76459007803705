import { TypedResult } from "core/CoreModels"

import ServiceApi from "core/services/serviceApi"
import PortalApi from "core/services/portalApi"

import {
  BannersClickedDto,
  SignaturesClickedDto,
  SignaturesHistoryDto,
  BannersHistoryDto,
} from "./TrackingModels"

import { RequestParams } from "core/services/requestBuilderService"

export function fetchBannerClicksByDate(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "smartlinkusages", {
    params: {
      $groupBy: "ClickDate",
      $filter: 'resourceType eq "Image"',
    },
  })
}

export async function fetchSignaturesDeliveredApi(params: RequestParams) {
  return PortalApi.request("GET", "/data/query/signaturesDelivered", { params })
}

export async function fetchBannersClickedApi(
  params: RequestParams,
): Promise<TypedResult<BannersClickedDto[]>> {
  return PortalApi.request("GET", "/data/banners/clicked", { params })
}

export async function fetchBannersHistoryApi(): Promise<
  TypedResult<BannersHistoryDto>
> {
  return PortalApi.request("GET", "/data/banners/history")
}

export async function fetchSignaturesClickedApi(
  params: RequestParams,
): Promise<TypedResult<SignaturesClickedDto[]>> {
  return PortalApi.request("GET", "/data/signatures/clicked", { params })
}

export async function fetchSignaturesHistoryApi(
  params: RequestParams,
): Promise<TypedResult<SignaturesHistoryDto>> {
  return PortalApi.request("GET", "/data/signatures/history", { params })
}

export async function createTrackingJobApi(): Promise<TypedResult<void>> {
  return PortalApi.request("POST", "/data/jobs/create")
}

export async function fetchSingleBannerHistoryApi(
  scenarioId: number,
  startDate: string,
  endDate?: string,
) {
  const params = {
    from: startDate,
    to: endDate,
  }

  return PortalApi.request("GET", `/data/banners/${scenarioId}/history`, {
    params,
  })
}

export async function fetchSingleBannerClickedApi(
  scenarioId: number,
  startDate: string,
  endDate?: string,
): Promise<TypedResult<BannersClickedDto[]>> {
  const params = {
    from: startDate,
    to: endDate,
  }

  return PortalApi.request("GET", `/data/banners/${scenarioId}/clicked`, {
    params,
  })
}
