import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"
import Feature, { TenantProperty } from "./FeaturesModels"

import { LoadingStatus } from "core/CoreModels"
import { GlobalStates } from "store"

interface FeaturesEntities {
  [id: string]: Feature
}

export interface FeatureState {
  domains: string[]
  backgroundTasks: string[]
  allFeatures: {
    entities: FeaturesEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
}

const featuresAdapter = createEntityAdapter<Feature>({
  selectId: (feat) => feat.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const {
  selectById: selectFeatureByName,
  selectAll: selectAllFeatures,
  selectIds: selectAllFeaturesNames,
} = featuresAdapter.getSelectors<GlobalStates>(
  (state) => state.features.allFeatures,
)

const initialState = {
  domains: [],
  backgroundTasks: [],
  allFeatures: featuresAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
}

const featuresReducer = createSlice({
  name: "feature",
  initialState,
  reducers: {
    fetchTenantFeatures(state) {
      state.allFeatures.loadingStatus = LoadingStatus.PENDING
    },
    fetchTenantFeaturesSuccess(state, action: PayloadAction<Feature[]>) {
      featuresAdapter.setAll(state.allFeatures, action.payload)
      state.allFeatures.loadingStatus = LoadingStatus.LOADED
    },
    fetchTenantFeaturesFailure(state) {
      state.allFeatures.loadingStatus = LoadingStatus.ERROR
    },
    updateTenantFeatureSuccess(state, action) {
      const updatedFeature = action.payload
      featuresAdapter.upsertOne(state.allFeatures, updatedFeature)
    },
    updateTenantFeatureFailure(state, action) {
      const updatedFeature = action.payload
      featuresAdapter.upsertOne(state.allFeatures, updatedFeature)
    },
    updateTenantFeatureProperties(
      state,
      action: PayloadAction<{ name: string; properties: TenantProperty[] }>,
    ) {
      const { name, properties } = action.payload
      featuresAdapter.updateOne(state.allFeatures, {
        id: name,
        changes: { properties },
      })
    },
    addBackgroundTask(state, action) {
      state.backgroundTasks.push(action.payload)
    },
    removeBackgroundTask(state, action) {
      state.backgroundTasks = state.backgroundTasks.filter(
        (t) => t.id !== action.payload.id,
      )
    },
  },
})

export const {
  fetchTenantFeatures,
  fetchTenantFeaturesSuccess,
  fetchTenantFeaturesFailure,
  updateTenantFeatureSuccess,
  updateTenantFeatureFailure,
  updateTenantFeatureProperties,
  addBackgroundTask,
  removeBackgroundTask,
} = featuresReducer.actions
export default featuresReducer.reducer
