import axios, { AxiosResponse } from "axios"
import { Template } from "entities/Template"

/**
 * Récupère les modèles depuis la gallerie de modèles
 * @memberof TemplateApi
 */
class TemplateApi {
  /**
   * Récupère la gallerie de modèles de signatures
   *
   * @returns
   * @memberof TemplateApi
   */
  public fetchSvg(svgUrl: string) {
    return axios.get(svgUrl, { responseType: "text" })
  }

  /**
   * Remplace les éventuels liens SVG contenus dans un modèle par leur markup SVG complet
   * permet de changer la couleur à la volée
   * @returns
   * @memberof TemplateApi
   */
  public replaceSvgLinkBySvg(template: Template) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (typeof error.response === "undefined") {
          // tslint:disable-next-line
          console.log(
            "CORS - Le cdn source n'autorise pas le portail à récupérer les logos SVG - Template ID: " +
              template.Id,
          )
        }
      },
    )

    const getSvgPromises: Array<Promise<AxiosResponse | null>> = []
    const svgUrls = template.GetSvgUrls()

    if (svgUrls.length === 0) return
    // test sur le 1er SVG pour savoir si on a les droits sur le CDN, et ne pas spammer les CORS
    axios.get(svgUrls[0], { responseType: "text" }).then((response) => {
      if (response === null)
        throw new Error(
          "CORS - Le cdn source n'autorise pas le portail à récupérer les logos SVG - Template ID: " +
            template.Id,
        )

      for (const svgUrl in svgUrls) {
        getSvgPromises.push(
          axios
            .get(svgUrls[svgUrl], { responseType: "text" })
            .catch((error) => {
              return null
            }),
        )
      }

      return Promise.all(getSvgPromises).then(
        (svgs) => {
          for (const i in svgs) {
            if (svgs[i] == null) continue
            template.ReplaceSvgLinksByMarkup(svgUrls[i], svgs[i].data)
          }
        },
        (err) => {},
      )
    })
  }
}

export default new TemplateApi()
