import { createIntl } from "react-intl"

import moment from "moment"
import { Moment } from "moment"

import StringHelper from "utils/StringHelper"

import { userPropertiesTranslationsForBuilder } from "./BuilderSignaturesTranslations"

import store from "store/configureStore"

import { appSelectors } from "features/App"

import { LoadingStatus } from "core/CoreModels"
import { UserProperty } from "features/Users/UserModels"

export interface BuilderSignatureDto {
  id: number
  name: string
  json: string
  activated: boolean
  isNewBuilder: true
  activatedDate?: string
  affectedUsers?: number[]
  affectedGroups?: number[]
  affectedUsersCount?: number[]
  created: string
  modified: string
  newMail: boolean
  inResponse: boolean
  weighting: number
}

export function resolveMissingPropertiesError(
  jsonTemplate: string,
  userProperties: UserProperty[],
): string {
  const parsedTemplate = JSON.parse(jsonTemplate)

  if (
    parsedTemplate?.configuration?.properties &&
    Object.keys(parsedTemplate?.configuration?.properties).length > 0
  )
    return null

  const state = store.getState()
  const locale = appSelectors.getSelectedLang(state)
  const translations = appSelectors.getTranslations(state)
  const messages = translations?.[locale.toUpperCase()]?.translations || {}
  const intl = createIntl({ locale, messages })

  const relatedUserProperties = userProperties.map((userProperty) => {
    let propertyLabel = StringHelper.Capitalize(userProperty.InternalName)
    if (userProperty.InternalName in userPropertiesTranslationsForBuilder)
      propertyLabel = intl.formatMessage(
        userPropertiesTranslationsForBuilder[userProperty.InternalName],
      )
    return {
      id: userProperty.Id,
      label: `#${propertyLabel}`,
      value: `?#${userProperty.InternalName}#?`,
      property: userProperty.InternalName,
    }
  })

  const propertiesFromBoxes = Object.values(parsedTemplate.data)
    .map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (boxData: any) => {
        if (boxData?.data?.text !== undefined) {
          const relatedUserProperty = relatedUserProperties.find(
            (rup) => rup.value === boxData?.data?.text,
          )

          if (!relatedUserProperty) return
          return {
            property: relatedUserProperty.property,
            value: relatedUserProperty.value,
            label: relatedUserProperty.label,
            id: boxData.id,
          }
        }
      },
    )
    .filter((prop) => prop !== undefined)

  if (propertiesFromBoxes.length === 0) return null

  const properties = {}

  propertiesFromBoxes.forEach((prop) => {
    properties[prop.property] = prop
  })

  return JSON.stringify({
    ...parsedTemplate,
    configuration: { ...parsedTemplate.configuration, properties },
  })
}

export function updateJsonTemplateName(
  jsonTemplate: string,
  updatedName: string,
) {
  const parsedTemplate = JSON.parse(jsonTemplate)

  if (!parsedTemplate?.document?.name) return JSON.stringify(parsedTemplate)

  parsedTemplate.document = {
    ...parsedTemplate.document,
    name: updatedName,
  }

  return JSON.stringify(parsedTemplate)
}

export class BuilderSignature {
  id: number
  name: string
  json: string
  affectedUsers?: number[]
  affectedGroups?: number[]
  activated: boolean
  activatedDate?: Moment
  affectedUsersCount?: number[]
  created: Moment
  modified: Moment
  affectUsersCountLoadingStatus: LoadingStatus
  newMail: boolean
  inResponse: boolean
  weighting: number
  isNewBuilder: boolean

  constructor(signature: BuilderSignatureDto) {
    this.id = signature.id
    this.name = signature.name
    this.json = signature.json
    this.activated = signature.activated
    this.activatedDate = moment(signature.activatedDate)
    this.affectedUsers = signature.affectedUsers
    this.affectedGroups = signature.affectedGroups
    this.affectedUsersCount = signature.affectedUsersCount || []
    this.created = moment(signature.created)
    this.modified = moment(signature.modified)
    this.affectUsersCountLoadingStatus = LoadingStatus.NOT_STARTED
    this.newMail = signature.newMail
    this.inResponse = signature.inResponse
    this.weighting = signature.weighting
    this.isNewBuilder = true
  }
}
