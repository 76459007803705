import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { LoadingStatus } from "core/CoreModels"

import { fetchBannersHistory } from "../TrackingReducers"

import { trackingSelectors } from ".."

const useBannersHistoryDatas = (): LoadingStatus => {
  const dispatch = useDispatch()

  const loadingStatus = useSelector(
    trackingSelectors.getBannersHistoryLoadingStatus,
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchBannersHistory())
    }
  }, [loadingStatus, dispatch])

  return loadingStatus
}

export default useBannersHistoryDatas
