import { FunctionComponent } from "react"

import { ReactComponent as LargeGraphIcon } from "features/Tracking/assets/large_loading_graph.svg"

interface SingleScenarioTrackingLoadingProps {
  title: string
  isMultiple: boolean
}

const KPI_BLOCKS = 3

const AXIS_BUBBLE_LOADING = 6

const SingleScenarioTrackingLoading: FunctionComponent<
  SingleScenarioTrackingLoadingProps
> = ({ title, isMultiple }) => {
  return (
    <div className="single-scen-track">
      <div className="single-scen-track__skeleton--header">
        <div className="single-scen-track__skeleton--header-title" />
        <div className="single-scen-track__skeleton--header-date-picker" />
        <div className="single-scen-track__skeleton--header-unselect" />
      </div>
      <div className="single-scen-track__skeleton--kpi-container">
        {[...Array(KPI_BLOCKS).keys()].map((b) => (
          <div className="single-scen-track__skeleton--kpi-block" key={b}>
            <div className="single-scen-track__skeleton--kpi-title" />
            <div className="single-scen-track__skeleton--kpi-value" />
            <div className="single-scen-track__skeleton--kpi-secondary" />
            <div className="single-scen-track__skeleton--kpi-time" />
          </div>
        ))}
      </div>
      <div className="single-scen-track__content">
        {isMultiple && <div className="single-scen-track__skeleton--legend" />}
        <div className="single-scen-track__skeleton--totals-container">
          <div className="single-scen-track__skeleton--clic-rate-container">
            <p className="single-scen-track__skeleton--clic-rate-title">
              {title}
            </p>
            <div className="single-scen-track__skeleton--graph-container">
              <div className="single-scen-track__skeleton--y-axis-container">
                {[...Array(AXIS_BUBBLE_LOADING).keys()].map((el) => (
                  <div
                    key={el}
                    className="single-scen-track__skeleton--graph-bubble"
                  />
                ))}
              </div>
              <div className="single-scen-track__skeleton--graph-block">
                <LargeGraphIcon className="single-scen-track__skeleton--graph" />
                <div className="single-scen-track__skeleton--x-axis-container">
                  {[...Array(AXIS_BUBBLE_LOADING).keys()].map((el) => (
                    <div
                      key={el}
                      className="single-scen-track__skeleton--graph-bubble"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleScenarioTrackingLoading
