import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import PillButtonsGroup from "components/PillButtonsGroup"

import useDiffusionMode from "features/Scenarios/hooks/useDiffusionMode"

const messages = defineMessages({
  internal: {
    id: "ScenariosList.Internal",
    defaultMessage: "Interne",
  },
  external: {
    id: "ScenariosList.External",
    defaultMessage: "Externe",
  },
})

const InternalExternalPills: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const { diffusionMode, setDiffusionMode } = useDiffusionMode()

  const handleCurrent = () => {
    const next = diffusionMode.internal
      ? { internal: false, external: true }
      : { internal: true, external: false }
    setDiffusionMode(next)
  }

  const options = [
    { id: "external", children: <>{formatMessage(messages.external)}</> },
    {
      id: "internal",
      children: <>{formatMessage(messages.internal)}</>,
    },
  ]

  const current = diffusionMode.internal ? "internal" : "external"

  return (
    <PillButtonsGroup
      current={current}
      onChange={handleCurrent}
      pillButtons={options}
      size="large"
    />
  )
}

export default InternalExternalPills
