import React, { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import useDebounce from "hooks/useDebounce"
import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import InputText from "components/TextBox/InputText"
import HoverTooltip from "components/HoverTooltip"

import StringHelper from "utils/StringHelper"

interface EditableEmailProps {
  initialValue: string
  id: string
  isNew: boolean
  isSuperAdmin: boolean
  onEditEmail: (email: string, id: string) => void
  onEditing: () => void
  onDisableSave: (value: boolean) => void
}

const checkInputValue = (value: string): string | null => {
  const isValidEmail = StringHelper.validateEmail(value)

  if (value === "" || isValidEmail) {
    return value
  }
  return null
}

const messages = defineMessages({
  superAdmin: {
    id: "AdminsGrid.SuperAdminInfo",
    defaultMessage: "Super Administrateur",
  },
  subsidiariesAdmin: {
    id: "AdminsGrid.SubsidiariesAdminInfo",
    defaultMessage: "Administrateur filiale",
  },
})

const EditableEmail: FunctionComponent<EditableEmailProps> = ({
  initialValue,
  id,
  isNew,
  isSuperAdmin,
  onEditEmail,
  onEditing,
  onDisableSave,
}) => {
  const [value, setValue] = React.useState(initialValue)
  const [isNotValidEmail, setIsNotValidEmail] = useState(false)
  const { hasSubsidiary } = useSubsidiariesData()

  const debouncedValue = useDebounce(value, 500)
  const intl = useIntl()

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (value === "") {
      onDisableSave(true)
    }
  }, [value])

  useEffect(() => {
    if (debouncedValue !== "") {
      if (checkInputValue(debouncedValue) === null) {
        setIsNotValidEmail(true)
        onDisableSave(true)
      } else {
        setIsNotValidEmail(false)
        onDisableSave(false)
      }
    } else {
      setIsNotValidEmail(false)
    }
  }, [debouncedValue])

  const onChange = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement
    setValue(value)
    onEditing()
  }

  const onBlur = () => {
    if (value !== initialValue) {
      onEditEmail(value, id)
    }
  }

  if (isNew) {
    return (
      <InputText
        value={value}
        className="editable-email"
        placeholder="Email"
        onChange={onChange}
        onBlur={onBlur}
        errorText={isNotValidEmail && "L'adresse email n'est pas valide"}
        error={isNotValidEmail}
      />
    )
  }

  if (isSuperAdmin) {
    return (
      <>
        <HoverTooltip
          key={id}
          level="info"
          tooltip={intl.formatMessage(messages.superAdmin)}
          align="left"
        >
          <div className="editable-email__super-admin--pin">S</div>
        </HoverTooltip>
        <div className="editable-email__super-admin--text">{value}</div>
      </>
    )
  }

  if (hasSubsidiary(id)) {
    return (
      <>
        <HoverTooltip
          key={id}
          level="info"
          tooltip={intl.formatMessage(messages.subsidiariesAdmin)}
          align="left"
        >
          <div className="editable-email__subsidiaries-admin--pin">F</div>
        </HoverTooltip>
        <div className="editable-email__subsidiaries-admin--text">{value}</div>
      </>
    )
  }

  return <div className="editable-email__email--text">{value}</div>
}

export default EditableEmail
