import { FunctionComponent, ReactNode, MouseEvent } from "react"

import { ReactComponent as IconClose } from "assets/icon-close.svg"

interface ModalProps {
  children: ReactNode
  title: string
  onCancel: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  title,
  onCancel,
}) => {
  return (
    <div className="modal-service__content">
      <div className="modal-service__header">
        <p className="modal-service__header--title">{title}</p>
        <button
          aria-label="close"
          className="modal-service__close"
          onClick={onCancel}
          type="button"
        >
          <IconClose />
        </button>
      </div>
      <div className="modal-service__body">{children}</div>
    </div>
  )
}

export default Modal
