import { useDispatch, useSelector } from "react-redux"

import moment, { Moment } from "moment"

import { trackingSelectors } from "features/Tracking"
import { setScenariosQueriesDateRange } from "features/Tracking/TrackingReducers"

const useScenariosQueriesDateRange = () => {
  const dispatch = useDispatch()

  const range = useSelector(trackingSelectors.getScenariosDateRange)

  const handleDateChange = (startDate: Moment, endDate: Moment) => {
    if (startDate !== null && endDate !== null)
      dispatch(
        setScenariosQueriesDateRange({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        }),
      )
  }

  return {
    startDate: moment(range.startDate),
    endDate: moment(range.endDate),
    handleDateChange,
  }
}

export default useScenariosQueriesDateRange
