import { useDispatch, useSelector } from "react-redux"
import { usersSelectors } from "features/Users"
import { offersSelectors } from "features/Offers"
import { resetReport as resetReportAction } from "features/Users/UsersReducers"
import { useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { ImportReport } from "features/Users/UserModels"
import { fetchActiveLicencesCountAction } from "features/Users/UsersActions"

interface UseUsersTab {
  openModalReport: boolean
  importReport: ImportReport
  handleReportClose: () => void
  getLicensedUsers: () => string
}

const messages = defineMessages({
  over: { id: "UsersTab.Over", defaultMessage: " sur " },
})

const useUsersTab = (): UseUsersTab => {
  const [openModalReport, setOpenModalReport] = useState<boolean>(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const importReport = useSelector(usersSelectors.getReport)
  const nbLicencedUsers = useSelector(usersSelectors.getActiveLicencesCount)
  const maxLicences = useSelector(offersSelectors.getMaxLicences)

  useEffect(() => {
    dispatch(fetchActiveLicencesCountAction())
  }, [dispatch])

  useEffect(() => {
    setOpenModalReport(!!importReport)
  }, [importReport])

  const handleReportClose = () => {
    setOpenModalReport(false)
    dispatch(resetReportAction())
  }

  const getLicensedUsers = () => {
    return (
      nbLicencedUsers +
      (maxLicences ? intl.formatMessage(messages.over) + maxLicences : "")
    )
  }

  return { openModalReport, importReport, handleReportClose, getLicensedUsers }
}

export default useUsersTab
