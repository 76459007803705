import React from "react"
import { TextField } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"

interface Props {
  onChange?: (value: string) => void
  onClick?: (value: string) => void
  placeholder?: string
  defaultValue?: string
  shrink?: boolean
}

interface State {
  value: string
}

class SearchBar extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue || "",
    }
  }

  changeValue = (newValue) => {
    this.setState({ value: newValue })
    if (this.props.onChange) this.props.onChange(newValue)
  }

  handleChange = (evt) => {
    this.changeValue(evt.target.value)
  }

  handleClear = () => {
    this.changeValue("")
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.state.value)
    }
  }

  handleKeyPress = (evt) => {
    if (evt.key == "Enter") {
      this.props.onClick(this.state.value)
    }
  }

  render() {
    const { placeholder } = this.props
    const { value } = this.state

    return (
      <div className="search-bar">
        <TextField
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.props.onClick && this.handleKeyPress}
          fullWidth={this.props.shrink ? false : true}
          autoFocus
          InputProps={{
            endAdornment: (
              <>
                {value && (
                  <div
                    className="search-bar__icon search-bar__icon--clickable"
                    onClick={this.handleClear}
                  >
                    <i className="material-icons search-bar__icon--clear">
                      clear
                    </i>
                  </div>
                )}
                <SearchIcon
                  classes={{
                    root: `search-bar__icon ${
                      this.props.onClick ? "search-bar__icon--clickable" : ""
                    }`,
                  }}
                  onClick={this.handleClick}
                />
              </>
            ),
          }}
        />
      </div>
    )
  }
}

export default SearchBar
