import { FunctionComponent } from "react"

import { Navigate } from "react-router-dom"

import { HOME_PORTAL_URL } from "router/RouterConstants"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { featureNames } from "config/config.features"

interface WithFeatureActiveProps {
  needActiveFeature?: featureNames
}

const WithFeatureActive: FunctionComponent<WithFeatureActiveProps> = ({
  children,
  needActiveFeature,
}) => {
  if (needActiveFeature === undefined) return <>{children}</>

  const activeFeature = useFeatureTogglr(needActiveFeature)

  if (activeFeature?.isActive) {
    return <>{children}</>
  }
  return <Navigate to={HOME_PORTAL_URL} />
}

export default WithFeatureActive
