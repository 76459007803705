import SubHeaderTabs from "components/SubHeaderTabs"
import { FunctionComponent } from "react"

const TrackingPageSubHeader: FunctionComponent = () => {
  return (
    <div className="tracking-page-header">
      <div />
      <div className="tracking-page-header__tabs">
        <SubHeaderTabs
          availableTabs={["signatures", "campaigns"]}
          defaultTab="signatures"
        >
          <SubHeaderTabs.Tab tabName="signatures">Signatures</SubHeaderTabs.Tab>
          <SubHeaderTabs.Tab tabName="campaigns">Campagnes</SubHeaderTabs.Tab>
        </SubHeaderTabs>
      </div>
      <div />
    </div>
  )
}

export default TrackingPageSubHeader
