import { MutableRefObject, useEffect, useRef } from "react"

import { useDispatch, useSelector } from "react-redux"

import { useIntersectionObserver } from "hooks/useIntersectionObserver"

import { loadBuilderSignatureAffectations } from "../BuilderSignaturesReducers"
import { builderSignaturesSelectors } from ".."

import { LoadingStatus } from "core/CoreModels"

interface UseBuilderSignatureAffectedCount {
  signatureRef: MutableRefObject<Element | null>
  loadingStatus: LoadingStatus
}

const useBuilderSignatureAffectedCount = (
  signatureId: number,
): UseBuilderSignatureAffectedCount => {
  const dispatch = useDispatch()
  const signatureRef = useRef<Element | null>(null)
  const isIntersecting = useIntersectionObserver(signatureRef)

  const loadingStatus = useSelector(
    builderSignaturesSelectors.getBuilderSignatureAffectUsersCountLoadingStatus(
      signatureId,
    ),
  )

  useEffect(() => {
    if (isIntersecting && loadingStatus !== LoadingStatus.LOADED)
      dispatch(loadBuilderSignatureAffectations(signatureId))
  }, [isIntersecting])

  return { signatureRef, loadingStatus }
}

export default useBuilderSignatureAffectedCount
