import React from "react"
import BannerClickByDate from "entities/BannerClickByDate"
import { FormattedMessage } from "react-intl"

import {
  AreaChart,
  Text,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts"

interface Props {
  clicksByDate: BannerClickByDate[]
}

const CustomAxisTick = (props) => {
  return <Text {...props}>{props.payload.value}</Text>
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload == null) return null
    return (
      <div className="banners-graph__tooltip">
        <span className="banners-graph__label banners-graph__blue">
          {" "}
          {label}
        </span>
        <br />
        <FormattedMessage
          id="BannerGraph.NbClicks"
          defaultMessage="Nombre de clics sur les bannières"
        />
        :<span className="banners-graph__blue"> {payload[0].value}</span>
      </div>
    )
  }
  return null
}

class Graph extends React.Component<Props> {
  render() {
    const { clicksByDate } = this.props
    return (
      <div className="banners-graph">
        <AreaChart
          width={560}
          height={260}
          data={clicksByDate}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <YAxis
            dataKey="nbClicks"
            tick={
              <CustomAxisTick
                style={{
                  fill: "#778F9B",
                  fontSize: "10px",
                  fontFamily: "Roboto",
                  lineHeight: "10px",
                  textAlign: "center",
                }}
              />
            }
            tickLine={false}
            axisLine={false}
          />
          <XAxis
            dataKey="dateLibelle"
            tick={
              <CustomAxisTick
                style={{
                  fill: "#778F9B",
                  fontSize: "10px",
                  fontFamily: "Roboto",
                  lineHeight: "10px",
                  textAlign: "center",
                }}
              />
            }
            tickMargin={8}
            tickLine={false}
            stroke={"#3D5AFE"}
            strokeWidth={2}
          />
          <Tooltip content={CustomTooltip} />
          <CartesianGrid strokeDasharray="2" />
          <Area
            type="monotone"
            dataKey="nbClicks"
            dot={true}
            stroke="#3D5AFE"
            fillOpacity={1}
            fill="#EBEEFE"
          />
        </AreaChart>
      </div>
    )
  }
}

export default Graph
