import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

interface SignaturesTileNewMailCountProps {
  newMailcount: number
  inResponseCount: number
}

const messages = defineMessages({
  newMail: {
    id: "HomePage.SignaturesTileNewMail",
    defaultMessage: "Nouveau Mail",
  },
  inResponse: {
    id: "HomePage.SignaturesTileInResponse",
    defaultMessage: "En réponse",
  },
})

const SignaturesTileNewMailCount: FunctionComponent<
  SignaturesTileNewMailCountProps
> = ({ newMailcount, inResponseCount }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="signature-tile__new-mail-count--container">
      <div className="signature-tile__new-mail-count--block">
        <p className="signature-tile__new-mail-count--value">{newMailcount}</p>
        <p className="signature-tile__new-mail-count--label">
          {formatMessage(messages.newMail)}
        </p>
      </div>
      <div className="signature-tile__new-mail-count--block">
        <p className="signature-tile__new-mail-count--value">
          {inResponseCount}
        </p>
        <p className="signature-tile__new-mail-count--label">
          {formatMessage(messages.inResponse)}
        </p>
      </div>
    </div>
  )
}

export default SignaturesTileNewMailCount
