import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { isSpan } from "utils/I18nHelpers"

import { ReactComponent as SubsidiaryDuotoneIcon } from "assets/duotone/icon-subsidiary-duotone-type2.svg"

import { Subsidiary } from "features/Subsidiaries/SubsidiaryModels"

interface SubsidiariesPillProps {
  subsidiaries: Subsidiary[]
}

const messages = defineMessages({
  subisidiaries: {
    id: "SubsidiariesAdminsGrid.SubsidiariesCell",
    defaultMessage: "<sp>{count}</sp> filiales",
  },
  assignment: {
    id: "SubsidiariesAdminsGrid.Assignment",
    defaultMessage: "Affecter",
  },
})

const SubisidiaryItem = ({ name, imgUrl }) => (
  <div className="subsidiaries-admins-grid__subsidiaries-item">
    <img
      src={imgUrl}
      className="subsidiaries-admins-grid__subsidiaries-item--img"
    />
    <p className="subsidiaries-admins-grid__subsidiaries-item--label">{name}</p>
  </div>
)

const SubsidiariesPill: FunctionComponent<SubsidiariesPillProps> = ({
  subsidiaries,
}) => {
  const { formatMessage } = useIntl()

  const [show, setShow] = useState(false)

  if (subsidiaries.length > 1)
    return (
      <>
        <div
          className="subsidiaries-admins-grid__subsidiaries-pill"
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <div className="subsidiaries-admins-grid__subsidiaries-pill--icon-container">
            <SubsidiaryDuotoneIcon className="subsidiaries-admins-grid__subsidiaries-pill--icon" />
          </div>
          <p className="subsidiaries-admins-grid__subsidiaries-pill--label">
            {formatMessage(messages.subisidiaries, {
              count: subsidiaries.length,
              sp: isSpan,
            })}
          </p>
          {show && (
            <div className="subsidiaries-admins-grid__subsidiaries-pill--container">
              {subsidiaries.map((s) => (
                <SubisidiaryItem key={s.id} imgUrl={s.iconUrl} name={s.name} />
              ))}
            </div>
          )}
        </div>
      </>
    )

  if (subsidiaries.length === 1)
    return (
      <div className="subsidiaries-admins-grid__subsidiaries-pill">
        <SubisidiaryItem
          imgUrl={subsidiaries[0].iconUrl}
          name={subsidiaries[0].name}
        />
      </div>
    )

  return null
}

export default SubsidiariesPill
