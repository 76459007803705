import PortalApi from "core/services/portalApi"
import ServiceApi from "core/services/serviceApi"

import {
  AccountInformations,
  AdminAccount,
  AdminAccountDto,
} from "./AccountsModels"

import { TypedResult } from "core/CoreModels"
import { UTMs } from "features/Marketing/MarketingModels"
import TenantProperty from "entities/TenantProperty"

export function fetchAdminsAccountsApi(): Promise<
  TypedResult<AdminAccountDto[]>
> {
  return PortalApi.request("GET", "/users")
}

export function createAdminAccountApi(
  account: AdminAccount,
): Promise<TypedResult<AdminAccountDto>> {
  return PortalApi.request("POST", "/users", {
    data: {
      userName: account.userName,
      roles: account.roles,
    },
  })
}

export function updateAdminAccountRolesApi(
  account: AdminAccount,
): Promise<TypedResult<AdminAccountDto>> {
  return PortalApi.request("PUT", `/users/${account.userName}`, {
    data: { roles: account.roles },
  })
}

export function deleteAdminAccountApi(
  id: string,
): Promise<TypedResult<AdminAccountDto>> {
  return PortalApi.request("DELETE", `/users/${id}`)
}

export function fetchAccountPropertySettings(
  propertyname: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", `/settings/${propertyname}`)
}

export function updateAccountPropertySettings<T>(
  propertyname: string,
  value: T,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("PUT", `/settings/${propertyname}`, {
    data: { value },
  })
}

export function resetTourGuide(): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/settings/reset")
}

export function fetchMe(): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", "/account/currentUser")
}

export function login(
  email: string,
  password: string,
  rememberMe = false,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/account/login", {
    data: { Email: email, Password: password, RememberMe: rememberMe },
  })
}

export function logout(): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", "/account/logout")
}

export function registerAccountApi(
  email: string,
  password: string,
  captcha: string,
  UTMDatas: UTMs,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/account", {
    data: {
      Email: email,
      Password: password,
      Captcha: captcha,
      ...UTMDatas,
    },
  })
}

export function fillAccountInformations(
  accountInformations: AccountInformations,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("PUT", "/account", { data: accountInformations })
}

export function resetPassword(
  resetPasswordJson: unknown,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/account/resetpassword", {
    data: resetPasswordJson,
  })
}

export function requestResetPassword(
  email: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/account/forgotpassword", {
    data: email,
  })
}

export function fetchCurrentTenant(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/tenant")
}

export function createCurrentTenant(): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/tenant")
}

export function fetchTenantProperties(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/tenant/properties")
}

export function createTenantPropertyApi(
  tenantProperty: TenantProperty,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("POST", "/tenant/properties", {
    data: tenantProperty,
  })
}

export function updateTenantPropertyApi(
  tenantProperty: TenantProperty,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request(
    "PUT",
    `/tenant/properties/${tenantProperty.Property}`,
    { data: tenantProperty },
  )
}

export function updateGoogleTenantProperty(): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/tenant/properties/google")
}

export function updateTenantFeature(feature: {
  Name: string
  Activated: boolean
}): Promise<TypedResult<unknown>> {
  return ServiceApi.request("PUT", "/tenant/features", { data: feature })
}

export function getWindowsVersions(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/versions", { params: { $top: 5 } })
}

export function fetchGoogleWorkspaceDomains(
  adminEmail: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/gworkspace/domains", {
    data: { adminEmail },
  })
}
