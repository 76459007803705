import React, { FunctionComponent, useMemo } from "react"

import { useDispatch, useSelector } from "react-redux"

import { appSelectors } from "features/App"

import { switchLanguage } from "features/App/AppReducers"

import LangPillSwitchItem from "./components/LangPillSwitchItem"

import { AvailableLanguages } from "features/App/AppModels"

const LangPillSwitch: FunctionComponent = () => {
  const dispatch = useDispatch()
  const locales = useSelector(appSelectors.getLocaleAsArray)
  const selectedLocale = useSelector(appSelectors.getSelectedLang)

  const handleSwitchLanguage = (locale: AvailableLanguages) =>
    dispatch(switchLanguage(locale))

  const localeItems = useMemo(
    () =>
      locales.map((locale) => (
        <LangPillSwitchItem
          key={locale.id}
          locale={locale.lang}
          onSwitchLanguage={handleSwitchLanguage}
          isSelected={locale.lang === selectedLocale}
        />
      )),
    [locales],
  )

  return <div className="lang-pill-switch">{localeItems}</div>
}

export default LangPillSwitch
