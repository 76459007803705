import React from "react"
import TrackingBanner from "features/Tracking/components/TrackingBanner"
import ScenarioMetric from "entities/ScenarioMetric"
import { useSlicedScenariosDatas } from "features/Scenarios/hooks/useSlicedScenariosDatas"

interface Props {
  scenarioMetrics: ScenarioMetric[]
}

const BannerTrackingList: React.FC<Props> = ({ scenarioMetrics }) => {
  useSlicedScenariosDatas({ forceAll: true })
  return (
    <div className="tracking-banners__container">
      {scenarioMetrics?.map((scenarioMetric) => (
        <TrackingBanner tracking={scenarioMetric} key={scenarioMetric.id} />
      ))}
    </div>
  )
}

export default BannerTrackingList
