import { Fragment } from "react"
import { useSelector } from "react-redux"
import { useNotifier } from "./useNotifier"
import { Notification } from "./NotificationModels"
import DefaultDisplay from "./components/DefaultDisplay"

interface NotificationProps {
  open: boolean
  transition: "left" | "right" | "up" | "down"
  handleClose()
  action?: any
  progress?: boolean
}

interface Props {
  Component?: any // | React.Component<NotificationProps> | React.FC<NotificationProps>
}

const ConnectedNotification: React.FC<Props> = ({ Component }) => {
  const { deleteNotification } = useNotifier()

  const Displayer = Component ?? DefaultDisplay
  const notifications = useSelector((state: any) => state.notifications)
  return notifications.map((notification: Notification, key) => (
    <Fragment key={key}>
      <Displayer
        notification={notification}
        onClose={() => deleteNotification(notification.id)}
      />
    </Fragment>
  ))
}

export default ConnectedNotification
