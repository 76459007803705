import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

const messages = defineMessages({
  title: { id: "RegisterPage.Title", defaultMessage: "Allez-y !" },
  subTitle: { id: "RegisterPage.SubTitle", defaultMessage: "Essayez nous" },
})

const RegisterLeftPanelMessage: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="register-left-panel__message">
      <p className="register-left-panel__message--title">
        {formatMessage(messages.title)}
      </p>
      <p className="register-left-panel__message--subtitle">
        {formatMessage(messages.subTitle)}
      </p>
    </div>
  )
}

export default RegisterLeftPanelMessage
