import React, { FunctionComponent, ReactNode } from "react"

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import HoverTooltip from "components/HoverTooltip"

interface DeleteButtonWithTipProps {
  message: ReactNode
  onClick: () => void
}

const DeleteButtonWithTip: FunctionComponent<DeleteButtonWithTipProps> = ({
  message,
  onClick,
}) => {
  return (
    <HoverTooltip level="warning" tooltip={message} align="right">
      <button
        type="button"
        className="sync-properties-grid__btn-delete mb-1"
        onClick={onClick}
      >
        <DeleteOutlinedIcon />
      </button>
    </HoverTooltip>
  )
}

export default DeleteButtonWithTip
