import { FunctionComponent, ReactNode } from "react"

interface ScenariosListLayoutContentProps {
  children: ReactNode
}

export type ScenariosListLayoutContentType =
  FunctionComponent<ScenariosListLayoutContentProps>

const ScenariosListLayoutContent: ScenariosListLayoutContentType = ({
  children,
}) => {
  return (
    <div className="scenarios-list-layout__content">
      <div className="scenarios-list-layout__content--container">
        {children}
      </div>
    </div>
  )
}

export default ScenariosListLayoutContent
