import { Dispatch, FunctionComponent } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import {
  ActionType,
  DiffusionModeFilterType,
  ScenarioModeFilterType,
  Types,
} from "features/Tracking/hooks/useScenariosTrackingSliderFilters"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"
import { featureNames } from "config/config.features"

interface ScenariosTrackingFiltersProps {
  diffusionMode: DiffusionModeFilterType
  scenarioMode: ScenarioModeFilterType
  dispatch: Dispatch<ActionType>
}

const messages = defineMessages({
  all: {
    id: "Tracking.ScenariosTrackingFiltersAll",
    defaultMessage: "Tous",
  },
  internal: {
    id: "Tracking.ScenariosTrackingFiltersInternal",
    defaultMessage: "Interne",
  },
  external: {
    id: "Tracking.ScenariosTrackingFiltersExternal",
    defaultMessage: "Externe",
  },
  unique: {
    id: "Tracking.ScenariosTrackingFiltersUnique",
    defaultMessage: "Unique",
  },
  scenario: {
    id: "Tracking.ScenariosTrackingFiltersScenario",
    defaultMessage: "Scénario",
  },
})

const ScenariosTrackingFilters: FunctionComponent<
  ScenariosTrackingFiltersProps
> = ({ diffusionMode, scenarioMode, dispatch }) => {
  const { formatMessage } = useIntl()

  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)

  return (
    <div className="scen-track-slider__filters">
      {internalExternalFeature?.isActive && (
        <div className="scen-track-slider__filters--container">
          <button
            type="button"
            className={classNames(
              "scen-track-slider__filters--pill",
              diffusionMode === "all" && "is-active",
            )}
            onClick={() => dispatch({ type: Types.UnsetDiffusionModeFilter })}
          >
            {formatMessage(messages.all)}
          </button>
          <button
            type="button"
            className={classNames(
              "scen-track-slider__filters--pill",
              diffusionMode === "internal" && "is-active",
            )}
            onClick={() =>
              dispatch({
                type: Types.FilterByDiffusionMode,
                payload: "internal",
              })
            }
          >
            {formatMessage(messages.internal)}
          </button>
          <button
            type="button"
            className={classNames(
              "scen-track-slider__filters--pill",
              diffusionMode === "external" && "is-active",
            )}
            onClick={() =>
              dispatch({
                type: Types.FilterByDiffusionMode,
                payload: "external",
              })
            }
          >
            {formatMessage(messages.external)}
          </button>
        </div>
      )}
      <div className="scen-track-slider__filters--container">
        <button
          type="button"
          className={classNames(
            "scen-track-slider__filters--pill",
            scenarioMode === "all" && "is-active",
          )}
          onClick={() => dispatch({ type: Types.UnsetScenarioModeFilter })}
        >
          {formatMessage(messages.all)}
        </button>
        <button
          type="button"
          className={classNames(
            "scen-track-slider__filters--pill",
            scenarioMode === "simple" && "is-active",
          )}
          onClick={() =>
            dispatch({ type: Types.FilterByScenarioMode, payload: "simple" })
          }
        >
          {formatMessage(messages.unique)}
        </button>
        <button
          type="button"
          className={classNames(
            "scen-track-slider__filters--pill",
            scenarioMode === "multiple" && "is-active",
          )}
          onClick={() =>
            dispatch({
              type: Types.FilterByScenarioMode,
              payload: "multiple",
            })
          }
        >
          {formatMessage(messages.scenario)}
        </button>
      </div>
    </div>
  )
}

export default ScenariosTrackingFilters
