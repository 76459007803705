import React from "react"

interface Props {
  isLoading: boolean
  fullScreen?: boolean
}

const SvgLoader: React.FC<Props> = ({ isLoading, fullScreen }) => {
  const getClass = () => {
    if (!isLoading) return "loader-container--hidden animated fadeOut"
    if (fullScreen) return "loader-container loader-container--fullscreen"
    return "loader-container"
  }

  return (
    <div className={getClass()}>
      {isLoading && (
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default SvgLoader
