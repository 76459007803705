import { FunctionComponent } from "react"
import { Outlet } from "react-router-dom"

import ScenarioLayout from "./ScenarioLayout"

const ScenariosRoutes: FunctionComponent = () => {
  return (
    <ScenarioLayout>
      <Outlet />
    </ScenarioLayout>
  )
}

export default ScenariosRoutes
