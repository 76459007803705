import { FunctionComponent } from "react"
import { defineMessages, useIntl } from "react-intl"

import { isCode, isSpan } from "utils/I18nHelpers"

import clientWindowsUserParamsView from "features/Parameters/assets/client-windows-user-params-view.webp"
import logoWindowsBubble from "features/Parameters/assets/logo_windows_bubble.webp"

interface InstallerWindowsExeProps {
  appLink: string
  apiKey: string
}

const messages = defineMessages({
  title: {
    id: "InstallerPanel.Windows.TitleExe",
    defaultMessage: "Installation par utilisateur (.exe)",
  },
  info1: {
    id: "InstallerPanel.Windows.ExeInformations1",
    defaultMessage: "Les mises à jour automatiques sont activées",
  },
  info2: {
    id: "InstallerPanel.Windows.ExeInformations2",
    defaultMessage:
      "L'application ne nécessite pas de privilège administrateur",
  },
  info3: {
    id: "InstallerPanel.Windows.ExeInformations3",
    defaultMessage:
      "Les fichiers de l'application sont installés dans <cd>%LocalAppData%</cd>",
  },
  dlLabel: {
    id: "InstallerPanel.Windows.ExeDownloadLabel",
    defaultMessage: "Téléchargez l'application (.exe) pour Windows",
  },
  btnLabel: {
    id: "InstallerPanel.Windows.ExeDownloadBtnLabel",
    defaultMessage: "Téléchargez l'installateur .exe",
  },
  installTitle: {
    id: "InstallerPanel.Windows.InstallExeTitle",
    defaultMessage: "Installation de l'application (.exe)",
  },
  installPart1: {
    id: "InstallerPanel.Windows.InstallExePart1",
    defaultMessage: "1. Installez l'application sur votre poste",
  },
  installPart2: {
    id: "InstallerPanel.Windows.InstallExePart2",
    defaultMessage:
      "2. Après l'installation, saisissez votre clé de sécurité (<sp>{apiKey}</sp>) dans le champ du formulaire",
  },
  installPart3: {
    id: "InstallerPanel.Windows.InstallExePart3",
    defaultMessage:
      "3. Cliquez sur Synchroniser pour récupérer votre signature mail !",
  },
})

const InstallerWindowsExe: FunctionComponent<InstallerWindowsExeProps> = ({
  appLink,
  apiKey = "",
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <div className="installer-windows-grid__exe--diff">
        <h5 className="installer-column__title">
          {formatMessage(messages.title)}
        </h5>
        <ul className="installer-column__list">
          <li className="installer-column__list--item">
            {formatMessage(messages.info1)}
          </li>
          <li className="installer-column__list--item">
            {formatMessage(messages.info2)}
          </li>
          <li className="installer-column__list--item">
            {formatMessage(messages.info3, { cd: isCode })}
          </li>
        </ul>
      </div>
      <div className="installer-windows-grid__exe--dl">
        <p className="installer-column__dl--label">
          {formatMessage(messages.dlLabel)}
        </p>
        <a className="installer-column__dl--button" href={appLink}>
          <img
            className="installer-column__dl--logo"
            alt="outlook logo"
            src={logoWindowsBubble}
          />
          <span className="installer-column__dl--btn-label">
            {formatMessage(messages.btnLabel)}
          </span>
        </a>
      </div>
      <div className="installer-windows-grid__exe--install">
        <h5 className="installer-column__install--title">
          {formatMessage(messages.installTitle)}
        </h5>
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart1)}
        </p>
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart2, {
            sp: isSpan,
            apiKey,
          })}
        </p>
        <img
          className="installer-column__install--img"
          src={clientWindowsUserParamsView}
          alt="Client Windows - Formulaire de paramètres utilisateurs"
        />
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart3)}
        </p>
      </div>
    </>
  )
}

export default InstallerWindowsExe
