import React from "react"
import { defineMessages } from "react-intl"
import { IntlShape, injectIntl } from "react-intl"
import Tooltip from "components/Tooltip"

interface IntlProps {
  intl: IntlShape
}

interface Props {
  intlId: string
}

interface State {
  show: boolean
  anchorEl: null | HTMLElement
}

const messages = defineMessages({
  NameYourSignature: {
    id: "Tooltip.NameYourSignature",
    defaultMessage: "Saisissez le nom de votre signature.",
  },
  ImportLogo: {
    id: "Tooltip.ImportLogo",
    defaultMessage: "Choisissez le logo de votre signature.",
  },
  DefineSocialNetwork: {
    id: "Tooltip.DefineSocialNetwork",
    defaultMessage: "Définissez vos url de réseaux sociaux.",
  },
  ChooseProperties: {
    id: "Tooltip.ChooseProperties",
    defaultMessage: "Choisissez les propriétés à afficher dans vos signatures.",
  },
  AddBanner: {
    id: "Tooltip.AddBanner",
    defaultMessage:
      "Choisissez une bannière existante ou créer une nouvelle bannière à associer à votre signature.",
  },
  AssignUsersToSignature: {
    id: "Tooltip.AssignUsersToSignature",
    defaultMessage:
      "Assignez les utilisateurs ou groupes qui recevront cette signature sur leur messagerie.",
  },
  AddBannerPicture: {
    id: "Tooltip.AddBannerPicture",
    defaultMessage:
      "Ajoutez une image provenant de votre ordinateur ou réalisez une nouvelle bannière via un éditeur complet.",
  },
  NameYourBanner: {
    id: "Tooltip.NameYourBanner",
    defaultMessage: "Saisissez le nom de votre bannière.",
  },
  BannerLink: {
    id: "Tooltip.BannerLink",
    defaultMessage: "Saisissez le lien cliquable de la bannière.",
  },
  BannerPublicationDate: {
    id: "Tooltip.BannerPublicationDate",
    defaultMessage:
      "Définissez les dates durant lesquelles la bannière sera affichée sous les signatures affectées.",
  },
  BannerAssignedSignatures: {
    id: "Tooltip.BannerAssignedSignatures",
    defaultMessage: "Liez des signatures à votre bannière.",
  },
  GSuiteAdmin: {
    id: "Tooltip.GSuiteAdmin",
    defaultMessage:
      "Le compte de service que vous avez paramétré utilise un compte administrateur GSuite pour synchroniser vos utilisateurs sur la plateforme BoostMyMail.",
  },
  OriginExplication: {
    id: "Tooltip.OriginGroupInfos",
    defaultMessage:
      "Indiquez l'origine des utilisateurs qui constitueront ce groupe : Portal: utilisateurs créés sur le portail ou via Excel. O365: groupe importé avec ses membres depuis votre annuaire Office 365. Gsuite : groupe importé avec ses membres depuis votre annuaire GSuite. Api: Groupe piloté par vore entreprise via un développement sur-mesure",
  },
})

class TooltipInfo extends React.Component<Props & IntlProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      anchorEl: null,
    }
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      show: !this.state.show,
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({ show: false, anchorEl: null })
  }

  render() {
    const { intlId } = this.props
    const text = this.props.intl.formatMessage({ id: intlId })
    let display = true
    if (text == intlId || text == null || text == "") display = false
    const tooltipProps = { ...this.props }
    delete tooltipProps.intlId

    return (
      <div className="tooltip-info">
        {this.props.children}
        {display && (
          <div className="tooltip-info__trigger" onClick={this.handleClick}>
            <i className="material-icons">info</i>
            <Tooltip
              show={this.state.show}
              parent={this.state.anchorEl}
              onClickAway={this.handleClose}
              onClickInside={this.handleClose}
              position={"top"}
              arrow={false}
            >
              <div>{text}</div>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(TooltipInfo)
