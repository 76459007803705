import { defineMessages } from "react-intl"

export const userPropertiesTranslationsForBuilder = defineMessages({
  city: { id: "Builder.City", defaultMessage: "Ville" },
  company: { id: "Builder.Company", defaultMessage: "Entreprise" },
  country: { id: "Builder.Country", defaultMessage: "Pays" },
  department: {
    id: "Builder.Department",
    defaultMessage: "Service",
  },
  firstname: { id: "Builder.Firstname", defaultMessage: "Prénom" },
  jobtitle: { id: "Builder.Jobtitle", defaultMessage: "Poste" },
  office: { id: "Builder.Office", defaultMessage: "Bureau" },
  mail: { id: "Builder.Mail", defaultMessage: "Mail" },
  mobile: { id: "Builder.Mobile", defaultMessage: "Mobile" },
  postalcode: {
    id: "Builder.Postalcode",
    defaultMessage: "Code Postal",
  },
  streetaddress: {
    id: "Builder.Streetaddress",
    defaultMessage: "Adresse",
  },
  service: { id: "Builder.Service", defaultMessage: "Service" },
  lastname: { id: "Builder.Lastname", defaultMessage: "Nom" },
  phone: { id: "Builder.Phone", defaultMessage: "Téléphone" },
  banner: { id: "Builder.Banner", defaultMessage: "Bannière" },
  website: { id: "Builder.Website", defaultMessage: "Site web" },
  disclaimer: {
    id: "Builder.Disclaimer",
    defaultMessage: "Disclaimer",
  },
  usermessage: {
    id: "Builder.Usermessage",
    defaultMessage: "Message utilisateur",
  },
  picture: { id: "Builder.Picture", defaultMessage: "Photo" },
  phones: { id: "Builder.CombinedPhonesLabel", defaultMessage: "Telephones" },
})
