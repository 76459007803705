import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as ClickPointerIcon } from "assets/icon-click-pointer.svg"
import { ReactComponent as LoadingSignatureModel } from "features/Tracking/assets/loading_signature_model.svg"

import SignaturePreview from "features/Signatures/components/SignaturePreview"

interface SignaturesPowerUsersOverviewProps {
  template: string
  nbClicks: number
  isLoading: boolean
}

const messages = defineMessages({
  information: {
    id: "Tracking.PowerUsersOverviewInformation",
    defaultMessage: "Clics générés avec la signature",
  },
})

const SignaturesPowerUsersOverview: FunctionComponent<
  SignaturesPowerUsersOverviewProps
> = ({ template, nbClicks, isLoading }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="sign-power-users-overview">
      <div className="sign-power-users-overview__infos">
        <div className="sign-power-users-overview__infos--icon">
          <ClickPointerIcon />
        </div>
        <p className="sign-power-users-overview__infos--clicks">{nbClicks}</p>
      </div>
      <p className="sign-power-users-overview__message">
        {formatMessage(messages.information)}
      </p>
      <div className="sign-power-users-overview__template">
        {isLoading ? (
          <LoadingSignatureModel className="sign-power-users__skeleton--template" />
        ) : (
          <SignaturePreview template={template} cardHeight={80} />
        )}
      </div>
    </div>
  )
}

export default SignaturesPowerUsersOverview
