import {
  COMBINED_PROPERTIES_PHONES,
  ORDERED_REGULAR_USER_PROPERTIES,
} from "./BuilderSignaturesConstants"

import { DraggableItem, CombinedDraggableItem } from "bmm-builder"
import { BuilderSignature } from "./BuilderSignaturesModels"

export function reorderUserProperties(userProperties) {
  const orderedProperties = []
  const cloneUserProperties = [...userProperties]
  ORDERED_REGULAR_USER_PROPERTIES.forEach((property) => {
    const found = cloneUserProperties.find((u) => u.property === property)

    if (found) {
      const index = cloneUserProperties
        .map((object) => object.id)
        .indexOf(found.id)
      orderedProperties.push(found)
      cloneUserProperties.splice(index, 1)
    }
  })

  return [...orderedProperties, ...cloneUserProperties]
}

export function extractAndReplacePhones(
  label: string,
  userProperties: Array<DraggableItem | CombinedDraggableItem>,
): Array<DraggableItem | CombinedDraggableItem> {
  const phonePropertyPredicate = (userProperty) =>
    userProperty.property === COMBINED_PROPERTIES_PHONES.phone

  const mobilePropertyPredicate = (userProperty) =>
    userProperty.property === COMBINED_PROPERTIES_PHONES.mobile

  const phoneProperty = userProperties.find(
    phonePropertyPredicate,
  ) as DraggableItem

  const mobileProperty = userProperties.find(
    mobilePropertyPredicate,
  ) as DraggableItem

  if (!phoneProperty || !mobileProperty) return userProperties

  const phonePropertyIndex = userProperties.findIndex(phonePropertyPredicate)

  const mobilePropertyIndex = userProperties.findIndex(mobilePropertyPredicate)

  userProperties.splice(
    Math.min(phonePropertyIndex, mobilePropertyIndex),
    2,
    buildCombinedPropertiesItemsTemplate(label, [
      phoneProperty,
      mobileProperty,
    ]) as CombinedDraggableItem,
  )

  return userProperties
}

export function buildCombinedPropertiesItemsTemplate(
  label: string,
  properties: [DraggableItem, DraggableItem],
): CombinedDraggableItem {
  return {
    id: properties[0].id,
    type: "combined",
    label: `#${label}`,
    combinedItems: properties,
  }
}

export function isBuilderSignature(signature): signature is BuilderSignature {
  return signature && typeof signature === "object" && signature.json
}
