import { FunctionComponent } from "react"

import classNames from "classnames"

interface WeightingIndicatorItemProps {
  weighting: number
  index: number
  indicatorWidth?: number
  heightMin?: number
  heightMax?: number
  heightIncrement?: number
  showLabel?: boolean
  onChange?: (value: number) => void
}

const WeightingIndicatorItem: FunctionComponent<
  WeightingIndicatorItemProps
> = ({
  weighting,
  index,
  onChange,
  indicatorWidth = 12,
  heightMin = 16,
  heightMax = 64,
  heightIncrement = 12,
  showLabel = true,
}) => {
  const getHeight = () => heightMin + heightIncrement * (index - 1)
  const getOpacity = () => getHeight() / heightMax
  const isColored = () => index <= weighting
  const isActive = () => index === weighting

  const handleChange = () => {
    if (onChange) onChange(index)
  }

  return (
    <div
      className={classNames("weighting__indicator", onChange && "is-clickable")}
      onClick={handleChange}
    >
      <div
        className={classNames(
          "weighting__indicator--visual",
          isActive() && "is-active",
        )}
        style={{
          width: `${indicatorWidth}px`,
          backgroundColor: isColored() ? "#3D5AFE" : "#000000",
          height: `${getHeight()}px`,
          borderRadius: `${getHeight()}px`,
          opacity: isColored() ? getOpacity() : 0.12,
        }}
      />
      {showLabel && (
        <p
          className={classNames(
            "weighting__indicator--label",
            isActive() && "is-active",
          )}
        >
          {index}
        </p>
      )}
    </div>
  )
}

export default WeightingIndicatorItem
