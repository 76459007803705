/**
 * Représente un contact stocké dans la CRM
 */
class CrmContact {
  Firstname: string
  Lastname: string
  Phone: string
  Mobile: string
  Email: string
  Function: string
}

export default CrmContact
