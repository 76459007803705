import { FunctionComponent } from "react"

import { useIntl, defineMessages } from "react-intl"

import { ModalActions } from "components/Modal"

interface MustSaveModalProps {
  onCancel: () => void
  onQuitWithoutSave: () => void
  onSave: () => void
  disableSave: boolean
}

const messages = defineMessages({
  content1: {
    id: "BuilderSignatures.SaveModalContentPart1",
    defaultMessage: "Votre travail ne sera pas enregistré.",
  },
  content2: {
    id: "BuilderSignatures.SaveModalContentPart2",
    defaultMessage:
      "Vous pouvez sauvegarder, quitter sans sauvegarder, ou continuer l’édition.",
  },
  continue: {
    id: "BuilderSignatures.SaveModalContentCancelButton",
    defaultMessage: "Continuer l’édition",
  },
  quitWithoutSave: {
    id: "BuilderSignatures.SaveModalContentQuitWithoutSaveButton",
    defaultMessage: "Quitter sans sauvegarder",
  },
  save: {
    id: "BuilderSignatures.SaveModalContentSaveButton",
    defaultMessage: "Enregistrer",
  },
})

const MustSaveModal: FunctionComponent<MustSaveModalProps> = ({
  onCancel,
  onQuitWithoutSave,
  onSave,
  disableSave,
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className="must-save-modal">
      <div className="must-save-modal__content">
        <p className="must-save-modal__content--text">
          {formatMessage(messages.content1)}
        </p>
        <p className="must-save-modal__content--text">
          {formatMessage(messages.content2)}
        </p>
      </div>
      <ModalActions>
        <ModalActions.Secondary onClick={onCancel}>
          {formatMessage(messages.continue)}
        </ModalActions.Secondary>
        <ModalActions.Secondary onClick={onQuitWithoutSave}>
          {formatMessage(messages.quitWithoutSave)}
        </ModalActions.Secondary>
        <ModalActions.Main onClick={onSave} disabled={disableSave}>
          {formatMessage(messages.save)}
        </ModalActions.Main>
      </ModalActions>
    </div>
  )
}

export default MustSaveModal
