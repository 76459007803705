/**
 * Represent a Banner instance
 *
 * @class Banner
 */
import moment, { Moment } from "moment"
import DateHelper from "../utils/DateHelper"
import StringHelper from "../utils/StringHelper"
import { User } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import Signature from "entities/Signature"
import BannerDto from "api/models/BannerDto"

export class Banner {
  Id: number
  Name: string
  Description: string
  Template: string
  StartDate: Moment | null
  EndDate: Moment | null
  Created: Moment | string
  Modified: Moment | string
  CreatedById: number
  ModifiedByid: number
  AffectedUsers: User[] = []
  AffectedGroups: Group[] = []
  AffectedSignatures: Signature[] = []
  Activated: boolean
  ActivatedDate: Moment
  ImageUrl: string
  LinkUrl: string
  // for storing image before upload it
  Image?: File
  UtcStartDate: Moment
  // for 'scenario'
  Images?: []
  Type: "simple" | "multiple"
  Priority: number
  Size: { width: number; height: number }

  constructor(jsonObject) {
    // Initialisation des champs obligatoires
    this.Created = DateHelper.ConvertToSQLDate(new Date())
    this.Modified = DateHelper.ConvertToSQLDate(new Date())

    for (const prop in jsonObject) {
      if (jsonObject.hasOwnProperty(prop)) {
        this[StringHelper.Capitalize(prop)] = jsonObject[prop]
      }
    }

    if (this.StartDate != null) {
      if (!moment.isMoment(this.StartDate)) {
        // 1. on reçoit toujours de l'UTC depuis le serveur
        const utcStartDate = moment.utc(this.StartDate, "YYYY-MM-DD HH")
        // 2. on le convertit ensuite dans la timezone courante
        this.StartDate = utcStartDate.local()
      }
    }

    if (this.EndDate != null) {
      // 1. on reçoit toujours de l'UTC depuis le serveur
      const utcEndDate = moment.utc(this.EndDate, "YYYY-MM-DD HH")
      // 2. on le convertit ensuite dans la timezone courante
      this.EndDate = utcEndDate.local()
    }

    this.Created = moment(this.Created)
    this.Modified = moment(this.Modified)

    // if (this.Resource == null) {
    //     this.setLinkUrl();
    //     this.setImageUrl();
    // }
  }

  /** Modifie le contenu de la ressource en fonction   **/
  public CompileTemplate() {
    // Normalisation de l'image avant envoi au service pour enregistrement en base
    this.Template = this.Template.split(" + ").join("").replace(/"/g, "")
    this.Template =
      '<a href="' + (this.LinkUrl ?? "") + '">' + this.Template + "</a>"
  }

  /* Définit si c'est une bannière par défaut */
  public IsDefault() {
    return this.StartDate == null && this.EndDate == null
  }

  /**
   * La date courante est entre la date de début et la date de fin de la bannière
   * ou pas de date définie
   */
  public IsAvailable() {
    const now = moment()
    return now.isBetween(this.StartDate, this.EndDate) || this.IsDefault()
  }

  /**
   * La date courante est entre la date de début et la date de fin de la bannière
   */
  public IsActive() {
    const now = moment()
    return now.isBetween(this.StartDate, this.EndDate)
  }

  /**
   * La date courante est avant la date de début de la bannière
   */
  public IsIncoming() {
    const now = moment()
    return now.isBefore(this.StartDate)
  }

  /**
   * La date courante est après la date de fin de la bannière
   */
  public IsFinished() {
    const now = moment()
    return now.isAfter(this.EndDate)
  }

  public toJson(): BannerDto {
    const bannerJson = {}
    // Mise à jour du template si la ressource associée a été modifiée
    this.CompileTemplate()

    for (const prop in this) {
      bannerJson[StringHelper.Capitalize(prop)] = this[prop]
    }

    if (this.StartDate) this.StartDate.utc()
    if (this.EndDate) this.EndDate.utc()

    return {
      Name: this.Name,
      Template: this.Template,
      StartDate: this.StartDate,
      EndDate: this.EndDate,
      Activated: this.Activated,
      ImageUrl: this.ImageUrl,
      LinkUrl: this.LinkUrl,
    }
  }
}

export default Banner
