import React from "react"

import { FormattedMessage } from "react-intl"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"

import NavigationButtons from "../Components/NavigationButtons"
import UsersSlider from "features/Enrollment/components/UsersSlider"
import OffersList from "features/Enrollment/components/OffersList"

import EnrollmentStep1Side from "./EnrollmentStep1Side"

import {
  USERS_LICENCES_MAX,
  USERS_LICENCES_MIN,
} from "features/Enrollment/EnrollmentConstants"

import { CatalogOffer } from "features/Offers/OffersModels"

import "./EnrollmentStep.scss"

const EnrollmentStep1: React.FC = () => {
  const { enrollmentDatas, updateNbUsers, updateOffer, allOffers } =
    useEnrollmentData()

  const handleUsersChange = (nbUsers: number) => {
    updateNbUsers(nbUsers)
  }

  const handleOfferChange = (selectedOffer: CatalogOffer) =>
    updateOffer(selectedOffer)

  return (
    <div className="enroll__step enroll__step1">
      <div className="enroll__box enroll__main">
        <div className="enroll__form">
          <h2>
            <span>1</span>
            <FormattedMessage
              id="Enroll.Step1Title"
              defaultMessage="Choisissez votre formule"
            />
          </h2>
          <fieldset>
            <legend>
              <FormattedMessage
                id="EnrollmentPlan.ChooseUsersNumberAndPlan"
                defaultMessage="Indiquez le nombre d'utilisateurs (vos collaborateurs) et sélectionnez votre offre"
              />
            </legend>
            <UsersSlider
              min={USERS_LICENCES_MIN}
              max={USERS_LICENCES_MAX}
              selectedValue={enrollmentDatas.nbUsers}
              onChange={handleUsersChange}
            />
            <OffersList
              offers={allOffers}
              nbUsers={enrollmentDatas.nbUsers}
              selectedOfferId={enrollmentDatas.selectedOffer?.id}
              updateSelectedOffer={handleOfferChange}
            />
          </fieldset>
        </div>
        <div>
          <NavigationButtons />
        </div>
      </div>
      <EnrollmentStep1Side />
    </div>
  )
}

export default EnrollmentStep1
