import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import classNames from "classnames"
import { partial } from "lodash"

import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import Toggle from "components/MUIToggle"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import { AvailableJobs } from "features/Jobs/JobsConstants"

import Feature from "features/Features/FeaturesModels"

interface SalesforceProps {
  feature: Feature
  updateFeature: (feature: Feature) => void
  launchJob: (feature: Feature) => void
}

const messages = defineMessages({
  title: {
    id: "SaleforcesForm.Title",
    defaultMessage: "Déploiement des signatures Salesforce",
  },
  description: {
    id: "SaleforcesForm.Description",
    defaultMessage:
      "Activez la synchronisation automatique des signatures mail de vos utilisateurs toutes les 12 heures.",
  },
  syncADDisable: {
    id: "SalesforceForm.SyncADDisable",
    defaultMessage: "Désactivée",
  },
  syncADEnable: {
    id: "SalesforceForm.SyncADEnable",
    defaultMessage: "Activée",
  },
  manualSync: {
    id: "SalesforceForm.ManualSync",
    defaultMessage: "Synchroniser manuellement",
  },
})

const Salesforce: FunctionComponent<SalesforceProps> = ({
  feature,
  updateFeature,
  launchJob,
}) => {
  const { formatMessage } = useIntl()

  const [launchSync, setLaunchSync] = useState(false)

  const isSyncing = useJobIsRunning(AvailableJobs.SYNC_USERS)

  useEffect(() => {
    if (!isSyncing) setLaunchSync(false)
  }, [isSyncing])

  const handleSync = () => {
    setLaunchSync(true)
    launchJob(feature)
  }

  const handleToggle = (
    feature: Feature,
    _evt: React.ChangeEvent<HTMLInputElement>,
    isActive: boolean,
  ) => {
    feature.activated = isActive
    updateFeature(feature)
  }

  return (
    <div className="salesforce-form">
      <div className="salesforce-form__container">
        <p className="salesforce-form__title">
          {formatMessage(messages.title)}
        </p>
        <p className="salesforce-form__description">
          {formatMessage(messages.description)}
        </p>
        <div className="salesforce-form__action">
          <div
            className={`salesforce-form__action--${
              feature.activated ? "inactive" : "active"
            }`}
          >
            {formatMessage(messages.syncADDisable)}
          </div>
          <div className="salesforce-form__toggle">
            <Toggle
              checked={feature.activated}
              onChange={partial(handleToggle, feature)}
            />
          </div>
          <div
            className={`salesforce-form__action--${
              feature.activated ? "active" : "inactive"
            }`}
            onClick={() => partial(handleToggle, feature)}
          >
            {formatMessage(messages.syncADEnable)}
          </div>
        </div>
        {feature.activated && (
          <button
            type="button"
            className="salesforce-form__sync"
            onClick={handleSync}
            disabled={launchSync || isSyncing}
          >
            <p className="salesforce-form__sync--label">
              {formatMessage(messages.manualSync)}
            </p>
            <SyncIcon
              className={classNames(
                "salesforce-form__sync--icon",
                (launchSync || isSyncing) && "is-loading",
              )}
            />
          </button>
        )}
      </div>
    </div>
  )
}

export default Salesforce
