/**
 * Default component used if no NotificationDisplay was supply
 */
import Notification from "components/Notification"
import CancelIcon from "@material-ui/icons/Cancel"
import { NotificationIcon } from "../NotificationIcons"

interface Props {
  notification: any
  onClose()
}

const DefaultDisplay: React.FC<Props> = ({ notification, onClose }) => {
  return (
    <div className="notifications__list">
      <Notification
        transition="left"
        open={true}
        handleClose={onClose}
        action={
          <>
            <CancelIcon className="info-notification" onClick={onClose} />
          </>
        }
      >
        <div className="notification__content">
          <NotificationIcon type={notification.type} />
          <div className="notification__text">{notification.message}</div>
        </div>
      </Notification>
    </div>
  )
}


export default DefaultDisplay
