import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import { PillSize } from "../PillButtonsGroup"

interface PillButtonElementProps {
  children: ReactNode
  isActive: boolean
  onClick: () => void
  size: PillSize
}

const PillButtonElement: FunctionComponent<PillButtonElementProps> = ({
  children,
  isActive,
  onClick,
  size,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        "pill-buttons-group__button",
        isActive && "is-active",
        size,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default PillButtonElement
