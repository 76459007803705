import { FunctionComponent, RefObject, useMemo } from "react"

import { useDispatch, useSelector } from "react-redux"

import { ScenarioVm } from "features/Scenarios/ScenarioModels"

import { duplicateScenarioAction } from "features/Scenarios/ScenarioActions"

import useScenarioAffectedDatas from "features/Scenarios/hooks/useScenarioAffectedDatas"

import Layout from "features/Scenarios/components/ScenarioCardLayout"

import { scenarioSelectors } from "features/Scenarios"

import { LoadingStatus } from "core/CoreModels"

interface ScenarioCardProps {
  scenario: ScenarioVm
  onDelete?: () => void
  onActivate?: (scenarioId: number, activate: boolean) => void
}

const ScenarioCard: FunctionComponent<ScenarioCardProps> = ({
  scenario,
  onDelete,
  onActivate,
}) => {
  const dispatch = useDispatch()
  const usersCount = useSelector(scenarioSelectors.getUsersCount(scenario.id))

  const { scenarioRef, loadingStatus } = useScenarioAffectedDatas(scenario.id)

  const handleDuplicate = () => {
    dispatch(duplicateScenarioAction(scenario.id))
  }

  const bannerImages = useMemo(
    () =>
      scenario.banners
        .sort(({ order: a }, { order: b }) => b - a)
        .map((b) => b.imageUrl),
    [scenario.banners],
  )

  return (
    <Layout cardRef={scenarioRef as RefObject<HTMLDivElement>}>
      <Layout.Images images={bannerImages} />
      <Layout.Details>
        <Layout.Infos>
          <Layout.Assignations
            affectedCount={usersCount}
            isLoading={loadingStatus !== LoadingStatus.LOADED}
          />
          <Layout.Type isMultiple={scenario.isMultiple()} />
          <Layout.Name name={scenario.name} />
          <Layout.Date
            startDate={scenario.startDate}
            endDate={scenario.endDate}
            isDefault={scenario.isDefault()}
          />
        </Layout.Infos>
        <Layout.Controls>
          <Layout.Toggle
            scenarioId={scenario.id}
            isActive={scenario.active}
            onActivate={onActivate}
          />
          {scenario.weighting !== null && (
            <Layout.Weight weighting={scenario.weighting} />
          )}
        </Layout.Controls>
      </Layout.Details>
      <Layout.Actions>
        <Layout.Edit
          scenarioId={scenario.id}
          isMultiple={scenario.isMultiple()}
        />
        <Layout.Assign
          scenarioId={scenario.id}
          isMultiple={scenario.isMultiple()}
          roundedCorner="top"
        />
        <Layout.Duplicate
          isMultiple={scenario.isMultiple()}
          onDuplicate={handleDuplicate}
        />
        <Layout.Delete
          isMultiple={scenario.isMultiple()}
          onDelete={onDelete}
          roundedCorner="bottom"
        />
      </Layout.Actions>
    </Layout>
  )
}

export default ScenarioCard
