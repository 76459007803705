import { DEFAULT_CATALOG_OFFER_PREMIUM_NAME } from "features/Offers/OffersConstants"

export const BMM_SALES_PHONE_NUMBER = "02 52 32 11 10"

export const BMM_SALES_MAIL = "contact@boostmymail.com"

export const TAX_COEFFICIENT = 1.2 // Taxe de 20%

export const USERS_LICENCES_DEFAULT = 100

export const USERS_LICENCES_MIN = 1

export const USERS_LICENCES_MAX = 500

export const USERS_SLIDER_MARKS = [
  { value: USERS_LICENCES_MIN, label: `${USERS_LICENCES_MIN}` },
  { value: USERS_LICENCES_MAX, label: `${USERS_LICENCES_MAX}+` },
]

export const DEFAULT_ENROLLMENT_OFFER_NAME = DEFAULT_CATALOG_OFFER_PREMIUM_NAME

export const DEFAULT_ENROLLMENT_MOST_POPULAR =
  DEFAULT_CATALOG_OFFER_PREMIUM_NAME

export const TAILOR_MADE_CTA_LINK =
  "https://www.boostmymail.com/fr/decouvrez-notre-plateforme-lors-dune-demo-gratuite/"

export const TAILOR_MADE_CTA_USERS_TRIGGER = 100

export const ENROLLMENT_STEP_SUCCESS = 6
