import React from "react"
import { useState } from "react"
import { Collapse } from "@material-ui/core"

interface Props {
  list: any[]
  label: string
  showTotal?: boolean
  displayProp: string
  info?: string
}

const ExpandList: React.FC<Props> = (props) => {
  const { list, label, showTotal, displayProp, info } = props
  const [expanded, setExpanded] = useState(false)

  const hasList = list.length > 0

  return (
    <div className="expand-list">
      <div
        className="expand-list__label"
        onClick={() => setExpanded(!expanded && hasList)}
      >
        {!expanded && (
          <i className="material-icons expand-list__icon">chevron_right</i>
        )}
        {expanded && (
          <i className="material-icons expand-list__icon">expand_more</i>
        )}
        <span>{label + (showTotal ? " " + list.length : "")}</span>
      </div>
      <Collapse in={expanded && hasList}>
        {info && <span className="expand-list__info">{info}</span>}
        <div className="expand-list__collapse">
          <div className="expand-list__list">
            {list.map((item, index) => (
              <span key={index}>{item[displayProp]}</span>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default ExpandList
