import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

interface SignatureCardInfosProps {
  children: ReactNode
  className?: string
}

export type SignatureCardInfosType = FunctionComponent<SignatureCardInfosProps>

const SignatureCardInfos: SignatureCardInfosType = ({
  children,
  className,
}) => {
  return (
    <div className={classNames("signature-card__infos", className)}>
      {children}
    </div>
  )
}

export default SignatureCardInfos
