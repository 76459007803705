import { FunctionComponent, ReactNode } from "react"

interface TrackingDataBlockProps {
  title: ReactNode
  children: ReactNode
}

const TrackingDataBlock: FunctionComponent<TrackingDataBlockProps> = ({
  title,
  children,
}) => {
  return (
    <div className="tracking-data-block">
      <div className="tracking-data-block__title">{title}</div>
      <div className="tracking-data-block__content">{children}</div>
    </div>
  )
}

export default TrackingDataBlock
