import React, { FunctionComponent } from "react"

import { useSelector } from "react-redux"

import useUsersData from "features/Users/hooks/useUsersData"

import { trackingSelectors } from "features/Tracking"

import { defineMessages, useIntl } from "react-intl"

import config from "config/config"

const messages = defineMessages({
  title: { id: "ConnectedIconUsersData.Title", defaultMessage: "Utilisateurs" },
})

const TrackingIconData: FunctionComponent = () => {
  const intl = useIntl()

  useUsersData()
  const dataNumber = useSelector(trackingSelectors.getNbUsersWithLicence)

  const imgSrc = `${config.cdnUrl}/images/bmm/tracking/icon_users.svg`
  const title = intl.formatMessage({ id: "ConnectedIconUsersData.Title" })

  return (
    <div className="tracking__icon-data">
      <div className="tracking__icon-data__container">
        <img src={imgSrc} className="tracking__icon-data__image" />
      </div>
      <div className="tracking__icon-data__separator"></div>
      <div className="tracking__icon-data__text_container">
        <div className="tracking__icon-data__number">{dataNumber}</div>
        <div className="tracking__icon-data__title">{title}</div>
      </div>
    </div>
  )
}

export default TrackingIconData
