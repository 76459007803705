import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as VeryLowIcon } from "assets/icon-weighting-very-low.svg"
import { ReactComponent as LowIcon } from "assets/icon-weighting-low.svg"
import { ReactComponent as MediumIcon } from "assets/icon-weighting-medium.svg"
import { ReactComponent as HighIcon } from "assets/icon-weighting-high.svg"
import { ReactComponent as VeryHighIcon } from "assets/icon-weighting-very-high.svg"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import AssignationsPanel, {
  AssignationsPanelSingleSignature,
} from "../AssignationsPanel"

import { LoadingStatus } from "core/CoreModels"

import { featureNames } from "config/config.features"

interface AssignationsPanelSignaturesProps {
  displayedIndex: number | null
  signatures: AssignationsPanelSingleSignature[]
  loadingStatus: LoadingStatus
  previous: () => void
  next: () => void
}

export type AssignationsPanelSignaturesType =
  FunctionComponent<AssignationsPanelSignaturesProps>

const messages = defineMessages({
  internal: {
    id: "AssignationsPanel.Internal",
    defaultMessage: "interne",
  },
  external: {
    id: "AssignationsPanel.External",
    defaultMessage: "externe",
  },
  newMail: {
    id: "AssignationsPanel.NewMail",
    defaultMessage: "nouveau mail",
  },
  inResponse: {
    id: "AssignationsPanel.InResponse",
    defaultMessage: "en réponse",
  },
  weighting: {
    id: "AssignationsPanel.Weighting",
    defaultMessage: "Priorité",
  },
  veryLow: {
    id: "AssignationsPanel.WeightingVeryLow",
    defaultMessage: "très faible",
  },
  low: {
    id: "AssignationsPanel.WeightingLow",
    defaultMessage: "faible",
  },
  medium: {
    id: "AssignationsPanel.WeightingMedium",
    defaultMessage: "moyenne",
  },
  high: {
    id: "AssignationsPanel.WeightingHigh",
    defaultMessage: "forte",
  },
  veryHigh: {
    id: "AssignationsPanel.WeightingVeryHigh",
    defaultMessage: "très forte",
  },
})

function getWeightingIcon(weighting: number) {
  switch (weighting) {
    case 2:
      return <LowIcon />
    case 3:
      return <MediumIcon />
    case 4:
      return <HighIcon />
    case 5:
      return <VeryHighIcon />
    case 1:
    default:
      return <VeryLowIcon />
  }
}

function getWeightingLabel(weighting: number) {
  switch (weighting) {
    case 2:
      return messages.low
    case 3:
      return messages.medium
    case 4:
      return messages.high
    case 5:
      return messages.veryHigh
    case 1:
    default:
      return messages.veryLow
  }
}

const AssignationsPanelSignatures: AssignationsPanelSignaturesType = ({
  displayedIndex,
  signatures,
  loadingStatus,
  previous,
  next,
}) => {
  const { formatMessage } = useIntl()

  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)
  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const containerRef = useRef<HTMLDivElement | null>(null)
  const signatureRef = useRef<HTMLDivElement | null>(null)
  const [scale, setScale] = useState(1)

  const displayComponentOrSkeletonLoading = useMemo(
    () => displayedIndex !== null && loadingStatus === LoadingStatus.LOADED,

    [displayedIndex, loadingStatus],
  )

  const getScale = (
    currentSignatureRef: HTMLDivElement | null,
    currentContainerRef: HTMLDivElement | null,
  ) => {
    if (currentSignatureRef === null || currentContainerRef === null) return 1
    const signatureHeight = currentSignatureRef.getBoundingClientRect().height
    const containerHeight = currentContainerRef.getBoundingClientRect().height
    const scale = containerHeight / signatureHeight
    return scale >= 1 ? 1 : scale
  }

  useEffect(() => {
    setScale(getScale(signatureRef.current, containerRef.current))
  }, [
    signatureRef.current,
    containerRef.current,
    displayedIndex,
    loadingStatus,
  ])

  const displayDiffusionMode =
    internalExternalFeature?.isActive &&
    signatures[displayedIndex]?.diffusionMode &&
    formatMessage(messages[signatures[displayedIndex].diffusionMode])

  const displaySendingMode =
    newMailInResponseFeature?.isActive &&
    signatures[displayedIndex]?.sendingMode &&
    formatMessage(messages[signatures[displayedIndex].sendingMode])

  return (
    <div className="assignations-panel__signatures">
      <div className="assignations-panel__signatures--header">
        {displayComponentOrSkeletonLoading ? (
          <>
            <p className="assignations-panel__signatures--diffusion-mode">
              {`${displayDiffusionMode || ""}${
                displayDiffusionMode && displaySendingMode ? " | " : ""
              }${displaySendingMode || ""}`}
            </p>
          </>
        ) : (
          <>
            <div className="skeleton skeleton__diffusion-mode" />
          </>
        )}
      </div>
      <div className="assignations-panel__signatures--content">
        {displayComponentOrSkeletonLoading ? (
          <div
            ref={containerRef}
            className="assignations-panel__signatures--container"
          >
            <div
              className="assignations-panel__signatures--inner-html"
              dangerouslySetInnerHTML={{
                __html: signatures[displayedIndex].template,
              }}
              ref={signatureRef}
              style={{
                zoom: scale,
              }}
            />
          </div>
        ) : (
          <div ref={signatureRef} className="skeleton skeleton__preview" />
        )}
      </div>
      <AssignationsPanel.Data>
        <div className="assignations-panel__signatures--data-container">
          {displayComponentOrSkeletonLoading ? (
            <>
              <p className="assignations-panel__signatures--name">
                {signatures[displayedIndex].name}
              </p>
              <div className="user-signature-preview__informations--weighting">
                {getWeightingIcon(signatures[displayedIndex].weighting)}
                <p className="user-signature-preview__informations--weighting-label">
                  {`${formatMessage(messages.weighting)} ${formatMessage(
                    getWeightingLabel(signatures[displayedIndex].weighting),
                  )}`}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="skeleton skeleton__title" />
              <div className="skeleton skeleton__title" />
            </>
          )}
        </div>
        <AssignationsPanel.Navigation
          isLoading={displayComponentOrSkeletonLoading}
          displayedIndex={displayedIndex}
          total={signatures.length}
          previous={previous}
          next={next}
        />
      </AssignationsPanel.Data>
    </div>
  )
}

export default AssignationsPanelSignatures
