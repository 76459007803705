import React from "react"

import { getBase64, getSizeOfImageFile } from "utils/ImageUtils"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import ImageResizer from "components/ImageResizer"

import CampaignPreview from "features/Scenarios/components/CampaignPreview"

const CampaignFormImages: React.FC = () => {
  const { editingCampaign, updateEditingCampaign } = useScenarioFormContext()
  const maxSize = { width: 900, height: 600 }

  const handleResize = (width: number, height: number) => {
    const updatedVisual = { ...editingCampaign.banners[0], width, height }
    updateEditingCampaign({ banners: [updatedVisual] })
  }

  const handleUpload = async (file: File) => {
    const size = await getSizeOfImageFile(file)
    const handleFileChangeCallback = (imageAsBase64Url) => {
      const updatedVisual = {
        imageUrl: imageAsBase64Url,
        width: size.width,
        height: size.height,
      }
      updateEditingCampaign({ banners: [updatedVisual] })
    }
    getBase64(file, handleFileChangeCallback)
  }

  return (
    <div className="campaignform__images">
      <CampaignPreview
        url={editingCampaign.banners[0]?.imageUrl}
        width={editingCampaign.banners[0]?.width}
        onUpload={editingCampaign.banners.length === 0 && handleUpload}
      />

      <BannerResizer
        height={editingCampaign.banners[0]?.height}
        width={editingCampaign.banners[0]?.width}
        maxSize={maxSize}
        opacity={editingCampaign.banners[0]?.imageUrl ? "1" : "0"}
        handleResize={handleResize}
      />
    </div>
  )
}

const BannerResizer = ({ maxSize, opacity, height, width, handleResize }) => {
  return (
    <div className="campaignform__imageresizer" style={{ opacity }}>
      <ImageResizer
        max={maxSize}
        size={{ width, height }}
        originalSize={{ width, height }}
        resizeImage={handleResize}
      />
    </div>
  )
}

export default CampaignFormImages
