export const TRIAL_OFFER_NAME = "Trial"
export const PREMIUM_OFFER_NAME = "Premium"
export const BASIC_OFFER_NAME = "Basic"

export const MAX_ADMINS = 50

export const DEFAULT_CATALOG_OFFER_PREMIUM_NAME = "Premium"
export const DEFAULT_CATALOG_OFFER_BASIC_NAME = "Basic"

export const DEFAULT_OFFER_CURRENCY = { symbol: "€", name: "EUR" }
