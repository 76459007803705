import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"

import { SignatureTracking } from "features/Tracking/TrackingModels"

import SingleSignatureTrackingHeader from "./components/SingleSignatureTrackingHeader"
import SingleSignatureTrackingLegend from "./components/SingleSignatureTrackingLegend"
import SingleSignatureTrackingGraph from "./components/SingleSignatureTrackingGraph"
import SingleSignatureTrackingInsufficientsDatas from "./components/SingleSignatureTrackingInsufficientsDatas"

import TrackingKPI from "features/Tracking/components/TrackingKPI"

import SignatureTrackingMapper from "features/Tracking/mappers/SignatureTrackingMapper"

import useTrackingGraphData from "features/Tracking/hooks/useTrackingGraphData"
import useSingleSignatureAffectedUsers from "features/Tracking/hooks/useSingleSignatureAffectedUsers"

import { MINIMUM_GRAPH_DATA_LENGTH } from "features/Tracking/TrackingConstants"

import { LoadingStatus } from "core/CoreModels"

interface SingleSignatureTrackingProps {
  signature: SignatureTracking
  handleUnselect: () => void
}

const messages = defineMessages({
  title: {
    id: "Tracking.SingleSignatureTrackingGraphTitle",
    defaultMessage: "Taux de clics",
  },
  maxAffectedUsersTooltip: {
    id: "Tracking.SingleSignatureTrackingKPIMaxAffectedUsersTooltip",
    defaultMessage: "Message d'information",
  },
  maxClicksTooltip: {
    id: "Tracking.SingleSignatureTrackingKPIMaxClicksTooltip",
    defaultMessage: "Message d'information",
  },
})

const SingleSignatureTracking: FunctionComponent<
  SingleSignatureTrackingProps
> = ({ signature, handleUnselect }) => {
  const { formatMessage } = useIntl()

  const { affectedUsersCount, loadingStatus } =
    useSingleSignatureAffectedUsers(signature)

  const { data, dispatch } = useTrackingGraphData<SignatureTracking>(
    signature,
    SignatureTrackingMapper.getGraphDataFromHistory,
  )

  const sufficientDatas = useMemo(
    () => signature.history.length >= MINIMUM_GRAPH_DATA_LENGTH,
    [signature],
  )

  return (
    <div className="single-sign-track">
      <SingleSignatureTrackingHeader
        name={signature.name}
        created={signature.created}
        handleUnselect={handleUnselect}
      />
      <div className="single-sign-track__content">
        <div className="single-sign-track__content--left-side">
          <SingleSignatureTrackingLegend
            series={data}
            template={signature.template}
            isBuilder={signature.isBuilder}
            dispatch={dispatch}
            sufficientGraphDatas={sufficientDatas}
          />
        </div>
        <div className="single-sign-track__content--right-side">
          <TrackingKPI
            maxAffectedUsers={signature.maxAffectedUsers}
            affectedUsers={affectedUsersCount}
            maxAffectedUsersDate={signature.maxAffectedUsersDate}
            maxClicks={signature.maxClicks}
            maxClicksDate={signature.maxClicksDate}
            totalClicks={signature.totalClicks}
            sufficientGraphDatas={signature.history.length > 0}
            maxAffectedUsersTooltip={formatMessage(
              messages.maxAffectedUsersTooltip,
            )}
            maxClicksTooltip={formatMessage(messages.maxClicksTooltip)}
            affectedUsersIsLoading={loadingStatus !== LoadingStatus.LOADED}
          />
          {sufficientDatas ? (
            <SingleSignatureTrackingGraph
              title={formatMessage(messages.title)}
              data={data
                .filter((history) => history.display === true)
                .sort((a, b) => Number(a.hover) - Number(b.hover))}
            />
          ) : (
            <SingleSignatureTrackingInsufficientsDatas
              title={formatMessage(messages.title)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleSignatureTracking
