import { FunctionComponent, ReactNode } from "react"

import { useSelector } from "react-redux"

import classNames from "classnames"

import { ReactComponent as IconCheck } from "assets/icon-check.svg"

import { accountsSelectors } from "features/Accounts"

import {
  HoverCardContent,
  OnBoardingKey,
} from "features/Accounts/AccountsModels"
import PreviewCard from "./PreviewCard"

interface WelcomeCardProps {
  id: OnBoardingKey
  icon: ReactNode
  label: string
  hoverCardContent?: HoverCardContent
  onDisplayHoverCard?: () => void
  onHideHoverCard?: () => void
  displayHoverCard?: boolean
}

const WelcomeCard: FunctionComponent<WelcomeCardProps> = ({
  id,
  icon,
  label,
  hoverCardContent,
  onDisplayHoverCard,
  onHideHoverCard,
  displayHoverCard = false,
}) => {
  const isCompleted = useSelector(
    accountsSelectors.getOnBoardingStepCompleted(id),
  )
  return (
    <div
      className={classNames(
        "onboarding-tile__welcome-card",
        isCompleted && "is-completed",
      )}
      onMouseEnter={() => onDisplayHoverCard && onDisplayHoverCard()}
      onMouseLeave={() => onHideHoverCard && onHideHoverCard()}
    >
      {isCompleted && (
        <IconCheck className="onboarding-tile__welcome-card--check" />
      )}
      <div className="onboarding-tile__welcome-card--icon">{icon}</div>
      <p className="onboarding-tile__welcome-card--label">{label}</p>
      {displayHoverCard && hoverCardContent && (
        <div
          className="onboarding-tile__welcome-card--preview"
          onMouseEnter={() => onDisplayHoverCard && onDisplayHoverCard()}
          onMouseLeave={() => onHideHoverCard && onHideHoverCard()}
        >
          <PreviewCard
            visual={hoverCardContent.visual}
            title={hoverCardContent.title}
            url={hoverCardContent.url}
            content={hoverCardContent.content}
          />
        </div>
      )}
    </div>
  )
}

export default WelcomeCard
