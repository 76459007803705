import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { uuid } from "utils/Uuid"

import { fetchSignaturesCustomQueryAction } from "features/Signatures/SignaturesActions"

import { signaturesSelectors } from ".."

import { Params } from "core/services/requestBuilderService"

import Signature from "entities/Signature"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

const useSignaturesCustomqueryData = (
  customQuery: Params<Signature | BuilderSignature>,
) => {
  const dispatch = useDispatch()
  const calledOnce = useRef(false)
  const requestId = useRef(uuid.generate())

  const result = useSelector(
    signaturesSelectors.getSignaturesCustomQueryResultById(requestId.current),
  )

  useEffect(() => {
    if (calledOnce.current) {
      return
    }
    dispatch(fetchSignaturesCustomQueryAction(requestId.current, customQuery))
    calledOnce.current = true
  }, [])

  return result
}

export default useSignaturesCustomqueryData
