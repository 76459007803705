import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import { addNotification } from "features/Notifications/NotificationReducer"
import { Notification } from "features/Notifications/NotificationModels"

import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"

import { GlobalStates } from "store"
import { LoadingStatus } from "core/CoreModels"

const waitForLoadingMessage = defineMessages({
  loading: {
    id: "Users.WaitForLoading",
    defaultMessage: "Merci d'attendre la fin du chargement de vos utilisateurs",
  },
})

interface UseWaitForUsersLoading {
  isLoaded: boolean
  notifyUser: () => void
}

const useWaitForUsersLoading = (): UseWaitForUsersLoading => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const usersLoadingStatus = useSelector<GlobalStates, LoadingStatus>(
    usersSelectors.getLoadingStatus,
  )
  const groupsLoadingStatus = useSelector<GlobalStates, LoadingStatus>(
    groupsSelectors.getIsLoaded,
  )

  const isLoaded =
    usersLoadingStatus === LoadingStatus.LOADED &&
    groupsLoadingStatus === LoadingStatus.LOADED

  const notifyUser = () => {
    dispatch(
      addNotification(
        new Notification(formatMessage(waitForLoadingMessage.loading), "INFO"),
      ),
    )
  }

  return { isLoaded, notifyUser }
}

export default useWaitForUsersLoading
