import { FunctionComponent } from "react"

import { SignatureRenderer } from "bmm-builder"

import SignaturePreview from "features/Signatures/components/SignaturePreview"

interface ScenarioBySignaturesCardContentProps {
  preview: string
  isNewBuilder: boolean
}

const ScenarioBySignaturesCardContent: FunctionComponent<
  ScenarioBySignaturesCardContentProps
> = ({ preview, isNewBuilder }) => {
  return (
    <div className="scen-by-sign-card__content">
      {isNewBuilder ? (
        <SignatureRenderer templateJSON={preview} scale={0.8} />
      ) : (
        <SignaturePreview template={preview} />
      )}
    </div>
  )
}

export default ScenarioBySignaturesCardContent
