import { useDispatch, useSelector } from "react-redux"
import { FunctionComponent, useEffect, useState } from "react"
import Card, { CardHeader } from "components/Card"
import Gallery from "components/Gallery"
import {
  signaturesSelectors,
  signaturesActionCreators,
} from "features/Signatures"
import { defineMessages, useIntl } from "react-intl"
import { Template } from "entities/Template"
import SignatureReadOnlyView from "features/Signatures/SignatureReadOnlyView/SignatureReadOnlyView"

import { clone } from "lodash"
import Button from "components/Button"

const defaultUserProperties = {
  firstname: "#Prénom",
  lastname: "#Nom",
  jobtitle: "#Poste",
  mail: "#Adresse mail",
  mobile: "#Mobile",
  phone: "#Téléphone",
  picture: "https://cdn.boostmymail.com/images/bmm/default-user.png",
  website: "#Site web",
}

const defaultUserMarkers = [
  "firstname",
  "lastname",
  "jobtitle",
  "mail",
  "phone",
  "mobile",
  "website",
  "picture",
]

const messages = defineMessages({
  GalleryLoading: {
    id: `SignatureEditor.GalleryLoading`,
    defaultMessage: "Chargement des modèles en cours",
  },
  GalleryAppliquer: {
    id: `SignatureEditor.GalleryApply`,
    defaultMessage: "Appliquer",
  },
})

const GalleryPresenter: FunctionComponent = () => {
  const intl = useIntl()

  const dispatch = useDispatch()

  const templates = useSelector(signaturesSelectors.getAllTemplates)
  const activeTemplate = useSelector(signaturesSelectors.getSelectedTemplate)

  const [currentTemplateId, setCurrentTemplateId] = useState(-1)
  const [templateGallery, setTemplateGallery] = useState<Array<Template>>([])

  useEffect(() => {
    if (currentTemplateId === -1 && activeTemplate != null )
      setCurrentTemplateId(activeTemplate.Id)

    // Initialisation de la gallery lorsqu'elle n'est pas déjà chargés,
    // et uniquement la première fois qu'on reçoit les modèles
    if (templateGallery.length > 0) return

    const compiledPreviews = templates.map((template) => {
      const cloned: Template = clone(template)

      cloned.userProperties = defaultUserMarkers
      cloned.sampleUserProps = defaultUserProperties
      cloned.UpdateTemplate()
      return cloned
    })
    setTemplateGallery(compiledPreviews)
  }, [templates])

  const onApplyTemplate = (templateId: number) => {
    dispatch(signaturesActionCreators.selectTemplate(templateId))
  }

  const handleChange = (index) => {
    setCurrentTemplateId(templates[index].Id)
  }

  const handleClick = () => {
    onApplyTemplate(currentTemplateId)
  }

  const getActiveTemplateIndex = () => {
    return templateGallery.findIndex((temp) => temp.Id === activeTemplate.Id)
  }

  if (!activeTemplate || templateGallery.length === 0)
    return (
      <Gallery>
        <Card>
          <CardHeader>{intl.formatMessage(messages.GalleryLoading)}</CardHeader>
          <div
            style={{
              width: "400px",
              height: "240px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SignatureReadOnlyView model="" />
          </div>
        </Card>
        ) )
      </Gallery>
    )

  const tailorMadeTemplate = templates.find(
    (temp) => temp.Name === "Template Z",
  )
  const tailorMadeTemplateId = tailorMadeTemplate && tailorMadeTemplate.Id

  return (
    <div data-cy="template-gallery">
      <Gallery onChange={handleChange} initialIndex={getActiveTemplateIndex()}>
        {templateGallery &&
          templateGallery.map((temp, index) => (
            <Card key={index}>
              <CardHeader>{temp.Name || `Modèle ${index}`} </CardHeader>
              <div
                style={{
                  width: "400px",
                  height: "240px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SignatureReadOnlyView model={temp.compiledContent} />
              </div>
            </Card>
          ))}
      </Gallery>
      <div className="gallery__button-zone">
        <Button
          disabled={
            activeTemplate &&
            (currentTemplateId === activeTemplate.Id ||
              currentTemplateId === tailorMadeTemplateId)
          }
          label={intl.formatMessage(messages.GalleryAppliquer)}
          onClick={handleClick}
        />
      </div>
    </div>
  )
}

export default GalleryPresenter
