/**
 * A time-based view of user's banners schedule
 */

import React, { useState } from "react"
import moment, { Moment } from "moment"
import CalendarViewDay from "./CalendarViewDay"
import { DayCell } from "./DayCell"
import CalendarRange from "./entities/CalendarRange"
import { getDayDatas } from "./CalendarController"

/**
 * An empty day cell in a CalendarView
 */

export const EmptyCell = () => <div className="cv-day">&nbsp;</div>

/**
 * CalendarView
 */
interface Props {
  ranges: CalendarRange[]
  selectedId?: number
  hoveredId?: number
  onSelect?: (id: number) => void
  onHover?: (id: number) => void
  onMonthChange?: (date: string) => void
}

const CalendarView: React.FC<Props> = ({
  ranges,
  selectedId,
  hoveredId,
  onSelect,
  onHover,
  onMonthChange,
}) => {
  /* State */
  const [curMonth, setCurMonth] = useState(moment())

  // dayView Popup
  const [showDayView, setShowDayView] = useState(false)
  const [dateDayView, setDateDayView] = useState("")
  const [rangesDayView, setRangesDayView] = useState([])
  const [posDayView, setPosDayView] = useState({ x: 0, y: 0 })

  /* Init */
  const { dayDatas, firstDay } = getDayDatas(
    curMonth.clone().startOf("month"),
    ranges,
  )
  const nbRanges = 4

  /* Callbacks */

  const goMonth = (date: Moment) => {
    setCurMonth(date)
    if (onMonthChange) onMonthChange(date.format("YYYY-MM-DD"))
  }

  const goPrev = () => goMonth(curMonth.clone().subtract(1, "M"))
  const goNext = () => goMonth(curMonth.clone().add(1, "M"))
  const goNow = () => goMonth(moment())

  const displayCalendarViewDay = (date, ranges, evt) => {
    const offset = { x: 40, y: 40 }
    setShowDayView(!showDayView)
    setRangesDayView(ranges)
    setDateDayView(date)
    setPosDayView({
      x: evt.target.parentElement.offsetLeft - offset.x,
      y: evt.target.parentElement.offsetTop - offset.y,
    })
    evt.stopPropagation()
  }

  /* Helpers */

  const getDayNamesStartOnMonday = () => {
    const dayNames = moment.weekdaysShort()
    dayNames.push(dayNames.shift())
    return dayNames
  }

  /* Rendering */

  const renderWeekDays = () => {
    const dayNames = getDayNamesStartOnMonday()
    return dayNames.map((day, index) => (
      <div key={index} className="cv-weekday">
        {day.toLowerCase()}
      </div>
    ))
  }

  return (
    <div className="calendar-view__container">
      <div
        className="calendar-view card"
        onClick={() => {
          showDayView ? setShowDayView(false) : onSelect && onSelect(0)
        }}
      >
        <div className="cv-header">
          <div>
            <div className="cv-btn cv-prev" onClick={goPrev}>
              <i className="material-icons cv-icons">chevron_left</i>
            </div>
            <div className="cv-btn cv-next" onClick={goNext}>
              <i className="material-icons cv-icons">chevron_right</i>
            </div>
            <div className="cv-title">
              <span className="cv-btn" onClick={goNow}>
                {curMonth.format("MMMM YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="cv-weekdays">{renderWeekDays()}</div>
        <div className="cv-days">
          {[...Array(firstDay - 1)].map(() => (
            <EmptyCell />
          ))}
          {dayDatas.map((dayData, index) => (
            <DayCell
              dayData={dayData}
              key={index}
              selectedId={selectedId}
              hoveredId={hoveredId}
              onShowMore={displayCalendarViewDay}
              onSelect={(rangeId) => onSelect && onSelect(rangeId)}
              onHover={(rangeId) => onHover && onHover(rangeId)}
              nbRanges={nbRanges}
            />
          ))}
        </div>
        <CalendarViewDay
          date={dateDayView}
          isVisible={showDayView}
          ranges={rangesDayView}
          posX={posDayView.x}
          posY={posDayView.y}
          onSelectRange={(rangeId) => onSelect && onSelect(rangeId)}
          onPopdown={() => setShowDayView(false)}
        ></CalendarViewDay>
      </div>
    </div>
  )
}

export default CalendarView
