import StringHelper from "utils/StringHelper"

/**
 * Represente une Version du client lourd BMM
 *
 * @class Version
 */
export class Version {
  MsiLink: string
  ReleaseNotes: string
  Released: string
  Version: string
  ExeLink: string

  constructor(versionJson) {
    for (const prop in versionJson) {
      if (versionJson.hasOwnProperty(prop)) {
        this[StringHelper.Capitalize(prop)] = versionJson[prop]
      }
    }
  }
}

export default Version
