import { FunctionComponent } from "react"

import classNames from "classnames"

import OnBoardingMessage from "./components/OnBoardingMessage"
import WelcomeCardsContainer from "./components/WelcomeCardsContainer"

import { useOnBoardingCompleted } from "./hooks/useOnBoardingCompleted"
import { useOnBoardingDisplay } from "./hooks/useOnBoardingDisplay"

const OnBoardingRibbon: FunctionComponent = () => {
  const completed = useOnBoardingCompleted()

  const display = useOnBoardingDisplay()

  if (!display) return null

  return (
    <div className={classNames("onboarding-tile", completed && "is-completed")}>
      <OnBoardingMessage />
      <WelcomeCardsContainer />
    </div>
  )
}

export default OnBoardingRibbon
