export function isStrong(chunks: string) {
  return <strong>{chunks}</strong>
}

export function isSpan(chunks: string) {
  return <span>{chunks}</span>
}

export function isCode(chunks: string) {
  return <code>{chunks}</code>
}
