import React from "react"

interface Props {
  image: Element | React.ReactElement
  label: string
  onClick()
  disabled?: boolean
}

const ImageButton: React.FC<Props> = (props) => {
  const { image, label, onClick, disabled } = props

  return (
    <div
      onClick={onClick}
      className={`image-button ${disabled ? "image-button--disable" : ""}`}
    >
      <div className="image-button__image">{image}</div>
      <span className="image-button__label">{label}</span>
    </div>
  )
}

export default ImageButton
