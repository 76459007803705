import { useNavigate } from "react-router-dom"

import { useCurrentAccountAccess } from "./useCurrentAccountAccess"

import { PARAMETERS_URL, SUBSCRIPTION_URL } from "router/RouterConstants"
import { RolesNeeded } from "router/routes"
import { useEffect } from "react"

function useHomePageRedirects() {
  const navigate = useNavigate()

  const {
    hasAccessTo,
    hasAccessToHomePage,
    homePageRedirectUrl,
    hasNoGlobalAccess,
  } = useCurrentAccountAccess()

  const hasAccessParameters = hasAccessTo(RolesNeeded.PARAMETERS_PAGE).isActive
  const hasAccessBilling = hasAccessTo(RolesNeeded.BILLING_PAGE).isActive

  useEffect(() => {
    if (hasNoGlobalAccess && hasAccessBilling) {
      navigate(SUBSCRIPTION_URL)
    }

    if (hasNoGlobalAccess && hasAccessParameters) {
      navigate(PARAMETERS_URL)
    }

    if (!hasAccessToHomePage) {
      if (homePageRedirectUrl) navigate(homePageRedirectUrl)
    }
  }, [])
}

export default useHomePageRedirects
