import React from "react"

import classNames from "classnames"

interface Props {
  legend: string
  icon: Element | React.ReactElement
  onClick: () => void
  isDisabled?: boolean
}

const DataGridButton: React.FC<Props> = ({
  legend,
  icon,
  onClick,
  isDisabled,
}) => {
  return (
    <div className="data-grid-button">
      <div className="data-grid-button__legend animated fadeIn">{legend}</div>
      <button
        type="button"
        className={classNames(
          "data-grid-button__icon",
          isDisabled && "is-disabled",
        )}
        onClick={onClick}
        disabled={isDisabled}
      >
        {icon}
      </button>
    </div>
  )
}

export default DataGridButton
