import React from "react"
import { FormattedMessage } from "react-intl"
import { IbanElement } from "@stripe/react-stripe-js"

interface PropsSepa {
  onChange?: (key: string, value: string | boolean) => void
}

const CheckoutSepa: React.FC<PropsSepa> = (props) => {
  return (
    <div className="bank-details__block">
      <div className="bank-details__side bank-details__facturation">
        <div className="stripe-checkout">
          <div className="bmm-form__input__container">
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="holder">
                <FormattedMessage
                  id="BillingForm.OwnerAccount"
                  defaultMessage="Titulaire du compte"
                ></FormattedMessage>
                <input
                  type="text"
                  className="billing-form__textbox"
                  name="holder_sepa"
                  onChange={(e) =>
                    props.onChange("holder_sepa", e.target.value)
                  }
                />
              </label>
            </div>
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="email">
                <FormattedMessage
                  id="BillingForm.Email"
                  defaultMessage="Email"
                ></FormattedMessage>
                <input
                  type="email"
                  className="billing-form__textbox"
                  name="email"
                  onChange={(e) =>
                    props.onChange(e.target.name, e.target.value)
                  }
                />
              </label>
            </div>
          </div>
          <div className="bmm-form__input__container">
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="iban">
                <FormattedMessage
                  id="BillingForm.IBAN"
                  defaultMessage="IBAN"
                ></FormattedMessage>
                <IbanElement
                  onChange={(e) => props.onChange(e.elementType, e.complete)}
                  options={{
                    supportedCountries: ["SEPA"],
                  }}
                />
              </label>
            </div>
          </div>
          <div className="bmm-form__input__container mt-2">
            <span className="bank-details__mandate">
              <FormattedMessage
                id="BillingForm.Mandate"
                defaultMessage="En signant ce formulaire de mandat, vous autorisez (A) Boost My Mail à envoyer des instructions à votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux instructions de Boost My Mail. Vous bénéficiez d’un droit à remboursement par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte."
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutSepa
