import React from "react"
import { injectIntl, defineMessages, IntlShape } from "react-intl"
import DataTag from "entities/DataTag"
import InputText from "components/Input/InputText"
import { InputStyle } from "components/Input/InputText/InputText"

interface Props {
  displayPreview: boolean
  dataTag: DataTag
  intl: IntlShape
  onChange(dataTag)
}

interface Props {
  displayPreview: boolean
  dataTag: DataTag
  intl: IntlShape
  onChange(dataTag)
}

interface State {
  value: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = defineMessages({
  linkLinkedin: {
    id: "SocialShareItem.Url.linkedin",
    defaultMessage: "Votre URL Linkedin",
  },
  linkFacebook: {
    id: "SocialShareItem.Url.facebook",
    defaultMessage: "Votre URL Facebook",
  },
  linkInsta: {
    id: "SocialShareItem.Url.instagram",
    defaultMessage: "Votre URL Instagram",
  },
  linkYoutube: {
    id: "SocialShareItem.Url.youtube",
    defaultMessage: "Votre URL Youtube",
  },
  linkTwitter: {
    id: "SocialShareItem.Url.twitter",
    defaultMessage: "Votre URL Twitter",
  },
  url: { id: "SocialShareItem.Url", defaultMessage: "Url" },
})

class SocialShareItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: props.dataTag.url,
    }
  }

  handleBlur = () => {
    const dataTag = this.props.dataTag
    this.props.onChange(dataTag)
  }

  handleChange = (evt) => {
    const dataTag = this.props.dataTag
    dataTag.url = evt.target.value
    this.setState({ value: evt.target.value })
  }

  handleReset = () => {
    const dataTag = this.props.dataTag
    dataTag.url = ""
    this.setState({ value: "" })
    this.props.onChange(dataTag)
  }

  render() {
    const { dataTag, displayPreview, intl } = this.props
    const { value } = this.state

    let floatingLabel = `${intl.formatMessage({
      id: "SocialShareItem.Url." + dataTag.name,
    })}`

    if (dataTag.name === "logo")
      floatingLabel = intl.formatMessage({ id: "SocialShareItem.Url" })

    return (
      <li className="social-share__item">
        <div className="social-share__item-left">
          {displayPreview && (
            <div
              className="social-share__logo"
              dangerouslySetInnerHTML={{ __html: dataTag.markup }}
            />
          )}
          <InputText
            inputOptions={{
              order: 1,
              size: "auto",
              visible: "true",
              type: "text",
              name: "",
              placeholder: floatingLabel,
              label: "",
              variant: InputStyle.OUTLINED,
              value: value,
              onChange: this.handleChange,
              onBlur: this.handleBlur,
            }}
          />
        </div>
      </li>
    )
  }
}

export default injectIntl(SocialShareItem)
