import { createAction } from "@reduxjs/toolkit"

import {
  UpdateUserPropertiesDto,
  UserProperties,
  UserProperty,
} from "features/Users/UserModels"

export const fetchExcelModelAction = createAction("user/fetchExcelModel")

export const deleteUserAction = createAction(
  "user/deleteUser",
  function prepare(userId: number) {
    return { payload: userId }
  },
)

export const togglePropertyAction = createAction(
  "user/toggleProperty",
  function prepare(property: UserProperty) {
    return {
      payload: property,
    }
  },
)

export const changeDefaultSignatureAction = createAction(
  "user/changeDefaultSignature",
  function prepare(signatureId: number, userId: number) {
    return {
      payload: {
        signatureId,
        userId,
      },
    }
  },
)

export const fetchDefaultSignatureAction = createAction(
  "user/fetchDefaultSignature",
  function prepare(userId: number) {
    return {
      payload: userId,
    }
  },
)

export const fetchAssignedSignaturesAction = createAction(
  "user/fetchAssignedSignatures",
  function prepare(userId: number) {
    return {
      payload: userId,
    }
  },
)

export const copySignatureByUserAction = createAction(
  "user/copySignatureByUser",
  function prepare(userId: number) {
    return {
      payload: userId,
    }
  },
)

export const copyPreviewMailSuccessAction = createAction(
  "user/copyPreviewMailSuccess",
)

export const copyPreviewMailNotFoundAction = createAction(
  "user/copyPreviewMailNotFound",
)

export const copyPreviewMailFailureAction = createAction(
  "user/copyPreviewMailFailure",
)

export const upsertLicenceAction = createAction(
  "user/upsertLicence",
  function prepare(id: number, isActivate: boolean) {
    return {
      payload: {
        id,
        isActivate,
      },
    }
  },
)

export const updatePropertiesAction = createAction(
  "user/updateProperties",
  function prepare(userProperties: Array<UpdateUserPropertiesDto>) {
    return {
      payload: userProperties,
    }
  },
)

export const deletePropertyAction = createAction(
  "user/deleteProperty",
  function prepare(userProperty: UpdateUserPropertiesDto) {
    return {
      payload: userProperty,
    }
  },
)

export const updateUserLicenceSuccess = createAction(
  "user/updateUserLicenceSuccess",
)

export const updateUserPhotoFailureAction = createAction(
  "user/updateUserPhotoFailure",
)

export const uploadExcelFileAction = createAction(
  "user/uploadExcelFile",
  function prepare(file: File) {
    return {
      payload: file,
    }
  },
)

export const upsertUserAction = createAction(
  "user/upsertUser",
  function prepare({
    userId,
    properties,
    redirect,
  }: {
    userId: number
    properties: UserProperties
    redirect: string
  }) {
    return { payload: { userId, properties, redirect } }
  },
)

export const fetchActiveLicencesCountAction = createAction(
  "user/fetchActivesLicencesCount",
)
