import React from "react"
import GalleryContainer from "./GalleryContainer"
import ArrowBtn from "components/ArrowBtn"

interface Props {
  initialIndex?: number
  onChange?: (index: number) => void
}

interface State {
  selectedIndex: number
}
/**
 * Composant Gallery - Affiche la liste d'enfants sous forme de
 * gallerie, en en affichant qu'un seul à la fois
 */
class Gallery extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: props.initialIndex || 0,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.initialIndex !== this.props.initialIndex) {
      this.setState({ selectedIndex: this.props.initialIndex })
    }
  }

  handlePrev = () => {
    if (this.prevAvailable()) this.updateState(this.state.selectedIndex - 1)
  }

  handleNext = () => {
    if (this.nextAvailable()) this.updateState(this.state.selectedIndex + 1)
  }

  updateState = (newIndex: number) => {
    this.setState({ selectedIndex: newIndex }, () => {
      this.props.onChange && this.props.onChange(this.state.selectedIndex)
    })
  }

  prevAvailable = () => this.state.selectedIndex > 0

  nextAvailable = () => {
    const nbChildrens = React.Children.count(this.props.children)
    return this.state.selectedIndex < nbChildrens - 1
  }

  render() {
    const { children } = this.props
    const { selectedIndex } = this.state

    return (
      <div className="gallery">
        <ArrowBtn
          direction="left"
          styles="filled"
          active={this.prevAvailable()}
          onClick={this.handlePrev}
        ></ArrowBtn>
        <GalleryContainer selectedIndex={selectedIndex}>
          {children}
        </GalleryContainer>
        <ArrowBtn
          direction="right"
          styles="filled"
          active={this.nextAvailable()}
          onClick={this.handleNext}
        ></ArrowBtn>
      </div>
    )
  }
}

export default Gallery
