import { useDispatch, useSelector } from "react-redux"

import { scenarioSelectors } from ".."

import { ScenarioVm } from "../ScenarioModels"

import { fetchScenarios as fetchScenariosAction } from "../ScenarioReducers"

import { deleteScenarioAction } from "../ScenarioActions"
import {
  DiffusionMode,
  activateScenario as activateScenarioAction,
} from "../ScenarioReducers"

import { ScenarioMapper } from "../mappers/ScenarioMapper"

import useDiffusionMode from "./useDiffusionMode"
import usePrevious from "hooks/usePrevious"
import { useEffect } from "react"
import { LoadingStatus } from "core/CoreModels"

interface UseSlicedScenariosDatas {
  scenarios: Array<ScenarioVm>
  loadingStatus: LoadingStatus
  activateScenario: (id: number, activated: boolean) => void
  deleteScenario: (id: number) => void
}

interface UseSlicedScenariosDatasProps {
  forceAll?: boolean
}

function getCurrentDiffusionMode(
  diffusionMode: DiffusionMode,
  forceAll?: boolean,
) {
  if (forceAll === true) return "all"
  if (diffusionMode.internal && !diffusionMode.external) return "internal"
  if (diffusionMode.external && !diffusionMode.internal) return "external"

  return "all"
}

export const useSlicedScenariosDatas = (
  props: UseSlicedScenariosDatasProps = {},
): UseSlicedScenariosDatas => {
  const dispatch = useDispatch()
  const { diffusionMode } = useDiffusionMode()

  const previousDiffusionMode = usePrevious(diffusionMode)

  const scenarios = useSelector(scenarioSelectors.getAllScenarios)
  const loadingStatus = useSelector(
    scenarioSelectors.getScenarioSliceLoadedStatus(
      getCurrentDiffusionMode(diffusionMode, props?.forceAll),
    ),
  )

  const deleteScenario = (scenarioId: number) =>
    dispatch(deleteScenarioAction(scenarioId))
  const activateScenario = (scenarioId: number, activated: boolean) =>
    dispatch(activateScenarioAction({ id: scenarioId, activated }))

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.NOT_STARTED &&
      previousDiffusionMode !== diffusionMode
    )
      dispatch(
        fetchScenariosAction(
          getCurrentDiffusionMode(diffusionMode, props?.forceAll),
        ),
      )
  }, [loadingStatus, dispatch, diffusionMode, previousDiffusionMode])

  return {
    scenarios: scenarios.map(ScenarioMapper.toViewModel),
    loadingStatus,
    deleteScenario,
    activateScenario,
  }
}
