import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  changeDefaultSignatureAction,
  upsertUserAction,
} from "features/Users/UsersActions"
import { User, UserProperties } from "features/Users/UserModels"
import Signature from "entities/Signature"
import { usersSelectors } from "features/Users"
import { fetchSingleUser, resetEditingUser } from "features/Users/UsersReducers"
import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

export interface UpsertUser {
  userId: number
  properties: UserProperties
  redirect: string
}

interface UseUserData {
  currentUser: User | null
  assignedSignatures: Array<Signature | BuilderSignature>
  defaultSignature: Signature | BuilderSignature | null
  isLoading: LoadingStatus
  isSaving: SavingStatus
  onUpsertUser: ({ userId, properties, redirect }: UpsertUser) => void
  onChangeDefaultSignature: (signatureId: number, userId: number) => void
  onResetEditingUser: () => void
}

const useSingleUserData = (currentUserId: number): UseUserData => {
  const dispatch = useDispatch()

  const currentUser = useSelector(usersSelectors.getEditingUser)
  const assignedSignatures = useSelector(
    usersSelectors.getEditingUserAssignedSignatures,
  )
  const defaultSignature = useSelector(
    usersSelectors.getEditingUserDefaultSignature,
  )
  const { loadingStatus: isLoading, savingStatus: isSaving } = useSelector(
    usersSelectors.getEditingUserStatuses,
  )

  useEffect(() => {
    if (currentUserId !== -1) {
      dispatch(fetchSingleUser(currentUserId))
    }
  }, [currentUserId, dispatch])

  const onUpsertUser = ({ userId, properties, redirect }) => {
    dispatch(upsertUserAction({ userId, properties, redirect }))
  }

  const onChangeDefaultSignature = (signatureId: number, userId: number) => {
    dispatch(changeDefaultSignatureAction(signatureId, userId))
  }

  const onResetEditingUser = () => {
    dispatch(resetEditingUser())
  }

  return {
    currentUser,
    assignedSignatures,
    defaultSignature,
    isLoading,
    isSaving,
    onUpsertUser,
    onChangeDefaultSignature,
    onResetEditingUser,
  }
}

export default useSingleUserData
