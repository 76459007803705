import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface ScenariosPowerUsersGraphInsufficientsDatasProps {
  totalClicks: string
}

const messages = defineMessages({
  clicks: {
    id: "Tracking.ScenariosPowerUsersGraphInsufficientsDatasClicks",
    defaultMessage: "clics générés",
  },
  infos: {
    id: "Tracking.ScenariosPowerUsersGraphInsufficientsDatasInformations",
    defaultMessage: "En attente de données suffisantes",
  },
})

const ScenariosPowerUsersGraphInsufficientsDatas: FunctionComponent<
  ScenariosPowerUsersGraphInsufficientsDatasProps
> = ({ totalClicks }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="scen-power-users-graph-insuff">
      <div className="scen-power-users-graph-insuff__value">
        <p className="scen-power-users-graph-insuff__value--data">
          {totalClicks}
        </p>
        <p className="scen-power-users-graph-insuff__value--label">
          {formatMessage(messages.clicks)}
        </p>
      </div>
      <div className="scen-power-users-graph-insuff__infos">
        <InfoIcon className="scen-power-users-graph-insuff__infos--icon" />
        <p className="scen-power-users-graph-insuff__infos--label">
          {formatMessage(messages.infos)}
        </p>
      </div>
    </div>
  )
}

export default ScenariosPowerUsersGraphInsufficientsDatas
