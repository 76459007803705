import { FunctionComponent } from "react"

import { useIntl } from "react-intl"

import CheckBox from "components/BmmCheckBox"

import { formatAmount } from "utils/NumberHelper"

import { DEFAULT_OFFER_CURRENCY } from "features/Offers/OffersConstants"

import { OfferOption } from "features/Offers/OffersModels"

interface EnrollmentOptionItemProps {
  option: OfferOption
  nbLicences: number
  onSelect(option: OfferOption): void
}

const EnrollmentOptionItem: FunctionComponent<EnrollmentOptionItemProps> = ({
  option,
  nbLicences,
  onSelect,
}) => {
  const { locale } = useIntl()
  const pricing = option.getPricingFromLicencesNumber(nbLicences)

  return (
    <div className="enrollment__option">
      <CheckBox
        className="-enrollment__option-checkbox"
        id="cbDesignedByUs"
        name="cbDesignedByUs"
        label={option.Name}
        checked={option.Selected}
        onCheck={() => onSelect(option)}
      />
      <span className="enrollment__option-price">
        {formatAmount(pricing, locale, DEFAULT_OFFER_CURRENCY.name)}
      </span>
    </div>
  )
}

export default EnrollmentOptionItem
