import { FunctionComponent } from "react"

import PodiumItem, { IPodiumItem } from "./components/PodiumItem"
import EmptyPodiumItem from "./components/EmptyPodiumItem"

interface PodiumProps {
  gold?: IPodiumItem
  silver?: IPodiumItem
  bronze?: IPodiumItem
  selected?: number
  roundedAvatar?: boolean
  largeAvatar?: boolean
  isLoading?: boolean
}

const Podium: FunctionComponent<PodiumProps> = ({
  gold,
  silver,
  bronze,
  selected,
  roundedAvatar,
  largeAvatar,
  isLoading,
}) => {
  return (
    <div className="podium">
      {gold ? (
        <PodiumItem
          type="gold"
          id={gold.id}
          name={gold.name}
          visual={gold.visual}
          actions={gold.actions}
          isSelected={selected === gold.id}
          handleSelect={gold.handleSelect}
          roundedAvatar={roundedAvatar}
          largeAvatar={largeAvatar}
        />
      ) : (
        <EmptyPodiumItem
          type="gold"
          largeAvatar={largeAvatar}
          isLoading={isLoading}
        />
      )}
      {silver ? (
        <PodiumItem
          type="silver"
          id={silver.id}
          name={silver.name}
          visual={silver.visual}
          actions={silver.actions}
          isSelected={selected === silver.id}
          handleSelect={silver.handleSelect}
          roundedAvatar={roundedAvatar}
          largeAvatar={largeAvatar}
        />
      ) : (
        <EmptyPodiumItem
          type="silver"
          largeAvatar={largeAvatar}
          isLoading={isLoading}
        />
      )}
      {bronze ? (
        <PodiumItem
          type="bronze"
          id={bronze.id}
          name={bronze.name}
          visual={bronze.visual}
          actions={bronze.actions}
          isSelected={selected === bronze.id}
          handleSelect={bronze.handleSelect}
          roundedAvatar={roundedAvatar}
          largeAvatar={largeAvatar}
        />
      ) : (
        <EmptyPodiumItem
          type="bronze"
          largeAvatar={largeAvatar}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default Podium
