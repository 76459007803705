import history from "utils/history"

import { withSagaErrorHandler } from "features/Errors"

import { put, call, takeLatest, takeEvery } from "redux-saga/effects"

import config from "config/config"
import {
  getPortalVersion,
  getServiceVersion,
  getTranslations,
} from "features/App/AppApi"
import { initLanguagesSuccess } from "features/App/AppReducers"
import { Languages } from "features/App/AppModels"
import { checkApiAction, initLanguagesAction } from "features/App/AppActions"

// Vérifie la disponibilité des API
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* checkApiStatus() {
  try {
    const response = yield call(getServiceVersion)

    if (!response.success) throw Error(response.errorMessage)

    yield writeVersionInConsole()
  } catch (err) {
    throw new Error("Api BMM Injoignable")
  }
}

function* writeVersionInConsole() {
  const response = yield call(getPortalVersion)
  if (response.success) {
    // eslint-disable-next-line no-console
    console.info(response.result)
    config.env = response.result.Env
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* setAvailableLanguages() {
  const translationsResult = yield call(getTranslations)

  if (!translationsResult.success)
    throw new Error(translationsResult.errorMessage)

  const availablesLang: Languages = {}

  for (const langIdx in translationsResult.result) {
    translationsResult.result[langIdx].translations = JSON.parse(
      translationsResult.result[langIdx].translations,
    )
    availablesLang[translationsResult.result[langIdx].lang] =
      translationsResult.result[langIdx]
  }

  yield put(initLanguagesSuccess(availablesLang))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function navigateTo(url: string, state?: unknown) {
  history.push(url, state)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* redirectTo(url: string) {
  yield (window.location.href = url)
}

export const appWatchers = [
  takeLatest(checkApiAction.type, withSagaErrorHandler(checkApiStatus)),
  takeEvery(initLanguagesAction.type, setAvailableLanguages),
]
