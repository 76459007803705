import React from "react"

export interface Visual {
  imageUrl: string
  width: number
  height: number
}

interface Props {
  visuals?: Visual[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?(campaign: any): void
}

// TODO - rename
const CampaignLibrary: React.FC<Props> = ({ visuals, onClick }) => {
  return (
    <div className="campaignlibrary">
      {visuals?.map((visual, index) => (
        <div
          key={index}
          className="campaign-library__item"
          style={{ backgroundImage: `url(${visual.imageUrl})` }}
          onClick={() => {
            onClick && onClick(visual)
          }}
        />
      ))}
    </div>
  )
}

export default CampaignLibrary
