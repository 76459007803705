import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import moment from "moment"

import "react-dates-gte-react-17/initialize"
import { DateRangePicker } from "react-dates-gte-react-17"
import "react-dates-gte-react-17/lib/css/_datepicker.css"

import type { Moment } from "moment"

const messages = defineMessages({
  start: { id: "DatePickerComponent.Start", defaultMessage: "Début" },
  end: { id: "DatePickerComponent.End", defaultMessage: "Fin" },
})

type FocusedInputShape = "startDate" | "endDate"

interface DatePickerProps {
  initialStartDate: Moment
  initialEndDate: Moment
  onDatesChange: (startDate: Moment, endDate: Moment) => void
  minDate?: Moment
  maxDate?: Moment
  isDayBlocked?: (day: Moment) => void
  isDisabled?: boolean
  focusedInput?: FocusedInputShape
  closeOnDateSelect?: boolean
}

const DatePicker: FunctionComponent<DatePickerProps> = ({
  initialStartDate,
  initialEndDate,
  onDatesChange,
  minDate,
  maxDate,
  isDayBlocked,
  focusedInput,
  isDisabled = false,
  closeOnDateSelect = false,
}) => {
  const { formatMessage } = useIntl()
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(null)
  const [focusedInputState, setFocusedInputState] = useState(focusedInput)

  useEffect(() => {
    setFocusedInputState(focusedInput)
  }, [])

  useEffect(() => {
    setStartDate(initialStartDate)
  }, [initialStartDate])

  useEffect(() => {
    setEndDate(initialEndDate)
  }, [initialEndDate])

  const handlesDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
    onDatesChange(startDate, endDate)
  }

  return (
    <div className="bloc-date-picker" data-cy="date-picker">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handlesDateChange}
        focusedInput={focusedInputState}
        onFocusChange={(focusedInput) => setFocusedInputState(focusedInput)}
        displayFormat={() => moment.localeData().longDateFormat("L")}
        startDateId="startDate"
        startDatePlaceholderText={formatMessage(messages.start)}
        endDateId="endDate"
        endDatePlaceholderText={formatMessage(messages.end)}
        keepOpenOnDateSelect={!closeOnDateSelect}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isOutsideRange={(_day) => false}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={1}
        disabled={isDisabled}
        isDayBlocked={isDayBlocked}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}

export default DatePicker
