import StringHelper from "utils/StringHelper"
import Group from "features/Groups/GroupsModels"

import { SCENARIO_ID_DATA_ATTRIBUTE } from "features/Scenarios/ScenarioConstants"

import { DiffusionModeEnum, SendingModeEnum } from "core/CoreModels"

export enum Origin {
  ORIGIN_O365 = "O365",
  ORIGIN_GSUITE = "GSuite",
  ORIGIN_API = "Api",
  ORIGIN_PORTAL = "Portal",
  ORIGIN_LUCCA = "Lucca",
  ORIGIN_DYNAMIC = "Dynamic",
}

export interface UserDto {
  id: number
  username: string
  origin: Origin
  activeLicence: boolean
  properties: Array<UserPropertiesDto>
}

interface UserPropertiesDto {
  internalName: string
  displayName: string
  value: string
}

export interface UpdateUserPropertiesDto {
  id: number
  internalName: string
  requestSource: string
  origin: Origin
  isEditable: boolean
  isReadOnly: boolean
}

export interface CheckPropertiesDto {
  name: string
  requestSource: string
  origin: Origin
}

export interface SyncProperty {
  hashtag: string
  technicalName: string
  syncSource: Origin
  selfService: boolean
}

export interface UserPropertiesById {
  [key: string]: UserProperty
}

export enum UserFilter {
  GROUPS = "GROUPS",
  USERS = "USERS",
}

interface UserToJson {
  Id: number
  Username: string
  Displayname: string
}

class PartialProperties {
  lastname: string | null
  firstname: string | null
  mail: string

  constructor(properties: Array<UserPropertiesDto>) {
    this.lastname =
      properties.find((p) => p.internalName === "lastname")?.value || null
    this.firstname =
      properties.find((p) => p.internalName === "firstname")?.value || null
    this.mail = properties.find((p) => p.internalName === "mail")?.value
  }
}

export class PartialUser {
  id: number
  username: string
  activeLicence: boolean
  origin: Origin
  properties: PartialProperties
  preview?: string | null

  constructor(user: UserDto) {
    this.id = user.id
    this.username = user.username
    this.activeLicence = user.activeLicence
    this.origin = user.origin
    this.properties = new PartialProperties(user.properties)
  }
}

export interface PreviewTemplateDto {
  bannerId: number | null
  content: string
  contentResponse: string | null
  internExternMode: 0 | 1
  newMailInResponseMode: 2 | 3
  name: string
  signatureId: number | null
}

export class PreviewTemplate {
  id: number
  name: string
  template: string
  diffusionMode: DiffusionModeEnum
  sendingMode: SendingModeEnum
  bannerId: number | null
  signatureId: number | null
  bannerTemplate: string | null
  signatureTemplate: string | null
  relatedScenario: number | null
  weighting: number

  constructor(
    previewTemplateDto: PreviewTemplateDto,
    weighting: number,
    id: number,
  ) {
    this.id = id
    this.name = previewTemplateDto.name
    this.template = this.setAnchorToNewPage(previewTemplateDto.content)
    this.diffusionMode =
      previewTemplateDto.internExternMode === 0
        ? DiffusionModeEnum.INTERNAL
        : DiffusionModeEnum.EXTERNAL
    this.sendingMode =
      previewTemplateDto.newMailInResponseMode === 2
        ? SendingModeEnum.NEWMAIL
        : SendingModeEnum.INRESPONSE
    this.bannerId = previewTemplateDto.bannerId
    this.signatureId = previewTemplateDto.signatureId
    this.weighting = weighting

    const { bannerTemplate, signatureTemplate, relatedScenario } =
      this.splitElementsFromTemplate(
        previewTemplateDto.content,
        previewTemplateDto.bannerId,
      )

    this.bannerTemplate =
      bannerTemplate !== null ? this.setAnchorToNewPage(bannerTemplate) : null
    this.signatureTemplate =
      signatureTemplate !== null
        ? this.setAnchorToNewPage(signatureTemplate)
        : null
    this.relatedScenario = relatedScenario
  }

  private setAnchorToNewPage(htmlStr: string): string {
    return htmlStr.replaceAll("<a ", "<a target='_blank'")
  }

  private splitElementsFromTemplate(
    template: string,
    bannerId: number | null,
  ): {
    bannerTemplate: string | null
    signatureTemplate: string | null
    relatedScenario: number | null
  } {
    let bannerTemplate: string | null = null
    let relatedScenario: number | null = null

    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(template, "text/html")
    const bannersHtml = htmlDoc.querySelectorAll(
      `[${SCENARIO_ID_DATA_ATTRIBUTE}]`,
    )

    bannersHtml.forEach((banner: HTMLElement) => {
      if (
        bannerId !== null &&
        banner.dataset?.bannerId === bannerId.toString()
      ) {
        bannerTemplate = banner.outerHTML
        relatedScenario = parseInt(banner.dataset?.scenarioId) || null
      }
      banner.remove()
    })

    const signatureTemplate = htmlDoc.body.innerHTML || null

    return { bannerTemplate, signatureTemplate, relatedScenario }
  }
}

/**
 * Représente un Utilisateur BMM
 * @class User
 */

export class User {
  Id: number
  Username: string
  DisplayName: string
  Group: Record<string, unknown>
  Properties: UserProperties
  Origin: string
  ActiveLicence: boolean
  Preview?: string

  /**
   * Créer une instance d'utilisateur à partir d'un JSON provenant soit de l'AzureAD, via Graph, soit de la BDD BMM. Les deux sources ont des propriétés nommée différement, le paramètre From permet de faire le binding entre les deux pour n'avoir qu'un seul objet User
   * @memberof User
   * @param jsonObject
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(jsonObject) {
    for (const prop in jsonObject) {
      if (prop == "properties") continue
      this[StringHelper.Capitalize(prop)] = jsonObject[prop]
    }

    if (jsonObject.properties) {
      this.Properties = {}
      jsonObject.properties.forEach(
        (prop) => (this.Properties[prop.internalName] = prop.value),
      )
    }
  }

  public static getFullName(user: User): string {
    const firstname = user.Properties?.firstname
    const lastname = user.Properties?.lastname

    if (firstname || lastname) {
      return `${firstname ? firstname : ""}${firstname && lastname ? " " : ""}${
        lastname ? lastname : ""
      }`
    }

    return user.Username
  }

  public toJson(): UserToJson {
    return {
      Id: this.Id,
      Username: this.Username,
      Displayname: this.DisplayName,
    }
  }
}

export class UserProperty {
  Id: string
  DisplayName: string
  InternalName: string
  Visible: boolean
  Disabled: boolean
  Origin: string
  Activated: boolean
  RequestSource: string
  IsEditable: boolean
  IsReadOnly: boolean
  isUnknown: boolean

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(userPropertyJson) {
    for (const prop in userPropertyJson) {
      // eslint-disable-next-line no-prototype-builtins
      if (userPropertyJson.hasOwnProperty(prop)) {
        this[StringHelper.Capitalize(prop)] = userPropertyJson[prop]
      }
    }
    this.Visible = false
  }
}

interface DefaultFields {
  mail?: string
  company?: string
  firstname?: string
  lastname?: string
  jobtitle?: string
  department?: string
  phone?: string
  mobile?: string
  streetaddress?: string
  postalcode?: string
  city?: string
  country?: string
  website?: string
  usermessage?: string
  disclaimer?: string
  picture?: string
  origin?: string
}
export interface UserProperties extends DefaultFields {
  [name: string]: string
}

export class ImportReport {
  Error: { message: string; param: string }[]
  NewUsers: User[]
  UpdatedUsers: User[]
  DeletedUsers: User[]
  DisabledDueToExcessLicenses: User[]
  UsersFailed: { message: string; param: string }[]
  NewGroups: Group[]
  UpdatedGroups: Group[]
  DeletedGroups: Group[]
  NotUpdatedGroups: Group[]
  PartiallyUpdatedUsers: User[]
  WarningPropertiesUpdated: string
  PartialUsersFailed: { message: string; param: string }[]

  constructor(jsonObject) {
    this.Error = this.messagesFromJson(jsonObject["error"])
    this.NewUsers = jsonObject["newUsers"].map((userJson) => new User(userJson))
    this.UpdatedUsers = jsonObject["updatedUsers"].map(
      (userJson) => new User(userJson),
    )
    this.DeletedUsers = jsonObject["deletedUsers"].map(
      (userJson) => new User(userJson),
    )
    this.DisabledDueToExcessLicenses = jsonObject[
      "disabledDueToExcessLicenses"
    ].map((userJson) => new User(userJson))
    this.UsersFailed = this.messagesFromJson(jsonObject["usersFailed"])

    this.NewGroups = jsonObject["newGroups"].map(
      (groupJson) => new Group(groupJson),
    )
    this.UpdatedGroups = jsonObject["updatedGroups"].map(
      (groupJson) => new Group(groupJson),
    )
    this.DeletedGroups = jsonObject["deletedGroups"].map(
      (groupJson) => new Group(groupJson),
    )
    this.NotUpdatedGroups = jsonObject["notUpdatedGroups"].map(
      (groupJson) => new Group(groupJson),
    )
    this.PartiallyUpdatedUsers = jsonObject["partiallyUpdatedUsers"].map(
      (userJson) => new User(userJson),
    )
    this.WarningPropertiesUpdated = jsonObject["warningPropertiesUpdated"]
    this.PartialUsersFailed = jsonObject["partialUsersFailed"].map(
      (failJson) => this.messagesFromJson(failJson)[0],
    )
  }

  private messagesFromJson(object) {
    const messages = []
    if (Object.keys(object).length > 0) {
      for (const prop in object) {
        // La regex supprime le paramètre entre parenthèses du message
        messages.push({
          message: prop.replace(/( \(.*)/, ""),
          param: object[prop],
        })
      }
    }
    return messages
  }

  public getStatus(): "error" | "warning" | "ok" {
    if (this.Error.length > 0) {
      return "error"
    } else if (
      this.UsersFailed.length > 0 ||
      this.PartialUsersFailed.length > 0 ||
      this.NotUpdatedGroups.length > 0
    ) {
      return "warning"
    } else {
      return "ok"
    }
  }
}
