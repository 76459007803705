import { useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import moment from "moment"
import classNames from "classnames"

import { Link } from "react-router-dom"

import ConfirmModal from "components/ConfirmModal"

import { ReactComponent as IconUser } from "assets/icon-user.svg"
import { ReactComponent as IconDelete } from "assets/icon-delete.svg"
import { ReactComponent as IconEdit } from "assets/icon-edit.svg"

import { Subsidiary } from "features/Subsidiaries/SubsidiaryModels"
import { deleteSubsidiary } from "features/Subsidiaries/SubsidiariesReducer"

import useAdminsAccountsData from "features/Accounts/hooks/useAdminsAccountsData"
import { usersSelectors } from "features/Users"

import { iconGroup } from "features/Scenarios/images"

import { SUBSIDIARIES_URL } from "router/RouterConstants"

import { LoadingStatus } from "core/CoreModels"

interface Props {
  subsidiary: Subsidiary
  usersLoadingStatus: LoadingStatus
}

const messages = defineMessages({
  subsidiaryDeleteTitle: {
    id: "SubsidiariesHome.DeleteSubsidiaries",
    defaultMessage: "Suppression d'une filiale",
  },
})

const SubsidiaryCard: React.FC<Props> = ({
  subsidiary,
  usersLoadingStatus,
}) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const allUsersWithLicenses = useSelector(
    usersSelectors.getAllUsersWithLicences,
  )
  const dispatch = useDispatch()
  const intl = useIntl()

  const onDelete = (subsidiary) => {
    dispatch(deleteSubsidiary(subsidiary.id))
  }

  const { adminAccounts } = useAdminsAccountsData()

  const adminsNamesSubsidiary = adminAccounts
    .filter((user) => subsidiary.aspNetUserIds.includes(user.id))
    .map((user) => user.userName)

  const userIdsWithoutLicenses = subsidiary.userIds.filter((u) =>
    allUsersWithLicenses.map((user) => user.Id).includes(u),
  )

  return (
    <div className="SubsidiaryCard">
      <ConfirmModal
        title={intl.formatMessage(messages.subsidiaryDeleteTitle)}
        message={`Vous allez supprimer la filiale ${subsidiary.name} , cette action sera irréversible, êtes-vous sûr de vouloir la supprimer ?`}
        action={() => onDelete(subsidiary)}
        handleClose={() => setOpenConfirm(false)}
        open={openConfirm}
      />
      <div className="SubsidiaryCard__image">
        <img src={subsidiary.iconUrl} alt="" />
      </div>
      <div className="SubsidiaryCard__details">
        <div className="SubsidiaryCard__infos">
          <div className="SubsidiaryCard__users">
            {/* en attendant les endpoints qui fourniront les données*/}
            {subsidiary.groupIds.length > 0 && (
              <div
                className={classNames({
                  "CampaignCard__users--withBorder":
                    subsidiary.userIds.length > 0,
                })}
              >
                <img alt="groups" src={iconGroup} />
                <div>{subsidiary.groupIds.length}</div>
              </div>
            )}
            {userIdsWithoutLicenses.length > 0 && (
              <div className="subsidiary-card__nb-users">
                <IconUser className="subsidiary-card__nb-users--icon" />
                {usersLoadingStatus !== LoadingStatus.LOADED ? (
                  <div className="subsidiary-card__nb-users--skeleton" />
                ) : (
                  <p className="subsidiary-card__nb-users--count">
                    {userIdsWithoutLicenses.length}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="SubsidiaryCard__name">{subsidiary.name}</div>
          <div className="SubsidiaryCard__address">
            {/*subsidiary.address*/}
          </div>
          <div className="SubsidiaryCard__phone">{/*subsidiary.phone*/}</div>
          <div className="SubsidiaryCard__website">
            {/*subsidiary.website*/}
          </div>
        </div>
        <div className="SubsidiaryCard__footer">
          <div className="SubsidiaryCard__admins">
            {adminsNamesSubsidiary?.length > 0 && (
              <div className="SubsidiaryCard__admins-pin">F</div>
            )}
            {adminsNamesSubsidiary.map((admin, index) => (
              <div className="SubsidiaryCard__admins-name">
                {admin}
                {index < adminsNamesSubsidiary.length - 1 && ", "}
              </div>
            ))}
          </div>
          <div className="SubsidiaryCard__date">
            <FormattedMessage
              id="SubsidiaryCard.DateMessage"
              defaultMessage="Créé le"
            />
            &nbsp;
            {moment(subsidiary.created).format(
              moment.locale() === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY",
            )}
          </div>
        </div>
      </div>
      <div className="SubsidiaryCard__actions">
        <div className="SubsidiaryCard__button">
          <Link
            className="SubsidiaryCard__button--edit"
            to={`${SUBSIDIARIES_URL}/edit/${subsidiary.id}`}
          >
            <IconEdit />
          </Link>
        </div>
        <div className="SubsidiaryCard__button">
          <a
            className="SubsidiaryCard__button--delete"
            onClick={() => setOpenConfirm(true)}
          >
            <IconDelete />
          </a>
        </div>
      </div>
    </div>
  )
}

export default SubsidiaryCard
