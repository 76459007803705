import { FunctionComponent, ReactNode } from "react"

import Carousel from "react-material-ui-carousel"

interface EnrollmentCarouselProps {
  children: ReactNode
}

const EnrollmentCarousel: FunctionComponent<EnrollmentCarouselProps> = ({
  children,
}) => {
  return (
    <Carousel
      IndicatorIcon={false}
      navButtonsWrapperProps={{
        className: "bmm-carousel__buttons",
        style: {
          display: "none",
        },
      }}
      navButtonsProps={{
        className: "bmm-carousel__button",
        style: {
          backgroundColor: "white",
          color: "#3D5AFE",
          border: "solid rgb(61, 90, 254) 1px",
        },
      }}
    >
      {children}
    </Carousel>
  )
}

export default EnrollmentCarousel
