import React, { FunctionComponent } from "react"

const LuccaIcon: FunctionComponent = () => {
  return (
    <svg
      width="18"
      height="28"
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5462 8.7395C17.5462 3.89916 13.5798 0 8.7395 0C3.89916 0 0 3.96639 0 8.80672C0 12.5042 2.35294 15.7983 5.78151 17.0756C3.09244 18.6891 2.21849 22.1849 3.89916 24.8739C4.36975 25.6807 5.10924 26.4202 5.91597 26.8908C6.58824 27.2269 7.39496 26.958 7.73109 26.3529C8.06723 25.7479 7.86555 24.9412 7.2605 24.605C5.78151 23.7311 5.31092 21.916 6.11765 20.437C6.92437 18.958 8.80672 18.4874 10.2857 19.2941C11.7647 20.1681 12.2353 21.9832 11.4286 23.4622C11.1597 23.9328 10.7563 24.3361 10.2857 24.605C9.68067 25.0084 9.47899 25.8151 9.88235 26.4874C10.2857 27.0924 11.0252 27.2941 11.6303 26.958C14.3866 25.3445 15.3277 21.8487 13.7143 19.1597C13.2437 18.3529 12.5714 17.6134 11.6975 17.1429C15.2605 15.7983 17.5462 12.437 17.5462 8.7395ZM11.6303 13.7143C10.958 14.0504 10.1513 13.8487 9.81513 13.2437C9.47899 12.6387 9.68067 11.8319 10.2857 11.4958C11.7647 10.6218 12.2353 8.80672 11.4286 7.32773C10.5546 5.84874 8.7395 5.37815 7.2605 6.18487C5.78151 7.05882 5.31092 8.87395 6.11765 10.3529C6.38656 10.8235 6.78992 11.2269 7.2605 11.4958C7.93277 11.8319 8.20168 12.6387 7.86555 13.3109C7.52941 13.9832 6.72269 14.2521 6.05042 13.916C5.98319 13.916 5.91597 13.8487 5.84874 13.7815C3.09244 12.1681 2.15126 8.67227 3.76471 5.91597C5.37815 3.15966 8.87395 2.21849 11.6303 3.83193C14.3866 5.44538 15.3277 8.94118 13.7143 11.6975C13.2437 12.5042 12.5042 13.2437 11.6303 13.7143Z"
        fill="url(#paint0_radial_6508_59433)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6508_59433"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.06101 9.21461) scale(12.1986 12.1986)"
        >
          <stop offset="0.05" stop-color="#F99B1C" />
          <stop offset="1" stop-color="#FEBC11" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default LuccaIcon
