import {
  ButtonHTMLAttributes,
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react"

import clsx from "clsx"

import { useSearchParams } from "react-router-dom"

interface SubHeaderTabProps {
  children: ReactNode
  tabName: string
}

export type SubHeaderTabType = FunctionComponent<
  SubHeaderTabProps & ButtonHTMLAttributes<HTMLButtonElement>
>

const SubHeaderTab: SubHeaderTabType = ({ children, tabName, ...others }) => {
  const [isActive, setIsActive] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const tabValue = searchParams.get("tab")
    if (tabValue && tabValue === tabName) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [searchParams])

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    const { onClick } = others
    searchParams.set("tab", tabName)
    setSearchParams(searchParams)
    onClick && onClick(e)
  }

  return (
    <button
      className={clsx(
        others.className,
        "sub-header-tab",
        isActive && "is-active",
      )}
      type={others.type || "button"}
      {...others}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export default SubHeaderTab
