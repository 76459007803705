import config from "config/config"

import Signature from "entities/Signature"
import { ScenarioMode, ScenarioVm } from "features/Scenarios/ScenarioModels"
import { User } from "features/Users/UserModels"
import SharedTrackingMapper from "./mappers/SharedTrackingMapper"

const DEFAULT_IMAGE_URL = `${config.cdnUrl}/images/bmm/users/ic_default_avatar.png`

import moment from "moment"
import { LoadingStatus } from "core/CoreModels"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"
import { isBuilderSignature } from "features/BuilderSignatures/BuilderSignaturesHelpers"
import { SIGNATURES_DEPLOYMENT_STATUS_RANGE } from "./TrackingRules"

export type SharedTrackingHistory = Array<
  ScenarioTrackingHistory[] | SignatureTrackingHistory[]
>

export interface BaseHistoryEvent {
  date: string
  quantity: number
}

export interface AffectedUsersHistory {
  date: string
  affectedUsers: number
}

export interface UserFromHistory {
  userId: number
  userName: string
  firstName: string
  lastName: string
  delivered: boolean
}

export interface SignatureDeploymentStatus {
  status: "error" | "warning" | "success"
  value: number
  id: number
}

export class SignatureDeployment {
  id: number
  name: string
  template: string
  users: UserFromHistory[]
  affectedUsers: number
  deployedUsers: number
  history: BaseHistoryEvent[]
  status: SignatureDeploymentStatus
  isBuilder: boolean

  constructor(
    signature: Signature | BuilderSignature,
    users: UserFromHistory[],
    history: BaseHistoryEvent[],
  ) {
    const deployedUsers = users.filter((u) => u.delivered === true)

    this.history = history
    this.affectedUsers = users.length
    this.deployedUsers = deployedUsers.length
    this.users = users
    this.status = SignatureDeployment.setDeploymentStatus(
      deployedUsers.length,
      users.length,
    )

    if (isBuilderSignature(signature)) {
      this.id = signature.id
      this.name = signature.name
      this.template = signature.json
      this.isBuilder = true
      return
    }

    this.id = signature.Id
    this.name = signature.Name
    this.template = signature.Template.GetTemplateWithoutBanner()
    this.isBuilder = false
  }

  static setDeploymentStatus(
    deployed: number,
    affected: number,
  ): SignatureDeploymentStatus {
    let percent = 100
    if (affected > 0) percent = Math.ceil((deployed * 100) / affected)

    if (percent < SIGNATURES_DEPLOYMENT_STATUS_RANGE.error)
      return { status: "error", value: percent, id: 0 }
    if (percent < SIGNATURES_DEPLOYMENT_STATUS_RANGE.warning)
      return { status: "warning", value: percent, id: 1 }
    return { status: "success", value: affected === 0 ? 0 : percent, id: 2 }
  }
}

export interface SignaturePowerUserHistory {
  userId: number
  signatureId: number
  history: BaseHistoryEvent[]
  nbClicks: number
}

export class SignaturesPowerUser {
  id: number
  fullName: string
  avatar: string
  signatureTemplate: string
  history: BaseHistoryEvent[]
  nbClicks: number
  signatureId: number

  constructor(
    user: User,
    signature: Signature,
    signaturePowerUserHistory: SignaturePowerUserHistory,
  ) {
    this.id = user.Id
    this.fullName = User.getFullName(user)
    this.avatar = user.Properties.picture || DEFAULT_IMAGE_URL
    this.signatureTemplate = signature.Template.GetTemplateWithoutBanner()
    this.history = SharedTrackingMapper.mergeHistoryEventsByDate(
      signaturePowerUserHistory.history,
    )
    this.nbClicks = signaturePowerUserHistory.nbClicks
    this.signatureId = signature.Id
  }
}

export interface SignatureTrackingHistoryData {
  [key: string]: {
    label: string
    value: string
    quantity: number
  }
}

export interface SignatureTrackingHistory {
  date: string
  data: SignatureTrackingHistoryData
}

export class SignatureTracking {
  id: number
  name: string
  template: string
  created: string
  maxAffectedUsers: number
  maxAffectedUsersDate: string
  maxClicks: number
  maxClicksDate: string
  totalClicks: number
  history: SignatureTrackingHistory[]
  isBuilder: boolean

  constructor(
    signature: Signature | BuilderSignature,
    maxAffectedUsers: { date: string; affectedUsers: number },
    maxClicks: BaseHistoryEvent,
    totalClicks: number,
    history: SignatureTrackingHistory[],
  ) {
    this.maxAffectedUsers = maxAffectedUsers.affectedUsers
    this.maxAffectedUsersDate = maxAffectedUsers.date
    this.maxClicks = maxClicks.quantity
    this.maxClicksDate = maxClicks.date
    this.totalClicks = totalClicks
    this.history = history

    if (isBuilderSignature(signature)) {
      this.id = signature.id
      this.name = signature.name
      this.template = signature.json
      this.created = signature.created as unknown as string
      this.isBuilder = true
      return
    }

    this.id = signature.Id
    this.name = signature.Name
    this.template = signature.Template.GetTemplateWithoutBanner()
    this.created = signature.Created as string
    this.isBuilder = false
  }
}

export interface TrackingGraphData {
  id: string | number
  label: string
  thumbnailUrl?: string
  color: string
  secondaryColor: string
  display: boolean
  hover: boolean
  selected: boolean
  data: {
    x: string
    y: number
  }[]
}

export class ScenariosPowerUser {
  id: number
  fullName: string
  avatar: string
  history: BaseHistoryEvent[]
  totalClicks: number

  constructor(user: User, history: BaseHistoryEvent[]) {
    this.id = user.Id
    this.fullName = User.getFullName(user)
    this.avatar = user.Properties.picture || DEFAULT_IMAGE_URL
    this.history = history
    this.totalClicks = history.reduce((acc, curr) => acc + curr.quantity, 0)
  }
}

export interface ScenarioTrackingHistoryData {
  [key: number]: {
    id: number
    quantity: number
  }
}

export interface ScenarioTrackingHistory {
  date: string
  data: ScenarioTrackingHistoryData
}

interface ScenarioTrackingBanners {
  id: number
  imageUrl: string
  order: number
}

export class ScenarioTracking {
  id: number
  name: string
  created: string
  banners: ScenarioTrackingBanners[]
  internal: boolean
  external: boolean
  affectedUsersHistory: AffectedUsersHistory[]
  history: ScenarioTrackingHistory[]
  scenarioMode: ScenarioMode
  startDate: string | null
  endDate: string | null
  isDefault: boolean
  loadingStatus: LoadingStatus

  constructor(
    scenario: ScenarioVm,
    affectedUsersHistory: AffectedUsersHistory[],
    history: ScenarioTrackingHistory[],
    loadingStatus = LoadingStatus.NOT_STARTED,
  ) {
    this.id = scenario.id
    this.name = scenario.name
    this.created = moment(scenario.created).toISOString()
    this.banners = scenario.banners
    this.internal = scenario.internal
    this.external = scenario.external
    this.affectedUsersHistory = affectedUsersHistory
    this.history = history
    this.scenarioMode = scenario.banners.length > 1 ? "multiple" : "simple"
    this.startDate = moment(scenario.startDate).format("YYYY-MM-DD")
    this.endDate = moment(scenario.endDate).format("YYYY-MM-DD")
    this.isDefault = scenario.isDefault()
    this.loadingStatus = scenario.isDefault()
      ? LoadingStatus.LOADED
      : loadingStatus
  }
}

export interface SignaturesDeploymentTrackingDatasVOneDto {
  userEmail: string
  deploymentMethod: string
  signatureIds: Array<number>
  bannerIds: Array<number>
}

export interface EventClickedDto {
  tenantId: number
  clickedDate: string
  nbClicks: number
  userId: number
  link: string | null
}

export interface EventHistoryDto {
  tenantId: number
  created: string
  nbAssignedUsers: number
  nbDeliveredUsers: number
}

export interface BannersClickedDto extends EventClickedDto {
  bannerId: number
}

export interface BannersHistoryDto extends EventHistoryDto {
  scenarioId: number
  scenarioName: string
}

export interface SignaturesClickedDto extends EventClickedDto {
  signatureId: number
}

export interface SignaturesHistoryDto extends EventHistoryDto {
  signatureId: number
  users: UserFromHistory[]
}

export interface QueriesDateRange {
  startDate: string
  endDate: string
}
