import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import PillButtonsGroup from "components/PillButtonsGroup"

import { signaturesSelectors } from "features/Signatures"
import { setEditingSignatureSendingMode } from "features/Signatures/SignaturesReducer"

const messages = defineMessages({
  newMail: {
    id: "SignatureEditor.NewMail",
    defaultMessage: "Nouveau mail",
  },
  inResponse: {
    id: "SignatureEditor.InResponse",
    defaultMessage: "En réponse",
  },
  twice: {
    id: "SignatureEditor.Twice",
    defaultMessage: "Les deux",
  },
})

function getCurrentSendingMode(newMail: boolean, inResponse: boolean) {
  if (newMail && inResponse) return "all"
  if (newMail && !inResponse) return "newMail"
  if (!newMail && inResponse) return "inResponse"
}

const SendingModesSelectors: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const newMailValue = useSelector(
    signaturesSelectors.getEditingSignatureNewMailValue,
  )
  const inResponseValue = useSelector(
    signaturesSelectors.getEditingSignatureInResponseValue,
  )

  const pillButtons = [
    {
      id: "newMail",
      children: <>{formatMessage(messages.newMail)}</>,
    },
    { id: "all", children: <>{formatMessage(messages.twice)}</> },
    { id: "inResponse", children: <>{formatMessage(messages.inResponse)}</> },
  ]

  const events = {
    newMail: setEditingSignatureSendingMode({
      newMail: true,
      inResponse: false,
    }),
    inResponse: setEditingSignatureSendingMode({
      newMail: false,
      inResponse: true,
    }),
    all: setEditingSignatureSendingMode({ newMail: true, inResponse: true }),
  }

  return (
    <div className="sending-modes-selectors">
      <PillButtonsGroup
        current={getCurrentSendingMode(newMailValue, inResponseValue)}
        onChange={(newSendingMode) => dispatch(events[newSendingMode])}
        pillButtons={pillButtons}
        hasSeparator
      />
    </div>
  )
}

export default SendingModesSelectors
