import {
  CONFIRM_USER_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  REGISTRATION_URL,
  RESET_PASSWORD_URL,
} from "router/RouterConstants"

import Login from "pages/public/LoginPage"
import RegisterPage from "pages/public/RegisterPage"
import ConfirmUser from "pages/public/ConfirmUserPage"
import ResetPassword from "pages/public/ResetPasswordPage"
import ForgotPassword from "pages/public/ForgotPasswordPage"

const publicRoutes = [
  { key: "login", exact: true, path: LOGIN_URL, element: <Login /> },
  {
    key: "registration",
    path: REGISTRATION_URL,
    element: <RegisterPage />,
  },
  {
    key: "confirmUser",
    path: CONFIRM_USER_URL,
    element: <ConfirmUser />,
  },
  {
    key: "resetPassword",
    path: RESET_PASSWORD_URL,
    element: <ResetPassword />,
  },
  {
    key: "forgotPassword",
    path: FORGOT_PASSWORD_URL,
    element: <ForgotPassword />,
  },
]

export default publicRoutes
