import { FunctionComponent, useEffect, useMemo, useState } from "react"

import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as PreviewIcon } from "assets/icon-preview.svg"

import TrackingDataBlock from "features/Tracking/components/TrackingDataBlock"
import Podium from "features/Tracking/components/Podium"

import ScenariosPowerUsersGraph from "./components/ScenariosPowerUsersGraph"
import ScenariosPowerUsersInsufficientsDatas from "./components/ScenariosPowerUsersInsufficientsDatas"
import ScenariosPowerUsersLoading from "./components/ScenariosPowerUsersLoading"
import ScenariosPowerUsersGraphInsufficientsDatas from "./components/ScenariosPowerUsersGraphInsufficientsDatas"

import { trackingSelectors } from "features/Tracking"

import { ScenariosPowerUser } from "features/Tracking/TrackingModels"
import { LoadingStatus } from "core/CoreModels"

import { MINIMUM_GRAPH_DATA_LENGTH } from "features/Tracking/TrackingConstants"

import { GlobalStates } from "store"

const messages = defineMessages({
  title: {
    id: "Tracking.ScenariosPowerUsers",
    defaultMessage: "Top Diffuseurs",
  },
})

function buildPodiumItemFromPowerUser(powerUser: ScenariosPowerUser) {
  return {
    id: powerUser.id,
    visual: powerUser.avatar,
    name: powerUser.fullName,
  }
}

const ScenariosPowerUsers: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [selectedPowerUser, setSelectedPowerUser] = useState(null)

  const loadingStatus = useSelector<GlobalStates, LoadingStatus>(
    trackingSelectors.getScenariosPowerUsersLoadingStatus,
  )
  const allScenariosPowerUsers = useSelector(
    trackingSelectors.getScenariosPowerUsersSortByTotalClicks,
  )

  const handleSelectOnPodium = (powerUser) => {
    setSelectedPowerUser(powerUser)
  }

  const powerUsers = useMemo(
    () =>
      allScenariosPowerUsers.map((powerUser) => ({
        ...buildPodiumItemFromPowerUser(powerUser),
        handleSelect: () => handleSelectOnPodium(powerUser),
        actions: [{ id: "0", icon: <PreviewIcon /> }],
      })),
    [allScenariosPowerUsers],
  )

  const sufficientData = useMemo(() => {
    if (allScenariosPowerUsers.length === 0) return false

    return true
  }, [allScenariosPowerUsers])

  useEffect(() => {
    if (selectedPowerUser && allScenariosPowerUsers.length > 0) {
      const updated = allScenariosPowerUsers
        .slice(0, 3)
        .find((s) => s.id === selectedPowerUser.id)

      setSelectedPowerUser(
        updated !== undefined ? updated : allScenariosPowerUsers[0],
      )
    }
  }, [selectedPowerUser, allScenariosPowerUsers])

  useEffect(() => {
    if (!selectedPowerUser && allScenariosPowerUsers.length > 0)
      setSelectedPowerUser(allScenariosPowerUsers[0])
  }, [selectedPowerUser, allScenariosPowerUsers])

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <TrackingDataBlock title={formatMessage(messages.title)}>
        <ScenariosPowerUsersLoading />
      </TrackingDataBlock>
    )

  return (
    <TrackingDataBlock title={formatMessage(messages.title)}>
      <div className="scen-power-users">
        {sufficientData ? (
          <>
            <div className="scen-power-users__side">
              {loadingStatus === LoadingStatus.LOADED && (
                <Podium
                  gold={powerUsers[0]}
                  silver={powerUsers[1]}
                  bronze={powerUsers[2]}
                  selected={selectedPowerUser?.id}
                />
              )}
            </div>
            <div className="scen-power-users__side">
              {selectedPowerUser ? (
                selectedPowerUser.history.length >=
                MINIMUM_GRAPH_DATA_LENGTH ? (
                  <ScenariosPowerUsersGraph data={selectedPowerUser.history} />
                ) : (
                  <ScenariosPowerUsersGraphInsufficientsDatas
                    totalClicks={selectedPowerUser.totalClicks}
                  />
                )
              ) : null}
            </div>
          </>
        ) : (
          <ScenariosPowerUsersInsufficientsDatas />
        )}
      </div>
    </TrackingDataBlock>
  )
}

export default ScenariosPowerUsers
