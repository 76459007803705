/**
 * Represent a Signature instance
 *
 * @class Signature
 */
import moment, { Moment } from "moment"
import StringHelper from "../utils/StringHelper"
import DateHelper from "../utils/DateHelper"
import { User } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import Template from "./Template" // Si on ajoute la déclaration directement depuis '../entities', cela ne fonctionne pas ... dépendance cyclique peut-etre?
import { LoadingStatus } from "core/CoreModels"

export class Signature {
  Id: number
  Name: string
  Description: string
  Template: Template
  StartDate: string
  EndDate: string
  Created: Moment | string
  Modified: Moment | string
  CreatedById: number
  ModifiedById: number
  AffectedUsers: User[]
  AffectedGroups: Group[]
  AffectedUsersIds: number[]
  AffectedGroupsIds: number[]
  Activated = false
  ActivatedDate: Moment
  AffectedUsersLoaded: LoadingStatus
  AffectedGroupsLoaded: LoadingStatus
  // Champ temporaire pour notifier que cette signature est affectée à une bannière
  Affected?: boolean
  BannerId: number[]
  AffectedUsersCount: number[]
  AffectUsersCountLoadingStatus: LoadingStatus
  NewMail: boolean
  InResponse: boolean
  Weighting: number
  isNewBuilder: boolean

  constructor(jsonObject) {
    // Init the created date because it's a mandatory field
    this.Created = DateHelper.ConvertToSQLDate(new Date())
    this.Modified = DateHelper.ConvertToSQLDate(new Date())
    this.AffectedUsers = []
    this.AffectedGroups = []
    this.AffectedUsersIds = []
    this.AffectedGroupsIds = []
    this.AffectedUsersCount = []
    this.AffectedUsersLoaded = LoadingStatus.NOT_STARTED
    this.AffectedGroupsLoaded = LoadingStatus.NOT_STARTED
    this.AffectUsersCountLoadingStatus = LoadingStatus.NOT_STARTED
    this.isNewBuilder = false

    for (const prop in jsonObject) {
      if (jsonObject.hasOwnProperty(prop)) {
        this[StringHelper.Capitalize(prop)] = jsonObject[prop]
      }
    }

    // initialisation du rawContent du template
    if (jsonObject.hasOwnProperty("template")) {
      this.Template = new Template({})

      this.Template.rawContent = jsonObject.template
      this.Template.rawLightContent = jsonObject.templateLight
      this.Template.rawRawContent = jsonObject.templateRaw

      this.Template.compiledContent = jsonObject.template
      this.Template.sampleUserProps = null
      this.Template.DecompileTemplateId()
      this.Template.DecompileColor()
    } else if (this.Template == null)
      this.Template = new Template({ template: "" })

    this.ActivatedDate = moment(this.ActivatedDate)
    this.Created = moment(this.Created)
    this.Modified = moment(this.Modified)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static toJson(signature: Signature) {
    const signatureJson = {}
    for (const prop in signature) {
      signatureJson[StringHelper.Capitalize(prop)] = signature[prop]
    }

    // Trim des espaces dans le nom
    signatureJson["Name"] = signatureJson["Name"]?.trim()

    const templates = signature.Template.GetRecordableTemplates()
    signatureJson["Template"] = templates.template

    // TODO - proposer la modification des templates Light et Raw via l'interface
    // signatureJson["TemplateLight"] = templates.templateLight;
    // signatureJson["TemplateRaw"] = templates.templateRaw;

    return signatureJson
  }
}

export default Signature
