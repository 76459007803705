import { ModalService } from "components/Modal"

import { useDispatch } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import ToggleSubsidiaryModal from "../components/ToggleSubsidiaryModal"

import { removeAdminAccountToSubsidiariesAction } from "features/Accounts/AccountsActions"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"

const messages = defineMessages({
  modalTitle: {
    id: "SubsidiariesAdminsGrid.ToggleSubsidiaryModalTitle",
    defaultMessage: "Sortir un administrateur du mode filiale",
  },
})

function useToggleSubsidiary(adminAccount: DataGridAdminAccount) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleRemove = () => {
    dispatch(removeAdminAccountToSubsidiariesAction(adminAccount.id))

    ModalService.close()
  }

  const handleCancel = () => {
    ModalService.close()
  }

  const handleDisplayModal = () => {
    ModalService.open({
      title: formatMessage(messages.modalTitle),
      content: (
        <ToggleSubsidiaryModal
          adminAccount={adminAccount}
          onDelete={handleRemove}
          onCancel={handleCancel}
        />
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCancel: async (_e) => handleCancel(),
    })
  }

  return { handleDisplayModal }
}

export default useToggleSubsidiary
