import { FunctionComponent, ReactNode } from "react"

interface LoginSliderCardProps {
  children: ReactNode
  title: string
  subtitle: string
}

const LoginSliderCard: FunctionComponent<LoginSliderCardProps> = ({
  children,
  title,
  subtitle,
}) => {
  return (
    <div className="login-slider__card">
      <div className="login-slider__card--icon-container">
        <div className="login-slider__card--icon">{children}</div>
      </div>
      <p className="login-slider__card--title">{title}</p>
      <p className="login-slider__card--subtitle">{subtitle}</p>
    </div>
  )
}

export default LoginSliderCard
