/**
 * Wrapper qui centralise la gestion des erreurs API
 */
import { createAction } from "@reduxjs/toolkit"
import { ErrorLog, ErrorTarget } from "./ErrorsModel"

export type WithError = { error: Error }

// Permet de notifier le front,  le back ou les deux d'une erreur
export const error = createAction<ErrorLog>("error/unexpected")

// Trigger by Api base service
export const apiError = createAction(
  "error/api",
  function prepare(message, stack) {
    return {
      payload: {
        message,
        stack,
      },
    }
  },
)

// Trigger by Saga Handler Error
export const sagaError = createAction(
  "error/saga",
  function prepare(
    message: string,
    stack,
    target?: ErrorTarget,
    feature?: string,
  ) {
    return {
      payload: {
        message,
        stack,
        target,
        feature,
      },
    }
  },
)

// Trigger by Error Boundaries
export const reactError = createAction<WithError>("error/react")
