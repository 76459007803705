import React from "react"
import { defineMessages, injectIntl, IntlShape } from "react-intl"
import ListGeneric from "components/ListGeneric"
import ImageButton from "components/ImageButton"
import TagSearchBar from "components/TagSearchBar"

interface Props {
  intl: IntlShape
  data: any[]
  initSelectedData?: any[]
  onSelectedChange?(selectedData: any[])
  avatarSrc?: string
}

const messages = defineMessages({
  btnSelectAll: {
    id: "ListPicker.BtnSelectAll",
    defaultMessage: "Tout sélectionner",
  },
  btnRemoveAll: {
    id: "ListPicker.BtnRemoveAll",
    defaultMessage: "Tout désélectionner",
  },
})

const ListPicker: React.FC<Props> = (props) => {
  const { intl, data, initSelectedData, onSelectedChange } = props
  const [filteredData, setFilteredData] = React.useState(data)
  const [selectedData, setSelectedData] = React.useState(initSelectedData || [])
  const [isAllSelected, setIsAllSelected] = React.useState(false)

  React.useEffect(() => {
    if (onSelectedChange) onSelectedChange(selectedData)
  }, [selectedData])

  React.useEffect(() => {
    setIsAllSelected(selectedData.length === data.length)
  }, [data, selectedData])

  const handleItemClick = (item) => {
    const isSelected = !!selectedData.find((e) => e.id === item.id)
    if (isSelected) {
      setSelectedData(selectedData.filter((e) => e.id !== item.id))
    } else {
      setSelectedData([...selectedData, item])
    }
  }

  const handleSearch = (input: string) => {
    const search = input.toLowerCase()
    setFilteredData(
      data.filter((item) => item.label.toLowerCase().includes(search)),
    )
  }

  return (
    <div className="list-picker">
      <TagSearchBar
        tags={selectedData}
        onTagClick={(item) => handleItemClick(item)}
        onSearchChange={(value) => handleSearch(value)}
        placeholder="Rechercher"
        displayNumber={8}
      />

      <div className="list-picker__select">
        {isAllSelected ? (
          <ImageButton
            image={<i className="material-icons">remove_circle</i>}
            label={intl.formatMessage(messages.btnRemoveAll)}
            onClick={() => setSelectedData([])}
          />
        ) : (
          <ImageButton
            image={<i className="material-icons">add_circle</i>}
            label={intl.formatMessage(messages.btnSelectAll)}
            onClick={() => setSelectedData(data)}
          />
        )}
      </div>

      <ListGeneric
        data={filteredData}
        styleOptions={{ width: "100%", maxHeight: "30vh", overflow: "auto" }}
        grouped
        selectedData={selectedData}
        onClick={(item) => handleItemClick(item)}
        avatarSrc={props.avatarSrc}
      />
    </div>
  )
}

export default injectIntl(ListPicker)
