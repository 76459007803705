export enum featureNames {
  LIMITATIONS = "limitations",
  SCENARIO_BY_SIGNATURES = "scenario-by-signatures",
  NEW_BUILDER = "new-builder",
  SELF_SERVICE_PROPERTIES = "self-service-properties",
  INTERNE_EXTERNE = "interne-externe",
  SUBSIDIARIES = "filiales",
  STATISTICS_V2 = "stats-v2",
  DYNAMIC_GROUPS = "dynamic-groups",
  SALESFORCE = "salesforce",
  SIGNATURES_INTERNE_EXTERNE = "Signatures-InterneExterne",
  NEWMAIL_INRESPONSE = "newmail-inresponse",
  EMAIL_USERNAME = "dissociation-email-username",
  ADDITIONAL_BANNER = "additional-banner",
  OWA = "owa",
}
