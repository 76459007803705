import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

interface ModalMainActionButtonProps {
  children: ReactNode
  color?: "is-primary" | "is-danger"
  className?: string
  type?: "button" | "reset" | "submit"
}

export type ModalMainActionButtonType = FunctionComponent<
  ModalMainActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>

const ModalMainActionButton: ModalMainActionButtonType = ({
  children,
  color = "is-primary",
  className,
  type = "button",
  ...others
}) => {
  return (
    <button
      className={classNames("modal-service__actions--main", color, className)}
      type={type}
      {...others}
    >
      {children}
    </button>
  )
}

export default ModalMainActionButton
