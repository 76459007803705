import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import ConnectedSignatureReadOnlyView from "../../SignatureReadOnlyView"

import StringHelper from "utils/StringHelper"
import Card, { CardHeader } from "components/Card"
import { useDispatch, useSelector } from "react-redux"
import { signaturesSelectors } from "features/Signatures"

import { updateSignatureName as updateSignatureNameAction } from "features/Signatures/SignaturesReducer"
import { updateSignatureAction } from "features/Signatures/SignaturesActions"

interface SignaturesPropertiesViewProps {
  onSignatureSaved?: () => void

  nextNavLink?: string
  prevNavLink?: string
  prev?: (evt, datas?) => void
  next?: (evt, datas?) => void
}

const SignaturesPropertiesView: FunctionComponent<
  SignaturesPropertiesViewProps
> = ({ onSignatureSaved, nextNavLink, prevNavLink, prev, next }) => {
  const dispatch = useDispatch()
  const signature = useSelector(signaturesSelectors.getActiveSignature)
  const signatureName = useSelector(signaturesSelectors.getSignatureName)
  const newMail = useSelector(
    signaturesSelectors.getEditingSignatureNewMailValue,
  )
  const inResponse = useSelector(
    signaturesSelectors.getEditingSignatureInResponseValue,
  )
  const weighting = useSelector(
    signaturesSelectors.getEditingSignatureWeightingValue,
  )

  const updateSignatureName = (signatureName) =>
    dispatch(updateSignatureNameAction(signatureName))
  const save = (signature) => dispatch(updateSignatureAction(signature))

  const handleSave = (evt) => {
    //cas clic directement sur suivant. Le null gère l'erreur pour la première entrée sur la page
    if (signatureName == null) {
      updateSignatureName("")
      evt.preventDefault()
    }
    //on envoie le nom pour la gestion des erreurs
    else if (
      signatureName === "" ||
      !StringHelper.validateNoSpecialChar(signatureName)
    ) {
      updateSignatureName(signatureName)
      evt.preventDefault()
    }
    //maj
    else {
      save({
        ...signature,
        Name: signatureName,
        NewMail: newMail,
        InResponse: inResponse,
        Weighting: weighting,
      })

      // mise à jour du parcours Trial
      onSignatureSaved && onSignatureSaved()
      next && next(evt)
    }
  }

  return (
    <div className="sig-form__main">
      <div className="signature-preview">
        <Card>
          <CardHeader>
            <div>Nouveau message</div>
            <div className="client-mail__icon">
              <i className={"material-icons"}>send</i>
            </div>
          </CardHeader>
          <div className="client-mail">
            <div className="client-mail__line">à</div>
            <div className="client-mail__line">Objet</div>
          </div>
          <div className="signature-template">
            <div className="signature-template__viewer">
              <ConnectedSignatureReadOnlyView />
            </div>
          </div>
        </Card>
      </div>
      <div className="bmm-button__container">
        {/*  Le tunnel TRIAL n'utilise pas le `step` du multi-step form, mais une navigation react-router */}
        {prevNavLink && nextNavLink && (
          <>
            <Link
              className="bmm-button"
              to={prevNavLink}
              data-cy="signature__step1-prec-btn"
              data-tour="sig-next-btn"
            >
              <div>
                <span className="bmm-button__intitule">
                  <FormattedMessage
                    id="SignaturePropertiesView.Previous"
                    defaultMessage="PRECEDENT"
                  />
                </span>
              </div>
            </Link>
            <Link
              className="bmm-button"
              data-cy="signature__step1-validate-btn"
              onClick={handleSave}
              to={nextNavLink}
              data-tour="sig-next-btn"
            >
              <div>
                <span className="bmm-button__intitule">
                  <FormattedMessage
                    id="SignaturePropertiesView.Next"
                    defaultMessage="SUIVANT"
                  />
                </span>
              </div>
            </Link>
          </>
        )}

        {!prevNavLink && prev && (
          <div
            className="bmm-button"
            data-cy="signature__step1-prec-btn"
            data-tour="sig-next-btn"
          >
            <div>
              <span className="bmm-button__intitule">
                <FormattedMessage
                  id="SignaturePropertiesView.Previous"
                  defaultMessage="PRECEDENT"
                />
              </span>
            </div>
          </div>
        )}

        {!prevNavLink && (
          <div
            className="bmm-button"
            data-cy="signature__step1-validate-btn"
            onClick={handleSave}
            data-tour="sig-next-btn"
          >
            <div>
              <span className="bmm-button__intitule">
                <FormattedMessage
                  id="SignaturePropertiesView.Next"
                  defaultMessage="SUIVANT"
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SignaturesPropertiesView
