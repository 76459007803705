import React from "react"
import { Paper, IconButton, InputBase } from "@material-ui/core"

interface State {
  value: string
}

class TexBoxWithButton extends React.Component<any, State> {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isSuccess) {
      this.setState({ value: "" })
    }
  }

  handleInputChange = (evt) => {
    const value = evt.target.value
    this.setState({ value })
    this.props.onChange && this.props.onChange(value)
  }

  handleClick = (evt) => {
    this.props.onClick(this.state.value)
  }

  handleKeyPresssed = (evt) => {
    if (evt.key === "Enter") this.props.onClick(this.state.value)
  }

  render() {
    return (
      <Paper>
        <InputBase
          className="bmm-input--with-button"
          placeholder="Code Promo"
          onChange={this.handleInputChange}
          onKeyPress={this.handleKeyPresssed}
          inputProps={{ "aria-label": "Valider le code promo" }}
          value={this.state.value}
        />
        {!this.props.isSuccess && (
          <IconButton
            type="submit"
            onClick={this.handleClick}
            className={"bmm-input__button"}
            aria-label="validate"
          >
            OK
          </IconButton>
        )}
        {this.props.isSuccess && (
          <IconButton
            type="submit"
            onClick={this.handleClick}
            className={"bmm-input__button"}
            aria-label="ok"
          >
            <i className="material-icons">done</i>
          </IconButton>
        )}
      </Paper>
    )
  }
}

export default TexBoxWithButton
