import { createAction } from "@reduxjs/toolkit"

import DataTag from "entities/DataTag"
import Signature from "entities/Signature"

import { TypeKeys } from "./SignaturesConstants"
import * as Interfaces from "./SignaturesConstants"

import { SendingModes } from "./SignaturesModels"

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
export const actionCreators = {
  // Assignations d'utilisateurs
  unassignAllGroups: () => ({ type: TypeKeys.DISSOCIATE_ALL_GROUPS }),
  duplicateSignature: (
    signature: Signature,
  ): Interfaces.DuplicateSignatureAction => ({
    type: TypeKeys.DUPLICATE_SIGNATURE,
    newSignature: signature,
  }),
  // TODO - action UI utiliser l'action DATA de màj d'une signature, c'est le toggle du composant qui dispatchera cette action
  activateSignature: (
    signature: Signature,
  ): Interfaces.ActivateSignatureAction => ({
    type: TypeKeys.ACTIVATE_SIGNATURE,
    signature,
  }),
  // TODO - migrer vers un selecteur + remonter les Id d'utilisateurs assignés dans le fetchSignatures
  loadActiveSignatureUsers: (): Interfaces.LoadAffectedUsersAction => ({
    type: TypeKeys.LOAD_AFFECTED_USERS,
  }),
  // TODO - migrer vers un selecteur + remonter les Id de groupe assignés dans le fetchSignatures
  loadActiveSignatureGroup: (): Interfaces.LoadAffectedGroupsAction => ({
    type: TypeKeys.LOAD_AFFECTED_GROUPS,
  }),
  // TODO - migrer vers le context du system SignatureEditor
  updateTemplateDataTag: (dataTag: DataTag) => ({
    type: TypeKeys.UPDATE_ACTIVE_SIGNATURE_TEMPLATE,
    dataTag,
  }),
  // TODO - migrer vers le state URL
  search: (search: string) => ({ type: TypeKeys.SEARCH_SIGNATURES, search }),
  // TODO - migrer vers le context du system SignatureEditor
  selectTemplate: (templateId: number): Interfaces.SelectTemplateAction => ({
    type: TypeKeys.SELECT_TEMPLATE,
    payload: { id: templateId },
  }),
  // TODO - migrer vers le context du system SignatureEditor
  selectPrevTemplate: (): Interfaces.SelectPrevTemplateAction => ({
    type: TypeKeys.SELECT_PREV_TEMPLATE,
  }),
  // TODO - migrer vers le context du system SignatureEditor
  selectNextTemplate: (): Interfaces.SelectNextTemplateAction => ({
    type: TypeKeys.SELECT_NEXT_TEMPLATE,
  }),
  // TODO - Passer par un selecteur + charger partiellement les signatures affectées à chaque utilisateurs dans le fetchUsers
  loadAllAffectedUser: (): Interfaces.LoadAllAffectedUsersAction => ({
    type: TypeKeys.LOAD_ALL_AFFECTED_USERS,
  }),
}

export const loadActiveSignatureAffectationsAction = createAction(
  "signature/loadActiveSignatureAffectations",
  function prepare(signatureId: number) {
    return { payload: signatureId }
  },
)

export const updateActiveSignaturesAssignationsAction = createAction(
  "signature/updateSignaturesAssignations",
  function prepare(usersIds, groupsIds) {
    return { payload: { usersIds, groupsIds } }
  },
)

export const createSignatureAction = createAction(
  "signature/createSignature",
  function prepare(signature: Signature) {
    return { payload: signature }
  },
)

export const createSignatureFailureAction = createAction(
  "signature/createSignatureFailure",
)

export const toggleSignatureAction = createAction(
  "signature/toggleSignature",
  function prepare(signature: Signature) {
    return { payload: signature }
  },
)

export const updateSignatureAction = createAction(
  "signature/updateSignature",
  function prepare(signature: Signature) {
    return { payload: signature }
  },
)

export const fetchTemplatesGalleryAction = createAction(
  "signature/fetchTemplatesGallery",
)

export const updateSignatureLogoAction = createAction(
  "signature/updateSignatureLogo",
  function prepare(base64LogoUrl: string) {
    return { payload: base64LogoUrl }
  },
)

export const deleteSignatureAction = createAction(
  "signature/deleteSignature",
  function prepare(signature: Signature) {
    return { payload: signature }
  },
)

export const initSignatureFormAction = createAction(
  "signature/initSignatureForm",
)

export const sendSignaturePreviewAction = createAction(
  "signature/sendSignaturePreview",
)

export const cropLogoAction = createAction(
  "signature/cropLogo",
  function prepare(logoUrl: string, width: number, height: number) {
    return { payload: { logoUrl, width, height } }
  },
)

export const updateLogoSizeAction = createAction(
  "signature/updateLogoSize",
  function prepare(width: number, height: number) {
    return { payload: { width, height } }
  },
)

export const toggleSendingModeAction = createAction(
  "signature/toggleSendingMode",
)

export const removeSendingModeFromSignatureAction = createAction(
  "signature/removeSendingMode",
  function prepare(signatureId: number, sendingMode: SendingModes) {
    return { payload: { signatureId, sendingMode } }
  },
)

export const fetchSignaturesCustomQueryAction = createAction(
  "signature/fetchSignaturesCustomQuery",
  function prepare(requestId: string, customQuery) {
    return {
      payload: { requestId, customQuery },
    }
  },
)
