import { FunctionComponent, RefObject, useMemo, useState } from "react"
import { SignatureMetricVM } from "features/Signatures/SignaturesModels"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import Gauge from "components/Gauge"
import Tooltip from "components/Tooltip"
import SearchBar from "components/SearchBar"
import ListGeneric from "components/ListGeneric"
import SignatureAssignation from "entities/SignatureAssignation"
import useSignatureAffectedDatas from "features/Signatures/hooks/useSignatureAffectedDatas"

interface State {
  anchorEl: null | HTMLElement
  show: boolean
  userList: SignatureAssignation[]
  filteredList: SignatureAssignation[]
}
interface TrackingMetricsProps {
  tracking: SignatureMetricVM
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = defineMessages({
  placeholder: {
    id: "TrackingMetrics.SearchPlaceholder",
    defaultMessage: "Rechercher",
  },
})

const gaugeRanges = [
  { min: 0, max: 33, color: "#EB2363" },
  { min: 33, max: 66, color: "#3D58FE" },
  { min: 66, max: 100, color: "#0DBCC3" },
]

const TrackingMetrics: FunctionComponent<TrackingMetricsProps> = ({
  tracking,
}) => {
  const intl = useIntl()
  const [state, setState] = useState<State>({
    show: false,
    anchorEl: null,
    userList: [],
    filteredList: [],
  })

  const { signatureRef } = useSignatureAffectedDatas(tracking.signatureId)

  const gaugeValue = useMemo(() => {
    if (tracking.nbAssignedUsers === 0) return 0
    return Math.round(
      100 - (tracking.nbUsersNoSignature * 100) / tracking.nbAssignedUsers,
    )
  }, [tracking])

  const { show, anchorEl, filteredList, userList } = state

  const handleClick = (evt) => {
    let list = []
    if (evt.currentTarget.id === "usersNoSignature") {
      list = tracking.signatureUsers.filter((user) => !user.usage)
    } else {
      list = tracking.signatureUsers
    }

    if (list.length > 0) {
      setState({
        show: !show,
        anchorEl: anchorEl ? null : evt.currentTarget,
        userList: list,
        filteredList: list,
      })
    }
  }

  const handleClose = () => {
    setState({
      show: false,
      anchorEl: null,
      userList: [],
      filteredList: [],
    })
  }

  const handleSearch = (input: string) => {
    const search = input.toLowerCase()
    setState({
      ...state,
      filteredList: userList.filter(
        (data) =>
          data.firstName.toLowerCase().includes(search) ||
          data.lastName.toLowerCase().includes(search),
      ),
    })
  }

  return (
    <div
      data-cy="tracking-metrics"
      className="tracking-metrics"
      ref={signatureRef as RefObject<HTMLDivElement>}
    >
      <div className="tracking-metrics__header">
        <div className="tracking-metrics__header__title">
          {tracking.signatureName}
        </div>
        <div className="tracking-metrics__header__subtitle">
          <FormattedMessage
            id="TrackingMetrics.CardLabel"
            defaultMessage="Déploiement de la signature"
          />
        </div>
      </div>

      <div data-cy="tracking-metrics__gauge" className="tracking-metrics__body">
        <Gauge value={gaugeValue || 0} colorRanges={gaugeRanges}></Gauge>
      </div>

      <div className="tracking-metrics__footer">
        <div
          id="usersNoSignature"
          className="tracking-metrics__footer__side"
          onClick={handleClick}
        >
          <div className="tracking-metrics__footer__side__stat">
            <span className="tracking-metrics__footer__icon">
              <i className="fas fa-male"></i>
            </span>
            <div data-cy="tracking-metrics__to-update">
              {tracking.nbUsersNoSignature}
            </div>
          </div>
          <div className="tracking-metrics__footer__title">
            <FormattedMessage
              id="TrackingMetrics.UsersUpdated"
              defaultMessage="Utilisateurs à déployer"
            />
          </div>
        </div>
        <div className="tracking-metrics__footer__divider"></div>
        <div
          data-cy="tracking-metrics__assigned__container"
          id="assignedUsers"
          className="tracking-metrics__footer__side"
          onClick={handleClick}
        >
          <div className="tracking-metrics__footer__side__stat">
            <span className="tracking-metrics__footer__icon tracking-metrics__footer__icon--color">
              <i className="fas fa-male"></i>
            </span>
            <div data-cy="tracking-metrics__assigned">
              {tracking.nbAssignedUsers}
            </div>
          </div>
          <div className="tracking-metrics__footer__title">
            <FormattedMessage
              id="TrackingMetrics.UsersAssigned"
              defaultMessage="Utilisteurs assignés"
            />
          </div>
        </div>
      </div>

      <Tooltip
        show={show}
        parent={anchorEl}
        onClickAway={handleClose}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClickInside={() => {}}
        arrow
      >
        <div data-cy="tracking-metrics__user-list">
          <SearchBar
            placeholder={intl.formatMessage({
              id: "TrackingMetrics.SearchPlaceholder",
            })}
            onChange={handleSearch}
          ></SearchBar>
          <ListGeneric
            styleOptions={{
              maxHeight: "250px",
              overflowY: "auto",
              textTransform: "capitalize",
            }}
            data={filteredList}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default TrackingMetrics
