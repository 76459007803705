import { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { defineMessages, useIntl } from "react-intl"

import EmptyPageCTA from "components/EmptyPageCTA"

import { trialTunnelSelectors } from "features/Trial"

import imageNewSignature from "../../assets/icone-signature-large.svg"
import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

const messages = defineMessages({
  alert: {
    id: "Signatures.NewSignatureAlert",
    defaultMessage:
      "Vous n’avez pas encore de signature de paramétrée. Ajoutez-en dès maintenant.",
  },
  title: {
    id: "Signatures.NewSignatureTitle",
    defaultMessage: "Signature",
  },
  subtitle: {
    id: "Signatures.NewSignatureSubtitle",
    defaultMessage: "Paramétrez votre première signature",
  },
  link: {
    id: "Signatures.NewSignatureLink",
    defaultMessage: "Créer votre signature",
  },
})

const SignaturesEmpty: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)

  return (
    <EmptyPageCTA>
      <EmptyPageCTA.Alert alert={formatMessage(messages.alert)} />
      <EmptyPageCTA.Cards>
        <EmptyPageCTA.Card
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subtitle)}
          image={imageNewSignature}
          imgAlt={formatMessage(messages.title)}
          link={formatMessage(messages.link)}
          to={
            !isTrialFinished
              ? `${TRIAL_TUNNEL_URL}/1`
              : "/portal/signature/create"
          }
        />
      </EmptyPageCTA.Cards>
    </EmptyPageCTA>
  )
}

export default SignaturesEmpty
