import { FunctionComponent, ReactNode } from "react"
import { useSelector } from "react-redux"

import CollapseList from "./CollapseList/CollapseList"

import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"

import { FormattedMessage } from "react-intl"

import Group from "features/Groups/GroupsModels"
import { User } from "features/Users/UserModels"

export interface PeoplePickerCollapseListItem {
  id: string | number
  label: string
  message?: ReactNode
  isDisabled?: boolean
}
interface PeoplePickerCollapseList {
  title: ReactNode
  selectedData: PeoplePickerCollapseListItem[]
  className?: string
  handleClick: (item: PeoplePickerCollapseListItem) => void
  selectAll: (items: PeoplePickerCollapseListItem[]) => void
  removeAll: () => void
}

interface PeoplePickerCollapseListsProps {
  groupCollapseList: PeoplePickerCollapseList
  userCollapseList: PeoplePickerCollapseList
  search?: string
  activeMembers: number[]
}

const groupOver = 7 // Group A->Z when lot of items

const groupsToItems = (groups: Group[]) =>
  groups.map((group) => {
    const message = (
      <div className="people-picker-collapse-list__tooltip">
        <p className="people-picker-collapse-list__tooltip--label">
          <FormattedMessage
            id="PeoplePicker.GroupMembersCount"
            defaultMessage="{count, plural, =0 {Ce groupe ne contient aucun membre} =1 {Ce groupe contient # membre} other {Ce groupe contient # membres}}"
            values={{ count: group.MembersId.length }}
          />
        </p>
      </div>
    )

    return { id: group.Id, label: group.Name, message }
  })

const usersToItems = (users: User[], activeMembers: number[]) =>
  users.map((user) => {
    const isDisabled = activeMembers.includes(user.Id)
    const userEmail =
      user.Properties?.firstname || user.Properties?.lastname
        ? user.Username
        : undefined
    const message = (
      <div className="people-picker-collapse-list__tooltip">
        {userEmail && (
          <p className="people-picker-collapse-list__tooltip--label">
            {userEmail}
          </p>
        )}
        {isDisabled && (
          <p className="people-picker-collapse-list__tooltip--label">
            <FormattedMessage
              id="PeoplePicker.UserAddedFromGroup"
              defaultMessage="Cet utilisateur appartient à un groupe déjà ajouté"
            />
          </p>
        )}
      </div>
    )

    return {
      id: user.Id,
      label: User.getFullName(user),
      message,
      isDisabled,
    }
  })

const PeoplePickerCollapseLists: FunctionComponent<
  PeoplePickerCollapseListsProps
> = ({ groupCollapseList, userCollapseList, search, activeMembers }) => {
  const activeGroups: Group[] = useSelector(groupsSelectors.getLicencedGroups)
  const activeUsers = useSelector(usersSelectors.getAllUsersWithLicences)

  return (
    <>
      <CollapseList
        title={groupCollapseList.title}
        data={groupsToItems(activeGroups)}
        type="groups"
        selectedData={groupCollapseList.selectedData}
        handleClick={groupCollapseList.handleClick}
        grouped={activeGroups.length > groupOver}
        search={search}
        selectAll={groupCollapseList.selectAll}
        removeAll={groupCollapseList.removeAll}
      />
      <CollapseList
        title={userCollapseList.title}
        data={usersToItems(activeUsers, activeMembers)}
        selectedData={userCollapseList.selectedData}
        handleClick={userCollapseList.handleClick}
        grouped={activeUsers.length > groupOver}
        search={search}
        selectAll={userCollapseList.selectAll}
        removeAll={userCollapseList.removeAll}
      />
    </>
  )
}

export default PeoplePickerCollapseLists
