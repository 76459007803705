import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"

import MyOffer from "./MyOfferPage"

import "css/SubscriptionPage.scss"

const SubscriptionPage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="Subscription.Title"
          defaultMessage="Mon Abonnement"
        />
      }
    >
      <div className="subscription-body">
        <MyOffer />
      </div>
    </Page>
  )
}

export default SubscriptionPage
