import React from "react"
import { Snackbar } from "@material-ui/core"
import LoadingBar from "components/LoadingBar"
import { VariantType } from "components/LoadingBar/LoadingBar"
import { Slide } from "@material-ui/core"

interface Props {
  open: boolean
  transition: "left" | "right" | "up" | "down"
  handleClose()
  action?: any
  progress?: boolean
}

class Notification extends React.Component<Props> {
  render() {
    const { open, transition, children, handleClose, action } = this.props
    const colors = { primary: "#3D5AFE", secondary: "#E1E5FD" }
    const message = this.props.progress ? (
      <>
        <LoadingBar variant={VariantType.INDETERMINATE} colors={colors} />
        {this.props.children}
      </>
    ) : (
      children
    )

    return (
      <Slide direction={transition} in={open} mountOnEnter unmountOnExit>
        <Snackbar
          className="bmm-notification"
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={message}
          onClose={handleClose}
          action={action}
        />
      </Slide>
    )
  }
}

export default Notification
