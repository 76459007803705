import { FunctionComponent } from "react"

import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { NavLink, redirect } from "react-router-dom"

import OnBoardingCard from "./OnBoardingCard"

import { tourGuideActionCreators, tourGuideSelectors } from "features/TourGuide"

import config from "config/config"
import { HOME_PORTAL_URL } from "router/RouterConstants"

import Tour from "entities/Tour"

const messages = defineMessages({
  step1title: { id: "OnBoarding.Step1Title", defaultMessage: "Etape 1" },
  step1subtitle: {
    id: "OnBoarding.Step1SubTitle",
    defaultMessage: "Importer les adresses mails de mes collaborateurs",
  },
  step2title: { id: "OnBoarding.Step2Title", defaultMessage: "Etape 2" },
  step2subtitle: {
    id: "OnBoarding.Step2SubTitle",
    defaultMessage:
      "Création d’une signature mail et intégration d’une bannière",
  },
  step3title: { id: "OnBoarding.Step3Title", defaultMessage: "Etape 3" },
  step3subtitle: { id: "OnBoarding.Step3SubTitle", defaultMessage: "Tracking" },
})

const OnBoarding: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const tours = useSelector(tourGuideSelectors.getTourGuideAllTours)

  const startTour = (selectedTour) => {
    dispatch(tourGuideActionCreators.startTourGuide(selectedTour))
  }

  const passthrough = () => {
    dispatch(tourGuideActionCreators.passThroughtTourGuide())
  }

  const handleGoClick = (selectedTour: Tour) => {
    startTour(selectedTour)
    redirect(HOME_PORTAL_URL)
  }

  const sections =
    tours === null ? (
      <section className="on-boarding__content">Chargement ...</section>
    ) : (
      <section className="on-boarding__content">
        <OnBoardingCard
          title={formatMessage(messages.step1title)}
          subtitle={formatMessage(messages.step1subtitle)}
          imageUrl={`${config.cdnUrl}/images/bmm/tourguide_01.png`}
          tour={tours["users"]}
          onClick={handleGoClick}
        />
        <OnBoardingCard
          title={formatMessage(messages.step2title)}
          subtitle={formatMessage(messages.step2subtitle)}
          imageUrl={`${config.cdnUrl}/images/bmm/tourguide_02.png`}
          tour={tours["signatures"]}
          onClick={handleGoClick}
        />
        <OnBoardingCard
          title={formatMessage(messages.step3title)}
          subtitle={formatMessage(messages.step3subtitle)}
          imageUrl={`${config.cdnUrl}/images/bmm/tourguide_03.png`}
          tour={tours["stats"]}
          onClick={handleGoClick}
        />
      </section>
    )

  return (
    <section className="row body-content l-one-column on-boarding">
      <img
        className="on-boarding__logo"
        src={`${config.cdnUrl}/images/bmm/logo_bmm_blue.svg`}
        alt=""
      />
      <header className="on-boarding__header">
        <h1 className="on-boarding__header-title">
          <FormattedMessage
            id="OnBoardingPage.Welcome"
            defaultMessage="Bienvenue !"
          />
        </h1>
        <div className="on-boarding__header-subtext">
          <FormattedMessage
            id="OnBoardingPage.WelcomeText1"
            defaultMessage="Suivez le guide pour faire vos premiers pas sur la plateforme Boost My Mail. C’est parti !"
          />
          <br />
          <FormattedMessage
            id="OnBoardingPage.WelcomeText2"
            defaultMessage="Suivez les étapes ou explorez librement par vous-même..."
          />
        </div>
      </header>
      {sections}
      <footer className="on-boarding__footer">
        <NavLink
          onClick={passthrough}
          to={HOME_PORTAL_URL}
          className="on-boarding__footer-link"
        >
          <FormattedMessage
            id="OnBoardingPage.PassDemo"
            defaultMessage="Passer la démo"
          />
          <div className="on-boarding__footer-link-lizeret"></div>
        </NavLink>
      </footer>
    </section>
  )
}

export default OnBoarding
