import { FunctionComponent } from "react"
import { Outlet } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import Page from "components/Page"

const BuilderSignaturePage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="BuilderSignature.Builder"
          defaultMessage="Builder"
        />
      }
    >
      <Outlet />
    </Page>
  )
}

export default BuilderSignaturePage
