import { FunctionComponent } from "react"

import AccountsInformationsReInsurancePanelContent from "./components/AccountsInformationsReInsurancePanelContent"
import AccountsInformationsReInsurancePanelCTA from "./components/AccountsInformationsReInsurancePanelCTA"
import AccountsInformationsReInsurancePanelMessages from "./components/AccountsInformationsReInsurancePanelMessages"

const AccountsInformationsReInsurancePanel: FunctionComponent = () => {
  return (
    <>
      <div className="reinsurance-panel">
        <AccountsInformationsReInsurancePanelMessages />
        <AccountsInformationsReInsurancePanelContent />
      </div>
      <AccountsInformationsReInsurancePanelCTA />
    </>
  )
}

export default AccountsInformationsReInsurancePanel
