import { FunctionComponent } from "react"

import { useSearchParams } from "react-router-dom"

import UsersTab from "features/Users/components/UsersTab"
import GroupsTab from "features/Groups/components/GroupsTab"

const UsersPageContent: FunctionComponent = () => {
  const [searchParams] = useSearchParams()

  const renderedComponent = {
    users: <UsersTab />,
    groups: <GroupsTab />,
  }

  const actualTab = searchParams.get("tab")
  return (
    <div className="users-page__content">
      <div className="users-page__container">
        {actualTab && renderedComponent[actualTab]}
      </div>
    </div>
  )
}

export default UsersPageContent
