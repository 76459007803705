import React, { FunctionComponent } from "react"

import DataTag from "entities/DataTag"
import SocialShareItem from "components/SocialShareList/SocialShareItem"

export interface Props {
  dataTags: DataTag[] | null
  displayPreview: boolean
  onUpdateDataTag(dataTag: DataTag)
}

interface SocialShareListProps {
  dataTags: DataTag[] | null
  displayPreview: boolean
  onUpdateDataTag(dataTag: DataTag)
}

const SocialShareList: FunctionComponent<SocialShareListProps> = ({
  dataTags,
  displayPreview,
  onUpdateDataTag,
}) => {
  const dataItems = dataTags
    ? dataTags
        .filter((dataTag) => dataTag.visible)
        .map((dataTag, index) => (
          <SocialShareItem
            key={index}
            dataTag={dataTag}
            onChange={onUpdateDataTag}
            displayPreview={displayPreview}
          />
        ))
    : null

  return (
    <div className="social-share">
      <ul className="social-share__list">{dataItems}</ul>
    </div>
  )
}

export default SocialShareList
