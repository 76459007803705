import { FunctionComponent, useEffect } from "react"

import { defineMessages, useIntl } from "react-intl"

import { Link, useNavigate } from "react-router-dom"
import { Scrollbars } from "react-custom-scrollbars-2"

import GenericForm from "components/GenericForm"
import Button from "components/Button"
import Spinner from "components/Spinner"

import useSingleUserData from "features/Users/hooks/useSingleUserData"
import useUsersData from "features/Users/hooks/useUsersData"
import useEditUserInitForm from "./hooks/useEditUserInitForm"

import EditUserFormLoadingSkeleton from "./components/EditUserFormLoadingSkeleton"

import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { USERS_URL } from "router/RouterConstants"

interface EditUserFormProps {
  currentUserId?: number
}

const messages = defineMessages({
  addBtn: { id: "EditUser.AddBtn", defaultMessage: "Ajouter" },
  editBtn: { id: "EditUser.EditBtn", defaultMessage: "Mettre à jour" },
})

const EditUserForm: FunctionComponent<EditUserFormProps> = ({
  currentUserId,
}) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  useUsersData()

  const {
    currentUser,
    assignedSignatures,
    defaultSignature,
    isLoading,
    isSaving,
    onUpsertUser,
    onChangeDefaultSignature,
    onResetEditingUser,
  } = useSingleUserData(currentUserId)

  const { initForm } = useEditUserInitForm({
    currentUser,
    currentUserId,
    assignedSignatures,
    defaultSignature,
  })

  useEffect(() => {
    if (isSaving === SavingStatus.SUCCESS) navigate(USERS_URL)
  }, [isSaving])

  const isNew = currentUserId === -1

  const properties = currentUser?.Properties

  const handleUser = (form) => {
    const newProperties = { ...properties }
    form.forEach((element) => {
      if (element.name !== "default-signature") {
        newProperties[element.name] = element.value
      }
    })
    onUpsertUser({
      userId: currentUserId,
      properties: newProperties,
      redirect: "",
    })
    if (!isNew) {
      const newDefaultSignature = +form.find(
        (elem) => elem.name === "default-signature",
      ).value
      onChangeDefaultSignature(newDefaultSignature, currentUserId)
    }
  }

  return (
    <div className="edit-user-form">
      <Scrollbars
        hideTracksWhenNotNeeded
        autoHeight
        autoHeightMax={"calc(100vh - 325px)"}
      >
        <div className="edit-user-form__body">
          {isLoading !== LoadingStatus.LOADED && currentUserId !== -1 ? (
            <EditUserFormLoadingSkeleton />
          ) : (
            <GenericForm
              formId="user-form"
              initForm={initForm}
              formValidate={handleUser}
            />
          )}
        </div>
      </Scrollbars>
      <div className="edit-user-form__footer">
        <Link
          to={USERS_URL}
          onClick={() => {
            onResetEditingUser()
          }}
        >
          <Button type="button" classes="bmm-button--reverse" label="Annuler" />
        </Link>
        <Button
          type="submit"
          form="user-form"
          data-cy="edit-user__add-button"
          label={formatMessage(isNew ? messages.addBtn : messages.editBtn)}
        />
      </div>
      {isSaving === SavingStatus.SAVING && (
        <div className="edit-user-form__saving-overlay">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default EditUserForm
