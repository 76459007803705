import { featureNames } from "config/config.features"
import { LoadingStatus } from "core/CoreModels"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GlobalStates } from "store"
import { fetchCurrentSubsidiaries } from "../SubsidiariesReducer"
import {
  getCurrentSubsidiariesLoadingStatus,
  getCurrentSubsidiary,
  getCurrentSubsidiaries,
} from "../SubsidiariesSelectors"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"

const useCurrentSubsidiariesData = () => {
  const dispatch = useDispatch()
  const subsidiaries = useSelector((state: GlobalStates) =>
    getCurrentSubsidiaries(state),
  )
  const loadingStatus = useSelector((state: GlobalStates) =>
    getCurrentSubsidiariesLoadingStatus(state),
  )
  const currentSubsidiary = useSelector((state: GlobalStates) =>
    getCurrentSubsidiary(state),
  )
  const isFeatureSubsidiariesActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.SUBSIDIARIES),
  )
  const hasSubsidiary = currentSubsidiary != null

  const isLoaded = LoadingStatus.LOADED
  useEffect(() => {
    if (
      loadingStatus == LoadingStatus.NOT_STARTED &&
      isFeatureSubsidiariesActive
    )
      dispatch(fetchCurrentSubsidiaries())
  }, [isFeatureSubsidiariesActive])

  return {
    subsidiaries,
    loadingStatus,
    currentSubsidiary,
    isLoaded,
    hasSubsidiary,
  }
}

export default useCurrentSubsidiariesData
