import { BoxTypes, IImageBox, ProcessorArgs } from "bmm-builder"

function isImageBox(box: BoxTypes): box is IImageBox {
  return box && typeof box === "object" && box.type === "image"
}

export function replaceImageBoxSrc(
  domainToRemove: string,
  domainToInsert: string,
) {
  return async function (args: Promise<ProcessorArgs>): Promise<ProcessorArgs> {
    const resolvedArgs = await args

    const updatedBoxes = resolvedArgs.boxes.map((box) => {
      if (isImageBox(box)) {
        const updatedSrc = box.data?.src?.replace(
          domainToRemove,
          domainToInsert,
        )

        return {
          ...box,
          data: {
            ...box.data,
            src: updatedSrc,
          },
        } as IImageBox
      }
      return box
    })

    return Promise.resolve({ ...resolvedArgs, boxes: updatedBoxes })
  }
}
