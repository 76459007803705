import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import { Moment } from "moment"

interface ScenarioCardDateProps {
  startDate: Moment | null
  endDate: Moment | null
  isDefault?: boolean
}

const messages = defineMessages({
  from: { id: "Campaigns.CardFrom", defaultMessage: "Du" },
  to: {
    id: ".CardTo",
    defaultMessage: "au",
  },

  default: {
    id: "Campaigns.Default",
    defaultMessage: "Par défaut",
  },
})

const formatDate = (date: Moment) => date.format("DD/MM/YYYY")

export type ScenarioCardDateType = FunctionComponent<ScenarioCardDateProps>

const ScenarioCardDate: ScenarioCardDateType = ({
  startDate,
  endDate,
  isDefault,
}) => {
  const { formatMessage } = useIntl()

  if (isDefault)
    return (
      <div className="scenario-card__date">
        {formatMessage(messages.default)}
      </div>
    )

  return (
    <div className="scenario-card__date">{`${formatMessage(
      messages.from,
    )} ${formatDate(startDate)} ${formatMessage(messages.to)} ${formatDate(
      endDate,
    )}`}</div>
  )
}

export default ScenarioCardDate
