import React from "react"
import BreadCrumb from "components/BreadCrumb"
import { Scrollbars } from "react-custom-scrollbars-2"

import "./MultiStepForm.scss"
import { SIGNATURES_URL } from "router/RouterConstants"

export enum FORM_LAYOUT {
  ONE_COL = "ONE_COL",
  TWO_COL = "TWO_COL",
}

export interface Props {
  datas: {}
  onNext?: (datas: any) => void
  onPrev?: (datas: any) => void
  title: string
  step?: number
  pages: any[]
  breadCrumbNodes: any[]
  showSubNav: boolean
  scrollToTop?: boolean
}

interface State {
  currentStep: number
  datas: any
}

interface LayoutClasses {
  main: string
  content: string
  sidebar?: string
}

// Personnalisation de la scrollbar
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    backgroundColor: "#3D5AFE",
    borderRadius: "4px",
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}

/**
 * Gestion de la navigation d'une page à l'autre dans les formulaires de création
 */
class MultiStepForm extends React.Component<Props, State> {
  public scrollbar
  constructor(props: Props) {
    super(props)
    this.scrollbar = React.createRef()
    this.state = {
      currentStep: props.step ? props.step : 1,
      datas: null,
    }
  }

  prev = (event, datas) => {
    if (this.props.onPrev) this.props.onPrev(datas)
    if (this.state.currentStep > 0)
      this.setState({ currentStep: this.state.currentStep - 1 })
  }

  next = (event, datas) => {
    if (this.props.onNext) this.props.onNext(datas)
    if (this.state.currentStep < this.props.pages.length)
      this.setState({ currentStep: this.state.currentStep + 1 })
  }

  updateDatas = (key: string, value: any) => {
    const tuple = {}
    tuple[key] = value
    this.setState({
      datas: Object.assign(this.props.datas, tuple),
    })
  }

  renderChildren = (currentStep: number) => {
    let currentChildren
    this.props.pages.forEach((child, i) => {
      // Add one for sync array index wich start at 0 with step number wich start at 1
      if (i + 1 === currentStep) {
        currentChildren = child.main
      }
    })

    const childrenProps = {
      updateDatas: this.updateDatas,
      prev: currentStep > 1 ? this.prev : null,
      next: this.next,
    }

    // We add a new method to each children for notify the parent when datas are updated
    return React.cloneElement(currentChildren, childrenProps)
  }

  renderSidebar = (currentStep: number) => {
    let currentSidebar
    this.props.pages.forEach((child, i) => {
      // Add one for sync array index wich start at 0 with step number wich start at 1
      if (i + 1 === currentStep) {
        currentSidebar = child.sidebar
      }
    })
    return currentSidebar
  }

  getLayoutClasses = (layout: FORM_LAYOUT): LayoutClasses => {
    // TODO - Retirer le composant Layout pour utiliser un systême de routing comme le Trial

    const sidebarClasses =
      this.props.step === 1 &&
      window.location.pathname.startsWith(SIGNATURES_URL)
        ? " l-2-columns__create-signature-sidebar"
        : ""

    switch (layout) {
      case FORM_LAYOUT.ONE_COL:
        return {
          main: "l-2-columns__main-content l-2-columns__main-content-last",
          content: "multistep__main-content multistep__main-last",
        }
      default:
        return {
          main: "l-2-columns__main-content",
          content: "multistep__main-content",
          sidebar: sidebarClasses,
        }
    }
  }

  render() {
    const { currentStep } = this.state
    const { pages, title } = this.props

    const layout =
      pages[currentStep - 1] != null && pages[currentStep - 1].layout != null
        ? pages[currentStep - 1].layout
        : null

    if (this.props.scrollToTop)
      this.scrollbar.current && this.scrollbar.current.scrollToTop()

    const layoutClasses = this.getLayoutClasses(layout)

    return (
      <section className="l-2-columns h-100">
        <section className="l-sub-header">
          <h4>{title}</h4>
          <div className="l-sub-header__liseret"></div>
        </section>
        <section className="l-2-columns__content">
          {layout !== FORM_LAYOUT.ONE_COL && (
            <aside
              className={"l-2-columns__aside-content" + layoutClasses.sidebar}
            >
              <Scrollbars
                ref={this.scrollbar}
                renderThumbVertical={renderThumb}
              >
                {this.renderSidebar(currentStep)}
              </Scrollbars>
            </aside>
          )}
          <main className={layoutClasses.main}>
            {/* Main */}
            <section className={layoutClasses.content}>
              <Scrollbars>{this.renderChildren(currentStep)}</Scrollbars>
            </section>
            <BreadCrumb nodes={this.props.breadCrumbNodes} step={currentStep} />
          </main>
        </section>
      </section>
    )
  }
}

export default MultiStepForm
