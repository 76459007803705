import AccountInformationsPage from "pages/private/AccountInformationsPage"
import Overview from "pages/private/OverviewPage"
import OnBoarding from "pages/private/OnBoardingPage"
import AdditionalInformations from "pages/private/AdditionalInformationsPage"
import TrialTunnelContext from "features/Trial/components"
import SignaturePage from "pages/private/Signature"
import BuilderSignaturePage from "pages/private/BuilderSignaturePage"
import SubsidiariesPage from "pages/private/SubsidiariesPage"
import { SubsidiaryForm } from "features/Subsidiaries"
import Expired from "pages/private/ExpiredPage"
import Enrollment from "pages/private/EnrollmentPage"
import SubscriptionPage from "pages/private/Subscription"

import SignaturesBuilder from "features/BuilderSignatures/components/SignaturesBuilder"
import BuilderList from "features/BuilderSignatures/components/BuilderList"

import {
  ACCOUNT_INFORMATIONS_URL,
  ADDITIONAL_INFOS_URL,
  ENROLLMENT_URL,
  EXPIRED_URL,
  HOME_PORTAL_URL,
  ON_BOARDING_URL,
  SIGNATURES_BUILDER_URL,
  SIGNATURES_URL,
  SUBSCRIPTION_URL,
  SUBSIDIARIES_URL,
  TRIAL_TUNNEL_URL,
} from "router/RouterConstants"

import { featureNames } from "config/config.features"

import { PrivateRoute } from "router/RouterModels"

const privateRoutes: PrivateRoute[] = [
  {
    key: "accountInformations",
    path: ACCOUNT_INFORMATIONS_URL,
    element: <AccountInformationsPage />,
  },
  {
    key: "overview",
    path: HOME_PORTAL_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      tourguide: true,
    },
    accessRoles: "ALL_ROLES",
    element: <Overview />,
  },
  {
    key: "onBoarding",
    path: ON_BOARDING_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
    },
    element: <OnBoarding />,
  },
  {
    key: "additionalInformations",
    path: ADDITIONAL_INFOS_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
    },
    element: <AdditionalInformations />,
  },
  {
    key: "trialTunnel",
    path: `${TRIAL_TUNNEL_URL}/:step?`,
    limitations: {
      accountFilled: true,
      offerValid: true,
    },
    element: <TrialTunnelContext />,
  },
  {
    key: "signatures",
    path: SIGNATURES_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      tourguide: true,
    },
    accessRoles: "SIGNATURES_PAGE",
    element: <SignaturePage />,
    children: [
      { key: "signatureCreate", path: "create", element: <SignaturePage /> },
      { key: "signatureEdit", path: "update", element: <SignaturePage /> },
    ],
  },
  {
    key: "builder",
    path: SIGNATURES_BUILDER_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      tourguide: true,
      activeFeature: featureNames.NEW_BUILDER,
    },
    accessRoles: "SIGNATURES_PAGE",
    element: <BuilderSignaturePage />,
    children: [
      {
        key: "builderList",
        path: "",
        index: true,
        element: <BuilderList />,
      },
      { key: "builderEdit", path: "edit", element: <SignaturesBuilder /> },
    ],
  },
  {
    key: "subsidiaryEdit",
    path: `${SUBSIDIARIES_URL}/edit/:subsidiaryId?`,
    limitations: {
      accountFilled: true,
      offerValid: true,
      tourguide: true,
      activeFeature: featureNames.SUBSIDIARIES,
    },
    accessRoles: "SUBSIDIARIES_PAGE",
    element: <SubsidiaryForm />,
  },
  {
    key: "subsidiaries",
    path: SUBSIDIARIES_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
      tourguide: true,
      activeFeature: featureNames.SUBSIDIARIES,
    },
    accessRoles: "SUBSIDIARIES_PAGE",
    element: <SubsidiariesPage />,
  },
  {
    key: "expired",
    path: EXPIRED_URL,
    element: <Expired />,
  },
  {
    key: "enrollment",
    path: ENROLLMENT_URL,
    element: <Enrollment />,
  },
  {
    key: "subscription",
    path: SUBSCRIPTION_URL,
    limitations: {
      accountFilled: true,
      offerValid: true,
    },
    accessRoles: "SUBSCRIPTION_PAGE",
    element: <SubscriptionPage />,
  },
]

export default privateRoutes
