import React from "react"
import { useState } from "react"
import { makeStyles, MenuItem } from "@material-ui/core"

interface Props {
  label: string
  onClick: () => void
  icon?: Element | React.ReactElement
  iconHover?: Element | React.ReactElement
  style?: { fontSize?: number; minHeight?: number }
}

const defaultStyle = {
  fontSize: 14,
  minHeight: 40,
}

const MenuListItem: React.FC<Props> = (props) => {
  const { label, onClick, icon, iconHover, style } = props
  const [isHover, setIsHover] = useState(false)

  const styleWithProps = {
    ...defaultStyle,
    ...style,
  }

  const useStyles = makeStyles({
    root: {
      fontSize: styleWithProps.fontSize,
      fontWeight: 500,
      minHeight: styleWithProps.minHeight,
      "&:hover": {
        color: "#3D5AFE",
      },
    },
  })
  const classes = useStyles({})

  const onMouseOver = () => {
    if (iconHover) {
      setIsHover(true)
    }
  }

  const onMouseLeave = () => {
    if (iconHover) {
      setIsHover(false)
    }
  }

  const renderIcon = () => {
    if (isHover && iconHover) {
      return iconHover
    } else {
      return icon
    }
  }

  return (
    <MenuItem
      classes={{ root: classes.root }}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <span className="menu-list-item__icon">{renderIcon()}</span>
      <span className="menu-list-item__label">{label}</span>
    </MenuItem>
  )
}

export default MenuListItem
