import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ModalService } from "components/Modal"

import { ReactComponent as DefaultUserPicture } from "assets/user-picture.svg"
import { ReactComponent as IconImage } from "assets/icon-picture.svg"
import { ReactComponent as EditIcon } from "assets/icon-edit.svg"
import { ReactComponent as DeleteIcon } from "assets/icon-delete.svg"

import UserAvatarManager from "features/Users/components/UserAvatarManager"

interface UserAvatarProps {
  pictureUrl?: string
  handlePicture: (picture?: string) => void
}

const messages = defineMessages({
  userAvatarAlt: {
    id: "UserPicture.Alt",
    defaultMessage: "Avatar du collaborateur",
  },
  addPictureTitle: {
    id: "UserPicture.AddPictureModalTitle",
    defaultMessage: "Ajouter une photo de profil",
  },
  editPictureTitle: {
    id: "UserPicture.EditPictureModalTitle",
    defaultMessage: "Modifier la photo de profil",
  },
})

const UserAvatar: FunctionComponent<UserAvatarProps> = ({
  pictureUrl,
  handlePicture,
}) => {
  const { formatMessage } = useIntl()
  const [pictureUrlState, setPhotoUrlState] = useState(undefined)

  useEffect(() => {
    setPhotoUrlState(pictureUrl)
  }, [pictureUrl])

  const handleValidate = (picture: string) => {
    setPhotoUrlState(picture)
    handlePicture(picture)
    ModalService.close()
  }

  const handleOpenModal = (type: "add" | "edit") => {
    const title = {
      add: formatMessage(messages.addPictureTitle),
      edit: formatMessage(messages.editPictureTitle),
    }

    ModalService.open({
      title: title[type],
      content: <UserAvatarManager onValidate={handleValidate} />,
    })
  }

  const handleDeletePicture = () => {
    setPhotoUrlState("")
    handlePicture("")
  }

  if (!pictureUrlState || pictureUrlState.length === 0)
    return (
      <div className="user-avatar is-default">
        <DefaultUserPicture className="user-avatar__default-icon" />
        <button
          type="button"
          className="user-avatar__add-button"
          onClick={() => handleOpenModal("add")}
        >
          <IconImage />
        </button>
      </div>
    )

  return (
    <div className="user-avatar">
      <img
        className="user-avatar__picture"
        src={pictureUrlState}
        alt={formatMessage(messages.userAvatarAlt)}
      />
      <div className="user-avatar__update-container">
        <button
          type="button"
          className="user-avatar__edit-button"
          onClick={() => handleOpenModal("edit")}
        >
          <EditIcon className="user-avatar__update-icon" />
        </button>
        <button
          type="button"
          className="user-avatar__delete-button"
          onClick={handleDeletePicture}
        >
          <DeleteIcon className="user-avatar__update-icon" />
        </button>
      </div>
    </div>
  )
}

export default UserAvatar
