import React from "react"
import { FormattedMessage } from "react-intl"

export default class NoMatch extends React.Component<any, any> {
  render() {
    return (
      <div className="col-sm-12">
        <section className="row body-content body-content-without-nav">
          <section className="l-sub-header l-sub-header-without-nav">
            <h4>404</h4>
            <div className="l-sub-header__liseret"></div>
          </section>
          <div className="flex-bloc-center body-main-content p-5 pb-5">
            <h5 className="title-tracking-soon">
              <FormattedMessage id="NotFound.Oups" defaultMessage="Oups" />
              <br />
            </h5>
            <p className="text-tracking-soon">
              <FormattedMessage
                id="NotFound.Text"
                defaultMessage="La page que vous demandez n'existe pas ..."
              />
            </p>
          </div>
        </section>
      </div>
    )
  }
}
