import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"

const messages = defineMessages({
  firstTitle: {
    id: "AccountsInformations.ReInsurancePanelMessagesFirstTitle",
    defaultMessage: "Les clients",
  },
  firstSubTitle: {
    id: "AccountsInformations.ReInsurancePanelMessagesFirstSubTitle",
    defaultMessage: "qui nous ont choisis",
  },
  secondTitle: {
    id: "AccountsInformations.ReInsurancePanelMessagesSecondTitle",
    defaultMessage: "chaque collaborateur",
  },
  secondSubTitle: {
    id: "AccountsInformations.ReInsurancePanelMessagesSecondSubTitle",
    defaultMessage: "Représente",
  },
})

const reInsurancePanelMessagesAvailable = [
  {
    steps: [0, 1, 2, 3],
    content: { title: messages.firstTitle, subtitle: messages.firstSubTitle },
  },
  {
    steps: [4, 5, 6],
    content: { title: messages.secondTitle, subtitle: messages.secondSubTitle },
  },
]

const AccountsInformationsReInsurancePanelMessages: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const currentRegisterStep = useSelector(accountsSelectors.getRegisterStep)

  const reInsurancePanelMessages = useMemo(
    () =>
      reInsurancePanelMessagesAvailable
        .map((message) => message.steps.map(() => message.content))
        .flat(),
    [currentRegisterStep],
  )

  if (!reInsurancePanelMessages[currentRegisterStep]) return null

  return (
    <div className="reinsurance-panel__messages">
      <p className="reinsurance-panel__messages--title">
        {formatMessage(reInsurancePanelMessages[currentRegisterStep].title)}
      </p>
      <p className="reinsurance-panel__messages--subtitle">
        {formatMessage(reInsurancePanelMessages[currentRegisterStep].subtitle)}
      </p>
    </div>
  )
}

export default AccountsInformationsReInsurancePanelMessages
