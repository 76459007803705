import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

import { useOnClickOutside } from "hooks/useOnClickOutside"

import MouseTooltip from "components/MouseTooltip"

import TrackingDataBlock from "features/Tracking/components/TrackingDataBlock"

import SignaturesDeploymentOverview from "./components/SignatureDeploymentOverview"
import SignatureDeploymentGraph from "./components/SignatureDeploymentGraph"
import SignatureDeploymentPreview from "./components/SignatureDeploymentPreview"
import SignatureDeploymentUsers from "./components/SignatureDeploymentUsers"
import SignaturesDeploymentInsufficientsDatas from "./components/SignaturesDeploymentInsufficientsDatas"
import SignatureDeploymentLoading from "./components/SignatureDeploymentLoading"

import { trackingSelectors } from "features/Tracking"

import { MINIMUM_GRAPH_DATA_LENGTH } from "features/Tracking/TrackingConstants"

import { LoadingStatus } from "core/CoreModels"
import { SignatureDeployment } from "features/Tracking/TrackingModels"

const messages = defineMessages({
  title: {
    id: "Tracking.SignaturesDeployment",
    defaultMessage: "Déploiement de signature",
  },
  infos: {
    id: "Tracking.SignaturesDeploymentInfos",
    defaultMessage:
      "Il ne s'agit pas d'un graphe en temps réel, les données du jour ne sont pas considérées.",
  },
})

const SignaturesDeployment: FunctionComponent = () => {
  const previewRef = useRef(null)
  const usersRef = useRef(null)
  const { formatMessage } = useIntl()

  const [visible, setVisible] = useState(false)
  const [selectedSignature, setSelectedSignature] =
    useState<SignatureDeployment | null>(null)
  const [displayPreview, setDisplayPreview] = useState<{
    template: string
    isBuilder: boolean
  } | null>(null)
  const [displayUsersId, setDisplayUsersId] = useState(null)
  const [displayCoordinates, setDisplayCoordinates] = useState({ x: 0, y: 0 })

  const loadingStatus = useSelector(
    trackingSelectors.getSignaturesDeploymentLoadingStatus,
  )
  const allSignaturesDeployment = useSelector(
    trackingSelectors.getAllSignaturesDeployment,
  )

  const signatureDeploymentUsers = useSelector(
    trackingSelectors.getSignatureDeploymentUsersInfos(displayUsersId),
  )

  const sortedSignaturesDeployment = useMemo(
    () =>
      allSignaturesDeployment.sort((a, b) => {
        if (a.status.id > b.status.id) return 1
        if (a.status.id < b.status.id) return -1

        if (a.status.status === "success")
          return b.status.value - a.status.value
        return a.status.value - b.status.value
      }),
    [allSignaturesDeployment],
  )

  const sufficientHistory = useMemo(() => {
    if (selectedSignature === null) return false
    if (selectedSignature.history.length <= MINIMUM_GRAPH_DATA_LENGTH)
      return false
    if (Math.max(...selectedSignature.history.map((h) => h.quantity)) === 0)
      return false
    return true
  }, [selectedSignature])

  useEffect(() => {
    if (!selectedSignature && sortedSignaturesDeployment.length > 0)
      setSelectedSignature(sortedSignaturesDeployment[0])
  }, [selectedSignature, sortedSignaturesDeployment])

  const handleDisplayPreview = (
    id: number | null,
    e?: MouseEvent<HTMLDivElement>,
  ) => {
    const signature = sortedSignaturesDeployment.find((sd) => sd.id === id)
    if (signature)
      setDisplayPreview({
        template: signature.template,
        isBuilder: signature.isBuilder,
      })
    else setDisplayPreview(null)

    signature && e && setDisplayCoordinates({ x: e.clientX, y: e.clientY })
  }

  const handleDisplayUsers = (
    id: number | null,
    e?: MouseEvent<HTMLDivElement>,
  ) => {
    if (!id) {
      setDisplayUsersId(null)
      return
    }

    const signature = allSignaturesDeployment.find((sd) => sd.id === id)
    if (signature) setDisplayUsersId(id)

    signature && e && setDisplayCoordinates({ x: e.clientX, y: e.clientY })
  }

  const title = (
    <>
      <div className="signatures-deployment__title">
        <p className="signatures-deployment__title--label">
          {formatMessage(messages.title)}
        </p>
        <InfoIcon
          className="signatures-deployment__title--icon"
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        />
      </div>
      <MouseTooltip visible={visible}>
        {formatMessage(messages.infos)}
      </MouseTooltip>
    </>
  )

  useOnClickOutside(previewRef, () => handleDisplayPreview(null))
  useOnClickOutside(usersRef, () => handleDisplayUsers(null))

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <TrackingDataBlock title={title}>
        <SignatureDeploymentLoading />
      </TrackingDataBlock>
    )

  return (
    <>
      <TrackingDataBlock title={title}>
        <div className="signatures-deployment">
          <div className="signatures-deployment__left-side">
            {sortedSignaturesDeployment.map((sd) => (
              <SignaturesDeploymentOverview
                key={sd.id}
                id={sd.id}
                title={sd.name}
                status={sd.status}
                deployed={sd.deployedUsers}
                total={sd.affectedUsers}
                isSelected={sd.id === selectedSignature?.id}
                handleClick={() => setSelectedSignature(sd)}
                handleDisplayPreview={handleDisplayPreview}
                handleDisplayUsers={handleDisplayUsers}
              />
            ))}
          </div>
          <div className="signatures-deployment__right-side">
            {selectedSignature?.history && sufficientHistory && (
              <SignatureDeploymentGraph
                data={selectedSignature.history}
                deploymentValue={selectedSignature.status.value}
              />
            )}
            {selectedSignature?.history && !sufficientHistory && (
              <SignaturesDeploymentInsufficientsDatas
                deploymentValue={selectedSignature.status.value}
              />
            )}
          </div>
        </div>
      </TrackingDataBlock>
      {displayPreview && (
        <div ref={previewRef}>
          <SignatureDeploymentPreview
            template={displayPreview.template}
            isBuilder={displayPreview.isBuilder}
            top={displayCoordinates.y}
            left={displayCoordinates.x}
          />
        </div>
      )}
      {signatureDeploymentUsers && displayUsersId && (
        <div ref={usersRef}>
          <SignatureDeploymentUsers
            users={signatureDeploymentUsers}
            top={displayCoordinates.y}
            left={displayCoordinates.x}
          />
        </div>
      )}
    </>
  )
}

export default SignaturesDeployment
