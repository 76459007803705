import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { uuid } from "utils/Uuid"

import { fetchScenariosCustomQueryAction } from "features/Scenarios/ScenarioActions"

import { Params } from "core/services/requestBuilderService"
import { ScenarioVm } from "features/Scenarios/ScenarioModels"
import { scenarioSelectors } from ".."

const useScenariosCustomqueryData = (customQuery: Params<ScenarioVm>) => {
  const dispatch = useDispatch()
  const calledOnce = useRef(false)
  const requestId = useRef(uuid.generate())

  const result = useSelector(
    scenarioSelectors.getScenariosCustomQueryResultById(requestId.current),
  )

  useEffect(() => {
    if (calledOnce.current) {
      return
    }
    dispatch(fetchScenariosCustomQueryAction(requestId.current, customQuery))
    calledOnce.current = true
  }, [])

  return result
}

export default useScenariosCustomqueryData
