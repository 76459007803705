import { accountsSelectors } from "features/Accounts"
import { offersSelectors } from "features/Offers"
import { GlobalStates } from "store"

const getTrialStep = (state: GlobalStates) => state.trial.trialStep

const bannerIsUnlocked = (state: GlobalStates) =>
  !offersSelectors.isTrial(state) ||
  accountsSelectors.getTrialCampaignUnlockedProperty(state)?.Value === "created"

const trialIsFinished = (state: GlobalStates) =>
  !offersSelectors.isTrial(state) || state.trial.trialStep === 7

export const selectors = {
  getTrialStep,
  bannerIsUnlocked,
  trialIsFinished,
}
