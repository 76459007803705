import { Params } from "core/services/requestBuilderService"

import { ScenarioVm } from "./ScenarioModels"

export const SCENARIO_ID_DATA_ATTRIBUTE = "data-scenario-id"

export const SCENARIOS_SLICES = ["internal", "external"] as const

export const AT_LEAST_ONE_SCENARIO_QUERY: Params<ScenarioVm> = {
  top: 1,
  fields: ["Id"],
}

export const BANNER_ACCEPTED_FILETYPES = [
  {
    name: "jpg",
    mimetype: "image/jpeg",
  },
  {
    name: "png",
    mimetype: "image/png",
  },
  {
    name: "gif",
    mimetype: "image/gif",
  },
]

// * Constantes pour les tailles maximum de bannières (100ko, 500ko et 800ko)
export const BANNER_SUCCESS_SIZE = 100000
export const BANNER_WARNING_SIZE = 500000
export const BANNER_ERROR_SIZE = 800000
