import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import { GlobalStates } from "store"

import {
  CatalogOffer,
  Offer,
  OfferOption,
  TenantOffer,
} from "features/Offers/OffersModels"

import { LoadingStatus } from "core/CoreModels"

interface OffersEntities {
  [id: string]: Offer
}

interface CatalogOffersEntities {
  [id: string]: CatalogOffer
}

interface OfferOptionsEntities {
  [id: string]: OfferOption
}

interface OfferState {
  currentOffer: {
    entity: TenantOffer | null
    loadingStatus: LoadingStatus
  }
  limitations: string | null
  trialLimitations: {
    title: string
    content: string
  } | null
  allOffers: {
    entities: OffersEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  allOptions: {
    entities: OfferOptionsEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  allCatalogOffers: {
    entities: CatalogOffersEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
}

const offersAdapter = createEntityAdapter<Offer>({
  selectId: (offer) => offer.Id,
  sortComparer: (a, b) => a.Id - b.Id,
})

export const { selectById: selectOfferById, selectAll: selectAllOffers } =
  offersAdapter.getSelectors<GlobalStates>((state) => state.offers.allOffers)

const catalogOffersAdapter = createEntityAdapter<CatalogOffer>({
  selectId: (offer) => offer.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const {
  selectById: selectCatalogOfferById,
  selectAll: selectAllCatalogOffers,
} = catalogOffersAdapter.getSelectors<GlobalStates>(
  (state) => state.offers.allCatalogOffers,
)

const offerOptionsAdapter = createEntityAdapter<OfferOption>({
  selectId: (option) => option.Id,
  sortComparer: (a, b) => a.Id - b.Id,
})

export const {
  selectById: selectOfferOptionById,
  selectAll: selectAllOfferOptions,
} = offerOptionsAdapter.getSelectors<GlobalStates>(
  (state) => state.offers.allOptions,
)

const initialState: OfferState = {
  currentOffer: { entity: null, loadingStatus: LoadingStatus.NOT_STARTED },
  limitations: null,
  trialLimitations: null,
  allOffers: offersAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  allOptions: offerOptionsAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  allCatalogOffers: catalogOffersAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
}

const offersReducer = createSlice({
  name: "offers",
  initialState,
  reducers: {
    fetchAllOffers(state) {
      state.allOffers.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllOffersSuccess(state, action: PayloadAction<Offer[]>) {
      offersAdapter.setAll(state.allOffers, action.payload)
      state.allOffers.loadingStatus = LoadingStatus.LOADED
    },
    fetchAllOffersFailure(state) {
      state.allOffers.loadingStatus = LoadingStatus.ERROR
    },
    fetchAllOptions(state) {
      state.allOptions.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllOptionsSuccess(state, action: PayloadAction<OfferOption[]>) {
      offerOptionsAdapter.setAll(state.allOptions, action.payload)
      state.allOptions.loadingStatus = LoadingStatus.LOADED
    },
    fetchAllOptionsFailure(state) {
      state.allOptions.loadingStatus = LoadingStatus.ERROR
    },
    fetchCurrentTenantOffer(state) {
      state.currentOffer.loadingStatus = LoadingStatus.PENDING
    },
    fetchCurrentTenantOfferSuccess(state, action: PayloadAction<TenantOffer>) {
      state.currentOffer.entity = action.payload
      state.currentOffer.loadingStatus = LoadingStatus.LOADED
    },
    fetchCurrentTenantOfferFailure(state) {
      state.currentOffer.loadingStatus = LoadingStatus.ERROR
    },
    showTrialLimitations(
      state,
      action: PayloadAction<{ title: string; content: string }>,
    ) {
      state.trialLimitations = action.payload
    },
    setOfferLimitations(state, action: PayloadAction<string>) {
      state.limitations = action.payload
    },
    resetOfferLimitations(state) {
      state.limitations = null
    },
    fetchAllCatalogOffers(state) {
      state.allCatalogOffers.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllCatalogOffersSuccess(state, action: PayloadAction<CatalogOffer[]>) {
      catalogOffersAdapter.setAll(state.allCatalogOffers, action.payload)
      state.allCatalogOffers.loadingStatus = LoadingStatus.LOADED
    },
    fetchAllCatalogOffersFailure(state) {
      state.allCatalogOffers.loadingStatus = LoadingStatus.ERROR
    },
  },
})

export const {
  fetchAllOffers,
  fetchAllOffersSuccess,
  fetchAllOffersFailure,
  fetchAllOptions,
  fetchAllOptionsSuccess,
  fetchAllOptionsFailure,
  fetchCurrentTenantOffer,
  fetchCurrentTenantOfferSuccess,
  fetchCurrentTenantOfferFailure,
  showTrialLimitations,
  setOfferLimitations,
  resetOfferLimitations,
  fetchAllCatalogOffers,
  fetchAllCatalogOffersSuccess,
  fetchAllCatalogOffersFailure,
} = offersReducer.actions

export default offersReducer.reducer
