import { FunctionComponent, ReactNode } from "react"

import HoverTooltip from "components/HoverTooltip"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface TrackingTotalsKPIBlockProps {
  icon: ReactNode
  value: number | string
  label: string
  tooltip?: string
}

const TrackingTotalsKPIBlock: FunctionComponent<
  TrackingTotalsKPIBlockProps
> = ({ icon, value, label, tooltip }) => {
  return (
    <div className="tracking-totals__kpi-block">
      {tooltip && (
        <div className="tracking-totals__kpi-block--tooltip">
          <HoverTooltip tooltip={tooltip} align="right">
            <InfoIcon />
          </HoverTooltip>
        </div>
      )}
      <div className="tracking-totals__kpi-block--container">
        <div className="tracking-totals__kpi-block--icon">{icon}</div>
        <p className="tracking-totals__kpi-block--value">{value}</p>
      </div>
      <p className="tracking-totals__kpi-block--label">{label}</p>
    </div>
  )
}

export default TrackingTotalsKPIBlock
