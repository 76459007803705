import { useEffect, useRef } from "react"
import { DataGridConfig } from "../PaginatedDataGrid"
import PaginatedDataGridCell from "./PaginatedDataGridCell"

interface PaginatedDataGridBodyProps<T, P> {
  config: DataGridConfig<T>
  data: T[]
  scrollToTop?: boolean
  getRowProps?: (rowData: T) => P
}
const renderRow = <T, P>(
  renderRowConfig: DataGridConfig<T>,
  data: T[],
  rowIndex: number,
  rowData: T,
  getRowProps?: (rowData: T) => P,
) => {
  return (
    <tr
      key={rowIndex}
      id={`data-grid_${rowIndex}`}
      className="data-grid__tr"
      {...(getRowProps && getRowProps(rowData))}
    >
      {renderRowConfig.columns.map((columnConfig, colIndex) => (
        <PaginatedDataGridCell
          column={columnConfig}
          data={data}
          rowIndex={rowIndex}
          colIndex={colIndex}
          rowData={rowData}
          isLoading={renderRowConfig?.isLoading}
          renderLoading={columnConfig?.renderLoading !== false}
        />
      ))}
    </tr>
  )
}
const PaginatedDataGridBody = <T, P>({
  config,
  data: allData,
  getRowProps,
  scrollToTop,
}: PaginatedDataGridBodyProps<T, P>): JSX.Element => {
  const bodyRef = useRef<HTMLTableSectionElement>(null)

  const handleScrollToTop = () => {
    if (bodyRef.current !== null)
      bodyRef.current.scroll({
        top: 0,
        behavior: "smooth",
      })
  }

  useEffect(() => {
    if (scrollToTop !== undefined) handleScrollToTop()
  }, [scrollToTop])

  if (config?.isLoading && allData.length === 0)
    return (
      <tbody className="data-grid__tbody">
        {[...Array(config?.skeletonRowQuantity || 6).keys()].map(
          (_rowData, rowIndex) =>
            renderRow(config, allData, rowIndex, {} as T, getRowProps),
        )}
      </tbody>
    )

  if (!config?.isLoading && allData.length === 0 && config?.background)
    return config.background

  return (
    <tbody ref={bodyRef} className="data-grid__tbody">
      {allData.map((rowData, rowIndex) =>
        renderRow(config, allData, rowIndex, rowData, getRowProps),
      )}
    </tbody>
  )
}

export default PaginatedDataGridBody
