import { connect } from "react-redux"
import SocialShareList from "components/SocialShareList"
import {
  signaturesActionCreators,
  signaturesSelectors,
} from "features/Signatures"
import DataTag from "entities/DataTag"

interface OwnProps {
  displayPreview: boolean
}

interface StateProps {
  dataTags: DataTag[]
}

interface DispatchProps {
  onUpdateDataTag(dataTag: DataTag)
}

const mapStateToProps = (state) => ({
  dataTags: signaturesSelectors.getActiveTemplateDataTags(state) as DataTag[],
})

const mapDispatchToProps = {
  onUpdateDataTag: signaturesActionCreators.updateTemplateDataTag,
}

const ConnectedSocialShareList = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SocialShareList)

export default ConnectedSocialShareList
