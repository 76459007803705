import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { ReactComponent as IconDelete } from "assets/icon-delete.svg"

import MouseTooltip from "components/MouseTooltip"
import ConfirmModal from "components/ConfirmModal"

interface ScenarioCardDeleteActionProps {
  isMultiple: boolean
  onDelete?: () => void
  roundedCorner?: "top" | "bottom"
  disabled?: boolean
}

export type ScenarioCardDeleteActionType =
  FunctionComponent<ScenarioCardDeleteActionProps>

const messages = defineMessages({
  deleteCampaign: {
    id: "ScenarioCard.DeleteCampaignTooltip",
    defaultMessage: "Supprimer la campagne",
  },
  deleteScenario: {
    id: "ScenarioCard.DeleteScenarioTooltip",
    defaultMessage: "Supprimer le scénario",
  },
})

const ScenarioCardDeleteAction: ScenarioCardDeleteActionType = ({
  isMultiple,
  onDelete,
  roundedCorner,
  disabled = false,
}) => {
  const { formatMessage } = useIntl()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [visible, setVisible] = useState(false)

  return (
    <>
      <ConfirmModal
        action={() => onDelete && onDelete()}
        handleClose={() => setOpenConfirm(false)}
        open={openConfirm}
      />
      <div
        onClick={() => {
          if (!disabled) setOpenConfirm(true)
        }}
        className={classNames(
          "scenario-card__delete-btn",
          roundedCorner && `scenario-card__rounder-corner--${roundedCorner}`,
        )}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <IconDelete className="scenario-card__btn--icon" />
      </div>
      <MouseTooltip visible={visible}>
        {isMultiple
          ? formatMessage(messages.deleteScenario)
          : formatMessage(messages.deleteCampaign)}
      </MouseTooltip>
    </>
  )
}

export default ScenarioCardDeleteAction
