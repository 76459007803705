import { FunctionComponent, ReactNode } from "react"

import { useDispatch, useSelector } from "react-redux"
import { offersSelectors } from "features/Offers"
import { Navigate } from "react-router-dom"
import { showTrialLimitations } from "features/Offers/OffersReducer"
import { HOME_PORTAL_URL } from "router/RouterConstants"

interface WithRequireNoTrialProps {
  children: ReactNode
  requirePaidOffer: boolean
  title?: string
  content?: string
}

const WithRequireNoTrial: FunctionComponent<WithRequireNoTrialProps> = ({
  children,
  requirePaidOffer,
  title,
  content,
}) => {
  const dispatch = useDispatch()
  const isTrial = useSelector(offersSelectors.isTrial)

  if (requirePaidOffer && isTrial) {
    dispatch(showTrialLimitations({ title, content }))
    return <Navigate to={HOME_PORTAL_URL} />
  }

  return <>{children}</>
}

export default WithRequireNoTrial
