import { FunctionComponent } from "react"

import { Link } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { ReactComponent as AddIcon } from "assets/icon-add.svg"

import { trialTunnelSelectors } from "features/Trial"

import { initActiveBuilderSignatureAction } from "features/BuilderSignatures/BuilderSignaturesActions"

import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

const NewBuilderButton: FunctionComponent = () => {
  const dispatch = useDispatch()
  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)

  const handleClick = () => {
    dispatch(initActiveBuilderSignatureAction(null))
  }
  return (
    <Link
      className="new-builder-btn"
      data-cy="new-builder-btn"
      onClick={handleClick}
      to={!isTrialFinished ? `${TRIAL_TUNNEL_URL}/1` : "/portal/builder/edit"}
    >
      <AddIcon />
    </Link>
  )
}

export default NewBuilderButton
