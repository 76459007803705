import { FunctionComponent, useEffect, useState } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import Toggle from "components/MUIToggle"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import Feature from "features/Features/FeaturesModels"

import { AvailableJobs } from "features/Jobs/JobsConstants"

interface LuccaToggleProps {
  featureLucca: Feature
  launchJob: (feature: Feature) => void
  handleToggle: (active: boolean) => void
  onActiveForm: (active: boolean) => void
}

const messages = defineMessages({
  toggleTitle: {
    id: "LuccaForm.ToggleTitle",
    defaultMessage: "Synchronisation utilisateurs",
  },
  toggleDescription: {
    id: "LuccaForm.ToggleDescription",
    defaultMessage:
      "Activez la synchronisation automatique, pour récupérer, toutes les 24h, vos informations utilisateur",
  },
  toggleDisable: {
    id: "LuccaForm.ToggleDisable",
    defaultMessage: "Désactivée",
  },
  toggleEnable: {
    id: "LuccaForm.ToggleEnable",
    defaultMessage: "Activée",
  },
  toggleDeploy: {
    id: "Lucca.ToggleDeploy",
    defaultMessage: "Synchroniser manuellement",
  },
  settings: { id: "Lucca.Params", defaultMessage: "Paramètres" },
})

const LuccaToggle: FunctionComponent<LuccaToggleProps> = ({
  featureLucca,
  launchJob,
  handleToggle,
  onActiveForm,
}) => {
  const { formatMessage } = useIntl()

  const [launchSync, setLaunchSync] = useState(false)

  const isSyncing = useJobIsRunning(AvailableJobs.SYNC_USERS)

  useEffect(() => {
    if (!isSyncing) setLaunchSync(false)
  }, [isSyncing])

  const handleSync = () => {
    setLaunchSync(true)
    launchJob(featureLucca)
  }

  return (
    <div className="lucca-toggle" data-cy="lucca-toggle">
      <div className="lucca-toggle__sync">
        <p className="lucca-toggle__title">
          {formatMessage(messages.toggleTitle)}
        </p>
        <p className="lucca-toggle__sync-description">
          {formatMessage(messages.toggleDescription)}
        </p>
        <div className="lucca-toggle__action">
          <div
            className={`lucca-toggle__action--${
              featureLucca?.activated ? "inactive" : "active"
            }`}
            onClick={() => handleToggle(false)}
          >
            {formatMessage(messages.toggleDisable)}
          </div>
          <div className="lucca-toggle__toggle">
            <Toggle
              checked={featureLucca?.activated}
              onChange={() => handleToggle(!featureLucca.activated)}
            />
          </div>
          <div
            className={`lucca-toggle__action--${
              featureLucca?.activated ? "active" : "inactive"
            }`}
            onClick={() => handleToggle(true)}
          >
            {formatMessage(messages.toggleEnable)}
          </div>
        </div>
        {featureLucca.activated && (
          <button
            type="button"
            className="lucca-toggle__manual-sync"
            onClick={handleSync}
            disabled={launchSync || isSyncing}
          >
            <p className={"lucca-toggle__manual-sync--label"}>
              {formatMessage(messages.toggleDeploy)}
            </p>

            <SyncIcon
              className={classNames(
                "lucca-toggle__manual-sync--icon",
                (launchSync || isSyncing) && "is-loading",
              )}
            />
          </button>
        )}
        <div className="lucca-toggle__params">
          <div
            className="lucca-toggle__params--btn"
            onClick={() => onActiveForm(true)}
          >
            <i className="material-icons lucca-toggle__params--icon">
              settings
            </i>
            {formatMessage(messages.settings)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LuccaToggle
