import React, { useState, useEffect, FunctionComponent } from "react"

import config from "config/config"

import { FormattedMessage, useIntl } from "react-intl"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"

import NavigationButtons from "../Components/NavigationButtons"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import CheckoutForm from "features/Enrollment/components/EnrollmentCheckoutForm"

import { formatAmount } from "utils/NumberHelper"

import "./EnrollmentStep.scss"

import visualSky from "features/Enrollment/assets/visual-sky.svg"
import Loader from "components/Loader"

import { DEFAULT_OFFER_CURRENCY } from "features/Offers/OffersConstants"

const EnrollmentStep5: FunctionComponent = () => {
  const { locale } = useIntl()
  const { enrollmentDatas, setEnrollmentDatas, subscribe } = useEnrollmentData()
  const [complete, setComplete] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    if (config.stripePublicKey) {
      setStripePromise(loadStripe(config.stripePublicKey))
    }
  }, [config.stripePublicKey])

  const handleNext = () => {
    subscribe()
    setSubmitted(true)
  }

  const isComplete = (checkout) => {
    const isCompleteCB =
      checkout.holder &&
      checkout.cardNumber &&
      checkout.cardExpiry &&
      checkout.cardCvc
    const isCompleteSepa =
      checkout.holder_sepa && checkout.email && checkout.iban
    if (isCompleteCB || isCompleteSepa) return true
  }

  const handleChange = (key, value) => {
    const checkout = { ...enrollmentDatas.checkout }
    checkout[key] = value
    setEnrollmentDatas({ checkout: { ...checkout } })
    setComplete(isComplete(checkout))
  }

  useEffect(() => {
    if (enrollmentDatas.enrollmentSuccess === true) {
      setEnrollmentDatas({ currentStep: 6 })
    }
  }, [enrollmentDatas])

  return (
    <>
      <Loader fullScreen isLoading={submitted} />
      <div className="enroll__step enroll_step5">
        <div className="enroll__box enroll__main">
          <div className="enroll__form">
            <h2>
              <span>5</span>Paiement
            </h2>
            <Elements stripe={stripePromise}>
              <CheckoutForm onChange={handleChange} />
            </Elements>
            {!complete && (
              <div className="enroll__warning">
                <FormattedMessage
                  id="StepSide5Warning"
                  defaultMessage="Veuillez compléter vos informations de paiement"
                />
              </div>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <NavigationButtons
              labelNext={`Payer ${formatAmount(
                enrollmentDatas.totalVAT,
                locale,
                DEFAULT_OFFER_CURRENCY.name,
              )}`}
              onNext={handleNext}
              disablePrevious={submitted}
              disableNext={!complete || submitted}
            />
          </div>
        </div>
        <div className="enroll__box enroll__aside enroll__aside__step5">
          <div className="enroll__aside__title">
            <FormattedMessage id="StepSide5a" defaultMessage="Prêt à" />
          </div>
          <div className="enroll__aside__subtitle">
            <FormattedMessage id="StepSide5b" defaultMessage="démarrer ?" />
          </div>
          <img src={visualSky} alt="" />
        </div>
      </div>
    </>
  )
}

export default EnrollmentStep5
