import { FormEvent, FunctionComponent, useState } from "react"

import { useDispatch } from "react-redux"

import UnderlinedInputField from "components/UnderlinedInputField"

import { updateAccountInformations } from "features/Accounts/AccountsReducer"

import AccountInformationsFormSubmitButton from "./AccountInformationsFormSubmitButton"
import { AccountInformations } from "features/Accounts/AccountsModels"

export interface AccountInformationsFormSingleFieldStepProps {
  nextRegisterStep: number
  label: string
  buttonLabel: string
  submitButtonLocker: (value: string) => boolean
  payload: (value: string, stepRegister: number) => AccountInformations
  initialValue?: string
  placeholder?: string
  validator?: (value: string) => string
}

const AccountInformationsFormSingleFieldStep: FunctionComponent<
  AccountInformationsFormSingleFieldStepProps
> = ({
  nextRegisterStep,
  label,
  placeholder,
  buttonLabel,
  initialValue = "",
  submitButtonLocker,
  payload,
  validator,
}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(initialValue)

  const handleChange = (e: FormEvent) => {
    const { value: inputValue } = e.target as HTMLInputElement

    if (validator) {
      const validatedValue = validator(inputValue)
      setValue(validatedValue)
    } else setValue(inputValue)
  }

  const handleSubmit = () => {
    dispatch(updateAccountInformations(payload(value, nextRegisterStep)))
  }

  return (
    <>
      <p className="account-informations-form__step--label">{label}</p>
      <UnderlinedInputField
        placeholder={placeholder}
        variant="xl"
        value={value}
        onChange={handleChange}
        autoFocus
      />
      <AccountInformationsFormSubmitButton
        disabled={submitButtonLocker(value)}
        onClick={handleSubmit}
      >
        {buttonLabel}
      </AccountInformationsFormSubmitButton>
    </>
  )
}

export default AccountInformationsFormSingleFieldStep
