import React, { FunctionComponent, ReactNode } from "react"

/** Layout **/
import "css/layout.scss"
import "css/version.scss"

/** Components **/
import GlobalNav from "./GlobalNav"

import ProfileHeader from "features/Accounts/components/ProfileHeader"
import AutoSave from "components/AutoSave"
import ConnectedGlobalLoader from "./ConnectedGlobalLoader"

import BackgroundProcess from "features/Jobs/components/BackgroundProcess/BackgroundProcess"

import TrialLimitationsModal from "features/Offers/components/TrialLimitationsModal"
import LimitationsModal from "features/Offers/components/LimitationsModal"

import { Notifications } from "features/Notifications"
import ModalProvider from "components/Modal"

interface LayoutProps {
  children: ReactNode
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <>
      <TrialLimitationsModal />
      <div className="container-fluid" id="wrapper" data-tour="main">
        <ConnectedGlobalLoader>
          <ProfileHeader />
          <AutoSave />
          <main className="col-12 m-0 container-fluid">
            <GlobalNav />
            <section className="main-page">{children}</section>
          </main>
        </ConnectedGlobalLoader>
        <LimitationsModal />
        <BackgroundProcess />
        <Notifications />
        <ModalProvider />
      </div>
    </>
  )
}

export default Layout
