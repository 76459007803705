import { Dispatch, FunctionComponent } from "react"

import { SignatureRenderer } from "bmm-builder"

import SignaturePreview from "features/Signatures/components/SignaturePreview"

import { defineMessages, useIntl } from "react-intl"

import GraphDataLegendItem from "features/Tracking/components/GraphDataLegendItem"

import {
  SignatureTracking,
  TrackingGraphData,
} from "features/Tracking/TrackingModels"

import { ActionType } from "features/Tracking/hooks/useTrackingGraphData"

interface SingleSignatureTrackingLegendProps {
  template: string
  isBuilder: boolean
  series: TrackingGraphData[]
  dispatch: Dispatch<ActionType<SignatureTracking>>
  sufficientGraphDatas?: boolean
}

const messages = defineMessages({
  title: {
    id: "Tracking.SingleSignatureTrackingLegendTitle",
    defaultMessage: "Légende",
  },
})

const SingleSignatureTrackingLegend: FunctionComponent<
  SingleSignatureTrackingLegendProps
> = ({ template, isBuilder, series, dispatch, sufficientGraphDatas }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="single-sign-track__legend--container">
      <div className="single-sign-track__preview">
        <div className="single-sign-track__preview--cover">
          {isBuilder ? (
            <SignatureRenderer templateJSON={template} scale={0.5} />
          ) : (
            <SignaturePreview template={template} cardHeight={100} />
          )}
        </div>
      </div>
      {sufficientGraphDatas && (
        <div className="single-sign-track__legend--data">
          <div className="single-sign-track__legend--title-container">
            <p className="single-sign-track__legend--title">
              {formatMessage(messages.title)}
            </p>
          </div>

          {series.map((serie) => (
            <GraphDataLegendItem
              key={serie.id}
              serie={serie}
              dispatch={dispatch}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SingleSignatureTrackingLegend
