import React from "react"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"

interface Props {
  name?: string
  value?: string
  error?: boolean
  errorText?: string
  disabled?: boolean
  placeholder?: string
  multiline?: boolean
  rows?: number
  required?: boolean
}

type InputTextProps = Props & TextFieldProps

const InputText: React.FC<InputTextProps> = (props) => {
  const {
    name,
    value,
    onChange,
    onBlur,
    error,
    errorText,
    disabled,
    placeholder,
    multiline,
    rows,
    required,
    ...others
  } = props

  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="dense"
      FormHelperTextProps={{
        error: true,
      }}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={error && errorText}
      disabled={disabled}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      required={required}
      {...others}
    />
  )
}

export default InputText
