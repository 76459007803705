import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { useDispatch, useSelector } from "react-redux"

import { ReactComponent as PreviewIcon } from "assets/icon-preview.svg"
import { ReactComponent as PieIcon } from "assets/icon-pie.svg"

import TrackingDataBlock from "features/Tracking/components/TrackingDataBlock"
import Podium from "features/Tracking/components/Podium"

import SignaturesPowerUsersOverview from "./components/SignaturesPowerUsersOverview"
import SignaturesPowerUsersGraph from "./components/SignaturesPowerUsersGraph"
import SignaturesPowerUsersInsufficientsDatas from "./components/SignaturesPowerUsersInsufficientsDatas"
import SignaturesPowerUsersLoading from "./components/SignaturesPowerUsersLoading"
import SignaturesPowerUsersGraphInsufficientsDatas from "./components/SignaturesPowerUsersGraphInsufficientsDatas"

import { trackingSelectors } from "features/Tracking"
import { previewMail } from "features/Users/UsersReducers"
import { usersSelectors } from "features/Users"

import { LoadingStatus } from "core/CoreModels"
import { SignaturesPowerUser } from "features/Tracking/TrackingModels"

import { MINIMUM_GRAPH_DATA_LENGTH } from "features/Tracking/TrackingConstants"

import { GlobalStates } from "store"

const messages = defineMessages({
  title: {
    id: "Tracking.SignaturesPowerUsers",
    defaultMessage: "Top Diffuseurs",
  },
})

function buildPodiumItemFromPowerUser(powerUser: SignaturesPowerUser) {
  return {
    id: powerUser.id,
    visual: powerUser.avatar,
    name: powerUser.fullName,
  }
}

const SignaturesPowerUsers: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const [selectedPowerUser, setSelectedPowerUser] = useState(null)
  const [sideDisplay, setSideDisplay] = useState<"overview" | "graph">(
    "overview",
  )

  const loadingStatus = useSelector<GlobalStates, LoadingStatus>(
    trackingSelectors.getSignaturesPowerUsersLoadingStatus,
  )
  const allSignaturesPowerUsers = useSelector(
    trackingSelectors.getSignaturesPowerUsersSortByTotalClicks,
  )

  const selectedPowerUserPreviewLoadingStatus = useSelector(
    usersSelectors.getUserTemplatesPreviewLoadingStatus,
  )

  const selectedPowerUserPreviewTemplates = useSelector(
    usersSelectors.getUserSignaturesTemplatesPreview,
  )

  const handleSelectOnPodium = (powerUser) => {
    setSelectedPowerUser(powerUser)
  }

  const actions = [
    {
      id: "0",
      icon: <PreviewIcon />,
      action: () => setSideDisplay("overview"),
    },
    { id: "1", icon: <PieIcon />, action: () => setSideDisplay("graph") },
  ]

  const powerUsers = useMemo(
    () =>
      allSignaturesPowerUsers.map((powerUser) => ({
        ...buildPodiumItemFromPowerUser(powerUser),
        handleSelect: () => handleSelectOnPodium(powerUser),
        actions,
      })),
    [allSignaturesPowerUsers],
  )

  const sufficientData = useMemo(() => {
    if (allSignaturesPowerUsers.length === 0) return false

    return true
  }, [allSignaturesPowerUsers])

  useEffect(() => {
    if (selectedPowerUser && allSignaturesPowerUsers.length > 0) {
      const updated = allSignaturesPowerUsers
        .slice(0, 3)
        .find((s) => s.id === selectedPowerUser.id)

      setSelectedPowerUser(
        updated !== undefined ? updated : allSignaturesPowerUsers[0],
      )
    }
  }, [selectedPowerUser, allSignaturesPowerUsers])

  useEffect(() => {
    if (!selectedPowerUser && allSignaturesPowerUsers.length > 0)
      setSelectedPowerUser(allSignaturesPowerUsers[0])
  }, [selectedPowerUser, allSignaturesPowerUsers])

  useEffect(() => {
    if (selectedPowerUser !== null) dispatch(previewMail(selectedPowerUser.id))
  }, [selectedPowerUser])

  const signatureTemplate = useMemo(() => {
    if (
      selectedPowerUser === null ||
      selectedPowerUserPreviewLoadingStatus !== LoadingStatus.LOADED
    )
      return

    const currentSign = selectedPowerUserPreviewTemplates.find(
      (template) => template.signatureId === selectedPowerUser.signatureId,
    )
    if (currentSign) return currentSign.signatureTemplate

    return selectedPowerUser.signatureTemplate
  }, [
    selectedPowerUser,
    selectedPowerUserPreviewLoadingStatus,
    selectedPowerUserPreviewTemplates,
  ])

  const powerUsersOverview = selectedPowerUser ? (
    <SignaturesPowerUsersOverview
      template={signatureTemplate}
      nbClicks={selectedPowerUser.nbClicks}
      isLoading={selectedPowerUserPreviewLoadingStatus !== LoadingStatus.LOADED}
    />
  ) : null

  const powerUsersGraph =
    selectedPowerUser?.history.length >= MINIMUM_GRAPH_DATA_LENGTH ? (
      <SignaturesPowerUsersGraph data={selectedPowerUser.history} />
    ) : (
      <SignaturesPowerUsersGraphInsufficientsDatas />
    )

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <TrackingDataBlock title={formatMessage(messages.title)}>
        <SignaturesPowerUsersLoading />
      </TrackingDataBlock>
    )

  return (
    <TrackingDataBlock title={formatMessage(messages.title)}>
      <div className="sign-power-users">
        {sufficientData ? (
          <>
            <div className="sign-power-users__side">
              <Podium
                gold={powerUsers[0]}
                silver={powerUsers[1]}
                bronze={powerUsers[2]}
                selected={selectedPowerUser?.id}
              />
            </div>
            <div className="sign-power-users__side">
              {sideDisplay === "overview"
                ? powerUsersOverview
                : powerUsersGraph}
            </div>
          </>
        ) : (
          <SignaturesPowerUsersInsufficientsDatas />
        )}
      </div>
    </TrackingDataBlock>
  )
}

export default SignaturesPowerUsers
