import { FunctionComponent } from "react"

import config from "config/config"
import { featureNames } from "config/config.features"

import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { NavLink, useLocation } from "react-router-dom"

import BmmNavLink from "components/BmmNavLink"

import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { accountsSelectors } from "features/Accounts"

import { offersSelectors } from "features/Offers"
import { trialTunnelSelectors } from "features/Trial"

import { useCurrentAccountAccess } from "features/Accounts/hooks/useCurrentAccountAccess"

import {
  CAMPAIGNS_URL,
  HOME_PORTAL_URL,
  ON_BOARDING_URL,
  PARAMETERS_URL,
  SIGNATURES_BUILDER_URL,
  SIGNATURES_URL,
  SUBSIDIARIES_URL,
  TRACKING_URL,
  USERS_URL,
} from "router/RouterConstants"

import { GlobalStates } from "store"

import "css/global-nav.scss"

import { RolesNeeded } from "router/routes"

const GlobalNav: FunctionComponent = () => {
  const { hasAccessTo, hasAccessToHomePage } = useCurrentAccountAccess()
  const location = useLocation()
  const isAuthentificated = useSelector<GlobalStates>(
    accountsSelectors.getIsAuthentified,
  )
  const isAccountInfosFilled = useSelector<GlobalStates>(
    accountsSelectors.getIsInformationsFilled,
  )

  const isTrial = useSelector<GlobalStates, boolean>(offersSelectors.isTrial)
  const isBannerUnlock = useSelector<GlobalStates>(
    trialTunnelSelectors.bannerIsUnlocked,
  )
  const isFeatureBuilderActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.NEW_BUILDER),
  )
  const isFeatureSubsidiariesActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.SUBSIDIARIES),
  )

  const isOnBoarding = location.pathname === ON_BOARDING_URL
  if (!isAuthentificated || !isAccountInfosFilled || isOnBoarding) return null

  return (
    <nav className="bg-faded sidebar-style-1 sidebar">
      <div>
        <NavLink to={HOME_PORTAL_URL} className="logo-header" end>
          <div className="logo-header__logo-container">
            <img
              src={`${config.cdnUrl}/images/bmm/symbol_bmm_bleu.svg`}
              alt=""
              className="bmm-symbol logo-header__logo"
            />
          </div>
        </NavLink>
        {config.cdnUrl != null && (
          <ul className="nav nav-pills flex-column sidebar-nav">
            <BmmNavLink
              to={HOME_PORTAL_URL}
              hidden={!hasAccessToHomePage}
              dataAttributes={{ "data-cy": "global-nav_home" }}
              end
            >
              <div className="icon_sb icon_home" />
              <div className="nav_link_text">
                <FormattedMessage
                  id="GlobalNav.Home"
                  defaultMessage="Accueil"
                />
              </div>
            </BmmNavLink>
            <BmmNavLink
              hidden={!hasAccessTo(RolesNeeded.SIGNATURES_PAGE).isActive}
              to={SIGNATURES_URL}
              dataAttributes={{ "data-cy": "global-nav_signatures" }}
            >
              <div className="icon_sb icon_signature" />
              <div className="nav_link_text">
                <FormattedMessage
                  id="GlobalNav.Signatures"
                  defaultMessage="Signatures"
                />
              </div>
            </BmmNavLink>
            {isFeatureBuilderActive && (
              <BmmNavLink
                to={SIGNATURES_BUILDER_URL}
                hidden={!hasAccessTo(RolesNeeded.SIGNATURES_PAGE).isActive}
                isBeta
                dataAttributes={{ "data-cy": "global-nav_builder" }}
              >
                <div className="icon_sb icon_builder" />
                <div className="nav_link_text">
                  <FormattedMessage
                    id="GlobalNav.Builder"
                    defaultMessage="Builder"
                  />
                </div>
              </BmmNavLink>
            )}
            <BmmNavLink
              locked={!isBannerUnlock}
              hidden={!hasAccessTo(RolesNeeded.SCENARIOS_PAGE).isActive}
              to={CAMPAIGNS_URL}
              dataAttributes={{ "data-cy": "global-nav_banner" }}
            >
              <div className="icon_sb icon_banner" />
              <div className="nav_link_text">
                <FormattedMessage
                  id="GlobalNav.Banners"
                  defaultMessage="Campagnes"
                />
              </div>
            </BmmNavLink>
            <BmmNavLink
              locked={isTrial}
              hidden={!hasAccessTo(RolesNeeded.TRACKING_PAGE).isActive}
              to={TRACKING_URL}
              dataAttributes={{ "data-cy": "global-nav_stats" }}
            >
              <div className="icon_sb icon_report" />
              <div className="nav_link_text">
                <FormattedMessage
                  id="GlobalNav.Stats"
                  defaultMessage="Statistiques"
                />
              </div>
            </BmmNavLink>
            <BmmNavLink
              locked={isTrial}
              hidden={!hasAccessTo(RolesNeeded.USERS_PAGE).isActive}
              to={USERS_URL}
              dataAttributes={{
                "data-cy": "global-nav_users",
                "data-tour": "global-nav__users",
              }}
            >
              <div className="icon_sb icon_users" />
              <div className="nav_link_text">
                <FormattedMessage
                  id="GlobalNav.Users"
                  defaultMessage="Utilisateurs"
                />
              </div>
            </BmmNavLink>
            {isFeatureSubsidiariesActive && (
              <BmmNavLink
                to={SUBSIDIARIES_URL}
                hidden={!hasAccessTo(RolesNeeded.SUBSIDIARIES_PAGE).isActive}
                dataAttributes={{ "data-cy": "global-subsidiaries" }}
              >
                <div className="icon_sb icon_subsidiaries" />
                <div className="nav_link_text">
                  <FormattedMessage
                    id="GlobalNav.Subsidiaries"
                    defaultMessage="Filiales"
                  />
                </div>
              </BmmNavLink>
            )}
            <BmmNavLink
              locked={isTrial}
              hidden={!hasAccessTo(RolesNeeded.PARAMETERS_PAGE).isActive}
              to={PARAMETERS_URL}
              dataAttributes={{
                "data-cy": "global-nav_parameters",
                "data-tour": "global-nav__parameters",
              }}
            >
              <div className="icon_sb icon_parameters" />
              <div className="nav_link_text">
                <FormattedMessage
                  id="GlobalNav.Parameters"
                  defaultMessage="Paramètres"
                />
              </div>
            </BmmNavLink>
          </ul>
        )}
      </div>
      <div className="nav__faq">
        <div className="nav__faq__separation" />
        <a
          data-cy="global-nav_faq"
          className="nav-link nav-link-faq"
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.boostmymail.com/"
        >
          <i className="material-icons">help_outline</i>
          <div className="nav_link_text">
            <FormattedMessage
              id="GlobalNav.Documentation"
              defaultMessage="HelpDesk"
            />
          </div>
        </a>
      </div>
    </nav>
  )
}

export default GlobalNav
