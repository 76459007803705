import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web"
import config from "config/config"

import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js"

const reactPlugin = new ReactPlugin()

// TODO -
// Logging capabilities: https://github.com/microsoft/ApplicationInsights-JS#sending-telemetry-to-the-azure-portal
// Tutorial: https://www.aaron-powell.com/posts/2019-10-04-implementing-monitoring-in-react-using-appinsights/

export const configureAppInsight = () => {
  const ai = new ApplicationInsights({
    config: {
      connectionString: config.appInsightPublicConnectionString,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
    },
  })

  ai.loadAppInsights()
  ai.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags || []
    envelope.tags.push({ "ai.cloud.role": "bmm-front-portal" })
  })

  return ai.appInsights
}

export default (Component) => withAITracking(reactPlugin, Component)
