/**
 * Lorsqu'on a besoin d'enrichir les objets retournés coté serveur
 * avec des propriétés métier coté Front
 */
import { Feature } from "./FeaturesModels"
import { partial } from "lodash"
import { pipe } from "lodash/fp"
import config from "config/config"
import { updateTenantFeaturePropertiesFromBmm } from "features/Accounts/AccountsSagas"
import { redirectTo } from "features/App/AppSagas"
import { checkOWAParameters } from "./FeaturesSagas"

// Factory function
const mapFeature = (json) => {
  const properties =
    json.properties?.map((prop) => ({
      property: prop.property,
      value: prop.value,
    })) ?? []
  return {
    name: json.name,
    activated: json.activated,
    jobName: json.jobName,
    properties,
    exclusiveFeatures: [],
    isConfigured: properties.some((prop) => prop.value != null),
    launchAtCreation: json.launchAtCreation,
  }
}

// Mixins function - pour permettre le paramétrage en amont des spécifités des features
const withExclusiveFeatures =
  (exclusiveFeatures: { [featName: string]: [string] }) => (feat) => ({
    ...feat,
    exclusiveFeatures: exclusiveFeatures[feat.name] || [],
  })

const withOnCheck =
  (onCheckFunc: { [featName: string]: unknown }) => (feat) => ({
    ...feat,
    onCheck: onCheckFunc[feat.name],
  })

const withOnActivate =
  (onActivateFunc: { [featName: string]: unknown }) => (feat) => ({
    ...feat,
    onActivate: onActivateFunc[feat.name],
  })

const withLaunchAtExecution =
  (launchAtCreation: { [featName: string]: boolean }) => (feat) => ({
    ...feat,
    launchAtCreation: launchAtCreation[feat.name],
  })

const withCreateRecurringJobs =
  (createRecurringJob: { [featName: string]: boolean }) => (feat) => ({
    ...feat,
    createRecurringJob: createRecurringJob[feat.name],
  })

const withUpdatePropertiesOrigin =
  (updatePropertiesOrigin: { [featName: string]: boolean }) => (feat) => ({
    ...feat,
    updatePropertiesOrigin: updatePropertiesOrigin[feat.name],
  })

const withUpdateFeature =
  (updateFeature: { [featName: string]: boolean }) => (feat) => ({
    ...feat,
    updateFeature: updateFeature[feat.name],
  })

// Factory Function - Encapsule les spécifités de chaque features
export const createFeatures = (featuresJson): Feature[] => {
  return featuresJson.map(
    pipe(
      mapFeature,
      // précise quelles features doivent être désactivées lorsqu'une feature est activée
      withExclusiveFeatures({ O365: ["GSuite"], GSuite: ["O365"] }),
      // précise une fonction à executer lorsque la feature est activée
      //  (pour appeler une API ou rediriger l'utilisateur sur le consent)
      withOnActivate({
        Salesforce: partial(redirectTo, config.salesforceUrlConsent),
        O365: partial(redirectTo, config.o365UrlConsent),
        GSuite: updateTenantFeaturePropertiesFromBmm,
        Gmail: updateTenantFeaturePropertiesFromBmm,
      }),
      withOnCheck({
        OWA: checkOWAParameters,
      }),
      withLaunchAtExecution({
        O365: false,
        GSuite: true,
        Api: false,
        Lucca: true,
        Salesforce: true,
        Sellsy: true,
      }),
      withCreateRecurringJobs({
        O365: false,
        GSuite: true,
        OWA: true,
        Gmail: true,
        Lucca: true,
        Salesforce: true,
        Api: true,
        Sellsy: true,
      }),
      withUpdatePropertiesOrigin({
        O365: false,
        GSuite: true,
        OWA: true,
        Gmail: false,
        Lucca: true,
        Salesforce: false,
        Sellsy: true,
      }),
      withUpdateFeature({
        O365: false,
        GSuite: true,
        OWA: true,
        Gmail: true,
        Lucca: true,
        Salesforce: true,
        Api: true,
        Sellsy: true,
      }),
    ),
  )
}
