import { FunctionComponent } from "react"

import Slider from "@material-ui/core/Slider"
import { withStyles } from "@material-ui/styles"
import { USERS_SLIDER_MARKS } from "features/Enrollment/EnrollmentConstants"
import { defineMessages, useIntl } from "react-intl"

interface UsersSliderProps {
  min: number
  max: number
  selectedValue: number
  onChange: (nbUser: number) => void
}

const SLIDER_FIXED_WIDTH = 450

const messages = defineMessages({
  nbUsersSingular: {
    id: "UsersSlider.MaxUsersNbUserSingular",
    defaultMessage: " utilisateur",
  },
  nbUsersPlural: {
    id: "UsersSlider.MaxUsersNbUsers",
    defaultMessage: " utilisateurs",
  },
})

const SliderWithStyles = withStyles({
  root: {
    color: "#D5E3FC",
    height: 5,
  },
  thumb: {
    height: 19,
    width: 19,
    border: "1px solid rgba(61,90,254,0.2)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 5px 6px 0 rgba(19,32,124,0.2)",
    marginLeft: -10,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "#3D5AFE",
    height: 5,
    borderRadius: 4,
  },
  rail: {
    opacity: 1,
    height: 5,
    borderRadius: 4,
  },
})(Slider)

const UsersSlider: FunctionComponent<UsersSliderProps> = ({
  min,
  max,
  selectedValue,
  onChange,
}) => {
  const { formatMessage } = useIntl()

  const leftMargin = (selectedValue * SLIDER_FIXED_WIDTH) / max

  const handleInputChange = (e) => {
    const inputValue = Number(e.target.value)

    if (isNaN(inputValue)) return
    if (inputValue < min) return onChange(min)
    if (inputValue > max) return onChange(max)

    onChange(inputValue)
  }

  return (
    <div className="users-slider__container">
      <div className="slider-container">
        <div className="slider-label active" style={{ left: leftMargin }}>
          {selectedValue}
          {formatMessage(
            selectedValue <= 1
              ? messages.nbUsersSingular
              : messages.nbUsersPlural,
          )}
        </div>
        <SliderWithStyles
          className="users-slider"
          min={min}
          max={max}
          step={1}
          defaultValue={min}
          value={selectedValue}
          onChange={(_event, value: number) => onChange(value)}
          marks={USERS_SLIDER_MARKS}
        />
      </div>
      <div className="users-slider__input--container">
        <input
          type="text"
          value={selectedValue}
          onChange={handleInputChange}
          className="users-slider__input--field"
        />
        <p className="users-slider__input--label">
          {formatMessage(
            selectedValue <= 1
              ? messages.nbUsersSingular
              : messages.nbUsersPlural,
          )}
        </p>
      </div>
    </div>
  )
}

export default UsersSlider
