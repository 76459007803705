import { call, put, takeEvery } from "redux-saga/effects"

import {
  fetchAllAlertsApi,
  updateAlertApi
} from "./AlertsApi"

import {
  fetchAllAlerts,
  fetchAllAlertsSuccess,
  fetchAllAlertsFailure,
  updateAlert,
  updateAlertSuccess,
  updateAlertFailure
} from "./AlertsReducer"

import { Alert } from "./AlertsModels"
import { withSagaErrorHandler } from "features/Errors"
import { TypedResult } from "core/CoreModels"

function* getAllAlertsAsync() {
  const response: TypedResult<Alert[]> = yield call(fetchAllAlertsApi)

  if (!response.success) throw new Error(response.errorMessage)
  yield put(fetchAllAlertsSuccess(response.result))
}

function* updateAlertAsync(action) {
  const response: TypedResult<unknown> = yield call(updateAlertApi,action.payload)
  if (!response.success) throw new Error(response.errorMessage)
  yield put(updateAlertSuccess(action.payload))
}

export const alertsWatchers = [
  takeEvery(
    fetchAllAlerts,
    withSagaErrorHandler(getAllAlertsAsync, fetchAllAlertsFailure()),
  ),
  takeEvery(
    updateAlert,
    withSagaErrorHandler(updateAlertAsync, updateAlertFailure()),
  ),
]
