import { FunctionComponent, useState } from "react"

import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router"

import { ModalActions } from "components/Modal"

import { ReactComponent as SubsidiaryDuotoneIcon } from "assets/duotone/icon-subsidiary-duotone-type2.svg"
import { ReactComponent as SubsidiaryIcon } from "assets/icon-subsidiary.svg"
import { ReactComponent as ChevronRightIcon } from "assets/icon-chevron-right.svg"

import { SUBSIDIARIES_URL } from "router/RouterConstants"

interface NoSubsidiaryModalProps {
  onCancel: () => void
}

const messages = defineMessages({
  add: {
    id: "AdminsGrid.ToggleSubsidiaryModalAddSubisdiary",
    defaultMessage: "Ajouter une nouvelle filiale",
  },
  try: {
    id: "AdminsGrid.ToggleSubsidiaryModalTrytoAddAdmin",
    defaultMessage: "Vous essayez d’ajouter un administrateur à une filiale.",
  },
  noSubsidiary: {
    id: "AdminsGrid.ToggleSubsidiaryModalNoSubsidiary",
    defaultMessage: "Aucune filiale n’est pour l'instant paramétrée.",
  },
  create: {
    id: "AdminsGrid.ToggleSubsidiaryModalCreateASubsidiary",
    defaultMessage: "Souhaitez-vous créer une filiale ?",
  },
})

const NoSubsidiaryModal: FunctionComponent<NoSubsidiaryModalProps> = ({
  onCancel,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <div className="no-subsidiary-modal">
      <div className="no-subsidiary-modal__content">
        <p className="no-subsidiary-modal__content--label">
          {formatMessage(messages.try)}
        </p>
        <p className="no-subsidiary-modal__content--label">
          {formatMessage(messages.noSubsidiary)}
        </p>
        <p className="no-subsidiary-modal__content--label">
          {formatMessage(messages.create)}
        </p>
      </div>
      <ModalActions>
        <ModalActions.Secondary
          onClick={() => {
            navigate(`${SUBSIDIARIES_URL}/edit`)
            onCancel()
          }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className="no-subsidiary-modal__actions--container">
            {isHovering ? (
              <SubsidiaryIcon className="no-subsidiary-modal__actions--sub-icon" />
            ) : (
              <SubsidiaryDuotoneIcon className="no-subsidiary-modal__actions--sub-icon" />
            )}
            <p
              className={classNames(
                "no-subsidiary-modal__actions--label",
                isHovering && "is-hovering",
              )}
            >
              {formatMessage(messages.add)}
            </p>
            <ChevronRightIcon className="no-subsidiary-modal__actions--cta-icon" />
          </div>
        </ModalActions.Secondary>
      </ModalActions>
    </div>
  )
}

export default NoSubsidiaryModal
