import { FunctionComponent, ReactNode } from "react"

import { defineMessages, useIntl } from "react-intl"

import HoverTooltip from "components/HoverTooltip"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface KeyPerformanceIndicatorBlockProps {
  mainValue: number | string
  title: string
  icon: ReactNode
  mainLabel: string
  mainTimeTrack: string
  secondaryLabel?: string
  secondaryValue?: number
  secondaryValueIsLoading?: boolean
  tooltip?: string
  isDisabled?: boolean
}

const messages = defineMessages({
  unavailable: {
    id: "Tracking.TrackingKPIUnavailable",
    defaultMessage: "Données indisponibles",
  },
})

const KeyPerformanceIndicatorBlock: FunctionComponent<
  KeyPerformanceIndicatorBlockProps
> = ({
  mainValue,
  title,
  icon,
  mainLabel,
  mainTimeTrack,
  secondaryLabel,
  secondaryValue,
  tooltip,
  secondaryValueIsLoading = false,
  isDisabled = false,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="kpi-block">
      {isDisabled && (
        <div className="kpi-block__disabled">
          <p className="kpi-block__disabled--label">
            {formatMessage(messages.unavailable)}
          </p>
        </div>
      )}
      {tooltip && (
        <div className="kpi-block__tooltip">
          <HoverTooltip tooltip={tooltip}>
            <InfoIcon />
          </HoverTooltip>
        </div>
      )}
      <div className="kpi-block__title">
        <div className="kpi-block__title--icon">{icon}</div>
        <p className="kpi-block__title--label">{title}</p>
      </div>
      <div className="kpi-block__content">
        <div className="kpi-block__main">
          <p className="kpi-block__main--value">{mainValue}</p>
          <p className="kpi-block__main--label">{mainLabel}</p>
          <p className="kpi-block__main--time-track">{mainTimeTrack}</p>
        </div>
        {secondaryLabel !== undefined && secondaryValue !== undefined && (
          <>
            <div className="kpi-block__divider">
              <p className="kpi-block__divider--value">/</p>
            </div>
            <div className="kpi-block__secondary">
              {secondaryValueIsLoading ? (
                <div className="kpi-block__skeleton--secondary-value" />
              ) : (
                <p className="kpi-block__secondary--value">{secondaryValue}</p>
              )}

              <p className="kpi-block__secondary--label">{secondaryLabel}</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default KeyPerformanceIndicatorBlock
