import { FunctionComponent, useMemo } from "react"

import { useSelector } from "react-redux"

import { LAST_REGISTER_STEP } from "features/Accounts/AccountsConstants"

import { accountsSelectors } from "features/Accounts"

const ProgressiveAccountInfosLogo: FunctionComponent = () => {
  const currentRegisterStep = useSelector(accountsSelectors.getRegisterStep)

  const fillLogo = useMemo(() => {
    const fillPercent = currentRegisterStep * (100 / (LAST_REGISTER_STEP - 1))

    return fillPercent > 100 ? 100 : fillPercent
  }, [currentRegisterStep])

  return (
    <div className="progressive-account-infos-logo">
      <svg
        width="243"
        height="48"
        viewBox="0 0 243 48"
        xmlns="http://www.w3.org/2000/svg"
        fill="url(#Gradient-1)"
      >
        <defs>
          <linearGradient
            id="Gradient-1"
            x="0%"
            y="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={`${fillLogo}%`} stop-color="#2ad3c2" />
            <stop offset={`${fillLogo}%`} stop-color="#ffffff" />
          </linearGradient>
        </defs>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.8814 8.11079C19.8058 7.91094 21.7491 7.98896 23.6513 8.34245C33.1564 10.2595 39.9635 18.6526 39.877 28.3487V32.3906C39.8875 35.0659 38.2803 37.4824 35.8091 38.5072C33.3378 39.5319 30.4923 38.9619 28.6064 37.0642C28.3684 36.8286 28.1504 36.5736 27.9546 36.3019L27.634 35.8817L27.2354 36.2292C24.1302 38.9702 19.7521 39.7429 15.8963 38.2303C12.0405 36.7177 9.35548 33.1744 8.94231 29.0531C8.92748 28.9076 8.89111 28.3851 8.89111 28.2544V1.42901C10.2903 0.838825 11.7342 0.360979 13.2092 0V19.1982C15.655 17.3005 18.7872 16.5211 21.8371 17.0513C27.1949 18.0535 31.0576 22.761 30.9944 28.2113V32.3906C30.9859 32.9906 31.2246 33.5678 31.6543 33.9866C32.5532 34.8621 33.984 34.8687 34.8908 34.0014C35.3174 33.5742 35.5555 32.9943 35.5522 32.3906V27.9419C35.5411 19.3255 28.5585 12.3435 19.9421 12.3332C19.4138 12.3331 18.8859 12.3623 18.3608 12.4207C18.3487 12.4218 18.3365 12.4228 18.3243 12.4239C18.2568 12.4297 18.1896 12.4355 18.1292 12.4355C16.9816 12.432 16.0353 11.5355 15.9697 10.3898C15.904 9.24415 16.7418 8.24545 17.8814 8.11079ZM19.5017 34.6554C22.8698 34.8763 25.8801 32.5668 26.539 29.2564C26.9848 27.0516 26.2966 24.77 24.706 23.1793C23.1154 21.5887 20.8338 20.9005 18.6289 21.3463C15.3185 22.0052 13.009 25.0154 13.2299 28.3836C13.4507 31.7517 16.1336 34.4345 19.5017 34.6554Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.7465 41.4692C21.7968 44.9094 14.2968 44.0521 9.27701 39.3579C4.2572 34.6637 2.89953 27.238 5.93361 21.0713C6.0735 20.7931 6.14741 20.4865 6.14957 20.1752V20.1721C6.14707 19.1563 5.44193 18.2774 4.4508 18.0547C3.45967 17.8321 2.44638 18.3249 2.00963 19.2421C0.684098 21.9513 -0.00334987 24.9281 1.22733e-05 27.9441C0.00392954 35.0313 3.76734 41.5844 9.8867 45.1594C16.006 48.7345 23.5627 48.7948 29.7383 45.3179C30.782 44.7755 31.2414 43.528 30.799 42.4382C30.544 41.8846 30.0676 41.464 29.4867 41.2796C28.9058 41.0952 28.2741 41.164 27.7465 41.4692Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.972 25.837C115.833 25.0953 115.156 24.5786 114.404 24.6403C114 24.5881 113.593 24.7125 113.287 24.9818C112.981 25.251 112.806 25.6391 112.807 26.0466C112.775 27.1525 114.282 27.4215 115.112 27.6609C117.847 28.468 120.429 29.3659 120.429 32.6852C120.448 34.1955 119.823 35.6425 118.709 36.663C117.48 37.738 115.914 38.3514 114.282 38.3977C112.694 38.485 111.138 37.9225 109.972 36.8395C108.807 35.7565 108.132 34.2461 108.102 32.6554H112.283C112.429 33.7287 113.353 34.5246 114.436 34.5107C114.902 34.5694 115.372 34.4321 115.733 34.1314C116.094 33.8308 116.315 33.3937 116.342 32.9245C116.342 31.5496 114.528 31.1303 113.452 30.7721C112.334 30.524 111.285 30.0338 110.377 29.3362C109.306 28.5073 108.691 27.2205 108.718 25.8667C108.718 22.7868 111.453 20.7533 114.467 20.7533C117.365 20.7042 119.789 22.944 119.968 25.837L115.972 25.837Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M56.6752 22.2257C58.0038 21.3242 59.5645 20.8255 61.1697 20.7897L61.1697 20.7897C63.535 20.6627 65.836 21.5841 67.46 23.3085C70.9017 26.6921 70.9884 32.2124 67.6547 35.7025C66.1114 37.3691 63.9589 38.3397 61.688 38.3929C61.5774 38.3962 61.4668 38.3979 61.3562 38.3979C56.5524 38.3011 52.7197 34.3589 52.7585 29.5542V15.8859H56.6752V22.2257ZM64.8092 32.9309C65.6953 31.9982 66.1704 30.749 66.128 29.4631L66.1279 29.4631C66.1252 28.1918 65.6035 26.9768 64.6837 26.0993C63.82 25.1736 62.6038 24.6582 61.338 24.6814C60.1226 24.6579 58.9559 25.1588 58.1359 26.0563C56.2566 28.017 56.2566 31.1105 58.1359 33.0712C59.0163 34.032 60.2811 34.5475 61.5823 34.4758C62.8251 34.4287 63.9931 33.8695 64.8092 32.9309Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M73.7064 23.3299C75.3288 21.6041 77.6258 20.6758 79.9917 20.7896C82.2716 20.8405 84.4307 21.8244 85.9651 23.5114C89.295 26.995 89.2023 32.5093 85.7571 35.879C84.2051 37.4991 82.0553 38.4099 79.8118 38.3978C79.6996 38.3978 79.5874 38.3961 79.4751 38.3928C77.2032 38.3631 75.0438 37.3989 73.505 35.7272C70.2065 32.221 70.2958 26.727 73.7064 23.3299ZM83.0188 33.0781C83.9782 32.1237 84.4993 30.8144 84.4581 29.4617L84.4581 29.4618C84.431 28.1933 83.9175 26.9837 83.0238 26.0831C82.1914 25.1786 81.0094 24.6769 79.7804 24.7065H79.759C78.5189 24.6934 77.3296 25.1983 76.4777 26.0996C75.5632 26.9897 75.0507 28.2138 75.0582 29.4898C75.0095 30.7718 75.4747 32.0202 76.3506 32.9576C77.1596 33.8971 78.3297 34.4486 79.5692 34.4745L79.6055 34.4778C80.8997 34.5715 82.163 34.0535 83.0188 33.0781Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.0112 23.3297C93.6341 21.6047 95.9308 20.6766 98.2965 20.7895V20.7895C100.576 20.8407 102.735 21.8245 104.27 23.5112C107.6 26.9949 107.507 32.5092 104.062 35.8789C102.51 37.499 100.36 38.4097 98.1166 38.3976C98.0043 38.3976 97.8921 38.396 97.7782 38.3927C95.5068 38.3626 93.3481 37.3984 91.8098 35.727C88.5091 32.2218 88.5983 26.726 92.0112 23.3297ZM101.324 33.0781C102.283 32.1237 102.804 30.8144 102.763 29.4617L102.763 29.4618C102.736 28.1933 102.222 26.9837 101.329 26.0831C100.493 25.1697 99.3008 24.6668 98.0638 24.7065C96.8237 24.6934 95.6344 25.1983 94.7825 26.0996C93.868 26.9896 93.3555 28.2137 93.363 29.4898C93.3142 30.7718 93.7795 32.0202 94.6554 32.9576C95.4643 33.8971 96.6344 34.4485 97.8739 34.4745L97.9103 34.4778C99.2044 34.571 100.468 34.053 101.324 33.0781Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M126.512 15.9132H122.541V32.7505C122.541 37.6362 126.57 38.3657 128.972 38.3657H129.947V34.7626H128.972C127.225 34.7626 126.512 34.0809 126.512 32.4089V24.9434H129.947V21.1306H126.512V15.9132Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.393 20.8458C155.39 20.6241 153.41 21.422 152.121 22.97C150.832 21.4221 148.852 20.6242 146.85 20.8458C145.116 20.9329 143.482 21.6883 142.292 22.9535C141.008 24.3663 140.336 26.2295 140.421 28.1363V38.0529H144.444V27.9002C144.41 27.0523 144.677 26.2198 145.198 25.5498C145.632 24.9783 146.317 24.6542 147.034 24.6817C147.844 24.6661 148.629 24.9648 149.224 25.5152C149.799 25.9917 150.121 26.708 150.095 27.4546V38.0529H154.144V27.4547C154.122 26.7143 154.432 26.0028 154.989 25.5152C155.597 24.9678 156.388 24.6701 157.206 24.6817C157.93 24.66 158.621 24.982 159.069 25.5499C159.596 26.2183 159.871 27.0499 159.849 27.9003V38.0529H163.871V28.1363C163.949 26.2264 163.269 24.3632 161.978 22.9535C160.771 21.6957 159.133 20.9424 157.393 20.8458Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M173.115 31.4286L168.979 21.1553H164.709L170.811 36.6022L168.169 43.2689H172.404L181.692 21.1553H177.479L173.115 31.4286Z"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M205.108 20.8453C203.106 20.626 201.127 21.4234 199.836 22.9696C198.548 21.4208 196.568 20.6227 194.566 20.8453C192.832 20.9332 191.198 21.6884 190.007 22.953C188.724 24.3658 188.052 26.229 188.137 28.1358V38.0523H192.161V27.8998C192.127 27.052 192.394 26.2195 192.914 25.5494C193.347 24.9778 194.033 24.6537 194.749 24.6812C195.56 24.6655 196.345 24.9649 196.94 25.5164C197.516 25.9917 197.838 26.7078 197.811 27.4541V38.0523H201.86V27.4541C201.838 26.7143 202.148 26.0035 202.705 25.5164C203.312 24.9687 204.104 24.6704 204.922 24.6812C205.645 24.6591 206.337 24.9812 206.785 25.5494C207.311 26.218 207.587 27.0494 207.564 27.8998V38.0523H211.587V28.1358C211.665 26.226 210.984 24.3629 209.694 22.953C208.487 21.6952 206.849 20.9419 205.108 20.8453"
          fill="url(#Gradient-1)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M221.505 20.7892C223.796 20.8438 225.967 21.8225 227.525 23.5027V23.5027C229.141 25.1142 230.001 27.3347 229.89 29.6147V38.0524H225.999V36.9349C224.618 37.9041 222.968 38.4156 221.281 38.3973C221.177 38.3973 221.071 38.3957 220.967 38.3924C218.702 38.3366 216.557 37.366 215.02 35.7019C211.696 32.2094 211.774 26.7004 215.195 23.303C216.83 21.5837 219.135 20.6653 221.505 20.7892ZM224.512 33.0707C225.473 32.1097 225.993 30.7929 225.947 29.4345V29.4345C225.94 28.1674 225.434 26.9539 224.539 26.0575C223.72 25.159 222.554 24.6572 221.338 24.6809C220.069 24.6549 218.846 25.1599 217.965 26.074C217.049 26.9628 216.537 28.1869 216.547 29.4626C216.497 30.7447 216.962 31.9935 217.839 32.9304C218.658 33.8711 219.83 34.4304 221.076 34.4753C222.376 34.5563 223.641 34.039 224.512 33.0707Z"
          fill="url(#Gradient-1)"
        />
        <rect
          x="232.517"
          y="21.1553"
          width="3.91843"
          height="16.8968"
          fill="url(#Gradient-1)"
        />
        <rect
          x="239.055"
          y="15.8871"
          width="3.94483"
          height="22.1653"
          fill="url(#Gradient-1)"
        />
        <rect
          x="232.516"
          y="15.8871"
          width="3.91843"
          height="3.91677"
          fill="url(#Gradient-1)"
        />
      </svg>
    </div>
  )
}

export default ProgressiveAccountInfosLogo
