import * as _ from "lodash"

/**
 * Helpers for string manipulations
 *
 * @class StringHelper
 */

class StringHelper {
  public static Capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  /**
   * Vérifie que le paramètre respecte le format d'une adresse email valide.
   * @param email
   */
  public static validateEmail(email: string): boolean {
    const trimedEmail = email.trim()
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(trimedEmail).toLowerCase())
  }

  /**
   * Vérifie que le paramètre respecte le format d'un numéro de téléphone valide.
   * @param phone
   */
  public static validatePhone(phone): boolean {
    const trimedPhone = phone.trim()
    const re =
      /^((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/
    return re.test(String(trimedPhone).toLowerCase())
  }

  public static validateLength(chain: string, maxLength = 8): boolean {
    return chain.length >= maxLength
  }

  public static validateLowerChar(chain: string, nbLower = 1): boolean {
    return (chain.match(/[a-z]/g) || []).length >= nbLower
  }

  public static validateUpperChar(chain: string, nbUpper = 1): boolean {
    return (chain.match(/[A-Z]/g) || []).length >= nbUpper
  }

  public static validateOnlyNumbers(chain): boolean {
    const regex = /^\d+$/
    return regex.test(chain)
  }

  public static validateNbNumbers(chain: string, nbNumber = 1): boolean {
    return (chain.match(/[0-9]/g) || []).length >= nbNumber
  }

  /**
   * Vérifie que le paramètre respecte le format d'une adresse password valide.
   * @param password
   */
  public static validatePassword(password): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(password).toLowerCase())
  }

  /**
   * Vérifie que la chaine ne contient pas de caractères invalides.
   * Retourne `true` si la chaine ne contient pas de caractères spéciaux
   * @param chain
   */
  public static validateNoSpecialChar(chain: string) {
    //on teste la non-présence des caractères spéciaux
    // @#$[]{};'"\/
    const regex = /[@#$*[\]{};'"\\/]/
    return !regex.test(chain)
  }

  /**
   * Replace all occurence between ?#<Property>#?
   * @param chain
   * @param replaceBy
   */
  public static ReplaceTags(
    chain: string,
    regex: RegExp,
    replaceBy: (occurence: string) => string,
  ) {
    const template = _.replace(chain, new RegExp(regex, "g"), replaceBy)
    return template
  }

  /**
   * Replace all occurence between ?#<Property>#?
   * @param chain
   * @param replaceBy
   */
  public static FindTags(chain: string, regex: RegExp) {
    const tags = []
    let matches = regex.exec(chain)
    while (matches != null) {
      tags.push(matches)
      matches = regex.exec(chain)
    }

    // Chaque "match" est constituée de [ <expression parsée>, <groupe>, <index>, <expression entière> ]
    return tags.map((match) => match[1])
  }

  public static GetImageSrc(imgMarkup: string) {
    /**
     * REGEXP
     *  - recupère tous les éléments <img />
     *  - isole le contenu dans l'attribue src -- src="(.*)"
     *  - (indépendamment des quotes simples / double) -- [\"\']
     */
    const regex = /<img .*src=["'](.*?)["'].*>/
    const match = imgMarkup.match(regex)
    if (match != null && match.length > 0) return match[1]
  }

  public static async writeToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  public static truncateText(text: string, maxLength = 25): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }

  static validateDomain(str: string) {
    /* eslint-disable-next-line no-useless-escape */
    return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/gi.test(
      str,
    )
  }
}

export default StringHelper
