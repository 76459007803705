import { FunctionComponent, useRef } from "react"
import { useSelector } from "react-redux"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import Toggle from "components/MUIToggle"
import LinkButton from "components/LinkButton"

import { accountsSelectors } from "features/Accounts"

import Feature from "features/Features/FeaturesModels"

interface ApiFormProps {
  featureApi: Feature
  updateFeature: (feature: Feature) => void
}

const messages = defineMessages({
  downloadScript: {
    id: "ApiForm.DownloadScript",
    defaultMessage: "Télécharger le script Powershell",
  },
})

const ApiForm: FunctionComponent<ApiFormProps> = ({
  featureApi,
  updateFeature,
}) => {
  const { formatMessage } = useIntl()

  const refApiKey = useRef(null)

  const apikey = useSelector(accountsSelectors.getClientApiKey)
  const scriptLink = useSelector(accountsSelectors.getPowershellLink)

  const handleSync = (isActive) => {
    featureApi.activated = isActive
    updateFeature(featureApi)
  }

  const handleCopyClipboard = () => {
    refApiKey.current.select()
    navigator.clipboard.writeText(apikey)
  }

  return (
    <div className="api-form">
      <div className="api-form__container">
        <div className="api-form__title">
          <FormattedMessage
            id="ApiForm.Title"
            defaultMessage="Annuaire utilisateurs"
          />
        </div>
        <p className="api-form__description">
          <FormattedMessage
            id="ApiForm.Description1"
            defaultMessage="Activez la synchronisation automatique de votre annuaire utilisateurs "
          />
          <span className="api-form__description--bold">
            <FormattedMessage
              id="ApiForm.Description2"
              defaultMessage="toutes les 24 heures"
            />
          </span>
        </p>
        <div className="api-form__action">
          <div
            className={`${
              featureApi.activated
                ? "api-form__action--inactive"
                : "api-form__action--active"
            }`}
          >
            <FormattedMessage
              id="ApiForm.Disable"
              defaultMessage="Désactivée"
            />
          </div>
          <div className="api-form__toggle">
            <Toggle
              checked={featureApi.activated}
              onChange={(evt, isActive) => handleSync(isActive)}
            />
          </div>
          <div
            className={`${
              !featureApi.activated
                ? "api-form__action--inactive"
                : "api-form__action--active"
            }`}
          >
            <FormattedMessage id="ApiForm.Enable" defaultMessage="Activée" />
          </div>
        </div>
        <p>
          <FormattedMessage
            id="ApiForm.Key"
            defaultMessage="Votre clé de sécurité :"
          />
        </p>
        <div className="api-form__apikey">
          <input
            type="text"
            className="api-form__apikey__input"
            defaultValue={apikey}
            ref={refApiKey}
            readOnly
          />
          <div className="api-form__apikey__copy">
            <div onClick={handleCopyClipboard}>
              <FormattedMessage
                id="Installer.Key"
                defaultMessage="Copier la clé"
              />
            </div>
          </div>
        </div>
        <div className="api-form__link">
          <LinkButton
            label={formatMessage(messages.downloadScript)}
            href={scriptLink}
            disabled={!scriptLink}
          />
        </div>
      </div>
    </div>
  )
}

export default ApiForm
