/**
 * Etats d'un job, copie des états coté Server
 */

export enum JobState {
  Enqueued,
  Scheduled,
  Processing,
  Succeeded,
  Failed,
  NotDefined,
  Done,
}

/**
 * Informations retournées par le serveur concernant un job
 */

export class JobData {
  jobId: string
  jobName: string
  state: JobState
  startDate: string
  retry: number
  percent: number
  open = true
}

export interface JobSendPreviewDto {
  tenantId: number
  adminMail: string
  signatureId: number
  recepientsEmails: string[]
}
