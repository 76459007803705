import { FunctionComponent, HTMLAttributes, ReactNode } from "react"

import classNames from "classnames"

export interface HomePageTileDatasContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  isColumn?: boolean
  className?: string
}

export type HomePageTileDatasContainerType =
  FunctionComponent<HomePageTileDatasContainerProps>

const HomePageTileDatasContainer: HomePageTileDatasContainerType = ({
  children,
  isColumn,
  className,
  ...others
}) => {
  return (
    <div
      className={classNames(
        "home-tile__datas",
        isColumn && "is-column",
        className,
      )}
      {...others}
    >
      {children}
    </div>
  )
}

export default HomePageTileDatasContainer
