import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import {
  CheckPropertiesDto,
  PartialUser,
  PreviewTemplate,
  User,
  UserFilter,
  UserProperty,
} from "features/Users/UserModels"
import { LoadingStatus, SavingStatus } from "core/CoreModels"

import { ImportReport } from "features/Users/UserModels"

import { GlobalStates } from "store"
import {
  SortOrder,
  SortOrders,
} from "components/PaginatedDataGrid/PaginatedDataGrid"

import Signature from "entities/Signature"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

export interface FetchPartialUsersActionPayload {
  pageSize: number
  pageNumber: number
  searchString: string
  orderBy: SortOrders
}

interface FetchPartialUsersSuccessActionPayload {
  partialUsers: Array<PartialUser>
  currentPage: number
  lastPage: number
  totalCount: number
  pageSize: number
}

export interface DynamicGroupFields {
  [id: string]: string | boolean
}

interface UsersEntities {
  [id: string]: User
}

interface PartialUsersEntities {
  [id: string]: PartialUser
}

interface UserPropertiesEntities {
  [id: string]: UserProperty
}

export interface UserState {
  allUsers: {
    entities: UsersEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  partialUsers: {
    entities: PartialUsersEntities
    ids: Array<EntityId>
    pageSize: number
    currentPage: number
    lastPage: number
    totalCount: number
    loadingStatus: LoadingStatus
    search: string
    orderBy: SortOrders
    activeLicencesCount: number
    previewMail: {
      loadingStatus: LoadingStatus
      templates: PreviewTemplate[]
    }
  }
  filter: UserFilter
  userProperties: {
    entities: UserPropertiesEntities
    ids: Array<EntityId>
    isChecking: boolean
    isLoading: boolean
  }
  sampleUser: User
  excelModelLink: string
  importReport: ImportReport | null
  activeUserId: number
  photo: string
  shouldRedirect: boolean
  editingUser: {
    loadingStatus: LoadingStatus
    savingStatus: SavingStatus
    entity: {
      user: User | null
      assignedSignatures: Array<Signature | BuilderSignature>
      defaultSignature: Signature | BuilderSignature | null
    }
  }
  dynamicUsers: {
    loadingStatus: LoadingStatus
    ids: number[]
  }
}

const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user.Id,
  sortComparer: (a, b) => a.Id - b.Id,
})

export const {
  selectById: selectUserById,
  selectAll: selectAllUsers,
  selectIds: selectAllUsersIds,
} = usersAdapter.getSelectors<GlobalStates>((state) => state.users.allUsers)

const partialUsersAdapter = createEntityAdapter<PartialUser>({
  selectId: (user) => user.id,
})

export const {
  selectById: selectPartialUserById,
  selectAll: selectAllPartialUsers,
} = partialUsersAdapter.getSelectors<GlobalStates>(
  (state) => state.users.partialUsers,
)

const userPropertiesAdapter = createEntityAdapter<UserProperty>({
  selectId: (userProperty) => userProperty.Id,
})

const initialState: UserState = {
  allUsers: usersAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  partialUsers: partialUsersAdapter.getInitialState({
    pageSize: 50,
    currentPage: 1,
    lastPage: 1,
    totalCount: 0,
    loadingStatus: LoadingStatus.NOT_STARTED,
    search: "",
    orderBy: [],
    activeLicencesCount: 0,
    previewMail: {
      loadingStatus: LoadingStatus.NOT_STARTED,
      templates: [],
    },
  }),
  filter: UserFilter.USERS,
  userProperties: userPropertiesAdapter.getInitialState({
    isChecking: false,
    isLoading: false,
  }),
  excelModelLink: "",
  importReport: null,
  sampleUser: null,
  activeUserId: null,
  photo: null,
  shouldRedirect: false,
  editingUser: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    savingStatus: SavingStatus.IDLE,
    entity: { user: null, assignedSignatures: [], defaultSignature: null },
  },
  dynamicUsers: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    ids: [],
  },
}

const usersReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkProperties(state, action: PayloadAction<Array<CheckPropertiesDto>>) {
      state.userProperties.isChecking = true
    },
    checkPropertiesSuccess(
      state,
      action: PayloadAction<{ [key: number]: UserProperty }>,
    ) {
      state.userProperties.isChecking = false
      userPropertiesAdapter.setAll(state.userProperties, action.payload)
    },
    checkPropertiesFailure(state) {
      state.userProperties.isChecking = false
    },
    fetchAllUsers(state) {
      state.allUsers.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllUsersSuccess(state, action: PayloadAction<Array<User>>) {
      usersAdapter.setAll(state.allUsers, action.payload)
      state.allUsers.loadingStatus = LoadingStatus.LOADED
    },
    fetchAllUsersFailure(state) {
      state.allUsers.loadingStatus = LoadingStatus.ERROR
    },
    setSampleUser(state, action: PayloadAction<User>) {
      state.sampleUser = action.payload
    },
    filterUsers(state, action: PayloadAction<UserFilter>) {
      state.filter = action.payload
    },
    searchUsers(state, action: PayloadAction<string>) {
      state.partialUsers.search = action.payload
    },
    setUser(state, action: PayloadAction<User>) {
      usersAdapter.setOne(state.allUsers, action.payload)
    },
    fetchProperties(state) {
      state.userProperties.isLoading = true
    },
    fetchPropertiesSuccess(state, action: PayloadAction<Array<UserProperty>>) {
      state.userProperties.isLoading = false
      userPropertiesAdapter.setAll(state.userProperties, action.payload)
    },
    fetchExcelModelSuccess(state, action: PayloadAction<string>) {
      state.excelModelLink = action.payload
    },
    uploadExcelFileSuccess(state, action: PayloadAction<ImportReport>) {
      state.importReport = action.payload
    },
    resetReport(state) {
      state.importReport = null
    },
    upsertUserSuccess(state, action: PayloadAction<User>) {
      usersAdapter.upsertOne(state.allUsers, action.payload)
    },
    deleteUserSuccess(state, action: PayloadAction<number>) {
      usersAdapter.removeOne(state.allUsers, action.payload.toString())
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    previewMail(state, _action: PayloadAction<number>) {
      state.partialUsers.previewMail.loadingStatus = LoadingStatus.PENDING
      state.partialUsers.previewMail.templates = []
    },
    previewMailSuccess(state, action: PayloadAction<PreviewTemplate[]>) {
      state.partialUsers.previewMail.loadingStatus = LoadingStatus.LOADED
      state.partialUsers.previewMail.templates = action.payload
    },
    previewMailFailure(state) {
      state.partialUsers.previewMail.loadingStatus = LoadingStatus.ERROR
    },
    shouldRedirect(state, action: PayloadAction<boolean>) {
      state.shouldRedirect = action.payload
    },
    fetchPartialUsers(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<FetchPartialUsersActionPayload>,
    ) {
      state.partialUsers.loadingStatus = LoadingStatus.PENDING
    },
    reloadPartialUsers(state) {
      state.partialUsers.loadingStatus = LoadingStatus.PENDING
    },
    fetchPartialUsersSuccess(
      state,
      action: PayloadAction<FetchPartialUsersSuccessActionPayload>,
    ) {
      partialUsersAdapter.setAll(
        state.partialUsers,
        action.payload.partialUsers,
      )
      state.partialUsers.lastPage = action.payload.lastPage
      state.partialUsers.totalCount = action.payload.totalCount
      state.partialUsers.loadingStatus = LoadingStatus.LOADED
    },
    setPartialUsersPageNumber(state, action: PayloadAction<number>) {
      state.partialUsers.currentPage = action.payload
    },
    setPartialUsersPageSize(state, action: PayloadAction<number>) {
      state.partialUsers.pageSize = action.payload
      state.partialUsers.currentPage = 1
    },
    setPartialUsersOrderBy(
      state,
      action: PayloadAction<{ columnKey: string; sortOrder: SortOrder }>,
    ) {
      state.partialUsers.orderBy = [action.payload]
    },
    addPartialUser(state, action: PayloadAction<PartialUser>) {
      partialUsersAdapter.addOne(state.partialUsers, action.payload)
      state.partialUsers.totalCount += 1
    },
    updatePartialUser(state, action: PayloadAction<PartialUser>) {
      partialUsersAdapter.upsertOne(state.partialUsers, action.payload)
      state.editingUser.savingStatus = SavingStatus.SUCCESS
    },
    fetchActiveLicencesCountSuccess(state, action: PayloadAction<number>) {
      state.partialUsers.activeLicencesCount = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchSingleUser(state, _action: PayloadAction<number>) {
      state.activeUserId = null
      state.editingUser.loadingStatus = LoadingStatus.PENDING
      state.editingUser.savingStatus = SavingStatus.IDLE
    },
    fetchSingleUserSuccess(
      state,
      action: PayloadAction<{
        user: User
        assignedSignatures: Signature[]
        defaultSignature: Signature | null
      }>,
    ) {
      state.editingUser.loadingStatus = LoadingStatus.LOADED
      state.editingUser.entity = action.payload
    },
    savingEditingUser(state) {
      state.editingUser.savingStatus = SavingStatus.SAVING
    },
    savingEditingUserFailure(state) {
      state.editingUser.savingStatus = SavingStatus.IDLE
    },
    resetEditingUser(state) {
      state.editingUser.loadingStatus = LoadingStatus.NOT_STARTED
      state.editingUser.savingStatus = SavingStatus.IDLE
      state.editingUser.entity = {
        user: null,
        assignedSignatures: [],
        defaultSignature: null,
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchDynamicUsers(state, _action: PayloadAction<DynamicGroupFields>) {
      state.dynamicUsers.loadingStatus = LoadingStatus.PENDING
      state.dynamicUsers.ids = []
    },
    fetchDynamicUsersSuccess(state, action: PayloadAction<number[]>) {
      state.dynamicUsers.loadingStatus = LoadingStatus.LOADED
      state.dynamicUsers.ids = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchDynamicUsersFailure(state, _action: PayloadAction<Error>) {
      state.dynamicUsers.loadingStatus = LoadingStatus.NOT_STARTED
    },
    resetDynamicUsers(state) {
      state.dynamicUsers.ids = []
      state.dynamicUsers.loadingStatus = LoadingStatus.NOT_STARTED
    },
  },
})

export const {
  checkProperties,
  checkPropertiesSuccess,
  checkPropertiesFailure,
  fetchAllUsers,
  fetchAllUsersSuccess,
  fetchAllUsersFailure,
  setSampleUser,
  filterUsers,
  searchUsers,
  setUser,
  fetchProperties,
  fetchPropertiesSuccess,
  fetchExcelModelSuccess,
  uploadExcelFileSuccess,
  resetReport,
  upsertUserSuccess,
  deleteUserSuccess,
  previewMail,
  previewMailSuccess,
  previewMailFailure,
  shouldRedirect,
  fetchPartialUsers,
  fetchPartialUsersSuccess,
  reloadPartialUsers,
  setPartialUsersPageNumber,
  setPartialUsersPageSize,
  setPartialUsersOrderBy,
  addPartialUser,
  updatePartialUser,
  fetchActiveLicencesCountSuccess,
  fetchSingleUser,
  fetchSingleUserSuccess,
  savingEditingUser,
  resetEditingUser,
  resetDynamicUsers,
  fetchDynamicUsers,
  fetchDynamicUsersFailure,
  fetchDynamicUsersSuccess,
  savingEditingUserFailure,
} = usersReducer.actions

export default usersReducer.reducer
