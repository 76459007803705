import { FunctionComponent } from "react"

import SignaturesTileActiveCount from "./SignaturesTileActiveCount"
import SignaturesTileActiveCountLoading from "./SignaturesTileActiveCountLoading"

import Tile from "features/Accounts/components/HomePageSingleTile"

import useSignaturesTileDatas from "../hooks/useSignaturesTileDatas"

import { LoadingStatus } from "core/CoreModels"

interface SignaturesTileAsRowDatasProps {
  to: string
  title: string
  addLabel: string
  label: string
  addSignature: () => void
}

const SignaturesTileAsRowDatas: FunctionComponent<
  SignaturesTileAsRowDatasProps
> = ({ to, title, addLabel, label, addSignature }) => {
  const { loadingStatus, count } = useSignaturesTileDatas()

  if (count.all === 0 && loadingStatus === LoadingStatus.LOADED)
    return (
      <Tile.AddButton to={to} isColumn onClick={addSignature}>
        {title}
      </Tile.AddButton>
    )

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <>
        <SignaturesTileActiveCountLoading>
          {label}
        </SignaturesTileActiveCountLoading>
        <div className="signature-tile__datas--add-btn-container">
          <Tile.AddButton isFullsize to={to} isColumn onClick={addSignature}>
            {addLabel}
          </Tile.AddButton>
        </div>
      </>
    )

  return (
    <>
      <SignaturesTileActiveCount activeCount={count.active}>
        {label}
      </SignaturesTileActiveCount>
      <div className="signature-tile__datas--add-btn-container">
        <Tile.AddButton isFullsize to={to} isColumn onClick={addSignature}>
          {addLabel}
        </Tile.AddButton>
      </div>
    </>
  )
}

export default SignaturesTileAsRowDatas
