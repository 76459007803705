import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { isSpan } from "utils/I18nHelpers"

import Toggle from "components/Toggle"
import { ModalActions } from "components/Modal"

import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

import { AdminAccount } from "features/Accounts/AccountsModels"

interface ToggleSubsidiaryModalProps {
  adminAccount: AdminAccount
  onToggle: (subsidiariesIds: number[]) => void
  onCancel: () => void
}

const messages = defineMessages({
  cancel: {
    id: "AdminsGrid.ToggleSubsidiaryModalCancel",
    defaultMessage: "Annuler",
  },
  toggle: {
    id: "AdminsGrid.ToggleSubsidiaryModalToggle",
    defaultMessage: "Basculer",
  },
  label: {
    id: "AdminsGrid.ToggleSubsidiaryModalLabel",
    defaultMessage:
      "Sur quelle filiale souhaitez-vous associer l’administrateur <sp>{name}</sp> ?",
  },
  disclaimer: {
    id: "AdminsGrid.ToggleSubsidiaryModalSuperAdminDisclaimer",
    defaultMessage:
      "Cet utilisateur est un SUPER ADMINISTRATEUR, cette action va retirer son rôle de super administeur.",
  },
})

const SubsidiaryItem = ({ subsidiary, handleChange }) => (
  <div className="toggle-subsidiary-modal__list--element">
    <img
      className="toggle-subsidiary-modal__list--img"
      src={subsidiary.iconUrl}
    />
    <p className="toggle-subsidiary-modal__list--label">{subsidiary.name}</p>
    <div className="toggle-subsidiary-modal__list--ctn-toggle">
      <Toggle
        className="toggle-subsidiary-modal__list--toggle"
        name={`${subsidiary.name}-${subsidiary.id}`}
        onChange={handleChange(subsidiary.id)}
      />
    </div>
  </div>
)

const ToggleSubsidiaryModal: FunctionComponent<ToggleSubsidiaryModalProps> = ({
  adminAccount,
  onToggle,
  onCancel,
}) => {
  const [subsidiariesIds, setSubsidiariesIds] = useState<number[]>([])
  const { formatMessage } = useIntl()
  const { subsidiaries } = useSubsidiariesData()

  const addToSubsidiariesIds = (id: number) => {
    setSubsidiariesIds((prevState) =>
      prevState.includes(id) ? prevState : [...prevState, id],
    )
  }

  const substractToSubsidiariesIds = (id: number) => {
    setSubsidiariesIds((prevState) => prevState.filter((v) => v !== id))
  }

  const handleChange = (id: number) => (toggle: boolean) => {
    if (toggle) return addToSubsidiariesIds(id)
    return substractToSubsidiariesIds(id)
  }

  const disclaimer = (
    <div className="toggle-subsidiary-modal__disclaimer">
      <InfoIcon className="toggle-subsidiary-modal__disclaimer--icon" />
      <p className="toggle-subsidiary-modal__disclaimer--label">
        {formatMessage(messages.disclaimer)}
      </p>
    </div>
  )

  return (
    <div className="toggle-subsidiary-modal">
      <div className="toggle-subsidiary-modal__content">
        <p className="toggle-subsidiary-modal__content--label">
          {formatMessage(messages.label, {
            name: adminAccount.userName,
            sp: isSpan,
          })}
        </p>
        <div className="toggle-subsidiary-modal__list">
          {subsidiaries.map((s) => (
            <SubsidiaryItem
              key={s.id}
              subsidiary={s}
              handleChange={handleChange}
            />
          ))}
        </div>
        {adminAccount.isSuperAdmin() && disclaimer}
      </div>
      <ModalActions>
        <ModalActions.Secondary onClick={onCancel}>
          {formatMessage(messages.cancel)}
        </ModalActions.Secondary>
        <ModalActions.Main onClick={() => onToggle(subsidiariesIds)}>
          {formatMessage(messages.toggle)}
        </ModalActions.Main>
      </ModalActions>
    </div>
  )
}

export default ToggleSubsidiaryModal
