import { UserDto } from "features/Users/UserModels"
import { User } from "features/Users/UserModels"
import { Origin } from "features/Users/UserModels"

export interface GroupDto {
  id: number
  name: string
  internalName: string
  activated: boolean
  members: UserDto[]
  origin: Origin
  modified: string
  query?: string
}

export interface GroupPayloadDto {
  id?: number
  Name: string
  Members: number[]
  Origin: Origin
  Query?: string
}

export interface GroupDtoWithMembersIds extends Omit<GroupDto, "members"> {
  membersId: number[]
  members: never
}

export class CreateGroupRequest {
  Name: string
  Members: number[]
  Origin: Origin
}

export class UpdateGroupRequest {
  Name: string
  Members: number[]
  Origin: Origin
}

export class PartialGroup {
  id: number
  name: string
  activated: boolean
  members: User[] = []
  membersId: number[] = []
  origin: Origin
  modified: string
  query?: string
  constructor(group: GroupDto | GroupDtoWithMembersIds) {
    const groupWithMembersIds = group as GroupDtoWithMembersIds

    this.id = group.id
    this.name = group.name
    this.activated = group.activated
    this.origin = group.origin
    this.modified = group.modified
    this.query = group.query
    if (group?.members) {
      this.members = group.members.map((u) => new User(u))
    }
    if (groupWithMembersIds?.membersId) {
      this.membersId = groupWithMembersIds.membersId
    }
  }
}

export class Group {
  Id: number
  Name: string
  InternalName: string
  Origin: Origin
  Activated: boolean
  Members: User[] = []
  MembersId: number[] = []
  query?: string

  constructor(group: GroupDto | GroupDtoWithMembersIds) {
    const groupWithMembersIds = group as GroupDtoWithMembersIds
    this.Id = group.id
    this.Name = group.name
    this.InternalName = group.internalName
    this.Origin = group.origin
    this.Activated = group.activated
    this.query = group.query
    if (group?.members) {
      this.Members = group.members.map((u) => new User(u))
    }
    if (groupWithMembersIds?.membersId) {
      this.MembersId = groupWithMembersIds.membersId
    }
  }
}

export default Group
