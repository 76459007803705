import React from "react"
import Dialog from "@material-ui/core/Dialog"

export interface Props {
  title: string
  open: boolean
  fullWidth?: boolean
  style?: {}
  actions?: any[]
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
  close()
}

export interface State {
  open: boolean
}

class BmmDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      open: props.open || false,
    }
  }

  componentWillReceiveProps(newProps: Props) {
    this.setState({ open: newProps.open })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.close()
  }

  render() {
    return (
      <Dialog
        style={this.props.style}
        open={this.state.open}
        maxWidth={this.props.maxWidth || false}
        onClose={this.handleClose}
      >
        {this.props.children}
      </Dialog>
    )
  }
}

export default BmmDialog
