import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import CodePromo from "entities/CodePromo"
import { EnrollmentDatas } from "./EnrollmentModels"
import { LoadingStatus } from "core/CoreModels"

const THRESHOLD_MIN = 1
const DEFAULT_SELECTED_OFFER_ID = 3 // Offre Premium

interface EnrollmentState {
  nbUsers: number
  credit: {
    isValidated: boolean
    isUpdateSuccess: boolean
    stripeError: string | null
    card: {
      id: string
      last4: string
      isdefault: boolean
    } | null
    sepa: {
      id: string
      holder: string
      email: string
      date: Date
      isdefault: boolean
    } | null
  }
  plan: {
    mode: string
    offerId?: number
  }
  enrollmentForm: {
    loadingStatus: LoadingStatus
    loading: boolean
    checkoutComplete: boolean
    billingComplete: false
    isValidating: boolean
    intentSecret: string
    isUpdate: boolean
    discount: {
      value: number
      isActive: boolean
    } | null
    wasTrial: boolean
    totalBilledAmount: number
    paymentMode: string
    currentStep: number
  }
  ui: {
    codepromo: CodePromo
    enrollmentSuccess: boolean
    newOfferInformations: null
  }
}

const initialState: EnrollmentState = {
  nbUsers: THRESHOLD_MIN,
  credit: {
    isValidated: false,
    stripeError: null,
    card: null,
    sepa: null,
    isUpdateSuccess: false,
  },
  plan: {
    mode: "annuel",
    offerId: DEFAULT_SELECTED_OFFER_ID,
  },
  enrollmentForm: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    loading: false,
    checkoutComplete: false,
    billingComplete: false,
    isValidating: false,
    intentSecret: null,
    discount: null,
    wasTrial: false,
    totalBilledAmount: null,
    isUpdate: false,
    paymentMode: null,
    currentStep: 1,
  },
  ui: {
    codepromo: null,
    enrollmentSuccess: false,
    newOfferInformations: null,
  },
}

const enrollmentReducer = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    saveStripePaymentFailure(state, action) {
      state.credit.stripeError = action.payload
    },
    createStripePaymentSuccess(state) {
      state.credit.isValidated = true
    },
    createStripePaymentFailure(state, action) {
      state.credit.isValidated = false
      state.credit.stripeError = action.payload
    },
    getStripePaymentMethodsSuccess(state, action) {
      state.credit.card = action.payload.card
      state.credit.sepa = action.payload.sepa
    },
    deleteStripePaymentMethodsSuccess(state) {
      state.credit.isUpdateSuccess = true
    },
    setStripePaymentMethodDefaultSuccess(state) {
      state.credit.isUpdateSuccess = true
    },
    updateStripePaymentMethodSuccess(state) {
      state.credit.isUpdateSuccess = true
    },
    updateStripePaymentMethodFailure(state, action) {
      state.credit.stripeError = action.payload
    },
    checkCodepromoSuccess(state, action) {
      state.ui.codepromo = action.payload
    },
    checkCodepromoFailure(state) {
      state.ui.codepromo = null
    },
    subscribeSuccess(state, action) {
      state.ui.enrollmentSuccess = action.payload.enrollmentSuccess
      state.ui.newOfferInformations = action.payload.newOfferInfos
    },
    checkedOutOffer(state, action) {
      state.enrollmentForm.wasTrial = action.payload.wasTrial
      state.enrollmentForm.totalBilledAmount = action.payload.totalBilledAmount
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    subscribe(state, _action: PayloadAction<EnrollmentDatas>) {
      state.enrollmentForm.isValidating = true
    },
    saveStripePayment(state, action) {
      state.enrollmentForm.paymentMode = action.payload.mode
    },
    askStripeValidation(state, action) {
      state.enrollmentForm.intentSecret = action.payload.intentSecret
      state.enrollmentForm.checkoutComplete = action.payload.complete
    },
    askStripeValidationUpdate(state, action) {
      state.enrollmentForm.intentSecret = action.payload.intentSecret
      state.enrollmentForm.checkoutComplete = action.payload.complete
      state.enrollmentForm.isUpdate = action.payload.isUpdate
    },
    initEnrollmentForm(state) {
      state.enrollmentForm.loadingStatus = LoadingStatus.PENDING
    },
    initEnrollmentFormSuccess(state) {
      state.enrollmentForm.loadingStatus = LoadingStatus.LOADED
    },
    updateEnrollmentStep(state, action: PayloadAction<number>) {
      state.enrollmentForm.currentStep = action.payload
    },
  },
})

export const {
  saveStripePaymentFailure,
  createStripePaymentSuccess,
  createStripePaymentFailure,
  getStripePaymentMethodsSuccess,
  deleteStripePaymentMethodsSuccess,
  setStripePaymentMethodDefaultSuccess,
  updateStripePaymentMethodSuccess,
  updateStripePaymentMethodFailure,
  checkCodepromoSuccess,
  checkCodepromoFailure,
  subscribeSuccess,
  checkedOutOffer,
  subscribe,
  saveStripePayment,
  askStripeValidation,
  askStripeValidationUpdate,
  initEnrollmentForm,
  initEnrollmentFormSuccess,
  updateEnrollmentStep,
} = enrollmentReducer.actions

export default enrollmentReducer.reducer
