import { FunctionComponent, ReactNode, RefObject } from "react"

import SignatureCardActions, {
  SignatureCardActionsType,
} from "./components/SignatureCardActions"
import SignatureCardAffectedCount, {
  SignatureCardAffectedCountType,
} from "./components/SignatureCardAffectedCount"
import SignatureCardAssignAction, {
  SignatureCardAssignActionType,
} from "./components/SignatureCardAssignAction"
import SignatureCardContent, {
  SignatureCardContentType,
} from "./components/SignatureCardContent"
import SignatureCardDate, {
  SignatureCardDateType,
} from "./components/SignatureCardDate"
import SignatureCardDeleteAction, {
  SignatureCardDeleteActionType,
} from "./components/SignatureCardDeleteAction"
import SignatureActionDuplicateAction, {
  SignatureActionDuplicateActionType,
} from "./components/SignatureCardDuplicateAction"
import SignatureCardEditAction, {
  SignatureCardEditActionType,
} from "./components/SignatureCardEditAction"
import SignatureCardInfos, {
  SignatureCardInfosType,
} from "./components/SignatureCardInfos"
import SignatureCardJsonPreview, {
  SignatureCardJsonPreviewType,
} from "./components/SignatureCardJsonPreview"
import SignatureCardName, {
  SignatureCardNameType,
} from "./components/SignatureCardName"
import SignatureCardPreview, {
  SignatureCardPreviewType,
} from "./components/SignatureCardPreview"
import SignatureCardToggle, {
  SignatureCardToggleType,
} from "./components/SignatureCardToggle"
import SignatureCardWeighting, {
  SignatureCardWeightingType,
} from "./components/SignatureCardWeighting"
import SignatureCardInfosExpanded, {
  SignatureCardInfosExpandedType,
} from "./components/SignatureCardInfosExpanded"

interface SignatureCardLayoutComposition {
  Content: SignatureCardContentType
  Preview: SignatureCardPreviewType
  JsonPreview: SignatureCardJsonPreviewType
  Infos: SignatureCardInfosType
  InfosExpanded: SignatureCardInfosExpandedType
  Name: SignatureCardNameType
  Date: SignatureCardDateType
  Toggle: SignatureCardToggleType
  Actions: SignatureCardActionsType
  AffectedCount: SignatureCardAffectedCountType
  Weighting: SignatureCardWeightingType
  Edit: SignatureCardEditActionType
  Assign: SignatureCardAssignActionType
  Duplicate: SignatureActionDuplicateActionType
  Delete: SignatureCardDeleteActionType
}

interface SignatureCardLayoutProps {
  children: ReactNode
  id: number | string
  cardRef?: RefObject<HTMLDivElement>
}

export type SignatureCardLayoutType =
  FunctionComponent<SignatureCardLayoutProps> & SignatureCardLayoutComposition

const SignatureCardLayout: SignatureCardLayoutType = ({
  children,
  id,
  cardRef,
}) => {
  const signatureId = `signature-card_${id}`

  return (
    <div
      className="signature-card"
      data-cy="signature-card"
      id={signatureId}
      ref={cardRef}
    >
      {children}
    </div>
  )
}

SignatureCardLayout.Content = SignatureCardContent
SignatureCardLayout.Preview = SignatureCardPreview
SignatureCardLayout.JsonPreview = SignatureCardJsonPreview
SignatureCardLayout.Infos = SignatureCardInfos
SignatureCardLayout.InfosExpanded = SignatureCardInfosExpanded
SignatureCardLayout.Name = SignatureCardName
SignatureCardLayout.Date = SignatureCardDate
SignatureCardLayout.Toggle = SignatureCardToggle
SignatureCardLayout.Actions = SignatureCardActions
SignatureCardLayout.AffectedCount = SignatureCardAffectedCount
SignatureCardLayout.Edit = SignatureCardEditAction
SignatureCardLayout.Assign = SignatureCardAssignAction
SignatureCardLayout.Duplicate = SignatureActionDuplicateAction
SignatureCardLayout.Delete = SignatureCardDeleteAction
SignatureCardLayout.Weighting = SignatureCardWeighting

export default SignatureCardLayout
