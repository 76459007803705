import { FunctionComponent } from "react"

import { useIntl } from "react-intl"
import classNames from "classnames"

import { getWeightingLabel } from "../Weighting"
import StringHelper from "utils/StringHelper"

export interface WeightingLabelProps {
  weighting: number
  shift?: boolean
}

const WeightingLabel: FunctionComponent<WeightingLabelProps> = ({
  weighting,
  shift = false,
}) => {
  const { formatMessage } = useIntl()

  return (
    <p className={classNames("weighting__label", shift && "shift")}>
      {StringHelper.Capitalize(formatMessage(getWeightingLabel(weighting)))}
    </p>
  )
}

export default WeightingLabel
