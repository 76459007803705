import { intersection } from "lodash"
import StringHelper from "utils/StringHelper"
import TourGuide, { TOURSGUIDE_PROPERTY_NAME } from "entities/TourGuide"
import TenantProperty from "entities/TenantProperty"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { featureNames } from "config/config.features"
import store from "store/configureStore"
import { LoadingStatus } from "core/CoreModels"

import { SendingModes } from "features/Signatures/SignaturesModels"

export enum Roles {
  BmmAdmin = "bmm-admin",
  ScenariosAdmin = "scenarios-admin",
  UsersAdmin = "users-admin",
  TenantAdmin = "tenant-admin",
  BillingAdmin = "billing-admin",
  TechnicalAdmin = "technical-admin",
  SignaturesAdmin = "signatures-admin",
  TrackingAdmin = "tracking-admin",
  SubsidiariesAdmin = "subsidiaries-admin",
}

interface AccountInformationsStep {
  stepRegister: number
}

interface AccountInformationsCompanyStep extends AccountInformationsStep {
  company: string
}

interface AccountInformationsEmployeesNumberStep
  extends AccountInformationsStep {
  employeesNumber: number
}

interface AccountInformationsFullNameStep extends AccountInformationsStep {
  firstname: string
  lastname: string
}

interface AccountInformationsPhoneNumberStep extends AccountInformationsStep {
  phoneNumber: string
}

interface AccountInformationsFunctionStep extends AccountInformationsStep {
  function: string
}

interface AccountInformationsCgsStep extends AccountInformationsStep {
  cgs: boolean
}

export type AccountInformations =
  | AccountInformationsStep
  | AccountInformationsCompanyStep
  | AccountInformationsEmployeesNumberStep
  | AccountInformationsFullNameStep
  | AccountInformationsPhoneNumberStep
  | AccountInformationsFunctionStep
  | AccountInformationsCgsStep

export interface AdminAccountDto {
  id: string
  company: string
  roles: Array<Roles>
  userName: string
}

interface DataGridAdminAccountVM extends AdminAccountDto {
  isNew: boolean
}

export interface RoleState {
  role: Roles
  isActive: boolean
}

export interface RolesState {
  roles: Roles[]
  isActive: boolean
}

export class AdminAccount {
  id: string
  company: string
  roles: Array<Roles>
  userName: string

  constructor(account: AdminAccountDto) {
    this.id = account.id
    this.company = account.company
    this.roles = account.roles
    this.userName = account.userName
  }

  private static rolesList() {
    return (Object.keys(Roles) as (keyof typeof Roles)[]).map(
      (key) => Roles[key],
    )
  }

  private static superAdminRolesList() {
    const state = store.getState()
    const rolesList = [
      Roles.ScenariosAdmin,
      Roles.UsersAdmin,
      Roles.BillingAdmin,
      Roles.TechnicalAdmin,
      Roles.SignaturesAdmin,
      Roles.TrackingAdmin,
      Roles.TenantAdmin,
    ]

    if (isFeatureActive(featureNames.SUBSIDIARIES)(state))
      rolesList.push(Roles.SubsidiariesAdmin)

    return rolesList
  }

  addRoles(roles: Roles[]): void {
    this.roles = [...this.roles, ...roles].reduce(
      (acc: Roles[], cur: Roles) => {
        if (!acc.includes(cur)) acc.push(cur)
        return acc
      },
      [],
    )
  }

  hasAccessTo(checkingRoles: Roles[]): RolesState {
    return {
      roles: checkingRoles,
      isActive: intersection(checkingRoles, this.roles).length > 0,
    }
  }

  toggleSuperAdmin(): void {
    if (this.isSuperAdmin()) {
      this.roles = this.roles.filter(
        (role) => !AdminAccount.superAdminRolesList().includes(role),
      )
    } else {
      this.roles = [...AdminAccount.superAdminRolesList(), ...this.roles]
    }
  }

  toggleRole(role: Roles): void {
    if (this.roles.includes(role)) {
      if (this.isSuperAdmin()) {
        this.roles = this.roles.filter(
          (r) => r !== Roles.TenantAdmin && r !== Roles.SubsidiariesAdmin,
        )
      }
      this.roles = this.roles.filter((r) => r !== role)
    } else {
      this.roles = [...this.roles, role]
    }
  }

  is(checkingRoles: Roles): RoleState {
    return {
      role: checkingRoles,
      isActive: this.roles.includes(checkingRoles),
    }
  }

  isBmmAdmin = (): RoleState => this.is(Roles.BmmAdmin)

  isScenariosAdmin = (): RoleState => this.is(Roles.ScenariosAdmin)

  isUsersAdmin = (): RoleState => this.is(Roles.UsersAdmin)

  isTenantAdmin = (): RoleState => this.is(Roles.TenantAdmin)

  isBillingAdmin = (): RoleState => this.is(Roles.BillingAdmin)

  isTechnicalAdmin = (): RoleState => this.is(Roles.TechnicalAdmin)

  isSignaturesAdmin = (): RoleState => this.is(Roles.SignaturesAdmin)

  isTrackingAdmin = (): RoleState => this.is(Roles.TrackingAdmin)

  isSubsidiariesAdmin = (): RoleState => this.is(Roles.SubsidiariesAdmin)

  isSuperAdmin = (): boolean => {
    return AdminAccount.superAdminRolesList().every((role) =>
      this.roles.includes(role),
    )
  }

  hasAllRoles(): boolean {
    return AdminAccount.rolesList().every((role) => this.roles.includes(role))
  }

  hasRole(role: Roles): boolean {
    return this.roles.includes(role)
  }
}

export class DataGridAdminAccount extends AdminAccount {
  isNew: boolean
  isError: boolean
  subsidiariesIds: number[]

  constructor(account: DataGridAdminAccountVM) {
    super(account)
    this.isNew = account.isNew
    this.isError = false
    this.subsidiariesIds = []
  }

  setUserName(userName: string): void {
    this.userName = userName
  }

  setError(error: boolean): void {
    this.isError = error
  }

  setSubsidiariesIds(subsidiariesIds: number[]) {
    this.subsidiariesIds = subsidiariesIds
  }
}

export class Account {
  TenantId: number
  Id: string
  UserName: string
  Email: string
  EmailConfirmed: false
  Firstname: string
  Lastname: string
  Company: string
  EmployeesNumber: number
  Function: string
  BusinessSegment: string
  PhoneNumber: string
  Tutorial: TourGuide
  Roles: Roles[]
  StepRegister: number

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(identityJson) {
    for (const prop in identityJson) {
      // eslint-disable-next-line no-prototype-builtins
      if (identityJson.hasOwnProperty(prop)) {
        this[StringHelper.Capitalize(prop)] = identityJson[prop]
      }
    }
    if (identityJson[TOURSGUIDE_PROPERTY_NAME] != null) {
      this.Tutorial = JSON.parse(identityJson[TOURSGUIDE_PROPERTY_NAME])
    }
  }
}

export class AccountData {
  company: TenantProperty
  employeesNumber: TenantProperty
  business: TenantProperty
}

const onBoardingState = ["uncompleted", "completed"] as const

const onBoardingItem = [
  "addUsers",
  "firstSignature",
  "firstCampaign",
  "assignedSignature",
  "syncedSignature",
] as const

type OnBoardingState = typeof onBoardingState[number]

export type OnBoardingKey = typeof onBoardingItem[number]

export type OnBoardingItem = {
  state: OnBoardingState
  date: string | null
}

export type OnBoardingItems = { [key in OnBoardingKey]: OnBoardingItem }

export interface OnBoarding {
  loadingStatus: LoadingStatus
  items: OnBoardingItems
}

export interface HoverCardContent {
  title: string
  visual: string
  url: string
  content: {
    title: string
    elements: string[]
  }[]
}

type SignaturesSendingModesCount = { [key in SendingModes]: number }

export type SignaturesTileCounts = {
  all: number
  active: number
} & SignaturesSendingModesCount

export interface SignaturesTileDatas {
  loadingStatus: LoadingStatus
  count: SignaturesTileCounts
}

export type CampaignsTileCount = {
  all: number
  campaigns: number
  scenarios: number
}

export interface CampaignsTileDatas {
  loadingStatus: LoadingStatus
  count: CampaignsTileCount
}

export type TrackingTileCount = {
  delivered: number
  signaturesClicked: number
  bannersClicked: number
}

export interface TrackingTileDatas {
  loadingStatus: LoadingStatus
  count: TrackingTileCount
}

export type UsersTileCount = {
  users: number
  groups: number
  licences: number
}

export interface UsersTileDatas {
  loadingStatus: LoadingStatus
  count: UsersTileCount
  latest: string[]
}
