import { FunctionComponent, MouseEventHandler } from "react"
import { defineMessages, useIntl } from "react-intl"

import { humanFileSize } from "utils/NumberHelper"

interface UploaderDisclaimerProps {
  currentSize: number
  maxSize: number
  onAccept: MouseEventHandler<HTMLButtonElement>
  onRefuse: MouseEventHandler<HTMLButtonElement>
}

const messages = defineMessages({
  label1: {
    id: "UploaderDisclaimer.Label1",
    defaultMessage:
      "Poids du visuel supérieur à la limite (Taille : {currentSize}, Limite : {maxSize}).",
  },
  label2: {
    id: "UploaderDisclaimer.Label2",
    defaultMessage: "Voulez-vous quand même l'ajouter ?",
  },
  yesBtn: {
    id: "UploaderDisclaimer.YesButton",
    defaultMessage: "OUI",
  },
  noBtn: {
    id: "UploaderDisclaimer.NoButton",
    defaultMessage: "NON",
  },
})

const UploaderDisclaimer: FunctionComponent<UploaderDisclaimerProps> = ({
  currentSize,
  maxSize,
  onAccept,
  onRefuse,
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className="uploader-disclaimer">
      <div className="uploader-disclaimer__container">
        <div>
          <p className="uploader-disclaimer__label">
            {formatMessage(messages.label1, {
              currentSize: humanFileSize(currentSize, true, 0),
              maxSize: humanFileSize(maxSize, true, 0),
            })}
          </p>
          <p className="uploader-disclaimer__label">
            {formatMessage(messages.label2)}
          </p>
        </div>
      </div>
      <div className="uploader-disclaimer__buttons-container">
        <button
          type="button"
          className="uploader-disclaimer__button"
          onClick={onAccept}
        >
          {formatMessage(messages.yesBtn)}
        </button>
        <button
          type="button"
          className="uploader-disclaimer__button"
          onClick={onRefuse}
        >
          {formatMessage(messages.noBtn)}
        </button>
      </div>
    </div>
  )
}

export default UploaderDisclaimer
