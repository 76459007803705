import React from "react"
import { DayCellRange } from "./DayCellRange"
import moment, { Moment } from "moment"

function isPastPresentOrFuture(date: Moment): string {
  if (moment().isSame(date, "day")) return "today"
  else if (date.isAfter(moment(), "day")) return "future"
  return "past"
}

export const DayCell = ({
  dayData,
  nbRanges,
  onShowMore,
  onSelect,
  onHover,
  selectedId,
  hoveredId,
  rangeHeight = 6,
  rangeMargin = 2,
}) => {
  const when = isPastPresentOrFuture(dayData.day)
  const displayedRanges = dayData.ranges.filter(
    (range) => range.order < nbRanges,
  )
  const nbOthers = dayData.ranges.length - displayedRanges.length

  const handleShowMore = (evt) => {
    onShowMore && onShowMore(dayData.day, dayData.ranges, evt)
  }

  return (
    <div className={`cv-day cv-day-${when}`}>
      <span className="cv-day-number ">{dayData.day.format("D")}</span>
      {displayedRanges.map((range, index) => (
        <DayCellRange
          key={index}
          range={range}
          onSelect={onSelect}
          onHover={onHover}
          selectedId={selectedId}
          hoveredId={hoveredId}
          height={rangeHeight}
          margin={rangeMargin}
        />
      ))}
      {dayData.ranges.length > nbRanges && (
        <div
          className="cv-btn cv-range-more"
          style={{ marginTop: nbRanges * (rangeHeight + rangeMargin) + 1 }}
          onClick={handleShowMore}
        >
          {nbOthers}
          {nbOthers < 1 ? <>autre</> : <>autres</>}
        </div>
      )}
    </div>
  )
}
