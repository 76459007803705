import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react"

import PeoplePicker, { Tag } from "components/PeoplePicker"

import { useScenarioFormContext } from "../ScenarioForm/ScenarioFormContext"

import { User } from "features/Users/UserModels"

import ArrayHelper from "utils/ArrayHelper"

interface ScenariosPeoplePickerProps {
  tags: Tag[]
  onSetTags: Dispatch<SetStateAction<Tag[]>>
}

const ScenariosPeoplePicker: FunctionComponent<ScenariosPeoplePickerProps> = ({
  tags,
  onSetTags,
}) => {
  const { editingCampaign, updateEditingCampaign, allUsers, allGroups } =
    useScenarioFormContext()

  useEffect(() => {
    const newTags = []
    if (editingCampaign.groupIds.length > 0) {
      newTags.push(
        ...editingCampaign.groupIds.map((groupId) => {
          const group = allGroups.find((group) => group.Id === groupId)
          if (!group) return null
          return {
            id: groupId,
            label: group?.Name || "",
            type: "group",
          }
        }),
      )
    }
    if (editingCampaign.userIds.length > 0) {
      newTags.push(
        ...editingCampaign.userIds.map((userId) => {
          const user = allUsers.find((user) => user.Id === userId)
          const fullName = (user && User.getFullName(user)) || ""
          if (!user) return null
          return {
            id: userId,
            label: fullName,
            type: "user",
          }
        }),
      )
    }
    onSetTags(newTags.filter(Boolean))
  }, [editingCampaign, allUsers, allGroups])

  const handleTagClick = (item) => {
    if (item.type === "group") {
      const groups = editingCampaign.groupIds
      updateEditingCampaign({ groupIds: groups.filter((g) => g !== item.id) })
      const remainingTags = ArrayHelper.reject(
        tags,
        (tag) => tag.type === "group" && tag.id === item.id,
      )
      onSetTags(remainingTags)
    }
    if (item.type === "user") {
      const users = editingCampaign.userIds
      updateEditingCampaign({
        userIds: users.filter((userId) => userId !== item.id),
      })
      const remainingTags = ArrayHelper.reject(
        tags,
        (tag) => tag.type === "user" && tag.id === item.id,
      )
      onSetTags(remainingTags)
    }
  }

  return <PeoplePicker tags={tags} onTagClick={handleTagClick} />
}

export default ScenariosPeoplePicker
