import React from "react"

interface Props {
  title: string
  active: boolean
}

class BreadCrumbItem extends React.Component<Props> {
  getClass = () => {
    return this.props.active
      ? "breadcrumb__item breadcrumb__item--active"
      : "breadcrumb__item"
  }

  render() {
    return (
      <div className={this.getClass()}>
        <div className="breadcrumb__circle-container">
          <div className="breadcrumb__title">{this.props.title}</div>
          <div className="breadcrumb__circle">
            <div className="breadcrumb__dot"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default BreadCrumbItem
