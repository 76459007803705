import React from "react"
import Loader from "../Loader"

interface Props {
  isLoading: boolean
}

class GlobalLoader extends React.Component<Props> {
  render() {
    if (this.props.isLoading)
      return (
        <div className="global-loader">
          <Loader fullScreen isLoading={this.props.isLoading} />
        </div>
      )

    return (
      <div className="row h-100 animated fadeIn">{this.props.children}</div>
    )
  }
}

export default GlobalLoader
