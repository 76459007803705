import { FunctionComponent } from "react"

import { Link, Navigate } from "react-router-dom"

import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import BgBlue from "assets/logo-bg-blue.svg"
import BgWhite from "assets/logo-bg-white.svg"
import BgGreen from "assets/logo-bg-green.svg"

import { offersSelectors } from "features/Offers"

import {
  BMM_SALES_MAIL,
  BMM_SALES_PHONE_NUMBER,
} from "features/Enrollment/EnrollmentConstants"
import { ENROLLMENT_URL, HOME_PORTAL_URL } from "router/RouterConstants"

const messages = defineMessages({
  trialExpired: {
    id: "ExpiredPage.TrialExpired",
    defaultMessage: "Votre période de gratuité est arrivée à son terme",
  },
  offerExpired: {
    id: "ExpiredPage.OfferExpired",
    defaultMessage:
      "Votre compte a été suspendu, contactez nous pour réactiver votre abonnement !",
  },
  subscribe: {
    id: "ExpiredPage.Subscribe",
    defaultMessage: "Souscrivez à un abonnement",
  },
  chooseBtn: {
    id: "ExpiredPage.ChooseBtn",
    defaultMessage: "Aller sur la page abonnement",
  },
  contact: {
    id: "ExpiredPage.Contact",
    defaultMessage: "Contactez notre service commercial",
  },
})

const ExpiredPageContent: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const isTrial = useSelector(offersSelectors.isTrial)
  const offerIsNotExpired = useSelector(
    offersSelectors.checkCurrentTenantOfferValidity,
  )

  if (offerIsNotExpired) return <Navigate to={HOME_PORTAL_URL} />

  return (
    <div className="expired">
      <div className="expired__title--container">
        <h1 className="expired__title--label">
          {formatMessage(
            isTrial ? messages.trialExpired : messages.offerExpired,
          )}
        </h1>
      </div>
      <div className="expired__informations">
        <div className="expired__lisere" />
        <p className="expired__informations--subscribe">
          {formatMessage(messages.subscribe)}
        </p>
        <Link
          to={ENROLLMENT_URL}
          className="expired__informations--cta"
          data-cy="expired-page__subscribe-button"
        >
          {formatMessage(messages.chooseBtn)}
        </Link>
        <div className="expired__lisere" />
        <p className="expired__informations--contact">
          {formatMessage(messages.contact)}
        </p>
        <a
          href={`tel:${BMM_SALES_PHONE_NUMBER}`}
          className="expired__informations--phone"
        >
          {BMM_SALES_PHONE_NUMBER}
        </a>
        <a
          href={`mailto:${BMM_SALES_MAIL}`}
          className="expired__informations--mail"
        >
          {BMM_SALES_MAIL}
        </a>
      </div>
      <img className="expired__images--blue" src={BgBlue} />
      <img className="expired__images--green" src={BgGreen} />
      <img className="expired__images--white" src={BgWhite} />
    </div>
  )
}

export default ExpiredPageContent
