import { createContext, useContext, useState } from "react"

import cuid from "cuid"
import { partial } from "lodash"

import useKeyboardShortcut from "hooks/useKeyboardShortcut"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"
import useDiffusionMode from "features/Scenarios/hooks/useDiffusionMode"

import { featureNames } from "config/config.features"

import { User } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import {
  ScenarioFormVm,
  SignaturesScenBySignsFeat,
} from "features/Scenarios/ScenarioModels"

interface ScenarioProvider {
  existingScenario: ScenarioFormVm
  saveScenario: (scenario: ScenarioFormVm) => void
  allUsers: User[]
  allGroups: Group[]
  allSignatures: SignaturesScenBySignsFeat[]
  initialStep?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visuals: any[]
  mode: "simple" | "multiple"
}

const defaultScenarioData: ScenarioFormVm = {
  name: "",
  default: false,
  startDate: null,
  endDate: null,
  weighting: 3,
  type: "simple" || "multiple",
  banners: [],
  userIds: [],
  signatureIds: [],
  groupIds: [],
  internal: true,
  external: true,
  additional: false,
}

const ScenarioFormContext = createContext({
  editingCampaign: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  setEditingCampaign: (_data: any) => null,
  saveCurrentCampaign: () => null,
  allUsers: [],
  allGroups: [],
  allSignatures: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
  updateEditingCampaign: (_newValues: {}) => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateEditingCampaignVisual: (_visual) => null,
  step: 1,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStep: (_step: number) => null,
  visuals: [],
})

/**
 * Persistence de l'état du formulaire durant la durée du tunnel Scenario
 */
const ScenarioContextProvider: React.FC<ScenarioProvider> = ({
  children,
  existingScenario,
  saveScenario,
  allGroups,
  allUsers,
  allSignatures,
  visuals,
  mode,
  initialStep = 1,
}) => {
  const { diffusionMode } = useDiffusionMode()

  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)
  let internal = true
  let external = true

  if (internalExternalFeature?.isActive) {
    internal = diffusionMode.internal
    external = diffusionMode.external
  }

  const [editingCampaign, setEditingCampaign] = useState<ScenarioFormVm>(
    existingScenario || {
      ...defaultScenarioData,
      type: mode,
      internal,
      external,
    },
  )
  const [step, setStep] = useState<number>(initialStep)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const updateEditingCampaign = (newValues: {}) => {
    setEditingCampaign({ ...editingCampaign, ...newValues })
  }

  // Si le mode est simple on remplace la 1ere image des bannières, si le mode est multiple alors on ajoute l'image aux bannières
  const updateEditingCampaignVisual = (visual) => {
    if (mode === "simple")
      setEditingCampaign({ ...editingCampaign, banners: [visual] })
    else {
      // On ajoute la bannière à la fin
      setEditingCampaign({
        ...editingCampaign,
        banners: [
          ...editingCampaign.banners,
          {
            ...visual,
            draggableId: cuid(),
            order: editingCampaign.banners.length,
          },
        ],
      })
    }
  }

  return (
    <ScenarioFormContext.Provider
      value={{
        editingCampaign,
        setEditingCampaign,
        updateEditingCampaign,
        updateEditingCampaignVisual,
        saveCurrentCampaign: partial(saveScenario, editingCampaign),
        allUsers,
        allSignatures,
        allGroups,
        step,
        setStep,
        visuals,
      }}
    >
      <DebugConsole jsonData={editingCampaign} />
      {children}
    </ScenarioFormContext.Provider>
  )
}

const DebugConsole = (jsonData) => {
  const [debug, setDebug] = useState(false)
  useKeyboardShortcut(
    ["Shift", "D"],
    () => setDebug((prevState) => !prevState),
    {},
  )
  return (
    <>
      {debug && (
        <div className="debug">{JSON.stringify(jsonData, null, 2)}</div>
      )}
    </>
  )
}

const useScenarioFormContext = () => useContext(ScenarioFormContext)

export { useScenarioFormContext, ScenarioContextProvider }
