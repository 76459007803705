import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import SignatureCardInfos from "./SignatureCardInfos"

interface SignatureCardInfosExpandedProps {
  children: ReactNode
  className?: string
}

export type SignatureCardInfosExpandedType =
  FunctionComponent<SignatureCardInfosExpandedProps>

const SignatureCardInfosExpanded: SignatureCardInfosExpandedType = ({
  children,
  className,
}) => {
  return (
    <SignatureCardInfos
      className={classNames("signature-card__infos", "is-expanded", className)}
    >
      {children}
    </SignatureCardInfos>
  )
}

export default SignatureCardInfosExpanded
