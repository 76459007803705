import { FunctionComponent } from "react"

import { GlobalStates } from "store"

import { useSelector } from "react-redux"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import classNames from "classnames"

import AddIcon from "@material-ui/icons/Add"
import SearchBar from "components/SearchBar"
import HoverTooltip from "components/HoverTooltip"
import DeleteButtonWithTip from "components/DeleteButtonWithTip"
import PaginatedDataGrid, { ColumnConfig } from "components/PaginatedDataGrid"
import SuperAdminToggle from "./components/SuperAdminToggle"
import ToggleSubsidiaryButton from "./components/ToggleSubsidiaryButton"

import { ReactComponent as UsersEmptyIcon } from "features/Users/assets/icon-users-empty-results.svg"
import { ReactComponent as WarningIcon } from "assets/icon-warning.svg"

import AccessRights from "features/Accounts/components/AccessRights"
import EditableEmail from "features/Accounts/components/EditableEmail"

import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"

import { featureNames } from "config/config.features"
import { NEW_ADMIN_ID } from "features/Accounts/components/AdminsPanel/AdminsPanel"

import { DataGridAdminAccount, Roles } from "features/Accounts/AccountsModels"

interface AdminsGridProps {
  data: DataGridAdminAccount[]
  isEditing: boolean
  isSaving: boolean
  currentAccountId: string
  disableSave: boolean
  savingErrorMessage: string | null
  currentPage: number
  lastPage: number
  pageSize: number
  onDisableSave: (value: boolean) => void
  onEditing: () => void
  onEditEmail: (email: string, id: string) => void
  onDeleteAdminAccount: (id: string) => void
  onToggleRole: (id: string, role: Roles) => void
  onToggleSuperAdmin: (id: string) => void
  onSearch: (search: string) => void
  onAddLine: () => void
  onSave: () => void
  changePage: (page: number) => void
  setPageSize: (pageSize: number) => void
  isLoading?: boolean
}

interface DataProvider {
  currentAccountId: string
  savingErrorMessage: string
  isLoading: boolean
  isFeatureSubsidiariesActive: boolean
  onEditing: () => void
  onEditEmail: (email: string, id: string) => void
  onDeleteAdminAccount: (id: string) => void
  onToggleRole: (id: string, role: Roles) => void
  onToggleSuperAdmin: (id: string) => void
  onDisableSave: (value: boolean) => void
}

const messages = defineMessages({
  addTooltip: {
    id: "AdminsGrid.AddTooltip",
    defaultMessage: "Ajouter un administrateur",
  },
})

const adminGridConfig = (dataProvider: DataProvider) => {
  const columns: ColumnConfig<DataGridAdminAccount>[] = [
    {
      label: <FormattedMessage id="AdminsGrid.Email" defaultMessage="email" />,
      value: (_value, adminAccount) => (
        <div className="admins-grid__cell--container start">
          <EditableEmail
            initialValue={adminAccount.userName}
            id={adminAccount.id}
            isNew={adminAccount.isNew}
            isSuperAdmin={adminAccount.isSuperAdmin()}
            onEditing={dataProvider.onEditing}
            onEditEmail={dataProvider.onEditEmail}
            onDisableSave={dataProvider.onDisableSave}
          />
        </div>
      ),
      cellsClassName: "admins-grid__cell--email",
    },
    {
      renderLoading: false,
      value: (_value, adminAccount) =>
        adminAccount.isError && (
          <div className="admins-grid__cell--container">
            <HoverTooltip
              level="warning"
              tooltip={dataProvider.savingErrorMessage}
              align="left"
            >
              <WarningIcon className="admins-grid__cell--warning" />
            </HoverTooltip>
          </div>
        ),
      cellsClassName: "admins-grid__cell--error",
    },
    {
      label: (
        <div className="admins-grid__header-item">
          <FormattedMessage
            id="AdminsGrid.SuperAdmin"
            defaultMessage="super utilisateur"
          />
        </div>
      ),
      value: (_value, adminAccount) => (
        <div className="admins-grid__cell--container">
          <SuperAdminToggle
            currentAccountId={dataProvider.currentAccountId}
            adminAccount={adminAccount}
            onChange={() => dataProvider.onToggleSuperAdmin(adminAccount.id)}
            tooltip={
              <FormattedMessage
                id="AdminsGrid.SuperAdminTooltip"
                defaultMessage="Vous ne pouvez pas désactiver votre propre rôle"
              />
            }
          />
        </div>
      ),
      cellsClassName: "admins-grid__cell--super-admin",
    },
    {
      label: (
        <div className="admins-grid__header-item">
          <FormattedMessage
            id="AdminsGrid.AccessRights"
            defaultMessage="droits d'accès"
          />
        </div>
      ),
      value: (_value, adminAccount) => (
        <AccessRights
          id={adminAccount.id}
          currentAccountId={dataProvider.currentAccountId}
          onToggleRole={dataProvider.onToggleRole}
          signaturesAdmin={adminAccount.isSignaturesAdmin()}
          scenariosAdmin={adminAccount.isScenariosAdmin()}
          trackingAdmin={adminAccount.isTrackingAdmin()}
          usersAdmin={adminAccount.isUsersAdmin()}
          technicalAdmin={adminAccount.isTechnicalAdmin()}
          billingAdmin={adminAccount.isBillingAdmin()}
        />
      ),
    },
  ]

  const deleteColumn = {
    renderLoading: false,
    value: (_value, adminAccount) =>
      adminAccount.id !== dataProvider.currentAccountId && (
        <div className="admins-grid__btn-delete">
          <DeleteButtonWithTip
            message={
              <FormattedMessage
                id="AdminsGrid.DeleteTooltip"
                defaultMessage="Supprimer l'administrateur"
              />
            }
            onClick={() => dataProvider.onDeleteAdminAccount(adminAccount.id)}
          />
        </div>
      ),
    cellsClassName: "admins-grid__cell--delete",
  }

  const toggleSubsidiaryColumn = {
    label: (
      <div className="admins-grid__header-item">
        <FormattedMessage
          id="AdminsGrid.ToggleSubsidiary"
          defaultMessage="bascule filiale"
        />
      </div>
    ),
    renderLoading: false,
    value: (_value, adminAccount) => {
      if (
        adminAccount.id === dataProvider.currentAccountId ||
        adminAccount.id === NEW_ADMIN_ID
      )
        return null

      return <ToggleSubsidiaryButton adminAccount={adminAccount} />
    },
    cellsClassName: "admins-grid__cell--toggle",
  }

  if (dataProvider.isFeatureSubsidiariesActive)
    columns.push(toggleSubsidiaryColumn)

  columns.push(deleteColumn)

  return {
    columns,
    isLoading: dataProvider.isLoading,
    skeletonRowQuantity: 6,
    background: (
      <div className="admins-grid__empty-results">
        <UsersEmptyIcon />
        <p className="admins-grid__empty-results--text">
          <FormattedMessage
            id="AdminsGrid.NoResults"
            defaultMessage="Aucun résultats de recherche"
          />
        </p>
      </div>
    ),
  }
}

const handleRowStyle = (rowData) => {
  let rowProps
  if (rowData.isNew) {
    rowProps = {
      ...rowProps,
      style: {
        backgroundColor: "#f6faff",
      },
    }
  }
  if (rowData.isError) {
    rowProps = {
      ...rowProps,
      style: {
        backgroundColor: "#FFF6DA",
      },
    }
  }
  return rowProps
}

const AdminsGrid: FunctionComponent<AdminsGridProps> = ({
  data,
  isEditing,
  isSaving,
  currentAccountId,
  disableSave,
  savingErrorMessage,
  onSearch,
  onAddLine,
  onEditing,
  onEditEmail,
  onDeleteAdminAccount,
  onToggleRole,
  onToggleSuperAdmin,
  onSave,
  onDisableSave,
  isLoading,
  currentPage,
  lastPage,
  pageSize,
  changePage,
  setPageSize,
}) => {
  const { formatMessage } = useIntl()
  const isFeatureSubsidiariesActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.SUBSIDIARIES),
  ) as boolean

  const dataProvider: DataProvider = {
    currentAccountId,
    savingErrorMessage,
    onEditing,
    onEditEmail,
    onDeleteAdminAccount,
    onToggleRole,
    onToggleSuperAdmin,
    onDisableSave,
    isLoading,
    isFeatureSubsidiariesActive,
  }

  return (
    <div className="admins-grid__container">
      <div className="admins-grid__header">
        <div className="admins-grid__title">
          <FormattedMessage
            id="AdminsGrid.Title"
            defaultMessage="Gestion des administrateurs"
          />
        </div>
        <div className="admins-grid__search">
          <SearchBar onChange={onSearch} placeholder="Rechercher" />
        </div>
        <div className="admins-grid__actions">
          {!isEditing && (
            <button
              type="button"
              className="admins-grid__btn"
              onClick={() => onAddLine()}
            >
              <HoverTooltip
                tooltip={formatMessage(messages.addTooltip)}
                level="info"
              >
                <AddIcon />
              </HoverTooltip>
            </button>
          )}
          {isEditing && (
            <button
              type="button"
              className={classNames(
                "admins-grid__btn-save",
                isSaving && "is-saving",
              )}
              onClick={onSave}
              disabled={disableSave}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
      <PaginatedDataGrid
        config={adminGridConfig(dataProvider)}
        data={data}
        currentPage={currentPage}
        lastPage={lastPage}
        pageSize={pageSize}
        sortOrders={[]}
        changePage={changePage}
        setPageSize={setPageSize}
        getRowProps={handleRowStyle}
      />
    </div>
  )
}

export default AdminsGrid
