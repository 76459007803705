import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import newSubsidiaryImage from "assets/duotone/icon-subsidiary-duotone.svg"

import EmptyPageCTA from "components/EmptyPageCTA"

import { SUBSIDIARIES_URL } from "router/RouterConstants"

const messages = defineMessages({
  alert: {
    id: "Subsidiaries.NewSubsidiaryAlert",
    defaultMessage:
      "Vous n'avez pas encore de filiale. Créez-en une dès maintenant.",
  },
  title: {
    id: "Subsidiaries.NewSubsidiaryTitle",
    defaultMessage: "filiale",
  },
  subtitle: {
    id: "Subsidiaries.NewSubsidiarySubtitle",
    defaultMessage:
      "Créer votre première filiale dès à présent, en cliquant sur le bouton ci-dessous",
  },
  link: {
    id: "Subsidiaries.NewSubsidiaryLink",
    defaultMessage: "Créer une filiale",
  },
})

const SubsidiariesListEmpty: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <EmptyPageCTA>
      <EmptyPageCTA.Alert alert={formatMessage(messages.alert)} />
      <EmptyPageCTA.Cards>
        <EmptyPageCTA.Card
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subtitle)}
          image={newSubsidiaryImage}
          imgAlt={formatMessage(messages.title)}
          link={formatMessage(messages.link)}
          to={`${SUBSIDIARIES_URL}/edit`}
        />
      </EmptyPageCTA.Cards>
    </EmptyPageCTA>
  )
}

export default SubsidiariesListEmpty
