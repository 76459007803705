import React from "react"

interface Props {
  index: number
  classname: string
}

class Slide extends React.Component<Props> {
  render() {
    const { children, classname } = this.props

    return <div className={`gallery__slide ${classname}`}>{children}</div>
  }
}

export default Slide
