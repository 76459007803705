import { ReactNode } from "react"

import PaginatedDataGridPagination from "./components/PaginatedDataGridPagination"
import PaginatedDataGridBody from "./components/PaginatedDataGridBody"
import PaginatedDataGridHeader from "./components/PaginatedDataGridHeader"

export type SortOrder = "asc" | "desc"

export type SortOrders = Array<{ columnKey: string; sortOrder: SortOrder }>

type ResolvableValue<T, U> =
  | ((allData: T[], rowData: T, rowIndex: number, isHovered: boolean) => U)
  | U

export interface ColumnConfig<T> {
  label?: ReactNode | (() => ReactNode)
  value?: ((allData: T[], rowData: T, rowIndex: number) => ReactNode) | keyof T
  onSort?: (columnKey: string, sortOrder: SortOrder) => void
  sortKey?: string
  cellsClassName?: ResolvableValue<T, string>
  renderLoading?: boolean
}

export interface DataGridConfig<T> {
  columns: ColumnConfig<T>[]
  isLoading?: boolean
  skeletonRowQuantity?: number
  background?: JSX.Element
}

interface PaginatedDataGridProps<T, P> {
  config: DataGridConfig<T>
  data: T[]
  currentPage: number
  lastPage: number
  pageSize: number
  sortOrders: SortOrders
  changePage: (page: number) => void
  setPageSize: (pageSize: number) => void
  pageSizeOptions?: Array<number>
  defaultPageSize?: number
  scrollToTop?: boolean
  getRowProps?: (rowData: T) => P
}

const PaginatedDataGrid = <T, P>({
  config,
  data,
  currentPage,
  lastPage,
  pageSize,
  changePage,
  setPageSize,
  sortOrders,
  getRowProps,
  scrollToTop,
  pageSizeOptions = [10, 20, 50, 100],
}: PaginatedDataGridProps<T, P>): JSX.Element => {
  return (
    <div className="data-grid">
      <div className="data-grid__container">
        <table className="data-grid__table">
          <PaginatedDataGridHeader config={config} sortOrders={sortOrders} />
          <PaginatedDataGridBody
            config={config}
            data={data}
            getRowProps={getRowProps}
            scrollToTop={scrollToTop}
          />
        </table>
      </div>
      <PaginatedDataGridPagination
        currentPage={currentPage}
        lastPage={lastPage}
        pageSize={pageSize}
        changePage={changePage}
        setPageSize={setPageSize}
        pageSizeOptions={pageSizeOptions}
        empty={data.length === 0}
      />
    </div>
  )
}

export default PaginatedDataGrid
