import classNames from "classnames"

import SortableButton from "./SortableButton"
import { DataGridConfig, SortOrders } from "../PaginatedDataGrid"

interface PaginatedDataGridHeaderProps<T> {
  config: DataGridConfig<T>
  sortOrders: SortOrders
}

const PaginatedDataGridHeader = <T,>({
  config,
  sortOrders,
}: PaginatedDataGridHeaderProps<T>): JSX.Element => {
  return (
    <thead className={classNames("data-grid__thead")}>
      <tr className="data-grid__tr">
        {config.columns.map((column, index) => (
          <th
            className={classNames("data-grid__th", column?.cellsClassName)}
            key={index}
          >
            <div className="data-grid__th__container">
              {column.sortKey ? (
                <SortableButton column={column} sortOrders={sortOrders}>
                  {column.label}
                </SortableButton>
              ) : (
                column.label
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default PaginatedDataGridHeader
