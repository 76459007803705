// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === "function"

export const compose = (...fctns) =>
  fctns.reduceRight(
    (prevFn, nextFn) =>
      (...args) =>
        nextFn(prevFn(...args)),
    (value) => value,
  )

export function getArrayIntersection<T>(arrA: T[], arrB: T[]) {
  return arrA.filter((el) => arrB.includes(el))
}
