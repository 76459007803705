import { FunctionComponent } from "react"

import Podium from "features/Tracking/components/Podium"

const ScenariosMostClickedLoading: FunctionComponent = () => {
  return (
    <div className="scen-most-clicked">
      <div className="scen-most-clicked__left-side">
        <Podium largeAvatar isLoading />
      </div>
      <div className="scen-most-clicked__right-side">
        <div className="scen-most-clicked__skeleton--container">
          <div className="scen-most-clicked__skeleton--comment" />
          <div className="scen-most-clicked__skeleton--data" />
        </div>
      </div>
    </div>
  )
}

export default ScenariosMostClickedLoading
