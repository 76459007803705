import { FunctionComponent } from "react"

import { Link, useLocation } from "react-router-dom"

import { FormattedMessage } from "react-intl"

import { ReactComponent as IconAgenda } from "assets/icon-agenda.svg"
import { ReactComponent as IconModel } from "assets/icon-model.svg"

import { CAMPAIGNS_URL } from "router/RouterConstants"

const agendaPath = `${CAMPAIGNS_URL}/calendar`

const ScenarioNavButton: FunctionComponent = () => {
  const { pathname } = useLocation()

  if (pathname.includes(agendaPath)) {
    return (
      <Link to={CAMPAIGNS_URL} className="scenario-nav-button">
        <IconModel className="scenario-nav-button__icon" />
        <span className="scenario-nav-button__label">
          <FormattedMessage
            id="Campaign.Campaigns"
            defaultMessage="Mes Campagnes"
          />
        </span>
      </Link>
    )
  }

  return (
    <Link to={agendaPath} className="scenario-nav-button">
      <IconAgenda className="scenario-nav-button__icon" />
      <span className="scenario-nav-button__label">
        <FormattedMessage id="Campaign.Agenda" defaultMessage="Agenda" />
      </span>
    </Link>
  )
}

export default ScenarioNavButton
