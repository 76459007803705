import { connect } from "react-redux"
import ColorPicker from "components/ColorPicker"
import { signaturesSelectors } from "features/Signatures"
import { Colors } from "entities/Template"
import { changeTemplateColor } from "../SignaturesReducer"

const mapStateToProps = (state) => ({
  colors: Colors,
  currentColor: signaturesSelectors.getTemplateColor(state),
})

const mapDispatchToProps = {
  onColorSelected: changeTemplateColor,
}

const ConnectedColorPicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColorPicker)

export default ConnectedColorPicker
