import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

const message = defineMessages({
  datas: {
    id: "Tracking.SignaturesPowerUsersInsufficientsDatas",
    defaultMessage:
      "En attente de données suffisantes pour afficher les tops diffuseurs",
  },
})

const SignaturesPowerUsersInsufficientsDatas: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="sign-power-users-insuff">
      <InfoIcon className="sign-power-users-insuff__icon" />
      <p className="sign-power-users-insuff__label">
        {formatMessage(message.datas)}
      </p>
    </div>
  )
}

export default SignaturesPowerUsersInsufficientsDatas
