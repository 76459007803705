import { ModalService } from "components/Modal"

import { useDispatch } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import NoSubsidiaryModal from "features/Subsidiaries/components/NoSubsidiaryModal"

import ToggleSubsidiaryModal from "../components/ToggleSubsidiaryModal"

import { addAdminAccountToSubsidiariesAction } from "features/Accounts/AccountsActions"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"

import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  toggleSubsidiaryTitle: {
    id: "AdminsGrid.ToggleSubsidiaryTitle",
    defaultMessage: "Basculer en administrateur filiale",
  },
  noSubsidiaryTitle: {
    id: "AdminsGrid.noSubsidiaryTitle",
    defaultMessage: "Pas de filiale de paramétrée",
  },
})

function useToggleSubsidiary(adminAccount: DataGridAdminAccount) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { subsidiaries, loadingStatus } = useSubsidiariesData()

  const handleToggle = (subsidiariesIds: number[]) => {
    dispatch(
      addAdminAccountToSubsidiariesAction(adminAccount.id, subsidiariesIds),
    )

    ModalService.close()
  }

  const handleCancel = () => {
    ModalService.close()
  }

  const handleDisplayToggleModal = () => {
    ModalService.open({
      title: formatMessage(messages.toggleSubsidiaryTitle),
      content: (
        <ToggleSubsidiaryModal
          adminAccount={adminAccount}
          onToggle={handleToggle}
          onCancel={handleCancel}
        />
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCancel: async (_e) => handleCancel(),
    })
  }

  const handleDisplayNoSubsidiaryModal = () => {
    ModalService.open({
      title: formatMessage(messages.noSubsidiaryTitle),
      content: <NoSubsidiaryModal onCancel={handleCancel} />,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCancel: async (_e) => handleCancel(),
    })
  }

  const handleDisplayModal = () => {
    loadingStatus === LoadingStatus.LOADED && subsidiaries.length > 0
      ? handleDisplayToggleModal()
      : handleDisplayNoSubsidiaryModal()
  }

  return { handleDisplayModal }
}

export default useToggleSubsidiary
