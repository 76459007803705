import React from "react"
import CopyPasteField from "components/CopyPasteField/CopyPasteField"
import { FormattedMessage } from "react-intl"
import config from "config/config"

interface GoogleConfigProps {
  googleAdminUrl: string
  bmmServiceAccountId: string
  scopes: string
}

const GoogleConfigScreen: React.FC<GoogleConfigProps> = ({
  googleAdminUrl,
  bmmServiceAccountId,
  scopes,
}) => {
  return (
    <div className="google-config">
      <div className="google-config__section">
        <div className="google-config__title">
          <FormattedMessage
            id="GSuiteForm.Step1Text"
            defaultMessage="1. Se rendre sur votre écran de gestion des permissions de clients Google Workspace :"
          />
        </div>
        <div className="google-config__content">
          <a href={googleAdminUrl} target="_blank" rel="noreferrer">
            {googleAdminUrl}
          </a>
        </div>
      </div>
      <div className="google-config__section">
        <div className="google-config__title">
          <FormattedMessage
            id="GSuiteForm.Step2Text"
            defaultMessage='2. Cliquez sur le bouton "Ajouter" à gauche du menu "Clients API" :'
          />
        </div>
        <div className="google-config__content">
          <img
            src={`${config.cdnUrl}/images/bmm/parameters/GsuiteWideDomainDelegationScreen.png`}
            alt=""
          />
        </div>
      </div>
      <div className="google-config__section">
        <div className="google-config__title">
          <FormattedMessage
            id="GSuiteForm.Step3Text"
            defaultMessage='3. Copier/Coller les valeurs ci-dessous dans les champs correspondants au niveau de votre espace Google Workspace, puis cliquer sur "Autoriser" :'
          />
        </div>
        <div className="google-config__content">
          <FakeGooglePopup
            bmmServiceAccountId={bmmServiceAccountId}
            scopes={scopes}
          ></FakeGooglePopup>
        </div>
      </div>
    </div>
  )
}

const FakeGooglePopup = ({ bmmServiceAccountId, scopes }) => {
  return (
    <div className="google-oauth-popup">
      <div className="google-oauth-popup__content">
        <div className="google-popup__copypastefield">
          <FormattedMessage
            id="GSuiteForm.GPopupClientId"
            defaultMessage="ID Client"
          ></FormattedMessage>
          <CopyPasteField
            value={bmmServiceAccountId}
            btnText="Copier la clé"
            layout="horizontal"
          />
        </div>
        <div className="google-popup__copypastefield">
          <FormattedMessage
            id="GSuiteForm.GPopupOAuthScopes"
            defaultMessage="Habilitations OAuth (séparées par une virgule)"
          ></FormattedMessage>
          <CopyPasteField value={scopes} btnText="Copier les scopes" />
        </div>
      </div>
    </div>
  )
}

export default GoogleConfigScreen
