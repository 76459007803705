import { FunctionComponent } from "react"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"
import { defineMessages, useIntl } from "react-intl"

const messages = defineMessages({
  label: {
    id: "HomePage.TrackingTileEmptyLabel",
    defaultMessage: "En attente de données suffisantes",
  },
})

const TrackingTileDatasEmptyState: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="tracking-tile__empty-state--container">
      <InfoIcon className="tracking-tile__empty-state--icon" />
      <p className="tracking-tile__empty-state--label">
        {formatMessage(messages.label)}
      </p>
    </div>
  )
}

export default TrackingTileDatasEmptyState
