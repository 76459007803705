import { FunctionComponent, useState } from "react"

import classNames from "classnames"

import { ReactComponent as IconChevronUp } from "assets/icon-chevron-up.svg"

import PeoplePickerTag from "./components/PeoplePickerTag"

export interface Tag {
  id: string | number
  label: string
  type?: "user" | "group"
}

interface PeoplePickerProps {
  tags: Tag[]
  maxTagsDisplays?: number
  overflowDirection?: "no-overflow" | "y-overflow" | "x-overflow"
  onTagClick: (tag: Tag) => void
}

function counterBackgroundColor(tags, maxTagsDisplays) {
  const overloadTags: Tag[] = tags.slice(maxTagsDisplays, tags.length + 1)
  const allGroups = overloadTags.every((tag) => tag.type === "group")

  if (allGroups) return "group-background"

  const containsGroup = overloadTags.some((tag) => tag.type === "group")
  const containsUser = overloadTags.some((tag) => tag.type === "user")

  if (containsGroup && containsUser) return "mixed-background"

  return "user-background"
}

const PeoplePicker: FunctionComponent<PeoplePickerProps> = ({
  tags,
  onTagClick,
  maxTagsDisplays = 6,
  overflowDirection = "y-overflow",
}) => {
  const [displayAllTags, setDisplayAllTags] = useState(false)
  const counter = tags.length - maxTagsDisplays

  return (
    <div className={classNames("people-picker__container", overflowDirection)}>
      {tags
        .slice(0, displayAllTags ? tags.length + 1 : maxTagsDisplays)
        .map((tag) => (
          <PeoplePickerTag
            key={`${tag.type}-${tag.id}`}
            label={tag.label}
            type={tag.type}
            onClick={() => onTagClick && onTagClick(tag)}
          />
        ))}
      {!displayAllTags && counter > 0 && (
        <button
          type="button"
          onClick={() => setDisplayAllTags(true)}
          className={classNames(
            "people-picker__tag--counter",
            counterBackgroundColor(tags, maxTagsDisplays),
          )}
        >
          <span className="people-picker__label--counter">{`${counter}+`}</span>
        </button>
      )}
      {displayAllTags && counter > 0 && (
        <button
          type="button"
          onClick={() => setDisplayAllTags(false)}
          className="people-picker__tag--chevron"
        >
          <IconChevronUp />
        </button>
      )}
    </div>
  )
}

export default PeoplePicker
