import React from "react"

import { defineMessages, useIntl } from "react-intl"

import EmptyPageCTA from "components/EmptyPageCTA"

import imageNewCampaign from "../../images/icon-campaign-simple-large.svg"
import imageNewScenario from "../../images/icon-campaign-multiple-large.svg"

import { CAMPAIGNS_URL } from "router/RouterConstants"

const messages = defineMessages({
  alert: {
    id: "Campaigns.NewCampaignAlert",
    defaultMessage:
      "Vous n’avez pas encore de campagne planifiée. Ajoutez-en dès maintenant.",
  },
  campaignTitle: {
    id: "Campaigns.NewCampaignTitle",
    defaultMessage: "Campagne unique",
  },
  campaignSubtitle: {
    id: "Campaigns.NewCampaignSubtitle",
    defaultMessage:
      "Ajouter une bannière dans les signatures de vos collaborateurs",
  },
  campaignLink: {
    id: "Campaigns.NewCampaignLink",
    defaultMessage: "Planifier une campagne",
  },

  scenarioTitle: {
    id: "Campaigns.NewScenarioTitle",
    defaultMessage: "Scénario de campagne",
  },
  scenarioSubtitle: {
    id: "Campaigns.NewScenarioSubtitle",
    defaultMessage: "Créer un enchaînement de bannière automatique",
  },
  scenarioLink: {
    id: "Campaigns.NewScenarioLink",
    defaultMessage: "Créer un scénario",
  },
})

const ScenariosListEmpty: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <EmptyPageCTA>
      <EmptyPageCTA.Alert alert={formatMessage(messages.alert)} />
      <EmptyPageCTA.Cards>
        <EmptyPageCTA.Card
          title={formatMessage(messages.campaignTitle)}
          subtitle={formatMessage(messages.campaignSubtitle)}
          image={imageNewCampaign}
          imgAlt={formatMessage(messages.campaignTitle)}
          link={formatMessage(messages.campaignLink)}
          to={`${CAMPAIGNS_URL}/edit/step/1`}
        />
        <EmptyPageCTA.Card
          title={formatMessage(messages.scenarioTitle)}
          subtitle={formatMessage(messages.scenarioSubtitle)}
          link={formatMessage(messages.scenarioLink)}
          image={imageNewScenario}
          imgAlt={formatMessage(messages.scenarioTitle)}
          to={`${CAMPAIGNS_URL}/edit/step/1/?mode=multiple`}
        />
      </EmptyPageCTA.Cards>
    </EmptyPageCTA>
  )
}

export default ScenariosListEmpty
