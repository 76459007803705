import { FunctionComponent, useEffect, useState } from "react"

import classNames from "classnames"

import Toggle from "components/MUIToggle"
import { defineMessages, useIntl } from "react-intl"

interface ScenarioCardToggleProps {
  scenarioId: number
  isActive: boolean
  onActivate?: (scenarioId: number, activate: boolean) => void
}

export type ScenarioCardToggleType = FunctionComponent<ScenarioCardToggleProps>

const messages = defineMessages({
  activated: {
    id: "ScenarioCard.Activated",
    defaultMessage: "Activé",
  },
  deactivated: {
    id: "ScenarioCard.Deactivated",
    defaultMessage: "Désactivé",
  },
})

const ScenarioCardToggle: ScenarioCardToggleType = ({
  scenarioId,
  isActive,
  onActivate,
}) => {
  const { formatMessage } = useIntl()
  const [active, setActive] = useState(isActive)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  return (
    <div className="scenario-card__toggle">
      <Toggle
        onChange={(_event, checked) => {
          setActive(checked)
          onActivate && onActivate(scenarioId, checked)
        }}
        trackColorChecked="#3d5afe"
        checked={active}
      />
      <p
        className={classNames(
          "scenario-card__toggle--label",
          !active && "is-inactive",
        )}
      >
        {active
          ? formatMessage(messages.activated)
          : formatMessage(messages.deactivated)}
      </p>
    </div>
  )
}

export default ScenarioCardToggle
