import { FunctionComponent } from "react"

import useAllSignaturesData from "features/Signatures/hooks/useAllSignaturesData"
import useSignaturesClickedData from "features/Tracking/hooks/useSignaturesClickedData"
import useSignaturesHistoryData from "features/Tracking/hooks/useSignaturesHistoryData"

import Loader from "components/Loader"

import SignaturesEmpty from "features/Signatures/components/SignaturesEmpty"

import SignaturesDeployment from "../SignaturesDeployment"
import SignaturesPowerUsers from "../SignaturesPowerUsers"
import SignaturesTrackingWrapper from "../SignaturesTrackingWrapper"

import { LoadingStatus } from "core/CoreModels"
import useAllBuilderSignaturesData from "features/BuilderSignatures/hooks/useAllBuilderSignaturesData"

const TrackingSignatures: FunctionComponent = () => {
  const { signatures, loadingStatus: signLoadingStatus } =
    useAllSignaturesData()
  const { builderSignatures, loadingStatus: buildLoadingStatus } =
    useAllBuilderSignaturesData()

  useSignaturesClickedData()
  useSignaturesHistoryData()

  if (
    signLoadingStatus !== LoadingStatus.LOADED ||
    buildLoadingStatus !== LoadingStatus.LOADED
  )
    return <Loader fullScreen isLoading />

  return signatures.length === 0 && builderSignatures.length === 0 ? (
    <SignaturesEmpty />
  ) : (
    <div className="tracking-signatures">
      <div className="tracking-signatures__double-row">
        <div className="col">
          <SignaturesDeployment />
        </div>
        <div className="col">
          <SignaturesPowerUsers />
        </div>
      </div>
      <div className="tracking-signatures__row">
        <div className="col tracking-signatures__wrapper">
          <SignaturesTrackingWrapper />
        </div>
      </div>
    </div>
  )
}

export default TrackingSignatures
