import { ChangeEvent, FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { partial } from "lodash"
import classNames from "classnames"

import { useDispatch } from "react-redux"
import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import Toggle from "components/MUIToggle"

import { addNotification } from "features/Notifications/NotificationReducer"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import { TENANT_PROPERTY_NAMES } from "entities/TenantProperties"

import Feature from "features/Features/FeaturesModels"
import { Notification } from "features/Notifications/NotificationModels"

interface OWAToggleProps {
  featureOWA: Feature
  updateFeature: (feature: Feature) => void
  launchJob: (feature: Feature) => void
}

const messages = defineMessages({
  toggleTitle: {
    id: "OWAForm.ToggleTitle",
    defaultMessage: "Déploiement des signatures OWA On-Premise",
  },
  toggleContent: {
    id: "OWAForm.ToggleContent",
    defaultMessage:
      "Activez le push automatique de vos signatures mail sur votre environnement OWA On-Premise toutes les 24 heures.",
  },
  deployOWADisable: {
    id: "OWAForm.DeployOWADisable",
    defaultMessage: "Désactivé",
  },
  deployOWAEnable: {
    id: "OWAForm.DeployOWAEnable",
    defaultMessage: "Activé",
  },
  manualDeploy: {
    id: "OWAForm.ManualDeploy",
    defaultMessage: "Déployer manuellement",
  },
  noProperties: {
    id: "OWAForm.RegisterPropertiesFirst",
    defaultMessage:
      "Merci de compléter les paramètres et cliquer sur enregistrer",
  },
})

function checkFeatureProperties(feature) {
  const defaultaccount = feature.properties.find(
    (p) => p.property === TENANT_PROPERTY_NAMES.OWA_ACCOUNTS,
  )

  const defaultEndpoint = feature.properties.find(
    (p) => p.property === TENANT_PROPERTY_NAMES.OWA_ENDPOINT_URL,
  )

  return defaultaccount?.value !== null && defaultEndpoint?.value !== null
}

const OWAToggle: FunctionComponent<OWAToggleProps> = ({
  featureOWA,
  updateFeature,
  launchJob,
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [launchDeploy, setLaunchDeploy] = useState(false)

  const isDeploying = useJobIsRunning("DeployOwa")

  useEffect(() => {
    if (!isDeploying) setLaunchDeploy(false)
  }, [isDeploying])

  const handleDeploy = () => {
    setLaunchDeploy(true)
    launchJob(featureOWA)
  }

  const handleToggle = (
    feature: Feature,
    _evt: ChangeEvent<HTMLInputElement>,
    isActive: boolean,
  ) => {
    const propertiesAreComplete = checkFeatureProperties(feature)

    if (!propertiesAreComplete && isActive) {
      dispatch(
        addNotification(
          new Notification(formatMessage(messages.noProperties), "WARNING"),
        ),
      )
      return
    }

    feature.activated = false
    featureOWA.properties = [
      {
        property: TENANT_PROPERTY_NAMES.OWA_ACCOUNTS,
        value: null,
      },
      {
        property: TENANT_PROPERTY_NAMES.OWA_ENDPOINT_URL,
        value: null,
      },
    ]
    updateFeature(feature)
  }

  return (
    <div className="owa-form__toggle">
      <p className="owa-form__toggle--title">
        {formatMessage(messages.toggleTitle)}
      </p>
      <p className="owa-form__toggle--content">
        {formatMessage(messages.toggleContent)}
      </p>
      <div className="owa-form__toggle--action">
        <div
          className={classNames(
            "owa-form__toggle--toggle-label",
            !featureOWA.activated && "is-active",
          )}
        >
          {formatMessage(messages.deployOWADisable)}
        </div>
        <Toggle
          checked={featureOWA.activated}
          onChange={partial(handleToggle, featureOWA)}
        />
        <div
          className={classNames(
            "owa-form__toggle--toggle-label",
            featureOWA.activated && "is-active",
          )}
        >
          {formatMessage(messages.deployOWAEnable)}
        </div>
      </div>
      {featureOWA.activated && (
        <button
          type="button"
          className="owa-form__sync"
          onClick={handleDeploy}
          disabled={launchDeploy || isDeploying}
        >
          <p className="owa-form__sync--label">
            {formatMessage(messages.manualDeploy)}
          </p>
          <SyncIcon
            className={classNames(
              "owa-form__sync--icon",
              (launchDeploy || isDeploying) && "is-loading",
            )}
          />
        </button>
      )}
    </div>
  )
}

export default OWAToggle
