import { FunctionComponent, useEffect, useState } from "react"

import { BuilderProvider } from "bmm-builder"

import UserAvatarUploader from "./components/UserAvatarUploader"
import UserAvatarCropper from "./components/UserAvatarCropper"
import { ModalService } from "components/Modal"

interface UploadedFile {
  file: Blob | null
  size: { width: number; height: number }
}

interface UserAvatarManagerProps {
  onValidate: (picture: string) => void
}

const initialState = {
  file: null,
  size: { width: 0, height: 0 },
}

const UserAvatarManager: FunctionComponent<UserAvatarManagerProps> = ({
  onValidate,
}) => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>(initialState)

  const handleKeyDown = ({ key }) => key === "Escape" && ModalService.close()

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  if (uploadedFile.file === null)
    return (
      <BuilderProvider>
        <div className="user-avatar-manager">
          <UserAvatarUploader
            handleUploadedFile={(file, width, height) =>
              setUploadedFile({ file, size: { width, height } })
            }
          />
        </div>
      </BuilderProvider>
    )

  return (
    <div className="user-avatar-manager">
      <UserAvatarCropper
        onValidate={onValidate}
        uploadedFile={uploadedFile.file}
      />
    </div>
  )
}

export default UserAvatarManager
