import { CatalogOffer } from "../Offers/OffersModels"

import { TAX_COEFFICIENT } from "./EnrollmentConstants"

export function monthlyPriceComputation(offer: CatalogOffer, nbUsers: number) {
  const monthlyPrice = offer?.unitPrice * nbUsers

  const isUnderMinimal = monthlyPrice < offer?.minimalPrice

  return {
    monthlyPrice: isUnderMinimal ? offer?.minimalPrice : monthlyPrice,
    isUnderMinimal,
  }
}

export function computeDiscountAmount(value: number, discountPercent: number) {
  return value * (discountPercent / 100)
}

export function globalPriceComputation(
  offer: CatalogOffer,
  nbUsers: number,
  options: number[],
  discountPercent?: number,
) {
  const priceMonthly = monthlyPriceComputation(offer, nbUsers).monthlyPrice

  const price = priceMonthly * 12

  const subTotal = [price, ...options].reduce((acc, curr) => acc + curr, 0)

  const discount = discountPercent
    ? computeDiscountAmount(subTotal, discountPercent)
    : 0

  const total = subTotal - discount

  const totalVAT = total * TAX_COEFFICIENT

  return { price, priceMonthly, subTotal, total, totalVAT }
}
