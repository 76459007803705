import useAlerts from "../../hook/useAlerts"
import { ReactComponent as BellIcon } from "../../assets/icon-bell.svg"
import { ReactComponent as ElipseIcon } from "../../assets/icon-elipse-green.svg"
import { useEffect, useRef, useState } from "react"
import AlertsContainer from "../AlertsContainer"

const BellButton = () => {
  const { alerts, allAlertAreRead } = useAlerts()
  const [open, setOpen] = useState(false)
  const disable = alerts.length == 0
  const bellButtonRef = useRef(null);

  const openAlerts = () => {
    if (!disable) setOpen(!open)
  }

  const handleClickOutside = (event) => {
    if (bellButtonRef.current && !bellButtonRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleAlertContainerClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={`menu__user__alert${disable ? "--disable" : ""}`} ref={bellButtonRef}>
      <div className={`menu__user__alert__container${open ? "--open" : ""}`} onClick={handleAlertContainerClick}>
        <BellIcon
          onClick={openAlerts}
          className={`menu__user__alert__container__icon${
            allAlertAreRead ? "--read" : "--new"
          }`}
        />
        {!allAlertAreRead && <ElipseIcon className="alert_elipse-icon" />}
      </div>
      { open &&
        <div onClick={handleAlertContainerClick}>
          <AlertsContainer alerts={alerts} />
        </div>
      }
    </div>
  )
}

export default BellButton
