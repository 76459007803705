import { FunctionComponent, ReactNode } from "react"

import { defineMessages, useIntl } from "react-intl"
import { Link } from "react-router-dom"

interface HomePageTileTitleProps {
  icon: ReactNode
  label: string
  to?: string
}

export type HomePageTileTitleType = FunctionComponent<HomePageTileTitleProps>

const messages = defineMessages({
  consult: {
    id: "HomePage.ConsultLink",
    defaultMessage: "Consulter",
  },
})

const HomePageTileTitle: HomePageTileTitleType = ({ icon, label, to }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="home-tile__title--container">
      <div className="home-tile__title--icon">{icon}</div>
      <p className="home-tile__title--label">{label}</p>
      {to && (
        <Link className="home-tile__title--link" to={to}>
          {formatMessage(messages.consult)}
        </Link>
      )}
    </div>
  )
}

export default HomePageTileTitle
