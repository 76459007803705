import { FunctionComponent } from "react"

import { Signature } from "entities/Signature"
import { FormattedMessage } from "react-intl"

export interface ActivateSignatureButtonProps {
  signature: Signature
  onActivate: (signature: Signature) => void
}

const ActivateSignatureButton: FunctionComponent<
  ActivateSignatureButtonProps
> = ({ signature, onActivate }) => {
  const handleClick = () => {
    onActivate(signature)
  }

  return (
    <div
      className="bmm-button"
      onClick={handleClick}
      data-cy="activate-signature-btn"
      data-tour="sig-assign-finish-btn"
    >
      <span className="bmm-button__intitule">
        <FormattedMessage
          id="ActivateSignatureButton.End"
          defaultMessage="TERMINER"
        />
      </span>
    </div>
  )
}

export default ActivateSignatureButton
