import React from "react"

import FormSection from "components/MultiStepForm/FormSection"

import { User } from "features/Users/UserModels"
import Signature from "entities/Signature"

interface Props {
  datas: Signature
}

class SignatureUsersSideBar extends FormSection<Props> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  removeUser = (selectedUser: User) => {
    const affectedUsers = (this.props.datas as Signature).AffectedUsers.filter(
      (user) => user.Id !== selectedUser.Id,
    )
    this.handleFormChange("AffectedUsers", affectedUsers)
  }

  assignUser = (selectedUser: User) => {
    const affectedUsers = [
      ...(this.props.datas as Signature).AffectedUsers,
      selectedUser,
    ]
    this.handleFormChange("AffectedUsers", affectedUsers)
  }

  render() {
    return (
      <div className="col-12 max-content overflow-x animated slideOutLeft"></div>
    )
  }
}

export default SignatureUsersSideBar
