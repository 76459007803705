import cuid from "cuid"
import mime from "mime"

import DateHelper from "utils/DateHelper"

export function generateImageName(): string {
  const format = "YYYYMMDD-HHmmss"
  const date = DateHelper.GetCurrentFormatedDate(format)
  const uniqueID = cuid().slice(-8)

  return `${date}-${uniqueID}`
}

export function fromBlobToFile(file: Blob): File {
  const fileName = `${generateImageName()}.${mime.getExtension(file.type)}`
  return new File([file], fileName, {
    lastModified: new Date().getTime(),
    type: file.type,
  })
}
