import {
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core"
import { SelectProps } from "@material-ui/core"
import React, { FunctionComponent } from "react"

interface Props {
  name?: string
  value?: string | number
  selectOptions?: { value: any; label: string }[]
  error?: boolean
  errorText?: string
  disabled?: boolean
}

type InputSelectProps = Props & SelectProps

const InputSelect: FunctionComponent<InputSelectProps> = (props) => {
  const {
    name,
    selectOptions,
    value,
    onChange,
    onBlur,
    error,
    errorText,
    disabled,
    ...others
  } = props

  return (
    <React.Fragment>
      <Select
        variant="outlined"
        fullWidth
        margin="dense"
        input={
          <OutlinedInput
            className="MuiFormControl-marginDense"
            labelWidth={0}
            error={error}
          />
        } // solution de contournement pour obtenir le style outlined (https://github.com/mui-org/material-ui/issues/14203)
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        {...others}
      >
        {selectOptions &&
          selectOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
      </Select>
      {error && <FormHelperText error={error}>{errorText}</FormHelperText>}
    </React.Fragment>
  )
}

export default InputSelect
