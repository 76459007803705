import { FunctionComponent, memo, useState } from "react"

import moment from "moment"
import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import { ResponsiveLine } from "@nivo/line"

import TrackingGraphTooltip from "features/Tracking/components/TrackingGraphTooltip"

import useGraphAxisConfig from "features/Tracking/hooks/useGraphAxisConfig"

import { BaseHistoryEvent } from "features/Tracking/TrackingModels"

import {
  PRIMARY_GRAPH_COLOR,
  PRIMARY_GRAPH_LIGHT_COLORS,
} from "features/Tracking/TrackingConstants"

interface SignatureDeploymentGraphProps {
  data: BaseHistoryEvent[]
  deploymentValue: number
}

const messages = defineMessages({
  bubbleLabel: {
    id: "Statistiques.SignaturesDeploymentBubbleLabel",
    defaultMessage: "de déploiement",
  },
  graphId: {
    id: "Statistiques.SignaturesDeploymentId",
    defaultMessage: "déploiements par jour",
  },
})

const SignatureDeploymentGraph: FunctionComponent<
  SignatureDeploymentGraphProps
> = ({ data, deploymentValue }) => {
  const { formatMessage } = useIntl()
  const [bubbleIsHovered, setBubbleIsHovered] = useState(false)

  const mappedData = [
    {
      id: formatMessage(messages.graphId),
      label: formatMessage(messages.graphId),
      display: true,
      hover: false,
      selected: false,
      data: data
        .map((click) => ({
          x: click.date,
          y: click.quantity,
        }))
        .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
      color: PRIMARY_GRAPH_COLOR,
      secondaryColor: PRIMARY_GRAPH_LIGHT_COLORS,
    },
  ]

  const { yScaleMax, axisLeftTickValues, axisBottomTickValues, measuredRef } =
    useGraphAxisConfig(mappedData)

  return (
    <div
      className="sign-depl-graph"
      onMouseEnter={() => {
        setBubbleIsHovered(true)
      }}
      onMouseLeave={() => {
        setBubbleIsHovered(false)
      }}
    >
      <div
        className={classNames(
          "sign-depl-graph__bubble",
          bubbleIsHovered && "is-hovered",
        )}
      >
        <p className="sign-depl-graph__bubble--value">{`${deploymentValue}%`}</p>
        <p className="sign-depl-graph__bubble--label">
          {formatMessage(messages.bubbleLabel)}
        </p>
      </div>
      <div className="sign-depl-graph__graph" ref={measuredRef}>
        <ResponsiveLine
          margin={{ top: 5, right: 5, left: 30, bottom: 25 }}
          data={mappedData}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: 0,
            max: yScaleMax,
          }}
          enableArea={true}
          areaOpacity={0.1}
          axisLeft={{
            tickValues: axisLeftTickValues,
          }}
          axisBottom={{
            format: (date) => moment(date).format("D MMM"),
            tickValues: axisBottomTickValues,
          }}
          curve={"monotoneX"}
          enableSlices={false}
          colors={["#3D5AFE"]}
          useMesh={true}
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          animate={true}
          theme={{
            textColor: "#00000061",
            fontSize: 9,
          }}
          tooltip={({ point }) => (
            <TrackingGraphTooltip point={point} unit="%" />
          )}
        />
      </div>
    </div>
  )
}

export default memo(SignatureDeploymentGraph)
