import React from "react"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"

import "./NavigationButtons.scss"

interface PropsNavigationButtons {
  labelPrevious?: string
  labelNext?: string
  disableNext?: boolean
  disablePrevious?: boolean
  onPrevious?(): void
  onNext?: () => void
}

const NavigationButtons: React.FC<PropsNavigationButtons> = ({
  labelPrevious,
  labelNext,
  disablePrevious,
  disableNext,
  onPrevious,
  onNext,
}) => {
  const { enrollmentDatas, setEnrollmentDatas } = useEnrollmentData()

  const labels = {
    previous: "Précédent",
    next: "Suivant",
  }

  const goToStep = (step: number) => {
    setEnrollmentDatas({ currentStep: step })
  }

  const handlePrevious = () => {
    const step = enrollmentDatas.currentStep
    if (step > 1) goToStep(step - 1)
  }

  const handleNext = () => {
    goToStep(enrollmentDatas.currentStep + 1)
  }

  return (
    <div className="navigationButtons">
      {enrollmentDatas.currentStep > 1 && (
        <button
          className="bmm-btn bmm-btn--outlined"
          disabled={disablePrevious}
          onClick={() => {
            onPrevious ? onPrevious() : handlePrevious()
          }}
        >
          {labelPrevious || labels.previous}
        </button>
      )}
      <button
        className="bmm-btn"
        disabled={disableNext}
        onClick={() => {
          onNext ? onNext() : handleNext()
        }}
      >
        {labelNext || labels.next}
      </button>
    </div>
  )
}

export default NavigationButtons
