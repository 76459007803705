import { FunctionComponent, ReactNode } from "react"

import { Roles } from "features/Accounts/AccountsModels"

import {
  WithAuthentication,
  WithAccountInformations,
  WithAccessRoles,
  WithValidOffer,
  WithRequireNoTrial,
  WithTourGuide,
  WithBannerUnlocked,
  WithFeatureActive,
} from "."

import { featureNames } from "config/config.features"

interface PrivateRoutesProps {
  children: ReactNode
  activeFeature?: featureNames
  limitationTitle?: string
  limitationContent?: string
  authentified?: boolean
  offerValid?: boolean
  requirePaidOffer?: boolean
  tourguide?: boolean
  accountFilled?: boolean
  bannerUnlocked?: boolean
  accessRoles?: Roles[]
}

const RestrictedRoute: FunctionComponent<PrivateRoutesProps> = ({
  children,
  activeFeature,
  limitationTitle,
  limitationContent,
  accessRoles,
  authentified = false,
  requirePaidOffer = false,
  offerValid = false,
  tourguide = false,
  accountFilled = false,
  bannerUnlocked = false,
}) => {
  return (
    <WithAuthentication needAuthentification={authentified}>
      <WithAccountInformations needAccountFilled={accountFilled}>
        <WithAccessRoles accessRoles={accessRoles}>
          <WithValidOffer needOfferValid={offerValid}>
            <WithRequireNoTrial
              requirePaidOffer={requirePaidOffer}
              title={limitationTitle}
              content={limitationContent}
            >
              <WithTourGuide tourguide={tourguide}>
                <WithBannerUnlocked
                  needBannerUnlocked={bannerUnlocked}
                  title={limitationTitle}
                  content={limitationContent}
                >
                  <WithFeatureActive needActiveFeature={activeFeature}>
                    {children}
                  </WithFeatureActive>
                </WithBannerUnlocked>
              </WithTourGuide>
            </WithRequireNoTrial>
          </WithValidOffer>
        </WithAccessRoles>
      </WithAccountInformations>
    </WithAuthentication>
  )
}

export default RestrictedRoute
