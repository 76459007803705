import React from "react"
import { useState } from "react"
import FileUpload, { FileUploadProps } from "./FileUpload"
import FileUploadInfo from "./FileUploadInfo"

interface Props {
  onFileChange(files: File)
  onCancel?()
  imageFile?: string
  hideProgress?: boolean
}

const FileUploadCard: React.FC<Props & FileUploadProps> = (props) => {
  const {
    onFileChange,
    onCancel,
    imageFile,
    fileTypes,
    disabled,
    header,
    label,
    labelAllowedFiles,
  } = props
  const [file, setFile] = useState<File>(null)

  const handleChange = (files) => {
    if (!files) return
    if (fileTypes && !fileTypes.includes(files[0]?.type)) return
    setFile(files[0])
    onFileChange(files[0])
  }

  const handleCancel = () => {
    setFile(null)
    onCancel()
  }

  return (
    <div className="file-upload-card">
      {!file || props.hideProgress ? (
        <FileUpload
          onFileChange={handleChange}
          fileTypes={fileTypes}
          disabled={disabled}
          header={header}
          label={label}
          labelAllowedFiles={labelAllowedFiles}
        ></FileUpload>
      ) : (
        <FileUploadInfo
          file={file}
          onCancel={handleCancel}
          imageFile={imageFile}
        ></FileUploadInfo>
      )}
    </div>
  )
}

export default FileUploadCard
