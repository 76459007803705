import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import Toggle from "components/MUIToggle"
import MouseTooltip from "components/MouseTooltip"

interface SignatureCardToggleProps {
  onActivate: () => void
  activated: boolean
}

export type SignatureCardToggleType =
  FunctionComponent<SignatureCardToggleProps>

const messages = defineMessages({
  activated: { id: "SignatureCard.Activated", defaultMessage: "Activé" },
  deactivated: { id: "SignatureCard.Deactivated", defaultMessage: "Désactivé" },
  activatedTooltip: {
    id: "SignatureCard.ActivatedTooltip",
    defaultMessage: "Désactiver la signature",
  },
  deactivatedTooltip: {
    id: "SignatureCard.DeactivatedTooltip",
    defaultMessage: "Activer la signature",
  },
})

const SignatureCardToggle: SignatureCardToggleType = ({
  onActivate,
  activated,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div className="signature-card__infos--toggle">
        <div
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
          <Toggle
            data-cy="signature-card__toggle"
            onChange={() => {
              onActivate()
            }}
            checked={activated}
          />
        </div>
        <p
          className={classNames(
            "signature-card__infos--toggle-label",
            activated && "activated",
          )}
        >
          {formatMessage(activated ? messages.activated : messages.deactivated)}
        </p>
      </div>
      <MouseTooltip visible={visible}>
        {formatMessage(
          activated ? messages.activatedTooltip : messages.deactivatedTooltip,
        )}
      </MouseTooltip>
    </>
  )
}

export default SignatureCardToggle
