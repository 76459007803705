import React from "react"
import Notification from "./"
import CancelIcon from "@material-ui/icons/Cancel"

interface State {
  open: boolean
}

class InfoNotification extends React.Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
  }
  render() {
    const { open } = this.state
    return (
      <Notification
        open={open}
        transition={"left"}
        handleClose={() => this.setState({ open: false })}
        progress={false}
        action={
          <React.Fragment>
            <CancelIcon
              className="info-notification"
              onClick={() => this.setState({ open: false })}
            />
          </React.Fragment>
        }
      >
        {this.props.children}
      </Notification>
    )
  }
}

export default InfoNotification
