import { FunctionComponent, useEffect } from "react"

import { useSearchParams } from "react-router-dom"

import ConnectedSyncProvider from "features/Features/components/SyncProvider"
import SyncProperties from "features/Users/components/SyncProperties"
import AdminsPanel from "features/Accounts/components/AdminsPanel"
import InstallerPanel, {
  PARAM_NAME,
} from "features/Parameters/components/InstallerPanel"

interface ParametersPageContentProps {
  hasAdminGranted: boolean
}

const ParametersPageContent: FunctionComponent<ParametersPageContentProps> = ({
  hasAdminGranted,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentTab = searchParams.get("tab")

  useEffect(() => {
    if (currentTab && currentTab !== "install") {
      searchParams.delete(PARAM_NAME)
      setSearchParams(searchParams)
    }
  }, [searchParams])

  const renderedComponent = {
    admin: hasAdminGranted ? <AdminsPanel /> : null,
    install: <InstallerPanel />,
    sync: <ConnectedSyncProvider />,
    properties: <SyncProperties />,
  }

  return (
    <div className="parameters-page__content">
      {currentTab && renderedComponent[currentTab]}
    </div>
  )
}

export default ParametersPageContent
