import { FunctionComponent, ReactNode } from "react"

import { useSelector } from "react-redux"

import UnauthorizedComponent from "pages/public/Unauthorized"

import { accountsSelectors } from "features/Accounts"
import { getArrayIntersection } from "utils/FunctionHelper"

import { Roles } from "features/Accounts/AccountsModels"

interface WithAccessRolesProps {
  children: ReactNode
  accessRoles?: Roles[]
}

const WithAccessRoles: FunctionComponent<WithAccessRolesProps> = ({
  children,
  accessRoles,
}) => {
  const currentRoles = useSelector(accountsSelectors.getRoles)

  if (accessRoles === undefined) return <>{children}</>

  const isAccessGranted =
    getArrayIntersection(accessRoles, currentRoles).length > 0

  if (!isAccessGranted) return <UnauthorizedComponent />

  return <>{children}</>
}

export default WithAccessRoles
