import React, { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import HoverTooltip from "components/HoverTooltip"
import { InputSelect } from "components/Select"

import APIIcon from "../APIIcon/APIIcon"
import LuccaIcon from "../LuccaIcon/LuccaIcon"
import GSuiteIcon from "../GSuiteIcon/GSuiteIcon"
import O365Icon from "../O365Icon/O365Icon"
import PortalIcon from "../PortalIcon/PortalIcon"
import WarningIcon from "../WarningIcon/WarningIcon"

import { Origin } from "features/Users/UserModels"

interface SyncSourceProps {
  sources: Array<{ label: string; value: string }>
  source: Origin
  isUnknown?: boolean
  isSyncSourceEditing?: boolean
  onEditSyncSource?: () => void
  onChangeSyncSource: (e) => void
}

const SyncSource: FunctionComponent<SyncSourceProps> = ({
  sources,
  source,
  isUnknown,
  isSyncSourceEditing,
  onEditSyncSource,
  onChangeSyncSource,
}) => {
  let sourceIcon

  switch (source) {
    case Origin.ORIGIN_PORTAL:
      sourceIcon = <PortalIcon />
      break
    case Origin.ORIGIN_GSUITE:
      sourceIcon = <GSuiteIcon />
      break
    case Origin.ORIGIN_O365:
      sourceIcon = <O365Icon />
      break
    case Origin.ORIGIN_API:
      sourceIcon = <APIIcon />
      break
    case Origin.ORIGIN_LUCCA:
      sourceIcon = <LuccaIcon />
      break
    default:
      break
  }

  return isUnknown && !isSyncSourceEditing ? (
    <HoverTooltip
      onClick={onEditSyncSource}
      level="info"
      tooltip={
        <FormattedMessage
          id="SyncPropertiesGrid.UnknownProperty"
          defaultMessage="Cette propriété n’existe pas"
        />
      }
    >
      <div>
        <WarningIcon />
      </div>
    </HoverTooltip>
  ) : isSyncSourceEditing ? (
    <InputSelect
      className="sync-properties-grid__cell--input"
      value={source}
      selectOptions={sources}
      onChange={onChangeSyncSource}
      displayEmpty={true}
      renderValue={
        source?.length
          ? undefined
          : () => (
              <span className="sync-properties-grid__select-placeholder">
                [Source]
              </span>
            )
      }
      disabled={sources.length <= 1}
    />
  ) : (
    <div onClick={onEditSyncSource}>{sourceIcon}</div>
  )
}

export default SyncSource
