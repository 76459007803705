import { useEffect } from "react"
import { useLocationState } from "./useLocationState"

export function useScroll(selector) {
  useEffect(() => {
    if (selector == null || selector === "") return
    const element = document.querySelector(selector)
    if (element == null) return
    element.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "nearest",
    })
  }, [])
}

/**
 *  scroll automatiquement au niveau de la div d'Id trouvée dans le context `LocationState`
 * @param key nom de la clé dans le state context qui stockera l'Id de l'élément cliqué
 */
export function useAutoScrollTo(key) {
  const { getLocationState, setLocationState } = useLocationState(key)
  useScroll("#" + getLocationState())
  return {
    setScrollId: setLocationState,
  }
}
