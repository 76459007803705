import { FunctionComponent, useState } from "react"

import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as IconDuplicate } from "assets/icon-duplicate.svg"

import MouseTooltip from "components/MouseTooltip"

interface ScenarioCardDuplicateActionProps {
  onDuplicate: () => void
  isMultiple: boolean
  roundedCorner?: "top" | "bottom"
}

export type ScenarioCardDuplicateActionType =
  FunctionComponent<ScenarioCardDuplicateActionProps>

const messages = defineMessages({
  duplicateCampaign: {
    id: "ScenarioCard.DuplicateCampaignTooltip",
    defaultMessage: "Dupliquer la campagne",
  },
  duplicateScenario: {
    id: "ScenarioCard.DuplicateScenarioTooltip",
    defaultMessage: "Dupliquer le scénario",
  },
})

const ScenarioCardDuplicateAction: ScenarioCardDuplicateActionType = ({
  onDuplicate,
  isMultiple,
  roundedCorner,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div
        className={classNames(
          "scenario-card__btn",
          roundedCorner && `scenario-card__rounder-corner--${roundedCorner}`,
        )}
        onClick={onDuplicate}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <IconDuplicate className="scenario-card__btn--icon" />
      </div>
      <MouseTooltip visible={visible}>
        {isMultiple
          ? formatMessage(messages.duplicateScenario)
          : formatMessage(messages.duplicateCampaign)}
      </MouseTooltip>
    </>
  )
}

export default ScenarioCardDuplicateAction
