import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { builderSignaturesSelectors } from "features/BuilderSignatures"
import {
  loadBuilderSignatureGroupsAction,
  loadBuilderSignatureUsersAction,
} from "../BuilderSignaturesActions"
import { updateActiveBuilderSignatureAssignment } from "../BuilderSignaturesReducers"

interface UseBuilderSignatureAffectedDatas {
  affectedUsers: number[]
  affectedGroups: number[]
}

const useBuilderSignatureAffectedDatas =
  (): UseBuilderSignatureAffectedDatas => {
    const dispatch = useDispatch()
    const { users: affectedUsers, groups: affectedGroups } = useSelector(
      builderSignaturesSelectors.getActiveBuilderSignatureAssignment,
    )
    const activeBuilderSignature = useSelector(
      builderSignaturesSelectors.getActiveBuilderSignature,
    )

    useEffect(() => {
      if (activeBuilderSignature?.id) {
        dispatch(loadBuilderSignatureUsersAction(activeBuilderSignature.id))
        dispatch(loadBuilderSignatureGroupsAction(activeBuilderSignature.id))
      }
    }, [dispatch, activeBuilderSignature?.id])

    useEffect(() => {
      if (activeBuilderSignature?.affectedUsers)
        dispatch(
          updateActiveBuilderSignatureAssignment({
            users: activeBuilderSignature?.affectedUsers,
          }),
        )
    }, [activeBuilderSignature?.affectedUsers])

    useEffect(() => {
      if (activeBuilderSignature?.affectedGroups)
        dispatch(
          updateActiveBuilderSignatureAssignment({
            groups: activeBuilderSignature?.affectedGroups,
          }),
        )
    }, [activeBuilderSignature?.affectedGroups])

    return { affectedUsers, affectedGroups }
  }

export default useBuilderSignatureAffectedDatas
