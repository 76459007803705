import { FunctionComponent } from "react"

import classNames from "classnames"

import { AvailableLanguages } from "features/App/AppModels"

interface LangPillSwitchItemProps {
  locale: AvailableLanguages
  isSelected: boolean
  onSwitchLanguage: (lang: AvailableLanguages) => void
}

const LangPillSwitchItem: FunctionComponent<LangPillSwitchItemProps> = ({
  locale,
  isSelected,
  onSwitchLanguage,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        "lang-pill-switch__item",
        isSelected && "is-selected",
      )}
      onClick={() => onSwitchLanguage(locale)}
    >
      {locale}
    </button>
  )
}

export default LangPillSwitchItem
