import { FunctionComponent, useEffect, useState } from "react"

import ReactCrop, { Crop } from "react-image-crop"

import classNames from "classnames"

import { getCroppedImgAsDataUrl } from "utils/ImageUtils"

import { FormattedMessage } from "react-intl"

import CropSize from "./CropSize"

interface ImageCropperProps {
  imageUrl: string
  cropWidth: number
  cropHeight: number
  setCroppedImage: (
    croppedImageUrl: string,
    width: number,
    height: number,
  ) => void
}

const cropInitialState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  unit: "px" as Crop["unit"],
}

const ImageCropper: FunctionComponent<ImageCropperProps> = ({
  imageUrl,
  cropWidth,
  cropHeight,
  setCroppedImage,
}) => {
  const [crop, setCrop] = useState<Crop>(cropInitialState)

  useEffect(() => {
    setCrop((prevState) => ({
      ...prevState,
      width: cropWidth,
      height: cropHeight,
    }))
  }, [])

  const validateCroppedImage = () => {
    getCroppedImgAsDataUrl(imageUrl, crop, (croppedImageDataUrl) => {
      setCroppedImage(croppedImageDataUrl, crop.width, crop.height)
    })
  }

  return (
    <div className={classNames("image-editor animated fadeIn")}>
      <CropSize width={crop.width} height={crop.height} />
      <div className="image-editor__container">
        <ReactCrop onChange={(updatedCrop) => setCrop(updatedCrop)} crop={crop}>
          <img src={imageUrl} />
        </ReactCrop>
      </div>
      <div className="bmm-button__container">
        <button
          className="bmm-button bmm-dialog__button__round"
          onClick={validateCroppedImage}
        >
          <FormattedMessage id="ImageCropper.Crop" defaultMessage="Recadrer" />
        </button>
      </div>
    </div>
  )
}

export default ImageCropper
