import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import moment from "moment"

import { Link } from "react-router-dom"

import Alert from "components/Alert"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import { WeightingReadOnly } from "components/Weighting"

import { BannerFormVm } from "features/Scenarios/ScenarioModels"

import { CAMPAIGNS_URL } from "router/RouterConstants"

import Group from "features/Groups/GroupsModels"

const messages = defineMessages({
  resume: {
    id: "Campaigns.CampaignSummary",
    defaultMessage: "Résumé de la campagne",
  },
  name: {
    id: "Campaigns.NameLabel",
    defaultMessage: "Nom",
  },
  link: {
    id: "Campaigns.Link",
    defaultMessage: "Lien",
  },
  updateLink: {
    id: "Campaigns.ChangeLink",
    defaultMessage: "Modifier",
  },
  noBannerLink: {
    id: "Campaign.NoBannerLink",
    defaultMessage: "Pas de lien de paramétré",
  },
  noLink: {
    id: "Campaigns.NoLink",
    defaultMessage: "Aucun lien pour cette campagne",
  },
  dates: {
    id: "Campaigns.Dates",
    defaultMessage: "Dates de publication",
  },
  changeDates: {
    id: "Campaign.ChangeDates",
    defaultMessage: "Changer les dates",
  },
  default: {
    id: "Campaign.Default",
    defaultMessage: "Campagne par défaut",
  },
  from: {
    id: "Campaign.From",
    defaultMessage: "Du",
  },
  to: {
    id: "Campaign.To",
    defaultMessage: "au",
  },
  associatedUsers: {
    id: "Campaigns.AssociatedUsers",
    defaultMessage: "Utilisateur(s) associés",
  },
  groups: {
    id: "Campaigns.Groups",
    defaultMessage: "groupes",
  },
  group: {
    id: "Campaigns.Group",
    defaultMessage: "groupe",
  },
  users: {
    id: "Campaigns.Users",
    defaultMessage: "utilisateurs",
  },
  user: {
    id: "Campaigns.User",
    defaultMessage: "utilisateur",
  },
  noUser: {
    id: "Campaigns.NoUSer",
    defaultMessage: "Aucun utilisateur n'est associé à cette campagne",
  },
  weighting: {
    id: "Campaigns.Weighting",
    defaultMessage: "Niveau de priorité",
  },
  additionalBanner: {
    id: "Campaigns.AdditionalBanner",
    defaultMessage: "Bannière additionnelle",
  },
  yes: {
    id: "Campaigns.Yes",
    defaultMessage: "Oui",
  },
  no: {
    id: "Campaigns.No",
    defaultMessage: "Non",
  },
})

function countDistinctUsers(groupIds: number[], allGroups: Group[]) {
  const ids = []
  groupIds.map((groupId) => {
    const group = allGroups.find((group) => group.Id === groupId)
    if (group) {
      group.MembersId.map((user) => {
        if (!ids.includes(user)) {
          ids.push(user)
        }
      })
    }
  })

  return ids.length
}

function truncate(str, length = 40) {
  if (str.length > length) str = str.substring(0, length - 1) + "…"
  return str
}

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY")
}

const CampaignFormSummarySidebar: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { editingCampaign, setStep, allGroups } = useScenarioFormContext()

  const baseUrl = `${CAMPAIGNS_URL}/edit/${editingCampaign.id}/step/`

  // Modification des liens des scénarios se fait en step 1 alors que pour un scénario simple, en step 1
  const updateLinkStep = editingCampaign.banners.length > 1 ? 1 : 2

  const updateQueryParamater =
    editingCampaign.banners.length > 1 ? "?mode=multiple" : ""

  return (
    <div className="campaignform__summary">
      <h4>{formatMessage(messages.resume)}</h4>
      <h5>{formatMessage(messages.name)}</h5>
      <label>{editingCampaign.name}</label>
      <h5>
        <span>{formatMessage(messages.link)}</span>
        <Link
          onClick={() => setStep(updateLinkStep)}
          to={`${baseUrl}${updateLinkStep}${updateQueryParamater}`}
        >
          {formatMessage(messages.updateLink)}
        </Link>
      </h5>
      {editingCampaign.type === "multiple" && (
        <>
          {editingCampaign.banners.map((banner: BannerFormVm, index) => (
            <div className="campaignscenariocardlist__link" key={index}>
              <div className="campaignscenariocardlist__number">
                {index + 1}
              </div>
              {banner?.linkUrl ? (
                <div>
                  {truncate(banner.linkUrl)}{" "}
                  <a
                    href={banner.linkUrl}
                    target="_blank"
                    title="Ouvrir le lien dans un nouvel onglet"
                  >
                    <i className="material-icons">link</i>
                  </a>
                </div>
              ) : (
                <div>{formatMessage(messages.noBannerLink)}</div>
              )}
            </div>
          ))}
        </>
      )}
      {editingCampaign.type === "simple" &&
        (editingCampaign.banners[0]?.linkUrl ? (
          <>
            <label
              className="campaignform__summary--label"
              style={{ display: "inline" }}
            >
              {editingCampaign.banners[0].linkUrl} &nbsp;
            </label>
            <a
              href={editingCampaign.banners[0].linkUrl}
              target="_blank"
              title="Ouvrir le lien dans un nouvel onglet"
            >
              <i className="material-icons">link</i>
            </a>
          </>
        ) : (
          <>
            <label
              className="campaignform__summary--label"
              style={{ paddingBottom: "1em" }}
            >
              {formatMessage(messages.noLink)}
            </label>
            <Alert title="Une campagne avec un lien boostera votre visibilité" />
          </>
        ))}
      <h5>
        <span>{formatMessage(messages.dates)}</span>
        <Link
          onClick={() => setStep(2)}
          to={`${baseUrl}2${updateQueryParamater}`}
        >
          {formatMessage(messages.changeDates)}
        </Link>
      </h5>
      {editingCampaign.default && (
        <label className="campaignform__summary--label">
          {formatMessage(messages.default)}
        </label>
      )}
      {!editingCampaign.default &&
        editingCampaign.startDate &&
        editingCampaign.endDate && (
          <label className="campaignform__summary--label">
            {`${formatMessage(messages.from)} ${formatDate(
              editingCampaign.startDate,
            )} ${formatMessage(messages.to)} ${formatDate(
              editingCampaign.endDate,
            )}`}
          </label>
        )}
      <h5>
        <span>{formatMessage(messages.additionalBanner)}</span>
        {formatMessage(editingCampaign.additional ? messages.yes : messages.no)}
      </h5>
      <h5>
        <span>{formatMessage(messages.associatedUsers)}</span>
        <Link onClick={() => setStep(3)} to={`${baseUrl}3`}>
          Modifier
        </Link>
      </h5>
      <div className="usersGroups">
        {editingCampaign.userIds.length > 0 ||
        editingCampaign.groupIds.length > 0 ? (
          <div>
            <i className="material-icons users">people</i>{" "}
            <label className="campaignform__summary--label">
              {editingCampaign.groupIds.length}&nbsp;
              {editingCampaign.groupIds.length > 1
                ? formatMessage(messages.groups)
                : formatMessage(messages.group)}
              {editingCampaign.groupIds.length > 0 && (
                <>
                  &nbsp; (
                  {countDistinctUsers(editingCampaign.groupIds, allGroups)}
                  &nbsp;
                  {countDistinctUsers(editingCampaign.groupIds, allGroups) > 1
                    ? formatMessage(messages.users)
                    : formatMessage(messages.user)}
                  )
                </>
              )}
            </label>
            <br />
            <i className="material-icons groups">person</i>{" "}
            <label className="campaignform__summary--label">
              {editingCampaign.userIds.length}&nbsp;
              {editingCampaign.userIds.length > 1
                ? formatMessage(messages.users)
                : formatMessage(messages.user)}
            </label>
          </div>
        ) : (
          <label className="campaignform__summary--label">
            {formatMessage(messages.noUser)}
          </label>
        )}
      </div>

      <h5>{formatMessage(messages.weighting)}</h5>
      <div className="weighting ">
        <WeightingReadOnly weighting={editingCampaign.weighting} />
      </div>
    </div>
  )
}

export default CampaignFormSummarySidebar
