import React from "react"
import config from "config/config"
import Tour from "entities/Tour"

interface Props {
  title: string
  subtitle: string
  imageUrl: string
  tour: Tour
  onClick(tour: Tour)
}

class OnBoardingCard extends React.Component<Props> {
  handleClick = (evt) => {
    this.props.onClick(this.props.tour)
  }

  render() {
    return (
      <article className="on-boarding__card">
        <section className="on-boarding__card-header">
          <h4 className="on-boarding__card-title">{this.props.title}</h4>
          <span className="on-boarding__card-subline">
            {this.props.subtitle}
          </span>
          <img
            src={this.props.imageUrl}
            alt=""
            className="on-boarding_card-img"
          />
          <div className="on-boarding__card-footer">
            {!new Tour(this.props.tour).isComplete() && (
              <button
                className="bmm-button on-boarding__btn"
                onClick={this.handleClick}
              >
                <img
                  src={`${config.cdnUrl}/images/bmm/play_btn.png`}
                  className="on-boarding__play-btn"
                  alt="play"
                />{" "}
                Go
              </button>
            )}

            {new Tour(this.props.tour).isComplete() && (
              <button className="bmm-button bmm-button--reverse on-boarding__btn on-boarding__btn--complete">
                <i className="material-icons animated bounceInDown">done</i>
              </button>
            )}
          </div>
        </section>
      </article>
    )
  }
}

export default OnBoardingCard
