import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as UserIcon } from "assets/icon-user.svg"
import { ReactComponent as ClickPointerIcon } from "assets/icon-click-pointer.svg"

import TrackingTotalsGraph from "./components/TrackingTotalsGraph"
import TrackingTotalsKPIBlock from "./components/TrackingTotalsKPIBlock"
import TrackingTotalsInsufficientDatas from "./components/TrackingTotalsInsufficientDatas"

import SharedTrackingMapper from "features/Tracking/mappers/SharedTrackingMapper"
import useTrackingGraphData from "features/Tracking/hooks/useTrackingGraphData"

import { SharedTrackingHistory } from "features/Tracking/TrackingModels"

import { MINIMUM_GRAPH_DATA_LENGTH } from "features/Tracking/TrackingConstants"

const messages = defineMessages({
  clickRate: {
    id: "TrackingTotals.ClicksRate",
    defaultMessage: "Taux de clics",
  },
  users: {
    id: "TrackingTotals.Users",
    defaultMessage: "Utilisateurs",
  },
  clicks: {
    id: "TrackingTotals.Clicks",
    defaultMessage: "clics générés",
  },
})

interface TrackingTotalsProps {
  entitiesTrackingHistory: SharedTrackingHistory
  maxUsers: number
  totalClicks: number
  tooltip?: string
}

const TrackingTotals: FunctionComponent<TrackingTotalsProps> = ({
  entitiesTrackingHistory,
  maxUsers,
  totalClicks,
  tooltip,
}) => {
  const { formatMessage } = useIntl()

  const { data } = useTrackingGraphData<SharedTrackingHistory>(
    entitiesTrackingHistory,
    SharedTrackingMapper.getGlobalHistoryGroupedByDate,
  )

  const sufficientGraphDatas =
    Math.max(...data.map((d) => d.data.length)) >= MINIMUM_GRAPH_DATA_LENGTH

  return (
    <div className="tracking-totals">
      {sufficientGraphDatas ? (
        <TrackingTotalsGraph
          title={formatMessage(messages.clickRate)}
          data={data}
        />
      ) : (
        <TrackingTotalsInsufficientDatas
          title={formatMessage(messages.clickRate)}
        />
      )}
      <div className="tracking-totals__kpi-container">
        <TrackingTotalsKPIBlock
          icon={<UserIcon className="tracking-totals__kpi-block--icon" />}
          label={formatMessage(messages.users)}
          value={maxUsers === 0 ? "-" : maxUsers}
          tooltip={tooltip}
        />
        <TrackingTotalsKPIBlock
          icon={
            <ClickPointerIcon className="tracking-totals__kpi-block--icon" />
          }
          label={formatMessage(messages.clicks)}
          value={totalClicks === 0 ? "-" : totalClicks}
        />
      </div>
    </div>
  )
}

export default TrackingTotals
