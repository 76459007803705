import { FunctionComponent } from "react"
import { defineMessages } from "react-intl"

import Container from "./components/WeightingContainer"

interface WeightingProps {
  weighting: number
  min?: number
  max?: number
  step?: number
  onChange?: (value: number) => void
}

export const DEFAULT_WEIGHTING = {
  min: 1,
  max: 5,
  step: 1,
}

export const messages = defineMessages({
  veryLow: { id: "Weighting.VeryLow", defaultMessage: "très faible" },
  low: { id: "Weighting.Low", defaultMessage: "faible" },
  medium: { id: "Weighting.Medium", defaultMessage: "moyenne" },
  high: { id: "Weighting.High", defaultMessage: "forte" },
  veryHigh: { id: "Weighting.VeryHigh", defaultMessage: "très forte" },
  weighting: { id: "Weighting.Label", defaultMessage: "Priorité" },
})

export function getWeightingLabel(weighting: number) {
  switch (weighting) {
    case 2:
      return messages.low
    case 3:
      return messages.medium
    case 4:
      return messages.high
    case 5:
      return messages.veryHigh
    case 1:
    default:
      return messages.veryLow
  }
}

const Weighting: FunctionComponent<WeightingProps> = ({
  weighting,
  onChange,
  min = DEFAULT_WEIGHTING.min,
  max = DEFAULT_WEIGHTING.max,
  step = DEFAULT_WEIGHTING.step,
}) => {
  return (
    <Container>
      <Container.Indicators
        weighting={weighting}
        min={min}
        max={max}
        onChange={onChange}
      />
      <Container.Slider
        weighting={weighting}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
      />
      <Container.Label weighting={weighting} />
    </Container>
  )
}

export default Weighting
