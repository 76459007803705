import { FunctionComponent } from "react"

import { NavLink } from "react-router-dom"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as LogoBMM } from "assets/logo-text-bmm.svg"
import { ReactComponent as Illustration } from "features/Accounts/assets/register-form-success-illustration.svg"

import { LOGIN_URL } from "router/RouterConstants"

const messages = defineMessages({
  thankYou: {
    id: "RegisterForm.ThankYou",
    defaultMessage: "Merci !",
  },
  mailConfirm: {
    id: "RegisterForm.MailConfirm",
    defaultMessage: "Un mail de confirmation vous a été envoyé !",
  },
  loginButton: {
    id: "RegisterForm.LoginButton",
    defaultMessage: "Authentification",
  },
})

const RegisterFormSuccess: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="register-form__success">
      <LogoBMM className="register-form__logo" />
      <div className="register-form__success--container">
        <p className="register-form__success--title">
          {formatMessage(messages.thankYou)}
        </p>
        <div className="register-form__success--email">
          <Illustration />
          <p className="register-form__success--subtitle">
            {formatMessage(messages.mailConfirm)}
          </p>
        </div>
        <NavLink
          className="register-form__success--redirect-button"
          to={LOGIN_URL}
        >
          {formatMessage(messages.loginButton)}
        </NavLink>
      </div>
    </div>
  )
}

export default RegisterFormSuccess
