import React from "react"
import { uuid } from "utils/Uuid"

export type NotificationType = "INFO" | "WARNING" | "ERROR" | "SUCCESS"

export interface NotificationDisplayProps {
  notification: Notification
}

export type NotificationDisplay =
  | React.FC<NotificationDisplayProps>
  | React.Component<NotificationDisplayProps>
export class Notification {
  id: string
  type: NotificationType
  message: string
  constructor(message: string, type: NotificationType) {
    this.id = uuid.generate()
    this.message = message
    this.type = type
  }
}
