import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { Origin, User } from "features/Users/UserModels"

import { MenuItem, Select, Slide, TextField } from "@material-ui/core"
import { Link, useNavigate } from "react-router-dom"

import Loader from "components/Loader"
import useUsersData from "features/Users/hooks/useUsersData"
import useGroupsData from "features/Groups/hooks/useGroupsData"
import useSingleGroupData from "features/Groups/hooks/useSingleGroupData"
import { useSelector } from "react-redux"
import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"
import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { Controller, useForm } from "react-hook-form"
import DynamicForm from "./DynamicForm"
import BasicForm from "./BasicForm"
import { featureNames } from "config/config.features"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { GlobalStates } from "store/globalState"
import { USERS_URL } from "router/RouterConstants"
import { accountsSelectors } from "features/Accounts"

interface EditGroupProps {
  currentGroupId: number
}

export interface Members {
  id: number
  label: string
}

const messages = defineMessages({
  portalOrigin: { id: "EditGroup.PortalOrigin", defaultMessage: "Portail" },
  o365Origin: { id: "EditGroup.O365Origin", defaultMessage: "O365" },
  gsuiteOrigin: { id: "EditGroup.GSuiteOrigin", defaultMessage: "GSuite" },
  apiOrigin: { id: "EditGroup.ApiOrigin", defaultMessage: "API" },
  dynamicGroup: {
    id: "EditGroup.DynamicGroups",
    defaultMessage: "Groupe dynamique",
  },
  required: {
    id: "EditGroup.Required",
    defaultMessage: "Le nom du groupe est obligatoire",
  },
  errorUnique: {
    id: "EditGroup.ErrorUnique",
    defaultMessage: "Ce nom de groupe est déjà utilisé",
  },
  headerEdit: {
    id: "EditGroup.HeaderEdit",
    defaultMessage: "Modifier un groupe",
  },
  headerAdd: {
    id: "EditGroup.HeaderAdd",
    defaultMessage: "Ajouter un groupe",
  },
  nameLabel: { id: "EditGroup.Name", defaultMessage: "Nom du groupe *" },
  originLabel: {
    id: "EditGroup.Origin",
    defaultMessage: "Origine du groupe",
  },
  groupPortal: {
    id: "EditGroup.GroupPortal",
    defaultMessage: "Groupe Portail",
  },
  groupM365: { id: "EditGroup.GroupM365", defaultMessage: "Groupe M365" },
  groupGoogle: { id: "EditGroup.GroupGoogle", defaultMessage: "Groupe Google" },
  groupApi: { id: "EditGroup.GroupApi", defaultMessage: "Groupe API" },
})

export function getUsersOptions(users: User[]): Members[] {
  return users.map((user) => ({
    id: user.Id,
    label: User.getFullName(user),
  }))
}

const originOptions = {
  [Origin.ORIGIN_PORTAL]: messages.groupPortal,
  [Origin.ORIGIN_O365]: messages.groupM365,
  [Origin.ORIGIN_GSUITE]: messages.groupGoogle,
  [Origin.ORIGIN_API]: messages.groupApi,
  [Origin.ORIGIN_DYNAMIC]: messages.dynamicGroup,
}

const EditGroup: FunctionComponent<EditGroupProps> = ({ currentGroupId }) => {
  const navigate = useNavigate()
  const allUsers = useSelector(usersSelectors.getAllUsers)
  const allGroups = useSelector(groupsSelectors.getAllGroups)
  const { loadingStatus: userLoadingStatus } = useUsersData()
  const { loadingStatus: groupsLoadingStatus } = useGroupsData()

  const isFeatureDynamicGroupsActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.DYNAMIC_GROUPS),
  ) as boolean
  const isApiSyncActive = useSelector(accountsSelectors.getApiSyncStatus)
  const isGSuiteSyncActive = useSelector(accountsSelectors.getGSuiteSyncStatus)
  const isO365SyncActive = useSelector(accountsSelectors.getO365SyncStatus)

  const isAtLeatASyncActive =
    isApiSyncActive ||
    isGSuiteSyncActive ||
    isO365SyncActive ||
    isFeatureDynamicGroupsActive

  const { formatMessage } = useIntl()
  const [members, setMembers] = useState<Members[]>([])
  const [slide, setSlide] = useState(true)
  const [isDynamic, setIsDynamic] = useState(false)
  const [displayAddButton, setDisplayAddButton] = useState<boolean>(true)

  const {
    currentGroup,
    isLoading,
    isSaving,
    onResetEditingGroup,
    onAddGroup,
    onEditGroup,
    onUpsertDynamicGroup,
  } = useSingleGroupData(currentGroupId)

  const form = useForm({
    shouldUnregister: true,
  })

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = form

  const watchOrigin = watch("origin")

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "origin")
        setDisplayAddButton(value.origin === Origin.ORIGIN_PORTAL)
    })
    return () => subscription.unsubscribe()
  }, [watchOrigin])

  useEffect(() => {
    if (currentGroup?.Origin === Origin.ORIGIN_PORTAL) setDisplayAddButton(true)
  }, [currentGroup])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name == "origin") {
        setIsDynamic(value.origin == Origin.ORIGIN_DYNAMIC)
      }
    })
    return () => subscription.unsubscribe()
  }, [watchOrigin])

  const isUpdate = !!currentGroup

  useEffect(() => {
    if (isSaving === SavingStatus.SUCCESS) navigate(`${USERS_URL}?tab=groups`)
  }, [isSaving])

  useEffect(() => {
    if (currentGroup) {
      const groupMembers =
        allUsers.length > 0
          ? currentGroup?.MembersId.map((id) =>
              allUsers.find((u) => u.Id === id),
            )
          : []

      setMembers(getUsersOptions(groupMembers))
      setValue("name", currentGroup.Name)
      setIsDynamic(currentGroup.Origin == Origin.ORIGIN_DYNAMIC)
      setValue("origin", currentGroup.Origin)
    }
  }, [currentGroup, allUsers])

  const groupExists = (name: string) => {
    if (allGroups && name) {
      const isNew = currentGroupId === -1
      return allGroups.some((group) => {
        return (
          (isNew || group.Id !== currentGroupId) &&
          group.InternalName.toLowerCase() === name.toLowerCase()
        )
      })
    }
  }

  const handleReset = () => {
    onResetEditingGroup()
  }

  const isLoaded =
    (isLoading === LoadingStatus.LOADED || currentGroupId == -1) &&
    userLoadingStatus === LoadingStatus.LOADED &&
    groupsLoadingStatus === LoadingStatus.LOADED

  const selectOptions = useMemo(
    () =>
      [
        {
          value: Origin.ORIGIN_PORTAL,
          label: messages.portalOrigin,
          isAvailable: true,
        },
        {
          value: Origin.ORIGIN_O365,
          label: messages.o365Origin,
          isAvailable: isO365SyncActive,
        },
        {
          value: Origin.ORIGIN_GSUITE,
          label: messages.gsuiteOrigin,
          isAvailable: isGSuiteSyncActive,
        },
        {
          value: Origin.ORIGIN_API,
          label: messages.apiOrigin,
          isAvailable: isApiSyncActive,
        },
        {
          value: Origin.ORIGIN_DYNAMIC,
          label: messages.dynamicGroup,
          isAvailable: isFeatureDynamicGroupsActive,
        },
      ].filter((sync) => sync.isAvailable),
    [
      isO365SyncActive,
      isGSuiteSyncActive,
      isApiSyncActive,
      isFeatureDynamicGroupsActive,
    ],
  )

  return (
    <div className="edit-group">
      <Loader
        fullScreen
        isLoading={
          isLoading === LoadingStatus.PENDING ||
          userLoadingStatus === LoadingStatus.PENDING ||
          groupsLoadingStatus === LoadingStatus.PENDING ||
          isSaving === SavingStatus.SAVING
        }
      />
      {isLoaded && (
        <>
          <Slide direction="right" in={slide} onExited={() => setSlide(false)}>
            <div className="edit-group__container">
              <div className="edit-group__header">
                <Link
                  to={`${USERS_URL}?tab=groups`}
                  onClick={() => handleReset()}
                >
                  <div className="edit-group__icon">
                    <i className="material-icons">arrow_back</i>
                  </div>
                </Link>
                <div className="edit-group__title">
                  {formatMessage(
                    isUpdate ? messages.headerEdit : messages.headerAdd,
                  )}
                </div>
              </div>
              <div className="edit-group__body" data-cy="edit-group">
                <form>
                  <div className="edit-group-form">
                    <div>
                      <div className="edit-group-form_title">
                        {formatMessage(messages.nameLabel)}
                      </div>
                      <div className="edit-group-form_field">
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: formatMessage(messages.required),
                            validate: {
                              unique: (value) =>
                                !groupExists(value) ||
                                formatMessage(messages.errorUnique),
                            },
                          }}
                          render={({ field: { onChange, value, ref } }) => {
                            return (
                              <TextField
                                style={{ width: "100%" }}
                                name={"name"}
                                variant="outlined"
                                value={value}
                                error={errors[`name`]}
                                helperText={
                                  errors[`name`] && errors[`name`].message
                                }
                                onChange={onChange}
                                inputRef={ref}
                              />
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      {isUpdate || !isAtLeatASyncActive ? (
                        <div className="edit-group__origin">
                          {formatMessage(
                            originOptions[
                              currentGroup?.Origin || Origin.ORIGIN_PORTAL
                            ],
                          )}
                        </div>
                      ) : (
                        <>
                          <div className="edit-group-form_title">
                            {formatMessage(messages.originLabel)}
                          </div>
                          <div className="edit-group-form_field">
                            <Controller
                              name={"origin"}
                              control={control}
                              defaultValue={Origin.ORIGIN_PORTAL}
                              rules={{
                                required: formatMessage(messages.required),
                                validate: {
                                  unique: (value) =>
                                    !groupExists(value) ||
                                    formatMessage(messages.errorUnique),
                                },
                              }}
                              render={({ field: { onChange, value, ref } }) => {
                                return (
                                  <Select
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    inputRef={ref}
                                  >
                                    {selectOptions.map((sync) => (
                                      <MenuItem
                                        key={sync.value}
                                        value={sync.value}
                                      >
                                        {formatMessage(sync.label)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {isDynamic ? (
                    <DynamicForm
                      allUsers={allUsers}
                      currentGroup={currentGroup}
                      onUpsertDynamicGroup={onUpsertDynamicGroup}
                      form={form}
                      members={members}
                      setMembers={setMembers}
                    />
                  ) : (
                    <BasicForm
                      allUsers={allUsers}
                      displayAddButton={displayAddButton}
                      isAtLeatASyncActive={isAtLeatASyncActive}
                      currentGroup={currentGroup}
                      onAddGroup={onAddGroup}
                      onEditGroup={onEditGroup}
                      form={form}
                      members={members}
                      setMembers={setMembers}
                    />
                  )}
                </form>
              </div>
            </div>
          </Slide>
        </>
      )}
    </div>
  )
}

export default EditGroup
