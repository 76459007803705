import React from "react"
import { LinearProgress } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

interface Props {
  variant?: VariantType
  value?: number
  colors?: { primary: string; secondary: string }
}

export enum VariantType {
  DETERMINATE = "determinate",
  INDETERMINATE = "indeterminate",
  BUFFER = "buffer",
  QUERY = "query",
}

class LoadingBar extends React.Component<Props> {
  render() {
    const { variant, value, colors } = this.props

    const StyledLinearProgress =
      colors != null
        ? withStyles({
            colorPrimary: {
              backgroundColor: colors.primary,
            },
            barColorPrimary: {
              backgroundColor: colors.secondary,
            },
          })(LinearProgress)
        : LinearProgress

    return (
      <div className="loading-bar">
        <StyledLinearProgress variant={variant} value={value} />
      </div>
    )
  }
}

export default LoadingBar
