import { FunctionComponent, ReactNode, useEffect } from "react"

import { useSearchParams } from "react-router-dom"

import SubHeaderTab, { SubHeaderTabType } from "./SubHeaderTab/SubHeaderTab"

interface SubHeaderTabsComposition {
  Tab: SubHeaderTabType
}

interface SubHeaderTabsProps {
  children: ReactNode
  availableTabs: string[]
  defaultTab: string
}

type SubHeaderTabsType = FunctionComponent<SubHeaderTabsProps> &
  SubHeaderTabsComposition

const SubHeaderTabs: SubHeaderTabsType = ({
  children,
  availableTabs,
  defaultTab,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const currentTab = searchParams.get("tab")
    if (!currentTab || !availableTabs.includes(currentTab)) {
      searchParams.set("tab", defaultTab)
      setSearchParams(searchParams)
    }
  }, [searchParams])

  return <div className="sub-header-tabs">{children}</div>
}

SubHeaderTabs.Tab = SubHeaderTab

export default SubHeaderTabs
