import { FunctionComponent, useMemo, useState } from "react"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { useDispatch, useSelector } from "react-redux"

import { Tag } from "components/PeoplePicker"
import PeoplePickerCollapseLists from "components/PeoplePickerCollapseLists"

import BuilderPeoplePicker from "features/BuilderSignatures/components/BuilderPeoplePicker"

import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"
import { User } from "features/Users/UserModels"

import useBuilderSignatureAffectedDatas from "features/BuilderSignatures/hooks/useBuilderSignatureAffectedDatas"
import { updateActiveBuilderSignatureAssignment } from "features/BuilderSignatures/BuilderSignaturesReducers"
import Group from "features/Groups/GroupsModels"
import ArrayHelper from "utils/ArrayHelper"

const messages = defineMessages({
  searchPlaceholder: {
    id: "Campaigns.SearchPlaceholder",
    defaultMessage: "Rechercher des utilisateurs / groupes",
  },
})

const tagToItems = (tags: Tag[], filter: "user" | "group") =>
  tags
    .filter((tag) => tag.type === filter)
    .map((tag) => {
      const { id, label } = tag
      return { id, label }
    })

const BuilderAssignmentPanel: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const [tags, setTags] = useState<Tag[]>([])
  const { affectedUsers, affectedGroups } = useBuilderSignatureAffectedDatas()

  const activeGroups: Group[] = useSelector(groupsSelectors.getLicencedGroups)
  const activeUsers = useSelector(usersSelectors.getAllUsersWithLicences)

  const activeMembers = useMemo(
    () => [
      ...new Set(
        activeGroups.reduce(
          (acc, group) =>
            affectedGroups.includes(group.Id)
              ? [...acc, ...group.MembersId]
              : acc,
          [],
        ),
      ),
    ],
    [affectedGroups, activeGroups],
  )

  const handleToggleUsers = (item) => {
    if (affectedUsers.find((userId) => userId === item.id)) {
      dispatch(
        updateActiveBuilderSignatureAssignment({
          users: affectedUsers.filter((userId) => userId !== item.id),
        }),
      )

      setTags(tags.filter((t) => t.id !== item.id))
    } else {
      dispatch(
        updateActiveBuilderSignatureAssignment({
          users: [...affectedUsers, item.id],
        }),
      )

      const itemTag = { id: item.id, label: item.label, type: "user" } as Tag
      setTags([...tags, itemTag])
    }
  }

  const handleToggleGroups = (item) => {
    if (affectedGroups.find((g) => g === item.id)) {
      dispatch(
        updateActiveBuilderSignatureAssignment({
          groups: affectedGroups.filter((g) => g !== item.id),
        }),
      )

      setTags(tags.filter((t) => t.id !== item.id))
    } else {
      const groupMembersIds =
        activeGroups.find((group) => group.Id === item.id)?.MembersId || []
      const remainingUsersIds = affectedUsers.filter(
        (affectedUser) => !groupMembersIds.includes(affectedUser),
      )

      dispatch(
        updateActiveBuilderSignatureAssignment({
          groups: [...affectedGroups, item.id],
          users: remainingUsersIds,
        }),
      )

      const itemTag = { id: item.id, label: item.label, type: "group" } as Tag
      setTags((prevTags) => {
        const remainingTags = ArrayHelper.reject(
          prevTags,
          (tag) =>
            tag.type === "user" && groupMembersIds.includes(tag.id as number),
        )
        return [...remainingTags, itemTag]
      })
    }
  }

  const selectAllGroups = () => {
    const userTags = tags.filter((t) => t.type === "user")
    dispatch(
      updateActiveBuilderSignatureAssignment({
        groups: activeGroups.map((g) => g.Id),
      }),
    )
    setTags([
      ...userTags,
      ...activeGroups.map(
        (group) => ({ id: group.Id, label: group.Name, type: "group" } as Tag),
      ),
    ])
  }

  const selectAllUsers = () => {
    const groupTags = tags.filter((t) => t.type === "group")
    dispatch(
      updateActiveBuilderSignatureAssignment({
        users: activeUsers.map((u) => u.Id),
      }),
    )
    setTags([
      ...groupTags,
      ...activeUsers.map(
        (user) =>
          ({ id: user.Id, label: User.getFullName(user), type: "user" } as Tag),
      ),
    ])
  }

  const removeAllGroups = () => {
    const userTags = tags.filter((t) => t.type === "user")
    setTags(userTags)
    dispatch(updateActiveBuilderSignatureAssignment({ groups: [] }))
  }

  const removeAllUsers = () => {
    const groupTags = tags.filter((t) => t.type === "group")
    setTags(groupTags)
    dispatch(updateActiveBuilderSignatureAssignment({ users: [] }))
  }

  return (
    <div className="builder-assignment-panel">
      <p className="builder-assignment-panel__title">
        <FormattedMessage
          id="BuildetAssignmentPanel.Title"
          defaultMessage="Sélectionner la liste des utilisateurs et/ou groupes à qui associer cette nouvelle signature de mail."
        />
      </p>
      <div className="builder-assignment-panel__search-field">
        <input
          placeholder={formatMessage(messages.searchPlaceholder)}
          className="builder-assignment-panel__search-field--input"
          onChange={(event) => setSearch(event.target.value)}
          value={search}
          autoFocus
        />
        {search ? (
          <i
            onClick={() => setSearch("")}
            className="builder-assignment-panel__search-field--icon-close material-icons"
          >
            close
          </i>
        ) : (
          <i className="builder-assignment-panel__search-field--icon-search material-icons">
            search
          </i>
        )}
      </div>
      <BuilderPeoplePicker
        tags={tags}
        onSetTags={setTags}
        assignedUsersIds={affectedUsers}
        assignedGroupsIds={affectedGroups}
      />
      <PeoplePickerCollapseLists
        groupCollapseList={{
          title: (
            <FormattedMessage
              id="BuildetAssignmentPanel.Groups"
              defaultMessage="Groupes"
            />
          ),
          selectedData: tagToItems(tags, "group"),
          handleClick: handleToggleGroups,
          selectAll: selectAllGroups,
          removeAll: removeAllGroups,
        }}
        userCollapseList={{
          title: (
            <FormattedMessage
              id="BuildetAssignmentPanel.Users"
              defaultMessage="Utilisateurs"
            />
          ),
          selectedData: tagToItems(tags, "user"),
          handleClick: handleToggleUsers,
          selectAll: selectAllUsers,
          removeAll: removeAllUsers,
        }}
        search={search}
        activeMembers={activeMembers}
      />
    </div>
  )
}

export default BuilderAssignmentPanel
