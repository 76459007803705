import { FunctionComponent } from "react"

import moment from "moment"
import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as UserIcon } from "assets/icon-user.svg"
import { ReactComponent as CupIcon } from "assets/icon-cup.svg"
import { ReactComponent as ClickPointerIcon } from "assets/icon-click-pointer.svg"

import KeyPerformanceIndicatorBlock from "./components/KeyPerformanceIndicatorBlock"

interface TrackingKPIProps {
  maxAffectedUsers: number
  maxAffectedUsersDate: string
  maxAffectedUsersTooltip: string
  maxClicks: number
  maxClicksDate: string
  maxClicksTooltip: string
  totalClicks: number
  sufficientGraphDatas?: boolean
  affectedUsers?: number
  affectedUsersIsLoading?: boolean
}

const messages = defineMessages({
  users: {
    id: "Tracking.TrackingKPIUsers",
    defaultMessage: "Utilisateurs",
  },
  max: {
    id: "Tracking.TrackingKPIMax",
    defaultMessage: "Maximum",
  },
  today: {
    id: "Tracking.TrackingKPIToday",
    defaultMessage: "Aujourd'hui",
  },
  clicks: {
    id: "Tracking.TrackingKPIClicks",
    defaultMessage: "Clics",
  },
  maxPerDay: {
    id: "Tracking.TrackingKPIMaxOverADay",
    defaultMessage: "Maximum sur un jour",
  },
  totalsClicks: {
    id: "Tracking.TrackingKPITotalsClicks",
    defaultMessage: "clics totaux",
  },
  total: {
    id: "Tracking.TrackingKPITotals",
    defaultMessage: "Total",
  },
  onPeriod: {
    id: "Tracking.TrackingKPIOnSelectedPeriod",
    defaultMessage: "sur la période sélectionnée",
  },
})

const TrackingKPI: FunctionComponent<TrackingKPIProps> = ({
  affectedUsers,
  maxAffectedUsers,
  maxAffectedUsersDate,
  maxAffectedUsersTooltip,
  maxClicks,
  maxClicksDate,
  maxClicksTooltip,
  totalClicks,
  sufficientGraphDatas = true,
  affectedUsersIsLoading = false,
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className="tracking-kpi">
      <KeyPerformanceIndicatorBlock
        icon={<UserIcon />}
        title={formatMessage(messages.users)}
        tooltip={maxAffectedUsersTooltip}
        mainValue={maxAffectedUsers}
        secondaryValue={affectedUsers}
        mainLabel={formatMessage(messages.max)}
        secondaryLabel={formatMessage(messages.today)}
        mainTimeTrack={moment(maxAffectedUsersDate).format("DD-MM-YYYY")}
        secondaryValueIsLoading={affectedUsersIsLoading}
      />
      <KeyPerformanceIndicatorBlock
        icon={<CupIcon />}
        title={formatMessage(messages.clicks)}
        tooltip={maxClicksTooltip}
        mainValue={sufficientGraphDatas ? maxClicks : "-"}
        mainLabel={formatMessage(messages.maxPerDay)}
        mainTimeTrack={moment(maxClicksDate).format("DD-MM-YYYY")}
      />
      <KeyPerformanceIndicatorBlock
        icon={<ClickPointerIcon />}
        title={formatMessage(messages.totalsClicks)}
        mainValue={sufficientGraphDatas ? totalClicks : "-"}
        mainLabel={formatMessage(messages.total)}
        mainTimeTrack={formatMessage(messages.onPeriod)}
      />
    </div>
  )
}

export default TrackingKPI
