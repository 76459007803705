import { FunctionComponent, useEffect, useMemo, useState } from "react"

import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as PreviewIcon } from "assets/icon-preview.svg"

import TrackingDataBlock from "features/Tracking/components/TrackingDataBlock"
import Podium from "features/Tracking/components/Podium"

import ScenariosMostClickedLoading from "./components/ScenariosMostClickedLoading"
import ScenariosMostClickedOverview from "./components/ScenariosMostClickedOverview"

import { trackingSelectors } from "features/Tracking"

import { GlobalStates } from "store"
import { LoadingStatus } from "core/CoreModels"
import { ScenarioTracking } from "features/Tracking/TrackingModels"

const messages = defineMessages({
  title: {
    id: "Tracking.ScenariosMostClicked",
    defaultMessage: "Top Campagne",
  },
})

function buildPodiumItemFromMostClicked(mostClicked: ScenarioTracking) {
  const firstBanner = mostClicked.banners.find((banner) => banner.order === 0)
  return {
    id: mostClicked.id,
    visual: firstBanner?.imageUrl,
    name: mostClicked.name,
  }
}

const ScenariosMostClicked: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [selectedMostClicked, setSelectedMostClicked] = useState(null)

  const loadingStatus = useSelector<GlobalStates, LoadingStatus>(
    trackingSelectors.getScenariosTrackingLoadingStatus,
  )
  const allScenariosMostClicked = useSelector(
    trackingSelectors.getScenariosTrackingDataSortByTotalClicks,
  )

  const handleSelectOnPodium = (podiumMostClicked) => {
    setSelectedMostClicked(podiumMostClicked)
  }

  const mostClicked = useMemo(
    () =>
      allScenariosMostClicked.map((s) => ({
        ...buildPodiumItemFromMostClicked(s),
        handleSelect: () => handleSelectOnPodium(s),
        actions: [{ id: "0", icon: <PreviewIcon /> }],
      })),
    [allScenariosMostClicked],
  )

  useEffect(() => {
    if (!selectedMostClicked && allScenariosMostClicked.length > 0)
      setSelectedMostClicked(allScenariosMostClicked[0])
  }, [selectedMostClicked, allScenariosMostClicked])

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <TrackingDataBlock title={formatMessage(messages.title)}>
        <ScenariosMostClickedLoading />
      </TrackingDataBlock>
    )

  return (
    <TrackingDataBlock title={formatMessage(messages.title)}>
      <div className="scen-most-clicked">
        <div className="scen-most-clicked__left-side">
          {loadingStatus === LoadingStatus.LOADED && (
            <Podium
              gold={mostClicked[0]}
              silver={mostClicked[1]}
              bronze={mostClicked[2]}
              selected={selectedMostClicked?.id}
              roundedAvatar
              largeAvatar
            />
          )}
        </div>
        <div className="scen-most-clicked__right-side">
          {selectedMostClicked && (
            <ScenariosMostClickedOverview
              nbClicks={selectedMostClicked.totalClicks}
            />
          )}
        </div>
      </div>
    </TrackingDataBlock>
  )
}

export default ScenariosMostClicked
