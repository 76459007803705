import { FunctionComponent } from "react"

import { OfferOption } from "features/Offers/OffersModels"
import EnrollmentOption from "./components/EnrollmentOptionItem"

interface EnrollmentOptionsProps {
  options: OfferOption[]
  nbLicences: number
  selectEnrollmentOption(option: OfferOption): void
}

const EnrollmentOptions: FunctionComponent<EnrollmentOptionsProps> = ({
  options,
  nbLicences,
  selectEnrollmentOption,
}) => {
  const handleOptions = (option: OfferOption) => {
    option.Selected = !option.Selected
    selectEnrollmentOption(option)
  }

  return (
    <div className="enrollment__options">
      {options.map((option) => (
        <EnrollmentOption
          key={option.Id}
          nbLicences={nbLicences}
          option={option}
          onSelect={handleOptions}
        />
      ))}
    </div>
  )
}

export default EnrollmentOptions
