import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"

import { ReactComponent as ErrorIcon } from "assets/icon-error.svg"

const MAIL_IS_NOT_PROFESSIONAL = "mail-is-not-professional"

const DOMAIN_ALREADY_REGISTERED = "domain-already-registered"

const messages = defineMessages({
  mailIsNotProfessional: {
    id: "GoogleOAuthErrorNotification.MailIsNotProfessional",
    defaultMessage:
      "Boost My Mail n'accepte que les comptes avec des noms de domaine professionnels",
  },
  domainAlreadyRegistered: {
    id: "GoogleOAuthErrorNotification.DomainAlreadyRegistered",
    defaultMessage:
      "Le nom de domaine est déjà utilisé, renseignez-vous auprès de votre service marketing",
  },
})

const GoogleOAuthErrorNotification: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const [searchParams] = useSearchParams()

  const displayErrorMessage = useMemo(() => {
    const error = searchParams.get("error")

    if (error === MAIL_IS_NOT_PROFESSIONAL)
      return messages.mailIsNotProfessional

    if (error === DOMAIN_ALREADY_REGISTERED)
      return messages.domainAlreadyRegistered

    return null
  }, [])

  if (displayErrorMessage === null) return null

  return (
    <div className="oauth-error-notif">
      <div className="oauth-error-notif__icon">
        <ErrorIcon />
      </div>
      <p className="oauth-error-notif__message">
        {formatMessage(displayErrorMessage)}
      </p>
    </div>
  )
}

export default GoogleOAuthErrorNotification
