import { FunctionComponent, useState } from "react"

import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"
import { Link } from "react-router-dom"

import { ReactComponent as IconEdit } from "assets/icon-edit.svg"

import MouseTooltip from "components/MouseTooltip"

import { CAMPAIGNS_URL } from "router/RouterConstants"

interface ScenarioCardEditActionProps {
  scenarioId: number
  isMultiple: boolean
  roundedCorner?: "top" | "bottom"
  disabled?: boolean
}

export type ScenarioCardEditActionType =
  FunctionComponent<ScenarioCardEditActionProps>

const messages = defineMessages({
  editCampaign: {
    id: "ScenarioCard.EditCampaignTooltip",
    defaultMessage: "Modifier la campagne",
  },
  editScenario: {
    id: "ScenarioCard.EditScenarioTooltip",
    defaultMessage: "Modifier le scénario",
  },
})

const ScenarioCardEditAction: ScenarioCardEditActionType = ({
  scenarioId,
  isMultiple,
  roundedCorner,
  disabled = false,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  const bannerEditLink = `${CAMPAIGNS_URL}/edit/${scenarioId}/step/1${
    isMultiple ? "/?mode=multiple" : ""
  }`

  return (
    <>
      <Link
        className={classNames(
          "scenario-card__btn",
          roundedCorner && `scenario-card__rounder-corner--${roundedCorner}`,
          disabled && "is-disabled",
        )}
        to={disabled ? "#" : bannerEditLink}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <IconEdit className="scenario-card__btn--icon" />
      </Link>
      <MouseTooltip visible={visible}>
        {isMultiple
          ? formatMessage(messages.editScenario)
          : formatMessage(messages.editCampaign)}
      </MouseTooltip>
    </>
  )
}

export default ScenarioCardEditAction
