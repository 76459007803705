import { useSelector } from "react-redux"
import { offersSelectors } from "../../Offers"
import { GlobalStates } from "../../../store"

export const useTrialFinished = () => {
  const isTrialFinished = useSelector(
    (state: GlobalStates) =>
      !offersSelectors.isTrial(state) || state.trial.trialStep === 7,
  )
  return isTrialFinished
}
