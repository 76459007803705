import { FunctionComponent } from "react"

import { useSearchParams } from "react-router-dom"

import TrackingSignatures from "../TrackingSignatures"
import TrackingScenarios from "../TrackingScenarios"

const TrackingPageContent: FunctionComponent = () => {
  const [searchParams] = useSearchParams()

  const renderedComponent = {
    signatures: <TrackingSignatures />,
    campaigns: <TrackingScenarios />,
  }

  const actualTab = searchParams.get("tab")
  return (
    <div className="tracking-page-content">
      {actualTab && renderedComponent[actualTab]}
    </div>
  )
}

export default TrackingPageContent
