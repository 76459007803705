import { FunctionComponent, UIEventHandler, useCallback, useState } from "react"

import useScenariosTrackingSliderFilters from "features/Tracking/hooks/useScenariosTrackingSliderFilters"
import useScenariosQueriesDateRange from "features/Tracking/hooks/useScenariosQueriesDateRange"

import TrackingDatePicker from "features/Tracking/components/TrackingDatePicker"
import HorizontalScrollingButtons from "features/Tracking/components/HorizontalScrollingButtons"

import ScenariosTrackingSliderItem from "./components/ScenariosTrackingSliderItem"
import ScenariosTrackingFilters from "./components/ScenariosTrackingFilters"

import { ScenarioTracking } from "features/Tracking/TrackingModels"

interface ScenariosTrackingSliderProps {
  scenarios: ScenarioTracking[]
  title: string
  handleSelect: (id: number) => void
  initialSliderScrollPosition: number
  handleInitialSliderScrollPosition: (scrollLeft: number) => void
}

const ScenariosTrackingSlider: FunctionComponent<
  ScenariosTrackingSliderProps
> = ({
  scenarios,
  title,
  handleSelect,
  initialSliderScrollPosition,
  handleInitialSliderScrollPosition,
}) => {
  const [callBackNode, setCallbackNode] = useState(null)

  const returnedRef = useCallback((node) => {
    if (node !== null) setCallbackNode(node)
  }, [])

  const { startDate, endDate, handleDateChange } =
    useScenariosQueriesDateRange()

  const { filteredScenarios, diffusionMode, scenarioMode, dispatch } =
    useScenariosTrackingSliderFilters(scenarios)

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { scrollLeft } = e.target as HTMLDivElement
    handleInitialSliderScrollPosition(scrollLeft)
  }

  return (
    <div className="scen-track-slider">
      <div className="scen-track-slider__header">
        <p className="scen-track-slider__title">
          {`${title} (`}
          <span className="scen-track-slider__title--scenarios">
            {filteredScenarios.length}
          </span>
          {`)`}
        </p>
        <TrackingDatePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDateChange}
        />
        <ScenariosTrackingFilters
          diffusionMode={diffusionMode}
          scenarioMode={scenarioMode}
          dispatch={dispatch}
        />
      </div>
      <HorizontalScrollingButtons
        node={callBackNode}
        scrollLeft={initialSliderScrollPosition}
      >
        <div
          className="scen-track-slider__scenarios"
          onScroll={handleScroll}
          ref={returnedRef}
        >
          {filteredScenarios.map((scenario) => (
            <ScenariosTrackingSliderItem
              key={scenario.id}
              scenario={scenario}
              handleSelect={handleSelect}
            />
          ))}
        </div>
      </HorizontalScrollingButtons>
    </div>
  )
}

export default ScenariosTrackingSlider
