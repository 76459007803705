import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityId,
} from "@reduxjs/toolkit"
import { Feature } from "./FeatureTogglrModels"
import { LoadingStatus } from "core/CoreModels"

interface FeatureTogglrEntities {
  [id: string]: { name: string; active: boolean }
}

export interface FeatureTogglrState {
  entities: FeatureTogglrEntities
  ids: EntityId[]
  loadingStatus: LoadingStatus
}

const featuresAdapter = createEntityAdapter<Feature>({
  selectId: (feature) => feature.name,
})

const featuresReducer = createSlice({
  name: "featureTogglr",
  initialState: featuresAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  reducers: {
    fetchFeatures(state) {
      state.loadingStatus = LoadingStatus.PENDING
    },
    setFeatures(state, action: PayloadAction<Feature[]>) {
      featuresAdapter.addMany(state, action.payload)
      state.loadingStatus = LoadingStatus.LOADED
    },
  },
})

export const { fetchFeatures, setFeatures } = featuresReducer.actions
export default featuresReducer.reducer
