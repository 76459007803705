export enum JobsActionsTypes {
  CONNECT_TO_HUB = "JOBS/CONNECT_TO_HUB",
}

export enum AvailableJobs {
  SYNC_O365 = "ISyncO365",
  SYNC_GSUITE = "ISyncGSuite",
  SYNC_API = "ISyncApi",
  SYNC_LUCCA = "ISyncLucca",
  DEPLOY_SALESFORCE = "IDeploySalesforce",
  DEPLOY_GMAIL = "IDeployGmail",
  DEPLOY_OWA = "IDeployOwa",
  DEPLOY_SELLSY = "IDeploySellsy",
  CONFIGURE_OFFER = "MANAGE_OFFER",
  SYNC_USERS = "SyncUsersOrchestratorJob",
}
