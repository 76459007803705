import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import { Link } from "react-router-dom"

import { ReactComponent as AddIcon } from "assets/icon-add.svg"

interface HomePageTileAddButtonProps {
  to: string
  children?: ReactNode
  isColumn?: boolean
  isFullsize?: boolean
  onClick?: () => void
}

export type HomePageTileAddButtonType =
  FunctionComponent<HomePageTileAddButtonProps>

const HomePageTileAddButton: HomePageTileAddButtonType = ({
  children,
  to,
  isColumn,
  isFullsize,
  onClick,
}) => {
  return (
    <Link
      to={to}
      className={classNames(
        "home-tile__add-btn--container",
        isColumn && "is-column",
        isFullsize && "is-fullsize",
      )}
      onClick={() => onClick && onClick()}
    >
      <div className="home-tile__add-btn--icon">
        <AddIcon />
      </div>
      {children && <p className="home-tile__add-btn--label">{children}</p>}
    </Link>
  )
}

export default HomePageTileAddButton
