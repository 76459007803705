import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from "redux-saga/effects"

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"

import {
  fetchBillingAddress,
  fetchBillingAddressSuccess,
  fetchBillingAddressFailure,
  fetchBillings,
  fetchBillingsSuccess,
  fetchBillingsFailure,
  updateBillingAddress,
  updateBillingAddressSuccess,
  updateBillingAddressFailure,
} from "./BillingsReducer"

import {
  fetchBillings as fetchBillingsApi,
  fetchBillingAddress as fetchBillingAddressApi,
  setBillingAddress as updateBillingAddressApi,
} from "./BillingsApi"

import { withSagaErrorHandler } from "features/Errors"
import { Billing, BillingAddress } from "./BillingsModels"
import { PayloadAction } from "@reduxjs/toolkit"
import { TypedResult } from "core/CoreModels"
import { hideGlobalLoader, showGlobalLoader } from "features/App/AppReducers"

function* fetchBillingsAsync(): Generator<
  | CallEffect<TypedResult<Billing[]>>
  | PutEffect<{
      payload: Billing[]
      type: string
    }>,
  void,
  TypedResult<Billing[]>
> {
  const result: TypedResult<Billing[]> = yield call(fetchBillingsApi)

  const billings = result.result
  yield put(fetchBillingsSuccess(billings))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* fetchBillingAddressAsync() {
  const result = yield call(fetchBillingAddressApi)

  if (result.result) {
    const billingAddress = result.result
    yield put(fetchBillingAddressSuccess(billingAddress))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* updateBillingAddressAsync(action: PayloadAction<BillingAddress>) {
  yield put(showGlobalLoader())

  const result = yield call(updateBillingAddressApi, action.payload)

  if (result != null && result.result) {
    const billingAddress: BillingAddress = result.result
    yield put(updateBillingAddressSuccess(billingAddress))
    yield put(
      addNotification(
        new Notification("Modifications enregistrées", "SUCCESS"),
      ),
    )
  }

  yield put(hideGlobalLoader())
}

export const billingsWatchers = [
  takeLatest(
    fetchBillings.type,
    withSagaErrorHandler(fetchBillingsAsync, fetchBillingsFailure()),
  ),
  takeLatest(
    fetchBillingAddress.type,
    withSagaErrorHandler(
      fetchBillingAddressAsync,
      fetchBillingAddressFailure(),
    ),
  ),
  takeLatest(
    updateBillingAddress.type,
    withSagaErrorHandler(
      updateBillingAddressAsync,
      updateBillingAddressFailure(),
    ),
  ),
]
