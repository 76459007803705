import { FunctionComponent } from "react"

import OWAToggle from "./components/OWAToggle"
import OWAParamsForm from "./components/OWAParamsForm"

import { TENANT_PROPERTY_NAMES } from "entities/TenantProperties"

import Feature from "features/Features/FeaturesModels"

interface OWAFormProps {
  featureOWA: Feature
  updateFeature: (feature: Feature) => void
  launchJob: (feature: Feature) => void
}

const OWAForm: FunctionComponent<OWAFormProps> = ({
  featureOWA,
  updateFeature,
  launchJob,
}) => {
  const handleActivateOWA = (
    login: string,
    password: string,
    domain: string,
    serviceUrl: string,
  ) => {
    featureOWA.activated = true

    featureOWA.properties = [
      {
        property: TENANT_PROPERTY_NAMES.OWA_ACCOUNTS,
        value: JSON.stringify([{ login, password, domain }]),
      },
      {
        property: TENANT_PROPERTY_NAMES.OWA_ENDPOINT_URL,
        value: serviceUrl,
      },
    ]

    updateFeature(featureOWA)
  }

  return (
    <div className="owa-form">
      <OWAToggle
        featureOWA={featureOWA}
        updateFeature={updateFeature}
        launchJob={launchJob}
      />
      <OWAParamsForm featureOWA={featureOWA} onActivate={handleActivateOWA} />
    </div>
  )
}

export default OWAForm
