/**
 * Represente une instance de l'offre
 *
 * @class Tenant
 */

import StringHelper from "utils/StringHelper"

import { Origin } from "features/Users/UserModels"
export class Tenant {
  Id: number
  TenantId: number
  Name: string
  Processed: boolean
  ApiKey: string
  Created: Date
  GSuite: boolean
  Api: boolean
  O365: boolean
  Lucca: boolean
  Salesforce: boolean

  constructor(jsonObject) {
    for (const prop in jsonObject) {
      if (jsonObject.hasOwnProperty(prop)) {
        const propName = StringHelper.Capitalize(prop)
        this[propName] = jsonObject[prop]
      }
    }
  }

  isReady = () => this.Processed

  getActiveSources = (): Array<Origin> => {
    const sources = []
    if (this.O365) {
      sources.push(Origin.ORIGIN_O365)
    }
    if (this.GSuite) {
      sources.push(Origin.ORIGIN_GSUITE)
    }
    if (this.Api) {
      sources.push(Origin.ORIGIN_API)
    }
    if (this.Lucca) {
      sources.push(Origin.ORIGIN_LUCCA)
    }
    return sources
  }
}

export default Tenant
