import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios"

import {
  PaginatedRequestConfig,
  PaginatedResult,
  TypedResult,
} from "core/CoreModels"

import store from "store/configureStore"

import { ErrorService } from "features/Errors/ErrorsService"
import { apiError } from "features/Errors"

import { createBaseAxiosInstance } from "./api"

let serviceApi: AxiosInstance = null

const mandatoryHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
  Expires: "-1",
}

function initializeRequestConfig(method, config) {
  const optionalHeaders = config?.headers || {}

  return {
    ...config,
    method,
    withCredentials: true,
    headers: {
      ...mandatoryHeaders,
      ...optionalHeaders,
    },
    responseType: "json",
    data: config?.data && JSON.stringify(config.data),
  }
}

function initializeMultiFormDataRequest(method, config) {
  const optionalHeaders = config?.headers || {}

  return {
    ...config,
    method,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      ...optionalHeaders,
    },
    data: config?.data,
  }
}

export default {
  initialize: (baseUrl: string): void => {
    serviceApi = createBaseAxiosInstance(baseUrl)
  },
  request<T>(
    method: Method,
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<TypedResult<T>> {
    const requestConfig = initializeRequestConfig(
      method,
      config,
    ) as AxiosRequestConfig

    if (serviceApi === null) throw Error("ServiceApi not initialized yet.")

    return serviceApi({
      url,
      ...requestConfig,
    })
      .then((response: AxiosResponse<T>) => {
        return new TypedResult(response) as TypedResult<T>
      })
      .catch((error: AxiosError) => {
        store.dispatch(
          apiError(error.message, ErrorService.apiErrorStack(error)),
        )
        return new TypedResult(error.response) as TypedResult<T>
      })
  },
  paginatedRequest<T>(
    method: Method,
    url: string,
    config: PaginatedRequestConfig,
  ): Promise<PaginatedResult<T>> {
    const requestConfig = initializeRequestConfig(
      method,
      config,
    ) as PaginatedRequestConfig

    if (serviceApi === null) throw Error("ServiceApi not initialized yet.")

    return serviceApi({
      url,
      ...requestConfig,
    })
      .then((response: AxiosResponse<T>) => {
        return new PaginatedResult(response) as PaginatedResult<T>
      })
      .catch((error: AxiosError) => {
        store.dispatch(
          apiError(error.message, ErrorService.apiErrorStack(error)),
        )
        return new PaginatedResult(error.response) as PaginatedResult<T>
      })
  },
  uploadFileRequest<T>(
    method: Method,
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<TypedResult<T>> {
    const requestConfig = initializeMultiFormDataRequest(
      method,
      config,
    ) as AxiosRequestConfig

    if (serviceApi === null) throw Error("ServiceApi not initialized yet.")

    return serviceApi({
      url,
      ...requestConfig,
    })
      .then((response: AxiosResponse<T>) => {
        return new TypedResult(response) as TypedResult<T>
      })
      .catch((error: AxiosError) => {
        store.dispatch(
          apiError(error.message, ErrorService.apiErrorStack(error)),
        )
        return new TypedResult(error.response) as TypedResult<T>
      })
  },
}
