import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import Group, { PartialGroup } from "features/Groups/GroupsModels"
import {
  SortOrder,
  SortOrders,
} from "components/PaginatedDataGrid/PaginatedDataGrid"

import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { GlobalStates } from "store"

export interface FetchPartialGroupsActionPayload {
  pageSize: number
  pageNumber: number
  query: string
  orderBy: SortOrders
}

interface FetchPartialGroupsSuccessActionPayload {
  partialGroups: Array<PartialGroup>
  currentPage: number
  lastPage: number
  totalCount: number
  pageSize: number
}

interface GroupsEntities {
  [id: string]: Group
}

interface PartialGroupsEntities {
  [id: string]: PartialGroup
}

export interface GroupsState {
  allGroups: {
    entities: GroupsEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
  partialGroups: {
    entities: PartialGroupsEntities
    ids: Array<EntityId>
    pageSize: number
    currentPage: number
    lastPage: number
    totalCount: number
    loadingStatus: LoadingStatus
    search: string
    orderBy: SortOrders
  }
  editingGroup: {
    loadingStatus: LoadingStatus
    assignedSignaturesLoadingStatus: LoadingStatus
    savingStatus: SavingStatus
    entity: Group | null
  }
}

const groupsAdapter = createEntityAdapter<Group>({
  selectId: (group: Group) => group.Id,
  sortComparer: (a: Group, b: Group) => (a.Name > b.Name ? 1 : -1),
})

export const { selectById: selectGroupById, selectAll: selectAllGroups } =
  groupsAdapter.getSelectors<GlobalStates>((state) => state.groups.allGroups)

const partialGroupsAdapter = createEntityAdapter<PartialGroup>({
  selectId: (group: PartialGroup) => group.id,
  sortComparer: (a: PartialGroup, b: PartialGroup) =>
    a.name < b.name ? -1 : 1,
})

export const {
  selectById: selectPartialGroupById,
  selectAll: selectAllPartialGroups,
} = partialGroupsAdapter.getSelectors<GlobalStates>(
  (state) => state.groups.partialGroups,
)

const initialState: GroupsState = {
  allGroups: groupsAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
  partialGroups: partialGroupsAdapter.getInitialState({
    pageSize: 50,
    currentPage: 1,
    lastPage: 1,
    totalCount: 0,
    loadingStatus: LoadingStatus.NOT_STARTED,
    search: "",
    orderBy: [],
  }),
  editingGroup: {
    loadingStatus: LoadingStatus.NOT_STARTED,
    assignedSignaturesLoadingStatus: LoadingStatus.NOT_STARTED,
    savingStatus: SavingStatus.IDLE,
    entity: null,
  },
}

const groupsReducer = createSlice({
  name: "groups",
  initialState,
  reducers: {
    searchGroups(state, action: PayloadAction<string>) {
      state.partialGroups.loadingStatus = LoadingStatus.PENDING
      state.partialGroups.search = action.payload
    },
    fetchAllGroups(state) {
      state.allGroups.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllGroupsSuccess(state, action: PayloadAction<Group[]>) {
      groupsAdapter.setAll(state.allGroups, action.payload)
      state.allGroups.loadingStatus = LoadingStatus.LOADED
    },
    createGroupSuccess(state, action: PayloadAction<Group>) {
      groupsAdapter.addOne(state.allGroups, action.payload)
    },
    editGroupSuccess(state, action: PayloadAction<Group>) {
      groupsAdapter.upsertOne(state.allGroups, action.payload)
    },
    deleteGroupSuccess(state, action: PayloadAction<number>) {
      partialGroupsAdapter.removeOne(state.partialGroups, action.payload)
      groupsAdapter.removeOne(state.allGroups, action.payload)
    },
    upsertGroupLicenceSuccess(
      state,
      action: PayloadAction<{ partialGroup: PartialGroup; group: Group }>,
    ) {
      partialGroupsAdapter.updateOne(state.partialGroups, {
        id: action.payload.partialGroup.id,
        changes: { activated: action.payload.partialGroup.activated },
      })
      groupsAdapter.upsertOne(state.allGroups, action.payload.group)
    },
    fetchPartialGroups(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<FetchPartialGroupsActionPayload>,
    ) {
      state.partialGroups.loadingStatus = LoadingStatus.PENDING
    },
    reloadPartialGroups(state) {
      state.partialGroups.loadingStatus = LoadingStatus.PENDING
    },
    fetchPartialGroupsSuccess(
      state,
      action: PayloadAction<FetchPartialGroupsSuccessActionPayload>,
    ) {
      partialGroupsAdapter.setAll(
        state.partialGroups,
        action.payload.partialGroups,
      )
      state.partialGroups.lastPage = action.payload.lastPage
      state.partialGroups.totalCount = action.payload.totalCount
      state.partialGroups.loadingStatus = LoadingStatus.LOADED
    },
    setPartialGroupsPageNumber(state, action: PayloadAction<number>) {
      state.partialGroups.currentPage = action.payload
    },
    setPartialGroupsPageSize(state, action: PayloadAction<number>) {
      state.partialGroups.pageSize = action.payload
      state.partialGroups.currentPage = 1
    },
    setPartialGroupsOrderBy(
      state,
      action: PayloadAction<{ columnKey: string; sortOrder: SortOrder }>,
    ) {
      state.partialGroups.orderBy = [action.payload]
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchGroup(state, _action: PayloadAction<number>) {
      state.editingGroup.loadingStatus = LoadingStatus.PENDING
      state.editingGroup.entity = null
    },
    fetchGroupSuccess(state, action: PayloadAction<Group>) {
      state.editingGroup.loadingStatus = LoadingStatus.LOADED
      state.editingGroup.entity = action.payload
    },
    editPartialGroupSuccess(state, action: PayloadAction<PartialGroup>) {
      partialGroupsAdapter.upsertOne(state.partialGroups, action.payload)
    },
    savingEditingGroup(state) {
      state.editingGroup.savingStatus = SavingStatus.SAVING
    },
    savingEditingGroupSuccess(state) {
      state.editingGroup.savingStatus = SavingStatus.SUCCESS
    },
    resetEditingGroup(state) {
      state.editingGroup.loadingStatus = LoadingStatus.NOT_STARTED
      state.editingGroup.assignedSignaturesLoadingStatus =
        LoadingStatus.NOT_STARTED
      state.editingGroup.savingStatus = SavingStatus.IDLE
      state.editingGroup.entity = null
    },
    fetchAssignedSignaturesToEditingGroup(state) {
      state.editingGroup.assignedSignaturesLoadingStatus = LoadingStatus.PENDING
    },
    fetchAssignedSignaturesToEditingGroupSuccess(state) {
      state.editingGroup.assignedSignaturesLoadingStatus = LoadingStatus.LOADED
    },
  },
})

export const {
  searchGroups,
  fetchAllGroups,
  fetchAllGroupsSuccess,
  createGroupSuccess,
  editGroupSuccess,
  deleteGroupSuccess,
  upsertGroupLicenceSuccess,
  fetchPartialGroups,
  reloadPartialGroups,
  fetchPartialGroupsSuccess,
  setPartialGroupsPageNumber,
  setPartialGroupsPageSize,
  setPartialGroupsOrderBy,
  fetchGroup,
  fetchGroupSuccess,
  editPartialGroupSuccess,
  savingEditingGroup,
  savingEditingGroupSuccess,
  resetEditingGroup,
  fetchAssignedSignaturesToEditingGroup,
  fetchAssignedSignaturesToEditingGroupSuccess,
} = groupsReducer.actions

export default groupsReducer.reducer
