import { SendingModes } from "features/Signatures/SignaturesModels"

export type FilterValuesType = typeof filterValues[number]

export type SortValuesType = typeof sortValues[number]

export type SearchMethod<T> = (e: T) => boolean

export type FilterMethods<T> = {
  [key in Exclude<FilterValuesType, "all">]: (e: T) => boolean
}

export type SortMethods<T> = {
  [key in SortValuesType]: (a: T, b: T) => number
}

export type FilterBySendingModeMethods<T> = {
  [key in SendingModes]: (e: T) => boolean
}

type Filters<T> = {
  search: string
  filter: FilterValuesType
  sort: SortValuesType
  searchMethod: SearchMethod<T>
  filterMethods: FilterMethods<T>
  sortMethods: SortMethods<T>
  sendingMode?: SendingModes
  filterBySendingModeMethods?: FilterBySendingModeMethods<T>
}

type Processor<T> = {
  value: string | FilterValuesType | SortValuesType | SendingModes
  fct: (
    signatures: T[],
    value: string | FilterValuesType | SortValuesType | SendingModes,
    methods:
      | SearchMethod<T>
      | FilterMethods<T>
      | SortMethods<T>
      | FilterBySendingModeMethods<T>,
  ) => T[]

  methods:
    | SearchMethod<T>
    | FilterMethods<T>
    | SortMethods<T>
    | FilterBySendingModeMethods<T>
}

export const defaultFilterValue = "all"

export const defaultSortValue = "alphabeticalOrder"

const filterValues = [defaultFilterValue, "activated", "deactivated"] as const

const sortValues = [
  defaultSortValue,
  "creationDate",
  "lastUpdate",
  "weighting",
] as const

function searchProcessor<T>(
  searchedList: T[],
  searchValue: string,
  searchMethod: SearchMethod<T>,
): T[] {
  if (searchValue.length < 3) return searchedList

  return searchedList.filter(searchMethod, { searchValue })
}

function filterByActivatedState<T>(
  listTofilter: T[],
  filterOption: FilterValuesType,
  filterMethods: FilterMethods<T>,
): T[] {
  switch (filterOption) {
    case "activated":
      return listTofilter.filter(filterMethods.activated)
    case "deactivated":
      return listTofilter.filter(filterMethods.deactivated)
    case "all":
    default:
      return listTofilter
  }
}

function sortByProperty<T>(
  listToSort: T[],
  sortOption: SortValuesType,
  sortMethods: SortMethods<T>,
): T[] {
  switch (sortOption) {
    case "alphabeticalOrder":
      return listToSort.sort(sortMethods.alphabeticalOrder)
    case "creationDate":
      return listToSort.sort(sortMethods.creationDate)
    case "lastUpdate":
      return listToSort.sort(sortMethods.lastUpdate)
    case "weighting":
      return listToSort.sort(sortMethods.weighting)
    default:
      return listToSort
  }
}

function filterBySendingMode<T>(
  listTofilter: T[],
  filterOption: SendingModes,
  filterMethods: FilterBySendingModeMethods<T>,
): T[] {
  switch (filterOption) {
    case "newMail":
      return listTofilter.filter(filterMethods.newMail)
    case "inResponse":
      return listTofilter.filter(filterMethods.inResponse)

    default:
      return listTofilter
  }
}

export function processList<T>(listToProcess: T[], filters: Filters<T>): T[] {
  const {
    search,
    filter,
    sort,
    sendingMode,
    searchMethod,
    filterMethods,
    sortMethods,
    filterBySendingModeMethods,
  } = filters

  const processors: Processor<T>[] = [
    { value: search, fct: searchProcessor, methods: searchMethod },
    {
      value: filter,
      fct: filterByActivatedState,
      methods: filterMethods,
    },
    { value: sort, fct: sortByProperty, methods: sortMethods },
  ]

  if (sendingMode && filterBySendingModeMethods)
    processors.push({
      value: sendingMode,
      fct: filterBySendingMode,
      methods: filterBySendingModeMethods,
    })

  return processors.reduce(
    (acc, { value, fct, methods }) => fct(acc, value, methods),
    listToProcess,
  )
}
