import { FunctionComponent, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { defineMessages, useIntl } from "react-intl"

import MouseTooltip from "components/MouseTooltip"
import PillButtonsGroup from "components/PillButtonsGroup"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

import { builderSignaturesSelectors } from "features/BuilderSignatures"

import { setActiveSendingMode } from "features/BuilderSignatures/BuilderSignaturesReducers"

const messages = defineMessages({
  sendingMode: {
    id: "BuilderParametersPanel.SendingMode",
    defaultMessage: "Modalité de diffusion",
  },
  tooltip: {
    id: "BuilderParametersPanel.SendingModeTooltip",
    defaultMessage: "Message d'information",
  },
  newMail: {
    id: "BuilderParametersPanel.NewMail",
    defaultMessage: "Nouveau Mail",
  },
  reply: {
    id: "BuilderParametersPanel.Reply",
    defaultMessage: "En réponse",
  },
  twice: {
    id: "BuilderParametersPanel.Twice",
    defaultMessage: "Les deux",
  },
})

function getCurrentSendingMode(newMail: boolean, inResponse: boolean) {
  if (newMail && inResponse) return "all"
  if (newMail && !inResponse) return "newMail"
  if (!newMail && inResponse) return "inResponse"
}

const SendingModeBlock: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [isHovered, setIsHovered] = useState(false)

  const newMail = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureNewMailValue,
  )
  const inResponse = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureInResponseValue,
  )

  const events = {
    newMail: setActiveSendingMode({
      newMail: true,
      inResponse: false,
    }),
    inResponse: setActiveSendingMode({
      newMail: false,
      inResponse: true,
    }),
    all: setActiveSendingMode({ newMail: true, inResponse: true }),
  }

  const options = [
    {
      id: "newMail",
      children: <>{formatMessage(messages.newMail)}</>,
    },
    { id: "all", children: <>{formatMessage(messages.twice)}</> },
    { id: "inResponse", children: <>{formatMessage(messages.reply)}</> },
  ]

  return (
    <>
      <div className="sending-mode__label">
        <p className="sending-mode__label--title">
          {formatMessage(messages.sendingMode)}
        </p>
        <InfoIcon
          className="sending-mode__label--icon"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </div>
      <div className="sending-mode__pills">
        <PillButtonsGroup
          current={getCurrentSendingMode(newMail, inResponse)}
          onChange={(newSendingMode) => dispatch(events[newSendingMode])}
          pillButtons={options}
          hasSeparator
        />
      </div>
      <MouseTooltip visible={isHovered}>
        {formatMessage(messages.tooltip)}
      </MouseTooltip>
    </>
  )
}

export default SendingModeBlock
