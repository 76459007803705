import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as StopIcon } from "assets/icon-stop.svg"
import { ReactComponent as VeryLowIcon } from "assets/icon-weighting-very-low.svg"
import { ReactComponent as LowIcon } from "assets/icon-weighting-low.svg"
import { ReactComponent as MediumIcon } from "assets/icon-weighting-medium.svg"
import { ReactComponent as HighIcon } from "assets/icon-weighting-high.svg"
import { ReactComponent as VeryHighIcon } from "assets/icon-weighting-very-high.svg"

import useUserSignaturePreview from "features/Users/hooks/useUserSignaturePreview"

import Wrapper from "./components/UserSignaturePreviewWrapper"

import { LoadingStatus } from "core/CoreModels"
import { PartialUser } from "features/Users/UserModels"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"
import { featureNames } from "config/config.features"

interface UserSignaturesPreviewProps {
  user: PartialUser
  close: () => void
}

const messages = defineMessages({
  empty: {
    id: "UserSignaturesPreview.Empty",
    defaultMessage: "n'est lié à aucune signature ou bannière",
  },
  internal: {
    id: "UserSignaturesPreview.Internal",
    defaultMessage: "interne",
  },
  external: {
    id: "UserSignaturesPreview.External",
    defaultMessage: "externe",
  },
  newMail: {
    id: "UserSignaturesPreview.NewMail",
    defaultMessage: "nouveau mail",
  },
  inResponse: {
    id: "UserSignaturesPreview.InResponse",
    defaultMessage: "en réponse",
  },
  weighting: {
    id: "UserSignaturesPreview.Weighting",
    defaultMessage: "Priorité",
  },
  veryLow: {
    id: "UserSignaturesPreview.WeightingVeryLow",
    defaultMessage: "très faible",
  },
  low: {
    id: "UserSignaturesPreview.WeightingLow",
    defaultMessage: "faible",
  },
  medium: {
    id: "UserSignaturesPreview.WeightingMedium",
    defaultMessage: "moyenne",
  },
  high: {
    id: "UserSignaturesPreview.WeightingHigh",
    defaultMessage: "forte",
  },
  veryHigh: {
    id: "UserSignaturesPreview.WeightingVeryHigh",
    defaultMessage: "très forte",
  },
})

const displayUserFullname = (user) => {
  const firstname = user.properties.firstname
  const lastname = user.properties.lastname

  if (firstname || lastname) {
    return `${firstname || ""}${firstname && lastname ? " " : ""}${
      lastname || ""
    }`
  }

  return user.username
}

function getWeightingIcon(weighting: number) {
  switch (weighting) {
    case 2:
      return <LowIcon />
    case 3:
      return <MediumIcon />
    case 4:
      return <HighIcon />
    case 5:
      return <VeryHighIcon />
    case 1:
    default:
      return <VeryLowIcon />
  }
}

function getWeightingLabel(weighting: number) {
  switch (weighting) {
    case 2:
      return messages.low
    case 3:
      return messages.medium
    case 4:
      return messages.high
    case 5:
      return messages.veryHigh
    case 1:
    default:
      return messages.veryLow
  }
}

const UserSignaturesPreview: FunctionComponent<UserSignaturesPreviewProps> = ({
  user,
  close,
}) => {
  const { formatMessage } = useIntl()

  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)
  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const { previews, loadingStatus } = useUserSignaturePreview(user.id)

  const { displayedPreviewIndex, previousPreview, nextPreview, templates } =
    previews

  const displayComponents = useMemo(
    () =>
      displayedPreviewIndex !== null && loadingStatus === LoadingStatus.LOADED,

    [displayedPreviewIndex, loadingStatus],
  )

  if (loadingStatus === LoadingStatus.LOADED && templates.length === 0)
    return (
      <Wrapper close={close}>
        <div className="user-signature-preview__empty--icon">
          <StopIcon />
        </div>
        <p className="user-signature-preview__empty--label">{`${displayUserFullname(
          user,
        )} ${formatMessage(messages.empty)}`}</p>
      </Wrapper>
    )

  const displayDiffusionMode =
    internalExternalFeature?.isActive &&
    templates[displayedPreviewIndex]?.diffusionMode &&
    formatMessage(messages[templates[displayedPreviewIndex].diffusionMode])

  const displaySendingMode =
    newMailInResponseFeature?.isActive &&
    templates[displayedPreviewIndex]?.sendingMode &&
    formatMessage(messages[templates[displayedPreviewIndex].sendingMode])

  if (!displayComponents)
    return (
      <Wrapper close={close}>
        <Wrapper.Preview>
          <div className="skeleton skeleton__preview" />
        </Wrapper.Preview>
        <Wrapper.Data>
          <Wrapper.Infos>
            <div className="skeleton skeleton__container">
              <div className="skeleton skeleton__title" />
              <div className="skeleton skeleton__title" />
            </div>
          </Wrapper.Infos>
          <Wrapper.Navigation
            displayedPreviewIndex={displayedPreviewIndex}
            templatesLength={templates.length}
            previousPreview={previousPreview}
            nextPreview={nextPreview}
          >
            <div className="skeleton skeleton__text" />
          </Wrapper.Navigation>
        </Wrapper.Data>
      </Wrapper>
    )

  return (
    <Wrapper close={close}>
      <Wrapper.Preview>
        <div
          dangerouslySetInnerHTML={{
            __html: templates[displayedPreviewIndex].template,
          }}
        />
      </Wrapper.Preview>
      <Wrapper.Data>
        <Wrapper.Infos>
          <>
            <p className="user-signature-preview__informations--diffusion-mode">
              {`${displayDiffusionMode || ""}${
                displayDiffusionMode && displaySendingMode ? " | " : ""
              }${displaySendingMode || ""}`}
            </p>
            <p className="user-signature-preview__informations--signature-name">
              {templates[displayedPreviewIndex].name}
            </p>
            <div className="user-signature-preview__informations--weighting">
              {getWeightingIcon(templates[displayedPreviewIndex].weighting)}
              <p className="user-signature-preview__informations--weighting-label">
                {`${formatMessage(messages.weighting)} ${formatMessage(
                  getWeightingLabel(templates[displayedPreviewIndex].weighting),
                )}`}
              </p>
            </div>
          </>
        </Wrapper.Infos>
        <Wrapper.Navigation
          displayedPreviewIndex={displayedPreviewIndex}
          templatesLength={templates.length}
          previousPreview={previousPreview}
          nextPreview={nextPreview}
        >
          <p className="user-signature-preview__navigation--current">{`${
            displayedPreviewIndex + 1
          }/${templates.length}`}</p>
        </Wrapper.Navigation>
      </Wrapper.Data>
    </Wrapper>
  )
}

export default UserSignaturesPreview
