import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"

import ConnectedForgotPasswordForm from "features/Accounts/components/ForgotPasswordForm"

const ForgotPasswordPage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="ForgotPassword.Title"
          defaultMessage="Mot de passe oublié ?"
        />
      }
      withoutNavbar
    >
      <div className="l-one-column__container h-100 ">
        <div className="l-one-column__content">
          <ConnectedForgotPasswordForm />
        </div>
      </div>
    </Page>
  )
}

export default ForgotPasswordPage
