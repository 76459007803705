import { FunctionComponent, useMemo, useState } from "react"

import { useSelector } from "react-redux"

import useBuilderSignaturesData from "features/BuilderSignatures/hooks/useBuilderSignaturesData"

import Layout from "features/Signatures/components/SignaturesListLayout"

import BuilderCard from "features/BuilderSignatures/components/BuilderCard"
import NewBuilderButton from "features/BuilderSignatures/components/NewBuilderButton"
import BuilderEmpty from "features/BuilderSignatures/components/BuilderEmpty"

import {
  defaultFilterValue,
  defaultSortValue,
  FilterValuesType,
  processList,
  SortValuesType,
} from "features/Signatures/components/SignaturesListLayout/signaturesListLayoutUtilities"

import { featureNames } from "config/config.features"

import { LoadingStatus } from "core/CoreModels"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"
import {
  AT_LEAST_ONE_BUILDER_SIGNATURE_QUERY,
  signaturesSelectors,
} from "features/Signatures"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"
import useSignaturesCustomqueryData from "features/Signatures/hooks/useSignaturesCustomqueryData"

function searchSignaturesByName(signature: BuilderSignature) {
  return signature.name.toLowerCase().includes(this.searchValue.toLowerCase())
}

const filterMethods = {
  activated: (signature: BuilderSignature) => signature.activated === true,
  deactivated: (signature: BuilderSignature) => signature.activated === false,
}

const sortMethods = {
  alphabeticalOrder: (a: BuilderSignature, b: BuilderSignature) =>
    a.name.localeCompare(b.name),
  creationDate: (a: BuilderSignature, b: BuilderSignature) =>
    a.created > b.created ? -1 : a.created < b.created ? 1 : 0,
  lastUpdate: (a: BuilderSignature, b: BuilderSignature) =>
    a.modified > b.modified ? -1 : a.modified < b.modified ? 1 : 0,
  weighting: (a: BuilderSignature, b: BuilderSignature) =>
    (a.weighting > b.weighting ? -1 : a.weighting < b.weighting ? 1 : 0) ||
    (a.modified > b.modified ? -1 : a.modified < b.modified ? 1 : 0),
}

const filterBySendingModeMethods = {
  newMail: (signature: BuilderSignature) => signature.newMail === true,
  inResponse: (signature: BuilderSignature) => signature.inResponse === true,
}

const SignaturesList: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("")
  const [filter, setFilter] = useState<FilterValuesType>(defaultFilterValue)
  const [sort, setSort] = useState<SortValuesType>(defaultSortValue)

  const sendingMode = useSelector(signaturesSelectors.getSendingMode)
  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const handleSearch = (searchValue) => {
    setSearch(searchValue)
  }

  const handleChangeFilter = (filterValue) => {
    setFilter(filterValue)
  }

  const handleChangeSort = (selectedOption) => {
    setSort(selectedOption.value)
  }

  const { builderSignatures, loadingStatus } = useBuilderSignaturesData()
  const atLeastOneBuilderSignature = useSignaturesCustomqueryData(
    AT_LEAST_ONE_BUILDER_SIGNATURE_QUERY,
  )

  const signaturesList = useMemo(() => {
    const commonProcessors = {
      search,
      filter,
      sort,
      searchMethod: searchSignaturesByName,
      sortMethods,
      filterMethods,
    }

    const newMailInResponseProcessor = newMailInResponseFeature?.isActive
      ? { sendingMode, filterBySendingModeMethods }
      : undefined

    return processList(builderSignatures, {
      ...commonProcessors,
      ...newMailInResponseProcessor,
    })
  }, [builderSignatures, search, filter, sort, sendingMode])

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <Layout
        newButton={<NewBuilderButton />}
        filter={filter}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        handleChangeSort={handleChangeSort}
      >
        <Layout.LoadingSkeleton />
      </Layout>
    )

  if (
    loadingStatus === LoadingStatus.LOADED &&
    builderSignatures.length === 0 &&
    atLeastOneBuilderSignature?.length === 0
  )
    return <BuilderEmpty />

  if (loadingStatus === LoadingStatus.LOADED && signaturesList.length === 0)
    return (
      <Layout
        newButton={<NewBuilderButton />}
        filter={filter}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        handleChangeSort={handleChangeSort}
      >
        <Layout.EmptyResults />
      </Layout>
    )

  return (
    <Layout
      newButton={<NewBuilderButton />}
      filter={filter}
      handleSearch={handleSearch}
      handleChangeFilter={handleChangeFilter}
      handleChangeSort={handleChangeSort}
    >
      {signaturesList.map((builderSignature) => {
        return (
          <BuilderCard
            key={builderSignature.id}
            builderSignature={builderSignature}
          />
        )
      })}
    </Layout>
  )
}

export default SignaturesList
