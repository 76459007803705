import { createElement, FunctionComponent } from "react"

import { Navigate } from "react-router-dom"

import { useSelector } from "react-redux"

import Spinner from "components/Spinner"

import { accountsSelectors } from "features/Accounts"

import useAccountsInformationForm from "./hooks/useAccountsInformationForm"

import { LAST_REGISTER_STEP } from "features/Accounts/AccountsConstants"

import { SavingStatus } from "core/CoreModels"
import { HOME_PORTAL_URL } from "router/RouterConstants"

const AccountInformationsForm: FunctionComponent = () => {
  const { component, componentProps } = useAccountsInformationForm()

  const currentRegisterStep = useSelector(accountsSelectors.getRegisterStep)
  const savingStatus = useSelector(
    accountsSelectors.getAccountInformationsSavingStatus,
  )

  if (currentRegisterStep === LAST_REGISTER_STEP)
    return <Navigate to={HOME_PORTAL_URL} />

  return (
    <div className="account-informations-form">
      {savingStatus === SavingStatus.SAVING ? (
        <Spinner color="white" />
      ) : (
        createElement(component, componentProps)
      )}
    </div>
  )
}

export default AccountInformationsForm
