import { FunctionComponent, useEffect } from "react"

import { defineMessages, useIntl } from "react-intl"

import loginSliderContent0 from "features/Accounts/assets/login-slider-step-0.webp"
import loginSliderContent1 from "features/Accounts/assets/login-slider-step-1.webp"

import { ReactComponent as IconDuotoneUsers } from "assets/duotone/icon-users-regular-duotone.svg"
import { ReactComponent as IconDuotoneSignature } from "assets/duotone/icon-create-signature-duotone.svg"
import { ReactComponent as IconDuotoneMegaphone } from "assets/duotone/icon-megaphone-duotone.svg"

import LoginSliderCard from "./LoginSliderCard"

interface LoginSliderContentProps {
  current: number
}

const messages = defineMessages({
  firstSliderDescription: {
    id: "LoginSlider.FirstSliderDescriptions",
    defaultMessage: "Interface de signature de mail Boost My Mail",
  },
  firstCardTitle: {
    id: "LoginSlider.FirstCardTitle",
    defaultMessage: "Ajouter vos",
  },
  firstCardSubTitle: {
    id: "LoginSlider.FirstCardSubTitle",
    defaultMessage: "utilisateurs",
  },
  secondCardTitle: {
    id: "LoginSlider.SecondCardTitle",
    defaultMessage: "Créer votre",
  },
  secondCardSubTitle: {
    id: "LoginSlider.SecondCardSubTitle",
    defaultMessage: "signature",
  },
  thirdCardTitle: {
    id: "LoginSlider.ThirdCardTitle",
    defaultMessage: "Planifier vos",
  },
  thirdCardSubTitle: {
    id: "LoginSlider.ThirdCardSubTitle",
    defaultMessage: "campagnes",
  },
})

const LoginSliderContent: FunctionComponent<LoginSliderContentProps> = ({
  current,
}) => {
  const { formatMessage } = useIntl()

  useEffect(() => {
    document.getElementById(`slide-${current}`)?.scrollIntoView()
  }, [current])

  return (
    <div className="login-slider__content">
      <div className="login-slider__content--item" id="slide-0">
        <img
          src={loginSliderContent0}
          alt={formatMessage(messages.firstSliderDescription)}
        />
        <p className="login-slider__content--first-description">
          {formatMessage(messages.firstSliderDescription)}
        </p>
      </div>
      <div className="login-slider__content--item" id="slide-1">
        <img
          className="login-slider__content--second-img"
          src={loginSliderContent1}
          alt="logos clients"
        />
      </div>
      <div className="login-slider__content--item" id="slide-2">
        <div className="login-slider__cards">
          <LoginSliderCard
            title={formatMessage(messages.firstCardTitle)}
            subtitle={formatMessage(messages.firstCardSubTitle)}
          >
            <IconDuotoneUsers />
          </LoginSliderCard>
          <LoginSliderCard
            title={formatMessage(messages.secondCardTitle)}
            subtitle={formatMessage(messages.secondCardSubTitle)}
          >
            <IconDuotoneSignature />
          </LoginSliderCard>
          <LoginSliderCard
            title={formatMessage(messages.thirdCardTitle)}
            subtitle={formatMessage(messages.thirdCardSubTitle)}
          >
            <IconDuotoneMegaphone />
          </LoginSliderCard>
        </div>
      </div>
    </div>
  )
}

export default LoginSliderContent
