import React, { FunctionComponent, useMemo } from "react"

import { useDispatch, useSelector } from "react-redux"

import { appSelectors } from "features/App"

import { switchLanguage } from "features/App/AppReducers"

import { AvailableLanguages } from "features/App/AppModels"

import LangSwitchItem from "./components/LangSwitchItem"

const LangSwitch: FunctionComponent = () => {
  const dispatch = useDispatch()
  const locales = useSelector(appSelectors.getLocaleAsArray)
  const selectedLocale = useSelector(appSelectors.getSelectedLang)

  const handleSwitchLanguage = (locale: AvailableLanguages) =>
    dispatch(switchLanguage(locale))

  const langItems = useMemo(
    () =>
      locales.map((locale, index) => (
        <LangSwitchItem
          key={locale.id}
          lang={locale.lang}
          onSwitchLanguage={handleSwitchLanguage}
          selected={locale.lang === selectedLocale}
          hasSeparation={index !== locales.length - 1}
        />
      )),
    [locales],
  )

  return <div className="langswitch">{langItems}</div>
}

export default LangSwitch
