import { useCurrentAccountAccess } from "features/Accounts/hooks/useCurrentAccountAccess"

import { RolesNeeded } from "router/routes"

const TILES_LAYOUTS_CLASSES = [
  "two-columns",
  "three-columns",
  "full",
  "u-form",
  "one-two",
  "two-two",
] as const

type TilesLayoutClasses = typeof TILES_LAYOUTS_CLASSES[number]

function getLayoutFromRoles(
  hasAccessSignatures,
  hasAccessCampaigns,
  hasAccessTracking,
  hasAccessUsers,
): TilesLayoutClasses {
  const activatedRoles = [
    hasAccessSignatures,
    hasAccessCampaigns,
    hasAccessTracking,
    hasAccessUsers,
  ].filter((role) => role === true)

  if (activatedRoles.length === 4) return "full"

  if (activatedRoles.length === 3) {
    if (!hasAccessUsers) return "three-columns"
    return "u-form"
  }

  if (activatedRoles.length === 2) {
    if (hasAccessUsers && !hasAccessTracking) return "one-two"
    if (hasAccessUsers && hasAccessTracking) return "two-two"
    return "two-columns"
  }
}

function useHomePagesTilesLayout() {
  const { hasAccessTo } = useCurrentAccountAccess()

  const hasAccessSignatures = hasAccessTo(RolesNeeded.SIGNATURES_PAGE).isActive
  const hasAccessCampaigns = hasAccessTo(RolesNeeded.CAMPAIGNS_PAGE).isActive
  const hasAccessTracking = hasAccessTo(
    RolesNeeded.TRACKING_PAGE && RolesNeeded.TRACKING_TILE_HOME_PAGE,
  ).isActive
  const hasAccessUsers = hasAccessTo(RolesNeeded.USERS_PAGE).isActive

  return {
    layout: getLayoutFromRoles(
      hasAccessSignatures,
      hasAccessCampaigns,
      hasAccessTracking,
      hasAccessUsers,
    ),
    hasAccessSignatures,
    hasAccessCampaigns,
    hasAccessTracking,
    hasAccessUsers,
  }
}

export default useHomePagesTilesLayout
