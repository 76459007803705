import { FunctionComponent, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { Link } from "react-router-dom"

import MouseTooltip from "components/MouseTooltip"

import { ReactComponent as UsersIcon } from "assets/icon-users-regular.svg"

interface SignatureCardAssignActionProps {
  onAffectUsers: () => void
  affectUrl: string
  roundedCorner?: "top" | "bottom"
  disabled?: boolean
}

export type SignatureCardAssignActionType =
  FunctionComponent<SignatureCardAssignActionProps>

const messages = defineMessages({
  assign: {
    id: "SignatureCard.AssignTooltip",
    defaultMessage: "Associer des utilisateurs et groupes",
  },
})

const SignatureCardAssignAction: SignatureCardAssignActionType = ({
  onAffectUsers,
  affectUrl,
  roundedCorner,
  disabled = false,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Link
        className={classNames(
          "signature-card__btn",
          roundedCorner && `signature-card__rounder-corner--${roundedCorner}`,
          disabled && "is-disabled",
        )}
        data-cy="signature-card__assignBtn"
        onClick={onAffectUsers}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        to={disabled ? "#" : affectUrl}
      >
        <UsersIcon className="signature-card__btn--icon" />
      </Link>
      <MouseTooltip visible={visible}>
        {formatMessage(messages.assign)}
      </MouseTooltip>
    </>
  )
}

export default SignatureCardAssignAction
