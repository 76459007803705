import { FunctionComponent } from "react"

import { useSelector } from "react-redux"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Scrollbars } from "react-custom-scrollbars-2"
import { Navigate } from "react-router-dom"

import Loader from "components/Loader"
import { VerticalBreadCrumb } from "components/BreadCrumb"
import TextBox from "components/TextBox"

import useUsersData from "features/Users/hooks/useUsersData"
import { usersSelectors } from "features/Users"
import useAdditionalInformations from "features/Users/hooks/useAdditionalInformations"

import { trialBreadCrumbMessages } from "features/Trial/components/TrialTunnelContext"

import config from "config/config"
import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  MultiStepFormSignatures: {
    id: "SignatureForm.Signatures",
    defaultMessage: "Signatures",
  },
  MultiStepFormSignaturesProperties: {
    id: "SignatureForm.SignaturesProperties",
    defaultMessage: "Propriétés",
  },
  MultiStepFormBanner: {
    id: "SignatureForm.Banner",
    defaultMessage: "Bannière",
  },
  MultiStepFormUsers: {
    id: "SignatureForm.Users",
    defaultMessage: "Utilisateurs",
  },
  address: { id: "AdditionalInfos.Address", defaultMessage: "Adresse" },
  lastName: { id: "AdditionalInfos.LastName", defaultMessage: "Nom" },
  firstName: { id: "AdditionalInfos.FirstName", defaultMessage: "Prénom" },
  phone: { id: "AdditionalInfos.Phone", defaultMessage: "Fixe" },
  mobile: { id: "AdditionalInfos.Mobile", defaultMessage: "Mobile" },
  jobTitle: { id: "AdditionalInfos.JobTitle", defaultMessage: "Fonction" },
  city: { id: "AdditionalInfos.City", defaultMessage: "Ville" },
  postalCode: {
    id: "AdditionalInfos.PostalCode",
    defaultMessage: "Code Postal",
  },
  mandatory: {
    id: "AdditionalInfos.Mandatory",
    defaultMessage: "Champ obligatoire",
  },
})

const AdditionalInformations: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const shouldRedirect = useSelector(usersSelectors.getShouldRedirect)

  const { properties, errors, handleInputChange, handleAddUser } =
    useAdditionalInformations()

  const { loadingStatus } = useUsersData()

  if (shouldRedirect) return <Navigate to={`${TRIAL_TUNNEL_URL}/2`} />

  const breadCrumbNodes = [
    {
      number: 1,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep1),
    },
    {
      number: 2,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep2),
    },
    {
      number: 3,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep3),
    },
    {
      number: 4,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep4),
    },
  ]

  return (
    <div className="trial__layout l-additionnal-infos">
      <div className="add-infos" data-cy="first-user-form">
        <div className="add-infos__container">
          <Loader
            isLoading={loadingStatus === LoadingStatus.PENDING}
            fullScreen
          />
          <div className="add-infos-content">
            <Scrollbars>
              <div className="add-infos__header">
                <h4 className="add-infos__header-title">
                  <FormattedMessage
                    id="AdditionalInfos.Header"
                    defaultMessage="Commencez par personnaliser votre signature mail"
                  />
                </h4>
              </div>
              <div className="additional-infos__body">
                <div className="additional-infos__left">
                  <div className="additional-infos__image-text">
                    <FormattedMessage
                      id="AdditionalInfos.ImageText"
                      defaultMessage="Ces informations nous servirons de base pour construire votre première signature. Lorem ipsum dolor sit amet, consectetur adipiscing."
                    />
                  </div>
                  <img
                    className="additional-infos__image-img"
                    alt="construct your signature"
                    src={`${config.cdnUrl}/images/bmm/onboarding/signature-card.png`}
                  />
                  <div className="additional-infos__image-clients">
                    <img
                      alt="o365"
                      src={`${config.cdnUrl}/images/bmm/onboarding/office_365_white.svg`}
                      className="additional-infos__image-client"
                    ></img>
                    <img
                      alt="outlook"
                      src={`${config.cdnUrl}/images/bmm/onboarding/outlook_white.svg`}
                      className="additional-infos__image-client"
                    ></img>
                    <img
                      alt="exchange"
                      src={`${config.cdnUrl}/images/bmm/onboarding/exchange_white.svg`}
                      className="additional-infos__image-client"
                    ></img>
                    <img
                      alt="gsuite"
                      src={`${config.cdnUrl}/images/bmm/onboarding/gsuite_white.svg`}
                      className="additional-infos__image-client"
                    ></img>
                  </div>
                </div>
                <div className="additional-infos__form">
                  <div className="additional-infos__input-container">
                    <div>
                      <label>
                        <FormattedMessage id="AdditionalInfos.LastName" />
                      </label>
                      <TextBox
                        data-cy="additional-infos__lastname"
                        variant="outlined"
                        mandatory
                        name="lastname"
                        onChange={handleInputChange}
                        value={properties.lastname}
                        errorText={
                          errors.lastname && formatMessage(messages.mandatory)
                        }
                      />
                    </div>
                    <div>
                      <label>
                        <FormattedMessage id="AdditionalInfos.FirstName" />
                      </label>
                      <TextBox
                        data-cy="additional-infos__firstname"
                        variant="outlined"
                        mandatory
                        name="firstname"
                        onChange={handleInputChange}
                        value={properties.firstname}
                        errorText={
                          errors.firstname && formatMessage(messages.mandatory)
                        }
                      />
                    </div>
                  </div>
                  <div className="additional-infos__input-container">
                    <div className="additional-infos__input-stretch">
                      <label>
                        <FormattedMessage id="AdditionalInfos.JobTitle" />
                      </label>
                      <TextBox
                        data-cy="additional-infos__jobtitle"
                        variant="outlined"
                        mandatory
                        name="jobtitle"
                        onChange={handleInputChange}
                        value={properties.jobtitle}
                        errorText={
                          errors.jobtitle && formatMessage(messages.mandatory)
                        }
                      />
                    </div>
                  </div>
                  <div className="additional-infos__input-container">
                    <div className="additional-infos__input-stretch">
                      <label>
                        <FormattedMessage id="AdditionalInfos.Address" />
                      </label>
                      <TextBox
                        data-cy="additional-infos__address"
                        variant="outlined"
                        mandatory
                        name="streetaddress"
                        onChange={handleInputChange}
                        value={properties.streetaddress}
                        errorText={
                          errors.streetaddress &&
                          formatMessage(messages.mandatory)
                        }
                      />
                    </div>
                  </div>
                  <div className="additional-infos__input-container">
                    <div style={{ width: "33%" }}>
                      <label>
                        <FormattedMessage id="AdditionalInfos.PostalCode" />
                      </label>
                      <TextBox
                        data-cy="additional-infos__postalcode"
                        variant="outlined"
                        mandatory
                        name="postalcode"
                        onChange={handleInputChange}
                        value={properties.postalcode}
                        errorText={
                          errors.postalcode && formatMessage(messages.mandatory)
                        }
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <label>
                        <FormattedMessage id="AdditionalInfos.City" />
                      </label>
                      <TextBox
                        data-cy="additional-infos__city"
                        style={{ width: "100%" }}
                        variant="outlined"
                        mandatory
                        name="city"
                        onChange={handleInputChange}
                        value={properties.city}
                        errorText={
                          errors.city && formatMessage(messages.mandatory)
                        }
                      />
                    </div>
                  </div>
                  <div className="additional-infos__input-container">
                    <div>
                      <label>
                        <FormattedMessage
                          id="AdditionalInfos.Phone"
                          defaultMessage="Fixe"
                        />
                      </label>
                      <TextBox
                        data-cy="additional-infos__phone"
                        variant="outlined"
                        name="phone"
                        onChange={handleInputChange}
                        value={properties.phone}
                      />
                    </div>
                    <div>
                      <label>
                        <FormattedMessage
                          id="AdditionalInfos.Mobile"
                          defaultMessage="Mobile"
                        />
                      </label>
                      <TextBox
                        data-cy="additional-infos__mobile"
                        variant="outlined"
                        name="mobile"
                        onChange={handleInputChange}
                        value={properties.mobile}
                      />
                    </div>
                  </div>
                  <div className="additional-infos__input-container">
                    <div className="additional-infos__input-stretch">
                      <label>
                        <FormattedMessage
                          id="AdditionalInfos.Web"
                          defaultMessage="Site web"
                        />
                      </label>
                      <TextBox
                        data-cy="additional-infos__website"
                        variant="outlined"
                        name="website"
                        onChange={handleInputChange}
                        value={properties.website}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="additional-infos__button-zone">
                <span
                  onClick={handleAddUser}
                  data-cy="additional-infos__add-btn"
                  data-tour="additional-infos__btn"
                  className="bmm-button edit-user__button"
                >
                  <FormattedMessage
                    id="AdditionalInfos.Save"
                    defaultMessage="Valider"
                  ></FormattedMessage>
                </span>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
      <div className="breadcrumb-container">
        <VerticalBreadCrumb nodes={breadCrumbNodes} step={1} />
      </div>
    </div>
  )
}

export default AdditionalInformations
