import { FunctionComponent, useEffect, useMemo, useState } from "react"

import moment from "moment"

import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import { trackingSelectors } from "features/Tracking"

import ScenariosTrackingSlider from "features/Tracking/components/ScenariosTrackingSlider"
import SingleScenarioTracking from "features/Tracking/components/SingleScenarioTracking"
import TrackingTotals from "features/Tracking/components/TrackingTotals"

import { LoadingStatus } from "core/CoreModels"
import { GlobalStates } from "store"
import ScenariosTrackingWrapperLoading from "./components/ScenariosTrackingWrapperLoading"
import useScenariosQueriesDateRange from "features/Tracking/hooks/useScenariosQueriesDateRange"

import { requestSingleScenarioTrackingDataAction } from "features/Tracking/TrackingActions"

const messages = defineMessages({
  title: {
    id: "Tracking.ScenariosTrackingSliderTitle",
    defaultMessage: "Toutes les campagnes",
  },
  tooltip: {
    id: "Tracking.ScenariosTrackingSliderTooltip",
    defaultMessage: "Nombre d'utilisateurs associés à une campagne",
  },
})

const ScenariosTrackingWrapper: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [selectedScenario, setSelectedScenario] = useState(null)
  const [initialSliderScrollPosition, setInitialSliderScrollPosition] =
    useState(undefined)

  const loadingStatus = useSelector<GlobalStates, LoadingStatus>(
    trackingSelectors.getScenariosTrackingLoadingStatus,
  )

  const selectedLoadingStatus = useSelector(
    trackingSelectors.getSingleScenarioTrackingLoadingStatus(
      selectedScenario?.id,
    ),
  )
  const allScenariosTracking = useSelector(
    trackingSelectors.getAllScenariosTracking,
  )

  const { startDate, endDate } = useScenariosQueriesDateRange()

  const maxUsers = useSelector(
    trackingSelectors.getAllScenariosTrackingMaxAffectedUsers,
  )

  const totalClicks = useSelector(
    trackingSelectors.getAllScenariosTrackingTotalClicksFromDateRange(
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
    ),
  )

  const entitiesTrackingHistory = useMemo(
    () =>
      allScenariosTracking.map((s) =>
        s.history.filter(
          (historyItem) =>
            moment(historyItem.date).isSameOrBefore(endDate) &&
            moment(historyItem.date).isSameOrAfter(startDate),
        ),
      ),
    [allScenariosTracking],
  )

  useEffect(() => {
    if (selectedScenario && allScenariosTracking.length > 0) {
      const updated = allScenariosTracking.find(
        (s) => s.id === selectedScenario.id,
      )

      setSelectedScenario(
        updated !== undefined ? updated : allScenariosTracking[0],
      )
    }
  }, [selectedScenario, allScenariosTracking])

  const handleSelect = (id: number) => {
    const relatedScenario = allScenariosTracking.find((scen) => scen.id === id)
    if (relatedScenario) setSelectedScenario(relatedScenario)
    if (
      !relatedScenario.isDefault &&
      selectedLoadingStatus !== LoadingStatus.LOADED
    )
      dispatch(requestSingleScenarioTrackingDataAction(relatedScenario.id))
  }

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <ScenariosTrackingWrapperLoading title={formatMessage(messages.title)} />
    )

  if (!selectedScenario)
    return (
      <>
        <ScenariosTrackingSlider
          scenarios={allScenariosTracking}
          handleSelect={handleSelect}
          title={formatMessage(messages.title)}
          initialSliderScrollPosition={initialSliderScrollPosition}
          handleInitialSliderScrollPosition={(p) =>
            setInitialSliderScrollPosition(p)
          }
        />
        <TrackingTotals
          entitiesTrackingHistory={entitiesTrackingHistory}
          maxUsers={maxUsers}
          totalClicks={totalClicks}
          tooltip={formatMessage(messages.tooltip)}
        />
      </>
    )

  return (
    <SingleScenarioTracking
      scenario={selectedScenario}
      handleUnselect={() => setSelectedScenario(null)}
    />
  )
}

export default ScenariosTrackingWrapper
