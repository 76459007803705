import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as IconCheck } from "assets/icon-check.svg"

const messages = defineMessages({
  header1: {
    id: "OfferDetails.Header1",
    defaultMessage: "SERVICES",
  },
  header2: {
    id: "OfferDetails.Header2",
    defaultMessage: "AMBASSADEUR",
  },
  header3: {
    id: "OfferDetails.Header3",
    defaultMessage: "PREMIUM",
  },
  row1: {
    id: "OfferDetails.Row1",
    defaultMessage:
      "Création d’une signature mail à partir de modèles personnalisables",
  },
  unlimited: {
    id: "OfferDetails.Unlimited",
    defaultMessage: "Illimité",
  },
  row2: {
    id: "OfferDetails.Row2",
    defaultMessage:
      "Mise à jour automatique de la signature mail sur les comptes utilisateurs des collaborateurs",
  },
  row3: {
    id: "OfferDetails.Row3",
    defaultMessage: "Diffusion de bannières mails",
  },
  row4: {
    id: "OfferDetails.Row4",
    defaultMessage: "Multi-signature collaborateur",
  },
  row5: {
    id: "OfferDetails.Row5",
    defaultMessage:
      "Synchronisation des annuaires utilisateurs et des groupes Microsoft et Google",
  },
  row6: {
    id: "OfferDetails.Row6",
    defaultMessage: "Hébergement des visuels",
  },
  row7: {
    id: "OfferDetails.Row7",
    defaultMessage:
      "Assistance téléphonique et chat en ligne de 9h à 12h et de 14h à 18h, du lundi au vendredi",
  },
  row8: {
    id: "OfferDetails.Row8",
    defaultMessage:
      "Consulting Marketing inclus: Performance de vos campagnes (à partir de 50 utilisateurs)",
  },
})

const OfferDetails: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="offer-details__container">
      <div className="offer-details__table">
        <table>
          <tbody>
            <tr>
              <th>{formatMessage(messages.header1)}</th>
              <th>{formatMessage(messages.header2)}</th>
              <th>{formatMessage(messages.header3)}</th>
            </tr>
            <tr>
              <td>{formatMessage(messages.row1)}</td>
              <td>2</td>
              <td>{formatMessage(messages.unlimited)}</td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row2)}</td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row2)}</td>
              <td>3</td>
              <td>{formatMessage(messages.unlimited)}</td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row4)}</td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row5)}</td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row6)}</td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row7)}</td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
              <td>
                <IconCheck className="offer-details__table--icon" />
              </td>
            </tr>
            <tr>
              <td>{formatMessage(messages.row8)}</td>
              <td>Non</td>
              <td>Oui</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OfferDetails
