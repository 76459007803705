import React, { ReactNode, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import MouseTooltip from "components/MouseTooltip"

export interface Item {
  id: string | number
  label: string
  message?: ReactNode
}

interface ListGenericItemProps {
  item: Item
  button?: true
  onClick?(item: Item)
  avatarSrc?: string
  onDelete?(item: Item)
  checked?: boolean
}

const messages = defineMessages({
  btnDelete: { id: "ListGenericItem.BtnDelete", defaultMessage: "Supprimer" },
})

const ListGenericItem: React.FC<ListGenericItemProps> = ({
  item,
  button,
  onClick,
  avatarSrc,
  onDelete,
  checked,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  const handleMouseEnter = () => {
    setVisible(true)
  }

  const handleMouseLeave = () => {
    setVisible(false)
  }

  return (
    <div
      key={item.id}
      data-cy="list-generic__item"
      className="list-generic-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItem button={button} onClick={() => onClick && onClick(item)}>
        {avatarSrc && (
          <ListItemIcon>
            <img src={avatarSrc} />
          </ListItemIcon>
        )}
        <ListItemText
          className="list-generic-item__label"
          primary={item.label}
        />
        {onDelete && (
          <ListItemSecondaryAction
            data-cy="list-generic-item__delete"
            className="list-generic-item__delete"
            onClick={() => onDelete(item)}
          >
            <span>{formatMessage(messages.btnDelete)}</span>
          </ListItemSecondaryAction>
        )}
        {checked && (
          <ListItemSecondaryAction>
            <i className="material-icons list-generic-item__icon">done</i>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {item.message && (
        <MouseTooltip visible={visible}>{item.message}</MouseTooltip>
      )}
    </div>
  )
}

export default ListGenericItem
