import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import Carousel from "features/Enrollment/components/EnrollmentCarousel"

import "./EnrollmentStep.scss"

import visualQuote from "features/Enrollment/assets/visual-quote.svg"

type Testimonial = {
  id: number
  name: string
  title: string
  value: string
}

const messages = defineMessages({
  customers: {
    id: "Enroll.StepSide2a",
    defaultMessage: "Des clients satisfaits",
  },
  says: {
    id: "Enroll.StepSide2b",
    defaultMessage: "et ils nous le disent !",
  },
})

const TESTIMONIALS = [
  {
    id: 0,
    name: "Arnaud Tesson",
    title: "Chef de projet Communication Corporate, Groupe Réalités",
    value:
      "Le groupe REALITES a mis en place une campagne pour annoncer la création d’un parcours d’achat dématérialisé. La campagne a généré 33 000 clics. Un résultat très positif qui a contribué, à générer une croissance des ventes.",
  },
  {
    id: 1,
    name: "Perrine Douillard",
    title: "Chargée de communication chez VM - Groupe Herige",
    value:
      "Aujourd’hui, nous ne pourrions plus nous passer de la solution ! C’est très plaisant pour les équipes marketing de produire des signatures mails personnalisées, utiles et pertinentes.",
  },
  {
    id: 2,
    name: "Marie Doxin",
    title: "Chef de projet numérique, Groupe chesneau",
    value:
      "La facilité de la mise en place et l’automatisation de déploiement des signatures mails a fini de nous convaincre.",
  },
  {
    id: 3,
    name: "Nathalie Ganaye",
    title: "Chargée de communication, LBA Walter France",
    value:
      "On voit un réel intérêt autour de nos bannières avec un volume de clics non négligeable.",
  },
  {
    id: 4,
    name: "Stén Bienvenu",
    title: "Dirigeant-fondateur, Agence de Communication Classe 7",
    value:
      "J’ai immédiatement été séduit par la simplicité de son interface pour créer des signatures mails et gérer des campagnes marketing.",
  },
]

const EnrollmentStep2Side: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="enroll__box enroll__aside enroll__aside__step2 bmm-carousel">
      <header>
        <div className="enroll__aside__title">
          {formatMessage(messages.customers)}
        </div>
        <div className="enroll__aside__subtitle">
          {formatMessage(messages.says)}
        </div>
      </header>
      <section>
        <img src={visualQuote} alt="" />
        <Carousel>
          {TESTIMONIALS.map((item: Testimonial) => (
            <div key={item.id}>
              <div className="bmm-carousel__text">{item.value}</div>
              <div className="bmm-carousel__name">{item.name}</div>
              <div className="bmm-carousel__title">{item.title}</div>
            </div>
          ))}
        </Carousel>
      </section>
    </div>
  )
}

export default EnrollmentStep2Side
