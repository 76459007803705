import { usersSelectors } from "features/Users"
import config from "config/config"
import {
  Account,
  AccountData,
  OnBoardingKey,
} from "features/Accounts/AccountsModels"
import { Tenant } from "entities/Tenant"
import { Origin } from "features/Users/UserModels"
import { LoadingStatus, SavingStatus } from "core/CoreModels"
import {
  selectAdminAccountById,
  selectAllAdminAccounts,
} from "./AccountsReducer"
import { Roles } from "./AccountsModels"
import { RootState } from "store/configureStore"
import Version from "entities/Version"
import TenantProperty from "entities/TenantProperty"
import { LAST_REGISTER_STEP } from "./AccountsConstants"
import { GlobalStates } from "store"
import { getCurrentSubsidiary } from "features/Subsidiaries/SubsidiariesSelectors"
import { selectAllSubsidiaries } from "features/Subsidiaries/SubsidiariesReducer"

/**
 * Accounts SELECTORS
 */

const getLoginErrors = (state: RootState): string => state.accounts.loginErrors

const getIsAuthentified = (state: RootState): boolean =>
  state.accounts.isAuthenticated

const getIsNotReadyYet = (state: RootState): boolean =>
  state.accounts.isNotReadyYet

const getCurrentAccount = (state: RootState): Account =>
  state.accounts.currentAccount

const getCurrentAccountId = (state: RootState): string =>
  state.accounts.currentAccount?.Id

const getCurrentAccountEmail = (state: RootState): string =>
  state.accounts.currentAccount?.Email

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getCreateAccountErrors = (state: RootState) =>
  state.accounts.createAccountErrors

const getResetPasswordErrors = (
  state: RootState,
): [
  {
    code: string
    description: string
  },
] => state.accounts.resetPassword.errors

const getCreateAccountSuccessStatus = (state: RootState): boolean =>
  state.accounts.isJustCreated

const getCaptcha = (state: RootState): string | null => state.accounts.captcha

const getLoadingStatus = (state: RootState): boolean => state.accounts.loading

const getWindowsVersions = (state: RootState): Version[] =>
  state.accounts.windowsVersions

const getMacLink = (state: RootState): string => state.accounts.macLink

const getPowershellLink = (state: RootState): string =>
  state.accounts.powershellLink

const getO365Link = (): string => config.o365UrlConsent

const getOwaLink = (): string => config.owaUrlConsent

const getGoogleAnalyticsProperty = (state: RootState): TenantProperty =>
  state.accounts.properties.googleanalytics

const getCGSLink = (): string => config.cgsLink

const getAccountData = (state: RootState): AccountData => {
  const accountData: AccountData = {
    company: state.accounts.properties.companyname,
    business: state.accounts.properties.business,
    employeesNumber: state.accounts.properties.employeesnumber,
  }

  return accountData
}

const getTenantProperty =
  (propertyName: string) =>
  (state: RootState): null | string => {
    return state.accounts.properties[propertyName] == null
      ? null
      : state.accounts.properties[propertyName].Value
  }

const getResetPasswordRedirectUrl = (state: RootState): string =>
  state.accounts.resetPassword.redirectUrl

const getTenantInformations = (state: RootState): Tenant =>
  state.accounts.currentTenant

const getTenantId = (state: RootState): number =>
  state.accounts.currentAccount?.TenantId

const getIsInformationsFilled = (state: RootState): boolean =>
  state.accounts.currentAccount &&
  state.accounts.currentAccount.StepRegister === LAST_REGISTER_STEP

const getRegisterStep = (state: GlobalStates): number =>
  state.accounts.currentAccount?.StepRegister || 0

const getClientApiKey = (state: RootState): string =>
  state.accounts.currentTenant?.ApiKey

const getIsAdminUserExists = (state: RootState): boolean => {
  const allUsers = usersSelectors.getAllUsers(state)
  const adminUser = getCurrentAccount(state)
  if (adminUser == null) return false

  const adminUserAlreadyExists = allUsers.filter(
    (us) => us.Username === adminUser.UserName,
  )
  return adminUserAlreadyExists.length > 0
}

const getGWorkspaceDomains = (state: RootState): string[] =>
  state.accounts.domains

const getRoles = (state: RootState): Roles[] =>
  state.accounts.currentAccount?.Roles

const isCurrentAdminSubsidiaryAdmin = (state: RootState): boolean => {
  const currentSubsidiary = getCurrentSubsidiary(state)

  if (currentSubsidiary === null) return false

  const currentAccount = getCurrentAccount(state)

  return currentSubsidiary.aspNetUserIds.includes(currentAccount.Id)
}

const getEnabledSynchronisationMethods = (state: RootState): Array<Origin> =>
  state.accounts.currentTenant?.getActiveSources() ?? []

const getAdminAccountsLoadingStatus = (state: RootState): LoadingStatus =>
  state.accounts.allAdminAccounts.loadingStatus

const getNewAdminAccountSavingStatus = (state: RootState): SavingStatus =>
  state.accounts.allAdminAccounts.savingStatus

const getNewAdminAccountErrorMessage = (state: RootState): string | null =>
  state.accounts.allAdminAccounts.newAdminAccountErrorMessage

const getAdminAccountUpdatingStatus = (state: RootState): SavingStatus =>
  state.accounts.allAdminAccounts.updatingStatus

const getAccountInformationsSavingStatus = (state: RootState): SavingStatus =>
  state.accounts.accountInformations.savingStatus

const getTrialCampaignUnlockedProperty = (state: RootState): TenantProperty =>
  state.accounts.properties?.campaignUnlockedProperty

const getTrackingJobCreatedProperty = (state: RootState): TenantProperty =>
  state.accounts.properties?.trackingJobCreated

const getTenantPropertiesLoadingStatus = (state: RootState): LoadingStatus =>
  state.accounts.propertiesLoadingStatus

const getApiSyncStatus = (state: RootState): boolean =>
  state.accounts.currentTenant?.Api || false

const getGSuiteSyncStatus = (state: RootState): boolean =>
  state.accounts.currentTenant?.GSuite || false

const getO365SyncStatus = (state: RootState): boolean =>
  state.accounts.currentTenant?.O365 || false

const getOnBoardingCompleted = (state: RootState): boolean => {
  const eventsDate = Object.values(state.accounts.onBoarding.items).map(
    (value) => (value.date === null ? null : value.date),
  )

  return eventsDate.every((d) => d !== null && !isNaN(new Date(d).valueOf()))
}

const getOnBoardingStepCompleted = (key: OnBoardingKey) => (state: RootState) =>
  state.accounts.onBoarding.items[key].state === "completed"

const getLastOnBoardingEventDate = (state: RootState): Date | null => {
  const eventsDate = Object.values(state.accounts.onBoarding.items).map(
    (value) => (value.date === null ? null : value.date),
  )

  if (eventsDate.includes(null)) return null

  return new Date(Math.max(...eventsDate.map(Date.parse)))
}

const getOnBoardingItems = (state: RootState) => state.accounts.onBoarding.items

const getOnBoardingItemsLoadingStatus = (state: RootState) =>
  state.accounts.onBoarding.loadingStatus

const getHomePageLoadingStatus = (state: RootState) =>
  state.accounts.homePage.loadingStatus

const getSignaturesTileCount = (state: RootState) =>
  state.accounts.homePage.signatures.count

const getSignaturesTileLoadingStatus = (state: RootState) =>
  state.accounts.homePage.signatures.loadingStatus

const getCampaignsTileCount = (state: RootState) =>
  state.accounts.homePage.campaigns.count

const getCampaignsTileLoadingStatus = (state: RootState) =>
  state.accounts.homePage.campaigns.loadingStatus

const getTrackingTileCount = (state: RootState) =>
  state.accounts.homePage.tracking.count

const getTrackingTileLoadingStatus = (state: RootState) =>
  state.accounts.homePage.tracking.loadingStatus

const getUsersTileCount = (state: RootState) =>
  state.accounts.homePage.users.count

const getUsersTileLatest = (state: RootState) =>
  state.accounts.homePage.users.latest

const getUsersTileLoadingStatus = (state: RootState) =>
  state.accounts.homePage.users.loadingStatus

const getTenantAdminAccounts = (state: RootState) => {
  const subsidiaries = selectAllSubsidiaries(state)

  const allAdminAccounts = selectAllAdminAccounts(state)

  return allAdminAccounts.filter(
    (a) =>
      !subsidiaries.some((subsidiary) =>
        subsidiary.aspNetUserIds.includes(a.id),
      ),
  )
}

const getSubsidiariesAdminAccounts = (state: RootState) => {
  const subsidiaries = selectAllSubsidiaries(state)

  const allAdminAccounts = selectAllAdminAccounts(state)

  return allAdminAccounts.filter((a) =>
    subsidiaries.some((subsidiary) => subsidiary.aspNetUserIds.includes(a.id)),
  )
}

const getAdminAccountById = (adminId: string) => (state) =>
  selectAdminAccountById(state, adminId)

export const selectors = {
  getLoadingStatus,
  getLoginErrors,
  getIsAuthentified,
  getIsNotReadyYet,
  getCurrentAccount,
  getCreateAccountErrors,
  getCreateAccountSuccessStatus,
  getCaptcha,
  getAccountData,
  getWindowsVersions,
  getMacLink,
  getPowershellLink,
  getO365Link,
  getOwaLink,
  getGoogleAnalyticsProperty,
  getCGSLink,
  getResetPasswordErrors,
  getResetPasswordRedirectUrl,
  getTenantInformations,
  getTenantId,
  getIsInformationsFilled,
  getClientApiKey,
  getIsAdminUserExists,
  getTenantProperty,
  getGWorkspaceDomains,
  getEnabledSynchronisationMethods,
  getRoles,
  getAdminAccountsLoadingStatus,
  getNewAdminAccountSavingStatus,
  getAdminAccountUpdatingStatus,
  getNewAdminAccountErrorMessage,
  getAllAdminAccounts: selectAllAdminAccounts,
  getCurrentAccountEmail,
  getCurrentAccountId,
  getRegisterStep,
  getAccountInformationsSavingStatus,
  getTrialCampaignUnlockedProperty,
  getTrackingJobCreatedProperty,
  getTenantPropertiesLoadingStatus,
  getApiSyncStatus,
  getGSuiteSyncStatus,
  getO365SyncStatus,
  getLastOnBoardingEventDate,
  getOnBoardingCompleted,
  getOnBoardingItems,
  getOnBoardingStepCompleted,
  getOnBoardingItemsLoadingStatus,
  getHomePageLoadingStatus,
  getSignaturesTileCount,
  getSignaturesTileLoadingStatus,
  getCampaignsTileCount,
  getCampaignsTileLoadingStatus,
  getTrackingTileCount,
  getTrackingTileLoadingStatus,
  getUsersTileCount,
  getUsersTileLatest,
  getUsersTileLoadingStatus,
  isCurrentAdminSubsidiaryAdmin,
  getTenantAdminAccounts,
  getSubsidiariesAdminAccounts,
  getAdminAccountById,
}

export default selectors
