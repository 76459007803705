import React from "react"
import BreadCrumbItem from "./VerticalBreadCrumbItem"

interface Props {
  nodes: any[]
  step: number
}

export class VerticalBreadCrumb extends React.Component<Props> {
  render() {
    const lastItemIdx = this.props.nodes.length - 1
    const nodesItem = this.props.nodes.map((node, idx) => {
      return (
        <>
          <BreadCrumbItem
            step={idx + 1}
            title={node.name}
            active={node.number <= this.props.step}
          />
          {idx !== lastItemIdx && (
            <div
              className={
                node.number <= this.props.step
                  ? "breadcrumb__vertical-separator breadcrumb__vertical-separator--active"
                  : "breadcrumb__vertical-separator"
              }
            />
          )}
        </>
      )
    })

    return (
      <nav className="breadcrumb__container-vertical">
        <div className="breadcrumb__vertical">{nodesItem}</div>
      </nav>
    )
  }
}
