import { FunctionComponent, ReactNode } from "react"

import InternalExternalPills from "features/Signatures/components/InternalExternalPills"
import NewMailReplyPills from "features/Signatures/components/NewMailReplyPills"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { featureNames } from "config/config.features"

interface SignaturesListLayoutHeaderProps {
  children: ReactNode
}

const SignaturesListLayoutHeader: FunctionComponent<
  SignaturesListLayoutHeaderProps
> = ({ children }) => {
  const internalExternalFeature = useFeatureTogglr(
    featureNames.SIGNATURES_INTERNE_EXTERNE,
  )
  const newReplyFeature = useFeatureTogglr(featureNames.NEWMAIL_INRESPONSE)
  return (
    <div className="signatures-list-layout__header">
      <div className="signatures-list-layout__header--container">
        <div className="signatures-list-layout__header--pills">
          {internalExternalFeature?.isActive && <InternalExternalPills />}
          {newReplyFeature?.isActive && <NewMailReplyPills />}
        </div>
        {children}
      </div>
    </div>
  )
}

export default SignaturesListLayoutHeader
