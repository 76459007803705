export function formatAmount(value: number, locale: string, currency?: string) {
  const options = {
    minimumFractionDigits: (value / 100) % 1 === 0 ? 0 : 2,
    currency,
    style: currency ? "currency" : undefined,
  }

  return new Intl.NumberFormat(locale, options).format(value / 100)
}

export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}
