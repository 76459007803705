import React, { useState } from "react"

import swap from "shared/sort/swap"

import ListDraggable from "components/ListDraggable"

import CampaignScenarioCard from "features/Scenarios/components/CampaignScenarioCard"

interface Props {
  scenario: any[]
  sortable?: boolean
  editable?: boolean
  numbered?: boolean
  onChange?(items: any[]): void
  onSave?({}): void
  onDelete?({}): void
}

const CampaignScenarioCardList: React.FC<Props> = ({
  scenario,
  onChange,
  onSave,
  onDelete,
  editable,
}) => {
  const [isDraggable, setIsDraggable] = useState(true)
  const getItems = () =>
    scenario
      .sort((a, b) => a.order - b.order)
      .map((banner, i) => ({
        data: banner,
        component: (
          <div className="campaignscenariocardlist__item">
            {editable && (
              <div className="campaignscenariocard__handle">
                <div
                  className="campaignscenariocard__handle__button"
                  onClick={() => handleArrows(banner, i, i - 1)}
                >
                  ↑
                </div>
                <div className="campaignscenariocard__handle__drag">‖</div>
                <div
                  className="campaignscenariocard__handle__button"
                  onClick={() => handleArrows(banner, i, i + 1)}
                >
                  ↓
                </div>
              </div>
            )}
            <CampaignScenarioCard
              disabled={!editable}
              banner={banner}
              onSave={onSave && onSave}
              onEditChange={(isEditing) => setIsDraggable(!isEditing)}
              onDelete={onDelete && onDelete}
            ></CampaignScenarioCard>
          </div>
        ),
      }))

  const handleArrows = (item, oldPosition, newPosition) => {
    if (newPosition === -1) return
    const items = getItems()
    if (newPosition >= items.length) return
    const reorderedItems = swap(items, oldPosition, newPosition, "data.order")
    onChange(reorderedItems)
  }

  return (
    <div className="campaignscenariocardlist">
      <div className="campaignscenariocardlist__dots"></div>
      <div className="campaignscenariocardlist__numbers">
        {scenario.map((item, i) => (
          <div key={i} className="campaignscenariocardlist__number">
            {i + 1}
          </div>
        ))}
      </div>
      <ListDraggable
        draggable={editable && isDraggable}
        items={getItems()}
        draggableKey="draggableId"
        onDragEnd={onChange && onChange}
      ></ListDraggable>
    </div>
  )
}

export default CampaignScenarioCardList
