import { FC } from "react";
import {Alert, AlertStatus} from "../../AlertsModels"

interface Props{
  alert: Alert
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const AlertCard: FC<Props> = ({ alert, onClick, onMouseEnter, onMouseLeave }) => {
  return <div className={`alert__card ${alert.readStatus==AlertStatus.Read?"alert--read":""}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
    <div onClick={onClick} className="alert__card__container">
      {
        alert.icon
        &&
        <img className="alert__card__img" src={alert.icon} />
      }
      <div className="alert__card__content">
        <p className="alert__card__title">{alert.title}</p>
        <p className={`alert__card__description`}>{alert.content}</p>
      </div>
      {
        alert.readStatus == AlertStatus.New &&
        <div className="green__circle"></div>
      }
    </div>
  </div>
};

export default AlertCard