import React, { FunctionComponent, ReactNode } from "react"

interface AssignationsPanelDataProps {
  children: ReactNode
}

export type AssignationsPanelDataType =
  FunctionComponent<AssignationsPanelDataProps>

const AssignationsPanelData: AssignationsPanelDataType = ({ children }) => {
  return <div className="assignations-panel__data">{children}</div>
}

export default AssignationsPanelData
