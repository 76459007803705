import React, { FunctionComponent, ReactNode } from "react"

import Select from "react-select"

import type {
  ActionMeta,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select"

export interface SelectedOption {
  label: string
  value: string
}

interface SelectFieldProps {
  options?: SelectedOption[]
  name?: string
  value?: SelectedOption
  defaultValue?: SelectedOption
  placeholder?: string
  isLoading?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  menuPlacement?: "top" | "auto" | "bottom"
  menuPortalTarget?: HTMLElement | null
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode
  onChange?: (
    newValue: SingleValue<SelectedOption> | MultiValue<SelectedOption>,
    actionMeta: ActionMeta<SelectedOption>,
  ) => void
}

export type SelectFieldType = FunctionComponent<SelectFieldProps>

const SelectField: SelectFieldType = ({
  name,
  value,
  defaultValue,
  placeholder,
  options,
  isLoading,
  isDisabled,
  isClearable,
  isSearchable,
  isMulti,
  menuPlacement,
  menuPortalTarget,
  onChange,
  noOptionsMessage,
}) => {
  const customStyles: StylesConfig<SelectedOption> = {
    valueContainer: (provided) => ({
      ...provided,
      height: "48px",
    }),
    container: (provided) => ({
      ...provided,
      height: "48px",
      border: "0",
      width: "190px",
    }),
    control: (provided) => ({
      ...provided,
      maxHeight: "48px",
      minHeight: "48px",
      backgroundColor: "#fff",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "48px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.12)",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Circular Std",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "18px",
      color: "rgba(0, 0, 0, 0.87)",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Circular Std",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "18px",
      color: "rgba(0, 0, 0, 0.54)",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "48px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#4D4D4D" : provided.color,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.54)",
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: "Circular Std",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "-0.2px",
      color: "rgba(0, 0, 0, 0.54)",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 100,
    }),
  }

  return (
    <Select
      name={name}
      value={value}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      isDisabled={isDisabled || isLoading}
      isSearchable={isSearchable}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
      styles={customStyles}
      placeholder={placeholder}
      menuPortalTarget={menuPortalTarget}
      noOptionsMessage={noOptionsMessage}
      isMulti={isMulti}
    />
  )
}

export default SelectField
