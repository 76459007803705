import React, { useState, useRef, useEffect } from "react"

import classNames from "classnames"

import { FormattedMessage } from "react-intl"

import { useOnClickOutside } from "hooks/useOnClickOutside"

import Collapse from "@material-ui/core/Collapse"

import ImageResizer from "components/ImageResizer"

import iconEdit from "./images/icon-pen-white.svg"
import iconDelete from "./images/icon-trash-white.svg"

interface Props {
  banner: any
  disabled?: boolean
  onSave?({}): void
  onDelete?({}): void
  onEditChange?: (isEditing: boolean) => void
}

const CampaignScenarioCard: React.FC<Props> = ({
  banner,
  onSave,
  onDelete,
  onEditChange,
  disabled,
}) => {
  const [editing, setEditing] = useState(false)
  const [info, setInfo] = useState({ ...banner })
  const maxSize = { width: 900, height: 600 }

  const ref = useRef(null)
  useOnClickOutside(ref, () => {
    setEditing(false)
    onEditChange(false)
  })

  useEffect(() => {
    if (!disabled && ref.current)
      ref.current?.scrollIntoView({ behavior: "smooth" })
  }, [])

  const handleEdit = () => {
    if (disabled) return
    const isEditing = !editing
    setEditing(isEditing)
    onEditChange(isEditing)
  }

  const handleDelete = () => {
    onDelete && onDelete(banner)
  }

  const handleResize = (width: number, height: number) => {
    setInfo({ ...info, width, height })
    handleSave()
  }

  const handleUrl = (url) => {
    setInfo({ ...info, linkUrl: url })
  }

  const handleSave = () => {
    onSave && onSave(info)
  }

  return (
    <div className="campaignscenariocard" ref={ref}>
      <div
        className={classNames("campaignscenariocard__banner", {
          "campaignscenariocard--disabled": disabled,
        })}
        style={{
          backgroundImage: `url(${banner.imageUrl})`,
          backgroundSize: `${info.width}px ${info.height}px`,
        }}
      >
        <a
          className={"campaignscenariocard__edit"}
          onClick={() => handleEdit()}
        >
          <img src={iconEdit} alt="edit" />
        </a>
        <a
          className="campaignscenariocard__delete"
          onClick={() => handleDelete()}
        >
          <img src={iconDelete} alt="" />
        </a>
      </div>

      <Collapse in={editing} timeout={200}>
        <div className="campaignscenariocard__settings">
          <ImageResizer
            max={maxSize}
            size={{ width: info.width, height: info.height }}
            resizeImage={handleResize}
          />
          <div className="campaignscenariocard__settings_title">
            <FormattedMessage id="Campaigns.Link" defaultMessage="Lien" />
          </div>
          <input
            type="url"
            placeholder="URL de la campagne"
            className="input url"
            value={info.linkUrl}
            onChange={(event) => handleUrl(event.target.value)}
            onBlur={() => handleSave()}
          />
          <div className="campaignscenariocard__settings__buttons" />
        </div>
      </Collapse>
    </div>
  )
}

export default CampaignScenarioCard
