import { FunctionComponent, ReactNode, useState } from "react"

import MouseTooltip from "components/MouseTooltip"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface TrackingTileInfosBubbleProps {
  children: ReactNode
}

const TrackingTileInfosBubble: FunctionComponent<
  TrackingTileInfosBubbleProps
> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className="tracking-tile__infos-bubble--container">
      <InfoIcon
        className="tracking-tile__infos-bubble--icon"
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      />
      <MouseTooltip visible={visible}>{children}</MouseTooltip>
    </div>
  )
}

export default TrackingTileInfosBubble
