import { FunctionComponent, useCallback, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { SignatureRenderer } from "bmm-builder"

import useSignaturesQueriesDateRange from "features/Tracking/hooks/useSignaturesQueriesDateRange"

import TrackingDatePicker from "features/Tracking/components/TrackingDatePicker"
import HorizontalScrollingButtons from "features/Tracking/components/HorizontalScrollingButtons"

import SignaturePreview from "features/Signatures/components/SignaturePreview"

import { SignatureTracking } from "features/Tracking/TrackingModels"

interface SignaturesTrackingSliderProps {
  signatures: SignatureTracking[]
  title: string
  handleSelect: (id: number) => void
}

const messages = defineMessages({
  subTitle: {
    id: "Tracking.SignaturesTrackingSliderSubTitle",
    defaultMessage: "Sélectionnez une signature pour voir ses statistiques",
  },
})

const SignaturesTrackingSlider: FunctionComponent<
  SignaturesTrackingSliderProps
> = ({ signatures, title, handleSelect }) => {
  const { formatMessage } = useIntl()
  const [callBackNode, setCallbackNode] = useState(null)

  const returnedRef = useCallback((node) => {
    if (node !== null) setCallbackNode(node)
  }, [])

  const { startDate, endDate, handleDateChange } =
    useSignaturesQueriesDateRange()

  return (
    <div className="sign-track-slider">
      <div className="sign-track-slider__header">
        <p className="sign-track-slider__title">
          {`${title} (`}
          <span className="sign-track-slider__title--signatures">
            {signatures.length}
          </span>
          {`)`}
        </p>
        <TrackingDatePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDateChange}
        />
        <p className="sign-track-slider__sub-title">
          {formatMessage(messages.subTitle)}
        </p>
      </div>
      <HorizontalScrollingButtons node={callBackNode}>
        <div className="sign-track-slider__templates" ref={returnedRef}>
          {signatures.map((signature) => (
            <div
              className="sign-track-slider__templates--element"
              key={signature.id}
              onClick={() => handleSelect(signature.id)}
            >
              <div className="sign-track-slider__templates--cover">
                {signature.isBuilder ? (
                  <SignatureRenderer
                    templateJSON={signature.template}
                    scale={0.5}
                  />
                ) : (
                  <SignaturePreview
                    template={signature.template}
                    cardHeight={100}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </HorizontalScrollingButtons>
    </div>
  )
}

export default SignaturesTrackingSlider
