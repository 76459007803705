import React, { useEffect, useState } from "react"
import {
  injectIntl,
  defineMessages,
  IntlShape,
  FormattedMessage,
} from "react-intl"
import GenericForm from "components/GenericForm"
import { InputSize, InputType } from "components/GenericForm/GenericForm"
import Button from "components/Button"
import Feature from "../../../FeaturesModels"
import config from "config/config"
import GSuiteSAConfigForm from "../GSuiteSAConfigForm"
import StatusIcon from "components/StatusIcon"
import MultiSelect from "components/MultiSelect"
import Loader from "components/Loader"
import { cloneDeep } from "lodash"

interface Props {
  intl: IntlShape
  featureGSuite: Feature
  domains: string[]
  onValidate(feature: Feature)
  onSave(feature: Feature)
  onCancel()
}

const messages = defineMessages({
  keyTitle: {
    id: "GSuiteForm.KeyTitle",
    defaultMessage:
      "Autorisez le compte de service BoostMyMail à accéder à vos utilisateurs",
  },
  keyStep1: {
    id: "GSuiteForm.Step1Text",
    defaultMessage:
      "1. Se rendre sur votre écran de gestion des permissions de clients Google Workspace",
  },
  keyStep2: {
    id: "GSuiteForm.Step2Text",
    defaultMessage: '2. Cliquez sur le bouton "Clients API"',
  },
  keyStep3: {
    id: "GSuiteForm.Step3Text",
    defaultMessage:
      '3. Copier/Coller les valeurs suivantes dans les champs correspondants puis cliquer sur "Autoriser"',
  },
  keyPlaceholder: {
    id: "GSuiteForm.KeyPlaceholder",
    defaultMessage: "Clé privée/publique",
  },
  emailAdminLabel: {
    id: "GSuiteForm.EmailAdminLabel",
    defaultMessage: "Email d'un compte Administrateur GSuite :",
  },
  emailAdminPlaceholder: {
    id: "GSuiteForm.EmailAdminPlaceholder",
    defaultMessage: "Ex: my-admin@boostmymail.com",
  },
  directoryLabel: {
    id: "GSuiteForm.DirectoryLabel",
    defaultMessage: "Nom du domaine GSuite de vos utilisateurs :",
  },
  directoryPlaceholder: {
    id: "GSuiteForm.DirectoryPlaceholder",
    defaultMessage: "Ex: boostmymail.com",
  },
  accountLabel: {
    id: "GSuiteForm.AccountLabel",
    defaultMessage: "Email d'un compte Administrateur GSuite :",
  },
  accountPlaceholder: {
    id: "GSuiteForm.AccountPlaceholder",
    defaultMessage: "Ex: my-admin@boostmymail.com",
  },
  formBtn: {
    id: "GSuiteForm.FormBtn",
    defaultMessage: "Valider mon paramétrage",
  },
  formDomainBtn: {
    id: "GSuiteForm.FormDomainBtn",
    defaultMessage: "Enregistrer",
  },
  cancelBtn: { id: "GSuiteForm.CancelBtn", defaultMessage: "Annuler" },
  required: { id: "GSuiteForm.Required", defaultMessage: "Champ obligatoire" },
  errorFormatMail: {
    id: "GSuiteForm.ErrorFormatMail",
    defaultMessage: "Format d'e-mail invalide",
  },
})

const GSuiteOptionsForm: React.FC<Props> = (props) => {
  const { intl, featureGSuite, domains, onValidate, onSave, onCancel } = props

  const syncgsuiteadminmail = featureGSuite.properties.find(
    (p) => p.property === "syncgsuiteadminmail",
  )
  const [adminEmail, setAdminEmail] = useState(
    syncgsuiteadminmail && syncgsuiteadminmail.value,
  )
  const [selectedDomains, setSelectedDomains] = useState(
    getSelectedDomainsFromTenantProperty(domains),
  )
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  function getSelectedDomainsFromTenantProperty(allDomains) {
    if (allDomains == null) return []
    const domainsProperty = featureGSuite.properties.find(
      (prop) => prop.property === "syncgsuitedomain",
    )
    if (domainsProperty == null || domainsProperty.value == null) return []

    const selectedDomains = domainsProperty.value.split(",")
    const options = domains.map((domain, index) => ({
      id: index,
      label: domain,
    }))
    return options.filter(
      ({ id, label }) => selectedDomains.indexOf(label) !== -1,
    )
  }

  useEffect(() => {
    if (domains.length > 0) {
      const tenantPropParameters = getSelectedDomainsFromTenantProperty(domains)
      setSelectedDomains(tenantPropParameters)
      setLoading(false)
    }
  }, [domains])

  const initForm = [
    {
      name: "syncgsuiteadminmail",
      label: intl.formatMessage(messages.emailAdminLabel),
      type: InputType.text,
      size: InputSize.S12,
      order: 4,
      value: adminEmail || "",
      rules: {
        required: intl.formatMessage(messages.required),
      },
      textAttr: {
        placeholder: intl.formatMessage(messages.emailAdminPlaceholder),
      },
    },
  ]

  const handleAdminConfigured = (form) => {
    const feature: Feature = { ...featureGSuite }
    const newProps = feature.properties.map((prop) => {
      const formValue = form.find((element) => prop.property === element.name)
      if (formValue != null)
        return { property: prop.property, value: formValue.value }
      return prop
    })

    setAdminEmail(form.find((f) => f.name === "syncgsuiteadminmail").value)
    feature.properties = newProps
    onValidate(feature)
    setLoading(true)
  }

  const handleDomainsSelected = () => {
    const isError = selectedDomains.length === 0 || adminEmail === ""
    if (isError) {
      setError(isError)
      return
    }
    const newFeature = cloneDeep(featureGSuite)

    newFeature.properties.find((p) => p.property === "syncgsuitedomain").value =
      selectedDomains.map((sd) => sd.label).join(",")

    onSave(newFeature)
  }

  return (
    <div className="gsuite-options-form">
      <div className="gsuite-options-form__title">
        <FormattedMessage
          id="GSuiteForm.DirectoryTitle"
          defaultMessage={"Connecter mon annuaire GSuite avec BoostMyMail"}
        ></FormattedMessage>
      </div>
      <GSuiteSAConfigForm
        bmmServiceAccountId={config.gsuiteServiceAccount.ServiceAccountId}
        scopes={config.gsuiteServiceAccount.Scopes}
        googleAdminUrl={config.gsuiteServiceAccount.GoogleWorkspaceAdminUrl}
      />
      <div className="google-config google-config__adminemail">
        <div className="google-config__title">
          <FormattedMessage
            id="GSuiteForm.Step4Text"
            defaultMessage="4. Renseignez l'addresse email d'un compte administrateur de votre domaine Google Workspace:"
          />
        </div>
        <GenericForm
          formId="gsuite-options-form"
          initForm={initForm}
          formValidate={handleAdminConfigured}
        />
      </div>
      <div className="gsuite-options-form__btn">
        {domains.length === 0 && !loading && (
          <Button
            type="button"
            classes="bmm-button--reverse"
            label={intl.formatMessage(messages.cancelBtn)}
            onClick={onCancel}
          ></Button>
        )}
        <Button
          type="submit"
          form="gsuite-options-form"
          label={intl.formatMessage(messages.formBtn)}
        ></Button>
      </div>

      <Loader isLoading={loading} />

      {domains.length > 0 && (
        <div className="gsuite-options__domains">
          <div className="gsuite-options__domains-status">
            <StatusIcon status={"ok"} />
            <div className="bmm__emphaze">
              {domains.length}{" "}
              <FormattedMessage
                id="GSuiteOptionsForm.DomainFounds"
                defaultMessage="domaine(s) disponible(s)"
              />
            </div>
          </div>
          <label className="gsuite-options-form__title">
            <FormattedMessage
              id="GSuiteForm.DirectoryLabel"
              defaultMessage="Nom du domaine GSuite de vos utilisateurs : *"
            />
          </label>

          <MultiSelect
            options={
              domains.map((domain, index) => ({ id: index, label: domain })) ||
              []
            }
            value={selectedDomains}
            onChange={setSelectedDomains}
            error={error}
            idProperty="id"
            displayProperty="label"
            emptyPlaceholder="Domaine(s)"
          />

          <div className="gsuite-options-form__btn">
            <Button
              type="button"
              classes="bmm-button--reverse"
              label={intl.formatMessage(messages.cancelBtn)}
              onClick={onCancel}
            ></Button>
            <Button
              label={intl.formatMessage(messages.formDomainBtn)}
              data-cy="gsuite-options__validate-domain-btn"
              onClick={() => handleDomainsSelected()}
            ></Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default injectIntl(GSuiteOptionsForm)
