import { FunctionComponent, useMemo, useState } from "react"

import { useSelector } from "react-redux"

import useSignatureData from "features/Signatures/hooks/useSignaturesData"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import SignaturesEmpty from "features/Signatures/components/SignaturesEmpty"
import SignatureCard from "features/Signatures/components/SignatureCard"
import Layout from "features/Signatures/components/SignaturesListLayout"
import NewSignatureButton from "features/Signatures/components/NewSignatureButton"

import {
  defaultFilterValue,
  defaultSortValue,
  FilterValuesType,
  processList,
  SortValuesType,
} from "features/Signatures/components/SignaturesListLayout/signaturesListLayoutUtilities"

import { featureNames } from "config/config.features"

import { LoadingStatus } from "core/CoreModels"
import Signature from "entities/Signature"
import {
  AT_LEAST_ONE_SIGNATURE_QUERY,
  signaturesSelectors,
} from "features/Signatures"
import useSignaturesCustomqueryData from "features/Signatures/hooks/useSignaturesCustomqueryData"

function searchSignaturesByName(signature: Signature) {
  return signature.Name.toLowerCase().includes(this.searchValue.toLowerCase())
}

const filterMethods = {
  activated: (signature: Signature) => signature.Activated === true,
  deactivated: (signature: Signature) => signature.Activated === false,
}

const sortMethods = {
  alphabeticalOrder: (a: Signature, b: Signature) =>
    a.Name.localeCompare(b.Name),
  creationDate: (a: Signature, b: Signature) =>
    a.Created > b.Created ? -1 : a.Created < b.Created ? 1 : 0,
  lastUpdate: (a: Signature, b: Signature) =>
    a.Modified > b.Modified ? -1 : a.Modified < b.Modified ? 1 : 0,
  weighting: (a: Signature, b: Signature) =>
    (a.Weighting > b.Weighting ? -1 : a.Weighting < b.Weighting ? 1 : 0) ||
    (a.Modified > b.Modified ? -1 : a.Modified < b.Modified ? 1 : 0),
}

const filterBySendingModeMethods = {
  newMail: (signature: Signature) => signature.NewMail === true,
  inResponse: (signature: Signature) => signature.InResponse === true,
}

const SignaturesList: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("")
  const [filter, setFilter] = useState<FilterValuesType>(defaultFilterValue)
  const [sort, setSort] = useState<SortValuesType>(defaultSortValue)

  const sendingMode = useSelector(signaturesSelectors.getSendingMode)
  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const handleSearch = (searchValue) => {
    setSearch(searchValue)
  }

  const handleChangeFilter = (filterValue) => {
    setFilter(filterValue)
  }

  const handleChangeSort = (selectedOption) => {
    setSort(selectedOption.value)
  }

  const { signatures, loadingStatus } = useSignatureData()
  const atLeastOneSignature = useSignaturesCustomqueryData(
    AT_LEAST_ONE_SIGNATURE_QUERY,
  )

  const signaturesList = useMemo(() => {
    const commonProcessors = {
      search,
      filter,
      sort,
      searchMethod: searchSignaturesByName,
      sortMethods,
      filterMethods,
    }

    const newMailInResponseProcessor = newMailInResponseFeature?.isActive
      ? { sendingMode, filterBySendingModeMethods }
      : undefined

    return processList(signatures, {
      ...commonProcessors,
      ...newMailInResponseProcessor,
    })
  }, [signatures, search, filter, sort, sendingMode])

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <Layout
        newButton={<NewSignatureButton />}
        filter={filter}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        handleChangeSort={handleChangeSort}
      >
        <Layout.LoadingSkeleton />
      </Layout>
    )

  if (
    loadingStatus === LoadingStatus.LOADED &&
    signatures.length === 0 &&
    atLeastOneSignature?.length === 0
  )
    return <SignaturesEmpty />

  if (loadingStatus === LoadingStatus.LOADED && signaturesList.length === 0)
    return (
      <Layout
        newButton={<NewSignatureButton />}
        filter={filter}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        handleChangeSort={handleChangeSort}
      >
        <Layout.EmptyResults />
      </Layout>
    )

  return (
    <Layout
      newButton={<NewSignatureButton />}
      filter={filter}
      handleSearch={handleSearch}
      handleChangeFilter={handleChangeFilter}
      handleChangeSort={handleChangeSort}
    >
      {signaturesList.map((signature) => {
        return <SignatureCard key={signature.Id} signature={signature} />
      })}
    </Layout>
  )
}

export default SignaturesList
