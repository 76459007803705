import { FunctionComponent } from "react"

import classNames from "classnames"

interface ScenarioCardTypeProps {
  isMultiple: boolean
}

export type ScenarioCardTypeType = FunctionComponent<ScenarioCardTypeProps>

const ScenarioCardType: ScenarioCardTypeType = ({ isMultiple }) => {
  return (
    <div
      className={classNames(
        "scenario-card__type",
        isMultiple ? "multiple" : "simple",
      )}
    >
      {isMultiple ? "Scénario" : "Unique"}
    </div>
  )
}

export default ScenarioCardType
