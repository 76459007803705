import React, { FunctionComponent } from "react"

const PortalIcon: FunctionComponent = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1496 11.4791C21.0967 11.285 20.0694 11.2461 19.0806 11.3562C18.4964 11.4144 18.0598 11.9258 18.0598 12.5148C18.0598 13.1621 18.5863 13.6799 19.209 13.6799C19.2283 13.6799 19.2492 13.6783 19.27 13.6766C19.2909 13.675 19.3118 13.6734 19.331 13.6734C19.6071 13.641 19.8896 13.6281 20.1721 13.6281C24.7499 13.6281 28.4802 17.3888 28.4802 22.0038V24.3923C28.4802 24.7224 28.3518 25.0331 28.1271 25.2596C27.6712 25.7192 26.8751 25.7257 26.4064 25.2532C26.1175 24.9684 26.0533 24.6318 26.0533 24.3988V22.1592C26.0533 19.2465 24.0308 16.6509 21.1801 16.1719C19.4466 15.8806 17.8222 16.3596 16.5895 17.324V7C15.7998 7.19418 15.0293 7.45309 14.291 7.76379V22.1592C14.291 22.2304 14.3102 22.5087 14.3166 22.5864C14.5221 24.703 15.8832 26.5801 17.8158 27.428C19.9859 28.3795 22.3808 27.9264 24.0501 26.4377L24.262 26.25L24.4353 26.4765C24.5573 26.6448 24.6664 26.7743 24.782 26.8843C25.4497 27.551 26.3293 27.9264 27.2667 27.9264C28.2041 27.9264 29.0902 27.5575 29.7579 26.8908C30.4192 26.2241 30.7787 25.3308 30.7787 24.3794V22.211C30.7852 17.0263 27.2025 12.4242 22.1496 11.4791ZM23.6841 22.6964C23.4145 24.1204 22.2844 25.2596 20.8784 25.5315C18.3615 26.0299 16.1786 23.8292 16.6665 21.2854C16.9362 19.8678 18.0726 18.7222 19.4787 18.4568C21.9891 17.9778 24.1656 20.1656 23.6841 22.6964ZM19.8293 30.6103C21.293 30.6103 22.731 30.2109 23.9828 29.4587C24.5669 29.106 25.3245 29.3456 25.6069 29.9913C25.8701 30.577 25.5812 31.2626 25.042 31.5821C23.4628 32.514 21.6653 33 19.8293 33C13.9811 33 9.21777 28.0609 9.21777 21.9969C9.21777 20.2796 9.60295 18.6487 10.2898 17.1976C10.8291 16.0461 12.4918 16.4322 12.4918 17.7102C12.4918 17.8833 12.4468 18.0497 12.3762 18.2028C11.8305 19.3477 11.5224 20.639 11.5224 21.9969C11.5224 26.7496 15.2522 30.6103 19.8293 30.6103Z"
        fill="#3D5AFE"
      />
    </svg>
  )
}

export default PortalIcon
