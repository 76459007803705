import { TypedResult } from "core/CoreModels"
import ServiceApi from "core/services/serviceApi"
import { Subsidiary } from "./SubsidiaryModels"

export interface CreateSubsidiaryRequest {
  name: string
  aspNetUserIds: string[]
  groupIds: string[]
  userIds: string[]
  iconUrl: string
}

export interface UpdateSubsidiaryRequest {
  id: number
  name: string
  aspNetUserIds: string[]
  groupIds: string[]
  userIds: string[]
  iconUrl: string
}

export function fetchSubsidiaries(): Promise<TypedResult<Array<Subsidiary>>> {
  return ServiceApi.request("GET", "/subsidiaries")
}

export function createSubsidiary(
  createSubsidiaryRequest: CreateSubsidiaryRequest,
): Promise<TypedResult<Subsidiary>> {
  return ServiceApi.request("POST", "/subsidiaries", {
    data: createSubsidiaryRequest,
  })
}

export function updateSubsidiaryApi(
  updateSubsidiaryRequest: UpdateSubsidiaryRequest,
): Promise<TypedResult<Subsidiary>> {
  return ServiceApi.request(
    "PUT",
    `/subsidiaries/${updateSubsidiaryRequest.id}`,
    {
      data: updateSubsidiaryRequest,
    },
  )
}

export function deleteSubsidiary(id: number): Promise<TypedResult<Subsidiary>> {
  return ServiceApi.request("DELETE", `/subsidiaries/${id}`)
}

export function updateCurrentSubsidiary(
  updateCurrentSubsidiaryRequest: number,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("PUT", `/admins/me/subsidiaries/current`, {
    data: updateCurrentSubsidiaryRequest,
  })
}

export function getCurrentSubsidiaries(): Promise<TypedResult<Subsidiary[]>> {
  return ServiceApi.request("GET", "/admins/me/subsidiaries")
}

export function getCurrentSubsidiary(): Promise<TypedResult<Subsidiary>> {
  return ServiceApi.request("GET", "/admins/me/subsidiaries/current")
}

export default {
  createSubsidiary,
  updateSubsidiary: updateSubsidiaryApi,
  fetchSubsidiaries,
  deleteSubsidiary,
  updateCurrentSubsidiary,
  getCurrentSubsidiaries,
  getCurrentSubsidiary,
}
