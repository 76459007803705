import * as React from "react"
import { useRef } from "react"
import { useIntl } from "react-intl"

interface Props {
  btnText?: string
  btnIntlId?: string
  value: string
  layout?: "horizontal" | "vertical"
  onCopied?: (value: string) => void
}

const CopyPasteField: React.FC<Props> = ({
  value,
  btnIntlId,
  btnText,
  layout = "horizontal",
  onCopied,
}) => {
  const inputRef = useRef(null)
  const intl = useIntl()

  if (btnIntlId != null) btnText = intl.formatMessage({ id: btnIntlId })

  const copyToClipboard = () => {
    inputRef.current.select()
    document.execCommand("copy")
    onCopied?.(value)
  }

  return (
    <div
      className={`copypastefield ${
        layout && layout === "vertical" && "copypastefield--vertical"
      }`}
      onClick={copyToClipboard}
    >
      <div className="copypastefiled-input__container">
        <input
          type="text"
          className="copypastefield-input"
          defaultValue={value}
          ref={inputRef}
          readOnly
        />
      </div>
      <div
        className={`copypastefield-btn ${
          layout && layout === "vertical" && "copypastefield-btn--vertical"
        }`}
        onClick={copyToClipboard}
      >
        {btnText}
      </div>
    </div>
  )
}

export default CopyPasteField
