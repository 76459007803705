import CalendarRange from "features/Scenarios/components/CampaignCalendar/CalendarView/entities/CalendarRange"
import moment from "moment"
import { Result } from "shared/core/Result"
import {
  Scenario,
  Banner,
  ScenarioFormVm,
  ScenarioDto,
  CreateScenarioDto,
} from "../ScenarioModels"
import { ScenarioVm } from "../ScenarioModels"
import { BannerMapper } from "./BannerMapper"

export class ScenarioMapper {
  static toCalendarViewModel(scenario: Scenario): CalendarRange {
    // TODO - mapping pour pouvoir utiliser le calendrier
    return {
      id: scenario.id,
      name: scenario.props.name,
      startDate: scenario.props.startDate,
      endDate: scenario.props.endDate,
      imageUrl: scenario.props.banners[0].props.imageUrl,
      linkUrl: scenario.props.banners[0].props.linkUrl,
      users: scenario.props.userIds,
      color: "#FFFFFF",
      signatures: [],
      order: 1,
    }
  }

  static toViewModel(raw: Scenario): ScenarioVm {
    return {
      id: raw.id,
      active: raw.props.activated,
      weighting: raw.props.weighting,
      name: raw.props.name,
      startDate: raw.props.startDate,
      endDate: raw.props.endDate,
      created: raw.props.created,
      banners: raw.props.banners.map(BannerMapper.toViewModel),
      userIds: raw.props.userIds || [],
      groupIds: raw.props.groupIds || [],
      signatureIds: raw.props.signatureIds || [],
      internal: raw.props.internal,
      external: raw.props.external,
      additional: raw.props.additional,
      type: raw.props.banners.length > 1 ? "multiple" : "simple",
      isMultiple: () => raw.props.banners.length > 1,
      isDefault: () => raw.props.startDate == null && raw.props.endDate == null,
    }
  }

  static toEntity(
    raw: any,
    bannerMapping?: (raw: any) => Result<Banner>,
  ): Result<Scenario> {
    const instance = Scenario.create(raw, bannerMapping)
    return instance
  }

  static toEntities(
    raws: any[],
    bannerMapping?: (raw: any) => Result<Banner>,
  ): Result<Scenario>[] {
    const allScenarios: Result<Scenario>[] = raws.map((raw) =>
      Scenario.create(raw, bannerMapping),
    )
    return allScenarios
  }

  static toDto(raw: Scenario): ScenarioDto {
    return {
      id: raw.id,
      activated: raw.props.activated,
      weighting: raw.props.weighting,
      name: raw.props.name,
      startDate: raw.props.default ? null : raw.props.startDate,
      endDate: raw.props.default ? null : raw.props.endDate,
      banners: raw.props.banners.map(BannerMapper.toDto),
      userIds: raw.props.userIds || [],
      groupIds: raw.props.groupIds || [],
      signatureIds: raw.props.signatureIds || [],
      internal: raw.props.internal,
      external: raw.props.external,
      additional: raw.props.additional,
    }
  }

  static toCreateScenarioDto(raw: Scenario): CreateScenarioDto {
    return {
      name: raw.props.name,
      startDate: raw.props.startDate,
      endDate: raw.props.endDate,
      banners: raw.props.banners.map(BannerMapper.toCreateDto),
      weighting: raw.props.weighting,
      internal: raw.props.internal,
      external: raw.props.external,
      additional: raw.props.additional,
    }
  }

  static toScenarioFormViewModel(scenario: Scenario): ScenarioFormVm {
    if (scenario == null) return null

    const scenarioVm: ScenarioFormVm = {
      id: scenario.id,
      name: scenario.props.name,
      userIds: scenario.props.userIds || [],
      groupIds: scenario.props.groupIds || [],
      signatureIds: scenario.props.signatureIds || [],
      banners: scenario.props.banners.map(BannerMapper.toBannerFormVm),
      startDate: scenario.props.startDate && moment(scenario.props.startDate),
      endDate: scenario.props.endDate && moment(scenario.props.endDate),
      weighting: scenario.props.weighting,
      type: scenario.props.banners.length > 0 ? "multiple" : "simple",
      internal: scenario.props.internal,
      external: scenario.props.external,
      default:
        scenario.props.startDate == null && scenario.props.endDate == null,
      additional: scenario.props.additional,
    }
    return scenarioVm
  }

  // Mapping pour compiler le template et checker les champs obligatoire - on compile les templates de bannières à partir des infos du formulaire
  static fromScenarioFormToDto(
    scenarioFormData: ScenarioFormVm,
  ): CreateScenarioDto | ScenarioDto {
    const scenarioEntity = ScenarioMapper.toEntity(
      scenarioFormData,
      BannerMapper.fromFormToEntity,
    )

    if (scenarioEntity.isFailure)
      throw new Error(scenarioEntity.error as string)

    const scenarioValue = scenarioEntity.getValue()

    const scenario = scenarioValue.id
      ? ScenarioMapper.toDto(scenarioValue)
      : ScenarioMapper.toCreateScenarioDto(scenarioValue)

    return scenario
  }
}
