import { FunctionComponent, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { NavLink } from "react-router-dom"
import Dialog from "@material-ui/core/Dialog"
import { MuiThemeProvider, createTheme } from "@material-ui/core"

import config from "config/config"

import { ENROLLMENT_URL } from "router/RouterConstants"

import { offersSelectors } from "features/Offers"
import { resetOfferLimitations } from "features/Offers/OffersReducer"

const messages = defineMessages({
  AdminLimitations: {
    id: "LimitationsModal.AdminLimitations",
    defaultMessage:
      "Vous avez atteint le nombre limite de compte administrateur pour votre offre.",
  },
  BannersLimitations: {
    id: "LimitationsModal.BannersLimitations",
    defaultMessage:
      "Vous avez atteint la limite de bannière active de votre offre.",
  },
  BannersLimitationsCantActivate: {
    id: "LimitationsModal.BannersLimitationsCantActivate",
    defaultMessage:
      "Vous avez atteint la limite de bannière active de votre offre. \n Cette bannière est sauvegardée mais ne sera pas activée.",
  },
  LicencesLimitations: {
    id: "LimitationsModal.LicencesLimitations",
    defaultMessage:
      "Vous avez atteint la limite de licences actives pour votre offre.",
  },
  SignatureLimitations: {
    id: "LimitationsModal.SignatureLimitations",
    defaultMessage: "Vous avez atteint la limite de signatures actives.",
  },
  SignatureLimitationsCantActivate: {
    id: "LimitationsModal.SignatureLimitationsCantActivate",
    defaultMessage:
      "La période d'essai autorise une seule signature mail active.",
  },
})

const theme = createTheme({})

const LimitationsModal: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const limitations = useSelector(offersSelectors.getOfferLimitations)
  const currentOffer = useSelector(offersSelectors.getCurrentOffer)

  const handleClose = () => {
    setOpen(false)
    dispatch(resetOfferLimitations())
  }

  useEffect(() => {
    if (limitations !== null && !open) setOpen(true)
  }, [limitations])

  let intlLimitations = null
  if (limitations !== null)
    intlLimitations = formatMessage({
      id: `LimitationsModal.${limitations}`,
      defaultMessage: messages[limitations]?.defaultMessage || "",
    })

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        className="confirm-modal"
        data-cy="limitations-modal"
        open={open}
        onClose={handleClose}
      >
        <div className="modal__header">
          <div className="modal__header-title"></div>
          <div className="modal__header-close">
            <a onClick={handleClose}>
              <i className=" material-icons">close</i>
            </a>
          </div>
        </div>
        <div className="limitations-modal__image-container">
          <img
            src={config.cdnUrl + "/images/bmm/icon-warning.svg"}
            className="limitations-modal__image"
            alt=""
          />
        </div>
        <div className="limitations-modal__title">
          {intlLimitations || limitations}
        </div>
        <div className="limitations-modal__current">
          <div>
            <FormattedMessage
              id="LimitationsModal.AcualOffer"
              defaultMessage="Vous disposez actuellement de l'offre "
            />
            <b> {currentOffer?.Name} </b>
          </div>
          <div className="bmm-button__container bmm-button__container--centered">
            <NavLink onClick={handleClose} to={ENROLLMENT_URL}>
              <div className="bmm-button">
                <div>
                  <span className="bmm-button__intitule">
                    Modifier mon offre
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </Dialog>
    </MuiThemeProvider>
  )
}

export default LimitationsModal
