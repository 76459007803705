/**
 * Billings REDUCER
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { LoadingStatus } from "core/CoreModels"

import { Billing, BillingAddress } from "./BillingsModels"

export interface BillingState {
  clientId: number
  allBillings: Array<Billing>
  address: BillingAddress
  addressError: boolean
  loadingStatus: LoadingStatus
  addressLoadingStatus: LoadingStatus
}

export interface BillingState {
  clientId: number
  allBillings: Array<Billing>
  address: BillingAddress
  addressError: boolean
  loadingStatus: LoadingStatus
  addressLoadingStatus: LoadingStatus
}

const initialState: BillingState = {
  clientId: -1,
  allBillings: [],
  address: {
    name: "",
    part1: "",
    part2: "",
    zip: "",
    town: "",
    countryCode: "",
    siret: "",
    phone: "",
  },
  addressError: true,
  loadingStatus: LoadingStatus.NOT_STARTED,
  addressLoadingStatus: LoadingStatus.NOT_STARTED,
}

const billingsReducer = createSlice({
  name: "billings",
  initialState,
  reducers: {
    fetchBillings(state) {
      state.loadingStatus = LoadingStatus.PENDING
    },
    fetchBillingsSuccess(state, action: PayloadAction<Array<Billing>>) {
      state.allBillings = action.payload
      state.loadingStatus = LoadingStatus.LOADED
    },
    fetchBillingsFailure(state) {
      state.loadingStatus = LoadingStatus.ERROR
    },
    fetchBillingAddress(state) {
      state.addressLoadingStatus = LoadingStatus.PENDING
    },
    fetchBillingAddressSuccess(state, action: PayloadAction<BillingAddress>) {
      state.address = action.payload
      state.addressLoadingStatus = LoadingStatus.LOADED
    },
    fetchBillingAddressFailure(state) {
      state.addressLoadingStatus = LoadingStatus.ERROR
    },
    setBillingAddress: (state, action: PayloadAction<BillingAddress>) => {
      state.address = action.payload
    },
    updateBillingAddress: (state, action: PayloadAction<BillingAddress>) => {
      state.address = action.payload
    },
    updateBillingAddressSuccess(state, action: PayloadAction<BillingAddress>) {
      state.address = action.payload
      state.addressError = false
    },
    updateBillingAddressFailure(state) {
      state.addressError = true
    },
  },
})

export const {
  fetchBillings,
  fetchBillingsSuccess,
  fetchBillingsFailure,
  fetchBillingAddress,
  fetchBillingAddressSuccess,
  fetchBillingAddressFailure,
  updateBillingAddress,
  updateBillingAddressSuccess,
  updateBillingAddressFailure,
} = billingsReducer.actions

export default billingsReducer.reducer
