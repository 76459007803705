import { FunctionComponent } from "react"

interface Props {
  title: string
}

const TrackingCounter: FunctionComponent<Props> = ({ title }) => {
  return (
    <div className="tracking-counter">
      <div className="tracking-counter__text">{title}</div>
    </div>
  )
}

export default TrackingCounter
