import React, { RefObject } from "react"

import { useSelector } from "react-redux"

import classnames from "classnames"

import { FormattedMessage } from "react-intl"

import { iconStroke, iconUser } from "../../images"

import Layout from "features/Scenarios/components/ScenarioCardLayout"

import useScenarioAffectedDatas from "features/Scenarios/hooks/useScenarioAffectedDatas"

import { scenarioSelectors } from "features/Scenarios"

import ScenarioMetric from "entities/ScenarioMetric"

interface Props {
  tracking: ScenarioMetric
}

const TrackingBanner: React.FC<Props> = ({ tracking }) => {
  const isMultiple = tracking.banners.length > 1
  const tagLibelle = (() => {
    let libelle = isMultiple ? "multiple" : "unique"
    if (tracking.default) libelle += " par défaut"
    return libelle
  })()

  const scenarioAssignedUsers = useSelector(
    scenarioSelectors.getUsersCount(tracking.id),
  )

  const { scenarioRef } = useScenarioAffectedDatas(tracking.id)

  return (
    <div
      className="tracking-banner"
      ref={scenarioRef as RefObject<HTMLDivElement>}
    >
      <div className="tracking-banner__image-container">
        <Layout.Images images={tracking.banners} />
      </div>
      <div className="tracking-banner__informations">
        <div className="tracking-banner__header">
          <div className="tracking-banner__title">{tracking.name}</div>
          <div
            className={classnames("tracking-banner__tag", {
              "tracking-banner__tag--multiple": isMultiple,
            })}
          >
            {tagLibelle}
          </div>
        </div>
        <div className="tracking-banner__metrics">
          <div className="tracking-banner__metric">
            <div className="tracking-banner__metric-top">
              <div className="tracking-banner__metric__icon">
                <img alt="tracking-banner-user" src={iconUser} />
              </div>
              <div className="tracking-banner__metric-data">
                {scenarioAssignedUsers}
              </div>
            </div>
            <div className="tracking-banner__metric__description">
              <FormattedMessage
                id="TrackingBanner.UsersLabel"
                defaultMessage="Utilisateurs"
              />
            </div>
          </div>

          <div className="tracking-banner__metric__separator"></div>

          <div className="tracking-banner__metric">
            <div className="tracking-banner__metric-top">
              <div className="tracking-banner__metric__icon">
                <img
                  alt="tracking banner generated clics"
                  src={iconStroke}
                  className="icon-cursor"
                />
              </div>
              <div className="tracking-banner__metric-data">
                {tracking.clickCount}
              </div>
            </div>
            <div className="tracking-banner__metric__description">
              <FormattedMessage
                id="TrackingBanner.NbClicLabel"
                defaultMessage="Nombre de clics"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrackingBanner
