import {
  put,
  call,
  takeEvery,
  select,
  takeLatest,
  all,
  take,
} from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"

import {
  Group,
  GroupDto,
  GroupDtoWithMembersIds,
  GroupPayloadDto,
  PartialGroup,
} from "features/Groups/GroupsModels"

import { groupsSelectors } from "features/Groups"

import { fetchAllUsers, resetDynamicUsers } from "features/Users/UsersReducers"

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"

import { withSagaErrorHandler } from "features/Errors"

import {
  createGroupApi,
  deleteGroupApi,
  fetchGroupApi,
  fetchGroupsApi,
  fetchPartialGroups,
  updateGroupApi,
  updateGroupLicencesApi,
} from "features/Groups/GroupsApi"
import { PaginatedResult, TypedResult } from "core/CoreModels"

import {
  FetchPartialGroupsActionPayload,
  fetchPartialGroupsSuccess,
  fetchAllGroups as fetchAllGroupsAction,
  fetchPartialGroups as fetchPartialGroupsAction,
  reloadPartialGroups as reloadPartialGroupsAction,
  fetchAllGroupsSuccess,
  createGroupSuccess,
  editGroupSuccess,
  deleteGroupSuccess,
  upsertGroupLicenceSuccess,
  fetchGroupSuccess,
  fetchGroup,
  resetEditingGroup,
  savingEditingGroup,
  savingEditingGroupSuccess,
  editPartialGroupSuccess,
  fetchAssignedSignaturesToEditingGroupSuccess,
  fetchAssignedSignaturesToEditingGroup,
} from "./GroupsReducer"
import {
  createGroupAction,
  deleteGroupAction,
  upsertDynamicGroupAction,
  editGroupAction,
  upsertLicenceGroupAction,
} from "./GroupsActions"
import { fetchUserIdsByQuery, fetchUsers } from "features/Users/UsersApi"
import { UserDto } from "features/Users/UserModels"
import { signaturesSelectors } from "features/Signatures"
import {
  fetchSignatures,
  loadMultipleAffectedGroups,
  loadMultipleAffectedGroupsSuccess,
} from "features/Signatures/SignaturesReducer"
import { setOfferLimitations } from "features/Offers/OffersReducer"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* getAllGroupsAsync() {
  const result: TypedResult<GroupDto[]> = yield call(fetchGroupsApi)
  if (!result.success) throw new Error(result.errorMessage)

  const groupsJson = result.result
  const groups = groupsJson.map((groupJson) => {
    return new Group(groupJson)
  })

  yield put(fetchAllGroupsSuccess(groups))
}

function* createGroupAsync(action: PayloadAction<GroupPayloadDto>) {
  yield put(savingEditingGroup())

  const result: TypedResult<GroupDto> = yield call(
    createGroupApi,
    action.payload,
  )

  if (!result.success) {
    const message =
      JSON.parse(result.errorMessage)?.errorMessage ||
      "Erreur lors de la creation du groupe"
    yield put(addNotification(new Notification(message, "INFO")))
    throw new Error(message)
  }

  const group = new Group(result.result)
  yield all([
    put(createGroupSuccess(group)),
    put(
      addNotification(
        new Notification("Groupe ajouté avec succès.", "SUCCESS"),
      ),
    ),
    put(reloadPartialGroupsAction()),
  ])

  yield put(savingEditingGroupSuccess())
  yield put(resetEditingGroup())
  yield put(resetDynamicUsers())
}

function* upsertDynamicGroupAsync(action: PayloadAction<GroupPayloadDto>) {
  const responseUserIds: TypedResult<number[]> = yield call(
    fetchUserIdsByQuery,
    action.payload.Query,
  )
  const responseUsers: PaginatedResult<UserDto[]> = yield call(fetchUsers)
  const members = responseUserIds.result.map(
    (id) => responseUsers.result.find((us) => us.id == id).id,
  )
  action.payload.Members = members
  yield call(action.payload.id ? updateGroupAsync : createGroupAsync, action)
}

function* updateGroupAsync(action: PayloadAction<GroupPayloadDto>) {
  yield put(savingEditingGroup())

  const result = yield call(updateGroupApi, action.payload.id, action.payload)

  if (!result.success)
    throw new Error(`Erreur lors de la modification du groupe`)

  const group = new Group({ ...result.result })
  const partialGroup = new PartialGroup({ ...result.result })
  yield all([
    put(editGroupSuccess(group)),
    put(editPartialGroupSuccess(partialGroup)),
    put(
      addNotification(
        new Notification("Groupe modifié avec succès.", "SUCCESS"),
      ),
    ),
  ])

  yield put(savingEditingGroupSuccess())
  yield put(resetEditingGroup())
  yield put(resetDynamicUsers())
}

function* deleteGroupAsync(action: PayloadAction<number>) {
  const result = yield call(deleteGroupApi, action.payload)

  if (!result.success)
    throw new Error(`Erreur lors de la suppression du groupe`)

  yield all([
    put(fetchAllUsers()),
    put(deleteGroupSuccess(action.payload)),
    put(reloadPartialGroupsAction()),
    put(fetchSignatures("all")),
  ])
}

/**
 * Créer ou met à jour la licence d'un Groupe
 * @param action
 */
function* upsertGroupLicenceAsync(
  action: PayloadAction<{ groupId: number; activated: boolean }>,
) {
  const result: TypedResult<GroupDto> = yield call(
    updateGroupLicencesApi,
    action.payload,
  )

  if (!result.success)
    throw new Error(`Erreur lors de la modification du groupe`)
  if (result.limitationsReach) {
    yield put(setOfferLimitations("LicencesLimitations"))
  }
  const partialGroup = new PartialGroup(result.result)
  const group = new Group(result.result)

  yield all([
    put(fetchAllUsers()),
    put(upsertGroupLicenceSuccess({ partialGroup, group })),
    put(fetchSignatures("all")),
  ])
}

function* fetchPartialGroupsAsync(
  action: PayloadAction<FetchPartialGroupsActionPayload>,
) {
  const { pageSize, pageNumber, query, orderBy } = action.payload
  const response: PaginatedResult<GroupDtoWithMembersIds[]> = yield call(
    fetchPartialGroups,
    {
      pageSize,
      pageNumber,
      query,
      orderBy,
    },
  )

  if (!response.success) throw new Error(response.errorMessage)

  const partialGroups = response.result.map(
    (partialGroup) => new PartialGroup(partialGroup),
  )

  yield put(
    fetchPartialGroupsSuccess({
      partialGroups,
      currentPage: response.currentPage,
      lastPage: response.lastPage,
      totalCount: response.totalCount,
      pageSize: response.pageSize,
    }),
  )
}

function* reloadPartialGroupsAsync() {
  const {
    currentPage: pageNumber,
    pageSize,
    orderBy,
    search: query,
  } = yield select(groupsSelectors.getPartialGroupsPaginationData)

  const actionPayload = {
    pageSize,
    pageNumber,
    query,
    orderBy,
  }

  const createAction = {
    payload: actionPayload,
    type: fetchAllUsers.type,
  } as PayloadAction<FetchPartialGroupsActionPayload>

  yield call(fetchPartialGroupsAsync, createAction)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* fetchGroupAsync(action: PayloadAction<number>) {
  const result: TypedResult<GroupDtoWithMembersIds> = yield call(
    fetchGroupApi,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const group = new Group(result.result)
  yield put(fetchGroupSuccess(group))
}

function* fetchGroupAssignationsToSignatures() {
  const activatedSignaturesIds = yield select(
    signaturesSelectors.getActivatedSignaturesIds,
  )

  if (activatedSignaturesIds.length > 0) {
    yield put(loadMultipleAffectedGroups(activatedSignaturesIds))

    yield take(loadMultipleAffectedGroupsSuccess.type)
  }

  yield put(fetchAssignedSignaturesToEditingGroupSuccess())
}

export const groupsWatchers = [
  takeEvery(fetchAllGroupsAction.type, getAllGroupsAsync),
  takeEvery(createGroupAction.type, withSagaErrorHandler(createGroupAsync)),
  takeEvery(editGroupAction.type, withSagaErrorHandler(updateGroupAsync)),
  takeEvery(
    upsertDynamicGroupAction.type,
    withSagaErrorHandler(upsertDynamicGroupAsync),
  ),
  takeEvery(deleteGroupAction.type, deleteGroupAsync),
  takeEvery(upsertLicenceGroupAction.type, upsertGroupLicenceAsync),
  takeLatest(fetchPartialGroupsAction.type, fetchPartialGroupsAsync),
  takeLatest(reloadPartialGroupsAction.type, reloadPartialGroupsAsync),
  takeLatest(fetchGroup.type, withSagaErrorHandler(fetchGroupAsync)),
  takeLatest(
    fetchAssignedSignaturesToEditingGroup.type,
    withSagaErrorHandler(fetchGroupAssignationsToSignatures),
  ),
]
