import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

interface ModalSecondaryActionButtonProps {
  children: ReactNode
  className?: string
  type?: "button" | "reset" | "submit"
}

export type ModalSecondaryActionButtonType = FunctionComponent<
  ModalSecondaryActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>

const ModalSecondaryActionButton: ModalSecondaryActionButtonType = ({
  children,
  className,
  type = "button",
  ...others
}) => {
  return (
    <button
      className={classNames("modal-service__actions--secondary", className)}
      type={type}
      {...others}
    >
      {children}
    </button>
  )
}

export default ModalSecondaryActionButton
