import ServiceApi from "core/services/serviceApi"

import { TypedResult } from "core/CoreModels"
import { UserDto } from "features/Users/UserModels"
import {
  CreateScenarioDto,
  ScenarioAssignationsDto,
  ScenarioCustomQueryDto,
  ScenarioDto,
} from "./ScenarioModels"
import { RequestParams } from "core/services/requestBuilderService"

export function fetchScenarios(
  params: RequestParams,
): Promise<TypedResult<ScenarioCustomQueryDto[]>> {
  return ServiceApi.request("GET", "/scenarios", { params })
}

export function fetchScenariosApi(
  diffusionMode: "internal" | "external" | null,
): Promise<TypedResult<ScenarioDto[]>> {
  const query = diffusionMode && `${diffusionMode} eq true`

  return ServiceApi.request("GET", "/scenarios", {
    params: { q: query },
  })
}

export function fetchScenarioAssignations(
  scenarioId: string,
): Promise<TypedResult<ScenarioAssignationsDto>> {
  return ServiceApi.request("GET", `/scenarios/${scenarioId}/assignations`)
}

/**
 * Creation d'un scénario avec ses bannières
 */
export function createScenario(
  createScenarioRequest: CreateScenarioDto,
): Promise<TypedResult<ScenarioDto>> {
  return ServiceApi.request("POST", "/scenarios", {
    data: createScenarioRequest,
  })
}

/**
 * Mets à jour le scénario du tenant
 */
export function updateScenario(
  scenario: ScenarioDto,
): Promise<TypedResult<ScenarioDto>> {
  return ServiceApi.request("PUT", `scenarios/${scenario.id}`, {
    data: scenario,
  })
}

/**
 * Supprime le scénario du tenant
 */
export function deleteScenario(
  scenarioId: number,
): Promise<TypedResult<ScenarioDto>> {
  return ServiceApi.request("DELETE", `scenarios/${scenarioId}`)
}

export function activateScenario(
  scenarioId: number,
  activate: boolean,
): Promise<TypedResult<ScenarioDto>> {
  return ServiceApi.request("PUT", `/scenarios/${scenarioId}/activated`, {
    data: activate,
  })
}

/**
 * Mets à jour toutes les associations du scenario avec les utilisateurs
 */
export function associateUsers(
  scenarioId: number,
  userIds: number[],
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("PUT", `scenarios/${scenarioId}/users`, {
    data: userIds,
  })
}

/**
 * Mets à jour toutes les associations du scenario avec les groupes
 */
export function associateGroups(
  scenarioId: number,
  groupIds: number[],
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("PUT", `scenarios/${scenarioId}/groups`, {
    data: groupIds,
  })
}

/**
 * Mets à jour toutes les associations du scenario avec les signatures
 */
export function associateSignatures(
  scenarioId: number,
  signatureIds: number[],
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("PUT", `scenarios/${scenarioId}/signatures`, {
    data: signatureIds,
  })
}
