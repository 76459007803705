import { FunctionComponent, useState } from "react"

import { useDispatch } from "react-redux"

import UnderlinedInputField from "components/UnderlinedInputField"

import { updateAccountInformations } from "features/Accounts/AccountsReducer"

import AccountInformationsFormSubmitButton from "./AccountInformationsFormSubmitButton"

import { AccountInformations } from "features/Accounts/AccountsModels"

interface AccountInformationsFormFullNameStepProps {
  nextRegisterStep: number
  label: string
  firstnamePlaceholder: string
  lastnamePlaceholder: string
  buttonLabel: string
  submitButtonLocker: (firstname: string, lastname: string) => boolean
  payload: (
    firstname: string,
    lastname: string,
    stepRegister: number,
  ) => AccountInformations
}

const AccountInformationsFormFullNameStep: FunctionComponent<
  AccountInformationsFormFullNameStepProps
> = ({
  nextRegisterStep,
  label,
  firstnamePlaceholder,
  lastnamePlaceholder,
  buttonLabel,
  submitButtonLocker,
  payload,
}) => {
  const dispatch = useDispatch()
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")

  const handleSubmit = () => {
    dispatch(
      updateAccountInformations(payload(firstname, lastname, nextRegisterStep)),
    )
  }

  return (
    <>
      <p className="account-informations-form__step--label">{label}</p>
      <div className="account-informations-form__full-name--container">
        <UnderlinedInputField
          placeholder={firstnamePlaceholder}
          variant="xl"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          autoFocus
        />
        <UnderlinedInputField
          placeholder={lastnamePlaceholder.toUpperCase()}
          variant="xl"
          value={lastname}
          onChange={(e) => setLastname(e.target.value.toUpperCase())}
        />
      </div>

      <AccountInformationsFormSubmitButton
        disabled={submitButtonLocker(firstname, lastname)}
        onClick={handleSubmit}
      >
        {buttonLabel}
      </AccountInformationsFormSubmitButton>
    </>
  )
}

export default AccountInformationsFormFullNameStep
