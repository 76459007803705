import React from "react"
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from "react-intl"
import Button from "components/Button"
import Modal from "components/ModalLegacy"
import { SizeString } from "components/ModalLegacy/Modal"

import StatusIcon from "components/StatusIcon"
import ExpandList from "components/ExpandList"

import { ImportReport } from "features/Users/UserModels"

interface Props {
  intl: IntlShape
  importReport: ImportReport
  open: boolean
  close: () => void
}

const messages = defineMessages({
  title: {
    id: "ImportExcelReport.Title",
    defaultMessage: "Rapport de l'import Excel",
  },
  newUsers: {
    id: "ImportExcelReport.NewUsers",
    defaultMessage: "Nouveaux utilisateurs : ",
  },
  updatedUsers: {
    id: "ImportExcelReport.UpdatedUsers",
    defaultMessage: "Utilisateurs mis à jour : ",
  },
  deletedUsers: {
    id: "ImportExcelReport.DeletedUsers",
    defaultMessage: "Utilisateurs supprimés : ",
  },
  disabledDueToExcessLicenses: {
    id: "ImportExcelReport.DisabledDueToExcessLicenses",
    defaultMessage:
      "Utilisateurs désactivés en raison d'un nombre de licences insuffisant : ",
  },
  partiallyUpdatedUsers: {
    id: "ImportExcelReport.PartiallyUpdatedUsers",
    defaultMessage: "Utilisateurs partiellement mis à jour : ",
  },
  newGroups: {
    id: "ImportExcelReport.NewGroups",
    defaultMessage: "Nouveaux groupes : ",
  },
  updatedGroups: {
    id: "ImportExcelReport.UpdatedGroups",
    defaultMessage: "Groupes mis à jour : ",
  },
  deletedGroups: {
    id: "ImportExcelReport.DeletedGroups",
    defaultMessage: "Groupes supprimés : ",
  },
  // Messages venant du rapport
  duplicateUser: {
    id: "ImportExcel.DuplicateUser",
    defaultMessage: "?#param#? (utilisateur en doublon)",
  },
  invalidEmail: {
    id: "ImportExcel.InvalidEmail",
    defaultMessage: "Email invalide (ligne ?#param#?)",
  },
  nbSheets: {
    id: "ImportExcel.NbSheets",
    defaultMessage: "Nombre de feuilles incorrecte",
  },
  noUsernameProperty: {
    id: "ImportExcel.NoUsernameProperty",
    defaultMessage: "Les propriétés doivent contenir un champ '?#param#?'",
  },
  noUsers: {
    id: "ImportExcel.NoUsers",
    defaultMessage: "Aucun utilisateur à importer",
  },
})

const ImportExcelReport: React.FC<Props> = (props) => {
  const { intl, importReport, open, close } = props

  const status = importReport && importReport.getStatus()

  const renderMessage = (string, param) => {
    let message = intl.formatMessage({ id: string })
    if (param) message = message.replace("?#param#?", param)

    return message
  }

  return (
    <div className="import-excel-report">
      {importReport && (
        <Modal
          open={open}
          close={close}
          maxWidth={SizeString.SM}
          title={intl.formatMessage(messages.title)}
          dialogContent={
            <div className="import-excel-report__content">
              <div className="import-excel-report__icon">
                <StatusIcon status={status} />
              </div>
              <div>
                {status === "error" && (
                  <div className="import-excel-report__error">
                    {importReport.Error[0] &&
                      renderMessage(
                        importReport.Error[0].message,
                        importReport.Error[0].param,
                      )}
                  </div>
                )}
                {status !== "error" && (
                  <React.Fragment>
                    {status === "warning" && (
                      <div className="import-excel-report__warnings">
                        {importReport.UsersFailed.length > 0 && (
                          <div className="import-excel-report__warning">
                            <FormattedMessage
                              id="ImportExcelReport.UsersFailed"
                              defaultMessage="L'import des utilisateurs suivants a échoué : "
                            />
                            <div className="import-excel-report__warning--list">
                              {importReport.UsersFailed.map((fail, index) => (
                                <span key={index}>
                                  {renderMessage(fail.message, fail.param)}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                        {importReport.PartialUsersFailed.length > 0 && (
                          <div className="import-excel-report__warning">
                            <FormattedMessage
                              id="ImportExcelReport.PartialUsersFailed"
                              defaultMessage="Les utilisateurs suivants n'ont pas été mis à jour : "
                            />
                            <div className="import-excel-report__warning--list">
                              {importReport.PartialUsersFailed.map(
                                (fail, index) => (
                                  <span
                                    key={index}
                                  >{`${fail.param} : ${fail.message}`}</span>
                                ),
                              )}
                            </div>
                          </div>
                        )}
                        {importReport.NotUpdatedGroups.length > 0 && (
                          <div className="import-excel-report__warning">
                            <FormattedMessage
                              id="ImportExcelReport.NotUpdatedGroups"
                              defaultMessage="Les groupes suivants n'ont pas été mis à jour : "
                            />
                            <div className="import-excel-report__warning--list">
                              {importReport.NotUpdatedGroups.map(
                                (item, index) => (
                                  <span key={index}>{item.Name}</span>
                                ),
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {status !== "ok" && (
                      <div className="import-excel-report__divider"></div>
                    )}
                    <div className="import-excel-report__infos">
                      <ExpandList
                        list={importReport.NewUsers}
                        label={intl.formatMessage(messages.newUsers)}
                        displayProp="Username"
                        showTotal
                      />
                      <ExpandList
                        list={importReport.UpdatedUsers}
                        label={intl.formatMessage(messages.updatedUsers)}
                        displayProp="Username"
                        showTotal
                      />
                      <ExpandList
                        list={importReport.DeletedUsers}
                        label={intl.formatMessage(messages.deletedUsers)}
                        displayProp="Username"
                        showTotal
                      />
                      <ExpandList
                        list={importReport.DisabledDueToExcessLicenses}
                        label={intl.formatMessage(
                          messages.disabledDueToExcessLicenses,
                        )}
                        displayProp="Username"
                        showTotal
                      />
                      <ExpandList
                        list={importReport.PartiallyUpdatedUsers}
                        label={intl.formatMessage(
                          messages.partiallyUpdatedUsers,
                        )}
                        displayProp="Username"
                        info={renderMessage(
                          "ImportExcelReport.PropertiesNotUpdated",
                          importReport.WarningPropertiesUpdated,
                        )}
                        showTotal
                      />
                      <ExpandList
                        list={importReport.NewGroups}
                        label={intl.formatMessage(messages.newGroups)}
                        displayProp="Name"
                        showTotal
                      />
                      <ExpandList
                        list={importReport.UpdatedGroups}
                        label={intl.formatMessage(messages.updatedGroups)}
                        displayProp="Name"
                        showTotal
                      />
                      <ExpandList
                        list={importReport.DeletedGroups}
                        label={intl.formatMessage(messages.deletedGroups)}
                        displayProp="Name"
                        showTotal
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          }
          dialogActions={<Button label="OK" onClick={close}></Button>}
        />
      )}
    </div>
  )
}

export default injectIntl(ImportExcelReport)
