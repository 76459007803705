export const PRIMARY_GRAPH_COLOR = "#3d5afe"

export const GRAPH_COLORS = [
  PRIMARY_GRAPH_COLOR,
  "#2AD3C2",
  "#E91E63",
  "#FFC107",
  "#FF8F27",
  "#CC5AEA",
  "#3B4DAD",
]

export const PRIMARY_GRAPH_LIGHT_COLORS = "#EBF2FF"

export const GRAPH_LIGHT_COLORS = [
  PRIMARY_GRAPH_LIGHT_COLORS,
  "#E3FFFC",
  "#FCDDE8",
  "#FFF6DA",
  "#FFF0E4",
  "#F9E3FF",
  "#E3E7FF",
]

export const MINIMUM_GRAPH_DATA_LENGTH = 2

export const GRAPH_TICK_VALUES_SET = new Set([
  15, 30, 60, 90, 180, 360, 720, 1080,
])

export const GRAPH_TICK_VALUES = {
  small: {
    15: "every 2 day",
    30: "every 4 day",
    60: "every week",
    90: "every 2 week",
    180: "every month",
    360: "every 2 month",
    720: "every 4 month",
    1080: "every 6 month",
  },
  large: {
    15: "every day",
    30: "every 2 day",
    60: "every 2 day",
    90: "every 4 day",
    180: "every week",
    360: "every 2 week",
    720: "every month",
    1080: "every 2 month",
  },
}

export const GRAPH_WIDTH_BREAKPOINT = 320

export const TRACKING_DATE_PICKER_PAST_LOCK = {
  amount: 1,
  unit: "year",
}
