import { FunctionComponent, ReactNode } from "react"

interface ScenarioCardControlsProps {
  children: ReactNode
}

export type ScenarioCardControlsType =
  FunctionComponent<ScenarioCardControlsProps>

const ScenarioCardControls: ScenarioCardControlsType = ({ children }) => {
  return <div className="scenario-card__controls">{children}</div>
}

export default ScenarioCardControls
