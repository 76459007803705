import { FunctionComponent } from "react"

import { useNavigate } from "react-router-dom"

import campaignIcon from "features/Scenarios/images/icon-campaign-simple.svg"
import scenarioIcon from "features/Scenarios/images/icon-campaign-multiple.svg"

import AddIcon from "@material-ui/icons/Add"

import MenuButton from "components/MenuButton"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import ScenarioNavButton from "features/Scenarios/components/ScenarioNavButton"
import InternalExternalPills from "features/Scenarios/components/InternalExternalPills"

import { featureNames } from "config/config.features"

import { CAMPAIGNS_URL } from "router/RouterConstants"

const ScenariosListLayoutHeader: FunctionComponent = () => {
  const navigate = useNavigate()
  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)

  const initNewCampaign = () => {
    navigate(`${CAMPAIGNS_URL}/edit/step/1`)
  }

  const initNewScenario = () => {
    navigate(`${CAMPAIGNS_URL}/edit/step/1?mode=multiple`)
  }

  const addMenu = [
    {
      label: "Nouvelle Campagne",
      icon: <img src={campaignIcon} />,
      onClick: initNewCampaign,
    },
    {
      label: "Créer un scénario",
      icon: <img src={scenarioIcon} />,
      onClick: initNewScenario,
    },
  ]

  return (
    <div className="scenarios-list-layout__header">
      <div className="scenarios-list-layout__header--container">
        <div className="scenarios-list-layout__header--pills">
          {internalExternalFeature?.isActive && <InternalExternalPills />}
        </div>
        <div className="scenarios-list-layout__header--menu">
          <ScenarioNavButton />
          <MenuButton choices={addMenu} icon={<AddIcon />} fab disableRipple />
        </div>
      </div>
    </div>
  )
}

export default ScenariosListLayoutHeader
