import React, { FunctionComponent, useEffect } from "react"

import InputText from "components/TextBox/InputText"

interface EditableTechnicalNameProps {
  initialValue: string
  id: string
  disabled: boolean
  onEditTechnicalName: (technicalName: string, id: string) => void
  onEditing: () => void
}

const EditableTechnicalName: FunctionComponent<EditableTechnicalNameProps> = ({
  initialValue,
  id,
  disabled,
  onEditTechnicalName,
  onEditing,
}) => {
  const [value, setValue] = React.useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onChange = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement
    setValue(value)
    onEditing()
  }

  const onBlur = () => {
    if (value !== initialValue) {
      onEditTechnicalName(value, id)
    }
  }

  return (
    <InputText
      value={value}
      className="sync-properties-grid__cell--input"
      placeholder="[Dénomination]"
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}

export default EditableTechnicalName
