import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react"

import AssignationsPanel from "../AssignationsPanel"

import { LoadingStatus } from "core/CoreModels"
import { AssignationsPanelSingleScenario } from "../AssignationsPanel"

interface AssignationsPanelScenariosProps {
  displayedIndex: number | null
  scenarios: AssignationsPanelSingleScenario[]
  loadingStatus: LoadingStatus
  previous: () => void
  next: () => void
}

export type AssignationsPanelScenariosType =
  FunctionComponent<AssignationsPanelScenariosProps>

const AssignationsPanelScenarios: AssignationsPanelScenariosType = ({
  displayedIndex,
  scenarios,
  loadingStatus,
  previous,
  next,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const bannerRef = useRef<HTMLDivElement | null>(null)
  const [scale, setScale] = useState(1)

  const displayComponentOrSkeletonLoading = useMemo(
    () => displayedIndex !== null && loadingStatus === LoadingStatus.LOADED,

    [displayedIndex, loadingStatus],
  )

  const getScale = (
    currentSignatureRef: HTMLDivElement | null,
    currentContainerRef: HTMLDivElement | null,
  ) => {
    if (currentSignatureRef === null || currentContainerRef === null) return 1
    const signatureHeight = currentSignatureRef.getBoundingClientRect().height
    const containerHeight = currentContainerRef.getBoundingClientRect().height
    const scale = containerHeight / signatureHeight
    return scale >= 1 ? 1 : scale
  }

  useEffect(() => {
    setScale(getScale(bannerRef.current, containerRef.current))
  }, [bannerRef.current, containerRef.current, displayedIndex, loadingStatus])

  const preview = scenarios[displayedIndex]?.template ? (
    <div
      className="assignations-panel__scenarios--inner-html"
      dangerouslySetInnerHTML={{
        __html: scenarios[displayedIndex].template,
      }}
      ref={bannerRef}
      style={{
        zoom: scale,
      }}
    />
  ) : (
    <AssignationsPanel.Carousel images={scenarios[displayedIndex]?.banners} />
  )

  return (
    <div className="assignations-panel__scenarios">
      <div className="assignations-panel__scenarios--header">
        {displayComponentOrSkeletonLoading ? (
          <p className="assignations-panel__scenarios--name">
            {scenarios[displayedIndex].name}
          </p>
        ) : (
          <div className="skeleton skeleton__title" />
        )}
      </div>

      <div className="assignations-panel__scenarios--content">
        {displayComponentOrSkeletonLoading ? (
          <div
            ref={containerRef}
            className="assignations-panel__scenarios--container"
          >
            {preview}
          </div>
        ) : (
          <div ref={bannerRef} className="skeleton skeleton__preview" />
        )}
      </div>
      <AssignationsPanel.Data>
        <AssignationsPanel.Navigation
          isLoading={displayComponentOrSkeletonLoading}
          displayedIndex={displayedIndex}
          total={scenarios.length}
          previous={previous}
          next={next}
        />
      </AssignationsPanel.Data>
    </div>
  )
}

export default AssignationsPanelScenarios
