import { FunctionComponent, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { isCode } from "utils/I18nHelpers"
import StringHelper from "utils/StringHelper"

import MouseTooltip from "components/MouseTooltip"

import logoWindowsBubble from "features/Parameters/assets/logo_windows_bubble.webp"

import { ReactComponent as CopyIcon } from "assets/icon_copy.svg"

import { DEFAULT_MSI_VERSION_NAME } from "features/Parameters/ParametersConstants"

interface InstallerWindowsMsiProps {
  appLink: string
  apiKey: string
}

const messages = defineMessages({
  title: {
    id: "InstallerPanel.Windows.TitleMsi",
    defaultMessage: "Déploiement de parc (.msi)",
  },
  info1: {
    id: "InstallerPanel.Windows.MsiInformations1",
    defaultMessage:
      "Les mises à jour automatiques sont désactivées. Votre équipe informatique doit déployer les nouvelles versions.",
  },
  info2: {
    id: "InstallerPanel.Windows.MsiInformations2",
    defaultMessage:
      "Les fichiers de l'application sont installés dans <cd>%ProgramFiles%</cd>",
  },
  info3: {
    id: "InstallerPanel.Windows.MsiInformations3",
    defaultMessage:
      "L'application peut être installé par GPO ou tout autre outil de déploiement automatisé",
  },
  dlLabel: {
    id: "InstallerPanel.Windows.MsiDownloadLabel",
    defaultMessage: "Téléchargez le package (.msi) pour Windows",
  },
  btnLabel: {
    id: "InstallerPanel.Windows.MsiDownloadBtnLabel",
    defaultMessage: "Téléchargez le package MSI",
  },
  installTitle: {
    id: "InstallerPanel.Windows.InstallMsiTitle",
    defaultMessage: "Déploiement du package (.msi)",
  },
  installPart1: {
    id: "InstallerPanel.Windows.InstallMsiPart1",
    defaultMessage:
      "1. Utilisez la commande suivante pour déployer le package.",
  },
  installPart2: {
    id: "InstallerPanel.Windows.InstallMsiPart2",
    defaultMessage: "msiexec /i {msiName} apikey={apiKey}",
  },
  installPart3: {
    id: "InstallerPanel.Windows.InstallMsiPart3",
    defaultMessage: "2. Déployez le client sur les postes de votre parc.",
  },
  installPart4: {
    id: "InstallerPanel.Windows.InstallMsiPart4",
    defaultMessage:
      "3. Le client se lancera automatiquement au démarrage de la session de l'utilisateur après le redémarrage du poste.",
  },
  installPart5: {
    id: "InstallerPanel.Windows.InstallMsiPart5",
    defaultMessage:
      "4. Vos utilisateurs récupéreront automatiquement leur signature mail !",
  },
  tooltip: {
    id: "InstallerPanel.Windows.InstallMsiCopyCommand",
    defaultMessage: "Copier la commande dans le presse papiers",
  },
})

const InstallerWindowsMsi: FunctionComponent<InstallerWindowsMsiProps> = ({
  appLink,
  apiKey = "",
}) => {
  const { formatMessage } = useIntl()

  const msiName = appLink.split("/").pop()

  const commandLine = formatMessage(messages.installPart2, {
    apiKey,
    msiName: msiName.length > 0 ? msiName : DEFAULT_MSI_VERSION_NAME,
  })

  const [isHovered, setIsHovered] = useState(false)
  const [heartbeat, setHeartbeat] = useState(0)

  return (
    <>
      <div className="installer-windows-grid__msi--diff">
        <h5 className="installer-column__title">
          {formatMessage(messages.title)}
        </h5>
        <ul className="installer-column__list">
          <li className="installer-column__list--item">
            {formatMessage(messages.info1)}
          </li>
          <li className="installer-column__list--item">
            {formatMessage(messages.info2, { cd: isCode })}
          </li>
          <li className="installer-column__list--item">
            {formatMessage(messages.info3)}
          </li>
        </ul>
      </div>
      <div className="installer-windows-grid__msi--dl">
        <p className="installer-column__dl--label">
          {formatMessage(messages.dlLabel)}
        </p>
        <a className="installer-column__dl--button" href={appLink}>
          <img
            className="installer-column__dl--logo"
            alt="outlook logo"
            src={logoWindowsBubble}
          />
          <span className="installer-column__dl--btn-label">
            {formatMessage(messages.btnLabel)}
          </span>
        </a>
      </div>
      <div className="installer-windows-grid__msi--install">
        <h5 className="installer-column__install--title">
          {formatMessage(messages.installTitle)}
        </h5>
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart1)}
        </p>
        <pre className="installer-column__install--cmd-line">
          <code>{commandLine}</code>
          <button
            type="button"
            className="installer-column__install--copy-container"
            onClick={() => {
              StringHelper.writeToClipboard(commandLine)
              setHeartbeat(1)
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onAnimationEnd={() => setHeartbeat(0)}
            data-heartbeat={heartbeat}
          >
            <CopyIcon className="installer-column__install--copy-icon" />
          </button>
        </pre>
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart3)}
        </p>
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart4)}
        </p>
        <p className="installer-column__install--informations">
          {formatMessage(messages.installPart5)}
        </p>
      </div>
      {isHovered && (
        <MouseTooltip visible={isHovered}>
          {formatMessage(messages.tooltip)}
        </MouseTooltip>
      )}
    </>
  )
}

export default InstallerWindowsMsi
