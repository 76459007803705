import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"
import Spinner from "components/Spinner"

import useHomePage from "features/Accounts/hooks/useHomePage"
import useHomePageRedirects from "features/Accounts/hooks/useHomePageRedirects"

import OnBoardingTile from "features/Accounts/components/OnBoardingTile"
import HomePageTiles from "features/Accounts/components/HomePageTiles"

import { LoadingStatus } from "core/CoreModels"

const Overview: FunctionComponent = () => {
  const loadingStatus = useHomePage()
  useHomePageRedirects()

  return (
    <Page
      title={<FormattedMessage id="Overview.Title" defaultMessage="Accueil" />}
    >
      {loadingStatus === LoadingStatus.LOADED ? (
        <div className="home-content">
          <OnBoardingTile />
          <HomePageTiles />
        </div>
      ) : (
        <div className="home-content__loader">
          <Spinner />
        </div>
      )}
    </Page>
  )
}

export default Overview
