import { FunctionComponent, useState } from "react"

import classNames from "classnames"

import MouseTooltip from "components/MouseTooltip"

import WeightingIndicatorItem from "./WeightingIndicatorItem"

export interface WeightingIndicatorsProps {
  weighting: number
  min: number
  max: number
  tooltip?: string
  showLabel?: boolean
  indicatorWidth?: number
  heightMin?: number
  heightMax?: number
  heightIncrement?: number
  isSmall?: boolean
  onChange?: (value: number) => void
}

const WeightingIndicators: FunctionComponent<WeightingIndicatorsProps> = ({
  weighting,
  min,
  max,
  tooltip,
  showLabel,
  indicatorWidth,
  heightMin,
  heightMax,
  heightIncrement,
  isSmall,
  onChange,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <>
      <div
        className={classNames("weighting__indicators", isSmall && "is-small")}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {[...Array(max)].map((_, index) => (
          <WeightingIndicatorItem
            key={index}
            weighting={weighting}
            index={index + min}
            showLabel={showLabel}
            indicatorWidth={indicatorWidth}
            heightMin={heightMin}
            heightMax={heightMax}
            heightIncrement={heightIncrement}
            onChange={onChange}
          />
        ))}
      </div>
      {tooltip && <MouseTooltip visible={isHovered}>{tooltip}</MouseTooltip>}
    </>
  )
}

export default WeightingIndicators
