export const USER_AVATAR_MIN_SIZE = 80

export const USER_AVATAR_MAX_SIZE = 150

export const USER_AVATAR_ACCEPTED_FILETYPES = [
  {
    name: "jpg",
    mimetype: "image/jpeg",
  },
  {
    name: "png",
    mimetype: "image/png",
  },
]
