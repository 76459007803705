import { FunctionComponent } from "react"

import { ReactComponent as Illustration } from "features/Accounts/assets/register-form-success-illustration.svg"

import AccountInformationsFormSubmitButton from "./AccountInformationsFormSubmitButton"
import { useDispatch } from "react-redux"
import { finishAccountRegistrationAction } from "features/Accounts/AccountsActions"

interface AccountInformationsFormSuccessStepProps {
  nextRegisterStep: number
  endOfSubscription: string
  buttonLabel: string
}

const AccountInformationsFormSuccessStep: FunctionComponent<
  AccountInformationsFormSuccessStepProps
> = ({ nextRegisterStep, endOfSubscription, buttonLabel }) => {
  const dispatch = useDispatch()
  const handleClick = () =>
    dispatch(finishAccountRegistrationAction(nextRegisterStep))

  return (
    <>
      <Illustration />
      <p className="account-informations-form__success--end-of-subscription">
        {endOfSubscription}
      </p>
      <AccountInformationsFormSubmitButton onClick={handleClick}>
        {buttonLabel}
      </AccountInformationsFormSubmitButton>
    </>
  )
}

export default AccountInformationsFormSuccessStep
