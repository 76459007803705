import React from "react"
import { FormattedMessage } from "react-intl"
import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"
import NavigationButtons from "../Components/NavigationButtons"
import EnrollmentStep4Side from "./EnrollmentStep4Side"
import BillingForm from "../Components/BillingForm"
import { Account } from "features/Accounts/AccountsModels"

import "./EnrollmentStep.scss"

const EnrollmentStep4: React.FC = () => {
  const { enrollmentDatas, setEnrollmentDatas } = useEnrollmentData()

  const fetchBillingAddress = () => null

  const getAccountInformations = () => {
    return new Account({})
  }

  const isFormValid = () => {
    if (
      enrollmentDatas.billingAddress.name &&
      enrollmentDatas.billingAddress.part1 &&
      enrollmentDatas.billingAddress.zip &&
      enrollmentDatas.billingAddress.town &&
      enrollmentDatas.billingAddress.countryCode &&
      enrollmentDatas.billingAddress.phone
    )
      return true
  }

  return (
    <div className="enroll__step enroll_step4">
      <div className="enroll__box enroll__main">
        <div className="enroll__form">
          <h2>
            <span>4</span>
            <FormattedMessage
              id="Enroll.Step4Title"
              defaultMessage="Renseignez vos données de facturation"
            />
          </h2>
          <BillingForm
            billingAddress={enrollmentDatas.billingAddress}
            isComplete={isFormValid()}
            isValidating={isFormValid()}
            accountInformations={getAccountInformations()}
            fetchBillingAddress={fetchBillingAddress}
            setBillingAddress={(billingAddress) =>
              setEnrollmentDatas({ billingAddress })
            }
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <NavigationButtons disableNext={!isFormValid()} />
        </div>
      </div>
      <EnrollmentStep4Side />
    </div>
  )
}

export default EnrollmentStep4
