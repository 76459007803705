import { FunctionComponent, useEffect, useRef, useState } from "react"

import { useSelector } from "react-redux"

import { NavLink } from "react-router-dom"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { MenuItem, Select, TextField } from "@material-ui/core"

import Toggle from "components/MUIToggle"
import Button from "components/Button"

import useDynamicUsers from "features/Users/hooks/useDynamicUsers"

import UsersSelectors from "features/Users/UsersSelectors"

import { Controller, FieldValues, UseFormReturn } from "react-hook-form"

import DynamicGroupsHelper, {
  attributeDynamicName,
  comparatorDynamicName,
  comparatorDynamicTypes,
  toggleDynamicName,
  valueDynamicName,
} from "utils/DynamicGroupsHelper"
import { Members } from "../EditGroup"

import { ReactComponent as IconUser } from "assets/icon-user.svg"

import { USERS_URL } from "router/RouterConstants"

import { User } from "features/Users/UserModels"

import { LoadingStatus } from "core/CoreModels"

interface DynamicFormProps {
  form: UseFormReturn<FieldValues, unknown>
  setMembers: React.Dispatch<React.SetStateAction<Members[]>>
  allUsers: User[]
  members: Members[]
  currentGroup
  onUpsertDynamicGroup
}

const messages = defineMessages({
  validBtn: { id: "EditDynamicGroup.ValidBtn", defaultMessage: "Valider" },
  fieldEmpty: {
    id: "EditDynamicGroup.fieldEmpty",
    defaultMessage: "Veuillez remplir le champ",
  },
  fieldMinLength: {
    id: "EditDynamicGroup.fieldMinLength",
    defaultMessage: "3 caractères minimums sont requis",
  },
})

const DynamicForm: FunctionComponent<DynamicFormProps> = ({
  form,
  setMembers,
  allUsers,
  members,
  currentGroup,
  onUpsertDynamicGroup,
}) => {
  const isUpdate = !!currentGroup
  const ref = useRef(null)
  const [rowsIndex, setRowsIndex] = useState([0])
  const userPropertiesById = useSelector(UsersSelectors.getUsersPropertiesById)
  const {
    loadingStatus: dynamicUserLoadingStatus,
    allDynamicUserIds,
    resetDynamicUsers,
    fetchDynamicUsers,
  } = useDynamicUsers()
  const { formatMessage } = useIntl()

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    resetField,
    unregister,
    watch,
    formState: { errors },
  } = form

  const watchAllFields = watch()

  useEffect(() => {
    setMembers(
      allDynamicUserIds.map((id) => {
        const user: User = allUsers.find((us) => us.Id == id)
        return {
          id: user.Id,
          label: User.getFullName(user),
        }
      }),
    )
  }, [allDynamicUserIds])

  useEffect(() => {
    if (dynamicUserLoadingStatus == LoadingStatus.LOADED) {
      ref.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [dynamicUserLoadingStatus])

  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (name) {
        if (name.includes(comparatorDynamicName) && fieldIsDisabled(name)) {
          const index = name.replace(comparatorDynamicName, "")
          resetField(valueDynamicName + index)
        }
        resetDynamicUsers()
        setMembers([])
      }
    })
    return () => subscription.unsubscribe()
  }, [watchAllFields])

  useEffect(() => {
    if (currentGroup?.query) {
      fillFields(currentGroup.query)
    }
  }, [currentGroup])

  const handleAdd = (form) => {
    const newGroup = {
      Name: form.name,
      Members: members.map((m) => m.id),
      Origin: form.origin,
      Query: DynamicGroupsHelper.dataToQuery(form),
    }
    onUpsertDynamicGroup(newGroup)
  }

  const handleEdit = (form) => {
    const updatedGroup = {
      id: currentGroup.Id,
      Name: form.name,
      Members: members.map((m) => m.id),
      Origin: form.origin,
      Query: DynamicGroupsHelper.dataToQuery(form),
    }
    onUpsertDynamicGroup(updatedGroup)
  }

  const fillFields = (query: string) => {
    const { fields, indexRows } = DynamicGroupsHelper.queryToFieldName(query)
    setRowsIndex(indexRows)
    Object.keys(fields).map((key) => {
      setValue(key, fields[key])
    })
  }

  const fieldsCorrectlyFill = () => {
    return rowsIndex.every((index) => {
      if (fieldIsDisabled(`${comparatorDynamicName}${index}`)) {
        return getValues(`${attributeDynamicName}${index}`) != ""
      }
      return (
        getValues(`${attributeDynamicName}${index}`) != "" &&
        getValues(`${comparatorDynamicName}${index}`) != "" &&
        getValues(`${valueDynamicName}${index}`)?.trim().length >= 3
      )
    })
  }

  const fieldIsDisabled = (fieldName) => {
    return (
      getValues(fieldName) == comparatorDynamicTypes["Est vide"] ||
      getValues(fieldName) == comparatorDynamicTypes["N'est pas vide"]
    )
  }

  const handleToggleChange = (toggleName) => {
    setValue(toggleName, !getValues(toggleName))
  }

  const handleAddRow = () => {
    setRowsIndex([...rowsIndex, Math.max(...rowsIndex) + 1])
    resetDynamicUsers()
    setMembers([])
  }

  const handleRemoveRow = (rowIndex) => {
    unregister(`${attributeDynamicName}${rowIndex}`)
    unregister(`${valueDynamicName}${rowIndex}`)
    unregister(`${comparatorDynamicName}${rowIndex}`)
    setRowsIndex(rowsIndex.filter((index) => index != rowIndex))
  }

  const handleCalculateClick = () => {
    const data = getValues()
    fetchDynamicUsers(data)
  }

  return (
    <>
      <div className="edit-dynamic_group-title">
        <FormattedMessage id="EditDynamicGroup.Title" defaultMessage="Règles" />
      </div>
      <div className="edit-dynamic_group-description">
        <FormattedMessage
          id="EditDynamicGroup.Description"
          defaultMessage="Définissez les règles d’appartenance à ce groupe. Vous pouvez en combiner jusqu’à deux maximums."
        />
      </div>
      <div className="edit-dynamic_group-selects">
        <>
          {rowsIndex.map((index) => (
            <>
              {index >= 1 && (
                <div className="dynamic-group_form_actions" key={index}>
                  <div className="dynamic-group_form_action">
                    <div
                      className={`${
                        getValues(`${toggleDynamicName}${index}`)
                          ? "dynamic-group_form_action--inactive"
                          : "dynamic-group_form_action--active"
                      }`}
                    >
                      <FormattedMessage
                        id="DynamicGroupForm.Et"
                        defaultMessage="Et"
                      />
                    </div>
                    <div className="dynamic-group_form_toggle">
                      <Controller
                        name={`toggle${index}`}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Toggle
                              key={`toggle${index}`}
                              checked={value}
                              onChange={onChange}
                            />
                          )
                        }}
                      ></Controller>
                    </div>
                    <div
                      className={`${
                        !getValues(`${toggleDynamicName}${index}`)
                          ? "dynamic-group_form_action--inactive"
                          : "dynamic-group_form_action--active"
                      }`}
                      onClick={() =>
                        handleToggleChange(`${toggleDynamicName}${index}`)
                      }
                    >
                      <FormattedMessage
                        id="DynamicGroupForm.Ou"
                        defaultMessage="Ou"
                      />
                    </div>
                  </div>
                  <div
                    className="dynamic-group_delete-row"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <FormattedMessage
                      id="DynamicGroupForm.Delete"
                      defaultMessage="Supprimer"
                    />
                  </div>
                </div>
              )}
              <div className="dynamic-group_fiels-row">
                <Controller
                  name={`${attributeDynamicName}${index}`}
                  control={control}
                  defaultValue={
                    userPropertiesById[Object.keys(userPropertiesById)[0]]
                      .InternalName
                  }
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Select
                        style={{ width: "100%" }}
                        variant="outlined"
                        className={"field-row_fullwidth"}
                        key={`${attributeDynamicName}${index}`}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                      >
                        {Object.keys(userPropertiesById)
                          .filter(
                            (key) =>
                              userPropertiesById[key].InternalName !=
                                "banner" &&
                              userPropertiesById[key].InternalName !=
                                "defaultsignature",
                          )
                          .map((key) => (
                            <MenuItem
                              key={`${index}${key}`}
                              value={userPropertiesById[key].InternalName}
                            >
                              {userPropertiesById[key].InternalName}
                            </MenuItem>
                          ))}
                      </Select>
                    )
                  }}
                ></Controller>
                <Controller
                  name={`${comparatorDynamicName}${index}`}
                  control={control}
                  defaultValue={
                    comparatorDynamicTypes[
                      Object.keys(comparatorDynamicTypes)[0]
                    ]
                  }
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Select
                        style={{ width: "100%" }}
                        variant="outlined"
                        className={"field-row_fullwidth"}
                        key={`${comparatorDynamicName}${index}`}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                      >
                        {Object.keys(comparatorDynamicTypes).map((key) => (
                          <MenuItem value={comparatorDynamicTypes[key]}>
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    )
                  }}
                ></Controller>
                <Controller
                  name={`${valueDynamicName}${index}`}
                  control={control}
                  defaultValue={""}
                  rules={
                    !fieldIsDisabled(`${comparatorDynamicName}${index}`)
                      ? {
                          required: {
                            value: true,
                            message: formatMessage(messages.fieldEmpty),
                          },
                          minLength: {
                            value: 3,
                            message: formatMessage(messages.fieldMinLength),
                          },
                        }
                      : { required: false }
                  }
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <TextField
                        id={`${valueDynamicName}${index}`}
                        className={`field-row_fullwidth ${
                          fieldIsDisabled(`${comparatorDynamicName}${index}`) &&
                          "field-row--disable"
                        }`}
                        disabled={fieldIsDisabled(
                          `${comparatorDynamicName}${index}`,
                        )}
                        variant="outlined"
                        value={value}
                        error={errors[`${valueDynamicName}${index}`]}
                        helperText={
                          errors[`${valueDynamicName}${index}`] &&
                          errors[`${valueDynamicName}${index}`].message
                        }
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )
                  }}
                ></Controller>
              </div>
            </>
          ))}
        </>
      </div>
      {rowsIndex.length == 1 && (
        <div className="dynamic-group_form_add-rules" onClick={handleAddRow}>
          <FormattedMessage
            id="DynamicGroup.addRules"
            defaultMessage="Ajouter une nouvelle règle"
          />
        </div>
      )}

      <div
        className={`dynamic-group_form_calculate-button${
          fieldsCorrectlyFill() ? "--enable" : "--disable"
        }`}
        onClick={fieldsCorrectlyFill() ? handleCalculateClick : null}
      >
        {dynamicUserLoadingStatus == LoadingStatus.PENDING ? (
          <div className="loader"></div>
        ) : (
          <FormattedMessage
            id="DynamicGroup.calculate"
            defaultMessage="Calculer et afficher les utilisateurs"
          />
        )}
      </div>
      <div
        className="dynamic-group_form_users"
        hidden={dynamicUserLoadingStatus != LoadingStatus.LOADED}
      >
        <div className="dynamic-group_form_user-preview_header">
          <div className="edit-group-form_title">
            <FormattedMessage
              id="DynamicGroup.userTitle"
              defaultMessage="Utilisateurs"
            />
          </div>
          <div className="dynamic-group_form_user-added">
            {members.length}
            &nbsp;
            <FormattedMessage
              id="EditDynamicGroup.Added"
              defaultMessage="ajoutés"
            />
          </div>
        </div>
        <div className="dynamic-group_form_userList-container">
          {members.map((user) => (
            <div className="dynamic-group_form_user-row">
              <IconUser className="people-picker-collapse-list__icon--users" />
              <NavLink to={`${USERS_URL}/${user.id}`}>{user.label}</NavLink>
            </div>
          ))}
        </div>
      </div>
      <div
        className="edit-group__footer"
        data-cy="edit-group__footer"
        ref={ref}
      >
        <Button
          onClick={handleSubmit(isUpdate ? handleEdit : handleAdd)}
          form="group-form"
          label={formatMessage(messages.validBtn)}
        />
      </div>
    </>
  )
}

export default DynamicForm
