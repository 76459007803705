import { ChangeEvent, FunctionComponent } from "react"

import O365Sync from "./components/O365Sync"

import Feature from "features/Features/FeaturesModels"

interface O365FormProps {
  featureO365: Feature
  updateFeature: (feature: Feature) => void
  launchJob: (feature: Feature) => void
}

const O365Form: FunctionComponent<O365FormProps> = ({
  featureO365,
  updateFeature,
  launchJob,
}) => {
  const handleToggleSync = (
    feature: Feature,
    _evt: ChangeEvent<HTMLInputElement>,
    isActive: boolean,
  ) => {
    feature.activated = isActive
    updateFeature(feature)
  }

  return (
    <div className="o365-form">
      {featureO365 != null && (
        <O365Sync
          featureO365={featureO365}
          handleToggle={handleToggleSync}
          launchJob={launchJob}
        />
      )}
    </div>
  )
}

export default O365Form
