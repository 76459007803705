import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

interface PageProps {
  children: ReactNode
  title: ReactNode
  withoutNavbar?: boolean
}

const Page: FunctionComponent<PageProps> = ({
  children,
  title,
  withoutNavbar,
}) => {
  return (
    <div className="col-12 body-content">
      <section className="col-12 home h-100">
        <section
          className={classNames(
            "l-sub-header",
            withoutNavbar && "without-navbar",
          )}
        >
          <h4 className="l-sub-header__title">{title}</h4>
          <div className="l-sub-header__liseret" />
        </section>
        {children}
      </section>
    </div>
  )
}

export default Page
