import { FunctionComponent } from "react"

import { fromBlobToFile } from "features/Storage/services/fileService"

import Alert from "components/Alert"

import BannerUploadCard from "features/Scenarios/components/BannerUploadCard"

import imageSignature from "./campaignPreview.svg"
import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"

interface Props {
  url?: string
  width?: number
  resize?: boolean
  onUpload?: (file: File) => void
  onLoad?: (img: unknown) => void
}

const messages = defineMessages({
  info: {
    id: "CampaignPreview.Informations",
    defaultMessage:
      "Nous préconisons un format par défaut de 600x150px. Cependant, sentez-vous libre dans le choix de la taille et du format de votre image. Qu’il soit portrait, paysage ou carré...",
  },
})

const CampaignPreview: FunctionComponent<Props> = ({
  url,
  width,
  onUpload,
  onLoad,
}) => {
  const { formatMessage } = useIntl()

  const handleUpload = (blobFile: Blob) => {
    const file = fromBlobToFile(blobFile)
    onUpload && onUpload(file)
  }

  const handleLoad = (event) => {
    onLoad && onLoad(event.target)
  }

  return (
    <div className="campaign-preview">
      <div
        className={classNames(
          "campaign-preview__info",
          url && "campaign-preview__info--transparent",
        )}
      >
        <Alert severity="info" title={formatMessage(messages.info)} />
      </div>
      <div className="campaign-preview__card">
        <div className="campaign-preview__banner">
          <div className="campaign-preview__signature">
            <img src={imageSignature} />
          </div>
          {url && <img src={url} width={width} onLoad={handleLoad} />}
        </div>
        {onUpload && (
          <div className="campaign-preview__upload">
            <BannerUploadCard onFileChange={handleUpload} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignPreview
