import { FunctionComponent, ReactNode } from "react"

interface ScenarioCardDetailsProps {
  children: ReactNode
}

export type ScenarioCardDetailsType =
  FunctionComponent<ScenarioCardDetailsProps>

const ScenarioCardDetails: ScenarioCardDetailsType = ({ children }) => {
  return <div className="scenario-card__details">{children}</div>
}

export default ScenarioCardDetails
