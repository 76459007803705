import { useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"

function useSignaturesTileDatas() {
  const loadingStatus = useSelector(
    accountsSelectors.getSignaturesTileLoadingStatus,
  )

  const count = useSelector(accountsSelectors.getSignaturesTileCount)

  return { loadingStatus, count }
}

export default useSignaturesTileDatas
