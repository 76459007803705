import { FormEventHandler, FunctionComponent } from "react"
import { defineMessages, useIntl } from "react-intl"
import { NavLink, Navigate } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { ReactComponent as LogoBMM } from "assets/logo-text-bmm.svg"

import { loginAction } from "features/Accounts/AccountsActions"

import UnderlinedInputField from "components/UnderlinedInputField"

import { FORGOT_PASSWORD_URL, REGISTRATION_URL } from "router/RouterConstants"

import LoginFormSubmitButton from "./components/LoginFormSubmitButton"
import RememberMeCheckbox from "./components/RememberMeCheckbox"

import PasswordUnderlinedInputField from "features/Accounts/components/PasswordUnderlinedInputField"
import GoogleOauthButton from "features/Accounts/components/GoogleOauthButton"
import O365OauthButton from "features/Accounts/components/O365OauthButton"
import GoogleOAuthErrorNotification from "features/Accounts/components/GoogleOAuthErrorNotification"

import { accountsSelectors } from "features/Accounts"
import { appSelectors } from "features/App"

const messages = defineMessages({
  connexion: {
    id: "LoginForm.Title",
    defaultMessage: "Connexion",
  },
  emailPlaceholder: {
    id: "LoginForm.EmailPlaceholder",
    defaultMessage: "Email",
  },
  passwordPlaceholder: {
    id: "LoginForm.PasswordPlaceholder",
    defaultMessage: "Mot de passe",
  },
  submitButton: {
    id: "LoginForm.SubmitButton",
    defaultMessage: "Se connecter",
  },
  rememberMe: {
    id: "LoginForm.RememberMe",
    defaultMessage: "Se souvenir de moi",
  },
  forgotPassword: {
    id: "LoginForm.ForgotPassword",
    defaultMessage: "Mot de passe oublié ?",
  },
  orText: {
    id: "LoginForm.Or",
    defaultMessage: "ou",
  },
  signUpButton: {
    id: "LoginForm.SignUpButton",
    defaultMessage: "Je m'inscris",
  },
  needAnAccount: {
    id: "LoginForm.NeedAnAccount",
    defaultMessage: "Vous n'avez pas encore de compte ?",
  },
  google: {
    id: "LoginForm.ConnectionGoogle",
    defaultMessage: "Se connecter avec Google",
  },
  microsoft: {
    id: "LoginForm.ConnectionMicrosoft",
    defaultMessage: "Se connecter avec Microsoft",
  },
})

const LoginForm: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const redirectTo = useSelector(appSelectors.getRedirectTo)
  const isAuthenticated = useSelector(accountsSelectors.getIsAuthentified)

  const handleLogin: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const email = e.target["email"].value
    const password = e.target["password"].value
    const rememberMe = e.target["rememberMe"].checked
    dispatch(loginAction(email, password, rememberMe))
  }

  if (isAuthenticated) return <Navigate to={redirectTo} />

  return (
    <div className="login-form">
      <LogoBMM className="login-form__logo" />
      <p className="login-form__title">{formatMessage(messages.connexion)}</p>
      <div className="login-form__form">
        <form className="login-form__form" onSubmit={handleLogin}>
          <UnderlinedInputField
            name="email"
            autoFocus
            placeholder={formatMessage(messages.emailPlaceholder)}
            required
          />
          <PasswordUnderlinedInputField
            placeholder={formatMessage(messages.passwordPlaceholder)}
          />
          <div className="login-form__options">
            <RememberMeCheckbox>
              {formatMessage(messages.rememberMe)}
            </RememberMeCheckbox>
            <NavLink
              to={FORGOT_PASSWORD_URL}
              className="login-form__options--forgot-password"
            >
              {formatMessage(messages.forgotPassword)}
            </NavLink>
          </div>
          <LoginFormSubmitButton>
            {formatMessage(messages.submitButton)}
          </LoginFormSubmitButton>
          <div className="login-form__or">
            <hr className="login-form__or--lines" />
            <p className="login-form__or--label">
              {formatMessage(messages.orText)}
            </p>
            <hr className="login-form__or--lines" />
          </div>
        </form>
        <O365OauthButton>{formatMessage(messages.microsoft)}</O365OauthButton>
        <GoogleOauthButton>{formatMessage(messages.google)}</GoogleOauthButton>
        <GoogleOAuthErrorNotification />
      </div>
      <div className="login-form__signup">
        <p className="login-form__signup--punchline">
          {formatMessage(messages.needAnAccount)}
        </p>
        <NavLink className="login-form__signup--button" to={REGISTRATION_URL}>
          {formatMessage(messages.signUpButton)}
        </NavLink>
      </div>
    </div>
  )
}

export default LoginForm
