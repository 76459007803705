import { Template } from "entities/Template"

/**
 * Template Factory - Permet de gérer les templates
 */
class TemplatesFactory {
  DeepCopy(originalTemplate: Template) {
    const template = new Template({})

    template.rawContent = originalTemplate.rawContent
    template.rawLightContent = originalTemplate.rawLightContent
    template.rawRawContent = originalTemplate.rawRawContent

    template.compiledContent = originalTemplate.compiledContent
    template.templateId = originalTemplate.Id

    return template
  }

  DeepCopyWithDataTags(originalTemplate: Template) {
    const template = new Template({})

    template.rawContent = originalTemplate.rawContent
    template.rawLightContent = originalTemplate.rawLightContent
    template.rawRawContent = originalTemplate.rawRawContent

    template.compiledContent = originalTemplate.compiledContent
    template.templateId = originalTemplate.Id
    template.dataTags = Object.assign({}, originalTemplate.dataTags)
    return template
  }
}

export default new TemplatesFactory()
