import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"
import config from "config/config"
import LinkButton from "components/LinkButton"

const ExportUsagesLink: FunctionComponent = () => {
  const interval = 2 // en jours
  const apiUrl = config.webApiUrl + "/api/usages/export?$since="

  return (
    <div className="export-usages-link">
      <div className="export-usages-link_label pb-4">
        <FormattedMessage
          id="ExportUsagesLink.Label"
          defaultMessage="Vérifiez si toutes vos signatures sont déployées en téléchargeant l'état des lieux"
        />
      </div>
      <div className="pb-5">
        <LinkButton
          label={"TÉLÉCHARGER"}
          href={apiUrl + interval}
          disabled
        ></LinkButton>
      </div>
    </div>
  )
}

export default ExportUsagesLink
