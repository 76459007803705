import { FunctionComponent, ReactNode } from "react"

interface LoginFormSubmitButtonProps {
  children: ReactNode
}

const LoginFormSubmitButton: FunctionComponent<LoginFormSubmitButtonProps> = ({
  children,
}) => {
  return (
    <button className="login-form__submit-button" type="submit">
      {children}
    </button>
  )
}

export default LoginFormSubmitButton
