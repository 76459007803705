import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import {
  fetchPartialUsers,
  reloadPartialUsers,
  searchUsers,
  setPartialUsersPageNumber,
  setPartialUsersPageSize,
} from "features/Users/UsersReducers"

import { usersSelectors } from "features/Users"

import { PartialUser } from "features/Users/UserModels"

import { LoadingStatus } from "core/CoreModels"
import useDebounce from "hooks/useDebounce"
import { SortOrders } from "components/PaginatedDataGrid/PaginatedDataGrid"

interface UsePartialUsersData {
  partialUsers: Array<PartialUser>
  currentPage: number
  lastPage: number
  pageSize: number
  totalCount: number
  loadingStatus: LoadingStatus
  searchValue: string
  orderBy: SortOrders
  changePage: (pageNumber: number) => void
  changePageSize: (pageSize: number) => void
  handleSearch: (input: string) => void
  reload: () => void
}

const usePartialUsersData = (): UsePartialUsersData => {
  const dispatch = useDispatch()

  const partialUsers: Array<PartialUser> = useSelector(
    usersSelectors.getAllPartialUsers,
  )
  const currentPage: number = useSelector(
    usersSelectors.getPartialUsersCurrentPage,
  )
  const lastPage: number = useSelector(usersSelectors.getPartialUsersLastPage)
  const pageSize: number = useSelector(usersSelectors.getPartialUsersPageSize)
  const totalCount: number = useSelector(
    usersSelectors.getPartialUsersTotalCount,
  )
  const loadingStatus: LoadingStatus = useSelector(
    usersSelectors.getPartialUsersLoadingStatus,
  )
  const searchString = useSelector(usersSelectors.getPartialUsersSearchString)
  const orderBy = useSelector(usersSelectors.getPartialUsersOrderBy)

  const [searchValue, setSearchValue] = useState<string>(searchString)
  const debouncedSearchString = useDebounce<string>(searchValue, 500)

  useEffect(() => {
    setSearchValue(searchString)
  }, [searchString])

  useEffect(() => {
    if (searchValue !== searchString) {
      dispatch(searchUsers(debouncedSearchString))
    }
  }, [debouncedSearchString])

  useEffect(() => {
    dispatch(
      fetchPartialUsers({
        pageSize,
        pageNumber: currentPage,
        searchString: searchString,
        orderBy,
      }),
    )
  }, [dispatch, searchString, currentPage, pageSize, orderBy])

  const changePage = (pageNumber: number): void => {
    dispatch(setPartialUsersPageNumber(pageNumber))
  }

  const changePageSize = (nextPageSize: number): void => {
    dispatch(setPartialUsersPageSize(nextPageSize))
  }

  const handleSearch = (input: string) => {
    const search = input.toLowerCase()
    setSearchValue(search)
  }

  const reload = () => {
    dispatch(reloadPartialUsers())
  }

  return {
    partialUsers,
    currentPage,
    lastPage,
    pageSize,
    totalCount,
    loadingStatus,
    searchValue,
    orderBy,
    changePage,
    changePageSize,
    handleSearch,
    reload,
  }
}

export default usePartialUsersData
