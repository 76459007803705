import React from "react"
import { Paper, ClickAwayListener, Fade } from "@material-ui/core"
import { ReferenceObject } from "popper.js"
import { PopperPlacementType } from "@material-ui/core/Popper"
import { Popper } from "@material-ui/core"

interface Props {
  children: React.ReactNode
  show: boolean
  parent: null | ReferenceObject
  onClickAway()
  onClickInside()
  arrow: boolean
  position?: string
}

const Tooltip: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(props.show)
  const [placement, setPlacement] = React.useState(props.position || "bottom")
  const [anchorEl, setAnchorEl] = React.useState(props.parent)
  const [arrowRef, setArrowRef] = React.useState(null)

  React.useEffect(() => {
    setOpen(props.show)
    setPlacement(props.position || "bottom")
    setAnchorEl(props.parent)
  }, [props.show, props.position, props.parent])

  return (
    <div className="tooltip">
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement as PopperPlacementType}
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: props.arrow,
            element: arrowRef,
          },
          preventOverflow: {
            enabled: "true",
            boundariesElement: "scrollParent",
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} in={open}>
            <div>
              {props.arrow && <div className="arrow" ref={setArrowRef} />}
              <ClickAwayListener onClickAway={props.onClickAway}>
                <Paper
                  elevation={3}
                  className="tooltip__content"
                  onClick={props.onClickInside}
                >
                  {props.children}
                </Paper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default Tooltip
