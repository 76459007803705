import { connect } from "react-redux"
import MenuUser from "./MenuUser"

import { offersSelectors } from "features/Offers"
import { logoutAction } from "../../AccountsActions"

interface OwnProps {
  expanded: boolean
  closeMenu: () => void
  openMenu: () => void
  resetTour: () => void
}

interface StateToProps {
  isTrial: boolean
}

interface MyDispatchToProps {
  logout: () => void
}

const mapStateToProps = (state) => ({
  isTrial: offersSelectors.isTrial(state),
})

const mapDispatchToProp = {
  logout: logoutAction,
}

const ConnectedMenuUser = connect<StateToProps, MyDispatchToProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProp,
)(MenuUser)

export default ConnectedMenuUser
