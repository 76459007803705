/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"

import { useIntl } from "react-intl"

import useKeyboardShortcut from "hooks/useKeyboardShortcut"

import { enrollmentSelectors } from "features/Enrollment"
import {
  subscribe as subscribeReducer,
  saveStripePayment as saveStripePaymentReducer,
  initEnrollmentForm,
} from "features/Enrollment/EnrollmentReducer"
import { EnrollmentDatas } from "features/Enrollment/EnrollmentModels"

import { offersSelectors } from "features/Offers"
import { CatalogOffer, OfferOption } from "features/Offers/OffersModels"

import { checkCodePromoAction } from "features/Enrollment/EnrollmentActions"
import { accountsSelectors } from "features/Accounts"

import {
  DEFAULT_ENROLLMENT_OFFER_NAME,
  ENROLLMENT_STEP_SUCCESS,
  USERS_LICENCES_DEFAULT,
} from "features/Enrollment/EnrollmentConstants"
import { LoadingStatus } from "core/CoreModels"
import { globalPriceComputation } from "features/Enrollment/EnrollmentRules"

const defaultDatas: EnrollmentDatas = {
  currentStep: 1,
  nbUsers: USERS_LICENCES_DEFAULT,
  price: 0,
  priceMonthly: 0,
  subTotal: 0,
  total: 0,
  totalVAT: 0,
  paymentMethod: "card",
  clients: [],
  discount: null,
  discountError: false,
  billingAddress: {
    name: "",
    siret: "",
    part1: "",
    part2: "",
    zip: "",
    town: "",
    countryCode: "",
    phone: "",
  },
  checkout: { holder: "", holder_sepa: "", email: "" },
  selectedOffer: null,
  options: [],
  enrollmentSuccess: false,
  newEnrollmentInformations: null,
}

const EnrollmentFormContext = createContext({
  enrollmentDatas: defaultDatas,
  setEnrollmentDatas: (_values: Partial<EnrollmentDatas>) => null,
  subscribe: () => null,
  intentSecret: "",
  paymentAlreadyValidated: false,
  saveStripePayment: (
    _method: string,
    _mode: string,
    _holder: string,
    _paymentIntent: object,
    _stripeError: object,
    _email?: string,
  ) => null,
  checkCoupon: (_coupon: string) => null,
  allOffers: [],
  allOptions: [],
  updateOffer: (_offer: CatalogOffer) => null,
  updateNbUsers: (_nbUsers: number) => null,
  updateOptions: (_options: OfferOption[]) => null,
})

function updatePrices(enrollmentDatas: EnrollmentDatas) {
  const { selectedOffer, nbUsers, options, discount } = enrollmentDatas

  if (selectedOffer === null) return

  const optionsPricing = options.map((opt) =>
    opt.getPricingFromLicencesNumber(nbUsers),
  )
  return globalPriceComputation(
    selectedOffer,
    nbUsers,
    optionsPricing,
    discount?.percent,
  )
}

export const EnrollmentContextProvider = ({ children }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [enrollmentDatas, setEnrollmentData] = useState(defaultDatas)

  // Debug
  const [debug, setDebug] = useState(false)
  useKeyboardShortcut(["Shift", "D"], () => setDebug(!debug), {})

  const setEnrollmentDatas = (values) => {
    setEnrollmentData((prevData) => ({
      ...prevData,
      ...values,
    }))
  }

  const updateOffer = (offer: CatalogOffer) =>
    setEnrollmentDatas({
      selectedOffer: offer,
      ...updatePrices({ ...enrollmentDatas, selectedOffer: offer }),
    })

  const updateNbUsers = (nbUsers: number) =>
    setEnrollmentDatas({
      nbUsers,
      ...updatePrices({ ...enrollmentDatas, nbUsers }),
    })

  const updateOptions = (options) =>
    setEnrollmentDatas({
      options,
      ...updatePrices({ ...enrollmentDatas, options }),
    })

  const allOffers = useSelector(offersSelectors.getAllCatalogOffers)

  // Options
  const allOptions = useSelector(offersSelectors.getAllOptions)

  // Checkout Form
  const paymentAlreadyValidated: boolean = useSelector(
    enrollmentSelectors.getValidatedStatus,
  )
  const intentSecret: string = useSelector(enrollmentSelectors.getIntentSecret)

  const currentStepFromStore = useSelector(
    enrollmentSelectors.getEnrollmentFormCurrentStep,
  )

  const employeesNumber =
    useSelector(accountsSelectors.getCurrentAccount)?.EmployeesNumber ||
    USERS_LICENCES_DEFAULT

  const subscribe = () => dispatch(subscribeReducer(enrollmentDatas))

  const saveStripePayment = (
    method: string,
    mode: string,
    holder: string,
    paymentIntent: null,
    stripeError: null,
    email?: string,
  ) =>
    dispatch(
      saveStripePaymentReducer({
        methodId: method,
        mode,
        holder,
        paymentIntent,
        stripeError,
        email,
      }),
    )

  // Coupon codes
  const checkCoupon = (code: string) => dispatch(checkCodePromoAction(code))

  const currentCoupon = useSelector(enrollmentSelectors.getCodePromo)

  const loadingStatus = useSelector(
    enrollmentSelectors.getEnrollmentFormLoadingStatus,
  )

  const enrollmentSuccess = useSelector(
    enrollmentSelectors.getEnrollmentSuccess,
  )
  const newEnrollmentInformations = useSelector(
    enrollmentSelectors.getNewOfferInformations,
  )

  // normalisation du nom label = Enroll.CouponLabelTEST50

  const addDiscount = (coupon: { Code: string; Percentdiscount: number }) => {
    const label = formatMessage({
      id: `Enroll.CouponLabel${coupon.Code}`,
      defaultMessage: `Reduction de ${coupon.Percentdiscount}%`,
    })
    const discount = {
      label,
      percent: coupon.Percentdiscount,
      code: coupon.Code,
    }

    setEnrollmentDatas({
      discount,
      discountError: false,
      ...updatePrices({ ...enrollmentDatas, discount }),
    })
  }

  useEffect(() => {
    updateNbUsers(employeesNumber)
  }, [])

  useEffect(() => {
    if (currentCoupon == null) return
    if (!currentCoupon.Valid) setEnrollmentDatas({ discountError: true })
    // fetch offer, options, billing infos, ...
    if (currentCoupon.Valid) addDiscount(currentCoupon)
  }, [currentCoupon])

  useEffect(() => {
    setEnrollmentDatas({ enrollmentSuccess, newEnrollmentInformations })
  }, [enrollmentSuccess])

  useEffect(() => {
    setEnrollmentDatas({ allOffers })
  }, [allOffers])

  useEffect(() => {
    const selectedOffer = allOffers.find(
      (offer) => offer.name === DEFAULT_ENROLLMENT_OFFER_NAME,
    )

    if (selectedOffer)
      setEnrollmentDatas({
        selectedOffer,
        ...updatePrices({ ...enrollmentDatas, selectedOffer }),
      })
  }, [allOffers])

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(initEnrollmentForm())
    }
  }, [loadingStatus])

  useEffect(() => {
    if (enrollmentSuccess && currentStepFromStore === ENROLLMENT_STEP_SUCCESS)
      setEnrollmentDatas({ currentStep: ENROLLMENT_STEP_SUCCESS })
  }, [enrollmentSuccess, currentStepFromStore])

  return (
    <EnrollmentFormContext.Provider
      value={{
        enrollmentDatas,
        setEnrollmentDatas,
        subscribe,
        intentSecret,
        saveStripePayment,
        paymentAlreadyValidated,
        checkCoupon,
        allOffers,
        allOptions,
        updateOffer,
        updateNbUsers,
        updateOptions,
      }}
    >
      {debug && (
        <div className="enroll__debug">
          enrollmentDatas: {JSON.stringify(enrollmentDatas, null, 2)}
        </div>
      )}
      {children}
    </EnrollmentFormContext.Provider>
  )
}

export const useEnrollmentData = () => useContext(EnrollmentFormContext)
