import { FunctionComponent } from "react"

import moment from "moment"

import { ResponsiveLine } from "@nivo/line"

import TrackingGraphTooltip from "features/Tracking/components/TrackingGraphTooltip"

import useGraphAxisConfig from "features/Tracking/hooks/useGraphAxisConfig"

import { TrackingGraphData } from "features/Tracking/TrackingModels"

interface SingleSignatureTrackingGraphProps {
  title: string
  data: TrackingGraphData[]
}

const SingleSignatureTrackingGraph: FunctionComponent<
  SingleSignatureTrackingGraphProps
> = ({ title, data }) => {
  const { yScaleMax, axisLeftTickValues, axisBottomTickValues, measuredRef } =
    useGraphAxisConfig(data)

  return (
    <div className="single-sign-track__graph">
      <p className="single-sign-track__graph--title">{title}</p>
      <div className="single-sign-track__graph--data" ref={measuredRef}>
        <ResponsiveLine
          margin={{ top: 5, right: 5, left: 30, bottom: 25 }}
          data={data}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: 0,
            max: yScaleMax,
          }}
          enableArea={true}
          axisLeft={{
            tickValues: axisLeftTickValues,
          }}
          axisBottom={{
            format: (date) => moment(date).format("D MMM"),
            tickValues: axisBottomTickValues,
          }}
          colors={data.map((history) =>
            history.hover ? history.secondaryColor : history.color,
          )}
          areaOpacity={0.3}
          enableSlices={false}
          useMesh={true}
          enablePoints={false}
          isInteractive={true}
          enableGridX={false}
          enableGridY={false}
          animate={true}
          theme={{
            textColor: "#00000061",
            fontSize: 9,
          }}
          tooltip={({ point }) => <TrackingGraphTooltip point={point} />}
        />
      </div>
    </div>
  )
}

export default SingleSignatureTrackingGraph
