import { FunctionComponent } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import TrackingDatePicker from "features/Tracking/components/TrackingDatePicker"

import { ScenarioMode } from "features/Scenarios/ScenarioModels"

import { Moment } from "moment"

interface SingleScenarioTrackingHeaderProps {
  name: string
  scenarioMode: ScenarioMode
  startDate: Moment
  endDate: Moment
  handleUnselect: () => void
  handleDateChange: (startDate: Moment, endDate: Moment) => void
  minDate?: Moment
}

const messages = defineMessages({
  ctaUnselect: {
    id: "Tracking.SingleScenarioTrackingHeaderUnselect",
    defaultMessage: "Annuler la sélection",
  },
  unique: {
    id: "Tracking.SingleScenarioTrackingHeaderUnique",
    defaultMessage: "Unique",
  },
  scenario: {
    id: "Tracking.SingleScenarioTrackingHeaderScenario",
    defaultMessage: "Scénario",
  },
})

const SingleScenarioTrackingHeader: FunctionComponent<
  SingleScenarioTrackingHeaderProps
> = ({
  name,
  scenarioMode,
  startDate,
  endDate,
  handleDateChange,
  handleUnselect,
  minDate,
}) => {
  const { formatMessage } = useIntl()

  const scenarioModeLabel =
    scenarioMode === "simple" ? messages.unique : messages.scenario

  return (
    <div className="single-scen-track__header">
      <div className="single-scen-track__title">
        <p className="single-scen-track__title--label">{name}</p>
        <p
          className={classNames(
            "single-scen-track__title--mode",
            scenarioMode === "simple" ? "is-unique" : "is-scenario",
          )}
        >
          {formatMessage(scenarioModeLabel)}
        </p>
      </div>
      <TrackingDatePicker
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleDateChange}
        minDate={minDate}
      />
      <div className="single-scen-track__header--unselect">
        <button
          className="single-scen-track__header--btn"
          type="button"
          onClick={() => handleUnselect()}
        >
          {formatMessage(messages.ctaUnselect)}
        </button>
      </div>
    </div>
  )
}

export default SingleScenarioTrackingHeader
