import { FunctionComponent, ReactNode } from "react"

interface LeftSideColumnProps {
  children: ReactNode
}

export type LeftSideColumnType = FunctionComponent<LeftSideColumnProps>

const LeftSideColumn: LeftSideColumnType = ({ children }) => {
  return <div className="two-columns-layout__left-side">{children}</div>
}

export default LeftSideColumn
