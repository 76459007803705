import { FunctionComponent, HTMLAttributes } from "react"

import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { trialTunnelSelectors } from "features/Trial"

import { useOnBoardingDisplay } from "features/Accounts/components/OnBoardingTile/hooks/useOnBoardingDisplay"

import Tile from "features/Accounts/components/HomePageSingleTile"

import CampaignsTileDatas from "./components/CampaignsTileDatas"

import { ReactComponent as IconDuotoneMegaphone } from "assets/duotone/icon-megaphone-duotone.svg"

import { CAMPAIGNS_URL } from "router/RouterConstants"

interface CampaignsTileProps extends HTMLAttributes<HTMLDivElement> {
  isTrial: boolean
  className?: string
}

const messages = defineMessages({
  title: {
    id: "HomePage.CampaignsTileTitle",
    defaultMessage: "Campagnes",
  },
  infos: {
    id: "HomePage.CampaignsTileInfos",
    defaultMessage:
      "Ajoutez une bannière cliquable dans les signatures mails des collaborateurs.",
  },
})

const CampaignsTile: FunctionComponent<CampaignsTileProps> = ({
  isTrial,
  className,
  ...others
}) => {
  const { formatMessage } = useIntl()

  const isBannerUnlocked = useSelector(trialTunnelSelectors.bannerIsUnlocked)

  const onBoardingDisplay = useOnBoardingDisplay()

  return (
    <Tile
      {...others}
      className={classNames(
        className,
        "campaigns-tile",
        !onBoardingDisplay && "is-taller",
      )}
    >
      <Tile.Title
        icon={<IconDuotoneMegaphone />}
        label={formatMessage(messages.title)}
        to={!isTrial && CAMPAIGNS_URL}
      />
      <Tile.Infos message={formatMessage(messages.infos)} />
      <Tile.Datas
        className="signature-tile__datas--margin"
        isColumn={!onBoardingDisplay}
      >
        {isBannerUnlocked ? <CampaignsTileDatas /> : <Tile.NeedSubscription />}
      </Tile.Datas>
    </Tile>
  )
}

export default CampaignsTile
