import { FunctionComponent } from "react"

import Carousel from "react-material-ui-carousel"

import imageSignature from "./campaignScenarioPreview.svg"

import { BannerFormVm } from "features/Scenarios/ScenarioModels"

interface CampaignScenarioPreviewProps {
  banners: BannerFormVm[]
}

const CampaignScenarioPreview: FunctionComponent<
  CampaignScenarioPreviewProps
> = ({ banners }) => {
  return (
    <div className="campaign-scenario-preview">
      <Carousel
        className="CampaignCard__carousel"
        animation="slide"
        indicatorIconButtonProps={{
          className: "CampaignCard__carousel__indicator",
        }}
        activeIndicatorIconButtonProps={{
          className: "CampaignCard__carousel__indicator--active",
        }}
        navButtonsProps={{ className: "CampaignCard__carousel__button" }}
        navButtonsWrapperProps={{
          className: "CampaignCard__carousel__button-wrapper",
        }}
        indicatorContainerProps={{
          className: "CampaignCard__carousel__indicators",
        }}
        autoPlay={false}
      >
        {banners.map((banner, index) => (
          <div key={index} className="campaign-preview CampaignCard">
            <div className="campaign-preview__card">
              <div className="campaign-preview__banner">
                <div className="campaign-preview__signature">
                  <img src={imageSignature} />
                </div>
                <a href={banner.linkUrl} target="_blank">
                  <img src={banner.imageUrl} width={banner.width} />
                </a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default CampaignScenarioPreview
