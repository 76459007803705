import React, { ReactNode, useEffect, useState } from "react"
import { CollapsedCardContext } from "./Context"

interface Props {
  onExpandedCardChange?: (nextCardId: string, prevCardId: string) => void
  defaultExpandedCardId?: string
  children?: ReactNode
}

// Top level component, manage expanded cards
const CollapsedCards: React.FC<Props> = ({
  defaultExpandedCardId,
  onExpandedCardChange,
  children,
}) => {
  const [expandedCardId, setExpandedCardId] = useState(defaultExpandedCardId)

  useEffect(() => {
    setExpandedCardId(defaultExpandedCardId)
  }, [defaultExpandedCardId])

  const handleExpandedCardChange = (newExpandedCardId: string) => {
    const previousTabId = expandedCardId
    setExpandedCardId(newExpandedCardId)

    if (onExpandedCardChange !== undefined)
      onExpandedCardChange(newExpandedCardId, previousTabId)
  }

  return (
    <CollapsedCardContext.Provider
      value={{ expandedCardId, setExpandedCard: handleExpandedCardChange }}
    >
      {children}
    </CollapsedCardContext.Provider>
  )
}

export default CollapsedCards
