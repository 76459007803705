import React from "react"
import { DayRange } from "./entities/CalendarRange"

interface DayRangeProps {
  range: DayRange
  selectedId?: number
  hoveredId?: number
  onSelect: (id?: number) => void
  onHover: (id?: number) => void
  height?: number
  margin?: number
}

export const DayCellRange: React.FC<DayRangeProps> = ({
  range,
  onSelect,
  onHover,
  selectedId,
  hoveredId,
  height = 6,
  margin = 2,
}) => {
  const style = {
    background: range.color,
    height: height,
    marginTop: range.order * (height + margin),
    marginBottom: margin,
  }

  let className = "cv-btn cv-range "

  // Range Start/End
  if (range.isFirstDay) className += "cv-range-start "
  if (range.isLastDay) className += "cv-range-end "

  // Range Selected/Hovered
  if (selectedId) {
    selectedId === range.id
      ? (className += "cv-range-selected ")
      : (className += "")
    hoveredId === range.id
      ? (className += "cv-range-hovered ")
      : (className += "cv-range-not-hovered ")
  } else {
    hoveredId === range.id
      ? (className += "cv-range-hovered ")
      : (className += "cv-range-not-hovered ")
    !hoveredId ? (className += "cv-range-selected ") : (className += "")
  }

  /* Rendering */

  return (
    <div
      className={className}
      style={style}
      onClick={(e) => {
        e.stopPropagation()
        onSelect(range.id)
      }}
      onMouseOver={() => onHover(range.id)}
      onMouseOut={() => onHover()}
    ></div>
  )
}
