import React from "react"
import ArrowBtn from "components/ArrowBtn"

interface State {
  open: boolean
}

interface Props {
  title: string
  emphaze?: string
  icon?: any
  forceOpen?: boolean
  initialOpen?: boolean
}

class AccordionItem extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      open: props.initialOpen,
    }
  }

  static getDerivedStateFromProps(props, state) {
    // On permet le pilotage de l'ouverture depuis le parent via une props pour la gestion des erreurs
    if (props.forceOpen === true) return { open: true }
    return null
  }

  handleExpand = (evt) => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { open } = this.state
    const { title, emphaze, icon } = this.props

    return (
      <div className="accordion__item" data-cy="accordion__item">
        <div>
          <div
            className="accordion__item-header"
            data-cy="accordion__item-header"
          >
            <div
              className="accordion__item-title"
              data-cy="accordion__item-title"
            >
              <div>{title}</div>
              <div className="accordion__item-color-container">
                {icon}
                {emphaze && (
                  <div
                    className="accordion__item-color"
                    style={{ backgroundColor: emphaze }}
                  />
                )}
              </div>
            </div>

            <div className="accordion__arrow">
              <ArrowBtn
                direction={open ? "bottom" : "right"}
                styles="outlined"
                active={open}
                onClick={this.handleExpand}
              ></ArrowBtn>
            </div>
          </div>
        </div>
        <div
          className="accordion__item-content"
          style={{ height: open ? "inherit" : "0px", overflow: "hidden" }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default AccordionItem
