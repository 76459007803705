import {
  selectAllBuilderSignatures,
  selectBuilderSignatureById,
  selectBuilderSignatureIds,
  selectBuilderSignatureSliceById,
} from "./BuilderSignaturesReducers"

import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { GlobalStates } from "store"
import { BuilderSignature } from "./BuilderSignaturesModels"
import { SignaturesSlices } from "features/Signatures/SignaturesReducer"

const getActiveBuilderSignature = (
  state: GlobalStates,
): BuilderSignature | null => {
  const { id } = state.builderSignatures.editingBuilderSignature
  return id ? selectBuilderSignatureById(state, id) : null
}

const getBuilderSignatureById = (id: number) => (state: GlobalStates) =>
  selectBuilderSignatureById(state, id)

const getActiveBuilderSignatureUsers = (state: GlobalStates): Array<number> =>
  getActiveBuilderSignature(state)?.affectedUsers || []

const getActiveBuilderSignatureGroups = (state: GlobalStates): Array<number> =>
  getActiveBuilderSignature(state)?.affectedGroups || []

const getBuilderSignaturesSavingStatus = (state: GlobalStates): SavingStatus =>
  state.builderSignatures.editingBuilderSignature.savingStatus

const getActiveBuilderSignatureAssignment = (
  state: GlobalStates,
): { users: Array<number>; groups: Array<number> } =>
  state.builderSignatures.editingBuilderSignature.assignments

const getActiveBuilderSignatureNewMailValue = (state: GlobalStates): boolean =>
  state.builderSignatures.editingBuilderSignature.sendingMode.newMail

const getActiveBuilderSignatureInResponseValue = (
  state: GlobalStates,
): boolean =>
  state.builderSignatures.editingBuilderSignature.sendingMode.inResponse

const getActiveBuilderSignatureWeightingValue = (state: GlobalStates) =>
  state.builderSignatures.editingBuilderSignature.weighting

const getBuilderSignatureAffectUsersCountLoadingStatus =
  (signatureId: number) =>
  (state: GlobalStates): LoadingStatus =>
    selectBuilderSignatureById(state, signatureId).affectUsersCountLoadingStatus

const getBuilderSignatureAffectUsersCount =
  (signatureId: number) =>
  (state: GlobalStates): number =>
    selectBuilderSignatureById(state, signatureId).affectedUsersCount.length

const getBuilderSignatureAffectedUsers =
  (signatureId: number) =>
  (state: GlobalStates): number[] =>
    selectBuilderSignatureById(state, signatureId).affectedUsersCount

const getBuilderSignaturesSliceLoadingStatus =
  (slice: "all" | SignaturesSlices) => (state: GlobalStates) =>
    selectBuilderSignatureSliceById(state, slice).loadingStatus

const getBuilderDebugMode = (state: GlobalStates): boolean =>
  state.builderSignatures.debugMode

const getNeedSaveEditingBuilderSignature = (state: GlobalStates): boolean =>
  state.builderSignatures.editingBuilderSignature.needSave

export const selectors = {
  getAllBuilderSignatures: selectAllBuilderSignatures,
  getAllBuilderSignaturesIds: selectBuilderSignatureIds,
  getBuilderSignatureById,
  getActiveBuilderSignature,
  getActiveBuilderSignatureUsers,
  getActiveBuilderSignatureGroups,
  getBuilderSignaturesSliceLoadingStatus,
  getBuilderSignaturesSavingStatus,
  getActiveBuilderSignatureAssignment,
  getBuilderSignatureAffectUsersCountLoadingStatus,
  getBuilderSignatureAffectUsersCount,
  getBuilderSignatureAffectedUsers,
  getActiveBuilderSignatureNewMailValue,
  getActiveBuilderSignatureInResponseValue,
  getActiveBuilderSignatureWeightingValue,
  getBuilderDebugMode,
  getNeedSaveEditingBuilderSignature,
}
