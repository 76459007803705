import { Fragment, FunctionComponent, useCallback } from "react"

import { injectIntl, IntlShape } from "react-intl"

import useJobs from "../../hooks/useJobs"

import { JobData, JobState } from "../../JobsModels"

import JobNotification from "../JobNotification/JobNotification"

import { getJobMessage, messages } from "../../JobsMessages"

import "./BackgroundProcess.scss"

interface BackgroundProcessProps {
  intl: IntlShape
}

const BackgroundProcess: FunctionComponent<BackgroundProcessProps> = ({
  intl,
}) => {
  const { jobsInBackgroundProcessChannel: jobs, updateJob } = useJobs()

  const handleClose = useCallback(
    (jobData: JobData) => {
      updateJob({ ...jobData, open: false })
    },
    [updateJob],
  )

  return (
    <div className="background-process" data-cy="background-process">
      <div className="notification-stack">
        {jobs.map((jd) => (
          <Fragment key={jd.jobId}>
            {jd.state === JobState.Succeeded && (
              <JobNotification
                jobData={jd}
                withBackground={false}
                progress={false}
                icon="check_circle"
                iconStyle={{ color: "#2AD3C2", width: "24px" }}
                handleClose={() => handleClose(jd)}
                message={intl.formatMessage(messages.SuccessMsg)}
                displayName={intl.formatMessage(getJobMessage(jd))}
              />
            )}

            {jd.state === JobState.Failed && (
              <JobNotification
                jobData={jd}
                icon="error"
                progress={false}
                withBackground={false}
                iconStyle={{ color: "#E91E63", width: "24px" }}
                handleClose={() => handleClose(jd)}
                message={intl.formatMessage(messages.FailedMsg)}
                displayName={intl.formatMessage(getJobMessage(jd))}
              />
            )}

            {jd.state !== JobState.Succeeded &&
              jd.state !== JobState.Failed && (
                <JobNotification
                  message={
                    jd.state === JobState.Scheduled
                      ? intl.formatMessage(messages.ScheduledMsg)
                      : intl.formatMessage(messages.ProcessingMsg)
                  }
                  displayName={intl.formatMessage(getJobMessage(jd))}
                  jobData={jd}
                  withBackground={true}
                  icon={
                    jd.state === JobState.Scheduled ? "access_time" : "sync"
                  }
                  iconStyle={{ color: "#FFFFFF" }}
                  handleClose={() => handleClose(jd)}
                  progress={true}
                />
              )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default injectIntl(BackgroundProcess)
