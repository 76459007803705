import { FunctionComponent, useMemo, useState } from "react"

import { useSelector } from "react-redux"
import { defineMessages, useIntl } from "react-intl"

import classNames from "classnames"

import Weighting from "components/Weighting"
import DatePicker from "components/DatePicker"
import Toggle from "components/MUIToggle"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import DiffusionModeSelector from "features/Scenarios/components/DiffusionModeSelector"

import { featureNames } from "config/config.features"

import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { accountsSelectors } from "features/Accounts"
import { AdminAccount } from "features/Accounts/AccountsModels"

interface CampaignFormSettingsSidebarProps {
  showUrl?: boolean
}

const messages = defineMessages({
  settings: {
    id: "Campaigns.CampaignSettings",
    defaultMessage: "Paramètres de la campagne",
  },
  nameLabel: {
    id: "Campaigns.NameLabel",
    defaultMessage: "Nom",
  },
  nameError: {
    id: "Campaigns.NameError",
    defaultMessage: "Champs requis",
  },
  link: {
    id: "Campaigns.Link",
    defaultMessage: "Lien",
  },
  default: {
    id: "Campaigns.Default",
    defaultMessage: "Campagne par défaut",
  },
  additionalBanner: {
    id: "Campaigns.AdditionalBanner",
    defaultMessage: "Bannière additionnelle",
  },
  dates: {
    id: "Campaigns.Dates",
    defaultMessage: "Dates de publication",
  },
  datesRangeError: {
    id: "Campaigns.DateRangeError",
    defaultMessage:
      "L'intervalle de date doit être défini avec une date de début et une date de fin.",
  },
  weighting: {
    id: "Campaigns.Weighting",
    defaultMessage: "Niveau de priorité",
  },
  weightingText: {
    id: "Campaigns.WeightingText",
    defaultMessage:
      "Modifier le curseur de priorité pour définir la campagne qui s'affichera en priorité par rapport à d'autres",
  },
})

const CampaignFormSettingsSidebar: FunctionComponent<
  CampaignFormSettingsSidebarProps
> = ({ showUrl = true }) => {
  const { formatMessage } = useIntl()
  const { editingCampaign, updateEditingCampaign } = useScenarioFormContext()
  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)
  const [error, setError] = useState(false)
  const [dateRangeError, setDateRangeError] = useState(false)

  const hash = document.location.hash.slice(1)

  const isFeatureAdditionalBannerActive = useSelector(
    isFeatureActive(featureNames.ADDITIONAL_BANNER),
  )

  const currentAccount = useSelector(accountsSelectors.getCurrentAccount)

  const isCurrentAccountSuperAdmin = useMemo(() => {
    if (currentAccount === null) return false

    const adminAccountDto = {
      id: currentAccount.Id,
      roles: currentAccount.Roles,
      company: currentAccount.Company,
      userName: currentAccount.UserName,
    }

    const adminAccount = new AdminAccount(adminAccountDto)

    return adminAccount.isSuperAdmin()
  }, [currentAccount])

  const updateCampaignLink = (linkUrl: string) => {
    const campaign = {
      ...editingCampaign,
      banners: [{ ...editingCampaign.banners[0], linkUrl }],
    }
    updateEditingCampaign(campaign)
  }
  const updateDateRange = (startDate, endDate) => {
    const dateRangeIsCorrect =
      editingCampaign.default === true || (startDate != null && endDate != null)
    setDateRangeError(!dateRangeIsCorrect)
    updateEditingCampaign({
      startDate: startDate,
      endDate: endDate,
    })
  }
  const updateCampaignProperty = (property: string, value: unknown) => {
    const campaign = { ...editingCampaign }
    campaign[property] = value
    updateEditingCampaign(campaign)
  }

  return (
    <div className="campaignform__settings">
      <form>
        <h4>{formatMessage(messages.settings)}</h4>
        <h5>
          <span>{formatMessage(messages.nameLabel)}</span>
          {error && (
            <span className="error">{formatMessage(messages.nameError)}</span>
          )}
        </h5>
        <input
          data-cy="scenario-name__input"
          className={error ? "input error" : "input"}
          placeholder="Nommez la campagne"
          name="name"
          value={editingCampaign.name}
          required
          maxLength={128}
          onChange={(event) => {
            if (event.target.value) setError(false)
            updateCampaignProperty(event.target.name, event.target.value)
          }}
          autoFocus={hash === ""}
          onBlur={(event) => !event.target.value && setError(true)}
        />
        {showUrl && (
          <>
            <h5>{formatMessage(messages.link)}</h5>
            <input
              type="url"
              placeholder="URL de la campagne"
              className="input url"
              value={editingCampaign.banners[0]?.linkUrl}
              onChange={(event) => updateCampaignLink(event.target.value)}
              autoFocus={hash == "url"}
            />
          </>
        )}
        <h5>
          <span>{formatMessage(messages.default)}</span>
          <Toggle
            checked={editingCampaign.default}
            value={editingCampaign.default}
            onChange={(_event, checked) =>
              updateCampaignProperty("default", checked)
            }
          />
        </h5>
        {isFeatureAdditionalBannerActive && isCurrentAccountSuperAdmin && (
          <h5>
            <span>{formatMessage(messages.additionalBanner)}</span>
            <Toggle
              checked={editingCampaign.additional}
              value={editingCampaign.additional}
              onChange={(_event, checked) =>
                updateCampaignProperty("additional", checked)
              }
            />
          </h5>
        )}
        <div
          id="dates"
          className={classNames(
            "campaignform__settings--dates",
            editingCampaign.default && "is-disabled",
          )}
        >
          <h5>{formatMessage(messages.dates)}</h5>
          {dateRangeError && (
            <span className="error">
              {formatMessage(messages.datesRangeError)}
            </span>
          )}
          <DatePicker
            initialStartDate={editingCampaign.startDate}
            initialEndDate={editingCampaign.endDate}
            onDatesChange={updateDateRange}
            closeOnDateSelect
            isDisabled={editingCampaign.default}
          />
        </div>
        {internalExternalFeature?.isActive && <DiffusionModeSelector />}
        <h5>{formatMessage(messages.weighting)}</h5>
        <p>{formatMessage(messages.weightingText)}</p>
        <div className="weighting">
          <Weighting
            weighting={editingCampaign.weighting}
            onChange={(weighting) => {
              updateCampaignProperty("weighting", weighting)
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default CampaignFormSettingsSidebar
