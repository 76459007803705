import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import HomePageTileDatasContainer, {
  HomePageTileDatasContainerProps,
} from "./HomePageTileDatasContainer"

export type HomePageTileDatasContainerWithIconType = FunctionComponent<
  HomePageTileDatasContainerProps & { icon: ReactNode }
>

const HomePageTileDatasContainerWithIcon: HomePageTileDatasContainerWithIconType =
  ({ children, icon, isColumn, className, ...others }) => {
    return (
      <HomePageTileDatasContainer
        isColumn={isColumn}
        className={classNames("with-icon", className)}
        {...others}
      >
        {children}
        <div className="home-tile__datas--icon-container">{icon}</div>
      </HomePageTileDatasContainer>
    )
  }

export default HomePageTileDatasContainerWithIcon
