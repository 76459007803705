import React from "react"

import { FormattedMessage } from "react-intl"

import { EnrollmentContextProvider } from "features/Enrollment/components/EnrollmentContext"

import Page from "components/Page"

import EnrollmentForm from "features/Enrollment/EnrollmentForm"

const EnrollmentPage: React.FC = () => {
  return (
    <Page
      title={
        <FormattedMessage
          id="Enrollment.Title"
          defaultMessage="Souscrire un abonnement"
        />
      }
    >
      <EnrollmentContextProvider>
        <EnrollmentForm />
      </EnrollmentContextProvider>
    </Page>
  )
}

export default EnrollmentPage
