import { FunctionComponent, ReactNode } from "react"

import ScenariosListLayoutHeader from "./components/ScenariosListLayoutHeader"

import ScenariosListLayoutLoadingSkeleton from "./components/ScenariosListLayoutLoadingSkeleton"
import ScenariosListLayoutEmptyResults from "./components/ScenariosListLayoutEmptyResults"

import ScenariosListLayoutSubHeader, {
  ScenariosListLayoutSubHeaderType,
} from "./components/ScenariosListLayoutSubHeader"

import ScenariosListLayoutContent, {
  ScenariosListLayoutContentType,
} from "./components/ScenariosListLayoutContent"

interface ScenariosListLayoutComposition {
  SubHeader: ScenariosListLayoutSubHeaderType
  Content: ScenariosListLayoutContentType
  LoadingSkeleton: FunctionComponent
  EmptyResults: FunctionComponent
}

interface ScenariosListLayoutProps {
  children: ReactNode
}

type ScenariosListLayoutType = FunctionComponent<ScenariosListLayoutProps> &
  ScenariosListLayoutComposition

const ScenariosListLayout: ScenariosListLayoutType = ({ children }) => {
  return (
    <div className="scenarios-list-layout">
      <ScenariosListLayoutHeader />
      {children}
    </div>
  )
}

ScenariosListLayout.SubHeader = ScenariosListLayoutSubHeader
ScenariosListLayout.Content = ScenariosListLayoutContent
ScenariosListLayout.LoadingSkeleton = ScenariosListLayoutLoadingSkeleton
ScenariosListLayout.EmptyResults = ScenariosListLayoutEmptyResults

export default ScenariosListLayout
