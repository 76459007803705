import { ChangeEvent, FunctionComponent } from "react"

import { withStyles } from "@material-ui/styles"
import Slider from "@material-ui/core/Slider"

export interface WeightingSliderProps {
  weighting: number
  min: number
  max: number
  step: number
  onChange?: (value: number) => void
}

const SliderWithStyles = withStyles({
  root: {
    color: "#E0E0E0",
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#3D5AFE",
    boxShadow: "0 5px 6px 0 rgba(19,32,124,0.2)",
    marginTop: -6,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    color: "#E0E0E0",
    height: 2,
    borderRadius: 3,
  },
  rail: {
    color: "#E0E0E0",
    opacity: 1,
    height: 2,
    borderRadius: 3,
  },
  mark: {
    backgroundColor: "#E0E0E0",
    width: 5,
    height: 5,
    borderRadius: "50%",
    marginTop: -1,
  },
})(Slider)

const WeightingSlider: FunctionComponent<WeightingSliderProps> = ({
  weighting,
  min,
  max,
  step,
  onChange,
}) => {
  const handleChange = (_event: ChangeEvent, value: number) => {
    if (onChange) onChange(value)
  }

  if (weighting < 0 || max < weighting || min > weighting) return null

  return (
    <div className="weighting__slider">
      <SliderWithStyles
        min={min}
        max={max}
        step={step}
        value={weighting}
        onChange={handleChange}
        marks
      />
    </div>
  )
}

export default WeightingSlider
