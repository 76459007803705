/**
 * Helpers for Date manipulations
 *
 * @class DateHelper
 */

import moment from "moment"

class DateHelper {
  public static GetCurrentFormatedDate(format: string) {
    return moment().format(format)
  }

  public static ConvertToSQLDate(date: Date): string {
    return new Date().toISOString().slice(0, 19).replace("T", " ")
  }

  public static ConvertToENSQLDate(date: Date): string {
    return new Date().toISOString().slice(0, 19).replace("T", " ")
  }

  public static IsBeforeDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false

    const aYear = a.year()
    const aMonth = a.month()

    const bYear = b.year()
    const bMonth = b.month()

    const isSameYear = aYear === bYear
    const isSameMonth = aMonth === bMonth

    if (isSameYear && isSameMonth) return a.date() < b.date()
    if (isSameYear) return aMonth < bMonth
    return aYear < bYear
  }

  public static IsInclusivelyAfterDay(a, b): boolean {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false
    return !DateHelper.IsBeforeDay(a, b)
  }

  public static IsNotExpired(startDate: Date, endDate: Date) {
    const currentDate = new Date(Date.now())
    return currentDate > new Date(startDate) && currentDate < new Date(endDate)
  }

  /**
   * Retourne le nombre de jours entre la date de début et celle de fin.
   * Si la date de début est > à la date de fin, retourne un nombre négatif
   * @param startDate date de début
   * @param endDate date de fin / courante
   */
  public static GetNumberOfDaysSince(startDate, endDate) {
    const since = moment(endDate).diff(startDate, "days")
    return since
  }
}

export default DateHelper

export function normalizeDate(date: string | null) {
  if (date === null) return null

  return `${date.split("T")[0]}T10:00:00Z`
}
