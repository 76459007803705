import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface SingleScenarioTrackingInsufficientsDatasProps {
  title: string
}

const message = defineMessages({
  datas: {
    id: "Tracking.SingleScenarioTrackingInsufficientsDatas",
    defaultMessage:
      "En attente de données suffisantes pour afficher les taux de clics",
  },
})

const SingleScenarioTrackingInsufficientsDatas: FunctionComponent<
  SingleScenarioTrackingInsufficientsDatasProps
> = ({ title }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="single-scen-track__insuff">
      <p className="single-scen-track__insuff--title">{title}</p>
      <InfoIcon className="single-scen-track__insuff--icon" />
      <p className="single-scen-track__insuff--label">
        {formatMessage(message.datas)}
      </p>
    </div>
  )
}

export default SingleScenarioTrackingInsufficientsDatas
