import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, AlertStatus } from "../AlertsModels"
import { LoadingStatus } from "core/CoreModels"
import { getLoadingStatus } from "../AlertsSelectors"
import { fetchAllAlerts, updateAlert, selectAllAlerts, selectAlertById } from "../AlertsReducer"
import { GlobalStates } from "store"

interface UseAlerts {
  allAlertAreRead: boolean
  alerts: Alert[]
  loadingStatus: LoadingStatus
  setAlertStatus: (alertId: number) => void
  getAlert: (alertId: number) => Alert
}

const useAlerts = (): UseAlerts => {
  const dispatch = useDispatch()

  const loadingStatus = useSelector(getLoadingStatus)
  const alerts = useSelector(selectAllAlerts)
  const allAlertAreRead = alerts.every(alert => alert.readStatus != AlertStatus.New)
  const setAlertStatus = (alertId: number) => dispatch(updateAlert(alertId))
  const getAlert = (alertId: number) => useSelector((state: GlobalStates) => selectAlertById(state, alertId))

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchAllAlerts())
    }
  }, [loadingStatus, dispatch])

  return { allAlertAreRead, loadingStatus, alerts, setAlertStatus, getAlert }
}

export default useAlerts
