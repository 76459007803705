import React from "react"
import { FormattedMessage } from "react-intl"

export const Unauthorized: React.FC = () => {
  return (
    <div className="col-sm-12">
      <section className="row body-content body-content-without-nav">
        <section className="l-sub-header l-sub-header-without-nav">
          <h4>401</h4>
          <div className="l-sub-header__liseret"></div>
        </section>
        <div className="flex-bloc-center body-main-content p-5 pb-5">
          <h5 className="title-unautorized-page">
            <FormattedMessage id="Unauthorized.Sorry" defaultMessage="Desolé" />
            <br />
          </h5>
          <p className="text-unautorized-page">
            <FormattedMessage
              id="Unauthorized.Text"
              defaultMessage="Vous n'avez pas accès à cette page"
            />
          </p>
        </div>
      </section>
    </div>
  )
}

export default Unauthorized
