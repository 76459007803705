import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import Tile from "features/Accounts/components/HomePageSingleTile"

import CampaignsTileActiveCount from "./CampaignsTileActiveCount"
import CampaignsTileDispatch from "./CampaignsTileDispatch"
import CampaignsTileDatasLoading from "./CampaignsTileDatasLoading"

import useCampaignsTileDatas from "../hooks/useCampaignsTileDatas"
import { useOnBoardingDisplay } from "features/Accounts/components/OnBoardingTile/hooks/useOnBoardingDisplay"

import { CAMPAIGNS_URL } from "router/RouterConstants"

import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  emptyTitle: {
    id: "HomePage.CampaignsTileEmptyStateTitle",
    defaultMessage: "Ajouter votre première campagne",
  },
  label: {
    id: "HomePage.CampaignsTileActiveCountLabel",
    defaultMessage: "Campagne(s)",
  },
})

const CampaignsTileDatas: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const onBoardingDisplay = useOnBoardingDisplay()

  const { loadingStatus, count } = useCampaignsTileDatas()

  if (count.all === 0 && loadingStatus === LoadingStatus.LOADED)
    return (
      <Tile.AddButton to={`${CAMPAIGNS_URL}/edit/step/1`} isColumn>
        {formatMessage(messages.emptyTitle)}
      </Tile.AddButton>
    )

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <CampaignsTileDatasLoading>
        {formatMessage(messages.label)}
      </CampaignsTileDatasLoading>
    )

  return (
    <div
      className={classNames(
        "campaigns-tile__datas--container",
        onBoardingDisplay && "flex-row",
      )}
    >
      <CampaignsTileActiveCount allCount={count.all}>
        {formatMessage(messages.label)}
      </CampaignsTileActiveCount>
      <CampaignsTileDispatch
        campaignsCount={count.campaigns}
        scenariosCount={count.scenarios}
        displayAsColumn={onBoardingDisplay}
      />
    </div>
  )
}

export default CampaignsTileDatas
