import { fetchGWorkspaceDomainsAction } from "features/Accounts/AccountsActions"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/configureStore"
import { accountsSelectors } from "../../../Accounts"
import Feature from "../../FeaturesModels"

import GSuiteForm from "./GSuiteForm"

interface OwnProps {
  featureGSuite: Feature
  featureGmail: Feature
  updateFeature(feature: Feature)
  launchJob(jobName: string)
}

const ConnectedGSuiteForm: React.FC<OwnProps> = (props) => {
  const domains = useSelector((state: RootState) =>
    accountsSelectors.getGWorkspaceDomains(state),
  )
  const dispatch = useDispatch()
  const fetchDomains = (adminEmail: string) =>
    dispatch(fetchGWorkspaceDomainsAction(adminEmail))

  useEffect(() => {
    const adminEmailProp = props.featureGSuite.properties.find(
      (p) => p.property === "syncgsuiteadminmail",
    )
    const domainsProp = props.featureGSuite.properties.find(
      (p) => p.property === "syncgsuitedomain",
    )
    if (
      adminEmailProp != null &&
      adminEmailProp.value != null &&
      domainsProp != null &&
      domainsProp.value != null
    ) {
      // gsuite configured
      fetchDomains(adminEmailProp.value)
    }
  }, [])

  return <GSuiteForm {...props} domains={domains} fetchDomains={fetchDomains} />
}
export default ConnectedGSuiteForm
