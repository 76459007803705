/**
 * Composant de layout responsable de la répartition et la mise en forme des composants
 */
import React from "react"
import { FormattedMessage } from "react-intl"

interface BannersProps {
  children: React.ReactNode
}

const ScenarioLayout: React.FC<BannersProps> = ({ children }) => {
  return (
    <div className="col-12 body-content h-100">
      <section className="col-12 home h-100">
        <section className="l-sub-header">
          <h4>
            <FormattedMessage
              id="ScenariosPage.Title"
              defaultMessage="Gestion des campagnes"
            />
          </h4>
          <div className="l-sub-header__liseret" />
        </section>
        <div className="CampaignLayout h-100">{children}</div>
      </section>
    </div>
  )
}

export default ScenarioLayout
