import { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { defineMessages, useIntl } from "react-intl"

import EmptyPageCTA from "components/EmptyPageCTA"

import { trialTunnelSelectors } from "features/Trial"

import imageNewSignature from "features/Signatures/assets/icone-signature-large.svg"
import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

const messages = defineMessages({
  alert: {
    id: "BuilderSignature.NewSignatureAlert",
    defaultMessage:
      "Vous n’avez pas encore de signature de paramétrée. Ajoutez-en dès maintenant.",
  },
  title: {
    id: "BuilderSignature.NewSignatureTitle",
    defaultMessage: "Signature",
  },
  subtitle: {
    id: "BuilderSignature.NewSignatureSubtitle",
    defaultMessage: "Paramétrez votre première signature",
  },
  link: {
    id: "BuilderSignature.NewSignatureLink",
    defaultMessage: "Créer votre signature",
  },
})

const BuilderEmpty: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)

  return (
    <EmptyPageCTA>
      <EmptyPageCTA.Alert alert={formatMessage(messages.alert)} />
      <EmptyPageCTA.Cards>
        <EmptyPageCTA.Card
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subtitle)}
          image={imageNewSignature}
          imgAlt={formatMessage(messages.title)}
          link={formatMessage(messages.link)}
          to={
            !isTrialFinished ? `${TRIAL_TUNNEL_URL}/1` : "/portal/builder/edit"
          }
        />
      </EmptyPageCTA.Cards>
    </EmptyPageCTA>
  )
}

export default BuilderEmpty
