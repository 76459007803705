import cuid from "cuid"

import mime from "mime"

import DateHelper from "utils/DateHelper"
import { getFileExtension } from "utils/ImageUtils"

import { BannerFormVm } from "features/Scenarios/ScenarioModels"

import { uploadImageToStorageAsUrl } from "features/Storage/StorageApi"

function generateBannerName(): string {
  const format = "DDMMYYYY_HHmmss"
  const date = DateHelper.GetCurrentFormatedDate(format)
  const uniqueID = cuid().slice(-8)

  return `${date}_${uniqueID}`
}

async function uploadCanvaImageAsync(
  imageUrl: string,
  designId: string,
  canvaUrl: string,
) {
  const notAlreadyUploaded = imageUrl.startsWith(
    "https://export-download.canva",
  )

  if (!notAlreadyUploaded) return

  // On place l'id du design canva dans le nom du visuel pour le récupérer en cas d'édition
  const canvaFileName = `c__${designId}__${generateBannerName()}`
  const mimeType = mime.getType(canvaUrl) || ""

  const canvaUploadResultData = await uploadImageToStorageAsUrl(
    canvaUrl,
    canvaFileName,
    mimeType,
    { designId },
  )

  return canvaUploadResultData.result.url
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function uploadImageAsync(imageUrl: string) {
  if (imageUrl.indexOf("data:image/") === -1) return

  const extension = getFileExtension(imageUrl)
  const fallbackBannerName = generateBannerName()
  const safeName = `${fallbackBannerName}.${extension}`
  const fileType = mime.getType(extension) || ""
  //Gestion du redimensionnement via balise HTML width/height pour ne pas perdre de qualité de visuels et ne pas surcharger le serveur
  const uploadResultData = await uploadImageToStorageAsUrl(
    imageUrl,
    safeName,
    fileType,
  )
  return uploadResultData.result.url
}

export async function uploadBannerAsync(banner: BannerFormVm): Promise<string> {
  let bannerImageUrl = banner.imageUrl

  if (banner.canva) {
    const canvaImageUrl = await uploadCanvaImageAsync(
      bannerImageUrl,
      banner.canva.designId,
      banner.canva.canvaUrl,
    )

    if (canvaImageUrl) bannerImageUrl = canvaImageUrl
  }

  const uploadedImageUrl = await uploadImageAsync(banner.imageUrl)

  if (uploadedImageUrl) bannerImageUrl = uploadedImageUrl

  return bannerImageUrl
}
