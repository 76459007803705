import { FunctionComponent, MutableRefObject, useRef, useState } from "react"

import MouseTooltip from "components/MouseTooltip"

interface OverflowedCellProps {
  text: string
}

const OverflowedCell: FunctionComponent<OverflowedCellProps> = ({ text }) => {
  const cellRef: MutableRefObject<HTMLSpanElement> = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  const overflowedWidth = cellRef.current
    ? cellRef.current.scrollWidth > cellRef.current.clientWidth
    : false

  return (
    <>
      <span
        ref={cellRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="data-grid__overflowed-cell"
      >
        {text}
      </span>
      {overflowedWidth && (
        <MouseTooltip visible={isHovered}>{text}</MouseTooltip>
      )}
    </>
  )
}

export default OverflowedCell
