import { FunctionComponent } from "react"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"
import { formatAmount } from "utils/NumberHelper"

import "./EnrollmentStep.scss"
import iconRocket from "features/Enrollment/assets/icon-rocket-alt.svg"
import iconUsers from "features/Enrollment/assets/icon-users.svg"
import iconSignature from "features/Enrollment/assets/icon-signature-alt.svg"
import iconCampaign from "features/Enrollment/assets/icon-speaker-alt.svg"
import moment from "moment"
import {
  CAMPAIGNS_URL,
  SIGNATURES_URL,
  USERS_URL,
} from "router/RouterConstants"
import { NavLink } from "react-router-dom"
import { DEFAULT_OFFER_CURRENCY } from "features/Offers/OffersConstants"

const messages = defineMessages({
  ht: { id: "Enroll.HT", defaultMessage: "HT" },
  ttc: { id: "Enroll.TTC", defaultMessage: "TTC" },
})

const EnrollmentStepSuccess: FunctionComponent = () => {
  const { formatMessage, locale } = useIntl()
  const { enrollmentDatas } = useEnrollmentData()

  const paymentInfos =
    enrollmentDatas?.newEnrollmentInformations?.paymentInfos || {}

  return (
    <div className="enroll__step enroll__step__success">
      <div className="enroll__box enroll__main">
        <div className="enroll__form enroll__recap enroll__recap--success">
          <div className="enroll__recap__title">
            <div>
              <FormattedMessage id="Enroll.ThankYou" defaultMessage="Merci !" />
            </div>
            <img src={iconRocket} alt="" />
          </div>
          <br />
          <div className="enroll__recap__line">
            <div className="enroll__recap__label">
              <FormattedMessage
                id="Enroll.NbUsers"
                defaultMessage="Nombre d'utilsateurs"
              />
            </div>
            <div className="enroll__recap__price--small">
              {paymentInfos.licenceVolume}
            </div>
          </div>
          <div className="enroll__recap__line">
            <div className="enroll__recap__label">
              <FormattedMessage
                id="Enroll.Subscription"
                defaultMessage="Abonnement"
              />
            </div>
            <div className="enroll__recap__price--small">
              {paymentInfos.offerName}
            </div>
          </div>
          {paymentInfos.offerOptions && paymentInfos.offerOptions.length > 0 && (
            <div className="enroll__recap__line">
              <div className="enroll__recap__label">
                <FormattedMessage
                  id="Enroll.Options"
                  defaultMessage="Options"
                />
              </div>
              <div className="enroll__recap__price--small">
                {paymentInfos.offerOptions.map((option) => (
                  <div>{option.Name}</div>
                ))}
              </div>
            </div>
          )}
          <div className="enroll__recap__line">
            <div className="enroll__recap__label">
              <FormattedMessage
                id="Enroll.EnrollmentDate"
                defaultMessage="Date d'engagement"
              />{" "}
              <small>
                <FormattedMessage
                  id="Enroll.EnrollmentDuration"
                  defaultMessage="(365 jours)"
                />
              </small>
            </div>
            <div className="enroll__recap__price--small">
              <small>
                <FormattedMessage id="Enroll.FromDate" defaultMessage="du" />
              </small>{" "}
              {moment(paymentInfos.startDate).format("DD/MM/YYYY")}
              &nbsp;
              <small>
                <FormattedMessage id="Enroll.ToDate" defaultMessage="au" />
              </small>{" "}
              {moment(paymentInfos.endDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <hr />
          <div className="enroll__recap__line enroll__recap__line--total ">
            <div className="enroll__recap__label">
              <FormattedMessage
                id="Enroll.Amount"
                defaultMessage="Montant total"
              />
            </div>
            <div className="enroll__recap__price--small">
              {`${formatAmount(
                paymentInfos.paiementTTC,
                locale,
                DEFAULT_OFFER_CURRENCY.name,
              )} ${formatMessage(messages.ttc)}`}
              <br />
              <small>
                <small>
                  {`${formatAmount(
                    paymentInfos.paiementHT,
                    locale,
                    DEFAULT_OFFER_CURRENCY.name,
                  )} ${formatMessage(messages.ht)}`}
                </small>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="enroll__aside enroll_aside_success">
        <NavLink to={USERS_URL}>
          <Badge icon={iconUsers} title="Ajouter vos" subtitle="utilisateurs" />
        </NavLink>
        <NavLink to={SIGNATURES_URL}>
          <Badge
            icon={iconSignature}
            title="Créer votre"
            subtitle="signature"
          />
        </NavLink>
        <NavLink to={`${CAMPAIGNS_URL}/edit/step/1/?mode=multiple`}>
          <Badge icon={iconCampaign} title="Créer votre" subtitle="Campagne" />
        </NavLink>
      </div>
    </div>
  )
}

interface BadgeProps {
  icon: string
  title: string | JSX.Element
  subtitle: string | JSX.Element
}

const Badge: React.FC<BadgeProps> = ({ icon, title, subtitle }) => {
  return (
    <div className="enroll__aside__badge">
      <img className="enroll__aside__badge__icon" src={icon} alt="" />
      <div style={{ width: "100%" }}>
        <div className="enroll__aside__badge__title">{title}</div>
        <div className="enroll__aside__badge__subtitle">{subtitle}</div>
      </div>
    </div>
  )
}

export default EnrollmentStepSuccess
