import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import SearchField from "components/SearchField"
import PillButtonsGroup from "components/PillButtonsGroup"
import SelectField from "components/SelectField"

import { FilterValuesType } from "../scenariosListLayoutUtilities"
import { SelectedOption } from "components/SelectField/SelectField"

interface ScenariosListLayoutSubHeaderProps {
  filter: FilterValuesType
  handleSearch: (searchValue: string) => void
  handleChangeFilter: (filterValue: string) => void
  handleChangeSort: (option: SelectedOption) => void
}

export type ScenariosListLayoutSubHeaderType =
  FunctionComponent<ScenariosListLayoutSubHeaderProps>

const messages = defineMessages({
  searchPlaceholder: {
    id: "ScenariosListLayout.SearchPlaceholder",
    defaultMessage: "Rechercher",
  },
  all: {
    id: "ScenariosListLayout.AllScenarios",
    defaultMessage: "Toutes",
  },
  unique: {
    id: "ScenariosListLayout.Unique",
    defaultMessage: "Unique",
  },
  scenario: {
    id: "ScenariosListLayout.Scenario",
    defaultMessage: "Scénario",
  },
  alphabeticalOrder: {
    id: "ScenariosListLayout.AlphabeticalOrder",
    defaultMessage: "Ordre alphabétique",
  },
  creationDate: {
    id: "ScenariosListLayout.CreationDate",
    defaultMessage: "Date de création",
  },
  priority: {
    id: "ScenariosListLayout.Priority",
    defaultMessage: "Priorité",
  },
})

const ScenariosListLayoutSubHeader: ScenariosListLayoutSubHeaderType = ({
  filter,
  handleSearch,
  handleChangeFilter,
  handleChangeSort,
}) => {
  const { formatMessage } = useIntl()

  const defaultValue = {
    value: "creationDate",
    label: formatMessage(messages.creationDate),
  }

  const filterOptions = [
    { id: "all", children: <>{formatMessage(messages.all)}</> },
    { id: "unique", children: <>{formatMessage(messages.unique)}</> },
    { id: "scenario", children: <>{formatMessage(messages.scenario)}</> },
  ]

  const sortOptions = [
    { ...defaultValue },
    {
      value: "alphabeticalOrder",
      label: formatMessage(messages.alphabeticalOrder),
    },
    { value: "priority", label: formatMessage(messages.priority) },
  ]

  return (
    <div className="scenarios-list-layout__sub-header">
      <div className="scenarios-list-layout__sub-header--container">
        <SearchField
          onSearch={handleSearch}
          placeholder={formatMessage(messages.searchPlaceholder)}
        />
        <PillButtonsGroup
          current={filter}
          onChange={handleChangeFilter}
          pillButtons={filterOptions}
        />
        <SelectField
          options={sortOptions}
          defaultValue={defaultValue}
          onChange={handleChangeSort}
          isSearchable={false}
        />
      </div>
    </div>
  )
}

export default ScenariosListLayoutSubHeader
