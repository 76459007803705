import * as React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

// ex: https://codesandbox.io/s/vertical-list-forked-psylj?file=/index.js
// doc: https://github.com/atlassian/react-beautiful-dnd/
interface Props {
  items: { data: any; component: React.ReactNode }[]
  // On se sert d'une clé, présente dans la structure de données `data` pour maintenir l'id des éléments, par défaut la propriété `id`
  draggableKey?: string
  onDragEnd?(items: any[], newPosition: number, oldPosition: number): void
  draggable?: boolean
}

const ListDraggable: React.FC<Props> = ({
  items,
  onDragEnd,
  draggableKey = "id",
  draggable = true,
}) => {
  const grid = 10

  const getItemStyle = (isDragging, draggableStyle) => ({
    // Avoids jumping and all
    userSelect: "none",
    //padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle,
  })

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const moved = items[result.source.index]
    items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, moved)
    onDragEnd && onDragEnd(items, result.destination.index, result.source.index)
  }

  return (
    <div className="listdraggable">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="list-content"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable
                  isDragDisabled={!draggable}
                  key={"drag" + item.data[draggableKey]}
                  draggableId={"drag" + item.data[draggableKey]}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      {item.component}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default ListDraggable
