import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

import config from "config/config"

import {
  ADD_IN_OUTLOOK_URL,
  FAQ_ADD_IN,
} from "features/Parameters/ParametersConstants"

const outlookBubbleLogoUrl = "/images/bmm/install_panel_outlook_bubble.png"

const addInExampleUrl = "/images/bmm/install_panel_addin_example.png"

const selectUsers = "/images/bmm/install_panel_select_users.png"

const messages = defineMessages({
  disclaimer1: {
    id: "InstallerPanel.AddInOutlook.DisclaimerSentence1",
    defaultMessage:
      "L’utilisation de l’add-in nécessite un abonnement Microsoft 365.",
  },
  disclaimer2: {
    id: "InstallerPanel.AddInOutlook.DisclaimerSentence2",
    defaultMessage: 'Seul un compte "Administrateur" peut déployer l’Add-In.',
  },
  deploy1: {
    id: "InstallerPanel.AddInOutlook.DeploySentence1",
    defaultMessage:
      "1. Déployer l’Add-In Outlook sur votre environnement Microsoft 365",
  },
  deploy2: {
    id: "InstallerPanel.AddInOutlook.DeploySentence2",
    defaultMessage: "Compatible uniquement avec le pack Office 365",
  },
  deploy3: {
    id: "InstallerPanel.AddInOutlook.DeploySentence3",
    defaultMessage:
      'Il vous faut un compte "Administrateur" pour le déploiement',
  },
  deployButton: {
    id: "InstallerPanel.AddInOutlook.DeployButton",
    defaultMessage: "Déployer",
  },
  selectUsers: {
    id: "InstallerPanel.AddInOutlook.SelectUsers",
    defaultMessage: '2. Selectionnez vos utilisateurs et cliquez sur "Suivant"',
  },
  helpTitle: {
    id: "InstallerPanel.AddInOutlook.HelpTitle",
    defaultMessage: "Besoin d’aide pour l’installation ?",
  },
  helpSentence: {
    id: "InstallerPanel.AddInOutlook.HelpSentence",
    defaultMessage: "Rendez-vous sur notre page d'aide à l'installation",
  },
  helpLink: {
    id: "InstallerPanel.AddInOutlook.HelpLink",
    defaultMessage: "Page d’aide à l’installation de l'Add-In Outlook",
  },
  helpExample: {
    id: "InstallerPanel.AddInOutlook.HelpExample",
    defaultMessage: "Exemple de l'Add-In dans votre Outlook",
  },
})

const InstallerOutlook: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="installer-outlook">
      <div className="installer-outlook__disclaimer">
        <InfoIcon className="installer-outlook__disclaimer--icon" />
        <div className="installer-outlook__disclaimer--sentences">
          <p className="installer-outlook__disclaimer--single-sentence">
            {formatMessage(messages.disclaimer1)}
          </p>
          <p className="installer-outlook__disclaimer--single-sentence">
            {formatMessage(messages.disclaimer2)}
          </p>
        </div>
      </div>
      <div className="installer-outlook__deploy">
        <div className="installer-outlook__deploy--sentences">
          <p className="installer-outlook__deploy--title">
            {formatMessage(messages.deploy1)}
          </p>
          <p className="installer-outlook__deploy--single-sentence">
            {formatMessage(messages.deploy2)}
          </p>
          <p className="installer-outlook__deploy--single-sentence">
            {formatMessage(messages.deploy3)}
          </p>
        </div>
        <a
          className="installer-outlook__deploy--button"
          target="_blank"
          href={ADD_IN_OUTLOOK_URL}
        >
          <img
            className="installer-outlook__deploy--button-logo"
            alt="outlook logo"
            src={`${config.cdnUrl}${outlookBubbleLogoUrl}`}
          />
          <span className="installer-outlook__deploy--button-label">
            {formatMessage(messages.deployButton)}
          </span>
        </a>
      </div>
      <p className="installer-outlook__select-users--sentence">
        {formatMessage(messages.selectUsers)}
      </p>

      <img
        className="installer-outlook__select-users--capture"
        alt="capture addin"
        src={`${config.cdnUrl}${selectUsers}`}
      />
      <p className="installer-outlook__help--title">
        {formatMessage(messages.helpTitle)}
      </p>
      <p className="installer-outlook__help--sentence">
        {`${formatMessage(messages.helpSentence)} : `}
        <a
          className="installer-outlook__help--link"
          target="_blank"
          href={FAQ_ADD_IN}
        >
          {formatMessage(messages.helpLink)}
        </a>
      </p>

      <img
        className="installer-outlook__help--capture"
        alt={formatMessage(messages.helpExample)}
        src={`${config.cdnUrl}${addInExampleUrl}`}
      />

      <p className="installer-outlook__help--example">
        {formatMessage(messages.helpExample)}
      </p>
    </div>
  )
}

export default InstallerOutlook
