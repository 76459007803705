import React, { FunctionComponent } from "react"
import classNames from "classnames"
import config from "config/config"
import { defineMessages, useIntl } from "react-intl"
import CollapsedCards, { CollapsedCard } from "components/Card/CollapsedCard"
import Feature from "../../FeaturesModels"

import Loader from "components/Loader"
import { featureNames } from "config/config.features"

import O365Form from "../O365Form"
import ApiForm from "../ApiForm"
import ConnectedGSuiteForm from "../GSuiteForm"
import OWAForm from "../OWAForm"
import LuccaLogoEnable from "../../images/lucca-logo--enable.png"
import LuccaLogoDisable from "../../images/lucca-logo--disable.png"

import LuccaFeature from "../LuccaFeature"
import SellsyFeature from "../SellsyFeature"
import Salesforce from "../Salesforce"

import salesforceLogoEnable from "../../images/salesforce-logo--enable.png"
import salesforceLogoDisable from "../../images/salesforce-logo--disable.png"

import logoSellsyInactive from "features/Features/assets/logo-sellsy-inactive.svg"
import logoSellsyActive from "features/Features/assets/logo-sellsy-active.svg"
import { ReactComponent as LogoExchangeServerActive } from "features/Features/assets/logo-exchange-server-active.svg"
import { ReactComponent as LogoExchangeServerInactive } from "features/Features/assets/logo-exchange-server-inactive.svg"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { AvailableJobs } from "features/Jobs/JobsConstants"

import { LoadingStatus } from "core/CoreModels"

interface Props {
  features: Feature[]
  loading: LoadingStatus
  backgroundTasks: boolean
  updateFeature: (feature: Feature) => void
  launchJob: (jobName: AvailableJobs) => void
}

const messages = defineMessages({
  chooseSync: {
    id: "SyncProvider.ChooseSync",
    defaultMessage: "Synchronisez en temps réel les informations utilisateurs",
  },
  owaLabel: {
    id: "SyncProvider.OWALabel",
    defaultMessage: "Exchange Server",
  },
})

const SyncProvider: FunctionComponent<Props> = ({
  features,
  updateFeature,
  launchJob,
  loading,
  backgroundTasks,
}) => {
  const { formatMessage } = useIntl()

  const isOWAFeatureActive = useFeatureTogglr(featureNames.OWA)

  function getFirstActiveFeature(): string {
    const activeFeature = features.find((f) => f.activated === true)
    if (activeFeature != null) return activeFeature.section
  }

  const getActiveClass = (featureNames: string[], imageName) => {
    const isActive = (featureName) =>
      features.find((f) => f.name === featureName).activated
    const atLeastOneActive = featureNames.some(isActive)
    return `${config.cdnUrl}/images/bmm/${imageName}${
      !atLeastOneActive ? "_disabled" : ""
    }.svg`
  }

  const salesforceFeature = useFeatureTogglr(featureNames.SALESFORCE)
  return (
    <>
      <div className="sync-provider" data-tour="sync-provider">
        <Loader fullScreen isLoading={loading === LoadingStatus.PENDING} />
        <div className="sync-provider__text">
          {formatMessage(messages.chooseSync)}
          <Loader isLoading={backgroundTasks} />
        </div>
        {features.length > 0 && (
          <CollapsedCards defaultExpandedCardId={getFirstActiveFeature()}>
            <CollapsedCard
              cardId="o365"
              header={
                <img
                  alt="o365"
                  src={getActiveClass(["O365"], "office_365")}
                  className="sync-provider__image--o365"
                />
              }
            >
              <O365Form
                featureO365={features.find((f) => f.name === "O365")}
                updateFeature={updateFeature}
                launchJob={(feature) => launchJob(feature.jobName)}
              />
            </CollapsedCard>
            {isOWAFeatureActive?.isActive && (
              <CollapsedCard
                cardId="owa"
                header={
                  <div className="sync-provider__owa--container">
                    {features.find((f) => f.name === "OWA")?.activated ? (
                      <LogoExchangeServerActive />
                    ) : (
                      <LogoExchangeServerInactive />
                    )}
                    <p
                      className={classNames(
                        "sync-provider__owa--label",
                        features.find((f) => f.name === "OWA")?.activated &&
                          "is-active",
                      )}
                    >
                      {formatMessage(messages.owaLabel)}
                    </p>
                  </div>
                }
              >
                <OWAForm
                  featureOWA={features.find((f) => f.name === "OWA")}
                  updateFeature={updateFeature}
                  launchJob={(feature) => launchJob(feature.jobName)}
                />
              </CollapsedCard>
            )}
            <CollapsedCard
              cardId="gsuite"
              header={
                <img
                  alt="gsuite"
                  src={getActiveClass(["GSuite", "Gmail"], "gsuite")}
                  className="sync-provider__image--gsuite"
                />
              }
            >
              <ConnectedGSuiteForm
                featureGSuite={features.find((f) => f.name === "GSuite")}
                featureGmail={features.find((f) => f.name === "Gmail")}
                updateFeature={updateFeature}
                launchJob={launchJob}
              />
            </CollapsedCard>
            <CollapsedCard
              cardId="api"
              header={
                <img
                  alt="api"
                  src={getActiveClass(["Api"], "api")}
                  className="sync-provider__image--api"
                />
              }
            >
              <ApiForm
                featureApi={features.find((f) => f.name === "Api")}
                updateFeature={updateFeature}
              />
            </CollapsedCard>
            <CollapsedCard
              cardId="lucca"
              header={
                <img
                  alt="lucca"
                  src={
                    features.find((f) => f.name === "Lucca").activated
                      ? LuccaLogoEnable
                      : LuccaLogoDisable
                  }
                  className="sync-provider__image--lucca"
                />
              }
            >
              <LuccaFeature
                featureLucca={features.find((f) => f.name === "Lucca")}
                launchJob={(feature) => launchJob(feature.jobName)}
                updateFeature={updateFeature}
              />
            </CollapsedCard>
            {salesforceFeature?.isActive && (
              <CollapsedCard
                cardId="Salesforce"
                header={
                  <img
                    alt="salesforce"
                    src={
                      features.find((f) => f.name === "Salesforce").activated
                        ? salesforceLogoEnable
                        : salesforceLogoDisable
                    }
                    className="sync-provider__image--salesforce"
                  />
                }
              >
                <Salesforce
                  feature={features.find((f) => f.name === "Salesforce")}
                  launchJob={(feature) => launchJob(feature.jobName)}
                  updateFeature={updateFeature}
                />
              </CollapsedCard>
            )}
            <CollapsedCard
              cardId="sellsy"
              header={
                <img
                  alt="Logo Sellsy"
                  src={
                    features.find((f) => f.name === "Sellsy")?.activated
                      ? logoSellsyActive
                      : logoSellsyInactive
                  }
                />
              }
            >
              <SellsyFeature
                featureSellsy={features.find((f) => f.name === "Sellsy")}
                launchJob={(feature) => launchJob(feature.jobName)}
                updateFeature={updateFeature}
              />
            </CollapsedCard>
          </CollapsedCards>
        )}
      </div>
    </>
  )
}

export default SyncProvider
