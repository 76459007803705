import { FunctionComponent, useMemo, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import SignatureCard from "features/Scenarios/components/ScenarioBySignaturesCard"

import { SignaturesScenBySignsFeat } from "features/Scenarios/ScenarioModels"

const messages = defineMessages({
  search: {
    id: "Campaigns.SearchSignaturesPlaceholder",
    defaultMessage: "Rechercher des signatures",
  },
  choices: {
    id: "Campaigns.SignatureAssignationsTitle",
    defaultMessage: "Choix des signatures",
  },
})

const CampaignFormSignaturesSidebar: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { editingCampaign, updateEditingCampaign, allSignatures } =
    useScenarioFormContext()
  const [search, setSearch] = useState("")

  const searchedSignatures = useMemo(
    () =>
      allSignatures.filter((signature) =>
        signature.name.toLowerCase().includes(search.toLowerCase()),
      ),
    [allSignatures, search],
  )

  const handleCheckSignature = (signatureId: number) => () => {
    const isIncluded = editingCampaign.signatureIds.some(
      (id: number) => id === signatureId,
    )
    const signatureIds = isIncluded
      ? editingCampaign.signatureIds.filter((id: number) => id !== signatureId)
      : [...editingCampaign.signatureIds, signatureId]

    updateEditingCampaign({ signatureIds })
  }

  return (
    <div className="campaignform__users">
      <h4>{formatMessage(messages.choices)}</h4>
      <div className="search-field">
        <input
          placeholder={formatMessage(messages.search)}
          className="search-field__input"
          onChange={(event) => setSearch(event.target.value)}
          value={search}
          autoFocus
        />
        {search ? (
          <i
            onClick={() => setSearch("")}
            className="search-field__icon search-field__icon--close material-icons"
          >
            close
          </i>
        ) : (
          <i className="search-field__icon search-field__icon--search material-icons">
            search
          </i>
        )}
      </div>

      <div className="signatures-list">
        {searchedSignatures.map((signature: SignaturesScenBySignsFeat) => (
          <SignatureCard
            key={signature.id}
            signature={signature}
            isChecked={editingCampaign.signatureIds.some(
              (id) => id === signature.id,
            )}
            onCheck={handleCheckSignature(signature.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default CampaignFormSignaturesSidebar
