import { FunctionComponent } from "react"

import { ReactComponent as SmallGraphIcon } from "features/Tracking/assets/small_loading_graph.svg"

const SIGNATURES_DEPLOYMENT_LOADING = 4

const X_AXIS_BUBBLE_LOADING = 6

const SignatureDeploymentLoading: FunctionComponent = () => {
  return (
    <div className="signatures-deployment">
      <div className="signatures-deployment__left-side">
        <div className="signatures-deployment__skeleton--left-side-container">
          {[...Array(SIGNATURES_DEPLOYMENT_LOADING).keys()].map((el) => (
            <div key={el} className="signatures-deployment__skeleton--block">
              <div className="signatures-deployment__skeleton--bar" />
              <div className="signatures-deployment__skeleton--bubble" />
              <div className="signatures-deployment__skeleton--bubble" />
            </div>
          ))}
        </div>
      </div>
      <div className="signatures-deployment__right-side">
        <div className="signatures-deployment__skeleton--right-side-container">
          <div className="signatures-deployment__skeleton--y-axis-container">
            {[...Array(X_AXIS_BUBBLE_LOADING).keys()].map((el) => (
              <div
                key={el}
                className="signatures-deployment__skeleton--graph-bubble"
              />
            ))}
          </div>
          <div className="signatures-deployment__skeleton--graph-container">
            <SmallGraphIcon className="signatures-deployment__skeleton--graph" />
            <div className="signatures-deployment__skeleton--x-axis-container">
              {[...Array(X_AXIS_BUBBLE_LOADING).keys()].map((el) => (
                <div
                  key={el}
                  className="signatures-deployment__skeleton--graph-bubble"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignatureDeploymentLoading
