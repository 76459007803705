import StringHelper from "../utils/StringHelper"

export class TenantProperty {
  Id: number
  Property: string
  Value: string

  constructor(property: string, value: string) {
    this.Property = property
    this.Value = value
  }

  isEmpty = () => {
    return this.Value != null
  }

  static fromJson(jsonObject) {
    const tenantProperty: TenantProperty = new TenantProperty(null, null)
    for (const prop in jsonObject) {
      tenantProperty[StringHelper.Capitalize(prop)] = jsonObject[prop]
    }
    return tenantProperty
  }
}

export default TenantProperty
