import {
  DiffusionMode,
  ScenarioEntity,
  ScenariosSlices,
  selectAllScenarios,
  selectScenarioById,
  selectScenarioSliceById,
  selectScenariosCustomQueryById,
} from "./ScenarioReducers"
import { flatten, uniqBy } from "lodash"

import { GlobalStates } from "store"
import { BannerDto, Scenario } from "./ScenarioModels"
import { LoadingStatus } from "core/CoreModels"

/** Meta selectors **/
const getLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.scenarios.loadingStatus

/** Derived Data selectors */
const getVisuals = (
  state: GlobalStates,
): Array<Omit<BannerDto, "template" | "id" | "linkUrl">> =>
  uniqBy(
    flatten(
      selectAllScenarios(state)?.map((scenario) => {
        return scenario.props.banners.map((banner) => ({
          imageUrl: banner.props.imageUrl,
          width: banner.props.width,
          height: banner.props.height,
        }))
      }),
    ),
    "imageUrl",
  )

const getUsersCount =
  (scenarioId: number) =>
  (state: GlobalStates): number => {
    const entity = state.scenarios.allScenarios.entities[
      scenarioId
    ] as unknown as ScenarioEntity
    return entity?.usersCount || 0
  }

const getUsersCountLoadingStatus =
  (scenarioId: number) =>
  (state: GlobalStates): LoadingStatus => {
    const entity = state.scenarios.allScenarios.entities[
      scenarioId
    ] as unknown as ScenarioEntity
    return entity?.usersCountLoadingStatus || LoadingStatus.NOT_STARTED
  }

const getDiffusionMode = (state: GlobalStates): DiffusionMode =>
  state.scenarios.diffusionMode

const getActivatedScenariosAssignedToGroup =
  (groupId: number) =>
  (state: GlobalStates): Scenario[] =>
    selectAllScenarios(state).filter(
      (scenario) =>
        scenario.props.activated &&
        scenario.props.groupIds.includes(groupId) &&
        (scenario.props.endDate === null || scenario.props.endDate.isAfter()),
    )

const getScenarioSliceLoadedStatus =
  (slice: "all" | ScenariosSlices) =>
  (state: GlobalStates): LoadingStatus =>
    selectScenarioSliceById(state, slice).loadingStatus

const getScenariosCustomQueryResultById =
  (requestId: string) => (state: GlobalStates) => {
    const customQueryEntity = selectScenariosCustomQueryById(state, requestId)

    if (!customQueryEntity) return null

    return customQueryEntity.result
  }

export const selectors = {
  getAllScenarios: selectAllScenarios,
  getScenarioById: selectScenarioById,
  getLoadingStatus,
  getVisuals,
  getUsersCount,
  getUsersCountLoadingStatus,
  getDiffusionMode,
  getActivatedScenariosAssignedToGroup,
  getScenarioSliceLoadedStatus,
  getScenariosCustomQueryResultById,
}
