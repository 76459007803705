import classBuilder from "classnames"
import { FC } from "react"
import "./Divider.scss"

interface Props {
  verticale?: boolean
  fullwidth?: boolean
  color?: string
}

const Divider: FC<Props> = ({verticale, fullwidth, color}) => {

  const buttonClass = classBuilder({
    "bmm-divider": true,
    "bmm-divider--verticale": verticale,
    "bmm-divider--fullwidth": fullwidth,
  })

  return <div
      className={buttonClass}
      style={{
        color,
      }}
        >

  </div>
}

export default Divider