import { FunctionComponent, useState, useCallback } from "react"

import SignaturePreview from "../../SignaturePreview"

interface SignatureCardPreviewProps {
  template?: string
}

export type SignatureCardPreviewType =
  FunctionComponent<SignatureCardPreviewProps>

const SignatureCardPreview: SignatureCardPreviewType = ({ template }) => {
  const [height, setHeight] = useState(0)

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])
  return (
    <div className="signature-card__preview" ref={measuredRef}>
      {template && <SignaturePreview template={template} cardHeight={height} />}
    </div>
  )
}

export default SignatureCardPreview
