import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import Dialog from "@material-ui/core/Dialog"

interface Props {
  open: boolean
  message?: string
  title?: string
  handleClose()
  action()
}

const ConfirmModal: React.FC<Props> = ({open, message, title, handleClose, action}) =>{
  const [isOpen, setIsOpen] = useState(open)

  useEffect(()=>{
    setIsOpen(open)
  },[open])
  

  const handleButtonClose = () => {
    setIsOpen(false)
    handleClose()
  }

  const confirmAction = () => {
    action()
    handleClose()
  }

  const renderMessage = () => {
    if (message != null) {
      // hack pour le caractère '\n' dans le message
      return message.split("\n").map((item, index) => {
        return (
          <span key={index}>
            {item}
            <br />
          </span>
        )
      })
    }
    return (
      <div>
        <b>
          <FormattedMessage
            id="ConfirmModal.Warning"
            defaultMessage="Attention! "
          />
        </b>
        <span>
          <FormattedMessage
            id="ConfirmModal.WarningAction"
            defaultMessage="Cette action est irréversible."
          />
        </span>
      </div>
    )
  }


    return (
      <div>
          <Dialog
            className="confirm-modal"
            open={isOpen}
            onClose={handleButtonClose}
          >
            {title &&
              <div className="confirm-modal__header">
                <div className="confirm-modal__header__title">
                  {title}
                </div>
                <div className="confirm-modal__header__button" onClick={handleButtonClose}>
                  <i className="material-icons">{"add"}</i>
                </div>
              </div>
            }
            <div className="confirm-modal__current">{renderMessage()}</div>
            <div className="bmm-button__container bmm-button__container">
              <a onClick={handleButtonClose}>
                <div className="bmm-button bmm-button__cancel-modal">
                  <div>
                    <span className="bmm-button__intitule">
                      <FormattedMessage
                        id="ConfirmModel.Cancel"
                        defaultMessage="Annuler"
                      />
                    </span>
                  </div>
                </div>
              </a>
              <a onClick={confirmAction}>
                <div className="bmm-button bmm-button__validate-modal">
                  <div>
                    <span className="bmm-button__intitule">
                      <FormattedMessage
                        id="ConfirmModal.Validate"
                        defaultMessage="Valider"
                      />
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </Dialog>
      </div>
    )
  }


export default ConfirmModal
