import { FunctionComponent } from "react"
import Carousel from "react-material-ui-carousel"

interface ScenarioCardsCarouselProps {
  images: string[]
}

export type ScenarioCardsCarouselType =
  FunctionComponent<ScenarioCardsCarouselProps>

const ScenarioCardsCarousel: ScenarioCardsCarouselType = ({ images }) => {
  if (images.length === 0) return null

  if (images.length === 1)
    return (
      <div
        className="scenario-card__carousel--item"
        style={{ backgroundImage: `url(${images[0]})` }}
      />
    )

  return (
    <Carousel
      className="scenario-card__carousel"
      animation="slide"
      navButtonsProps={{
        className: "scenario-card__carousel--button",
        style: {
          backgroundColor: "white",
          color: "#2AD3C2",
          opacity: 1,
        },
      }}
      autoPlay={false}
    >
      {images.map((image, index) => (
        <div
          key={index}
          className="scenario-card__carousel--item"
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}
    </Carousel>
  )
}

export default ScenarioCardsCarousel
