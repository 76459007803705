import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import { ReactComponent as StopIcon } from "assets/icon-stop.svg"

interface AssignationsPanelItemProps {
  children: ReactNode
  title: string
  emptyLabel: string
  isEmpty: boolean
  isFirst?: boolean
}

export type AssignationsPanelItemType =
  FunctionComponent<AssignationsPanelItemProps>

const AssignationsPanelItem: AssignationsPanelItemType = ({
  children,
  title,
  emptyLabel,
  isEmpty,
  isFirst,
}) => {
  const emptyDiv = (
    <div className="assignations-panel__item--empty-container">
      <div className="assignations-panel__item--empty-icon">
        <StopIcon />
      </div>
      <p className="assignations-panel__item--empty-label">{emptyLabel}</p>
    </div>
  )

  return (
    <div
      className={classNames("assignations-panel__item", isFirst && "is-first")}
    >
      <div
        className={classNames(
          "assignations-panel__item--title",
          isFirst && "is-first",
        )}
      >
        <p className="assignations-panel__item--label">{title}</p>
      </div>
      <div className="assignations-panel__item--content">
        {isEmpty ? emptyDiv : children}
      </div>
    </div>
  )
}

export default AssignationsPanelItem
