import { Feature, FeatureSet } from "./FeatureTogglrModels"
import { GlobalStates } from "store"

export const getFeatures = (state: GlobalStates): FeatureSet =>
  state.featureTogglr.entities

export const getFeature = (
  state: GlobalStates,
  featureName: string,
): Feature | null => getFeatures(state)[featureName]

export const isFeatureActive =
  (featureName: string) =>
  (state: GlobalStates): boolean =>
    getFeatures(state)[featureName]?.isActive || false

export const getFeaturesLoadingStatus = (state: GlobalStates) =>
  state.featureTogglr.loadingStatus
