import { get, set } from "lodash"

/**
 * Retourne une collection avec les éléments inversés, réordonnée
 * @param items collection
 * @param oldValue ancienne valeur de l'item cible
 * @param newValue nouvelle valeur de l'item cible
 * @param orderProperties nom de la propriété de l'objet, utilisée pour le swap et le re-order
 * @returns
 */
const swap = (items, oldValue, newValue, orderProperties = "order") => {
  const source = items.find((i) => get(i, orderProperties) === oldValue)
  const dest = items.find((i) => get(i, orderProperties) === newValue)
  if (source == null || dest == null)
    throw new Error(
      `swap: item of key: '${orderProperties}' not found in the collection`,
    )

  set(source, orderProperties, newValue)
  set(dest, orderProperties, oldValue)
  return items.sort((a, b) =>
    get(a, orderProperties) > get(b, orderProperties) ? 1 : -1,
  )
}

export default swap
