import { useSelector } from "react-redux"

import { selectors as jobsSelectors } from "features/Jobs/JobSelectors"

import { AvailableJobs } from "features/Jobs/JobsConstants"

function useJobIsRunning(job: AvailableJobs | string) {
  const jobsQueue = useSelector(jobsSelectors.getJobsQueue)

  return jobsQueue
    .filter((j) => j.state === 2)
    .map((j) => j.jobName)
    .includes(job)
}

export default useJobIsRunning
