import { CSSProperties, FunctionComponent } from "react"

import Notification, { NotificationAction } from "components/Notification"

import { JobData } from "features/Jobs/JobsModels"

interface JobNotificationProps {
  jobData: JobData
  progress: boolean
  displayName: string
  message: string
  handleClose: (jobData: JobData) => void
  icon: string
  iconStyle: CSSProperties
  withBackground: boolean
}

const JobNotification: FunctionComponent<JobNotificationProps> = ({
  jobData,
  progress,
  displayName,
  message,
  handleClose,
  icon,
  iconStyle,
  withBackground,
}) => (
  <Notification
    key={jobData.jobId}
    open={jobData.open}
    transition={"left"}
    handleClose={() => handleClose(jobData)}
    progress={progress}
    action={
      <NotificationAction onClick={() => handleClose(jobData)}>
        FERMER
      </NotificationAction>
    }
  >
    <div className="notification__content">
      <div
        className={
          withBackground
            ? "notification__background-icon"
            : "notification__background-icon--without-bg"
        }
      >
        <i className="material-icons" style={iconStyle}>
          {icon}
        </i>
      </div>
      <div className="notification__text">
        {displayName} {message}
      </div>
    </div>
  </Notification>
)

export default JobNotification
