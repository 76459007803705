import React from "react"
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded"
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded"
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded"
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded"

interface Props {
  direction: "top" | "right" | "bottom" | "left"
  styles: {} | "outlined" | "filled"
  onClick(evt)
  active?: boolean
}

const styles = {
  outlined: {
    active: { color: "#3D5AFE", backgroundColor: "#F2F4FF" },
    inactive: {
      border: "1px solid #3D5AFE",
      backgroundColor: "#FFFFFF",
      color: "#3D5AFE",
    },
  },
  filled: {
    active: { color: "#FFFFFF", backgroundColor: "#3D5AFE" },
    inactive: { color: "#FFFFFF", backgroundColor: "rgba(61,90,254,0.5)" },
  },
}

class ArrowBtn extends React.Component<Props> {
  getDirectionIcon = (direction: string) => {
    switch (direction) {
      case "top":
        return <ExpandLessRoundedIcon />
      case "right":
        return <KeyboardArrowRightRoundedIcon />
      case "bottom":
        return <ExpandMoreRoundedIcon />
      default:
        return <KeyboardArrowLeftRoundedIcon />
    }
  }

  getStyle = (style) => {
    // style directe
    if (typeof style === "object") return style

    if (styles[style] == null) return styles["outlined"].active

    if (this.props.active) return styles[style].active
    else return styles[style].inactive
  }

  render() {
    const { onClick, direction, styles: style } = this.props

    return (
      <div
        className="arrow-btn"
        onClick={(evt) => onClick(evt)}
        style={this.getStyle(style)}
      >
        {this.getDirectionIcon(direction)}
      </div>
    )
  }
}

export default ArrowBtn
