import { FunctionComponent } from "react"

import moment, { Moment } from "moment"

import DatePicker from "components/DatePicker"
import { TRACKING_DATE_PICKER_PAST_LOCK } from "features/Tracking/TrackingConstants"

interface TrackingDatePickerProps {
  startDate: Moment
  endDate: Moment
  onDatesChange: (startDate: Moment, endDate: Moment) => void
  minDate?: Moment
}

const TrackingDatePicker: FunctionComponent<TrackingDatePickerProps> = ({
  startDate,
  endDate,
  onDatesChange,
  minDate,
}) => {
  return (
    <div className="tracking-date-picker">
      <DatePicker
        initialStartDate={startDate}
        initialEndDate={endDate}
        onDatesChange={onDatesChange}
        isDayBlocked={(day) =>
          moment(day).isAfter() ||
          moment(day).isBefore(
            minDate
              ? minDate
              : moment().subtract(
                  TRACKING_DATE_PICKER_PAST_LOCK.amount as moment.DurationInputArg1,
                  TRACKING_DATE_PICKER_PAST_LOCK.unit as moment.unitOfTime.DurationConstructor,
                ),
          )
        }
        maxDate={moment().add(1, "days")}
      />
    </div>
  )
}

export default TrackingDatePicker
