import moment from "moment"

import SharedTrackingMapper from "./SharedTrackingMapper"

import {
  GRAPH_COLORS,
  GRAPH_LIGHT_COLORS,
} from "features/Tracking/TrackingConstants"

import {
  SignatureTracking,
  TrackingGraphData,
  SignatureTrackingHistory,
  SignaturesClickedDto,
  SignaturePowerUserHistory,
  SignaturesHistoryDto,
  BaseHistoryEvent,
  SignaturesDeploymentTrackingDatasVOneDto,
} from "../TrackingModels"
import { User } from "features/Users/UserModels"

const SignatureTrackingMapper = {
  getSeriesFromSignatureTrackingHistory(history: SignatureTrackingHistory[]): {
    id: string
    label: string
  }[] {
    return history
      .map((history) =>
        Object.entries(history.data).map(([key, values]) => ({
          id: key,
          label: values.label,
        })),
      )
      .flat()
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.id === value.id && t.label === value.label),
      )
  },
  getGraphDataFromHistory(signature: SignatureTracking): TrackingGraphData[] {
    const { history } = signature
    const series =
      SignatureTrackingMapper.getSeriesFromSignatureTrackingHistory(history)

    const totals = {
      id: "total",
      label: "total",
      display: true,
      hover: false,
      selected: false,
      data: history
        .map((el) => ({
          x: el.date,
          y: Object.values(el.data)
            .map((value) => value.quantity)
            .reduce((acc, v) => acc + v, 0),
        }))
        .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
    }

    return [
      totals,
      ...series.map((serie) => ({
        id: serie.id,
        label: serie.label,
        display: true,
        hover: false,
        selected: false,
        data: history
          .map((el) => ({
            x: el.date,
            y: el.data[serie.id]?.quantity || 0,
          }))
          .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
      })),
    ].map((serie, i) => ({
      ...serie,
      color: GRAPH_COLORS[i % GRAPH_COLORS.length],
      secondaryColor: GRAPH_LIGHT_COLORS[i % GRAPH_LIGHT_COLORS.length],
    }))
  },
  getSignaturesPowerUserHistory(
    signaturesClickedDatas: SignaturesClickedDto[],
    userId: number,
  ) {
    const userClickedData = signaturesClickedDatas.filter(
      (b) => b.userId === userId,
    )
    if (userClickedData.length === 0) return null

    const historyGroupedBySignature: {
      [key: number]: SignaturePowerUserHistory
    } = {}

    userClickedData.forEach((historyEvent) => {
      if (historyEvent.signatureId in historyGroupedBySignature) {
        historyGroupedBySignature[historyEvent.signatureId].nbClicks +=
          historyEvent.nbClicks
        historyGroupedBySignature[historyEvent.signatureId].history.push({
          date: historyEvent.clickedDate,
          quantity: historyEvent.nbClicks,
        })
      } else {
        historyGroupedBySignature[historyEvent.signatureId] = {
          userId,
          signatureId: historyEvent.signatureId,
          history: [
            {
              date: historyEvent.clickedDate,
              quantity: historyEvent.nbClicks,
            },
          ],
          nbClicks: historyEvent.nbClicks,
        }
      }
    })

    const maxClicksHistory = Object.values(historyGroupedBySignature).reduce(
      (prev, curr) => (prev.nbClicks > curr.nbClicks ? prev : curr),
    )

    return maxClicksHistory
  },
  getSignatureTotalClicks(signatureClickedEvents: SignaturesClickedDto[]) {
    return signatureClickedEvents.reduce((acc, curr) => acc + curr.nbClicks, 0)
  },
  getSignatureMaxClicks(signatureClickedEvents: SignaturesClickedDto[]) {
    return SharedTrackingMapper.getHistoryFromEventsClicked(
      signatureClickedEvents,
    ).reduce((prev, curr) => (prev.quantity > curr.quantity ? prev : curr), {
      date: moment(new Date()).format("YYYY-MM-DD"),
      quantity: 0,
    })
  },
  getSignaturesTrackingDataHistory(
    signatureClickedEvents: SignaturesClickedDto[],
  ) {
    const historyGroupedByDate: { [key: string]: SignatureTrackingHistory } = {}

    signatureClickedEvents.forEach((clickedEvent) => {
      const { clickedDate } = clickedEvent
      const clickedEventLabel =
        SignatureTrackingMapper.getSignatureHistoryEventLabel(clickedEvent.link)
      clickedDate in historyGroupedByDate
        ? clickedEventLabel in historyGroupedByDate[clickedDate].data
          ? (historyGroupedByDate[clickedDate].data[
              clickedEventLabel
            ].quantity += clickedEvent.nbClicks)
          : (historyGroupedByDate[clickedDate].data[clickedEventLabel] = {
              label: clickedEventLabel,
              value: clickedEvent.link,
              quantity: clickedEvent.nbClicks,
            })
        : (historyGroupedByDate[clickedDate] = {
            date: clickedDate,
            data: {
              [clickedEventLabel]: {
                value: clickedEvent.link,
                label: clickedEventLabel,
                quantity: clickedEvent.nbClicks,
              },
            },
          })
    })

    return Object.values(historyGroupedByDate)
  },
  getSignatureHistoryEventLabel(eventLinkValue: string): string {
    const PROPERTY_REGEXP = /\?#(?<property>.*)#\?/gim

    const URL_REGEXP =
      /(?:(?:http?)[s]*:\/\/)?([a-z0-9-%/&=?]*\.)*(?<domain>[a-z0-9-%/&=?]+)\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?/gim

    const linkIsProperty = PROPERTY_REGEXP.exec(eventLinkValue)

    if (linkIsProperty !== null && linkIsProperty?.groups?.property)
      return linkIsProperty.groups.property

    const linkIsUrl = URL_REGEXP.exec(eventLinkValue)

    if (linkIsUrl !== null && linkIsUrl?.groups?.domain)
      return linkIsUrl.groups.domain

    return eventLinkValue
  },
  getSignaturesDeploymentsDataHistory(
    signatureHistoryEvents: SignaturesHistoryDto[],
  ): BaseHistoryEvent[] {
    return signatureHistoryEvents.map((event) => ({
      date: event.created,
      quantity:
        event.users.length === 0
          ? 0
          : Math.ceil(
              (event.users.filter((u) => u.delivered === true).length * 100) /
                event.users.length,
            ),
    }))
  },
  getSignatureDeployedUsers(
    usersDeployed: SignaturesDeploymentTrackingDatasVOneDto[],
    allUsers: User[],
    signatureId: number,
  ): number[] {
    return usersDeployed
      .filter((userDeployed) => userDeployed.signatureIds.includes(signatureId))
      .map((userDeployed) => {
        const currentUser = allUsers.find(
          (user) =>
            user.Username.toLowerCase() ===
            userDeployed.userEmail.toLowerCase(),
        )
        return currentUser?.Id
      })
      .filter((userDeployed) => userDeployed !== undefined)
  },
  getSignatureRelatedUsersState(historyEvents: SignaturesHistoryDto[]) {
    if (historyEvents.length === 0) return []

    const mostRecentEvent = historyEvents.reduce((a, b) => {
      return new Date(a.created) > new Date(b.created) ? a : b
    })

    return mostRecentEvent.users
  },
}

export default SignatureTrackingMapper
