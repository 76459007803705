import { FunctionComponent, LabelHTMLAttributes, ReactNode } from "react"

import classNames from "classnames"

import Toggle from "components/Toggle"

interface ToggleFieldProps {
  children: ReactNode
  name: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  onChange?: (toggle: boolean) => void
  defaultChecked?: boolean
  disabled?: boolean
  className?: string
}

const ToggleField: FunctionComponent<ToggleFieldProps> = ({
  children,
  name,
  labelProps,
  onChange,
  disabled,
  className,
  defaultChecked,
}) => {
  const { className: labelClassName, ...othersLabelProps } = labelProps || {
    className: undefined,
  }

  return (
    <div className={classNames("toggle-field", className)}>
      <Toggle
        name={name}
        onChange={onChange}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <label
        htmlFor={name}
        className={classNames("toggle-field__label", labelClassName)}
        {...othersLabelProps}
      >
        {children}
      </label>
    </div>
  )
}

export default ToggleField
