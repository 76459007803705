import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

interface SignatureCardActionsProps {
  children: ReactNode
  small?: boolean
}

export type SignatureCardActionsType =
  FunctionComponent<SignatureCardActionsProps>

const SignatureCardActions: SignatureCardActionsType = ({
  children,
  small,
}) => {
  return (
    <div className={classNames("signature-card__actions", small && "small")}>
      {children}
    </div>
  )
}

export default SignatureCardActions
