import CanvaApi from "./Canva"

interface Props {
  handleCanva(options: { exportUrl; designId })
  designId?: string
}

const CanvaButton: React.FC<Props> = ({ handleCanva, designId }) => {
  const createDesign = () => {
    CanvaApi.Api.createDesign({
      design: {
        type: "EmailHeader",
      },
      onDesignPublish: (opts) => {
        handleCanva(opts)
      },
    })
  }

  // https://docs.developer.canva.com/button/javascript/editing-designs
  const editDesign = () =>
    CanvaApi.Api.editDesign({
      design: {
        id: designId,
      },
      onDesignPublish: (opts) => {
        handleCanva(opts)
      },
    })

  const canvaAction = designId != null ? editDesign : createDesign
  return (
    <button className="canva-button canva-button" onClick={canvaAction}>
      Lancer le designer <div className="canva-button__logo" />
    </button>
  )
}

export default CanvaButton
