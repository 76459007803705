import { FunctionComponent, ReactNode } from "react"

interface EmptyPageCardsProps {
  children: ReactNode
}

export type EmptyPageCardsType = FunctionComponent<EmptyPageCardsProps>

const EmptyPageCards: EmptyPageCardsType = ({ children }) => {
  return <div className="empty-page-cta__cards">{children}</div>
}

export default EmptyPageCards
