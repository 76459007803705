import React, { FunctionComponent } from "react"

import { useDispatch } from "react-redux"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import config from "config/config"

import { resetEditingUser } from "features/Users/UsersReducers"

import { useNavigate } from "react-router-dom"

import { MenuList } from "@material-ui/core"

import Tooltip from "components/Tooltip"
import MenuListItem from "components/MenuListItem"

import ConnectedImportExcelPopup from "../../ImportExcelPopup"

interface UsersHeaderProps {
  refresh: () => void
  hasSubsidiary: boolean
}

const messages = defineMessages({
  menuCreation: {
    id: "UsersHeader.menuCreation",
    defaultMessage: "Formulaire de création",
  },
  menuExcel: { id: "UsersHeader.menuExcel", defaultMessage: "Fichier Excel" },
})

const UsersHeader: FunctionComponent<UsersHeaderProps> = ({
  refresh,
  hasSubsidiary,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [openMenu, setOpenMenu] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openModalImport, setOpenModalImport] = React.useState<boolean>(false)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpenMenu(!openMenu)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenMenu(false)
  }

  const handleCreateNewUser = () => {
    dispatch(resetEditingUser())
    navigate("/portal/users/-1")
  }

  return (
    <>
      <ConnectedImportExcelPopup
        open={openModalImport}
        close={() => setOpenModalImport(false)}
      />

      <Tooltip
        show={openMenu}
        parent={anchorEl}
        onClickAway={handleClose}
        onClickInside={handleClose}
        arrow={false}
        position={"bottom-start"}
      >
        <div className="users-header__add-menu">
          <MenuList disablePadding>
            <MenuListItem
              label={intl.formatMessage(messages.menuCreation)}
              onClick={() => handleCreateNewUser()}
              icon={
                <img
                  alt="creation"
                  src={`${config.cdnUrl}/images/bmm/users/ic_clipboard.svg`}
                  className="users-header__add-menu__icon"
                />
              }
              iconHover={
                <img
                  alt="creation"
                  src={`${config.cdnUrl}/images/bmm/users/ic_clipboard_hover.svg`}
                  className="users-header__add-menu__icon"
                />
              }
            ></MenuListItem>
            <MenuListItem
              label={intl.formatMessage(messages.menuExcel)}
              onClick={() => setOpenModalImport(true)}
              icon={
                <img
                  alt="export"
                  src={`${config.cdnUrl}/images/bmm/users/ic_logo_excel.svg`}
                  className="users-header__add-menu__icon"
                />
              }
              iconHover={
                <img
                  alt="export"
                  src={`${config.cdnUrl}/images/bmm/users/ic_logo_excel_hover.svg`}
                  className="users-header__add-menu__icon"
                />
              }
            ></MenuListItem>
          </MenuList>
        </div>
      </Tooltip>

      <div className="users-header">
        {!hasSubsidiary && (
          <div
            className={`users-header__action ${
              openMenu ? "users-header__action--active" : ""
            }`}
            onClick={handleMenu}
          >
            <div data-tour="user-header__add-btn">
              <i className="material-icons">person_add</i>
              <div className="users-header__action-libelle">
                <FormattedMessage
                  id="UsersHeader.Add"
                  defaultMessage="Ajouter"
                />
              </div>
            </div>
          </div>
        )}
        <div className="users-header__action" onClick={refresh}>
          <i className="material-icons">refresh</i>
          <div className="users-header__action-libelle">
            <FormattedMessage
              id="RefreshBtn.Refresh"
              defaultMessage="Actualiser"
            />
          </div>
        </div>
        <div className="users-header__action">
          <a href={config.webApiUrl + "/api/sync/export"}>
            <img
              alt="export_img"
              className="users-header__action--image"
              src={`${config.cdnUrl}/images/bmm/users/ic_excel.svg`}
            />
            <div className="users-header__action-libelle users-header__action-libelle__export">
              <FormattedMessage
                id="UsersHeader.Export"
                defaultMessage="Exporter"
              />
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default UsersHeader
