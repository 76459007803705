import { FunctionComponent, useMemo, useRef } from "react"

import { SignatureRenderer } from "bmm-builder"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

interface SignatureCardJsonPreviewProps {
  signature: BuilderSignature
}

export type SignatureCardJsonPreviewType =
  FunctionComponent<SignatureCardJsonPreviewProps>

const SignatureCardJsonPreview: SignatureCardJsonPreviewType = ({
  signature,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const scale = useMemo(() => {
    if (containerRef.current === null) return 1

    const containerHeight = containerRef.current.getBoundingClientRect()?.height

    const parsedSignatureJSON = JSON.parse(signature.json)

    const signatureHeight = parsedSignatureJSON?.document?.height

    if (containerHeight && signatureHeight)
      return containerHeight / signatureHeight
  }, [containerRef.current, signature])

  return (
    <div ref={containerRef} className="signature-card__preview">
      <SignatureRenderer templateJSON={signature.json} scale={scale} />
    </div>
  )
}

export default SignatureCardJsonPreview
