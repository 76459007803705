import React from "react"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { makeStyles } from "@material-ui/styles"
import { KeyboardArrowDown } from "@material-ui/icons"
import ListItemText from "@material-ui/core/ListItemText"

interface Props {
  options: any[]
  value: any[]
  onChange(value: any[])
  idProperty: string
  displayProperty: string
  emptyPlaceholder?: string
  error?: boolean
}

const useStyles = makeStyles({
  root: {
    fontSize: 14,
  },
})

const MultiSelect: React.FC<Props> = (props) => {
  const {
    options,
    value,
    onChange,
    idProperty,
    displayProperty,
    emptyPlaceholder,
    error,
  } = props
  const classes = useStyles(props)

  const getSelectedOptions = (selectedList) => {
    return selectedList.map((so) => options.find((o) => o[idProperty] === so))
  }

  const handleChange = (event) => {
    const selectedOptions = getSelectedOptions(event.target.value)
    onChange(selectedOptions)
  }

  const isSelected = (option) => {
    return value.some((o) => o[idProperty] === option[idProperty])
  }

  return (
    <div className="multiselect" data-cy="multiselect">
      <Select
        error={error}
        variant="outlined"
        fullWidth
        margin="dense"
        multiple
        displayEmpty
        value={value.map((so) => so[idProperty])}
        onChange={handleChange}
        renderValue={(selected: string[]) => {
          if (selected.length === 0) return emptyPlaceholder || ""

          const selectedOptions = getSelectedOptions(selected)
          return selectedOptions.map((o) => o[displayProperty]).join(", ")
        }}
        IconComponent={KeyboardArrowDown}
        input={<OutlinedInput labelWidth={0} className="multiselect__input" />}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 176,
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option[idProperty]}
            dense
            className="multiselect__options"
            classes={{ root: classes.root }}
            divider
          >
            <ListItemText
              primary={option[displayProperty]}
              className={`${isSelected(option) ? "" : "multiselect__option"}`}
            />
            {isSelected(option) && (
              <i className="material-icons multiselect__item-icon">done</i>
            )}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default MultiSelect
