import { FunctionComponent, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import StringHelper from "utils/StringHelper"
import { reorderUserProperties } from "features/BuilderSignatures/BuilderSignaturesHelpers"

import Loader from "components/Loader"

import {
  BuilderPage,
  BuilderConfiguration,
  BuilderContextPartialState,
} from "bmm-builder"

import PortalBuilderToolbar from "features/BuilderSignatures/components/PortalBuilderToolbar"
import SendPreviewField from "features/BuilderSignatures/components/SendPreviewField"
import { builderSignaturesSelectors } from "features/BuilderSignatures"
import {
  createBuilderSignature,
  setNeedSave,
  updateBuilderSignature,
} from "features/BuilderSignatures/BuilderSignaturesReducers"
import { sendBuilderSignaturePreviewAction } from "features/BuilderSignatures/BuilderSignaturesActions"

import { addNotification } from "features/Notifications/NotificationReducer"
import { Notification } from "features/Notifications/NotificationModels"

import { usersSelectors } from "features/Users"
import useUsersData from "features/Users/hooks/useUsersData"
import useGroupsData from "features/Groups/hooks/useGroupsData"
import { useOnClickOutside } from "hooks/useOnClickOutside"

import { ErrorService } from "features/Errors/ErrorsService"

import { replaceImageBoxSrc } from "features/BuilderSignatures/BuilderProcessors"

import config from "config/config"

import { userPropertiesTranslationsForBuilder } from "features/BuilderSignatures/BuilderSignaturesTranslations"

import {
  CDN_DOMAIN,
  DEFAULT_BUILDER_SCENE_SIZE,
  PROD_STORAGE_DOMAIN,
} from "features/BuilderSignatures/BuilderSignaturesConstants"

import { LoadingStatus, SavingStatus } from "core/CoreModels"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

const messages = defineMessages({
  mustBeSaved: {
    id: "SignaturesBuilder.MustBeSaved",
    defaultMessage: "La signature doit être enregistrée",
  },
  mustBeNamed: {
    id: "SignaturesBuilder.MustBeNamed",
    defaultMessage: "La signature doit avoir un nom",
  },
})

const SignaturesBuilder: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const sendPreviewRef = useRef(null)
  const needSaveRef = useRef(null)
  const [signatureName, setSignatureName] = useState("")
  const [displaySendPreview, setDisplaySendPreview] = useState(false)
  const [builderSignatureId, setBuilderSignatureId] = useState<number | null>(
    null,
  )

  useOnClickOutside(sendPreviewRef, () => {
    setDisplaySendPreview(false)
    setBuilderSignatureId(null)
  })

  const { loadingStatus: usersLoadingStatus } = useUsersData()
  const { loadingStatus: groupsLoadingStatus } = useGroupsData()

  const userProperties = useSelector(usersSelectors.getUserProperties)

  const debugMode = useSelector(builderSignaturesSelectors.getBuilderDebugMode)

  const activeBuilderSignature: BuilderSignature | null = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignature,
  )
  const savingStatus = useSelector(
    builderSignaturesSelectors.getBuilderSignaturesSavingStatus,
  )

  const newMail = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureNewMailValue,
  )
  const inResponse = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureInResponseValue,
  )

  const weighting = useSelector(
    builderSignaturesSelectors.getActiveBuilderSignatureWeightingValue,
  )

  const builderConfiguration: BuilderConfiguration = {
    size: DEFAULT_BUILDER_SCENE_SIZE,
    magnetism: {
      mode: "guided",
      stuckValue: 20,
    },
    collision: "chained",
    debug: debugMode,
    linkableProperties: reorderUserProperties(
      userProperties
        .filter(
          (u) => u.InternalName !== "banner" && u.InternalName !== "picture",
        )
        .map((userProperty) => {
          let propertyLabel = StringHelper.Capitalize(userProperty.InternalName)
          if (userProperty.InternalName in userPropertiesTranslationsForBuilder)
            propertyLabel = formatMessage(
              userPropertiesTranslationsForBuilder[userProperty.InternalName],
            )
          return {
            id: userProperty.Id,
            label: `#${propertyLabel}`,
            value: `?#${userProperty.InternalName}#?`,
            property: userProperty.InternalName,
          }
        }),
    ),
  }

  const notificationMustBeSaved = () =>
    dispatch(
      addNotification(
        new Notification(formatMessage(messages.mustBeSaved), "WARNING"),
      ),
    )

  const notificationMustBeNamed = () =>
    dispatch(
      addNotification(
        new Notification(formatMessage(messages.mustBeNamed), "WARNING"),
      ),
    )

  const handleBuilderError = (builderError) => {
    const errorService: ErrorService = new ErrorService(config.logUrl)

    const stack = JSON.stringify({
      builderState: builderError.builderState,
      componentStack: builderError.errorInfo.componentStack,
    })

    errorService.catchError({
      message: builderError.error.message,
      stack,
      feature: "Builder",
    })
  }

  const handleCreateBuilderSignature = (name, json) => {
    dispatch(
      createBuilderSignature({ name, json, newMail, inResponse, weighting }),
    )
  }

  const handleUpdateBuilderSignature = (id, name, json) => {
    dispatch(
      updateBuilderSignature({
        id,
        name,
        json,
        newMail,
        inResponse,
        weighting,
      }),
    )
  }

  const handleSaveBuilderSignature = (signature) => {
    dispatch(setNeedSave(false))
    needSaveRef.current = null
    const signatureName = JSON.parse(signature.json).document.name
    if (signature.id) {
      handleUpdateBuilderSignature(signature.id, signatureName, signature.json)
    } else {
      handleCreateBuilderSignature(signatureName, signature.json)
    }
  }

  const handleSendTest = (signature) => {
    const signatureName = JSON.parse(signature.json).document.name

    if (!signature.id) {
      notificationMustBeSaved()
      return
    }

    if (signatureName?.length === 0) {
      notificationMustBeNamed()
      return
    }

    setBuilderSignatureId(signature.id)
    setDisplaySendPreview(true)
  }

  const handleSendPreview = (email: string) => {
    dispatch(sendBuilderSignaturePreviewAction(builderSignatureId, [email]))
    setBuilderSignatureId(null)
    setDisplaySendPreview(false)
  }

  const handleExport = (partialState: BuilderContextPartialState) => {
    if (needSaveRef.current === null) {
      needSaveRef.current = partialState.timeMachine.capsules
      return
    }

    needSaveRef.current = partialState.timeMachine.capsules

    const onlyAddTemplates = partialState.timeMachine.capsules.every(
      (c) => c.action === "ADD_TEMPLATE",
    )
    if (!onlyAddTemplates) dispatch(setNeedSave(true))
  }

  return (
    <div className="signatures-builder">
      <Loader
        fullScreen
        isLoading={
          usersLoadingStatus === LoadingStatus.PENDING ||
          groupsLoadingStatus === LoadingStatus.PENDING
        }
      />
      <BuilderPage
        builderConfiguration={builderConfiguration}
        toolbar={
          <PortalBuilderToolbar
            onSave={handleSaveBuilderSignature}
            signatureName={signatureName}
            activeBuilderSignature={activeBuilderSignature}
          />
        }
        onSave={handleSaveBuilderSignature}
        onSendTest={handleSendTest}
        onError={handleBuilderError}
        onChangeName={setSignatureName}
        onExport={handleExport}
        templateDTO={activeBuilderSignature}
        isSaving={savingStatus === SavingStatus.SAVING}
        postLoadProcessors={[
          replaceImageBoxSrc(CDN_DOMAIN, PROD_STORAGE_DOMAIN),
        ]}
        preSaveProcessors={[
          replaceImageBoxSrc(PROD_STORAGE_DOMAIN, CDN_DOMAIN),
        ]}
      />
      <SendPreviewField
        display={displaySendPreview}
        sendPreviewRef={sendPreviewRef}
        onSendPreview={handleSendPreview}
      />
    </div>
  )
}

export default SignaturesBuilder
