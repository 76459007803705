import React from "react"
import config from "config/config"

interface Props {
  status: string
}

const StatusIcon: React.FC<Props> = (props) => {
  const { status } = props

  return (
    <div className="status-icon">
      {status === "error" && (
        <img src={config.cdnUrl + "/images/bmm/ic_error.svg"} alt="error" />
      )}
      {status === "warning" && (
        <img src={config.cdnUrl + "/images/bmm/ic_alert.svg"} alt="warning" />
      )}
      {status === "ok" && (
        <img src={config.cdnUrl + "/images/bmm/ic_check.svg"} alt="ok" />
      )}
    </div>
  )
}

export default StatusIcon
