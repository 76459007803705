import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ErrorMessage } from "./ErrorsModel"

/**
 * Error State - Gestion des erreurs
 */
const initialState = []

const errorReducers = createSlice({
  name: "errors",
  initialState,
  reducers: {
    catchFrontError(state, action: PayloadAction<ErrorMessage>) {
      state.push(action.payload)
      return state
    },
  },
})

export const { catchFrontError } = errorReducers.actions
export default errorReducers.reducer
