import { FunctionComponent, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { FormattedMessage } from "react-intl"

import { Scrollbars } from "react-custom-scrollbars-2"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import Page from "components/Page"
import Loader from "components/Loader"

import { ConnectedTrackingBannerList } from "features/Tracking/BannersTracking"
import ExportUsagesLink from "features/Tracking/components/ExportUsagesLink"
import { ConnectedBannerClicksGraph } from "features/Tracking/BannersTracking"

import TrackingIconData from "features/Tracking/components/TrackingIconData"
import TrackingSignatureList from "features/Tracking/SignaturesTracking/SignatureTrackingList"
import BannersTrackingCounter from "features/Tracking/components/BannersTrackingCounter"
import SignaturesTrackingCounter from "features/Tracking/components/SignaturesTrackingCounter"

import { signaturesActionCreators } from "features/Signatures"

import TrackingPageHeader from "features/Tracking/components/TrackingPageSubHeader"
import TrackingPageContent from "features/Tracking/components/TrackingPageContent"

import { featureNames } from "config/config.features"

import { getFeaturesLoadingStatus } from "features/FeatureTogglr/FeatureTogglrSelectors"

import {
  checkTrackingJob,
  getBannersClickAction,
  initQueriesDateRangeAction,
} from "features/Tracking/TrackingActions"

import { fetchSignaturesDelivered } from "features/Tracking/TrackingReducers"
import { LoadingStatus } from "core/CoreModels"

const TrackingPage: FunctionComponent = () => {
  const newVersionStatistiques = useFeatureTogglr(featureNames.STATISTICS_V2)

  const dispatch = useDispatch()

  const featuresTogglerLoadingStatus = useSelector(getFeaturesLoadingStatus)

  useEffect(() => {
    dispatch(checkTrackingJob())
    dispatch(initQueriesDateRangeAction())
  }, [])

  useEffect(() => {
    if (!newVersionStatistiques?.isActive) {
      dispatch(signaturesActionCreators.loadAllAffectedUser())
      dispatch(getBannersClickAction())
      dispatch(fetchSignaturesDelivered())
    }
  }, [])

  if (featuresTogglerLoadingStatus !== LoadingStatus.LOADED)
    return (
      <Page
        title={
          <FormattedMessage
            id="TrackingPage.Title"
            defaultMessage="Statistiques"
          />
        }
      >
        <Loader fullScreen isLoading />
      </Page>
    )

  return (
    <Page
      title={
        <FormattedMessage
          id="TrackingPage.Title"
          defaultMessage="Statistiques"
        />
      }
    >
      <Scrollbars>
        {newVersionStatistiques?.isActive ? (
          <>
            <TrackingPageHeader />
            <TrackingPageContent />
          </>
        ) : (
          <div className="tracking__page">
            <div className="tracking__page__users">
              <TrackingIconData />
            </div>
            <div className="tracking__page__metrics">
              <div className="col-6 tracking__container">
                <SignaturesTrackingCounter />
                <TrackingSignatureList />
                <ExportUsagesLink />
              </div>
              <div className="col-6 tracking__container">
                <BannersTrackingCounter />
                <ConnectedBannerClicksGraph />
                <ConnectedTrackingBannerList />
              </div>
            </div>
          </div>
        )}
      </Scrollbars>
    </Page>
  )
}

export default TrackingPage
