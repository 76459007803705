import React, { useState } from "react"

import { Slide } from "@material-ui/core"

import GSuiteOptionsForm from "./GSuiteOptionsForm"
import GmailOptionsForm from "./GmailOptionsForm"

import GSuiteToggle from "./components/GSuiteToggle"
import GmailToggle from "./components/GmailToggle"

import Feature from "features/Features/FeaturesModels"

interface Props {
  featureGSuite: Feature
  featureGmail: Feature
  domains: string[]
  fetchDomains: (adminEmail: string) => void
  updateFeature(feature: Feature)
  launchJob(jobName: string)
}

const GSuiteForm: React.FC<Props> = (props) => {
  const {
    featureGSuite,
    featureGmail,
    updateFeature,
    launchJob,
    fetchDomains,
    domains,
  } = props

  const [localGSuite, setGSuiteFeature] = useState(featureGSuite)

  const [slide, setSlide] = React.useState<boolean>(false)
  const [activeFeature, setActiveFeature] = React.useState<string>(null)
  const [showForm, setShowForm] = React.useState<boolean>(false)

  const handleOptionsForm = (feature: Feature) => {
    const adminEmail = feature.properties.find(
      (tp) => tp.property === "syncgsuiteadminmail",
    )
    localGSuite.properties = feature.properties
    setGSuiteFeature(localGSuite)

    if (adminEmail !== undefined) fetchDomains(adminEmail.value)
  }

  const handleOptionsFormSaved = (feature: Feature) => {
    feature.activated = true
    updateFeature(feature)
    if (feature.name === "GSuite") setGSuiteFeature(feature)

    setShowForm(false)
    setSlide(false)
  }

  const handleCancel = () => {
    setActiveFeature(null)
    setShowForm(false)
    setSlide(false)
  }

  const handleToggle = (feature: Feature, isActive: boolean) => {
    if (isActive) {
      setActiveFeature(feature.name)
      setShowForm(true)
      setSlide(true)
    } else {
      feature.activated = false
      updateFeature(feature)
    }
  }

  const handleGmailToggle = (feature: Feature, isActive: boolean) => {
    setActiveFeature(feature.name)
    if (isActive) {
      const googleFeatureAlreadyActive = featureGSuite.activated
      if (googleFeatureAlreadyActive) {
        // si GSuite est déjà activé, on a déjà le JSON du compte de service,
        // donc on valide directement le job sans ré-enregistrer le JSON
        handleOptionsFormSaved(feature)
      } else {
        setShowForm(true)
        setSlide(true)
      }
    } else {
      feature.activated = false
      updateFeature(feature)
    }
  }

  const handleOpenParams = (feature: Feature) => {
    setActiveFeature(feature.name)
    setShowForm(true)
    setSlide(true)
  }

  if (featureGSuite == null || featureGmail == null) return null

  return (
    <div className="gsuite-form">
      {showForm && (
        <Slide
          direction="right"
          in={slide}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <div>
            {activeFeature === "GSuite" && (
              <GSuiteOptionsForm
                featureGSuite={localGSuite}
                onValidate={handleOptionsForm}
                onSave={handleOptionsFormSaved}
                onCancel={handleCancel}
                domains={domains}
              />
            )}
            {activeFeature === "Gmail" && (
              <GmailOptionsForm
                featureGmail={featureGmail}
                onValidate={handleOptionsFormSaved}
                onCancel={handleCancel}
              />
            )}
          </div>
        </Slide>
      )}

      {!showForm && (
        <div className="gsuite-form__toggles">
          <GSuiteToggle
            featureGSuite={featureGSuite}
            onToggle={(isActive) => handleToggle(featureGSuite, isActive)}
            onTriggerJob={() => launchJob(featureGSuite.jobName)}
            onOpenParams={() => handleOpenParams(featureGSuite)}
          />
          <GmailToggle
            featureGmail={featureGmail}
            onToggle={(isActive) => handleGmailToggle(featureGmail, isActive)}
            onTriggerJob={() => launchJob(featureGmail.jobName)}
            onOpenParams={() => handleOpenParams(featureGmail)}
          />
        </div>
      )}
    </div>
  )
}

export default GSuiteForm
