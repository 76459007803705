import { FunctionComponent, ReactNode } from "react"

interface TrackingTileDatasContainerProps {
  children: ReactNode
  label: string
  infos: string
}

const TrackingTileDatasContainer: FunctionComponent<
  TrackingTileDatasContainerProps
> = ({ children, label, infos }) => {
  return (
    <div className="tracking-tile__datas--container">
      <div className="tracking-tile__datas--title">
        <p className="tracking-tile__datas--label">{label}</p>
        <p className="tracking-tile__datas--infos">{infos}</p>
      </div>
      {children}
    </div>
  )
}

export default TrackingTileDatasContainer
