import { FunctionComponent } from "react"

import { useSelector } from "react-redux"
import classNames from "classnames"

import { offersSelectors } from "features/Offers"

import useHomePagesTilesLayout from "./hooks/useHomePagesTilesLayout"

import SignaturesTile from "features/Accounts/components/SignaturesTile"
import CampaignsTile from "features/Accounts/components/CampaignsTile"
import TrackingTile from "features/Accounts/components/TrackingTile"
import UsersTile from "features/Accounts/components/UsersTile"

const HomePageTiles: FunctionComponent = () => {
  const {
    layout,
    hasAccessSignatures,
    hasAccessCampaigns,
    hasAccessTracking,
    hasAccessUsers,
  } = useHomePagesTilesLayout()

  const isTrial = useSelector(offersSelectors.isTrial)

  return (
    <div className={classNames("home-tiles__container", layout)}>
      {hasAccessSignatures && (
        <SignaturesTile
          className="home-tiles__container--child"
          isTrial={isTrial}
        />
      )}
      {hasAccessCampaigns && (
        <CampaignsTile
          className="home-tiles__container--child"
          isTrial={isTrial}
        />
      )}
      {hasAccessTracking && (
        <TrackingTile
          className="home-tiles__container--child"
          isTrial={isTrial}
        />
      )}
      {hasAccessUsers && (
        <UsersTile className="home-tiles__container--child" isTrial={isTrial} />
      )}
    </div>
  )
}

export default HomePageTiles
