import {
  put,
  call,
  select,
  take,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"
import { enrollmentSelectors } from "."

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"

import { OfferOption } from "features/Offers/OffersModels"
import CodePromo from "entities/CodePromo"

import { EnrollmentDatas, SaveStripePaymentPayload } from "./EnrollmentModels"
import { accountsSelectors } from "features/Accounts"
import { checkCodePromoApi } from "features/Marketing/MarketingApi"
import {
  attachStripePaymentToClient,
  createStripeCustomer,
  createStripeSetup,
  getStripePaymentMethods as getStripePaymentMethodsApi,
  deleteStripePaymentMethod as deleteStripePaymentMethodApi,
  setStripeDefaultPaymentMethod,
} from "features/Billings/BillingsApi"
import { updateTenantOffer } from "./EnrollmentApi"
import { hideGlobalLoader, showGlobalLoader } from "features/App/AppReducers"
import {
  askStripeValidation,
  checkCodepromoFailure,
  checkCodepromoSuccess,
  createStripePaymentFailure,
  createStripePaymentSuccess,
  deleteStripePaymentMethodsSuccess,
  getStripePaymentMethodsSuccess,
  initEnrollmentForm,
  initEnrollmentFormSuccess,
  saveStripePayment,
  saveStripePaymentFailure,
  setStripePaymentMethodDefaultSuccess,
  subscribe,
  subscribeSuccess,
  updateStripePaymentMethodSuccess,
} from "./EnrollmentReducer"
import { PayloadAction } from "@reduxjs/toolkit"
import {
  checkCodePromoAction,
  deleteStripePaymentMethodAction,
  getStripePaymentMethodsAction,
  setStripeDefaultPaymentMethodAction,
  updateBillingInformationsAction,
} from "./EnrollmentActions"

import { BillingAddress } from "features/Billings/BillingsModels"
import { billingsSelectors } from "features/Billings"
import { updateBillingAddress } from "features/Billings/BillingsReducer"
import { getorLoadAllCatalogOffersAsync } from "features/Offers/OffersSagas"
import { computeDiscountAmount } from "./EnrollmentRules"
import { DEFAULT_OFFER_CURRENCY } from "features/Offers/OffersConstants"
import { formatAmount } from "utils/NumberHelper"

/**
 * Inscription de l'utilisateur si ses données sont correcte. Inclut:
 *  - validation du billing form
 *  - creation de la configuration d'intention
 *  - creation du paiement Stripe
 *  - mise à jour des infos de facturation Sellsy
 *  - copie de l'offre selectionnée de la table globale dans la table des offres du tenant utilisateur
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* subscribeAsync(action: PayloadAction<EnrollmentDatas>) {
  const enrollmentDatas = action.payload
  const description = getStripeDescription(enrollmentDatas)
  // 1. Creation de la configuration d'intention de paiement
  const setupIntentSecret = yield call(
    createSetupIntent,
    description,
    enrollmentDatas.totalVAT / 100,
  )

  // 2. Envoi du code et flag pour déclencher la vérification du moyen de paiement dans le composant React Checkout
  yield put(
    askStripeValidation({
      intentSecret: setupIntentSecret,
      complete: true,
    }),
  )

  const [success] = yield race([
    take(createStripePaymentSuccess.type),
    take(createStripePaymentFailure.type),
  ])

  if (success) {
    const currentAccount = yield select(accountsSelectors.getCurrentAccount)

    // création du job async
    const newOfferInfos = yield call(
      createUpsellJob,
      enrollmentDatas,
      currentAccount.Email,
    )

    // 6. inscription terminée correctement, on redirige vers la page de succès
    yield put(
      subscribeSuccess({
        enrollmentSuccess: true,
        newOfferInfos,
      }),
    )
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createUpsellJob(
  enrollmentDatas: EnrollmentDatas,
  userName: string,
) {
  const maxUserVolume = enrollmentDatas.nbUsers

  const optionsInformations = enrollmentDatas.options.map(
    (opt: OfferOption) => ({
      Id: opt.Id,
      Name: opt.Name,
      Price: opt.getPricingFromLicencesNumber(maxUserVolume),
    }),
  )

  const currentOffer = enrollmentDatas.selectedOffer

  const startDate = new Date(new Date().setHours(0, 0, 0, 0))
  const endDate = new Date(startDate)
  endDate.setFullYear(endDate.getFullYear() + 1)

  const billingAddress = enrollmentDatas.billingAddress

  const offerInformations = {
    paymentInfos: {
      userName,
      offerId: currentOffer.id,
      offerName: currentOffer.name,
      licenceVolume: maxUserVolume,
      paiementHT: enrollmentDatas.total,
      paiementTTC: enrollmentDatas.totalVAT,
      offerOptions: optionsInformations,
      modePaiement: enrollmentDatas.paymentMethod,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
    billingAddress,
  }

  const parametersJson: string = JSON.stringify(offerInformations)

  yield call(updateTenantOffer, parametersJson)
  return offerInformations
}

/**
 * Creation d'une configuration d'intention de paiement pour l'utilisateur courant,
 * pour pouvoir le débiter plus tard
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createSetupIntent(description: string, fullPrice: number) {
  const intentResponse = yield call(createStripeSetup, description, fullPrice)
  return intentResponse.result.intentSecret
}

/**
 * Enregistrement du moyen de paiement de l'utilisateur Stripe et mise à jour de la configuration d'intention
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* saveStripePaymentAsync(
  action: PayloadAction<SaveStripePaymentPayload>,
) {
  try {
    if (action.payload.holder === "" || action.payload.stripeError != null) {
      const errorMsg =
        action.payload.holder === ""
          ? "Enrollment.NoPaymentOwnerError"
          : action.payload.stripeError.message
      yield put(
        askStripeValidation({
          complete: false,
        }),
      )
      yield put(saveStripePaymentFailure(errorMsg))
      yield put(
        addNotification(
          new Notification(
            "Merci de compléter vos informations de paiement",
            "ERROR",
          ),
        ),
      )
      return
    }

    // 1. Stripe - creation du customer s'il n'existe pas
    const result = yield call(
      createStripeCustomer,
      action.payload.holder,
      action.payload.paymentIntent.paymentIntent,
      "",
      "",
    )
    if (!result.success) {
      yield put(createStripePaymentFailure(result.result.message))
      yield put(hideGlobalLoader())
      return
    }
    // 2. Stripe - attachement du moyen de paiement au customer
    if (action.payload.methodId) {
      yield call(deleteStripePaymentMethodApi, action.payload.methodId)
    }

    yield call(
      attachStripePaymentToClient,
      result.result.paymentIntent,
      result.result.customerId,
    )

    // 3. Stripe - Si le montant est à facturer tout de suite, creation du paiement, sinon mise à jour du moyen de paiement seulement
    const isUpdate = yield select(enrollmentSelectors.isUpdate)
    if (isUpdate) {
      yield put(updateStripePaymentMethodSuccess())
      yield put(hideGlobalLoader())
    } else {
      yield put(createStripePaymentSuccess())
    }
  } catch (err) {
    yield put(
      askStripeValidation({
        complete: false,
      }),
    )
    yield put(createStripePaymentFailure(err))
  }
}

function getStripeDescription(enrollmentDatas) {
  const { nbUsers, subTotal, discount, selectedOffer } = enrollmentDatas

  const codePromoString = enrollmentDatas.discount
    ? ` - Promo ${discount.code} (${formatAmount(
        computeDiscountAmount(subTotal, discount.percent),
        "FR",
        DEFAULT_OFFER_CURRENCY.name,
      )})`
    : ""

  const options = enrollmentDatas.options
    .map((option) => `+ ${option.PaymentName}`)
    .join("")

  return `${nbUsers} licences Boost My Mail ${selectedOffer.name} ${codePromoString} ${options}`
}

/**
 * Vérification de l'existance d'un code promo
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* checkCodePromo(action: PayloadAction<string>) {
  try {
    const requestResult = yield call(checkCodePromoApi, action.payload)

    if (requestResult.httpStatus === 500)
      throw new Error(requestResult.errorMessage)

    if (requestResult.httpStatus === 404) throw new Error("Code promo invalide")

    const codePromo = new CodePromo(requestResult.result)
    // verification pour un Trial, si la promotion est plus importante
    const trialDiscount = yield select((state) =>
      enrollmentSelectors.getDiscount(state),
    )

    if (trialDiscount > codePromo.Percentdiscount) {
      yield put(
        addNotification(
          new Notification(
            `La réduction de ${codePromo.Percentdiscount}% de votre code promotionnel est inférieure à la promotion exceptionnelle de ${trialDiscount}% dont vous bénéficiez en tant que nouveau client`,
            "ERROR",
          ),
        ),
      )
      return
    }

    yield put(
      addNotification(
        new Notification(
          `Une réduction de ${codePromo.Percentdiscount}% a été appliquée à votre panier`,
          "SUCCESS",
        ),
      ),
    )
    yield put(checkCodepromoSuccess(codePromo))
  } catch (error) {
    yield put(checkCodepromoFailure())
    yield put(addNotification(new Notification("Code promo invalide", "ERROR")))
  }
}

/**
 * Récupération des moyens de paiement
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getStripePaymentMethods() {
  const result = yield call(getStripePaymentMethodsApi)
  yield put(
    getStripePaymentMethodsSuccess({
      card: result.result.card,
      sepa: result.result.sepa,
    }),
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* deleteStripePaymentMethod(action) {
  yield put(showGlobalLoader())
  yield call(deleteStripePaymentMethodApi, action.payload)
  yield put(deleteStripePaymentMethodsSuccess())
  yield put(hideGlobalLoader())
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* setStripeDefaultPaymentMethodAsync(action) {
  yield put(showGlobalLoader())
  yield call(setStripeDefaultPaymentMethod, action.payload)
  yield put(setStripePaymentMethodDefaultSuccess())
  yield put(hideGlobalLoader())
}

export function* updateBillingInformations() {
  // 3. Si le paiement est valide, mise à jour des informations Sellsy
  const billingInformations: BillingAddress = yield select(
    billingsSelectors.getBillingAddress,
  )
  yield call(updateBillingAddress, billingInformations)
}

function* initEnrollmentFormOrchestrator() {
  yield call(getorLoadAllCatalogOffersAsync)

  yield put(initEnrollmentFormSuccess())
}

export const enrollmentWatchers = [
  takeEvery(subscribe.type, subscribeAsync),
  takeEvery(checkCodePromoAction.type, checkCodePromo),
  takeEvery(getStripePaymentMethodsAction.type, getStripePaymentMethods),
  takeEvery(deleteStripePaymentMethodAction.type, deleteStripePaymentMethod),
  takeEvery(
    setStripeDefaultPaymentMethodAction.type,
    setStripeDefaultPaymentMethodAsync,
  ),
  takeLatest(updateBillingInformationsAction.type, updateBillingInformations),
  takeEvery(saveStripePayment.type, saveStripePaymentAsync),
  takeLatest(initEnrollmentForm.type, initEnrollmentFormOrchestrator),
]
