import { FunctionComponent } from "react"

import { ReactComponent as PreviousIcon } from "assets/icon-previous.svg"
import { ReactComponent as NextIcon } from "assets/icon-next.svg"

interface AssignationsPanelNavigationProps {
  isLoading: boolean
  displayedIndex: number | null
  total: number
  previous: () => void
  next: () => void
}

export type AssignationsPanelNavigationType =
  FunctionComponent<AssignationsPanelNavigationProps>

const AssignationsPanelNavigation: AssignationsPanelNavigationType = ({
  isLoading,
  displayedIndex,
  total,
  previous,
  next,
}) => {
  return (
    <div className="assignations-panel__navigation">
      {isLoading ? (
        <p className="assignations-panel__navigation--current">{`${
          displayedIndex + 1
        }/${total}`}</p>
      ) : (
        <div className="skeleton skeleton__text" />
      )}
      <button
        className="assignations-panel__navigation--prev"
        onClick={previous}
        disabled={displayedIndex === null || displayedIndex === 0}
      >
        <PreviousIcon />
      </button>
      <button
        className="assignations-panel__navigation--next"
        onClick={next}
        disabled={displayedIndex === null || displayedIndex >= total - 1}
      >
        <NextIcon />
      </button>
    </div>
  )
}

export default AssignationsPanelNavigation
