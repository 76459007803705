import { FunctionComponent, memo } from "react"

const EDIT_FORM_USER_ROW = 7

const EditUserFormLoadingSkeleton: FunctionComponent = () => {
  return (
    <div className="edit-user-form__skeleton">
      <div className="edit-user-form__skeleton--round" />
      {[...Array(EDIT_FORM_USER_ROW).keys()].map((el) => (
        <div key={el} className="edit-user-form__skeleton--row">
          <div className="edit-user-form__skeleton--form-element">
            <div className="edit-user-form__skeleton--form-label" />
            <div className="edit-user-form__skeleton--form-input" />
          </div>
          <div className="edit-user-form__skeleton--form-element">
            <div className="edit-user-form__skeleton--form-label" />
            <div className="edit-user-form__skeleton--form-input" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default memo(EditUserFormLoadingSkeleton)
