import React from "react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import Checkbox from "@material-ui/core/Checkbox"
import NavigationButtons from "../Components/NavigationButtons"
import EnrollmentStep2Side from "./EnrollmentStep2Side"

import EnrollmentOptions from "features/Enrollment/components/EnrollmentOptions"
import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"
import { OfferOption } from "features/Offers/OffersModels"

import logoOutlook from "features/Enrollment/assets/logo-outlook-full.svg"
import logoGsuite from "features/Enrollment/assets/logo-gsuite-full.svg"
import logoEmail from "features/Enrollment/assets/logo-email-full.svg"

const clients = [
  { name: "outlook", icon: logoOutlook },
  { name: "gsuite", icon: logoGsuite },
  { name: "email", icon: logoEmail },
]

const EnrollmentStep2: React.FC = () => {
  const { enrollmentDatas, setEnrollmentDatas, allOptions, updateOptions } =
    useEnrollmentData()
  const [showWarning, setShowWarning] = useState(false)

  const handleClientChange = (client: string, active: boolean) => {
    if (active)
      setEnrollmentDatas({ clients: [...enrollmentDatas.clients, client] })
    else
      setEnrollmentDatas({
        clients: enrollmentDatas.clients.filter((item) => item !== client),
      })
    if (client === "email") setShowWarning(active)
  }

  const handleOptionChange = (option: OfferOption) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let options: any
    if (option.Selected) options = [...enrollmentDatas.options, option]
    else
      options = enrollmentDatas.options.filter((item) => item.Id !== option.Id)

    updateOptions(options)
  }

  return (
    <div className="enroll__step enroll_step2">
      <div className="enroll__box enroll__main enroll__most_popular">
        <div className="enroll__form">
          <h2>
            <span>2</span>
            <FormattedMessage
              id="Enroll.Step2Title"
              defaultMessage="Choisissez vos options"
            />
          </h2>
          <fieldset>
            <legend>
              <FormattedMessage
                id="Enroll.Step2Options"
                defaultMessage="Séléctionnez vos options"
              />
            </legend>
            <EnrollmentOptions
              options={allOptions}
              nbLicences={enrollmentDatas.nbUsers}
              selectEnrollmentOption={handleOptionChange}
            />
          </fieldset>
          <fieldset>
            <legend>
              <FormattedMessage
                id="Enroll.Step2Mail"
                defaultMessage="Séléctionnez votre messagerie"
              />
            </legend>
            {clients.map((client, i) => (
              <label key={i}>
                <Checkbox
                  checked={enrollmentDatas.clients.includes(client["name"])}
                  color="primary"
                  onChange={(e, val) => handleClientChange(client["name"], val)}
                />
                <img alt={client["name"]} src={client["icon"]} />
              </label>
            ))}
          </fieldset>
          {showWarning && (
            <div className="enroll__warning">
              <i className="material-icons">warning</i>
              <div>
                Vous avez choisi "<strong>Autres</strong>", pour savoir si nous
                pouvons prendre en charge votre messagerie, merci de nous
                contacter au <em>02.52.32.11.10</em> ou par mail{" "}
                <em>support@boostmymail.com</em>
              </div>
            </div>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <NavigationButtons
            disableNext={
              enrollmentDatas.clients.length === 0 ||
              enrollmentDatas.clients.includes("email")
            }
          />
        </div>
      </div>
      <EnrollmentStep2Side />
    </div>
  )
}

export default EnrollmentStep2
