import { FunctionComponent, ReactNode } from "react"

import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

import { showTrialLimitations } from "features/Offers/OffersReducer"
import { trialTunnelSelectors } from "features/Trial"

import { HOME_PORTAL_URL } from "router/RouterConstants"

interface WithBannerUnlockedProps {
  children: ReactNode
  needBannerUnlocked?: boolean
  title?: string
  content?: string
}

const WithBannerUnlocked: FunctionComponent<WithBannerUnlockedProps> = ({
  children,
  needBannerUnlocked,
  title,
  content,
}) => {
  const dispatch = useDispatch()

  const isBannerUnlocked = useSelector(trialTunnelSelectors.bannerIsUnlocked)

  if (needBannerUnlocked && !isBannerUnlocked) {
    dispatch(showTrialLimitations({ title, content }))
    return <Navigate to={HOME_PORTAL_URL} />
  }

  return <>{children}</>
}

export default WithBannerUnlocked
