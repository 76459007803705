import { useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"
import { offersSelectors } from "features/Offers"

import { onBoardingInProgress } from "features/Accounts/AccountsRules"

export function useOnBoardingDisplay() {
  const isTrial = useSelector(offersSelectors.isTrial)
  const onBoardingCompletedDate = useSelector(
    accountsSelectors.getLastOnBoardingEventDate,
  )

  return onBoardingInProgress(isTrial, onBoardingCompletedDate)
}
