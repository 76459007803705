import { FunctionComponent } from "react"

import { useParams } from "react-router-dom"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"

import EditUserForm from "features/Users/components/EditUserForm"
import EditUserAssignationsPanel from "features/Users/components/EditUserAssignationsPanel"

const EditUserPage: FunctionComponent = () => {
  const { user } = useParams()

  const userId = parseInt(user) || -1

  return (
    <Page
      title={
        <FormattedMessage
          id="UsersPage.Title"
          defaultMessage="Gestion des utilisateurs"
        />
      }
    >
      <div className="edit-user__container">
        <div className="edit-user left-side">
          <div className="edit-user__header">
            {userId === -1 ? (
              <FormattedMessage
                id="UsersPage.HeaderAdd"
                defaultMessage="Créer un utilisateur"
              />
            ) : (
              <FormattedMessage
                id="UsersPage.HeaderEdit"
                defaultMessage="Modifier un utilisateur"
              />
            )}
            <div className="edit-user__divider"></div>
          </div>
          <div className="edit-user__body">
            <EditUserForm currentUserId={userId} />
          </div>
        </div>
        {userId !== -1 && <EditUserAssignationsPanel userId={userId} />}
      </div>
    </Page>
  )
}

export default EditUserPage
