import { FunctionComponent, useState } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as DeleteIcon } from "assets/icon-delete.svg"

import ConfirmModal from "components/ConfirmModal/ConfirmModal"
import MouseTooltip from "components/MouseTooltip"

export type SignatureCardDeleteActionType =
  FunctionComponent<SignatureCardDeleteActionProps>

interface SignatureCardDeleteActionProps {
  onDelete: () => void
  roundedCorner?: "top" | "bottom"
  alternateTooltip?: string
  disabled?: boolean
}

const messages = defineMessages({
  delete: {
    id: "SignatureCard.DeleteTooltip",
    defaultMessage: "Supprimer la signature",
  },
})

const SignatureCardDeleteAction: SignatureCardDeleteActionType = ({
  onDelete,
  roundedCorner,
  alternateTooltip,
  disabled = false,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  return (
    <>
      <ConfirmModal
        action={() => {
          onDelete()
        }}
        handleClose={() => {
          setOpenConfirm(false)
        }}
        open={openConfirm}
      />
      <div
        className={classNames(
          "signature-card__delete-btn",
          roundedCorner && `signature-card__rounder-corner--${roundedCorner}`,
        )}
        onClick={() => {
          if (!disabled) setOpenConfirm(true)
        }}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <DeleteIcon className="signature-card__btn--icon" />
      </div>
      <MouseTooltip visible={visible}>
        {alternateTooltip ? alternateTooltip : formatMessage(messages.delete)}
      </MouseTooltip>
    </>
  )
}

export default SignatureCardDeleteAction
