import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"

export enum SizeString {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}

interface Props {
  open: boolean
  close()
  title: string
  dialogContent: Element | React.ReactElement
  dialogActions?: Element | React.ReactElement
  maxWidth?: SizeString
}

const Modal: React.FC<Props> = (props) => {
  const { open, close, title, dialogContent, dialogActions, maxWidth } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth || false}
      open={open}
      onClose={close}
      className="bmm-modal"
      aria-labelledby="dialog"
    >
      <DialogTitle disableTypography className="bmm-modal__title">
        <div className="bmm-modal__title__label">
          <span>{title}</span>
        </div>
        <div className="bmm-modal__title__close" onClick={close}>
          <i className="material-icons">cancel</i>
        </div>
      </DialogTitle>
      <DialogContent dividers>{dialogContent}</DialogContent>
      {dialogActions && (
        <div className="bmm-modal__actions">
          <DialogActions>{dialogActions}</DialogActions>
        </div>
      )}
    </Dialog>
  )
}

export default Modal
