import React from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import TextBox from "./TextBox"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }),
)

const OutlinedTextField: React.FC<any> = function (props) {
  const classes = useStyles({})

  return (
    <TextBox
      id="outlined-bare"
      className={classes.textField + " bmm-textbox"}
      margin="normal"
      variant="outlined"
      {...props}
    />
  )
}

export default OutlinedTextField
