import moment from "moment"
import { Moment } from "moment"
import CalendarRange, { DayRange } from "./entities/CalendarRange"

export const getDayDatas = (
  currentDay: Moment,
  ranges: CalendarRange[] = [],
): { dayDatas; firstDay } => {
  const nbDays = currentDay.daysInMonth()
  const firstDay = currentDay.clone().startOf("month").isoWeekday()
  const coloredRanges: CalendarRange[] = ranges.map(setRangeColor)

  const setRangeOrder = (dayRanges) => {
    const orderedRanges = []
    for (const dayRange of dayRanges) {
      const globalRange = coloredRanges.find((gr) => gr.id === dayRange.id)
      if (globalRange == null) throw new Error("range doesn't exists")
      let order = 0
      if (globalRange.order == null) {
        order = getFreeSpot(dayRanges)
        globalRange.order = order
      }
      orderedRanges.push({ ...dayRange, order: globalRange.order })
    }

    return orderedRanges
  }

  const dayDatas = [...new Array(nbDays)].map((_, dayPos) => {
    const dayRanges = getRanges(currentDay, coloredRanges)
    // définition du order pour les ranges
    const orderedRanges = setRangeOrder(dayRanges)

    const orderedRangesWithEdges = orderedRanges.map((r: DayRange) => {
      r.isFirstDay = currentDay.isSame(r.startDate, "day")
      r.isLastDay = currentDay.isSame(r.endDate, "day")
      return r
    })
    const day = currentDay.clone()
    currentDay.add(1, "day")
    return { day, dayPos, ranges: orderedRangesWithEdges }
  })

  return { dayDatas, firstDay }
}

function getRanges(currentDay: Moment, ranges = []) {
  return ranges.filter((range) =>
    currentDay.isBetween(
      moment(range.startDate),
      moment(range.endDate),
      "days",
      "[]",
    ),
  )
}

export function getFreeSpot(dayRanges) {
  let isFree = false
  let index = 0
  while (!isFree) {
    if (dayRanges.some((dr) => dr.order === index)) index++
    else isFree = true
  }
  return index
}

/**
 * Attributes a unique color to a calendarRange
 */
export function setRangeColor(range: CalendarRange) {
  const colors = ["#3D5AFE", "#2AD3C2", "#FFC107", "#E91E63"]
  if (!range.color) {
    range.color = colors[range.id % colors.length]
  }
  return { ...range }
}

export default getDayDatas
