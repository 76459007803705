import { FunctionComponent } from "react"

import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { SellsyFormState } from "features/Features/hooks/useSellsyFeature"

import { SELLSY_HELP_LINK } from "features/Features/FeaturesConstants"

import Feature from "features/Features/FeaturesModels"

interface SellsyParametersProps {
  featureSellsy: Feature
  sellsyFormState: SellsyFormState
  onChangeClientId: (e) => void
  onChangeClientSecret: (e) => void
}

const messages = defineMessages({
  title: {
    id: "SellsyForm.ParametersTitle",
    defaultMessage: "Paramètrage",
  },
  apiKeyPlaceholder: {
    id: "SellsyForm.ApiKeyPlaceHolder",
    defaultMessage: "6ebe45eb-5541-474e-ab33-a42f7a177a70",
  },
  apiKeyError: {
    id: "SellsyForm.ApiKeyError",
    defaultMessage: "Merci de saisir une clé API",
  },
  apiSecretPlaceholder: {
    id: "SellsyForm.ApiSecretPlaceHolder",
    defaultMessage: "9a16712dd412ec4e399f111ced99875csse179747",
  },
  apiSecretError: {
    id: "SellsyForm.ApiKeyError",
    defaultMessage: "Merci de saisir une valeur pour le secret",
  },
})

const SellsyParameters: FunctionComponent<SellsyParametersProps> = ({
  featureSellsy,
  sellsyFormState,
  onChangeClientId,
  onChangeClientSecret,
}) => {
  const { sellsyClientId, sellsyClientSecret } = sellsyFormState
  const { formatMessage } = useIntl()

  return (
    <div className="sellsy-parameters">
      <p className="sellsy-parameters__title">
        {formatMessage(messages.title)}
      </p>
      <p className="sellsy-parameters__description">
        <FormattedMessage
          id="SellsyForm.ParametersDescription"
          defaultMessage="Suivez notre tuto pour configurer l’accès à Sellsy en <help>cliquant ici</help>"
          values={{
            help: (chunks: string) => (
              <a
                className="sellsy-parameters__helplink"
                target="_blank"
                href={SELLSY_HELP_LINK}
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
      <div className="sellsy-parameters__form">
        <div className="sellsy-parameters__api-key">
          <p className="sellsy-parameters__form--label">
            <FormattedMessage
              id="SellsyForm.ApiKeyLabel"
              defaultMessage="Votre <highlight>Client ID</highlight> Sellsy"
              values={{ highlight: (chunks) => <b>{chunks}</b> }}
            />
          </p>
          <input
            name={sellsyClientId.name}
            value={sellsyClientId.value}
            onChange={onChangeClientId}
            className="sellsy-parameters__form--input"
            placeholder={formatMessage(messages.apiKeyPlaceholder)}
            disabled={featureSellsy.activated}
          />
          {sellsyClientId.error && (
            <span className="sellsy-parameters__form--error">
              {formatMessage(messages.apiKeyError)}
            </span>
          )}
        </div>
        <div className="sellsy-parameters__api-secret">
          <p className="sellsy-parameters__form--label">
            <FormattedMessage
              id="SellsyForm.ApiSecretLabel"
              defaultMessage="Votre <highlight>Client Secret</highlight> Sellsy"
              values={{ highlight: (chunks) => <b>{chunks}</b> }}
            />
          </p>
          <input
            name={sellsyClientSecret.name}
            value={sellsyClientSecret.value}
            onChange={onChangeClientSecret}
            className="sellsy-parameters__form--input"
            placeholder={formatMessage(messages.apiSecretPlaceholder)}
            disabled={featureSellsy.activated}
          />
          {sellsyClientSecret.error && (
            <span className="sellsy-parameters__form--error">
              {formatMessage(messages.apiSecretError)}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default SellsyParameters
