import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { previewMail } from "features/Users/UsersReducers"

import { usersSelectors } from "features/Users"

import { LoadingStatus } from "core/CoreModels"
import { PreviewTemplate } from "features/Users/UserModels"

interface UseTemplatesPreview {
  templates: PreviewTemplate[]
  displayedPreviewIndex: number | null
  previousPreview: () => void
  nextPreview: () => void
}
interface UseUserSignaturePreview {
  previews: UseTemplatesPreview
  loadingStatus: LoadingStatus
}

const useTemplatesPreview = (selector, loadingStatus): UseTemplatesPreview => {
  const [displayedPreviewIndex, setDisplayedPreviewIndex] = useState<
    number | null
  >(null)

  const templates: PreviewTemplate[] = useSelector(selector)

  const previousPreview = () =>
    setDisplayedPreviewIndex((prevState) => prevState - 1)

  const nextPreview = () =>
    setDisplayedPreviewIndex((prevState) => prevState + 1)

  useEffect(() => {
    if (loadingStatus === LoadingStatus.LOADED && templates.length > 0)
      setDisplayedPreviewIndex(0)
  }, [loadingStatus, templates])

  return { templates, displayedPreviewIndex, previousPreview, nextPreview }
}

const useUserSignaturePreview = (userId: number): UseUserSignaturePreview => {
  const dispatch = useDispatch()

  const loadingStatus = useSelector(
    usersSelectors.getUserTemplatesPreviewLoadingStatus,
  )

  const previews = useTemplatesPreview(
    usersSelectors.getUserTemplatesPreview,
    loadingStatus,
  )

  useEffect(() => {
    dispatch(previewMail(userId))
  }, [userId])

  return { previews, loadingStatus }
}

export default useUserSignaturePreview
