import { FunctionComponent, useEffect, useState } from "react"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { ReactComponent as UsersEmptyIcon } from "features/Users/assets/icon-users-empty-results.svg"

import SearchBar from "components/SearchBar"
import ImportExcelReport from "../ImportExcelReport"
import UsersGrid from "features/Users/components/UsersGrid"
import UsersHeader from "./UsersHeader"

import usePartialUsersData from "features/Users/hooks/usePartialUsersData"

import { LoadingStatus } from "core/CoreModels"
import useUsersTab from "features/Users/hooks/useUsersTab"
import useCurrentSubsidiariesData from "features/Subsidiaries/hooks/useCurrentSubsidiariesData"
import usePrevious from "hooks/usePrevious"

const messages = defineMessages({
  filter: { id: "UsersTab.FilterPlaceholder", defaultMessage: "Rechercher" },
  over: { id: "UsersTab.Over", defaultMessage: " sur " },
  noUsers: {
    id: "UsersTab.NoUsers",
    defaultMessage: "Vous n'avez pas encore d'utilisateurs",
  },
  addUsers: {
    id: "UsersTab.AddUsers",
    defaultMessage: "Ajoutez-en dès maintenant",
  },
})

const UsersTab: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const {
    partialUsers,
    currentPage,
    lastPage,
    pageSize,
    totalCount,
    loadingStatus,
    changePage,
    changePageSize,
    searchValue,
    orderBy,
    handleSearch,
    reload,
  } = usePartialUsersData()
  const { hasSubsidiary } = useCurrentSubsidiariesData()
  const { openModalReport, importReport, handleReportClose, getLicensedUsers } =
    useUsersTab()

  const [isLoading, setIsLoading] = useState(loadingStatus)
  const [noUsers, setNoUsers] = useState(false)
  const previousLoadingStatus = usePrevious(loadingStatus)
  const previousSearchValue = usePrevious(searchValue)

  useEffect(() => {
    let timer
    if (
      loadingStatus === LoadingStatus.PENDING &&
      previousLoadingStatus === LoadingStatus.LOADED
    )
      timer = setTimeout(() => {
        setIsLoading(loadingStatus)
      }, 500)
    else setIsLoading(loadingStatus)
    return () => clearTimeout(timer)
  }, [loadingStatus, previousLoadingStatus])

  useEffect(() => {
    let timer
    if (
      loadingStatus === LoadingStatus.LOADED &&
      totalCount === 0 &&
      searchValue.length === 0 &&
      previousSearchValue?.length === 0
    )
      timer = setTimeout(() => {
        setNoUsers(true)
      }, 100)
    else setNoUsers(false)
    return () => clearTimeout(timer)
  }, [loadingStatus, totalCount, searchValue, previousSearchValue])

  const renderEmpty = () => {
    return (
      <div className="users-tab__empty">
        <UsersEmptyIcon className="users-tab__empty__image" />
        <div className="users-tab__empty__text">
          <span>{formatMessage(messages.noUsers)}</span>
          <span>{formatMessage(messages.addUsers)}</span>
        </div>
      </div>
    )
  }

  const renderData = () => {
    return (
      <div className="users-tab__container">
        <UsersGrid
          hasSubsidiary={hasSubsidiary}
          partialUsers={partialUsers}
          currentPage={currentPage}
          lastPage={lastPage}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          sortOrders={orderBy}
          isLoading={isLoading === LoadingStatus.PENDING}
        />
        <div className="users-tab__licenced-users">
          {!hasSubsidiary && (
            <>
              <FormattedMessage
                id="UsersTab.Billable"
                defaultMessage="Comptes actifs : "
              />
              {getLicensedUsers()}
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="users-tab">
      <ImportExcelReport
        importReport={importReport}
        open={openModalReport}
        close={handleReportClose}
      />
      <div className="users-tab__header">
        <div className="users-tab__search">
          <SearchBar
            onChange={handleSearch}
            placeholder={formatMessage(messages.filter)}
            defaultValue={searchValue}
          />
        </div>
        <UsersHeader refresh={reload} hasSubsidiary={hasSubsidiary} />
      </div>
      {noUsers ? renderEmpty() : renderData()}
    </div>
  )
}

export default UsersTab
