import { FunctionComponent, useState } from "react"

import { useNavigate } from "react-router"

import { FieldValues, UseFormReturn } from "react-hook-form"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { getUsersOptions, Members } from "../EditGroup"

import Modal from "components/ModalLegacy"
import { SizeString } from "components/ModalLegacy/Modal"
import ListPicker from "components/ListPicker"
import ImageButton from "components/ImageButton"
import ListGeneric from "components/ListGeneric"
import Button from "components/Button"

import config from "config/config"
import { USERS_URL } from "router/RouterConstants"

import Group from "features/Groups/GroupsModels"
import { Origin, User } from "features/Users/UserModels"

interface Props {
  form: UseFormReturn<FieldValues, unknown>
  setMembers: React.Dispatch<React.SetStateAction<Members[]>>
  allUsers: User[]
  displayAddButton: boolean
  isAtLeatASyncActive: boolean
  members: Members[]
  currentGroup: Group
  onAddGroup
  onEditGroup
}

const messages = defineMessages({
  addBtn: { id: "EditGroup.AddBtn", defaultMessage: "Ajouter" },
  groupName: { id: "EditGroup.Name", defaultMessage: "Nom du groupe" },
  groupOrigin: { id: "EditGroup.Origin", defaultMessage: "Origine du groupe" },
  validBtn: { id: "EditGroup.ValidBtn", defaultMessage: "Valider" },
  modalTitle: {
    id: "EditGroup.ModalTitle",
    defaultMessage: "Ajouter des utilisateurs",
  },
})

const BasicForm: FunctionComponent<Props> = ({
  form,
  displayAddButton,
  isAtLeatASyncActive,
  setMembers,
  allUsers,
  members,
  currentGroup,
  onAddGroup,
  onEditGroup,
}) => {
  const { handleSubmit } = form
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const [openPicker, setOpenPicker] = useState(false)
  const [selectedPickerData, setSelectedPickerData] = useState([])
  const isUpdate = !!currentGroup

  const handleAdd = (form) => {
    const newGroup = {
      Name: form.name,
      Members: members.map((m) => m.id),
      Origin: isAtLeatASyncActive ? form.origin : Origin.ORIGIN_PORTAL,
    }
    onAddGroup(newGroup)
  }

  const handleEdit = (form) => {
    const updatedGroup = {
      id: currentGroup.Id,
      Name: form.name,
      Members: members.map((m) => m.id),
      Origin: form.origin,
    }
    onEditGroup(updatedGroup)
  }

  const submitModal = () => {
    setMembers(selectedPickerData)
    setOpenPicker(false)
  }

  const renderModal = () => {
    return (
      <Modal
        open={openPicker}
        close={() => setOpenPicker(false)}
        maxWidth={SizeString.SM}
        title={formatMessage(messages.modalTitle)}
        dialogContent={
          <ListPicker
            data={getUsersOptions(allUsers)}
            initSelectedData={members}
            onSelectedChange={(selectedData) =>
              setSelectedPickerData(selectedData)
            }
          />
        }
        dialogActions={
          <Button
            type="button"
            label={formatMessage(messages.validBtn)}
            onClick={submitModal}
          />
        }
      />
    )
  }

  return (
    <>
      {renderModal()}

      <div className="edit-group__label">
        <div className="edit-group-form_title">
          <FormattedMessage
            id="EditGroup.Users"
            defaultMessage="Utilisateurs"
          />
        </div>
        {members.length > 0 && <span>{` (${members.length})`}</span>}
      </div>
      <div data-cy="edit-group__members" className="edit-group__list">
        {displayAddButton && (
          <ImageButton
            image={<i className="material-icons">add_circle</i>}
            label={formatMessage(messages.addBtn)}
            onClick={() => setOpenPicker(true)}
            disabled={!allUsers || allUsers.length === 0}
          />
        )}
        <ListGeneric
          data={members}
          avatarSrc={`${config.cdnUrl}/images/bmm/users/ic_user_selection.svg`}
          styleOptions={{
            width: 400,
            minHeight: "150px",
            maxHeight: "30vh",
            overflowY: "auto",
          }}
          onClick={(item) => {
            navigate(`${USERS_URL}/${item.id}`)
          }}
          onDelete={(item) =>
            setMembers(members.filter((e) => e.id !== item.id))
          }
        />
      </div>
      <div className="edit-group__footer" data-cy="edit-group__footer">
        <Button
          onClick={handleSubmit(isUpdate ? handleEdit : handleAdd)}
          form="group-form"
          label={formatMessage(messages.validBtn)}
        />
      </div>
    </>
  )
}

export default BasicForm
