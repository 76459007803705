import { Dispatch, FunctionComponent } from "react"

import classNames from "classnames"

import { ReactComponent as HideIcon } from "assets/icon-hide.svg"
import { ReactComponent as PreviewIcon } from "assets/icon-preview.svg"

import { ActionType, Types } from "features/Tracking/hooks/useTrackingGraphData"

import {
  ScenarioTracking,
  SignatureTracking,
  TrackingGraphData,
} from "features/Tracking/TrackingModels"

interface GraphDataLegendItemProps {
  serie: TrackingGraphData
  dispatch: Dispatch<ActionType<SignatureTracking | ScenarioTracking>>
}

const GraphDataLegendItem: FunctionComponent<GraphDataLegendItemProps> = ({
  serie,
  dispatch,
}) => {
  return (
    <div
      className={classNames(
        "graph-data-legend-item",
        serie.selected && "is-selected",
        serie.thumbnailUrl && "is-large",
      )}
      onMouseEnter={() =>
        dispatch({ type: Types.HoverData, payload: serie.id })
      }
      onMouseLeave={() => dispatch({ type: Types.StopHoverData })}
      onClick={() =>
        serie.selected
          ? dispatch({ type: Types.UnselectData })
          : dispatch({ type: Types.SelectData, payload: serie.id })
      }
    >
      <div className="graph-data-legend-item__container">
        {serie.thumbnailUrl && (
          <img
            className="graph-data-legend-item__thumbnail"
            src={serie.thumbnailUrl}
            alt={serie.label}
          />
        )}
        <div className="graph-data-legend-item__color--container">
          <div
            className="graph-data-legend-item__color--background-one"
            style={{ background: serie.color }}
          />
          <div
            className="graph-data-legend-item__color"
            style={{ background: serie.color }}
          />
          <div
            className="graph-data-legend-item__color--background-two"
            style={{ background: serie.color }}
          />
        </div>
        <p className="graph-data-legend-item__label">{serie.label}</p>
      </div>
      <div className="graph-data-legend-item__icon">
        {serie.display ? <PreviewIcon /> : <HideIcon />}
      </div>
    </div>
  )
}

export default GraphDataLegendItem
