import Tour from "./Tour"

export const TOURSGUIDE_PROPERTY_NAME = "tutorial"
export const TOURS_LIST_PROPERTY_NAME = "tours"

/**
 * Représente l'état du guide pour l'utilisateur courant
 */
class TourGuide {
  display: boolean
  firstVisit: boolean
  tours: any

  constructor(tourGuideJson: JSON) {
    for (const prop in tourGuideJson) {
      if (tourGuideJson.hasOwnProperty(prop)) {
        this[prop] = tourGuideJson[prop]
      }
    }
    for (const key in this.tours) {
      this.tours[key] = new Tour(this.tours[key])
    }
  }

  updateTour(updatedTour: Tour) {
    this.tours = { ...this.tours, [updatedTour.name]: new Tour(updatedTour) }
    this.updateDisplay()
  }

  updateDisplay() {
    this.display = false
    for (const key in this.tours) {
      this.tours[key] = new Tour(this.tours[key])
      if (!this.tours[key].isComplete()) this.display = true
    }
  }
}

export default TourGuide
