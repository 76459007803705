import { FunctionComponent } from "react"

import { Link } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { ReactComponent as AddIcon } from "assets/icon-add.svg"

import { trialTunnelSelectors } from "features/Trial"

import {
  setActiveSignature,
  setSignatureFormStep,
} from "features/Signatures/SignaturesReducer"

import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

const NewSignatureButton: FunctionComponent = () => {
  const dispatch = useDispatch()
  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)

  const handleClick = () => {
    dispatch(setSignatureFormStep(1))
    dispatch(setActiveSignature(null))
  }
  return (
    <Link
      className="new-signature-btn"
      data-cy="new-signature-btn"
      onClick={handleClick}
      to={
        !isTrialFinished ? `${TRIAL_TUNNEL_URL}/1` : "/portal/signature/create"
      }
    >
      <AddIcon />
    </Link>
  )
}

export default NewSignatureButton
