import { FunctionComponent, ReactNode } from "react"
import ModalMainActionButton, {
  ModalMainActionButtonType,
} from "./components/ModalMainActionButton"
import ModalSecondaryActionButton, {
  ModalSecondaryActionButtonType,
} from "./components/ModalSecondaryActionButton"

interface ModalActionsComposition {
  Main: ModalMainActionButtonType
  Secondary: ModalSecondaryActionButtonType
}

interface ModalActionsProps {
  children: ReactNode
}

type ModalActionsType = FunctionComponent<ModalActionsProps> &
  ModalActionsComposition

const ModalActions: ModalActionsType = ({ children }) => {
  return <div className="modal-service__actions">{children}</div>
}

ModalActions.Main = ModalMainActionButton
ModalActions.Secondary = ModalSecondaryActionButton

export default ModalActions
