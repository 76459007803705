import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import ConnectedLangSwitch from "features/App/components/LangSwitch"
import ConnectedMenuUser from "../MenuUser"
import { NavLink } from "react-router-dom"
import SignatureProgress from "features/Signatures/SignatureProgress/"
import DropDown from "components/DropDown"
import { useDispatch, useSelector } from "react-redux"

import { Item } from "components/DropDown/DropDown"
import { GlobalStates } from "store/globalState"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { featureNames } from "config/config.features"
import useCurrentSubsidiariesData from "features/Subsidiaries/hooks/useCurrentSubsidiariesData"
import { accountsSelectors } from "features/Accounts"
import { ENROLLMENT_URL } from "router/RouterConstants"
import { updateCurrentSubsidiaryAction } from "features/Subsidiaries/SubsidiariesActions"

interface ProfilHeaderProps {
  identity: any
  offer: any
  resetTour: () => void
}

const ProfilHeader: React.FC<ProfilHeaderProps> = ({
  identity,
  offer,
  resetTour,
}) => {
  const [expanded, setExpended] = useState(false)
  const { currentSubsidiary, isLoaded, subsidiaries } =
    useCurrentSubsidiariesData()

  const dispatch = useDispatch()

  const isFeatureSubsidiariesActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.SUBSIDIARIES),
  )

  const isAccountInfosFilled = useSelector<GlobalStates>(
    accountsSelectors.getIsInformationsFilled,
  )

  const handleChange = (item: Item) => {
    dispatch(updateCurrentSubsidiaryAction(item.id))
  }

  const handleCloseMenu = () => {
    setExpended(false)
  }
  const handleOpenMenu = () => {
    setExpended(true)
  }

  const handleSwitchMenu = () => {
    setExpended(!expanded)
  }

  if (identity == null || !isAccountInfosFilled) {
    return null
  }

  const listItem = subsidiaries?.map((subsidiary) => ({
    id: subsidiary.id,
    name: subsidiary.name,
    img: subsidiary.iconUrl,
  }))
  const selectedItem = {
    id: currentSubsidiary?.id,
    name: currentSubsidiary?.name,
  }

  return (
    <div className="absolute-header">
      <div className="header-wrapper-left-side">
        {subsidiaries?.length > 1 &&
          isFeatureSubsidiariesActive &&
          isLoaded && (
            <DropDown
              handleChange={handleChange}
              listItem={listItem}
              selectedItem={selectedItem}
            />
          )}
      </div>

      <div className="header-wrapper-right-side">
        <SignatureProgress />
        <div className="header__left">
          {offer && offer.Id === 1 && (
            <NavLink
              className="link-header-abo"
              to={ENROLLMENT_URL}
              data-cy="cta-button"
            >
              <button className="bmm-button-header-abo">
                <FormattedMessage
                  id="ProfileHeader.Abonnement"
                  defaultMessage="S'ABONNER"
                />
              </button>
            </NavLink>
          )}
        </div>
        <div className="header__right">
          <div className="login">
            {identity && (
              <div className="align-center">
                <div
                  className="profile__container "
                  onClick={() => handleSwitchMenu()}
                >
                  <div className="profile__name" data-cy="profile__name">
                    {identity.UserName}
                  </div>
                </div>
              </div>
            )}
            {identity && (
              <ConnectedMenuUser
                expanded={expanded}
                openMenu={handleOpenMenu}
                closeMenu={handleCloseMenu}
                resetTour={resetTour}
              />
            )}
          </div>
          <ConnectedLangSwitch />
        </div>
      </div>
    </div>
  )
}
export default ProfilHeader
