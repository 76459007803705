import React, { FunctionComponent } from "react"
import ConnectedOnBoarding from "features/TourGuide/components/OnBoarding"

const OnBoardingPage: FunctionComponent = () => {
  return (
    <div className="col-sm-12">
      <ConnectedOnBoarding />
    </div>
  )
}

export default OnBoardingPage
