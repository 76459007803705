import { put, call } from "redux-saga/effects"

import { PayloadAction } from "@reduxjs/toolkit"

import { fetchCurrentTenantOffer } from "features/Accounts/AccountsSagas"
import { fetchAllUsers } from "features/Users/UsersReducers"
import { fetchAllGroups } from "features/Groups/GroupsReducer"
import {
  createQueueInstructionSuccessAction,
  deleteInstructionSuccessAction,
  fetchTenantFeaturesAction,
  loadInitialDatasAction,
  syncFinishedAction,
  tenantCreatedAction,
} from "features/Accounts/AccountsActions"

import {
  deleteOrigin,
  deleteRecurringJob,
  launchJob,
  launchRecurringJob,
  updateOrigin,
} from "features/Jobs/JobsApi"

import { addNotification } from "features/Notifications/NotificationReducer"

import { Notification } from "features/Notifications/NotificationModels"
import { hideGlobalLoader } from "features/App/AppReducers"

import { AvailableJobs } from "features/Jobs/JobsConstants"
import { JobData } from "features/Jobs/JobsModels"
import { updateEnrollmentStep } from "features/Enrollment/EnrollmentReducer"

import { ENROLLMENT_STEP_SUCCESS } from "features/Enrollment/EnrollmentConstants"

const CREATE_TENANT_JOB_NAME = "ISubscribe"
const SYNC_PREFIX_JOB_NAME = "ISync"
const UPSELL_JOB_NAME = "IUpsell"

// Orchestrateur de job
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* backgroundJobCallbacks(action: PayloadAction<JobData>) {
  if (action.payload.jobName === CREATE_TENANT_JOB_NAME) {
    yield put(tenantCreatedAction())
  }

  if (action.payload.jobName === UPSELL_JOB_NAME) {
    yield call(fetchCurrentTenantOffer)
    // * On force le step d'enrollment pour garder la page de succès affiché
    yield put(updateEnrollmentStep(ENROLLMENT_STEP_SUCCESS))
    yield put(loadInitialDatasAction())
  }

  if (action.payload.jobName.includes(SYNC_PREFIX_JOB_NAME)) {
    yield put(syncFinishedAction())
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createJobInstruction(
  action: PayloadAction<{
    instructionName: AvailableJobs
    isRecurring: boolean
  }>,
) {
  const { instructionName, isRecurring } = action.payload

  const isSync = instructionName.indexOf("ISync") !== -1

  const resultCreate = isRecurring
    ? yield call(launchRecurringJob, instructionName)
    : yield call(launchJob, instructionName)

  if (resultCreate.httpStatus !== 200) {
    throw resultCreate
  }

  if (isRecurring && isSync) {
    const resultUpdate = yield call(updateOrigin, instructionName)
    if (resultUpdate.httpStatus !== 200) {
      throw resultUpdate
    }
  }

  yield put(fetchTenantFeaturesAction())
  yield put(createQueueInstructionSuccessAction())
  yield put(hideGlobalLoader())

  if (isRecurring) {
    const message = isSync
      ? "La synchronisation a été activée"
      : "Le déploiement a été activé"
    yield put(addNotification(new Notification(message, "SUCCESS")))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* deleteJobInstruction(
  action: PayloadAction<{ instructionName: AvailableJobs; notified: boolean }>,
) {
  const { instructionName, notified } = action.payload

  const isSync = instructionName.indexOf("ISync") !== -1

  if (isSync) {
    const resultDeleteOrigin = yield call(deleteOrigin, instructionName)

    if (resultDeleteOrigin.httpStatus !== 200) {
      yield put(hideGlobalLoader())
      throw resultDeleteOrigin
    }
  }

  const resultDeleteJob = yield call(deleteRecurringJob, instructionName)

  if (resultDeleteJob.httpStatus !== 200) {
    throw resultDeleteJob
  }

  yield put(fetchTenantFeaturesAction())
  yield put(fetchAllUsers())
  yield put(fetchAllGroups())

  yield put(deleteInstructionSuccessAction())

  if (notified) {
    const message = isSync
      ? "La synchronisation a été désactivée"
      : "Le déploiement a été désactivé"
    yield put(addNotification(new Notification(message, "SUCCESS")))
  }
}
