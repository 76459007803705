
import React, { FC } from "react";
import CloseIcon from "@material-ui/icons/Close"
interface AdminItem {
    id: string
    label: string
  }

interface Props{
    subsidiariesAdmin: AdminItem[]
    removeOne?: (id: string) => void 
}

const SubsidiaryAdminPicker: FC<Props> = ({subsidiariesAdmin, removeOne} ) => {
    return( 
    <div className='subsidiary-admin-cards-container'>
        {subsidiariesAdmin.map((userAdmin) => (
            <div className="subsidiary-admin-card">
                <div className="subsidiary-admin-card_info">
                    <div className="subsidiary-admin-card_info--pin">F</div>
                    {userAdmin.label}
                </div>
                {
                    removeOne
                    &&
                    <CloseIcon onClick={() => removeOne(userAdmin.id) }/>
                }
            </div>
        ))}
    </div>)
   
}

export default SubsidiaryAdminPicker