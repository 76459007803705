import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit"

import moment from "moment"
import { RootState } from "store/configureStore"

import { BuilderSignature } from "./BuilderSignaturesModels"

import {
  LoadingSlices,
  LoadingSlicesTypes,
  createLoadingSlicesAdapter,
} from "core/services/loadingSlicesService"

import { SignaturesSlices } from "features/Signatures/SignaturesReducer"

import { LoadingStatus, SavingStatus } from "core/CoreModels"

import { SIGNATURES_SLICES } from "features/Signatures"
import { DEFAULT_SIGNATURE_WEIGHTING_VALUE } from "features/Signatures/SignaturesRules"

interface BuilderSignaturesEntities {
  [id: number]: BuilderSignature
}

export interface BuilderSignaturesState {
  allBuilderSignatures: {
    entities: BuilderSignaturesEntities
    ids: Array<number>
    signaturesSlices: LoadingSlices<SignaturesSlices>
  }
  editingBuilderSignature: {
    savingStatus: SavingStatus
    id: number | null
    assignments: { users: number[]; groups: number[] }
    sendingMode: { newMail: boolean; inResponse: boolean }
    weighting: number
    needSave: boolean
  }
  debugMode: boolean
}

const builderSignaturesAdapter = createEntityAdapter<BuilderSignature>({
  selectId: (builderSignature) => builderSignature.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const {
  selectAll: selectAllBuilderSignatures,
  selectById: selectBuilderSignatureById,
  selectIds: selectBuilderSignatureIds,
} = builderSignaturesAdapter.getSelectors<RootState>(
  (state) => state.builderSignatures.allBuilderSignatures,
)

const builderSignaturesSlicesAdapter =
  createLoadingSlicesAdapter<SignaturesSlices>(SIGNATURES_SLICES)

export const {
  selectAll: selectAllBuilderSignaturesSlices,
  selectById: selectBuilderSignatureSliceById,
} = builderSignaturesSlicesAdapter.getSelectors<RootState>(
  (state) => state.builderSignatures.allBuilderSignatures.signaturesSlices,
)

const initialState = {
  allBuilderSignatures: builderSignaturesAdapter.getInitialState({
    savingStatus: SavingStatus.IDLE,
    signaturesSlices: builderSignaturesSlicesAdapter.getInitialState(),
    activeId: null,
    activeBuilderSignatureAssignment: {
      users: [],
      groups: [],
    },
  }),
  editingBuilderSignature: {
    savingStatus: SavingStatus.IDLE,
    id: null,
    assignments: {
      users: [],
      groups: [],
    },
    sendingMode: { newMail: true, inResponse: false },
    weighting: DEFAULT_SIGNATURE_WEIGHTING_VALUE,
    needSave: false,
  },
  debugMode: false,
}

const builderSignaturesReducer = createSlice({
  name: "builderSignatures",
  initialState,
  reducers: {
    fetchBuilderSignatures(
      state,
      action: PayloadAction<LoadingSlicesTypes<SignaturesSlices>>,
    ) {
      builderSignaturesSlicesAdapter.setSliceToPending(
        state.allBuilderSignatures.signaturesSlices,
        action.payload,
      )
    },
    fetchBuilderSignaturesSuccess(
      state,
      action: PayloadAction<{
        signatures: BuilderSignature[]
        slice: LoadingSlicesTypes<SignaturesSlices>
      }>,
    ) {
      const { signatures, slice } = action.payload
      builderSignaturesAdapter.upsertMany(
        state.allBuilderSignatures,
        signatures,
      )

      builderSignaturesSlicesAdapter.setSliceToLoaded(
        state.allBuilderSignatures.signaturesSlices,
        slice,
      )
    },
    fetchBuilderSignaturesFailure(state) {
      builderSignaturesSlicesAdapter.setSliceToError(
        state.allBuilderSignatures.signaturesSlices,
        "all",
      )
    },
    createBuilderSignature(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<
        Pick<
          BuilderSignature,
          "name" | "json" | "newMail" | "inResponse" | "weighting"
        >
      >,
    ) {
      state.editingBuilderSignature.savingStatus = SavingStatus.SAVING
    },
    createBuilderSignatureSuccess(
      state,
      action: PayloadAction<BuilderSignature>,
    ) {
      builderSignaturesAdapter.addOne(
        state.allBuilderSignatures,
        action.payload,
      )
      state.editingBuilderSignature.id = action.payload.id
      state.editingBuilderSignature.savingStatus = SavingStatus.IDLE
      state.editingBuilderSignature.needSave = false
    },
    updateBuilderSignature(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<
        Pick<
          BuilderSignature,
          "id" | "name" | "json" | "newMail" | "inResponse" | "weighting"
        >
      >,
    ) {
      state.editingBuilderSignature.savingStatus = SavingStatus.SAVING
    },
    updateBuilderSignatureSuccess(
      state,
      action: PayloadAction<BuilderSignature>,
    ) {
      builderSignaturesAdapter.upsertOne(
        state.allBuilderSignatures,
        action.payload,
      )
      builderSignaturesAdapter.updateOne(state.allBuilderSignatures, {
        id: action.payload.id,
        changes: {
          affectUsersCountLoadingStatus: LoadingStatus.NOT_STARTED,
        },
      })

      state.editingBuilderSignature.savingStatus = SavingStatus.IDLE
      state.editingBuilderSignature.needSave = false
    },
    updateBuilderSignatureUsersSuccess(
      state,
      action: PayloadAction<{
        signatureId: number
        usersIds: number[]
      }>,
    ) {
      builderSignaturesAdapter.updateOne(state.allBuilderSignatures, {
        id: action.payload.signatureId,
        changes: {
          affectedUsers: action.payload.usersIds,
          affectUsersCountLoadingStatus: LoadingStatus.NOT_STARTED,
        },
      })

      state.editingBuilderSignature.savingStatus = SavingStatus.IDLE
    },
    updateBuilderSignatureGroupsSuccess(
      state,
      action: PayloadAction<{
        signatureId: number
        groupsIds: number[]
      }>,
    ) {
      builderSignaturesAdapter.updateOne(state.allBuilderSignatures, {
        id: action.payload.signatureId,
        changes: {
          affectedGroups: action.payload.groupsIds,
          affectUsersCountLoadingStatus: LoadingStatus.NOT_STARTED,
        },
      })
    },
    updateActiveBuilderSignatureAssignment(
      state,
      action: PayloadAction<{
        users?: number[]
        groups?: number[]
      }>,
    ) {
      if (action.payload.users)
        state.editingBuilderSignature.assignments.users = action.payload.users

      if (action.payload.groups)
        state.editingBuilderSignature.assignments.groups = action.payload.groups
    },
    setActiveBuilderSignature(state, action: PayloadAction<number | null>) {
      state.editingBuilderSignature.id = action.payload
      state.editingBuilderSignature.assignments = {
        users: [],
        groups: [],
      }
    },
    deleteBuilderSignatureSuccess(state, action: PayloadAction<number>) {
      builderSignaturesAdapter.removeOne(
        state.allBuilderSignatures,
        action.payload,
      )
    },
    activateBuilderSignatureSuccess(
      state,
      action: PayloadAction<{
        id: number
        activated: boolean
        activatedDate: string
      }>,
    ) {
      const { id, activated, activatedDate } = action.payload
      builderSignaturesAdapter.updateOne(state.allBuilderSignatures, {
        id,
        changes: {
          activated: activated,
          activatedDate: moment(activatedDate),
        },
      })
    },
    loadBuilderSignatureAffectations(state, action: PayloadAction<number>) {
      builderSignaturesAdapter.updateOne(state.allBuilderSignatures, {
        id: action.payload,
        changes: {
          affectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    assignationBuilderSignatureSuccess(
      state,
      action: PayloadAction<{
        id: number
        affectedGroups: number[]
        affectedUsers: number[]
        affectedUsersCount: number[]
      }>,
    ) {
      const { id, affectedGroups, affectedUsers, affectedUsersCount } =
        action.payload
      builderSignaturesAdapter.updateOne(state.allBuilderSignatures, {
        id,
        changes: {
          affectedGroups: affectedGroups,
          affectedUsers: affectedUsers,
          affectedUsersCount: affectedUsersCount,
          affectUsersCountLoadingStatus: LoadingStatus.LOADED,
        },
      })
    },
    setActiveSendingMode(
      state,
      action: PayloadAction<{ newMail: boolean; inResponse: boolean }>,
    ) {
      state.editingBuilderSignature.sendingMode = action.payload
    },
    setEditingBuilderSignatureWeighting(state, action: PayloadAction<number>) {
      state.editingBuilderSignature.weighting = action.payload
    },
    toggleDebugMode(state) {
      state.debugMode = !state.debugMode
    },
    setNeedSave(state, action: PayloadAction<boolean>) {
      state.editingBuilderSignature.needSave = action.payload
    },
  },
})

export const {
  fetchBuilderSignatures,
  fetchBuilderSignaturesSuccess,
  fetchBuilderSignaturesFailure,
  setActiveBuilderSignature,
  createBuilderSignature,
  createBuilderSignatureSuccess,
  updateBuilderSignature,
  updateBuilderSignatureSuccess,
  updateBuilderSignatureUsersSuccess,
  updateBuilderSignatureGroupsSuccess,
  updateActiveBuilderSignatureAssignment,
  deleteBuilderSignatureSuccess,
  activateBuilderSignatureSuccess,
  loadBuilderSignatureAffectations,
  assignationBuilderSignatureSuccess,
  setActiveSendingMode,
  setEditingBuilderSignatureWeighting,
  toggleDebugMode,
  setNeedSave,
} = builderSignaturesReducer.actions

export default builderSignaturesReducer.reducer
