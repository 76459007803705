import React from "react"
import { FormattedMessage } from "react-intl"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"

interface PropsCB {
  onChange?: (key: string, value: string | boolean) => void
}

const CheckoutCB: React.FC<PropsCB> = (props) => {
  return (
    <div className="bank-details__facturation">
      <div className="bank-details__form">
        <div className="stripe-checkout">
          <div className="bmm-form__input__container">
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="holder">
                <FormattedMessage
                  id="BillingForm.OwnerCard"
                  defaultMessage="Titulaire de la carte"
                ></FormattedMessage>
                <input
                  className="billing-form__textbox"
                  name="holder"
                  onChange={(e) =>
                    props.onChange(e.target.name, e.target.value)
                  }
                  type="text"
                />
              </label>
            </div>
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="number">
                <FormattedMessage
                  id="BillingForm.CardNumber"
                  defaultMessage="Numéro de carte"
                ></FormattedMessage>
                <CardNumberElement
                  onChange={(e) => props.onChange(e.elementType, e.complete)}
                />
              </label>
            </div>
          </div>
          <div className="bmm-form__input__container">
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="expiry">
                <FormattedMessage
                  id="BillingForm.CardExpiry"
                  defaultMessage="Expire fin"
                ></FormattedMessage>
                <CardExpiryElement
                  onChange={(e) => props.onChange(e.elementType, e.complete)}
                />
              </label>
            </div>
            <div className="billing-form__input">
              <label className="billing-form__label" htmlFor="cvv">
                <FormattedMessage
                  id="BillingForm.CardCVC"
                  defaultMessage="CVC"
                ></FormattedMessage>
                <CardCvcElement
                  onChange={(e) => props.onChange(e.elementType, e.complete)}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutCB
