import React from "react"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"

import "./Stepper.scss"

interface Props {
  activeStep?: number
  steps: React.ReactNode[]
  disableRipple?: boolean
  onClick?(step: number): void
}

const BmmStepper: React.FC<Props> = ({
  activeStep = 1,
  steps,
  disableRipple,
  onClick,
}) => {
  const handleClick = (i) => {
    if (activeStep < steps.length + 1) onClick && onClick(i + 1)
  }
  return (
    <Stepper
      activeStep={activeStep - 1}
      style={{ backgroundColor: "transparent" }}
      className="stepper"
      alternativeLabel
    >
      {steps?.map(
        (label, i) =>
          label !== "" && (
            <Step key={i}>
              <StepButton
                onClick={(e) => {
                  handleClick(i)
                }}
                completed={activeStep > i + 1}
                style={{
                  cursor: activeStep > steps.length ? "default" : "pointer",
                }}
                disableRipple={disableRipple}
              >
                {label}
              </StepButton>
            </Step>
          ),
      )}
    </Stepper>
  )
}

export default BmmStepper
