import { FunctionComponent, useState } from "react"

import { useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as IconDuotoneUsers } from "assets/duotone/icon-users-regular-duotone.svg"
import { ReactComponent as IconDuotoneSignature } from "assets/duotone/icon-signature-duotone.svg"
import { ReactComponent as IconDuotoneMegaphone } from "assets/duotone/icon-megaphone-duotone.svg"
import { ReactComponent as IconDuotoneAddUsers } from "assets/duotone/icon-add-users-duotone.svg"
import { ReactComponent as IconDuotoneSync } from "assets/duotone/icon-synchronisation-duotone.svg"

import signatureWelcomeCardVisual from "features/Accounts/assets/welcome-card-signature-banner.webp"
import scenarioWelcomeCardVisual from "features/Accounts/assets/welcome-card-scenario-banner.webp"
import usersWelcomeCardVisual from "features/Accounts/assets/welcome-card-users-banner.webp"

import WelcomeCard from "./WelcomeCard"

import { trialTunnelSelectors } from "features/Trial"

import {
  CAMPAIGNS_URL,
  SIGNATURES_URL,
  TRIAL_TUNNEL_URL,
  USERS_URL,
} from "router/RouterConstants"

import { OnBoardingKey } from "features/Accounts/AccountsModels"

import { accountsSelectors } from "features/Accounts"
import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  addUsers: {
    id: "OnBoarding.WelcomeCardAddUsers",
    defaultMessage: "Ajoutez vos utilisateurs",
  },
  createSignature: {
    id: "OnBoarding.WelcomeCardCreateSignature",
    defaultMessage: "Créez votre première signature",
  },
  createCampaign: {
    id: "OnBoarding.WelcomeCardCreateCampaign",
    defaultMessage: "Créez votre première campagne",
  },
  assignUsers: {
    id: "OnBoarding.WelcomeCardAssignUsers",
    defaultMessage: "Signatures associées aux utilisateurs",
  },
  firstSync: {
    id: "OnBoarding.WelcomeCardFirstSync",
    defaultMessage: "Première synchronisation",
  },
  usersTitle: {
    id: "OnBoarding.HoverCardUsersTitle",
    defaultMessage: "Ajoutez vos utilisateurs",
  },
  signatureTitle: {
    id: "OnBoarding.HoverCardSignatureTitle",
    defaultMessage: "Créez votre première signature de mail",
  },
  scenarioTitle: {
    id: "OnBoarding.HoverCardScenarioTitle",
    defaultMessage: "Créez votre première bannière ou un scénario",
  },
  usersContentTitleOne: {
    id: "OnBoarding.HoverCardUserContentTitleOne",
    defaultMessage:
      "J'importe les utilisateurs de mon entreprise avec leurs données",
  },
  usersContentTitleTwo: {
    id: "OnBoarding.HoverCardUserContentTitleTwo",
    defaultMessage:
      "J'attribue une licence aux personnes qui bénéficieront d'une signature",
  },
  usersContentTitleThree: {
    id: "OnBoarding.HoverCardUserContentTitleThree",
    defaultMessage: "Je créé des groupes de collaborateurs",
  },
  usersContentElementOne: {
    id: "OnBoarding.HoverCardUserContentElementOne",
    defaultMessage:
      "En m'appuyant sur un annuaire : Microsoft, Google Workspace ou Lucca",
  },
  usersContentElementTwo: {
    id: "OnBoarding.HoverCardUserContentElementTwo",
    defaultMessage: "En passant par une API",
  },
  usersContentElementThree: {
    id: "OnBoarding.HoverCardUserContentElementThree",
    defaultMessage:
      "En important un fichier Excel après avoir téléchargé le modèle",
  },
  usersContentElementFour: {
    id: "OnBoarding.HoverCardUserContentElementFour",
    defaultMessage: "En ajoutant manuellement mes collaborateurs",
  },
  usersContentElementFive: {
    id: "OnBoarding.HoverCardUserContentElementFive",
    defaultMessage:
      "(manuellement ou automatiquement, par services, par filiales, par marques...)",
  },
  signatureContentTitleOne: {
    id: "OnBoarding.HoverCardSignatureContentTitleOne",
    defaultMessage: "J'ajoute toutes les propriétés utilisateurs souhaitées",
  },
  signatureContentTitleTwo: {
    id: "OnBoarding.HoverCardSignatureContentTitleTwo",
    defaultMessage:
      "Je personnalise la mise en page, les textes et les couleurs",
  },
  signatureContentTitleThree: {
    id: "OnBoarding.HoverCardSignatureContentTitleThree",
    defaultMessage: "Je paramètre les modalités de ma signature",
  },
  signatureContentTitleFour: {
    id: "OnBoarding.HoverCardSignatureContentTitleFour",
    defaultMessage: "J'assigne la signature à des utilisateurs et groupes",
  },
  signatureContentElementOne: {
    id: "OnBoarding.HoverCardSignatureContentElementOne",
    defaultMessage:
      "(nom, prénom, poste, téléphone, réseaux sociaux, séparateur, photo, logo...)",
  },
  signatureContentElementTwo: {
    id: "OnBoarding.HoverCardSignatureContentElementTwo",
    defaultMessage:
      "(disposition des éléments, ajout de marges, taille des textes, code couleurs)",
  },
  signatureContentElementThree: {
    id: "OnBoarding.HoverCardSignatureContentElementThree",
    defaultMessage: "(interne, externe ou les deux)",
  },
  scenarioContentTitleOne: {
    id: "OnBoarding.HoverCardScenarioContentTitleOne",
    defaultMessage: "Je créé une campagne simple ou un scénario de campagne",
  },
  scenarioContentTitleTwo: {
    id: "OnBoarding.HoverCardScenarioContentTitleTwo",
    defaultMessage: "J'importe un ou plusieurs visuels",
  },
  scenarioContentTitleThree: {
    id: "OnBoarding.HoverCardScenarioContentTitleThree",
    defaultMessage: "Je paramètre la campagne",
  },
  scenarioContentTitleFour: {
    id: "OnBoarding.HoverCardScenarioContentTitleFour",
    defaultMessage: "J'assigne la campagne à des utilisateurs et groupes",
  },
  scenarioContentElementOne: {
    id: "OnBoarding.HoverCardScenarioContentElementOne",
    defaultMessage:
      "Une campagne = un seul visuel. Un scénario = des visuels qui s'alternent toutes les 3h.",
  },
  scenarioContentElementTwo: {
    id: "OnBoarding.HoverCardScenarioContentElementTwo",
    defaultMessage:
      "Dimension conseillée : 600x150px. Format : JPG, PNG ou GIF.",
  },
  scenarioContentElementThree: {
    id: "OnBoarding.HoverCardScenarioContentElementThree",
    defaultMessage:
      "(nom, lien, dates de publication, ciblage et niveau de priorité)",
  },
})

function WELCOME_CARDS_ITEMS(
  format: (...args: unknown[]) => string,
  isTrialFinished: boolean,
) {
  return [
    {
      id: "addUsers",
      icon: <IconDuotoneAddUsers />,
      label: messages.addUsers,
      hoverCardcontent: {
        title: format(messages.usersTitle),
        visual: usersWelcomeCardVisual,
        url: `${USERS_URL}/-1`,
        content: [
          {
            title: format(messages.usersContentTitleOne),
            elements: [
              format(messages.usersContentElementOne),
              format(messages.usersContentElementTwo),
              format(messages.usersContentElementThree),
              format(messages.usersContentElementFour),
            ],
          },
          {
            title: format(messages.usersContentTitleTwo),
            elements: [],
          },
          {
            title: format(messages.usersContentTitleThree),
            elements: [format(messages.usersContentElementFive)],
          },
        ],
      },
    },
    {
      id: "firstSignature",
      icon: <IconDuotoneSignature />,
      label: messages.createSignature,
      hoverCardcontent: {
        title: format(messages.signatureTitle),
        visual: signatureWelcomeCardVisual,
        url: isTrialFinished
          ? `${SIGNATURES_URL}/create`
          : `${TRIAL_TUNNEL_URL}/1`,
        content: [
          {
            title: format(messages.signatureContentTitleOne),
            elements: [format(messages.signatureContentElementOne)],
          },
          {
            title: format(messages.signatureContentTitleTwo),
            elements: [format(messages.signatureContentElementTwo)],
          },
          {
            title: format(messages.signatureContentTitleThree),
            elements: [format(messages.signatureContentElementThree)],
          },
          { title: format(messages.signatureContentTitleFour), elements: [] },
        ],
      },
    },
    {
      id: "firstCampaign",
      icon: <IconDuotoneMegaphone />,
      label: messages.createCampaign,
      hoverCardcontent: {
        title: format(messages.scenarioTitle),
        visual: scenarioWelcomeCardVisual,
        url: `${CAMPAIGNS_URL}/edit/step/1`,
        content: [
          {
            title: format(messages.scenarioContentTitleOne),
            elements: [format(messages.scenarioContentElementOne)],
          },
          {
            title: format(messages.scenarioContentTitleTwo),
            elements: [format(messages.scenarioContentElementTwo)],
          },
          {
            title: format(messages.scenarioContentTitleThree),
            elements: [format(messages.scenarioContentElementThree)],
          },
          {
            title: format(messages.scenarioContentTitleFour),
            elements: [],
          },
        ],
      },
    },
    {
      id: "assignedSignature",
      icon: <IconDuotoneUsers />,
      label: messages.assignUsers,
    },
    {
      id: "syncedSignature",
      icon: <IconDuotoneSync />,
      label: messages.firstSync,
    },
  ]
}

const WELCOME_CARDS_LOADING_ITEMS_LENGTH = 5

const VISIBLE_CARD_DEFAULT_STATE = {
  addUsers: false,
  firstSignature: false,
  firstCampaign: false,
}

const WelcomeCardsContainer: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(VISIBLE_CARD_DEFAULT_STATE)
  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)

  const loadingStatus = useSelector(
    accountsSelectors.getOnBoardingItemsLoadingStatus,
  )

  const handleDisplay = (cardId) => {
    setVisible((prevState) =>
      Object.entries(prevState).reduce(
        (acc, [key]) => ({ ...acc, [key]: key === cardId }),
        prevState,
      ),
    )
  }

  const handleHide = () => {
    setVisible((prevState) =>
      Object.entries(prevState).reduce(
        (acc, [key]) => ({ ...acc, [key]: false }),
        prevState,
      ),
    )
  }

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <div className="onboarding-tile__welcome-cards-container">
        {[...Array(WELCOME_CARDS_LOADING_ITEMS_LENGTH)].map((_, index) => (
          <div className="onboarding-tile__skeleton-card" key={index} />
        ))}
      </div>
    )
  return (
    <div className="onboarding-tile__welcome-cards-container">
      <div className="onboarding-tile__welcome-cards-container--line" />
      {WELCOME_CARDS_ITEMS(formatMessage, isTrialFinished).map((card) => (
        <WelcomeCard
          id={card.id as OnBoardingKey}
          key={card.id}
          icon={card.icon}
          label={formatMessage(card.label)}
          hoverCardContent={card.hoverCardcontent}
          displayHoverCard={visible?.[card.id]}
          onDisplayHoverCard={() => handleDisplay(card.id)}
          onHideHoverCard={() => handleHide()}
        />
      ))}
    </div>
  )
}

export default WelcomeCardsContainer
