import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import {
  getBase64,
  getSizeOfImageFile,
  getImageSizeFromDataUrl,
} from "utils/ImageUtils"
import { fromBlobToFile } from "features/Storage/services/fileService"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import TooltipInfo from "components/TooltipInfo"
import CanvaButton from "components/CanvaButton"

import CampaignFormatSelector from "features/Scenarios/components/CampaignFormatSelector"
import CampaignLibrary from "features/Scenarios/components/CampaignLibrary"
import BannerUploadCard from "features/Scenarios/components/BannerUploadCard"

const messages = defineMessages({
  addPicture: {
    id: "Campaigns.AddPicture",
    defaultMessage: "Ajouter un visuel",
  },
  uploadTitle: {
    id: "Campaigns.UploadTitle",
    defaultMessage: "Importer une image",
  },
  formatTitle: {
    id: "Campaigns.FormatTitle",
    defaultMessage: "Choisir un format",
  },
  canvaTitle: {
    id: "Campaigns.CanvaTitle",
    defaultMessage: "Créer une bannière avec Canva",
  },
  bannerPreviewTitle: {
    id: "Campaigns.BannerPreviewTitle",
    defaultMessage: "Choisir un visuel dans la bibliothèque",
  },
})

const CampaignFormImagesSidebar: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { editingCampaign, updateEditingCampaignVisual, visuals } =
    useScenarioFormContext()
  const showFormat = false

  const handleUpload = async (blobFile: Blob) => {
    const file = fromBlobToFile(blobFile)
    const size = await getSizeOfImageFile(file)

    const handleFileChangeCallback = (imageAsBase64Url: string) => {
      updateEditingCampaignVisual({
        imageUrl: imageAsBase64Url,
        width: size.width,
        height: size.height,
      })
    }
    getBase64(file, handleFileChangeCallback)
  }

  const handleLibrary = (visual) => {
    updateEditingCampaignVisual(visual)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCanva = async (options: any) => {
    const size = await getImageSizeFromDataUrl(options.exportUrl)
    updateEditingCampaignVisual({
      imageUrl: options.exportUrl,
      width: size.width,
      height: size.height,
      canva: {
        designId: options.designId,
        designName: options.designTitle,
        canvaUrl: options.exportUrl,
      },
    })
  }

  return (
    <div className="campaignform__images">
      <TooltipInfo intlId="Tooltip.AddBannerPicture">
        <h4>{formatMessage(messages.addPicture)}</h4>
      </TooltipInfo>
      <h5>{formatMessage(messages.uploadTitle)}</h5>
      <BannerUploadCard onFileChange={handleUpload} />
      {showFormat && (
        <>
          <h5>{formatMessage(messages.formatTitle)}</h5>
          <CampaignFormatSelector
            active={"default"} // TODO
          />
        </>
      )}
      <h5>{formatMessage(messages.canvaTitle)}</h5>
      {/* // TODO -  Permettre une selection de la bannière en cours d'édition à droite - actuellement sur le mode scenario, on tape sur la 1ère bannière */}
      <CanvaButton
        designId={editingCampaign.banners[0]?.canva?.designId}
        handleCanva={handleCanva}
      />
      <h5>{formatMessage(messages.bannerPreviewTitle)}</h5>
      <div className="campaign-library-container">
        <CampaignLibrary visuals={visuals} onClick={handleLibrary} />
      </div>
    </div>
  )
}

export default CampaignFormImagesSidebar
