import React, { FunctionComponent } from "react"

const GSuiteIcon: FunctionComponent = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        d="M10.0135 24.273C10.0047 24.032 10.0434 23.7917 10.1273 23.5665C10.2112 23.3412 10.3386 23.1355 10.5018 22.9618C10.6605 22.7929 10.8518 22.6597 11.0634 22.5706C11.2751 22.4815 11.5025 22.4385 11.7312 22.4442C11.9604 22.4352 12.1887 22.4767 12.4009 22.566C12.613 22.6553 12.8039 22.7903 12.9606 22.9618C13.2778 23.3178 13.4536 23.7824 13.4536 24.2644C13.4536 24.7464 13.2778 25.211 12.9606 25.567C12.8025 25.7367 12.6114 25.8705 12.3996 25.9597C12.1878 26.0488 11.9601 26.0914 11.7312 26.0846C11.5023 26.0914 11.2746 26.0488 11.0628 25.9596C10.851 25.8704 10.6599 25.7365 10.5018 25.5668C10.3386 25.3931 10.2112 25.1874 10.1273 24.9621C10.0434 24.7368 10.0047 24.4965 10.0134 24.2555L10.0135 24.273ZM10.6365 24.2555C10.6294 24.4193 10.6541 24.5829 10.709 24.7369C10.7639 24.8909 10.848 25.0322 10.9565 25.1527C11.0556 25.2616 11.1756 25.3484 11.3088 25.4078C11.4421 25.4671 11.5859 25.4978 11.7312 25.4978C11.8765 25.4978 12.0203 25.4671 12.1536 25.4078C12.2869 25.3484 12.4068 25.2616 12.5059 25.1527C12.7234 24.9083 12.8384 24.586 12.8259 24.2555C12.8357 23.9306 12.7208 23.6148 12.5059 23.3756C12.4065 23.2642 12.2855 23.1752 12.1507 23.1143C12.0158 23.0534 11.8701 23.022 11.7227 23.022C11.5754 23.022 11.4296 23.0534 11.2948 23.1143C11.16 23.1752 11.039 23.2642 10.9396 23.3756C10.8324 23.4935 10.7489 23.6319 10.694 23.7829C10.6391 23.9339 10.6138 24.0945 10.6196 24.2555"
        fill="#737373"
      />
      <path
        d="M14.5269 25.9811H13.9038V22.5649H14.5101V23.117H14.5438C14.6192 22.9222 14.7554 22.7584 14.9312 22.6512C15.1053 22.5303 15.3102 22.4642 15.5206 22.4612C15.6818 22.457 15.8422 22.4863 15.9921 22.5473L15.7901 23.1688C15.671 23.1273 15.5452 23.1097 15.4196 23.1171C15.3013 23.1174 15.1844 23.1437 15.0769 23.1943C14.9694 23.2449 14.8737 23.3186 14.7964 23.4104C14.622 23.5955 14.5252 23.8432 14.5269 24.1005V25.9811V25.9811Z"
        fill="#737373"
      />
      <path
        d="M19.2084 25.9811H18.4506L17.4065 24.3764L16.8844 24.8767V25.9809H16.2612V20.9777H16.8843V24.0487L18.3327 22.565H19.1411V22.5822L17.8443 23.9107L19.2084 25.9466V25.9811Z"
        fill="#737373"
      />
      <path
        d="M21.9204 25.032C21.9199 25.1811 21.8844 25.3279 21.817 25.46C21.7496 25.5921 21.6523 25.7058 21.5331 25.7913C21.2632 25.9863 20.9423 26.0939 20.612 26.1C20.2818 26.1062 19.9573 26.0107 19.6806 25.8259C19.4331 25.6535 19.2446 25.406 19.1416 25.1181L19.6974 24.8766C19.7684 25.0686 19.891 25.2361 20.0511 25.3596C20.2015 25.4751 20.3853 25.5357 20.5732 25.5318C20.7514 25.5396 20.9277 25.4915 21.0784 25.3938C21.1354 25.3643 21.1839 25.3202 21.2193 25.2658C21.2547 25.2114 21.2758 25.1485 21.2805 25.0833C21.2805 24.859 21.1124 24.7037 20.7921 24.6003L20.2195 24.445C19.5795 24.2728 19.2426 23.9452 19.2426 23.4794C19.2397 23.334 19.272 23.1901 19.3367 23.0607C19.4014 22.9312 19.4963 22.8202 19.6131 22.7375C19.8715 22.5642 20.171 22.4662 20.4795 22.454C20.7881 22.4419 21.0941 22.516 21.3646 22.6685C21.5899 22.7957 21.7676 22.996 21.8699 23.2378L21.3141 23.4794C21.2473 23.3315 21.1348 23.2103 20.9941 23.1344C20.8446 23.0504 20.676 23.0087 20.5057 23.0136C20.3515 23.0112 20.1998 23.053 20.0678 23.1344C20.0097 23.1625 19.9603 23.2062 19.9247 23.2609C19.8891 23.3156 19.8687 23.3792 19.8657 23.4449C19.8657 23.6347 20.0509 23.7727 20.4046 23.859L20.9098 23.997C21.5669 24.1692 21.9031 24.5146 21.9031 25.0322"
        fill="#737373"
      />
      <path
        d="M24.0926 26.1017C23.8579 26.0997 23.627 26.0407 23.419 25.9295C23.2264 25.8324 23.0633 25.6832 22.9474 25.4982H22.9137L22.9474 25.9812V27.4998H22.3242V22.5648H22.9305V23.0478H22.9642C23.0808 22.8633 23.2437 22.7144 23.4358 22.6165C23.7303 22.465 24.0657 22.4184 24.389 22.484C24.7123 22.5497 25.0051 22.7238 25.221 22.9789C25.5327 23.3333 25.7013 23.7959 25.6926 24.2729C25.7047 24.7505 25.5357 25.2143 25.221 25.5669C25.0842 25.7372 24.9118 25.8738 24.7164 25.9664C24.5211 26.059 24.3078 26.1053 24.0926 26.1018V26.1017ZM23.9916 25.5152C24.1349 25.5181 24.2771 25.4887 24.408 25.4291C24.539 25.3695 24.6556 25.2811 24.7494 25.1701C24.9506 24.9199 25.0606 24.6058 25.0606 24.2817C25.0606 23.9575 24.9506 23.6434 24.7494 23.3932C24.6556 23.2823 24.539 23.1938 24.408 23.1342C24.2771 23.0746 24.1349 23.0452 23.9916 23.0482C23.8485 23.0463 23.7066 23.0762 23.5758 23.1358C23.445 23.1953 23.3283 23.2832 23.2337 23.3932C23.0384 23.6461 22.9322 23.9592 22.9322 24.2817C22.9322 24.6041 23.0384 24.9172 23.2337 25.1701C23.3291 25.2793 23.4459 25.3665 23.5765 25.4259C23.7072 25.4854 23.8486 25.5158 23.9916 25.5152Z"
        fill="#737373"
      />
      <path
        d="M27.4107 22.4441C27.8093 22.4248 28.2003 22.5604 28.5055 22.8237C28.6443 22.9579 28.7525 23.1217 28.8224 23.3037C28.8922 23.4856 28.922 23.6811 28.9097 23.8761V25.981H28.3034V25.4979H28.2697C28.1608 25.6806 28.0071 25.8309 27.8239 25.9338C27.6407 26.0368 27.4344 26.0887 27.2255 26.0845C26.8877 26.0966 26.5576 25.9797 26.2992 25.7567C26.1795 25.6532 26.084 25.5236 26.0199 25.3772C25.9558 25.2309 25.9246 25.0716 25.9287 24.9113C25.924 24.7491 25.9567 24.5881 26.024 24.4413C26.0913 24.2945 26.1913 24.1659 26.316 24.0659C26.6209 23.847 26.9881 23.7378 27.3601 23.7554C27.6812 23.7417 28.0002 23.813 28.2865 23.9624V23.8071C28.2874 23.6991 28.2652 23.5922 28.2216 23.4938C28.178 23.3955 28.1139 23.3081 28.0339 23.2377C27.8686 23.0819 27.6521 22.9957 27.4275 22.9961C27.2615 22.9914 27.0971 23.0302 26.9497 23.1087C26.8024 23.1872 26.677 23.3029 26.5855 23.4448L26.0634 23.0998C26.217 22.8844 26.4207 22.7116 26.656 22.5971C26.8912 22.4826 27.1506 22.4301 27.4107 22.4441ZM26.6023 24.9286C26.6023 25.0089 26.6206 25.0882 26.6556 25.1601C26.6907 25.2319 26.7416 25.2945 26.8044 25.3427C26.9425 25.4569 27.1154 25.5178 27.2928 25.5149C27.4238 25.5162 27.5537 25.4909 27.6751 25.4406C27.7965 25.3903 27.9069 25.3158 28.0002 25.2216C28.0985 25.1329 28.1778 25.0242 28.2329 24.9024C28.288 24.7806 28.3177 24.6484 28.3202 24.5142C28.0798 24.34 27.7888 24.2549 27.4949 24.2726C27.2677 24.2673 27.0443 24.3336 26.8549 24.4624C26.7767 24.5106 26.7123 24.579 26.6679 24.6608C26.6236 24.7425 26.601 24.8347 26.6023 24.9282"
        fill="#737373"
      />
      <path
        d="M31.0482 26.1018C30.8193 26.1086 30.5915 26.0661 30.3797 25.9769C30.1679 25.8878 29.9768 25.754 29.8187 25.5843C29.4953 25.2291 29.3201 24.7586 29.3303 24.273C29.3215 24.0321 29.3602 23.7917 29.4442 23.5664C29.5281 23.3411 29.6554 23.1355 29.8187 22.9617C29.9774 22.7929 30.1686 22.6596 30.3803 22.5705C30.5919 22.4814 30.8193 22.4384 31.0481 22.4441C31.3646 22.4339 31.6764 22.5243 31.9407 22.7029C32.1967 22.8819 32.3958 23.1338 32.5133 23.4275L31.9407 23.6691C31.8728 23.4756 31.7463 23.3093 31.5797 23.1945C31.4131 23.0797 31.2151 23.0223 31.0145 23.0308C30.8727 23.0324 30.7329 23.0657 30.6049 23.1283C30.4769 23.1908 30.3638 23.2812 30.2733 23.3931C30.0734 23.6406 29.964 23.9518 29.964 24.273C29.964 24.5943 30.0734 24.9054 30.2733 25.1529C30.3887 25.2865 30.5353 25.3878 30.6994 25.4474C30.8636 25.507 31.0398 25.5229 31.2115 25.4936C31.3832 25.4642 31.5449 25.3907 31.6813 25.2798C31.8176 25.1689 31.9243 25.0243 31.9911 24.8596L32.547 25.1012C32.435 25.3983 32.2347 25.6518 31.9743 25.8258C31.7069 26.0129 31.3889 26.1095 31.0651 26.1019"
        fill="#737373"
      />
      <path
        d="M34.4168 26.1019C34.1902 26.1107 33.9644 26.069 33.755 25.9796C33.5456 25.8902 33.3577 25.7554 33.2042 25.5843C33.0418 25.41 32.9149 25.2043 32.831 24.9791C32.7472 24.754 32.708 24.5139 32.7158 24.2731C32.701 23.7899 32.8701 23.3197 33.1874 22.9618C33.3403 22.7984 33.5234 22.6677 33.726 22.5773C33.9286 22.487 34.1467 22.4388 34.3677 22.4356C34.5886 22.4324 34.808 22.4742 35.0131 22.5586C35.2181 22.643 35.4047 22.7684 35.5621 22.9273C35.8484 23.2551 36 23.721 36 24.3076V24.3937H33.3725C33.3701 24.5439 33.3972 24.6931 33.4521 24.8324C33.5071 24.9717 33.5888 25.0983 33.6925 25.2046C33.8133 25.3222 33.9594 25.409 34.119 25.458C34.2787 25.5069 34.4473 25.5167 34.6113 25.4864C34.7753 25.4562 34.9301 25.3868 35.063 25.2839C35.196 25.181 35.3034 25.0474 35.3766 24.894L35.9324 25.1701C35.7886 25.4486 35.5731 25.6814 35.3093 25.8433C35.0321 26.004 34.7185 26.0873 34.4 26.0849L34.4168 26.1019ZM33.3895 23.8418H35.3263C35.3099 23.6152 35.2071 23.4044 35.04 23.2551C34.8489 23.0863 34.6014 22.9997 34.3494 23.0136C34.1274 23.01 33.9117 23.0897 33.7431 23.2378C33.5663 23.3933 33.4473 23.6066 33.4063 23.8417"
        fill="#737373"
      />
      <path
        d="M11.8997 15.3358V14.5594H14.426C14.4515 14.7191 14.4628 14.8807 14.4597 15.0425C14.4597 15.6119 14.3081 16.3365 13.8025 16.8541C13.5535 17.12 13.2519 17.3283 12.9179 17.4652C12.584 17.602 12.2253 17.6643 11.866 17.6477C11.1288 17.6432 10.4228 17.3424 9.8999 16.81C9.37703 16.2776 9.07913 15.5561 9.07031 14.8009C9.07916 14.0443 9.37993 13.322 9.90691 12.7918C10.4339 12.2616 11.1443 11.9666 11.8829 11.9713C12.2319 11.9659 12.5784 12.0318 12.9023 12.1651C13.2261 12.2984 13.5208 12.4965 13.7691 12.7478L13.2301 13.2999C12.8647 12.9382 12.3741 12.7397 11.866 12.7478C11.6022 12.7478 11.341 12.8016 11.0976 12.906C10.8543 13.0105 10.6337 13.1636 10.4487 13.3563C10.2638 13.549 10.1181 13.7775 10.0202 14.0285C9.92235 14.2795 9.87425 14.548 9.87873 14.8182C9.87425 15.0885 9.92235 15.3569 10.0202 15.6079C10.1181 15.8589 10.2638 16.0874 10.4487 16.2801C10.6337 16.4729 10.8543 16.6259 11.0976 16.7304C11.341 16.8348 11.6022 16.8886 11.866 16.8886C12.1261 16.8969 12.3851 16.8489 12.6259 16.7479C12.8668 16.6468 13.0841 16.4948 13.2638 16.302C13.5006 16.0355 13.643 15.6952 13.668 15.3358H11.8997Z"
        fill="#737373"
      />
      <path
        d="M18.3156 15.8189C18.3156 16.1806 18.2109 16.5342 18.0147 16.8349C17.8186 17.1357 17.5397 17.3701 17.2135 17.5085C16.8873 17.647 16.5284 17.6832 16.182 17.6126C15.8357 17.542 15.5176 17.3678 15.268 17.1121C15.0183 16.8563 14.8483 16.5304 14.7794 16.1756C14.7105 15.8208 14.7459 15.4531 14.881 15.1189C15.0162 14.7848 15.245 14.4991 15.5386 14.2982C15.8322 14.0972 16.1773 13.99 16.5304 13.99C17.0039 13.99 17.458 14.1827 17.7928 14.5257C18.1276 14.8686 18.3157 15.3338 18.3157 15.8189H18.3156ZM17.541 15.8189C17.5595 15.6729 17.5474 15.5245 17.5056 15.3837C17.4637 15.2428 17.393 15.1128 17.2981 15.0022C17.2033 14.8915 17.0865 14.8029 16.9556 14.7422C16.8247 14.6815 16.6826 14.6501 16.5389 14.6501C16.3952 14.6501 16.2531 14.6815 16.1222 14.7422C15.9913 14.8029 15.8745 14.8915 15.7796 15.0022C15.6848 15.1128 15.6141 15.2428 15.5722 15.3837C15.5303 15.5245 15.5183 15.6729 15.5368 15.8189C15.5183 15.9649 15.5303 16.1132 15.5722 16.2541C15.6141 16.3949 15.6848 16.525 15.7796 16.6356C15.8745 16.7462 15.9913 16.8348 16.1222 16.8955C16.2531 16.9562 16.3952 16.9876 16.5389 16.9876C16.6826 16.9876 16.8247 16.9562 16.9556 16.8955C17.0865 16.8348 17.2033 16.7462 17.2981 16.6356C17.393 16.525 17.4637 16.3949 17.5056 16.2541C17.5474 16.1132 17.5595 15.9649 17.541 15.8189Z"
        fill="#737373"
      />
      <path
        d="M22.2059 15.8189C22.2059 16.1824 22.1007 16.5376 21.9036 16.8398C21.7066 17.142 21.4264 17.3775 21.0987 17.5166C20.7709 17.6556 20.4103 17.692 20.0623 17.6211C19.7144 17.5502 19.3948 17.3752 19.144 17.1182C18.8931 16.8612 18.7223 16.5338 18.6531 16.1774C18.5839 15.8209 18.6194 15.4515 18.7552 15.1157C18.891 14.7799 19.1209 14.493 19.4159 14.2911C19.7108 14.0892 20.0576 13.9814 20.4124 13.9814C20.8881 13.9814 21.3443 14.175 21.6807 14.5196C22.017 14.8642 22.206 15.3316 22.206 15.8189H22.2059ZM21.4313 15.8189C21.4498 15.6729 21.4378 15.5246 21.3959 15.3837C21.354 15.2429 21.2833 15.1128 21.1885 15.0022C21.0936 14.8916 20.9768 14.803 20.8459 14.7423C20.715 14.6816 20.5729 14.6502 20.4292 14.6502C20.2855 14.6502 20.1434 14.6816 20.0125 14.7423C19.8816 14.803 19.7648 14.8916 19.67 15.0022C19.5751 15.1128 19.5044 15.2429 19.4626 15.3837C19.4207 15.5246 19.4086 15.6729 19.4271 15.8189C19.4086 15.9649 19.4207 16.1133 19.4626 16.2541C19.5044 16.395 19.5751 16.525 19.67 16.6356C19.7648 16.7463 19.8816 16.8349 20.0125 16.8956C20.1434 16.9563 20.2855 16.9877 20.4292 16.9877C20.5729 16.9877 20.715 16.9563 20.8459 16.8956C20.9768 16.8349 21.0936 16.7463 21.1885 16.6356C21.2833 16.525 21.354 16.395 21.3959 16.2541C21.4378 16.1133 21.4498 15.9649 21.4313 15.8189Z"
        fill="#737373"
      />
      <path
        d="M25.9453 14.0937V17.3718C25.9453 18.7175 25.1706 19.2696 24.2612 19.2696C23.9234 19.2725 23.5925 19.1722 23.3105 18.9817C23.0286 18.7911 22.8084 18.5189 22.678 18.1997L23.3685 17.9064C23.436 18.0948 23.5572 18.258 23.7164 18.3749C23.8755 18.4919 24.0654 18.5571 24.2612 18.5621C24.8506 18.5621 25.2211 18.1825 25.2211 17.4924V17.2165H25.1874C25.0693 17.3519 24.9237 17.4592 24.7607 17.5308C24.5978 17.6023 24.4215 17.6364 24.2443 17.6306C23.7728 17.6274 23.3217 17.4333 22.9895 17.0907C22.6572 16.7481 22.4707 16.2847 22.4707 15.8017C22.4707 15.3188 22.6572 14.8554 22.9895 14.5128C23.3217 14.1701 23.7728 13.9761 24.2443 13.9729C24.4212 13.9694 24.5968 14.0045 24.7594 14.0759C24.922 14.1473 25.0679 14.2533 25.1874 14.387H25.2211V14.0764H25.9623L25.9453 14.0937ZM25.2548 15.819C25.2548 15.1806 24.8338 14.6975 24.2949 14.6975C23.7559 14.6975 23.3015 15.1633 23.3015 15.819C23.2947 15.9586 23.3152 16.0982 23.3616 16.2296C23.4081 16.3611 23.4797 16.4817 23.5721 16.5845C23.6646 16.6873 23.7762 16.7702 23.9002 16.8283C24.0243 16.8865 24.1585 16.9187 24.2949 16.9232C24.8338 16.9232 25.2548 16.4573 25.2548 15.819Z"
        fill="#737373"
      />
      <path
        d="M27.2756 12.1783H26.501V17.5217H27.2756V12.1783Z"
        fill="#737373"
      />
      <path
        d="M30.2907 16.4226L30.897 16.8367C30.6874 17.1657 30.3792 17.416 30.0188 17.5499C29.6584 17.6838 29.2652 17.6942 28.8985 17.5794C28.5319 17.4646 28.2115 17.2309 27.9857 16.9134C27.76 16.5959 27.641 16.2119 27.6467 15.8191C27.6467 14.7322 28.4046 13.9902 29.3308 13.9902C30.257 13.9902 30.7118 14.7495 30.8633 15.1462L30.9474 15.3533L28.5729 16.3539C28.6464 16.5229 28.7673 16.6657 28.9203 16.764C29.0732 16.8623 29.2513 16.9118 29.4318 16.906C29.608 16.9097 29.7819 16.8653 29.9358 16.7774C30.0897 16.6894 30.218 16.5611 30.3075 16.4057L30.2907 16.4226ZM28.4382 15.7668L30.0381 15.0936C29.9763 14.9719 29.8813 14.8712 29.7646 14.8038C29.6479 14.7364 29.5147 14.7052 29.381 14.7141C29.2512 14.7206 29.1239 14.7537 29.0067 14.8113C28.8895 14.8689 28.7847 14.9498 28.6984 15.0495C28.6121 15.1491 28.5461 15.2653 28.5043 15.3914C28.4624 15.5175 28.4456 15.6509 28.4547 15.7837"
        fill="#737373"
      />
      <path
        d="M9.42347 20.9775L8.44664 24.9113H8.41294L7.35199 21.6507H6.77937L5.71834 24.9113H5.68464L4.65762 20.9775H4.00049L5.36464 25.981H6.00465L7.04879 22.6855H7.08249L8.12663 25.981H8.76664L10.0803 20.9775H9.42347Z"
        fill="#737373"
      />
    </svg>
  )
}

export default GSuiteIcon
