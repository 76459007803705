import { useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"

function useUsersTileDatas() {
  const loadingStatus = useSelector(accountsSelectors.getUsersTileLoadingStatus)

  const latest = useSelector(accountsSelectors.getUsersTileLatest)

  const count = useSelector(accountsSelectors.getUsersTileCount)

  return { loadingStatus, count, latest }
}

export default useUsersTileDatas
