import React from "react"
import { defineMessages, injectIntl, IntlShape } from "react-intl"

import Json from "./Countries.json"

const messages = defineMessages({
  Country: { id: "Countries.Country", defaultMessage: "Pays*" },
})

export interface Props {
  value: string
  intl: IntlShape
  onChange(code: string)
}

export interface State {
  value: string
}

class Countries extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value })
  }
  handleChange = (value) => {
    this.setState({ value: value.target.value })
    this.props.onChange(value.target.value)
  }
  render() {
    const countries = JSON.parse(Json)
    return (
      <select
        data-cy="billing-form__state"
        className="bmm-select"
        value={this.state.value}
        onChange={this.handleChange}
      >
        <option value="">Choisir</option>
        {countries.map((ctry, index) => (
          <option key={index} value={ctry.code}>
            {ctry.name}{" "}
          </option>
        ))}
      </select>
    )
  }
}

const styles = {
  underlineFocusStyle: {
    borderColor: "#4776E6",
  },
  floatingLabelStyle: {
    color: "#747475",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
  },
  floatingLabelFocusStyle: {
    color: "#4776E6",
    fontSize: "0.9rem",
  },
  selectedMenuItemStyle: {
    color: "#3d5afe",
  },
}

export default injectIntl(Countries)
