import { FunctionComponent } from "react"

import { WeightingIndicator } from "components/Weighting"

interface SignatureCardWeightingProps {
  weighting: number
}

export type SignatureCardWeightingType =
  FunctionComponent<SignatureCardWeightingProps>

const SignatureCardWeighting: SignatureCardWeightingType = ({ weighting }) => {
  if (!weighting) return null
  return (
    <div className="signature-card__weighting">
      <WeightingIndicator weighting={weighting} />
    </div>
  )
}

export default SignatureCardWeighting
