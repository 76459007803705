import { FunctionComponent } from "react"

import { useIntl } from "react-intl"

import Container from "./components/WeightingContainer"

import { DEFAULT_WEIGHTING, getWeightingLabel, messages } from "./Weighting"

interface WeightingIndicatorProps {
  weighting: number
  min?: number
  max?: number
}

const WeightingIndicator: FunctionComponent<WeightingIndicatorProps> = ({
  weighting,
  min = DEFAULT_WEIGHTING.min,
  max = DEFAULT_WEIGHTING.max,
}) => {
  const { formatMessage } = useIntl()
  return (
    <Container>
      <Container.Indicators
        weighting={weighting}
        min={min}
        max={max}
        tooltip={`${formatMessage(messages.weighting)} ${formatMessage(
          getWeightingLabel(weighting),
        )}`}
        showLabel={false}
        indicatorWidth={6.5}
        heightMin={12}
        heightMax={42}
        heightIncrement={8}
        isSmall
      />
    </Container>
  )
}

export default WeightingIndicator
