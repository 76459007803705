import { Crop } from "react-image-crop"

const SVG_IMAGE_SIZE = 50

export function getResizedBase64(
  file: File,
  maxWidth: number,
  maxHeight: number,
  cb: (fileAsDataUrl: string | ArrayBuffer) => void,
) {
  const reader = new FileReader()

  reader.onload = (event) => {
    if (file.type.indexOf("image") > -1) {
      const img = new Image()
      img.crossOrigin = "Anonymous"
      img.src = (event.target as FileReader).result as string

      img.onload = () => {
        let width = img.width
        let height = img.height
        const widthGreaterThanHeight = img.width > img.height

        if (widthGreaterThanHeight) {
          if (width > maxWidth) {
            width = maxWidth

            height *= maxWidth / img.width
          }
        } else {
          if (height > maxHeight) {
            height = maxHeight
            width *= maxHeight / img.height
          }
        }

        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        canvas.width = width
        canvas.height = height
        img.width = width
        img.height = height
        ctx.drawImage(img, 0, 0, width, height)

        cb(canvas.toDataURL(file.type))
      }
    }
  }

  reader.onerror = function () {
    throw Error("getBase64 - fichier incorrect")
  }

  if (file) {
    reader.readAsDataURL(file)
  }
}

export function getBase64(
  file: File,
  cb: (fileAsDataUrl: string | ArrayBuffer) => void,
) {
  const reader = new FileReader()

  reader.onload = () => {
    cb(reader.result)
  }

  reader.onerror = function () {
    throw Error("getBase64 - fichier incorrect")
  }

  if (file) {
    reader.readAsDataURL(file)
  }
}

export function getBase64ImgAsFile(
  base64ImageUrl,
  fileName,
  width,
  height,
  cb: (file: File) => void,
) {
  const img = new Image()
  img.crossOrigin = "Anonymous"

  img.onload = () => {
    const canvas = document.createElement("canvas")
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext("2d")

    ctx.drawImage(img, 0, 0, width, height)

    // As a blob
    return convertInBlob(canvas, base64ImageUrl, fileName).then(
      (file: Blob) => {
        cb(file as File)
      },
    )
  }

  img.onerror = () => {
    cb(null)
    throw Error(
      `getBase64ImgAsFile - erreur lors de la conversion en File de : ${fileName} `,
    )
  }

  img.src = base64ImageUrl
}

export function getImageSizeFromDataUrl(
  base64ImageUrl: string,
): Promise<{ width: number; height: number }> {
  return new Promise(function (resolved) {
    const image = new Image()
    image.onload = () => {
      resolved({ width: image.width, height: image.height })
    }
    image.src = base64ImageUrl
  })
}

export function getSizeOfImageFile(
  imageFile: File,
): Promise<{ width: number; height: number }> {
  return new Promise(function (resolved) {
    const reader = new FileReader()
    reader.onload = () => {
      const image = new Image()
      image.onload = () => {
        resolved({ width: image.width, height: image.height })
      }
      image.src = reader.result.toString()
    }
    reader.readAsDataURL(imageFile)
  })
}

export function getCroppedImgAsDataUrl(
  imageUrl: string,
  pixelCrop: Crop,
  cb: (imageUrl: string) => void,
  format?: "rounded" | "round",
) {
  const img = new Image()
  img.crossOrigin = "Anonymous"

  img.onload = () => {
    const canvas: HTMLCanvasElement = document.createElement("canvas")
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d")

    if (format === "round") {
      ctx.beginPath()
      ctx.arc(
        pixelCrop.width / 2,
        pixelCrop.height / 2,
        pixelCrop.width / 2,
        0,
        Math.PI * 2,
      )
      ctx.closePath()
      ctx.clip()
    }

    if (format === "rounded") {
      ctx.beginPath()
      ctx.roundRect(0, 0, pixelCrop.width, pixelCrop.height, [8])
      ctx.stroke()
      ctx.closePath()
      ctx.clip()
    }

    ctx.drawImage(
      img,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    )

    return cb(canvas.toDataURL())
  }
  img.src = imageUrl
}

const convertInBlob = (
  canvas: HTMLCanvasElement,
  dataUrl: string,
  fileName: string,
): Promise<Blob> => {
  const extension = getFileMediatype(dataUrl)

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        (file: File) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(file as any).name = fileName

          resolve(file)
        },
        extension,
        1,
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const getResizedImgAsFile = (
  originalBase64ImageUrl: string,
  imageWidth: number,
  imageHeight: number,
  fileName: string,
  cb: (file: File | Error) => void,
) => {
  const img = new Image()
  img.crossOrigin = "Anonymous"
  img.src = originalBase64ImageUrl

  img.onload = () => {
    const canvas = document.createElement("canvas")
    canvas.width = imageWidth
    canvas.height = imageHeight

    const ctx = canvas.getContext("2d")

    ctx.drawImage(img, 0, 0, imageWidth, imageHeight)

    // As a blob
    convertInBlob(canvas, originalBase64ImageUrl, fileName).then(
      (file: Blob) => {
        cb(file as File)
      },
      (error) => {
        cb(new Error("convertInBlob" + error.message))
      },
    )
  }
}

export const getFileMediatype = (originalBase64ImageUrl: string): string => {
  let mediatype = ""
  try {
    if (originalBase64ImageUrl.startsWith("data:"))
      mediatype = originalBase64ImageUrl.split("data:")[1].split(";")[0]
  } catch (err) {
    throw Error(err)
  }
  return mediatype
}

/**
 *
 * @param sourceSVG Markup SVG
 * @param fileName nom du fichier généré
 * @param cb fonction appelée après la conversion en fichier
 */
export const getSvgAsFile = (
  sourceSVG: string,
  fileName: string,
  cb: (file: File | Error) => void,
) => {
  // check the validity of the svg string
  const doc = new DOMParser().parseFromString(sourceSVG, "text/xml")
  const data = new XMLSerializer().serializeToString(doc)
  // create an image for drawing into the svg
  const img = new Image()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window.URL || (window as any).webkitURL || window

  const blob = new Blob([data], { type: "image/svg+xml" })
  const url = win.createObjectURL(blob)

  img.onload = function () {
    const canvas = document.createElement("canvas")
    canvas.width = SVG_IMAGE_SIZE
    canvas.height = SVG_IMAGE_SIZE
    canvas.getContext("2d").drawImage(img, 0, 0, SVG_IMAGE_SIZE, SVG_IMAGE_SIZE)

    win.revokeObjectURL(url)
    const uri = canvas.toDataURL("image/png")
    convertInBlob(canvas, uri, fileName).then(
      (blob: Blob) => {
        const file = blob as File
        cb(file)
      },
      (error) => {
        cb(new Error("convertInBlob" + error.message))
      },
    )
  }

  img.src = url
}

export const getFileExtension = (fileUrl: string): string => {
  let extension = ""
  try {
    if (fileUrl.startsWith("data:")) {
      const regexpExtension = /data:image\/(.*);.*/
      const mediatype = fileUrl.match(regexpExtension)
      if (mediatype[1] != null) extension = mediatype[1]
    } else {
      const splitTab = fileUrl.split(".")
      extension = splitTab[splitTab.length - 1]
    }
  } catch (err) {
    throw Error(err)
  }
  return extension
}

export function getImageSizeFromBlob(
  file: Blob,
  callback: (width: number, height: number, file: Blob) => void,
): void {
  const readedFile = new FileReader()

  readedFile.onload = function () {
    const img = new Image()

    img.onload = function () {
      callback(img.naturalWidth, img.naturalHeight, file)
    }

    img.src = readedFile.result as string
  }

  readedFile.readAsDataURL(file)
}

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
    reader.onabort = () => reject(new Error("Read aborted"))
    reader.readAsDataURL(blob)
  })
}
