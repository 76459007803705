import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import moment from "moment"

import Signature from "entities/Signature"
import Template, { TemplateMode } from "entities/Template"

import {
  SIGNATURES_SLICES,
  SignaturesActivatedStateFilter,
} from "./SignaturesConstants"

import { GlobalStates } from "store"
import { LoadingStatus } from "core/CoreModels"
import { SendingModes, SignatureDto, Size } from "./SignaturesModels"
import { User } from "features/Users/UserModels"

import {
  createLoadingSlicesAdapter,
  LoadingSlices,
  LoadingSlicesTypes,
} from "core/services/loadingSlicesService"
import { DEFAULT_SIGNATURE_WEIGHTING_VALUE } from "./SignaturesRules"

import { Params } from "core/services/requestBuilderService"
import {
  BuilderSignature,
  BuilderSignatureDto,
} from "features/BuilderSignatures/BuilderSignaturesModels"

interface SignaturesEntities {
  [id: string]: Signature
}

interface TemplateGalleryEntities {
  [id: string]: Template
}

export type SignaturesSlices = typeof SIGNATURES_SLICES[number]

interface SignatureCustomQueryEntity {
  requestId: string
  customQuery: Params<Signature | BuilderSignature>
  result: Partial<SignatureDto[] | BuilderSignatureDto[]>
}

interface SignatureCustomQueryEntities {
  [id: string]: SignatureCustomQueryEntity
}

interface SignatureState {
  allSignatures: {
    entities: SignaturesEntities
    ids: Array<EntityId>
    activatedStateFilter: SignaturesActivatedStateFilter
    signaturesSlices: LoadingSlices<SignaturesSlices>
    sendingMode: SendingModes
  }
  editingSignature: {
    newMail: boolean
    inResponse: boolean
    weighting: number
  }
  newSignature: Signature // Signature pas encore sauvegardée, on la conserve pour l'étape 1, en zone "tampon"
  activeSignatureId: number
  activeSignatureBannerId: number[]
  templatesGallery: {
    entities: TemplateGalleryEntities
    ids: Array<EntityId>
  }
  signatureName: string
  signatureNameError: boolean
  logo: {
    isUploaded: boolean
    isCropped: boolean
    originalImageUrl: string
    size: {
      width: number
      height: number
    }
    maxSize: {
      width: number
      height: number
    }
  }
  selectedTemplateId: number
  templateMode: TemplateMode
  templateColor: string
  selectedUser: User[]
  affectedUsersLoaded: boolean
  isUpdated: boolean
  actionSignature: string
  currentStep: number
  emailPreviewSent: boolean
  trial: number
  signaturesCustomQueries: {
    entities: SignatureCustomQueryEntities
    ids: Array<EntityId>
  }
}

const signaturesAdapter = createEntityAdapter<Signature>({
  selectId: (sd) => sd.Id,
  sortComparer: (a, b) => a.Id - b.Id,
})

export const {
  selectById: selectSignatureById,
  selectAll: selectAllSignatures,
  selectIds: selectAllSignaturesIds,
} = signaturesAdapter.getSelectors<GlobalStates>(
  (state) => state.signatures.allSignatures,
)

const templatesGalleryAdapter = createEntityAdapter<Template>({
  selectId: (sd) => sd.Id,
  sortComparer: (a, b) => a.Id - b.Id,
})

export const {
  selectById: selectTemplateFromGalleryById,
  selectAll: selectAllTemplatesFromGallery,
  selectIds: selectAllTemplatesIdsFromGallery,
} = templatesGalleryAdapter.getSelectors<GlobalStates>(
  (state) => state.signatures.templatesGallery,
)

const signaturesSlicesAdapter =
  createLoadingSlicesAdapter<SignaturesSlices>(SIGNATURES_SLICES)

export const {
  selectAll: selectAllSignaturesSlices,
  selectById: selectSignatureSliceById,
} = signaturesSlicesAdapter.getSelectors<GlobalStates>(
  (state) => state.signatures.allSignatures.signaturesSlices,
)

const signaturesCustomQueriesAdapter =
  createEntityAdapter<SignatureCustomQueryEntity>({
    selectId: (s) => s.requestId,
    sortComparer: (a, b) => a.requestId.localeCompare(b.requestId),
  })

export const {
  selectAll: selectAllSignaturesCustomQueries,
  selectById: selectSignaturesCustomQueryById,
} = signaturesCustomQueriesAdapter.getSelectors<GlobalStates>(
  (state) => state.signatures.signaturesCustomQueries,
)

const initialState: SignatureState = {
  allSignatures: signaturesAdapter.getInitialState({
    activatedStateFilter: SignaturesActivatedStateFilter.ALL,
    signaturesSlices: signaturesSlicesAdapter.getInitialState(),
    sendingMode: "newMail",
  }),
  editingSignature: {
    newMail: true,
    inResponse: true,
    weighting: DEFAULT_SIGNATURE_WEIGHTING_VALUE,
  },
  templatesGallery: templatesGalleryAdapter.getInitialState(),
  affectedUsersLoaded: false, // global
  templateColor: "", // editingSignature
  signatureName: "", // editingSignature
  signatureNameError: false,
  activeSignatureId: null, // editingSignature
  activeSignatureBannerId: [], // editingSignature
  newSignature: new Signature({}), // editingSignature
  selectedTemplateId: null, // editingSignature
  templateMode: TemplateMode.Full, // editingSignature
  currentStep: 1, // editingSignature
  selectedUser: null,
  isUpdated: true,
  actionSignature: "",
  emailPreviewSent: false,
  logo: {
    isUploaded: false,
    isCropped: false,
    originalImageUrl: "",
    size: {
      width: 0,
      height: 0,
    },
    maxSize: {
      width: 0,
      height: 0,
    },
  },
  trial: 0,
  signaturesCustomQueries: signaturesCustomQueriesAdapter.getInitialState(),
}

const signaturesReducer = createSlice({
  name: "signatures",
  initialState,
  reducers: {
    fetchSignatures(
      state,
      action: PayloadAction<LoadingSlicesTypes<SignaturesSlices>>,
    ) {
      signaturesSlicesAdapter.setSliceToPending(
        state.allSignatures.signaturesSlices,
        action.payload,
      )
    },
    fetchSignaturesSuccess(
      state,
      action: PayloadAction<{
        signatures: Signature[]
        slice: LoadingSlicesTypes<SignaturesSlices>
      }>,
    ) {
      const { signatures, slice } = action.payload
      signaturesAdapter.upsertMany(state.allSignatures, signatures)

      signaturesSlicesAdapter.setSliceToLoaded(
        state.allSignatures.signaturesSlices,
        slice,
      )
    },
    fetchSignaturesFailure(state) {
      signaturesSlicesAdapter.setSliceToError(
        state.allSignatures.signaturesSlices,
        "all",
      )
    },
    fillTemplateUserPropertiesSuccess(
      state,
      action: PayloadAction<Signature[]>,
    ) {
      signaturesAdapter.setAll(state.allSignatures, action.payload)
    },
    setActivatedStateFilter(
      state,
      action: PayloadAction<SignaturesActivatedStateFilter>,
    ) {
      state.allSignatures.activatedStateFilter = action.payload
    },
    createSignatureSuccess(state, action: PayloadAction<Signature>) {
      signaturesAdapter.addOne(state.allSignatures, action.payload)
      state.activeSignatureId = action.payload.Id
      state.newSignature = new Signature({})
      state.isUpdated = true
    },
    affectUserIdToSignature(state, action: PayloadAction<number>) {
      const { activeSignatureId } = state
      const activeSignature = state.allSignatures.entities[activeSignatureId]

      const affectedUsersIds = activeSignature?.AffectedUsersIds || []

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedUsersIds: [...affectedUsersIds, action.payload],
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    affectGroupIdToSignature(state, action: PayloadAction<number>) {
      const { activeSignatureId } = state
      const activeSignature = state.allSignatures.entities[activeSignatureId]

      const affectedGroupsIds = activeSignature?.AffectedGroupsIds || []

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedGroupsIds: [...affectedGroupsIds, action.payload],
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    removeSingleUserIdToSignature(state, action: PayloadAction<number>) {
      const { activeSignatureId } = state
      const activeSignature = state.allSignatures.entities[activeSignatureId]

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedUsersIds: activeSignature.AffectedUsersIds.filter(
            (id) => id !== action.payload,
          ),
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    removeUsersIdsToSignature(state, action: PayloadAction<number[]>) {
      const { activeSignatureId } = state
      const activeSignature = state.allSignatures.entities[activeSignatureId]

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedUsersIds: activeSignature.AffectedUsersIds.filter(
            (id) => !action.payload.includes(id),
          ),
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    removeGroupIdToSignature(state, action: PayloadAction<number>) {
      const { activeSignatureId } = state
      const activeSignature = state.allSignatures.entities[activeSignatureId]

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedGroupsIds: activeSignature.AffectedGroupsIds.filter(
            (id) => id !== action.payload,
          ),
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    affectAllUsersIdsToSignature(state, action: PayloadAction<number[]>) {
      const { activeSignatureId } = state

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedUsersIds: action.payload,
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    affectAllGroupsIdsToSignature(state, action: PayloadAction<number[]>) {
      const { activeSignatureId } = state

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedGroupsIds: action.payload,
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    removeAllUsersIdsToSignature(state) {
      const { activeSignatureId } = state

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedUsersIds: [],
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    removeAllGroupsIdsToSignature(state) {
      const { activeSignatureId } = state

      signaturesAdapter.updateOne(state.allSignatures, {
        id: activeSignatureId,
        changes: {
          AffectedGroupsIds: [],
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    updateSignatureSuccess(state, action: PayloadAction<Signature>) {
      signaturesAdapter.setOne(state.allSignatures, action.payload)
      state.isUpdated = true
      state.actionSignature = "UPDATE_SUCCESS"
    },
    deleteSignatureSuccess(state, action: PayloadAction<Signature>) {
      signaturesAdapter.removeOne(state.allSignatures, action.payload.Id)
      if (state.activeSignatureId === action.payload.Id)
        state.activeSignatureId = null
    },
    loadAffectedUsersSuccess(
      state,
      action: PayloadAction<{ signatureId: number; affectedUsers: number[] }>,
    ) {
      const { signatureId, affectedUsers } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id: signatureId,
        changes: {
          AffectedUsersIds: affectedUsers,
          AffectedUsersLoaded: LoadingStatus.LOADED,
        },
      })
    },
    loadActiveSignatureAffectedUsersSuccess(
      state,
      action: PayloadAction<number[]>,
    ) {
      state.newSignature.AffectedUsersIds = action.payload
      state.newSignature.AffectedUsersLoaded = LoadingStatus.LOADED
    },
    loadMultipleAffectedGroups(state, action: PayloadAction<number[]>) {
      signaturesAdapter.updateMany(state.allSignatures, [
        ...action.payload.map((signatureId) => ({
          id: signatureId,
          changes: {
            AffectedGroupsLoaded: LoadingStatus.PENDING,
          },
        })),
      ])
    },
    loadMultipleAffectedGroupsSuccess(
      state,
      action: PayloadAction<
        { signatureId: number; affectedGroups: number[] }[]
      >,
    ) {
      signaturesAdapter.updateMany(state.allSignatures, [
        ...action.payload.map(({ signatureId, affectedGroups }) => ({
          id: signatureId,
          changes: {
            AffectedGroupsIds: affectedGroups,
            AffectedGroupsLoaded: LoadingStatus.LOADED,
          },
        })),
      ])
    },
    loadAffectedGroupsSuccess(
      state,
      action: PayloadAction<{ signatureId: number; affectedGroups: number[] }>,
    ) {
      const { signatureId, affectedGroups } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id: signatureId,
        changes: {
          AffectedGroupsIds: affectedGroups,
          AffectedGroupsLoaded: LoadingStatus.LOADED,
        },
      })
    },
    loadActiveSignatureAffectedGroupsSuccess(
      state,
      action: PayloadAction<number[]>,
    ) {
      state.newSignature.AffectedGroupsIds = action.payload
      state.newSignature.AffectedGroupsLoaded = LoadingStatus.LOADED
    },
    loadSignatureAffectedUsersCount(state, action: PayloadAction<number>) {
      signaturesAdapter.updateOne(state.allSignatures, {
        id: action.payload,
        changes: {
          AffectUsersCountLoadingStatus: LoadingStatus.PENDING,
        },
      })
    },
    loadSignatureAffectedUsersCountSuccess(
      state,
      action: PayloadAction<{
        signatureId: number
        affectedUsersCount: number[]
      }>,
    ) {
      const { signatureId, affectedUsersCount } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id: signatureId,
        changes: {
          AffectedUsersCount: affectedUsersCount,
          AffectUsersCountLoadingStatus: LoadingStatus.LOADED,
        },
      })
    },
    updateSignatureName(state, action: PayloadAction<string>) {
      state.signatureName = action.payload
      state.signatureNameError = action.payload === ""
    },
    updateTemporarySignatureSuccess(state, action: PayloadAction<Signature>) {
      state.newSignature = action.payload
    },
    setActiveSignature(state, action: PayloadAction<Signature>) {
      state.activeSignatureId = action.payload?.Id
      state.signatureName = action.payload?.Name
    },
    setActiveSignatureSuccess(state, action: PayloadAction<Signature>) {
      state.activeSignatureId = action.payload.Id
      state.newSignature = action.payload
      state.currentStep = 1
    },
    setSignatureFormStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload
    },
    fetchTemplatesGallerySuccess(state, action: PayloadAction<Template[]>) {
      templatesGalleryAdapter.setAll(state.templatesGallery, action.payload)
      state.selectedTemplateId = action.payload[0].Id
    },
    selectTemplateSuccess(state, action: PayloadAction<number>) {
      state.selectedTemplateId = action.payload
    },
    selectTemplateMode(state, action: PayloadAction<TemplateMode>) {
      state.templateMode = action.payload
    },
    changeTemplateColor(state, action: PayloadAction<string>) {
      state.templateColor = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendPreviewMail(state, _action: PayloadAction<string>) {
      state.emailPreviewSent = false
    },
    sendPreviewMailSuccess(state) {
      state.emailPreviewSent = true
    },
    sendPreviewMailFailure(state) {
      state.emailPreviewSent = false
    },
    initSignatureLogo(
      state,
      action: PayloadAction<{ logoUrl: string; size: Size; maxSize: Size }>,
    ) {
      state.logo.originalImageUrl = action.payload.logoUrl
      state.logo.size = action.payload.size
      state.logo.maxSize = action.payload.maxSize
    },
    updateSignatureLogoSuccess(state) {
      state.logo.isUploaded = true
      state.logo.isCropped = false
    },
    resizeSignatureLogoSuccess(state, action: PayloadAction<Size>) {
      state.logo.isUploaded = false
      state.logo.isCropped = true
      state.logo.size = action.payload
    },
    activateSignatureSuccess(
      state,
      action: PayloadAction<{
        id: number
        activated: boolean
        activatedDate: string
        modified: string
      }>,
    ) {
      const { id, activated, activatedDate, modified } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id,
        changes: {
          Activated: activated,
          ActivatedDate: moment(activatedDate),
          Modified: moment(modified),
        },
      })
    },
    assignUsersSuccess(
      state,
      action: PayloadAction<{ id: number; usersIds: number[] }>,
    ) {
      const { id, usersIds } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id,
        changes: {
          AffectedUsersIds: usersIds,
        },
      })
    },
    assignGroupsSuccess(
      state,
      action: PayloadAction<{ id: number; groupsIds: number[] }>,
    ) {
      const { id, groupsIds } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id,
        changes: {
          AffectedGroupsIds: groupsIds,
        },
      })
    },
    updateSendingMode(state, action: PayloadAction<SendingModes>) {
      state.allSignatures.sendingMode = action.payload
    },
    setEditingSignatureSendingMode(
      state,
      action: PayloadAction<{ newMail: boolean; inResponse: boolean }>,
    ) {
      state.editingSignature.newMail = action.payload.newMail
      state.editingSignature.inResponse = action.payload.inResponse
    },
    setEditingSignatureWeighting(state, action: PayloadAction<number>) {
      state.editingSignature.weighting = action.payload
    },
    removeSendingModeFromSignatureSuccess(
      state,
      action: PayloadAction<{
        id: number
        newMail: boolean
        inResponse: boolean
      }>,
    ) {
      const { id, newMail, inResponse } = action.payload
      signaturesAdapter.updateOne(state.allSignatures, {
        id,
        changes: { NewMail: newMail, InResponse: inResponse },
      })
    },
    fetchSignaturesCustomQuerySuccess(
      state,
      action: PayloadAction<SignatureCustomQueryEntity>,
    ) {
      signaturesCustomQueriesAdapter.upsertOne(
        state.signaturesCustomQueries,
        action.payload,
      )
    },
  },
})

export const {
  fetchSignatures,
  fetchSignaturesSuccess,
  fetchSignaturesFailure,
  fillTemplateUserPropertiesSuccess,
  setActivatedStateFilter,
  createSignatureSuccess,
  affectUserIdToSignature,
  affectGroupIdToSignature,
  removeSingleUserIdToSignature,
  removeUsersIdsToSignature,
  removeGroupIdToSignature,
  affectAllUsersIdsToSignature,
  affectAllGroupsIdsToSignature,
  updateSignatureSuccess,
  deleteSignatureSuccess,
  loadAffectedUsersSuccess,
  loadActiveSignatureAffectedUsersSuccess,
  loadMultipleAffectedGroups,
  loadMultipleAffectedGroupsSuccess,
  loadAffectedGroupsSuccess,
  loadActiveSignatureAffectedGroupsSuccess,
  loadSignatureAffectedUsersCount,
  loadSignatureAffectedUsersCountSuccess,
  updateSignatureName,
  updateTemporarySignatureSuccess,
  setActiveSignatureSuccess,
  setActiveSignature,
  setSignatureFormStep,
  fetchTemplatesGallerySuccess,
  selectTemplateSuccess,
  selectTemplateMode,
  changeTemplateColor,
  sendPreviewMail,
  sendPreviewMailSuccess,
  sendPreviewMailFailure,
  initSignatureLogo,
  updateSignatureLogoSuccess,
  resizeSignatureLogoSuccess,
  activateSignatureSuccess,
  assignUsersSuccess,
  assignGroupsSuccess,
  removeAllUsersIdsToSignature,
  removeAllGroupsIdsToSignature,
  updateSendingMode,
  setEditingSignatureSendingMode,
  removeSendingModeFromSignatureSuccess,
  setEditingSignatureWeighting,
  fetchSignaturesCustomQuerySuccess,
} = signaturesReducer.actions

export default signaturesReducer.reducer
