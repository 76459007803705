import { useMemo } from "react"

import SyncProvider from "./SyncProvider"
import { useFeatures } from "../../hooks/useFeatures"

function getSection(name: string) {
  const isFeat = (featNames: string[]) => (name) =>
    featNames.some((featName) => featName.toLowerCase() === name.toLowerCase())
  if (isFeat(["o365", "owa"])(name)) return "o365"
  if (isFeat(["gsuite", "gmail"])(name)) return "gsuite"
  if (isFeat(["api"])(name)) return "api"
  if (isFeat(["lucca"])(name)) return "lucca"
  if (isFeat(["salesforce"])(name)) return "salesforce"
}

const ConnectedSyncProvider = () => {
  const {
    isLoaded,
    isBackgroundTask,
    features,
    updateFeature,
    createJobInstruction,
  } = useFeatures()

  const extendedFeatures = useMemo(
    () =>
      features.map((feature) => ({
        ...feature,
        section: getSection(feature.name),
      })),
    [features],
  )

  return (
    <SyncProvider
      features={extendedFeatures}
      loading={isLoaded}
      backgroundTasks={isBackgroundTask}
      updateFeature={updateFeature}
      launchJob={createJobInstruction}
    />
  )
}

export default ConnectedSyncProvider
