import { FunctionComponent } from "react"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import Page from "components/Page"

import ResetPasswordForm from "features/Accounts/components/ResetPasswordForm"
import O365OauthButton from "features/Accounts/components/O365OauthButton"
import GoogleOauthButton from "features/Accounts/components/GoogleOauthButton"

const messages = defineMessages({
  google: {
    id: "ConfirmUserPage.ConnectionGoogle",
    defaultMessage: "Se connecter avec Google",
  },
  microsoft: {
    id: "ConfirmUserPage.ConnectionMicrosoft",
    defaultMessage: "Se connecter avec Microsoft",
  },
  or: {
    id: "ConfirmUserPage.Or",
    defaultMessage: "OU",
  },
  formTitle: {
    id: "ConfirmUserPage.FormTitle",
    defaultMessage: "Définissez votre mot de passe",
  },
})

const ConfirmUserPage: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <Page
      title={
        <FormattedMessage
          id="ConfirmUserPage.Title"
          defaultMessage="Confirmer votre adresse email"
        />
      }
      withoutNavbar
    >
      <div className="l-one-column__container h-100">
        <div className="l-one-column__content">
          <ResetPasswordForm
            title={formatMessage(messages.formTitle)}
            isConfirmUser={true}
          />
          <div className="confirm-page__separators bmm__emphaze">
            <div className="confirm-page__separators-container">
              <div className="bmm-separator" />
              <div>{formatMessage(messages.or)}</div>
              <div className="bmm-separator" />
            </div>
          </div>
          <div className="confirm-page__separation-label bmm__emphaze">
            <FormattedMessage
              id="ConfirmUserPage.OrWithOAuthAccount"
              defaultMessage="Connectez-vous avec votre compte d'entreprise:"
            />
          </div>
          <div className="confirm-page__btn-container">
            <O365OauthButton isOutlined>
              {formatMessage(messages.microsoft)}
            </O365OauthButton>
            <GoogleOauthButton isOutlined>
              {formatMessage(messages.google)}
            </GoogleOauthButton>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default ConfirmUserPage
