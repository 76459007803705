import { FunctionComponent, useState } from "react"

import { useDispatch } from "react-redux"

import TextBox from "components/TextBox/TextBox"
import StringHelper from "utils/StringHelper"

import { requestResetPasswordAction } from "features/Accounts/AccountsActions"

import { defineMessages, useIntl } from "react-intl"

const messages = defineMessages({
  forgot: { id: "ForgotPassword.Email", defaultMessage: "E-mail" },
  writeEmail: {
    id: "ForgotPasswordForm.WriteEmail",
    defaultMessage:
      "Veuillez saisir votre email ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe",
  },
  resetPassword: {
    id: "ForgotPasswordForm.ResetPassword",
    defaultMessage: "Réinitialiser votre mot de passe",
  },
})

const ForgotPasswordForm: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const [email, setEmail] = useState("")

  const handleInputChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSendEmail = () => {
    if (!StringHelper.validateEmail(email)) return
    dispatch(requestResetPasswordAction(email))
  }

  return (
    <div className="forgot-password__container">
      <div className="forgot-password">
        <div className="bmm-form">
          <h4 className="bmm-form__title login-form__forgot-password__title">
            {formatMessage(messages.writeEmail)}
          </h4>
          <div className="bmm-form__content">
            <div className="bmm-form__input__container">
              <div className="bmm-form__input-400">
                <TextBox
                  data-cy="forgot-password__input"
                  name="email"
                  hintText={formatMessage(messages.forgot)}
                  mandatory
                  onChange={handleInputChange}
                  value={email}
                />
              </div>
            </div>
            <div className="bmm-form__button-zone">
              <button
                className="bmm-form__button bmm-button"
                onClick={handleSendEmail}
              >
                {formatMessage(messages.resetPassword)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordForm
