import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Carousel from "features/Enrollment/components/EnrollmentCarousel"

import "./EnrollmentStep.scss"

import logos1 from "features/Enrollment/assets/logos-carrousel-1.png"
import logos2 from "features/Enrollment/assets/logos-carrousel-2.png"

const EnrollmentStep4Side: FunctionComponent = () => {
  return (
    <div className="enroll__box enroll__aside">
      <header>
        <div className="enroll__aside__title">
          <FormattedMessage
            id="Enroll.StepSide4a"
            defaultMessage="Plus de 250 clients"
          />
        </div>
        <div className="enroll__aside__subtitle">
          <FormattedMessage
            id="Enroll.StepSide4b"
            defaultMessage="Nous ont déjà rejoints"
          />
        </div>
      </header>
      <section>
        <Carousel>
          <div style={{ textAlign: "center", marginTop: "75px" }}>
            <img src={logos2} alt="" style={{ width: "60%" }} />
          </div>
          <div style={{ textAlign: "center", marginTop: "75px" }}>
            <img src={logos1} alt="" style={{ width: "60%" }} />
          </div>
        </Carousel>
      </section>
    </div>
  )
}

export default EnrollmentStep4Side
