import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import Config from "config/config"
import { HOME_PORTAL_URL } from "router/RouterConstants"

import { AvailableLanguages, Languages } from "./AppModels"

export interface AppState {
  isHidden: boolean
  selectedLocale: AvailableLanguages
  locales: Languages | null
  redirectTo: string
  globalLoading: boolean
  config: typeof Config
}

const initialState: AppState = {
  isHidden: true,
  selectedLocale: "FR",
  locales: null,
  redirectTo: HOME_PORTAL_URL,
  globalLoading: true,
  config: Config,
}

const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    storePath(state, action: PayloadAction<string>) {
      state.redirectTo = action.payload
    },
    showAutosave(state) {
      state.isHidden = false
    },
    hideAutosave(state) {
      state.isHidden = true
    },
    showGlobalLoader(state) {
      state.globalLoading = true
    },
    hideGlobalLoader(state) {
      state.globalLoading = false
    },
    initLanguagesSuccess(state, action: PayloadAction<Languages>) {
      state.locales = action.payload
    },
    switchLanguage(state, action: PayloadAction<AvailableLanguages>) {
      state.selectedLocale = action.payload
    },
  },
})

export const {
  storePath,
  showAutosave,
  hideAutosave,
  showGlobalLoader,
  hideGlobalLoader,
  initLanguagesSuccess,
  switchLanguage,
} = appReducer.actions

export default appReducer.reducer
