import { FunctionComponent } from "react"

import Container from "./components/WeightingContainer"

import { DEFAULT_WEIGHTING } from "./Weighting"

interface WeightingReadOnlyProps {
  weighting: number
  min?: number
  max?: number
}

const WeightingReadOnly: FunctionComponent<WeightingReadOnlyProps> = ({
  weighting,
  min = DEFAULT_WEIGHTING.min,
  max = DEFAULT_WEIGHTING.max,
}) => {
  return (
    <Container>
      <Container.Indicators weighting={weighting} min={min} max={max} />
      <Container.Label weighting={weighting} shift />
    </Container>
  )
}

export default WeightingReadOnly
