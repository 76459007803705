import CanvaApi from "components/CanvaButton/Canva"

/**
 * Récupère la configuration directement depuis le Store Redux
 */
export class Config {
  appInsightPublicConnectionString
  webApiUrl = "https://staging-service.boostmymail.com"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  proxyUrl: string = (window as any).bmmProxyUrl || ""
  webJobsApiUrl = "https://staging-jobs.boostmymail.com"
  stripeEndpoint = "https://js.stripe.com/v3/"
  stripePublicKey: string = null
  cdnUrl = "https://cdn.boostmymail.com"
  froala: string
  reCaptchaClientKey: string
  defaultImageUrl = "https://cdn.boostmymail.com/images/bmm/picto_logo.png"
  defaultBannerUrl = "https://cdn.boostmymail.com/images/bmm/default-banner.png"
  defaultPhotoUrl = "https://cdn.boostmymail.com/images/bmm/default-user.png"
  o365UrlConsent: string
  owaUrlConsent: string
  salesforceUrlConsent: string
  locale: string
  canvaApiKey: string
  highBarPlaceholder = "https://cdn.boostmymail.com/images/bmm/highbar.png"
  macOsDownloadLink = ""
  vNext = "false"
  sync: {
    O365Fields: string[]
    GSuiteFields: string[]
  } = {
      O365Fields: [],
      GSuiteFields: [],
    }
  gsuiteServiceAccount: {
    ServiceAccountId: string
    Scopes: string
    GoogleWorkspaceAdminUrl: string
  } = {
      ServiceAccountId: "111111111111111111111",
      Scopes: "https://scope1,https://scope2,https://scope3,https://scope4",
      GoogleWorkspaceAdminUrl:
        "https://admin.google.com/ac/owl/domainwidedelegation",
    }

  defaultUser: {} = {
    Id: 1000,
    Username: "l.tommybo@boostmymail.com",
    Properties: {
      company: "BoostMyMail",
      firstname: "Lisa",
      lastname: "Tommybo",
      jobtitle: "Directrice Marketing",
      department: "COMMUNICATION",
      mail: "l.tommybo@boostmymail.com",
      phone: "02.12.34.56.78",
      mobile: "06.12.34.56.78",
      streetaddress: "10 Boulevard du Zenith",
      postalcode: "44800",
      city: "SAINT-HERBLAIN",
      country: "France",
      website: "www.boostmymail.com",
      usermessage: "Absente le mercredi après-midi",
      disclaimer: "Pensez à la planète, imprimer ce mail que si nécessaire",
      picture: "https://cdn.boostmymail.com/images/bmm/default-user.png",
    },
  }
  maintenanceMode = "false"
  cgsLink: string
  env: "Production" | "Development" = "Development"
  logUrl = "/logs"

  public setFromState(configJson) {
    this.webApiUrl = configJson["WebApiUrl"]
    this.webJobsApiUrl = configJson["WebJobsApiUrl"]
    this.cdnUrl = configJson["CdnUrl"]
    this.froala = configJson["FroalaKey"]
    this.reCaptchaClientKey = configJson["RecaptchaClientKey"]
    this.o365UrlConsent = configJson["O365UrlConsent"]
    this.owaUrlConsent = configJson["OwaUrlConsent"]
    this.salesforceUrlConsent = configJson["SalesforceUrlConsent"]
    this.cgsLink = configJson["CGSLink"]
    this.maintenanceMode = configJson["MaintenanceMode"]
    this.stripePublicKey = configJson["StripePublicKey"]

    this.canvaApiKey = configJson["CanvaApiKey"]
    if (this.canvaApiKey != null) CanvaApi.SetCanvaApiKey(this.canvaApiKey)

    this.macOsDownloadLink = configJson["MacOsLink"]
    this.vNext = configJson["VNext"]

    if (configJson["Sync"]) {
      this.sync.O365Fields = configJson["Sync"]["O365"].split(",")
      this.sync.GSuiteFields = configJson["Sync"]["GSuite"].split(",")
    }
    if (configJson["GSuiteServiceAccount"]) {
      this.gsuiteServiceAccount = configJson["GSuiteServiceAccount"]
    }

    this.appInsightPublicConnectionString = configJson["AppInsightPublicConnectionString"]
  }
}

export default new Config()
