import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { FileUploader } from "bmm-builder"

import { ModalActions, ModalService } from "components/Modal"

import {
  USER_AVATAR_ACCEPTED_FILETYPES,
  USER_AVATAR_MAX_SIZE,
  USER_AVATAR_MIN_SIZE,
} from "features/Users/UsersConstants"
import { getImageSizeFromBlob } from "utils/ImageUtils"

type UploadErrorTypes = "fileType" | "tooSmall" | "tooBig"

interface UserAvatarUploaderProps {
  handleUploadedFile: (file: Blob, width: number, height: number) => void
}

const messages = defineMessages({
  cancel: {
    id: "UserAvatarManager.Cancel",
    defaultMessage: "Annuler",
  },
  leaflet: {
    id: "UserAvatarManager.leaflet",
    defaultMessage: "Notice",
  },
  minSize: {
    id: "UserAvatarManager.Minsize",
    defaultMessage: "Dimensions mini",
  },
  maxSize: {
    id: "UserAvatarManager.MaxSize",
    defaultMessage: "Dimensions maxi",
  },
  unauthorizedSize: {
    id: "UserAvatarManager.UnauthorizedSize",
    defaultMessage: "Dimension non autorisée",
  },
  pictureTooSmall: {
    id: "UserAvatarManager.PictureTooSmall",
    defaultMessage: "Image trop petite",
  },
  pictureTooBig: {
    id: "UserAvatarManager.PictureTooBig",
    defaultMessage: "Image trop grande",
  },
})

function checkPictureSize(
  width: number,
  height: number,
): UploadErrorTypes | null {
  if (Math.min(width, height) < USER_AVATAR_MIN_SIZE) return "tooSmall"
  if (Math.max(width, height) > USER_AVATAR_MAX_SIZE) return "tooBig"
  return null
}

const UserAvatarUploader: FunctionComponent<UserAvatarUploaderProps> = ({
  handleUploadedFile,
}) => {
  const { formatMessage } = useIntl()
  const [errorType, setErrorType] = useState<UploadErrorTypes>("fileType")
  const [displayError, setDisplayError] = useState<"error" | undefined>(
    undefined,
  )

  useEffect(() => {
    if (errorType === "tooSmall" || errorType === "tooBig") {
      const timer = setTimeout(() => {
        setErrorType("fileType")
        setDisplayError(undefined)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [displayError])

  const checkFileProperties = (width: number, height: number, file: Blob) => {
    const pictureFileError = checkPictureSize(width, height)

    if (pictureFileError) {
      setErrorType(pictureFileError)
      setDisplayError("error")
      return
    }
    handleUploadedFile(file, width, height)
  }

  const handleUpload = async (file: Blob) => {
    getImageSizeFromBlob(file, checkFileProperties)
  }

  const errorLabels = {
    fileType: (
      <p className="user-avatar-manager__upload-field--label">
        Format non reconnu
        <br />
        {`(format ${USER_AVATAR_ACCEPTED_FILETYPES.map(
          (format) => format.name,
        ).join(", ")} attendus)`}
      </p>
    ),
    tooSmall: (
      <p className="user-avatar-manager__upload-field--label">
        {formatMessage(messages.unauthorizedSize)}
        <br />
        {formatMessage(messages.pictureTooSmall)}
      </p>
    ),
    tooBig: (
      <p className="user-avatar-manager__upload-field--label">
        {formatMessage(messages.unauthorizedSize)}
        <br />
        {formatMessage(messages.pictureTooBig)}
      </p>
    ),
  }

  return (
    <div className="user-avatar-manager__uploader">
      <div className="user-avatar-manager__leaflet">
        <p className="user-avatar-manager__leaflet--label">{`${formatMessage(
          messages.leaflet,
        )} :`}</p>
        <p className="user-avatar-manager__leaflet--size-value">{`${formatMessage(
          messages.minSize,
        )} : ${USER_AVATAR_MIN_SIZE} x ${USER_AVATAR_MIN_SIZE} pixels`}</p>
        <p className="user-avatar-manager__leaflet--size-value">{`${formatMessage(
          messages.maxSize,
        )} : ${USER_AVATAR_MAX_SIZE} x ${USER_AVATAR_MAX_SIZE} pixels`}</p>
      </div>
      <div className="user-avatar-manager__upload-field">
        <FileUploader
          onUploaded={handleUpload}
          accept={USER_AVATAR_ACCEPTED_FILETYPES}
          label={
            <p className="user-avatar-manager__upload-field--label">
              <span style={{ color: "#3d5afe" }}>Cliquez</span> ou faites
              glisser
              <br />
              {`votre fichier ici (${USER_AVATAR_ACCEPTED_FILETYPES.map(
                (format) => format.name,
              ).join(", ")})`}
            </p>
          }
          displayAltLabel={displayError}
          errorLabel={errorLabels[errorType]}
        />
      </div>
      <div className="user-avatar-manager__upload-actions">
        <ModalActions>
          <ModalActions.Secondary onClick={() => ModalService.close()}>
            {formatMessage(messages.cancel)}
          </ModalActions.Secondary>
        </ModalActions>
      </div>
    </div>
  )
}

export default UserAvatarUploader
