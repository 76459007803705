import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import useAllSignaturesData from "features/Signatures/hooks/useAllSignaturesData"

import { signaturesSelectors } from "features/Signatures"

import { LoadingStatus } from "core/CoreModels"

import Signature from "entities/Signature"
import { groupsSelectors } from ".."
import { fetchAssignedSignaturesToEditingGroup } from "../GroupsReducer"

interface UseSignaturesAssignedToGroup {
  affectedSignatures: Signature[]
  loadingStatus: LoadingStatus
  displayedIndex: number | null
  previousSignature: () => void
  nextSignature: () => void
}

const useSignaturesAssignedToGroup = (
  groupId: number,
): UseSignaturesAssignedToGroup => {
  const dispatch = useDispatch()
  const [displayedIndex, setDisplayedIndex] = useState<number | null>(null)

  const previousSignature = () =>
    setDisplayedIndex((prevState) => prevState - 1)

  const nextSignature = () => setDisplayedIndex((prevState) => prevState + 1)

  const { signatures, loadingStatus: signaturesLoadingStatus } =
    useAllSignaturesData()

  const affectedSignatures = useSelector(
    signaturesSelectors.getSignaturesAffectedToGroup(groupId),
  )

  const assignedSignaturesloadingStatus = useSelector(
    groupsSelectors.getEditingGroupAssignedSignaturesLoadingStatus,
  )

  useEffect(() => {
    if (
      signatures.length > 0 &&
      signaturesLoadingStatus === LoadingStatus.LOADED &&
      assignedSignaturesloadingStatus === LoadingStatus.NOT_STARTED
    ) {
      dispatch(fetchAssignedSignaturesToEditingGroup())
    }
  }, [signatures, signaturesLoadingStatus, assignedSignaturesloadingStatus])

  useEffect(() => {
    if (
      assignedSignaturesloadingStatus === LoadingStatus.LOADED &&
      signatures.length > 0
    )
      setDisplayedIndex(0)
  }, [assignedSignaturesloadingStatus, signatures])

  return {
    affectedSignatures,
    loadingStatus:
      signatures.length === 0 &&
      signaturesLoadingStatus === LoadingStatus.LOADED
        ? signaturesLoadingStatus
        : assignedSignaturesloadingStatus,
    displayedIndex,
    previousSignature,
    nextSignature,
  }
}

export default useSignaturesAssignedToGroup
