import { FunctionComponent, useState } from "react"

import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"
import { Link } from "react-router-dom"

import { ReactComponent as IconAssign } from "assets/icon-users-regular.svg"

import MouseTooltip from "components/MouseTooltip"

import { CAMPAIGNS_URL } from "router/RouterConstants"

interface ScenarioCardAssignActionProps {
  scenarioId: number
  isMultiple: boolean
  roundedCorner?: "top" | "bottom"
  disabled?: boolean
}

export type ScenarioCardAssignActionType =
  FunctionComponent<ScenarioCardAssignActionProps>

const messages = defineMessages({
  assign: {
    id: "ScenarioCard.AssignTooltip",
    defaultMessage: "Associer des utilisateurs et groupes",
  },
})

const ScenarioCardAssignAction: ScenarioCardAssignActionType = ({
  scenarioId,
  isMultiple,
  roundedCorner,
  disabled = false,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  const assignScenarioLink = `${CAMPAIGNS_URL}/edit/${scenarioId}/step/3${
    isMultiple ? "/?mode=multiple" : ""
  }`
  return (
    <>
      <Link
        className={classNames(
          "scenario-card__btn",
          roundedCorner && `scenario-card__rounder-corner--${roundedCorner}`,
          disabled && "is-disabled",
        )}
        to={assignScenarioLink}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <IconAssign className="scenario-card__btn--icon" />
      </Link>
      <MouseTooltip visible={visible}>
        {formatMessage(messages.assign)}
      </MouseTooltip>
    </>
  )
}

export default ScenarioCardAssignAction
