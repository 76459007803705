import { FunctionComponent, ReactNode } from "react"

import WeightingSlider, { WeightingSliderProps } from "./WeightingSlider"
import WeightingIndicators, {
  WeightingIndicatorsProps,
} from "./WeightingIndicators"
import WeightingLabel, { WeightingLabelProps } from "./WeightingLabel"

interface WeightingContainerComposition {
  Indicators: FunctionComponent<WeightingIndicatorsProps>
  Slider: FunctionComponent<WeightingSliderProps>
  Label: FunctionComponent<WeightingLabelProps>
}

interface WeightingContainerProps {
  children: ReactNode
}

type WeightingContainerType = FunctionComponent<WeightingContainerProps> &
  WeightingContainerComposition

const WeightingContainer: WeightingContainerType = ({ children }) => {
  return <div className="weighting__container">{children}</div>
}

WeightingContainer.Indicators = WeightingIndicators
WeightingContainer.Slider = WeightingSlider
WeightingContainer.Label = WeightingLabel

export default WeightingContainer
