import { FunctionComponent } from "react"
import { useMatch } from "react-router-dom"
import { defineMessages, useIntl } from "react-intl"

import SignatureForm from "features/Signatures/SignatureForm"

import SignatureHomePage from "./SignatureHomePage/SignatureHomePage"

import { SIGNATURES_URL } from "router/RouterConstants"

const messages = defineMessages({
  NewSignatureTitle: {
    id: "SignaturesPage.NewSignatureTitle",
    defaultMessage: "Nouveau modèle de publipostage",
  },
  EditSignatureTitle: {
    id: "SignaturesPage.EditSignatureTitle",
    defaultMessage: "Modifier mon modèle de publipostage",
  },
})

const SignaturesRoutes: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const createPageMatch = useMatch(`${SIGNATURES_URL}/create`)
  const updatePageMatch = useMatch(`${SIGNATURES_URL}/update`)

  if (createPageMatch)
    return <SignatureForm title={formatMessage(messages.NewSignatureTitle)} />

  if (updatePageMatch)
    return <SignatureForm title={formatMessage(messages.EditSignatureTitle)} />

  return <SignatureHomePage />
}

export default SignaturesRoutes
