import { Moment } from "moment"
import { Entity } from "shared/core/Entity"
import { Result } from "shared/core/Result"
import { Guard } from "shared/core/Guard"
import { BannerMapper } from "./mappers/BannerMapper"

import $ from "jquery"
import moment from "moment"
import { getImageSizeFromDataUrl } from "utils/ImageUtils"

export type ScenarioMode = "simple" | "multiple"

// * Scenario DTO
export interface CreateScenarioDto {
  name: string
  startDate: Moment
  endDate: Moment
  banners: CreateBannerDto[]
  weighting: number
  internal: boolean
  external: boolean
  additional: boolean
}

export interface CreateBannerDto {
  template: string
  imageUrl: string
  linkUrl: string
}

export interface ScenarioDto {
  id: number
  weighting: number
  name: string
  startDate: Moment | null
  endDate: Moment | null
  activated: boolean
  banners: BannerDto[]
  userIds: number[]
  groupIds: number[]
  signatureIds: number[]
  internal: boolean
  external: boolean
  additional: boolean
}

export type BannerDto = CreateBannerDto & { id: number }

interface AssignedBannersScenario {
  bannerId: number
}

export interface ScenarioCustomQueryDto {
  id: number
  activeRange: {
    startDate: string | null
    endDate: string | null
    isInfinite: boolean
  }
  assignedBannersScenario: AssignedBannersScenario[]
}

export interface ScenarioAssignationsDto {
  id: number
  userIds: number[]
}

/**
 * Scenario ViewModels
 */
interface BannerProps {
  id?: number
  order: number
  imageUrl: string
  linkUrl: string
  template: string
  width?: number
  height?: number
  canvaDesignId?: string
}

export class Banner extends Entity<BannerProps> {
  private constructor({ id, ...data }: BannerProps) {
    super(data, id)
    this.props.canvaDesignId = this.ParseCanvaData(this.props.imageUrl)
  }

  // Generation dynamique du template à partir des datas du formulaire
  public static createFromFormData(formData: BannerProps): Result<Banner> {
    const propsResult = Guard.againstNullOrUndefinedList([
      { argument: formData.imageUrl, argumentName: "banner image url" },
      { argument: formData.width, argumentName: "banner image width" },
      { argument: formData.height, argumentName: "banner image height" },
    ])

    if (!propsResult.succeeded) return Result.fail<Banner>(propsResult.message)

    const template =
      formData.template == null || formData.template === ""
        ? Banner.GenerateTemplate(
            formData.imageUrl,
            formData.linkUrl,
            formData.width,
            formData.height,
          )
        : formData.template

    const instance = new Banner({ ...formData, template })
    return Result.ok(instance)
  }

  private static GenerateTemplate(
    imageUrl: string,
    linkUrl: string,
    width: number,
    height: number,
  ): string {
    const template =
      `<img alt='Campaign Banner' nosend='1' width='${width}' height='${Number(
        height,
      ).toFixed(0)}' src='" + ${imageUrl} + "' />`
        .split(" + ")
        .join("")
        .replace(/"/g, "")
    const wrappedByLink =
      '<a href="' + (linkUrl ?? "") + '">' + template + "</a>"
    return wrappedByLink
  }

  ParseCanvaData(imageUrl: string): string {
    if (imageUrl == null || imageUrl === "") return null

    const fileName = imageUrl.split("/")[imageUrl.split("/").length - 1]
    if (!fileName?.startsWith("c__")) return null
    return fileName.split("__")[1]
  }

  public static create(props: BannerProps): Result<Banner> {
    const propsResult = Guard.againstNullOrUndefinedList([
      { argument: props.imageUrl, argumentName: "banner image url" },
    ])
    if (!propsResult.succeeded) return Result.fail<Banner>(propsResult.message)

    const instance = new Banner(props)
    Banner.ParseSize(instance)
    return Result.ok(instance)
  }

  private static async ParseSize(banner: Banner) {
    const image = $(banner.props.template).find("img")
    const width = image.attr("width")
    const height = image.attr("height")
    // Si aucune taille n'est spécifiée, alors on la déduit de l'image
    if (
      (width === "undefined" && height === "undefined") ||
      (width == null && height == null)
    ) {
      const imageSize = await getImageSizeFromDataUrl(image.attr("src"))
      banner.props.width = imageSize.width
      banner.props.height = imageSize.height
    } else {
      banner.props.width = width
      banner.props.height = height
    }

    return banner
  }
}

interface ScenarioProps {
  id?: number | null
  activated: boolean
  weighting: number
  name: string
  startDate: Moment
  endDate: Moment
  created: Moment
  banners: Banner[]
  userIds: number[]
  groupIds: number[]
  signatureIds: number[]
  internal: boolean
  external: boolean
  default: boolean
  additional: boolean
}

export class Scenario extends Entity<ScenarioProps> {
  private constructor({ id, ...data }: ScenarioProps) {
    super(data, id)
    data.startDate = data.startDate != null ? moment(data.startDate) : null
    data.endDate = data.endDate != null ? moment(data.endDate) : null
    data.created = moment(data.created)
  }

  public static create(
    props: ScenarioProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bannerMapping?: (raw: any) => Result<Banner>,
  ): Result<Scenario> {
    const propsResult = Guard.againstNullOrUndefinedList([
      { argument: props.name, argumentName: "scenario name" },
    ])
    if (!propsResult.succeeded)
      return Result.fail<Scenario>(propsResult.message)

    const instance = new Scenario(props)
    const mappingBanners = props.banners.map((b) =>
      bannerMapping ? bannerMapping(b) : BannerMapper.toEntity(b),
    )
    if (mappingBanners.some((mb) => mb.isFailure))
      return Result.fail<Scenario>(
        `Mapping Error - entity Banners - scenarioId: ${instance.id}`,
      )

    instance.props.banners = mappingBanners
      .filter((mb) => mb.isSuccess)
      .map((mb) => mb.getValue())
    return Result.ok(instance)
  }
}

export interface ScenarioVm {
  id: number
  active: boolean
  weighting: number
  name: string
  startDate: Moment | null
  endDate: Moment | null
  created: Moment
  banners: BannerVm[]
  userIds: number[]
  groupIds: number[]
  signatureIds: number[]
  internal: boolean
  external: boolean
  additional: boolean
  type: "simple" | "multiple"
  isMultiple: () => boolean
  isDefault: () => boolean
}

export interface ScenarioFormVm {
  id?: number
  name: string
  default: boolean
  startDate: Moment
  endDate: Moment
  weighting: number
  type: "simple" | "multiple"
  banners: BannerFormVm[]
  userIds: number[]
  groupIds: number[]
  signatureIds: number[]
  internal: boolean
  external: boolean
  additional: boolean
}

export class BannerVm {
  id: number
  order: number
  imageUrl: string
  linkUrl: string
}

export interface BannerFormVm {
  id?: number
  draggableId: number | string
  order: number
  imageUrl: string
  linkUrl: string
  width: number
  height: number
  canva?: {
    designId: string
    designName: string
    canvaUrl: string
  }
}

export interface SignaturesScenBySignsFeat {
  id: number
  name: string
  preview: string
  isNewBuilder: boolean
}
