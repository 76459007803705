export enum ErrorTarget {
  Backend,
  Frontend,
  Both,
}

export interface ErrorLog {
  message: string
  stack: string
  target: ErrorTarget
  feature?: string | null
}

/**
 * Message affichage via l'UI pour donner du feedback à l'utilisateur
 */
export interface ErrorMessage {
  message: string
  feature?: string | null
}
