import { FunctionComponent } from "react"

import { useSelector } from "react-redux"

import TrackingMetrics from "features/Tracking/components/TrackingMetrics"
import { Scrollbars } from "react-custom-scrollbars-2"

import { trackingSelectors } from "features/Tracking"

import useAllSignaturesData from "features/Signatures/hooks/useAllSignaturesData"

const TrackingSignatureList: FunctionComponent = () => {
  useAllSignaturesData()
  const signaturesMetrics = useSelector(trackingSelectors.getSignaturesMetrics)

  const trackingSignatures = signaturesMetrics.map((signatureMetric, index) => (
    <div
      key={index}
      className={
        signaturesMetrics.length > 1 || signaturesMetrics.length !== index - 1
          ? "tracking-metrics__list"
          : ""
      }
    >
      <TrackingMetrics tracking={signatureMetric} key={index} />
    </div>
  ))

  return (
    <div className="tracking-metrics__container">
      <Scrollbars
        hideTracksWhenNotNeeded
        autoHide
        autoHeight
        autoHeightMin="100%"
        autoHeightMax={680}
      >
        <div className="tracking-metrics__content">{trackingSignatures}</div>
      </Scrollbars>
    </div>
  )
}

export default TrackingSignatureList
