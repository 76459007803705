import { FunctionComponent, RefObject } from "react"

import { useDispatch } from "react-redux"

import moment from "moment"

import Layout from "features/Signatures/components/SignatureCardLayout"

import {
  deleteBuilderSignatureAction,
  duplicateBuilderSignatureAction,
  initActiveBuilderSignatureAction,
  toggleBuilderSignatureAction,
} from "features/BuilderSignatures/BuilderSignaturesActions"

import useBuilderSignatureAffectedDatas from "features/BuilderSignatures/hooks/useBuilderSignatureAffectedCount"

import { LoadingStatus } from "core/CoreModels"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

interface BuilderSignatureCardProps {
  builderSignature: BuilderSignature
}

const BuilderCard: FunctionComponent<BuilderSignatureCardProps> = ({
  builderSignature,
}) => {
  const dispatch = useDispatch()
  const { signatureRef: signatureCardRef, loadingStatus } =
    useBuilderSignatureAffectedDatas(builderSignature.id)

  const onEdit = () => {
    dispatch(initActiveBuilderSignatureAction(builderSignature.id))
  }

  const onDelete = () => {
    dispatch(deleteBuilderSignatureAction(builderSignature.id))
  }

  const onActivate = () => {
    dispatch(
      toggleBuilderSignatureAction({
        ...builderSignature,
        activated: !builderSignature.activated,
      }),
    )
  }

  const onDuplicate = () =>
    dispatch(duplicateBuilderSignatureAction(builderSignature))

  return (
    <Layout
      cardRef={signatureCardRef as RefObject<HTMLDivElement>}
      id={builderSignature.id}
    >
      <Layout.Content>
        <Layout.JsonPreview signature={builderSignature} />
        <Layout.Infos>
          <Layout.Name name={builderSignature.name} />
          {builderSignature.activated && (
            <Layout.Date
              type="activated"
              date={builderSignature.activatedDate.format("L")}
            />
          )}
          {builderSignature.created !== builderSignature.modified && (
            <Layout.Date
              type="lastUpdate"
              date={moment(builderSignature.modified).format("L")}
            />
          )}
          <Layout.Toggle
            activated={builderSignature.activated}
            onActivate={onActivate}
          />
        </Layout.Infos>
        <Layout.InfosExpanded>
          <Layout.AffectedCount
            activated={builderSignature.activated}
            affectedCount={(builderSignature?.affectedUsersCount || []).length}
            isLoading={loadingStatus !== LoadingStatus.LOADED}
          />
          <Layout.Weighting weighting={builderSignature.weighting} />
        </Layout.InfosExpanded>
      </Layout.Content>
      <Layout.Actions>
        <Layout.Edit onEdit={onEdit} editLink="/portal/builder/edit" />
        <Layout.Assign
          onAffectUsers={onEdit}
          affectUrl="/portal/builder/edit?panel=diffusion"
          roundedCorner="top"
        />
        <Layout.Duplicate onDuplicate={onDuplicate} />
        <Layout.Delete onDelete={onDelete} roundedCorner="bottom" />
      </Layout.Actions>
    </Layout>
  )
}

export default BuilderCard
