import React from "react"
import { FormattedMessage } from "react-intl"

import { CustomPicker } from "react-color"
import {
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common"

interface Props {
  color: string
  onChange(color)
  onReset()
}

class BmmColorPicker extends React.Component<Props> {
  handleEditableChange = (event) => {
    if (event.editableColor != null) {
      this.props.onChange(event.editableColor)
    }
  }

  render() {
    const { onChange, color } = this.props

    const styles = {
      hue: {
        height: 10,
        position: "relative",
        marginBottom: 10,
      },
    }

    return (
      <div className="bmm-picker">
        <div className="color-picker__saturation">
          <Saturation {...this.props} onChange={onChange} />
        </div>
        <div className="color-picker__hue">
          <Hue {...this.props} onChange={onChange} />
        </div>
        <div className="color-picker__editable">
          <div className="color-picker__editable-libelle">hex :</div>
          <EditableInput
            label="editableColor"
            value={"#" + color}
            onChange={this.handleEditableChange}
          />
        </div>
        <div className="color-picker__reset" onClick={this.props.onReset}>
          <FormattedMessage
            id="ColorPickerContainer.Reset"
            defaultMessage="Réinitialiser"
          />
        </div>
      </div>
    )
  }
}

export default CustomPicker(BmmColorPicker)
