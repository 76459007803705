/**
 * Utilisation d'un context pour conserver en mémoire les différents éléments visités au fil de la navigation
 *
 */
import { createContext, useContext } from "react"

export const LocationStateContext = createContext({
  locations: {},
})

// Retourne une fonction qui garde en mémoire les données
export function useLocationState(key) {
  const locationContext = useContext(LocationStateContext)

  const setLocationState = (id) => {
    locationContext.locations[key] = id
  }

  const getLocationState = () => {
    return locationContext.locations[key]
  }

  return {
    setLocationState,
    getLocationState,
  }
}
