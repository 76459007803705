import { FunctionComponent } from "react"

import Layout from "components/TwoColumnsLayout"

import LangPillSwitch from "features/App/components/LangPillSwitch"
import RegisterLeftPanel from "features/Accounts/components/RegisterLeftPanel"
import RegisterForm from "features/Accounts/components/RegisterForm"

import useUTMManagement from "features/Marketing/hooks/useUTMManagement"

const RegisterPage: FunctionComponent = () => {
  useUTMManagement()
  return (
    <Layout>
      <Layout.Left>
        <RegisterLeftPanel />
      </Layout.Left>
      <Layout.Right>
        <LangPillSwitch />
        <RegisterForm />
      </Layout.Right>
    </Layout>
  )
}

export default RegisterPage
