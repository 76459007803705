import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import Toggle from "components/MUIToggle"

import Feature from "features/Features/FeaturesModels"

import { AvailableJobs } from "features/Jobs/JobsConstants"

interface GSuiteToggleProps {
  featureGSuite: Feature
  onToggle: (activate: boolean) => void
  onTriggerJob: () => void
  onOpenParams: () => void
}

const messages = defineMessages({
  syncADTitle: {
    id: "GSuiteForm.SyncADTitle",
    defaultMessage: "Annuaire utilisateurs",
  },
  syncADDescription1: {
    id: "GSuiteForm.SyncADDescription1",
    defaultMessage:
      "Activez la synchronisation automatique de votre annuaire utilisateurs ",
  },
  syncADDescription2: {
    id: "GSuiteForm.SyncADDescription2",
    defaultMessage: "toutes les 24 heures",
  },
  syncADDisable: {
    id: "GSuiteForm.SyncADDisable",
    defaultMessage: "Désactivée",
  },
  syncADEnable: {
    id: "GSuiteForm.SyncADEnable",
    defaultMessage: "Activée",
  },
  manualSync: {
    id: "GSuiteForm.ManualSync",
    defaultMessage: "Synchroniser manuellement",
  },
  settings: {
    id: "GSuiteForm.Settings",
    defaultMessage: "Paramètres",
  },
})

const GSuiteToggle: FunctionComponent<GSuiteToggleProps> = ({
  featureGSuite,
  onToggle,
  onTriggerJob,
  onOpenParams,
}) => {
  const { formatMessage } = useIntl()

  const [launchSync, setLaunchSync] = useState(false)

  const isSyncing = useJobIsRunning(AvailableJobs.SYNC_USERS)

  useEffect(() => {
    if (!isSyncing) setLaunchSync(false)
  }, [isSyncing])

  const handleSync = () => {
    setLaunchSync(true)
    onTriggerJob()
  }

  const handleToggle = (isActive) => {
    if (isActive === null) {
      isActive = !featureGSuite.activated
    }

    onToggle(isActive)
  }

  return (
    <div className="gsuite-toggle" data-cy="gsuite-toggle">
      <p className="gsuite-toggle__title">
        {formatMessage(messages.syncADTitle)}
      </p>
      <p className="gsuite-toggle__description">
        {formatMessage(messages.syncADDescription1)}
        <span className="gsuite-toggle__description--bold">
          {formatMessage(messages.syncADDescription2)}
        </span>
      </p>
      <div className="gsuite-toggle__action">
        <div
          className={`gsuite-toggle__action--${
            featureGSuite.activated ? "inactive" : "active"
          }`}
          onClick={() => handleToggle(false)}
        >
          {formatMessage(messages.syncADDisable)}
        </div>
        <div className="gsuite-toggle__toggle">
          <Toggle
            checked={featureGSuite.activated}
            onChange={() => handleToggle(null)}
          />
        </div>
        <div
          className={`gsuite-toggle__action--${
            featureGSuite.activated ? "active" : "inactive"
          }`}
          onClick={() => handleToggle(true)}
        >
          {formatMessage(messages.syncADEnable)}
        </div>
      </div>
      {featureGSuite.activated && (
        <>
          <button
            className="gsuite-toggle__sync"
            onClick={handleSync}
            disabled={launchSync || isSyncing}
          >
            <p className="gsuite-toggle__sync--label">
              {formatMessage(messages.manualSync)}
            </p>
            <SyncIcon
              className={classNames(
                "gsuite-toggle__sync--icon",
                (launchSync || isSyncing) && "is-loading",
              )}
            />
          </button>
          <div className="gsuite-toggle__params">
            <div className="gsuite-toggle__params__btn" onClick={onOpenParams}>
              <i className="material-icons gsuite-toggle__params__btn--icon">
                settings
              </i>
              {formatMessage(messages.settings)}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default GSuiteToggle
