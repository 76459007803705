import { connect } from "react-redux"
import { signaturesSelectors } from "features/Signatures"
import SignatureNameInput from "features/Signatures/SignatureForm/1.SignatureProperties/SignatureNameInput"
import { updateSignatureName } from "features/Signatures/SignaturesReducer"

const mapStateToProps = (state) => ({
  signatureName: signaturesSelectors.getSignatureName(state),
  reduxError: signaturesSelectors.getSignatureNameError(state),
})

const mapDispatchToProps = {
  updateSignatureName: updateSignatureName,
}

const ConnectedTextBox = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignatureNameInput)

export default ConnectedTextBox
