/**
 * Composant Wrapper - Encapsule le Material Ui Field pour pouvoir préciser le style générique qu'une seule fois.
 */
import React from "react"
import TextField from "@material-ui/core/TextField"
import { injectIntl, IntlShape, defineMessages } from "react-intl"

interface State {
  value: string
  errorText: string
  isFocus: boolean
}

interface IntlProps {
  intl: IntlShape
}

const messages = defineMessages({
  mandatory: {
    id: "TextBox.MandatoryField",
    defaultMessage: "Champ obligatoire",
  },
})

class TextBox extends React.Component<any & IntlProps, State> {
  textField
  constructor(props) {
    super(props)
    this.textField = React.createRef()
    this.state = {
      value: props.value,
      errorText: null,
      isFocus: false,
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      value: newProps.value,
    })
  }

  validate = (value: string) => {
    if (!this.props.validators || this.props.validators.length === 0)
      return true

    this.props.validators.map((validator) => {
      if (validator.checker(value) === false) {
        this.setState({ errorText: validator.errorText })
        return false
      }
      return true
    })
    return true
  }

  handleInputChange = (evt) => {
    const value = evt.target.value
    if (this.state.isFocus) {
      if (this.props.mandatory && value === "") {
        this.setState({
          errorText: this.props.intl.formatMessage(messages.mandatory),
        })
      } else {
        this.setState({ errorText: "" })
      }
      this.validate(value)
    }
    this.setState({ value })
    this.props.onChange(evt)
  }

  handleInputFocus = (evt) => {
    this.setState({ isFocus: true })
  }

  handleInputBlur = (evt) => {
    if (this.state.isFocus) {
      if (this.props.mandatory && evt.target.value === "") {
        this.setState({
          errorText: this.props.intl.formatMessage(messages.mandatory),
        })
      } else {
        this.setState({ errorText: "" })
      }
      this.validate(evt.target.value)
    }
    this.setState({ isFocus: false })
    if (this.props.onBlur) this.props.onBlur(evt)
  }

  render() {
    const { errorText } = this.state

    if (this.props.focus) this.textField.current.focus()

    return (
      <TextField
        id={this.props.id}
        style={this.props.style}
        className={this.props.className}
        ref={this.textField}
        disabled={this.props.disabled}
        value={this.state.value}
        name={this.props.name}
        error={this.props.error}
        helperText={errorText || this.props.errorText}
        label={this.props.hintText}
        onChange={this.handleInputChange}
        onBlur={this.handleInputBlur}
        fullWidth={this.props.fullWidth}
        placeholder={this.props.placeholder}
        multiline={this.props.multiline}
        rowsMax={this.props.rowsMax}
        rows={this.props.rows}
        margin={this.props.margin}
        type={this.props.type}
        variant={this.props.variant}
        data-cy={this.props["data-cy"]}
      />
    )
  }
}

export default injectIntl(TextBox)
