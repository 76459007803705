export interface Alert
{
    id: number
    title: string
    excerpt: string
    image: string
    icon: string
    link: string
    content: string
    readStatus: AlertStatus
}

export enum AlertStatus{
  New = 0,
  NotRead = 1,
  Read = 2
}