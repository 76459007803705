import React from "react"

interface Props {}

class Grid extends React.Component<Props> {
  render() {
    const { children } = this.props
    return <div className="grid">{children}</div>
  }
}

export default Grid
