import React from "react"
import ImagePicker from "components/ImagePicker"
import ConnectedLogoUrlField from "../ConnectedLogoUrlField"
import Dialog from "components/Dialog"
import ImageCropper from "components/ImageCropper"
import ImageResizer from "components/ImageResizer"
import {
  FormattedMessage,
  defineMessages,
  IntlShape,
  injectIntl,
} from "react-intl"
import { getImageSizeFromDataUrl } from "utils/ImageUtils"

import { Notification } from "features/Notifications"

const messages = defineMessages({
  cropTitle: {
    id: "SignaturePropertiesSideBar.CropTitle",
    defaultMessage: "Redimensionner",
  },
  imageTooBig: {
    id: "Notification.ImageTooLarge",
    defaultMessage:
      "L'image que vous essayez de télécharger est trop importante.",
  },
})

interface State {
  imageUrl: string
  isUploaded: boolean
  initialImageUrl: string
  naturalSize: { width: number; height: number }
}

interface Props {
  cropWidth: number
  cropHeight: number
  max: { width: number; height: number }
  size: { width: number; height: number }
  imageUrl: string
  onImageChange(base64Url: string)
  onImageResize(width: number, height: number)
  onImageCrop(baseUrl: string, width: number, height: number)
  onSizeLimitReach(notification: Notification)
  intl: IntlShape
}

class LogoPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const imageUrl = props.imageUrl ? props.imageUrl : ""

    // recupération de la taille naturelle de l'image sur le CDN à partir de son URL
    if (imageUrl != null && imageUrl !== "") {
      this.getNaturalSize(imageUrl)
    }

    this.state = {
      imageUrl,
      isUploaded: false,
      initialImageUrl: props.imageUrl ? props.imageUrl : "",
      naturalSize: props.max, // on initialise à la taille idéale
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.imageUrl !== this.props.imageUrl)
      this.setState((prevState) => ({
        ...prevState,
        imageUrl: this.props.imageUrl,
      }))
  }

  getNaturalSize = (imageUrl) => {
    getImageSizeFromDataUrl(imageUrl).then((naturalSize) => {
      this.setState({ naturalSize })
    })
  }

  handleImageChange = (base64Url: string) => {
    this.setState({ initialImageUrl: base64Url, isUploaded: true })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSizeLimitReach = (_file: File) => {
    this.props.onSizeLimitReach(
      new Notification("Notification.ImageTooLarge", "WARNING"),
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleFileChange = (_file: File, _refresh: boolean) => null

  handleCrop = (base64CroppedUrl: string, width: number, height: number) => {
    // actuellement, le crop n'altèrant pas la qualité de l'image, on conserve ce traitement coté client
    this.setState(
      {
        imageUrl: base64CroppedUrl,
        isUploaded: false,
        naturalSize: { width, height },
      },
      () => {
        this.props.onImageCrop(this.state.imageUrl, width, height)
      },
    )
  }

  handleClose = () => {
    this.props.onImageChange(this.state.imageUrl)
  }

  handleResize = (width: number, height: number) => {
    this.props.onImageResize(width, height)
  }

  render() {
    const { cropWidth, cropHeight, size, intl } = this.props

    return (
      <div className="logo-import">
        <div className="logo-import__infos">
          <ImagePicker
            showPreview={true}
            onImageChange={this.handleImageChange}
            onFileChange={this.handleFileChange}
            imageUrl={this.state.imageUrl}
            maxWidth={500}
            maxHeight={200}
            sizeLimit={200000000}
            handleSizeLimitReach={this.handleSizeLimitReach}
          />
          <ConnectedLogoUrlField displayPreview={false} />
        </div>
        <Dialog
          open={this.state.isUploaded}
          close={this.handleClose}
          title={intl.formatMessage(messages.cropTitle)}
          fullWidth
        >
          <ImageCropper
            setCroppedImage={this.handleCrop}
            cropWidth={cropWidth}
            cropHeight={cropHeight}
            imageUrl={this.state.initialImageUrl}
          />
        </Dialog>
        <h5 className="l-2-columns__subtitle">
          <FormattedMessage
            id="SignaturePropertiesSideBar.Resize"
            defaultMessage="Redimensionner"
          />
        </h5>
        <div className="logo-import__resize">
          <ImageResizer
            max={this.state.naturalSize}
            size={size}
            resizeImage={this.handleResize}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(LogoPicker)
