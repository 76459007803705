import moment from "moment"

import { ON_BOARDING_COMPLETED_DURATION_DAYS } from "./AccountsConstants"

import {
  OnBoardingItem,
  OnBoardingItems,
  OnBoardingKey,
} from "./AccountsModels"

function allOnBoardingRulesChecked(currentState: OnBoardingItems) {
  return Object.values(currentState).every((item) => item.state === "completed")
}

export function commonOnBoardingRule(
  result: unknown[],
  currentState: OnBoardingItems,
  onBoardingKey: OnBoardingKey,
): OnBoardingItem {
  if (allOnBoardingRulesChecked(currentState))
    return currentState[onBoardingKey]

  const isCompleted = result.length > 0
  return {
    state: isCompleted ? "completed" : "uncompleted",
    date: isCompleted ? new Date().toISOString() : null,
  }
}

export function syncedSignatureOnBoardingRule(
  result: unknown[],
  currentState: OnBoardingItems,
): OnBoardingItem {
  if (result.length === 0 || currentState.syncedSignature.state === "completed")
    return currentState.syncedSignature

  return { state: "completed", date: new Date().toISOString() }
}

export function onBoardingRulesValidation(
  apiCallResults,
  currentState: OnBoardingItems,
) {
  return apiCallResults.reduce((acc, curr) => {
    const currentOnBoardingKey = curr.onBoardingRule(
      curr.result,
      currentState,
      curr.onBoardingKey,
    )

    return { ...acc, [curr.onBoardingKey]: currentOnBoardingKey }
  }, currentState)
}

export function onBoardingInProgress(
  isTrial: boolean,
  lastCompletedStep: Date | null,
) {
  if (isTrial || lastCompletedStep === null) return true

  return moment().isBefore(
    moment(lastCompletedStep).add(
      ON_BOARDING_COMPLETED_DURATION_DAYS.value,
      ON_BOARDING_COMPLETED_DURATION_DAYS.unit as moment.unitOfTime.DurationConstructor,
    ),
  )
}
