import { ReactNode, MouseEvent } from "react"

export interface ModalConfig {
  title: string
  content: ReactNode
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => Promise<void | boolean>
}

class ModalServiceImpl {
  private modalProviderHandler: ((config: ModalConfig) => void) | undefined =
    undefined

  private closeModal: (() => void) | undefined = undefined

  setModalProvider = (
    modalProviderHandler: (config: ModalConfig) => void,
    closeModal: () => void,
  ) => {
    this.modalProviderHandler = modalProviderHandler
    this.closeModal = closeModal
  }

  open = (config: ModalConfig) =>
    this.modalProviderHandler &&
    this.modalProviderHandler({
      title: config.title,
      content: config.content,
      onCancel: config.onCancel || (() => Promise.resolve(true)),
    })

  close = () => this.closeModal && this.closeModal()
}

const ModalService = new ModalServiceImpl()

export default ModalService
