import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import {
  fetchPartialGroups,
  reloadPartialGroups,
  searchGroups,
  setPartialGroupsPageNumber,
  setPartialGroupsPageSize,
} from "features/Groups/GroupsReducer"

import { groupsSelectors } from "features/Groups"

import { PartialGroup } from "features/Groups/GroupsModels"

import { LoadingStatus } from "core/CoreModels"
import useDebounce from "hooks/useDebounce"
import { SortOrders } from "components/PaginatedDataGrid/PaginatedDataGrid"

interface UsePartialGroupsData {
  partialGroups: Array<PartialGroup>
  currentPage: number
  lastPage: number
  pageSize: number
  totalCount: number
  loadingStatus: LoadingStatus
  searchValue: string
  debouncedSearchString: string
  orderBy: SortOrders
  changePage: (pageNumber: number) => void
  changePageSize: (pageSize: number) => void
  handleSearch: (input: string) => void
  reload: () => void
}

const usePartialGroupsData = (): UsePartialGroupsData => {
  const dispatch = useDispatch()

  const partialGroups: Array<PartialGroup> = useSelector(
    groupsSelectors.getAllPartialGroups,
  )
  const {
    currentPage,
    lastPage,
    pageSize,
    orderBy,
    search: searchString,
    loadingStatus,
    totalCount,
  } = useSelector(groupsSelectors.getPartialGroupsPaginationData)

  const [searchValue, setSearchValue] = useState<string>(searchString)
  const debouncedSearchString = useDebounce<string>(searchValue, 500)

  useEffect(() => {
    setSearchValue(searchString)
  }, [searchString])

  useEffect(() => {
    if (searchValue !== searchString) {
      dispatch(searchGroups(debouncedSearchString))
    }
  }, [debouncedSearchString])

  useEffect(() => {
    dispatch(
      fetchPartialGroups({
        pageSize,
        pageNumber: currentPage,
        query: searchString,
        orderBy,
      }),
    )
  }, [dispatch, searchString, currentPage, pageSize, orderBy])

  const changePage = (pageNumber: number): void => {
    dispatch(setPartialGroupsPageNumber(pageNumber))
  }

  const changePageSize = (nextPageSize: number): void => {
    dispatch(setPartialGroupsPageSize(nextPageSize))
  }

  const handleSearch = (input: string) => {
    const search = input.toLowerCase()
    setSearchValue(search)
  }

  const reload = () => {
    dispatch(reloadPartialGroups())
  }

  return {
    partialGroups,
    currentPage,
    lastPage,
    pageSize,
    totalCount,
    loadingStatus,
    searchValue,
    debouncedSearchString,
    orderBy,
    changePage,
    changePageSize,
    handleSearch,
    reload,
  }
}

export default usePartialGroupsData
