import { TenantProperty } from "entities/TenantProperty"
import Feature from "features/Features/FeaturesModels"
import { createAction } from "@reduxjs/toolkit"
import { UserProperties } from "features/Users/UserModels"
import { Account } from "./AccountsModels"

export const addAdminUserAction = createAction(
  "accounts/addAdminUser",
  function prepare(
    userId: number,
    properties: UserProperties,
    redirect: string,
  ) {
    return {
      payload: {
        userId,
        properties,
        redirect,
      },
    }
  },
)

export const deleteAdminAccountAction = createAction(
  "adminAccounts/deleteAdminAccount",
  function prepare(adminAccountUserName: string, adminAccountId: string) {
    return {
      payload: { userName: adminAccountUserName, id: adminAccountId },
    }
  },
)

export const fetchAccountsLinksAction = createAction(
  "accounts/fetchAccountsLinks",
)

export const resetPasswordAction = createAction(
  "accounts/resetPassword",
  function prepare(
    params: { userId: string; code: string; isConfirmUser: boolean },
    values: { password: string; confirmPassword: string },
  ) {
    return {
      payload: { params, values },
    }
  },
)

export const requestResetPasswordAction = createAction(
  "accounts/requestResetPassword",
  function prepare(email: string) {
    return {
      payload: email,
    }
  },
)

export const requestResetPasswordSuccessAction = createAction(
  "accounts/requestResetPasswordSuccess",
)

export const requestResetPasswordFailureAction = createAction(
  "accounts/requestResetPasswordFailure",
)

export const fetchGWorkspaceDomainsAction = createAction(
  "accounts/fetchGWorkspaceDomains",
  function prepare(adminEmail: string) {
    return {
      payload: adminEmail,
    }
  },
)

export const fetchGWorkspaceDomainsFailureAction = createAction(
  "accounts/fetchGWorkspaceDomainsFailure",
)

export const createTenantPropertyAction = createAction(
  "accounts/createTenantProperty",
  function prepare(tenantProperty: TenantProperty) {
    return {
      payload: tenantProperty,
    }
  },
)

export const updateTenantPropertyAction = createAction(
  "accounts/updateTenantProperty",
  function prepare(tenantProperty: TenantProperty) {
    return {
      payload: tenantProperty,
    }
  },
)

export const fetchTenantFeaturesAction = createAction(
  "accounts/fetchTenantFeatures",
)

export const createTenantFeaturePropertiesAction = createAction(
  "accounts/updateTenantFeatureProperties",
  function prepare(feature: Feature) {
    return {
      payload: feature,
    }
  },
)

export const updateTenantFeaturePropertiesSuccessAction = createAction(
  "accounts/updateTenantFeaturePropertiesSuccess",
)

export const updateTenantPropertiesFromBmmAction = createAction(
  "accounts/updateTenantPropertiesFromBmm",
)

export const updateTenantFeaturePropertiesFromBmmSuccessAction = createAction(
  "accounts/updateTenantFeaturePropertiesFromBmmSuccess",
)

export const fetchCurrentAccountAction = createAction(
  "accounts/fetchCurrentAccount",
)

export const loginAction = createAction(
  "accounts/login",
  function prepare(email: string, password: string, rememberMe: boolean) {
    return {
      payload: { email, password, rememberMe },
    }
  },
)

export const loginSuccessAction = createAction(
  "accounts/loginSuccess",
  function prepare(account: Account) {
    return {
      payload: account,
    }
  },
)

export const logoutAction = createAction("accounts/logout")

export const loadInitialDatasAction = createAction("accounts/loadInitialDatas")

export const loadInitialDatasSuccessAction = createAction(
  "accounts/loadInitialDatasSuccess",
)

export const tenantCreatedAction = createAction("accounts/tenantCreated")

export const syncFinishedAction = createAction("accounts/syncFinished")

export const createQueueInstructionSuccessAction = createAction(
  "accounts/createQueueInstructionSuccess",
)

export const deleteInstructionSuccessAction = createAction(
  "accounts/deleteInstructionSuccess",
)

export const finishAccountRegistrationAction = createAction(
  "accounts/finishAccountRegistration",
  function prepare(stepRegister: number) {
    return {
      payload: { stepRegister },
    }
  },
)

export const addAdminAccountToSubsidiariesAction = createAction(
  "accounts/addAdminAccountToSubsidiaries",
  function prepare(adminId, subsidiariesIds) {
    return { payload: { adminId, subsidiariesIds } }
  },
)

export const removeAdminAccountToSubsidiariesAction = createAction(
  "accounts/removeAdminAccountToSubsidiaries",
  function prepare(adminId: string) {
    return { payload: adminId }
  },
)
