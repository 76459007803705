import moment from "moment"

import {
  GRAPH_COLORS,
  GRAPH_LIGHT_COLORS,
} from "features/Tracking/TrackingConstants"

import {
  BannersClickedDto,
  BaseHistoryEvent,
  ScenarioTracking,
  ScenarioTrackingHistory,
  TrackingGraphData,
} from "features/Tracking/TrackingModels"

import SharedTrackingMapper from "./SharedTrackingMapper"

const ScenarioTrackingMapper = {
  getSeriesFromSignatureTrackingHistory(history: ScenarioTrackingHistory[]): {
    id: number
    label: string
  }[] {
    return history
      .map((history) =>
        Object.entries(history.data).map(([key, values]) => ({
          id: Number(key),
          label: values.label,
        })),
      )
      .flat()
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.id === value.id && t.label === value.label),
      )
  },
  getGraphDataFromHistory(scenario: ScenarioTracking): TrackingGraphData[] {
    const { history, banners } = scenario
    const series =
      ScenarioTrackingMapper.getSeriesFromSignatureTrackingHistory(history)

    const totals = {
      id: "total",
      label: "Total des campagnes",
      display: true,
      hover: false,
      selected: false,
      data: history
        .map((el) => ({
          x: el.date,
          y: Object.values(el.data)
            .map((value) => value.quantity)
            .reduce((acc, v) => acc + v, 0),
        }))
        .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
    }

    return [
      totals,
      ...series.map((serie, i) => ({
        id: serie.id,
        label: `Campagne ${i + 1}`,
        thumbnailUrl:
          banners.find((banner) => banner.id === serie.id)?.imageUrl || "",
        display: true,
        hover: false,
        selected: false,
        data: history
          .map((el) => ({
            x: el.date,
            y: el.data[serie.id]?.quantity || 0,
          }))
          .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
      })),
    ].map((serie, i) => ({
      ...serie,
      color: GRAPH_COLORS[i % GRAPH_COLORS.length],
      secondaryColor: GRAPH_LIGHT_COLORS[i % GRAPH_LIGHT_COLORS.length],
    }))
  },
  getScenariosPowerUserHistory(
    bannersClickedData: BannersClickedDto[],
    userId: number,
  ): BaseHistoryEvent[] {
    const userClickedData = bannersClickedData.filter(
      (b) => b.userId === userId,
    )

    if (userClickedData.length === 0) return null

    return SharedTrackingMapper.getHistoryFromEventsClicked(userClickedData)
  },
  getScenarioTotalClicks(
    bannersClickedData: BannersClickedDto[],
    bannersIds: number[],
  ) {
    const bannersTotalClicks = bannersClickedData.filter((b) =>
      bannersIds.includes(b.bannerId),
    )

    return bannersTotalClicks.reduce((acc, curr) => acc + curr.nbClicks, 0)
  },
  getScenarioMaxClicks(
    bannersClickedData: BannersClickedDto[],
    bannersIds: number[],
  ) {
    const bannersClickedDate = bannersClickedData.filter((b) =>
      bannersIds.includes(b.bannerId),
    )

    return SharedTrackingMapper.getHistoryFromEventsClicked(
      bannersClickedDate,
    ).reduce((prev, curr) => (prev.quantity > curr.quantity ? prev : curr), {
      date: moment(new Date()).format("YYYY-MM-DD"),
      quantity: 0,
    })
  },
  getScenariosTrackingDataHistory(
    bannersClickedData: BannersClickedDto[],
    bannersIds: number[],
  ) {
    const bannersClickedDate = bannersClickedData.filter((b) =>
      bannersIds.includes(b.bannerId),
    )

    const historyGroupedByDate: { [key: string]: ScenarioTrackingHistory } = {}

    bannersClickedDate.forEach((clickedEvent) => {
      const { clickedDate } = clickedEvent
      clickedDate in historyGroupedByDate
        ? clickedEvent.bannerId in historyGroupedByDate[clickedDate].data
          ? (historyGroupedByDate[clickedDate].data[
              clickedEvent.bannerId
            ].quantity += clickedEvent.nbClicks)
          : (historyGroupedByDate[clickedDate].data[clickedEvent.bannerId] = {
              id: clickedEvent.bannerId,
              quantity: clickedEvent.nbClicks,
            })
        : (historyGroupedByDate[clickedDate] = {
            date: clickedDate,
            data: {
              [clickedEvent.bannerId]: {
                id: clickedEvent.bannerId,
                quantity: clickedEvent.nbClicks,
              },
            },
          })
    })

    return Object.values(historyGroupedByDate)
  },
  getTotalClicksFromScenarioTrackingHistory(
    history: ScenarioTrackingHistory[],
    from: string,
    to: string,
  ) {
    return history
      .filter(
        (hEvent) =>
          moment(hEvent.date).isSameOrAfter(from) &&
          moment(hEvent.date).isSameOrBefore(to),
      )
      .map((hEvent) =>
        Object.values(hEvent.data).map(
          (item: { id: number; quantity: number }) => item.quantity,
        ),
      )
      .flat()
      .reduce((acc, curr) => acc + curr, 0)
  },
  getMaxClicksFromScenarioTrackingHistory(
    history: ScenarioTrackingHistory[],
    from: string,
    to: string,
  ) {
    const historyEventsFromRange = history.filter(
      (hEvent) =>
        moment(hEvent.date).isSameOrAfter(from) &&
        moment(hEvent.date).isSameOrBefore(to),
    )

    const historyEventPerBanner = historyEventsFromRange
      .map((hEvent) =>
        Object.values(hEvent.data).map(
          (bEvent: { id: number; quantity: number }) => ({
            date: hEvent.date,
            quantity: bEvent.quantity,
          }),
        ),
      )
      .flat()

    const historyGroupedByDate: {
      [key: string]: { date: string; quantity: number }
    } = {}

    historyEventPerBanner.forEach((hEvent) => {
      const clickedDate = hEvent.date
      clickedDate in historyGroupedByDate
        ? (historyGroupedByDate[clickedDate].quantity += hEvent.quantity)
        : (historyGroupedByDate[clickedDate] = {
            date: clickedDate,
            quantity: hEvent.quantity,
          })
    })

    return Object.values(historyGroupedByDate).reduce(
      (prev, curr) => (prev.quantity > curr.quantity ? prev : curr),
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        quantity: 0,
      },
    )
  },
}

export default ScenarioTrackingMapper
