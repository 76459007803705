import { FunctionComponent, HTMLAttributes } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import useUsersTileDatas from "./hooks/useUsersTileDatas"

import { ReactComponent as IconDuotoneUsers } from "assets/duotone/icon-users-regular-duotone.svg"

import Tile from "features/Accounts/components/HomePageSingleTile"

import UsersTileDatas from "./components/UsersTileDatas"

import { USERS_URL } from "router/RouterConstants"
import UsersTileLatest from "./components/UsersTileLatest"
import { LoadingStatus } from "core/CoreModels"

interface UsersTileProps extends HTMLAttributes<HTMLDivElement> {
  isTrial: boolean
  className?: string
}

const messages = defineMessages({
  title: {
    id: "HomePage.UsersTileTitle",
    defaultMessage: "Utilisateurs",
  },
  pillLabel: {
    id: "HomePage.UsersTileLicencesLabel",
    defaultMessage: "Licences totales",
  },
  noUsers: {
    id: "HomePage.UsersTileNoUsers",
    defaultMessage: "Aucun utilisateurs paramétrés.",
  },
})

const UsersTile: FunctionComponent<UsersTileProps> = ({
  isTrial,
  className,
  ...others
}) => {
  const { formatMessage } = useIntl()

  const { loadingStatus, count, latest } = useUsersTileDatas()

  const noUsers = !isTrial && count.users === 0 && (
    <p className="users-tile__header--no-users">
      {formatMessage(messages.noUsers)}
    </p>
  )

  const licencesPill = count.users !== 0 && (
    <div className="users-tile__pill">
      <p className="users-tile__pill--count">{count.licences}</p>
      <p className="users-tile__pill--label">
        {formatMessage(messages.pillLabel)}
      </p>
    </div>
  )

  return (
    <Tile {...others} className={classNames(className, "users-tile")}>
      <div className="users-tile__main">
        <div className="users-tile__header">
          <Tile.Title
            icon={<IconDuotoneUsers />}
            label={formatMessage(messages.title)}
            to={!isTrial && count.users !== 0 && USERS_URL}
          />
          {noUsers}
        </div>
        <Tile.Datas className="relative">
          {isTrial ? (
            <Tile.NeedSubscription />
          ) : (
            <>
              {licencesPill}
              <UsersTileDatas
                loadingStatus={loadingStatus}
                latestCount={latest.length}
                usersCount={count.users}
                groupsCount={count.groups}
              />
            </>
          )}
        </Tile.Datas>
      </div>
      {count.users !== 0 && !isTrial && (
        <UsersTileLatest
          isLoading={loadingStatus !== LoadingStatus.LOADED}
          latestUsers={latest}
        />
      )}
    </Tile>
  )
}

export default UsersTile
