import { FunctionComponent, ReactNode } from "react"

import classNames from "classnames"

import { ReactComponent as IconChevronLeft } from "assets/icon-chevron-left.svg"
import { ReactComponent as IconChevronRight } from "assets/icon-chevron-right.svg"

import useHorizontalScrollingButtons from "./hooks/useHorizontalScrollingButtons"

interface HorizontalScrollingButtonsProps {
  children: ReactNode
  node: HTMLDivElement | null
  scrollLeft?: number
}

const CLICK_SCROLL_INCREMENT = 30
const HOVER_SCROLL_INCREMENT = 1

const HorizontalScrollingButtons: FunctionComponent<
  HorizontalScrollingButtonsProps
> = ({ children, node, scrollLeft }) => {
  const {
    overflow,
    isHovering,
    onContainerEnter,
    onContainerLeave,
    onScrollNode,
    setButtonHovering,
  } = useHorizontalScrollingButtons(node, scrollLeft)

  return (
    <div
      className="hor-scroll-btns"
      onMouseEnter={onContainerEnter}
      onMouseLeave={onContainerLeave}
    >
      {overflow && (
        <div className="hor-scroll-btns__container is-left">
          <button
            className={classNames(
              "hor-scroll-btns__btn",
              isHovering && "is-display",
            )}
            onClick={() => onScrollNode(-CLICK_SCROLL_INCREMENT)}
            onMouseEnter={() => setButtonHovering(-HOVER_SCROLL_INCREMENT)}
            onMouseLeave={() => setButtonHovering(null)}
          >
            <IconChevronLeft className="hor-scroll-btns__btn--icon" />
          </button>
        </div>
      )}
      {children}
      {overflow && (
        <div className="hor-scroll-btns__container is-right">
          <button
            className={classNames(
              "hor-scroll-btns__btn",
              isHovering && "is-display",
            )}
            onClick={() => onScrollNode(CLICK_SCROLL_INCREMENT)}
            onMouseEnter={() => setButtonHovering(HOVER_SCROLL_INCREMENT)}
            onMouseLeave={() => setButtonHovering(null)}
          >
            <IconChevronRight className="hor-scroll-btns__btn--icon" />
          </button>
        </div>
      )}
    </div>
  )
}

export default HorizontalScrollingButtons
