import { FunctionComponent, RefObject, useMemo } from "react"

import moment from "moment"

import { defineMessages, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import {
  signaturesActionCreators,
  signaturesSelectors,
} from "features/Signatures"

import Signature from "entities/Signature"

import { useTrialFinished } from "features/Trial/hooks/useTrialFinished"
import { useAutoScrollTo } from "hooks/useScroll"
import useSignatureAffectedDatas from "features/Signatures/hooks/useSignatureAffectedDatas"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import Layout from "features/Signatures/components/SignatureCardLayout"

import {
  deleteSignatureAction,
  removeSendingModeFromSignatureAction,
  toggleSignatureAction,
} from "features/Signatures/SignaturesActions"
import {
  setActiveSignature,
  setSignatureFormStep,
} from "features/Signatures/SignaturesReducer"

import { TRIAL_TUNNEL_URL } from "router/RouterConstants"
import { featureNames } from "config/config.features"

import { LoadingStatus } from "core/CoreModels"

interface SignatureCardProps {
  signature: Signature
}

const messages = defineMessages({
  newMailTooltip: {
    id: "SignatureCard.DeleteNewMailTooltip",
    defaultMessage: "Supprimer la modalité Nouveau mail",
  },
  inResponseTooltip: {
    id: "SignatureCard.DeleteReplyTooltip",
    defaultMessage: "Supprimer la modalité Mail en réponse",
  },
})

const SignatureCard: FunctionComponent<SignatureCardProps> = ({
  signature,
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const isTrialFinished = useTrialFinished()
  const signatureEditLink = isTrialFinished
    ? "/portal/signature/update"
    : `${TRIAL_TUNNEL_URL}/1`
  const { setScrollId } = useAutoScrollTo("signatures")

  const signatureId = `signature-card_${signature.Id}`

  const alternateTooltip = {
    newMail: formatMessage(messages.newMailTooltip),
    inResponse: formatMessage(messages.inResponseTooltip),
  }

  const newMailReplyFeature = useFeatureTogglr(featureNames.NEWMAIL_INRESPONSE)

  const sendingMode = useSelector(signaturesSelectors.getSendingMode)

  const alternatedeleteAction = useMemo(() => {
    if (!newMailReplyFeature?.isActive) return undefined

    if (signature.NewMail && signature.InResponse) return sendingMode

    return undefined
  }, [newMailReplyFeature, signature, sendingMode])

  const deleteSignature = (signature: Signature) =>
    dispatch(deleteSignatureAction(signature))

  const duplicateSignature = (signature) =>
    dispatch(signaturesActionCreators.duplicateSignature(signature))

  const setStep = (step: number) => dispatch(setSignatureFormStep(step))

  const { signatureRef, loadingStatus } = useSignatureAffectedDatas(
    signature.Id,
  )

  const onEdit = () => {
    setScrollId(signatureId)
    setStep(1)
    dispatch(setActiveSignature(signature))
  }

  const onAffectUsers = () => {
    setStep(2)
    dispatch(setActiveSignature(signature))
  }

  const onDelete = () => {
    if (alternatedeleteAction)
      dispatch(removeSendingModeFromSignatureAction(signature.Id, sendingMode))
    else deleteSignature(signature)
  }

  const onActivate = () => {
    dispatch(toggleSignatureAction(signature))
  }

  const onDuplicate = () => {
    duplicateSignature(signature)
  }

  return (
    <Layout
      cardRef={signatureRef as RefObject<HTMLDivElement>}
      id={signature.Id}
    >
      <Layout.Content>
        <Layout.Preview
          template={signature.Template.GetTemplateWithoutBanner()}
        />
        <Layout.Infos>
          <Layout.Name name={signature.Name} />
          {signature.Activated && (
            <Layout.Date
              type="activated"
              date={signature.ActivatedDate.format("L")}
            />
          )}
          {signature.Created !== signature.Modified && (
            <Layout.Date
              type="lastUpdate"
              date={moment(signature.Modified).format("L")}
            />
          )}
          <Layout.Toggle
            activated={signature.Activated}
            onActivate={onActivate}
          />
        </Layout.Infos>
        <Layout.InfosExpanded>
          <Layout.AffectedCount
            activated={signature.Activated}
            affectedCount={signature.AffectedUsersCount.length}
            isLoading={loadingStatus !== LoadingStatus.LOADED}
          />
          <Layout.Weighting weighting={signature.Weighting} />
        </Layout.InfosExpanded>
      </Layout.Content>
      <Layout.Actions>
        <Layout.Edit onEdit={onEdit} editLink={signatureEditLink} />
        <Layout.Assign
          onAffectUsers={onAffectUsers}
          affectUrl="/portal/signature/update"
          roundedCorner="top"
        />
        <Layout.Duplicate onDuplicate={onDuplicate} />
        <Layout.Delete
          onDelete={onDelete}
          roundedCorner="bottom"
          alternateTooltip={
            alternatedeleteAction && alternateTooltip[alternatedeleteAction]
          }
        />
      </Layout.Actions>
    </Layout>
  )
}

export default SignatureCard
