import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import { ReactComponent as SubsidiaryIcon } from "assets/icon-subsidiary.svg"
import { ReactComponent as EditIcon } from "assets/icon-edit.svg"

import SubsidiariesPill from "./SubsidiariesPill"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"
import useAssignSubsidiary from "../hooks/useAssignSubsidiary"

interface SubsidiariesCellProps {
  adminAccount: DataGridAdminAccount
}

const messages = defineMessages({
  assignment: {
    id: "SubsidiariesAdminsGrid.Assignment",
    defaultMessage: "Affecter",
  },
})

const SubsidiariesAssignmentButton = ({ onClick }) => {
  const { formatMessage } = useIntl()

  return (
    <button className="subsidiaries-assignment-btn" onClick={onClick}>
      <SubsidiaryIcon className="subsidiaries-assignment-btn__icon" />
      <p className="subsidiaries-assignment-btn__label">
        {formatMessage(messages.assignment)}
      </p>
    </button>
  )
}

const SubsidiariesAssignmentEditButton = ({ onClick }) => {
  return (
    <button className="subsidiaries-edit-assignment-btn" onClick={onClick}>
      <EditIcon className="subsidiaries-edit-assignment-btn__icon" />
    </button>
  )
}

const SubsidiariesCell: FunctionComponent<SubsidiariesCellProps> = ({
  adminAccount,
}) => {
  const { id, isNew, subsidiariesIds } = adminAccount

  const { getSubsidiariesByUserId, subsidiaries } = useSubsidiariesData()
  const handleDisplayModal = useAssignSubsidiary(adminAccount)

  if (isNew && subsidiariesIds.length === 0)
    return <SubsidiariesAssignmentButton onClick={() => handleDisplayModal()} />

  const relatedSubsidiaries = subsidiaries.filter((s) =>
    subsidiariesIds.includes(s.id),
  )

  if (isNew && subsidiariesIds.length > 0)
    return (
      <div className="subsidiaries-cell__edit-container">
        <SubsidiariesPill subsidiaries={relatedSubsidiaries} />
        <SubsidiariesAssignmentEditButton
          onClick={() => handleDisplayModal()}
        />
      </div>
    )

  const adminSubsidiaries = getSubsidiariesByUserId(id)

  return <SubsidiariesPill subsidiaries={adminSubsidiaries} />
}

export default SubsidiariesCell
