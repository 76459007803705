import { FunctionComponent, useEffect, useState } from "react"

import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { NavLink, redirect } from "react-router-dom"

import { offersSelectors } from "features/Offers"

import { ENROLLMENT_URL, HOME_PORTAL_URL } from "router/RouterConstants"

const TrialLimitationsModal: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const limitations = useSelector(offersSelectors.getTrialLimitations)

  const handleClose = () => {
    setOpen(false)
    redirect(HOME_PORTAL_URL)
  }

  useEffect(() => {
    if (limitations !== null && !open) setOpen(true)
  }, [limitations])

  if (limitations === null || !open) return null

  return (
    <div className="global-nav__overlay">
      <div
        className="global-nav__dialog-container"
        data-cy="global-nav__dialog-container"
      >
        <div className="global-nav__trial-dialog">
          <div className="global-nav__dialog-close">
            <a data-cy="global-nav__dialog-close" onClick={handleClose}>
              <i className=" material-icons">close</i>
            </a>
          </div>
          <div className="global-nav__dialog-header">
            <div
              className="global-nav__dialog-title"
              data-cy="global-nav__dialog-title"
            >
              {limitations.title}
            </div>
          </div>
          <div className="global-nav__dialog-content">
            {limitations.content}
          </div>
          <div className="global-nav__dialog-button__container">
            <NavLink
              data-cy="global-nav__dialog-button"
              onClick={handleClose}
              to={ENROLLMENT_URL}
              className="global-nav__dialog-button"
            >
              <FormattedMessage
                id="TrialDialog.Sabonner"
                defaultMessage="S'abonner"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialLimitationsModal
