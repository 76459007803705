import { createAction } from "@reduxjs/toolkit"

export const getBannersClickAction = createAction("tracking/fetchBannersClick")

export const checkTrackingJob = createAction("tracking/checkTrackingJob")

export const initQueriesDateRangeAction = createAction(
  "tracking/initQueriesDateRange",
)

export const requestSingleScenarioTrackingDataAction = createAction(
  "tracking/requestSingleScenarioTrackingData",
  function prepare(id: number) {
    return {
      payload: id,
    }
  },
)
