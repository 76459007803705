import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { groupsSelectors } from "features/Groups"

import { GlobalStates } from "store"

import { fetchAllGroups } from "features/Groups/GroupsReducer"

import { LoadingStatus } from "core/CoreModels"
import Group from "features/Groups/GroupsModels"

interface UseGroupsData {
  groups: Group[]
  loadingStatus: LoadingStatus
}

const useGroupsData = (): UseGroupsData => {
  const dispatch = useDispatch()
  const groups = useSelector(groupsSelectors.getAllGroups)
  const loadingStatus = useSelector<GlobalStates, LoadingStatus>(
    groupsSelectors.getIsLoaded,
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchAllGroups())
    }
  }, [loadingStatus, dispatch])

  return { groups, loadingStatus }
}

export default useGroupsData
