import PortalApi from "core/services/portalApi"

import { MailingContact } from "entities/MailingContact"

import { TypedResult } from "core/CoreModels"

export function checkCodePromoApi(
  codePromo: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", `/crm/v1/codepromos/${codePromo}`)
}

export function updateMailingContact(
  mailingContact: MailingContact,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/mailingcontact", { data: mailingContact })
}
