import { FunctionComponent, useEffect, useState } from "react"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import { FormattedMessage, useIntl } from "react-intl"
import HoverTooltip from "components/HoverTooltip"

const messages = {
  infos: {
    id: "Campaigns.SelectDiffusionMode.Infos",
    defaultMessage: "Message d'information...",
  },
}

const DiffusionModeSelector: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [error, setError] = useState(false)
  const { editingCampaign, updateEditingCampaign } = useScenarioFormContext()
  const { internal, external } = editingCampaign

  useEffect(() => {
    if (!internal && !external) {
      setError(true)
    }
  }, [internal, external])

  const handleCheckBox = (current, other, event) => {
    const twoCheckboxAreEmpty =
      event.target.checked == false && editingCampaign[other] == false

    if (!twoCheckboxAreEmpty)
      updateEditingCampaign({
        [current]: event.target.checked,
      })
    setError(twoCheckboxAreEmpty)
  }

  return (
    <>
      <div className="diffusion-mode-selector__title">
        <h5>
          <FormattedMessage
            id="Campaigns.SelectDiffusionMode"
            defaultMessage="Ciblage de la campagne"
          />
        </h5>
        <HoverTooltip
          level="info"
          tooltip={formatMessage(messages.infos)}
          align="right"
          className="diffusion-mode-selector__title--icon"
        >
          <i className="material-icons ">info</i>
        </HoverTooltip>
      </div>
      {error && (
        <span className="error">
          <FormattedMessage
            id="Campaigns.BroadcastedError"
            defaultMessage="Un scénario doit avoir au moins une modalité"
          />
        </span>
      )}
      <div className="diffusion-mode-selector__checkboxes">
        <div>
          <input
            className="diffusion-mode-selector__checkbox"
            type="checkbox"
            id="external"
            name="external"
            checked={external}
            onChange={(e) => handleCheckBox("external", "internal", e)}
          />
          <label htmlFor="external" className="diffusion-mode-selector__label">
            <FormattedMessage
              id="Campaigns.External"
              defaultMessage="Externe"
            />
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            className="diffusion-mode-selector__checkbox"
            id="internal"
            name="internal"
            checked={internal}
            onChange={(e) => handleCheckBox("internal", "external", e)}
          />
          <label htmlFor="internal" className="diffusion-mode-selector__label">
            <FormattedMessage
              id="Campaigns.Internal"
              defaultMessage="Interne"
            />
          </label>
        </div>
      </div>
    </>
  )
}

export default DiffusionModeSelector
