/**
 * Cypress MiddleWare
 * Ecoute la dernière action du store
 */
export const cypressMiddleware = (store) => (next) => (action) => {
  if ((window as any).Cypress && (window as any).storeSubscriber) {
    ;(window as any).storeSubscriber.publish(action.type, action)
  }

  return next(action)
}
