import { Params } from "core/services/requestBuilderService"

import { OnBoardingItems } from "./AccountsModels"

import { User } from "features/Users/UserModels"
import Signature from "entities/Signature"
import { ScenarioVm } from "features/Scenarios/ScenarioModels"
import Group from "features/Groups/GroupsModels"

export const DEMO_EXTERNAL_LINK =
  "https://hello.boostmymail.com/meetings/bmm/demo"

export const SHOWCASE_SITE_URL =
  "https://www.boostmymail.com?utm_source=portal&utm_medium=login"

export const SHOWCASE_SITE_DOMAIN_NAME = "www.boostmymail.com"

export const LAST_REGISTER_STEP = 7

export const ACCOUNT_INFOS_FIELD_MIN_LENGTH = 3

export const ACCOUNT_INFOS_FIELD_MAX_LENGTH = 254

export const GOOGLE_REDIRECT_URL = "/account/externalauthentication/Google"

export const O365_REDIRECT_URL = "/account/externalauthentication/Microsoft"

export const DEFAULT_ON_BOARDING_ITEMS: OnBoardingItems = {
  addUsers: { state: "uncompleted", date: null },
  firstSignature: { state: "uncompleted", date: null },
  firstCampaign: { state: "uncompleted", date: null },
  assignedSignature: { state: "uncompleted", date: null },
  syncedSignature: { state: "uncompleted", date: null },
}

export const ON_BOARDING_COMPLETED_DURATION_DAYS = {
  value: 7,
  unit: "d",
}

export const AT_LEAST_ONE_ACTIVE_USER_QUERY: Params<User> = {
  top: 1,
  fields: ["Id"],
  queries: {
    ActiveLicence: {
      operand: "Licence.activated",
      operator: "eq",
      value: "true",
    },
  },
}

export const AT_LEAST_ONE_ACTIVE_SIGNATURE_QUERY: Params<Signature> = {
  top: 1,
  fields: ["Id"],
  queries: {
    Activated: {
      operand: "Activated",
      operator: "eq",
      value: "true",
    },
  },
}

export const AT_LEAST_ONE_ACTIVE_BANNER_QUERY: Params<ScenarioVm> = {
  top: 1,
  fields: ["Id"],
  queries: {
    active: {
      operand: "Activated",
      operator: "eq",
      value: "true",
    },
  },
}

export const AT_LEAST_ONE_USER_ASSIGNED_TO_SIGNATURE_QUERY: Params<Signature> =
  {
    top: 1,
    fields: ["Id"],
    queries: {
      AffectedUsers: {
        operand: "UserSignatures.count",
        operator: "gte",
        value: 1,
        separator: "or",
      },
      AffectedGroups: {
        operand: "GroupSignatures.count",
        operator: "gte",
        value: 1,
      },
    },
  }

export const ALL_SIGNATURES_TILE_DATAS_QUERY: Params<Signature> = {
  fields: ["Id", "Activated", "NewMail", "InResponse"],
}

export const ALL_ACTIVES_CURRENT_CAMPAIGNS_QUERY: Params<ScenarioVm> = {
  fields: ["Id", "ActiveRange", "AssignedBannersScenario(BannerId)"],
  queries: {
    active: {
      operand: "Activated",
      operator: "eq",
      value: "true",
    },
  },
}

export const TRACKING_TILE_PERIOD_RANGE = {
  amount: 30,
  unit: "days",
}

export const TRACKING_TILE_DELIVERED_PERIOD = {
  amount: 1,
  unit: "days",
}

export const ALL_ACTIVE_USERS_COUNT_QUERY: Params<User> = {
  fields: ["Id"],
  queries: {
    ActiveLicence: {
      operand: "Licence.Activated",
      operator: "eq",
      value: "true",
    },
  },
}

export const ALL_ACTIVE_GROUPS_COUNT_QUERY: Params<Group> = {
  fields: ["Id"],
  queries: {
    Activated: {
      operand: "Activated",
      operator: "eq",
      value: "true",
    },
  },
}

export const LATEST_TEN_USERS_QUERY: Params<User> = {
  top: 10,
  fields: ["Id", "Username", "Properties(Property(InternalName),Value)"],
  orderBy: { columnKey: "created", sortBy: "desc" },
}
