import { FunctionComponent, ReactNode } from "react"

interface ScenarioCardInfosProps {
  children: ReactNode
}

export type ScenarioCardInfosType = FunctionComponent<ScenarioCardInfosProps>

const ScenarioCardInfos: ScenarioCardInfosType = ({ children }) => {
  return <div className="scenario-card__infos">{children}</div>
}

export default ScenarioCardInfos
