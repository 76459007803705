import ServiceApi from "core/services/serviceApi"
import PortalApi from "core/services/portalApi"

import { TypedResult } from "core/CoreModels"
import { FeatureResponse, FeatureRequest } from "./FeaturesModels"

/**
 * Fetch Tenant Features
 */
export function fetchTenantFeatures(): Promise<TypedResult<FeatureResponse[]>> {
  return ServiceApi.request("GET", "/tenant/syncproperties")
}

/**
 * Mets à jour la feature du tenant
 */
export function updateTenantFeature(
  feature: FeatureRequest,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("PUT", "/tenant/syncproperties", { data: feature })
}

export function checkDeployPropertiesApi<T>(data: T) {
  return PortalApi.request("POST", "/deployproperties/check", { data })
}
