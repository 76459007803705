import {
  Banner,
  BannerVm,
  BannerFormVm,
  BannerDto,
  CreateBannerDto,
} from "../ScenarioModels"

import { Result } from "shared/core/Result"

import cuid from "cuid"

export class BannerMapper {
  static toBannerFormVm(banner: Banner): BannerFormVm {
    return {
      draggableId: banner.id ?? cuid(),
      id: banner.id,
      order: banner.props.order,
      imageUrl: banner.props.imageUrl,
      width: banner.props.width,
      height: banner.props.height,
      linkUrl: banner.props.linkUrl,
      canva: {
        designId: banner.props.canvaDesignId,
        canvaUrl: null,
        designName: null,
      },
    }
  }

  static toViewModel(raw: Banner): BannerVm {
    return {
      id: raw.id,
      order: raw.props.order,
      imageUrl: raw.props.imageUrl,
      linkUrl: raw.props.linkUrl,
    }
  }

  static fromFormToEntity(raw: any): Result<Banner> {
    const instance = Banner.createFromFormData(raw)
    return instance
  }

  static toEntity(raw: any): Result<Banner> {
    const instance = Banner.create(raw)
    return instance
  }

  static toCreateDto(raw: any): CreateBannerDto {
    return {
      imageUrl: raw.props.imageUrl,
      linkUrl: raw.props.linkUrl,
      template: raw.props.template,
    }
  }

  static toDto(raw: any): BannerDto {
    return {
      id: raw.id,
      imageUrl: raw.props.imageUrl,
      linkUrl: raw.props.linkUrl,
      template: raw.props.template,
    }
  }
}
