import { FunctionComponent } from "react"

import { NavLink } from "react-router-dom"

import { ReactComponent as IconGroup } from "assets/icon-group.svg"

import Group from "features/Groups/GroupsModels"
import { GROUPS_URL } from "router/RouterConstants"

interface EditUserAssignationsPanelGroupsProps {
  associatedGroups: Group[] | null
}

const EditUserAssignationsPanelGroups: FunctionComponent<
  EditUserAssignationsPanelGroupsProps
> = ({ associatedGroups }) => {
  return (
    <div className="edit-user-assignations-panel__groups">
      {associatedGroups === null ? (
        <div className="edit-user-assignations-panel__groups--skeleton-loading" />
      ) : (
        associatedGroups.map((group) => (
          <div
            key={group.Id}
            className="edit-user-assignations-panel__groups--item"
          >
            <div className="edit-user-assignations-panel__groups--icon">
              <IconGroup />
            </div>
            <NavLink
              to={`${GROUPS_URL}/${group.Id}`}
              className="edit-user-assignations-panel__groups--name"
            >
              {group.Name}
            </NavLink>
          </div>
        ))
      )}
    </div>
  )
}

export default EditUserAssignationsPanelGroups
