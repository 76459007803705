import React, { FunctionComponent, useEffect, useState } from "react"

import { InputAdornment } from "@material-ui/core"

import InputText from "components/TextBox/InputText"

interface EditableHashtagProps {
  initialValue: string
  id: string
  disabled: boolean
  onEditHashtag: (hashtag: string, id: string) => void
  checkDuplicateHashtags: (hashtag: string, id: string) => boolean
  onDisableSave: () => void
  onEditing: () => void
}

const checkInputValue = (value: string): string | null => {
  const isValidString = value.match(/^[a-zA-Z0-9]*$/)

  if (value === "" || isValidString) {
    return value
  }
  return null
}

const EditableHashtag: FunctionComponent<EditableHashtagProps> = ({
  initialValue,
  id,
  disabled,
  onEditHashtag,
  checkDuplicateHashtags,
  onDisableSave,
  onEditing,
}) => {
  const [value, setValue] = useState(initialValue)
  const [alreadyExists, setAlreadyExists] = useState(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (value === "") {
      onDisableSave()
    }
  }, [value])

  const onChange = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement
    if (checkInputValue(value) !== null) {
      setValue(value)
      onEditing()
      const duplicate = checkDuplicateHashtags(value, id)
      if (duplicate) {
        setAlreadyExists(true)
      } else {
        setAlreadyExists(false)
      }
    }
  }

  const onBlur = () => {
    if (value !== initialValue) {
      onEditHashtag(value, id)
    }
  }

  return (
    <InputText
      value={value}
      className="sync-properties-grid__cell--input"
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            className="sync-properties-grid__cell--input-adornment"
          >
            #
          </InputAdornment>
        ),
      }}
      placeholder="[Nom de la propriété]"
      errorText={alreadyExists && "La propriété doit être unique"}
      error={alreadyExists}
      disabled={disabled}
    />
  )
}

export default EditableHashtag
