import "./FileUpload.scss"
import React from "react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import iconFileUpload from "./FileUpload.svg"

export interface FileUploadProps {
  onFileChange(file: File | FileList)
  label?: React.ReactNode
  labelAllowedFiles?: React.ReactNode
  fileTypes?: string
  disabled?: boolean
  header?: Element | React.ReactElement
  size?: "small"
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const {
    onFileChange,
    fileTypes,
    disabled,
    header,
    label,
    labelAllowedFiles,
  } = props
  const [isDragOver, setIsDragOver] = useState(false)

  const toggleOver = (isOver) => {
    setIsDragOver(isOver)
  }

  const handleDrop = (e) => {
    toggleOver(false)
    onFileChange(e.dataTransfer.files)
  }

  return (
    <div
      className={`file-upload ${isDragOver ? "file-upload--hover" : ""} ${
        disabled ? "file-upload--disabled" : ""
      }`}
      onDragLeave={() => toggleOver(false)}
      onDragOver={() => toggleOver(true)}
      onDrop={(event) => handleDrop(event)}
    >
      {header && <div className="file-upload__header">{header}</div>}
      <div className="file-upload__image__container">
        <img className="file-upload__image" src={iconFileUpload} />
      </div>
      <div className="file-upload__label">
        <div style={{ color: "#3D5AFE" }}>
          {label || (
            <FormattedMessage
              id="FileUpload.Text"
              defaultMessage="Cliquez ou déposez votre image ici"
            />
          )}
        </div>
        {labelAllowedFiles || (
          <FormattedMessage
            id="FileUpload.DropTextAlt"
            defaultMessage="(Formats jpeg, png ou gif)"
          />
        )}
      </div>
      <input
        id="file-upload"
        name="file-upload"
        type="file"
        className="file-upload__input"
        onChange={(evt) => onFileChange(evt.target.files)}
        accept={fileTypes}
      />
    </div>
  )
}

export default FileUpload
