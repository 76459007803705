import React from "react"
import Button from "@material-ui/core/Button"

interface Props {
  onClick()
}

class NotificationAction extends React.Component<Props> {
  render() {
    const { children, onClick } = this.props
    return (
      <Button size="small" className="notification__action" onClick={onClick}>
        {children}
      </Button>
    )
  }
}

export default NotificationAction
