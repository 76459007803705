import { SortOrders } from "components/PaginatedDataGrid/PaginatedDataGrid"
import { PaginatedResult, TypedResult } from "core/CoreModels"
import ServiceApi from "core/services/serviceApi"
import {
  CreateGroupRequest,
  GroupDto,
  GroupDtoWithMembersIds,
  UpdateGroupRequest,
} from "./GroupsModels"
import { RequestParams } from "core/services/requestBuilderService"

interface FetchPartialGroupsParams {
  pageSize: number
  pageNumber: number
  query: string
  orderBy: SortOrders
}

export function fetchGroupsApi(
  params: RequestParams = {},
): Promise<TypedResult<GroupDtoWithMembersIds[]>> {
  return ServiceApi.request("GET", "/groups", {
    headers: { "X-Version": 2 },
    params,
  })
}

export function fetchGroupApi(
  groupId: number,
): Promise<TypedResult<GroupDtoWithMembersIds>> {
  return ServiceApi.request("GET", `/groups/${groupId}`, {
    headers: { "X-Version": 2 },
  })
}

export function createGroupApi(
  group: CreateGroupRequest,
): Promise<TypedResult<GroupDto>> {
  return ServiceApi.request("POST", `/groups`, { data: group })
}

export function updateGroupApi(
  groupId: number,
  group: UpdateGroupRequest,
): Promise<TypedResult<GroupDto>> {
  return ServiceApi.request("PUT", `/groups/${groupId}`, { data: group })
}

export function updateGroupLicencesApi({
  groupId,
  activated,
}: {
  groupId: number
  activated: boolean
}): Promise<TypedResult<GroupDto>> {
  return ServiceApi.request("PUT", `/groups/${groupId}/activated`, {
    data: activated,
  })
}

export function deleteGroupApi(
  groupId: number,
): Promise<TypedResult<GroupDto>> {
  return ServiceApi.request("DELETE", `/groups/${groupId}`)
}

function buildOrderByString(orderBy: SortOrders) {
  return orderBy
    .map(({ columnKey, sortOrder }) => {
      return `${columnKey} ${sortOrder}`
    })
    .join(" ")
}

export function fetchPartialGroups(
  params: FetchPartialGroupsParams,
): Promise<PaginatedResult<GroupDtoWithMembersIds[]>> {
  const { pageSize, pageNumber, query, orderBy } = params

  const groupsSearchQuery = query !== "" ? `name contains ${query}` : null

  const requestConfig = {
    params: {
      pageSize,
      pageNumber,
      q: groupsSearchQuery,
      orderby: orderBy.length === 0 ? null : buildOrderByString(orderBy),
    },
    headers: { "X-Version": 2 },
  }

  return ServiceApi.paginatedRequest("GET", "/groups", requestConfig)
}
