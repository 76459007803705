import { FunctionComponent, ReactNode } from "react"

import { NavLink } from "react-router-dom"

import classNames from "classnames"

import { ReactComponent as LockIcon } from "assets/icon-lock.svg"

interface BmmNavLinkProps {
  children: ReactNode
  to: string
  dataAttributes?: Record<string, string>
  locked?: boolean
  hidden?: boolean
  isBeta?: boolean
  end?: boolean
}

const BmmNavLink: FunctionComponent<BmmNavLinkProps> = ({
  children,
  to,
  locked,
  hidden,
  dataAttributes,
  isBeta,
  end = false,
}) => {
  return (
    <NavLink
      data-cy={dataAttributes?.["data-cy"]}
      data-tour={dataAttributes?.["data-tour"]}
      to={to}
      end={end}
      className={({ isActive }) =>
        classNames(
          "nav_link_left_menu nav-link",
          isActive && "active",
          locked && "nav-link__locked",
        )
      }
      hidden={hidden}
    >
      {locked && (
        <div className="nav-link__locked--container">
          <LockIcon className="nav-link__locked--icon" />
        </div>
      )}
      {isBeta && (
        <div className="nav-link__beta--container">
          <p className="nav-link__beta--label">BETA</p>
        </div>
      )}
      {children}
    </NavLink>
  )
}

export default BmmNavLink
