import { FunctionComponent } from "react"

import classNames from "classnames"

import Carousel from "react-material-ui-carousel"

import { SingleScenarioBanner } from "../AssignationsPanel"

interface AssignationsPanelCarouselProps {
  images: SingleScenarioBanner[]
}

export type AssignationsPanelCarouselType =
  FunctionComponent<AssignationsPanelCarouselProps>

function renderimageDiv(image: SingleScenarioBanner) {
  return image.linkUrl === null ? (
    <div
      key={image.id}
      className={classNames("assignations-panel__carousel--item")}
      style={{ backgroundImage: `url(${image.url})` }}
    />
  ) : (
    <a key={image.id} target="_blank" href={image.linkUrl}>
      <div
        className={classNames("assignations-panel__carousel--item")}
        style={{ backgroundImage: `url(${image.url})` }}
      />
    </a>
  )
}

const AssignationsPanelCarousel: AssignationsPanelCarouselType = ({
  images,
}) => {
  if (images.length === 0) return null

  if (images.length === 1) return renderimageDiv(images[0])

  return (
    <Carousel
      className="assignations-panel__carousel"
      animation="slide"
      navButtonsProps={{
        className: "assignations-panel__carousel--button",
        style: {
          backgroundColor: "white",
          color: "#2AD3C2",
          opacity: 1,
        },
      }}
      autoPlay={false}
    >
      {images.map(renderimageDiv)}
    </Carousel>
  )
}

export default AssignationsPanelCarousel
