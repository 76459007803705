import { useParams } from "react-router-dom"

import { useSearchParams } from "react-router-dom"

import { ScenarioMode } from "../ScenarioModels"

export type ScenarioParams = {
  baseUrl: string
  scenarioId: number
  mode: ScenarioMode
  step: number
}

/**
 * Hook de gestion de l'url du routing
 */
export const useScenarioFromRoutes = (): ScenarioParams => {
  const [searchParams] = useSearchParams()
  const { scenarioId, step } = useParams()

  const modeValue = searchParams.get("mode")

  // baseUrl is the path until the step/
  const baseUrl = location.pathname.split("step/")[0] + "step/"

  return {
    baseUrl,
    scenarioId: scenarioId && parseInt(scenarioId),
    mode: modeValue !== "multiple" ? "simple" : "multiple",
    step: step && parseInt(step),
  }
}
