import { FunctionComponent } from "react"

interface SignatureCardNameProps {
  name: string
}

export type SignatureCardNameType = FunctionComponent<SignatureCardNameProps>

const SignatureCardName: SignatureCardNameType = ({ name }) => {
  return <div className="signature-card__infos--title">{name}</div>
}

export default SignatureCardName
