import { connect } from "react-redux"
import { signaturesSelectors } from "../"

import SignatureReadOnlyView from "./SignatureReadOnlyView"

const mapStateToProps = (state) => ({
  model: signaturesSelectors.getActiveCompiledTemplate(state),
})

const ConnectedFroalaView = connect(
  mapStateToProps,
  null,
)(SignatureReadOnlyView)

export default ConnectedFroalaView
