import { EntityAdapter, EntityId, createEntityAdapter } from "@reduxjs/toolkit"

import { LoadingStatus } from "core/CoreModels"
import { containsSameMembers } from "utils/ArrayHelper"

export type LoadingSlicesTypes<T> = undefined | "all" | T

interface LoadedSlice<T extends string> {
  id: T | "all"
  value: T | null
  loadingStatus: LoadingStatus
}

type LoadedSlicesEntities<T extends string> = {
  [key in T | "all"]: LoadedSlice<T>
}

export interface LoadingSlices<T extends string> {
  entities: LoadedSlicesEntities<T>
  ids: Array<EntityId>
  availableSlices: readonly string[]
}

const sliceAllInitialState = {
  id: "all",
  loadingStatus: LoadingStatus.NOT_STARTED,
  value: null,
}

function createLoadingSliceInitialState(adapter, availableSlices) {
  const emptyLoadingSlicesState = adapter.getInitialState({
    availableSlices,
  })

  const loadedSlices = availableSlices.reduce(
    (acc, slice: string) => [
      ...acc,
      {
        id: slice,
        value: slice,
        loadingStatus: LoadingStatus.NOT_STARTED,
      },
    ],
    [sliceAllInitialState],
  )

  return adapter.setAll(emptyLoadingSlicesState, loadedSlices)
}

function setSliceLoadingStatus<T extends string>(
  adapter,
  loadingStatus: LoadingStatus,
) {
  return function (loadingSlices, slice: T | "all") {
    const { availableSlices } = loadingSlices
    if (slice === "all") {
      return adapter.updateMany(loadingSlices, [
        ...[...availableSlices, "all"].map((slice) => ({
          id: slice,
          changes: { loadingStatus },
        })),
      ])
    }
    return adapter.updateOne(loadingSlices, {
      id: slice,
      changes: { loadingStatus },
    })
  }
}

function allSlicesAreLoaded(adapter, currentSlice, loadingSlices) {
  const currentLoadedSlices = adapter
    .getSelectors()
    .selectAll(loadingSlices)
    .filter(
      (slice) =>
        slice.id !== "all" &&
        slice.id !== currentSlice &&
        slice.loadingStatus === LoadingStatus.LOADED,
    )
    .map((slice) => slice.id)

  return containsSameMembers(loadingSlices.availableSlices, [
    ...currentLoadedSlices,
    currentSlice,
  ])
}

function setSliceLoadedStatus<T extends string>(adapter) {
  return function (loadingSlices, slice: T | "all") {
    const loadingStatus = LoadingStatus.LOADED

    if (slice === "all" || allSlicesAreLoaded(adapter, slice, loadingSlices)) {
      return adapter.updateMany(loadingSlices, [
        ...[...loadingSlices.availableSlices, "all"].map((slice) => ({
          id: slice,
          changes: { loadingStatus },
        })),
      ])
    }
    return adapter.updateOne(loadingSlices, {
      id: slice,
      changes: { loadingStatus },
    })
  }
}

function createLoadingSlicesStatesFactory(adapter) {
  const resetSliceLoadingStatus = setSliceLoadingStatus(
    adapter,
    LoadingStatus.NOT_STARTED,
  )
  const setSliceToPending = setSliceLoadingStatus(
    adapter,
    LoadingStatus.PENDING,
  )
  const setSliceToError = setSliceLoadingStatus(adapter, LoadingStatus.ERROR)
  const setSliceToLoaded = setSliceLoadedStatus(adapter)

  return {
    resetSliceLoadingStatus,
    setSliceToPending,
    setSliceToError,
    setSliceToLoaded,
  }
}

function createLoadingSlicesSelectorsFactory<T extends string>(
  adapter: EntityAdapter<LoadedSlice<T>>,
) {
  return function <S>(selectState) {
    return adapter.getSelectors<S>(selectState)
  }
}

export function createLoadingSlicesAdapter<T extends string>(
  availableSlices: readonly string[],
) {
  const loadingSliceAdapter = createEntityAdapter<LoadedSlice<T>>({
    selectId: (slice) => slice.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id),
  })

  const getInitialState = () =>
    createLoadingSliceInitialState(loadingSliceAdapter, availableSlices)

  return {
    getInitialState,
    ...createLoadingSlicesStatesFactory(loadingSliceAdapter),
    getSelectors: createLoadingSlicesSelectorsFactory(loadingSliceAdapter),
  }
}
