import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import { useCurrentAccountAccess } from "features/Accounts/hooks/useCurrentAccountAccess"

import {
  ENROLLMENT_URL,
  LOGIN_URL,
  SUBSCRIPTION_URL,
} from "router/RouterConstants"

import { RolesNeeded } from "router/routes"
import BellButton from "features/Alerts/components/BellButton"

interface Props {
  isTrial: boolean
  expanded: boolean
  closeMenu()
  openMenu()
  resetTour()
  logout()
}

const MenuUser: React.FC<Props> = ({
  isTrial,
  expanded,
  closeMenu,
  openMenu,
  resetTour,
  logout,
}) => {
  const [isExpanded, setIsExpended] = useState(expanded)
  const { hasAccessTo } = useCurrentAccountAccess()
  useEffect(() => {
    setIsExpended(expanded)
  }, [expanded])

  const renderIcon = () => {
    if (!isExpanded) return "expand_more"
    return "expand_less"
  }

  const handleNavClick = () => {
    closeMenu()
  }

  const handleLogout = () => {
    closeMenu()
    logout()
  }

  const handleResetTour = () => {
    closeMenu()
    resetTour()
  }

  const handleExpandMenu = () => {
    const expanded = !isExpanded
    if (expanded) openMenu()
    else closeMenu()
  }

  const getCssClass = () => {
    if (expanded) return "menu-user__nav"
    return "menu-user__nav hide"
  }

  return (
    <>
      <div className="menu-user">
        <div
          data-cy="menu-user_button"
          className="menu-user__button"
          onClick={() => handleExpandMenu()}
        >
          <i className="material-icons">{renderIcon()}</i>
        </div>

        <ul className={getCssClass()}>
          {isTrial && (
            <li className="menu-user__nav__item">
              <Link to={ENROLLMENT_URL} onClick={() => handleNavClick()}>
                <div className="menu-user__nav__item__text">
                  <FormattedMessage
                    id="MenuUser.MenuSubscription"
                    defaultMessage="Abonnement"
                  />
                </div>
              </Link>
            </li>
          )}

          {!isTrial && (
            <li
              className="menu-user__nav__item"
              hidden={!hasAccessTo(RolesNeeded.BILLING_PAGE).isActive}
            >
              <Link to={SUBSCRIPTION_URL} onClick={() => handleNavClick()}>
                <div className="menu-user__nav__item__text">
                  <FormattedMessage
                    id="MenuUser.MenuSubscription"
                    defaultMessage="Abonnement"
                  />
                </div>
              </Link>
            </li>
          )}

          {!isTrial && (
            <li className="menu-user__nav__item">
              <Link
                data-cy="menu-user__launch-tourguide"
                to={LOGIN_URL}
                onClick={() => handleResetTour()}
              >
                <div className="menu-user__nav__item__text">
                  <FormattedMessage
                    id="MenuUser.ResetTour"
                    defaultMessage="Lancer la visite guidée"
                  />
                </div>
              </Link>
            </li>
          )}
        </ul>
      </div>
      <BellButton />
      <Link
        to={LOGIN_URL}
        onClick={() => handleLogout()}
        className="menu-user_logout-link"
      >
        <div className="menu-user_logout">
          <i className="material-icons menu-user__icon">power_settings_new</i>
        </div>
      </Link>
    </>
  )
}

export default MenuUser
