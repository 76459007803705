import React from "react"
import { IntlShape, injectIntl, defineMessages } from "react-intl"
import "./UserPropertiesPanel.scss"

import Grid from "components/Grid"
import CheckboxCard from "components/CheckboxCard"
import { UserProperty } from "features/Users/UserModels"

interface Props {
  properties: UserProperty[]
  toggleProperty?: (prop: UserProperty) => void
  intl: IntlShape
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = defineMessages({
  city: { id: "UserPropertiesPanel.City", defaultMessage: "Ville" },
  company: { id: "UserPropertiesPanel.Company", defaultMessage: "Entreprise" },
  country: { id: "UserPropertiesPanel.Country", defaultMessage: "Pays" },
  department: {
    id: "UserPropertiesPanel.Department",
    defaultMessage: "Service",
  },
  firstname: { id: "UserPropertiesPanel.Firstname", defaultMessage: "Prénom" },
  jobtitle: { id: "UserPropertiesPanel.Jobtitle", defaultMessage: "Poste" },
  office: { id: "UserPropertiesPanel.Office", defaultMessage: "Bureau" },
  mail: { id: "UserPropertiesPanel.Mail", defaultMessage: "Mail" },
  mobile: { id: "UserPropertiesPanel.Mobile", defaultMessage: "Mobile" },
  postalcode: {
    id: "UserPropertiesPanel.Postalcode",
    defaultMessage: "Code Postal",
  },
  streetaddress: {
    id: "UserPropertiesPanel.Streetaddress",
    defaultMessage: "Adresse",
  },
  service: { id: "UserPropertiesPanel.Service", defaultMessage: "Service" },
  lastname: { id: "UserPropertiesPanel.Lastname", defaultMessage: "Nom" },
  phone: { id: "UserPropertiesPanel.Phone", defaultMessage: "Téléphone" },
  banner: { id: "UserPropertiesPanel.Banner", defaultMessage: "Bannière" },
  website: { id: "UserPropertiesPanel.Website", defaultMessage: "Site web" },
  disclaimer: {
    id: "UserPropertiesPanel.Disclaimer",
    defaultMessage: "Disclaimer",
  },
  usermessage: {
    id: "UserPropertiesPanel.Usermessage",
    defaultMessage: "Message utilisateur",
  },
  picture: { id: "UserPropertiesPanel.Picture", defaultMessage: "Photo" },
})

class UserPropertiesPanel extends React.Component<Props> {
  handleClick = (propertyId) => {
    this.props.toggleProperty(
      this.props.properties.find((prop) => prop.Id === propertyId),
    )
  }

  render() {
    const { properties, intl } = this.props

    // Gestion de la traduction
    const intlProperties =
      properties &&
      properties.map((prop) => {
        const intlId =
          "UserPropertiesPanel." +
          prop.InternalName[0].toUpperCase() +
          prop.InternalName.slice(1).toLowerCase()
        const intlDisplay = intl.formatMessage({ id: intlId })
        if (intlId !== intlDisplay)
          // si la traduction existe pas,
          prop.DisplayName = intlDisplay
        return prop
      })

    return (
      <div className="up-panel">
        {properties && (
          <div>
            <Grid>
              {intlProperties.map((prop, idx) => (
                <CheckboxCard
                  key={idx}
                  type="checkbox"
                  checked={prop.Visible}
                  onClick={() => this.handleClick(prop.Id)}
                >
                  #{prop.DisplayName}
                </CheckboxCard>
              ))}
            </Grid>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(UserPropertiesPanel)
