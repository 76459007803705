import "./FileUploadInfo.scss"
import React from "react"

interface Props {
  file: File
  onCancel()
  imageFile?: string
}

const FileUploadInfo: React.FC<Props> = (props) => {
  const { file, onCancel, imageFile } = props

  return (
    <div className="file-upload-info">
      {imageFile && (
        <div className="file-upload-info__image__container">
          <img className="file-upload-info__image" src={imageFile} />
        </div>
      )}
      <div className="file-upload-info__file">
        <span className="file-upload-info__file__name">{file.name}</span>
        <span className="file-upload-info__file__size">
          {(file.size / (1024 * 1024)).toFixed(2)}MB
        </span>
      </div>
      <div className="file-upload-info__cancel" onClick={onCancel}>
        <i className="material-icons" style={{ color: "#E5284C" }}>
          cancel
        </i>
      </div>
    </div>
  )
}

export default FileUploadInfo
