import { FunctionComponent, memo } from "react"

import moment from "moment"

import { defineMessages, useIntl } from "react-intl"

import { ResponsiveLine } from "@nivo/line"

import useGraphAxisConfig from "features/Tracking/hooks/useGraphAxisConfig"

import TrackingGraphTooltip from "features/Tracking/components/TrackingGraphTooltip"

import {
  PRIMARY_GRAPH_COLOR,
  PRIMARY_GRAPH_LIGHT_COLORS,
} from "features/Tracking/TrackingConstants"

interface SignaturesPowerUsersGraphProps {
  data: Array<{ date: string; quantity: number }>
}

const messages = defineMessages({
  graphId: {
    id: "Tracking.SignaturesPowerUsersGraphId",
    defaultMessage: "clics par jour",
  },
})

const SignaturesPowerUsersGraph: FunctionComponent<
  SignaturesPowerUsersGraphProps
> = ({ data }) => {
  const { formatMessage } = useIntl()

  const mappedData = [
    {
      id: formatMessage(messages.graphId),
      label: formatMessage(messages.graphId),
      display: true,
      hover: false,
      selected: false,
      data: data
        .map((click) => ({
          x: click.date,
          y: click.quantity,
        }))
        .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
      color: PRIMARY_GRAPH_COLOR,
      secondaryColor: PRIMARY_GRAPH_LIGHT_COLORS,
    },
  ]

  const { yScaleMax, axisLeftTickValues, axisBottomTickValues, measuredRef } =
    useGraphAxisConfig(mappedData)

  return (
    <div className="sign-power-users-graph" ref={measuredRef}>
      <ResponsiveLine
        margin={{ top: 5, right: 5, left: 30, bottom: 25 }}
        data={mappedData}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          useUTC: false,
          precision: "day",
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: "linear",
          min: 0,
          max: yScaleMax,
        }}
        enableArea={true}
        areaOpacity={0.1}
        axisLeft={{
          tickValues: axisLeftTickValues,
        }}
        axisBottom={{
          format: (date) => moment(date).format("D MMM"),
          tickValues: axisBottomTickValues,
        }}
        curve={"monotoneX"}
        enableSlices={false}
        colors={["#3D5AFE"]}
        useMesh={true}
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        animate={true}
        theme={{
          textColor: "#00000061",
          fontSize: 9,
        }}
        tooltip={({ point }) => <TrackingGraphTooltip point={point} />}
      />
    </div>
  )
}

export default memo(SignaturesPowerUsersGraph)
