import StringHelper from "utils/StringHelper"

export class CodePromo {
  Code: string
  DateOfUse: string
  Percentdiscount: number // Remise en %
  Amountdiscount: number // Remise en €
  Valuediscount: number // Remise avantage (ex. 1 bannière offerte d'une valeur de 200€)
  Valid: boolean

  constructor(json) {
    for (const prop in json) {
      this[StringHelper.Capitalize(prop)] = json[prop]
    }
    // un code promo est valide par défaut
    this.Valid = true
  }
}

export default CodePromo
