import classnames from "classnames"

interface Props {
  full: boolean
  withSubTitle: boolean
}

export const CenteredLayout: React.FC<Props> = ({ children, full, withSubTitle }) => (
  <div
    className={classnames("l-centered", {
      "l-withSubTitle": withSubTitle,
      "l-full": full,
    })}
  >
    {children}
  </div>
)