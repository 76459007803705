import classNames from "classnames"

import { isFunction } from "utils/FunctionHelper"

import { ColumnConfig } from "../PaginatedDataGrid"

interface PaginatedDataGridCellsProps<T> {
  column: ColumnConfig<T>
  data: T[]
  rowIndex: number
  colIndex: number
  rowData: T
  renderLoading: boolean
  isLoading?: boolean
}

function renderValue<T>(
  column: ColumnConfig<T>,
  data: T[],
  rowIndex: number,
  rowData: T,
  isLoading,
  renderLoading,
): React.ReactNode | null {
  if (isLoading && renderLoading)
    return <div className="data-grid__skeleton--cell" />

  if (!column.value || (isLoading && !renderLoading)) return null

  if (isFunction(column.value)) return column.value(data, rowData, rowIndex)

  return rowData[column.value]
}

export default function PaginatedDataGridCell<T>({
  column,
  data,
  rowIndex,
  colIndex,
  rowData,
  renderLoading,
  isLoading,
}: PaginatedDataGridCellsProps<T>): JSX.Element {
  return (
    <td
      data-cy="datagrid__td"
      className={classNames("data-grid__td", column?.cellsClassName)}
      key={colIndex}
      data-row={rowIndex}
      data-col={colIndex}
    >
      {renderValue(column, data, rowIndex, rowData, isLoading, renderLoading)}
    </td>
  )
}
