import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { AvailableUTMParameters, UTMs } from "./MarketingModels"

interface MarketingState {
  allUTMs: UTMs
}

const initialState: MarketingState = {
  allUTMs: {},
}

const marketingReducer = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    addUTM(
      state,
      action: PayloadAction<{ utm: AvailableUTMParameters; value: string }>,
    ) {
      const { utm, value } = action.payload
      state.allUTMs[utm] = value
    },
    removeUTM(state, action: PayloadAction<AvailableUTMParameters>) {
      delete state.allUTMs[action.payload]
    },
    resetUTM(state) {
      state.allUTMs = {}
    },
  },
})

export const { addUTM, removeUTM, resetUTM } = marketingReducer.actions

export default marketingReducer.reducer
