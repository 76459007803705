import { FunctionComponent } from "react"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import registerPanelCardImg from "features/Accounts/assets/register-illustration.webp"

const messages = defineMessages({
  cardTextMiddle: {
    id: "RegisterPage.CardTextMiddle",
    defaultMessage:
      "Puis abonnez-vous pour la synchroniser et utiliser toutes les fonctionnalités",
  },
})

const RegisterLeftPanelCard: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="register-left-panel__card">
      <p className="register-left-panel__card--text">
        <FormattedMessage
          id="RegisterPage.CardTextTop"
          defaultMessage="Créez une signature mail en <cta>10 minutes</cta> seulement, c'est sans engagement."
          values={{
            cta: (chunks: string) => (
              <span className="register-left-panel__card--text time">
                {chunks}
              </span>
            ),
          }}
        />
      </p>
      <p className="register-left-panel__card--text">
        {formatMessage(messages.cardTextMiddle)}
      </p>
      <img
        className="register-left-panel__card--img"
        src={registerPanelCardImg}
        alt="logos clients"
      />
    </div>
  )
}

export default RegisterLeftPanelCard
