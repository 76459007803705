import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

const messages = defineMessages({
  infos: {
    id: "Tracking.SignaturesPowerUsersGraphInsufficientsDatasInformations",
    defaultMessage: "En attente de données suffisantes",
  },
})

const SignaturesPowerUsersGraphInsufficientsDatas: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="sign-power-users-graph-insuff">
      <div className="sign-power-users-graph-insuff__infos">
        <InfoIcon className="sign-power-users-graph-insuff__infos--icon" />
        <p className="sign-power-users-graph-insuff__infos--label">
          {formatMessage(messages.infos)}
        </p>
      </div>
    </div>
  )
}

export default SignaturesPowerUsersGraphInsufficientsDatas
