import { FunctionComponent, ReactNode } from "react"

import { ReactComponent as IconChevronLeft } from "assets/icon-chevron-left.svg"
import { ReactComponent as IconChevronRight } from "assets/icon-chevron-right.svg"

interface LoginSliderNavProps {
  current: ReactNode
  last: number
  onPrevious: () => void
  onNext: () => void
}

const LoginSliderNav: FunctionComponent<LoginSliderNavProps> = ({
  current,
  last,
  onPrevious,
  onNext,
}) => {
  return (
    <div className="login-slider__nav">
      <button
        type="button"
        className="login-slider__nav--button"
        disabled={current === 0}
        onClick={onPrevious}
      >
        <IconChevronLeft />
      </button>
      <button
        type="button"
        className="login-slider__nav--button"
        disabled={current === last}
        onClick={onNext}
      >
        <IconChevronRight />
      </button>
    </div>
  )
}

export default LoginSliderNav
