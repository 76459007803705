/**
 * Groups SELECTORS
 */
import { GlobalStates } from "store"
import Group from "features/Groups/GroupsModels"
import { createSelector } from "reselect"
import { signaturesSelectors } from "features/Signatures"
import { orderBy, uniqBy } from "lodash"

import { LoadingStatus, PaginationData, SavingStatus } from "core/CoreModels"
import {
  selectAllGroups,
  selectAllPartialGroups,
  selectGroupById,
  selectPartialGroupById,
} from "./GroupsReducer"
import { User } from "features/Users/UserModels"
import { RootState } from "store/configureStore"

const getLicencedGroups = (state: GlobalStates): Group[] =>
  selectAllGroups(state).filter((group: Group) => group.Activated)

const getSearchString = (state: GlobalStates): string =>
  state.groups.partialGroups.search.toLowerCase()

const getExcludeAffectedGroups = (
  groups: Group[],
  groupsToExclude: Group[],
  searchString: string,
) => {
  const isNotExclude = (group: Group) =>
    groupsToExclude.find(
      (excludeGroup: Group) => excludeGroup.Id === group.Id,
    ) == null
  if (searchString !== "") {
    groups = groups.filter(
      (group: Group) =>
        (group.Name && group.Name.toLowerCase().indexOf(searchString) !== -1) ||
        (group.Name === undefined &&
          group.InternalName.toLowerCase().indexOf(searchString) !== -1),
    )
  }

  return orderBy(groups.filter(isNotExclude), "Id")
}

const getActiveGroupsWithoutAffectedUsers = createSelector(
  [
    getLicencedGroups,
    signaturesSelectors.getActiveSignatureAffectedGroups,
    getSearchString,
  ],
  (groups, affectedGroups, searchString) =>
    getExcludeAffectedGroups(groups, affectedGroups, searchString),
)

const getLoadingStatus = (state: GlobalStates): boolean =>
  state.groups.allGroups.loadingStatus === LoadingStatus.PENDING

const getMembers = (state: GlobalStates, groupId) =>
  selectGroupById(state, groupId)?.Members

const getAllMembersForGroups = (
  state: GlobalStates,
  groupIds: number[],
): User[] => {
  return uniqBy(
    groupIds
      .map((id) => getMembers(state, id))
      .flat()
      .filter((u) => u != null),
    "Id",
  )
}

const getIsLoaded = (state: GlobalStates): LoadingStatus =>
  state.groups.allGroups.loadingStatus

const getPartialGroupsPaginationData = (
  state: GlobalStates,
): PaginationData => {
  const {
    currentPage,
    lastPage,
    pageSize,
    orderBy,
    search,
    loadingStatus,
    totalCount,
  } = state.groups.partialGroups

  return {
    currentPage,
    lastPage,
    pageSize,
    orderBy,
    search: search.toLowerCase(),
    loadingStatus,
    totalCount,
  }
}

const getEditingGroup = (state: GlobalStates): Group =>
  state.groups.editingGroup.entity

const getEditingGroupStatuses = (
  state: RootState,
): { loadingStatus: LoadingStatus; savingStatus: SavingStatus } => {
  const { loadingStatus, savingStatus } = state.groups.editingGroup
  return { loadingStatus, savingStatus }
}

const getEditingGroupAssignedSignaturesLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.groups.editingGroup.assignedSignaturesLoadingStatus

export const selectors = {
  getAllGroups: selectAllGroups,
  getGroupById: selectGroupById,
  getLicencedGroups,
  getActiveGroupsWithoutAffectedUsers,
  getLoadingStatus,
  getSearchString,
  getAllMembersForGroups,
  getIsLoaded,
  getAllPartialGroups: selectAllPartialGroups,
  getPartialGroupsById: selectPartialGroupById,
  getPartialGroupsPaginationData,
  getEditingGroup,
  getEditingGroupStatuses,
  getEditingGroupAssignedSignaturesLoadingStatus,
}

export default selectors
