import { FunctionComponent } from "react"

interface EmptyPageAlertProps {
  alert: string
}

export type EmptyPageAlertType = FunctionComponent<EmptyPageAlertProps>

const EmptyPageAlert: EmptyPageAlertType = ({ alert }) => {
  return (
    <div className="empty-page-cta__alert">
      <i className="empty-page-cta__alert--icon material-icons">help_outline</i>
      <div>{alert}</div>
    </div>
  )
}

export default EmptyPageAlert
