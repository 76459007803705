import { FunctionComponent } from "react"

import Layout from "features/Signatures/components/SignatureCardLayout"

const SignaturesListLayoutLoadingSkeleton: FunctionComponent = () => {
  return (
    <>
      {[1, 2, 3].map((id) => (
        <Layout id={id} key={id}>
          <Layout.Content>
            <div className="signatures-list-layout__skeleton--preview" />
            <Layout.Infos>
              <div className="signatures-list-layout__skeleton--name" />
              <div className="signatures-list-layout__skeleton--date" />
              <div className="signatures-list-layout__skeleton--toggle" />
            </Layout.Infos>
            <Layout.AffectedCount
              activated={false}
              affectedCount={0}
              isLoading={true}
            />
          </Layout.Content>
          <Layout.Actions>
            <Layout.Edit onEdit={() => null} editLink="" disabled />
            <Layout.Assign onAffectUsers={() => null} affectUrl="" disabled />
            <Layout.Duplicate onDuplicate={() => null} />
            <Layout.Delete onDelete={() => null} disabled />
          </Layout.Actions>
        </Layout>
      ))}
    </>
  )
}

export default SignaturesListLayoutLoadingSkeleton
