import { useEffect, useState } from "react"

import useGroupsData from "features/Groups/hooks/useGroupsData"

import { LoadingStatus } from "core/CoreModels"
import Group from "features/Groups/GroupsModels"

type UseSingleUserGroupsAssignation = Group[] | null

const useSingleUserGroupsAssignation = (
  userId: number,
): UseSingleUserGroupsAssignation => {
  const [userAffectations, setUserAffectations] = useState<Group[] | null>(null)
  const { groups, loadingStatus } = useGroupsData()

  useEffect(() => {
    if (loadingStatus === LoadingStatus.LOADED) {
      setUserAffectations(
        groups.filter((group) => group.MembersId.includes(userId)),
      )
    }
  }, [loadingStatus, groups])

  return userAffectations
}

export default useSingleUserGroupsAssignation
