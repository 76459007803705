// ----------------
// SELECTORS  - These are functions for filtered signatures / AdProps.

import { map, orderBy } from "lodash"
import { GlobalStates } from "store"
import Signature from "entities/Signature"
import Template, { TemplateMode } from "entities/Template"
import DataTag from "entities/DataTag"
import {
  SignaturesActivatedStateFilter,
  signaturesSelectors,
} from "features/Signatures"
import { LoadingStatus } from "core/CoreModels"
import { selectAllUsers } from "features/Users/UsersReducers"
import { User } from "features/Users/UserModels"
import {
  SignaturesSlices,
  selectAllSignatures,
  selectAllSignaturesIds,
  selectAllTemplatesFromGallery,
  selectAllTemplatesIdsFromGallery,
  selectSignatureById,
  selectSignatureSliceById,
  selectSignaturesCustomQueryById,
  selectTemplateFromGalleryById,
} from "./SignaturesReducer"

import { SendingModes, Size } from "./SignaturesModels"
import LogoTag from "entities/LogoTag"
import Group from "features/Groups/GroupsModels"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getActiveCompiledTemplate = (state) => {
  const activeSignature = getActiveSignature(state)
  return activeSignature.Template.compiledContent
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getActiveSignature = (state: GlobalStates) => {
  const activeSignatureId = state.signatures.activeSignatureId
  // on reconstruit la signature du State car lorsque cette dernière est créée coté SSR, elle est ensuite stockée sous forme de plain object JS, donc sans ses méthodes.
  if (activeSignatureId == null)
    return new Signature(state.signatures.newSignature)
  return selectSignatureById(state, activeSignatureId)
}

const getActiveSignatureTemplate = (state: GlobalStates): Template => {
  const activeSignatureId = state.signatures.activeSignatureId
  // on reconstruit la signature du State car lorsque cette dernière est créée coté SSR, elle est ensuite stockée sous forme de plain object JS, donc sans ses méthodes.
  if (activeSignatureId == null)
    return new Signature(state.signatures.newSignature).Template
  return selectSignatureById(state, activeSignatureId).Template
}

const getActiveTemplateDataTags = (state: GlobalStates): DataTag[] | null => {
  const activeSignature = getActiveSignature(state)
  if (activeSignature.Template)
    return map(activeSignature.Template.dataTags).filter(
      (datatag: DataTag) => datatag.name !== "logo",
    )

  return null
}

const getSignatureForActiveBanner = (state: GlobalStates): Signature[] => {
  const signatures = selectAllSignatures(state)
  const activeSignature = signaturesSelectors.getActiveSignature(state)

  if (activeSignature !== null)
    signatures.find((signature) => signature.Id === activeSignature.Id)
  return signatures
}

const getUsersByIds = (state, userIds) =>
  selectAllUsers(state).filter((user) => userIds.indexOf(user.Id) !== -1)

const getActiveSignatureAffectedUsers = (state: GlobalStates): Array<User> => {
  const allUsers = getUsersByIds(
    state,
    getActiveSignature(state).AffectedUsers.map((user) => user.Id),
  )
  return orderBy(allUsers, ["Properties.lastname"], ["asc"])
}

const getActiveSignatureAffectedUsersIds = (state: GlobalStates): number[] =>
  getActiveSignature(state).AffectedUsersIds

/**
 * Retourne les groupes du state dont l'Id est contenu dans le tableau passé en paramètre.
 */
const getGroupsByIds = (state, groupIds) =>
  state.groups.allGroups.ids
    .map((groupId) => state.groups.allGroups.entities[groupId])
    .filter((group) => groupIds.indexOf(group.Id) !== -1)

/**
 * Retourne les groupes affectés à la signature courante.
 */
const getActiveSignatureAffectedGroups = (state: GlobalStates): Group[] => {
  const activeSignature: Signature = getActiveSignature(state)
  const allGroups = getGroupsByIds(
    state,
    activeSignature.AffectedGroups.map((group) => group.Id),
  )
  return orderBy(allGroups, ["DispayName"], ["asc"])
}

const getActiveSignatureAffectedGroupsIds = (state: GlobalStates): number[] =>
  getActiveSignature(state).AffectedGroupsIds

const getActiveSignatureLogoUrl = (state: GlobalStates): string => {
  const activeSignature = getActiveSignature(state)
  if (activeSignature.Template.GetLogoUrl != null)
    return activeSignature.Template.GetLogoUrl()
}

const getLogoSizes = (state: GlobalStates) => {
  const activeSignature = getActiveSignature(state)
  return {
    size: { ...activeSignature.Template.dataTags.logo.size },
    maxSize: { ...activeSignature.Template.dataTags.logo.maxSize },
    idealSize: { ...activeSignature.Template.dataTags.logo.idealSize },
  }
}

const getLogoDataTag = (state: GlobalStates): LogoTag[] => {
  const activeSignature = getActiveSignature(state)
  if (activeSignature.Template) return [activeSignature.Template.dataTags.logo]
}

const getActivatedStateFilterState = (
  state: GlobalStates,
): SignaturesActivatedStateFilter => {
  return state.signatures.allSignatures.activatedStateFilter
}

const getSignaturesFromActivatedStateFilter = (
  state: GlobalStates,
): Signature[] => {
  const signatures = selectAllSignatures(state)
  const filterState = getActivatedStateFilterState(state)

  const filteredSignatures = {
    [SignaturesActivatedStateFilter.ALL]: signatures,
    [SignaturesActivatedStateFilter.SHOW_ACTIVATED]: signatures.filter(
      (signature) => signature.Activated === true,
    ),
    [SignaturesActivatedStateFilter.SHOW_DESACTIVATED]: signatures.filter(
      (signature) => signature.Activated === false,
    ),
  }

  return filteredSignatures[filterState]
}

const getActiveSignatureId = (state: GlobalStates): number | null =>
  state.signatures.activeSignatureId

const getActiveSignatureAffectedGroupsLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.signatures.newSignature?.AffectedGroupsLoaded

const getActiveSignatureAffectedUsersLoadingStatus = (
  state: GlobalStates,
): LoadingStatus => state.signatures.newSignature?.AffectedUsersLoaded

const getSignatureAffectedGroupsLoadingStatus = (
  state: GlobalStates,
  signatureId: number,
): LoadingStatus => selectSignatureById(state, signatureId).AffectedGroupsLoaded

const getSignatureAffectedUsersLoadingStatus = (
  state: GlobalStates,
  signatureId: number,
): LoadingStatus => selectSignatureById(state, signatureId).AffectedUsersLoaded

const getSignatureAffectUsersCountLoadingStatus =
  (signatureId: number) =>
  (state: GlobalStates): LoadingStatus =>
    selectSignatureById(state, signatureId).AffectUsersCountLoadingStatus

const getSignatureAffectUsersCount =
  (signatureId: number) =>
  (state: GlobalStates): number =>
    selectSignatureById(state, signatureId).AffectedUsersCount.length

const getSignatureAffectedUsers =
  (signatureId: number) =>
  (state: GlobalStates): number[] =>
    selectSignatureById(state, signatureId).AffectedUsersCount

const getDefaultTemplate = (state: GlobalStates): Template =>
  selectAllTemplatesFromGallery(state)[0]

const getSelectedTemplate = (state: GlobalStates): Template =>
  selectTemplateFromGalleryById(state, state.signatures.selectedTemplateId)

const getTemplateMode = (state: GlobalStates): TemplateMode =>
  state.signatures.templateMode

const getTemplateColor = (state: GlobalStates): string =>
  state.signatures.templateColor

const getCurrentStep = (state: GlobalStates): number =>
  state.signatures.currentStep

const getActiveSignatureBannerId = (state: GlobalStates): number[] =>
  state.signatures.activeSignatureBannerId

const getSignatureName = (state: GlobalStates): string =>
  state.signatures.signatureName

const getSignatureNameError = (state: GlobalStates): boolean =>
  state.signatures.signatureNameError

const getEditingSignatureNewMailValue = (state: GlobalStates) =>
  state.signatures.editingSignature.newMail

const getEditingSignatureInResponseValue = (state: GlobalStates) =>
  state.signatures.editingSignature.inResponse

const getEditingSignatureWeightingValue = (state: GlobalStates) =>
  state.signatures.editingSignature.weighting

const getEmailPreviewSent = (state: GlobalStates): boolean =>
  state.signatures.emailPreviewSent

const isLogoUploaded = (state: GlobalStates): boolean =>
  state.signatures.logo.isUploaded

const getLogoMaxSize = (state: GlobalStates): Size =>
  getLogoSizes(state).maxSize

const getLogoIdealSize = (state: GlobalStates): Size =>
  getLogoSizes(state).idealSize

const getLogoSize = (state: GlobalStates): Size => getLogoSizes(state).size

// Used by calendarview
const getAffectedUsersLoadingStatus = (state: GlobalStates): boolean =>
  state.signatures.affectedUsersLoaded

const getActivatedSignaturesIds = (state: GlobalStates): number[] =>
  selectAllSignatures(state)
    .filter((signature) => signature.Activated)
    .map((signature) => signature.Id)

const getSignaturesAffectedToGroup =
  (groupId: number) =>
  (state: GlobalStates): Signature[] =>
    selectAllSignatures(state).filter(
      (signature) =>
        signature.AffectedGroupsLoaded === LoadingStatus.LOADED &&
        signature.AffectedGroupsIds.includes(groupId),
    )

const getSignaturesSliceLoadingStatus =
  (slice: "all" | SignaturesSlices) => (state: GlobalStates) =>
    selectSignatureSliceById(state, slice).loadingStatus

const getSendingMode = (state: GlobalStates): SendingModes =>
  state.signatures.allSignatures.sendingMode

const getSignaturesCustomQueryResultById =
  (requestId: string) => (state: GlobalStates) => {
    const customQueryEntity = selectSignaturesCustomQueryById(state, requestId)

    if (!customQueryEntity) return null

    return customQueryEntity.result
  }

export const selectors = {
  // signature selectors
  getAllSignatures: selectAllSignatures,
  getSignatureById: selectSignatureById,
  getAllSignaturesIds: selectAllSignaturesIds,
  getSignatureForActiveBanner,
  getActiveSignatureId,
  getActiveSignature,
  getActiveSignatureTemplate,
  getActiveSignatureAffectedUsers,
  getActiveSignatureAffectedUsersIds,
  getActiveSignatureAffectedGroups,
  getActiveSignatureAffectedGroupsIds,
  getSignatureAffectUsersCountLoadingStatus,
  getSignatureAffectUsersCount,
  getActiveSignatureLogoUrl,
  getActiveCompiledTemplate,
  getActiveSignatureBannerId,
  getTemplateMode,
  getTemplateColor,
  getSignaturesFromActivatedStateFilter,
  getActivatedStateFilterState,
  getCurrentStep,
  getSignatureName,
  getSignatureNameError,
  // template selectors
  getLogoDataTag,
  isLogoUploaded,
  getActiveTemplateDataTags,
  getDefaultTemplate,
  getSelectedTemplate,
  getLogoMaxSize,
  getLogoSize,
  getLogoIdealSize,
  getTemplateAllIds: selectAllTemplatesIdsFromGallery,
  getTemplateById: selectTemplateFromGalleryById,
  getAllTemplates: selectAllTemplatesFromGallery,
  // Email
  getEmailPreviewSent,
  getAffectedUsersLoadingStatus,
  getActiveSignatureAffectedGroupsLoadingStatus,
  getActiveSignatureAffectedUsersLoadingStatus,
  getSignatureAffectedGroupsLoadingStatus,
  getSignatureAffectedUsersLoadingStatus,
  getActivatedSignaturesIds,
  getSignaturesAffectedToGroup,
  getSignaturesSliceLoadingStatus,
  getSendingMode,
  getEditingSignatureNewMailValue,
  getEditingSignatureInResponseValue,
  getEditingSignatureWeightingValue,
  getSignatureAffectedUsers,
  getSignaturesCustomQueryResultById,
}
