import { FunctionComponent } from "react"

import { defineMessages, FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"

import { trialActionCreators } from "features/Trial"

import AdditionalInformations from "features/Users/components/AdditionalInformations"
import TrialSignatureCreation from "./2.TrialSignatureCreation"
import SignaturePrevisualisation from "./3.SignaturePrevisualisation"
import Advertising from "./7.Advertising"

interface TrialSteps {
  step: string | undefined
  init: () => void
  setTrialStep: (step: number) => void
  sendSignatureMail: (withBanner: boolean) => void
}

export const trialSteps = ({
  step,
  init,
  setTrialStep,
  sendSignatureMail,
}: TrialSteps) => {
  if (!step) return <TrialSignatureCreation />

  switch (parseInt(step)) {
    case 1:
      init()
      setTrialStep(1)
      return <AdditionalInformations />
    case 2:
      return <TrialSignatureCreation />
    case 3:
      setTrialStep(5)
      sendSignatureMail(false) // envoi sans la bannière
      return <SignaturePrevisualisation />
    case 4:
      setTrialStep(7)
      return <Advertising />
    default:
      return <TrialSignatureCreation />
  }
}

export const trialBreadCrumbMessages = defineMessages({
  TrialBreadCrumbStep1: {
    id: "TrialTunnel.TrialBreadCrumbStep1",
    defaultMessage: "Personnaliser",
  },
  TrialBreadCrumbStep2: {
    id: "TrialTunnel.TrialBreadCrumbStep2",
    defaultMessage: "Sélectionner",
  },
  TrialBreadCrumbStep3: {
    id: "TrialTunnel.TrialBreadCrumbStep3",
    defaultMessage: "Prévisualiser",
  },
  TrialBreadCrumbStep4: {
    id: "TrialTunnel.TrialBreadCrumbStep4",
    defaultMessage: "Synchroniser",
  },
})

const TrialTunnelContext: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { step } = useParams()

  const init = () => {
    dispatch(trialActionCreators.initTrial())
  }

  const setTrialStep = (trialStep: number) => {
    dispatch(trialActionCreators.setTrialStep(trialStep))
  }

  const sendSignatureMail = (withBanner: boolean) => {
    dispatch(trialActionCreators.sendTrialSignaturePreviewByMail(withBanner))
  }

  return (
    <section className="l-2-columns h-100">
      <section className="l-sub-header">
        <h4>
          <FormattedMessage
            id="TrialTunnel.Title"
            defaultMessage="Ma nouvelle signature mail"
          />
        </h4>
        <div className="l-sub-header__liseret"></div>
      </section>
      <section className="l-trial-tunnel">
        {trialSteps({
          step: step || "1",
          init,
          setTrialStep,
          sendSignatureMail,
        })}
      </section>
    </section>
  )
}

export default TrialTunnelContext
