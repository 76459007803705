import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { withSagaErrorHandler } from "features/Errors"

import {
  deleteImageFromStorage,
  fetchAllImages as fetchAllImagesApi,
  uploadImageToStorage,
} from "./StorageApi"

import {
  fetchAllImages,
  fetchAllImagesFailure,
  fetchAllImagesSuccess,
  uploadImageSuccess,
  deleteImageSuccess,
} from "./StorageReducers"

import {
  StorageImage,
  StorageImageDto,
  StorageMetadatas,
  StorageTags,
} from "./StorageModels"
import { TypedResult } from "core/CoreModels"
import { PayloadAction } from "@reduxjs/toolkit"
import {
  deleteImageFromStorageAction,
  uploadImageToStorageAction,
} from "./StorageActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getAllStorageImagesAsync(
  action: PayloadAction<Array<string>>,
) {
  const response: TypedResult<StorageImageDto[]> = yield call(
    fetchAllImagesApi,
    action.payload,
  )

  if (!response.success) throw new Error(response.errorMessage)

  const storageImagesJson = response.result
  const storageImages = storageImagesJson.map(
    (imageJson) => new StorageImage(imageJson),
  )

  yield put(fetchAllImagesSuccess(storageImages))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* uploadStorageImageAsync(
  action: PayloadAction<{
    file: File
    tags?: StorageTags
    metadatas?: StorageMetadatas
  }>,
) {
  const { file, tags, metadatas } = action.payload

  const response: TypedResult<StorageImageDto> = yield call(
    uploadImageToStorage,
    file,
    tags,
    metadatas,
  )

  if (!response.success) throw new Error(response.errorMessage)

  const uploadedImage = new StorageImage(response.result)

  yield put(uploadImageSuccess(uploadedImage))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* deleteImageFromStorageAsync(action: PayloadAction<string>) {
  const response: TypedResult<StorageImageDto> = yield call(
    deleteImageFromStorage,
    action.payload,
  )

  if (!response.success) throw new Error(response.errorMessage)

  yield put(deleteImageSuccess(action.payload))
}

export const storageWatchers = [
  takeLatest(
    fetchAllImages.type,
    withSagaErrorHandler(getAllStorageImagesAsync, fetchAllImagesFailure()),
  ),
  takeEvery(
    uploadImageToStorageAction.type,
    withSagaErrorHandler(uploadStorageImageAsync),
  ),
  takeEvery(
    deleteImageFromStorageAction.type,
    withSagaErrorHandler(deleteImageFromStorageAsync),
  ),
]
