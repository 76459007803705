import React from "react"
import SelectField from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"

interface State {
  error: boolean
}

interface Props {
  value: string
  name?: string
  id?: string
  onChange(evt)
  mandatory?: boolean
  defaultMessage?: string
  label: string
  disabled?: boolean
  fullWidth?: boolean
  errorText?: string
}

class Select extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  handleChange = (evt) => {
    if (
      this.props.mandatory &&
      (this.props.value === "" ||
        this.props.value === this.props.defaultMessage)
    )
      this.setState({ error: true })
    if (
      this.props.mandatory &&
      this.props.value !== "" &&
      this.props.value !== this.props.defaultMessage
    )
      this.setState({ error: false })
    this.props.onChange(evt)
  }

  render() {
    const { error } = this.state
    const { errorText, label, id, name, value, fullWidth, disabled } =
      this.props

    return (
      <FormControl className="bmm-select" variant="outlined">
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <SelectField
          value={value}
          onChange={this.handleChange}
          inputProps={{
            name: name,
            id: id,
          }}
          autoWidth={fullWidth}
          disabled={disabled}
        >
          {this.props.children}
        </SelectField>
        <FormHelperText error={error}>{errorText}</FormHelperText>
      </FormControl>
    )
  }
}

export default Select
