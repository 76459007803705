import { FunctionComponent } from "react"
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

import { useIntl } from "react-intl"

import Layout from "../pages/shared/Layout"

import NoMatch from "pages/public/NoMatch"

import { RestrictedRoute } from "router/components"

import { Roles } from "features/Accounts/AccountsModels"

import publicRoutes from "router/routes/publicRoutes"

import privateRoutes from "./routes/privateRoutes"
import limitedByOfferRoutes from "./routes/limitedByOfferRoutes"

export const RolesNeeded = {
  ADMIN_PAGE: [Roles.BmmAdmin, Roles.TenantAdmin],
  PARAMETERS_PAGE: [Roles.TechnicalAdmin, Roles.BmmAdmin, Roles.TenantAdmin],
  SCENARIOS_PAGE: [Roles.ScenariosAdmin, Roles.BmmAdmin],
  USERS_PAGE: [Roles.UsersAdmin, Roles.BmmAdmin],
  SUBSCRIPTION_PAGE: [Roles.BillingAdmin, Roles.BmmAdmin],
  BILLING_PAGE: [Roles.BillingAdmin, Roles.BmmAdmin],
  GROUP_PAGE: [Roles.UsersAdmin, Roles.BmmAdmin],
  SIGNATURES_PAGE: [Roles.SignaturesAdmin, Roles.BmmAdmin],
  TRACKING_PAGE: [Roles.TrackingAdmin, Roles.BmmAdmin],
  CAMPAIGNS_PAGE: [Roles.ScenariosAdmin, Roles.BmmAdmin],
  SUBSIDIARIES_PAGE: [Roles.SubsidiariesAdmin, Roles.BmmAdmin],
  TRACKING_TILE_HOME_PAGE: [Roles.SubsidiariesAdmin, Roles.TenantAdmin],
  GLOBAL_NAV: [
    Roles.BmmAdmin,
    Roles.ScenariosAdmin,
    Roles.SignaturesAdmin,
    Roles.TechnicalAdmin,
    Roles.TenantAdmin,
    Roles.TrackingAdmin,
    Roles.UsersAdmin,
  ],
  ALL_ROLES: [
    Roles.BillingAdmin,
    Roles.BmmAdmin,
    Roles.ScenariosAdmin,
    Roles.SignaturesAdmin,
    Roles.TechnicalAdmin,
    Roles.TenantAdmin,
    Roles.TrackingAdmin,
    Roles.UsersAdmin,
  ],
}

function includeLayout(element) {
  return <Layout>{element}</Layout>
}

const GlobalRoutes: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {publicRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={includeLayout(route.element)}
          />
        ))}
        {privateRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={includeLayout(
              <RestrictedRoute
                authentified
                accessRoles={RolesNeeded[route.accessRoles]}
                {...route?.limitations}
              >
                {route.element}
              </RestrictedRoute>,
            )}
          >
            {(route.children || []).map((childRoute) => (
              <Route
                key={childRoute.key}
                path={childRoute.path}
                element={childRoute.element}
              />
            ))}
          </Route>
        ))}
        {limitedByOfferRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={includeLayout(
              <RestrictedRoute
                authentified
                accessRoles={RolesNeeded[route.accessRoles]}
                {...route?.limitations}
                limitationTitle={formatMessage(route.limitationTitle)}
                limitationContent={formatMessage(route.limitationContent)}
              >
                {route.element}
              </RestrictedRoute>,
            )}
          >
            {(route.children || []).map((childRoute) => (
              <Route
                key={childRoute.key}
                path={childRoute.path}
                element={childRoute.element}
              />
            ))}
          </Route>
        ))}
        <Route path="*" element={includeLayout(<NoMatch />)} />
      </>,
    ),
  )
  return <RouterProvider router={router} />
}

export default GlobalRoutes
