import React from "react"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"
import NavigationButtons from "../Components/NavigationButtons"
import CouponCode from "../Components/CouponCode"
import { formatAmount } from "utils/NumberHelper"

import "./EnrollmentStep.scss"
import iconOffer from "features/Enrollment/assets/icon-offer.svg"
import visualGift from "features/Enrollment/assets/visual-gift.svg"
import iconPackBasic from "features/Enrollment/assets/visual-rocket-alt.svg"
import iconPackPremium from "features/Enrollment/assets/visual-rocket.svg"

import {
  DEFAULT_CATALOG_OFFER_BASIC_NAME,
  DEFAULT_CATALOG_OFFER_PREMIUM_NAME,
  DEFAULT_OFFER_CURRENCY,
} from "features/Offers/OffersConstants"
import { computeDiscountAmount } from "features/Enrollment/EnrollmentRules"

const messages = defineMessages({
  ht: {
    id: "Enroll.HT",
    defaultMessage: "HT",
  },
  ttc: {
    id: "Enroll.TTC",
    defaultMessage: "TTC",
  },
  title: {
    id: "Enroll.Step3Title",
    defaultMessage: "Récapitulatif",
  },
  offer: {
    id: "Enroll.Step3Offer",
    defaultMessage: "Offre",
  },
})

function getOfferIcon(offerName: string | null) {
  switch (offerName) {
    case DEFAULT_CATALOG_OFFER_BASIC_NAME:
      return iconPackBasic
    case DEFAULT_CATALOG_OFFER_PREMIUM_NAME:
    default:
      return iconPackPremium
  }
}

const EnrollmentStep3: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const { enrollmentDatas, setEnrollmentDatas, checkCoupon } =
    useEnrollmentData()

  const { price, subTotal, total, totalVAT, currentStep, discount } =
    enrollmentDatas

  const _ht = formatMessage(messages.ht)
  const _ttc = formatMessage(messages.ttc)

  const handleCouponCode = (code: string) => {
    checkCoupon(code)
  }

  const savePrices = () => {
    const nextStep = currentStep + 1
    setEnrollmentDatas({ total, totalVAT })
    setEnrollmentDatas({ currentStep: nextStep })
  }

  return (
    <div className="enroll__step enroll_step3">
      <div className="enroll__box enroll__main">
        <div className="enroll__form enroll__recap">
          <h2>
            <span>3</span>
            {formatMessage(messages.title)}
          </h2>
          <div className="enroll__recap__line ">
            <div className="enroll__recap__label">
              <img
                className=""
                src={getOfferIcon(enrollmentDatas?.selectedOffer.name)}
                alt={`${formatMessage(messages.offer)} ${
                  enrollmentDatas?.selectedOffer.name || ""
                }`}
                style={{ float: "left", maxHeight: "3em" }}
              />
              <label>
                <FormattedMessage
                  id="Enroll.Step4Plan"
                  defaultMessage="Abonnement :"
                />
              </label>
              {`${formatMessage(messages.offer)} ${
                enrollmentDatas?.selectedOffer.name || ""
              }`}
            </div>
            <div className="enroll__recap__price">
              {formatAmount(price, locale, DEFAULT_OFFER_CURRENCY.name)}
              <small> {_ht}</small>
            </div>
          </div>
          {enrollmentDatas.options.length != 0 && (
            <div className="enroll__recap__line enroll__recap__line--option">
              <div className="enroll__recap__label">
                <label>
                  <FormattedMessage
                    id="Enroll.Step4Options"
                    defaultMessage="Options :"
                  />
                </label>
              </div>
            </div>
          )}
          {enrollmentDatas.options.map((option) => (
            <div
              key={option.Id}
              className="enroll__recap__line enroll__recap__line--option"
            >
              <div className="enroll__recap__label">{option.Name}</div>
              <div className="enroll__recap__price">
                {formatAmount(
                  option.getPricingFromLicencesNumber(enrollmentDatas.nbUsers),
                  locale,
                  DEFAULT_OFFER_CURRENCY.name,
                )}
                <small> {_ht}</small>
              </div>
            </div>
          ))}
          <hr />
          <div className="enroll__recap__line enroll__recap__line--subtotal">
            <div className="enroll__recap__label">
              <FormattedMessage
                id="Enroll.Step4Subtotal"
                defaultMessage="Sous-total :"
              />
            </div>
            <div className="enroll__recap__price">
              {formatAmount(subTotal, locale, DEFAULT_OFFER_CURRENCY.name)}
              <small> {_ht}</small>
            </div>
          </div>
          {discount && (
            <div className="enroll__recap__line enroll__recap__line--offer">
              <div className={"enroll__recap__label"}>
                <div>
                  <img src={iconOffer} alt="" />
                  {discount.label}
                  <small>
                    {" "}
                    <FormattedMessage
                      id="Enroll.Step4Value"
                      defaultMessage="d'une valeur de"
                    />{" "}
                    {formatAmount(
                      computeDiscountAmount(subTotal, discount.percent),
                      locale,
                      DEFAULT_OFFER_CURRENCY.name,
                    )}
                  </small>
                </div>
              </div>
              <div className="enroll__recap__price">
                -
                {formatAmount(
                  computeDiscountAmount(subTotal, discount.percent),
                  locale,
                  DEFAULT_OFFER_CURRENCY.name,
                )}
              </div>
            </div>
          )}
          <hr />
          <div className="enroll__recap__line enroll__recap__line--total">
            <div className="enroll__recap__label">
              <FormattedMessage
                id="Enroll.Step4Total"
                defaultMessage="Total :"
              />
            </div>
            <div className="enroll__recap__price">
              {`${formatAmount(
                total,
                locale,
                DEFAULT_OFFER_CURRENCY.name,
              )} ${_ht}`}
              <small>
                <br />
                {`${formatAmount(
                  totalVAT,
                  locale,
                  DEFAULT_OFFER_CURRENCY.name,
                )} ${_ttc}`}
              </small>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <NavigationButtons onNext={savePrices} />
        </div>
      </div>
      <div className="enroll__box enroll__aside enroll__aside__step3">
        <div className="enroll__aside__title">
          <FormattedMessage id="Enroll.StepSide3a" defaultMessage="Code" />
        </div>
        <div className="enroll__aside__subtitle">
          <FormattedMessage id="Enroll.StepSide3b" defaultMessage="promo" />
        </div>
        <img src={visualGift} alt="" />
        <div className="enroll__aside__input">
          <CouponCode
            error={enrollmentDatas.discountError}
            onSubmit={handleCouponCode}
          />
        </div>
      </div>
    </div>
  )
}

export default EnrollmentStep3
