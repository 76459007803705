/**
 * Tour - représente un tourJS
 */
class Tour {
  id: number
  name: string
  currentStep: number
  steps: number
  title: string

  constructor(jsonObject) {
    for (const prop in jsonObject) {
      this[prop] = jsonObject[prop]
    }
  }

  isComplete() {
    // la step courante est basée sur un index, le steps est un nombre
    return this.currentStep + 1 >= this.steps
  }
}

export default Tour
