import { FunctionComponent } from "react"

import { Link } from "react-router-dom"

import Button from "components/Button"

interface EmptyPageCardProps {
  title: string
  subtitle: string
  image: string
  imgAlt: string
  link: string
  to: string
}

export type EmptyPageCardType = FunctionComponent<EmptyPageCardProps>

const EmptyPageCard: EmptyPageCardType = ({
  title,
  subtitle,
  image,
  imgAlt,
  link,
  to,
}) => {
  return (
    <Link to={to}>
      <div className="empty-page-cta__card">
        <div>
          <div className="empty-page-cta__card--title">{title}</div>
          <div className="empty-page-cta__card--subtitle">{subtitle}</div>
        </div>
        <div className="empty-page-cta__card--image">
          <img src={image} alt={imgAlt} />
        </div>
        <div>
          <Button label={link} />
        </div>
      </div>
    </Link>
  )
}

export default EmptyPageCard
