import { FunctionComponent } from "react"

import classNames from "classnames"

import { ReactComponent as CupIcon } from "assets/icon-cup.svg"
import { ReactComponent as IconImage } from "assets/icon-picture.svg"

interface EmptyPodiumItemProps {
  type: "gold" | "silver" | "bronze"
  largeAvatar?: boolean
  isLoading?: boolean
}

const EmptyPodiumItem: FunctionComponent<EmptyPodiumItemProps> = ({
  type,
  largeAvatar,
  isLoading,
}) => {
  return (
    <div className={classNames(`podium__${type}`)}>
      <div className="podium__icon">
        <CupIcon className={classNames(type)} />
      </div>
      <div>
        <div className="podium__empty-infos">
          <div
            className={classNames(
              "podium__empty-infos--thumbnail",
              largeAvatar && "is-large",
              isLoading && "is-loading",
            )}
          >
            <IconImage
              className={classNames(
                "podium__empty-infos--icon",
                !largeAvatar && "is-small",
              )}
            />
          </div>
          <div
            className={classNames(
              "podium__empty-infos--name",
              isLoading && "is-loading",
            )}
          />
          <div
            className={classNames(
              "podium__empty-infos--actions",
              isLoading && "is-loading",
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default EmptyPodiumItem
