import React from "react"
import { Scrollbars } from "react-custom-scrollbars-2"
import {
  SignaturePropertiesSideBar,
  SignaturePropertiesView,
} from "features/Signatures/SignatureForm/1.SignatureProperties"

import { VerticalBreadCrumb } from "components/BreadCrumb"
import { injectIntl, IntlShape } from "react-intl"
import { trialBreadCrumbMessages } from "../TrialTunnelContext"

interface Props {
  intl: IntlShape
}

class TrialSignatureCreationForm extends React.Component<Props> {
  public scrollbar
  constructor(props) {
    super(props)
    this.scrollbar = React.createRef()
  }

  render() {
    const { formatMessage } = this.props.intl
    const breadCrumbNodes = [
      {
        number: 1,
        name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep1),
      },
      {
        number: 2,
        name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep2),
      },
      {
        number: 3,
        name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep3),
      },
      {
        number: 4,
        name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep4),
      },
    ]

    return (
      <section className="l-2-columns__content">
        <aside className="l-2-columns__aside-content">
          <Scrollbars ref={this.scrollbar}>
            <SignaturePropertiesSideBar />
          </Scrollbars>
        </aside>
        <main className="l-2-columns__main-content">
          {/* Main */}
          <section className="multistep__main-content">
            <Scrollbars>
              <SignaturePropertiesView
                nextNavLink={"/portal/trial/3"}
                prevNavLink={"/portal/trial/1"}
                onSignatureSaved={() => null}
              />
            </Scrollbars>
          </section>
        </main>
        <div className="breadcrumb-container breadcrumb-container__sigcreation">
          <VerticalBreadCrumb nodes={breadCrumbNodes} step={2} />
        </div>
      </section>
    )
  }
}

export default injectIntl(TrialSignatureCreationForm)
