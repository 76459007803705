import { ChangeEventHandler, useReducer } from "react"

import {
  SELLSY_CLIENT_ID_NAME,
  SELLSY_CLIENT_SECRET_NAME,
} from "features/Features/FeaturesConstants"

import Feature from "features/Features/FeaturesModels"

export enum SellsyActionsTypes {
  ChangeSellsyClientIdValue = "CHANGE_SELLSY_CLIENT_ID_VALUE",
  DisplaySellsyClientIdError = "DISPLAY_SELLSY_CLIENT_ID_ERROR",
  ChangeSellsyClientSecretValue = "CHANGE_SELLSY_CLIENT_SECRET_VALUE",
  DisplaySellsyClientSecretError = "DISPLAY_SELLSY_CLIENT_SECRET_ERROR",
  ResetInputsValues = "RESET_INPUTS_VALUES",
}

export interface ChangeSellsyClientIdValue {
  type: SellsyActionsTypes.ChangeSellsyClientIdValue
  payload: string
}

export interface DisplaySellsyClientIdError {
  type: SellsyActionsTypes.DisplaySellsyClientIdError
  payload: boolean
}

export interface ChangeSellsyClientSecretValue {
  type: SellsyActionsTypes.ChangeSellsyClientSecretValue
  payload: string
}

export interface DisplaySellsyClientSecretError {
  type: SellsyActionsTypes.DisplaySellsyClientSecretError
  payload: boolean
}

export interface ResetInputsValues {
  type: SellsyActionsTypes.ResetInputsValues
}

export type SellsyActionType =
  | ChangeSellsyClientIdValue
  | DisplaySellsyClientIdError
  | ChangeSellsyClientSecretValue
  | DisplaySellsyClientSecretError
  | ResetInputsValues

interface SellsyFormStateInput {
  name: string
  value: string
  error: boolean
}

export interface SellsyFormState {
  sellsyClientId: SellsyFormStateInput
  sellsyClientSecret: SellsyFormStateInput
}

interface UseSellsyFeature {
  sellsyFormState: SellsyFormState
  changeClientId: ChangeEventHandler<HTMLInputElement>
  changeClientSecret: ChangeEventHandler<HTMLInputElement>
  displaySellsyClientIdError: (errorValue: boolean) => void
  displaySellsyClientSecretError: (errorValue: boolean) => void
  resetInputsValues: () => void
}

const formInitialState: SellsyFormState = {
  sellsyClientId: {
    name: SELLSY_CLIENT_ID_NAME,
    value: "",
    error: false,
  },
  sellsyClientSecret: {
    name: SELLSY_CLIENT_SECRET_NAME,
    value: "",
    error: false,
  },
}

function reducer(state, action: SellsyActionType) {
  switch (action.type) {
    case SellsyActionsTypes.ChangeSellsyClientIdValue:
      return {
        ...state,
        sellsyClientId: {
          ...state.sellsyClientId,
          value: action.payload,
        },
      }
    case SellsyActionsTypes.DisplaySellsyClientIdError:
      return {
        ...state,
        sellsyClientId: {
          ...state.sellsyClientId,
          error: action.payload,
        },
      }
    case SellsyActionsTypes.ChangeSellsyClientSecretValue:
      return {
        ...state,
        sellsyClientSecret: {
          ...state.sellsyClientSecret,
          value: action.payload,
        },
      }
    case SellsyActionsTypes.DisplaySellsyClientSecretError:
      return {
        ...state,
        sellsyClientSecret: {
          ...state.sellsyClientSecret,
          error: action.payload,
        },
      }
    case SellsyActionsTypes.ResetInputsValues:
      return {
        ...state,
        sellsyClientId: {
          ...state.sellsyClientId,
          value: "",
        },
        sellsyClientSecret: {
          ...state.sellsyClientSecret,
          value: "",
        },
      }
    default:
      throw new Error()
  }
}

const initializer = (featureSellsy: Feature) => (initialState) => {
  const sellsyClientIdValue =
    featureSellsy.properties.find((p) => p.property === SELLSY_CLIENT_ID_NAME)
      .value || ""

  const sellsyClientSecretValue =
    featureSellsy.properties.find(
      (p) => p.property === SELLSY_CLIENT_SECRET_NAME,
    ).value || ""

  return {
    ...initialState,
    sellsyClientId: {
      ...initialState.sellsyClientId,
      value: sellsyClientIdValue,
    },
    sellsyClientSecret: {
      ...initialState.sellsyClientSecret,
      value: sellsyClientSecretValue,
    },
  }
}

const useSellsyFeature = (featureSellsy: Feature): UseSellsyFeature => {
  const [sellsyFormState, dispatch] = useReducer(
    reducer,
    formInitialState,
    initializer(featureSellsy),
  )

  const changeClientId: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target

    dispatch({
      type: SellsyActionsTypes.ChangeSellsyClientIdValue,
      payload: value,
    })
  }

  const changeClientSecret: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target

    dispatch({
      type: SellsyActionsTypes.ChangeSellsyClientSecretValue,
      payload: value,
    })
  }

  const displaySellsyClientIdError = (errorValue: boolean) =>
    dispatch({
      type: SellsyActionsTypes.DisplaySellsyClientIdError,
      payload: errorValue,
    })

  const displaySellsyClientSecretError = (errorValue: boolean) =>
    dispatch({
      type: SellsyActionsTypes.DisplaySellsyClientSecretError,
      payload: errorValue,
    })

  const resetInputsValues = () =>
    dispatch({ type: SellsyActionsTypes.ResetInputsValues })

  return {
    sellsyFormState,
    changeClientId,
    changeClientSecret,
    displaySellsyClientIdError,
    displaySellsyClientSecretError,
    resetInputsValues,
  }
}

export default useSellsyFeature
