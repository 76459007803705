import { FunctionComponent, useMemo, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import Toggle from "components/MUIToggle"

import { ReactComponent as SearchIcon } from "assets/icon-search.svg"
import { ReactComponent as CloseIcon } from "assets/icon-close.svg"
import { ReactComponent as UserIcon } from "assets/icon-user.svg"

import { UserFromHistory } from "features/Tracking/TrackingModels"

interface SignatureDeploymentUsersProps {
  users: UserFromHistory[]

  top: number
  left: number
}

interface DeployedUser extends UserFromHistory {
  fullName: string
}

const messages = defineMessages({
  title: {
    id: "Tracking.SignaturesDeployment",
    defaultMessage: "Déploiement de signature",
  },
  search: {
    id: "Tracking.SignaturesDeploymentUsersSearch",
    defaultMessage: "Rechercher un utilisateur",
  },
  toggle: {
    id: "Tracking.SignaturesDeploymentToggleLabel",
    defaultMessage: "Non déployés",
  },
})

const relativeOffset = { top: 15, left: 10 }

function getFullName(user: UserFromHistory) {
  if (!user?.firstName || !user?.lastName) return user.userName

  return `${user.firstName} ${user.lastName}`
}

function filterDeployedPredicate(showUnDeployedOnly: boolean) {
  return function (user: DeployedUser) {
    return showUnDeployedOnly ? user.delivered === false : true
  }
}

function filterSearchedPredicate(searchValue: string) {
  return function (user: DeployedUser) {
    if (searchValue.length === 0) return true
    return user.fullName
      .toLocaleLowerCase()
      .includes(searchValue.toLocaleLowerCase())
  }
}

const SignatureDeploymentUsers: FunctionComponent<
  SignatureDeploymentUsersProps
> = ({ users, top, left }) => {
  const { formatMessage } = useIntl()

  const [searchValue, setSearchValue] = useState("")
  const [showUnDeployedOnly, setShowUnDeployedOnly] = useState(true)

  const allUsers: DeployedUser[] = useMemo(
    () =>
      users
        .map((u) => ({
          ...u,
          fullName: getFullName(u),
        }))
        .filter(filterDeployedPredicate(showUnDeployedOnly))
        .filter(filterSearchedPredicate(searchValue)),
    [users, showUnDeployedOnly, searchValue],
  )

  return (
    <div
      className="sign-depl-users"
      style={{
        top: `${top + relativeOffset.top}px`,
        left: `${left - relativeOffset.left}px`,
      }}
    >
      <div className="sign-depl-users__header">
        <p className="sign-depl-users__header--title">
          {formatMessage(messages.title)}
        </p>
        <div className="sign-depl-users__header--toggle">
          <Toggle
            checked={showUnDeployedOnly}
            onChange={() => setShowUnDeployedOnly((prevState) => !prevState)}
          />
          <p className="sign-depl-users__header--toggle-label">
            {formatMessage(messages.toggle)}
          </p>
        </div>
      </div>
      <div className="sign-depl-users__search">
        <input
          className="sign-depl-users__search--input"
          placeholder={formatMessage(messages.search)}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {searchValue.length > 0 ? (
          <CloseIcon
            className="sign-depl-users__search--icon"
            onClick={() => setSearchValue("")}
          />
        ) : (
          <SearchIcon className="sign-depl-users__search--icon" />
        )}
      </div>
      <div className="sign-depl-users__users">
        {allUsers.map((user) => (
          <div className="sign-depl-users__users--container" key={user.userId}>
            <UserIcon className="sign-depl-users__users--icon" />
            <p
              className={classNames(
                "sign-depl-users__users--label",
                user.delivered && "is-deployed",
              )}
            >
              {user.fullName}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SignatureDeploymentUsers
