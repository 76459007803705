import { FunctionComponent, useMemo, useState } from "react"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import Layout from "features/Scenarios/components/ScenariosListLayout"
import ScenarioCard from "features/Scenarios/components/ScenarioCard"
import ScenariosListEmpty from "features/Scenarios/components/ScenariosListEmpty"

import { ScenarioVm } from "features/Scenarios/ScenarioModels"

import {
  DiffusionModes,
  FilterValuesType,
  SortValuesType,
  defaultFilterValue,
  defaultSortValue,
  processList,
} from "features/Scenarios/components/ScenariosListLayout/scenariosListLayoutUtilities"

import { featureNames } from "config/config.features"
import { LoadingStatus } from "core/CoreModels"

import useDiffusionMode from "features/Scenarios/hooks/useDiffusionMode"
import { useSlicedScenariosDatas } from "features/Scenarios/hooks/useSlicedScenariosDatas"
import useScenariosCustomqueryData from "features/Scenarios/hooks/useScenariosCustomqueryData"

import { AT_LEAST_ONE_SCENARIO_QUERY } from "features/Scenarios/ScenarioConstants"

function searchScenariosByName(scenario: ScenarioVm) {
  return scenario.name.toLowerCase().includes(this.searchValue.toLowerCase())
}

const filterMethods = {
  scenario: (scenario: ScenarioVm) => scenario.isMultiple() === true,
  unique: (scenario: ScenarioVm) => scenario.isMultiple() === false,
}

const sortMethods = {
  alphabeticalOrder: (a: ScenarioVm, b: ScenarioVm) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
  creationDate: (a: ScenarioVm, b: ScenarioVm) =>
    a.created > b.created ? -1 : a.created < b.created ? 1 : 0,
  priority: (a: ScenarioVm, b: ScenarioVm) =>
    a.weighting > b.weighting ? -1 : a.weighting < b.weighting ? 1 : 0,
}

const filterByDiffusionModeMethods = {
  internal: (scenario: ScenarioVm) => scenario.internal === true,
  external: (scenario: ScenarioVm) => scenario.external === true,
}

const ScenariosList: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("")
  const [filter, setFilter] = useState<FilterValuesType>(defaultFilterValue)
  const [sort, setSort] = useState<SortValuesType>(defaultSortValue)

  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)
  const { diffusionMode } = useDiffusionMode()

  const { scenarios, loadingStatus, deleteScenario, activateScenario } =
    useSlicedScenariosDatas()

  const atLeastOneScenario = useScenariosCustomqueryData(
    AT_LEAST_ONE_SCENARIO_QUERY,
  )

  const handleSearch = (searchValue) => {
    setSearch(searchValue)
  }

  const handleChangeFilter = (filterValue) => {
    setFilter(filterValue)
  }

  const handleChangeSort = (selectedOption) => {
    setSort(selectedOption.value)
  }

  const scenariosList = useMemo(() => {
    const commonProcessors = {
      search,
      filter,
      sort,
      searchMethod: searchScenariosByName,
      sortMethods,
      filterMethods,
    }

    const currentDiffusionMode: DiffusionModes =
      diffusionMode.internal === true ? "internal" : "external"

    const internalExternalProcessor = internalExternalFeature?.isActive
      ? { diffusionMode: currentDiffusionMode, filterByDiffusionModeMethods }
      : undefined

    return processList(scenarios, {
      ...commonProcessors,
      ...internalExternalProcessor,
    })
  }, [scenarios, search, filter, sort, diffusionMode])

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <Layout>
        <Layout.SubHeader
          filter={filter}
          handleSearch={handleSearch}
          handleChangeFilter={handleChangeFilter}
          handleChangeSort={handleChangeSort}
        />
        <Layout.Content>
          <Layout.LoadingSkeleton />
        </Layout.Content>
      </Layout>
    )

  if (
    loadingStatus === LoadingStatus.LOADED &&
    scenarios.length === 0 &&
    atLeastOneScenario?.length === 0
  )
    return <ScenariosListEmpty />

  if (loadingStatus === LoadingStatus.LOADED && scenariosList.length === 0)
    return (
      <Layout>
        <Layout.SubHeader
          filter={filter}
          handleSearch={handleSearch}
          handleChangeFilter={handleChangeFilter}
          handleChangeSort={handleChangeSort}
        />
        <Layout.Content>
          <Layout.EmptyResults />
        </Layout.Content>
      </Layout>
    )

  return (
    <Layout>
      <Layout.SubHeader
        filter={filter}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        handleChangeSort={handleChangeSort}
      />
      <Layout.Content>
        {scenariosList.map((scenario) => (
          <ScenarioCard
            key={scenario.id}
            scenario={scenario}
            onDelete={() => deleteScenario && deleteScenario(scenario.id)}
            onActivate={(scenarioId, toggled) =>
              activateScenario && activateScenario(scenarioId, toggled)
            }
          />
        ))}
      </Layout.Content>
    </Layout>
  )
}

export default ScenariosList
