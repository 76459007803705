import { useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import { ModalService } from "components/Modal"

import NoSubsidiaryModal from "features/Subsidiaries/components/NoSubsidiaryModal"

import AssignSubisidiaryModal from "../components/AssignSubisidiaryModal"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"

import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  modalTitle: {
    id: "SubsidiariesAdminsGrid.AssignSubisidiaryModalTitle",
    defaultMessage: "Affecter a une ou plusieurs filiales",
  },
  noSubsidiaryTitle: {
    id: "SubsidiariesAdminsGrid.noSubsidiaryTitle",
    defaultMessage: "Pas de filiale de paramétrée",
  },
})

function useAssignSubsidiary(adminAccount: DataGridAdminAccount) {
  const { formatMessage } = useIntl()
  const [originalSubsidiariesIds, setOriginalSubsidiariesIds] = useState<
    number[]
  >([])

  const { subsidiaries, loadingStatus } = useSubsidiariesData()

  useEffect(() => {
    setOriginalSubsidiariesIds(adminAccount.subsidiariesIds)
  }, [])

  const handleCancel = () => {
    adminAccount.setSubsidiariesIds(originalSubsidiariesIds)
    ModalService.close()
  }

  const handleDisplayAssignModal = () => {
    ModalService.open({
      title: formatMessage(messages.modalTitle),
      content: (
        <AssignSubisidiaryModal
          adminAccount={adminAccount}
          onAssign={(subsidiariesIds) => {
            setOriginalSubsidiariesIds(subsidiariesIds)
          }}
          onCancel={handleCancel}
          onClose={() => {
            ModalService.close()
          }}
        />
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCancel: async (_e) => handleCancel(),
    })
  }

  const handleDisplayNoSubsidiaryModal = () => {
    ModalService.open({
      title: formatMessage(messages.noSubsidiaryTitle),
      content: <NoSubsidiaryModal onCancel={handleCancel} />,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCancel: async (_e) => handleCancel(),
    })
  }

  const handleDisplayModal = () => {
    loadingStatus === LoadingStatus.LOADED && subsidiaries.length > 0
      ? handleDisplayAssignModal()
      : handleDisplayNoSubsidiaryModal()
  }

  return handleDisplayModal
}

export default useAssignSubsidiary
