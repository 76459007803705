import { FunctionComponent } from "react"

import {
  DEMO_EXTERNAL_LINK,
  SHOWCASE_SITE_DOMAIN_NAME,
  SHOWCASE_SITE_URL,
} from "features/Accounts/AccountsConstants"

interface CallToActionZoneProps {
  ctaLabel: string
  ctaButtonLabel: string
  showcaseLabel?: string
}

const CallToActionZone: FunctionComponent<CallToActionZoneProps> = ({
  ctaLabel,
  ctaButtonLabel,
  showcaseLabel,
}) => (
  <>
    <div className="cta-zone__demo">
      <p className="cta-zone__demo--title">{ctaLabel}</p>
      <a
        className="cta-zone__demo--link"
        href={DEMO_EXTERNAL_LINK}
        target="_blank"
      >
        {ctaButtonLabel}
      </a>
    </div>
    {showcaseLabel && (
      <p className="cta-zone__showcase">
        {showcaseLabel}
        &nbsp;
        <a
          className="cta-zone__showcase--link"
          href={SHOWCASE_SITE_URL}
          target="_blank"
        >
          {SHOWCASE_SITE_DOMAIN_NAME}
        </a>
      </p>
    )}
  </>
)

export default CallToActionZone
