import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { builderSignaturesSelectors } from "features/BuilderSignatures"

import { fetchBuilderSignatures } from "features/BuilderSignatures/BuilderSignaturesReducers"

import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"
import { LoadingStatus } from "core/CoreModels"

interface UseAllBuilderSignaturesData {
  builderSignatures: Array<BuilderSignature>
  loadingStatus: LoadingStatus
}

const useAllBuilderSignaturesData = (): UseAllBuilderSignaturesData => {
  const dispatch = useDispatch()
  const builderSignatures = useSelector(
    builderSignaturesSelectors.getAllBuilderSignatures,
  )

  const loadingStatus = useSelector(
    builderSignaturesSelectors.getBuilderSignaturesSliceLoadingStatus("all"),
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchBuilderSignatures("all"))
    }
  }, [loadingStatus, dispatch])

  return { builderSignatures, loadingStatus }
}

export default useAllBuilderSignaturesData
