import { createAction } from "@reduxjs/toolkit"
import { StorageMetadatas, StorageTags } from "./StorageModels"

export const uploadImageToStorageAction = createAction(
  "storage/uploadStorageImage",
  function prepare(
    file: File,
    tags?: StorageTags,
    metadatas?: StorageMetadatas,
  ) {
    return { payload: { file, tags, metadatas } }
  },
)

export const deleteImageFromStorageAction = createAction(
  "storage/deleteImage",
  function prepare(id) {
    return { payload: id }
  },
)
