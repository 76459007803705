import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { signaturesSelectors } from "features/Signatures"
import { builderSignaturesSelectors } from "features/BuilderSignatures"

import { loadSignatureAffectedUsersCount } from "features/Signatures/SignaturesReducer"
import { loadBuilderSignatureAffectations } from "features/BuilderSignatures/BuilderSignaturesReducers"

import { LoadingStatus } from "core/CoreModels"

function useSingleSignatureAffectedUsers(signature) {
  const dispatch = useDispatch()

  const [
    loadingStatusSelector,
    affectedUsersSelector,
    affectedUsersLoadingAction,
  ] = signature.isBuilder
    ? [
        builderSignaturesSelectors.getBuilderSignatureAffectUsersCountLoadingStatus,
        builderSignaturesSelectors.getBuilderSignatureAffectUsersCount,
        loadBuilderSignatureAffectations,
      ]
    : [
        signaturesSelectors.getSignatureAffectUsersCountLoadingStatus,
        signaturesSelectors.getSignatureAffectUsersCount,
        loadSignatureAffectedUsersCount,
      ]

  const loadingStatus = useSelector(loadingStatusSelector(signature.id))

  const affectedUsersCount = useSelector(affectedUsersSelector(signature.id))

  useEffect(() => {
    if (
      loadingStatus !== LoadingStatus.LOADED &&
      loadingStatus !== LoadingStatus.PENDING
    )
      dispatch(affectedUsersLoadingAction(signature.id))
  }, [loadingStatus])

  return { affectedUsersCount, loadingStatus }
}

export default useSingleSignatureAffectedUsers
