import React from "react"

import { FormattedMessage } from "react-intl"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext"

import EnrollmentLayout from "features/Enrollment/components/EnrollmentLayout"

import Step1 from "./Steps/EnrollmentStep1"
import Step2 from "./Steps/EnrollmentStep2"
import Step3 from "./Steps/EnrollmentStep3"
import Step4 from "./Steps/EnrollmentStep4"
import Step5 from "./Steps/EnrollmentStep5"
import StepSuccess from "./Steps/EnrollmentStepSuccess"

const Steps = [
  <FormattedMessage id="Enrollment.step1" defaultMessage="Formule" />,
  <FormattedMessage id="Enrollment.step2" defaultMessage="Options" />,
  <FormattedMessage id="Enrollment.step3alt" defaultMessage="Récapitulatif" />,
  <FormattedMessage
    id="Enrollment.step4alt"
    defaultMessage="Données de facturation"
  />,
  <FormattedMessage id="Enrollment.step5" defaultMessage="Paiement" />,
]

const componentToRender = {
  1: <Step1 />,
  2: <Step2 />,
  3: <Step3 />,
  4: <Step4 />,
  5: <Step5 />,
  6: <StepSuccess />,
}

const EnrollmentForm: React.FC = () => {
  const { enrollmentDatas } = useEnrollmentData()

  return (
    <EnrollmentLayout steps={Steps}>
      {componentToRender[enrollmentDatas.currentStep]}
    </EnrollmentLayout>
  )
}

export default EnrollmentForm
