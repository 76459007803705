/**
 * Sagas Scenarios
 */
import {
  put,
  call,
  takeLatest,
  all,
  select,
  takeEvery,
  take,
} from "redux-saga/effects"

import { LoadingStatus, TypedResult } from "core/CoreModels"
import { LoadingSlicesTypes } from "core/services/loadingSlicesService"

import {
  createScenario,
  createScenarioSuccess,
  createScenarioFailure,
  deleteScenarioSuccess,
  fetchScenarios,
  fetchScenariosSuccess,
  fetchScenariosFailure,
  updateScenario,
  updateScenarioSuccess,
  updateScenarioFailure,
  ActivateAction,
  activateScenario as activateScenarioAction,
  activateScenarioFailure as activateScenarioFailureAction,
  fetchScenarioAssignations as fetchScenarioAssignationsAction,
  fetchScenarioAssignationsSuccess,
  ScenariosSlices,
  duplicateScenarioSuccess,
  fetchScenariosCustomQuerySuccess,
} from "./ScenarioReducers"

import {
  fetchScenarios as fetchScenariosCustomQueryApi,
  fetchScenariosApi,
  createScenario as createScenarioApi,
  updateScenario as updateScenarioApi,
  deleteScenario,
  associateUsers,
  associateGroups,
  associateSignatures,
  activateScenario,
  fetchScenarioAssignations,
} from "./ScenarioApi"

import { ScenarioMapper } from "./mappers/ScenarioMapper"
import { PayloadAction } from "@reduxjs/toolkit"
import { ScenarioFormVm, ScenarioDto, ScenarioVm } from "./ScenarioModels"
import { addNotification, Notification } from "features/Notifications"

import { withSagaErrorHandler } from "features/Errors"
import {
  activateScenarioSuccessAction,
  associateScenarioGroupsAction,
  associateScenarioSignaturesAction,
  associateScenarioUsersAction,
  deleteScenarioAction,
  duplicateScenarioAction,
  fetchScenariosCustomQueryAction,
} from "./ScenarioActions"

import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"

import { error } from "features/Errors/ErrorsActions"
import { ErrorTarget } from "features/Errors/ErrorsModel"

import { featureNames } from "config/config.features"

import { uploadBannerAsync } from "features/Scenarios/services/bannerService"
import { scenarioSelectors } from "."
import { setOfferLimitations } from "features/Offers/OffersReducer"
import { normalizeDate } from "utils/DateHelper"
import {
  Params,
  requestParamsBuilder,
} from "core/services/requestBuilderService"

export function* getOrLoadScenariosSlice(neededSlice: "all" | ScenariosSlices) {
  const scenariosLoaded = yield select(
    scenarioSelectors.getScenarioSliceLoadedStatus(neededSlice),
  )

  if (scenariosLoaded !== LoadingStatus.LOADED) {
    yield put(fetchScenarios("all"))
    yield take(fetchScenariosSuccess.type)
  }

  const allScenarios = yield select(scenarioSelectors.getAllScenarios)

  return allScenarios
}

function* assignUsersAsync(scenarioId: number, usersId: Array<number>) {
  const associateUsersAction = associateScenarioUsersAction(scenarioId, usersId)
  const users = yield call(associateScenarioUsersAsync, associateUsersAction)

  return users?.map((u) => u.id) ?? []
}

function* assignGroupsAsync(scenarioId: number, groupIds: number[]) {
  const associateGroupsAction = associateScenarioGroupsAction(
    scenarioId,
    groupIds,
  )
  const groups = yield call(associateScenarioGroupsAsync, associateGroupsAction)

  return groups?.map((g) => g.id) ?? []
}

function* assignSignaturesAsync(scenarioId: number, signatureIds: number[]) {
  const associateSignaturesAction = associateScenarioSignaturesAction(
    scenarioId,
    signatureIds,
  )
  const signatures = yield call(
    associateScenarioSignaturesAsync,
    associateSignaturesAction,
  )

  return signatures?.map((s) => s.id) ?? []
}

function* fetchScenariosAsync(
  action: PayloadAction<LoadingSlicesTypes<ScenariosSlices>>,
) {
  let activeDiffusionMode = action.payload
  const loadedSlices: LoadingSlicesTypes<ScenariosSlices> = action.payload

  if (action.payload === "all") activeDiffusionMode = null

  const result: TypedResult<ScenarioDto[]> = yield call(
    fetchScenariosApi,
    activeDiffusionMode as "internal" | "external" | null,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const scenariosDtoWithNormalizedDates = result.result.map((s) => ({
    ...s,
    startDate: normalizeDate(s.startDate as unknown as string),
    endDate: normalizeDate(s.endDate as unknown as string),
  }))

  const mappingResult = ScenarioMapper.toEntities(
    scenariosDtoWithNormalizedDates,
  )

  // Comment router en call non-blockant? Pattern - partial errors ou collection error
  yield all(
    mappingResult
      .filter((mr) => mr.isFailure)
      .map((mr) => {
        const actualError = new Error(mr.error as string)
        put(
          error({
            message: actualError.message,
            stack: actualError.stack,
            feature: "scenarios",
            target: ErrorTarget.Both,
          }),
        )
      }),
  )

  const scenarios = mappingResult
    .filter((result) => result.isSuccess)
    .map((result) => result.getValue())

  yield put(fetchScenariosSuccess({ scenarios, loadedSlices }))
}

function* createScenarioAsync(action: PayloadAction<ScenarioFormVm>) {
  const result = yield call(upsertScenarioAsync, action, createScenarioApi)

  // Messaging - notification
  yield put(
    addNotification(new Notification(`Campagne créée avec succès`, "INFO")),
  )

  yield put(createScenarioSuccess(result))
}

function* associateScenarioUsersAsync(
  action: PayloadAction<{ scenarioId: number; userIds: number[] }>,
) {
  const { scenarioId, userIds } = action.payload

  const result: TypedResult<ScenarioDto> = yield call(
    associateUsers,
    scenarioId,
    userIds,
  )
  if (!result.success) {
    throw new Error(result.errorMessage)
  }
  return result.result
}

function* associateScenarioGroupsAsync(
  action: PayloadAction<{ scenarioId: number; groupIds: number[] }>,
) {
  const { scenarioId, groupIds } = action.payload

  const result: TypedResult<ScenarioDto> = yield call(
    associateGroups,
    scenarioId,
    groupIds,
  )
  if (!result.success) throw new Error(result.errorMessage)

  return result.result
}

function* associateScenarioSignaturesAsync(
  action: PayloadAction<{ scenarioId: number; signatureIds: number[] }>,
) {
  const { scenarioId, signatureIds } = action.payload

  const result: TypedResult<ScenarioDto> = yield call(
    associateSignatures,
    scenarioId,
    signatureIds,
  )
  if (!result.success) {
    throw new Error(result.errorMessage)
  }
  return result.result
}

function* activateScenarioAsync(action: PayloadAction<ActivateAction>) {
  const { id, activated } = action.payload

  const result: TypedResult<ScenarioDto> = yield call(
    activateScenario,
    id,
    activated,
  )

  // Gestion des limitations
  if (result.limitationsReach === true) {
    yield put(setOfferLimitations("BannersLimitationsCantActivate"))
    yield put(activateScenarioFailureAction({ id, activated: !activated }))
  }

  if (!result.success) {
    // optimistic ui - cancel
    yield put(activateScenarioFailureAction({ id, activated: !activated }))
    throw new Error(result.errorMessage)
  }
  yield put(activateScenarioSuccessAction())
}

function* updateScenariosAsync(action: PayloadAction<ScenarioFormVm>) {
  const result = yield call(upsertScenarioAsync, action, updateScenarioApi)

  yield put(fetchScenarioAssignationsAction(result.id))
  yield take(fetchScenarioAssignationsSuccess.type)

  yield put(updateScenarioSuccess(result))

  yield put(
    addNotification(new Notification(`Campagne modifiée avec succès`, "INFO")),
  )
}

function* upsertScenarioAsync(
  action: PayloadAction<ScenarioFormVm>,
  scenarioApi,
) {
  const scenarioFormData = action.payload

  // Upload des images et trim des urls
  for (const banner of scenarioFormData.banners) {
    if (banner?.linkUrl && banner?.linkUrl.length > 0)
      banner.linkUrl = banner.linkUrl.trim()
    banner.imageUrl = yield call(uploadBannerAsync, banner)
  }

  const scenarioDto = ScenarioMapper.fromScenarioFormToDto(scenarioFormData)
  // API Call
  const result: TypedResult<ScenarioDto> = yield call(scenarioApi, scenarioDto)

  if (!result.success) throw new Error(result.errorMessage)

  // Gestion des limitations
  if (result.limitationsReach === true) {
    yield put(setOfferLimitations("BannersLimitationsCantActivate"))
  }
  const isScenarioBySignatureActive = yield select(
    isFeatureActive(featureNames.SCENARIO_BY_SIGNATURES),
  )

  if (isScenarioBySignatureActive) {
    result.result.signatureIds = yield call(
      assignSignaturesAsync,
      result.result.id,
      scenarioFormData.signatureIds,
    )
  }

  // Déléguer l'assignation User & Group
  result.result.userIds = yield call(
    assignUsersAsync,
    result.result.id,
    scenarioFormData.userIds,
  )
  result.result.groupIds = yield call(
    assignGroupsAsync,
    result.result.id,
    scenarioFormData.groupIds,
  )

  const scenarioDtoWithNormalizedDates = {
    ...result.result,
    startDate: normalizeDate(result.result.startDate as unknown as string),
    endDate: normalizeDate(result.result.endDate as unknown as string),
  }

  return ScenarioMapper.toEntity(scenarioDtoWithNormalizedDates).getValue()
}

function* deleteScenarioAsync(action: PayloadAction<number>) {
  const result: TypedResult<ScenarioDto> = yield call(
    deleteScenario,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  // Messaging - notification
  yield put(addNotification(new Notification(`Campagne supprimée`, "ERROR")))
  yield put(deleteScenarioSuccess(action.payload))
}

function* loadScenarioAffectationsAsync(action) {
  const scenarioId = action.payload

  const result = yield call(fetchScenarioAssignations, scenarioId)

  if (!result.success) throw new Error(result.errorMessage)

  yield put(
    fetchScenarioAssignationsSuccess({
      scenarioId,
      count: result.result.userIds.length,
    }),
  )
}

function* duplicateScenarioAsync(action: PayloadAction<number>) {
  const relatedScenario = yield select((state) =>
    scenarioSelectors.getScenarioById(state, action.payload),
  )
  const scenarioFormVm = ScenarioMapper.toScenarioFormViewModel(relatedScenario)

  const readyToDuplicateScenario = {
    ...scenarioFormVm,
    id: undefined,
    name: `${scenarioFormVm.name} - copie`,
    userIds: [],
    groupIds: [],
    signatureIds: [],
  }

  const processedAction = { ...action, payload: readyToDuplicateScenario }

  const result = yield call(
    upsertScenarioAsync,
    processedAction,
    createScenarioApi,
  )

  // Messaging - notification
  yield put(
    addNotification(new Notification(`Campagne dupliquée avec succès`, "INFO")),
  )

  yield put(duplicateScenarioSuccess(result))
}

export function* fetchScenariosCustomQueryAsync(
  action: PayloadAction<{ requestId: string; customQuery: Params<ScenarioVm> }>,
) {
  const { requestId, customQuery } = action.payload

  const response = yield call(
    fetchScenariosCustomQueryApi,
    requestParamsBuilder(customQuery),
  )

  if (!response.success) throw new Error(response.errorMessage)

  const { result } = response

  yield put(
    fetchScenariosCustomQuerySuccess({ requestId, customQuery, result }),
  )
}

export const scenarioWatchers = [
  takeLatest(
    fetchScenarios.type,
    withSagaErrorHandler(fetchScenariosAsync, fetchScenariosFailure()),
  ),
  takeLatest(
    updateScenario.type,
    withSagaErrorHandler(updateScenariosAsync, updateScenarioFailure()),
  ),
  takeLatest(
    associateScenarioUsersAction.type,
    withSagaErrorHandler(associateScenarioUsersAsync),
  ),
  takeLatest(
    associateScenarioGroupsAction.type,
    withSagaErrorHandler(associateScenarioGroupsAsync),
  ),
  takeLatest(
    activateScenarioAction.type,
    withSagaErrorHandler(
      activateScenarioAsync,
      activateScenarioFailureAction(),
    ),
  ),
  takeLatest(
    createScenario.type,
    withSagaErrorHandler(createScenarioAsync, createScenarioFailure()),
  ),
  takeLatest(
    deleteScenarioAction.type,
    withSagaErrorHandler(deleteScenarioAsync),
  ),
  takeEvery(
    fetchScenarioAssignationsAction.type,
    loadScenarioAffectationsAsync,
  ),
  takeLatest(
    duplicateScenarioAction.type,
    withSagaErrorHandler(duplicateScenarioAsync),
  ),
  takeEvery(
    fetchScenariosCustomQueryAction.type,
    withSagaErrorHandler(fetchScenariosCustomQueryAsync),
  ),
]
