import { FunctionComponent, HTMLAttributes, ReactNode } from "react"

import { defineMessages, useIntl } from "react-intl"

interface CampaignsTileActiveCountProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  allCount: number
}

const messages = defineMessages({
  today: {
    id: "HomePage.CampaignsTileToday",
    defaultMessage: "aujourd'hui",
  },
})

const CampaignsTileActiveCount: FunctionComponent<
  CampaignsTileActiveCountProps
> = ({ children, allCount, ...others }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="campaigns-tile__active-count--container" {...others}>
      <p className="campaigns-tile__active-count--all-count">{allCount}</p>
      <p className="campaigns-tile__active-count--label">{children}</p>
      <p className="campaigns-tile__active-count--today">
        {formatMessage(messages.today)}
      </p>
    </div>
  )
}

export default CampaignsTileActiveCount
