import useJobs from "features/Jobs/hooks/useJobs"
import { JobState } from "features/Jobs/JobsModels"
import { last } from "lodash"
import SignatureProgress from "./SignatureProgress"

const ConnectedSignatureProgress = () => {
  const { jobsInSignatureCompilation } = useJobs()
  const currentJob = last(jobsInSignatureCompilation)

  if (currentJob == null) return null

  return (
    <SignatureProgress
      value={currentJob?.percent}
      total={100}
      isDone={currentJob?.state === JobState.Succeeded}
    />)
}

export default ConnectedSignatureProgress