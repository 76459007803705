import {
  FunctionComponent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  SVGProps,
} from "react"

import classNames from "classnames"

interface UnderlinedInputFieldProps {
  type?: HTMLInputTypeAttribute
  leftIcon?: SVGProps<SVGSVGElement>
  rightIcon?: SVGProps<SVGSVGElement>
  variant?: "xl"
}

type UnderlinedInputFieldType = UnderlinedInputFieldProps &
  InputHTMLAttributes<HTMLInputElement>

const UnderlinedInputField: FunctionComponent<UnderlinedInputFieldType> = ({
  leftIcon,
  rightIcon,
  variant,
  type = "text",
  ...others
}) => {
  return (
    <div className="input-underlined-field">
      {leftIcon && (
        <div className="input-underlined-field__icon">{leftIcon}</div>
      )}
      <input
        className={classNames("input-underlined-field__input", variant)}
        type={type}
        {...others}
      />
      {rightIcon && (
        <div className="input-underlined-field__icon">{rightIcon}</div>
      )}
    </div>
  )
}

export default UnderlinedInputField
