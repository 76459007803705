import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

interface LoginSliderMessagesProps {
  current: number
}

const messages = defineMessages({
  firstTitle: {
    id: "LoginSlider.FirstTitle",
    defaultMessage: "La solution de gestion de la signature mail",
  },
  firstSubTitle: {
    id: "LoginSlider.FirstSubTitle",
    defaultMessage: "Dédiée aux entreprises",
  },
  secondTitle: {
    id: "LoginSlider.SecondTitle",
    defaultMessage: "Plus de 600 clientS",
  },
  secondSubTitle: {
    id: "LoginSlider.SecondSubTitle",
    defaultMessage: "Nous ont déjà rejoint",
  },
  thirdTitle: {
    id: "LoginSlider.ThirdTitle",
    defaultMessage: "Une application dédiée",
  },
  thirdSubTitle: {
    id: "LoginSlider.ThirdSubTitle",
    defaultMessage: "à la diffusion de signature et campagne",
  },
})

const loginSliderMessages = [
  { title: messages.firstTitle, subtitle: messages.firstSubTitle },
  { title: messages.secondTitle, subtitle: messages.secondSubTitle },
  { title: messages.thirdTitle, subtitle: messages.thirdSubTitle },
]

const LoginSliderMessages: FunctionComponent<LoginSliderMessagesProps> = ({
  current,
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className="login-slider__messages">
      <p className="login-slider__messages--title">
        {formatMessage(loginSliderMessages[current].title)}
      </p>
      <p className="login-slider__messages--subtitle">
        {formatMessage(loginSliderMessages[current].subtitle)}
      </p>
    </div>
  )
}

export default LoginSliderMessages
