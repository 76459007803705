import React from "react"

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView"

export interface Props {
  model: string
}

/**
 * Encapsule le Froala Editor pour gérer les options plus tard (edition / afficher la bannière / interactions ...)
 */
class SignatureReadOnlyView extends React.Component<Props> {
  render() {
    return (
      <div className="signature-form__preview">
        <div className="signature-form_preview__overlay" />
        {typeof window != null && <FroalaEditorView model={this.props.model} />}
      </div>
    )
  }
}

export default SignatureReadOnlyView
