import { FunctionComponent, useState } from "react"

import { useSelector } from "react-redux"

import { FormattedMessage } from "react-intl"

import moment from "moment"

import OfferDetailsPopup from "features/Enrollment/components/OfferDetailsPopup"

import imageBasic from "features/Enrollment/assets/visual-rocket-alt.svg"
import imagePremium from "features/Enrollment/assets/visual-rocket.svg"

import imageUsers from "./images/icon-users-blue.svg"
import imageCalendar from "./images/icon-calendar-blue.svg"

import { offersSelectors } from "features/Offers"

const DATE_FORMAT = "DD MMMM YYYY"

const MyOfferPage: FunctionComponent = () => {
  const [popup, setPopup] = useState({ open: false })
  const handleClosePopup = () => setPopup({ open: false })

  const tenantOffer = useSelector(offersSelectors.getCurrentTenantOffer)

  if (tenantOffer == null) return null

  const nbUsers =
    tenantOffer.MaxLicences === 0 ? (
      <FormattedMessage
        id="SubscriptionPage.InfiniteUsers"
        defaultMessage="Illimité"
      />
    ) : (
      tenantOffer.MaxLicences
    )

  return (
    <div className="manage-subscription__offer">
      <div className="manage-subscription__offer__card">
        <div className="manage-subscription__offer__description">
          <div className="manage-subscription__offer__description__name">
            {`Pack ${tenantOffer.Name}`}
          </div>
          <a
            onClick={() => setPopup({ open: true })}
            className="manage-subscription__offer__description__link"
          >
            <FormattedMessage
              id="OfferCard.DetailsLink"
              defaultMessage="Détails de l'offre"
            />
          </a>
          {tenantOffer.Name === "Basic" ? (
            <img
              src={imageBasic}
              className="manage-subscription__offer__description__icon"
            />
          ) : (
            <img
              src={imagePremium}
              className="manage-subscription__offer__description__icon"
            />
          )}
        </div>
        <div className="manage-subscription__offer__detail">
          <div className="manage-subscription__offer__detail__image">
            <img src={imageCalendar} alt="" />
          </div>
          <div className="manage-subscription__offer__detail__label">
            <FormattedMessage
              id="SubscriptionPage.MyOfferTabUntil"
              defaultMessage="Valable jusqu'au"
            />
          </div>
          <div className="manage-subscription__offer__detail__value">
            {moment(tenantOffer.EndDate).format(DATE_FORMAT)}
          </div>
        </div>
        <div className="manage-subscription__offer__detail">
          <div className="manage-subscription__offer__detail__image">
            <img src={imageUsers} alt="" />
          </div>
          <div className="manage-subscription__offer__detail__label">
            <FormattedMessage
              id="SubscriptionPage.MyOfferUsers"
              defaultMessage="Utilisateurs"
            />
          </div>
          <div className="manage-subscription__offer__detail__value">
            {nbUsers}
          </div>
        </div>
      </div>
      <OfferDetailsPopup open={popup.open} onClose={handleClosePopup} />
    </div>
  )
}

export default MyOfferPage
