import { FC, Fragment, useState } from "react"
import { Alert } from "../../AlertsModels"
import AlertCard from "../AlertCard"
import useAlerts from "../../hook/useAlerts"
import Divider from "components/Divider"
import AlertInfo from "../AlertInfo"

interface Props {
  alerts: Alert[]
}

const AlertsContainer: FC<Props> = ({ alerts }) => {
  let timerId
  const { setAlertStatus } = useAlerts()
  const [alertSelected, setAlertSelected] = useState<Alert>(null)
  const [open, setOpen] = useState(false)

  const handleClickAlert = (alert: Alert) => {
    clearTimeout(timerId)
    setAlertSelected(alert)
    setAlertStatus(alert.id)
    setOpen(true)
  }

  const handleCardMouseEnter = (alert: Alert) => {
    timerId = setTimeout(() => {
      setAlertStatus(alert.id)
    }, 2000)
  }

  const handleCardMouseLeave = () => {
    clearTimeout(timerId)
  }

  const removeAlertSelected = () => {
    setOpen(false)
    setTimeout(() => {
      setAlertSelected(null)
    }, 500)
  }

  return (
    <div className="alerts__container" >
      {
        alertSelected
        &&
        <AlertInfo
          alert={alertSelected}
          removeAlertSelected={removeAlertSelected}
        />
      }

      <div className={`alerts__container__cards  ${open  ? "alerts__container__cards--hide": "alerts__container__cards--show"}`}>
        {alerts.map((alert, index) => (
          <Fragment key={alert.id}>
            <AlertCard
              onClick={() => handleClickAlert(alert)}
              onMouseEnter={() => handleCardMouseEnter(alert)}
              onMouseLeave={handleCardMouseLeave}
              alert={alert}
            />
            {index !== alerts.length - 1 && <Divider />}
          </Fragment>
        ))}
      </div>

  </div>
  )
}

export default AlertsContainer
