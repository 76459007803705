import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { ReactComponent as SuccessIcon } from "assets/icon-success.svg"

import {
  DEFAULT_ENROLLMENT_MOST_POPULAR,
  TAILOR_MADE_CTA_LINK,
  TAILOR_MADE_CTA_USERS_TRIGGER,
} from "features/Enrollment/EnrollmentConstants"
import {
  DEFAULT_CATALOG_OFFER_BASIC_NAME,
  DEFAULT_CATALOG_OFFER_PREMIUM_NAME,
  DEFAULT_OFFER_CURRENCY,
} from "features/Offers/OffersConstants"

import { CatalogOffer } from "features/Offers/OffersModels"
import { monthlyPriceComputation } from "features/Enrollment/EnrollmentRules"
import { Link } from "react-router-dom"
import { formatAmount } from "utils/NumberHelper"
import { isStrong, isSpan } from "utils/I18nHelpers"

interface OfferCardProps {
  offer: CatalogOffer
  nbUsers: number
  isActive: boolean
  onSelect: (offer: CatalogOffer) => void
}

function renderLimitationMessage(offer) {
  switch (offer.name) {
    case DEFAULT_CATALOG_OFFER_PREMIUM_NAME:
      return messages.premiumOfferLimitations

    case DEFAULT_CATALOG_OFFER_BASIC_NAME:
      return messages.basicOfferLimitations
  }
}

const messages = defineMessages({
  offer: {
    id: "Enrollment.OfferCardOffer",
    defaultMessage: "Offre",
  },
  ribbon: {
    id: "Enrollment.OfferCardMostPopularRibbon",
    defaultMessage: "La plus populaire",
  },
  perMonth: {
    id: "Enrollment.OfferCardPerMonthPerUser",
    defaultMessage: "par mois/utilisateur",
  },
  basicOfferLimitations: {
    id: "Enrollment.OfferCardBasicLimitation",
    defaultMessage: "<b>1 seule</b> signature",
  },
  premiumOfferLimitations: {
    id: "Enrollment.OfferCardPremiumLimitation",
    defaultMessage: "Signatures et Campagnes <b>illimitées</b>",
  },
  pricePerMonth: {
    id: "Enrollment.OfferCardPricePerMonth",
    defaultMessage:
      "<sp>{price}</sp> HT/mois pour <sp>{count}</sp> utilisateurs",
  },
  minimal: {
    id: "Enrollment.OfferCardMinimalPrice",
    defaultMessage: "Abonnement minimum de {price}/mois",
  },
  tailorMadePrice: {
    id: "Enrollment.OfferCardTailorMadePrice",
    defaultMessage: "Besoin d’un tarif sur-mesure ?",
  },
  tailorMadePriceCta: {
    id: "Enrollment.OfferCardTailorMadePriceCta",
    defaultMessage: "Contactez-nous !",
  },
})

const OfferCard: FunctionComponent<OfferCardProps> = ({
  offer,
  nbUsers,
  isActive,
  onSelect,
}) => {
  const { formatMessage, locale } = useIntl()

  const monthlyPrice = useMemo(
    () => offer && monthlyPriceComputation(offer, nbUsers),
    [offer, nbUsers],
  )

  if (!offer) return null

  const tailorMade = isActive &&
    offer.name === DEFAULT_ENROLLMENT_MOST_POPULAR &&
    nbUsers >= TAILOR_MADE_CTA_USERS_TRIGGER && (
      <>
        <p className="offer-card__tailor-made--infos">
          {formatMessage(messages.tailorMadePrice)}
        </p>
        <Link
          className="offer-card__tailor-made--cta"
          to={TAILOR_MADE_CTA_LINK}
          target="_blank"
        >
          {formatMessage(messages.tailorMadePriceCta)}
        </Link>
      </>
    )

  return (
    <div
      onClick={() => onSelect(offer)}
      className={classNames(
        "offer-card",
        offer.name.toLowerCase(),
        isActive && "is-active",
      )}
    >
      {offer.name === DEFAULT_ENROLLMENT_MOST_POPULAR && (
        <div className="offer-card__ribbon">
          <span className="offer-card__ribbon--label">
            {formatMessage(messages.ribbon)}
          </span>
        </div>
      )}
      {isActive ? (
        <SuccessIcon
          className={classNames(
            "offer-card__selected-icon",
            offer.name.toLowerCase(),
          )}
        />
      ) : (
        <div className="offer-card__unselected" />
      )}
      <p className="offer-card__name">{`${formatMessage(messages.offer)} ${
        offer.name
      }`}</p>

      <div
        className={classNames(
          "offer-card__monthly-price--container",
          offer.name.toLowerCase(),
        )}
      >
        <p className="offer-card__monthly-price--label">
          {formatAmount(offer.unitPrice, locale)}
          <span className="offer-card__monthly-price--currency">
            {DEFAULT_OFFER_CURRENCY.symbol}
          </span>
        </p>
        <p className="offer-card__monthly-price--infos">
          {formatMessage(messages.perMonth)}
        </p>
      </div>
      <p
        className={classNames("offer-card__message", offer.name.toLowerCase())}
      >
        {formatMessage(renderLimitationMessage(offer), { b: isStrong })}
      </p>
      {isActive && (
        <div
          className={classNames(
            "offer-card__price--container",
            offer.name.toLowerCase(),
          )}
        >
          <p className="offer-card__price--computed">
            {formatMessage(messages.pricePerMonth, {
              sp: isSpan,
              count: nbUsers,
              price: formatAmount(
                monthlyPrice.monthlyPrice,
                locale,
                DEFAULT_OFFER_CURRENCY.name,
              ),
            })}
          </p>
          {tailorMade}
        </div>
      )}
      {isActive && monthlyPrice.isUnderMinimal && (
        <p className="offer-card__minimal-price">
          {formatMessage(messages.minimal, {
            price: formatAmount(
              offer.minimalPrice,
              locale,
              DEFAULT_OFFER_CURRENCY.name,
            ),
          })}
        </p>
      )}
    </div>
  )
}

export default OfferCard
