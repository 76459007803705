import { LoadingStatus, TypedResult } from "core/CoreModels"

import { PayloadAction } from "@reduxjs/toolkit"
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"

import {
  fetchBuilderSignatures as fetchBuilderSignaturesApi,
  deleteBuilderSignature,
  createBuilderSignature as createBuilderSignatureApi,
  updateBuilderSignature as updateBuilderSignatureApi,
  fetchBuilderSignaturesUsers,
  fetchBuilderSignaturesGroups,
  updateBuilderSignatureUsers,
  updateBuilderSignatureGroups,
  activateSignature,
  fetchGroupsForSignature,
  fetchUsersForSignature,
  fetchSignatureAssignations,
} from "features/Signatures/SignaturesApi"

import { sendPreviewJob } from "features/Jobs/JobsApi"

import {
  activateBuilderSignatureSuccess,
  createBuilderSignature,
  createBuilderSignatureSuccess,
  deleteBuilderSignatureSuccess,
  fetchBuilderSignatures as fetchBuilderSignaturesAction,
  fetchBuilderSignaturesFailure,
  fetchBuilderSignaturesSuccess,
  updateBuilderSignatureGroupsSuccess,
  updateBuilderSignature,
  updateBuilderSignatureSuccess,
  updateBuilderSignatureUsersSuccess,
  assignationBuilderSignatureSuccess,
  loadBuilderSignatureAffectations,
  setActiveSendingMode,
  setActiveBuilderSignature,
  setEditingBuilderSignatureWeighting,
} from "./BuilderSignaturesReducers"

import { withSagaErrorHandler } from "features/Errors"
import {
  BuilderSignature,
  BuilderSignatureDto,
  resolveMissingPropertiesError,
  updateJsonTemplateName,
} from "./BuilderSignaturesModels"

import {
  deleteBuilderSignatureAction,
  loadBuilderSignatureGroupsAction,
  loadBuilderSignatureUsersAction,
  toggleBuilderSignatureAction,
  sendBuilderSignaturePreviewAction,
  initActiveBuilderSignatureAction,
  duplicateBuilderSignatureAction,
} from "./BuilderSignaturesActions"

import { addNotification, Notification } from "features/Notifications"

import { accountsSelectors } from "features/Accounts"
import { builderSignaturesSelectors } from "features/BuilderSignatures"

import { UserDto } from "features/Users/UserModels"
import { GroupDto } from "features/Groups/GroupsModels"
import { usersSelectors } from "features/Users"
import { signaturesSelectors } from "features/Signatures"
import { SignaturesSlices } from "features/Signatures/SignaturesReducer"
import { LoadingSlicesTypes } from "core/services/loadingSlicesService"
import { DEFAULT_SIGNATURE_WEIGHTING_VALUE } from "features/Signatures/SignaturesRules"

function* affectationBuilderSignatureAsync(action: PayloadAction<number>) {
  try {
    const [affectedUsers, affectedGroups, affectedUsersCount] = yield all([
      call(fetchUsersForSignature, action.payload),
      call(fetchGroupsForSignature, action.payload),
      call(fetchSignatureAssignations, action.payload),
    ])

    if (!affectedUsers.success) throw new Error(affectedUsers.errorMessage)
    if (!affectedGroups.success) throw new Error(affectedGroups.errorMessage)
    if (!affectedUsersCount.success)
      throw new Error(affectedUsersCount.errorMessage)

    yield put(
      assignationBuilderSignatureSuccess({
        id: action.payload,
        affectedGroups: affectedGroups.result,
        affectedUsers: affectedUsers.result,
        affectedUsersCount: affectedUsersCount.result.userIds,
      }),
    )
  } catch (error) {
    yield put(addNotification(new Notification(error.message, "ERROR")))
  }
}

export function* getOrLoadBuilderSignaturesSlice(
  neededSlice: "all" | SignaturesSlices,
) {
  const builderSignaturesLoadingStatus = yield select(
    builderSignaturesSelectors.getBuilderSignaturesSliceLoadingStatus(
      neededSlice,
    ),
  )

  if (builderSignaturesLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchBuilderSignaturesAction(neededSlice))
    yield take(fetchBuilderSignaturesSuccess.type)
  }

  const allBuilderSignatures: BuilderSignature[] = yield select(
    builderSignaturesSelectors.getAllBuilderSignatures,
  )

  return allBuilderSignatures
}

function* fetchBuilderSignaturesAsync(
  action: PayloadAction<LoadingSlicesTypes<SignaturesSlices>>,
) {
  const result: TypedResult<Array<BuilderSignatureDto>> = yield call(
    fetchBuilderSignaturesApi,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const builderSignatures = result.result.map(
    (signature) => new BuilderSignature(signature),
  )
  const userProperties = yield select(usersSelectors.getUserProperties)

  // ! Fix pour résoudre le problème des missing properties
  const fixedBuilderSignatures = builderSignatures.map((sig) => {
    const fixedTemplate = resolveMissingPropertiesError(
      sig.json,
      userProperties,
    )

    if (fixedTemplate !== null) sig.json = fixedTemplate

    return sig
  })

  yield put(
    fetchBuilderSignaturesSuccess({
      signatures: fixedBuilderSignatures,
      slice: action.payload,
    }),
  )
}

function* createBuilderSignatureAsync(
  action: PayloadAction<Omit<BuilderSignature, "id" | "template">>,
) {
  const result: TypedResult<BuilderSignatureDto> = yield call(
    createBuilderSignatureApi,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const builderSignature = new BuilderSignature(result.result)

  const { usersIds, groupsIds } = yield call(
    updateBuilderSignatureAssignmentAsync,
    builderSignature.id,
  )

  yield put({
    type: toggleBuilderSignatureAction.type,
    payload: new BuilderSignature({ ...result.result, activated: true }),
  })

  yield put(
    createBuilderSignatureSuccess({
      ...builderSignature,
      affectedUsers: usersIds,
      affectedGroups: groupsIds,
    }),
  )

  yield put(
    addNotification(
      new Notification(
        "Votre modèle de signature a bien été enregistré.",
        "INFO",
      ),
    ),
  )
}

function* updateBuilderSignatureAsync(
  action: PayloadAction<Omit<BuilderSignature, "template">>,
) {
  const result: TypedResult<BuilderSignatureDto> = yield call(
    updateBuilderSignatureApi,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const builderSignature = new BuilderSignature(result.result)

  const { usersIds, groupsIds } = yield call(
    updateBuilderSignatureAssignmentAsync,
    builderSignature.id,
  )

  yield put(
    updateBuilderSignatureSuccess({
      ...builderSignature,
      affectedUsers: usersIds,
      affectedGroups: groupsIds,
    }),
  )

  yield put(
    addNotification(
      new Notification(
        "Votre modèle de signature a bien été mis à jour.",
        "INFO",
      ),
    ),
  )
}

function* deleteBuilderSignatureAsync(action: PayloadAction<number>) {
  const result: TypedResult<BuilderSignatureDto> = yield call(
    deleteBuilderSignature,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  yield put(
    addNotification(
      new Notification("Signature supprimée avec succès", "INFO"),
    ),
  )

  yield put(deleteBuilderSignatureSuccess(action.payload))
}

function* fetchBuilderSignaturesUsersAsync(action: PayloadAction<number>) {
  const result: TypedResult<Array<number>> = yield call(
    fetchBuilderSignaturesUsers,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const usersIds = result.result

  yield put(
    updateBuilderSignatureUsersSuccess({
      signatureId: action.payload,
      usersIds,
    }),
  )
}

function* fetchBuilderSignaturesGroupsAsync(action: PayloadAction<number>) {
  const result: TypedResult<Array<number>> = yield call(
    fetchBuilderSignaturesGroups,
    action.payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const groupsIds = result.result

  yield put(
    updateBuilderSignatureGroupsSuccess({
      signatureId: action.payload,
      groupsIds,
    }),
  )
}

function* updateBuilderSignatureAssignmentAsync(signatureId: number) {
  const { users, groups } = yield select(
    builderSignaturesSelectors.getActiveBuilderSignatureAssignment,
  )
  const usersResult: TypedResult<UserDto[]> = yield call(
    updateBuilderSignatureUsers,
    signatureId,
    users,
  )

  if (!usersResult.success) throw new Error(usersResult.errorMessage)

  const usersIds = usersResult.result.map((user) => user.id)

  const groupsResult: TypedResult<GroupDto[]> = yield call(
    updateBuilderSignatureGroups,
    signatureId,
    groups,
  )

  if (!groupsResult.success) throw new Error(groupsResult.errorMessage)

  const groupsIds = groupsResult.result.map((group) => group.id)

  return { usersIds, groupsIds }
}

function* updateBuilderSignatureActivation(
  action: PayloadAction<BuilderSignature>,
) {
  const result: TypedResult<BuilderSignatureDto> = yield call(
    activateSignature,
    action.payload.id,
    action.payload.activated,
  )

  if (!result.success) throw new Error(result.errorMessage)

  yield put(
    activateBuilderSignatureSuccess({
      id: action.payload.id,
      activated: result.result.activated,
      activatedDate: result.result.activatedDate,
    }),
  )
}

function* sendBuilderSignaturePreview(
  action: PayloadAction<{ signatureId: number; recepientsEmails: string[] }>,
) {
  const { signatureId, recepientsEmails } = action.payload

  const currentTenantId = yield select(accountsSelectors.getTenantId)
  const currentAdminEmail = yield select(
    accountsSelectors.getCurrentAccountEmail,
  )

  const result = yield call(sendPreviewJob, {
    tenantId: currentTenantId,
    adminMail: currentAdminEmail,
    signatureId,
    recepientsEmails,
  })

  if (!result.success) throw new Error(result.errorMessage)

  yield put(
    addNotification(
      new Notification("Previsualisation envoyée avec succès", "SUCCESS"),
    ),
  )
}

export function* initActiveBuilderSignatureAsync(
  action: PayloadAction<number | null>,
) {
  if (action.payload === null) {
    const sendingMode = yield select(signaturesSelectors.getSendingMode)
    const activeSendingMode =
      sendingMode === "newMail"
        ? { newMail: true, inResponse: false }
        : { newMail: false, inResponse: true }

    yield all([
      put(setActiveSendingMode(activeSendingMode)),
      put(
        setEditingBuilderSignatureWeighting(DEFAULT_SIGNATURE_WEIGHTING_VALUE),
      ),
    ])
  } else {
    const activeSignature = yield select(
      builderSignaturesSelectors.getBuilderSignatureById(action.payload),
    )
    yield all([
      put(
        setActiveSendingMode({
          newMail: activeSignature.newMail,
          inResponse: activeSignature.inResponse,
        }),
      ),
      put(setEditingBuilderSignatureWeighting(activeSignature.weighting)),
    ])
  }

  yield put(setActiveBuilderSignature(action.payload))
}

export function* duplicateBuilderSignatureAsync(
  action: PayloadAction<BuilderSignature>,
) {
  const duplicatedName = `${action.payload.name} (copie)`

  const builderSignatureData = {
    ...action.payload,
    affectedUsers: [],
    affectedGroups: [],
    name: duplicatedName,
    json: updateJsonTemplateName(action.payload.json, duplicatedName),
    activated: false,
  }

  delete builderSignatureData.id

  const result: TypedResult<BuilderSignatureDto> = yield call(
    createBuilderSignatureApi,
    builderSignatureData,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const builderSignature = new BuilderSignature(result.result)

  yield put(createBuilderSignatureSuccess(builderSignature))

  yield put(
    addNotification(
      new Notification("Signature dupliquée avec succès.", "SUCCESS"),
    ),
  )
}

export const builderSignaturesWatchers = [
  takeLatest(
    fetchBuilderSignaturesAction.type,
    withSagaErrorHandler(
      fetchBuilderSignaturesAsync,
      fetchBuilderSignaturesFailure(),
    ),
  ),
  takeLatest(
    createBuilderSignature.type,
    withSagaErrorHandler(createBuilderSignatureAsync),
  ),
  takeLatest(
    updateBuilderSignature.type,
    withSagaErrorHandler(updateBuilderSignatureAsync),
  ),
  takeLatest(
    deleteBuilderSignatureAction.type,
    withSagaErrorHandler(deleteBuilderSignatureAsync),
  ),
  takeLatest(
    loadBuilderSignatureUsersAction.type,
    withSagaErrorHandler(fetchBuilderSignaturesUsersAsync),
  ),
  takeLatest(
    loadBuilderSignatureGroupsAction.type,
    withSagaErrorHandler(fetchBuilderSignaturesGroupsAsync),
  ),
  takeLatest(
    toggleBuilderSignatureAction.type,
    withSagaErrorHandler(updateBuilderSignatureActivation),
  ),
  takeEvery(
    loadBuilderSignatureAffectations.type,
    withSagaErrorHandler(affectationBuilderSignatureAsync),
  ),
  takeLatest(
    sendBuilderSignaturePreviewAction.type,
    withSagaErrorHandler(sendBuilderSignaturePreview),
  ),
  takeLatest(
    initActiveBuilderSignatureAction.type,
    withSagaErrorHandler(initActiveBuilderSignatureAsync),
  ),
  takeLatest(
    duplicateBuilderSignatureAction.type,
    withSagaErrorHandler(duplicateBuilderSignatureAsync),
  ),
]
