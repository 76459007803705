import { FunctionComponent } from "react"

import { useSelector } from "react-redux"

import { trackingSelectors } from "features/Tracking"

import { defineMessages, useIntl } from "react-intl"

import TrackingCounter from "../TrackingCounter"

const messages = defineMessages({
  title: {
    id: "ConnectedSignatureCounter.Title",
    defaultMessage: "Signatures",
  },
  circle: { id: "Circle", defaultMessage: "Cercle" },
  max: { id: "Max", defaultMessage: "Max" },
  meter: { id: "Meter", defaultMessage: "Mètre" },
  treshold: { id: "Threshold", defaultMessage: "Seuil" },
})

const SignaturesTrackingCounter: FunctionComponent = () => {
  const intl = useIntl()

  const dataNumber = useSelector(trackingSelectors.getNbSignatures)
  const title = intl.formatMessage({ id: "ConnectedSignatureCounter.Title" })

  const renderTitle = () => {
    return `${title} (${dataNumber})`
  }

  return <TrackingCounter title={renderTitle()} />
}

export default SignaturesTrackingCounter
