import { useSelector } from "react-redux"
import { GlobalStates } from "store"

import { getFeature } from "../FeatureTogglrSelectors"

import { Feature } from "../FeatureTogglrModels"

import { featureNames } from "config/config.features"

/**
 * Hook d'accès aux features
 */
export const useFeatureTogglr = (featureName: featureNames): Feature | null => {
  // Selectors
  const feature = useSelector<GlobalStates, Feature | null>((state) =>
    getFeature(state, featureName),
  )
  return feature
}
