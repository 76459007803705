import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import SubHeaderTabs from "components/SubHeaderTabs"

const messages = defineMessages({
  users: { id: "UsersPage.UsersTab", defaultMessage: "Utilisateurs" },
  groups: { id: "UsersPage.GroupsTab", defaultMessage: "Groupes" },
})

const tabs = [
  { id: "users", label: messages.users },
  { id: "groups", label: messages.groups },
]

const UsersPageHeader: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="users-page-header">
      <div />
      <div className="users-page-header__tabs">
        <SubHeaderTabs
          availableTabs={tabs.map((tab) => tab.id)}
          defaultTab={"users"}
        >
          {tabs.map((tab) => (
            <SubHeaderTabs.Tab key={tab.id} tabName={tab.id}>
              {formatMessage(tab.label)}
            </SubHeaderTabs.Tab>
          ))}
        </SubHeaderTabs>
      </div>
      <div />
    </div>
  )
}

export default UsersPageHeader
