import { FunctionComponent, ReactNode, RefObject } from "react"

import ScenarioCardImages, {
  ScenarioCardImagesType,
} from "./components/ScenarioCardImages"

import ScenarioCardDetails, {
  ScenarioCardDetailsType,
} from "./components/ScenarioCardDetails"

import ScenarioCardInfos, {
  ScenarioCardInfosType,
} from "./components/ScenarioCardInfos"

import ScenarioCardAssignationsCount, {
  ScenarioCardAssignationsCountType,
} from "./components/ScenarioCardAssignationsCount"

import ScenarioCardType, {
  ScenarioCardTypeType,
} from "./components/ScenarioCardType"

import ScenarioCardName, {
  ScenarioCardNameType,
} from "./components/ScenarioCardName"

import ScenarioCardDate, {
  ScenarioCardDateType,
} from "./components/ScenarioCardDate"

import ScenarioCardControls, {
  ScenarioCardControlsType,
} from "./components/ScenarioCardControls"

import ScenarioCardToggle, {
  ScenarioCardToggleType,
} from "./components/ScenarioCardToggle"

import ScenarioCardWeight, {
  ScenarioCardWeightType,
} from "./components/ScenarioCardWeight"

import ScenarioCardActions, {
  ScenarioCardActionsType,
} from "./components/ScenarioCardActions"

import ScenarioCardEditAction, {
  ScenarioCardEditActionType,
} from "./components/ScenarioCardEditAction"

import ScenarioCardAssignAction, {
  ScenarioCardAssignActionType,
} from "./components/ScenarioCardAssignAction"

import ScenarioCardDuplicateAction, {
  ScenarioCardDuplicateActionType,
} from "./components/ScenarioCardDuplicateAction"

import ScenarioCardDeleteAction, {
  ScenarioCardDeleteActionType,
} from "./components/ScenarioCardDeleteAction"

interface ScenarioCardLayoutComposition {
  Images: ScenarioCardImagesType
  Details: ScenarioCardDetailsType
  Infos: ScenarioCardInfosType
  Assignations: ScenarioCardAssignationsCountType
  Type: ScenarioCardTypeType
  Name: ScenarioCardNameType
  Date: ScenarioCardDateType
  Controls: ScenarioCardControlsType
  Toggle: ScenarioCardToggleType
  Weight: ScenarioCardWeightType
  Actions: ScenarioCardActionsType
  Edit: ScenarioCardEditActionType
  Assign: ScenarioCardAssignActionType
  Duplicate: ScenarioCardDuplicateActionType
  Delete: ScenarioCardDeleteActionType
}

interface ScenarioCardLayoutProps {
  children: ReactNode
  cardRef?: RefObject<HTMLDivElement>
}

type ScenarioCardLayoutType = FunctionComponent<ScenarioCardLayoutProps> &
  ScenarioCardLayoutComposition

const ScenarioCardLayout: ScenarioCardLayoutType = ({ children, cardRef }) => {
  return (
    <div className="scenario-card" ref={cardRef}>
      {children}
    </div>
  )
}

ScenarioCardLayout.Images = ScenarioCardImages
ScenarioCardLayout.Details = ScenarioCardDetails
ScenarioCardLayout.Infos = ScenarioCardInfos
ScenarioCardLayout.Assignations = ScenarioCardAssignationsCount
ScenarioCardLayout.Type = ScenarioCardType
ScenarioCardLayout.Name = ScenarioCardName
ScenarioCardLayout.Date = ScenarioCardDate
ScenarioCardLayout.Controls = ScenarioCardControls
ScenarioCardLayout.Toggle = ScenarioCardToggle
ScenarioCardLayout.Weight = ScenarioCardWeight
ScenarioCardLayout.Actions = ScenarioCardActions
ScenarioCardLayout.Edit = ScenarioCardEditAction
ScenarioCardLayout.Assign = ScenarioCardAssignAction
ScenarioCardLayout.Duplicate = ScenarioCardDuplicateAction
ScenarioCardLayout.Delete = ScenarioCardDeleteAction

export default ScenarioCardLayout
