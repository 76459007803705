import { TypedResult } from "core/CoreModels"
import PortalApi from "core/services/portalApi"
import ServiceApi from "core/services/serviceApi"

export function getServiceVersion(): Promise<TypedResult<unknown>> {
  return ServiceApi.request("GET", "/infos")
}

export function getPortalVersion(): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", "/infos")
}

export function fetchConfig(): Promise<TypedResult<string>> {
  return PortalApi.request("GET", "/config")
}

export function getTranslations(): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", "/translation")
}

export function recordGaApi(
  source: string,
  hostname: string,
  pageName: string,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("POST", "/ga", {
    data: {
      Source: source,
      HostName: hostname,
      PageName: pageName,
    },
  })
}
