import { createAction } from "@reduxjs/toolkit"

export const checkCodePromoAction = createAction(
  "enrollment/checkCodePromo",
  function prepare(codePromo: string) {
    return { payload: codePromo }
  },
)

export const getStripePaymentMethodsAction = createAction(
  "enrollment/getStripePaymentMethods",
)

export const deleteStripePaymentMethodAction = createAction(
  "enrollment/deleteStripePaymentMethod",
  function prepare(methodId: string) {
    return { payload: methodId }
  },
)

export const setStripeDefaultPaymentMethodAction = createAction(
  "enrollment/setStripeDefaultPaymentMethod",
  function prepare(methodId: string) {
    return { payload: methodId }
  },
)

export const updateBillingInformationsAction = createAction(
  "enrollment/updateBillingInformations",
)
