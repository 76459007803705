import { FunctionComponent, MouseEvent, ReactNode } from "react"

import classNames from "classnames"

import { ReactComponent as CupIcon } from "assets/icon-cup.svg"

export interface PodiumAction {
  id: string
  icon: ReactNode
  action?: (e: MouseEvent<HTMLButtonElement>) => void
}

export interface IPodiumItem {
  id: number
  visual: string
  name: string
  handleSelect: () => void
  actions: PodiumAction[]
}

export interface PodiumItemProps {
  type: "gold" | "silver" | "bronze"
  isSelected: boolean
  roundedAvatar?: boolean
  largeAvatar?: boolean
}

const PodiumItem: FunctionComponent<PodiumItemProps & IPodiumItem> = ({
  type,
  visual,
  name,
  actions,
  isSelected,
  handleSelect,
  roundedAvatar,
  largeAvatar,
}) => {
  return (
    <div className={classNames(`podium__${type}`)}>
      <div className="podium__icon">
        <CupIcon className={classNames(type)} />
      </div>
      <div
        className={classNames("podium__user", isSelected && "is-selected")}
        onClick={handleSelect}
      >
        <div className="podium__infos">
          <img
            className={classNames(
              "podium__infos--avatar",
              roundedAvatar && "is-rounded",
              largeAvatar && "is-large",
              visual === undefined && "is-empty",
            )}
            src={visual}
          />
          <p className="podium__infos--name">{name}</p>
        </div>
        <div className="podium__actions">
          {actions.map((action) => (
            <button
              className="podium__actions--btn"
              type="button"
              key={action.id}
              onClick={action.action}
            >
              {action.icon}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PodiumItem
