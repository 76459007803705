import { FunctionComponent } from "react"

import RegisterLeftPanelCallToAction from "./components/RegisterLeftPanelCallToAction"
import RegisterLeftPanelCard from "./components/RegisterLeftPanelCard"
import RegisterLeftPanelMessage from "./components/RegisterLeftPanelMessage"

const RegisterLeftPanel: FunctionComponent = () => {
  return (
    <>
      <div className="register-left-panel">
        <RegisterLeftPanelMessage />
        <RegisterLeftPanelCard />
      </div>
      <RegisterLeftPanelCallToAction />
    </>
  )
}

export default RegisterLeftPanel
