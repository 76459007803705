import { FunctionComponent } from "react"
import { defineMessages, useIntl } from "react-intl"
import classnames from "classnames"

import { getBase64, getSizeOfImageFile } from "utils/ImageUtils"
import { fromBlobToFile } from "features/Storage/services/fileService"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import Alert from "components/Alert"
import ErrorBoundary from "components/ErrorBoundary"

import CampaignScenarioCardList from "features/Scenarios/components/CampaignScenarioCardList"
import BannerUploadCard from "features/Scenarios/components/BannerUploadCard"

interface CampaignFormScenarioProps {
  editable?: boolean
}

const messages = defineMessages({
  info: {
    id: "CampaignPreview.Informations",
    defaultMessage:
      "Nous préconisons un format par défaut de 600x150px. Cependant, sentez-vous libre dans le choix de la taille et du format de votre image. Qu’il soit portrait, paysage ou carré...",
  },
})

const CampaignFormScenario: FunctionComponent<CampaignFormScenarioProps> = ({
  editable = true,
}) => {
  const { formatMessage } = useIntl()

  const {
    editingCampaign,
    updateEditingCampaign,
    updateEditingCampaignVisual,
  } = useScenarioFormContext()

  const handleChange = (reorderedItems) => {
    const banners = reorderedItems.map((item) => item.data)
    for (let order = 0; order < banners.length; order++) {
      banners[order].order = order
    }
    updateEditingCampaign({ banners })
  }

  const handleSave = (banner) => {
    const updatedIndex = editingCampaign.banners.findIndex(
      (existingbanner) => existingbanner.draggableId === banner.draggableId,
    )
    editingCampaign.banners[updatedIndex] = banner
    updateEditingCampaign({ banners: [...editingCampaign.banners] })
  }

  const handleDelete = (banner) => {
    updateEditingCampaign({
      banners: editingCampaign.banners.filter(
        (item) => item.draggableId !== banner.draggableId,
      ),
    })
  }

  const handleUpload = async (blobFile: Blob) => {
    const file = fromBlobToFile(blobFile)
    const size = await getSizeOfImageFile(file)
    const handleFileChangeCallback = (imageAsBase64Url) => {
      updateEditingCampaignVisual({
        imageUrl: imageAsBase64Url,
        width: size.width,
        height: size.height,
      })
    }
    getBase64(file, handleFileChangeCallback)
  }

  return (
    <ErrorBoundary>
      <div
        className={classnames("campaignform__images campaignform__scenario", {
          "campaignform__scenario--editable": editable,
        })}
      >
        {editable && editingCampaign.banners.length === 0 && (
          <div className="campaignform__disclaimer">
            <Alert severity="info" title={formatMessage(messages.info)} />
          </div>
        )}
        <>
          {editingCampaign.banners.length > 0 && (
            <>
              <h3>
                <span className="count">{editingCampaign.banners.length}</span>
                <span>
                  {editingCampaign.banners.length > 1
                    ? "Visuels chargés"
                    : "Visuel chargé"}
                </span>
              </h3>

              <div className="scenarios__content">
                <div
                  className={classnames("campaignform__scenarios-container", {
                    "campaignform__scenarios-container--scrollable":
                      editable && editingCampaign.banners.length >= 3,
                  })}
                >
                  <CampaignScenarioCardList
                    scenario={editingCampaign.banners}
                    editable={editable}
                    onChange={handleChange}
                    onSave={handleSave}
                    onDelete={handleDelete}
                  />
                  <div
                    className={classnames({
                      "campaignform__scenarios-container--bottom-shadow":
                        editable && editingCampaign.banners.length >= 3,
                    })}
                  ></div>
                </div>
              </div>
            </>
          )}
          <div
            className={classnames({
              "scenariosform__mini-upload": editingCampaign.banners.length >= 3,
            })}
          >
            {editable && <BannerUploadCard onFileChange={handleUpload} />}
          </div>
        </>
      </div>
    </ErrorBoundary>
  )
}

export default CampaignFormScenario
