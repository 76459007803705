import React, { FunctionComponent } from "react"

const O365Icon: FunctionComponent = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="white" />
      <path
        d="M21.6308 21.0195C21.6581 20.8657 21.6651 9.75048 21.6391 9.50792C19.5271 9.95779 17.4143 10.4079 15.3006 10.8583V18.6937C14.2104 19.0748 13.123 19.4546 12.0382 19.8333C12.0318 19.8306 12.0256 19.8276 12.0196 19.8243C12.0148 19.8212 12.0087 19.8165 12.0083 19.8122C12.0039 19.7828 12.0014 19.7533 12.0005 19.7237C12.0001 16.5578 11.9999 13.3942 12.0001 10.2332C12.0452 10.1926 12.1031 10.1653 12.1661 10.155C15.2699 9.11553 18.3741 8.0765 21.4787 7.03786C21.5805 6.99674 21.6952 6.98883 21.8029 7.01552C23.5061 7.45758 25.2103 7.89676 26.9154 8.33307C26.9402 8.33978 26.9649 8.3476 27 8.35771V21.7021C26.5106 21.8238 26.0255 21.9454 25.54 22.0651C24.3041 22.3699 23.0683 22.6746 21.8324 22.9794C21.7191 23.0103 21.5974 23.0064 21.4869 22.968C18.4339 21.9592 15.3803 20.9522 12.3261 19.9472C12.2621 19.9298 12.2038 19.8988 12.1561 19.8568C15.3145 20.2445 18.4727 20.6321 21.6308 21.0195Z"
        fill="#D64F27"
      />
      <path
        d="M11.1051 29.399H10.4873V28.8906H11.0797C11.1145 28.8579 11.1046 28.8269 11.1046 28.7993C11.1057 28.6181 11.1051 28.4365 11.1053 28.2551C11.0998 28.0402 11.1461 27.8274 11.24 27.6353C11.4185 27.2574 11.7882 27.0139 12.1973 27.0049C12.3397 26.9932 12.483 27.0059 12.6213 27.0423C12.6453 27.0491 12.6686 27.0588 12.695 27.0682V27.5943C12.6669 27.6086 12.6475 27.5855 12.6259 27.5767C12.4725 27.5118 12.3037 27.4959 12.1414 27.5312C11.9896 27.5608 11.8598 27.6613 11.7905 27.8031C11.7434 27.8953 11.7146 27.9962 11.7059 28.0998C11.6834 28.3581 11.6991 28.617 11.6954 28.8816H13.1101C13.1238 28.8582 13.1282 28.8303 13.1225 28.8036C13.1233 28.6091 13.1204 28.4145 13.1235 28.2202C13.1177 27.8851 13.2454 27.562 13.4772 27.3259C13.6612 27.1389 13.9046 27.0259 14.163 27.0077C14.3145 26.9914 14.4676 27.0011 14.616 27.0363C14.6514 27.0404 14.6851 27.0541 14.7137 27.0758V27.6049C14.6226 27.5648 14.5271 27.5362 14.4293 27.5197C14.3318 27.5068 14.2328 27.5124 14.1372 27.5363C13.9833 27.5729 13.8557 27.6826 13.7937 27.8318C13.7457 27.9381 13.7195 28.0533 13.7165 28.1704C13.7117 28.397 13.7136 28.624 13.713 28.8509C13.7148 28.8633 13.7179 28.8754 13.7221 28.8872H14.5659V29.3964H13.7128V32.6351H13.125C13.1206 32.4838 13.1235 32.3299 13.1229 32.1761C13.1225 32.0239 13.1229 31.8715 13.1229 31.7193V29.4022H11.696V32.6349H11.1073C11.1026 32.5202 11.1059 32.4019 11.1053 32.2839C11.1047 32.1642 11.1051 32.0442 11.1051 31.9243V29.399L11.1051 29.399Z"
        fill="#D64F27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1596 28.6003C4.93667 29.0451 4.85795 29.5195 4.85211 30.0203L4.85211 30.0203C4.84983 30.3897 4.91479 30.7565 5.04403 31.104C5.27991 31.711 5.68788 32.1656 6.30975 32.4284C6.79015 32.6269 7.32031 32.6841 7.83409 32.5929C8.76924 32.4438 9.39739 31.9273 9.72383 31.07C9.83203 30.7756 9.89531 30.4675 9.91167 30.1554C9.95296 29.6872 9.88742 29.2159 9.7197 28.7749C9.44405 28.0854 8.94981 27.6118 8.21259 27.3911C7.76696 27.2625 7.29466 27.2471 6.84106 27.3461C6.07133 27.5007 5.50411 27.9131 5.1596 28.6003ZM9.2584 29.8625C9.25953 29.8891 9.26057 29.9136 9.26173 29.9352C9.25611 30.3464 9.21242 30.7187 9.04805 31.0672C8.75996 31.6769 8.26907 32.0111 7.57537 32.0698C7.26226 32.1033 6.94544 32.0604 6.65394 31.9448C6.1883 31.7517 5.88318 31.4109 5.69809 30.9628C5.53693 30.5534 5.4786 30.1129 5.52792 29.6774C5.55345 29.3362 5.65897 29.0052 5.83646 28.7093C6.20571 28.107 6.75789 27.8213 7.48112 27.836C7.69597 27.8378 7.90893 27.875 8.11092 27.9459C8.50786 28.0904 8.83 28.3804 9.00761 28.753C9.14588 29.0357 9.22695 29.3413 9.24647 29.6535C9.25264 29.7268 9.25576 29.8003 9.2584 29.8625Z"
        fill="#D64F27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0013 27.9696V27.4305C30.9367 27.4004 30.8693 27.3764 30.8001 27.3589C30.5684 27.3014 30.3296 27.2758 30.0907 27.2829C29.6852 27.2827 29.2914 27.416 28.9726 27.6615C28.6635 27.9053 28.4233 28.2226 28.2752 28.5832C28.1098 28.9692 28.0098 29.3792 27.9794 29.7967C27.9348 30.242 27.9555 30.6912 28.0408 31.1308C28.0999 31.4491 28.2277 31.7513 28.4157 32.0173C28.6772 32.3956 29.1104 32.6255 29.5769 32.6335C30.3173 32.6628 30.9237 32.2917 31.191 31.6309C31.3768 31.1854 31.3863 30.6881 31.2179 30.236C31.0812 29.8267 30.7503 29.5076 30.3301 29.38C30.1169 29.316 29.893 29.2932 29.6709 29.3129C29.514 29.3222 29.3601 29.3595 29.2169 29.4228C28.9754 29.5339 28.7768 29.718 28.6503 29.9479C28.6469 29.9538 28.6442 29.9604 28.6413 29.9671C28.6324 29.9885 28.6228 30.0114 28.5892 30.0174C28.5853 29.6515 28.6395 29.2873 28.7497 28.9376C28.8372 28.6587 28.9869 28.4023 29.1879 28.1869C29.478 27.8847 29.8384 27.7617 30.2579 27.7897C30.4932 27.8055 30.7235 27.8633 30.9376 27.9603C30.9414 27.962 30.9452 27.964 30.949 27.966C30.9649 27.9745 30.981 27.9831 31.0013 27.9696ZM28.9683 31.809C28.7541 31.5703 28.6333 31.265 28.6275 30.9473C28.6249 30.3745 28.9402 29.9597 29.4087 29.8432C29.6285 29.7867 29.8609 29.7983 30.0737 29.8765C30.3604 29.9814 30.5362 30.1924 30.6285 30.4697C30.732 30.7713 30.7385 31.0968 30.6473 31.4022C30.5605 31.694 30.386 31.9246 30.0944 32.0489C29.7061 32.2212 29.2483 32.1237 28.9683 31.809Z"
        fill="#D64F27"
      />
      <path
        d="M32.0055 29.9007C32.0673 29.0344 32.1295 28.1614 32.1922 27.2817H34.7364V27.807C34.7352 27.809 34.7337 27.8108 34.7319 27.8123C34.7295 27.8146 34.7265 27.8163 34.7233 27.8173C34.717 27.8196 34.7103 27.8209 34.7036 27.8213H32.7434C32.702 28.3338 32.6664 28.8431 32.6328 29.3506C32.6674 29.364 32.7054 29.3671 32.7418 29.3596C33.0064 29.3523 33.2712 29.3351 33.5353 29.3711C33.8711 29.4073 34.1901 29.5315 34.4572 29.7301C34.7254 29.9352 34.9044 30.2286 34.9595 30.5533C35.0298 30.883 35.0076 31.2248 34.8951 31.5437C34.716 32.025 34.3582 32.3373 33.8625 32.5122C33.5513 32.6221 33.2272 32.6421 32.8991 32.6333C32.6543 32.6318 32.4109 32.5981 32.1758 32.5329C32.0981 32.5096 32.0228 32.4797 31.9507 32.4436C31.9223 32.4295 31.9002 32.4146 31.9004 32.3782C31.9017 32.184 31.9011 31.9897 31.9011 31.7901C31.9296 31.7907 31.957 31.8009 31.9783 31.8191C32.2509 31.9803 32.5581 32.0802 32.8767 32.1114C33.1479 32.1437 33.4232 32.1063 33.6744 32.0029C34.051 31.854 34.3079 31.5141 34.3383 31.1245C34.3588 30.9661 34.3478 30.8055 34.306 30.651C34.224 30.3492 33.9936 30.1047 33.6888 29.9961C33.5027 29.9236 33.3044 29.8841 33.1036 29.8795C32.7606 29.8666 32.4171 29.8775 32.0757 29.9121C32.0518 29.9192 32.0257 29.915 32.0055 29.9007H32.0055Z"
        fill="#D64F27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1559 30.8193H22.8819L22.8819 30.8193C22.8819 30.7659 22.8824 30.7138 22.883 30.6626C22.8842 30.5494 22.8853 30.4406 22.8804 30.3321C22.8735 30.0954 22.8273 29.8613 22.7434 29.639C22.5912 29.2515 22.3356 28.9528 21.9272 28.8061C21.4752 28.6332 20.9689 28.6547 20.5343 28.8653C20.0759 29.0829 19.7965 29.4513 19.6301 29.9087C19.5008 30.2643 19.4842 30.6312 19.5182 31.0019C19.5356 31.2185 19.5865 31.4313 19.6694 31.6332C19.8892 32.1531 20.2733 32.4887 20.8496 32.596C21.1934 32.6545 21.5458 32.647 21.8866 32.5737C22.1132 32.5295 22.33 32.4468 22.5269 32.3293C22.5846 32.3027 22.6187 32.2434 22.6117 32.1818C22.6078 32.1018 22.6086 32.0216 22.6093 31.9413C22.6096 31.8984 22.61 31.8555 22.6097 31.8127C22.6096 31.8055 22.6101 31.7982 22.6107 31.7908C22.6122 31.7694 22.6137 31.7479 22.5999 31.7285C22.5986 31.7285 22.5973 31.7283 22.5961 31.7282C22.5936 31.7279 22.5914 31.7277 22.5899 31.7287C22.5875 31.7301 22.5851 31.7316 22.5827 31.733C22.5598 31.7469 22.537 31.7608 22.5146 31.7757C22.1248 32.0487 21.6414 32.165 21.1654 32.1003C20.8721 32.0707 20.6039 31.9263 20.4226 31.7005C20.3071 31.5528 20.2287 31.381 20.1936 31.1987C20.162 31.0747 20.1493 30.9469 20.1559 30.8193ZM22.0354 29.5603C22.1935 29.782 22.2419 30.0324 22.2443 30.3032H20.1584C20.221 29.9292 20.3723 29.6091 20.6955 29.3889C21.0046 29.1787 21.3428 29.1632 21.6902 29.2881C21.8316 29.3418 21.9523 29.4369 22.0354 29.5603Z"
        fill="#D64F27"
      />
      <path
        d="M24.8298 30.1094V29.6293C24.8656 29.6174 24.9038 29.614 24.9413 29.6193C25.1455 29.6173 25.3499 29.6238 25.5537 29.6024C25.7246 29.5883 25.8921 29.5483 26.0502 29.4838C26.352 29.366 26.5529 29.0854 26.5613 28.7697C26.5738 28.6393 26.5649 28.5078 26.5349 28.3802C26.4537 28.0642 26.2362 27.8862 25.9145 27.8222C25.4738 27.7308 25.0137 27.8172 24.6398 28.0614C24.6177 28.0751 24.596 28.0898 24.5743 28.1041C24.5556 28.1165 24.5367 28.1288 24.5028 28.1513C24.5019 28.0459 24.5011 27.9529 24.5006 27.8599C24.5002 27.7678 24.5006 27.6754 24.5006 27.5811C24.6785 27.4755 24.8731 27.3991 25.0766 27.355C25.4516 27.2611 25.8445 27.2574 26.2214 27.3442C26.6023 27.44 26.9021 27.6445 27.0806 27.9937C27.1984 28.2247 27.2109 28.4719 27.1843 28.7224C27.1698 28.8687 27.1296 29.0115 27.0653 29.1446C26.9387 29.397 26.7244 29.598 26.4602 29.712C26.3803 29.7491 26.2957 29.7766 26.213 29.8083C26.1926 29.8162 26.1666 29.8138 26.152 29.8437C26.2775 29.8609 26.4011 29.8892 26.5214 29.9281C26.9283 30.0734 27.2379 30.3255 27.3412 30.7424C27.478 31.294 27.3427 31.7861 26.9019 32.1791C26.648 32.4011 26.3319 32.5449 25.9937 32.5923C25.5638 32.6656 25.1225 32.6446 24.7021 32.5308C24.5787 32.496 24.4601 32.4471 24.3486 32.385C24.3144 32.3722 24.2921 32.3399 24.293 32.3042C24.2954 32.1195 24.2947 31.9346 24.2956 31.7495C24.2956 31.7444 24.3016 31.7394 24.3084 31.7288C24.3374 31.7478 24.3664 31.7669 24.3954 31.7861C24.8614 32.1024 25.4463 32.2057 25.9972 32.069C26.1514 32.0328 26.2952 31.963 26.4176 31.865C26.6165 31.7123 26.7392 31.4848 26.7552 31.2391C26.7687 31.1056 26.758 30.9708 26.7234 30.8409C26.6681 30.631 26.5287 30.4513 26.3363 30.3417C26.1617 30.2444 25.9691 30.1811 25.7695 30.1555C25.4982 30.1223 25.2245 30.1102 24.9512 30.1191C24.9105 30.1245 24.8691 30.1212 24.8298 30.1094L24.8298 30.1094Z"
        fill="#D64F27"
      />
      <path
        d="M19.2214 28.8855V29.4844C19.1806 29.4844 19.1561 29.4555 19.127 29.4382C18.8648 29.2744 18.5592 29.1954 18.2511 29.2117C17.7855 29.2373 17.45 29.4679 17.2224 29.8678C17.1233 30.0465 17.0625 30.2442 17.044 30.4482C17.0083 30.7253 17.0317 31.0069 17.1125 31.2743C17.2642 31.7762 17.7257 32.1178 18.2465 32.1137C18.5581 32.1206 18.8642 32.0306 19.1232 31.856C19.1489 31.8331 19.1794 31.8161 19.2123 31.8064V32.362C19.1946 32.3819 19.1721 32.3968 19.147 32.4052C18.5656 32.6884 17.9743 32.7185 17.3828 32.4481C16.9738 32.262 16.6644 31.9071 16.5338 31.4746C16.3275 30.8893 16.3681 30.2445 16.646 29.6901C16.9346 29.1067 17.4215 28.7888 18.0642 28.7108C18.3979 28.6643 18.7377 28.6982 19.0557 28.8097C19.1135 28.8289 19.169 28.8543 19.2214 28.8855Z"
        fill="#D64F27"
      />
      <path
        d="M15.549 32.6351H15.0151C14.995 32.5637 14.9872 29.1778 15.0053 28.9883C15.186 28.9697 15.3677 28.9673 15.5488 28.981C15.5611 29.0544 15.5627 32.5336 15.549 32.6351Z"
        fill="#D64F27"
      />
      <path
        d="M14.9956 27.4118C15.0048 27.1778 15.1972 26.9946 15.4279 27.0001C15.6585 27.0057 15.8421 27.198 15.8404 27.4322C15.8369 27.5456 15.789 27.6529 15.7074 27.7303C15.6257 27.8078 15.5171 27.8491 15.4054 27.845C15.2934 27.846 15.186 27.8002 15.1084 27.7182C15.0309 27.6362 14.99 27.5253 14.9956 27.4118Z"
        fill="#D64F27"
      />
    </svg>
  )
}

export default O365Icon
