import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import ConnectedUserPropertiesPanel from "features/Users/components/UserPropertiesPanel"
import SendingModesSelectors from "features/Signatures/components/SendingModesSelectors"

import ConnectedLogoPicker from "./LogoPicker"
import ConnectedSocialShareList from "./ConnectedSocialShare"
import ConnectedSignatureNameInput from "./ConnectedSignatureNameInput"
import AccordionItem from "components/Accordion"
import ConnectedGallery from "./ConnectedGallery"
import SignatureWeighting from "./SignatureWeighting"

import ConnectedColorPicker from "features/Signatures/SignatureTemplate/ConnectedColorPicker"

import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { featureNames } from "config/config.features"

export const messages = defineMessages({
  /* Sections Translations */
  SectionGallery: {
    id: `SignatureEditor.SectionGallery`,
    defaultMessage: "Sélectionnez 1 de nos modèles.",
  },
  SectionLogo: {
    id: `SignatureEditor.SectionLogo`,
    defaultMessage: "Importer votre logo",
  },
  SectionProps: {
    id: `SignatureEditor.SectionProps`,
    defaultMessage: "Choisir vos propriétés",
  },
  SectionColor: {
    id: `SignatureEditor.SectionColor`,
    defaultMessage: "Couleur",
  },
  SectionSocials: {
    id: `SignatureEditor.SectionSocials`,
    defaultMessage: "Renseigner vos réseaux sociaux",
  },
  SectionSocialsDescription: {
    id: `SignatureEditor.SectionSocialsDescription`,
    defaultMessage:
      "Les réseaux sociaux sont devenues de vraies vitrines et vous permettent de propulser votre communication encore plus rapidement qu’avec un simple site internet.",
  },
  SectionNewMailInResponse: {
    id: "SignatureEditor.SectionNewMailInResponse",
    defaultMessage: "Modalité d'envoi",
  },
  SectionWeighting: {
    id: "SignatureEditor.SectionWeighting",
    defaultMessage: "Niveau de priorité",
  },
})

const SignaturePropertiesSideBar: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )
  return (
    <div className="max-content sig-form__sidebar" data-tour="sig-properties">
      <AccordionItem
        initialOpen={true}
        title={formatMessage(messages.SectionGallery)}
      >
        <div className="signature-editor__section signature-editor__gallery">
          <ConnectedGallery />
        </div>
      </AccordionItem>
      <AccordionItem
        initialOpen={false}
        title={formatMessage(messages.SectionLogo)}
      >
        <div className="signature-editor__section signature-editor__logo-picker">
          <ConnectedLogoPicker />
        </div>
      </AccordionItem>
      <AccordionItem
        initialOpen={false}
        title={formatMessage(messages.SectionProps)}
      >
        <div className="signature-editor__section">
          <ConnectedUserPropertiesPanel />
        </div>
      </AccordionItem>
      {/* Embarquement de l'accrodeon pour ajouter l'indicateur de champs obligatoire et l'ouvrir  */}
      <ConnectedSignatureNameInput />
      {newMailInResponseFeature?.isActive && (
        <AccordionItem
          initialOpen={false}
          title={formatMessage(messages.SectionNewMailInResponse)}
        >
          <SendingModesSelectors />
        </AccordionItem>
      )}
      <AccordionItem
        initialOpen={false}
        title={formatMessage(messages.SectionWeighting)}
      >
        <SignatureWeighting />
      </AccordionItem>
      <AccordionItem
        initialOpen={false}
        title={formatMessage(messages.SectionColor)}
        emphaze={"#E91E63"}
      >
        <div className="signature-editor__section signature-editor__colors">
          <ConnectedColorPicker />
        </div>
      </AccordionItem>
      <AccordionItem
        initialOpen={false}
        title={formatMessage(messages.SectionSocials)}
      >
        <div className="signature-editor__section signatures-editor__socials">
          <div className="signature-editor__explanation bmm__text">
            {formatMessage(messages.SectionSocialsDescription)}
          </div>
          <ConnectedSocialShareList displayPreview={true} />
        </div>
      </AccordionItem>
    </div>
  )
}

export default SignaturePropertiesSideBar
