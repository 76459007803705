import React from "react"
import { Grid, TextField } from "@material-ui/core"
import { FormInput } from "../FormInput"
import { TextFieldProps } from "@material-ui/core/TextField"

export enum InputStyle {
  STANDARD = "standard",
  OUTLINED = "outlined",
  FILLED = "filled",
}

interface Props {
  inputOptions: FormInput & TextFieldProps
  value?: string
}

/**
 * Wrappeur du composant material-ui {@link https://v4-3-3.material-ui.com/components/text-fields/#textfield}
 */
const InputText: React.FC<Props> = ({ inputOptions, value }) => {
  return (
    <Grid className="input-text" item xs={inputOptions.size}>
      <TextField
        fullWidth={true}
        value={value}
        id={inputOptions.name}
        visible={inputOptions.visible}
        {...inputOptions}
      />
    </Grid>
  )
}


export default InputText
