/**
 * On utilise les hooks suffixé de `Data` en façade pour brancher nos Pages qui sont déjà responsable de la partie parsing
 * de l'Url, pour centraliser l'accès aux actions/query (CQRS) via les actions redux.
 */
import { useSelector, useDispatch } from "react-redux"

import { usersSelectors } from "../../Users"
import { groupsSelectors } from "../../Groups"
import { ScenarioMapper } from "../mappers/ScenarioMapper"
import { scenarioSelectors } from ".."
import {
  fetchScenarios as fetchScenariosAction,
  updateScenario as updateScenarioAction,
  createScenario as createScenarioAction,
} from "../ScenarioReducers"
import { ScenarioFormVm, ScenarioVm, BannerDto } from "../ScenarioModels"
import { deleteScenarioAction } from "../ScenarioActions"
import { activateScenario as activateScenarioAction } from "../ScenarioReducers"
import { LoadingStatus } from "core/CoreModels"
import { User } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import { useEffect } from "react"
import useDiffusionMode from "./useDiffusionMode"
import usePrevious from "hooks/usePrevious"

interface UseScenarioData {
  scenarios: Array<ScenarioVm>
  existingScenario: ScenarioFormVm
  loadingStatus: LoadingStatus
  visuals: Array<Omit<BannerDto, "template" | "id" | "linkUrl">>
  activateScenario: (id: number, activated: boolean) => void
  allUsers: Array<User>
  allGroups: Array<Group>
  saveScenario: (scenario: ScenarioFormVm) => void
  deleteScenario: (id: number) => void
}

/**
 * Hook d'accès aux données du state nécessaires à l'édition d'un scénario
 */
export const useScenarioData = (
  editingScenarioId?: number,
): UseScenarioData => {
  const dispatch = useDispatch()
  const { diffusionMode } = useDiffusionMode()

  const previousDiffusionMode = usePrevious(diffusionMode)

  // Selectors
  const visuals = useSelector(scenarioSelectors.getVisuals)
  const allGroups: Group[] = useSelector(groupsSelectors.getAllGroups)
  const allUsers: Array<User> = useSelector(usersSelectors.getAllUsers)
  const loadingStatus = useSelector(scenarioSelectors.getLoadingStatus)
  const scenarios = useSelector(scenarioSelectors.getAllScenarios)

  // ActionCreators
  const createScenario = (scenarioData: ScenarioFormVm) =>
    dispatch(createScenarioAction(scenarioData))
  const updateScenario = (scenarioData: ScenarioFormVm) =>
    dispatch(updateScenarioAction(scenarioData))
  const deleteScenario = (scenarioId: number) =>
    dispatch(deleteScenarioAction(scenarioId))
  const activateScenario = (scenarioId: number, activated: boolean) =>
    dispatch(activateScenarioAction({ id: scenarioId, activated }))

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.NOT_STARTED &&
      previousDiffusionMode !== diffusionMode
    ) {
      dispatch(fetchScenariosAction())
    }
  }, [loadingStatus, dispatch, diffusionMode, previousDiffusionMode])

  // Utils methods
  const existingScenario =
    scenarios &&
    editingScenarioId &&
    ScenarioMapper.toScenarioFormViewModel(
      scenarios.find((s) => s.id === editingScenarioId),
    )

  const saveScenario =
    existingScenario != null ? updateScenario : createScenario

  return {
    scenarios: scenarios.map(ScenarioMapper.toViewModel),
    existingScenario,
    loadingStatus,
    visuals,
    activateScenario,
    allUsers,
    allGroups,
    saveScenario,
    deleteScenario,
  }
}
