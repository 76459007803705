import { connect } from "react-redux"
import GlobalLoader from "components/GlobalLoader"
import { appSelectors } from "features/App"

const mapStateToProps = (state) => ({
  isLoading: appSelectors.getGlobalLoadingStatus(state),
})

interface StateProps {
  isLoading: boolean
}

const ConnectedGlobalLoader = connect<StateProps, {}, { children }>(
  mapStateToProps,
  null,
)(GlobalLoader)

export default ConnectedGlobalLoader
