import { FunctionComponent } from "react"

import classNames from "classnames"

import { Link } from "react-router-dom"

export interface NavigationData {
  active: boolean
  visible: boolean
  to?: string
  nextLabel?: string
}

interface NavigationBtnProps {
  prevData?: NavigationData
  nextData?: NavigationData
  onNext?: () => void
  onPrev?: () => void
}

export const NavigationButtons: FunctionComponent<NavigationBtnProps> = ({
  prevData,
  nextData,
  onPrev,
  onNext,
}) => {
  return (
    <div className="nav-buttons">
      {prevData.visible && (
        <Link
          to={prevData.to}
          className={classNames(
            "bmm-btn bmm-btn--outlined",
            !prevData.active && "bmm-btn--disabled",
          )}
          data-cy="scenarios__nav-button-prev"
          onClick={onPrev}
        >
          Précédent
        </Link>
      )}
      {nextData.visible && (
        <Link
          to={nextData.to}
          className={classNames(
            "bmm-btn",
            !nextData.active && " bmm-btn--disabled",
          )}
          data-cy="scenarios__nav-button-next"
          onClick={onNext}
        >
          {nextData.nextLabel ?? "Suivant"}
        </Link>
      )}
    </div>
  )
}

export default NavigationButtons
