import { FunctionComponent } from "react"

import ScenarioCardsCarousel from "./ScenarioCardCarousel"

interface ScenarioCardImagesProps {
  images: string[]
}

export type ScenarioCardImagesType = FunctionComponent<ScenarioCardImagesProps>

const ScenarioCardImages: ScenarioCardImagesType = ({ images }) => {
  return (
    <div className="scenario-card__image">
      <ScenarioCardsCarousel images={images} />
    </div>
  )
}

export default ScenarioCardImages
