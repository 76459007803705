import React from "react"
import { Stack, Box, Text, Meter, Grommet } from "grommet"

interface Props {
  value: number
  defaultColor?: string
  colorRanges?: { min: number; max: number; color: string }[]
}

class Gauge extends React.Component<Props> {
  static defaultProps = {
    value: 0,
    defaultColor: "#3D5AFE",
  }

  render() {
    const { value, defaultColor, colorRanges } = this.props

    let displayValue = 0
    let displayColor = ""
    let hasColor = false

    // Détermine la couleur en fonction de la gamme
    if (colorRanges) {
      colorRanges.forEach((range) => {
        if (value > range.min && value <= range.max) {
          hasColor = true
          displayColor = range.color
        }
      })
    }

    // Si pas de gammes ou aucune gamme ne correspond à la valeur, on utilise la couleur par défaut
    if (!hasColor) {
      displayColor = defaultColor
    }

    // La valeur affichée est limitée à 100
    displayValue = value >= 100 ? 100 : value

    return (
      <div className="gauge">
        <Grommet plain>
          <Stack anchor="center">
            <Meter
              a11yTitle={"gauge"}
              round={true}
              size="xsmall"
              thickness="8px"
              type="circle"
              values={[
                {
                  value: displayValue,
                  label: "Value",
                  color: displayColor,
                },
              ]}
            />
            <Box pad={{ bottom: "xsmall" }}>
              <Text weight="bold">{`${displayValue}%`}</Text>
            </Box>
          </Stack>
        </Grommet>
      </div>
    )
  }
}

export default Gauge
