import React from "react"
import { FormattedMessage } from "react-intl"
import config from "config/config"

const CheckoutLogos = () => (
  <div className="bank-details__secure-container">
    <div className="bank-details__secure-payment">
      <div className="bank-details__libelle">
        <i className="material-icons">lock</i>
        <FormattedMessage
          id="EnrollmentPayment.SecurePayment"
          defaultMessage="Paiement sécurisé"
        />
      </div>
      <div className="bank-details__side-item">
        <img
          src={config.cdnUrl + "/images/bmm/enrollment/icon-cb.jpg"}
          alt=""
        />
      </div>
      <div className="bank-details__side-item">
        <img
          src={config.cdnUrl + "/images/bmm/enrollment/icon-mastercard.png"}
          alt=""
        />
      </div>
      <div className="bank-details__side-item">
        <img
          src={config.cdnUrl + "/images/bmm/enrollment/icon-visa.jpg"}
          alt=""
        />
      </div>
    </div>
  </div>
)

export default CheckoutLogos
