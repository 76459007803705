import React, { useMemo, useState } from "react"

import { useSelector } from "react-redux"

import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import useGroupsData from "features/Groups/hooks/useGroupsData"
import useUsersData from "features/Users/hooks/useUsersData"

import ArrayHelper from "utils/ArrayHelper"

import PeoplePickerCollapseLists from "components/PeoplePickerCollapseLists"
import Loader from "components/Loader"

import ScenariosPeoplePicker from "features/Scenarios/components/ScenariosPeoplePicker"

import { groupsSelectors } from "features/Groups"
import { usersSelectors } from "features/Users"

import Group from "features/Groups/GroupsModels"
import { User } from "features/Users/UserModels"
import { LoadingStatus } from "core/CoreModels"

import type { Tag } from "components/PeoplePicker"

class ListItem {
  id: number
  label: string
}

const messages = defineMessages({
  searchPlaceholder: {
    id: "Campaigns.SearchPlaceholder",
    defaultMessage: "Rechercher des utilisateurs",
  },
})

const CampaignFormUsersSidebar: React.FC = () => {
  const { formatMessage } = useIntl()
  const { loadingStatus: usersLoadingStatus } = useUsersData()
  const { loadingStatus: groupsLoadingStatus } = useGroupsData()
  const { editingCampaign, updateEditingCampaign } = useScenarioFormContext()
  const [tags, setTags] = useState<Tag[]>([])
  const [search, setSearch] = useState("")

  const activeGroups: Group[] = useSelector(groupsSelectors.getLicencedGroups)
  const activeUsers = useSelector(usersSelectors.getAllUsersWithLicences)

  /* Users */
  const selectedUsers = activeUsers.filter((user) =>
    editingCampaign.userIds.includes(user.Id),
  )

  const itemToUser = (item: ListItem) =>
    activeUsers.find((user) => user.Id === item.id)

  const handleToggleUsers = (item) => {
    const user = itemToUser(item)
    const users = editingCampaign.userIds
    if (users.find((userId) => userId === user.Id)) {
      updateEditingCampaign({
        userIds: users.filter((userId) => userId !== user.Id),
      })
      setTags(tags.filter((t) => t.id !== user.Id))
    } else {
      updateEditingCampaign({ userIds: [...users, user.Id] })
      const itemTag = {
        id: user.Id,
        label: User.getFullName(user),
        type: "user",
      } as Tag
      setTags([...tags, itemTag])
    }
  }

  /* Groups */
  const selectedGroups = activeGroups.filter((group) =>
    editingCampaign.groupIds.includes(group.Id),
  )

  const activeMembers = useMemo(
    () => [
      ...new Set(
        selectedGroups.reduce((acc, group) => [...acc, ...group.MembersId], []),
      ),
    ],
    [selectedGroups],
  )

  const itemToGroup = (item: ListItem) =>
    activeGroups.find((group) => group.Id === item.id)

  const handleToggleGroups = (item) => {
    const group = itemToGroup(item)
    const groups = editingCampaign.groupIds

    if (groups.find((g) => g === group.Id)) {
      updateEditingCampaign({ groupIds: groups.filter((g) => g !== group.Id) })
      setTags(tags.filter((t) => t.id !== group.Id))
    } else {
      const groupMembersIds =
        activeGroups.find((group) => group.Id === item.id)?.MembersId || []
      const users = editingCampaign.userIds
      const remainingUsersIds = users.filter(
        (affectedUser) => !groupMembersIds.includes(affectedUser),
      )

      updateEditingCampaign({
        groupIds: [...groups, group.Id],
        userIds: remainingUsersIds,
      })

      const itemTag = {
        id: group.Id,
        label: group.Name,
        type: "group",
      } as Tag

      setTags((prevTags) => {
        const remainingTags = ArrayHelper.reject(
          prevTags,
          (tag) =>
            tag.type === "user" && groupMembersIds.includes(tag.id as number),
        )
        return [...remainingTags, itemTag]
      })
    }
  }

  const selectAllGroups = () => {
    updateEditingCampaign({
      groupIds: [...activeGroups.map((group) => group.Id)],
    })
    const userTags = tags.filter((t) => t.type === "user")

    setTags([
      ...userTags,
      ...activeGroups.map((group) => {
        return {
          id: group.Id,
          label: group.Name,
          type: "group",
        } as Tag
      }),
    ])
  }

  const selectAllUsers = () => {
    updateEditingCampaign({
      userIds: [...activeUsers.map((user) => user.Id)],
    })
    const groupTags = tags.filter((t) => t.type === "group")

    setTags([
      ...groupTags,
      ...activeUsers.map((user) => {
        return {
          id: user.Id,
          label: User.getFullName(user),
          type: "user",
        } as Tag
      }),
    ])
  }

  const removeAllUsers = () => {
    updateEditingCampaign({
      userIds: [],
    })
    const groupTags = tags.filter((t) => t.type === "group")
    setTags(groupTags)
  }

  const removeAllGroups = () => {
    updateEditingCampaign({
      groupIds: [],
    })
    const userTags = tags.filter((t) => t.type === "user")
    setTags(userTags)
  }

  return (
    <div className="campaignform__users">
      <h4>
        <FormattedMessage
          id="Campaigns.UsersTitle"
          defaultMessage="Choix des utilisateurs"
        />
      </h4>
      <Loader
        fullScreen
        isLoading={
          usersLoadingStatus === LoadingStatus.PENDING ||
          groupsLoadingStatus === LoadingStatus.PENDING
        }
      />
      <div className="search-field">
        <input
          placeholder={formatMessage(messages.searchPlaceholder)}
          className="search-field__input"
          onChange={(event) => setSearch(event.target.value)}
          value={search}
          autoFocus
        />
        {search ? (
          <i
            onClick={() => setSearch("")}
            className="search-field__icon search-field__icon--close material-icons"
          >
            close
          </i>
        ) : (
          <i className="search-field__icon search-field__icon--search material-icons">
            search
          </i>
        )}
      </div>
      <ScenariosPeoplePicker tags={tags} onSetTags={setTags} />
      <PeoplePickerCollapseLists
        groupCollapseList={{
          title: (
            <FormattedMessage id="Campaigns.Groups" defaultMessage="Groupes" />
          ),
          selectedData: selectedGroups.map((group) => {
            return { id: group.Id, label: group.Name }
          }),
          handleClick: handleToggleGroups,
          selectAll: selectAllGroups,
          removeAll: removeAllGroups,
        }}
        userCollapseList={{
          title: (
            <FormattedMessage
              id="Campaigns.Users"
              defaultMessage="Utilisateurs"
            />
          ),
          selectedData: selectedUsers.map((user) => {
            return {
              id: user.Id,
              label: User.getFullName(user),
            }
          }),
          handleClick: handleToggleUsers,
          selectAll: selectAllUsers,
          removeAll: removeAllUsers,
        }}
        search={search}
        activeMembers={activeMembers}
      />
    </div>
  )
}

export default CampaignFormUsersSidebar
