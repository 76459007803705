import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchTenantFeatures } from "features/Features/FeaturesReducers"
import {
  getAllFeatures,
  getBackgroundTasks,
  getFeatureLoadingStatus,
} from "features/Features/FeaturesSelectors"
import { updateTenantFeatureAction } from "features/Features/FeaturesActions"

import Feature from "features/Features/FeaturesModels"

import { createJobInstructionAction } from "features/Jobs/JobsActions"

import { GlobalStates } from "store/globalState"
import { LoadingStatus } from "core/CoreModels"

export const useFeatures = () => {
  const isLoaded = useSelector(getFeatureLoadingStatus)
  const isBackgroundTask = useSelector(
    (state: GlobalStates) => getBackgroundTasks(state).length > 0,
  )
  const features = useSelector(getAllFeatures)

  const dispatch = useDispatch()
  const fetchFeatures = () => dispatch(fetchTenantFeatures())
  const updateFeature = (feature: Feature) =>
    dispatch(updateTenantFeatureAction(feature))
  const createJobInstruction = (jobName) =>
    dispatch(createJobInstructionAction(jobName, false))

  useEffect(() => {
    if (isLoaded === LoadingStatus.NOT_STARTED) fetchFeatures()
  }, [])

  return {
    isLoaded,
    isBackgroundTask,
    features,
    updateFeature,
    createJobInstruction,
  }
}
