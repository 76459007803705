import { FunctionComponent } from "react"

interface HomePageTileInfosProps {
  message: string
}

export type HomePageTileInfosType = FunctionComponent<HomePageTileInfosProps>

const HomePageTileInfos: HomePageTileInfosType = ({ message }) => {
  return (
    <div className="home-tile__infos--container">
      <p className="home-tile__infos--message">{message}</p>
    </div>
  )
}

export default HomePageTileInfos
