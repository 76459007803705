import React, { FunctionComponent, ReactNode } from "react"
import { useSelector } from "react-redux"

import { IntlProvider } from "react-intl"

import moment from "moment"
import "moment/locale/fr"

import { appSelectors } from "features/App"

interface IntlProviderWrapperProps {
  children: ReactNode
}

const IntlProviderWrapper: FunctionComponent<IntlProviderWrapperProps> = ({
  children,
}) => {
  const selectedLang = useSelector(appSelectors.getSelectedLang)
  const translations = useSelector(appSelectors.getTranslations)

  moment.locale(selectedLang.toLowerCase())

  const Fragment = (props) => props.children

  const messages =
    translations?.[selectedLang.toUpperCase()]?.translations || {}

  if (translations === null) return null

  return (
    <IntlProvider
      textComponent={Fragment}
      key={selectedLang}
      locale={selectedLang}
      messages={messages}
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") return null
        throw err
      }}
    >
      {children}
    </IntlProvider>
  )
}

export default IntlProviderWrapper
