import { MutableRefObject, useEffect, useRef } from "react"

import { useIntersectionObserver } from "hooks/useIntersectionObserver"

import { useDispatch, useSelector } from "react-redux"

import { fetchScenarioAssignations } from "features/Scenarios/ScenarioReducers"

import { scenarioSelectors } from "features/Scenarios"

import { LoadingStatus } from "core/CoreModels"

interface UseScenarioAffectedDatas {
  scenarioRef: MutableRefObject<Element | null>
  loadingStatus: LoadingStatus
}

const useScenarioAffectedDatas = (
  scenarioId: number,
): UseScenarioAffectedDatas => {
  const dispatch = useDispatch()
  const scenarioRef = useRef<Element | null>(null)
  const isIntersecting = useIntersectionObserver(scenarioRef)

  const loadingStatus = useSelector(
    scenarioSelectors.getUsersCountLoadingStatus(scenarioId),
  )

  useEffect(() => {
    if (
      isIntersecting &&
      loadingStatus !== LoadingStatus.LOADED &&
      loadingStatus !== LoadingStatus.PENDING
    )
      dispatch(fetchScenarioAssignations(scenarioId))
  }, [isIntersecting])

  return { scenarioRef, loadingStatus }
}

export default useScenarioAffectedDatas
