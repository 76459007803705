import { FunctionComponent } from "react"

import { ReactComponent as IconUser } from "assets/icon-user.svg"
import { ReactComponent as IconGroup } from "assets/icon-group.svg"
import StringHelper from "utils/StringHelper"
import CloseIcon from "@material-ui/icons/Close"

interface PeopleTagProps {
  label: string
  type?: "user" | "group"
  onClick: () => void
}

const PeopleTag: FunctionComponent<PeopleTagProps> = ({
  label,
  onClick,
  type = "user",
}) => {
  return (
    <button type="button" className={`people-picker__tag--${type}`}>
      {type === "user" ? (
        <IconUser className="people-picker__icon--user" />
      ) : (
        <IconGroup className="people-picker__icon--group" />
      )}
      <span className={`people-picker__label--${type}`}>
        {StringHelper.truncateText(label)}
      </span>
      <CloseIcon className={"people-picker__icon--remove"} onClick={onClick} />
    </button>
  )
}

export default PeopleTag
