import { FunctionComponent } from "react"

import Content from "./components/ScenarioBySignaturesCardContent"
import Header from "./components/ScenarioBySignaturesCardHeader"

import { SignaturesScenBySignsFeat } from "features/Scenarios/ScenarioModels"

interface ScenarioBySignaturesCardProps {
  signature: SignaturesScenBySignsFeat
  isChecked: boolean
  onCheck: () => void
}

const ScenarioBySignaturesCard: FunctionComponent<
  ScenarioBySignaturesCardProps
> = ({ signature, isChecked, onCheck }) => {
  return (
    <div className="scen-by-sign-card">
      <Header name={signature.name} checked={isChecked} onCheck={onCheck} />
      <Content
        preview={signature.preview}
        isNewBuilder={signature.isNewBuilder}
      />
    </div>
  )
}

export default ScenarioBySignaturesCard
