import { FunctionComponent, ReactNode } from "react"

import { SelectedOption } from "components/SelectField/SelectField"

import SignaturesListLayoutHeader from "./SignaturesListLayoutHeader/SignaturesListLayoutHeader"
import SignaturesListLayoutSubHeader from "./SignaturesListLayoutSubHeader/SignaturesListLayoutSubHeader"
import SignaturesListLayoutLoadingSkeleton from "./SignaturesListLayoutLoadingSkeleton/SignaturesListLayoutLoadingSkeleton"
import SignaturesListLayoutEmptyResults from "./SignaturesListLayoutEmptyResults/SignaturesListLayoutEmptyResults"

import { FilterValuesType } from "./signaturesListLayoutUtilities"

interface SignaturesListLayoutComposition {
  LoadingSkeleton: FunctionComponent
  EmptyResults: FunctionComponent
}

interface SignaturesListLayoutProps {
  children: ReactNode
  newButton: ReactNode
  filter: FilterValuesType
  handleSearch: (searchValue: string) => void
  handleChangeFilter: (filterValue: string) => void
  handleChangeSort: (option: SelectedOption) => void
}

type SignaturesListLayoutType = SignaturesListLayoutComposition &
  FunctionComponent<SignaturesListLayoutProps>

const SignaturesListLayout: SignaturesListLayoutType = ({
  children,
  newButton,
  filter,
  handleSearch,
  handleChangeFilter,
  handleChangeSort,
}) => {
  return (
    <>
      <SignaturesListLayoutHeader>{newButton}</SignaturesListLayoutHeader>
      <SignaturesListLayoutSubHeader
        filter={filter}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        handleChangeSort={handleChangeSort}
      />
      <div className="signatures-list-layout__content">
        <div className="signatures-list-layout__content--container">
          {children}
        </div>
      </div>
    </>
  )
}

SignaturesListLayout.LoadingSkeleton = SignaturesListLayoutLoadingSkeleton
SignaturesListLayout.EmptyResults = SignaturesListLayoutEmptyResults

export default SignaturesListLayout
