import { createAction } from "@reduxjs/toolkit"

import { AvailableJobs } from "./JobsConstants"

import { JobData } from "./JobsModels"

export const createJobInstructionAction = createAction(
  "jobs/createJobInstruction",
  function prepare(instructionName: AvailableJobs, isRecurring: boolean) {
    return { payload: { instructionName, isRecurring } }
  },
)

export const deleteJobInstructionAction = createAction(
  "jobs/deleteJobInstruction",
  function prepare(instructionName: AvailableJobs, notified: boolean) {
    return { payload: { instructionName, notified } }
  },
)

export const jobFinishedAction = createAction(
  "jobs/jobFinished",
  function prepare(job: JobData) {
    return { payload: job }
  },
)
