import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"

import SubHeaderTabs from "components/SubHeaderTabs"

interface ParametersPageHeaderProps {
  hasAdminGranted: boolean
}

const messages = defineMessages({
  admin: { id: "ParametersPage.AdminsTab", defaultMessage: "Administrateurs" },
  install: { id: "ParametersPage.InstallTab", defaultMessage: "Outlook" },
  sync: { id: "ParametersPage.SyncTabs", defaultMessage: "Synchronisation" },
  properties: { id: "ParametersPage.PropsTab", defaultMessage: "Proprietes" },
})

const hasAdminGrantedTabs = [
  { id: "admin", label: messages.admin },
  { id: "install", label: messages.install },
  { id: "sync", label: messages.sync },
  { id: "properties", label: messages.properties },
]

const tabs = [{ id: "install", label: messages.install }]

const ParametersPageHeader: FunctionComponent<ParametersPageHeaderProps> = ({
  hasAdminGranted,
}) => {
  const { formatMessage } = useIntl()

  const filteredTabs = useMemo(
    () => (hasAdminGranted ? hasAdminGrantedTabs : tabs),
    [hasAdminGranted],
  )

  return (
    <div className="parameters-page-header">
      <div />
      <div className="parameters-page-header__tabs">
        <SubHeaderTabs
          availableTabs={filteredTabs.map((tab) => tab.id)}
          defaultTab={hasAdminGranted ? "admin" : "install"}
        >
          {filteredTabs.map((tab) => (
            <SubHeaderTabs.Tab key={tab.id} tabName={tab.id}>
              {formatMessage(tab.label)}
            </SubHeaderTabs.Tab>
          ))}
        </SubHeaderTabs>
      </div>
      <div />
    </div>
  )
}

export default ParametersPageHeader
