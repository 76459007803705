const diffusionModes = ["internal", "external"] as const

export type DiffusionModes = typeof diffusionModes[number]

export type FilterValuesType = typeof filterValues[number]

export type SortValuesType = typeof sortValues[number]

export type SearchMethod<T> = (e: T) => boolean

export type FilterMethods<T> = {
  [key in Exclude<FilterValuesType, "all">]: (e: T) => boolean
}

export type SortMethods<T> = {
  [key in SortValuesType]: (a: T, b: T) => number
}

export type FilterByDiffusionModeMethods<T> = {
  [key in DiffusionModes]: (e: T) => boolean
}

type Filters<T> = {
  search: string
  filter: FilterValuesType
  sort: SortValuesType
  searchMethod: SearchMethod<T>
  filterMethods: FilterMethods<T>
  sortMethods: SortMethods<T>
  diffusionMode?: DiffusionModes
  filterByDiffusionModeMethods?: FilterByDiffusionModeMethods<T>
}

type Processor<T> = {
  value: string | FilterValuesType | SortValuesType | DiffusionModes
  fct: (
    signatures: T[],
    value: string | FilterValuesType | SortValuesType | DiffusionModes,
    methods:
      | SearchMethod<T>
      | FilterMethods<T>
      | SortMethods<T>
      | FilterByDiffusionModeMethods<T>,
  ) => T[]

  methods:
    | SearchMethod<T>
    | FilterMethods<T>
    | SortMethods<T>
    | FilterByDiffusionModeMethods<T>
}

export const defaultFilterValue = "all"

export const defaultSortValue = "creationDate"

const filterValues = [defaultFilterValue, "unique", "scenario"] as const

const sortValues = [defaultSortValue, "alphabeticalOrder", "priority"] as const

function searchProcessor<T>(
  searchedList: T[],
  searchValue: string,
  searchMethod: SearchMethod<T>,
): T[] {
  if (searchValue.length < 3) return searchedList

  return searchedList.filter(searchMethod, { searchValue })
}

function filterByActivatedState<T>(
  listTofilter: T[],
  filterOption: FilterValuesType,
  filterMethods: FilterMethods<T>,
): T[] {
  switch (filterOption) {
    case "unique":
      return listTofilter.filter(filterMethods.unique)
    case "scenario":
      return listTofilter.filter(filterMethods.scenario)
    case "all":
    default:
      return listTofilter
  }
}

function sortByProperty<T>(
  listToSort: T[],
  sortOption: SortValuesType,
  sortMethods: SortMethods<T>,
): T[] {
  switch (sortOption) {
    case "alphabeticalOrder":
      return listToSort.sort(sortMethods.alphabeticalOrder)
    case "creationDate":
      return listToSort.sort(sortMethods.creationDate)
    case "priority":
      return listToSort.sort(sortMethods.priority)
    default:
      return listToSort
  }
}

function filterByDiffusionMode<T>(
  listTofilter: T[],
  filterOption: DiffusionModes,
  filterMethods: FilterByDiffusionModeMethods<T>,
): T[] {
  switch (filterOption) {
    case "internal":
      return listTofilter.filter(filterMethods.internal)
    case "external":
      return listTofilter.filter(filterMethods.external)
    default:
      return listTofilter
  }
}

export function processList<T>(listToProcess: T[], filters: Filters<T>): T[] {
  const {
    search,
    filter,
    sort,
    diffusionMode,
    searchMethod,
    filterMethods,
    sortMethods,
    filterByDiffusionModeMethods,
  } = filters

  const processors: Processor<T>[] = [
    { value: search, fct: searchProcessor, methods: searchMethod },
    {
      value: filter,
      fct: filterByActivatedState,
      methods: filterMethods,
    },
    { value: sort, fct: sortByProperty, methods: sortMethods },
  ]

  if (diffusionMode && filterByDiffusionModeMethods)
    processors.push({
      value: diffusionMode,
      fct: filterByDiffusionMode,
      methods: filterByDiffusionModeMethods,
    })

  return processors.reduce(
    (acc, { value, fct, methods }) => fct(acc, value, methods),
    listToProcess,
  )
}
