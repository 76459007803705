import moment from "moment"

export const SIGNATURES_DEPLOYMENT_FETCH_DATA_RANGE = {
  from: moment().subtract(7, "d").format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
}

export const SIGNATURES_DEPLOYMENT_STATUS_RANGE = {
  error: 60,
  warning: 80,
}
