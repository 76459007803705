import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as IconDuotoneSignatures } from "assets/duotone/icon-create-signature-duotone.svg"

import useTrackingTileDatas from "../hooks/useTrackingTileDatas"

import Tile from "features/Accounts/components/HomePageSingleTile"

import TrackingTileDatasContainer from "./TrackingTileDatasContainer"
import TrackingTileDatasEmptyState from "./TrackingTileDatasEmptyState"
import TrackingTileLoadingState from "./TrackingTileLoadingState"
import TrackingTileClicks from "./TrackingTileClicks"
import TrackingTileInfosBubble from "./TrackingTileInfosBubble"

import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  label: {
    id: "HomePage.TrackingTileSignaturesLabel",
    defaultMessage: "Signatures",
  },
  infos: {
    id: "HomePage.TrackingTileSignaturesInfos",
    defaultMessage: "sur les 30 derniers jours",
  },
  clicks: {
    id: "HomePage.TrackingTileSignaturesClicks",
    defaultMessage: "Clics générés",
  },
  infosBubble: {
    id: "HomePage.TrackingTileSignaturesInfosBubble",
    defaultMessage: "Comprend les signatures supprimées",
  },
})

const TrackingTileSignaturesBlock: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const { count, loadingStatus } = useTrackingTileDatas()

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <TrackingTileDatasContainer
        label={formatMessage(messages.label)}
        infos={formatMessage(messages.infos)}
      >
        <Tile.Datas>
          <TrackingTileLoadingState />
        </Tile.Datas>
      </TrackingTileDatasContainer>
    )

  if (count.signaturesClicked === 0 && loadingStatus === LoadingStatus.LOADED)
    return (
      <TrackingTileDatasContainer
        label={formatMessage(messages.label)}
        infos={formatMessage(messages.infos)}
      >
        <Tile.Datas>
          <TrackingTileDatasEmptyState />
        </Tile.Datas>
      </TrackingTileDatasContainer>
    )

  return (
    <TrackingTileDatasContainer
      label={formatMessage(messages.label)}
      infos={formatMessage(messages.infos)}
    >
      <Tile.DatasWithIcon icon={<IconDuotoneSignatures />}>
        <TrackingTileInfosBubble>
          {formatMessage(messages.infosBubble)}
        </TrackingTileInfosBubble>
        <TrackingTileClicks
          value={count.signaturesClicked}
          unit={formatMessage(messages.clicks)}
        />
      </Tile.DatasWithIcon>
    </TrackingTileDatasContainer>
  )
}

export default TrackingTileSignaturesBlock
