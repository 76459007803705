import classNames from "classnames"
import { FunctionComponent, ReactNode } from "react"

interface OAuthProviderButtonProps {
  children: ReactNode
  redirectUrl: string
  providerName: string
  isOutlined?: boolean
}

const OAuthProviderButton: FunctionComponent<OAuthProviderButtonProps> = ({
  children,
  redirectUrl,
  providerName,
  isOutlined = false,
}) => {
  return (
    <form action={redirectUrl} method="post" className="oauth-provider">
      <button
        type="submit"
        name="provider"
        value={providerName}
        className={classNames(
          "oauth-provider__button",
          isOutlined && "is-outlined",
        )}
      >
        {children}
      </button>
    </form>
  )
}

export default OAuthProviderButton
