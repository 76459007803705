import { FunctionComponent, useMemo, useState } from "react"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"

import { User } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import useGroupsData from "features/Groups/hooks/useGroupsData"
import useUsersData from "features/Users/hooks/useUsersData"
import { Tag } from "components/PeoplePicker"
import { useDispatch, useSelector } from "react-redux"
import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"
import { signaturesSelectors } from "features/Signatures"
import SignaturePeoplePicker from "../SignaturePeoplePicker"

import PeoplePickerCollapseLists from "components/PeoplePickerCollapseLists"
import {
  affectAllGroupsIdsToSignature,
  affectAllUsersIdsToSignature,
  affectGroupIdToSignature,
  affectUserIdToSignature,
  removeAllGroupsIdsToSignature,
  removeAllUsersIdsToSignature,
  removeGroupIdToSignature,
  removeSingleUserIdToSignature,
  removeUsersIdsToSignature,
} from "features/Signatures/SignaturesReducer"
import { LoadingStatus } from "core/CoreModels"
import Loader from "components/Loader"
import ArrayHelper from "utils/ArrayHelper"

class ListItem {
  id: number
  label: string
}

const messages = defineMessages({
  searchPlaceholder: {
    id: "Campaigns.SearchPlaceholder",
    defaultMessage: "Rechercher des utilisateurs",
  },
})

const SignaturesFormUsersSidebar: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { loadingStatus: usersLoadingStatus } = useUsersData()
  const { loadingStatus: groupsLoadingStatus } = useGroupsData()

  const activeGroups: Group[] = useSelector(groupsSelectors.getLicencedGroups)
  const activeUsers = useSelector(usersSelectors.getAllUsersWithLicences)
  const assignedUsersIds = useSelector(
    signaturesSelectors.getActiveSignatureAffectedUsersIds,
  )
  const assignedGroupsIds = useSelector(
    signaturesSelectors.getActiveSignatureAffectedGroupsIds,
  )

  const activeSignatureAffectedUsersLoadingStatus = useSelector(
    signaturesSelectors.getActiveSignatureAffectedUsersLoadingStatus,
  )
  const activeSignatureAffectedGroupsLoadingStatus = useSelector(
    signaturesSelectors.getActiveSignatureAffectedGroupsLoadingStatus,
  )

  const [tags, setTags] = useState<Tag[]>([])
  const [search, setSearch] = useState("")

  const isLoaded = useMemo(
    () =>
      usersLoadingStatus === LoadingStatus.LOADED &&
      groupsLoadingStatus === LoadingStatus.LOADED &&
      activeSignatureAffectedUsersLoadingStatus === LoadingStatus.LOADED &&
      activeSignatureAffectedGroupsLoadingStatus === LoadingStatus.LOADED,

    [
      usersLoadingStatus,
      groupsLoadingStatus,
      activeSignatureAffectedUsersLoadingStatus,
      activeSignatureAffectedGroupsLoadingStatus,
    ],
  )

  const handleAssignUser = (userId: number) => {
    dispatch(affectUserIdToSignature(userId))
  }

  const handleAssignGroup = (groupId: number) => {
    dispatch(affectGroupIdToSignature(groupId))
  }

  const handleRemoveSingleUser = (userId: number) => {
    dispatch(removeSingleUserIdToSignature(userId))
  }

  const handleRemoveUsers = (usersIds: number[]) => {
    dispatch(removeUsersIdsToSignature(usersIds))
  }

  const handleRemoveGroup = (groupId: number) => {
    dispatch(removeGroupIdToSignature(groupId))
  }

  const handleAssignAllUsers = (usersIds: number[]) => {
    dispatch(affectAllUsersIdsToSignature(usersIds))
  }

  const handleRemoveAllUsers = () => dispatch(removeAllUsersIdsToSignature())

  const handleRemoveAllGroups = () => dispatch(removeAllGroupsIdsToSignature())

  const handleAssignAllGroups = () => {
    dispatch(
      affectAllGroupsIdsToSignature(
        activeGroups.map((activeGroup) => activeGroup.Id),
      ),
    )
  }

  /* Users */
  const selectedUsers = activeUsers.filter((user) =>
    assignedUsersIds.includes(user.Id),
  )

  const handleToggleUsers = (item: ListItem) => {
    if (assignedUsersIds.find((userId: number) => userId === item.id)) {
      handleRemoveSingleUser(item.id)
      setTags(tags.filter((t) => t.id !== item.id))
    } else {
      handleAssignUser(item.id)
      const itemTag = {
        ...item,
        type: "user",
      } as Tag
      setTags([...tags, itemTag])
    }
  }

  /* Groups */
  const selectedGroups = activeGroups.filter((group) =>
    assignedGroupsIds.includes(group.Id),
  )

  const activeMembers = useMemo(
    () => [
      ...new Set(
        selectedGroups.reduce((acc, group) => [...acc, ...group.MembersId], []),
      ),
    ],
    [selectedGroups],
  )

  const handleToggleGroups = (item: ListItem) => {
    if (assignedGroupsIds.find((id: number) => id === item.id)) {
      handleRemoveGroup(item.id)

      setTags(tags.filter((t) => t.id !== item.id))
    } else {
      handleAssignGroup(item.id)

      const groupMembersIds =
        activeGroups.find((group) => group.Id === item.id)?.MembersId || []
      handleRemoveUsers(groupMembersIds)

      const itemTag = {
        ...item,
        type: "group",
      } as Tag

      setTags((prevTags) => {
        const remainingTags = ArrayHelper.reject(
          prevTags,
          (tag) =>
            tag.type === "user" && groupMembersIds.includes(tag.id as number),
        )
        return [...remainingTags, itemTag]
      })
    }
  }

  const selectAllGroups = () => {
    const userTags = tags.filter((t) => t.type === "user")
    handleAssignAllGroups()
    setTags([
      ...userTags,
      ...activeGroups.map((group) => {
        return {
          id: group.Id,
          label: group.Name,
          type: "group",
        } as Tag
      }),
    ])
  }

  const selectAllUsers = () => {
    const groupTags = tags.filter((t) => t.type === "group")

    const usersNotSelectedMembersOfAGroup = activeUsers.filter(
      (user) => !activeMembers.includes(user.Id),
    )
    handleAssignAllUsers(usersNotSelectedMembersOfAGroup.map((user) => user.Id))
    setTags([
      ...groupTags,
      ...usersNotSelectedMembersOfAGroup.map((user) => {
        return {
          id: user.Id,
          label: User.getFullName(user),
          type: "user",
        } as Tag
      }),
    ])
  }
  const removeAllUsers = () => {
    handleRemoveAllUsers()
    const groupTags = tags.filter((t) => t.type === "group")
    setTags(groupTags)
  }

  const removeAllGroups = () => {
    handleRemoveAllGroups()
    const userTags = tags.filter((t) => t.type === "user")
    setTags(userTags)
  }

  return (
    <>
      <Loader fullScreen isLoading={!isLoaded} />
      <div className="campaignform__container">
        <div className="campaignform__panel">
          <div className="campaignform__users">
            <h4 className="campaignform__panel--title">
              <FormattedMessage
                id="Campaigns.UsersTitle"
                defaultMessage="Choix des utilisateurs"
              />
            </h4>
            <div className="search-field">
              <input
                placeholder={formatMessage(messages.searchPlaceholder)}
                className="search-field__input"
                onChange={(event) => setSearch(event.target.value)}
                value={search}
                autoFocus
              />
              {search ? (
                <i
                  onClick={() => setSearch("")}
                  className="search-field__icon search-field__icon--close material-icons"
                >
                  close
                </i>
              ) : (
                <i className="search-field__icon search-field__icon--search material-icons">
                  search
                </i>
              )}
            </div>
            <SignaturePeoplePicker
              tags={tags}
              assignedUsersIds={assignedUsersIds}
              assignedGroupsIds={assignedGroupsIds}
              onSetTags={setTags}
            />
            <PeoplePickerCollapseLists
              groupCollapseList={{
                title: (
                  <FormattedMessage
                    id="Campaigns.Groups"
                    defaultMessage="Groupes"
                  />
                ),
                selectedData: selectedGroups.map((group) => {
                  return { id: group.Id, label: group.Name }
                }),
                handleClick: handleToggleGroups,
                selectAll: selectAllGroups,
                removeAll: removeAllGroups,
              }}
              userCollapseList={{
                title: (
                  <FormattedMessage
                    id="Campaigns.Users"
                    defaultMessage="Utilisateurs"
                  />
                ),
                selectedData: selectedUsers.map((user) => {
                  return {
                    id: user.Id,
                    label: User.getFullName(user),
                  }
                }),
                handleClick: handleToggleUsers,
                selectAll: selectAllUsers,
                removeAll: removeAllUsers,
              }}
              search={search}
              activeMembers={activeMembers}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SignaturesFormUsersSidebar
