import { connect } from "react-redux"
import Graph from "components/Graph"
import { trackingSelectors } from ".."

const mapStateToProps = (state) => ({
  clicksByDate: trackingSelectors.getBannerMetricsByDate(state),
})

const ConnectedGraph = connect(mapStateToProps, null)(Graph)

export default ConnectedGraph
