import ServiceApi from "core/services/serviceApi"
import PortalApi from "core/services/portalApi"
import CrmContact from "entities/CrmContact"

import { Billing, BillingAddress } from "./BillingsModels"

import { TypedResult } from "core/CoreModels"

// Storage Account document - Service API
export function fetchBillings(): Promise<TypedResult<Array<Billing>>> {
  return ServiceApi.request("GET", "billings")
}

// CRM informations - bridge calls with Portal backend based on /crm route
export function fetchBillingAddress(): Promise<TypedResult<BillingAddress>> {
  return PortalApi.request("GET", "/crm/addresses")
}

export function setBillingAddress(
  billingAddress: BillingAddress,
): Promise<TypedResult<BillingAddress>> {
  return PortalApi.request("POST", "/crm/addresses", {
    data: billingAddress,
  })
}

export function updateCrmContact(
  crmContact: CrmContact,
): Promise<TypedResult<CrmContact>> {
  return PortalApi.request("POST", "/crm/contacts", { data: crmContact })
}

// Payment informations - use Stripe from Portal Backend

export function createStripeCustomer(
  holder: string,
  paymentIntent: unknown,
  billingAddress: string,
  phone: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/payment/customer", {
    data: { holder, paymentIntent, billingAddress, phone },
  })
}

export function attachStripePaymentToClient(
  setupIntent: unknown,
  customerId: unknown,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/payment/attach", {
    data: { setupIntent, customerId },
  })
}

export function createStripeSetup(
  descriptor: string,
  price: number,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/payment/setup", {
    data: { descriptor, price },
  })
}

export function getStripePaymentMethods(): Promise<TypedResult<unknown>> {
  return PortalApi.request("GET", "/payment/methods")
}

export function deleteStripePaymentMethod(
  methodId: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("DELETE", `/payment/methods/${methodId}`)
}

export function setStripeDefaultPaymentMethod(
  methodId: string,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("PUT", `/payment/methods/default/${methodId}`)
}
