import { FunctionComponent, ReactNode } from "react"

interface CampaignsTileDatasLoadingProps {
  children: ReactNode
}

const CampaignsTileDatasLoading: FunctionComponent<
  CampaignsTileDatasLoadingProps
> = ({ children }) => {
  return (
    <div className="campaigns-tile__skeleton-loading--container">
      <div className="campaigns-tile__skeleton-loading--all-count">
        <div className="campaigns-tile__skeleton-loading--value" />
        <p className="campaigns-tile__active-count--label">{children}</p>
      </div>
      <div className="campaigns-tile__skeleton-loading--dispatch">
        <div className="campaigns-tile__skeleton-loading--vertical " />
        <div className="campaigns-tile__skeleton-loading--vertical " />
      </div>
    </div>
  )
}

export default CampaignsTileDatasLoading
