import React from "react"

import { useScenarioFormContext } from "features/Scenarios/components/ScenarioForm/ScenarioFormContext"

import CampaignScenarioPreview from "./CampaignScenarioPreview"

const ContextedCampaignScenarioPreview: React.FC = () => {
  const { editingCampaign } = useScenarioFormContext()

  return <CampaignScenarioPreview banners={editingCampaign.banners} />
}

export default ContextedCampaignScenarioPreview
