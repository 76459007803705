import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as ClickPointerIcon } from "assets/icon-click-pointer.svg"
import { ReactComponent as InfoIcon } from "assets/icon-info.svg"

interface ScenariosMostClickedOverviewProps {
  nbClicks: number
}

const messages = defineMessages({
  information: {
    id: "Tracking.ScenariosMostClickedOverviewInformations",
    defaultMessage: "Clics générés",
  },
  insufficient: {
    id: "Tracking.ScenariosMostClickedInsufficientsDatas",
    defaultMessage:
      "En attente de données suffisantes pour afficher les tops diffuseurs",
  },
})

const ScenariosMostClickedOverview: FunctionComponent<
  ScenariosMostClickedOverviewProps
> = ({ nbClicks }) => {
  const { formatMessage } = useIntl()

  const clicks = (
    <>
      <div className="scen-most-clicked-overview__infos">
        <div className="scen-most-clicked-overview__infos--icon">
          <ClickPointerIcon />
        </div>
        <p className="scen-most-clicked-overview__infos--message">
          {formatMessage(messages.information)}
        </p>
      </div>
      <p className="scen-most-clicked-overview__clicks">{nbClicks}</p>
    </>
  )

  const insufficientInformations = (
    <>
      <div className="scen-most-clicked-overview__infos--icon">
        <InfoIcon />
      </div>
      <p className="scen-most-clicked-overview__insuff-datas">
        {formatMessage(messages.insufficient)}
      </p>
    </>
  )

  return (
    <div className="scen-most-clicked-overview">
      {nbClicks > 0 ? clicks : insufficientInformations}
    </div>
  )
}

export default ScenariosMostClickedOverview
