/**
 * Billings SELECTORS
 */
import { LoadingStatus } from "core/CoreModels"
import { GlobalStates } from "store"

import { Billing, BillingAddress } from "./BillingsModels"

const getAll = (state: GlobalStates): Array<Billing> =>
  state.billings.allBillings

const getBillingAddress = (state: GlobalStates): BillingAddress =>
  state.billings.address

const billingAddressIsComplete = (state: GlobalStates): boolean => {
  let isComplete = true
  const address = state.billings.address
  for (const prop in address) {
    if (address[prop] === "" || address[prop] == null) {
      if (prop === "part2") continue
      if (prop === "siret") continue
      isComplete = false
      break
    }
  }
  return isComplete
}

const getLoadingStatus = (state: GlobalStates): LoadingStatus =>
  state.billings.loadingStatus

export const selectors = {
  getAll,
  getBillingAddress,
  billingAddressIsComplete,
  getLoadingStatus,
}
