import { MouseEvent, useEffect, useState } from "react"

import ModalService, { ModalConfig } from "./ModalService"

import Modal from "./Modal"
import classNames from "classnames"

const ModalProvider = (): JSX.Element => {
  const [modal, setModal] = useState<JSX.Element | undefined>()

  const closeModal = () => {
    setModal(undefined)
  }

  useEffect(() => {
    return ModalService.setModalProvider((conf: ModalConfig) => {
      const handleCancel = async (e: MouseEvent<HTMLButtonElement>) => {
        const result = await conf.onCancel(e)
        if (result) closeModal()
      }
      setModal(
        <Modal onCancel={handleCancel} title={conf.title}>
          {conf.content}
        </Modal>,
      )
    }, closeModal)
  }, [])

  return (
    <div className={classNames("modal-service", modal && "is-opened")}>
      {modal}
    </div>
  )
}

export default ModalProvider
