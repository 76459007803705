import { selectAllStorageImages } from "./StorageReducers"

import { LoadingStatus } from "core/CoreModels"
import { RootState } from "store/configureStore"
import { StorageImage } from "./StorageModels"

const getStorageImagesLoadingStatus = (state: RootState): LoadingStatus =>
  state.storage.allImages.loadingStatus

const getBuilderImagesFromImagesStorages = (state: RootState): StorageImage[] =>
  selectAllStorageImages(state).filter((image) =>
    Object.keys(image.tags).includes("new-builder"),
  )

export const selectors = {
  getAllStorageImages: selectAllStorageImages,
  getStorageImagesLoadingStatus,
  getBuilderImagesFromImagesStorages,
}

export default selectors
