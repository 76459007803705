import React, { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import Page from "components/Page"

import ExpiredPageContent from "features/Enrollment/components/ExpiredPageContent"

const ExpiredPage: FunctionComponent = () => {
  return (
    <Page
      title={
        <FormattedMessage id="ExpiredPage.Title" defaultMessage="S'abonner" />
      }
    >
      <ExpiredPageContent />
    </Page>
  )
}

export default ExpiredPage
