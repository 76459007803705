import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import CallToActionZone from "features/Accounts/components/CallToActionZone"

const messages = defineMessages({
  loginCTATitle: {
    id: "LoginCTA.Title",
    defaultMessage: "Vous ne connaissez pas l'application ?",
  },
  loginCTADemo: {
    id: "LoginCTA.Demo",
    defaultMessage: "Planifier une démo",
  },
  loginCTAShowcaseSite: {
    id: "LoginCTA.ShowcaseSite",
    defaultMessage: "Visitez notre site",
  },
})

const LoginSliderCallToAction: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <CallToActionZone
      ctaLabel={formatMessage(messages.loginCTATitle)}
      ctaButtonLabel={formatMessage(messages.loginCTADemo)}
      showcaseLabel={formatMessage(messages.loginCTAShowcaseSite)}
    />
  )
}

export default LoginSliderCallToAction
