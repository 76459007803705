import { FunctionComponent } from "react"

import { defineMessages, useIntl, FormattedMessage } from "react-intl"
import Page from "components/Page"

import ResetPasswordForm from "features/Accounts/components/ResetPasswordForm"

const messages = defineMessages({
  formTitle: {
    id: "ResetPasswordPage.FormTitle",
    defaultMessage: "Réinitialiser votre mot de passe",
  },
})

const ResetPasswordPage: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <Page
      title={
        <FormattedMessage
          id="ResetPasswordPage.Title"
          defaultMessage="Modifier votre mot de passe"
        />
      }
      withoutNavbar
    >
      <div className="l-one-column__container h-100">
        <div className="l-one-column__content">
          <ResetPasswordForm
            title={formatMessage(messages.formTitle)}
            isConfirmUser={false}
          />
        </div>
      </div>
    </Page>
  )
}

export default ResetPasswordPage
