import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import moment, { Moment } from "moment"
import CalendarRange from "../CalendarView/entities/CalendarRange"
import DatePicker from "components/DatePicker"
import iconEdit from "./calendar-card-edit.svg"
import iconCalendar from "./calendar-card-calendar.svg"
import Button from "components/Button"

import { CAMPAIGNS_URL } from "router/RouterConstants"

interface Props {
  range: CalendarRange
  isExtended?: boolean
  editable?: boolean
  setEditable?: (id: number) => void
  onClose?: () => void
  onChange?: (range: CalendarRange) => void
  onSelect?: (id: number) => void
  signaturesLoaded?: boolean
  signatureUsersLoaded?: boolean
}

const CalendarCard: React.FC<Props> = ({
  range,
  isExtended,
  editable,
  setEditable,
  onClose,
  onChange,
  onSelect,
  signaturesLoaded,
  signatureUsersLoaded,
}) => {
  const [isEditable, setIsEditable] = useState(Boolean)
  // store for rollback purpose
  const [initialStartDate] = useState(range.startDate)
  const [initialEndDate] = useState(range.endDate)

  const editLink = `${CAMPAIGNS_URL}/edit/${range.id}/step/1` // TODO - ${range. ? "/?mode=multiple" : ""}`

  const handleDateChange = (startDate: Moment, endDate: Moment) => {
    range.startDate = startDate
    range.endDate = endDate
    onChange({ ...range })
  }

  return (
    <div className="calendar-view-banner--container">
      <div
        className="calendar-view-banner"
        onClick={() => {
          onSelect && onSelect(range.id)
        }}
      >
        <div
          className="cvb-image"
          style={{ backgroundImage: `url(${range.imageUrl})` }}
        >
          <Link className="cvb-edit" to={editLink}>
            <img src={iconEdit} alt="" />
          </Link>
          <a
            className="cvb-edit"
            onClick={() => {
              setIsEditable(!isEditable)
              setEditable && setEditable(range.id)
              onSelect && onSelect(range.id)
            }}
          >
            <img src={iconCalendar} alt="" />
          </a>
        </div>
        <div className="cvb-details">
          {!(isEditable || editable) && (
            <RangeInfo
              range={range}
              isExtended={isExtended}
              signaturesLoaded={signaturesLoaded}
              signatureUsersLoaded={signatureUsersLoaded}
            ></RangeInfo>
          )}
          {(isEditable || editable) && (
            <div className="cvb-range bmmDatePicker">
              <label>
                <FormattedMessage
                  id="CalendarViewBanner.publication_dates"
                  defaultMessage="Dates de publication"
                />
              </label>
              <DatePicker
                initialStartDate={moment(range.startDate)}
                initialEndDate={moment(range.endDate)}
                onDatesChange={(startDate, endDate) => {
                  onChange && handleDateChange(startDate, endDate)
                }}
              />
            </div>
          )}
          <div className="cvb-color" style={{ background: range.color }}></div>
        </div>
      </div>
      <div className="cvb-actions">
        {isExtended && !isEditable && !editable && (
          <div>
            <Button
              classes="test1 test2"
              rounded
              outlined
              icon="close"
              onClick={() => {
                onClose && onClose()
              }}
            />
          </div>
        )}
        {(isEditable || editable) && (
          <div>
            <Button
              rounded
              outlined
              icon="arrow_back"
              onClick={() => {
                setIsEditable(false)
                setEditable(0)
                onChange && handleDateChange(initialStartDate, initialEndDate)
              }}
            />
            <Button
              rounded
              icon="done"
              onClick={() => {
                setIsEditable(false)
                setEditable(0)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const RangeInfo = ({
  range,
  isExtended,
  signaturesLoaded,
  signatureUsersLoaded,
}) => {
  const dateFormat = "DD/MM/YYYY"
  const startDate = moment(range.startDate).format(dateFormat)
  const endDate = moment(range.endDate).format(dateFormat)
  const empty = "--"
  return (
    <div>
      <FormattedMessage
        id="CalendarViewBanner.campaign"
        defaultMessage="Campagne :"
      />{" "}
      {range.name}
      <br />
      <FormattedMessage
        id="CalendarViewBanner.signature"
        defaultMessage="Signature(s) :"
      />
      &nbsp;
      {!signaturesLoaded && <span>...</span>}
      {signaturesLoaded && range.signatures.length}
      <br />
      <div
        className={`cvb-extended  ${isExtended ? "cvb-extended-active" : ""}`}
      >
        <FormattedMessage
          id="CalendarViewBanner.users"
          defaultMessage="Utilisateur(s) :"
        />
        &nbsp;
        {!signatureUsersLoaded && <span>...</span>}
        {signatureUsersLoaded && range.users}
        <br />
        <FormattedMessage
          id="CalendarViewBanner.linkUrl"
          defaultMessage="Lien :"
        />{" "}
        <a href={range.linkUrl} target="_blank" rel="noreferrer">
          {range.linkUrl ? range.linkUrl : empty}
        </a>
      </div>
      <div className="cvb-date">
        <FormattedMessage id="CalendarViewBanner.from" defaultMessage="Du" />{" "}
        {startDate}
        <FormattedMessage id="CalendarViewBanner.to" defaultMessage="au" />{" "}
        {endDate}
      </div>
    </div>
  )
}

export default CalendarCard
