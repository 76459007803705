import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"

const handleRequest = (config: AxiosRequestConfig) => config
const handleResponse = (data: AxiosResponse) => data
const handleError = (error: AxiosError): Promise<AxiosError> =>
  Promise.reject(error)

export function createBaseAxiosInstance(baseURL: string): AxiosInstance {
  const instance = axios.create({
    baseURL,
  })
  instance.interceptors.request.use(handleRequest, handleError)

  instance.interceptors.response.use(handleResponse, handleError)

  return instance
}
