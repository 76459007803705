import { FunctionComponent, HTMLAttributes } from "react"

import { defineMessages, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { Link } from "react-router-dom"
import classNames from "classnames"

import { trialTunnelSelectors } from "features/Trial"

import { isSpan } from "utils/I18nHelpers"

import { ReactComponent as IconDuotoneCampaign } from "assets/duotone/icon-campaign-duotone.svg"
import { ReactComponent as IconDuotoneScenario } from "assets/duotone/icon-scenario-duotone.svg"
import { ReactComponent as AddIcon } from "assets/icon-add.svg"

import useDiffusionMode from "features/Scenarios/hooks/useDiffusionMode"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import { CAMPAIGNS_URL } from "router/RouterConstants"
import { featureNames } from "config/config.features"

interface CampaignsTileDispatchProps extends HTMLAttributes<HTMLDivElement> {
  campaignsCount: number
  scenariosCount: number
  displayAsColumn: boolean
}

const messages = defineMessages({
  campaigns: {
    id: "HomePage.CampaignsTileDispatchCampaigns",
    defaultMessage: "<sp>{count}</sp> unique(s)",
  },
  scenarios: {
    id: "HomePage.CampaignsTileDispatchCampaigns",
    defaultMessage: "<sp>{count}</sp> scénario(s)",
  },
})

const CampaignsTileDispatch: FunctionComponent<CampaignsTileDispatchProps> = ({
  campaignsCount,
  scenariosCount,
  displayAsColumn,
  ...others
}) => {
  const { formatMessage } = useIntl()
  const { setDiffusionMode } = useDiffusionMode()
  const internalExternalFeature = useFeatureTogglr(featureNames.INTERNE_EXTERNE)

  const isTrialFinished = useSelector(trialTunnelSelectors.trialIsFinished)

  const handleAddBanner = () => {
    if (isTrialFinished) {
      internalExternalFeature?.isActive &&
        setDiffusionMode({ internal: false, external: false })
    }
  }

  return (
    <div
      className={classNames(
        "campaigns-tile__dispatch--container",
        displayAsColumn && "flex-column",
      )}
      {...others}
    >
      <Link
        onClick={handleAddBanner}
        to={`${CAMPAIGNS_URL}/edit/step/1`}
        className={classNames(
          "campaigns-tile__dispatch--side",
          displayAsColumn && "flex-row",
        )}
      >
        <IconDuotoneCampaign className="campaigns-tile__dispatch--icon" />
        <p className="campaigns-tile__dispatch--label">
          {formatMessage(messages.campaigns, {
            count: campaignsCount,
            sp: isSpan,
          })}
        </p>
        <AddIcon className="campaigns-tile__dispatch--add-icon" />
      </Link>
      <Link
        onClick={handleAddBanner}
        to={`${CAMPAIGNS_URL}/edit/step/1?mode=multiple`}
        className={classNames(
          "campaigns-tile__dispatch--side",
          displayAsColumn && "flex-row",
        )}
      >
        <IconDuotoneScenario className="campaigns-tile__dispatch--icon" />
        <p className="campaigns-tile__dispatch--label">
          {formatMessage(messages.scenarios, {
            count: scenariosCount,
            sp: isSpan,
          })}
        </p>

        <AddIcon className="campaigns-tile__dispatch--add-icon" />
      </Link>
    </div>
  )
}

export default CampaignsTileDispatch
