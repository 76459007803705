import React, { Component } from "react"
import classBuilder from "classnames"

interface Props {
  label?: string
  classes?: string
  disabled?: boolean
  outlined?: boolean
  rounded?: boolean
  small?: boolean
  icon?: string
  type?: "button" | "submit" | "reset"
  form?: string
  onClick?: () => void
}

class Button extends Component<Props> {
  render() {
    const { label, classes, disabled, outlined, rounded, icon, small } =
      this.props

    let buttonClass = classBuilder({
      "bmm-button": true,
      "bmm-button--mini-width": true,
      "bmm-button--disable": disabled,
      "bmm-button--rounded": rounded,
      "bmm-button--reverse": outlined,
      "bmm-button--small": small,
    })

    buttonClass += " " + classes

    return (
      <button className={buttonClass} {...this.props}>
        {icon && <i className="material-icons">{icon}</i>}
        {label}
      </button>
    )
  }
}

export default Button
