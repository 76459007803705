import { FunctionComponent } from "react"

import { FormattedMessage } from "react-intl"

import { useParams } from "react-router-dom"

import EditGroup from "features/Groups/components/EditGroup"

import Page from "components/Page"
import EditGroupAssignationsPanel from "features/Groups/components/EditGroupAssignationsPanel"

const EditGroupPage: FunctionComponent = () => {
  const { group } = useParams<{ group: string }>()
  const groupId = parseInt(group) || -1

  return (
    <Page
      title={
        <FormattedMessage
          id="UsersPage.Title"
          defaultMessage="Gestion des utilisateurs"
        />
      }
    >
      <div className="edit-group__container">
        <div className="edit-group__left-side">
          <EditGroup currentGroupId={groupId} />
        </div>
        {groupId !== -1 && <EditGroupAssignationsPanel groupId={groupId} />}
      </div>
    </Page>
  )
}

export default EditGroupPage
