import { FunctionComponent, ReactNode } from "react"

import { ReactComponent as GoogleIcon } from "assets/icon-google.svg"

import { GOOGLE_REDIRECT_URL } from "features/Accounts/AccountsConstants"

import OAuthProviderButton from "features/Accounts/components/OAuthProviderButton"

import useOauthProviderRedirectUrl from "features/Accounts/hooks/useOauthProviderRedirectUrl"

interface GoogleOauthButtonProps {
  children: ReactNode
  isOutlined?: boolean
}

const GoogleOauthButton: FunctionComponent<GoogleOauthButtonProps> = ({
  children,
  isOutlined,
}) => {
  const redirectUrl = useOauthProviderRedirectUrl(GOOGLE_REDIRECT_URL)

  return (
    <OAuthProviderButton
      redirectUrl={redirectUrl}
      providerName="Google"
      isOutlined={isOutlined}
    >
      <GoogleIcon /> {children}
    </OAuthProviderButton>
  )
}

export default GoogleOauthButton
