import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { useOnBoardingCompleted } from "../hooks/useOnBoardingCompleted"

const messages = defineMessages({
  title: {
    id: "OnBoarding.Title",
    defaultMessage: "Bienvenue",
  },
  message: {
    id: "OnBoarding.Message",
    defaultMessage:
      "Suivez les étapes suivantes pour configurer votre compte et ainsi profiter de toutes les fonctionnalités de Boost My Mail.",
  },
  titleValidated: {
    id: "OnBoarding.TitleValidated",
    defaultMessage: "Félicitations !",
  },
  messageValidated: {
    id: "OnBoarding.MessageValidated",
    defaultMessage:
      "Vous avez terminé la configuration de votre compte. Votre aventure avec Boost My Mail ne fait que commencer !",
  },
})

const OnBoardingMessage: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const completed = useOnBoardingCompleted()

  return (
    <div className="onboarding-tile__message">
      {completed ? (
        <>
          <p
            className={classNames(
              "onboarding-tile__message--title",
              "is-completed",
            )}
          >
            {formatMessage(messages.titleValidated)}
          </p>
          <p
            className={classNames(
              "onboarding-tile__message--message",
              "is-completed",
            )}
          >
            {formatMessage(messages.messageValidated)}
          </p>
        </>
      ) : (
        <>
          <p className="onboarding-tile__message--title">
            {formatMessage(messages.title)}
          </p>
          <p className="onboarding-tile__message--message">
            {formatMessage(messages.message)}
          </p>
        </>
      )}
    </div>
  )
}

export default OnBoardingMessage
