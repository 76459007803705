import { TypedResult } from "core/CoreModels"
import PortalApi from "core/services/portalApi"
import { Alert } from "./AlertsModels"

export function fetchAllAlertsApi() {
  return PortalApi.request("GET", "/alertes")
}

export function updateAlertApi(alertId: number): Promise<TypedResult<unknown>> {
  return PortalApi.request("PUT", `/alertes/${alertId}`)
}
