import PortalApi from "core/services/portalApi"
import ServiceApi from "core/services/serviceApi"

import {
  CheckPropertiesDto,
  UpdateUserPropertiesDto,
  UserDto,
} from "./UserModels"
import { PaginatedResult, TypedResult } from "core/CoreModels"

import { SortOrders } from "components/PaginatedDataGrid/PaginatedDataGrid"

import { SignatureDto } from "features/Signatures/SignaturesModels"
import { BuilderSignatureDto } from "features/BuilderSignatures/BuilderSignaturesModels"
import { StorageTags, StorageMetadatas } from "features/Storage/StorageModels"
import { RequestParams } from "core/services/requestBuilderService"

interface FetchPartialUsersParams {
  pageSize: number
  pageNumber: number
  searchString?: string
  orderBy: SortOrders
  query?: string
}

// * Recuperation des utilisateurs
export function fetchUsers(
  params: RequestParams = {},
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("GET", `/users`, { params })
}

//  * Créer un utilisateur BMM
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createBmmUser(user): Promise<TypedResult<UserDto>> {
  return ServiceApi.request("POST", `/users`, { data: user })
}

// * Modifie un utilisateur BMM

export function updateBmmUser(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  userId,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  user,
): Promise<TypedResult<UserDto>> {
  return ServiceApi.request("PUT", `/users/${userId}`, { data: user })
}

//  * Supprime un utilisateur - ne renvoie rien
export function deleteUser(userId: number): Promise<TypedResult<void>> {
  return ServiceApi.request("DELETE", `/users/${userId}`)
}

//  * Récupère les signatures d'un utilisateurs
export function fetchUserSignatures(
  userId: number,
): Promise<TypedResult<Array<SignatureDto | BuilderSignatureDto>>> {
  return ServiceApi.request("GET", `/users/${userId}/signatures`)
}

export function fetchUserDefaultSignature(
  userId: number,
): Promise<TypedResult<SignatureDto | BuilderSignatureDto>> {
  return ServiceApi.request("GET", `/users/${userId}/signatures/default`)
}

//  * Défini la signature par défaut d'un utilisateur - ne renvoie rien
export function updateDefaultUserSignature(
  userId: number,
  signatureId: number,
): Promise<TypedResult<void>> {
  return ServiceApi.request("PUT", `/users/${userId}/signatures/default`, {
    data: signatureId,
  })
}

//  * Retire la signature par défaut d'un utilisateur - ne renvoie rien

export function removeDefaultUserSignature(
  userId: number,
): Promise<TypedResult<void>> {
  return ServiceApi.request("DELETE", `/users/${userId}/signatures/default`)
}

//  * Met à jour la licence d'un utilisateur
export function updateLicence(
  userId: number,
  activate: boolean,
): Promise<TypedResult<UserDto>> {
  return ServiceApi.request("PUT", `/users/${userId}/licence`, {
    data: activate,
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fetchUsersProperties() {
  return ServiceApi.request("GET", "/properties")
}

export function updateUsersProperties(
  usersProperties: Array<UpdateUserPropertiesDto>,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("POST", "/properties", { data: usersProperties })
}

export function deleteUsersProperty(
  userProperty: UpdateUserPropertiesDto,
): Promise<TypedResult<unknown>> {
  return ServiceApi.request("DELETE", "/properties", { data: userProperty })
}

export function checkUsersProperties(
  usersProperties: Array<CheckPropertiesDto>,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/syncproperties/check", {
    data: usersProperties,
  })
}

function buildOrderByString(orderBy: SortOrders) {
  return orderBy
    .map(({ columnKey, sortOrder }) => {
      return `${columnKey} ${sortOrder}`
    })
    .join(" ")
}

export function fetchPartialUsers(
  params: FetchPartialUsersParams,
): Promise<PaginatedResult<UserDto[]>> {
  const { pageSize, pageNumber, searchString, orderBy, query } = params

  const requestConfig = {
    params: {
      pageSize,
      pageNumber,
      s: searchString !== "" ? searchString : null,
      q: query !== "" ? query : null,
      orderby: orderBy.length === 0 ? null : buildOrderByString(orderBy),
    },
  }

  return ServiceApi.paginatedRequest("GET", "/users", requestConfig)
}

export function fetchUserIdsByQuery(
  query: string,
): Promise<TypedResult<UserDto[]>> {
  return ServiceApi.request("GET", `/users/properties?query=${query}`)
}

export function fetchActiveLicencesCount(): Promise<TypedResult<number>> {
  return ServiceApi.request("GET", "/users/activeLicences")
}

export function fetchSingleUser(userId: number): Promise<TypedResult<UserDto>> {
  return ServiceApi.request("GET", `/users/${userId}`)
}

export function importUsersToTenantAsExcelFile(
  file: File,
  tags: StorageTags = {},
  metadatas: StorageMetadatas = {},
): Promise<TypedResult<unknown>> {
  const formData = new FormData()
  formData.append("formFile", file)
  formData.append("fileName", file.name)
  formData.append("fileType", file.type)
  formData.append("metadatas", JSON.stringify(metadatas))
  formData.append("tags", JSON.stringify(tags))

  return ServiceApi.uploadFileRequest("POST", "/sync/importexcel", {
    data: formData,
  })
}
