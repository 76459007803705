import React, { useState } from "react"
import CalendarCard from "../CalendarCard/ConnectedCalendarCard"
import CalendarCardListEmpty from "./CalendarCardListEmpty"
import CalendarRange from "../CalendarView/entities/CalendarRange"

interface Props {
  ranges?: CalendarRange[]
  date?: string
  selectedId?: number
  hoveredId?: number
  onCloseSelected?: () => void
  onSelect?: (id: number) => void
  onChange?
  signaturesLoaded?: boolean
  signatureUsersLoaded?: boolean
}

const CalendarCardList: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(Number)
  const setEditable = (id: number) => {
    setEdit(id)
  }

  /* Banner list */
  const jsxBanners = []
  props.ranges.sort((a, b) => (a.startDate < b.startDate ? -1 : 1))
  props.ranges.map((range, i) => {
    let isExtended = false
    if (range.id == props.selectedId) isExtended = true
    if (
      !props.selectedId ||
      range.id == props.selectedId ||
      (range.id == props.hoveredId && !props.selectedId)
    )
      jsxBanners.push(
        <div
          className={
            props.selectedId || props.hoveredId == range.id ? "cvb-hovered" : ""
          }
        >
          <CalendarCard
            key={i}
            editable={edit == range.id}
            range={range}
            setEditable={setEditable}
            isExtended={isExtended}
            onClose={props.onCloseSelected}
            // onChange={(start, end) => { props.onChange && props.onChange(range.id, start, end); }}
            onSelect={(id) => {
              props.onSelect && props.onSelect(range.id)
            }}
            signaturesLoaded={props.signaturesLoaded}
            signatureUsersLoaded={props.signatureUsersLoaded}
          ></CalendarCard>
        </div>,
      )
  })

  return (
    <div className={`bannercardslist ${props.hoveredId ? "bcl-hovered" : ""}`}>
      {jsxBanners.length ? jsxBanners : <CalendarCardListEmpty />}
    </div>
  )
}

export default CalendarCardList
