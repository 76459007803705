import React, { useContext } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import { CollapsedCardContext } from "./Context"

interface Props {
  cardId: string
  header: Element | React.ReactElement
}

const CollapsedCard: React.FC<Props> = ({ cardId, header, children }) => {
  const { expandedCardId, setExpandedCard } = useContext(CollapsedCardContext)

  const ExpandIcon = () => {
    return (
      <span className="collapse-card__icon">
        {expandedCardId === cardId ? (
          <i className="material-icons">remove</i>
        ) : (
          <i className="material-icons">add</i>
        )}
      </span>
    )
  }

  return (
    <div className="collapse-card" data-cy="collapsed-card">
      <Accordion
        className="collapse-card__container"
        elevation={5}
        expanded={expandedCardId === cardId}
        onChange={() => setExpandedCard(cardId)}
      >
        <AccordionSummary expandIcon={ExpandIcon()}>{header}</AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

export default CollapsedCard
