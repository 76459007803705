import classNames from "classnames"
import { FunctionComponent } from "react"

interface SpinnerProps {
  color?: "white"
}

const Spinner: FunctionComponent<SpinnerProps> = ({ color }) => {
  return <div className={classNames("spinner", color)} />
}

export default Spinner
