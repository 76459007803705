import { FunctionComponent, useMemo } from "react"

import { defineMessages, useIntl } from "react-intl"

import AssignationsPanel from "components/AssignationsPanel"

import useSignaturesAssignedToGroup from "features/Groups/hooks/useSignaturesAssignedToGroup"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"
import useScenariosAssignedToGroup from "features/Groups/hooks/useScenariosAssignedToGroup"

import { featureNames } from "config/config.features"

import { BANNER_TAG } from "entities/Template"
import Signature from "entities/Signature"

import { LoadingStatus, SendingModeEnum } from "core/CoreModels"

interface EditGroupAssignationsPanelProps {
  groupId: number
}

const messages = defineMessages({
  signaturesTitle: {
    id: "EditGroupAssignationsPanel.SignaturesTitle",
    defaultMessage: "Signatures associées",
  },
  signaturesEmpty: {
    id: "EditGroupAssignationsPanel.SignaturesEmpty",
    defaultMessage: "Ce groupe ne diffuse pas de signature",
  },
  bannersTitle: {
    id: "EditGroupAssignationsPanel.BannersTitle",
    defaultMessage: "Bannières associées",
  },
  bannersEmpty: {
    id: "EditGroupAssignationsPanel.BannersEmpty",
    defaultMessage: "Ce groupe ne diffuse pas de bannière",
  },
})

function getSendingModeFromSignature(
  signature: Signature,
  isFeatureActive: boolean,
) {
  if (!isFeatureActive) return
  if (signature.NewMail === true && signature.InResponse === true) return

  if (signature.NewMail === true) return SendingModeEnum.NEWMAIL

  return SendingModeEnum.INRESPONSE
}

const EditGroupAssignationsPanel: FunctionComponent<
  EditGroupAssignationsPanelProps
> = ({ groupId }) => {
  const { formatMessage } = useIntl()

  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const {
    affectedSignatures,
    loadingStatus: affectedSignaturesLoadingStatus,
    displayedIndex: affectedSignaturesDisplayedIndex,
    previousSignature,
    nextSignature,
  } = useSignaturesAssignedToGroup(groupId)
  const {
    affectedScenarios,
    loadingStatus: affectedScenariosLoadingStatus,
    displayedIndex: affectedScenariosDisplayedIndex,
    previousScenario,
    nextScenario,
  } = useScenariosAssignedToGroup(groupId)

  const displayedAffectedsignatures = useMemo(() => {
    if (!newMailInResponseFeature?.isActive) return affectedSignatures

    return affectedSignatures
      .map((sign) => {
        if (sign.NewMail && sign.InResponse)
          return [
            { ...sign, InResponse: false },
            { ...sign, NewMail: false },
          ]

        return sign
      })
      .flat()
  }, [affectedSignatures])

  return (
    <div className="edit-group-assignations-panel">
      <AssignationsPanel>
        <AssignationsPanel.Item
          title={formatMessage(messages.signaturesTitle)}
          emptyLabel={formatMessage(messages.signaturesEmpty)}
          isEmpty={
            affectedSignaturesLoadingStatus === LoadingStatus.LOADED &&
            affectedSignatures.length === 0
          }
        >
          <AssignationsPanel.Signatures
            displayedIndex={affectedSignaturesDisplayedIndex}
            loadingStatus={affectedSignaturesLoadingStatus}
            previous={previousSignature}
            next={nextSignature}
            signatures={displayedAffectedsignatures.map((signature) => ({
              name: signature.Name,
              template: signature.Template.compiledContent
                .replaceAll("<a ", "<a target='_blank'")
                .replace(BANNER_TAG, ""),
              sendingMode: getSendingModeFromSignature(
                signature,
                newMailInResponseFeature?.isActive,
              ),
              weighting: signature.Weighting,
            }))}
          />
        </AssignationsPanel.Item>
        <AssignationsPanel.Item
          title={formatMessage(messages.bannersTitle)}
          emptyLabel={formatMessage(messages.bannersEmpty)}
          isEmpty={
            affectedScenariosLoadingStatus === LoadingStatus.LOADED &&
            affectedScenarios.length === 0
          }
        >
          <AssignationsPanel.Scenarios
            displayedIndex={affectedScenariosDisplayedIndex}
            loadingStatus={affectedScenariosLoadingStatus}
            previous={previousScenario}
            next={nextScenario}
            scenarios={affectedScenarios.map((scenario) => ({
              name: scenario.name,
              banners: scenario.banners.map((banner) => ({
                id: banner.id,
                url: banner.imageUrl,
                linkUrl: banner.linkUrl,
              })),
            }))}
          />
        </AssignationsPanel.Item>
      </AssignationsPanel>
    </div>
  )
}

export default EditGroupAssignationsPanel
