import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { storageSelectors } from "features/Storage"

import { LoadingStatus } from "core/CoreModels"
import { fetchAllImages } from "../StorageReducers"
import { RootState } from "store/configureStore"

interface UseStorageImagesData {
  loadingStatus: LoadingStatus
}

const useStorageImagesDatas = (tags: string[] = []): UseStorageImagesData => {
  const dispatch = useDispatch()
  const loadingStatus = useSelector<RootState, LoadingStatus>(
    storageSelectors.getStorageImagesLoadingStatus,
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchAllImages(tags))
    }
  }, [loadingStatus, dispatch])

  return { loadingStatus }
}

export default useStorageImagesDatas
