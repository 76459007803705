import { FunctionComponent, useMemo } from "react"

import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"

import Page from "components/Page"

import ParametersPageHeader from "features/Parameters/components/ParametersPageHeader"
import ParametersPageContent from "features/Parameters/components/ParametersPageContent"

import { accountsSelectors } from "features/Accounts"

import { RolesNeeded } from "router/routes"

const ParametersPage: FunctionComponent = () => {
  const currentRoles = useSelector(accountsSelectors.getRoles)
  const hasAdminGranted = useMemo(
    () =>
      currentRoles.filter((x) => RolesNeeded.ADMIN_PAGE.includes(x)).length > 0,
    [currentRoles],
  )

  return (
    <Page
      title={
        <FormattedMessage
          id="ParametersPage.Title"
          defaultMessage="Mes paramètres"
        />
      }
    >
      <ParametersPageHeader hasAdminGranted={hasAdminGranted} />
      <ParametersPageContent hasAdminGranted={hasAdminGranted} />
    </Page>
  )
}

export default ParametersPage
