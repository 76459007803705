import PortalApi from "core/services/portalApi"

import { TypedResult } from "core/CoreModels"
import { JobSendPreviewDto } from "./JobsModels"

export function launchJob(job: string): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", `/jobs/${job}`)
}

export function launchRecurringJob(job: string): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", `/jobs/${job}/recurring`)
}

export function deleteRecurringJob(job: string): Promise<TypedResult<unknown>> {
  return PortalApi.request("DELETE", `/jobs/${job}`)
}

export function updateOrigin(job: string): Promise<TypedResult<unknown>> {
  return PortalApi.request("PUT", `/jobs/origin/${job}`)
}

export function deleteOrigin(job: string): Promise<TypedResult<unknown>> {
  return PortalApi.request("DELETE", `/jobs/origin/${job}`)
}

export function sendPreviewJob(
  sendPreviewDto: JobSendPreviewDto,
): Promise<TypedResult<unknown>> {
  return PortalApi.request("POST", "/jobs/sendpreview", {
    data: sendPreviewDto,
  })
}
