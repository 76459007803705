import React from "react"
import { FormattedMessage } from "react-intl"

export interface FormSectionProps {
  // parent validation
  updateDatas?: (key: string, value: any) => void
  next?: ((evt, datas?) => void) | string | any
  prev?: ((evt, datas?) => void) | string | any
  save?: (datas) => void
}

/**
 * Base class for characterize the form sections we can add in the MultiStep form.
 * Propose an implementation
 * @abstract
 * @class FormSection
 * @extends {React.Component<FormSectionProps, {}>}
 */
abstract class FormSection<P> extends React.Component<P & FormSectionProps> {
  state

  constructor(props) {
    super(props)
    // add datas in the state
    this.state = {
      value: {},
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
  }

  handleFormChange(key, value) {
    const formState = this.state
    formState.value[key] = value
    this.setState(formState)
  }

  handleInputChange(input) {
    const val = input.target.value
    const name = input.target.name
    // Validation here
    this.handleFormChange(name, val)
  }
}

export default FormSection
