/**
 * Représente le nombre de clics par date pour toutes les bannières
 * @class BannerClickByDate
 */
import { Moment } from "moment"

class BannerClickByDate {
  date: Moment
  dateLibelle: string
  nbClicks: number
}

export default BannerClickByDate
