import { TrialActionTypes } from "./"

export const actionCreators = {
  initTrial: () => ({ type: TrialActionTypes.INIT_TRIAL_DATA }),
  setTrialSignature: () => ({ type: TrialActionTypes.SET_TRIAL_SIGNATURE }),
  setTrialBanner: () => ({ type: TrialActionTypes.SET_TRIAL_BANNER }),
  setTrialSignatureBanner: () => ({
    type: TrialActionTypes.SET_TRIAL_SIGNATURE_BANNER,
  }),
  setTrialStep: (step: number) => ({
    type: TrialActionTypes.SET_TRIAL_STEP,
    step,
  }),
  fetchTrialStep: () => ({ type: TrialActionTypes.FETCH_TRIAL }),
  sendTrialSignaturePreviewByMail: (withBanner: boolean) => ({
    type: TrialActionTypes.SEND_TRIAL_SIGNATURE_PREVIEW,
    withBanner,
  }),
}
