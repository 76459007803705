import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as EmptyResults } from "features/Scenarios/assets/icon-scenarios-empty-results.svg"

const messages = defineMessages({
  noResults: {
    id: "ScenariosListLayout.EmptyResults",
    defaultMessage: "Aucun résultat de recherche",
  },
})

const ScenariosListLayoutEmptyResults: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="scenarios-list-layout__no-results">
      <EmptyResults />
      <p className="scenarios-list-layout__no-results--label">
        {formatMessage(messages.noResults)}
      </p>
    </div>
  )
}

export default ScenariosListLayoutEmptyResults
