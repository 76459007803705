import { FunctionComponent } from "react"

import Loader from "components/Loader"

import useSubsidiariesData from "../../hooks/useSubsidiariesData"
import SubsidiariesList from "../SubsidiariesList"
import SubsidiariesListEmpty from "../SubsidiariesListEmpty"

const SubsidiariesHome: FunctionComponent = () => {
  const { subsidiaries, isLoading } = useSubsidiariesData()

  if (isLoading) return <Loader isLoading={isLoading} fullScreen />

  if (subsidiaries.length > 0)
    return <SubsidiariesList subsidiaries={subsidiaries} />

  return <SubsidiariesListEmpty />
}

export default SubsidiariesHome
