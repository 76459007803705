import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import PeoplePicker, { Tag } from "components/PeoplePicker"

import ArrayHelper from "utils/ArrayHelper"
import { usersSelectors } from "features/Users"
import { groupsSelectors } from "features/Groups"

import {
  removeGroupIdToSignature,
  removeSingleUserIdToSignature,
} from "features/Signatures/SignaturesReducer"

import Group from "features/Groups/GroupsModels"
import { User } from "features/Users/UserModels"

interface SignaturePeoplePickerProps {
  tags: Tag[]
  assignedUsersIds: number[]
  assignedGroupsIds: number[]
  onSetTags: Dispatch<SetStateAction<Tag[]>>
}

const SignaturePeoplePicker: FunctionComponent<SignaturePeoplePickerProps> = ({
  tags,
  assignedUsersIds,
  assignedGroupsIds,
  onSetTags,
}) => {
  const dispatch = useDispatch()
  const allUsers = useSelector(usersSelectors.getAllUsers)
  const allGroups: Group[] = useSelector(groupsSelectors.getAllGroups)

  useEffect(() => {
    const newTags = []
    if (assignedGroupsIds.length > 0) {
      newTags.push(
        ...assignedGroupsIds.map((groupId) => {
          const group = allGroups.find((group) => group.Id === groupId)
          if (!group) return null
          return {
            id: groupId,
            label: group?.Name || "",
            type: "group",
          }
        }),
      )
    }
    if (assignedUsersIds.length > 0) {
      newTags.push(
        ...assignedUsersIds.map((userId) => {
          const user = allUsers.find((user) => user.Id === userId)
          const fullName = (user && User.getFullName(user)) || ""
          if (!user) return null
          return {
            id: userId,
            label: fullName,
            type: "user",
          }
        }),
      )
    }
    onSetTags(newTags.filter(Boolean))
  }, [assignedGroupsIds, assignedUsersIds, allGroups, allUsers, onSetTags])

  const handleRemoveUser = (userId: number) => {
    dispatch(removeSingleUserIdToSignature(userId))
  }

  const handleRemoveGroup = (groupId: number) => {
    dispatch(removeGroupIdToSignature(groupId))
  }

  const handleTagClick = (item) => {
    if (item.type === "group") {
      handleRemoveGroup(item.id)
      const remainingTags = ArrayHelper.reject(
        tags,
        (tag) => tag.type === "group" && tag.id === item.id,
      )
      onSetTags(remainingTags)
    }
    if (item.type === "user") {
      handleRemoveUser(item.id)
      const remainingTags = ArrayHelper.reject(
        tags,
        (tag) => tag.type === "user" && tag.id === item.id,
      )
      onSetTags(remainingTags)
    }
  }

  return <PeoplePicker tags={tags} onTagClick={handleTagClick} />
}

export default SignaturePeoplePicker
