import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react"

import { FormattedMessage } from "react-intl"

import classNames from "classnames"

import StringHelper from "utils/StringHelper"

import { ReactComponent as WarningIcon } from "assets/icon-warning.svg"
import { ReactComponent as CheckIcon } from "assets/icon-check.svg"

interface RegisterFormPasswordValidatorProps {
  password: string
  onValidate: Dispatch<SetStateAction<boolean>>
}

const highlightedValues = {
  hgt: (chunks: string) => (
    <span className="register-form__pwd-validator--text highlight">
      {chunks}
    </span>
  ),
}

const validators = [
  {
    id: "minLength",
    checker: StringHelper.validateLength,
    translation: {
      id: "RegisterForm.PasswordMinLength",
      defaultMessage: "8 <hgt>caractères</hgt> minimum requis",
      values: highlightedValues,
    },
  },
  {
    id: "oneLowercase",
    checker: StringHelper.validateLowerChar,
    translation: {
      id: "RegisterForm.PasswordOneLowercase",
      defaultMessage: "1 <hgt>m</hgt>inuscule minimum requise",
      values: highlightedValues,
    },
  },
  {
    id: "oneUppercase",
    checker: StringHelper.validateUpperChar,
    translation: {
      id: "RegisterForm.PasswordOneUppercase",
      defaultMessage: "1 <hgt>M</hgt>ajuscule minimum requise",
      values: highlightedValues,
    },
  },
  {
    id: "oneNumber",
    checker: StringHelper.validateNbNumbers,
    translation: {
      id: "RegisterForm.PasswordOneNumber",
      defaultMessage: "<hgt>1</hgt> chiffre minimum requis",
      values: highlightedValues,
    },
  },
]

const RegisterFormPasswordValidator: FunctionComponent<
  RegisterFormPasswordValidatorProps
> = ({ password, onValidate }) => {
  useEffect(() => {
    const isValid = validators.reduce((acc, curr, i) => {
      if (i === 0 || acc) return curr.checker(password)
      return false
    }, false)

    onValidate(isValid)
  }, [password])

  return (
    <div className="register-form__pwd-validator">
      {validators.map((validator) => {
        const validate = validator.checker(password)
        return (
          <div
            className="register-form__pwd-validator--element"
            key={validator.id}
          >
            {validate ? (
              <CheckIcon className="register-form__pwd-validator--check-icon" />
            ) : (
              <WarningIcon className="register-form__pwd-validator--warning-icon" />
            )}
            <p
              className={classNames(
                "register-form__pwd-validator--text",
                validate ? "is-valid" : "is-invalid",
              )}
            >
              <FormattedMessage
                id={validator.translation.id}
                defaultMessage={validator.translation.defaultMessage}
                values={validator.translation.values}
              />
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default RegisterFormPasswordValidator
