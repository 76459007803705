import { FunctionComponent, ReactNode } from "react"

import { ReactComponent as O365Icon } from "assets/icon-O365.svg"

import { O365_REDIRECT_URL } from "features/Accounts/AccountsConstants"

import OAuthProviderButton from "features/Accounts/components/OAuthProviderButton"
import useOauthProviderRedirectUrl from "features/Accounts/hooks/useOauthProviderRedirectUrl"

interface O365OauthButtonProps {
  children: ReactNode
  isOutlined?: boolean
}

const O365OauthButton: FunctionComponent<O365OauthButtonProps> = ({
  children,
  isOutlined,
}) => {
  const redirectUrl = useOauthProviderRedirectUrl(O365_REDIRECT_URL)

  return (
    <OAuthProviderButton
      redirectUrl={redirectUrl}
      providerName="Microsoft"
      isOutlined={isOutlined}
    >
      <O365Icon /> {children}
    </OAuthProviderButton>
  )
}

export default O365OauthButton
