import React from "react"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"

interface Props {
  tags: any[]
  onTagClick(item: any)
  onSearchChange(value: string)
  placeholder?: string
  displayNumber?: number
}

const TagSearchBar: React.FC<Props> = (props) => {
  const { tags, onTagClick, onSearchChange, placeholder, displayNumber } = props
  const [value, setValue] = React.useState("")
  const [showAllTags, setShowAllTags] = React.useState(false)

  const counter = tags.length - displayNumber

  const handleChange = (evt) => {
    setValue(evt.target.value)
    onSearchChange(evt.target.value)
  }

  return (
    <div className="tag-search-bar">
      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        fullWidth
        InputProps={{
          startAdornment: (
            <div className="tag-search-bar__tags">
              {tags &&
                tags.map((value, index) => {
                  if (
                    !showAllTags &&
                    !!displayNumber &&
                    index === displayNumber
                  )
                    return (
                      <span
                        className="tag-search-bar__tag"
                        onClick={() => setShowAllTags(true)}
                      >
                        {`${counter}+`}
                      </span>
                    )

                  if (!showAllTags && !!displayNumber && index > displayNumber)
                    return

                  return (
                    <span
                      key={index}
                      className="tag-search-bar__tag"
                      onClick={() => onTagClick(value)}
                    >
                      {value.label}
                    </span>
                  )
                })}
            </div>
          ),
          endAdornment: (
            <SearchIcon classes={{ root: `tag-search-bar__icon` }} />
          ),
        }}
      />
    </div>
  )
}

export default TagSearchBar
