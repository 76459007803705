import { FunctionComponent, ReactNode } from "react"

import { ReactComponent as IconUser } from "assets/icon-user.svg"
import { ReactComponent as IconGroup } from "assets/icon-group.svg"

interface UsersTileDatasSideProps {
  children: ReactNode
  label: string
  infos: string
  icon: "users" | "groups"
}

function renderIcon(icon) {
  const icons = {
    users: <IconUser className="users-tile__datas--icon users" />,
    groups: <IconGroup className="users-tile__datas--icon groups" />,
  }

  return icons[icon]
}

const UsersTileDatasSide: FunctionComponent<UsersTileDatasSideProps> = ({
  children,
  label,
  infos,
  icon,
}) => {
  return (
    <div className="users-tile__datas--side">
      <div className="users-tile__datas--icon-container">
        {renderIcon(icon)}
        {children}
      </div>
      <p className="users-tile__datas--label">{label}</p>
      <p className="users-tile__datas--infos">{infos}</p>
    </div>
  )
}

export default UsersTileDatasSide
