import { FunctionComponent } from "react"

import { useSearchParams } from "react-router-dom"

import SubsidiariesHome from "features/Subsidiaries/components/SubsidiariesHome"
import SubsidiariesAdminsPanel from "features/Subsidiaries/components/SubsidiariesAdminsPanel"

const SubsidiariesPageContent: FunctionComponent = () => {
  const [searchParams] = useSearchParams()

  const renderedComponent = {
    subsidiaries: <SubsidiariesHome />,
    admin: <SubsidiariesAdminsPanel />,
  }

  const actualTab = searchParams.get("tab")
  return (
    <div className="subsidiaries-page__content">
      {actualTab && renderedComponent[actualTab]}
    </div>
  )
}

export default SubsidiariesPageContent
