import { FunctionComponent, useEffect, useMemo, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { defineMessages, useIntl } from "react-intl"

import { ModalActions, ModalService } from "components/Modal"

import useAdminsAccountsData from "features/Accounts/hooks/useAdminsAccountsData"
import useSubsidiariesData from "features/Subsidiaries/hooks/useSubsidiariesData"

import SubsidiariesAdminsGrid from "features/Subsidiaries/components/SubsidiariesAdminsGrid"

import { accountsSelectors } from "features/Accounts"
import { addNotification } from "features/Notifications"

import {
  createSubsidiaryAdminAccount,
  updateAdminAccountRoles,
} from "features/Accounts/AccountsReducer"
import { deleteAdminAccountAction } from "features/Accounts/AccountsActions"

import { DataGridAdminAccount, Roles } from "features/Accounts/AccountsModels"
import { Notification } from "features/Notifications/NotificationModels"

import { LoadingStatus, SavingStatus } from "core/CoreModels"

export const NEW_SUBSIDIARY_ADMIN_ID = "-1"

const messages = defineMessages({
  modalTitle: {
    id: "SubsidiariesAdminsGrid.ModalTitle",
    defaultMessage: "Suppression d'administrateur",
  },
  modalLabel: {
    id: "SubsidiariesAdminsGrid.ModalLabel",
    defaultMessage:
      "Vous allez supprimer l'administrateur, cette action sera irréversible, êtes-vous sûr de vouloir continuer ?",
  },
  cancel: {
    id: "SubsidiariesAdminsGrid.ModalCancel",
    defaultMessage: "Annuler",
  },
  delete: {
    id: "SubsidiariesAdminsGrid.ModalDelete",
    defaultMessage: "Supprimer",
  },
  mustHaveSubsidiaries: {
    id: "SubsidiariesAdminsGrid.MustHaveSubsidiaries",
    defaultMessage:
      "Vous devez ajouter au moins une filiale pour créer cet administrateur",
  },
})

const SubsidiariesAdminsPanelModal = ({ onCancel, onDelete }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="subsidiary-modal">
      <div className="subsidiary-modal__content">
        <p className="subsidiary-modal__content--label">
          {formatMessage(messages.modalLabel)}
        </p>
      </div>
      <ModalActions>
        <ModalActions.Secondary onClick={onCancel}>
          {formatMessage(messages.cancel)}
        </ModalActions.Secondary>
        <ModalActions.Main color="is-danger" onClick={() => onDelete()}>
          {formatMessage(messages.delete)}
        </ModalActions.Main>
      </ModalActions>
    </div>
  )
}

const SubsidiariesAdminsPanel: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const [newAdminAccount, setNewAdminAccount] =
    useState<DataGridAdminAccount | null>(null)
  const [data, setData] = useState([])
  const [searchString, setSearchString] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(50)
  const [isEditing, setIsEditing] = useState(false)
  const [disableSave, setDisableSave] = useState(false)

  const { Id: currentAccountId } = useSelector(
    accountsSelectors.getCurrentAccount,
  )
  const newAdminAccountSavingStatus = useSelector(
    accountsSelectors.getNewAdminAccountSavingStatus,
  )
  const adminAccountUpdatingStatus = useSelector(
    accountsSelectors.getAdminAccountUpdatingStatus,
  )
  const newAdminAccountErrorMessage = useSelector(
    accountsSelectors.getNewAdminAccountErrorMessage,
  )

  const { subsidiariesAdminAccounts, loadingStatus } = useAdminsAccountsData()
  const { loadingStatus: loadingStatusSubsidiaries } = useSubsidiariesData()

  useEffect(() => {
    if (newAdminAccountSavingStatus === SavingStatus.SUCCESS) {
      setNewAdminAccount(null)
      setIsEditing(false)
    }
    if (newAdminAccountSavingStatus === SavingStatus.ERROR) {
      const newData = data.map((adminAccount) => {
        if (adminAccount.isNew) adminAccount.setError(true)

        return adminAccount
      })
      setData(newData)
    }
  }, [newAdminAccountSavingStatus])

  useEffect(() => {
    const newData = subsidiariesAdminAccounts.map(
      (adminAccount) =>
        new DataGridAdminAccount({ ...adminAccount, isNew: false }),
    )

    setData([newAdminAccount, ...newData].filter(Boolean))
  }, [subsidiariesAdminAccounts, newAdminAccount])

  const filteredData = useMemo(
    () =>
      data.filter((adminAccount) => {
        if (searchString.length >= 3) {
          const { userName } = adminAccount
          return userName?.includes(searchString) || adminAccount.isNew
        }
        return adminAccount
      }),
    [data, searchString],
  )

  const lastPage = useMemo(
    () => Math.ceil(filteredData.length / currentPageSize),
    [filteredData, currentPageSize],
  )

  const handleSearch = (searchValue: string) => {
    setSearchString(searchValue)
  }

  const handleSave = () => {
    if (newAdminAccount.subsidiariesIds.length === 0) {
      dispatch(
        addNotification(
          new Notification(
            formatMessage(messages.mustHaveSubsidiaries),
            "INFO",
          ),
        ),
      )
      return
    }

    dispatch(
      createSubsidiaryAdminAccount({
        adminAccount: newAdminAccount,
        subsidiariesIds: newAdminAccount.subsidiariesIds,
      }),
    )
  }

  const handleAddLine = () => {
    const newAdminAccount = new DataGridAdminAccount({
      id: NEW_SUBSIDIARY_ADMIN_ID,
      company: "",
      roles: [],
      userName: "",
      isNew: true,
    })
    setNewAdminAccount(newAdminAccount)
    setIsEditing(true)
  }

  const handleCancel = () => {
    ModalService.close()
  }

  const deleteAdminAccount = (id: string) => {
    const isNew = data.find((adminAccount) => adminAccount.isNew)
    const relatedAdminAccount = data.find(
      (adminAccount) => adminAccount.id === id,
    )

    if (isNew && relatedAdminAccount) {
      setIsEditing(false)
      setNewAdminAccount(null)
    } else if (relatedAdminAccount) {
      dispatch(
        deleteAdminAccountAction(
          relatedAdminAccount.userName,
          relatedAdminAccount.id,
        ),
      )
    }

    handleCancel()
  }

  const handleDeleteAdminAccount = (id) => {
    ModalService.open({
      title: formatMessage(messages.modalTitle),
      content: (
        <SubsidiariesAdminsPanelModal
          onCancel={handleCancel}
          onDelete={() => deleteAdminAccount(id)}
        />
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCancel: async (_e) => handleCancel(),
    })
  }

  const handleEditEmail = (email: string, id: string) => {
    const newData = data.map((item) => {
      setIsEditing(true)
      if (item.id === id) {
        item.setUserName(email)
      }
      return item
    })
    setData(newData)
  }

  const handleToggleRole = (id: string, role: Roles) => {
    const adminAccount = data.find((adminAccount) => adminAccount.id === id)
    if (adminAccount.isNew) {
      adminAccount.toggleRole(role)
      setNewAdminAccount(new DataGridAdminAccount(adminAccount))
      return
    }
    if (
      !adminAccount.hasRole(role) &&
      adminAccountUpdatingStatus !== SavingStatus.SAVING
    ) {
      adminAccount.toggleRole(role)
      dispatch(updateAdminAccountRoles(adminAccount))
      return
    }
    if (adminAccountUpdatingStatus !== SavingStatus.SAVING) {
      adminAccount.toggleRole(role)
      dispatch(updateAdminAccountRoles(adminAccount))
    }
  }

  return (
    <SubsidiariesAdminsGrid
      adminsCount={subsidiariesAdminAccounts.length}
      data={filteredData.slice(
        currentPageSize * (currentPage - 1),
        currentPageSize * currentPage,
      )}
      onSearch={handleSearch}
      isLoading={
        loadingStatus === LoadingStatus.PENDING ||
        loadingStatusSubsidiaries === LoadingStatus.PENDING
      }
      currentPage={currentPage}
      pageSize={currentPageSize}
      lastPage={lastPage}
      changePage={(page) => setCurrentPage(page)}
      setPageSize={(pageSize) => setCurrentPageSize(pageSize)}
      isEditing={isEditing}
      onAddLine={handleAddLine}
      isSaving={newAdminAccountSavingStatus === SavingStatus.SAVING}
      disableSave={disableSave}
      onSave={handleSave}
      onDisableSave={(value) => setDisableSave(value)}
      onEditing={() => setIsEditing(true)}
      onEditEmail={handleEditEmail}
      currentAccountId={currentAccountId}
      onToggleRole={handleToggleRole}
      savingErrorMessage={newAdminAccountErrorMessage}
      onDeleteAdminAccount={handleDeleteAdminAccount}
    />
  )
}

export default SubsidiariesAdminsPanel
