import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import CallToActionZone from "features/Accounts/components/CallToActionZone"

const messages = defineMessages({
  loginCTATitle: {
    id: "RegisterCTA.Title",
    defaultMessage: "Vous ne connaissez pas l'application ?",
  },
  loginCTADemo: {
    id: "RegisterCTA.Demo",
    defaultMessage: "Planifier une démo",
  },
})

const RegisterLeftPanelCallToAction: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <CallToActionZone
      ctaLabel={formatMessage(messages.loginCTATitle)}
      ctaButtonLabel={formatMessage(messages.loginCTADemo)}
    />
  )
}

export default RegisterLeftPanelCallToAction
