/**
 * Helpers for Dynamic Query manipulations
 *
 * @class DynamicGroupsHelper
 */

import { DynamicGroupFields } from "features/Users/UsersReducers"
import {range} from "lodash"

export const comparatorDynamicName = 'comparator'
export const valueDynamicName = 'value'
export const attributeDynamicName = 'attribute'
export const toggleDynamicName = 'toggle'

export const comparatorDynamicTypes = {
    "Est égale à" : "eq", 
    "Est différent de": "neq", 
    "Contient": "contains", 
    "Ne contient pas": "!contains", 
    "Est vide": 'eq null', 
    "N'est pas vide": 'neq null',
}

class DynamicGroupsHelper {

    public static dataToQuery(data: DynamicGroupFields){
        let query = ""

        const keys = Object.keys(data).filter((key) =>{
            return key.includes(`${toggleDynamicName}`) || key.includes(`${valueDynamicName}`) || key.includes(`${attributeDynamicName}`) || key.includes(`${comparatorDynamicName}`) 
        })
        range((keys.length % 3) + 1).map((index) => { 
            query+=`${data[`${attributeDynamicName}${index}`]} ${data[`${comparatorDynamicName}${index}`]} ${ data[`${valueDynamicName}${index}`] ? data[`${valueDynamicName}${index}`] : ""}` 
            
            if(index < keys.length % 3){
                query+=` ${data[`${toggleDynamicName}${index+1}`] == true ? "or" : "and"} `
            }
        })
        return query;
    }

    public static queryToFieldName(query: String){
        const fields = {}
        const indexRows = []
        const Rows = query?.replaceAll("\\","").split(/(\sor\s|\sand\s)/g).filter(element => element != " or " &&  element != " and ")
        Rows?.forEach((row, index) => {
            indexRows.push(index);
            const rowsplitedBySpace =  row.split(" ")
            const rowsplitedByComparator=  row.split(" eq ").join(',').split(" neq ").join(',').split(" contains ").join(',').split(" !contains ").join(',').split(",")
            if(row.includes(comparatorDynamicTypes["N'est pas vide"])){
                fields[`${comparatorDynamicName}${index}`] = comparatorDynamicTypes["N'est pas vide"]
            }else if(row.includes(comparatorDynamicTypes["Est vide"])){
                fields[`${comparatorDynamicName}${index}`] = comparatorDynamicTypes["Est vide"]
            }else{
                fields[`${comparatorDynamicName}${index}`] = rowsplitedBySpace[1]
                fields[`${valueDynamicName}${index}`] = rowsplitedByComparator[1]
            }
            fields[`${attributeDynamicName}${index}`] = rowsplitedBySpace[0] 
        })
        const allToggle = query?.match(/(\sor\s|\sand\s)/g)
        allToggle?.map((toggleContent, index) => {
            fields[`${toggleDynamicName}${index+1}`] = toggleContent.trim() == "or"
        })
        return {fields,indexRows}
    }
}

export default DynamicGroupsHelper

