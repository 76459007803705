import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { accountsSelectors } from "features/Accounts"
import { addAdminUserAction } from "features/Accounts/AccountsActions"

import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

import { usersSelectors } from ".."

interface PropertiesState {
  firstname: string
  lastname: string
  streetaddress: string
  postalcode: string
  city: string
  jobtitle: string
  phone: string
  mobile: string
  website: string
  mail: string
}

interface ErrorsState {
  firstname: boolean
  lastname: boolean
  streetaddress: boolean
  postalcode: boolean
  city: boolean
  jobtitle: boolean
}

const propertiesInitialState = {
  firstname: "",
  lastname: "",
  streetaddress: "",
  postalcode: "",
  city: "",
  jobtitle: "",
  phone: "",
  mobile: "",
  website: "",
  mail: null,
}

const errorsInitialState = {
  firstname: false,
  lastname: false,
  streetaddress: false,
  postalcode: false,
  city: false,
  jobtitle: false,
}

const useAdditionalInformations = () => {
  const dispatch = useDispatch()
  const [properties, setProperties] = useState<PropertiesState>(
    propertiesInitialState,
  )
  const [errors, setErrors] = useState<ErrorsState>(errorsInitialState)

  const adminAccount = useSelector(accountsSelectors.getCurrentAccount)
  const adminUser = useSelector(usersSelectors.getCurrentUser)

  useEffect(() => {
    if (adminUser?.Properties)
      setProperties((prevState) => ({
        ...prevState,
        ...adminUser.Properties,
      }))
  }, [adminUser])

  useEffect(() => {
    if (adminAccount)
      setProperties((prevState) => ({
        ...prevState,
        firstname: adminAccount.Firstname,
        lastname: adminAccount.Lastname,
        jobtitle: adminAccount.Function,
        phone: adminAccount.PhoneNumber,
        mail: adminAccount.Email,
      }))
  }, [adminAccount])

  const checkInput = (inputValue: string, inputName: string) => {
    setErrors((prevState) => ({
      ...prevState,
      [inputName]: inputValue.length === 0,
    }))
  }

  const handleInputChange = (event) => {
    const { value, name } = event.target

    checkInput(value, name)

    setProperties((prevState) => ({ ...prevState, [name]: value }))
  }

  const checkInputs = () => {
    for (const key in errors) {
      errors[key] = properties[key] === ""
    }
    setErrors({ ...errors })
    for (const key in errors) if (errors[key] === true) return false
    return true
  }

  const handleAddUser = () => {
    if (!checkInputs()) return

    const userId = adminUser?.Id || -1

    const userProperties = { ...properties, usermessage: "", disclaimer: "" }

    dispatch(
      addAdminUserAction(userId, userProperties, `${TRIAL_TUNNEL_URL}/2`),
    )
  }

  return { properties, errors, handleInputChange, handleAddUser }
}

export default useAdditionalInformations
