import {
  put,
  call,
  takeEvery,
  all,
  select,
  takeLatest,
  take,
} from "redux-saga/effects"

import { PayloadAction } from "@reduxjs/toolkit"

import { signaturesSelectors } from "features/Signatures"

import {
  updateActiveSignaturesAssignationsAction,
  loadActiveSignatureAffectationsAction,
  toggleSendingModeAction,
  removeSendingModeFromSignatureAction,
  fetchSignaturesCustomQueryAction,
} from "features/Signatures/SignaturesActions"

import { withSagaErrorHandler } from "features/Errors"

import {
  fetchSignatures as fetchSignaturesCustomQueryApi,
  fetchGroupsForSignature,
  fetchSignatureAssignations,
  fetchUsersForSignature,
  assignUsersToSignature,
  assignGroupsToSignature,
  fetchSignaturesApi,
  updateSignature,
} from "features/Signatures/SignaturesApi"

import { LoadingStatus, TypedResult } from "core/CoreModels"
import {
  SendingModes,
  SignatureAssignationsDto,
  SignatureDto,
} from "./SignaturesModels"
import { User, UserDto } from "features/Users/UserModels"
import Group from "features/Groups/GroupsModels"
import {
  SignaturesSlices,
  assignGroupsSuccess,
  assignUsersSuccess,
  fetchSignatures,
  fetchSignaturesCustomQuerySuccess,
  fetchSignaturesFailure,
  fetchSignaturesSuccess,
  loadActiveSignatureAffectedGroupsSuccess,
  loadActiveSignatureAffectedUsersSuccess,
  loadAffectedGroupsSuccess,
  loadAffectedUsersSuccess,
  loadMultipleAffectedGroups,
  loadMultipleAffectedGroupsSuccess,
  loadSignatureAffectedUsersCount,
  loadSignatureAffectedUsersCountSuccess,
  removeSendingModeFromSignatureSuccess,
  setEditingSignatureSendingMode,
  updateSendingMode,
} from "./SignaturesReducer"
import Signature from "entities/Signature"

import { fillUserProperties } from "sagas/signatures.sagas"

import { LoadingSlicesTypes } from "core/services/loadingSlicesService"
import { featureNames } from "config/config.features"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import {
  Params,
  requestParamsBuilder,
} from "core/services/requestBuilderService"
import { BuilderSignature } from "features/BuilderSignatures/BuilderSignaturesModels"

export function* getOrLoadSignaturesSlice(
  neededSlice: "all" | SignaturesSlices,
) {
  const signaturesLoadingStatus = yield select(
    signaturesSelectors.getSignaturesSliceLoadingStatus(neededSlice),
  )

  if (signaturesLoadingStatus !== LoadingStatus.LOADED) {
    yield put(fetchSignatures(neededSlice))
    yield take(fetchSignaturesSuccess.type)
  }

  const allSignatures: Signature[] = yield select(
    signaturesSelectors.getAllSignatures,
  )

  return allSignatures
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSignaturesAsync(
  action: PayloadAction<LoadingSlicesTypes<SignaturesSlices>>,
) {
  const isSignaturesLoaded = yield select(
    signaturesSelectors.getSignaturesSliceLoadingStatus(action.payload),
  )

  if (isSignaturesLoaded !== LoadingStatus.LOADED) {
    const result: TypedResult<SignatureDto[]> = yield call(
      fetchSignaturesApi,
      action.payload,
    )

    if (!result.success) throw new Error(result.errorMessage)

    const signatures = result.result.map(
      (signaturesJson) => new Signature(signaturesJson),
    )
    // Compile une seule fois les templates pour extraire les données persistantes (logo, social, ...) et replacer les bons tags pour permettre la modif
    const allSignatures = signatures.map((signature: Signature) => {
      signature.Template.userProperties = signature.Template.GetUserProperties()
      // extraction des tags HTML portant la balise bmm-data-tag
      signature.Template.DecompileDataTags()
      // remplacement des balises par les marqueurs ?##?
      signature.Template.CompileDataTags()

      return signature
    })

    yield put(
      fetchSignaturesSuccess({
        signatures: allSignatures,
        slice: action.payload,
      }),
    )
  }

  // On remplace les tags lorsque les users sont chargés
  yield call(fillUserProperties)
}

function* fetchSignatureAffectationAsync(signatureId) {
  const [affectedUsersResult, affectedGroupsResult] = yield all([
    call(fetchUsersForSignature, signatureId),
    call(fetchGroupsForSignature, signatureId),
  ])

  const affectedUsers = affectedUsersResult.result
  const affectedGroups = affectedGroupsResult.result

  return { affectedUsers, affectedGroups }
}

function* loadActiveSignatureAffectationsAsync(action) {
  const signatureId = action.payload

  const { affectedUsers, affectedGroups } = yield call(
    fetchSignatureAffectationAsync,
    signatureId,
  )
  yield all([
    put(loadActiveSignatureAffectedUsersSuccess(affectedUsers)),
    put(loadActiveSignatureAffectedGroupsSuccess(affectedGroups)),
    put(loadAffectedUsersSuccess({ signatureId, affectedUsers })),
    put(loadAffectedGroupsSuccess({ signatureId, affectedGroups })),
  ])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSignatureAffectationsByUsersIdsAsync(
  action: PayloadAction<number>,
) {
  const response: TypedResult<SignatureAssignationsDto> = yield call(
    fetchSignatureAssignations,
    action.payload,
  )

  if (!response.success) throw new Error(response.errorMessage)

  yield put(
    loadSignatureAffectedUsersCountSuccess({
      signatureId: response.result.id,
      affectedUsersCount: response.result.userIds,
    }),
  )
}

function* assignUsersToActiveSignature(payload: number[]) {
  const activeSignature = yield select(signaturesSelectors.getActiveSignature)

  const result: TypedResult<UserDto[]> = yield call(
    assignUsersToSignature,
    activeSignature.Id,
    payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const usersIds = result.result
    .map((userJson) => new User(userJson))
    .map((user) => user.Id)

  yield put(assignUsersSuccess({ id: activeSignature.id, usersIds }))
}

function* assignGroupsToActiveSignature(payload: number[]) {
  const activeSignature = yield select(signaturesSelectors.getActiveSignature)

  const result = yield call(
    assignGroupsToSignature,
    activeSignature.Id,
    payload,
  )

  if (!result.success) throw new Error(result.errorMessage)

  const groupsIds = result.result
    .map((group) => new Group(group))
    .map((group) => group.Id)

  yield put(assignGroupsSuccess({ id: activeSignature.Id, groupsIds }))
}

function* assignToActiveSignatureAsync(
  action: PayloadAction<{ usersIds: number[]; groupsIds: number[] }>,
) {
  const activeSignature = yield select(signaturesSelectors.getActiveSignature)
  const { usersIds, groupsIds } = action.payload

  yield all([
    call(assignUsersToActiveSignature, usersIds),
    call(assignGroupsToActiveSignature, groupsIds),
  ])
  yield put(loadSignatureAffectedUsersCount(activeSignature.Id))
}

function* fetchSignatureGroupsAffectationAsync(signatureId) {
  const result = yield call(fetchGroupsForSignature, signatureId)

  if (!result.success) throw new Error(result.errorMessage)

  const affectedGroups = result.result

  return { signatureId, affectedGroups }
}

function* loadMultipleSignatureGroupsAffectationsAsync(action) {
  const signatureIds = action.payload

  const responses = yield all(
    signatureIds.map((signatureId) =>
      call(fetchSignatureGroupsAffectationAsync, signatureId),
    ),
  )

  yield put(loadMultipleAffectedGroupsSuccess(responses))
}

export function* initSendingMode() {
  const sendingModesInit = {
    newMail: { newMail: true, inResponse: false },
    inResponse: { newMail: false, inResponse: true },
  }

  const activeSignatureId = yield select(
    signaturesSelectors.getActiveSignatureId,
  )
  const isNewMailInResponseFeatureActive = yield select(
    isFeatureActive(featureNames.NEWMAIL_INRESPONSE),
  )

  if (!isNewMailInResponseFeatureActive) {
    yield put(
      setEditingSignatureSendingMode({ newMail: true, inResponse: true }),
    )
    return
  }

  if (!activeSignatureId) {
    const sendingMode = yield select(signaturesSelectors.getSendingMode)
    yield put(setEditingSignatureSendingMode(sendingModesInit[sendingMode]))
    return
  }

  const activeSignature: Signature = yield select(
    signaturesSelectors.getActiveSignature,
  )

  yield put(
    setEditingSignatureSendingMode({
      newMail: activeSignature.NewMail,
      inResponse: activeSignature.InResponse,
    }),
  )
  return
}

function* toggleSendingMode() {
  const currentSendingMode = yield select(signaturesSelectors.getSendingMode)

  const newSendingMode =
    currentSendingMode === "newMail" ? "inResponse" : "newMail"

  yield put(updateSendingMode(newSendingMode))
}

function* removeSendingModeFromSignatureAsync(
  action: PayloadAction<{ signatureId: number; sendingMode: SendingModes }>,
) {
  const { signatureId, sendingMode } = action.payload

  const selectedSignature = yield select((state) =>
    signaturesSelectors.getSignatureById(state, signatureId),
  )

  const updatedSignature =
    sendingMode === "newMail"
      ? { ...selectedSignature, NewMail: false }
      : { ...selectedSignature, InResponse: false }

  const response = yield call(
    updateSignature,
    Signature.toJson(updatedSignature) as Signature,
  )

  if (!response.success) throw new Error(response.errorMessage)

  const newSignature = new Signature(response.result)

  yield put(
    removeSendingModeFromSignatureSuccess({
      id: newSignature.Id,
      newMail: newSignature.NewMail,
      inResponse: newSignature.InResponse,
    }),
  )
}

export function* fetchSignaturesCustomQueryAsync(
  action: PayloadAction<{
    requestId: string
    customQuery: Params<Signature | BuilderSignature>
  }>,
) {
  const { requestId, customQuery } = action.payload

  const response = yield call(
    fetchSignaturesCustomQueryApi,
    requestParamsBuilder(customQuery),
  )

  if (!response.success) throw new Error(response.errorMessage)

  const { result } = response

  yield put(
    fetchSignaturesCustomQuerySuccess({ requestId, customQuery, result }),
  )
}

export const signaturesWatchers = [
  takeEvery(
    loadActiveSignatureAffectationsAction.type,
    withSagaErrorHandler(loadActiveSignatureAffectationsAsync),
  ),
  takeEvery(
    loadSignatureAffectedUsersCount.type,
    withSagaErrorHandler(fetchSignatureAffectationsByUsersIdsAsync),
  ),
  takeEvery(
    updateActiveSignaturesAssignationsAction.type,
    withSagaErrorHandler(assignToActiveSignatureAsync),
  ),
  takeLatest(
    loadMultipleAffectedGroups.type,
    withSagaErrorHandler(loadMultipleSignatureGroupsAffectationsAsync),
  ),
  takeEvery(
    fetchSignatures.type,
    withSagaErrorHandler(fetchSignaturesAsync, fetchSignaturesFailure()),
  ),
  takeEvery(toggleSendingModeAction.type, toggleSendingMode),
  takeLatest(
    removeSendingModeFromSignatureAction.type,
    removeSendingModeFromSignatureAsync,
  ),
  takeEvery(
    fetchSignaturesCustomQueryAction.type,
    withSagaErrorHandler(fetchSignaturesCustomQueryAsync),
  ),
]
