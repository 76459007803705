import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import SignaturesTrackingSlider from "features/Tracking/components/SignaturesTrackingSlider"
import SingleSignatureTracking from "features/Tracking/components/SingleSignatureTracking"
import TrackingTotals from "features/Tracking/components/TrackingTotals"

import SignaturesTrackingWrapperLoading from "./components/SignaturesTrackingWrapperLoading"

import { trackingSelectors } from "features/Tracking"

import { GlobalStates } from "store"
import { LoadingStatus } from "core/CoreModels"

const messages = defineMessages({
  title: {
    id: "Tracking.SignaturesTrackingSliderTitle",
    defaultMessage: "Toutes les signatures",
  },
  tooltip: {
    id: "Tracking.SignaturesTrackingSliderTooltip",
    defaultMessage: "Nombre d'utilisateurs associés à une signature",
  },
})

const SignaturesTrackingWrapper: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [selectedSignature, setSelectedSignature] = useState(null)

  const loadingStatus = useSelector<GlobalStates, LoadingStatus>(
    trackingSelectors.getSignaturesTrackingLoadingStatus,
  )
  const allSignaturesTracking = useSelector(
    trackingSelectors.getAllSignaturesTracking,
  )

  const maxUsers = useMemo(
    () => Math.max(...allSignaturesTracking.map((s) => s.maxAffectedUsers)),
    [allSignaturesTracking],
  )

  const totalClicks = useMemo(
    () =>
      allSignaturesTracking
        .map((s) => s.totalClicks)
        .reduce((acc, curr) => acc + curr, 0),
    [allSignaturesTracking],
  )

  const entitiesTrackingHistory = useMemo(
    () => allSignaturesTracking.map((s) => s.history),
    [allSignaturesTracking],
  )

  useEffect(() => {
    if (selectedSignature && allSignaturesTracking.length > 0) {
      const updated = allSignaturesTracking.find(
        (s) => s.id === selectedSignature.id,
      )

      setSelectedSignature(
        updated !== undefined ? updated : allSignaturesTracking[0],
      )
    }
  }, [selectedSignature, allSignaturesTracking])

  const handleSelect = (id: number) => {
    const relatedSignature = allSignaturesTracking.find(
      (sign) => sign.id === id,
    )
    if (relatedSignature) setSelectedSignature(relatedSignature)
  }

  if (loadingStatus !== LoadingStatus.LOADED)
    return (
      <SignaturesTrackingWrapperLoading title={formatMessage(messages.title)} />
    )

  if (!selectedSignature)
    return (
      <>
        <SignaturesTrackingSlider
          signatures={allSignaturesTracking}
          handleSelect={handleSelect}
          title={formatMessage(messages.title)}
        />
        <TrackingTotals
          entitiesTrackingHistory={entitiesTrackingHistory}
          maxUsers={maxUsers}
          totalClicks={totalClicks}
          tooltip={formatMessage(messages.tooltip)}
        />
      </>
    )

  return (
    <SingleSignatureTracking
      signature={selectedSignature}
      handleUnselect={() => setSelectedSignature(null)}
    />
  )
}

export default SignaturesTrackingWrapper
