import React, { Fragment } from "react"

import CollapseListItem from "../CollapseListItem/CollapseListItem"

import { PeoplePickerCollapseListItem } from "../PeoplePickerCollapseLists"

interface CollapseListContentProps {
  data: PeoplePickerCollapseListItem[]
  grouped?: boolean
  selectedData?: PeoplePickerCollapseListItem[]
  onClick?: (item: PeoplePickerCollapseListItem) => void
}

const CollapseListContent: React.FC<CollapseListContentProps> = (props) => {
  const { data, grouped, selectedData, onClick } = props

  function listeElement() {
    return data
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
      .map((value, index, list) => {
        let group = ""
        if (index === 0) {
          group = list[index].label.substring(0, 1)
        } else if (
          list[index - 1].label.substring(0, 1) !==
          list[index].label.substring(0, 1)
        ) {
          group = list[index].label.substring(0, 1)
        }
        return (
          <Fragment key={value.id}>
            {group.length > 0 && grouped && (
              <p className="people-picker-collapse-list__list--grouped">
                {group.toUpperCase()}
              </p>
            )}
            <CollapseListItem
              item={value}
              onClick={onClick}
              checked={
                selectedData && selectedData.map((i) => i.id).includes(value.id)
              }
            />
          </Fragment>
        )
      })
  }

  return (
    <div className="people-picker-collapse-list__list">{listeElement()}</div>
  )
}

export default CollapseListContent
