import { FunctionComponent } from "react"

import Podium from "features/Tracking/components/Podium"

import { ReactComponent as SmallGraphIcon } from "features/Tracking/assets/small_loading_graph.svg"

const AXIS_BUBBLE_LOADING = 6

const ScenariosPowerUsersLoading: FunctionComponent = () => {
  return (
    <div className="scen-power-users">
      <div className="scen-power-users__side">
        <Podium isLoading />
      </div>
      <div className="scen-power-users__side">
        <div className="scen-power-users__skeleton--graph-container">
          <div className="scen-power-users__skeleton--y-axis-container">
            {[...Array(AXIS_BUBBLE_LOADING).keys()].map((el) => (
              <div
                key={el}
                className="scen-power-users__skeleton--graph-bubble"
              />
            ))}
          </div>
          <div className="scen-power-users__skeleton--graph-block">
            <SmallGraphIcon className="scen-power-users__skeleton--graph" />
            <div className="scen-power-users__skeleton--x-axis-container">
              {[...Array(AXIS_BUBBLE_LOADING).keys()].map((el) => (
                <div
                  key={el}
                  className="scen-power-users__skeleton--graph-bubble"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScenariosPowerUsersLoading
