import Feature from "features/Features/FeaturesModels"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import imgApiKeyImage from "../../../images/copy_api_key.png"
import generateApiKeyImage from "../../../images/generate-api.png"

interface Props {
  onActiveForm(active: boolean)
  onSave(dataform: { [x: string]: any })
  featureLucca: Feature
}

const LuccaParamsForm: React.FC<Props> = ({
  onActiveForm,
  onSave,
  featureLucca,
}) => {
  const [urlLuccas, setUrlLuccas] = useState(
    featureLucca.properties.find((p) => p.property === "luccadomain").value,
  )
  const [apiKeyLucca, setApiKeyLucca] = useState(
    featureLucca.properties.find((p) => p.property === "luccaapikey").value,
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleApiKey = (event) => {
    errors["luccaapikey"] = null
    setApiKeyLucca(event.target.value)
  }

  const handleUrl = (event) => {
    errors["luccadomain"] = null
    setUrlLuccas(event.target.value)
  }

  return (
    <div className="lucca_form_settings">
      <div className="lucca_form_title">
        <FormattedMessage
          id="LuccaForm.SettingsTitle"
          defaultMessage="Connecter mon annuaire Lucca avec BoostMyMail"
        />
      </div>
      <form>
        <p className="lucca_form_text">
          <FormattedMessage
            id="LuccaForm.SettingsDescription_1"
            defaultMessage="1. Rendez-vous dans le menu Réglages > API Keys "
          />
        </p>
        <p className="lucca_form_text">
          <FormattedMessage
            id="LuccaForm.SettingsDescription_2"
            defaultMessage="2. S'il n'y a aucune clé de créée veuillez cliquer sur 'Generate API Key'"
          />
        </p>
        <div className="lucca-config__content">
          <img src={generateApiKeyImage} alt="" />
        </div>
        <p className="lucca_form_text">
          <FormattedMessage
            id="LuccaForm.SettingsDescription_3"
            defaultMessage="3. Copier/Coller l'api key dans le champ correspondant ci-dessous "
          />
        </p>
        <div className="lucca-config__content">
          <img src={imgApiKeyImage} alt="" />
        </div>
        <div className="lucca_form_settings_url">
          <p className="lucca_form_settings_url-title">
            <FormattedMessage
              id="LuccaForm.SettingsUrlTitle"
              defaultMessage="URL de votre site Lucca "
            />
          </p>
          <input
            {...register("luccadomain", {
              required: `Champ vide veuillez le remplir `,
              pattern: {
                value: /^https:\/\/[a-zA-Z0-9\-._]+.(ilucca|ilucca-demo).net$/,
                message: "Veuillez renseigner une URL lucas",
              },
            })}
            type="text"
            className={`lucca_form_settings_url-input`}
            placeholder={"https://NOM.ilucca.net"}
            value={urlLuccas}
            onChange={handleUrl}
          />
          {errors["luccadomain"] && (
            <span className="lucca_error_form">
              <FormattedMessage
                id="LuccaForm.ErrorMessageDomainUrl"
                defaultMessage="Votre URL doit être de la forme https://NOM.ilucca.net"
              />
            </span>
          )}
        </div>
        <div className="lucca_form_settings_key">
          <p className="lucca_form_settings_key-title">
            <FormattedMessage
              id="LuccaForm.SettingsKeyTitle"
              defaultMessage="Votre clé API Lucca"
            />
          </p>
          <input
            {...register("luccaapikey", {
              required: `Champ vide veuillez le remplir `,
              pattern: {
                value: /^[a-zA-Z0-9]{8}-([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{12}$/,
                message: "L'Api n'est pas bien renseignée",
              },
            })}
            type="text"
            className="lucca_form_settings_key-input"
            placeholder={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
            value={apiKeyLucca}
            onChange={handleApiKey}
          />
          {errors["luccaapikey"] && (
            <span className="lucca_error_form">
              <FormattedMessage
                id="LuccaForm.ErrorMessageApiKey"
                defaultMessage="Votre clé API doit suivre le format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx "
              />
            </span>
          )}
        </div>
        <div className={"lucca_form-button_content"}>
          <button
            onClick={() => onActiveForm(false)}
            className={"lucca_form_button--active"}
          >
            <FormattedMessage
              id="LuccaForm.CancelButton"
              defaultMessage="Annuler"
            />
          </button>

          <button
            onClick={handleSubmit((data) => onSave(data))}
            className={"lucca_form_button--active"}
          >
            <FormattedMessage
              id="LuccaForm.SaveButton"
              defaultMessage="Enregistrer"
            />
          </button>
        </div>
      </form>
    </div>
  )
}

export default LuccaParamsForm
