import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import AssignationsPanel from "components/AssignationsPanel"

import useSingleUserGroupsAssignation from "features/Users/hooks/useSingleUserGroupsAssignation"
import useUserSignaturePreview from "features/Users/hooks/useUserSignaturePreview"

import EditUserAssignationsPanelGroups from "./EditUserAssignationsPanelGroups/EditUserAssignationsPanelGroups"

import { LoadingStatus } from "core/CoreModels"

interface EditUserAssignationsPanelProps {
  userId: number
}

const messages = defineMessages({
  groupsTitle: {
    id: "EditUserAssignationsPanel.GroupsTitle",
    defaultMessage: "Groupe associé",
  },
  groupEmpty: {
    id: "EditUserAssignationsPanel.GroupsEmpty",
    defaultMessage: "Cet utilisateur ne fait partie d'aucun groupe",
  },
  signaturesTitle: {
    id: "EditUserAssignationsPanel.SignaturesTitle",
    defaultMessage: "Signatures associées",
  },
  signaturesEmpty: {
    id: "EditUserAssignationsPanel.SignaturesEmpty",
    defaultMessage: "Cet utilisateur ne diffuse pas de signature",
  },
})

const EditUserAssignationsPanel: FunctionComponent<
  EditUserAssignationsPanelProps
> = ({ userId }) => {
  const { formatMessage } = useIntl()

  const associatedGroups = useSingleUserGroupsAssignation(userId)

  const { previews, loadingStatus } = useUserSignaturePreview(userId)

  return (
    <div className="edit-user-assignations-panel">
      <AssignationsPanel>
        <AssignationsPanel.Item
          title={formatMessage(messages.groupsTitle)}
          emptyLabel={formatMessage(messages.groupEmpty)}
          isEmpty={associatedGroups !== null && associatedGroups.length === 0}
        >
          <EditUserAssignationsPanelGroups
            associatedGroups={associatedGroups}
          />
        </AssignationsPanel.Item>
        <AssignationsPanel.Item
          title={formatMessage(messages.signaturesTitle)}
          emptyLabel={formatMessage(messages.signaturesEmpty)}
          isEmpty={
            loadingStatus === LoadingStatus.LOADED &&
            previews.templates.length === 0
          }
        >
          <AssignationsPanel.Signatures
            displayedIndex={previews.displayedPreviewIndex}
            signatures={previews.templates.map((template) => ({
              name: template.name,
              template: template.template,
              diffusionMode: template.diffusionMode,
              sendingMode: template.sendingMode,
              weighting: template.weighting,
            }))}
            loadingStatus={loadingStatus}
            previous={previews.previousPreview}
            next={previews.nextPreview}
          />
        </AssignationsPanel.Item>
      </AssignationsPanel>
    </div>
  )
}

export default EditUserAssignationsPanel
