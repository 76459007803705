import { ReactElement, FC } from "react"
import { NotificationType } from "../../NotificationModels"

export interface NotificationIconProps {
  type: NotificationType
}

export const NotificationIcon: FC<NotificationIconProps> = ({ type }) => {
  const icons: Map<NotificationType, ReactElement> = new Map<
    NotificationType,
    ReactElement
  >()
  icons.set("INFO", <InfoNotificationIcon />)
  icons.set("ERROR", <ErrorNotificationIcon />)
  icons.set("WARNING", <WarningNotificationIcon />)
  icons.set("SUCCESS", <SuccessNotificationIcon />)

  if (type == null || !icons.has(type)) return

  return icons.get(type)
}

const ErrorNotificationIcon = () => {
  return (
    <div className="notification__background-icon--without-bg">
      <i className="material-icons" style={{ color: "#E91E63", width: "24px" }}>
        error
      </i>
    </div>
  )
}

const InfoNotificationIcon = () => {
  return (
    <div className="notification__background-icon--without-bg">
      <i className="material-icons" style={{ color: "#3d5afe", width: "24px" }}>
        info
      </i>
    </div>
  )
}


const SuccessNotificationIcon = () => {
  return (
    <div className="notification__background-icon--without-bg">
      <i className="material-icons" style={{ color: "#2AD3C2", width: "24px" }}>
        check_circle
      </i>
    </div>
  )
}

const WarningNotificationIcon = () => {
  return (
    <div className="notification__background-icon--without-bg">
      <i className="material-icons" style={{ color: "#ffc107", width: "24px" }}>
        warning
      </i>
    </div>
  )
}
