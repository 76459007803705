import React from "react"

class CardHeader extends React.Component {
  render() {
    const { children } = this.props

    return <div className="dsbmm-card__header">{children}</div>
  }
}

export default CardHeader
