import { FormEventHandler, FunctionComponent, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { defineMessages, useIntl } from "react-intl"

import { Navigate } from "react-router-dom"

import { ReactComponent as LogoBMM } from "assets/logo-text-bmm.svg"

import UnderlinedInputField from "components/UnderlinedInputField"
import ReCaptcha from "components/ReCaptcha"

import PasswordUnderlinedInputField from "features/Accounts/components/PasswordUnderlinedInputField"

import GoogleOauthButton from "features/Accounts/components/GoogleOauthButton"
import O365OauthButton from "features/Accounts/components/O365OauthButton"

import {
  fillCaptcha,
  createCurrentAccount,
} from "features/Accounts/AccountsReducer"

import { addNotification } from "features/Notifications/NotificationReducer"
import { Notification } from "features/Notifications/NotificationModels"

import { accountsSelectors } from "features/Accounts"
import { appSelectors } from "features/App"
import RegisterFormSubmitButton from "./components/RegisterFormSubmitButton"
import RegisterFormPasswordValidator from "./components/RegisterFormPasswordValidator"
import RegisterFormSuccess from "./components/RegisterFormSuccess"

const messages = defineMessages({
  register: {
    id: "RegisterForm.Title",
    defaultMessage: "Créer votre compte",
  },
  emailPlaceholder: {
    id: "RegisterForm.EmailPlaceholder",
    defaultMessage: "Email",
  },
  passwordPlaceholder: {
    id: "RegisterForm.PasswordPlaceholder",
    defaultMessage: "Mot de passe",
  },
  submitButton: {
    id: "RegisterForm.SubmitButton",
    defaultMessage: "Créer mon compte",
  },
  orText: {
    id: "RegisterForm.Or",
    defaultMessage: "ou",
  },
  google: {
    id: "RegisterForm.ConnectionGoogle",
    defaultMessage: "Créer avec Google",
  },
  microsoft: {
    id: "RegisterForm.ConnectionMicrosoft",
    defaultMessage: "Créer avec Microsoft",
  },
  passwordNotValid: {
    id: "RegisterForm.PasswordNotValid",
    defaultMessage: "Votre mot de passe ne remplit pas les critères",
  },
  completeCaptcha: {
    id: "RegisterForm.CompleteCaptcha",
    defaultMessage: "Merci de valider le captcha",
  },
})

const RegisterForm: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const [password, setPassword] = useState("")
  const [passwordIsValid, setPasswordIsValid] = useState(false)

  const redirectTo = useSelector(appSelectors.getRedirectTo)
  const isAuthenticated = useSelector(accountsSelectors.getIsAuthentified)

  const captcha = useSelector(accountsSelectors.getCaptcha)
  const accountIsCreated = useSelector(
    accountsSelectors.getCreateAccountSuccessStatus,
  )

  const checkPassword = () => {
    if (!passwordIsValid) {
      dispatch(
        addNotification(
          new Notification(formatMessage(messages.passwordNotValid), "ERROR"),
        ),
      )
      return true
    }
    return false
  }

  const checkCaptcha = () => {
    if (captcha === null || captcha.length === 0) {
      dispatch(
        addNotification(
          new Notification(formatMessage(messages.completeCaptcha), "ERROR"),
        ),
      )
      return true
    }
    return false
  }

  const handleRegister: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const email: string = e.target["email"].value

    if (checkPassword()) return

    if (checkCaptcha()) return

    dispatch(createCurrentAccount({ email, password }))
  }

  const handleCaptcha = (captchaValue: string) =>
    dispatch(fillCaptcha(captchaValue))

  if (isAuthenticated) return <Navigate to={redirectTo} />

  if (accountIsCreated) return <RegisterFormSuccess />

  return (
    <div className="register-form">
      <LogoBMM className="register-form__logo" />
      <p className="login-form__title">{formatMessage(messages.register)}</p>
      <div className="register-form__form">
        <form className="register-form__form" onSubmit={handleRegister}>
          <UnderlinedInputField
            name="email"
            autoFocus
            placeholder={formatMessage(messages.emailPlaceholder)}
            required
          />
          <PasswordUnderlinedInputField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={formatMessage(messages.passwordPlaceholder)}
          />
          <RegisterFormPasswordValidator
            password={password}
            onValidate={setPasswordIsValid}
          />
          <ReCaptcha
            id="recaptcha"
            onChange={handleCaptcha}
            captcha={captcha}
          />
          <RegisterFormSubmitButton>
            {formatMessage(messages.submitButton)}
          </RegisterFormSubmitButton>
          <div className="register-form__or">
            <hr className="register-form__or--lines" />
            <p className="register-form__or--label">
              {formatMessage(messages.orText)}
            </p>
            <hr className="register-form__or--lines" />
          </div>
        </form>
        <O365OauthButton>{formatMessage(messages.microsoft)}</O365OauthButton>
        <GoogleOauthButton>{formatMessage(messages.google)}</GoogleOauthButton>
      </div>
    </div>
  )
}

export default RegisterForm
