import TenantProperty from "../entities/TenantProperty"

export enum TENANT_PROPERTY_NAMES {
  CUSTOM_MSI_LINK_NAME = "addinmsilink",
  GOOGLE_ANALYTICS_NAME = "googleanalytics",
  MESSAGING_NAME = "messaging",
  TRACKING_JOB_CREATED = "trackingJobCreated",
  ON_BOARDING = "onBoarding",
  SYNC_GSUITE_DOMAIN = "syncgsuitedomain",
  SYNC_GSUITE_ADMIN_MAIL = "syncgsuiteadminmail",
  SYNC_GSUITE_JSON = "gsuitejson",
  OWA_ACCOUNTS = "owaaccounts",
  OWA_ENDPOINT_URL = "owaendpointurl",
}

export default interface TenantProperties {
  googleanalytics: TenantProperty
  companyname: TenantProperty
  business: TenantProperty
  employeesnumber: TenantProperty
  messaging: TenantProperty
  customMsiLink: TenantProperty
  campaignUnlockedProperty: TenantProperty
  trackingJobCreated: TenantProperty
  onBoarding: TenantProperty
  syncgsuitedomain: TenantProperty
  syncgsuiteadminmail: TenantProperty
  gsuitejson: TenantProperty
  owaaccounts: TenantProperty
  owaendpointurl: TenantProperty
}
