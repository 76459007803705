import { FunctionComponent, useEffect, useState } from "react"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { ReactComponent as GroupsEmptyIcon } from "features/Groups/assets/icon-groups-empty-results.svg"
import { ReactComponent as AddGroupIcon } from "features/Groups/assets/icon-add-group.svg"
import { ReactComponent as RefreshIcon } from "assets/icon-refresh.svg"

import { useNavigate } from "react-router-dom"

import SearchBar from "components/SearchBar"

import usePartialGroupsData from "features/Groups/hooks/usePartialGroupsData"

import { resetEditingGroup } from "features/Groups/GroupsReducer"

import GroupsGrid from "features/Groups/components/GroupsGrid"

import { LoadingStatus } from "core/CoreModels"
import { GROUPS_URL } from "router/RouterConstants"
import usePrevious from "hooks/usePrevious"

const messages = defineMessages({
  filter: { id: "GroupsTab.FilterPlaceholder", defaultMessage: "Rechercher" },
  noUsers: {
    id: "GroupsTab.NoUsers",
    defaultMessage: "Vous n'avez pas encore de groupes",
  },
  addUsers: {
    id: "GroupsTab.AddUsers",
    defaultMessage: "Ajoutez-en dès maintenant",
  },
})

const GroupsTab: FunctionComponent = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const {
    partialGroups,
    currentPage,
    lastPage,
    pageSize,
    totalCount,
    loadingStatus,
    searchValue,
    orderBy,
    changePage,
    changePageSize,
    handleSearch,
    reload,
  } = usePartialGroupsData()

  const previousSearchValue = usePrevious(searchValue)

  const handleRedirectToGroupPage = () => {
    dispatch(resetEditingGroup())
    navigate(`${GROUPS_URL}/-1`)
  }
  const [noUsers, setNoUsers] = useState(false)
  const [isLoading, setIsLoading] = useState(loadingStatus)
  const previousLoadingStatus = usePrevious(loadingStatus)

  useEffect(() => {
    let timer
    if (
      loadingStatus === LoadingStatus.PENDING &&
      previousLoadingStatus === LoadingStatus.LOADED
    )
      timer = setTimeout(() => {
        setIsLoading(loadingStatus)
      }, 500)
    else setIsLoading(loadingStatus)
    return () => clearTimeout(timer)
  }, [loadingStatus, previousLoadingStatus])

  useEffect(() => {
    let timer
    if (
      loadingStatus === LoadingStatus.LOADED &&
      totalCount === 0 &&
      searchValue.length === 0 &&
      previousSearchValue?.length === 0
    )
      timer = setTimeout(() => {
        setNoUsers(true)
      }, 100)
    else setNoUsers(false)
    return () => clearTimeout(timer)
  }, [loadingStatus, totalCount, searchValue, previousSearchValue])

  const renderEmpty = () => (
    <div className="groups-tab__empty">
      <GroupsEmptyIcon className="groups-tab__empty__image" />
      <div className="groups-tab__empty__text">
        <span>{formatMessage(messages.noUsers)}</span>
        <span>{formatMessage(messages.addUsers)}</span>
      </div>
    </div>
  )

  const renderData = () => (
    <div className="groups-tab__container">
      <GroupsGrid
        partialGroups={partialGroups}
        currentPage={currentPage}
        lastPage={lastPage}
        pageSize={pageSize}
        changePage={changePage}
        changePageSize={changePageSize}
        sortOrders={orderBy}
        isLoading={isLoading === LoadingStatus.PENDING}
      />
    </div>
  )

  return (
    <div className="groups-tab">
      <div className="groups-tab__header">
        <div className="groups-tab__search">
          <SearchBar
            shrink
            onChange={handleSearch}
            placeholder={formatMessage(messages.filter)}
            defaultValue={searchValue}
          />
        </div>
        <div className="groups-tab__actions">
          <div
            className="groups-tab__action"
            onClick={handleRedirectToGroupPage}
          >
            <AddGroupIcon className="groups-tab__add-icon" />
            <div className="groups-tab__action-libelle">
              <FormattedMessage id="GroupsTab.Add" defaultMessage="Ajouter" />
            </div>
          </div>
          <div className="groups-tab__action" onClick={reload}>
            <RefreshIcon className="groups-tab__refresh-icon" />
            <div className="groups-tab__action-libelle">
              <FormattedMessage
                id="RefreshBtn.Refresh"
                defaultMessage="Actualiser"
              />
            </div>
          </div>
        </div>
      </div>
      {noUsers ? renderEmpty() : renderData()}
    </div>
  )
}

export default GroupsTab
