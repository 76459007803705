import Signature from "entities/Signature"
import {
  BuilderSignature,
  BuilderSignatureDto,
} from "features/BuilderSignatures/BuilderSignaturesModels"
import { SignatureDto } from "features/Signatures/SignaturesModels"

export class SignatureMapper {
  static create(
    signature: SignatureDto | BuilderSignatureDto,
  ): Signature | BuilderSignature {
    return signature.isNewBuilder
      ? new BuilderSignature(signature)
      : new Signature(signature)
  }

  static getSignatureOption(signature: Signature | BuilderSignature): {
    value: number
    label: string
  } {
    return signature instanceof Signature
      ? {
          value: signature.Id,
          label: signature.Name,
        }
      : {
          value: signature.id,
          label: signature.name,
        }
  }

  static getSignatureId(signature: Signature | BuilderSignature): number {
    return signature instanceof Signature ? signature.Id : signature.id
  }
}
