const ORDER_BY = ["asc", "desc"] as const

type OrderBy = typeof ORDER_BY[number]

type QueryOperand = {
  operand: string
  operator: string
  value: string | number
  separator?: string
}

type Query<T> = { [key in keyof T]: QueryOperand | QueryOperand[] }

interface CommonParams {
  pageSize: number
  pageNumber: number
  top: number
  from: string
  to: string
}

export interface QueryParams<T> extends CommonParams {
  orderBy: {
    columnKey: string
    sortBy: OrderBy
  }
  search: string
  queries: Query<Partial<T>>
  fields: string[]
}

export interface RequiredRequestParams extends CommonParams {
  orderby: string
  s: string
  q: string
  fields: string
}

export type Params<T> = Partial<QueryParams<T>>

export type RequestParams = Partial<RequiredRequestParams>

function queryOperandBuilder(q: QueryOperand) {
  return `${q.operand} ${q.operator} ${q.value}${
    q?.separator ? ` ${q.separator}` : ""
  }`
}

function queryBuilder<T>(queries: Query<T>) {
  return Object.values(queries)
    .map((q: QueryOperand | QueryOperand[]) =>
      Array.isArray(q)
        ? q.map((subQ) => queryOperandBuilder(subQ)).join(" ")
        : queryOperandBuilder(q),
    )
    .join(" ")
}

function fieldsBuilder(fields: string[]) {
  return fields.join(",")
}

export function requestParamsBuilder<T>(params: Params<T>): RequestParams {
  return {
    from: params?.from,
    to: params?.to,
    pageSize: params?.pageSize,
    pageNumber: params?.pageNumber,
    top: params?.top,
    orderby:
      params?.orderBy && `${params.orderBy.columnKey} ${params.orderBy.sortBy}`,
    s: params?.search,
    q: params?.queries ? queryBuilder(params.queries) : undefined,
    fields: params?.fields ? fieldsBuilder(params.fields) : undefined,
  }
}
