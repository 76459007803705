import { FunctionComponent, useMemo, useState } from "react"

import classNames from "classnames"

import { defineMessages, useIntl } from "react-intl"

import MouseTooltip from "components/MouseTooltip"

import { ReactComponent as ScenarioIconAdmin } from "assets/icon_scenarios_admin.svg"
import { ReactComponent as SignaturesIconAdmin } from "assets/icon_signatures_admin.svg"
import { ReactComponent as TrackingIconAdmin } from "assets/icon_tracking_admin.svg"
import { ReactComponent as UsersIconAdmin } from "assets/icon_users_admin.svg"
import { ReactComponent as TechnicalIconAdmin } from "assets/icon_technical_admin.svg"
import { ReactComponent as BillingIconAdmin } from "assets/icon_billing_admin.svg"

import { Roles, RoleState } from "features/Accounts/AccountsModels"

interface AccessRightsProps {
  id: string
  currentAccountId: string
  signaturesAdmin: RoleState
  scenariosAdmin: RoleState
  trackingAdmin: RoleState
  usersAdmin: RoleState
  technicalAdmin: RoleState
  billingAdmin: RoleState
  onToggleRole: (id: string, role: Roles) => void
}

const messages = defineMessages({
  signaturesAdmin: {
    id: "AccessRights.SignaturesAdminTooltip",
    defaultMessage: 'Gérer l’accès au menu "Signature"',
  },
  scenariosAdmin: {
    id: "AccessRights.ScenariosAdminTooltip",
    defaultMessage: 'Gérer l’accès au menu "Campagne"',
  },
  trackingAdmin: {
    id: "AccessRights.TrackingAdminTooltip",
    defaultMessage: 'Gérer l’accès au menu "Statistiques"',
  },
  usersAdmin: {
    id: "AccessRights.UsersAdminTooltip",
    defaultMessage: 'Gérer l’accès au menu "Utilisateurs"',
  },
  technicalAdmin: {
    id: "AccessRights.TechnicalAdminTooltip",
    defaultMessage: 'Gérer l’accès au menu "Paramètres"',
  },
  billingAdmin: {
    id: "AccessRights.BillingAdminTooltip",
    defaultMessage: 'Gérer l’accès au menu "Facturation"',
  },
})

const AccessRights: FunctionComponent<AccessRightsProps> = ({
  id,
  currentAccountId,
  signaturesAdmin,
  scenariosAdmin,
  trackingAdmin,
  usersAdmin,
  technicalAdmin,
  billingAdmin,
  onToggleRole,
}) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  const [tooltipMessage, setTooltipMessage] = useState(null)

  const rolesArray = useMemo(
    () => [
      {
        key: 0,
        icon: <SignaturesIconAdmin />,
        role: signaturesAdmin,
        message: messages.signaturesAdmin,
      },
      {
        key: 1,
        icon: <ScenarioIconAdmin />,
        role: scenariosAdmin,
        message: messages.scenariosAdmin,
      },
      {
        key: 2,
        icon: <TrackingIconAdmin />,
        role: trackingAdmin,
        message: messages.trackingAdmin,
      },
      {
        key: 3,
        icon: <UsersIconAdmin />,
        role: usersAdmin,
        message: messages.usersAdmin,
      },
      {
        key: 4,
        icon: <TechnicalIconAdmin />,
        role: technicalAdmin,
        message: messages.technicalAdmin,
      },
      {
        key: 5,
        icon: <BillingIconAdmin />,
        role: billingAdmin,
        message: messages.billingAdmin,
      },
    ],
    [
      signaturesAdmin,
      scenariosAdmin,
      trackingAdmin,
      usersAdmin,
      technicalAdmin,
      billingAdmin,
    ],
  )

  const updateRoleIsDisabled = (role) =>
    currentAccountId === id && role.role === Roles.TechnicalAdmin

  const handleMouseEnter = (message) => {
    setVisible(true)
    setTooltipMessage(message)
  }

  const handleMouseLeave = () => {
    setVisible(false)
  }

  return (
    <>
      <div className="access-rights">
        {rolesArray.map(({ key, icon, role, message }) => (
          <div
            key={key}
            className={classNames(
              "access-rights__element",
              role.isActive ? "active" : "inactive",
              updateRoleIsDisabled(role) && "disabled",
            )}
            onClick={() =>
              !updateRoleIsDisabled(role) && onToggleRole(id, role.role)
            }
            onMouseEnter={() => handleMouseEnter(message)}
            onMouseLeave={handleMouseLeave}
          >
            {icon}
          </div>
        ))}
      </div>
      {tooltipMessage && (
        <MouseTooltip visible={visible}>
          {formatMessage(tooltipMessage)}
        </MouseTooltip>
      )}
    </>
  )
}

export default AccessRights
