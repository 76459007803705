import {
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react"

import classNames from "classnames"

import { ReactComponent as SearchIcon } from "assets/icon-search.svg"
import { ReactComponent as CloseIcon } from "assets/icon-close.svg"

import useDebounce from "hooks/useDebounce"

interface SearchFieldProps {
  onSearch: (searchValue: string) => void
  debounceDelay?: number
}

type SearchFieldType = SearchFieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">

const SearchField: FunctionComponent<SearchFieldType> = ({
  onSearch,
  debounceDelay = 500,
  ...others
}) => {
  const [searchValue, setSearchValue] = useState("")

  const debouncedSearchValue = useDebounce(searchValue, debounceDelay)

  useEffect(() => {
    onSearch(debouncedSearchValue)
  }, [debouncedSearchValue])

  return (
    <div className="search-field-generic">
      <input
        className={classNames("search-field-generic__input", others.className)}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        {...others}
      />
      {searchValue.length > 0 ? (
        <CloseIcon
          className="search-field__icon search-field-generic__icon--close"
          onClick={() => setSearchValue("")}
        />
      ) : (
        <SearchIcon className="search-field-generic__icon" />
      )}
    </div>
  )
}

export default SearchField
