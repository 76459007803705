import { FunctionComponent, useState } from "react"
import { useSelector } from "react-redux"

import config from "config/config"
import moment from "moment"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import { Navigate } from "react-router-dom"

import { PartialGroup } from "features/Groups/GroupsModels"

import { ReactComponent as GroupsEmptyIcon } from "features/Groups/assets/icon-groups-empty-results.svg"

import ConfirmModal from "components/ConfirmModal"
import Toggle from "components/MUIToggle"
import DataGridButton from "components/DataGridButton"
import PaginatedDataGrid, {
  SortOrder,
  SortOrders,
} from "components/PaginatedDataGrid/PaginatedDataGrid"
import { useDispatch } from "react-redux"
import { setPartialGroupsOrderBy } from "features/Groups/GroupsReducer"
import {
  deleteGroupAction,
  upsertLicenceGroupAction,
} from "features/Groups/GroupsActions"
import { OverflowedCell } from "components/PaginatedDataGrid"
import { accountsSelectors } from "features/Accounts"

interface GroupsGridProps {
  partialGroups: PartialGroup[]
  currentPage: number
  lastPage: number
  pageSize: number
  sortOrders: SortOrders
  changePage: (pageNumber: number) => void
  changePageSize: (pageSize: number) => void
  isLoading?: boolean
}

const messages = defineMessages({
  editLegend: { id: "GroupsGrid.EditLegend", defaultMessage: "Editer" },
  deleteLegend: { id: "GroupsGrid.DeleteLegend", defaultMessage: "Supprimer" },
  disableDelete: {
    id: "GroupsGrid.DisableDelete",
    defaultMessage:
      "Veuillez contacter votre administrateur pour effectuer cette action",
  },
  noResults: {
    id: "GroupsTab.NoResults",
    defaultMessage: "Aucun résultats de recherche",
  },
})

const GroupsGrid: FunctionComponent<GroupsGridProps> = ({
  partialGroups,
  currentPage,
  lastPage,
  pageSize,
  sortOrders,
  changePage,
  changePageSize,
  isLoading,
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [redirectToGroup, setRedirectToGroup] = useState<boolean>(false)
  const [selectedGroupId, setSelectedGroupId] = useState<number>(-1)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const isSubsidiaryAdmin = useSelector(
    accountsSelectors.isCurrentAdminSubsidiaryAdmin,
  )

  const handleDeleteGroupClick = (partialGroup) => {
    setSelectedGroupId(partialGroup.id)
    setOpenConfirm(true)
  }

  const handleCloseModal = () => {
    setSelectedGroupId(-1)
    setOpenConfirm(false)
  }

  const deleteGroup = () => {
    dispatch(deleteGroupAction(selectedGroupId))
  }

  const handleGroupSubscription = (partialGroup) => {
    const activated = !partialGroup.activated
    dispatch(upsertLicenceGroupAction(partialGroup.id, activated))
  }

  const handleEditGroup = (partialGroup) => {
    setSelectedGroupId(partialGroup.id)
    setRedirectToGroup(true)
  }

  const handleSort = (columnKey: string, sortOrder: SortOrder) => {
    dispatch(setPartialGroupsOrderBy({ columnKey, sortOrder }))
  }

  const groupsGridConfig = {
    columns: [
      {
        label: (
          <FormattedMessage id="GroupsGrid.Licence" defaultMessage="Licence" />
        ),
        value: (_value, partialGroup) => {
          return (
            <Toggle
              checked={partialGroup.activated}
              onChange={() => handleGroupSubscription(partialGroup)}
            />
          )
        },
        onSort: handleSort,
        sortKey: "activated",
      },
      {
        label: (
          <FormattedMessage
            id="GroupsGrid.GroupName"
            defaultMessage="Nom du groupe"
          />
        ),
        value: (_value, partialGroup) => (
          <OverflowedCell
            text={partialGroup.name || partialGroup.internalName}
          />
        ),
        onSort: handleSort,
        sortKey: "name",
      },
      {
        label: (
          <FormattedMessage
            id="GroupsGrid.NbUsers"
            defaultMessage="Nombre d'utilisateurs"
          />
        ),
        value: (_value, partialGroup) => partialGroup.membersId.length,
      },
      {
        label: (
          <FormattedMessage
            id="GroupsGrid.updateDate"
            defaultMessage="Modifié le"
          />
        ),
        value: (_value, partialGroup) =>
          moment(partialGroup.modified).format("LL"),
        onSort: handleSort,
        sortKey: "modified",
      },
      {
        label: (
          <FormattedMessage id="GroupsGrid.source" defaultMessage="Source" />
        ),
        value: (_value, partialGroup) => partialGroup.origin,
        onSort: handleSort,
        sortKey: "origin",
      },
      {
        label: "",
        renderLoading: false,
        value: (_value, partialGroup) => {
          return (
            <div className="groups-grid__actions__cell">
              <div className="groups-grid__actions__container">
                <div className="groups-grid__actions__icons">
                  <DataGridButton
                    legend={formatMessage(messages.editLegend)}
                    icon={
                      <img
                        alt="edit"
                        className="groups-grid__actions__icon-image"
                        src={`${config.cdnUrl}/images/bmm/icon_edit_hover-01.svg`}
                      />
                    }
                    onClick={() => handleEditGroup(partialGroup)}
                  />
                  <DataGridButton
                    legend={formatMessage(
                      isSubsidiaryAdmin
                        ? messages.disableDelete
                        : messages.deleteLegend,
                    )}
                    icon={
                      <img
                        alt="delete"
                        className="groups-grid__actions__icon-image"
                        src={`${config.cdnUrl}/images/bmm/icon_delete_hover-01.svg`}
                      />
                    }
                    onClick={() => handleDeleteGroupClick(partialGroup)}
                    isDisabled={isSubsidiaryAdmin}
                  />
                </div>
              </div>
            </div>
          )
        },
      },
    ],
    isLoading,
    skeletonRowQuantity: 6,
    background: (
      <div className="groups-grid__empty-results">
        <GroupsEmptyIcon />
        <p className="groups-grid__empty-results--text">
          {formatMessage(messages.noResults)}
        </p>
      </div>
    ),
  }

  if (redirectToGroup)
    return <Navigate to={{ pathname: `/portal/groups/${selectedGroupId}` }} />

  return (
    <div className="groups-grid">
      <ConfirmModal
        action={deleteGroup}
        handleClose={handleCloseModal}
        open={openConfirm}
      />
      <PaginatedDataGrid
        config={groupsGridConfig}
        data={partialGroups}
        currentPage={currentPage}
        lastPage={lastPage}
        pageSize={pageSize}
        changePage={changePage}
        setPageSize={changePageSize}
        sortOrders={sortOrders}
      />
    </div>
  )
}

export default GroupsGrid
