import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { defineMessages } from "react-intl"
import StringHelper from "utils/StringHelper"
import InputText from "components/Input/InputText"
import { InputStyle } from "components/Input/InputText/InputText"
import AccordionItem from "components/Accordion"

const messages = defineMessages({
  name: { id: "SignatureNameInput.Name", defaultMessage: "Nom" },
  newSignature: {
    id: "SignatureNameInput.NewSignature",
    defaultMessage: "Nouvelle signature",
  },
  SectionSignatureName: {
    id: `SignatureEditor.SectionSignatureName`,
    defaultMessage: "Nommer votre signature",
  },
})

interface Props {
  signatureName: string
  reduxError: boolean
  updateSignatureName(sigName: string)
}

const SignatureNameInput: React.FC<Props> = ({
  signatureName,
  reduxError,
  updateSignatureName,
}) => {
  const [name, setName] = useState(signatureName)
  const [showError, setShowError] = useState(false)
  const [inputError, setInputError] = useState(false)

  useEffect(() => {
    setName(signatureName)
  }, [signatureName])

  const handleInputBlur = () => {
    setShowError(name === "")
    setInputError(!StringHelper.validateNoSpecialChar(name))
    if (!inputError) {
      updateSignatureName(name.trim())
    }
  }

  const handleInputChange = (evt) => {
    setName(evt.target.value)
  }
  const intl = useIntl()
  return (
    <AccordionItem
      initialOpen={false}
      forceOpen={reduxError}
      title={intl.formatMessage(messages.SectionSignatureName)}
      icon={
        reduxError && (
          <div className="edit-signature__warning">
            <div className="material-icons">error</div>{" "}
            <div>champ obligatoire</div>
          </div>
        )
      }
    >
      <div className="d-flex signature-name mb-3">
        <div
          className="signature-name__input"
          data-cy="edit-signature__name-input"
        >
          <div className="signature-editor__section signature-editor__signature-name">
            <div className="bmm__label">
              {intl.formatMessage(messages.name)}
            </div>
            <InputText
              inputOptions={{
                order: 1,
                size: "auto",
                visible: "true",
                type: "text",
                name: "",
                placeholder: intl.formatMessage(messages.newSignature),
                label: "",
                variant: InputStyle.OUTLINED,
                value: name,
                onBlur: handleInputBlur,
                onChange: handleInputChange,
              }}
            />
            <span className="error-message">
              {(showError || reduxError) && (
                <FormattedMessage
                  id="SignatureNameInput.MandatoryField"
                  defaultMessage="Champ obligatoire"
                />
              )}
              {inputError && (
                <FormattedMessage
                  id="SignatureNameInput.ErrorMandatoryField"
                  defaultMessage="Ne pas utiliser de caractères spéciaux"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    </AccordionItem>
  )
}

export default SignatureNameInput
