import { FunctionComponent } from "react"

import Podium from "features/Tracking/components/Podium"

const SignaturesPowerUsersLoading: FunctionComponent = () => {
  return (
    <div className="sign-power-users">
      <div className="sign-power-users__side">
        <Podium isLoading />
      </div>
      <div className="sign-power-users__side">
        <div className="sign-power-users__skeleton--container">
          <div className="sign-power-users__skeleton--data" />
          <div className="sign-power-users__skeleton--comment" />
          <div className="sign-power-users__skeleton--signature" />
        </div>
      </div>
    </div>
  )
}

export default SignaturesPowerUsersLoading
