import { FunctionComponent } from "react"

import Loader from "components/Loader"

import ScenariosListEmpty from "features/Scenarios/components/ScenariosListEmpty"

import { useSlicedScenariosDatas } from "features/Scenarios/hooks/useSlicedScenariosDatas"

import useBannersClickedDatas from "features/Tracking/hooks/useBannersClickedDatas"
import useBannersHistoryDatas from "features/Tracking/hooks/useBannersHistoryDatas"

import ScenariosPowerUsers from "features/Tracking/components/ScenariosPowerUsers"
import ScenariosTrackingWrapper from "features/Tracking/components/ScenariosTrackingWrapper"
import ScenariosMostClicked from "features/Tracking/components/ScenariosMostClicked"

import { LoadingStatus } from "core/CoreModels"

const TrackingScenarios: FunctionComponent = () => {
  const { scenarios, loadingStatus } = useSlicedScenariosDatas({
    forceAll: true,
  })
  useBannersClickedDatas()
  useBannersHistoryDatas()

  if (loadingStatus !== LoadingStatus.LOADED)
    return <Loader fullScreen isLoading />

  return scenarios.length === 0 ? (
    <ScenariosListEmpty />
  ) : (
    <div className="tracking-scenarios">
      <div className="tracking-scenarios__double-row">
        <div className="col">
          <ScenariosPowerUsers />
        </div>
        <div className="col">
          <ScenariosMostClicked />
        </div>
      </div>
      <div className="tracking-scenarios__row">
        <div className="col tracking-scenarios__wrapper">
          <ScenariosTrackingWrapper />
        </div>
      </div>
    </div>
  )
}

export default TrackingScenarios
