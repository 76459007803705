import { FunctionComponent } from "react"

import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as EmptyResults } from "features/Signatures/assets/icon-signatures-empty-results.svg"

const messages = defineMessages({
  noResults: {
    id: "SignaturesListLayout.EmptyResults",
    defaultMessage: "Aucun résultat de recherche",
  },
})

const SignaturesListLayoutEmptyResults: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="signatures-list-layout__no-results">
      <EmptyResults />
      <p className="signatures-list-layout__no-results--label">
        {formatMessage(messages.noResults)}
      </p>
    </div>
  )
}

export default SignaturesListLayoutEmptyResults
