import moment from "moment"

import {
  BannersHistoryDto,
  BannersClickedDto,
  BaseHistoryEvent,
  SharedTrackingHistory,
  SignaturesHistoryDto,
  SignaturesClickedDto,
  TrackingGraphData,
  ScenarioTrackingHistory,
  AffectedUsersHistory,
} from "features/Tracking/TrackingModels"

import {
  GRAPH_TICK_VALUES,
  GRAPH_TICK_VALUES_SET,
  GRAPH_WIDTH_BREAKPOINT,
  PRIMARY_GRAPH_COLOR,
  PRIMARY_GRAPH_LIGHT_COLORS,
} from "features/Tracking/TrackingConstants"

const ONE_DAY = 1000 * 60 * 60 * 24

const SharedTrackingMapper = {
  getMaxFromHistory(data: TrackingGraphData[]): number {
    return Math.max(
      ...data.map((history) => history.data.map((values) => values.y)).flat(),
    )
  },
  getYScaleFromMax(max: number): { max: number; values?: number[] } {
    if (max > 1000) return { max: max + 100 }
    if (max > 500) return { max: 1000, values: [0, 200, 400, 600, 800, 1000] }
    if (max > 300) return { max: 500, values: [0, 100, 200, 300, 400, 500] }
    if (max > 200) return { max: 300, values: [0, 60, 120, 180, 240, 300] }
    if (max > 100) return { max: 200, values: [0, 40, 80, 120, 160, 200] }
    if (max > 50) return { max: 100, values: [0, 20, 40, 60, 80, 100] }
    if (max > 25) return { max: 50, values: [0, 10, 20, 30, 40, 50] }
    if (max > 10) return { max: 25, values: [0, 5, 10, 15, 20, 25] }
    return { max: 10, values: [0, 2, 4, 6, 8, 10] }
  },
  getGraphWidthBreakpoint(graphWidth: number) {
    return graphWidth < GRAPH_WIDTH_BREAKPOINT ? "small" : "large"
  },
  getTickValuesFromGraphData(
    graphData: TrackingGraphData[],
    graphWidth: number,
  ) {
    const globalHistory = graphData
      .map((singleHistory) => singleHistory.data)
      .flat()

    const lastHistoryEvent = new Date(
      Math.max(
        ...globalHistory.map((historyEvent) =>
          new Date(historyEvent.x).getTime(),
        ),
      ),
    ).getTime()

    const firstHistoryEvent = new Date(
      Math.min(
        ...globalHistory.map((historyEvent) =>
          new Date(historyEvent.x).getTime(),
        ),
      ),
    ).getTime()

    const graphRange = lastHistoryEvent / ONE_DAY - firstHistoryEvent / ONE_DAY

    const graphTickValueKey = Math.min(
      ...[...GRAPH_TICK_VALUES_SET].filter((val) => val > graphRange),
    )

    const graphBreakpoint =
      SharedTrackingMapper.getGraphWidthBreakpoint(graphWidth)

    return GRAPH_TICK_VALUES[graphBreakpoint][graphTickValueKey]
  },
  getHistoryFromEventsClicked(
    clickedEvents: BannersClickedDto[] | SignaturesClickedDto[],
  ): BaseHistoryEvent[] {
    const historyGroupedByDate = {}

    clickedEvents.forEach((clickedEvent) => {
      const clickedDate = clickedEvent.clickedDate
      clickedDate in historyGroupedByDate
        ? (historyGroupedByDate[clickedDate].quantity += clickedEvent.nbClicks)
        : (historyGroupedByDate[clickedDate] = {
            date: clickedDate,
            quantity: clickedEvent.nbClicks,
          })
    })

    return Object.values(historyGroupedByDate)
  },
  getEventsMaxAffectedUsers(
    historyEvents: Array<SignaturesHistoryDto | BannersHistoryDto>,
  ): { date: string; affectedUsers: number } {
    return historyEvents
      .map((event) => ({
        date: event.created,
        affectedUsers: event.nbAssignedUsers,
      }))
      .reduce(
        (prev, curr) => (prev.affectedUsers > curr.affectedUsers ? prev : curr),
        {
          date: moment(new Date()).format("YYYY-MM-DD"),
          affectedUsers: 0,
        },
      )
  },
  getEventsAffectedUsersHistory(
    historyEvents: Array<SignaturesHistoryDto | BannersHistoryDto>,
  ): AffectedUsersHistory[] {
    return historyEvents.map((event) => ({
      date: event.created,
      affectedUsers: event.nbAssignedUsers,
    }))
  },
  getGlobalHistoryGroupedByDate(
    globalHistory: SharedTrackingHistory,
  ): TrackingGraphData[] {
    const totalClicksPerHistoryItem = globalHistory
      .flat()
      .map((historyItem) => ({
        date: historyItem.date,
        quantity: Object.values(historyItem.data)
          .map((value) => value.quantity)
          .reduce((acc, curr) => acc + curr, 0),
      }))

    const totals = {
      id: "totals",
      label: "totals",
      display: true,
      hover: false,
      selected: false,
      data: SharedTrackingMapper.mergeHistoryEventsByDate(
        totalClicksPerHistoryItem,
      )
        .map((item) => ({
          x: item.date,
          y: item.quantity,
        }))
        .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)),
      color: PRIMARY_GRAPH_COLOR,
      secondaryColor: PRIMARY_GRAPH_LIGHT_COLORS,
    }

    return [totals]
  },
  mergeHistoryEventsByDate(history: BaseHistoryEvent[]) {
    const historyGroupedByDate: { [key: string]: BaseHistoryEvent } = {}

    history.forEach((historyItem) => {
      const { date, quantity } = historyItem
      date in historyGroupedByDate
        ? (historyGroupedByDate[date].quantity += quantity)
        : (historyGroupedByDate[date] = {
            date,
            quantity,
          })
    })

    return Object.values(historyGroupedByDate)
  },
  removeDuplicatesFromTrackingHistoryByDate(
    history: ScenarioTrackingHistory[],
  ) {
    return history.reduce((acc, curr) => {
      if (!acc.find((item) => item.date === curr.date)) acc.push(curr)
      return acc
    }, [])
  },
}

export default SharedTrackingMapper
