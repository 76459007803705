import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import usePrevious from "hooks/usePrevious"
import { useFeatureTogglr } from "features/FeatureTogglr/hooks/useFeatureTogglr"

import Signature from "entities/Signature"

import { getFeaturesLoadingStatus } from "features/FeatureTogglr/FeatureTogglrSelectors"

import { signaturesSelectors } from "../"
import { fetchSignatures } from "../SignaturesReducer"

import { featureNames } from "config/config.features"

import { LoadingStatus } from "core/CoreModels"

interface UseSignaturesData {
  signatures: Array<Signature>
  loadingStatus: LoadingStatus
}

const useSignatureData = (): UseSignaturesData => {
  const dispatch = useDispatch()
  const signatures = useSelector(signaturesSelectors.getAllSignatures)
  const sendingMode = useSelector(signaturesSelectors.getSendingMode)
  const featuresTogglrLoadingStatus = useSelector(getFeaturesLoadingStatus)

  const previousSendingMode = usePrevious(sendingMode)

  const newMailInResponseFeature = useFeatureTogglr(
    featureNames.NEWMAIL_INRESPONSE,
  )

  const signaturesLoadingSlices = newMailInResponseFeature?.isActive
    ? sendingMode
    : "all"

  const loadingStatus = useSelector(
    signaturesSelectors.getSignaturesSliceLoadingStatus(
      signaturesLoadingSlices,
    ),
  )

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.NOT_STARTED &&
      featuresTogglrLoadingStatus === LoadingStatus.LOADED &&
      sendingMode !== previousSendingMode
    ) {
      dispatch(fetchSignatures(signaturesLoadingSlices))
    }
  }, [loadingStatus, dispatch])

  return {
    signatures,
    loadingStatus,
  }
}

export default useSignatureData
