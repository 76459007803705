import { combineReducers } from "redux"
import app from "./App"
import billings from "./Billings"
import enrollments from "./Enrollment"
import groups from "./Groups"
import offers from "./Offers"
import signatures from "./Signatures"
import tourguide from "./TourGuide"
import tracking from "./Tracking"
import trial from "./Trial"
import users from "./Users"
import marketing from "./Marketing"
import features from "./Features"
import notifications from "./Notifications"
import scenarios from "./Scenarios"
import featureTogglr from "./FeatureTogglr"
import { ErrorsReducer as errors } from "./Errors"
import builderSignatures from "./BuilderSignatures"
import jobs from "./Jobs"
import accounts from "./Accounts"
import subsidiaries from "./Subsidiaries/SubsidiariesReducer"
import storage from "./Storage"
import alerts from "./Alerts/AlertsReducer"

const rootReducer = combineReducers({
  app,
  users,
  groups,
  accounts,
  tourguide, // TODO - déplacer le tour guide dans l'account reducer, implique de refactoriser ce dernier
  signatures,
  offers,
  billings,
  enrollments,
  tracking,
  trial,
  marketing,
  features,
  featureTogglr,
  scenarios,
  notifications,
  errors,
  builderSignatures,
  jobs,
  subsidiaries,
  alerts,
  storage,
})

export default rootReducer
