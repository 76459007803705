import { LoadingStatus } from "core/CoreModels"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GlobalStates } from "store"
import {
  fetchSubsidiaries,
  selectAllSubsidiaries,
} from "../SubsidiariesReducer"
import { getUpdatingStatus, getLoadingStatus } from "../SubsidiariesSelectors"
import { isFeatureActive } from "features/FeatureTogglr/FeatureTogglrSelectors"
import { featureNames } from "config/config.features"

const useSubsidiariesData = () => {
  const dispatch = useDispatch()
  const subsidiaries = useSelector(selectAllSubsidiaries)
  const loadingStatus = useSelector((state: GlobalStates) =>
    getLoadingStatus(state),
  )
  const isUpdated = useSelector((state: GlobalStates) =>
    getUpdatingStatus(state),
  )
  const isFeatureSubsidiariesActive = useSelector<GlobalStates>(
    isFeatureActive(featureNames.SUBSIDIARIES),
  )
  const isLoading = loadingStatus == LoadingStatus.PENDING

  useEffect(() => {
    if (
      loadingStatus == LoadingStatus.NOT_STARTED &&
      isFeatureSubsidiariesActive
    )
      dispatch(fetchSubsidiaries())
  }, [isFeatureSubsidiariesActive])

  const getSubsidiariesByUserId = (id) =>
    subsidiaries.filter((subsidiary) => subsidiary.aspNetUserIds.includes(id))

  const getAllAffectedAdmins = (): string[] =>
    subsidiaries
      ?.map((subsidiary) => subsidiary.aspNetUserIds)
      ?.reduce((acc, curVal) => acc.concat(curVal), [])

  const hasSubsidiary = (id) =>
    subsidiaries.some((subsidiary) => subsidiary.aspNetUserIds.includes(id))

  return {
    subsidiaries,
    isLoading,
    isUpdated,
    loadingStatus,
    hasSubsidiary,
    getSubsidiariesByUserId,
    getAllAffectedAdmins,
  }
}

export default useSubsidiariesData
