import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { fetchAdminAccounts } from "features/Accounts/AccountsReducer"
import { accountsSelectors } from ".."
import { LoadingStatus } from "core/CoreModels"
import { AdminAccount } from "../AccountsModels"

interface UseAdminsAccountsData {
  adminAccounts: AdminAccount[]
  tenantAdminAccounts: AdminAccount[]
  subsidiariesAdminAccounts: AdminAccount[]
  loadingStatus: LoadingStatus
}

const useAdminsAccountsData = (): UseAdminsAccountsData => {
  const dispatch = useDispatch()
  const loadingStatus = useSelector(
    accountsSelectors.getAdminAccountsLoadingStatus,
  )
  const adminAccounts = useSelector(accountsSelectors.getAllAdminAccounts)

  const tenantAdminAccounts = useSelector(
    accountsSelectors.getTenantAdminAccounts,
  )

  const subsidiariesAdminAccounts = useSelector(
    accountsSelectors.getSubsidiariesAdminAccounts,
  )

  useEffect(() => {
    if (loadingStatus === LoadingStatus.NOT_STARTED) {
      dispatch(fetchAdminAccounts())
    }
  }, [dispatch, loadingStatus])

  return {
    adminAccounts,
    tenantAdminAccounts,
    subsidiariesAdminAccounts,
    loadingStatus,
  }
}

export default useAdminsAccountsData
