import { FunctionComponent, ReactNode } from "react"

import Stepper from "components/Stepper"

import { useEnrollmentData } from "features/Enrollment/components/EnrollmentContext/EnrollmentContext"

import EnrollmentSummary from "features/Enrollment/components/EnrollmentSummary"

interface Props {
  children: ReactNode
  steps: (string | JSX.Element)[]
}

const EnrollmentLayout: FunctionComponent<Props> = ({ children, steps }) => {
  const { enrollmentDatas, setEnrollmentDatas } = useEnrollmentData()

  const goToStep = (step: number) => {
    setEnrollmentDatas({ currentStep: step })
  }

  return (
    <div className="enroll h-100">
      <div className="enroll__header">
        <Stepper
          activeStep={enrollmentDatas.currentStep}
          onClick={goToStep}
          steps={steps}
        />
      </div>
      <div className="enroll__content">{children}</div>
      {enrollmentDatas.currentStep > 1 &&
        enrollmentDatas.currentStep < steps.length && (
          <div className="enroll__footer">
            <EnrollmentSummary
              onClick={() => {
                goToStep(1)
              }}
            />
          </div>
        )}
    </div>
  )
}

export default EnrollmentLayout
