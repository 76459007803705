import React from "react"

class Card extends React.Component {
  render() {
    const { children } = this.props
    return <div className="dsbmm-card">{children}</div>
  }
}

export default Card
