import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  Update, // pour inspecter l'objet wrapper dans Immer
} from "@reduxjs/toolkit"

import { RootState } from "store/configureStore"

import { SCENARIOS_SLICES } from "./ScenarioConstants"

import { Params } from "core/services/requestBuilderService"
import {
  Scenario,
  ScenarioDto,
  ScenarioFormVm,
  ScenarioVm,
} from "./ScenarioModels"
import { LoadingStatus } from "core/CoreModels"
import {
  createLoadingSlicesAdapter,
  LoadingSlices,
  LoadingSlicesTypes,
} from "core/services/loadingSlicesService"

/**
 * Feature State - gestion des fonctionnalités du tenant
 */

export type ScenariosSlices = typeof SCENARIOS_SLICES[number]

export interface ActivateAction {
  id: number
  activated: boolean
}

export interface ScenarioEntity {
  id: number
  props: Scenario
  usersCount: number
  usersCountLoadingStatus: LoadingStatus
}

interface ScenarioEntities {
  [id: number]: ScenarioEntity
}

export interface DiffusionMode {
  internal: boolean
  external: boolean
}

interface ScenarioCustomQueryEntity {
  requestId: string
  customQuery: Params<ScenarioVm>
  result: Partial<ScenarioDto[]>
}

interface ScenarioCustomQueryEntities {
  [id: number]: ScenarioCustomQueryEntity
}

export interface ScenarioState {
  allScenarios: {
    entities: ScenarioEntities
    ids: Array<number>
  }
  loadingStatus: LoadingStatus
  scenariosSlices: LoadingSlices<ScenariosSlices>
  diffusionMode: DiffusionMode
  prevState: {
    entities: ScenarioEntities
    ids: Array<number>
  }
  scenariosCustomQueries: {
    entities: ScenarioCustomQueryEntities
    ids: Array<number>
  }
}

const scenarioAdapter = createEntityAdapter<Scenario>({
  selectId: (scenario) => scenario.id,
  sortComparer: (a, b) => a.props.name.localeCompare(b.props.name),
})

export const {
  selectAll: selectAllScenarios,
  selectById: selectScenarioById,
  selectIds: selectScenarioIds,
} = scenarioAdapter.getSelectors<RootState>(
  (state) => state.scenarios.allScenarios,
)

const scenariosSlicesAdapter =
  createLoadingSlicesAdapter<ScenariosSlices>(SCENARIOS_SLICES)

export const {
  selectAll: selectAllScenariosSlices,
  selectById: selectScenarioSliceById,
} = scenariosSlicesAdapter.getSelectors<RootState>(
  (state) => state.scenarios.scenariosSlices,
)

const scenariosCustomQueriesAdapter =
  createEntityAdapter<ScenarioCustomQueryEntity>({
    selectId: (scenario) => scenario.requestId,
    sortComparer: (a, b) => a.requestId.localeCompare(b.requestId),
  })

export const {
  selectAll: selectAllScenariosCustomQueries,
  selectById: selectScenariosCustomQueryById,
} = scenariosCustomQueriesAdapter.getSelectors<RootState>(
  (state) => state.scenarios.scenariosCustomQueries,
)

const initialState = {
  allScenarios: scenarioAdapter.getInitialState(),
  loadingStatus: LoadingStatus.NOT_STARTED,
  diffusionMode: { internal: false, external: true },
  scenariosSlices: scenariosSlicesAdapter.getInitialState(),
  prevState: {
    entities: {},
    ids: [],
  },
  scenariosCustomQueries: scenariosCustomQueriesAdapter.getInitialState(),
}

const scenarioReducer = createSlice({
  name: "scenario",
  initialState,
  reducers: {
    fetchScenarios(
      state,
      action: PayloadAction<LoadingSlicesTypes<ScenariosSlices>>,
    ) {
      state.loadingStatus = LoadingStatus.PENDING
      scenariosSlicesAdapter.setSliceToPending(
        state.scenariosSlices,
        action.payload,
      )
    },
    fetchScenariosSuccess(
      state,
      action: PayloadAction<{
        scenarios: Scenario[]
        loadedSlices: LoadingSlicesTypes<ScenariosSlices>
      }>,
    ) {
      const { scenarios, loadedSlices } = action.payload
      scenarioAdapter.upsertMany(state.allScenarios, scenarios)
      state.loadingStatus = LoadingStatus.LOADED
      scenariosSlicesAdapter.setSliceToLoaded(
        state.scenariosSlices,
        loadedSlices,
      )
    },
    fetchScenariosFailure(state) {
      state.loadingStatus = LoadingStatus.ERROR
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createScenario(state, action: PayloadAction<ScenarioFormVm>) {
      state.loadingStatus = LoadingStatus.PENDING
    },
    createScenarioSuccess(state, action) {
      const newScenario = action.payload
      // pour rollback en cas d'erreur
      state.prevState = { ...state.allScenarios }

      state.loadingStatus = LoadingStatus.LOADED
      scenarioAdapter.addOne(state.allScenarios, newScenario)
    },
    createScenarioFailure(state) {
      state.loadingStatus = LoadingStatus.LOADED
      state.allScenarios = { ...state.prevState }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateScenario(state, action: PayloadAction<ScenarioFormVm>) {
      // pour rollback en cas d'erreur
      state.prevState = { ...state.allScenarios }
      state.loadingStatus = LoadingStatus.PENDING
    },
    updateScenarioSuccess(state, action: PayloadAction<Scenario>) {
      const updatedScenario = action.payload
      scenarioAdapter.upsertOne(state.allScenarios, updatedScenario)
      state.loadingStatus = LoadingStatus.LOADED
    },
    updateScenarioFailure(state) {
      state.allScenarios = { ...state.prevState }
      state.loadingStatus = LoadingStatus.LOADED
    },
    duplicateScenarioSuccess(state, action) {
      scenarioAdapter.addOne(state.allScenarios, action.payload)
    },
    activateScenario(state, action: PayloadAction<ActivateAction>) {
      const activatedScenario = state.allScenarios.entities[action.payload.id]
      activatedScenario.props.activated = action.payload.activated
      scenarioAdapter.upsertOne(state.allScenarios, activatedScenario)
    },
    activateScenarioFailure(state, action: PayloadAction<ActivateAction>) {
      const activatedScenario = state.allScenarios.entities[action.payload.id]
      activatedScenario.props.activated = action.payload.activated
      scenarioAdapter.upsertOne(state.allScenarios, activatedScenario)
    },
    deleteScenarioSuccess(state, action: PayloadAction<number>) {
      scenarioAdapter.removeOne(state.allScenarios, action.payload)
    },
    fetchScenarioAssignations(state, action: PayloadAction<number>) {
      scenarioAdapter.updateOne(state.allScenarios, {
        id: action.payload,
        changes: { usersCountLoadingStatus: LoadingStatus.PENDING },
      } as Update<Scenario>)
    },
    fetchScenarioAssignationsSuccess(
      state,
      action: PayloadAction<{ scenarioId: number; count: number }>,
    ) {
      scenarioAdapter.updateOne(state.allScenarios, {
        id: action.payload.scenarioId,
        changes: {
          usersCount: action.payload.count,
          usersCountLoadingStatus: LoadingStatus.LOADED,
        },
      } as Update<Scenario>)
    },
    updateDiffusionMode(state, action: PayloadAction<DiffusionMode>) {
      state.diffusionMode = action.payload
    },
    fetchScenariosCustomQuerySuccess(
      state,
      action: PayloadAction<ScenarioCustomQueryEntity>,
    ) {
      scenariosCustomQueriesAdapter.upsertOne(
        state.scenariosCustomQueries,
        action.payload,
      )
    },
  },
})

export const {
  fetchScenarios,
  fetchScenariosSuccess,
  fetchScenariosFailure,
  deleteScenarioSuccess,
  createScenario,
  createScenarioSuccess,
  createScenarioFailure,
  updateScenario,
  updateScenarioSuccess,
  updateScenarioFailure,
  activateScenario,
  activateScenarioFailure,
  fetchScenarioAssignations,
  fetchScenarioAssignationsSuccess,
  updateDiffusionMode,
  duplicateScenarioSuccess,
  fetchScenariosCustomQuerySuccess,
} = scenarioReducer.actions

export default scenarioReducer.reducer
