import React from "react"
import { FormattedMessage } from "react-intl"
import Countries from "components/Countries"
import { BillingAddress } from "features/Billings/BillingsModels"
import { Account } from "features/Accounts/AccountsModels"

import "./BillingForm.scss"

export interface Props {
  billingAddress: BillingAddress
  isComplete: boolean
  isValidating: boolean
  accountInformations: Account
  fetchBillingAddress(): void
  setBillingAddress(billingAddress: BillingAddress): void
}

export interface State {
  billingAddress: BillingAddress
  errors: {
    name: boolean
    part1: boolean
    zip: boolean
    town: boolean
    countryCode: boolean
    phone: boolean
    siret: boolean
  }
  dirty: {
    name: boolean
    part1: boolean
    zip: boolean
    town: boolean
    countryCode: boolean
    phone: boolean
    siret: boolean
  }
}

class BillingForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    // pour l'instant on charge uniquement le nom du compte courant comme Raison Sociale
    this.props.fetchBillingAddress()
    const billingAddress = this.props.billingAddress
    if (billingAddress.phone === "")
      billingAddress.phone = props.accountInformations.PhoneNumber
    if (billingAddress.name === "")
      billingAddress.name = props.accountInformations.Company

    this.state = {
      billingAddress,
      errors: {
        name: false,
        part1: false,
        zip: false,
        town: false,
        countryCode: false,
        phone: false,
        siret: false,
      },
      dirty: {
        name: false,
        part1: false,
        zip: false,
        town: false,
        countryCode: false,
        phone: false,
        siret: false,
      },
    }
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({ billingAddress: nextProps.billingAddress })
    this.checkInputs()
  }

  handleInputChange = (event) => {
    const value = event.target.value
    const property = event.target.name
    const currState = this.state

    currState.billingAddress[property] = value
    currState.dirty[property] = true
    this.setState(currState)
  }

  handleCountryChange = (country) => {
    const currState = this.state
    currState.billingAddress.countryCode = country
    currState.dirty.countryCode = true
    this.setState(currState)
    this.updateBillingInformations()
  }

  updateBillingInformations = () => {
    this.checkInputs()
    this.props.setBillingAddress(this.state.billingAddress)
  }

  handleBlur = (evt) => {
    this.updateBillingInformations()
  }

  checkInputs = () => {
    const errors = this.state.errors
    for (const key in errors) {
      errors[key] =
        (this.state.dirty[key] || this.props.isValidating) &&
        this.state.billingAddress[key] === ""
    }
    this.setState({ errors })
    for (const key in errors) if (errors[key] === true) return false
    return true
  }

  getTextBoxClass = (fieldName) => {
    if (this.state.errors[fieldName])
      return "billing-form__input billing-form__input--error"
    return "billing-form__input"
  }

  render() {
    const { billingAddress } = this.state

    return (
      <div>
        <div>
          <div className="bmm-form billing-form">
            <div className="bmm-form__content">
              <div className="bmm-form__input__container">
                <div className={this.getTextBoxClass("name")}>
                  <label className="billing-form__label" htmlFor="name">
                    <FormattedMessage
                      id="BillingForm.CompanyText"
                      defaultMessage="Raison sociale"
                    ></FormattedMessage>
                    <input
                      type="text"
                      data-cy="billing-form__name"
                      className="billing-form__textbox"
                      name="name"
                      onChange={this.handleInputChange}
                      value={billingAddress.name}
                      onBlur={this.handleBlur}
                    />
                  </label>
                  {this.state.errors.name && (
                    <span className="billing-form__error-message">
                      <FormattedMessage
                        id="BillingForm.RequiredField"
                        defaultMessage="Champ obligatoire"
                      />
                    </span>
                  )}
                </div>
                <div className="billing-form__input">
                  <label className="billing-form__label" htmlFor="siret">
                    <FormattedMessage
                      id="BillingForm.SiretText"
                      defaultMessage="Numéro de SIRET"
                    ></FormattedMessage>
                    <input
                      type="text"
                      name="siret"
                      data-cy="billing-form__siret"
                      className="billing-form__textbox"
                      onChange={this.handleInputChange}
                      value={billingAddress.siret}
                      onBlur={this.handleBlur}
                    />
                  </label>
                </div>
              </div>
              <div
                className={
                  this.getTextBoxClass("part1") +
                  " bmm-form__input__container bmm-form__input__container--only-one"
                }
              >
                <label
                  className="billing-form__label billing-form__label--full"
                  htmlFor="address"
                >
                  <FormattedMessage
                    id="BillingForm.AddressText"
                    defaultMessage="Adresse"
                  ></FormattedMessage>
                  <input
                    type="text"
                    className="billing-form__textbox"
                    data-cy="billing-form__part1"
                    name="part1"
                    onChange={this.handleInputChange}
                    value={billingAddress.part1}
                    onBlur={this.handleBlur}
                  />
                </label>
                {this.state.errors.part1 && (
                  <span className="billing-form__error-message">
                    <FormattedMessage
                      id="BillingForm.RequiredField"
                      defaultMessage="Champ obligatoire"
                    />
                  </span>
                )}
              </div>
              <div className="bmm-form__input__container">
                <div className={this.getTextBoxClass("zip")}>
                  <label className="billing-form__label" htmlFor="zip">
                    <FormattedMessage
                      id="BillingForm.PostalCodeText"
                      defaultMessage="Code postal"
                    ></FormattedMessage>
                    <input
                      type="text"
                      data-cy="billing-form__zip"
                      className="billing-form__textbox"
                      name="zip"
                      onChange={this.handleInputChange}
                      value={billingAddress.zip}
                      onBlur={this.handleBlur}
                    />
                  </label>
                  {this.state.errors.zip && (
                    <span className="billing-form__error-message">
                      <FormattedMessage
                        id="BillingForm.RequiredField"
                        defaultMessage="Champ obligatoire"
                      />
                    </span>
                  )}
                </div>
                <div className={this.getTextBoxClass("town")}>
                  <label className="billing-form__label" htmlFor="city">
                    <FormattedMessage
                      id="BillingForm.CityText"
                      defaultMessage="Ville"
                    ></FormattedMessage>
                    <input
                      type="text"
                      data-cy="billing-form__town"
                      className="billing-form__textbox"
                      name="town"
                      onChange={this.handleInputChange}
                      value={billingAddress.town}
                      onBlur={this.handleBlur}
                    />
                  </label>
                  {this.state.errors.town && (
                    <span className="billing-form__error-message">
                      <FormattedMessage
                        id="BillingForm.RequiredField"
                        defaultMessage="Champ obligatoire"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className="bmm-form__input__container">
                <div className={this.getTextBoxClass("country")}>
                  <label className="billing-form__label" htmlFor="country">
                    <FormattedMessage
                      id="BillingForm.Country"
                      defaultMessage="Pays *"
                    ></FormattedMessage>
                    <Countries
                      value={this.state.billingAddress.countryCode}
                      onChange={this.handleCountryChange}
                    />
                  </label>
                  {this.state.errors.countryCode && (
                    <span className="billing-form__error-message">
                      <FormattedMessage
                        id="BillingForm.RequiredField"
                        defaultMessage="Champ obligatoire"
                      />
                    </span>
                  )}
                </div>
                <div className={this.getTextBoxClass("phone")}>
                  <label className="billing-form__label" htmlFor="phone">
                    <FormattedMessage
                      id="BillingForm.PhoneText"
                      defaultMessage="Téléphone"
                    ></FormattedMessage>
                    <input
                      type="text"
                      data-cy="billing-form__phone"
                      className="billing-form__textbox"
                      name="phone"
                      onChange={this.handleInputChange}
                      value={billingAddress.phone}
                      onBlur={this.handleBlur}
                    />
                  </label>
                  {this.state.errors.phone && (
                    <span className="billing-form__error-message">
                      <FormattedMessage
                        id="BillingForm.RequiredField"
                        defaultMessage="Champ obligatoire"
                      />
                    </span>
                  )}
                </div>
              </div>
              <br />
              {/* <div className="bmm-form__button-zone">
                              <button className="bmm-button" onClick={() => this.handleSaveBillingAddress()}><FormattedMessage id="BillingForm.Save" defaultMessage="Enregistrer" /></button>
                          </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BillingForm
