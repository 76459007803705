import React, { CSSProperties } from "react"
import { List, Typography } from "@material-ui/core"
import ListGenericItem from "./ListGenericItem"

import type { Item } from "./ListGenericItem"

interface Props {
  data: Item[]
  avatarSrc?: string
  styleOptions?: CSSProperties
  grouped?: boolean
  selectedData?: Item[]
  onClick?: (item: Item) => void
  onDelete?: (item: Item) => void
}

/**
 * Composant liste permettant de grouper et de supprimer les éléments
 */
const ListGeneric: React.FC<Props> = (props) => {
  const {
    data,
    avatarSrc,
    styleOptions,
    grouped,
    selectedData,
    onClick,
    onDelete,
  } = props

  function listeElement() {
    return data
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
      .map((value, index, list) => {
        let group = ""
        if (index === 0) {
          group = list[index].label.substring(0, 1)
        } else if (
          list[index - 1].label.substring(0, 1) !==
          list[index].label.substring(0, 1)
        ) {
          group = list[index].label.substring(0, 1)
        }
        return (
          <div key={value.id}>
            {grouped && <Typography>{group.toUpperCase()}</Typography>}
            <ListGenericItem
              item={value}
              button={onClick ? true : null}
              onClick={onClick}
              avatarSrc={avatarSrc}
              onDelete={onDelete}
              checked={
                selectedData && selectedData.map((i) => i.id).includes(value.id)
              }
            />
          </div>
        )
      })
  }

  return (
    <div className="list-generic">
      <List style={styleOptions} dense={true}>
        {listeElement()}
      </List>
    </div>
  )
}

export default ListGeneric
