import React from "react"

import { FormattedMessage } from "react-intl"

import iconHand from "features/Enrollment/assets/icon-hand.svg"
import iconSpeaker from "features/Enrollment/assets/icon-speaker.svg"
import iconRocket from "features/Enrollment/assets/icon-rocket.svg"
import iconSignature from "features/Enrollment/assets/icon-signature.svg"
import iconSupport from "features/Enrollment/assets/icon-support.svg"
import visualLaptop from "features/Enrollment/assets/visual-laptop.png"

import "./EnrollmentStep.scss"
const EnrollmentStep1Side: React.FC = () => {
  return (
    <div className="enroll__box enroll__aside">
      <header>
        <div className="enroll__aside__title">
          <FormattedMessage
            id="Enroll.StepSide1a"
            defaultMessage="La solution de gestion n°1"
          />
        </div>
        <div className="enroll__aside__subtitle">
          <FormattedMessage
            id="Enroll.StepSide1b"
            defaultMessage="pour vos signatures de mail"
          />
        </div>
      </header>
      <section>
        <div style={{ textAlign: "center" }}>
          <img
            src={visualLaptop}
            width="349"
            className="enroll__box__visual"
            alt=""
          />
        </div>
        <ul>
          <li>
            <img alt="" src={iconSignature} />{" "}
            <FormattedMessage
              id="Enroll.labelSignature"
              defaultMessage="Des signatures de mail design"
            />
          </li>
          <li>
            <img alt="" src={iconSpeaker} />{" "}
            <FormattedMessage
              id="Enroll.labelCampaign"
              defaultMessage="Des campagne créatives"
            />
          </li>
          <li>
            <img alt="" src={iconHand} />{" "}
            <FormattedMessage
              id="Enroll.labelUi"
              defaultMessage="Une interface simplissime"
            />
          </li>
          <li>
            <img alt="" src={iconRocket} />{" "}
            <FormattedMessage
              id="Enroll.labelDeploy"
              defaultMessage="Un déploiement ultra-rapide"
            />
          </li>
          <li>
            <img alt="" src={iconSupport} />{" "}
            <FormattedMessage
              id="Enroll.labelSupport"
              defaultMessage="Un support premium"
            />
          </li>
        </ul>
      </section>
    </div>
  )
}

export default EnrollmentStep1Side
