import { TrialActionTypes } from "."
import { TrialState } from "store/trialState"

const initialState = {
  trialStep: 0,
}

const trialReducer = (state: TrialState = initialState, action) => {
  switch (action.type) {
    case TrialActionTypes.SET_TRIAL_STEP_SUCCESS:
    case TrialActionTypes.FETCH_TRIAL_SUCCESS:
      return { ...state, trialStep: action.trialStep }
    default:
      return state
  }
}

export default trialReducer
