import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit"

import { GlobalStates } from "store"
import { Alert, AlertStatus } from "./AlertsModels"
import { LoadingStatus } from "core/CoreModels"


interface AlertsEntities {
  [id: string]: Alert
}

interface AlertState {
  allAlerts:{
    entities: AlertsEntities
    ids: Array<EntityId>
    loadingStatus: LoadingStatus
  }
}

const alertsAdapter = createEntityAdapter<Alert>({
  selectId: (alert) => alert.id,
  sortComparer: (a, b) => a.id - b.id,
})

export const { selectById: selectAlertById, selectAll: selectAllAlerts } =
alertsAdapter.getSelectors<GlobalStates>((state) => state.alerts.allAlerts)


const initialState: AlertState = {
  allAlerts: alertsAdapter.getInitialState({
    loadingStatus: LoadingStatus.NOT_STARTED,
  }),
}

const alertsReducer = createSlice({
  name: "adminAlerts",
  initialState,
  reducers: {
    fetchAllAlerts(state) {
      state.allAlerts.loadingStatus = LoadingStatus.PENDING
    },
    fetchAllAlertsSuccess(state, action: PayloadAction<Alert[]>) {
      alertsAdapter.setAll(state.allAlerts, action.payload)
      state.allAlerts.loadingStatus = LoadingStatus.LOADED
    },
    fetchAllAlertsFailure(state) {
      state.allAlerts.loadingStatus = LoadingStatus.ERROR
    },

    updateAlert(state, action: PayloadAction<number>){
      state.allAlerts.loadingStatus = LoadingStatus.PENDING
    },
    updateAlertSuccess(state, action: PayloadAction<number>){
      alertsAdapter.updateOne(
        state.allAlerts,
        {
          id: action.payload,
          changes: {readStatus: AlertStatus.Read}
        })
      state.allAlerts.loadingStatus = LoadingStatus.LOADED
    },
    updateAlertFailure(state){
      state.allAlerts.loadingStatus = LoadingStatus.ERROR
    },
  },
})

export const {
  fetchAllAlerts,
  fetchAllAlertsSuccess,
  fetchAllAlertsFailure,
  updateAlert,
  updateAlertFailure,
  updateAlertSuccess
} = alertsReducer.actions

export default alertsReducer.reducer
