import { FunctionComponent, ReactElement } from "react"

import Toggle from "components/MUIToggle"
import HoverTooltip from "components/HoverTooltip"

import { DataGridAdminAccount } from "features/Accounts/AccountsModels"

interface SuperAdminToggleProps {
  currentAccountId: string
  adminAccount: DataGridAdminAccount
  onChange: () => void
  tooltip: ReactElement
}

const SuperAdminToggle: FunctionComponent<SuperAdminToggleProps> = ({
  currentAccountId,
  adminAccount,
  onChange,
  tooltip,
}) => {
  const toggle = (
    <Toggle
      checked={adminAccount.isSuperAdmin()}
      onChange={() => adminAccount.id !== currentAccountId && onChange()}
    />
  )

  if (adminAccount.id === currentAccountId)
    return (
      <HoverTooltip tooltip={tooltip} level="warning">
        {toggle}
      </HoverTooltip>
    )

  return toggle
}

export default SuperAdminToggle
