import { FunctionComponent, useState } from "react"

import LoginSliderCallToAction from "./components/LoginSliderCallToAction"
import LoginSliderContent from "./components/LoginSliderContent"
import LoginSliderMessages from "./components/LoginSliderMessages"
import LoginSliderNav from "./components/LoginSliderNav"

const LoginSlider: FunctionComponent = () => {
  const [current, setCurrent] = useState<number>(0)

  const handlePrevious = () => setCurrent((prevState) => prevState - 1)

  const handleNext = () => setCurrent((prevState) => prevState + 1)

  return (
    <>
      <div className="login-slider">
        <LoginSliderMessages current={current} />
        <LoginSliderContent current={current} />
        <LoginSliderNav
          current={current}
          last={2}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      </div>
      <LoginSliderCallToAction />
    </>
  )
}

export default LoginSlider
