import React from "react"
import {
  injectIntl,
  defineMessages,
  IntlShape,
  FormattedMessage,
} from "react-intl"
import Button from "components/Button"
import Feature from "../../../FeaturesModels"
import config from "config/config"
import GSuiteSAConfigForm from "../GSuiteSAConfigForm"

interface Props {
  intl: IntlShape
  featureGmail: Feature
  onValidate(feature: Feature)
  onCancel()
}

const messages = defineMessages({
  keyTitle: {
    id: "GSuiteForm.KeyTitle",
    defaultMessage: "Copie du fichier clé privée/publique",
  },
  keyDescription: {
    id: "GSuiteForm.KeyDescription",
    defaultMessage:
      "Téléchargé sur votre poste après la création du compte de service",
  },
  keyPlaceholder: {
    id: "GSuiteForm.KeyPlaceholder",
    defaultMessage: "Clé privée/publique",
  },
  formBtn: { id: "GSuiteForm.FormBtn", defaultMessage: "Enregistrer" },
  cancelBtn: { id: "GSuiteForm.CancelBtn", defaultMessage: "Annuler" },
  required: { id: "GSuiteForm.Required", defaultMessage: "Champ obligatoire" },
})

const GmailOptionsForm: React.FC<Props> = (props) => {
  const { intl, featureGmail: feature, onCancel, onValidate } = props

  return (
    <div className="gsuite-options-form">
      <div className="gsuite-options-form__title">
        <FormattedMessage
          id="GMailOptions.Title"
          defaultMessage={
            "Déployer les signatures de vos collaborateurs sur GMail"
          }
        ></FormattedMessage>
      </div>
      <GSuiteSAConfigForm
        bmmServiceAccountId={config.gsuiteServiceAccount.ServiceAccountId}
        scopes={config.gsuiteServiceAccount.Scopes}
        googleAdminUrl={config.gsuiteServiceAccount.GoogleWorkspaceAdminUrl}
      />

      <div className="gmail-options-form__btn">
        <Button
          type="button"
          classes="bmm-button--reverse"
          label={intl.formatMessage(messages.cancelBtn)}
          onClick={onCancel}
        ></Button>
        <Button
          label={intl.formatMessage(messages.formBtn)}
          onClick={() => onValidate(feature)}
        />
      </div>
    </div>
  )
}

export default injectIntl(GmailOptionsForm)
