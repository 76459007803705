import { FunctionComponent, ReactNode } from "react"

import { ReactComponent as CloseIcon } from "assets/icon-close.svg"

import UserSignaturePreviewPreview, {
  UserSignaturePreviewPreviewType,
} from "./UserSignaturePreviewPreview"
import UserSignaturePreviewData, {
  UserSignaturePreviewDataType,
} from "./UserSignaturePreviewData"
import UserSignaturePreviewInformations, {
  UserSignaturePreviewInformationsType,
} from "./UserSignaturePreviewInformations"
import UserSignaturePreviewNavigation, {
  UserSignaturePreviewNavigationType,
} from "./UserSignaturePreviewNavigation"

interface UserSignaturePreviewWrapperComposition {
  Preview: UserSignaturePreviewPreviewType
  Data: UserSignaturePreviewDataType
  Infos: UserSignaturePreviewInformationsType
  Navigation: UserSignaturePreviewNavigationType
}

interface UserSignaturePreviewWrapperProps {
  children: ReactNode
  close: () => void
}

const UserSignaturePreviewWrapper: FunctionComponent<UserSignaturePreviewWrapperProps> &
  UserSignaturePreviewWrapperComposition = ({ children, close }) => {
  return (
    <div className="user-signature-preview">
      <div className="user-signature-preview__container">
        <button className="user-signature-preview__close-btn" onClick={close}>
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  )
}

UserSignaturePreviewWrapper.Preview = UserSignaturePreviewPreview
UserSignaturePreviewWrapper.Data = UserSignaturePreviewData
UserSignaturePreviewWrapper.Infos = UserSignaturePreviewInformations
UserSignaturePreviewWrapper.Navigation = UserSignaturePreviewNavigation

export default UserSignaturePreviewWrapper
