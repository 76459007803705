import { FunctionComponent } from "react"

import classNames from "classnames"

import { FormattedMessage, defineMessages, useIntl } from "react-intl"

import AddIcon from "@material-ui/icons/Add"

import SearchField from "components/SearchField"
import PaginatedDataGrid from "components/PaginatedDataGrid"
import DeleteButtonWithTip from "components/DeleteButtonWithTip"
import HoverTooltip from "components/HoverTooltip"

import SubsidiariesCell from "./components/SubsidiariesCell"
import ToggleSubsidiaryButton from "./components/ToggleSubsidiaryButton"

import { ReactComponent as UsersEmptyIcon } from "features/Users/assets/icon-users-empty-results.svg"
import { ReactComponent as WarningIcon } from "assets/icon-warning.svg"

import EditableEmail from "features/Accounts/components/EditableEmail"
import AccessRights from "features/Accounts/components/AccessRights"

import { NEW_SUBSIDIARY_ADMIN_ID } from "features/Subsidiaries/components/SubsidiariesAdminsPanel/SubsidiariesAdminsPanel"

import { DataGridAdminAccount, Roles } from "features/Accounts/AccountsModels"

interface SubsidiariesAdminsGridProps {
  data: DataGridAdminAccount[]
  isEditing: boolean
  isSaving: boolean
  disableSave: boolean
  currentPage: number
  lastPage: number
  pageSize: number
  currentAccountId: string
  savingErrorMessage: string | null
  onAddLine: () => void
  onSearch: (searchValue: string) => void
  onSave: () => void
  onDisableSave: (value: boolean) => void
  onEditing: () => void
  onEditEmail: (email: string, id: string) => void
  changePage: (page: number) => void
  setPageSize: (pageSize: number) => void
  onToggleRole: (id: string, role: Roles) => void
  onDeleteAdminAccount: (id: string) => void
  adminsCount?: number
  isLoading?: boolean
}

interface DataProvider {
  isLoading: boolean
  currentAccountId: string
  savingErrorMessage: string | null
  onDisableSave: (value: boolean) => void
  onEditing: () => void
  onEditEmail: (email: string, id: string) => void
  onToggleRole: (id: string, role: Roles) => void
  onDeleteAdminAccount: (id: string) => void
}

const messages = defineMessages({
  title: {
    id: "SubsidiariesAdminsGrid.Title",
    defaultMessage: "Administrateurs",
  },
  search: {
    id: "SubsidiariesAdminsGrid.SearchBarPlaceholder",
    defaultMessage: "Rechercher",
  },
  addTooltip: {
    id: "SubsidiariesAdminsGrid.AddTooltip",
    defaultMessage: "Ajouter un administrateur",
  },
})

function subsidiariesAdminsGridConfig(dataProvider: DataProvider) {
  const columns = [
    {
      label: (
        <FormattedMessage
          id="SubsidiariesAdminsGrid.Email"
          defaultMessage="email"
        />
      ),
      value: (_value, adminAccount) => (
        <div className="subsidiaries-admins-grid__cell--container start">
          <EditableEmail
            initialValue={adminAccount.userName}
            id={adminAccount.id}
            isNew={adminAccount.isNew}
            isSuperAdmin={adminAccount.isSuperAdmin()}
            onEditing={dataProvider.onEditing}
            onEditEmail={dataProvider.onEditEmail}
            onDisableSave={dataProvider.onDisableSave}
          />
        </div>
      ),
      cellsClassName: "subsidiaries-admins-grid__cell--email",
    },
    {
      renderLoading: false,
      value: (_value, adminAccount) =>
        adminAccount.isError && (
          <div className="subsidiaries-admins-grid__cell--container">
            <HoverTooltip
              level="warning"
              tooltip={dataProvider.savingErrorMessage}
              align="left"
            >
              <WarningIcon className="subsidiaries-admins-grid__cell--warning" />
            </HoverTooltip>
          </div>
        ),
      cellsClassName: "subsidiaries-admins-grid__cell--error",
    },
    {
      label: (
        <div className="subsidiaries-admins-grid__header-item">
          <FormattedMessage
            id="SubsidiariesAdminsGrid.Susidiaries"
            defaultMessage="filiales"
          />
        </div>
      ),
      value: (_value, adminAccount) => (
        <div className="subsidiaries-admins-grid__cell--container">
          <SubsidiariesCell adminAccount={adminAccount} />
        </div>
      ),
      cellsClassName: "subsidiaries-admins-grid__cell--subsidiaries",
    },
    {
      label: (
        <div className="subsidiaries-admins-grid__header-item">
          <FormattedMessage
            id="SubsidiariesAdminsGrid.AccessRights"
            defaultMessage="droits d'accès"
          />
        </div>
      ),
      value: (_value, adminAccount) => (
        <AccessRights
          id={adminAccount.id}
          currentAccountId={dataProvider.currentAccountId}
          onToggleRole={dataProvider.onToggleRole}
          signaturesAdmin={adminAccount.isSignaturesAdmin()}
          scenariosAdmin={adminAccount.isScenariosAdmin()}
          trackingAdmin={adminAccount.isTrackingAdmin()}
          usersAdmin={adminAccount.isUsersAdmin()}
          technicalAdmin={adminAccount.isTechnicalAdmin()}
          billingAdmin={adminAccount.isBillingAdmin()}
        />
      ),
    },
    {
      label: (
        <div className="admins-grid__header-item">
          <FormattedMessage
            id="AdminsGrid.ToggleSubsidiary"
            defaultMessage="bascule filiale"
          />
        </div>
      ),
      renderLoading: false,
      value: (_value, adminAccount) => {
        if (
          adminAccount.id === dataProvider.currentAccountId ||
          adminAccount.id === NEW_SUBSIDIARY_ADMIN_ID
        )
          return null

        return <ToggleSubsidiaryButton adminAccount={adminAccount} />
      },
      cellsClassName: "subsidiaries-admins-grid__cell--toggle",
    },
    {
      renderLoading: false,
      value: (_value, adminAccount) => (
        <div className="admins-grid__btn-delete">
          <DeleteButtonWithTip
            message={
              <FormattedMessage
                id="AdminsGrid.DeleteTooltip"
                defaultMessage="Supprimer l'administrateur"
              />
            }
            onClick={() => dataProvider.onDeleteAdminAccount(adminAccount.id)}
          />
        </div>
      ),
      cellsClassName: "subsidiaries-admins-grid__cell--delete",
    },
  ]

  return {
    columns,
    skeletonRowQuantity: 6,
    background: (
      <div className="subsidiaries-admins-grid__empty-results">
        <UsersEmptyIcon />
        <p className="subsidiaries-admins-grid__empty-results--text">
          <FormattedMessage
            id="SubsidiariesAdminsGrid.NoResults"
            defaultMessage="Aucun résultats de recherche"
          />
        </p>
      </div>
    ),
    isLoading: dataProvider.isLoading,
  }
}

const handleRowStyle = (rowData) => {
  let rowProps
  if (rowData.isNew) {
    rowProps = {
      ...rowProps,
      style: {
        backgroundColor: "#f6faff",
      },
    }
  }
  if (rowData.isError) {
    rowProps = {
      ...rowProps,
      style: {
        backgroundColor: "#FFF6DA",
      },
    }
  }
  return rowProps
}

const SubsidiariesAdminsGrid: FunctionComponent<
  SubsidiariesAdminsGridProps
> = ({
  data,
  isEditing,
  isSaving,
  disableSave,

  onAddLine,
  onSave,
  onSearch,
  adminsCount,
  isLoading,
  currentPage,
  lastPage,
  pageSize,
  changePage,
  setPageSize,
  onDisableSave,
  onEditing,
  onEditEmail,
  currentAccountId,
  onToggleRole,
  savingErrorMessage,
  onDeleteAdminAccount,
}) => {
  const { formatMessage } = useIntl()

  const dataProvider = {
    isLoading,
    currentAccountId,
    onDisableSave,
    onEditing,
    onEditEmail,
    onToggleRole,
    savingErrorMessage,
    onDeleteAdminAccount,
  }

  return (
    <div className="subsidiaries-admins-grid__container">
      <div className="subsidiaries-admins-grid__header">
        <div className="subsidiaries-admins-grid__title">
          <p className="subsidiaries-admins-grid__title--label">
            {formatMessage(messages.title)}
          </p>
          {adminsCount !== undefined && (
            <p className="subsidiaries-admins-grid__title--count">
              {adminsCount}
            </p>
          )}
        </div>
        <div className="subsidiaries-admins-grid__search">
          <SearchField
            onSearch={onSearch}
            placeholder={formatMessage(messages.search)}
          />
        </div>
        <div className="subsidiaries-admins-grid__actions">
          {!isEditing && (
            <button
              type="button"
              className="subsidiaries-admins-grid__btn"
              onClick={() => onAddLine()}
            >
              <HoverTooltip
                tooltip={formatMessage(messages.addTooltip)}
                level="info"
              >
                <AddIcon />
              </HoverTooltip>
            </button>
          )}
          {isEditing && (
            <button
              type="button"
              className={classNames(
                "subsidiaries-admins-grid__btn-save",
                isSaving && "is-saving",
              )}
              onClick={onSave}
              disabled={disableSave}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
      <PaginatedDataGrid
        config={subsidiariesAdminsGridConfig(dataProvider)}
        data={data}
        currentPage={currentPage}
        lastPage={lastPage}
        pageSize={pageSize}
        sortOrders={[]}
        changePage={changePage}
        setPageSize={setPageSize}
        getRowProps={handleRowStyle}
      />
    </div>
  )
}

export default SubsidiariesAdminsGrid
