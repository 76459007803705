import { ChangeEvent, FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"

import { partial } from "lodash"
import classNames from "classnames"

import Toggle from "components/MUIToggle"

import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import Feature from "features/Features/FeaturesModels"

import { AvailableJobs } from "features/Jobs/JobsConstants"

interface O365SyncProps {
  featureO365: Feature
  handleToggle: (
    feature: Feature,
    _evt: ChangeEvent<HTMLInputElement>,
    isActive: boolean,
  ) => void
  launchJob: (feature: Feature) => void
}

const messages = defineMessages({
  syncADTitle: {
    id: "O365Form.SyncADTitle",
    defaultMessage: "Annuaire utilisateurs",
  },
  syncADDescription1: {
    id: "O365Form.SyncADDescription1",
    defaultMessage:
      "Activez la synchronisation automatique de votre annuaire utilisateurs ",
  },
  syncADDescription2: {
    id: "O365Form.SyncADDescription2",
    defaultMessage: "toutes les 24 heures",
  },
  syncADDisable: {
    id: "O365Form.SyncADDisable",
    defaultMessage: "Désactivée",
  },
  syncADEnable: {
    id: "O365Form.SyncADEnable",
    defaultMessage: "Activée",
  },
  manualSync: {
    id: "O365Form.ManualSync",
    defaultMessage: "Synchroniser manuellement",
  },
})

const O365Sync: FunctionComponent<O365SyncProps> = ({
  featureO365,
  handleToggle,
  launchJob,
}) => {
  const { formatMessage } = useIntl()
  const [launchSync, setLaunchSync] = useState(false)

  const isSyncing = useJobIsRunning(AvailableJobs.SYNC_USERS)

  useEffect(() => {
    if (!isSyncing) setLaunchSync(false)
  }, [isSyncing])

  const handleSync = () => {
    setLaunchSync(true)
    launchJob(featureO365)
  }

  return (
    <div className="o365-toggle">
      <p className="o365-toggle__title">
        {formatMessage(messages.syncADTitle)}
      </p>
      <p className="o365-toggle__description">
        {formatMessage(messages.syncADDescription1)}
        <span className="o365-toggle__description--bold">
          {formatMessage(messages.syncADDescription2)}
        </span>
      </p>
      <div className="o365-toggle__action">
        <div
          className={`o365-toggle__action--${
            featureO365.activated ? "inactive" : "active"
          }`}
        >
          {formatMessage(messages.syncADDisable)}
        </div>
        <div className="o365-toggle__toggle">
          <Toggle
            checked={featureO365.activated}
            onChange={partial(handleToggle, featureO365)}
          />
        </div>
        <div
          className={`o365-toggle__action--${
            featureO365.activated ? "active" : "inactive"
          }`}
          onClick={() => partial(handleToggle, featureO365)}
        >
          {formatMessage(messages.syncADEnable)}
        </div>
      </div>
      {featureO365.activated && (
        <button
          type="button"
          className="o365-toggle__sync"
          onClick={handleSync}
          disabled={launchSync || isSyncing}
        >
          <p className="o365-toggle__sync--label">
            {formatMessage(messages.manualSync)}
          </p>
          <SyncIcon
            className={classNames(
              "o365-toggle__sync--icon",
              (launchSync || isSyncing) && "is-loading",
            )}
          />
        </button>
      )}
    </div>
  )
}

export default O365Sync
