import { FunctionComponent, ReactNode } from "react"

interface UserSignaturePreviewDataProps {
  children: ReactNode
}

export type UserSignaturePreviewDataType =
  FunctionComponent<UserSignaturePreviewDataProps>

const UserSignaturePreviewData: UserSignaturePreviewDataType = ({
  children,
}) => {
  return <div className="user-signature-preview__data">{children}</div>
}

export default UserSignaturePreviewData
