import React, { FunctionComponent } from "react"

const WarningIcon: FunctionComponent = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5596_52604)">
        <path
          d="M29.7665 26.1799L25.6332 19.0199L21.4998 11.8666C20.8332 10.7133 19.1665 10.7133 18.4998 11.8666L14.3665 19.0266L10.2332 26.1866C9.5665 27.3399 10.3998 28.7866 11.7332 28.7866H19.9998H28.2665C29.5998 28.7799 30.4332 27.3333 29.7665 26.1799ZM19.9998 25.6066C19.6998 25.6066 19.4598 25.3399 19.4598 25.0133C19.4598 24.6866 19.6998 24.4199 19.9998 24.4199C20.2998 24.4199 20.5398 24.6866 20.5398 25.0133C20.5398 25.3399 20.2998 25.6066 19.9998 25.6066ZM20.8332 17.7066L20.4998 22.1133C20.4798 22.3933 20.2598 22.6133 20.0065 22.6133C19.7465 22.6133 19.5332 22.3933 19.5132 22.1133L19.1798 17.7066C19.1598 17.4533 19.2398 17.1999 19.3998 17.0066C19.5598 16.8199 19.7798 16.7133 20.0132 16.7133C20.2465 16.7133 20.4665 16.8199 20.6265 17.0066C20.7732 17.1999 20.8465 17.4533 20.8332 17.7066Z"
          fill="#FFC107"
        />
      </g>
      <defs>
        <clipPath id="clip0_5596_52604">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WarningIcon
