/**
 * Représente les informations d'un contact à enregistrer dans la mailing list
 */

export class MailingContact {
  Email: string
  Attributes: {}
}

export default MailingContact
