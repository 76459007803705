import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { JobData } from "./JobsModels"

export interface JobsState {
  queue: Array<JobData>
}

const initialState = {
  queue: [],
}

const jobsReducer = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    addJob: (state, action: PayloadAction<JobData>) => {
      state.queue.push(action.payload)
    },
    removeJob: (state, action: PayloadAction<JobData>) => {
      const { queue } = state
      const index = queue.findIndex((job) => job.jobId === action.payload.jobId)
      if (index !== -1) {
        queue.splice(index, 1)
      }
    },
    updateJob: (state, action: PayloadAction<JobData>) => {
      const { queue } = state
      const index = queue.findIndex((job) => job.jobId === action.payload.jobId)
      if (index !== -1) {
        queue[index] = action.payload
      }
    },
  },
})

export const { addJob, removeJob, updateJob } = jobsReducer.actions

export default jobsReducer.reducer
