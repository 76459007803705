import { FunctionComponent } from "react"

import { Link } from "react-router-dom"
import { defineMessages, useIntl } from "react-intl"

import { ReactComponent as LockIcon } from "assets/icon-lock.svg"

import { ENROLLMENT_URL } from "router/RouterConstants"

const messages = defineMessages({
  label: {
    id: "HomePage.NeedSubscriptionLabel",
    defaultMessage: "Nécessite un abonnement actif",
  },
  cta: {
    id: "HomePage.NeedSubscriptionCta",
    defaultMessage: "S'abonner",
  },
})

const HomePageTileNeedSubcription: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <div className="home-tile__need-subscription--container">
      <LockIcon className="home-tile__need-subscription--icon" />
      <p className="home-tile__need-subscription--label">
        {formatMessage(messages.label)}
      </p>
      <Link to={ENROLLMENT_URL} className="home-tile__need-subscription--cta">
        {formatMessage(messages.cta)}
      </Link>
    </div>
  )
}

export default HomePageTileNeedSubcription
