import { Dispatch, useEffect, useReducer } from "react"

import { TrackingGraphData } from "../TrackingModels"

interface TrackingGraphStateType {
  data: TrackingGraphData[]
}

export enum Types {
  Init = "INIT_DATA",
  HoverData = "HOVER_DATA",
  StopHoverData = "STOP_HOVER_DATA",
  SelectData = "SELECT_DATA",
  UnselectData = "UNSELECT_DATA",
}

interface InitData<T> {
  type: Types.Init
  payload: { data: T; mapper: (data: T) => TrackingGraphData[] }
}

interface HoverData {
  type: Types.HoverData
  payload: string | number
}

interface StopHoverData {
  type: Types.StopHoverData
}

interface SelectData {
  type: Types.SelectData
  payload: string | number
}

interface UnselectData {
  type: Types.UnselectData
}

export type ActionType<T> =
  | InitData<T>
  | HoverData
  | StopHoverData
  | SelectData
  | UnselectData

interface UseTrackingGraphData<T> {
  data: TrackingGraphData[]
  dispatch: Dispatch<ActionType<T>>
}

function init<T>(state: TrackingGraphStateType, action: InitData<T>) {
  const data = action.payload.mapper(action.payload.data)

  return { ...state, data }
}

function hoverData(state: TrackingGraphStateType, action: HoverData) {
  const { data } = state

  const isSelected = data.find((history) => history.selected === true)

  if (isSelected) return { ...state }

  const updatedData = data.map((history) => {
    if (history.id !== action.payload) return { ...history, hover: true }
    return { ...history, hover: false }
  })

  return { ...state, data: [...updatedData] }
}

function stopHoverData(state: TrackingGraphStateType) {
  const { data } = state

  const isSelected = data.find((history) => history.selected === true)

  if (isSelected) return { ...state }

  const updatedData = data.map((history) => {
    return { ...history, hover: false }
  })

  return { ...state, data: [...updatedData] }
}

function selectData(state: TrackingGraphStateType, action: SelectData) {
  const { data } = state
  const updatedData = data.map((history) => {
    if (history.id === action.payload)
      return { ...history, display: true, selected: true, hover: false }
    return { ...history, display: false, selected: false }
  })

  return { ...state, data: [...updatedData] }
}

function unselectData(state: TrackingGraphStateType) {
  const { data } = state
  const updatedData = data.map((history) => {
    return { ...history, display: true, selected: false }
  })

  return { ...state, data: [...updatedData] }
}

const initialState = {
  data: [],
}

function reducer<T>(
  state: TrackingGraphStateType = initialState,
  action: ActionType<T>,
): TrackingGraphStateType {
  let newState: TrackingGraphStateType
  switch (action.type) {
    case Types.Init: {
      newState = init(state, action)
      break
    }
    case Types.HoverData: {
      newState = hoverData(state, action)
      break
    }
    case Types.StopHoverData: {
      newState = stopHoverData(state)
      break
    }
    case Types.SelectData: {
      newState = selectData(state, action)
      break
    }
    case Types.UnselectData: {
      newState = unselectData(state)
      break
    }
    default:
      throw new Error("Unknown action")
  }
  return newState
}

const useTrackingGraphData = <T>(
  trackingData: T,
  mapper: (data: T) => TrackingGraphData[],
): UseTrackingGraphData<T> => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { data } = state

  useEffect(() => {
    dispatch({ type: Types.Init, payload: { data: trackingData, mapper } })
  }, [trackingData])

  return { data, dispatch }
}

export default useTrackingGraphData
