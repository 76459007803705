import { FunctionComponent, useEffect, useState } from "react"

import { defineMessages, useIntl } from "react-intl"
import classNames from "classnames"

import { ReactComponent as SyncIcon } from "assets/icon-synchro.svg"

import useJobIsRunning from "features/Jobs/hooks/useJobIsRunning"

import Toggle from "components/MUIToggle"

import Feature from "features/Features/FeaturesModels"

interface SellsyToggleProps {
  featureSellsy: Feature
  launchJob: (feature: Feature) => void
  handleToggle: (active: boolean) => void
}

const messages = defineMessages({
  title: {
    id: "SellsyForm.ToggleTitle",
    defaultMessage: "Déploiement des signatures Sellsy",
  },
  description: {
    id: "SellsyForm.ToggleDescription",
    defaultMessage:
      "Activez la synchronisation automatique, deux fois par jour, de vos signatures mail auprès de vos utilisateurs Sellsy",
  },
  disabled: {
    id: "SellsyForm.ToggleDisable",
    defaultMessage: "Désactivé",
  },
  enabled: {
    id: "SellsyForm.ToggleEnable",
    defaultMessage: "Activé",
  },
  manualSync: {
    id: "SellsyForm.ManualSync",
    defaultMessage: "Synchroniser manuellement",
  },
})

const SellsyToggle: FunctionComponent<SellsyToggleProps> = ({
  featureSellsy,
  launchJob,
  handleToggle,
}) => {
  const { formatMessage } = useIntl()

  const [launchSync, setLaunchSync] = useState(false)

  const isSyncing = useJobIsRunning("DeploySellsy")

  useEffect(() => {
    if (!isSyncing) setLaunchSync(false)
  }, [isSyncing])

  const handleSync = () => {
    setLaunchSync(true)
    launchJob(featureSellsy)
  }

  return (
    <div className="sellsy-toggle">
      <p className="sellsy-toggle__title">{formatMessage(messages.title)}</p>
      <p className="sellsy-toggle__description">
        {formatMessage(messages.description)}
      </p>
      <div className="sellsy-toggle__action">
        <div
          className={`sellsy-toggle__action--${
            featureSellsy?.activated ? "inactive" : "active"
          }`}
          onClick={() => handleToggle(false)}
        >
          {formatMessage(messages.disabled)}
        </div>
        <div className="sellsy-toggle__toggle">
          <Toggle
            checked={featureSellsy?.activated}
            onChange={() => handleToggle(!featureSellsy.activated)}
          />
        </div>
        <div
          className={`sellsy-toggle__action--${
            featureSellsy?.activated ? "active" : "inactive"
          }`}
          onClick={() => handleToggle(true)}
        >
          {formatMessage(messages.enabled)}
        </div>
      </div>
      {featureSellsy.activated && (
        <button
          type="button"
          className="sellsy-toggle__sync"
          onClick={handleSync}
          disabled={launchSync || isSyncing}
        >
          <p className={"sellsy-toggle__sync--label"}>
            {formatMessage(messages.manualSync)}
          </p>
          <SyncIcon
            className={classNames(
              "sellsy-toggle__sync--icon",
              (launchSync || isSyncing) && "is-loading",
            )}
          />
        </button>
      )}
    </div>
  )
}

export default SellsyToggle
