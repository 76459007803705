import React from "react"
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from "react-intl"
import config from "config/config"
import ButtonCard from "components/ButtonCard"
import FileUploadCard from "components/FileUploadCard"
import { useState } from "react"
import Button from "components/Button"
import Modal from "components/ModalLegacy"
import { SizeString } from "components/ModalLegacy/Modal"

interface Props {
  intl: IntlShape
  open: boolean
  close: () => void
  excelModelLink: string
  uploadFile: (file: File) => void
}

const messages = defineMessages({
  title: {
    id: "ImportExcelPopup.Title",
    defaultMessage: "Importer un fichier Excel",
  },
  btnDownload: {
    id: "ImportExcelPopup.BtnDownload",
    defaultMessage: "Téléchargez",
  },
  btnModel: {
    id: "ImportExcelPopup.BtnModel",
    defaultMessage: " notre modèle",
  },
})

const ImportExcelPopup: React.FC<Props> = (props) => {
  const { intl, open, close, excelModelLink, uploadFile } = props
  const [step, setStep] = useState(1)
  const [heightUpload, setHeightUpload] = useState(165)
  const [widthUpload, setWidthUpload] = useState(189)
  const [excelFile, setExcelFile] = useState<File>(null)

  const handleFileChange = (file) => {
    setExcelFile(file)
    setHeightUpload(97)
    setWidthUpload(400)
    setStep(2)
  }

  const handleCancel = () => {
    reset()
  }

  const handleUploadFile = () => {
    uploadFile(excelFile)
    close()
    reset()
  }

  const reset = () => {
    setHeightUpload(165)
    setWidthUpload(189)
    setStep(1)
  }

  return (
    <div className="import-excel-popup">
      <Modal
        open={open}
        close={close}
        maxWidth={SizeString.SM}
        title={intl.formatMessage(messages.title)}
        dialogContent={
          <div className="import-excel-popup__body">
            {step == 1 && (
              <div className="import-excel-popup__label">
                <FormattedMessage
                  id="ImportExcelPopup.ImportText"
                  defaultMessage="Complétez votre liste d'utilisateurs en important un fichier Excel"
                />
              </div>
            )}
            <div className="import-excel-popup__actions">
              {step === 1 && (
                <div className="import-excel-popup__model">
                  <a
                    className="import-excel-popup__model__link"
                    href={excelModelLink}
                  >
                    <ButtonCard
                      header={
                        <div className="import-excel-popup__actions__header">
                          1
                        </div>
                      }
                      imageSrc={`${config.cdnUrl}/images/bmm/users/ic_file_excel.svg`}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}}
                    >
                      <span style={{ color: "#3D5AFE" }}>
                        {intl.formatMessage(messages.btnDownload)}
                      </span>
                      <span>{intl.formatMessage(messages.btnModel)}</span>
                    </ButtonCard>
                  </a>
                </div>
              )}
              <div style={{ height: heightUpload, width: widthUpload }}>
                <FileUploadCard
                  label={
                    <FormattedMessage
                      id="FileUpload.DropExcelAlt"
                      defaultMessage="Cliquez ou déposez votre fichier Excel ici"
                    />
                  }
                  labelAllowedFiles={<></>}
                  header={
                    <div className="import-excel-popup__actions__header">2</div>
                  }
                  onFileChange={handleFileChange}
                  onCancel={handleCancel}
                  imageFile={`${config.cdnUrl}/images/bmm/users/ic_file_excel.svg`}
                  fileTypes=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ></FileUploadCard>
              </div>
            </div>
          </div>
        }
        dialogActions={
          step === 2 ? (
            <Button label="Envoyer" onClick={handleUploadFile}></Button>
          ) : null
        }
      ></Modal>
    </div>
  )
}

export default injectIntl(ImportExcelPopup)
