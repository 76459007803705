import { FunctionComponent } from "react"

import { useSelector } from "react-redux"
import { FormattedMessage, useIntl } from "react-intl"
import moment from "moment"
import { Scrollbars } from "react-custom-scrollbars-2"
import { NavLink } from "react-router-dom"

import { VerticalBreadCrumb } from "components/BreadCrumb"

import SignatureReadOnlyView from "features/Signatures/SignatureReadOnlyView/SignatureReadOnlyView"

import { signaturesSelectors } from "features/Signatures"
import { usersSelectors } from "features/Users"

import Config from "config/config"

import { trialBreadCrumbMessages } from "../TrialTunnelContext"
import { TRIAL_TUNNEL_URL } from "router/RouterConstants"

const SignaturePrevisualisation: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const activeSignature = useSelector(signaturesSelectors.getActiveSignature)
  const currentUser = useSelector(usersSelectors.getSampleUser)

  const renderNavigation = () => (
    <div className="previsualisation__button-zone">
      <NavLink
        to={`${TRIAL_TUNNEL_URL}/2`}
        className="bmm-button previsu__button"
        data-cy="trial__stepPrevisu-prev"
      >
        <FormattedMessage
          id="Previsualisation.Precedent"
          defaultMessage="Précédent"
        />
      </NavLink>
      <NavLink
        to={`${TRIAL_TUNNEL_URL}/4`}
        className="bmm-button previsu__button"
        data-cy="trial__stepPrevisu-next"
      >
        <FormattedMessage
          id="Previsualisation.AddBannerMail"
          defaultMessage="Ajouter une bannière mail"
        />
      </NavLink>
    </div>
  )

  const breadCrumbNodes = [
    {
      number: 1,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep1),
    },
    {
      number: 2,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep2),
    },
    {
      number: 3,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep3),
    },
    {
      number: 4,
      name: formatMessage(trialBreadCrumbMessages.TrialBreadCrumbStep4),
    },
  ]

  const fakeMailContent = (
    <FormattedMessage
      id="Previsualisation.ContentSignature"
      defaultMessage="Comme convenu, vous trouverez ci-dessous votre nouvelle signature mail."
    />
  )

  const sampleProperties = {
    ...Config.defaultUser,
    ...(currentUser && currentUser.Properties),
  }

  return (
    <section className="l-2-columns__content">
      <main className="l-2-columns__main-content l-2-columns__main-content-last">
        <section className="multistep__main-content multistep__main-last">
          <div className="signature-previsualisation__container">
            <div className="signature-previsualisation__content">
              <Scrollbars>
                <h4 className="signature-previsualisation__header-title">
                  <FormattedMessage
                    id="SignaturePrevisualisation.Header"
                    defaultMessage="Prévisualiser votre signature mail"
                  />
                </h4>
                <div className="signature-previsualisation__lizeret" />
                <div className="infos-banner__container">
                  <div className="infos-banner infos-banner--infos">
                    <div className="infos-banner__text__container">
                      <span className="infos-banner__link__title">
                        <FormattedMessage
                          id="SignaturePrevisualisation.Congratulations"
                          defaultMessage="Félicitations ! Votre signature email vous attend sur votre messagerie."
                        />
                      </span>
                    </div>
                    <div className="infos-banner__right">
                      <i className="material-icons">mail_outline</i>
                    </div>
                  </div>
                  <div className="signature-previsualisation__card">
                    <div className="mail__header">
                      <div className="mail__header-right">
                        <div className="mail__rounded_profil">
                          <div>
                            {sampleProperties.lastname &&
                              sampleProperties.lastname[0]}
                          </div>
                        </div>
                        <div className="mail_header-text">
                          <div className="mail_header-title">
                            {`${sampleProperties.firstname} ${sampleProperties.lastname}`}
                          </div>
                          <div className="mail_header-subtitle">
                            <FormattedMessage
                              id="Previsualisation.To"
                              defaultMessage="à Boost My Mail"
                            />
                            <i className="material-icons">arrow_drop_down</i>
                          </div>
                        </div>
                      </div>
                      <div className="mail__header-date">
                        {moment().format("LL")}
                      </div>
                    </div>
                    <div className="mail-content">
                      <FormattedMessage
                        id="Previsualisation.Content1"
                        defaultMessage="Bonjour,"
                      />
                      <br />
                      {fakeMailContent}
                      <br />
                      <FormattedMessage
                        id="Previsualisation.Content3"
                        defaultMessage="Bien à vous,"
                      />
                      <br />
                      <FormattedMessage
                        id="Previsualisation.Content4"
                        defaultMessage="La team Boost My Mail"
                      />
                    </div>
                    <div className="mail-footer">
                      <SignatureReadOnlyView
                        model={activeSignature.Template.compiledContent}
                      />
                    </div>
                  </div>
                </div>
                {renderNavigation()}
              </Scrollbars>
            </div>
          </div>
        </section>
      </main>
      <div className="breadcrumb-container breadcrumb-container__sigcreation">
        <VerticalBreadCrumb nodes={breadCrumbNodes} step={3} />
      </div>
    </section>
  )
}

export default SignaturePrevisualisation
